import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { activateOrgMembershipWithLogin, activateUserAndOrgMembership } from "@notemeal/shared/ui/utils/tokens";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { useCookieContext } from "../../contexts/Cookie";
import { NAV_ROOT } from "../Auth/AuthPaths";

export const ActivateUserPage = () => {
  const { activateToken: maybeActivateToken } = useParams();
  const activateToken = maybeActivateToken || "";
  const { forceRefresh } = useCookieContext();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorUsername, setErrorUsername] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [linkToExistingAccount, setLinkToExistingAccount] = useState(false);

  const handleChangeUsername = (username: string) => {
    setUsername(username);
    setErrorUsername("");
    setErrorPassword("");
    setErrorConfirmPassword("");
  };

  const handleChangePassword = (password: string) => {
    setPassword(password);
    setErrorUsername("");
    setErrorPassword("");
    setErrorConfirmPassword("");
  };

  const handleChangeConfirmPassword = (confirmPassword: string) => {
    setConfirmPassword(confirmPassword);
    setErrorUsername("");
    setErrorPassword("");
    setErrorConfirmPassword("");
  };

  const handleCreateAccount = () => {
    if (password.length < 8) {
      setErrorPassword("Password must have at least 8 characters");
    } else if (!linkToExistingAccount && password !== confirmPassword) {
      setErrorConfirmPassword("Passwords must match");
    } else {
      const activatePromise = linkToExistingAccount
        ? activateOrgMembershipWithLogin({ activateToken, username, password })
        : activateUserAndOrgMembership({ activateToken, username, password });

      activatePromise
        .then(res => {
          forceRefresh();
          navigate(NAV_ROOT);
        })
        .catch(err => {
          const errors = err.response.data.errors;
          if (errors) {
            errors.forEach((e: string) => {
              if (e.includes("user exists")) {
                setErrorPassword(`${e.replace("Error: ", "")}, use 'Click here to login' link below`);
              } else if (e.includes("account does not belong to")) {
                setErrorUsername(`${e.replace("Error: ", "")}, use 'Forgot username?' link below`);
              } else {
                setErrorUsername(e);
              }
            });
          }
        });
    }
  };

  const title = linkToExistingAccount ? "Log into my account" : "Create an account";
  const subtitle = `${
    linkToExistingAccount ? "Link your invitation to an existing account" : "Claim your invitation"
  } by entering your details below.`;
  const linkText = linkToExistingAccount
    ? "No login? Click here to create a new account."
    : "Already have an account? Click here to login.";
  const buttonText = linkToExistingAccount ? "Login" : "Create Account";
  const disableButton = !username && !password && !confirmPassword;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Username"
          placeholder="john.doe"
          error={!!errorUsername}
          helperText={errorUsername}
          value={username}
          onChange={e => handleChangeUsername(e.target.value)}
        />
        <TextField
          label="Password"
          error={!!errorPassword}
          helperText={errorPassword}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={e => handleChangePassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {!linkToExistingAccount && (
          <TextField
            label="Confirm Password"
            error={!!errorConfirmPassword}
            helperText={errorConfirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={e => handleChangeConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle confirm password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    size="large"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        <Button
          variant="text"
          sx={{
            px: 0.5,
            color: "black",
            backgroundColor: "white",
            "&.MuiButton-root:hover": {
              backgroundColor: "#EAECF0",
            },
            alignSelf: "flex-end",
          }}
          onClick={() => setLinkToExistingAccount(!linkToExistingAccount)}
        >
          {linkText}
        </Button>
      </Box>
      <Button disabled={disableButton} onClick={handleCreateAccount}>
        {buttonText}
      </Button>
    </>
  );
};

import React, { useReducer } from "react";
import { getInitialBulkEditTimingState } from "../../utils";
import TimingEditor from "../TimingEditor";
import { timingReducer } from "../timingReducer";
import { BaseBulkEditProps, BaseContentProps } from "../types";
import BaseContent from "./BaseContent";

interface TimingContentProps extends BaseContentProps {
  onBulkEdit: BaseBulkEditProps["onBulkEdit"];
  clientTimezone: BaseBulkEditProps["clientTimezone"];
}

const TimingContent = ({ isOpen, onBack, onCancel, onBulkEdit, mealMenuInstances, saving, clientTimezone }: TimingContentProps) => {
  const mealMenuIds = mealMenuInstances.map(mealMenuInstance => mealMenuInstance.id);
  const initialTimingState = getInitialBulkEditTimingState(mealMenuInstances, clientTimezone);
  const [timingState, timingDispatch] = useReducer(timingReducer, initialTimingState);

  return (
    <BaseContent
      isOpen={isOpen}
      onCancel={onCancel}
      saving={saving}
      onSubmit={async () => {
        await onBulkEdit({
          mealMenuIds,
          actionType: "timing",
          sharingState: null,
          notificationSentBeforeOrderDueInMinutes: null,
          timingState,
        });
        onBack();
      }}
      title={`Adjust Timings for ${mealMenuInstances.length} Meal Menu(s)`}
      renderContent={() => <TimingEditor timingState={timingState} dispatch={timingDispatch} />}
      submitDisabled={mealMenuInstances.length === 0 || saving}
    />
  );
};

export default TimingContent;

import { LinearProgress, Theme, Typography, linearProgressClasses, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { round } from "../utils/numbers";

interface FatProgressBarProps {
  current: number;
  target: number;
  unit: string;
  usePercent?: boolean;
  error?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fatProgressContainer: {
      width: 90,
      gridTemplateRows: "16px",
      gridTemplateColumns: "30px 30px 30px",
      paddingLeft: theme.spacing(2),
      display: "grid",
    },
    fatProgressLabel: {
      zIndex: 1000,
      gridRowStart: 1,
      gridColumnStart: 2,
      paddingLeft: theme.spacing(),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    root: {
      height: "inherit",
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
  })
);

const FatProgressBar = ({ current, target, unit, usePercent = false, error = false }: FatProgressBarProps) => {
  const {
    palette: { info },
  } = useTheme();
  const value = (100 * current) / target;
  const classes = useStyles();
  return (
    <div className={classes.fatProgressContainer}>
      <LinearProgress
        sx={{
          height: "inherit",
          borderRadius: "3px",
          gridRowStart: 1,
          gridColumnStart: 1,
          gridColumnEnd: 4,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: info.lighter,
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: info.light,
          },
        }}
        variant="determinate"
        value={Math.min(100, value)}
      />
      <div className={classes.fatProgressLabel}>
        <Typography variant="subtitle1">{usePercent ? `${round(value, 0)}%` : `${current}/${target}${unit}`}</Typography>
      </div>
    </div>
  );
};

export default FatProgressBar;

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Typography } from "@mui/material";
import { getMenuItemMaxAmount } from "@notemeal/shared/ui/MenuItem/utils";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import { MealMenuDiningStationWithItemPreviewsFragment, MenuItemAppearancePreviewFragment } from "../../../types";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import DiningStation from "./DiningStation";

interface MenuOrderDiningStationListProps {
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[];
  currentOrderDiningStationAmounts: { [id: string]: number | undefined };
  currentOrderMenuItemAmounts: { [menuItemId: string]: number | undefined };
  timelineMealFlow?: boolean;
  onSelectItem: (
    appearance: MenuItemAppearancePreviewFragment,
    currentAmountForDiningStation: number,
    maxAmountForDiningStation: number | null
  ) => void;
  forOrder: boolean;
  onBack: () => void;
}

const MenuOrderDiningStationList = ({
  onSelectItem,
  mealMenuDiningStations,
  timelineMealFlow,
  forOrder,
  currentOrderDiningStationAmounts,
  currentOrderMenuItemAmounts,
  onBack,
}: MenuOrderDiningStationListProps) => {
  const { setMessage } = useSnackbar();

  const handleSelectItem = (
    appearance: MenuItemAppearancePreviewFragment,
    forOrder: boolean,
    currentAmountForDiningStation: number,
    maxAmountForDiningStation: number | null
  ) => {
    const maxAmount = getMenuItemMaxAmount({
      currentAmountForMenuSection: currentAmountForDiningStation,
      maxAmountForMenuSection: maxAmountForDiningStation,
      currentAmountForMenuItem: currentOrderMenuItemAmounts[appearance.menuItem.id] || 0,
      maxAmountForMenuItem: appearance.maxAmount,
    });

    if (maxAmount !== null && maxAmount <= 0) {
      setMessage("warning", `Cannot add any more ${appearance.menuItem.name} to order!`);
    } else if (forOrder && !appearance.availableForOrder) {
      setMessage("warning", "Item not available for order");
    } else {
      onSelectItem(appearance, currentAmountForDiningStation, maxAmountForDiningStation);
    }
  };

  return (
    <>
      {!timelineMealFlow && (
        <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
          <IconButton
            size="small"
            onClick={onBack}
            sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h3">
            <strong>Add Item to {forOrder ? "Order" : "Log"}</strong>
          </Typography>
        </Box>
      )}
      {sortByKey(mealMenuDiningStations, "position").map(mealMenuDiningStation => {
        const currentAmountForDiningStation = currentOrderDiningStationAmounts[mealMenuDiningStation.id] || 0;
        return (
          <DiningStation
            key={mealMenuDiningStation.id}
            mealMenuDiningStation={mealMenuDiningStation}
            onSelectItem={(appearance, forOrder) =>
              handleSelectItem(appearance, forOrder, currentAmountForDiningStation, mealMenuDiningStation.maxAmount)
            }
            forOrder={forOrder}
            currentAmountForDiningStation={currentAmountForDiningStation}
          />
        );
      })}
    </>
  );
};

export default MenuOrderDiningStationList;

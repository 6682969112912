import { ServingAmountForConversionFragment } from "../../types";
import { ServingAmountWithConversion } from "./getServingAmountsWithConversions";

interface GroupedServingAmountConversion {
  __typename: "GroupedServingAmountConversion";
  foodOrRecipe: {
    id: string;
    name: string;
  };
  unitPrefix: string | null;
  unitSuffix: string | null;
  servingId: string;
  position: number;
  conversionAmounts: {
    amount: number;
    position: number;
    toUnit: {
      id: string;
      name: string;
    };
  }[];
}

export type GroupedServingAmountWithConversion<SA extends ServingAmountForConversionFragment> = SA | GroupedServingAmountConversion;

export const groupServingAmountsWithConversions = <SA extends ServingAmountForConversionFragment>(
  servingAmountsWithConversions: readonly ServingAmountWithConversion<SA>[]
): readonly GroupedServingAmountWithConversion<SA>[] => {
  const { servingAmounts, groupsMap } = servingAmountsWithConversions.reduce<{
    servingAmounts: readonly SA[];
    groupsMap: Record<string, GroupedServingAmountConversion | undefined>;
  }>(
    ({ servingAmounts, groupsMap }, nextServingAmount) => {
      if (nextServingAmount.__typename === "ServingAmount") {
        return {
          servingAmounts: [...servingAmounts, nextServingAmount],
          groupsMap,
        };
      } else {
        const groupKey = `${nextServingAmount.unitPrefix || ""}:${nextServingAmount.foodOrRecipe.id}:${nextServingAmount.unitSuffix || ""}`;
        const matchingGroup = groupsMap[groupKey];
        if (matchingGroup) {
          return {
            servingAmounts,
            groupsMap: {
              ...groupsMap,
              [groupKey]: {
                ...matchingGroup,
                conversionAmounts: [
                  ...matchingGroup.conversionAmounts,
                  {
                    amount: nextServingAmount.amount,
                    position: nextServingAmount.position,
                    toUnit: nextServingAmount.toUnit,
                  },
                ],
              },
            },
          };
        } else {
          const { position, foodOrRecipe, unitPrefix, unitSuffix, servingId } = nextServingAmount;
          return {
            servingAmounts,
            groupsMap: {
              ...groupsMap,
              [groupKey]: {
                __typename: "GroupedServingAmountConversion",
                position,
                foodOrRecipe,
                unitPrefix,
                unitSuffix,
                servingId,
                conversionAmounts: [
                  {
                    amount: nextServingAmount.amount,
                    position: nextServingAmount.position,
                    toUnit: nextServingAmount.toUnit,
                  },
                ],
              },
            },
          };
        }
      }
    },
    { servingAmounts: [], groupsMap: {} }
  );
  return [...servingAmounts, ...Object.values(groupsMap).flatMap(g => g || [])];
};

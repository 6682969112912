import { Box, Checkbox, FormControlLabel, SxProps, Typography } from "@mui/material";
import React from "react";

interface RestrictAccessToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  sx?: SxProps;
}

const RestrictAccessLabel = () => (
  <Box>
    <Typography variant="h4">Full access to only dietitians</Typography>
    <Typography variant="body2" color="textSecondary">
      Enabling this will restrict ordering and logging to only dietitians. Athletes will only be able to view the menu.
    </Typography>
  </Box>
);

export const RestrictAccessToggle = ({ checked, onChange, sx }: RestrictAccessToggleProps) => {
  return (
    <FormControlLabel
      label={<RestrictAccessLabel />}
      sx={sx}
      control={<Checkbox checked={checked} onChange={e => onChange(e.currentTarget.checked)} />}
    />
  );
};

import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography, styled } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { ActiveTransferFragment, ActiveTransfersTeamsOptionFragment, useActiveTransfersTeamsOptionsQuery } from "apps/web/src/types";
import { useUser } from "apps/web/src/utils/tokens";
import React, { useState } from "react";

const Label = styled(Typography)`
  font-weight: 600;
  display: inline;
`;

const TransferDetailsWrapper = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.palette.grey[200]};
  padding: ${theme.spacing(2)};
  margin: ${theme.spacing(1, 0)};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`
);

interface TransferModalProps {
  transfer: ActiveTransferFragment;
  onClose: () => void;
  open: boolean;
  onReclaim: () => void;
  onRecieve: (teamId: string) => void;
}

export const TransferModal = ({ transfer, onClose, open, onReclaim, onRecieve }: TransferModalProps) => {
  const user = useUser();
  const mode = user?.orgMembership?.org.id === transfer.releasedByOrg.id ? "reclaim" : "claim";
  const [selectedTeam, setSelectedTeam] = useState<ActiveTransfersTeamsOptionFragment | null>(null);
  const { formatDateWithLocale } = useDateFormatting();

  const { data } = useActiveTransfersTeamsOptionsQuery();

  const birthDateString = transfer.athlete?.birthDate;
  const birthDate = birthDateString ? formatDateWithLocale(new Date(birthDateString)) : "-";

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Transferring Athlete" onClose={onClose} />
      <DialogContent sx={{ paddingBottom: theme => theme.spacing(5) }}>
        <Box>
          <Typography variant="h4">Athlete Information</Typography>
          <TransferDetailsWrapper>
            <Typography>
              <Label>Name:</Label> {transfer.athlete?.lastName}, {transfer.athlete?.firstName}
            </Typography>
            <Typography>
              <Label>Birthdate:</Label> {birthDate}
            </Typography>
            <Typography>
              <Label>Releasing Org:</Label> {transfer.releasedByOrg?.name}
            </Typography>
            {transfer.note && (
              <Typography>
                <Label>Notes:</Label> {transfer.note}
              </Typography>
            )}
          </TransferDetailsWrapper>
        </Box>
        {mode === "reclaim" && (
          <div>
            <Typography variant="h4">Reclaim Athlete</Typography>
            <Typography variant="body2Medium">
              This athlete was added to the Transfer Portal from your org, but has not yet been claimed by another org. Click “Reclaim” if
              you would like to reclaim this athlete and remove them from the Transfer Portal.
            </Typography>
          </div>
        )}
        {mode === "claim" && (
          <div>
            <Typography variant="h4">Assign Team</Typography>
            <Typography variant="body2Medium">
              Please assign the athlete to an existing team in order to successfully transfer them to your organization.
            </Typography>
            <Box sx={{ pt: 2, maxWidth: "300px" }}>
              <Autocomplete
                filterSelectedOptions
                options={data?.teams ?? []}
                getOptionLabel={option => option.name}
                renderInput={params => <TextField {...params} label={"Team"} />}
                isOptionEqualToValue={(option: ActiveTransfersTeamsOptionFragment, value: ActiveTransfersTeamsOptionFragment) =>
                  option.id === value.id
                }
                value={selectedTeam}
                onChange={(_, value) => setSelectedTeam(value)}
              />
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {mode === "reclaim" && <Button onClick={onReclaim}>Reclaim</Button>}
        {mode === "claim" && (
          <Button disabled={!selectedTeam} onClick={() => onRecieve(selectedTeam?.id ?? "")}>
            Add to Org
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

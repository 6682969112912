import ScheduleSelect from "apps/web/src/components/Schedule/Select";
import React, { Dispatch } from "react";
import { MealPlanCalendarForm } from "./MealPlanCalendarForm";
import SchedulePreview from "./SchedulePreview";
import { EditMealPlanCalendarAction } from "./reducer";
import { EditMealPlanCalendarState } from "./types";

interface MealPlanCalendarEditFormProps {
  state: EditMealPlanCalendarState;
  dispatch: Dispatch<EditMealPlanCalendarAction>;
}

export const MealPlanCalendarEditForm = ({ state, dispatch }: MealPlanCalendarEditFormProps) => {
  const scheduleOrPlaceholder = state.schedule ? state.schedule : { id: "custom", name: "Custom Schedule", meals: [], activities: [] };

  return (
    <MealPlanCalendarForm
      state={state}
      dispatch={dispatch}
      startDateInPastBehavior="disabled"
      initialExpandedMenuSection="dateAssignment"
      renderScheduleMenuSection={({ onEditSchedule }) => (
        <>
          <ScheduleSelect
            schedules={[scheduleOrPlaceholder]}
            teamSchedules={[]}
            selectedSchedule={scheduleOrPlaceholder}
            disabled
            onChange={() => {}}
          />
          <SchedulePreview state={state} onEditSchedule={onEditSchedule} />
        </>
      )}
    />
  );
};

import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import { Dialog, DialogContent, DialogTitle, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import { dateAndTimeToIsoInTz, formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import React, { useState } from "react";
import MenuItemPrintableNutrientInfoDialog from "../../../components/MenuItem/PrintableNutrientInfo/Dialog";
import MealMenuDiningStationReadonlyGroupForm from "../DiningStation/ReadonlyGroupForm";
import RestauranMenuLinkReadonlyForm from "../RestaurantMenu/ReadonlyForm";
import { getInitialSelectedRestaurantMenuLinkId } from "../RestaurantMenu/utils";
import { StandaloneMenuDialogState } from "../types";
import MenuDialogMenuAvatars from "./MenuAvatars";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
    },
    spacer: {
      flexGrow: 1,
    },
    innerHeader: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      position: "relative",
      padding: 0,
    },
    innerContent: {
      padding: `0 15%`,
      overflowY: "auto",
      height: "100%",
    },
    avatars: {
      position: "absolute",
      top: 0,
      left: 0,
    },
    form: {
      paddingBottom: theme.spacing(2),
    },
    title: {
      padding: theme.spacing(2, 1),
      display: "flex",
      alignItems: "center",
    },
    marginLeft: {
      marginLeft: theme.spacing(),
    },
  })
);

interface MenusDialogReadonlyProps {
  open: boolean;
  onClose: () => void;
  mealMenu: StandaloneMenuDialogState;
  clientTimezone: string;
}

const MenusDialogReadonly = ({ open, onClose, mealMenu, clientTimezone }: MenusDialogReadonlyProps) => {
  const classes = useStyles();
  const timezone = mealMenu.timezone || clientTimezone;
  const { formatDateInTimezoneWithLocale } = useDateFormatting();
  const [menuItemsToPrint, setMenuItemsToPrint] = useState<string[] | null>(null);

  const hasRestaurantMenus = useHasFeature("hasRestaurantMenus");
  const showAvatars = hasRestaurantMenus && mealMenu.restaurantMenuLinks.length > 0;

  const [selectedRestaurantMenuLinkId, setSelectedRestaurantMenuLinkId] = useState<string | null>(
    getInitialSelectedRestaurantMenuLinkId(mealMenu.restaurantMenuLinks, mealMenu.mealMenuDiningStations)
  );
  const selectedRestaurantMenuLink = mealMenu.restaurantMenuLinks.find(l => l.id === selectedRestaurantMenuLinkId);
  const mealMenuStart = dateAndTimeToIsoInTz(
    {
      date: mealMenu.startDate,
      time: mealMenu.startTime,
    },
    timezone
  );

  const handlePrint = () => {
    if (selectedRestaurantMenuLink) {
      setMenuItemsToPrint(
        selectedRestaurantMenuLink.restaurantMenu.sections.flatMap(rms => rms.menuItemAppearances.map(mia => mia.menuItem.id))
      );
    } else {
      setMenuItemsToPrint(mealMenu.mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances.map(mia => mia.menuItem.id)));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogTitle>
        <div className={classes.header}>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
          <div>
            <Typography variant="h3" className={classes.marginLeft}>
              {mealMenu.name} ({capitalize(mealMenu.type)})
            </Typography>
            <div className={classes.innerHeader}>
              <Typography
                variant="h3"
                color="textSecondary"
                className={classes.marginLeft}>
                {formatDateInTimezoneWithLocale(mealMenuStart, clientTimezone, { variant: "long" })}
              </Typography>
              <div className={classes.marginLeft} />
              <div className={classes.marginLeft} />
              <Typography
                variant="h3"
                color="textSecondary"
                className={classes.marginLeft}>
                {formatTimeRangeInTimezone(mealMenuStart, mealMenu.durationInMinutes, timezone)}
              </Typography>
            </div>
          </div>
          <div className={classes.spacer} />
          <Tooltip title="Print All Menu Items">
            <IconButton onClick={handlePrint} size="large">
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {showAvatars && (
          <MenuDialogMenuAvatars
            selectedRestaurantMenuLinkId={selectedRestaurantMenuLinkId}
            onSelectRestaurantMenuLink={setSelectedRestaurantMenuLinkId}
            className={classes.avatars}
            restaurantMenuLinks={mealMenu.restaurantMenuLinks}
          />
        )}
        <div className={classes.innerContent}>
          {selectedRestaurantMenuLink ? (
            <RestauranMenuLinkReadonlyForm restaurantMenuLink={selectedRestaurantMenuLink} />
          ) : (
            <MealMenuDiningStationReadonlyGroupForm mealMenu={mealMenu} />
          )}
        </div>
      </DialogContent>
      {menuItemsToPrint !== null && (
        <MenuItemPrintableNutrientInfoDialog
          menuItemIds={menuItemsToPrint}
          open={!!menuItemsToPrint}
          onClose={() => setMenuItemsToPrint(null)}
        />
      )}
    </Dialog>
  );
};

export default MenusDialogReadonly;

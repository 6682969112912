import { Avatar, Box, SxProps, Typography, styled } from "@mui/material";
import { parseDate } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { DAY_HEADER_HEIGHT } from "./utils";

const HeaderDate = styled(Box)(({ theme: { spacing } }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderBottom: "thin solid lightgray",
  borderTop: "thin solid lightgray",
  borderRight: "thin solid lightgray",
  boxSizing: "border-box",
  padding: spacing(0.5),
  height: DAY_HEADER_HEIGHT,
  minWidth: 100,
}));

const DateAvatar = styled(Avatar, { shouldForwardProp: prop => prop !== "isToday" })<{ isToday: boolean }>(
  ({
    theme: {
      palette: { info, text, getContrastText },
    },
    isToday,
  }) => ({
    backgroundColor: isToday ? info.main : "inherit",
    color: isToday ? getContrastText(info.main) : text.secondary,
  })
);

interface CalendarDateHeaderProps {
  children: React.ReactNode;
  sx?: SxProps;
  className?: string;
}

export const CalendarDateHeader = ({ children, sx }: CalendarDateHeaderProps) => {
  return <HeaderDate sx={{ ...sx }}>{children}</HeaderDate>;
};

interface CalendarDayOfWeekLabelProps {
  dayOfWeekText: string;
  isToday: boolean;
}

export const CalendarDayOfWeekLabel = ({ dayOfWeekText, isToday }: CalendarDayOfWeekLabelProps) => {
  return (
    <Typography
      variant="body1"
      component="span"
      color="textSecondary">
      {dayOfWeekText}
    </Typography>
  );
};

interface CalendarDateLabelProps {
  date: string;
  isToday: boolean;
}

export const CalendarDateLabel = ({ date, isToday }: CalendarDateLabelProps) => {
  return (
    <DateAvatar isToday={isToday}>
      <Typography variant="body2Semibold" color="inherit">
        {parseDate(date).getDate()}
      </Typography>
    </DateAvatar>
  );
};

import React, { Dispatch } from "react";
import { CustomizationSelect } from "../CustomizationSelect";
import { DigitalDisplaysConfigActions } from "../reducer";

const ITEMS_PER_ROW = "Menu Items per Row";
export const ONE = "1";
export const TWO = "2";
export const THREE = "3";
const ITEMS_PER_ROW_OPTIONS = [ONE, TWO, THREE] as const;
export type ItemsPerRow = (typeof ITEMS_PER_ROW_OPTIONS)[number];
export const DEFAULT_ITEMS_PER_ROW = TWO;

export interface ItemsPerRowSelectProps {
  value: ItemsPerRow;
  dispatch: Dispatch<DigitalDisplaysConfigActions>;
  className?: string;
}

export const ItemsPerRowSelect = ({ value, dispatch, className }: ItemsPerRowSelectProps) => {
  const handleSelectItemsPerRow = (value: ItemsPerRow) => {
    dispatch({ type: "SET_ITEMS_PER_ROW", payload: value });
  };

  return (
    <CustomizationSelect
      label={ITEMS_PER_ROW}
      value={value}
      onChange={handleSelectItemsPerRow}
      options={[TWO, THREE]}
      className={className}
    />
  );
};

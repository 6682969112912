import { ApolloError } from "@apollo/client";
import { exchangeAmountsAreUnset } from "@notemeal/shared/ui/ExchangeAmount/utils";
import { getServingAmountsMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { Macros, getMacrosHash, maybeMacrosToMacros, scaleMacros, sumMacros } from "@notemeal/shared/utils/macro-protocol";
import { RenderCalendarEventArgs } from "apps/web/src/components/Calendar/Week/utils";
import { useCurrentMacrosContext } from "apps/web/src/contexts/CurrentMacros";
import {
  EditFullExchangeMealTemplateFragment,
  EditFullMacroMealTemplateFragment,
  EditFullMealPlanFragment,
  ExchangeAmountFragment,
  MealPlanDateAssignmentPreviewFragment,
  useCalculateExchangeTotalsMutation,
} from "apps/web/src/types";
import { useCallback, useEffect, useRef } from "react";
import { MealPlanCalendarEvent } from "./Calendar/types";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";

export type MealPlanTab = "targets" | "options" | "calendar";

type EditMealTemplateMealTemplateFragment = readonly (EditFullExchangeMealTemplateFragment | EditFullMacroMealTemplateFragment)[];

export const getMacroMealTemplatesMacros = (mealTemplates: readonly EditFullMacroMealTemplateFragment[], tab: MealPlanTab): Macros => {
  if (tab === "targets") {
    return sumMacros(mealTemplates.map(maybeMacrosToMacros));
  } else {
    return getMealTemplateMacros(mealTemplates);
  }
};

export const getExchangeMealTemplatesMacros = (
  mealTemplates: readonly EditFullExchangeMealTemplateFragment[],
  exchangeTargets: readonly ExchangeAmountFragment[],
  tab: MealPlanTab
): Macros => {
  if (tab === "targets") {
    return sumMacros(exchangeTargets.map(({ exchange, amount }) => scaleMacros(exchange, amount)));
  } else {
    return getMealTemplateMacros(mealTemplates);
  }
};

export const getMealTemplateMacros = (mealTemplates: EditMealTemplateMealTemplateFragment) => {
  return sumMacros(
    mealTemplates
      .flatMap(mt => {
        return mt.mealOptions.flatMap(mo => (mo.id === mt.selectedMealOptionId ? [mo] : []));
      })
      .map(mo => getServingAmountsMacros(mo.servingAmounts))
  );
};

export const useMealPlanSideEffects = (mealPlan: EditFullMealPlanFragment, tab: MealPlanTab): void => {
  const isExchange = mealPlan.type === "exchange";

  const { setMessage } = useSnackbar();

  const { mealTemplates, exchangeTargets } = mealPlan;
  const { setCurrentMacros } = useCurrentMacrosContext();
  const mutationOptions = {
    onError: (e: ApolloError) => setMessage("error", e.message),
  };

  const macroTemplates = mealTemplates as readonly EditFullMacroMealTemplateFragment[];
  const exchangeTemplates = mealTemplates as readonly EditFullExchangeMealTemplateFragment[];
  const currentMacros = isExchange
    ? getExchangeMealTemplatesMacros(exchangeTemplates, exchangeTargets || [], tab)
    : getMacroMealTemplatesMacros(macroTemplates, tab);
  const currentMacrosHashRef = useRef("");

  const [calculateExchangeTotals] = useCalculateExchangeTotalsMutation(mutationOptions);
  const handleDetermineTotalExchanges = useCallback(() => {
    calculateExchangeTotals({ variables: { mealPlanId: mealPlan.id } });
  }, [mealPlan.id, calculateExchangeTotals]);

  useEffect(() => {
    const newCurrentMacrosHash = getMacrosHash(currentMacros);
    if (currentMacrosHashRef.current !== newCurrentMacrosHash) {
      currentMacrosHashRef.current = newCurrentMacrosHash;
      setCurrentMacros(currentMacros);
    }

    // set exchange totals
    if (isExchange && exchangeAmountsAreUnset(exchangeTargets)) {
      handleDetermineTotalExchanges();
    }
  }, [setCurrentMacros, currentMacros, handleDetermineTotalExchanges, exchangeTargets, isExchange]);
};

const getOverriddingDateAssignment = (
  event_date: string,
  athleteDateAssignments: readonly MealPlanDateAssignmentPreviewFragment[],
  mealPlanId: string,
  mealPlanAssignedDates: readonly string[]
): MealPlanDateAssignmentPreviewFragment | undefined => {
  return athleteDateAssignments.find(
    da => da.date === event_date && mealPlanAssignedDates.includes(event_date) && da.mealPlan.id !== mealPlanId
  );
};

export const getOverridingMealPlanName = (
  date: string,
  athleteDateAssignments: readonly MealPlanDateAssignmentPreviewFragment[],
  mealPlanId: string,
  mealPlanAssignedDates: readonly string[]
): string | undefined => {
  const test = getOverriddingDateAssignment(date, athleteDateAssignments, mealPlanId, mealPlanAssignedDates)?.mealPlan.name;
  return test;
};

export const getEventArgsForEvent = (
  args: RenderCalendarEventArgs,
  event: MealPlanCalendarEvent,
  athleteDateAssignments: readonly MealPlanDateAssignmentPreviewFragment[],
  mealPlanId: string,
  mealPlanAssignedDates: readonly string[]
): RenderCalendarEventArgs => {
  const event_start = serializeDate(event.start);
  const isOverridden = getOverriddingDateAssignment(event_start, athleteDateAssignments, mealPlanId, mealPlanAssignedDates);

  if (isOverridden && isOverridden.mealPlan.id !== event.mealPlanId && event.type !== "Teamworks") {
    return {
      ...args,
      eventStyle: {
        textDecoration: "line-through",
      },
    };
  }

  return args;
};

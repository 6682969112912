import { Box, Divider, ListItemButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";

const StyledMenuItem = styled(ListItemButton)(({ theme: { spacing } }) => ({
  gap: spacing(1),
}));

export const PopoverListItem = ({
  icon,
  copy,
  onClick,
  disabled,
}: {
  icon: ReactNode;
  copy: string;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <StyledMenuItem
      onClick={onClick}
      aria-label={copy}
      disabled={disabled}>
      <Box sx={{ color: "greyscale.500", display: "flex", alignItems: "center" }}>{icon}</Box>{" "}
      <Typography variant="body2">{copy}</Typography>
    </StyledMenuItem>
  );
};

export const StyledDivider = styled(Divider)(({ theme: { spacing } }) => ({
  margin: spacing(1, 2),
}));

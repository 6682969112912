import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, Dialog, DialogActions, DialogContent, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import AutocompleteForMultiselect from "../../../../../../components/universal/AutocompleteForMultiselect";
import ChipListPreview from "../../../../../../components/universal/ChipList/Preview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "center",
    },
    editButton: {
      marginLeft: theme.spacing(),
    },
    input: {
      width: "100%",
    },
  })
);

interface HasId {
  id: string | number;
}

interface ProfileSyncRuleSelectionCriteriaProps<T extends HasId> {
  title: string;
  value: readonly T[];
  options: readonly T[];
  getItemLabel: (t: T) => string;
  onChange: (value: readonly T[]) => void;
  noItemsChipPlaceholder?: string;
  className?: string;
  groupBy?: (option: T) => string;
  filterOptions?: (options: readonly T[], selectedOptions: readonly T[]) => T[];
  disableCloseOnSelect?: boolean;
  noOptionsText?: string;
  warningMessage?: string;
}

const ProfileSyncRuleSelectionCriteria = <T extends HasId>({
  title,
  value,
  options,
  onChange,
  getItemLabel,
  noItemsChipPlaceholder,
  className,
  groupBy,
  filterOptions,
  disableCloseOnSelect,
  noOptionsText,
  warningMessage,
}: ProfileSyncRuleSelectionCriteriaProps<T>) => {
  const classes = useStyles();

  const [modalValue, setModalValue] = useState<readonly T[] | null>(null);
  const open = !!modalValue;
  const onClose = () => setModalValue(null);

  const chipItems =
    value.length === 0 && noItemsChipPlaceholder
      ? [
          {
            id: "",
            label: noItemsChipPlaceholder,
          },
        ]
      : value.map(v => ({
          id: v.id,
          label: getItemLabel(v),
        }));

  return (
    <div className={className}>
      <div className={classes.flex}>
        <Typography>{title}</Typography>
        <IconButton
          size="small"
          onClick={() => setModalValue(value)}
          className={classes.editButton}>
          <EditIcon fontSize="small" />
        </IconButton>
        {warningMessage && (
          <Tooltip title={warningMessage}>
            <WarningIcon color="error" />
          </Tooltip>
        )}
      </div>
      <div>
        <ChipListPreview items={chipItems} />
      </div>
      {modalValue && (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="xs"
          fullWidth>
          <DialogTitle title={title} onClose={onClose} />
          <DialogContent>
            <AutocompleteForMultiselect<T>
              options={filterOptions ? filterOptions(options, modalValue) : options}
              value={modalValue}
              isOptionSelected={t => modalValue.map(t => t.id).includes(t.id)}
              getOptionLabel={getItemLabel}
              onChange={setModalValue}
              inputProps={{
                className: classes.input,
                autoFocus: true,
                label: `Select ${title}`,
              }}
              groupBy={groupBy}
              disableCloseOnSelect={disableCloseOnSelect}
              noOptionsText={noOptionsText}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onChange(modalValue);
                onClose();
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ProfileSyncRuleSelectionCriteria;

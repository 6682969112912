import { Box, Button } from "@mui/material";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { LabeledSelectWithPlaceholder } from "apps/web/src/components/universal/LabeledSelectWithPlaceholder";
import { PlannedMenuWithWeeksFragment } from "apps/web/src/types";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { MenuBuilderWeek } from "../../../MenuBuilder/Builder/utils";
import AdvancedSelectionDialog from "../../../Tags/Dialogs/Mutation/AdvancedSelection/AdvancedSelectionDialog";
import ShareWithInfo from "../../../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import { buildInitialCreateAdvancedSelectionStateFromTeams } from "../../../Tags/reducers/advancedSelectionReducers";
import TeamMultiSelect from "../../SelectComponents/TeamMultiSelect";
import { useImportPlannedMenu } from "./ImportPlannedMenuContext";
import PlannedMenuWeekMultiSelect from "./WeekSelect";

interface ImportPlannedMenuFormHeaderProps {
  plannedMenu: PlannedMenuWithWeeksFragment;
}

export const ImportPlannedMenuFormHeader = ({ plannedMenu }: ImportPlannedMenuFormHeaderProps) => {
  const [advancedSelectionOpen, setAdvancedSelectionOpen] = useState(false);
  const [weeksForUnsavedChangesWarning, setWeeksForUnsavedChangesWarning] = useState<MenuBuilderWeek[] | null>(null);
  const { allTeams, allWeeks, getSelectedWeeksData, form, selectedWeeks, shareState, setShareState } = useImportPlannedMenu();
  const { control, watch, getFieldState, setValue } = form;

  const selectedWeekIds = watch("weeks") && watch("weeks").map(week => week.id);

  const handleChangeWeeks = (newWeeks: MenuBuilderWeek[], promptUser: boolean) => {
    const mealsDirty = selectedWeekIds && selectedWeekIds.some((w, i) => getFieldState(`weeks.${i}.meals`).isDirty);
    //if there are unsaved changes in the meals, show user those changes will be discarded when weeks change
    if (promptUser && mealsDirty && selectedWeekIds) {
      setWeeksForUnsavedChangesWarning(newWeeks);
    } else {
      //reset meals for new weeks. the meals will be fetched and populated onClose of the week select
      const weeksInput = newWeeks.map(week => {
        const { startDate, endDate, ...rest } = week;
        return { ...rest, meals: [] };
      });
      setValue("weeks", weeksInput);
      setWeeksForUnsavedChangesWarning(null);
    }
  };

  return (
    <>
      <SectionHeader
        header="Menu Details"
        subcopy="Select the weeks that you would like to publish. Menu can be shared with teams at a later time."
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Controller
          name={"plannedMenu"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <LabeledSelectWithPlaceholder
              optionToName={option => option.name}
              placeholder={"Menu"}
              selectedOption={plannedMenu}
              options={[plannedMenu]}
              disabled
              {...field}
              sx={{ width: "300px" }}
            />
          )}
        />
        <Controller
          name={"weeks"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PlannedMenuWeekMultiSelect
              {...field}
              onChange={value => handleChangeWeeks(value, true)}
              allWeeks={allWeeks}
              selectedWeeks={selectedWeekIds ? selectedWeeks : []}
              onClose={() => {
                //fetches meal data for selected weeks once user is done selecting
                // closes the select component. this avoids fetching for each week selected
                if (selectedWeekIds && selectedWeeks) {
                  getSelectedWeeksData(selectedWeeks.map(week => week.id));
                }
              }}
            />
          )}
        />
        {shareState.__typename === "Teams" && (
          <TeamMultiSelect
            allTeams={allTeams}
            selectedTeams={shareState.teams}
            onChange={teams =>
              setShareState({
                type: "MenuChangeTeamsAction",
                payload: {
                  teams,
                },
              })
            }
            sx={{ width: "240px" }}
            props={{ label: "Teams (optional)" }}
          />
        )}
        {shareState.__typename === "Tags" && (
          <ShareWithInfo
            state={shareState.advancedSelectionState}
            onClear={() => setShareState({ type: "MenuClearAdvancedSelectionAction" })}
            maxChips={4}
            containerSx={{ width: "300px", lineHeight: "14px" }}
            showAdvancedSelectionClear={true}
            showWrapper
          />
        )}
        <Box sx={{ flex: 1, mt: 3 }}>
          <Button
            sx={{ height: "36px", width: "200px" }}
            variant="outlined"
            onClick={() => setAdvancedSelectionOpen(true)}>
            {shareState.__typename === "Teams" ? "Advanced Selection" : "Edit Advanced Selection"}
          </Button>
        </Box>

        {advancedSelectionOpen && (
          <AdvancedSelectionDialog
            title="Share Menu"
            open={advancedSelectionOpen}
            onClose={() => setAdvancedSelectionOpen(false)}
            onSave={advancedSelectionState => {
              setShareState({ type: "MenuChangeAdvancedSelectionAction", payload: { advancedSelectionState } });
              setAdvancedSelectionOpen(false);
            }}
            initialState={
              shareState.__typename === "Teams"
                ? buildInitialCreateAdvancedSelectionStateFromTeams(shareState.teams)
                : shareState.advancedSelectionState
            }
          />
        )}
        {weeksForUnsavedChangesWarning !== null && (
          <ConfirmationDialog
            open={weeksForUnsavedChangesWarning !== null}
            onCancel={() => setWeeksForUnsavedChangesWarning(null)}
            onConfirm={() => handleChangeWeeks(weeksForUnsavedChangesWarning, false)}
            title="Unsaved Changes"
            message="Are you sure you want to change the number of weeks selected? Any changes that were made to those weeks will be discarded."
          />
        )}
      </Box>
    </>
  );
};

import { Dialog } from "@mui/material";
import React from "react";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { OrgFoodRequestForm } from "./OrgFoodRequestForm";

interface FoodModalProps {
  open: boolean;
  onClose: () => void;
}

export const OrgFoodRequestModal = ({ open, onClose }: FoodModalProps) => {
  const { setMessage } = useSnackbar();

  const handleRequestSuccess = (msg: string) => {
    setMessage("success", msg);
    onClose();
  };

  const handleRequestFailure = (msg: string) => {
    setMessage("error", msg);
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      open={open}>
      <OrgFoodRequestForm
        onClose={onClose}
        onRequestSuccess={handleRequestSuccess}
        onRequestFailure={handleRequestFailure} />
    </Dialog>
  );
};

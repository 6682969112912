import { Box, TextField } from "@mui/material";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { BaseFoodFormType } from "../../FoodFormSchema/FoodFormSchema";
import { roundFoodMacro } from "../utils";

interface FoodMacrosProps {
  isDisabled: boolean;
  form: UseFormReturn<BaseFoodFormType>;
}

export const FoodMacros = ({ isDisabled, form }: FoodMacrosProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: 2 }}>
      <Controller
        name={"choPer100g"}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <FoodMacro
            {...field}
            macro="cho"
            error={Boolean(errors.choPer100g)}
            helperText={errors.choPer100g?.message}
            value={field.value}
            isDisabled={isDisabled}
          />
        )}
      />
      <Controller
        name={"proPer100g"}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <FoodMacro
            {...field}
            macro="pro"
            error={Boolean(errors.proPer100g)}
            helperText={errors.proPer100g?.message}
            value={field.value}
            isDisabled={isDisabled}
          />
        )}
      />
      <Controller
        name={"fatPer100g"}
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <FoodMacro
            {...field}
            onChange={onChange}
            macro="fat"
            error={Boolean(errors.fatPer100g)}
            helperText={errors.fatPer100g?.message}
            value={field.value}
            isDisabled={isDisabled}
          />
        )}
      />
    </Box>
  );
};

interface FoodMacroProps {
  value: number | null;
  macro: "cho" | "pro" | "fat";
  onChange: (value: number) => void;
  isDisabled: boolean;
  error: boolean;
  helperText?: string;
}

const FoodMacro = ({ value, macro, onChange, isDisabled, error, helperText }: FoodMacroProps) => {
  const label = macro === "cho" ? "CHO/100g" : macro === "pro" ? "PRO/100g" : "FAT/100g";

  return (
    <TextField
      disabled={isDisabled}
      onChange={e => onChange(inputToNumber(e.target.value) ?? 0)}
      error={error}
      helperText={helperText}
      label={label}
      type="number"
      value={value ? roundFoodMacro(value) : null}
    />
  );
};

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { memo } from "react";
import { FixedSizeList as List, ListChildComponentProps, areEqual } from "react-window";

interface ListSliderProps<T> {
  initialIndex: number;
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listSliderContainer: {
      width: "100%",
      height: "100%",
    },
    fabFlexStart: {
      position: "absolute",
      bottom: theme.spacing(),
      left: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    listSlider: {
      display: "flex",
      flexFlow: "row",
      width: "100%",
      height: "100%",
    },
    oneThird: {
      minWidth: "33.33%",
    },
  })
);

const ListSlider = <T extends object>({ initialIndex, items, renderItem }: ListSliderProps<T>) => {
  const classes = useStyles();

  const ListChild = memo(({ data, index, style }: ListChildComponentProps) => {
    // Data passed to List as "itemData" is available as props.data
    const items = data;
    const item = items[index];
    return <div style={{ ...style, overflowY: "auto" }}>{renderItem(item, index)}</div>;
  }, areEqual);

  return (
    <div className={classes.listSliderContainer}>
      <div className={classes.listSlider}>
        <List
          style={{ overflowY: "hidden" }}
          itemCount={items.length}
          itemData={items}
          itemSize={document.documentElement.clientWidth / 3 - 16}
          layout="horizontal"
          width={document.documentElement.clientWidth}
          height={"100%"}
        >
          {ListChild}
        </List>
      </div>
    </div>
  );
};

export default ListSlider;

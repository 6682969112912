import {
  CreateGoalConfigurationWithIndividualDatesInput,
  DayOfWeek,
  EditGoalConfigurationWithDaysOfWeekInput,
  EditGoalConfigurationWithIndividualDatesInput,
  GoalConfigurationForTableFragment,
  GoalTargetForTableFragment,
} from "apps/web/src/types";
import { z } from "zod";
import { DayOfWeekSchema, IntegerSchema } from "@notemeal/validators";
import { ORDERED_DAYS_OF_WEEK, parseDate, serializeDate, today } from "@notemeal/shared/ui/utils/dateTimes";
import { CreateGoalConfigurationWithDaysOfWeekInput } from "libs/shared/ui/src/lib/types";
import { startOfDay } from "date-fns";

const GoalCategorySchema = z.object(
  {
    id: z.string().min(1),
    name: z.string().min(1),
    defaultEmoji: z.string().min(1),
  },
  { required_error: "Required" }
);
const CompletionCriteriaSchema = z.object({
  markAsComplete: z.boolean({ required_error: "Please select one of the choices below" }),
  target: z.object({ value: IntegerSchema, unit: z.string({ required_error: "Required" }).min(1, { message: "Required" }) }).optional(),
});
const GoalReminderSchema = z.array(
  z.object({ time: z.string().min(1, { message: "Required" }), message: z.string().min(1, { message: "Required" }) })
);

export const GoalWeeklyAssignmentSchema = z.object({
  type: z.literal("weekly", { required_error: "Required" }),
  daysOfWeek: z
    .array(DayOfWeekSchema, { required_error: "Please select at least one day of week" })
    .min(1, { message: "Please select at least one day of the week" }),
  startDate: z.date({ required_error: "Required" }),
  endDate: z.date({ required_error: "Required" }).refine(endDate => endDate >= startOfDay(today), "End date cannot be in past"),
  originalStartDate: z.date().optional(),
});

export const GoalIndividualDatesAssignmentSchema = z.object({
  type: z.literal("individualDates"),
  dates: z
    .array(z.string(), { required_error: "Please select at least one date from the calendar" })
    .min(1, { message: "Please select at least one date from the calendar" }),
});

export const GoalConfigurationAssignmentSchema = z
  .discriminatedUnion("type", [GoalWeeklyAssignmentSchema, GoalIndividualDatesAssignmentSchema])
  .superRefine((data, ctx) => {
    if (data.type === "weekly") {
      const { startDate, endDate, originalStartDate } = data;

      if (startDate > endDate) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["endDate"], message: "End date must be after start date" });
      }
      if (!originalStartDate && startDate < startOfDay(today)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["startDate"], message: "Start date cannot be in the past" });
      }
      if (originalStartDate && serializeDate(startDate) !== serializeDate(originalStartDate) && startDate < startOfDay(today)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["startDate"], message: "Start date must be unchanged if it's in the past" });
      }
    }
  });

export const GoalConfigurationFormSchema = z.object({
  task: z.string({ required_error: "Task is required" }).min(1, { message: "Required" }).max(40, { message: "Max 40 characters" }),
  reminders: GoalReminderSchema,
  timezone: z.string().min(1, { message: "Required" }),
  type: GoalCategorySchema,
  completionCriteria: CompletionCriteriaSchema,
  assignments: GoalConfigurationAssignmentSchema,
});

export type GoalConfigurationAssignmentType = z.infer<typeof GoalConfigurationAssignmentSchema>;
export type GoalConfigurationCompletionCriteriaType = z.infer<typeof CompletionCriteriaSchema>;
export type GoalConfigurationFormType = z.infer<typeof GoalConfigurationFormSchema>;
export type GoalConfigurationWeeklyAssignmentType = z.infer<typeof GoalWeeklyAssignmentSchema>;
export type GoalConfigurationIndividualAssignmentType = z.infer<typeof GoalIndividualDatesAssignmentSchema>;

export type GoalConfigurationDeepPartial = Partial<Omit<GoalConfigurationFormType, "assignments" | "completionCriteria">> & {
  assignments: Partial<GoalConfigurationAssignmentType>;
  completionCriteria: Partial<GoalConfigurationCompletionCriteriaType>;
};

export const goalConfigurationFormDefaultValues = (goalConfiguration?: GoalConfigurationForTableFragment): GoalConfigurationDeepPartial => {
  if (goalConfiguration) {
    const { category, target, reminders, startDate, endDate, dayOfWeekAssignments, individualDates } = goalConfiguration;
    return {
      task: goalConfiguration.name,
      type: {
        id: category.id,
        name: category.name,
        defaultEmoji: category.defaultEmoji,
      },
      completionCriteria: {
        markAsComplete: !target,
        target: target ? { value: target.value, unit: target.unit } : undefined,
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      reminders: reminders.map(r => ({ time: r.time, message: r.message })),
      assignments:
        dayOfWeekAssignments.length && startDate && endDate
          ? {
              type: "weekly",
              startDate: parseDate(startDate),
              endDate: parseDate(endDate),
              daysOfWeek: dayOfWeekAssignments.map(d => DayOfWeekSchema.parse(d)),
              originalStartDate: parseDate(startDate),
            }
          : { type: "individualDates", dates: [...individualDates] },
    };
  }
  const today = new Date();
  return {
    task: "",
    type: undefined,
    completionCriteria: { markAsComplete: undefined },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    assignments: { type: "weekly", daysOfWeek: [...ORDERED_DAYS_OF_WEEK], startDate: today, endDate: undefined },
    reminders: [],
  };
};

export type CreateGoalConfigurationInputType = CreateGoalConfigurationWithDaysOfWeekInput | CreateGoalConfigurationWithIndividualDatesInput;

export const goalConfigurationFormToCreateInput = (
  athleteId: string,
  goal: GoalConfigurationFormType
): CreateGoalConfigurationInputType => {
  if (goal.assignments.type === "weekly") {
    return {
      input: {
        athleteId,
        categoryId: goal.type.id,
        emoji: goal.type.defaultEmoji,
        ...goalConfigurationFormToInputCommon(goal),
        target: getTargetInput(goal, null).target,
      },
      ...getDayOfWeekInput(goal.assignments),
    };
  } else {
    return {
      input: {
        athleteId,
        categoryId: goal.type.id,
        emoji: goal.type.defaultEmoji,
        ...goalConfigurationFormToInputCommon(goal),
        target: getTargetInput(goal, null).target,
      },
      individualDates: goal.assignments.dates,
    };
  }
};

export type EditGoalConfigurationInputType = EditGoalConfigurationWithDaysOfWeekInput | EditGoalConfigurationWithIndividualDatesInput;

export const goalConfigurationFormToEditInput = (
  previousVersion: GoalConfigurationForTableFragment,
  goal: GoalConfigurationFormType,
  loadedIndividualDates: string[]
): EditGoalConfigurationInputType => {
  const newName = goal.task !== previousVersion.name ? goal.task : null;
  if (goal.assignments.type === "weekly") {
    return {
      input: {
        id: previousVersion.id,
        ...goalConfigurationFormToInputCommon(goal),
        ...getTargetInput(goal, previousVersion.target),
        name: newName,
      },

      ...getDayOfWeekInput(goal.assignments),
    };
  } else {
    const individualDates = goal.assignments.type === "individualDates" ? goal.assignments.dates : [];
    const removeIndividualDates = loadedIndividualDates.filter(date => !individualDates.includes(date));
    const addIndividualDates = individualDates.filter(date => !loadedIndividualDates.includes(date));
    return {
      input: {
        id: previousVersion.id,
        ...goalConfigurationFormToInputCommon(goal),
        ...getTargetInput(goal, previousVersion.target),
        name: newName,
      },
      addIndividualDates: addIndividualDates || [],
      removeIndividualDates: removeIndividualDates || [],
    };
  }
};

const goalConfigurationFormToInputCommon = (goal: GoalConfigurationFormType) => {
  return {
    name: goal.task,
    timezone: goal.timezone,
    reminders: goal.reminders,
  };
};

const getTargetInput = (
  goal: GoalConfigurationFormType,
  previousTarget: GoalTargetForTableFragment | null
): { removeTarget: boolean; target: { value: number; unit: string } | null } => {
  if (previousTarget) {
    if (goal.completionCriteria.markAsComplete) {
      return { target: null, removeTarget: true };
    } else if (
      previousTarget.unit === goal.completionCriteria.target?.unit &&
      previousTarget.value === goal.completionCriteria.target?.value
    ) {
      //target unchanged
      return { target: null, removeTarget: false };
    } else if (goal.completionCriteria.target) {
      //target has been updated
      return { target: { value: goal.completionCriteria.target.value, unit: goal.completionCriteria.target.unit }, removeTarget: false };
    } else {
      return { target: null, removeTarget: false };
    }
  } else if (goal.completionCriteria.markAsComplete) {
    return { target: null, removeTarget: false };
  } else if (goal.completionCriteria.target?.value && goal.completionCriteria.target.unit) {
    return { target: { value: goal.completionCriteria.target.value, unit: goal.completionCriteria.target.unit }, removeTarget: false };
  } else {
    return { target: null, removeTarget: false };
  }
};

const getDayOfWeekInput = (
  assignments: GoalConfigurationWeeklyAssignmentType
): { startDate: string; endDate: string; dayOfWeekAssignments: DayOfWeek[] } => {
  return {
    dayOfWeekAssignments: assignments.daysOfWeek,
    startDate: serializeDate(assignments.startDate),
    endDate: serializeDate(assignments.endDate),
  };
};

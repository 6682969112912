import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";
import React from "react";

interface AllDayCellProps {
  height: number;
  onClick: () => void;
  disableDrag: boolean;
  isDisabled?: boolean;
}

const CalendarAllDayCell = ({ height, onClick, disableDrag, isDisabled }: AllDayCellProps) => {
  const { setNodeRef } = useDroppable({
    id: "all_day",
    disabled: disableDrag,
  });

  return (
    <Box
      sx={({ spacing }) => ({
        borderBottom: "thin solid lightgray",
        borderRight: "thin solid lightgray",
        boxSizing: "border-box",
        mr: spacing(-2),
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        backgroundColor: isDisabled ? "grey.100" : "inherit",
      })}
      style={{ minHeight: height }}
      onClick={onClick}
    >
      <Box ref={setNodeRef} />
    </Box>
  );
};

export default CalendarAllDayCell;

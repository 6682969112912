import { Box, Typography } from "@mui/material";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import { slotProps } from "apps/web/src/pages/Theme/ThemeUtils";
import React from "react";

export const ThemeViewerDateTime = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">Date Time Pickers</Typography>
      <Typography variant="h3">DesktopTimePicker</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <DesktopTimePicker
          slotProps={slotProps} // In other locations this hasn't been needed if the parent flex has alignItems: "flex-end"
          label="Start Time"
          value={new Date().setHours(6, 0, 0, 0)}
        />
      </Box>
      <Typography variant="h3">DesktopDatePicker</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <DesktopDatePicker
          slotProps={slotProps} // In other locations this hasn't been needed if the parent flex has alignItems: "flex-end"
          label="Start Date"
          value={new Date()}
        />
      </Box>
    </Box>
  );
};

export const muiAutocomplete = {
  styleOverrides: {
    root: {
      "& .MuiOutlinedInput-root": {
        paddingTop: 6,
        paddingBottom: 6,
        "& .MuiAutocomplete-input": {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
        paddingTop: 2,
        paddingBottom: 2,
        "& .MuiAutocomplete-input": {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
};

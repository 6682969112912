import CloseIcon from "@mui/icons-material/Close";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ReplayIcon from "@mui/icons-material/Replay";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Button, Card, Dialog, DialogActions, DialogContent, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import { sortByKey } from "@notemeal/utils/sort";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import ActivityTypeSelect from "apps/web/src/components/Activity/TypeSelect";
import MealTypeSelect from "apps/web/src/components/Meal/TypeSelect";
import React, { Dispatch, useReducer, useState } from "react";
import { EditSchedule } from "../reducer";
import { MealPlanCalendarState } from "../types";
import { resolveEditScheduleEventsToSchedule } from "../utils";
import { EditScheduleAction, editScheduleReducer, getEditScheduleCanSaveTooltips, initScheduleState } from "./reducer";
import { EditScheduleEvent } from "./types";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";

const DISABLED_MEAL_SUBCOPY =
  " is currently disabled due to the individual overrides that have been made on the calendar view. Click on the reset icon to re-enable the meal and make changes as needed.";

interface MealPlanCalendarFormEditScheduleDialogProps {
  open: boolean;
  onClose: () => void;
  onDone: (args: EditSchedule["payload"]) => void;
  calendarState: MealPlanCalendarState;
  scheduleName: string;
}

const MealPlanCalendarFormEditScheduleDialog = ({
  open,
  onClose,
  onDone,
  calendarState,
  scheduleName,
}: MealPlanCalendarFormEditScheduleDialogProps) => {
  const [state, dispatch] = useReducer(editScheduleReducer, initScheduleState(calendarState));

  const tooltips = getEditScheduleCanSaveTooltips(state);
  const handleDone = () => {
    if (tooltips.length > 0) {
      return;
    }
    onDone(resolveEditScheduleEventsToSchedule(state.events));
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth>
      <DialogTitle title="Edit Schedule" onClose={onClose} />
      <DialogContent sx={{ flex: 1, overflowY: "hidden", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3">{scheduleName}</Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              startIcon={<RestaurantIcon />}
              onClick={() => dispatch({ type: "AddEvent", payload: { type: "meal" } })}
            >
              Add Meal
            </Button>
            <Button
              variant="outlined"
              startIcon={<DirectionsRunIcon />}
              onClick={() => dispatch({ type: "AddEvent", payload: { type: "activity" } })}
            >
              Add Activity
            </Button>
          </Box>
        </Box>
        <Box sx={{ overflowY: "auto", display: "flex", flexDirection: "column", gap: 2 }}>
          {sortByKey(state.events, "start").map(event =>
            event.modified ? (
              <TWTooltip title="Disabled Meal" subcopy={event.name.concat(DISABLED_MEAL_SUBCOPY)}>
                <Box>
                  <ScheduleItem
                    key={event.id}
                    event={event}
                    dispatch={dispatch} />
                </Box>
              </TWTooltip>
            ) : (
              <ScheduleItem
                key={event.id}
                event={event}
                dispatch={dispatch} />
            )
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before advancing" items={tooltips}>
          <Button onClick={handleDone}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default MealPlanCalendarFormEditScheduleDialog;

interface ScheduleItemProps {
  event: EditScheduleEvent;
  dispatch: Dispatch<EditScheduleAction>;
}

const ScheduleItem = ({ event, dispatch }: ScheduleItemProps) => {
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  return (
    <Card
      sx={{
        minHeight: "80px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
        gap: 2,
      }}
    >
      <Box
        sx={{
          mr: "2px",
          height: "80px",
          width: "6px",
          backgroundColor: event.type === "meal" ? "greyscale.400" : "accents.orangeDark.400",
        }}
      />
      {event.type === "meal" ? (
        <RestaurantIcon fontSize="small" sx={{ alignSelf: "center", color: "greyscale.400" }} />
      ) : (
        <DirectionsRunIcon fontSize="small" sx={{ alignSelf: "center", color: "accents.orangeDark.400" }} />
      )}
      <TextField
        disabled={event.modified}
        autoFocus={!event.name}
        label="Name"
        value={event.name}
        onChange={e =>
          dispatch({
            type: "EditEventName",
            payload: {
              id: event.id,
              name: e.target.value,
            },
          })
        }
      />
      <DesktopTimePicker
        sx={{ mt: 3 }}
        disabled={event.modified}
        label="Start"
        value={event.startRaw}
        onChange={time =>
          dispatch({
            type: "EditEventStart",
            payload: {
              id: event.id,
              time,
            },
          })
        }
      />
      <DesktopTimePicker
        sx={{ mt: 3 }}
        disabled={event.modified}
        label="End"
        value={event.endRaw}
        onChange={time =>
          dispatch({
            type: "EditEventEnd",
            payload: {
              id: event.id,
              time,
            },
          })
        }
      />
      {event.type === "meal" ? (
        <MealTypeSelect
          sx={{ width: "190px" }}
          value={event.mealType}
          disabled={event.modified}
          onChange={mealType =>
            dispatch({
              type: "EditMealType",
              payload: {
                id: event.id,
                mealType,
              },
            })
          }
        />
      ) : (
        <ActivityTypeSelect
          sx={{ width: "190px" }}
          value={event.activityType}
          disabled={event.modified}
          onChange={activityType =>
            dispatch({
              type: "EditActivityType",
              payload: {
                id: event.id,
                activityType,
              },
            })
          }
        />
      )}
      <IconButton
        disabled={event.modified}
        onClick={() =>
          dispatch({
            type: "RemoveEvent",
            payload: {
              id: event.id,
            },
          })
        }
      >
        <CloseIcon />
      </IconButton>
      {event.modified ? (
        <Tooltip title={"Resetting will get rid of the previous changes made on the calendar view"}>
          <IconButton onClick={() => setResetDialogOpen(true)}>
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Box sx={{ width: 48 }} />
      )}
      {resetDialogOpen && (
        <ConfirmationDialog
          open={resetDialogOpen}
          title={`Reset ${capitalize(event.type)}`}
          message={`Resetting this ${event.type} will get rid of the previous changes made on the calendar view. Are you sure you would like to proceed?`}
          onCancel={() => setResetDialogOpen(false)}
          onConfirm={() => {
            dispatch({
              type: "ResetEvent",
              payload: {
                id: event.id,
              },
            });
            setResetDialogOpen(false);
          }}
          confirmLabel="Reset"
          variant="containedDestructive"
        />
      )}
    </Card>
  );
};

import { FullSportFragment } from "../../../../types";

export interface GenderObject {
  id: string;
  name: "Men's" | "Women's";
}

export interface TeamFormState {
  sport: FullSportFragment | null;
  gender: GenderObject | null;
  name: string | null;
}

interface ChangeGender {
  type: "CHANGE_GENDER";
  payload: GenderObject;
}

interface ChangeSport {
  type: "CHANGE_SPORT";
  payload: FullSportFragment;
}

interface ChangeName {
  type: "CHANGE_NAME";
  payload: string;
}

export type TeamFormAction = ChangeGender | ChangeSport | ChangeName;

export const teamFormReducer = (state: TeamFormState, action: TeamFormAction): TeamFormState => {
  switch (action.type) {
    case "CHANGE_GENDER":
      return {
        ...state,
        gender: action.payload,
        name: state.name ? state.name : `${action.payload.name + (state.sport ? state.sport.name : "")}`,
      };
    case "CHANGE_SPORT":
      return {
        ...state,
        sport: action.payload,
        name:
          state.gender && state.name && state.name !== state.gender.name
            ? state.name
            : `${state.gender ? state.gender.name : ""} ${action.payload.name}`,
      };
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
  }
};

export const teamFormToSaveTooltips = (state: TeamFormState): string[] => {
  let tooltips = [];

  if (state.name === null) {
    tooltips.push("'Name' is required!");
  }
  return tooltips;
};

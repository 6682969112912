import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, styled, TableCell, TableRow, Typography } from "@mui/material";
import { EditTeam, NONE, TeamEditModal } from "apps/web/src/views/Teams/EditModal/TeamEditModal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import NotificationCountLabel from "../../../../components/Notification/CountLabel";
import TablePage from "../../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../../components/universal/TablePage/DefaultHeader";
import { TeamsTableTeamFragment, useTeamPageEditTeamMutation, useTeamsPageDeleteTeamMutation, useTeamsPageQuery } from "../../../../types";
import { getRowsForTablePage, useOffsetPagination } from "../../../../utils/pagination";
import { CreateTeamModal } from "../../../../views/Teams/CreateModal/CreateTeamModal";
import DeleteDialog from "../../../../views/Teams/DeleteModal";
import { filterAndSortTeamsForTable, getMobileUserCountForTeam, isSubscribed } from "../../../../views/Teams/utils";
import { getNavOrgTeam } from "../Team/TeamPaths";

const NameAndNotification = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  alignItems: "center",
}));

export const TeamsPage = () => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useTeamsPageQuery({
    fetchPolicy: "network-only",
  });
  const paginationHooks = useOffsetPagination();
  const { limit, page } = paginationHooks;
  const [search, setSearch] = useState<string>("");

  const [teamsPageDeleteTeam] = useTeamsPageDeleteTeamMutation({
    onCompleted: () => {
      setDeleteTeam(false);
      refetch();
    },
  });

  const [teamPageEditTeam, { loading: teamPageEditTeamLoading }] = useTeamPageEditTeamMutation({});

  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const [currentTeam, setCurrentTeam] = useState<TeamsTableTeamFragment | null>(null);

  const [createTeam, setCreateTeam] = useState(false);
  const [editTeam, setEditTeam] = useState(false);
  const [deleteTeam, setDeleteTeam] = useState(false);

  const handleSaveEditTeam = async ({ teamId, teamName, gender, sportId }: EditTeam) => {
    await teamPageEditTeam({
      variables: {
        input: {
          id: teamId,
          team: {
            name: teamName,
            gender: gender !== NONE ? gender : null,
            sportId: sportId !== NONE ? sportId : null,
          },
        },
      },
    });
    setEditTeam(false);
  };

  const getEditTeam = ({ id: teamId, name: teamName, gender, sport }: TeamsTableTeamFragment) => ({
    teamId,
    teamName,
    gender: gender || "",
    sportId: sport?.id || "",
  });

  const handleDeleteTeam = (teamId: string) => {
    teamsPageDeleteTeam({
      variables: { input: { teamId } },
    });
  };

  const clearMoreButton = () => {
    setMoreAnchorEl(null);
  };

  const sortedAndFilteredTeams = data ? filterAndSortTeamsForTable(data.teams, search) : [];

  const teamsForTablePage = getRowsForTablePage({
    rows: sortedAndFilteredTeams,
    limit,
    page,
  });

  return (
    <>
      <TablePage
        header={
          <TablePageDefaultHeader
            title="Team"
            queryText={search}
            onChangeQueryText={setSearch}
            onCreate={() => setCreateTeam(true)} />
        }
        tableHeaderRow={
          <TableRow>
            <TableCell padding="checkbox">Actions</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Subscribed</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Sport</TableCell>
            <TableCell># Athletes</TableCell>
            <TableCell># Mobile Accounts</TableCell>
          </TableRow>
        }
        paginationHooks={paginationHooks}
        tableBodyRows={teamsForTablePage.map(row => (
          <TableRow
            sx={{ cursor: "pointer" }}
            key={row.id}
            hover
            onClick={() => navigate(getNavOrgTeam(row.id))}>
            <TableCell>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  setCurrentTeam(row);
                  setMoreAnchorEl(e.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
            <TableCell scope="row">
              <NameAndNotification>
                <Typography variant="body2">{row.name}</Typography>
                <NotificationCountLabel max={99} notifications={row.recentNotifications} />
              </NameAndNotification>
            </TableCell>
            <TableCell>{isSubscribed(row) ? "✓" : ""}</TableCell>
            <TableCell>{row.gender}</TableCell>
            <TableCell>{row.sport?.name}</TableCell>
            <TableCell>{row.athletes.length}</TableCell>
            <TableCell>{getMobileUserCountForTeam(row)}</TableCell>
          </TableRow>
        ))}
        loading={loading}
        total={sortedAndFilteredTeams.length || 0}
      />
      <Menu
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={clearMoreButton}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            setEditTeam(true);
          }}
        >
          Edit team
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            setDeleteTeam(true);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {createTeam && <CreateTeamModal open={createTeam} onClose={() => setCreateTeam(false)} />}
      {currentTeam && editTeam && (
        <TeamEditModal
          open={editTeam}
          onClose={() => setEditTeam(false)}
          editTeam={getEditTeam(currentTeam)}
          onSave={handleSaveEditTeam}
          saving={teamPageEditTeamLoading}
        />
      )}
      {currentTeam && deleteTeam && (
        <DeleteDialog
          team={currentTeam}
          open={deleteTeam}
          onCancel={() => setDeleteTeam(false)}
          onDelete={() => handleDeleteTeam(currentTeam.id)}
        />
      )}
    </>
  );
};

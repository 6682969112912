import { Box, Typography } from "@mui/material";
import React from "react";
import { Avatar, ChannelHeaderProps, useChannelPreviewInfo, useChannelStateContext } from "stream-chat-react";

export const ChannelHeader = (props: ChannelHeaderProps) => {
  const { title } = props;
  const { channel } = useChannelStateContext();
  const { displayImage, displayTitle } = useChannelPreviewInfo({
    channel,
  });

  return (
    <div className="str-chat__channel-header">
      <Box
        sx={({ palette: { common, divider }, spacing }) => ({
          background: common.white,
          padding: spacing(2),
          borderBottom: `1px solid ${divider}`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        })}
      >
        <Avatar
          size={40}
          image={displayImage}
          name={displayTitle} />
        <Typography variant="h4">{title || displayTitle}</Typography>
      </Box>
    </div>
  );
};

import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { sortFnByKey } from "@notemeal/utils/sort";
import React from "react";
import { EventFragment, Maybe } from "../../../types";

interface ComplianceEventListProps {
  eventsAndMealPlans: (EventFragment & { mealPlanName: Maybe<string> })[];
}

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    recentViewsList: {
      padding: spacing(2),
    },
    viewedAtFont: {
      color: grey[600],
      paddingLeft: spacing(1),
    },
  })
);

const ComplianceEventList = ({ eventsAndMealPlans }: ComplianceEventListProps) => {
  const classes = useStyles();
  const { formatEventDatetimeWithLocale } = useDateFormatting();

  const eventsWithComponents = eventsAndMealPlans.map(({ type, datetime, mealPlanName }, idx) => {
    // TODO support "name" (of compliance event) here instead of hard-coding "viewed" below
    const dateString = `viewed  ${formatEventDatetimeWithLocale(datetime, { formatLong: true })}`;

    const component = (
      <div key={idx}>
        {mealPlanName && (
          <Typography variant="body1" component="p">
            {mealPlanName}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          classes={{ root: classes.viewedAtFont }}
          component="p">
          {dateString}
        </Typography>
      </div>
    );
    return { datetime, component };
  });

  return (
    <div className={classes.recentViewsList}>
      {
        eventsWithComponents.length === 0 ? (
          <Typography variant="body2Medium" component="p">
            No Meal Plan Views!
          </Typography>
        ) : (
          eventsWithComponents
            .sort(sortFnByKey("datetime"))
            .reverse()
            .map(({ component }) => component)
        )
        // ^ This is an in-place 'reverse()', which is OK b/c `sort()` returns a new array anyway
      }
    </div>
  );
};

export default ComplianceEventList;

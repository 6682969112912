import { useApolloClient } from "@apollo/client";
import { FullServingFragment, ConvertedServingDocument, ConvertedServingQuery, ConvertedServingQueryVariables } from "../../../../types";

interface useConvertToUnitsArgs {
  servingId: string;
  onSelectServing: (serving: FullServingFragment) => void;
}

interface useConvertToUnitsPayload {
  onConvertToUnits: (toUnitId: string) => void;
}

export const useConvertToUnits = ({ servingId, onSelectServing }: useConvertToUnitsArgs): useConvertToUnitsPayload => {
  const apolloClient = useApolloClient();

  return {
    onConvertToUnits: async (toUnitId: string) => {
      const { data } = await apolloClient.query<ConvertedServingQuery, ConvertedServingQueryVariables>({
        query: ConvertedServingDocument,
        variables: {
          input: { servingId, toUnitId },
        },
      });
      if (data) {
        onSelectServing(data.convertedServing);
      }
    },
  };
};

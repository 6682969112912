interface ToStringable {
  toString: () => string;
}
export const getMostCommonValueInList = <T extends ToStringable | null>(list: Array<T>): T | null => {
  let frequencyMap: Record<string, number> = {};
  let maxOccrance: T | null = null;
  let maxOccranceFrequency: number = -1;

  list.forEach(item => {
    const key = item !== null ? `__${item.toString()}` : "null";
    const currValue = (frequencyMap[key] || 0) + 1;
    if (currValue > maxOccranceFrequency) {
      maxOccranceFrequency = currValue;
      maxOccrance = item;
    }
    frequencyMap = {
      ...frequencyMap,
      [key]: currValue,
    };
  });

  return maxOccrance;
};

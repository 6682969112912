import { Checkbox, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import { sortByKey } from "@notemeal/utils/sort";
import classnames from "classnames";
import React, { useState } from "react";
import MenuPlateCard from "../../../../components/MenuSelection/MenuPlateCard";
import { RestaurantMenuFormFragment, RestaurantMenuLinkPlateItemFormFragment } from "../../../../types";
import { RestaurantMenuLinkPlateState } from "../types";
import EditDialog from "./Dialog/Edit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    textfield: {
      padding: theme.spacing(),
    },
    checkBoxField: {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.grey[100],
      margin: theme.spacing(2, 0, 2),
      padding: theme.spacing(0.5, 0, 0.5),
      borderRadius: theme.spacing(),
    },
    plateOrderSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(1.5, 0, 1.5),
      borderTop: "solid thin lightgray",
    },
  })
);

interface RestaurantMenuLinkPlateCardProps {
  className?: string;
  allowPlateOrders: boolean;
  restaurantMenuLinkPlate: RestaurantMenuLinkPlateState;
  restaurantMenu: RestaurantMenuFormFragment;
  onChangeBulkOrderAmount: (bulkOrderAmountInput: string) => void;
  onChangeItems: (items: readonly RestaurantMenuLinkPlateItemFormFragment[], editedItemIds: readonly string[]) => void;
  onSetDefault: (checkboxValue: boolean) => void;
  onRemove: () => void;
}

const RestaurantMenuLinkPlateCard = ({
  allowPlateOrders,
  restaurantMenuLinkPlate,
  restaurantMenu,
  onChangeBulkOrderAmount,
  onChangeItems,
  onSetDefault,
  onRemove,
  className,
}: RestaurantMenuLinkPlateCardProps) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [allowBulkOrder, setAllowBulkOrder] = useState(restaurantMenuLinkPlate.bulkOrderAmountInput.length > 0 || false);

  const bulkOrderAmountError =
    restaurantMenuLinkPlate.bulkOrderAmountInput.length > 0 && inputToNumber(restaurantMenuLinkPlate.bulkOrderAmountInput) === null;

  const plateTitle = `Plate ${restaurantMenuLinkPlate.position} ${restaurantMenuLinkPlate.isDefault ? "(Default)" : ""}`;
  const handleBulkOrderChange = () => {
    setAllowBulkOrder(!allowBulkOrder);
    onChangeBulkOrderAmount("");
  };

  return (
    <div className={classnames(classes.root, className)}>
      <MenuPlateCard
        menuSelectionItems={sortByKey(restaurantMenuLinkPlate.items, "position")}
        plateTitle={plateTitle}
        targetMacros={null}
        onRemove={onRemove}
        onClick={() => setDialogOpen(true)}
      >
        <div className={classes.checkBoxField} onClick={() => onSetDefault(!restaurantMenuLinkPlate.isDefault)}>
          <Checkbox size="small" checked={restaurantMenuLinkPlate.isDefault || false} />
          <Typography variant="body2">Set as default plate</Typography>
        </div>
        <div className={classes.checkBoxField} onClick={handleBulkOrderChange}>
          <Checkbox
            size="small"
            checked={allowBulkOrder || false}
            onChange={e => setAllowBulkOrder(e.target.checked)} />
          <Typography variant="body2">Enable bulk order {allowBulkOrder && ":"}</Typography>
          {allowBulkOrder && (
            <TextField
              onClick={e => e.stopPropagation()}
              onMouseDown={e => e.stopPropagation()}
              value={restaurantMenuLinkPlate.bulkOrderAmountInput}
              onChange={e => onChangeBulkOrderAmount(e.target.value)}
              error={bulkOrderAmountError}
              className={classes.textfield}
              placeholder={"#"}
            />
          )}
        </div>

        {allowPlateOrders && (
          <Typography className={classes.plateOrderSection} variant="body1">
            {`Athlete order count: ${restaurantMenuLinkPlate.orders.length}
          `}
          </Typography>
        )}
      </MenuPlateCard>
      {dialogOpen && (
        <EditDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          restaurantMenuLinkPlate={restaurantMenuLinkPlate}
          onEditPlate={onChangeItems}
          restaurantMenu={restaurantMenu}
        />
      )}
    </div>
  );
};

export default RestaurantMenuLinkPlateCard;

import { ProfileSyncRuleWithEntities } from "@notemeal/profile-sync";
import { DraggableSpring, useDraggable } from "@notemeal/shared/ui/hooks/useDraggable";
import React, { ReactNode } from "react";
import { ReactEventHandlers } from "react-use-gesture/dist/types";

interface DraggableProfileSyncRuleListProps {
  renderRule: (rule: ProfileSyncRuleWithEntities, bindProps: ReactEventHandlers, spring: DraggableSpring) => ReactNode;
  ruleHeight: number;
  onChangeOrder: (ids: string[]) => void;
  rules: ProfileSyncRuleWithEntities[];
  className?: string;
}

const DraggableProfileSyncRuleList = ({ rules, renderRule, ruleHeight, onChangeOrder, className }: DraggableProfileSyncRuleListProps) => {
  const draggableObjects = rules.map((rule, index) => ({
    id: rule.id,
    index,
    height: ruleHeight,
  }));
  const { springs, bind } = useDraggable(draggableObjects, onChangeOrder);

  const renderDraggableRule = (rule: ProfileSyncRuleWithEntities) => {
    const springObject = draggableObjects.find(({ id }) => id === rule.id);
    if (!springObject) {
      return;
    }

    const spring = springs[springObject.index];
    const bindProps = bind(springObject.index);

    return renderRule(rule, bindProps, spring);
  };

  const key = rules.map(({ id }) => id).join("-");

  return (
    <div className={className} key={key}>
      {rules.map(rule => renderDraggableRule(rule))}
    </div>
  );
};

export default DraggableProfileSyncRuleList;

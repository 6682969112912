interface MenuItemAppearancePartial {
  menuItem: MenuItemPartial;
}

interface MenuItemPartial {
  id: string;
}

interface MealMenuDiningStationPartial {
  menuItemAppearances: readonly MenuItemAppearancePartial[];
  name: string;
  mealMenuId: string;
}

interface OrderWithItemsAndMealMenu {
  itemsByDiningStation: readonly {
    id: string;
    menuItem: {
      id: string;
    };
  }[];
  mealMenu: {
    id: string;
  };
}

export const menuOrderItemIdsToDiningStationName = <I extends OrderWithItemsAndMealMenu>(
  mealMenuDiningStations: readonly MealMenuDiningStationPartial[],
  orders: readonly I[]
): Record<string, string> => {
  const orderItemWithDiningStation = orders.flatMap(order => {
    return order.itemsByDiningStation.flatMap(orderItem => {
      const diningStation = mealMenuDiningStations.find(ds => {
        return ds.mealMenuId === order.mealMenu.id && ds.menuItemAppearances.map(mia => mia.menuItem.id).includes(orderItem.menuItem.id);
      });

      if (!diningStation) {
        return [];
      }
      return {
        orderItem,
        diningStation,
      };
    });
  });

  return orderItemWithDiningStation.reduce((map, { orderItem, diningStation }) => {
    return {
      ...map,
      [orderItem.id]: diningStation.name,
    };
  }, {});
};

import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenuRounded";
import React from "react";
import { NavigateOptions, To } from "react-router-dom-v5-compat";

// full page path
export const NAV_ORG_FOOD_MANAGEMENT = "/org/food-management";
export const NAV_ORG_FOOD_MANAGEMENT_RECIPE = "/org/food-management/recipes";
export const NAV_ORG_RECIPE = "/org/recipe";
export const NAV_ORG_RECIPE_CREATE = "/org/recipe/create";

// local child paths
export const NAV_FOOD_MANAGEMENT_RECIPES = "recipes";
export const NAV_FOOD_MANAGEMENT_FOODS = "foods";
export const NAV_FOOD_MANAGEMENT_FOOD_GROUPS = "food-groups";

// full child paths
export const NAV_ORG_FOOD_MANAGEMENT_RECIPES = `${NAV_ORG_FOOD_MANAGEMENT}/${NAV_FOOD_MANAGEMENT_RECIPES}`;

export const orgFoodManagementLink = {
  to: NAV_ORG_FOOD_MANAGEMENT,
  name: "Food Management",
  icon: <RestaurantMenuIcon />,
};

export const orgFoodManagementRecipeLink = {
  to: NAV_ORG_FOOD_MANAGEMENT_RECIPE,
  name: "Recipe",
};

export const getOrgRecipeNavigation = (
  state?: {
    readonly?: boolean;
    duplicatedRecipeId?: string;
    isDuplicate?: boolean;
  },
  id?: string
): { to: To; options?: NavigateOptions } => {
  if (!!id) {
    return {
      to: `${NAV_ORG_RECIPE}/${id}`,
      options: { state },
    };
  }

  return {
    to: NAV_ORG_RECIPE_CREATE,
    options: { state },
  };
};

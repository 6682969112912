import { Box, Button, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React, { useState } from "react";
import { TeamMealMenuPreviewFragment } from "../../../../types";
import AdvancedSelectionDialog from "../../../Tags/Dialogs/Mutation/AdvancedSelection/AdvancedSelectionDialog";
import ShareWithInfo from "../../../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import { AdvancedSelectionState, buildInitialCreateAdvancedSelectionState } from "../../../Tags/reducers/advancedSelectionReducers";
import MenusTeamMultiSelect from "../../SelectComponents/TeamMultiSelect";
import { getInitialBulkEditSharingState } from "../../utils";
import { BaseBulkEditProps, BaseContentProps } from "../types";
import BaseContent from "./BaseContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    teamsSelect: { minWidth: 400 },
    container: { display: "flex", justifyContent: "space-between", alignItems: "baseline" },
    tagContainer: { alignItems: "center" },
    button: { marginLeft: theme.spacing(2), minWidth: theme.spacing(30) },
    editButton: { height: 46 },
  })
);

interface SharingContentProps extends BaseContentProps {
  onBulkEdit: BaseBulkEditProps["onBulkEdit"];
  allTeams: BaseBulkEditProps["allTeams"];
}

const SharingContent = ({ isOpen, onBack, onCancel, onBulkEdit, mealMenuInstances, saving, allTeams }: SharingContentProps) => {
  const classes = useStyles();
  const mealMenuIds = mealMenuInstances.map(mealMenuInstance => mealMenuInstance.id);
  const [initialSelectedTeams, initialAdvancedSelectionState] = getInitialBulkEditSharingState(mealMenuInstances);
  const [currentSelectedTeams, setCurrentSelectedTeams] = useState<readonly TeamMealMenuPreviewFragment[] | null>(initialSelectedTeams);
  const [advancedSelectionState, setAdvancedSelectionState] = useState<AdvancedSelectionState | null>(initialAdvancedSelectionState);
  const [advancedSelectionOpen, setAdvancedSelectionOpen] = useState(false);

  const handleTeamsChange = (teams: readonly TeamMealMenuPreviewFragment[]) => {
    setCurrentSelectedTeams(teams);
    setAdvancedSelectionState(null);
  };

  const handleAdvancedSelectionChange = (state: AdvancedSelectionState) => {
    setCurrentSelectedTeams(null);
    setAdvancedSelectionState(state);
    setAdvancedSelectionOpen(false);
  };

  return (
    <BaseContent
      isOpen={isOpen}
      onCancel={onCancel}
      saving={saving}
      onSubmit={async () => {
        await onBulkEdit({
          mealMenuIds,
          actionType: "sharing",
          sharingState: {
            teamIds: currentSelectedTeams ? currentSelectedTeams.map(team => team.id) : null,
            advancedSelectionState,
          },
          notificationSentBeforeOrderDueInMinutes: null,
          timingState: null,
        });
        onBack();
      }}
      title={`Reassign Sharing for ${mealMenuInstances.length} Meal Menu(s)`}
      renderContent={() => (
        <>
          <Box sx={{ p: 2, mt: 2, backgroundColor: "grey.200", borderRadius: "5px" }}>
            <Typography variant="body2">Your sharing selection will override any sharing currently in place</Typography>
          </Box>
          {currentSelectedTeams && (
            <div className={classes.container}>
              <MenusTeamMultiSelect
                className={classes.teamsSelect}
                onChange={teams => handleTeamsChange(teams)}
                allTeams={allTeams}
                selectedTeams={currentSelectedTeams ?? []}
              />
              <Button
                variant="outlined"
                onClick={() => setAdvancedSelectionOpen(true)}
                className={classes.button}
                size="large">
                Switch to Advanced Selection
              </Button>
            </div>
          )}
          {advancedSelectionState && (
            <div className={classNames(classes.container, classes.tagContainer)}>
              <ShareWithInfo
                state={advancedSelectionState}
                maxChips={6}
                showAdvancedSelectionClear={false}
                showWrapper />
              <Button
                variant="outlined"
                onClick={() => setAdvancedSelectionOpen(true)}
                className={classNames(classes.button, classes.editButton)}
                size="large"
              >
                Edit Advanced Selection
              </Button>
            </div>
          )}
          {advancedSelectionOpen && (
            <AdvancedSelectionDialog
              title="Bulk Share Menu"
              open={advancedSelectionOpen}
              onClose={() => setAdvancedSelectionOpen(false)}
              onSave={state => {
                handleAdvancedSelectionChange(state);
              }}
              initialState={advancedSelectionState ?? buildInitialCreateAdvancedSelectionState()}
            />
          )}
        </>
      )}
      submitDisabled={mealMenuInstances.length === 0 || saving}
    />
  );
};

export default SharingContent;

import React from "react";
import { DietFragment, useDietCursorConnectionQuery } from "../../../../types";
import { BasicOption } from "../../../universal/BaseAsyncAutocomplete";
import BaseFilter from "./BaseFilter/BaseFilterWithInfiniteScroll";
import { baseEdgesAreEqual, baseGetKey, baseGetLabel, getBaseTransformAndFilter } from "./BaseFilter/utils";

interface DietFilterProps {
  selectedDiets: DietFragment[] | undefined;
  handleChange: (newValue: DietFragment[] | null) => void;
  disabled?: boolean;
}

const DietFilter = ({ selectedDiets, handleChange, disabled = false }: DietFilterProps) => {
  const transformAndFilterDietOptions = getBaseTransformAndFilter(selectedDiets ?? []);

  return (
    <BaseFilter
      getLabel={baseGetLabel}
      getKey={baseGetKey}
      filterLabel={"Diet"}
      selectedFilters={selectedDiets ?? []}
      handleChange={filters => handleChange(filters.length === 0 ? null : filters)}
      disabled={disabled}
      queryKey="dietCursorConnection"
      useCursorConnectionQuery={useDietCursorConnectionQuery}
      edgesAreEqual={baseEdgesAreEqual}
      renderOption={({ name }) => <BasicOption element={name} />}
      transformAndFilterOptions={transformAndFilterDietOptions}
    />
  );
};

export default DietFilter;

import { MenuItem, TextField } from "@mui/material";
import React from "react";

interface MenuPrepTimeSelectProps {
  value: number;
  onChange: (value: number) => void;
}

const PREP_TIME_IN_MINUTES_OPTIONS = [5, 10, 15, 20, 25, 30, 45, 60, 90];

const MenuPrepTimeSelect = ({ value, onChange }: MenuPrepTimeSelectProps) => {
  return (
    <>
      <TextField
        select
        label="Prep Time"
        value={value}
        onChange={e => onChange(parseFloat(e.target.value))}
        sx={{ minWidth: 100, marginLeft: 2 }}
      >
        {PREP_TIME_IN_MINUTES_OPTIONS.map(pt => (
          <MenuItem key={pt} value={pt}>
            {pt}m
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default MenuPrepTimeSelect;

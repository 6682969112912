import { Worksheet } from "exceljs";
import { AthleteAnthropometryRow, applyFillToCell, getOutlierFlag, Aggregations } from "../utils";
import { Rank } from "../types";
import { Theme } from "@mui/material";

type AthleteAnthropometryRowWithMetadata = AthleteAnthropometryRow & {
  isMostRecent: boolean;
  rank: Rank;
  makeGrey: boolean;
  firstName: string;
  lastName: string;
  position: string;
};

interface AddRowToWorksheetProps {
  theme: Theme;
  worksheet: Worksheet;
  row: AthleteAnthropometryRowWithMetadata;
  filteredAggregations: Aggregations;
  highlight: boolean;
}

export const addRowToWorksheet = ({ theme, worksheet, row, filteredAggregations, highlight }: AddRowToWorksheetProps): void => {
  const wsRow = worksheet.addRow({
    ...row,
    height: row.height || "",
    weight: row.weight || "",
    weightChange: row.weightChange || "",
    percentBodyFat: row.percentBodyFat || "",
    percentBodyFatChange: row.percentBodyFatChange || "",
    percentBodyFatArrow: getArrowForTrend(row.percentBodyFatChange),
    bodyFatMass: row.bodyFatMass || "",
    bodyFatMassChange: row.bodyFatMassChange || "",
    bodyFatMassArrow: getArrowForTrend(row.bodyFatMassChange),
    leanBodyMass: row.leanBodyMass || "",
    leanBodyMassChange: row.leanBodyMassChange || "",
    leanBodyMassArrow: getArrowForTrend(row.leanBodyMassChange),
    visceralFatArea: row.visceralFatArea || "",
    visceralFatAreaChange: row.visceralFatAreaChange || "",
    visceralFatAreaArrow: getArrowForTrend(row.visceralFatAreaChange),
    trunkFat: row.trunkFat || "",
    trunkFatChange: row.trunkFatChange || "",
    trunkFatArrow: getArrowForTrend(row.trunkFatChange),
    skeletalMuscleMass: row.skeletalMuscleMass || "",
    skeletalMuscleMassChange: row.skeletalMuscleMassChange || "",
    skeletalMuscleMassArrow: getArrowForTrend(row.skeletalMuscleMassChange),
    dryLeanMass: row.dryLeanMass || "",
    dryLeanMassChange: row.dryLeanMassChange || "",
    dryLeanMassArrow: getArrowForTrend(row.dryLeanMassChange),
    boneMineralDensityZScore: row.boneMineralDensityZScore || "",
    boneMineralContent: row.boneMineralContent || "",
    BMI: row.BMI || "",
  });

  if (row.makeGrey) {
    wsRow.eachCell(cell => applyFillToCell(theme, cell, "lightGrey"));
  }

  if (row.isMostRecent) {
    // Rank cell coloring
    const rankCell = wsRow.getCell("rank");
    switch (row.rank) {
      case "Excellent":
        applyFillToCell(theme, rankCell, "green");
        break;
      case "Good":
        applyFillToCell(theme, rankCell, "blue");
        break;
      case "Avg":
        applyFillToCell(theme, rankCell, "yellow");
        break;
      case "Below Avg":
        applyFillToCell(theme, rankCell, "orange");
        break;
      case "Poor":
        applyFillToCell(theme, rankCell, "red");
        break;
    }
    // Outlier cell coloring
    const outlierCheckCols: (keyof AthleteAnthropometryRow)[] = ["bodyFatMass", "leanBodyMass", "percentBodyFat"];
    if (highlight) {
      outlierCheckCols.forEach(col => {
        const outlierFlag = getOutlierFlag(row, col, filteredAggregations);
        if (outlierFlag >= 1) {
          applyFillToCell(theme, wsRow.getCell(col), "green");
        } else if (outlierFlag <= -1) {
          applyFillToCell(theme, wsRow.getCell(col), "yellow");
        }
      });
    }
  }
};

const getArrowForTrend = (changeValue: number | null) => {
  return changeValue ? (changeValue > 0 ? "⬆" : changeValue < 0 ? "⬇" : "⮕") : "";
};

import { pickIfTruthy } from "../pick";
import { isSameDay } from "date-fns";

export type LinkType = "one" | "many" | "none" | "NA";

export interface ILinkResult<F, T extends F, L extends F> {
  mergedRow: L & T;
  originalRow: T;
  linkedRows: L[];
  type: LinkType;
  linkOnFields: (keyof F)[];
  linkFields: (keyof L)[];
}

export interface ILinkProps<F, T extends F, L extends F> {
  row: T;
  linkableRows: L[];
  linkOnFields: (keyof F)[];
  linkFields: (keyof L)[];
}

const simplify = (val1: string) => {
  const s = val1.toLowerCase();
  const punctuationless = s.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
  return punctuationless.replace(/\s{2,}/g, " ");
};

export const isEqual = <T>(val1: T, val2: T): boolean => {
  if (!val1 || !val2) {
    return false;
  }
  // If these are null/undefined/'', we don't want to match to many rows by accident

  // TODO: We only compare 'dates' b/c we don't have any case of caring about time right now
  // All of the imports we handle do not capture a timestamp.
  if (typeof (val1 as unknown as Date).getMonth === "function") {
    // return String(val1) === String(val2)
    return isSameDay(val1 as unknown as Date, val2 as unknown as Date);
  } else if (typeof (val1 as unknown as string) === "string") {
    return simplify(val1 as unknown as string).trim() === simplify(val2 as unknown as string).trim();
  } else {
    return val1 === val2;
  }
};

export const link = <F, T extends F, L extends F>({
  row,
  linkableRows,
  linkOnFields,
  linkFields,
}: ILinkProps<F, T, L>): ILinkResult<F, T, L> => {
  const linkedRows = linkableRows.filter(r => linkOnFields.every(field => isEqual(row[field], r[field] as unknown as T[keyof T])));
  const matchType =
    linkedRows.length === 1
      ? "one"
      : linkOnFields.length === 0 && linkFields.length === 0
      ? "NA"
      : linkedRows.length === 0
      ? "none"
      : "many";
  return {
    linkedRows,
    originalRow: row,
    mergedRow: {
      ...row,
      ...pickIfTruthy(linkedRows[0], linkFields),
    },
    type: matchType,
    linkOnFields,
    linkFields,
  };
};

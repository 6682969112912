import AddIcon from "@mui/icons-material/Add";
import { Button, Chip, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { PositionFragment } from "../../../../../types";
import CreatePositionModal from "./CreateModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(3, 0),
      flexBasis: "100%",
    },
  })
);

interface PositionChipListProps {
  positions: readonly PositionFragment[];
  sportId: string;
}

const PositionChipList = ({ positions, sportId }: PositionChipListProps) => {
  const classes = useStyles();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.title}>
        Positions
      </Typography>
      {positions.map(pos => (
        <Chip
          color="info"
          key={pos.id}
          size="small"
          label={pos.name} />
      ))}
      <br />
      <br />
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setCreateModalOpen(true)}>
        Add Position
      </Button>
      <CreatePositionModal
        sportId={sportId}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)} />
    </React.Fragment>
  );
};

export default PositionChipList;

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useCompassRecipesQuery } from "apps/web/src/types";
import React, { useState } from "react";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { Buttons } from "./Buttons";
import { ListDisplay } from "./ListDisplay";
import { SearchProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      flexGrow: 1,
      paddingRight: theme.spacing(3),
    },
    searchBar: {
      flexGrow: 1,
    },
    multiQuerySearchBarContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "100%",
    },
  })
);

export const NameSearch = ({ isImporting, setSelectedRecipe, selectedRecipe, setSelectedLabel, selectedLabel }: SearchProps) => {
  const classes = useStyles();
  const [nameQuery, setNameQuery] = useState("");
  const [search, setSearch] = useState("");
  const { setMessage } = useSnackbar();

  const { data: nameRecipes, loading: loadingNameRecipes } = useCompassRecipesQuery({
    variables: { query: nameQuery },
    skip: nameQuery.length < 3,
    onError: e => {
      setMessage("error", "There was an issue with your search. Please try again.");
    },
  });
  const loading = loadingNameRecipes || isImporting;
  const invalidInput = nameQuery.length > 0 && nameQuery.length < 3;

  return (
    <>
      <div className={classes.multiQuerySearchBarContainer}>
        <div className={classes.searchBar}>
          <TextField
            className={classes.search}
            label="Name"
            placeholder="Search by name"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={e => setSearch(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                setNameQuery(search);
              }
            }}
            error={invalidInput}
            helperText={invalidInput ? "Enter at least 3 characters" : ""}
          />
        </div>
        <Buttons
          setSelectedLabel={setSelectedLabel}
          selectedLabel={selectedLabel}
          setSelectedRecipe={setSelectedRecipe} />
      </div>
      <ListDisplay
        recipes={nameRecipes ? nameRecipes.compass_recipes : null}
        selectedRecipe={selectedRecipe}
        setSelectedRecipe={setSelectedRecipe}
        loading={loading}
        invalidSearch={null}
      />
    </>
  );
};

import { MenuItem, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectRoot: {
      padding: theme.spacing(0.5, 0),
    },
  })
);

export interface CustomizationSelectProps<T extends string> {
  label: string;
  value: T;
  onChange: (value: T) => void;
  options: readonly T[];
  className?: string;
}

export const CustomizationSelect = <T extends string>({ label, value, onChange, options, className }: CustomizationSelectProps<T>) => {
  const classes = useStyles();

  return (
    <TextField
      select
      label={label}
      margin="dense"
      inputProps={{
        "aria-label": label,
      }}
      value={value}
      SelectProps={{
        classes: {
          select: classes.selectRoot,
        },
      }}
      className={className}
      placeholder={label}
    >
      {options.map(option => {
        return (
          <MenuItem
            key={option}
            value={option}
            onClick={() => onChange(option)}>
            {option}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

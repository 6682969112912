import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import React from "react";

import { MealOptionMode, MealOptionModeComponentProps } from "../mode";
import PickListModeContent from "./Content";

const MealOptionPickListModeComponent = ({
  renderTotals,
  mealOption,
  contentClassName,
  totalsClassName,
  onEditServingAmounts,
  recipeDialogOpen,
  onCloseRecipeDialog,
  onCreateRecipe,
  exchangeSet,
  targetExchangeAmounts,
}: MealOptionModeComponentProps) => {
  if (!exchangeSet || !targetExchangeAmounts) {
    return null;
  }

  return (
    <PickListModeContent
      renderTotals={renderTotals}
      totalsClassName={totalsClassName}
      mealOptionServingAmounts={mealOption.servingAmounts}
      targetExchangeAmounts={targetExchangeAmounts}
      exchangeSet={exchangeSet}
      contentClassName={contentClassName}
      onEditServingAmounts={onEditServingAmounts}
      recipeDialogOpen={recipeDialogOpen}
      onCloseRecipeDialog={onCloseRecipeDialog}
      onCreateRecipe={onCreateRecipe}
    />
  );
};

export const PickListMode: MealOptionMode = {
  name: "Pick List",
  icon: <FormatListNumberedIcon />,
  component: MealOptionPickListModeComponent,
};

import { TextField, Typography } from "@mui/material";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { OrgLinkerOrgFragment, TeamworksOrgFragment, useLinkTeamworksOrgMutation } from "../../../../../types";
import ConfirmDialog from "../shared/ConfirmDialog";

interface ConfirmLinkDialogProps {
  org: OrgLinkerOrgFragment;
  teamworksOrg: TeamworksOrgFragment;
  confirmComplete: (org: OrgLinkerOrgFragment, teamworksOrg: TeamworksOrgFragment) => void;
  confirmCancel: () => void;
  isOpen: boolean;
}

const ConfirmLinkDialog = ({ org, teamworksOrg, confirmComplete, confirmCancel, isOpen }: ConfirmLinkDialogProps) => {
  const { setMessage } = useSnackbar();
  const [name, setName] = useState(teamworksOrg.name);

  const [linkOrg, { loading }] = useLinkTeamworksOrgMutation({
    onError: () => {
      setMessage("error", "Error occurred while linking org");
    },
    // Need to just evict cache here because the query is run with a search term.
    update: (cache, { data }) => {
      if (data) {
        cache.evict({
          fieldName: "teamworksOrgCursorConnection",
          broadcast: true,
        });
      }
    },
    onCompleted: data => {
      const newOrg = data.linkTeamworksOrg.org;
      setMessage("success", `Successfully Linkd Org with name: ${newOrg.name}`);
      confirmComplete(newOrg, teamworksOrg);
    },
  });

  const onConfirm = () =>
    linkOrg({
      variables: {
        input: { id: org.id, teamworksId: teamworksOrg.id, name },
      },
    });

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Link Org From Teamworks Org"
      content={
        <>
          <Typography>Notemeal Name: {org.name}</Typography>
          <Typography>Teamworks Name: {teamworksOrg.name}</Typography>
          <TextField
            label="Org Name"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth />
        </>
      }
      loading={loading}
      onCancel={confirmCancel}
      onConfirm={onConfirm}
      confirmDisabled={name === ""}
    />
  );
};

export default ConfirmLinkDialog;

import { MenuSection } from "../MenuSelection/utils";
import { MenuSelectionItemOptionWithIdsFragment, MenuItemWithIdsFragment, MenuItemFormFragment } from "../types";

export type MenuItemWithSelectionOptions<I extends MenuItemWithIdsFragment> = Omit<I, "choices"> & {
  choices: ReadonlyArray<
    Omit<I["choices"][0], "options"> & {
      options: ReadonlyArray<
        I["choices"][0]["options"][0] & {
          menuSelectionItemOption: { amount: number; percentConsumed: number | null } | null;
        }
      >;
    }
  >;
};

export const addSelectionOptionsToMenuItem = <I extends MenuItemWithIdsFragment>(
  item: I,
  options: readonly MenuSelectionItemOptionWithIdsFragment[]
): MenuItemWithSelectionOptions<I> => {
  const menuSelectionItemOptionsByMicoId = options.reduce(
    (
      mapping: {
        [id: string]: MenuSelectionItemOptionWithIdsFragment | undefined;
      },
      o
    ) => {
      return {
        ...mapping,
        [o.menuItemChoiceOption.id]: o,
      };
    },
    {}
  );

  return {
    ...item,
    choices: item.choices.map(({ options, ...c }: I["choices"][0]) => ({
      ...c,
      options: options.map((o: I["choices"][0]["options"][0]) => {
        const selectionItemOption = menuSelectionItemOptionsByMicoId[o.id];
        return {
          ...o,
          menuSelectionItemOption: selectionItemOption
            ? { amount: selectionItemOption.amount, percentConsumed: selectionItemOption.percentConsumed }
            : null,
        };
      }),
    })),
  };
};

// TODO: These names
export type MenuItemForModal = MenuItemWithSelectionOptions<MenuItemFormFragment>;
export type MenuItemChoiceForModal = MenuItemForModal["choices"][0];
export type MenuItemChoiceOptionForModal = MenuItemChoiceForModal["options"][0];

export const getMenuItemOptionErrors = (menuItem: MenuItemForModal): string[] => {
  return menuItem.choices.reduce((errors: string[], c) => {
    const optionsForChoiceCount = c.options.filter(o => o.menuSelectionItemOption !== null).length;
    if (c.required && optionsForChoiceCount === 0) {
      return [...errors, `'${c.name}' choice is required`];
    }
    if (c.maxOptionsCount && optionsForChoiceCount > c.maxOptionsCount) {
      return [...errors, `Only ${c.maxOptionsCount} choice(s) allowed for '${c.name}'`];
    }
    return errors;
  }, []);
};

interface getMenuItemMaxAmountArgs {
  currentAmountForMenuItem: number;
  maxAmountForMenuItem: number | null;
  currentAmountForMenuSection: number;
  maxAmountForMenuSection: number | null;
  menuSelectionItemAmount?: number;
}

/**
 * Returns the maxAmount of a MenuItem that may be set when adding or editing an selection.
 * Considers both MenuItemAppearance.maxAmount and MealMenuDiningStation.maxAmount constraints, using most strict existing value
 *
 * Note: menuSelectionItemAmount is added to the maxAmount total because an existing MenuSelectionItem's amount should already
 * be counted in each of the currentAmount inputs
 */
export const getMenuItemMaxAmount = ({
  currentAmountForMenuSection,
  maxAmountForMenuSection,
  currentAmountForMenuItem,
  maxAmountForMenuItem,
  menuSelectionItemAmount = 0,
}: getMenuItemMaxAmountArgs): number | null => {
  let remaining: number | null;
  if (maxAmountForMenuItem !== null && maxAmountForMenuSection !== null) {
    const remainingForMenuItem = maxAmountForMenuItem - currentAmountForMenuItem;
    const remainingForMenuSection = maxAmountForMenuSection - currentAmountForMenuSection;
    remaining = Math.min(remainingForMenuItem, remainingForMenuSection);
  } else if (maxAmountForMenuItem !== null) {
    remaining = maxAmountForMenuItem - currentAmountForMenuItem;
  } else if (maxAmountForMenuSection !== null) {
    remaining = maxAmountForMenuSection - currentAmountForMenuSection;
  } else {
    remaining = null;
  }

  if (remaining === null) {
    return null;
  } else {
    return Math.max(0, remaining + menuSelectionItemAmount);
  }
};

export const getMenuSectionAmountsForMenuItem = (
  menuItemId: string,
  menuSections: readonly MenuSection[],
  menuSectionAmounts: Record<string, number | undefined>
): {
  currentAmountForMenuSection: number;
  maxAmountForMenuSection: number | null;
} => {
  const matchingMenuSection = menuSections.find(({ menuItemAppearances }) =>
    menuItemAppearances.map(mia => mia.menuItem.id).includes(menuItemId)
  );
  const currentAmountForMenuSection = (matchingMenuSection && menuSectionAmounts[matchingMenuSection.id]) || 0;

  return {
    currentAmountForMenuSection,
    maxAmountForMenuSection: matchingMenuSection?.maxAmount || null,
  };
};

import { Box, Typography, styled } from "@mui/material";
import { macroGPerKgToGramsMetricMacrosRounded } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { MacroProtocolState } from "./reducer/macroProtocolReducer";
import { getAnthroSnapshotFromAnthroState } from "./utils/macroProtocolUtils";

interface MacroProtocolSummaryProps {
  macroProtocol: MacroProtocolState;
}

const TypographyRow = styled(Typography)(() => ({ display: "flex", justifyContent: "space-between" }));

const MacroProtocolSummary = ({ macroProtocol }: MacroProtocolSummaryProps) => {
  const { cho, pro, fat } = macroProtocol;
  const { weightInKg } = getAnthroSnapshotFromAnthroState(macroProtocol.anthropometry);
  const { cho: choGrams, pro: proGrams, fat: fatGrams, kcal: kcalGrams } = macroGPerKgToGramsMetricMacrosRounded(cho, pro, fat, weightInKg);
  const choText = cho.usesPercent ? `${cho.percent}%` : `${cho.gPerKg} g/kg`;
  const proText = pro.usesPercent ? `${pro.percent}%` : `${pro.gPerKg} g/kg`;
  const fatText = fat.usesPercent ? `${fat.percent}%` : `${fat.gPerKg} g/kg`;

  return (
    <Box sx={{ minWidth: 150 }}>
      <TypographyRow variant="body2">
        <span>
          <Box sx={{ color: "cho.main" }} component={"span"}>
            CHO
          </Box>
          :
        </span>
        <span>
          {choGrams}g ({choText})
        </span>
      </TypographyRow>
      <TypographyRow variant="body2">
        <span>
          <Box sx={{ color: "pro.main" }} component={"span"}>
            PRO
          </Box>
        </span>
        <span>
          {proGrams}g ({proText})
        </span>
      </TypographyRow>
      <TypographyRow variant="body2">
        <span>
          <Box sx={{ color: "fat.main" }} component={"span"}>
            FAT
          </Box>
        </span>
        <span>
          {fatGrams}g ({fatText})
        </span>
      </TypographyRow>
      <TypographyRow variant="body2">
        <span>Calories:</span>
        <span>{kcalGrams} kcal</span>
      </TypographyRow>
    </Box>
  );
};

export default MacroProtocolSummary;

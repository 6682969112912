import { DataProxy, FetchResult } from "@apollo/client";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import BrandTable from "../../../../components/Staff/Shared/Brand/Table";
import {
  BrandFragment,
  BrandsDocument,
  BrandsQuery,
  CreateBrandMutation,
  EditBrandMutation,
  useBrandsQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
} from "../../../../types";
import { update } from "../../../../utils/remove";

interface UpdateCacheCreateBrand {
  cache: DataProxy;
  mutationResult: FetchResult<CreateBrandMutation>;
}

interface UpdateCacheEditBrand {
  cache: DataProxy;
  mutationResult: FetchResult<EditBrandMutation>;
}

const updateCacheCreateBrand = ({ cache, mutationResult }: UpdateCacheCreateBrand): void => {
  const { data } = mutationResult;
  const fullBrandsQuery: BrandsQuery | null = cache.readQuery({
    query: BrandsDocument,
    variables: { searchTerm: "", limit: 100 },
  });
  if (fullBrandsQuery && data) {
    const newBrand = data.createBrand.brand;
    cache.writeQuery<BrandsQuery>({
      query: BrandsDocument,
      data: {
        ...fullBrandsQuery,
        brands: [...fullBrandsQuery.brands, newBrand],
      },
    });
  }
};

const updateCacheEditBrand = ({ cache, mutationResult }: UpdateCacheEditBrand): void => {
  const { data } = mutationResult;
  const fullBrandsQuery: BrandsQuery | null = cache.readQuery({
    query: BrandsDocument,
    variables: { searchTerm: "", limit: 100 },
  });
  if (fullBrandsQuery && data) {
    const editedBrand = data.editBrand;
    const idx = fullBrandsQuery.brands.findIndex(b => b.id === editedBrand.brand.id);
    cache.writeQuery<BrandsQuery>({
      query: BrandsDocument,
      data: {
        ...fullBrandsQuery,
        brands: update(fullBrandsQuery.brands, idx, editedBrand.brand),
      },
    });
  }
};

export const BrandsPage = () => {
  const { data, loading } = useBrandsQuery({
    variables: { searchTerm: "", limit: 100 },
  });
  const [createBrand] = useCreateBrandMutation({
    update: (cache, mutationResult) => {
      updateCacheCreateBrand({ cache, mutationResult });
    },
  });
  const [editBrand] = useEditBrandMutation({
    update: (cache, mutationResult) => {
      updateCacheEditBrand({ cache, mutationResult });
    },
  });
  const onAddRow = (row: Pick<BrandFragment, "name" | "usdaManufacturerName">): Promise<void> =>
    new Promise((resolve, reject) => {
      const input = {
        name: row.name,
        usdaManufacturerName: row.usdaManufacturerName,
      };
      createBrand({ variables: { input } });
      resolve();
    });

  const onUpdateRow = (newRow: BrandFragment): Promise<void> =>
    new Promise((resolve, reject) => {
      const input = {
        brand: {
          name: newRow.name,
          usdaManufacturerName: newRow.usdaManufacturerName,
        },
        id: newRow.id,
      };
      editBrand({ variables: { input } });
      resolve();
    });

  if (!data || !!loading) {
    return <Loading />;
  }

  return <BrandTable
    onRowAdd={onAddRow}
    onRowUpdate={onUpdateRow}
    rows={[...data.brands]} />;
};

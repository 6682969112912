import { TextFieldProps, Theme } from "@mui/material";

export const muiOutlinedInput = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: TextFieldProps; theme: Theme }) => {
      const {
        palette: { info },
      } = theme;
      return {
        padding: {
          small: "2px 16px",
          medium: "6px 16px",
        }[ownerState.size as "small" | "medium"],
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: info.main,
        },
      };
    },
    input: ({ ownerState, theme }: { ownerState: TextFieldProps; theme: Theme }) => {
      return {
        padding: 0,
      };
    },
  },
};

import { TableCell, TableRow, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getNavOrgAthlete } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { FoodLogNotificationSettings } from "../../../../types";
import AthleteActions from "./Actions";
import { EnableAthlete } from "./Table";
import { IAthleteEngagementRowInput } from "./utils";

interface AthleteEngagementRowProps {
  row: IAthleteEngagementRowInput;
  displayEnableAccount: boolean;
  onClickEnableAccount: (athlete: EnableAthlete) => void;
  displaySubscribeToAthleteModal: (args: { athleteNotificationSettings: FoodLogNotificationSettings | null; athleteId: string }) => void;
  teamFoodLogNotificationSettings: FoodLogNotificationSettings | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
    },
    headerCell: { padding: theme.spacing(0.5) },
    paleGrey: {
      opacity: theme.palette.action.disabledOpacity,
    },
    smallFont: { fontSize: 13 },
    actions: { display: "flex", alignContent: "center" },
  })
);

const AthleteEngagementRow = ({
  row,
  displayEnableAccount,
  onClickEnableAccount,
  displaySubscribeToAthleteModal,
  teamFoodLogNotificationSettings,
}: AthleteEngagementRowProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const athleteSubscriptionProps = {
    athleteNotificationSettings: row.foodLogNotificationSettings,
    teamNotificationSettings: teamFoodLogNotificationSettings,
  };

  const handleClickSubscribeToAthlete = () => {
    displaySubscribeToAthleteModal({
      athleteId: row.id,
      athleteNotificationSettings: athleteSubscriptionProps.athleteNotificationSettings,
    });
  };

  return (
    <TableRow
      hover
      className={classes.row}
      onClick={() => navigate(getNavOrgAthlete(row.id))}>
      <AthleteActions
        onClickEnableAccount={onClickEnableAccount}
        displaySubscribeToAthleteModal={handleClickSubscribeToAthlete}
        teamFoodLogNotificationSettings={teamFoodLogNotificationSettings}
        row={row}
        displayEnableAccount={displayEnableAccount}
      />

      <TableCell>{row.firstName}</TableCell>
      <TableCell>{row.lastName}</TableCell>
      <TableCell>{row.position?.name || ""}</TableCell>
      <TableCell>{row.totalMealsCount}</TableCell>
      <TableCell>{row.mealsLoggedCount}</TableCell>
      <TableCell>{row.mealsOrderedCount}</TableCell>
      <TableCell>{row.mealPlanViewCount}</TableCell>
      <TableCell>{row.imageUploadCount}</TableCell>
    </TableRow>
  );
};

export default AthleteEngagementRow;

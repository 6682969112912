import { Button } from "@mui/material";
import { TeamGoalConfigurationFragment } from "apps/web/src/types";
import { format } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React from "react";

interface DownloadButtonProps {
  data: readonly TeamGoalConfigurationFragment[];
  teamName: string;
}

interface CSVData {
  team: string;
  date_exported: string;
  athlete: string;
  position: string;
  goal_name: string;
  goal_start_date: string;
  goal_category: string;
  count_completed_goal_task_to_date: number;
  count_assigned_goal_task_to_date: number;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ data, teamName }) => {
  const downloadXLSX = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const formatData = (goal: TeamGoalConfigurationFragment): CSVData => ({
      team: teamName,
      date_exported: new Date().toISOString().split("T")[0],
      athlete: `${goal.athlete?.lastName}, ${goal.athlete?.firstName}`,
      position: goal?.athlete?.position?.name || "",
      goal_name: goal.name,
      goal_start_date: goal.startDate as string,
      goal_category: `${goal.category.defaultEmoji} ${goal.category.name}`,
      count_completed_goal_task_to_date: goal.completedGoalCount,
      count_assigned_goal_task_to_date: goal.totalGoalCount,
    });

    const serializedData = data.map(formatData);
    const headers = Object.keys(serializedData[0]);

    worksheet.columns = headers.map(header => ({
      header,
      key: header,
      width: Math.max(header.length, ...serializedData.map(row => row[header as keyof CSVData]?.toString().length || 10)) + 2,
    }));

    serializedData.forEach(row => {
      worksheet.addRow(headers.map(header => row[header as keyof CSVData]));
    });

    const fileName = `goals_for_${teamName}_export_date_${format(new Date(), "yyyy-MM-dd")}`;
    saveAs(new Blob([await workbook.xlsx.writeBuffer()]), fileName + ".xlsx");
  };

  return (
    <Button
      variant="text"
      size="small"
      onClick={downloadXLSX}
      aria-label="Export Goals"
      disabled={data.length < 1}>
      Export
    </Button>
  );
};

export default DownloadButton;

import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React from "react";
import { TeamsTableTeamFragment } from "../../../types";

interface DeleteTeamDialogProps {
  team: TeamsTableTeamFragment;
  onCancel: () => void;
  onDelete: () => void;
  open: boolean;
}

const DeleteTeamModal = ({ team, onCancel, onDelete, open }: DeleteTeamDialogProps) => (
  <>
    {team.athletes.length === 0 && (
      <ConfirmationDialog
        open={open}
        title="Delete Team"
        message={`Are you sure you want to delete '${team.name}'?`}
        onCancel={onCancel}
        onConfirm={onDelete}
        variant="containedDestructive"
      />
    )}
    {!team.canBeDeleted && (
      <ConfirmationDialog
        open={open}
        title="Delete Team"
        message="You cannot delete a team with rostered or archived players. Remove athletes from team to delete."
        onCancel={onCancel}
        onConfirm={onCancel}
      />
    )}
  </>
);

export default DeleteTeamModal;

import React from "react";
import BaseAsyncAutoComplete, { BasicOption } from "../../../../components/universal/BaseAsyncAutocomplete";
import { DietFragment, useStaffDashboardDietsQuery } from "../../../../types";

const useSearchQuery = (query: string) => {
  const { data, loading } = useStaffDashboardDietsQuery({
    variables: { input: { limit: 25, offset: 0 }, query },
  });
  const options = data ? [...data.dietOffsetConnection.edges] : undefined;
  return { options, loading };
};

interface RestaurantMenuFormDietsProps {
  className?: string;
  supportedDiets: readonly DietFragment[];
  onChange: (supportedDiets: DietFragment[]) => void;
}

const RestaurantMenuFormDiets = ({ className, supportedDiets, onChange }: RestaurantMenuFormDietsProps) => {
  const supportedDietIds = supportedDiets.map(d => d.id);
  const filterUsedOptions = (options: DietFragment[] | undefined) => {
    if (options === undefined) {
      return undefined;
    }
    return options.filter(tt => !supportedDietIds.includes(tt.id));
  };

  return (
    <div className={className}>
      <BaseAsyncAutoComplete
        useSearchQuery={useSearchQuery}
        inputPlaceholder="Supported Diets"
        noOptionsText="No Diets Found"
        handleChange={onChange}
        filterUsedOptions={filterUsedOptions}
        multiple
        renderOption={({ name }: DietFragment) => <BasicOption element={name} />}
        getOptionLabel={(d: DietFragment) => d.name}
        value={supportedDiets.concat()}
      />
    </div>
  );
};

export default RestaurantMenuFormDiets;

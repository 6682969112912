import { z } from "zod";
import { GenericFoodSchema } from "./FoodSchema";

export const BonAppetitFoodSchema = GenericFoodSchema.merge(
  z.object({
    source: z.literal("bonAppetit"),
    bonAppetitItemId: z.string(),
    bonAppetitItemHash: z.string(),
  })
);

import React from "react";
import InfiniteScrollAutocomplete, {
  InfiniteScrollAutocompleteProps,
  NonGenericAutoCompleteProps,
} from "../../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import { CursorPaginationQueryVariables } from "../../../components/universal/InfiniteScroll/useInfiniteCursorConnectionScroll";

type BaseTagSelectorProps<K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables> = Omit<
  InfiniteScrollAutocompleteProps<K, EdgesType, QueryVariables, EdgesType, true, false>,
  "multiple" | "getOptionSelected"
>;

export type BaseTagSelectorPassThroughProps = Pick<NonGenericAutoCompleteProps, "textInputLabel">;
const BaseTagSelector = <K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables>({
  edgesAreEqual,
  sx,
  ...props
}: BaseTagSelectorProps<K, EdgesType, QueryVariables>) => {
  return (
    <InfiniteScrollAutocomplete
      sx={sx}
      multiple
      getOptionSelected={edgesAreEqual}
      disableCloseOnSelect
      blurOnSelect={false}
      showStartAdornment={false}
      disableRootPadding
      edgesAreEqual={edgesAreEqual}
      {...props}
    />
  );
};

export default BaseTagSelector;

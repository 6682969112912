import { parseTime } from "@notemeal/shared/ui/utils/dateTimes";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import {
  ScheduleModalEditorActivityState,
  ScheduleModalEditorMealState,
  ScheduleModalEditorState,
} from "../../../reducers/ScheduleModalEditor";
import { ActivityType, MacroMealPlanTemplateMealFragment, MealPlanTemplateActivityFragment, MealType } from "../../../types";
import { getDefaultMealRatio } from "./scheduleUtils";

export interface BaseScheduleItem {
  name: string;
  start: string;
  end: string;
}

export interface ScheduleMeal extends BaseScheduleItem {
  type: MealType;
}

export interface CreateServingAmountInputItem {
  servingId: string;
  amount: number;
  position: number;
}

export interface MealOptionInputItemWithId {
  servingAmounts: CreateServingAmountInputItem[];
  position: number;
  note: string | null;
  id: string;
  name: string | null;
}

export interface MealInputWithId {
  choRatio: number;
  choRatioInput: string;
  fatRatio: number;
  fatRatioInput: string;
  proRatio: number;
  proRatioInput: string;
  meal: ScheduleMeal;
  mealOptions: readonly MealOptionInputItemWithId[];
  id: string;
}

export interface ActivityInputWithId extends BaseScheduleItem {
  id: string;
  type: ActivityType;
}

export interface ScheduleState {
  meals: MealInputWithId[];
  activities: ActivityInputWithId[];
}

interface EditMealAction {
  type: "EDIT_MEAL";
  payload: MealInputWithId;
}

interface OverwriteScheduleStateAction {
  type: "OVERWRITE_SCHEDULE_STATE";
  payload: {
    meals: MealInputWithId[];
    activities: ActivityInputWithId[];
  };
}

interface DistributeMacrosAction {
  type: "DISTRIBUTE_MACROS";
}

export type ScheduleAction = EditMealAction | OverwriteScheduleStateAction | DistributeMacrosAction;

export const scheduleReducer = (state: ScheduleState, action: ScheduleAction): ScheduleState => {
  switch (action.type) {
    case "EDIT_MEAL": {
      const meals = state.meals.map(meal => (meal.id === action.payload.id ? action.payload : meal));

      return {
        ...state,
        meals,
      };
    }
    case "OVERWRITE_SCHEDULE_STATE":
      return {
        ...action.payload,
      };
    case "DISTRIBUTE_MACROS":
      return {
        ...state,
        meals: state.meals.map(meal => {
          const isSnack = meal.meal.type === "snack";
          const ratio = isSnack ? 1 : 2;
          const stringRatio = ratio.toString();
          return {
            ...meal,
            choRatio: ratio,
            fatRatio: ratio,
            proRatio: ratio,
            choRatioInput: stringRatio,
            fatRatioInput: stringRatio,
            proRatioInput: stringRatio,
          };
        }),
      };
    default:
      return state;
  }
};

export const convertTemplateScheduleStateToScheduleEditorState = (state: ScheduleState): ScheduleModalEditorState => {
  const meals: ScheduleModalEditorMealState[] = state.meals.map(meal => ({
    id: meal.id,
    name: meal.meal.name,
    type: meal.meal.type,
    start: meal.meal.start,
    startValue: parseTime(meal.meal.start),
    end: meal.meal.end,
    endValue: parseTime(meal.meal.end),
    diningSources: [],
  }));

  const activities: ScheduleModalEditorActivityState[] = state.activities.map(activity => ({
    id: activity.id,
    name: activity.name,
    type: activity.type,
    start: activity.start,
    startValue: parseTime(activity.start),
    end: activity.end,
    endValue: parseTime(activity.end),
  }));

  return {
    scheduleId: null,
    scheduleName: "",
    canEditScheduleName: false,
    // View State
    meals,
    activities,
    // Pre-mutation State
    initialMeals: meals,
    initialActivities: activities,
    activityFactor: null,
  };
};

export const convertScheduleModalEditorMealToTemplateScheduleMeal = (
  meal: ScheduleModalEditorMealState,
  prevMeals: MealInputWithId[]
): MealInputWithId => {
  const maybeExistingMeal = prevMeals.find(prevMeal => prevMeal.id === meal.id);
  const defaultRatio = getDefaultMealRatio(meal.type);
  const defaultRatioString = defaultRatio.toString();
  return {
    id: meal.id,
    meal: {
      end: meal.end,
      start: meal.start,
      name: meal.name,
      type: meal.type,
    },
    mealOptions: maybeExistingMeal?.mealOptions ?? [],
    choRatio: maybeExistingMeal?.choRatio ?? defaultRatio,
    choRatioInput: maybeExistingMeal?.choRatioInput ?? defaultRatioString,
    proRatio: maybeExistingMeal?.proRatio ?? defaultRatio,
    proRatioInput: maybeExistingMeal?.proRatioInput ?? defaultRatioString,
    fatRatio: maybeExistingMeal?.fatRatio ?? defaultRatio,
    fatRatioInput: maybeExistingMeal?.fatRatioInput ?? defaultRatioString,
  };
};

export const convertScheduleModalEditorActivityToTemplateScheduleActivity = (
  activity: ScheduleModalEditorActivityState
): ActivityInputWithId => {
  return {
    id: activity.id,
    end: activity.end,
    start: activity.start,
    name: activity.name,
    type: activity.type,
  };
};

export const getCanSaveScheduleTooltips = (state: ScheduleState): string[] => {
  if (state.meals.length === 0) {
    return ["You need at least one meal to create a meal plan"];
  }
  const hasEventErrors = [...state.meals.map(({ meal }) => meal), ...state.activities].some(event => {
    return event.start === "" || event.end === "" || event.name === "";
  });
  const tooltips = [];

  if (hasEventErrors) {
    tooltips.push("All Meals and Activities must have a stat, end, name, and type");
  }

  const hasValidRatios = state.meals.every(meal => {
    const hasValidChoRatio = meal.choRatio >= 0 && meal.choRatio === inputToNumber(meal.choRatioInput);
    const hasValidProRatio = meal.proRatio >= 0 && meal.proRatio === inputToNumber(meal.proRatioInput);
    const hasValidfatRatio = meal.fatRatio >= 0 && meal.fatRatio === inputToNumber(meal.fatRatioInput);
    return hasValidChoRatio && hasValidProRatio && hasValidfatRatio;
  });
  if (!hasValidRatios) {
    tooltips.push("All meals must have a non-negative ChoRatio, ProRatio, and FatRatio");
  }
  return tooltips;
};

export const buildInitialEditScheduleStateFromMealPlanTemplate = (
  serverMeals: readonly MacroMealPlanTemplateMealFragment[],
  serverActivities: readonly MealPlanTemplateActivityFragment[]
): ScheduleState => {
  const meals: MealInputWithId[] = serverMeals.map(meal => ({
    id: meal.id,
    feId: meal.id,
    choRatio: meal.choRatio,
    choRatioInput: meal.choRatio.toString(),
    fatRatio: meal.fatRatio,
    fatRatioInput: meal.fatRatio.toString(),
    proRatio: meal.proRatio,
    proRatioInput: meal.proRatio.toString(),
    meal: {
      end: meal.end,
      name: meal.name,
      start: meal.start,
      type: meal.type,
    },
    mealOptions: meal.mealOptions.map(mealOption => ({
      name: mealOption.name,
      note: mealOption.note,
      id: mealOption.id,
      position: mealOption.position,
      servingAmounts: mealOption.servingAmounts.map(sa => ({
        servingId: sa.serving.id,
        amount: sa.amount,
        position: sa.position,
      })),
    })),
  }));

  const activities = serverActivities.map(activity => ({
    id: activity.id,
    end: activity.end,
    name: activity.name,
    start: activity.start,
    type: activity.type,
  }));

  return { meals, activities };
};

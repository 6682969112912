import React, { useState } from "react";
import { NotemealOnlyProfile } from "../../reducer";
import { filterProfileForSearchTerm } from "../../utils";
import BaseNotemealOnlyList from "./BaseNotemealOnlyProfileList";

interface UnlinkedNotemealOnlyProfileListProps {
  onAdd: (profile: NotemealOnlyProfile) => void;
  profiles: readonly NotemealOnlyProfile[];
}

const UnlinkedNotemealOnlyProfileList = (props: UnlinkedNotemealOnlyProfileListProps) => {
  const { profiles } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const searchProps = { searchTerm, setSearchTerm };
  const filteredProfiles = profiles.filter(p => {
    const firstName = p.__typename === "Athlete" ? p.firstName : p.user.firstName;
    const lastName = p.__typename === "Athlete" ? p.lastName : p.user.lastName;
    return filterProfileForSearchTerm({
      firstName,
      lastName,
      searchTerm,
    });
  });
  return (
    <BaseNotemealOnlyList
      {...props}
      searchProps={searchProps}
      profiles={filteredProfiles}
      title="Unlinked Nutrition Profiles"
      columnMode="Add"
    />
  );
};

export default UnlinkedNotemealOnlyProfileList;

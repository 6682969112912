import { Box, Typography } from "@mui/material";
import { getFoodSearchFiltersForOrgLocale } from "@notemeal/food/utils";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { TWButtonGroup } from "apps/web/src/componentLibrary/TWButtonGroup/TWButtonGroup";
import React, { useState } from "react";
import {
  Food,
  FoodGroupsDocument,
  FoodGroupsQuery,
  FoodGroupsQueryVariables,
  SimpleFoodGroupFragment,
  SimpleFoodsDocument,
  SimpleFoodsQuery,
  SimpleFoodsQueryVariables,
} from "../../../types";
import { IFoodGroupFood } from "../../FoodGroup/FoodTable";
import { AutocompleteQuerySearchBar } from "../../universal/AutocompleteQuerySearchBar/AutocompleteQuerySearchBar";

interface AddFoodProps {
  type: "Athlete" | "MealPlan";
  id: string;
  food: Pick<Food, "id" | "name">;
}

interface AddFoodGroupProps {
  type: "Athlete" | "MealPlan";
  id: string;
  foodGroup: SimpleFoodGroupFragment;
}

interface FoodMultiSearchBarProps {
  type: "Athlete" | "MealPlan";
  preference: "like" | "dislike";
  preferrerId: string;
  addFood: ({ type, id, food }: AddFoodProps) => void;
  addFoodGroup: ({ type, id, foodGroup }: AddFoodGroupProps) => void;
}

export const FoodMultiSearchBar = ({ preference, preferrerId, addFood, addFoodGroup, type }: FoodMultiSearchBarProps) => {
  const FOODS = "Foods";
  const FOOD_GROUPS = "Food Groups";
  const [selected, setSelected] = useState(FOOD_GROUPS);
  const buttons = [FOOD_GROUPS, FOODS];
  const positiveCapsLabel = type === "Athlete" ? "Liked" : "Promoted";
  const negativeCapsLabel = type === "Athlete" ? "Disliked" : "Avoided";
  const positiveLabel = type === "Athlete" ? "liked" : "promoted";
  const negativeLabel = type === "Athlete" ? "disliked" : "avoided";
  const title = `${preference === "like" ? positiveCapsLabel : negativeCapsLabel} Food Groups/Foods`;
  const placeholder = `Search ${preference === "like" ? positiveLabel : negativeLabel} foods`;
  const localeContext = useLocaleContext();
  const { localeFilter, dataSourceFilter } = getFoodSearchFiltersForOrgLocale(localeContext.locale);
  const commonStyle = { mt: 0, width: "280px" };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography sx={{ mb: 1 }} variant="h4">
        {title}
      </Typography>
      <TWButtonGroup buttons={buttons} onSelected={setSelected} />
      {selected === FOODS && (
        <AutocompleteQuerySearchBar<IFoodGroupFood, SimpleFoodsQuery, SimpleFoodsQueryVariables>
          sx={commonStyle}
          query={SimpleFoodsDocument}
          placeholder={placeholder}
          getOptionLabel={o => o.name}
          getOptionsFromQueryData={data => [...data.foods]}
          getQueryOptions={searchTerm => ({
            variables: {
              searchTerm,
              limit: 10,
              localeCodes: localeFilter,
              dataSources: dataSourceFilter,
            },
          })}
          onChange={o => (o ? addFood({ type, id: preferrerId, food: { id: o.id, name: o.name } }) : null)}
          getUserFriendlyQueryErrorMessage={() => "Error searching foods, please try again."}
        />
      )}
      {selected === FOOD_GROUPS && (
        <AutocompleteQuerySearchBar<SimpleFoodGroupFragment, FoodGroupsQuery, FoodGroupsQueryVariables>
          sx={commonStyle}
          query={FoodGroupsDocument}
          minInputChars={0} // instant search
          placeholder={preference === "like" ? `Search ${positiveLabel} food groups` : `Search ${negativeLabel} food groups`}
          getOptionLabel={o => o.name}
          getOptionsFromQueryData={data => [...data.foodGroups]}
          getQueryOptions={searchTerm => ({
            variables: {
              searchTerm,
              limit: 100,
            },
          })}
          onChange={o => (o ? addFoodGroup({ type, id: preferrerId, foodGroup: o }) : null)}
          getUserFriendlyQueryErrorMessage={() => "Error searching food groups, please try again."}
        />
      )}
    </Box>
  );
};

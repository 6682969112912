import { ApolloCache, Reference } from "@apollo/client";
import { Athlete, MealPlan } from "apps/web/src/types";

const ATHLETE_EVICT_FIELDS: (keyof Athlete)[] = [
  "mealPlanDateAssignmentsInRange",
  "priorityMealPlanDaysOfWeekInRange",
  "mealPlanIndividualDatesInRange",
];

const MEAL_PLAN_EVICT_FIELDS: (keyof MealPlan)[] = ["individualDatesInRange", "daysOfWeek", "isAutoSuggestionsEnabled"];

type EvictMealPlanCalendarQueriesForAthleteArgs = {
  athleteId: string;
  cache: ApolloCache<object>;
} & (
  | {
      type: "Create";
    }
  | {
      type: "Edit" | "Delete";
      mealPlanId: string;
    }
);

export const evictMealPlanCalendarQueriesForAthlete = ({
  athleteId,
  cache,
  ...rest
}: EvictMealPlanCalendarQueriesForAthleteArgs): boolean => {
  const id = `Athlete:${athleteId}`;

  const mealPlansResult = cache.modify({
    id,
    broadcast: true,
    fields: {
      mealPlans: (mealPlans: Reference[]) => {
        return mealPlans.map(mp => {
          MEAL_PLAN_EVICT_FIELDS.forEach(fieldName => {
            cache.evict({
              id: mp.__ref,
              broadcast: true,
              fieldName,
            });
          });
          return mp;
        });
      },
    },
  });

  const athleteResults = ATHLETE_EVICT_FIELDS.map(fieldName =>
    cache.evict({
      id,
      broadcast: true,
      fieldName,
    })
  );

  if (rest.type === "Edit") {
    const editResult = cache.evict({
      id: `MealPlan:${rest.mealPlanId}`,
      broadcast: true,
      fieldName: "id",
    });

    return athleteResults
      .concat(mealPlansResult)
      .concat(editResult)
      .every(result => result);
  } else if (rest.type === "Delete") {
    const deleteResult = cache.evict({
      id: `MealPlan:${rest.mealPlanId}`,
      broadcast: true,
    });

    return athleteResults
      .concat(mealPlansResult)
      .concat(deleteResult)
      .every(result => result);
  } else {
    return athleteResults.concat(mealPlansResult).every(result => result);
  }
};

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Divider, FormControlLabel, Menu, MenuItem, PopoverOrigin, Switch, Typography } from "@mui/material";
import { TWIconButton } from "apps/web/src/componentLibrary/TWIconButton/TWIconButton";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import React, { useState } from "react";

interface RecipeHeaderProps {
  handleClose: () => void;
  onChange: () => void;
  handleSave: () => void;
  handleDuplicate: () => void;
  canSaveTooltips: string[];
  forStaff?: boolean;
  isShared: boolean;
  readonly?: boolean;
  setPrintOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditRecipeHeader = ({
  handleClose,
  onChange,
  handleSave,
  isShared,
  readonly,
  handleDuplicate,
  forStaff,
  setPrintOpen,
  canSaveTooltips,
}: RecipeHeaderProps) => {
  const [actionsMenu, setActionsMenu] = useState<HTMLElement | null>(null);

  const print = () => {
    setActionsMenu(null);
    setPrintOpen(true);
  };

  const duplicate = () => {
    setActionsMenu(null);
    handleDuplicate();
  };

  const anchorOrigin: PopoverOrigin = { vertical: "bottom", horizontal: "right" };
  const transformOrigin: PopoverOrigin = { vertical: "top", horizontal: "right" };

  return (
    <Box sx={{ gap: 2, display: "flex", flexDirection: "column", flex: 1, overflowY: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"h2"}>{readonly ? "View Recipe" : "Edit Recipe"}</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <FormControlLabel
            label={forStaff ? "Share with orgs" : "Share with athletes"}
            labelPlacement="end"
            checked={isShared}
            onChange={onChange}
            control={<Switch sx={{ ml: 1.5, mr: 1 }} />}
          />
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          {!readonly && (
            <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
              <Button onClick={() => handleSave()} disabled={canSaveTooltips.length > 0}>
                Save
              </Button>
            </TWItemizedTooltip>
          )}
          <TWIconButton
            variant="outlined"
            onClick={e => setActionsMenu(e.currentTarget)}
            icon={<MoreVertIcon />} />
          <Menu
            anchorEl={actionsMenu}
            open={!!actionsMenu}
            onClose={() => setActionsMenu(null)}
            keepMounted
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          >
            <MenuItem dense onClick={duplicate}>
              Duplicate
            </MenuItem>
            <MenuItem dense onClick={print}>
              Print
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
import { ExportActivityItem } from "@notemeal/shared/ui/Activity";
import { ExchangeMealPlanLegacyDisplaySettings, MacroMealPlanLegacyDisplaySettings } from "@notemeal/shared/ui/DisplaySettings/utils";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { genericSort } from "@notemeal/utils/sort";
import React, { memo } from "react";
import { FullMealPlanFragment, FullMealTemplateFragment, RecipeWithServingsFragment } from "../../../types";
import { CurrentMealPlanExportInfo } from "../../../views/Team/Athletes/MealPlans/BulkExportModal";
import AppendixFoodGroup from "./Appendix/FoodGroup";
import AppendixRecipe from "./Appendix/Recipe";
import Header from "./Header";
import MealExchangeLists from "./Meal/ExchangeLists";
import FullExchangeLists from "./Meal/FullExchangeLists";
import MealPage from "./Meal/Page";
import MealRow from "./Meal/Row";
import PagedContainer from "./PagedContainer";
import { isMacro } from "./utils";
import { ExportPdfState } from "./utils/pdfReducer";

const formatDateRange = (mealPlan: FullMealPlanFragment, formatDateWithLocale: (date: Date | string) => string): string => {
  if (mealPlan.startDate && mealPlan.endDate) {
    return ` | ${formatDateWithLocale(mealPlan.startDate)} -> ${formatDateWithLocale(mealPlan.endDate)}`;
  } else {
    return "";
  }
};

const canJoinExchangeListsAndOptions = (
  mt: FullMealTemplateFragment,
  toolbarState: ExportPdfState,
  displaySettings: MacroMealPlanLegacyDisplaySettings | ExchangeMealPlanLegacyDisplaySettings
): boolean => {
  if (toolbarState.expandExchanges) {
    return false;
  }

  const maxServings = mt.mealOptions.reduce((maxx, next) => (next.servingAmounts.length > maxx ? next.servingAmounts.length : maxx), 0);
  const displayExchangeLists =
    displaySettings.__typename === "ExchangeMealPlanLegacyDisplaySettings" && displaySettings.displayExchangeLists;
  if (!displaySettings.displayMealOptions && displayExchangeLists) {
    return true;
  }

  if (toolbarState.orientation === "portrait") {
    return displayExchangeLists && mt.mealOptions.length <= 3 && maxServings <= 6;
  }
  return false;
};

// onChangePageDivs should not change on its own (ideally never), i.e. see useCallback if necessary
// This allows memo() to prevent infinite render loop
interface MealPlanExportContainerProps {
  mealPlan: FullMealPlanFragment;
  recipes: readonly RecipeWithServingsFragment[];
  toolbarState: ExportPdfState;
  displaySettings: ExchangeMealPlanLegacyDisplaySettings | MacroMealPlanLegacyDisplaySettings;
  onChangePageDivs: (divs: HTMLDivElement[], mealPlanInfo: CurrentMealPlanExportInfo) => void;
}

const MealPlanExportContainer = ({ mealPlan, displaySettings, toolbarState, onChangePageDivs, recipes }: MealPlanExportContainerProps) => {
  const { formatDateWithLocale } = useDateFormatting();

  const handleDateFormatRangeWithLocale = (mealplan: FullMealPlanFragment) => {
    return formatDateRange(mealplan, formatDateWithLocale);
  };

  const exchangeSettings = displaySettings.__typename === "ExchangeMealPlanLegacyDisplaySettings" ? displaySettings : null;
  const macroSettings = displaySettings.__typename === "MacroMealPlanLegacyDisplaySettings" ? displaySettings : null;

  const { athlete, mealTemplates, activityTemplates, description, promotedFoodGroups, avoidedFoodGroups } = mealPlan;
  const exchangeSet = mealPlan.exchangeSet;

  const activityObjs = activityTemplates.map(at => ({
    start: at.activity.start,
    component: <ExportActivityItem key={`activity-${at.activity.id}`} activity={at.activity} />,
    exchangeListsComponent: null,
  }));

  const mealObjs = mealTemplates.map(mt => {
    // TODO: Rip the 'MealPage' into its 2 children components if we exceed height of page in utils/pagify

    if (canJoinExchangeListsAndOptions(mt, toolbarState, displaySettings)) {
      return {
        // When we can, we render the 'ExportMealOptionRow' and the 'MealExchangeLists' in one page
        start: mt.meal.start,
        exchangeListsComponent: null,
        component: (
          <MealPage
            key={`meal-page-${mt.id}`}
            orientation={toolbarState.orientation}
            condensed={toolbarState.condensed}
            expandExchanges={toolbarState.expandExchanges}
            displayMacros={
              (exchangeSettings && exchangeSettings.displayMealMacros) || (macroSettings && macroSettings.displayMealMacros) || false
            }
            displayExchanges={(exchangeSettings && exchangeSettings.displayMealExchanges) || false}
            displayMealOptions={
              (exchangeSettings && exchangeSettings.displayMealOptions) || (macroSettings && macroSettings.displayMealOptions) || false
            }
            expandRecipes={toolbarState.expandRecipes}
            mealTemplate={mt}
            exchangeSet={exchangeSet}
            displaySecondaryExchanges={toolbarState.displaySecondaryExchanges}
            displayMealOptionMacros={
              (exchangeSettings && exchangeSettings.displayMealOptionMacros) ||
              (macroSettings && macroSettings.displayMealOptionMacros) ||
              false
            }
            recipes={recipes}
          />
        ),
      };
    } else {
      return {
        start: mt.meal.start,
        component: (
          <MealRow
            key={`meal-${mt.id}`}
            orientation={toolbarState.orientation}
            displayMacros={exchangeSettings?.displayMealMacros || macroSettings?.displayMealMacros || false}
            displayExchanges={exchangeSettings?.displayMealExchanges || true}
            displayMealOptions={exchangeSettings?.displayMealOptions || macroSettings?.displayMealOptions || false}
            expandRecipes={toolbarState.expandRecipes}
            mealTemplate={mt}
            recipes={recipes}
            displayMealOptionMacros={exchangeSettings?.displayMealOptionMacros || macroSettings?.displayMealOptionMacros || false}
          />
        ),
        exchangeListsComponent:
          !toolbarState.condensed && exchangeSettings?.displayExchangeLists && !isMacro(mt) ? (
            <MealExchangeLists
              key={`plate-${mt.id}`}
              mealTemplate={mt}
              exchangeSet={exchangeSet}
              expandExchanges={toolbarState.expandExchanges}
              displaySecondaryExchanges={toolbarState.displaySecondaryExchanges}
              recipes={recipes}
            />
          ) : null,
      };
    }
  });

  const descriptionComponent = description ? (
    <Box key={`export-mpt-description`} sx={{ lineHeight: 0.65, fontStyle: "italic" }}>
      <Typography
        key={`export-mpt-description-typography`}
        sx={{ lineHeight: 0.8, fontSize: "10px", color: "grey.600" }}
        variant="subtitle1"
      >
        {description}
      </Typography>
    </Box>
  ) : null;

  const eventComponents = [...mealObjs, ...activityObjs]
    .sort((a, b) => genericSort(a.start, b.start))
    .flatMap(obj => [obj.component, obj.exchangeListsComponent]);

  const recipeComponents = toolbarState.displayRecipes
    ? recipes.filter(r => !!r.steps && r.hasFullAccess).map(r => <AppendixRecipe key={r.id} recipe={r} />)
    : [];

  const promotedFoodGroupComponents = toolbarState.displayFoodGroups
    ? promotedFoodGroups.map(foodGroup => (
        <div key={foodGroup.id}>
          <AppendixFoodGroup
            orientation={toolbarState.orientation}
            promoteOrAvoid="promote"
            foodGroup={foodGroup} />
        </div>
      ))
    : [];

  const avoidedFoodGroupComponents = toolbarState.displayFoodGroups
    ? avoidedFoodGroups.map(foodGroup => (
        <div key={foodGroup.id}>
          <AppendixFoodGroup
            orientation={toolbarState.orientation}
            promoteOrAvoid="avoid"
            foodGroup={foodGroup} />
        </div>
      ))
    : [];

  const singleExchangeListComponent = !isMacro(mealTemplates[0]) && toolbarState.condensed && (
    <FullExchangeLists
      key={`full-exchange-list-${mealTemplates[0].id}`}
      mealTemplate={mealTemplates[0]}
      exchangeSet={exchangeSet}
      expandExchanges={toolbarState.expandExchanges}
      orientation={toolbarState.orientation}
      displaySecondaryExchanges={true}
      recipes={recipes}
    />
  );

  // WARNING!: Make sure that any 'component' included in the meal plan is not wrapped in a React.Fragment
  // We get funky behavior, i.e. the bug fixed in 0066eb550ab5d82b39b59901de6e3c32d89fc9ce
  const sortedComponents = [
    descriptionComponent,
    ...eventComponents,
    ...recipeComponents,
    ...promotedFoodGroupComponents,
    ...avoidedFoodGroupComponents,
    singleExchangeListComponent,
  ].filter(c => !!c);

  const header = (
    <Header
      mealPlan={mealPlan}
      athleteName={athlete.firstName + " " + athlete.lastName}
      athleteBirthDate={athlete.birthDate}
      displayTitle={toolbarState.displayTitle}
      displayHeaderExchanges={exchangeSettings?.displayDailyExchanges || false}
      displayHeaderMacros={exchangeSettings?.displayDailyMacros || macroSettings?.displayDailyMacros || false}
      title={`${mealPlan.name}${handleDateFormatRangeWithLocale(mealPlan)}`}
    />
  );

  return (
    <PagedContainer
      header={header}
      sortedComponents={sortedComponents}
      orientation={toolbarState.orientation}
      onChangePageDivs={divs =>
        onChangePageDivs(divs, {
          hasEmail: !!athlete.email,
          name: mealPlan.name,
          athlete: { firstName: athlete.firstName, lastName: athlete.lastName },
        })
      }
    />
  );
};

export default memo(MealPlanExportContainer);

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { PaginationHooks, getMuiTablePaginationProps } from "../../utils/pagination";

export interface IFoodGroup {
  id: string;
  name: string;
  source: string | null;
  foodsCount: number;
}

interface FoodGroupTableProps {
  rows: IFoodGroup[];
  setSelectedFoodGroup: (foodGroup: IFoodGroup | null) => void;
  setFoodGroupModalOpen: (open: boolean) => void;
  paginationHooks: PaginationHooks;
  total: number;
  setSortOrder: (sortOrder: "ASC" | "DESC") => void;
  sortOrder: "ASC" | "DESC";
}

interface ReadOnlyFoodGroupTableProps extends FoodGroupTableProps {
  readOnly: boolean;
}

const isReadOnly = (props: FoodGroupTableProps | ReadOnlyFoodGroupTableProps): props is ReadOnlyFoodGroupTableProps => {
  return (props as ReadOnlyFoodGroupTableProps).readOnly !== undefined;
};

export const FoodGroupTable = (props: ReadOnlyFoodGroupTableProps | FoodGroupTableProps) => {
  const { setFoodGroupModalOpen, setSelectedFoodGroup, rows: foodGroups, paginationHooks } = props;
  const { total, setSortOrder, sortOrder } = props;

  const toggleSortOrder = () => setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 300 }}>
                <TableSortLabel onClick={toggleSortOrder}>
                  <b>Name</b>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <b># Foods</b>
              </TableCell>
              {isReadOnly(props) && (
                <TableCell>
                  <b>Source</b>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {foodGroups.map(foodGroup => {
              const { id, name, foodsCount, source } = foodGroup;
              return (
                <TableRow
                  id={id}
                  key={id}
                  onClick={() => {
                    setSelectedFoodGroup(foodGroup);
                    setFoodGroupModalOpen(true);
                  }}
                >
                  <TableCell>
                    <Typography>{name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{foodsCount}</Typography>
                  </TableCell>
                  {isReadOnly(props) && (
                    <TableCell>
                      <Typography>{source}</Typography>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
        {...getMuiTablePaginationProps(paginationHooks, total)}
      />
    </Box>
  );
};

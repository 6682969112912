import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer } from "react";
import { MacroProtocolState, macroProtocolReducer } from "../reducer/macroProtocolReducer";
import SampleDataForm from "./SampleDataForm";

interface SampleDataModalProps {
  initialMacroProtocolState: MacroProtocolState;
  onClose: () => void;
  open: boolean;
  onDone: (macroProtocol: MacroProtocolState) => void;
}

const SampleDataModal = ({ initialMacroProtocolState, onClose, open, onDone }: SampleDataModalProps) => {
  const [state, dispatch] = useReducer(macroProtocolReducer, initialMacroProtocolState);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={onClose}
      open={open}>
      <DialogTitle title="Edit Meal Plan Template" onClose={onClose} />
      <DialogContent>
        <SampleDataForm state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDone(state);
            onClose();
          }}
        >
          {"Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SampleDataModal;

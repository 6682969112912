import { Box, SxProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DayOfWeek } from "apps/web/src/types";
import classnames from "classnames";
import React from "react";
import { ORDERED_DAYS_OF_WEEK } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { info, grey } }: Theme) =>
  createStyles({
    day: {
      height: 24,
      width: 24,
      margin: spacing(0, 0.5),
      flexShrink: 0,
      flexGrow: 0,
      backgroundColor: grey[100],
      borderWidth: "2px",
      borderColor: grey[100],
      borderStyle: "solid",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    selectedDay: {
      borderColor: info.main,
    },
  })
);

interface MealPlanCalendarDayOfWeekSelectorProps {
  daysOfWeek: readonly DayOfWeek[];
  onToggleDayOfWeek: (dayOfWeek: DayOfWeek) => void;
  sx?: SxProps;
}

const MealPlanCalendarDayOfWeekSelector = ({ daysOfWeek, onToggleDayOfWeek, sx }: MealPlanCalendarDayOfWeekSelectorProps) => {
  const classes = useStyles();

  return (
    <Box sx={{ ...sx, display: "flex" }}>
      {ORDERED_DAYS_OF_WEEK.map(d => (
        <Box
          key={d}
          className={classnames(classes.day, {
            [classes.selectedDay]: daysOfWeek.includes(d),
          })}
          onClick={() => onToggleDayOfWeek(d)}
        >
          <Typography>{getDayOfWeekLabel(d)}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MealPlanCalendarDayOfWeekSelector;

const getDayOfWeekLabel = (dayOfWeek: DayOfWeek) => {
  if (dayOfWeek === "thursday") {
    return "Th";
  }
  return dayOfWeek.charAt(0).toUpperCase();
};

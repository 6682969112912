import { datadogRum } from "@datadog/browser-rum";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import { getNavOrgKitchenMenuScheduleImport } from "apps/web/src/pages/Auth/Org/Kitchen/KitchenPaths";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { getNavOrgMenuBuilder } from "../../../pages/Auth/Org/MenuBuilder/MenuBuilderPaths";
import { usePlannedMenuTable } from "./PlannedMenuTableProvider";

export const PlannedMenuRows = () => {
  const navigate = useNavigate();
  const {
    rows,
    moreInfo,
    setMoreInfo,
    setDeletablePlannedMenu,
    setMenuToDuplicate,
    setMenuToEdit,
    getFormattedNameForUser,
    getFormattedDateRange,
    getFormattedTimeDifference,
  } = usePlannedMenuTable();

  return (
    <>
      {rows.map(row => {
        const { id, name, description, mealTypes, createdBy, startDate, endDate, timezone, lastEditedAt, lastEditedBy } = row;
        return (
          <TableRow
            id={id}
            key={id}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(getNavOrgMenuBuilder(id))}>
            <TableCell sx={{ maxWidth: "200px" }}>{name}</TableCell>
            <TableCell sx={{ maxWidth: "340px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {description ? (
                <TWTooltip title={`Description`} subcopy={description}>
                  <span>{description}</span>
                </TWTooltip>
              ) : null}
            </TableCell>
            <TableCell>{mealTypes.map(capitalize).join(", ")}</TableCell>
            <TableCell sx={{ maxWidth: "140px" }}>{createdBy && getFormattedNameForUser(createdBy)}</TableCell>
            <TableCell>{getFormattedDateRange({ startDate, endDate, timezone })}</TableCell>
            <TableCell sx={{ minWidth: "160px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {lastEditedAt && getFormattedTimeDifference(lastEditedAt, timezone)}{" "}
              {lastEditedBy && (
                <TWTooltip title={`Last Edited By`} subcopy={`${lastEditedBy.firstName} ${lastEditedBy.lastName}`}>
                  <Typography
                    component="span"
                    color="mediumEmphasisTextLight"
                    sx={{ fontSize: "12px" }}
                  >{`(${lastEditedBy.firstName} ${lastEditedBy.lastName})`}</Typography>
                </TWTooltip>
              )}
            </TableCell>

            <TableCell>
              <IconButton
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  setMoreInfo({ moreAnchorElement: e.currentTarget, plannedMenu: row });
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      <Menu
        anchorEl={moreInfo?.moreAnchorElement}
        open={!!moreInfo}
        onClose={() => {
          setMoreInfo(null);
        }}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {moreInfo && (
          <>
            <MenuItem
              dense
              onClick={() => {
                setMenuToEdit(moreInfo.plannedMenu);
                setMoreInfo(null);
              }}
            >
              Edit menu details
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                datadogRum.addAction("menu_builder.table.clicked_publish_menu", { menuId: moreInfo.plannedMenu.id });
                setMoreInfo(null);
                navigate(getNavOrgKitchenMenuScheduleImport(moreInfo.plannedMenu.startDate, moreInfo.plannedMenu.id));
              }}
            >
              Publish
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                setMenuToDuplicate(moreInfo.plannedMenu);
                setMoreInfo(null);
              }}
            >
              Duplicate
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                setDeletablePlannedMenu(moreInfo.plannedMenu);
                setMoreInfo(null);
              }}
            >
              Delete
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

import { ApolloCache } from "@apollo/client";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import { NamedTagForEditFragment, useEditCustomTagMutation, useEditSmartTagMutation } from "../../../../../types";
import { buildInitialEditState, combinedTagStateReducer, useValidateCombinedStateForEdit } from "../../../reducers/combinedTagReducers";
import { buildSaveCustomTagInput } from "../../../reducers/customTagReducers";
import { buildSaveSmartTagInput } from "../../../reducers/smartTagReducers";
import BaseMutationContent from "../BaseContent/BaseMutationContent";

interface EditDialogContentProps {
  onClose: () => void;
  existingTag: NamedTagForEditFragment;
  resetTable: () => void;
}

const EditDialogContent = ({ onClose, existingTag, resetTable }: EditDialogContentProps) => {
  const initialState = buildInitialEditState(existingTag);
  const [state, dispatch] = useReducer(combinedTagStateReducer, initialState);
  const [canSaveTooltips, isTagNameUnique] = useValidateCombinedStateForEdit(state, existingTag.tagName.name);
  const { setMessage } = useSnackbar();

  const update = <T,>(cache: ApolloCache<T>, namedTag: NamedTagForEditFragment) => {
    const cacheId = cache.identify(namedTag);
    cache.modify({
      id: cacheId,
      fields: {
        tag: () => namedTag.tag,
        tagName: () => namedTag.tagName,
      },
    });
  };

  const onCompleted = (namedTag: NamedTagForEditFragment) => {
    setMessage("success", `Successfully edited Tag: ${namedTag.tagName.name}`);
    resetTable();
    onClose();
  };

  const onError = () => {
    setMessage("error", `Error Editing Tag: ${existingTag.tagName.name}`);
  };

  const [editCustomTag, { loading: loadingCustom }] = useEditCustomTagMutation({
    update: (cache, { data }) => {
      if (data) {
        const namedTagForEdit = data.editCustomTag;
        update(cache, namedTagForEdit);
      }
    },
    onCompleted: data => {
      const namedTagForEdit = data.editCustomTag;
      onCompleted(namedTagForEdit);
    },
    onError,
  });

  const [editSmartTag, { loading: loadingSmart }] = useEditSmartTagMutation({
    update: (cache, { data }) => {
      if (data) {
        const namedTagForEdit = data.editSmartTag;
        update(cache, namedTagForEdit);
      }
    },
    onCompleted: data => {
      const namedTagForEdit = data.editSmartTag;
      onCompleted(namedTagForEdit);
    },
    onError,
  });

  const loading = loadingCustom || loadingSmart;

  const handleSave = () => {
    if (loading) {
      return;
    }
    if (state.__typeName === "Custom") {
      const input = buildSaveCustomTagInput(state);
      editCustomTag({ variables: { input: { id: existingTag.tag.id, tagInput: input } } });
    } else {
      const input = buildSaveSmartTagInput(state);
      if (!input) {
        return;
      }
      editSmartTag({ variables: { input: { id: existingTag.tag.id, tagInput: input } } });
    }
  };

  return (
    <BaseMutationContent
      state={state}
      dispatch={dispatch}
      onClose={onClose}
      handleSave={handleSave}
      loading={loading}
      canSaveTooltips={canSaveTooltips}
      isTagNameUnique={isTagNameUnique}
      tagId={existingTag.tag.id}
    />
  );
};

export default EditDialogContent;

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { SnackbarVariant } from "apps/web/src/components/Snackbar/Snackbar";
import { OrgGroup, useMoveRecipeToOrgGroupMutation } from "apps/web/src/types";
import React, { useEffect, useState } from "react";
import { useRecipesPage } from "./useRecipesPage";

interface MoveRecipeToOrgGroupDialogProps {
  setSuccessMsg: (variant: SnackbarVariant, message: string) => void;
  onClose: () => void;
}

export function MoveRecipeToOrgGroupDialog({ onClose, setSuccessMsg }: MoveRecipeToOrgGroupDialogProps) {
  const { moveToOrgGroupRecipe, recipeSharingOrgGroups, resetTable, setMoveToOrgGroupRecipe } = useRecipesPage();
  const [orgGroup, setOrgGroup] = useState<Pick<OrgGroup, "name" | "id"> | null>(null);

  useEffect(() => {
    if (recipeSharingOrgGroups.length === 1) {
      setOrgGroup(recipeSharingOrgGroups[0]);
    }
  }, [recipeSharingOrgGroups, recipeSharingOrgGroups.length]);

  const [moveRecipeToOrgGroup] = useMoveRecipeToOrgGroupMutation({
    variables: {
      input: {
        id: moveToOrgGroupRecipe?.id ?? "",
        orgGroupId: orgGroup?.id ?? "",
      },
    },
    onCompleted: () => {
      resetTable();
      setMoveToOrgGroupRecipe(null);
      setSuccessMsg("success", `Moved ${moveToOrgGroupRecipe?.name ?? "the recipe"} to ${orgGroup?.name ?? "your"} Org Group Recipes`);
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      open={true}
      onClose={onClose}>
      <DialogTitle title="Move Recipe" onClose={onClose} />
      <DialogContent>
        Are you sure you want to move the recipe to Org Group Recipes? Doing so will allow other dietitians to view and duplicate it to
        their orgs.
        {recipeSharingOrgGroups.length > 1 && (
          <>
            <br />
            If so, please select the org group that you would like to move this recipe to.
            <Autocomplete
              onChange={(_, option) => setOrgGroup(option?.orgGroup ?? null)}
              options={recipeSharingOrgGroups.map(og => ({ label: og.name, orgGroup: og }))}
              renderInput={params => <TextField {...params} label="Org Group" />}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          aria-label="Cancel">
          Cancel
        </Button>
        <Button
          disabled={!orgGroup}
          onClick={() => moveRecipeToOrgGroup()}
          aria-label="Print">
          {recipeSharingOrgGroups.length > 1 ? "Continue" : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box, Button, IconButton, Typography } from "@mui/material";
import { AppleAppStoreIcon, GooglePlayStoreIcon } from "@notemeal/shared/ui/global/Icons";
import { appleAppStoreLink, googlePlayStoreLink } from "@notemeal/shared/ui/utils/links";
import React from "react";
import { useLogout } from "../../utils/tokens";

export const MobileWelcomePage = () => {
  const logout = useLogout();
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">Download Nutrition</Typography>
        <Typography variant="subtitle1">Download the Nutrition app to login</Typography>
      </Box>
      <Button onClick={logout}>Back to Login</Button>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton href={appleAppStoreLink}>
          <AppleAppStoreIcon />
        </IconButton>
        <IconButton href={googlePlayStoreLink}>
          <GooglePlayStoreIcon />
        </IconButton>
      </Box>
    </>
  );
};

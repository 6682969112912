import { Runtype, Static } from "runtypes";
import useLocalStorageState from "use-local-storage-state";

const Storage = localStorage;

type LocalStorageKey =
  | "orderKioskLoggingEnabled"
  | "nixAppId"
  | "nixAppKey"
  | "webSchemaVersion"
  | "notemealPrinterType"
  | "autoprintEnabled"
  | "printerName";

const defaultValues = {
  orderKioskLoggingEnabled: true,
  nixAppId: "",
  nixAppKey: "",
  webSchemaVersion: "0.0.0",
  notemealPrinterType: "",
  autoprintEnabled: false,
  printerName: "",
};

const parseJsonAsType = <T extends any>(jsonValue: string | null, runType: Runtype<T>): T | null => {
  if (jsonValue == null) {
    return null;
  }
  try {
    const parsedValue = JSON.parse(jsonValue);
    if (runType.guard(parsedValue)) {
      return parsedValue;
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const useLocalStorageValueAsType = <RT extends Runtype>(
  key: LocalStorageKey,
  runType: RT
): [Static<RT>, (value: Static<RT>) => void] => {
  const [localStorageValue, _setLocalStorageValue] = useLocalStorageState<any>(key, { defaultValue: defaultValues[key] });

  const setLocalStorageValue = (newValue: Static<RT>) => {
    _setLocalStorageValue(newValue);
    try {
      const stringifiedValue = JSON.stringify(newValue);
      Storage.setItem(key, stringifiedValue);
    } catch {}
  };

  return [localStorageValue, setLocalStorageValue];
};

// only when needed outside of function component (i.e. nixAppId)
export const setLocalStorageValue = <RT extends Runtype>(key: LocalStorageKey, value: Static<RT>) => {
  Storage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageValue = <RT extends Runtype>(key: LocalStorageKey, runType: RT): Static<RT> =>
  parseJsonAsType(Storage.getItem(key), runType) ?? defaultValues[key];

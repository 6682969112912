import { useState } from "react";

import { useTimelineForDatePayload } from "./useTimelineForDate";

import { FoodLogActivityTimelineFragment, TimelineMealFragment } from "../../../types";

interface MealsAndActivitiesForCreateMealPlan {
  meals: readonly TimelineMealFragment[];
  activities: readonly FoodLogActivityTimelineFragment[];
}

interface useCreateMealPlanModalHelperPayload {
  mealsAndActivities: MealsAndActivitiesForCreateMealPlan | null;
  loading: boolean;
  onChangeOpen: (open: boolean) => void;
}

export const useCreateMealPlanModalHelper = (datePayloads: (useTimelineForDatePayload | null)[]): useCreateMealPlanModalHelperPayload => {
  const [mealsAndActivities, setMealsAndActivities] = useState<MealsAndActivitiesForCreateMealPlan | null>(null);

  const onChangeOpen = (open: boolean) => {
    if (open) {
      setMealsAndActivities({
        meals: datePayloads.flatMap(
          p => p?.data?.athlete.timelineDate.meals.flatMap(m => (m.__typename === "TimelineMeal" ? m : [])) || []
        ),
        activities: datePayloads.flatMap(
          p => p?.data?.athlete.timelineDate.activities.flatMap(i => (i.__typename === "FoodLogActivity" ? i : [])) || []
        ),
      });
    } else {
      setMealsAndActivities(null);
    }
  };

  const loading = datePayloads.some(p => p?.loading);

  return {
    mealsAndActivities,
    loading,
    onChangeOpen,
  };
};

import {
  Macros,
  getMacroProtocolMacros as getMacroProtocolMacrosShared,
  maybeAthleteBirthDateToAge,
} from "@notemeal/shared/utils/macro-protocol";
import { AnthropometryFormField, FullMacroProtocolFragment, RmrMethod } from "../types";

export const getMacroProtocolMacros = (macroProtocol: FullMacroProtocolFragment, athleteBirthdate: string | null): Macros =>
  getMacroProtocolMacrosShared({
    ...macroProtocol,
    anthropometrySnapshot: { ...macroProtocol.anthropometrySnapshot, age: maybeAthleteBirthDateToAge(athleteBirthdate) },
  });

export const MINIMUM_AGE = 13;

export const invalidAge = (birthdate: string | null) => {
  const maybeAge = maybeAthleteBirthDateToAge(birthdate);
  return !maybeAge || maybeAge < MINIMUM_AGE;
};

export interface requiredAnthropometryFormFieldsForMacroProtocolProps {
  calorieBudget?: { rmrMethod: RmrMethod | null } | null;
}

export const requiredAnthropometryFormFieldsForMacroProtocol = (
  macroProtocol: requiredAnthropometryFormFieldsForMacroProtocolProps
): AnthropometryFormField[] => {
  if (!macroProtocol.calorieBudget?.rmrMethod) {
    return [];
  }
  switch (macroProtocol.calorieBudget.rmrMethod) {
    case "cunningham":
      return ["percentBodyFat", "weight"];
    case "harrisBenedict":
      return ["height", "weight"];
    case "mifflin":
      return ["height", "weight"];
    case "average":
      return ["percentBodyFat", "height", "weight"];
    case "%future added value":
      return [];
    default:
      return [];
  }
};

export const formatKcalOffset = (kcalOffset: number) => {
  if (kcalOffset > 0) {
    return `+${kcalOffset} kcal`;
  } else {
    return `${kcalOffset} kcal`;
  }
};

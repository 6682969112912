import { FoodDataSource } from "@notemeal/graphql/types";
import { GB_LOCALE_CODE, US_LOCALE_CODE } from "@notemeal/locale/utils";

const BASE_ALLOWLISTED_DATA_SOURCES = [
  FoodDataSource.bonAppetit,
  FoodDataSource.cbord,
  FoodDataSource.compass,
  FoodDataSource.manual,
  FoodDataSource.nutritionix,
  FoodDataSource.restaurant,
  FoodDataSource.sodexo,
];

const STAFF_FOOD_SOURCES = [FoodDataSource.manual, FoodDataSource.mccance, FoodDataSource.usda];

const SOURCES_FOR_LOCALE_MAP: Record<string, FoodDataSource[]> = {
  [US_LOCALE_CODE]: [...BASE_ALLOWLISTED_DATA_SOURCES, FoodDataSource.usda],
  [GB_LOCALE_CODE]: [...BASE_ALLOWLISTED_DATA_SOURCES, FoodDataSource.mccance],
};

export const getStaffFoodSources = () => {
  return STAFF_FOOD_SOURCES;
};

export const getStaffFoodSourcesForLocale = (localeCode: string) => {
  const localeSources = getFoodDataSourcesForLocale({ localeCode, isStaff: true });
  return getStaffFoodSources().filter(source => localeSources.includes(source));
};

export const getFoodDataSourcesForLocale = ({ localeCode, isStaff }: { localeCode: string; isStaff: boolean }): FoodDataSource[] => {
  const localeSources = SOURCES_FOR_LOCALE_MAP[localeCode];
  if (isStaff) {
    return [...BASE_ALLOWLISTED_DATA_SOURCES, FoodDataSource.mccance, FoodDataSource.usda].filter(source => localeSources.includes(source));
  } else {
    return localeSources;
  }
};

export const getFoodSearchFiltersForOrgLocale = (localeCode: string) => {
  return {
    localeFilter: [localeCode],
    dataSourceFilter: getFoodDataSourcesForLocale({ localeCode, isStaff: false }),
  };
};

export const getDefaultFoodSearchFiltersForOrgLocale = (isStaff: boolean, localeCode: string) => {
  if (isStaff) {
    return {
      localeFilter: [],
      dataSourceFilter: [],
    };
  }
  return getFoodSearchFiltersForOrgLocale(localeCode);
};

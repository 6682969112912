import { getLink } from "../../../NavUtils";

// full page path
export const getNavOrgAthlete = (athleteId: string) => `/org/athletes/${athleteId}`;

// local child paths
export const NAV_ATHLETE_SUMMARY = "summary";
export const NAV_ATHLETE_MEAL_PLANS = "meal-plans";
export const NAV_ATHLETE_GOALS = "goals";
export const NAV_ATHLETE_FOOD_LOGS = "food-logs";
export const NAV_ATHLETE_ANTHROPOMETRY = "anthropometry";
export const NAV_ATHLETE_NOTES = "notes";

// full child paths
export const getNavOrgAthleteSummary = (athleteId: string) => `${getNavOrgAthlete(athleteId)}/${NAV_ATHLETE_SUMMARY}`;
export const getNavOrgAthleteMealPlans = (athleteId: string) => `${getNavOrgAthlete(athleteId)}/${NAV_ATHLETE_MEAL_PLANS}`;
export const getNavOrgAthleteMealPlansMealPlan = (athleteId: string, mealPlanId: string) =>
  `${getNavOrgAthlete(athleteId)}/${NAV_ATHLETE_MEAL_PLANS}/${mealPlanId}`;
export const getNavOrgAthleteFoodLogs = (athleteId: string) => `${getNavOrgAthlete(athleteId)}/${NAV_ATHLETE_FOOD_LOGS}`;
export const getNavOrgAthleteAnthropometry = (athleteId: string) => `${getNavOrgAthlete(athleteId)}/${NAV_ATHLETE_ANTHROPOMETRY}`;

export const getNavOrgAthleteLink = (athleteId: string, athleteName: string) => getLink(athleteName, getNavOrgAthlete(athleteId));

export const getNavOrgAthleteGoalCompliance = (athleteId: string, goalConfigurationId: string) =>
  `${getNavOrgAthlete(athleteId)}/${NAV_ATHLETE_GOALS}/${goalConfigurationId}`;

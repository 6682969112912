import { TimelineMealForKioskFragment, TimelineMealInput, TimelineMealTemplateForKioskFragment } from "../../types";
import { v4 as uuidV4 } from "uuid";
import { uniq } from "lodash";

export const ORDER = "Order";
export const CHECK_IN = "Check-in"; // TODO: sunset
export const HUB_CHECK_IN = "Kiosk Check-in";
export type KioskMode = typeof ORDER | typeof HUB_CHECK_IN;

export interface KioskTeam<A extends KioskAthlete> {
  id: string;
  name: string;
  athletes: readonly A[];
}

export interface KioskAthlete {
  id: string;
  firstName: string;
  lastName: string;
  team: {
    id: string;
    name: string;
  };
}

export const getLettersForAthleteNavigation = (names: readonly string[]) => {
  const letters = names.map(name => name.substring(0, 1).toUpperCase());
  return uniq(letters);
};

export const getTimelineMealInputForKiosk = (
  matchingTimelineMealOrTemplate: TimelineMealForKioskFragment | TimelineMealTemplateForKioskFragment | undefined
): TimelineMealInput | null => {
  return matchingTimelineMealOrTemplate?.__typename === "TimelineMeal"
    ? {
        timelineMealId: matchingTimelineMealOrTemplate.id,
        addTimelineMeal: {
          name: matchingTimelineMealOrTemplate.name,
          start: matchingTimelineMealOrTemplate.start,
          timezone: matchingTimelineMealOrTemplate.timezone,
          durationInMinutes: matchingTimelineMealOrTemplate.durationInMinutes,
          type: matchingTimelineMealOrTemplate.type,
          mealTemplateId: matchingTimelineMealOrTemplate.mealTemplate?.id ?? null,
        },
      }
    : matchingTimelineMealOrTemplate?.__typename === "TimelineMealTemplate"
    ? {
        timelineMealId: uuidV4(),
        addTimelineMeal: {
          name: matchingTimelineMealOrTemplate.template.meal.name,
          start: matchingTimelineMealOrTemplate.start,
          timezone: matchingTimelineMealOrTemplate.timezone,
          durationInMinutes: matchingTimelineMealOrTemplate.durationInMinutes,
          type: matchingTimelineMealOrTemplate.template.meal.type,
          mealTemplateId: matchingTimelineMealOrTemplate.template.id,
        },
      }
    : null;
};

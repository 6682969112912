import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import TablePage from "../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../components/universal/TablePage/DefaultHeader";
import { AgreementFormFragment, StaffDashboardAgreementFormsQuery } from "../../../types";
import { PaginationHooks } from "../../../utils/pagination";

interface AgreementFormTableProps {
  offsetConnectionData?: StaffDashboardAgreementFormsQuery;
  loading: boolean;
  paginationHooks: PaginationHooks;
  onAddAgreementForm: () => void;
  onEditAgreementForm: (form: AgreementFormFragment) => void;
}

const AgreementFormTable = ({
  offsetConnectionData,
  loading,
  paginationHooks,
  onAddAgreementForm,
  onEditAgreementForm,
}: AgreementFormTableProps) => {
  const { queryText, onChangeQueryText } = paginationHooks;

  const header = (
    <TablePageDefaultHeader
      title="Agreement Form"
      queryText={queryText}
      onChangeQueryText={onChangeQueryText}
      onCreate={onAddAgreementForm}
    />
  );

  const tableBodyRows = offsetConnectionData
    ? offsetConnectionData.agreementFormOffsetConnection.edges.map(agreementForm => (
        <TableRow key={agreementForm.id}>
          <TableCell scope="row">
            <IconButton
              onClick={() =>
                onEditAgreementForm({
                  title: agreementForm.title,
                  url: agreementForm.url,
                  id: agreementForm.id,
                  acceptAgreementText: agreementForm.acceptAgreementText,
                })
              }
            >
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell scope="row">{agreementForm.title}</TableCell>
          <TableCell scope="row">{agreementForm.url}</TableCell>
        </TableRow>
      ))
    : null;

  const tableHeaderRow = (
    <TableRow>
      <TableCell>Edit</TableCell>
      <TableCell>Title</TableCell>
      <TableCell>Url</TableCell>
    </TableRow>
  );

  return (
    <TablePage
      header={header}
      tableHeaderRow={tableHeaderRow}
      tableBodyRows={tableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={offsetConnectionData?.agreementFormOffsetConnection.pageInfo.total || 0}
    />
  );
};

export default AgreementFormTable;

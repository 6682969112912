import { EditMacroState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import { macroMathsToMacroTargets } from "@notemeal/shared/utils/macro-protocol";
import { MealPlanTemplateMacroProtocolFragment } from "../../../../types";
import { RMR_METHOD_ORDER } from "../../../MacroProtocol/utils";
import { MealPlanTemplateGoalState } from "../Goals/goalsReducer";
import { getAnthroSnapshotFromAnthroState } from "../utils/macroProtocolUtils";
import { getDefaultAnthropometryFormFields } from "./anthropometryFormFieldsReducer";
import { AnthropometryAction, AnthropometryState, anthropometryReducer } from "./anthropometryReducer";
import { getDefaultAnthropometryTemplate } from "./anthropometryTemplateReducer";
import { CalorieBudgetAction, CalorieBudgetState, calorieBudgetReducer, getCalorieBudgetFromState } from "./calorieBudgetReducer";
import { EditMacrosAction, createMacroCalorieAction, macroReducer } from "./macroReducer";
import { parseAdditionalState } from "../../mealPlanTemplateUtils";

const defaultCho = {
  percentInput: "50",
  gPerKGInput: "0",
  percent: 50,
  gPerKg: 0,
  usesPercent: true,
};

const defaultPro = {
  percentInput: "25",
  gPerKGInput: "0",
  percent: 25,
  gPerKg: 0,
  usesPercent: true,
};

const defaultFat = {
  percentInput: "25",
  gPerKGInput: "0",
  percent: 25,
  gPerKg: 0,
  usesPercent: true,
};

export interface MacroProtocolState {
  cho: EditMacroState;
  pro: EditMacroState;
  fat: EditMacroState;

  useExistingAnthropometry: boolean;

  anthropometry: AnthropometryState;
  calorieBudget: CalorieBudgetState;
  usingCalorieBudget: boolean;
}

interface SetUsingCalorieBudget {
  type: "SET_USING_CALORIE_BUDGET";
  payload: boolean;
}

interface SwitchAnthropometryType {
  type: "SWITCH_ANTHRO_TYPE";
  payload: "template" | "formFields";
  isMetricLocale: boolean;
}

interface EditUseExistingAnthroAction {
  type: "EDIT_USE_EXISTING_ANTHRO";
  payload: boolean;
}

interface ResetMacrosToPercentages {
  type: "RESET_MACROS_TO_PERCENTAGES";
}

export type MacroProtocolAction =
  | CalorieBudgetAction
  | AnthropometryAction
  | EditMacrosAction
  | SetUsingCalorieBudget
  | SwitchAnthropometryType
  | EditUseExistingAnthroAction
  | ResetMacrosToPercentages;

const adjustMacros = (partialNewState: MacroProtocolState) => {
  const { anthropometry, calorieBudget } = partialNewState;
  const targetAnthroSnapshot = getAnthroSnapshotFromAnthroState(anthropometry);

  return {
    ...partialNewState,
    cho: macroReducer(partialNewState, createMacroCalorieAction("cho", calorieBudget), targetAnthroSnapshot),
    pro: macroReducer(partialNewState, createMacroCalorieAction("pro", calorieBudget), targetAnthroSnapshot),
    fat: macroReducer(partialNewState, createMacroCalorieAction("fat", calorieBudget), targetAnthroSnapshot),
  };
};

export const macroProtocolReducer = (state: MacroProtocolState, action: MacroProtocolAction): MacroProtocolState => {
  switch (action.type) {
    case "CHANGE_MACRO_PERCENT":
    case "CHANGE_MACRO_G_PER_KG":
      const macro = action.payload.macro;
      return {
        ...state,
        [macro]: macroReducer(state, action, getAnthroSnapshotFromAnthroState(state.anthropometry)),
      };
    case "EDIT_RMR_METHOD":
    case "EDIT_ACTIVITY_FACTOR":
    case "SET_SELECTED_PREVIEW_GOAL":
    case "OVERWRITE_GOALS": {
      const partialNewState = {
        ...state,
        calorieBudget: calorieBudgetReducer(state.calorieBudget, action),
      };
      return adjustMacros(partialNewState);
    }
    case "EDIT_FORM_FIELDS":
    case "EDIT_HEIGHT":
    case "EDIT_PERCENT_BODY_FAT":
    case "EDIT_WEIGHT":
    case "EDIT_SAMPLE_SEX":
    case "EDIT_SAMPLE_WEIGHT":
    case "EDIT_SAMPLE_HEIGHT":
    case "EDIT_SAMPLE_PERCENT_BODY_FAT":
    case "EDIT_SAMPLE_AGE": {
      const partialNewState = {
        ...state,
        anthropometry: anthropometryReducer(state.anthropometry, action),
      };
      return adjustMacros(partialNewState);
    }
    case "SET_USING_CALORIE_BUDGET":
      return {
        ...state,
        usingCalorieBudget: action.payload,
      };
    case "SWITCH_ANTHRO_TYPE":
      return {
        ...state,
        anthropometry:
          action.payload === "template"
            ? getDefaultAnthropometryTemplate(action.isMetricLocale)
            : getDefaultAnthropometryFormFields(action.isMetricLocale),
      };
    case "EDIT_USE_EXISTING_ANTHRO":
      return {
        ...state,
        useExistingAnthropometry: action.payload,
      };
    case "RESET_MACROS_TO_PERCENTAGES":
      const partialNewState = {
        ...state,
        cho: defaultCho,
        pro: defaultPro,
        fat: defaultFat,
      };
      return adjustMacros(partialNewState);
    default:
      return state;
  }
};

export const getBlankMacroProtocolState = (isMetricLocale: boolean): MacroProtocolState => ({
  cho: defaultCho,
  pro: defaultPro,
  fat: defaultFat,

  useExistingAnthropometry: true,

  anthropometry: getDefaultAnthropometryFormFields(isMetricLocale),
  calorieBudget: {
    rmrMethod: "mifflin",
    activityFactor: 1,
    activityFactorInput: "1",
    goals: [],
    selectedPreviewGoal: null,
  },
  usingCalorieBudget: true,
});

const getCalorieBudgetFromMacroProtocol = (macroProtocol: MealPlanTemplateMacroProtocolFragment): CalorieBudgetState => {
  const { calorieBudget } = macroProtocol;
  const activityFactor = calorieBudget?.activityFactor ?? 1;
  const goals: MealPlanTemplateGoalState[] =
    calorieBudget?.goals.map(goal => ({
      __typename: "MealPlanTemplateGoalState",
      id: goal.id,
      feId: goal.id,
      goalTypeId: goal.goalType.id,
      kcalOffset: goal.kcalOffset,
      kcalOffsetInput: goal.kcalOffset.toString(),
      name: goal.name,
    })) ?? [];
  return {
    rmrMethod: calorieBudget?.rmrMethod ?? RMR_METHOD_ORDER[0],
    activityFactor,
    activityFactorInput: activityFactor.toString(),
    goals,
    selectedPreviewGoal: goals.length > 0 ? goals[0] : null,
  };
};

const getAnthropometryFromMacroProtocol = (
  macroProtocol: MealPlanTemplateMacroProtocolFragment,
  isMetricLocale: boolean
): AnthropometryState => {
  const { anthropometry } = macroProtocol;
  if (anthropometry.__typename === "AnthropometryForm") {
    return {
      ...getDefaultAnthropometryFormFields(isMetricLocale),
      formFields: anthropometry.questions.map(({ field }) => field),
    };
  } else {
    // TODO: metric update - for the walk phase these can be assumed to be metric
    return {
      __typename: "Template",
      height: anthropometry.height,
      heightInput: anthropometry.height?.toString() ?? "",
      percentBodyFat: anthropometry.percentBodyFat,
      percentBodyFatInput: anthropometry.percentBodyFat?.toString() ?? "",
      weight: anthropometry.weight,
      weightInput: anthropometry.weight.toString(),
      sampleSex: "male",
      sampleAge: null,
      sampleAgeInput: "",
    };
  }
};

export const buildMacroProtocolState = (
  macroProtocol: MealPlanTemplateMacroProtocolFragment,
  additionalState: string | null,
  isMetricLocale: boolean
): MacroProtocolState => {
  const { cho, pro, fat, useExistingAnthropometry } = macroProtocol;

  // In additionalState we save the user's sample data edits and, if they've turned off calorie budget, we save a copy of that to restore
  // their edits (such as goals and selected goal) if they turn it back on.
  // TODO: this additional state needs to be converted into metric in BE I think we need to version this
  const additionalStateParsed = parseAdditionalState(additionalState);
  const calorieBudgetFromAdditionalState = additionalStateParsed?.calorieBudget;
  const anthropometryFromState = additionalStateParsed?.anthropometry;
  let calorieBudgetState = getCalorieBudgetFromMacroProtocol(macroProtocol);
  if (macroProtocol.calorieBudget === null && calorieBudgetFromAdditionalState) {
    calorieBudgetState = calorieBudgetFromAdditionalState;
  }
  const anthropometry = anthropometryFromState || getAnthropometryFromMacroProtocol(macroProtocol, isMetricLocale);

  const anthropometrySnapshot = getAnthroSnapshotFromAnthroState(anthropometry);
  const calorieBudget = getCalorieBudgetFromState(calorieBudgetState);
  const {
    cho: choMacroTarget,
    pro: proMacroTarget,
    fat: fatMacroTarget,
  } = macroMathsToMacroTargets({ cho, pro, fat }, calorieBudget, anthropometrySnapshot);

  const partialNewState = {
    useExistingAnthropometry,
    anthropometry,
    calorieBudget: calorieBudgetState,
    usingCalorieBudget: macroProtocol.calorieBudget !== null,
    cho: macroTargetStateToEditMacroState(choMacroTarget),
    pro: macroTargetStateToEditMacroState(proMacroTarget),
    fat: macroTargetStateToEditMacroState(fatMacroTarget),
  };

  const newMacroProtocolState = {
    ...partialNewState,
    cho: macroReducer(partialNewState, createMacroCalorieAction("cho", calorieBudgetState), anthropometrySnapshot),
    pro: macroReducer(partialNewState, createMacroCalorieAction("pro", calorieBudgetState), anthropometrySnapshot),
    fat: macroReducer(partialNewState, createMacroCalorieAction("fat", calorieBudgetState), anthropometrySnapshot),
  };

  return newMacroProtocolState;
};

interface MacroTargetState {
  percent: number;
  gPerKg: number;
  usesPercent: boolean;
}

const macroTargetStateToEditMacroState = (macro: MacroTargetState): EditMacroState => ({
  ...macro,
  percentInput: macro.percent.toString(),
  gPerKGInput: macro.gPerKg.toString(),
});

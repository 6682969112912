import { Box, Popover } from "@mui/material";
import React, { ReactNode } from "react";

export const MD_POPOVER_WIDTH = 250;
export const LG_POPOVER_WIDTH = 325;
export const XL_POPOVER_WIDTH = 470;

export const variantStyles = {
  md: {
    width: MD_POPOVER_WIDTH,
  },
  lg: {
    width: LG_POPOVER_WIDTH,
  },
  xl: {
    width: XL_POPOVER_WIDTH,
  },
};

export interface MealPlanCalendarEventPopoverBaseProps {
  children: ReactNode;
  anchorEl: HTMLElement;
  onClose: () => void;
  variant?: "md" | "lg" | "xl";
}

const MealPlanCalendarEventPopoverBase = ({ children, anchorEl, onClose, variant = "md" }: MealPlanCalendarEventPopoverBaseProps) => {
  const popoverWidth = variant === "md" ? MD_POPOVER_WIDTH : variant === "xl" ? XL_POPOVER_WIDTH : LG_POPOVER_WIDTH;

  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: popoverWidth + 10,
      }}
      PaperProps={{
        sx: variantStyles[variant],
        elevation: 24,
      }}
    >
      <Box sx={({ spacing }) => ({ margin: spacing(1, 2) })}>{children}</Box>
    </Popover>
  );
};

export default MealPlanCalendarEventPopoverBase;

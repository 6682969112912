/*
 ************************************************************************************
 * Copyright (C) 2019 Openbravo S.L.U.
 * Licensed under the Apache Software License version 2.0
 * You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to  in writing,  software  distributed
 * under the License is distributed  on  an  "AS IS"  BASIS,  WITHOUT  WARRANTIES  OR
 * CONDITIONS OF ANY KIND, either  express  or  implied.  See  the  License  for  the
 * specific language governing permissions and limitations under the License.
 ************************************************************************************
 */
import { useLocalStorageValueAsType } from "apps/web/src/utils/localStorage";

import { EPSONTMT88VI, STARSP700, BROTHERQL820NWB, STARSML300, EPSONTML90, EPSONTML100 } from "./printers";
import { String } from "runtypes";

export interface WebDevice {
  connected(): boolean;
  request(): Promise<void>;
  sendData(data: Uint8Array): Promise<void>;
}

export interface PrinterType {
  name: string;
  createWebDevice: () => WebDevice;
}

const getValue = (printerName: string) => {
  if (printerName === "") {
    return null;
  } else {
    switch (printerName) {
      case EPSONTMT88VI.name:
        return EPSONTMT88VI;
      case EPSONTML90.name:
        return EPSONTML90;
      case EPSONTML100.name:
        return EPSONTML100;
      case STARSP700.name:
        return STARSP700;
      case STARSML300.name:
        return STARSML300;
      case BROTHERQL820NWB.name:
        return BROTHERQL820NWB;
      default:
        alert(`"${printerName}" not supported`);
        return null;
    }
  }
};

export const useSavedPrinterType = () => {
  const [printerName, setPrinterName] = useLocalStorageValueAsType("printerName", String);

  return { value: getValue(printerName), setValue: setPrinterName };
};

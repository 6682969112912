import { ZodErrorMap, ZodIssue, ZodIssueCode, z } from "zod";
import { RmrCaloriesArgs } from "../types/RmrTypes";
import { RMR_MIN_AGE, RMR_MIN_HEIGHT, RMR_MIN_PERCENT_BODY_FAT } from "../Utils";

export const ERROR_WEIGHT_REQUIRED = "ERROR_WEIGHT_REQUIRED";
export const ERROR_HEIGHT_REQUIRED = "ERROR_HEIGHT_REQUIRED";
export const ERROR_AGE_REQUIRED = "ERROR_AGE_REQUIRED";
export const ERROR_SEX_REQUIRED = "ERROR_SEX_REQUIRED";
export const ERROR_CUNNINGHAM_REQUIRED = "ERROR_CUNNINGHAM_REQUIRED";
export const ERROR_WEIGHT_MINIMUM = "ERROR_WEIGHT_MINIMUM";
export const ERROR_HEIGHT_MINIMUM = "ERROR_HEIGHT_MINIMUM";
export const ERROR_LEAN_BODY_MASS_MINIMUM = "ERROR_LEAN_BODY_MASS_MINIMUM";
export const ERROR_PERCENT_BODY_FAT_MINIMUM = "ERROR_PERCENT_BODY_FAT_MINIMUM";
export const ERROR_AGE_MINIMUM = "ERROR_AGE_MINIMUM";

export const RmrWeightSchema = z.number();
export const RmrHeightSchema = z.number().min(RMR_MIN_HEIGHT, { message: ERROR_HEIGHT_MINIMUM });
export const RmrLeanBodyMassSchema = z.number().nullable().optional();
export const RmrPercentBodyFatSchema = z
  .number()
  .min(RMR_MIN_PERCENT_BODY_FAT, { message: ERROR_PERCENT_BODY_FAT_MINIMUM })
  .nullable()
  .optional();
export const RmrCunninghamWeightSchema = RmrWeightSchema.optional();
export const RmrAgeSchema = z.number().min(RMR_MIN_AGE, { message: ERROR_AGE_MINIMUM });
export const RmrSexTypeSchema = z.enum(["male", "female", "%future added value"]);

export const RmrCaloriesSchema = z
  .discriminatedUnion("rmrMethod", [
    z.object({
      rmrMethod: z.literal("cunningham"),
      leanBodyMass: RmrLeanBodyMassSchema,
      percentBodyFat: RmrPercentBodyFatSchema,
      weight: RmrCunninghamWeightSchema,
    }),
    z.object({
      rmrMethod: z.literal("mifflin"),
      weight: RmrWeightSchema,
      height: RmrHeightSchema,
      age: RmrAgeSchema,
      sex: RmrSexTypeSchema,
    }),
    z.object({
      rmrMethod: z.literal("harrisBenedict"),
      weight: RmrWeightSchema,
      height: RmrHeightSchema,
      age: RmrAgeSchema,
      sex: RmrSexTypeSchema,
    }),
    z.object({
      rmrMethod: z.literal("average"),
      leanBodyMass: RmrLeanBodyMassSchema,
      percentBodyFat: RmrPercentBodyFatSchema,
      weight: RmrCunninghamWeightSchema,
      height: RmrHeightSchema,
      age: RmrAgeSchema,
      sex: RmrSexTypeSchema,
    }),
  ])
  .refine(
    data =>
      (data.rmrMethod !== "cunningham" && data.rmrMethod !== "average") ||
      (data.leanBodyMass !== null && data.leanBodyMass !== undefined) ||
      (data.percentBodyFat && data.weight),
    { message: ERROR_CUNNINGHAM_REQUIRED }
  );

// Passed in as second param to .parse()
export const RmrCaloriesCustomErrorMap: ZodErrorMap = (issue, ctx) => {
  if (issue.code === ZodIssueCode.invalid_type) {
    switch (issue.path[0]) {
      case "weight":
        return { message: ERROR_WEIGHT_REQUIRED };
      case "height":
        return { message: ERROR_HEIGHT_REQUIRED };
      case "age":
        return { message: ERROR_AGE_REQUIRED };
      case "sex":
        return { message: ERROR_SEX_REQUIRED };
    }
  }
  return { message: ctx.defaultError };
};

export const DEFAULT_ERROR_CUNNINGHAM_REQUIRED = "Either lean body mass or both percent body fat and weight is required for Cunningham.";
export const DEFAULT_ERROR_WEIGHT_REQUIRED = "Missing the required field 'weight'.";
export const DEFAULT_ERROR_HEIGHT_REQUIRED = "Missing the required field 'height'.";
export const DEFAULT_ERROR_AGE_REQUIRED = "Missing the required field 'age'.";
export const DEFAULT_ERROR_SEX_REQUIRED = "Missing the required field 'sex'.";
export const DEFAULT_ERROR_WEIGHT_MINIMUM = "Weight is below the minimum."; //`Weight must be at least ${RMR_MIN_WEIGHT}.`;
export const DEFAULT_ERROR_LEAN_BODY_MASS_MINIMUM = "Lean Body Mass is below the minimum."; //`Lean Body Mass must be at least ${RMR_MIN_LEAN_BODY_MASS}.`;
export const DEFAULT_ERROR_HEIGHT_MINIMUM = "Height is below the minimum."; //`Height must be at least ${RMR_MIN_HEIGHT}.`;
export const DEFAULT_ERROR_AGE_MINIMUM = `Age must be at least ${RMR_MIN_AGE}.`;

export const rmrErrorLookup = new Map<string, string>([
  [ERROR_CUNNINGHAM_REQUIRED, DEFAULT_ERROR_CUNNINGHAM_REQUIRED],
  [ERROR_WEIGHT_REQUIRED, DEFAULT_ERROR_WEIGHT_REQUIRED],
  [ERROR_HEIGHT_REQUIRED, DEFAULT_ERROR_HEIGHT_REQUIRED],
  [ERROR_AGE_REQUIRED, DEFAULT_ERROR_AGE_REQUIRED],
  [ERROR_SEX_REQUIRED, DEFAULT_ERROR_SEX_REQUIRED],
  [ERROR_WEIGHT_MINIMUM, DEFAULT_ERROR_WEIGHT_MINIMUM],
  [ERROR_LEAN_BODY_MASS_MINIMUM, DEFAULT_ERROR_LEAN_BODY_MASS_MINIMUM],
  [ERROR_HEIGHT_MINIMUM, DEFAULT_ERROR_HEIGHT_MINIMUM],
  [ERROR_AGE_MINIMUM, DEFAULT_ERROR_AGE_MINIMUM],
]);

export const validateRmr = (rmrCaloriesArgs: RmrCaloriesArgs): ZodIssue[] => {
  const safeParseResult = RmrCaloriesSchema.safeParse(rmrCaloriesArgs, { errorMap: RmrCaloriesCustomErrorMap });
  return safeParseResult.success ? [] : safeParseResult.error.issues;
};

export const hasRmrErrors = (rmrCaloriesArgs: RmrCaloriesArgs): boolean => validateRmr(rmrCaloriesArgs).length > 0;

import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

interface MenuItemAvailableForOrderInputProps {
  variant: "MenuItem" | "MenuItemAppearance" | "RestaurantLinkMenuItemAppearance";
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const MenuItemAvailableForOrderInput = ({ variant, value, onChange, disabled }: MenuItemAvailableForOrderInputProps) => {
  return (
    <FormControlLabel
      label={variant === "MenuItem" ? "Available for Order (Default)" : "Available for Order"}
      disabled={variant === "RestaurantLinkMenuItemAppearance"}
      control={<Checkbox
        size="small"
        checked={value}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled} />}
    />
  );
};

export default MenuItemAvailableForOrderInput;

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import React from "react";

interface AiAugmentedRecipeNameProps {
  name: string;
  isAiGenerated?: boolean;
  isAiTranscribed?: boolean;
  sx?: SxProps;
}

export const AiAugmentedRecipeName = ({ name, isAiGenerated, isAiTranscribed, sx }: AiAugmentedRecipeNameProps) => {
  return (
    <>
      <Tooltip
        title={
          <>
            <strong>{name}</strong>
            <br />
            <span>This recipe was {isAiGenerated ? "built" : "transcribed"} using AI!</span>
          </>
        }
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Typography lineHeight="18px" sx={sx}>
            {name}
          </Typography>
          {isAiGenerated && <AutoAwesomeIcon sx={{ fontSize: "16px", m: 0.5 }} />}
          {isAiTranscribed && <AutoStoriesIcon sx={{ fontSize: "16px", m: 0.5 }} />}
        </Box>
      </Tooltip>
    </>
  );
};

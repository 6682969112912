import {
  CreateSmartTagInput,
  NamedTagForEditFragment,
  NamedTagForSelectionFragment,
  PositionWitSportFragment,
  TagConditionType,
  TeamPreviewFragment,
} from "../../../types";
import { SmartTagCombinedState } from "./combinedTagReducers";

interface PositionTagState {
  type: "Position";
  positions: readonly PositionWitSportFragment[];
}

interface TeamTagState {
  type: "Team";
  teams: readonly TeamPreviewFragment[];
}

interface ComboTagState {
  type: "Tag";
  tags: readonly NamedTagForSelectionFragment[];
}

interface EmptyTagState {
  type: "Empty";
}

export type SmartTagState = PositionTagState | TeamTagState | ComboTagState | EmptyTagState;
export type SmartTagStateType = SmartTagState["type"];

interface EditPositionsAction {
  type: "EDIT_POSITIONS_ACTION";
  payload: readonly PositionWitSportFragment[];
}

interface EditTeamsAction {
  type: "EDIT_TEAMS_ACTION";
  payload: readonly TeamPreviewFragment[];
}

interface EditTagsAction {
  type: "EDIT_TAGS_ACTION";
  payload: readonly NamedTagForSelectionFragment[];
}

interface SwitchTypeAction {
  type: "SWITCH_TYPE_ACTION";
  payload: SmartTagStateType;
}

export type SmartTagAction = EditPositionsAction | EditTeamsAction | EditTagsAction | SwitchTypeAction;

export const smartTagReducer = (state: SmartTagState, action: SmartTagAction): SmartTagState => {
  switch (action.type) {
    case "SWITCH_TYPE_ACTION": {
      if (action.payload === "Position") {
        return {
          type: "Position",
          positions: [],
        };
      } else if (action.payload === "Tag") {
        return {
          type: "Tag",
          tags: [],
        };
      } else if (action.payload === "Team") {
        return {
          type: "Team",
          teams: [],
        };
      } else if (action.payload === "Empty") {
        return {
          type: "Empty",
        };
      }
      return state;
    }
    case "EDIT_POSITIONS_ACTION":
      if (state.type === "Position") {
        return {
          ...state,
          positions: action.payload,
        };
      }
      return state;
    case "EDIT_TEAMS_ACTION":
      if (state.type === "Team") {
        return {
          ...state,
          teams: action.payload,
        };
      }
      return state;
    case "EDIT_TAGS_ACTION":
      if (state.type === "Tag") {
        return {
          ...state,
          tags: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
};

export const isEmptySmartTagState = (state: SmartTagState | null): boolean => {
  if (state === null || state.type === "Empty") {
    return true;
  } else if (state.type === "Position") {
    return state.positions.length === 0;
  } else if (state.type === "Tag") {
    return state.tags.length === 0;
  } else if (state.type === "Team") {
    return state.teams.length === 0;
  }
  return false;
};

export const createInitialSmartTagState = (): SmartTagState => ({
  type: "Empty",
});

export const buildInitialSmartTagEditState = ({ tag, tagName }: NamedTagForEditFragment): SmartTagState => {
  if (tag.__typename === "PositionTag") {
    return {
      ...tagName,
      type: "Position",
      positions: tag.positions,
    };
  } else if (tag.__typename === "ComboTag") {
    return {
      ...tagName,
      type: "Tag",
      tags: tag.namedChildTags,
    };
  } else if (tag.__typename === "TeamTag") {
    return {
      ...tagName,
      type: "Team",
      teams: tag.teams,
    };
  }
  return createInitialSmartTagState();
};

export const useValidateSmartTagStateForSave = (state: SmartTagState | null): string[] => {
  const errors = [];
  if (state === null) {
    return [];
  }
  if (state.type === "Empty") {
    errors.push("You must select an Attribute to create a smart tag");
  } else if (state.type === "Position" && state.positions.length === 0) {
    errors.push("You must select at least one Position");
  } else if (state.type === "Tag" && state.tags.length === 0) {
    errors.push("You must select at least one Tag");
  } else if (state.type === "Team" && state.teams.length === 0) {
    errors.push("You must select at least one Team");
  }
  return errors;
};

export const buildSaveSmartTagInput = ({
  name,
  description,
  isPrintable,
  smartState: state,
}: SmartTagCombinedState): CreateSmartTagInput | null => {
  let conditionType: TagConditionType;
  let entityIds: string[];

  if (state.type === "Position") {
    conditionType = "position";
    entityIds = state.positions.map(({ id }) => id);
  } else if (state.type === "Team") {
    conditionType = "team";
    entityIds = state.teams.map(({ id }) => id);
  } else if (state.type === "Tag") {
    conditionType = "tag";
    entityIds = state.tags.map(({ tag }) => tag.id);
  } else {
    return null;
  }

  return {
    name,
    description,
    condition: {
      isComplement: false,
      type: conditionType,
      entityIds,
    },
    isPrintable,
  };
};

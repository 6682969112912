import { Box, TextField } from "@mui/material";
import { sortExchanges } from "@notemeal/shared/ui/Exchange/utils";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import React, { memo } from "react";
import { FullExchangeSetFragment, useChangeExchangeTargetMutation } from "../../../../../types";
import { ExchangeTargetsById } from "../../../../MealPlan/Edit/Exchange/utils";

interface ExchangeAmountsProps {
  mealTemplateId: string;
  exchangeTargetsById: ExchangeTargetsById;
  exchangeSet: FullExchangeSetFragment;
}

export const ExchangeAmounts = memo(({ mealTemplateId, exchangeTargetsById, exchangeSet }: ExchangeAmountsProps) => {
  const exchanges = exchangeSet.exchanges;
  const [changeExchangeTarget] = useChangeExchangeTargetMutation();

  return (
    <Box sx={{ flex: 1, display: "flex", gap: 2, alignItems: "center" }}>
      {sortExchanges(exchanges).map(exchange => {
        const { amount, amountInput, pickListServingIds } = exchangeTargetsById[exchange.id];
        return (
          <TextField
            sx={{ mt: 0, flex: 1 }}
            key={exchange.id}
            type="number"
            inputProps={{ min: 0, step: 0.5, style: { textAlign: "center" } }}
            value={amountInput}
            error={inputToNumber(amountInput) !== amount}
            label=""
            onChange={e =>
              changeExchangeTarget({
                variables: {
                  exchangeId: exchange.id,
                  target: e.target.value,
                  pickListServingIds,
                  mealTemplateId,
                },
              })
            }
          />
        );
      })}
    </Box>
  );
});

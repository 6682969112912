import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import classNames from "classnames";
import React from "react";
import LoadingButton from "../../../../../components/universal/LoadingButton";

interface ConfirmDialogProps {
  isOpen: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
  confirmDisabled?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: boolean;
  classes?: {
    root?: string;
    header?: string;
    content?: string;
    actions?: string;
  };
}

const ConfirmDialog = ({
  isOpen,
  title,
  content,
  onCancel,
  onConfirm,
  loading,
  confirmDisabled,
  maxWidth = "sm",
  fullWidth = false,
  classes,
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={() => onCancel()}
      classes={{ paper: classNames(classes?.root) }}
    >
      <DialogTitle className={classNames(classes?.header)}>{title}</DialogTitle>
      <DialogContent className={classNames(classes?.content)}>{content}</DialogContent>
      <DialogActions className={classNames(classes?.actions)}>
        <Button
          variant="outlined"
          onClick={() => onCancel()}
          disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={() => onConfirm()}
          disabled={loading || confirmDisabled}
          loading={loading}
          buttonText="Confirm" />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

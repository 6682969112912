import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";

interface InstructionsModalProps {
  open: boolean;
  onDone: (deliveryInstructions: string) => void;
  onClose: () => void;
}

const InstructionsModal = ({ open, onDone, onClose }: InstructionsModalProps) => {
  const [instructions, setInstructions] = useState<string>("");
  const handleDone = () => {
    onDone(instructions);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Instructions" onClose={onClose} />
      <DialogContent>
        <TextField
          placeholder="Instructions entered here will be included on the first sheet of the export."
          fullWidth
          multiline
          rows={4}
          value={instructions}
          onChange={e => setInstructions(e.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleDone}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstructionsModal;

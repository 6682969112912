import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import DiningStationReadonlyForm from "../DiningStation/ReadonlyForm";
import { MenuDialogAction } from "../reducer";
import { StandaloneMenuDialogState } from "../types";
import QuickActions from "./QuickActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      paddingBottom: theme.spacing(2),
    },
    header: {
      padding: theme.spacing(2, 1),
    },
    title: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
  })
);

interface MealMenuDiningStationReadonlyGroupFormProps {
  mealMenu: StandaloneMenuDialogState;
  dispatch?: (action: MenuDialogAction) => void;
}

const MealMenuDiningStationReadonlyGroupForm = ({ mealMenu, dispatch }: MealMenuDiningStationReadonlyGroupFormProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography
          className={classes.title}
          variant="h3"
          color="textSecondary">
          Dining Stations
        </Typography>
        {dispatch && (
          <QuickActions
            mealMenuDiningStations={mealMenu.mealMenuDiningStations}
            userOrderLimit={mealMenu.userOrderLimit}
            dispatch={dispatch}
          />
        )}
      </div>
      {sortByKey(mealMenu.mealMenuDiningStations, "position").map(mmds => (
        <div key={mmds.id} className={classes.form}>
          <DiningStationReadonlyForm mealMenuDiningStation={mmds} orderItemCounts={mealMenu.orderItemCounts} />
        </div>
      ))}
    </>
  );
};

export default MealMenuDiningStationReadonlyGroupForm;

import React from "react";
import { useTerritoriesQuery } from "../../types";
import LabeledSelectNoId from "../universal/LabeledSelectNoId";

interface TerritorySelectProps {
  value: string | null;
  onChange: (value: string | null) => void;
  allowNone?: boolean;
}

export const TerritorySelect = ({ value, onChange, allowNone = false }: TerritorySelectProps) => {
  const { data } = useTerritoriesQuery({ fetchPolicy: "network-only" });

  const territories = [...(data?.territories.map(territory => territory.name) ?? []), ...(allowNone ? ["None"] : [])];

  return (
    <LabeledSelectNoId
      sx={{ minWidth: 200 }}
      selectedOption={value ?? "None"}
      placeholder="Select locale"
      label="Territory"
      options={territories}
      optionToName={(name: string) => name}
      onChange={t => onChange(t === "None" && allowNone ? null : t)}
    />
  );
};

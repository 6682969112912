import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { AthleteForCustomTagFragment } from "../../../../../../types";
import { AthleteItemContent } from "../../BaseContent/AthleteListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: theme.spacing(2),
    },
    athleteContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  })
);
interface SelectedAthleteRowProps {
  athlete: AthleteForCustomTagFragment;
  onClick: (athlete: AthleteForCustomTagFragment) => void;
}

const SelectedAthleteRow = ({ athlete, onClick }: SelectedAthleteRowProps) => {
  const classes = useStyles();
  return (
    <div className={classes.listItem} key={athlete.id}>
      <div className={classes.athleteContent}>
        <AthleteItemContent athlete={athlete} />
      </div>
      <IconButton onClick={() => onClick(athlete)} size="large">
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default React.memo(SelectedAthleteRow);

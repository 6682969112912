export const MACROS_PROGRESS_LARGE_WIDTH = 286;
export const MACROS_PROGRESS_MEDIUM_WIDTH = 196;
export const MACROS_PROGRESS_SMALL_WIDTH = 151;

export const MACROS_PROGRESS_LARGE_HEIGHT = 142;
export const MACROS_PROGRESS_MEDIUM_HEIGHT = 110;
export const MACROS_PROGRESS_SMALL_HEIGHT = 90;

export const getCaption = (current: number, target: number, unit: string, hideNumbers?: boolean, excludeTarget?: boolean) => {
  const value = Math.round((100 * current) / target);
  return hideNumbers
    ? isFinite(value)
      ? `${value}%`
      : `N/A%`
    : excludeTarget
    ? `${Math.round(current)}${unit}`
    : `${Math.round(current)}${unit}/${Math.round(target)}${unit}`;
};

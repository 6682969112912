import {
  AdvancedSelectionConditionFragment,
  AdvancedSelectionGroupFragment,
  AdvancedSelectionFragment,
  AdvancedSelectionInput,
  AthleteForCustomTagFragment,
  NamedTagForSelectionFragment,
  PositionWitSportFragment,
  TeamPreviewFragment,
} from "../../../types";
import { v4 as uuid } from "uuid";

interface BaseAdvancedSelectionCondition {
  id: string; // This is a FE used value
  isComplement: boolean | null;
}

export interface PositionCondition extends BaseAdvancedSelectionCondition {
  __typename: "Positions";
  positions: readonly PositionWitSportFragment[];
}

export interface TeamCondition extends BaseAdvancedSelectionCondition {
  __typename: "Teams";
  teams: readonly TeamPreviewFragment[];
}

export interface TagCondition extends BaseAdvancedSelectionCondition {
  __typename: "Tags";
  tags: readonly NamedTagForSelectionFragment[];
}

interface EmptyCondition extends BaseAdvancedSelectionCondition {
  __typename: "Empty";
}

export type AdvancedSelectionCondition = PositionCondition | TeamCondition | TagCondition | EmptyCondition;

export type AdvancedSelectionConditionType = AdvancedSelectionCondition["__typename"];

export interface AdvancedSelectionGroup {
  id: string; // This is a FE used value
  conditions: AdvancedSelectionCondition[];
}

export interface AdvancedSelectionState {
  id: string;
  includeAthletes: readonly AthleteForCustomTagFragment[];
  excludeAthletes: readonly AthleteForCustomTagFragment[];
  groups: AdvancedSelectionGroup[];
}

export type IsComplementString = "Include" | "Exclude";

interface UpdateIncludeAthleteAction {
  type: "UPDATE_INCLUDE_ATHLETES";
  payload: AthleteForCustomTagFragment[];
}
export interface AddExcludeAthleteAction {
  type: "ADD_EXCLUDE_ATHLETE";
  payload: AthleteForCustomTagFragment;
}

interface UpdateExcludeAthleteAction {
  type: "UPDATE_EXCLUDE_ATHLETES";
  payload: AthleteForCustomTagFragment[];
}

interface AddGroupAction {
  type: "ADD_GROUP";
}

export interface RemoveGroupAction {
  type: "REMOVE_GROUP";
  payload: { groupId: string };
}

interface AddConditionAction {
  type: "ADD_CONDITION";
  payload: { groupId: string };
}

export interface RemoveConditionAction {
  type: "REMOVE_CONDITION";
  payload: { groupId: string; conditionId: string };
}

interface EditIsComplementAction {
  type: "EDIT_IS_COMPLEMENT";
  payload: { groupId: string; conditionId: string; isComplementString: IsComplementString };
}

interface EditConditionTypeAction {
  type: "EDIT_CONDITION_TYPE";
  payload: { groupId: string; conditionId: string; __typename: AdvancedSelectionConditionType };
}

interface EditConditionPositionsAction {
  type: "EDIT_POSITIONS_ACTION";
  payload: { groupId: string; conditionId: string; positions: readonly PositionWitSportFragment[] };
}

interface EditConditionTeamsAction {
  type: "EDIT_TEAMS_ACTION";
  payload: { groupId: string; conditionId: string; teams: readonly TeamPreviewFragment[] };
}

interface EditConditionTagsAction {
  type: "EDIT_TAGS_ACTION";
  payload: { groupId: string; conditionId: string; tags: readonly NamedTagForSelectionFragment[] };
}

export type EditConditionAction =
  | EditIsComplementAction
  | EditConditionTypeAction
  | EditConditionPositionsAction
  | EditConditionTeamsAction
  | EditConditionTagsAction;

export type EditGroupAction = AddConditionAction | RemoveConditionAction | EditConditionAction;

export type SelectAthleteAction = UpdateIncludeAthleteAction | UpdateExcludeAthleteAction;

export type AdvancedSelectionAction = SelectAthleteAction | AddGroupAction | RemoveGroupAction | EditGroupAction | AddExcludeAthleteAction;

const assignConditionReducer = (state: AdvancedSelectionCondition, action: EditConditionAction): AdvancedSelectionCondition => {
  switch (action.type) {
    case "EDIT_IS_COMPLEMENT":
      return {
        ...state,
        isComplement: action.payload.isComplementString === "Exclude",
      };
    case "EDIT_CONDITION_TYPE": {
      const { id, isComplement } = state;
      const baseState = { id, isComplement };
      if (action.payload.__typename === "Positions") {
        return {
          ...baseState,
          __typename: "Positions",
          positions: [],
        };
      } else if (action.payload.__typename === "Tags") {
        return {
          ...baseState,
          __typename: "Tags",
          tags: [],
        };
      } else if (action.payload.__typename === "Teams") {
        return {
          ...baseState,
          __typename: "Teams",
          teams: [],
        };
      }
      return {
        ...baseState,
        __typename: "Empty",
      };
    }
    case "EDIT_POSITIONS_ACTION":
      if (state.__typename === "Positions") {
        return {
          ...state,
          positions: action.payload.positions,
        };
      }
      return state;
    case "EDIT_TEAMS_ACTION":
      if (state.__typename === "Teams") {
        return {
          ...state,
          teams: action.payload.teams,
        };
      }
      return state;
    case "EDIT_TAGS_ACTION":
      if (state.__typename === "Tags") {
        return {
          ...state,
          tags: action.payload.tags,
        };
      }
      return state;

    default:
      return state;
  }
};

const assignGroupReducer = (state: AdvancedSelectionGroup, action: EditGroupAction): AdvancedSelectionGroup => {
  switch (action.type) {
    case "ADD_CONDITION":
      return { ...state, conditions: [...state.conditions, buildEmptyCondition()] };
    case "REMOVE_CONDITION":
      return {
        ...state,
        conditions: state.conditions.filter(condition => condition.id !== action.payload.conditionId),
      };
    case "EDIT_IS_COMPLEMENT":
    case "EDIT_CONDITION_TYPE":
    case "EDIT_POSITIONS_ACTION":
    case "EDIT_TEAMS_ACTION":
    case "EDIT_TAGS_ACTION":
      return {
        ...state,
        conditions: state.conditions.map(condition =>
          condition.id === action.payload.conditionId ? assignConditionReducer(condition, action) : condition
        ),
      };
    default:
      return state;
  }
};

export const assignReducer = (state: AdvancedSelectionState, action: AdvancedSelectionAction): AdvancedSelectionState => {
  switch (action.type) {
    case "UPDATE_INCLUDE_ATHLETES":
      return { ...state, includeAthletes: action.payload };
    case "UPDATE_EXCLUDE_ATHLETES":
      return { ...state, excludeAthletes: action.payload };
    case "ADD_EXCLUDE_ATHLETE":
      return {
        ...state,
        excludeAthletes: [...state.excludeAthletes, action.payload],
        includeAthletes: state.includeAthletes.filter(athlete => athlete.id !== action.payload.id),
      };
    case "ADD_GROUP":
      return { ...state, groups: [...state.groups, buildEmptyGroup()] };
    case "REMOVE_GROUP":
      return {
        ...state,
        groups: state.groups.filter(group => group.id !== action.payload.groupId),
      };
    case "ADD_CONDITION":
    case "REMOVE_CONDITION":
    case "EDIT_IS_COMPLEMENT":
    case "EDIT_CONDITION_TYPE":
    case "EDIT_POSITIONS_ACTION":
    case "EDIT_TEAMS_ACTION":
    case "EDIT_TAGS_ACTION":
      return {
        ...state,
        groups: state.groups.map(group => (group.id === action.payload.groupId ? assignGroupReducer(group, action) : group)),
      };
    default:
      return state;
  }
};

const buildEmptyCondition = (): AdvancedSelectionCondition => ({
  id: uuid(),
  __typename: "Empty",
  isComplement: null,
});

const buildEmptyGroup = (): AdvancedSelectionGroup => ({
  id: uuid(),
  conditions: [buildEmptyCondition()],
});

export const buildInitialCreateAdvancedSelectionState = (): AdvancedSelectionState => ({
  id: uuid(),
  includeAthletes: [],
  excludeAthletes: [],
  groups: [buildEmptyGroup()],
});

const buildConditionFromTeams = (teams: readonly TeamPreviewFragment[]): AdvancedSelectionCondition => ({
  id: uuid(),
  __typename: "Teams",
  isComplement: false,
  teams,
});

const buildGroupWithTeams = (teams: readonly TeamPreviewFragment[]): AdvancedSelectionGroup => ({
  id: uuid(),
  conditions: [buildConditionFromTeams(teams)],
});

export const buildInitialCreateAdvancedSelectionStateFromTeams = (teams: readonly TeamPreviewFragment[]): AdvancedSelectionState => ({
  id: uuid(),
  includeAthletes: [],
  excludeAthletes: [],
  groups: [buildGroupWithTeams(teams)],
});

const buildConditionFromExistingAdvancedSelection = (existingCondition: AdvancedSelectionConditionFragment): AdvancedSelectionCondition => {
  switch (existingCondition.__typename) {
    case "PositionTag":
      return {
        ...existingCondition,
        __typename: "Positions",
      };
    case "TeamTag":
      return {
        ...existingCondition,
        __typename: "Teams",
      };

    case "ComboTag":
      return {
        ...existingCondition,
        __typename: "Tags",
        tags: existingCondition.namedChildTags,
      };
    default:
      return buildEmptyCondition();
  }
};

const buildGroupFromExistingAdvancedSelection = (existingGroup: AdvancedSelectionGroupFragment): AdvancedSelectionGroup => ({
  id: uuid(),
  conditions: existingGroup.conditions.map(buildConditionFromExistingAdvancedSelection),
});

export const buildInitialEditAdvancedSelectionState = (existingAdvancedSelection: AdvancedSelectionFragment): AdvancedSelectionState => ({
  id: existingAdvancedSelection.id,
  includeAthletes: existingAdvancedSelection.includeAthletes,
  excludeAthletes: existingAdvancedSelection.excludeAthletes,
  groups: existingAdvancedSelection.groups.map(buildGroupFromExistingAdvancedSelection),
});

export const buildInitialState = (existingAdvancedSelection?: AdvancedSelectionFragment): AdvancedSelectionState =>
  existingAdvancedSelection
    ? buildInitialEditAdvancedSelectionState(existingAdvancedSelection)
    : buildInitialCreateAdvancedSelectionState();

const validateConditionCanSaveTooltips = (condition: AdvancedSelectionCondition): string[] => {
  const errors: string[] = [];
  if (condition.isComplement === null) {
    errors.push("All Conditions must have either Include or Exclude selected");
  }
  if (condition.__typename === "Empty") {
    errors.push("All Conditions must have an Attribute type");
  } else if (condition.__typename === "Positions" && condition.positions.length === 0) {
    errors.push("All Position Conditions must have at least one position selected");
  } else if (condition.__typename === "Teams" && condition.teams.length === 0) {
    errors.push("All Team Conditions must have at least one team selected");
  } else if (condition.__typename === "Tags" && condition.tags.length === 0) {
    errors.push("All Tag Conditions must have at least one tag selected");
  }
  return errors;
};

interface DuplicateObj {
  id: string;
  name: string;
}

const detectDuplicateErrors = (objList: DuplicateObj[], getErrorFromName: (name: string) => string): string[] => {
  const duplicateNames: Set<string> = new Set();
  const seenIds: Set<string> = new Set();

  objList.forEach(({ id, name }) => {
    if (seenIds.has(id)) {
      duplicateNames.add(name);
    } else {
      seenIds.add(id);
    }
  });

  return [...duplicateNames].map(getErrorFromName);
};

const detectDuplicatePositions = (positions: PositionWitSportFragment[]): string[] => {
  return detectDuplicateErrors(positions, name => `Duplicate Position: ${name} found in the same group`);
};
const detectDuplicateTeams = (teams: TeamPreviewFragment[]): string[] => {
  return detectDuplicateErrors(teams, name => `Duplicate Team: ${name} found in the same group`);
};

const detectDuplicateTags = (tags: DuplicateObj[]): string[] => {
  return detectDuplicateErrors(tags, name => `Duplicate Tag: ${name} found in the same group`);
};

const validateGroupCanSaveTooltips = (group: AdvancedSelectionGroup): string[] => {
  if (group.conditions.length === 0) {
    return ["Groups must have at least on condition"];
  }

  const conditionErrors = group.conditions.flatMap(validateConditionCanSaveTooltips);

  const duplicatePositionErrors = detectDuplicatePositions(
    group.conditions.flatMap(condition => (condition.__typename === "Positions" ? condition.positions : []))
  );

  const duplicateTeamErrors = detectDuplicateTeams(
    group.conditions.flatMap(condition => (condition.__typename === "Teams" ? condition.teams : []))
  );

  const duplicateTagsErrors = detectDuplicateTags(
    group.conditions.flatMap(condition =>
      condition.__typename === "Tags" ? condition.tags.map(({ tag, tagName }) => ({ id: tag.id, name: tagName.name })) : []
    )
  );

  return [...conditionErrors, ...duplicatePositionErrors, ...duplicateTeamErrors, ...duplicateTagsErrors];
};

export const validateCanSaveTooltips = ({ groups, includeAthletes, excludeAthletes }: AdvancedSelectionState): string[] => {
  const groupErrors = groups.flatMap(validateGroupCanSaveTooltips);
  const stateErrors: string[] = [];

  const athleteErrors = detectDuplicateErrors(
    [...includeAthletes, ...excludeAthletes].map(athlete => ({ id: athlete.id, name: `${athlete.lastName}, ${athlete.firstName}` })),
    name => `Duplicate Athlete: ${name} in Include/Exclude athletes`
  );
  return [...stateErrors, ...athleteErrors, ...groupErrors];
};

export const isAdvancedSelectionEmpty = ({ groups, includeAthletes, excludeAthletes }: AdvancedSelectionState): boolean => {
  if (groups.length === 0 && includeAthletes.length === 0 && excludeAthletes.length === 0) {
    return true;
  }
  return false;
};

export const buildAdvancedSelectionInput = (state: AdvancedSelectionState): AdvancedSelectionInput => {
  return {
    excludeAthleteIds: state.excludeAthletes.map(({ id }) => id),
    includeAthleteIds: state.includeAthletes.map(({ id }) => id),
    groups: state.groups.map(group => ({
      conditions: group.conditions.flatMap(condition => {
        if (condition.isComplement === null || condition.__typename === "Empty") {
          return [];
        }
        if (condition.__typename === "Positions") {
          return {
            isComplement: condition.isComplement,
            type: "position",
            entityIds: condition.positions.map(({ id }) => id),
          };
        } else if (condition.__typename === "Teams") {
          return {
            isComplement: condition.isComplement,
            type: "team",
            entityIds: condition.teams.map(({ id }) => id),
          };
        }
        if (condition.__typename === "Tags") {
          return {
            isComplement: condition.isComplement,
            type: "tag",
            entityIds: condition.tags.map(namedTag => namedTag.tag.id),
          };
        }
        return [];
      }),
    })),
  };
};

export const isConditionEmpty = (condition: AdvancedSelectionCondition): boolean => {
  if (condition.__typename === "Empty") {
    return true;
  } else if (condition.__typename === "Positions") {
    return condition.positions.length === 0;
  } else if (condition.__typename === "Teams") {
    return condition.teams.length === 0;
  } else if (condition.__typename === "Tags") {
    return condition.tags.length === 0;
  }
  return true;
};

export const isGroupEmpty = (group: AdvancedSelectionGroup): boolean =>
  group.conditions.length === 0 || group.conditions.every(isConditionEmpty);

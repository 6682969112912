import { Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemText, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getMacroProtocolMacros } from "@notemeal/shared/ui/MacroProtocol/utils";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { PreviewMealPlanFragment } from "../../../../types";
import { useMacroProgressViews } from "../contexts/useMacroProgressViews";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      flexGrow: 1,
    },
  })
);

interface TimelineDateColumChangeMealPlanDialogProps {
  open: boolean;
  onClose: () => void;
  date: string;
  currentMealPlanId: string | null;
  athleteBirthdate: string | null;
  athleteMealPlans: readonly PreviewMealPlanFragment[];
  onEdit: (mealPlanId: string) => void;
  onRemove: () => void;
}

const TimelineDateColumChangeMealPlanDialog = ({
  open,
  onClose,
  currentMealPlanId,
  athleteBirthdate,
  athleteMealPlans,
  date,
  onEdit,
  onRemove,
}: TimelineDateColumChangeMealPlanDialogProps) => {
  const classes = useStyles();
  const macroProgressViews = useMacroProgressViews();
  const { formatDateWithLocale } = useDateFormatting();
  const [selectedMealPlanId, setSelectedMealPlanId] = useState<string | null>(currentMealPlanId);

  const handleRemove = () => {
    onRemove();
    onClose();
  };

  const handleChoose = () => {
    if (selectedMealPlanId) {
      onEdit(selectedMealPlanId);
      onClose();
    }
  };

  // TODO: Refactor out finding of Meal Plan "assigned" to today (mobile?), and use that here...

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title={`Change Meal Plan for ${formatDateWithLocale(date)}`} onClose={onClose} />
      <DialogContent>
        <List dense>
          {athleteMealPlans.map(mp => {
            const macros = getMacroProtocolMacros(mp.macroProtocol, athleteBirthdate);
            return (
              <ListItem
                key={mp.id}
                button
                selected={mp.id === selectedMealPlanId}
                onClick={() => setSelectedMealPlanId(mp.id)}>
                <ListItemText
                  primary={mp.name}
                  primaryTypographyProps={{
                    variant: "body1",
                  }}
                  secondary={macroProgressViews === "all" ? <MacrosSummaryLabel
                    macros={macros}
                    kcalPrefix
                    variant="sm" /> : null}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Tooltip title={`Remove Meal Plan from ${formatDateWithLocale(date)}`}>
          <Button variant="containedDestructive" onClick={handleRemove}>
            Remove
          </Button>
        </Tooltip>
        <div className={classes.spacer} />
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={selectedMealPlanId ? "" : "Select a Meal Plan above"}>
          <Button onClick={handleChoose}>OK</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TimelineDateColumChangeMealPlanDialog;

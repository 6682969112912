import React from "react";
import DialogContent from "./Content";
import { useMenuSuggestionsPreviewQuery } from "../../../../types";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";

interface MenuSuggestionsPreviewDialogProps {
  mealMenuId: string;
  onClose: () => void;
  open: boolean;
}

const MenuSuggestionsPreviewDialog = ({ mealMenuId, onClose, open }: MenuSuggestionsPreviewDialogProps) => {
  const { data } = useMenuSuggestionsPreviewQuery({
    variables: { mealMenuId },
  });

  return !data ? <LoadingBackdrop open={open} onClose={onClose} /> : <DialogContent
    open={open}
    onClose={onClose}
    data={data} />;
};

export default MenuSuggestionsPreviewDialog;

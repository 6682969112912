import { Button, ButtonProps, CircularProgress, SxProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { LocaleType } from "@notemeal/locale/utils";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import React, { useState } from "react";
import { ILinkResult, link } from "../../../utils/import/link";
import { IMatchResult, match } from "../../../utils/import/match";
import { ImportLinkModal } from "./LinkModal";

export interface IFileImport {
  file: File;
  onError: (msg: string) => void;
  locale: LocaleType;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightIcon: {
      marginLeft: theme.spacing(1),
      color: "black",
    },
  })
);

export interface ImportMatchResults<T, A> {
  singleMatches: IMatchResult<T, A>[];
  manyMatches: IMatchResult<T, A>[];
  failedMatches: IMatchResult<T, A>[];
}

export type ValidateFn<
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
> = (
  entities: IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>[]
) => IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>[];

interface ImportButtonProps<
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
> {
  label: string;
  matchableRows: (LinkEntityFields & ImportableEntity & AdditionalDisplayFields & { id: string })[];
  linkableRows: LinkEntityFields[];

  matchFields: (keyof ImportableEntity)[];
  linkFromEntityName: string;
  linkToEntityName: string;
  linkFields: (keyof LinkEntityFields)[];
  linkOnFields: (keyof LinkFields)[];
  loadAndParse: (props: IFileImport) => Promise<ImportableEntity[] | null>;
  // These all happen sequentially.
  onMatched: (props: ImportMatchResults<LinkEntityFields & ImportableEntity, AdditionalDisplayFields>) => void;
  onError: (msg: string) => void;
  onLinked?: (props: ILinkResult<LinkFields, ImportableEntity, LinkEntityFields>[]) => void;
  onFileLoaded?: (file: File) => void;
  onFileParsed?: (rows: ImportableEntity[]) => void;
  sx?: SxProps;
  color?: ButtonProps["color"];
  size?: ButtonProps["size"];
  validate?: ValidateFn<LinkFields, ImportableEntity, LinkEntityFields, AdditionalDisplayFields>;
}

const ImportButton = <
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
>({
  label,
  matchableRows,
  linkableRows,
  matchFields,
  linkFields,
  linkOnFields,
  loadAndParse,
  linkToEntityName,
  linkFromEntityName,
  onLinked,
  onMatched,
  onFileLoaded,
  onFileParsed,
  onError,
  sx,
  color,
  size,
  validate,
}: ImportButtonProps<LinkFields, ImportableEntity, LinkEntityFields, AdditionalDisplayFields>) => {
  const classes = useStyles();
  const [state, setState] = useState<null | "loading" | "linking" | "resolving-links" | "matching">(null);
  const [linkResults, setLinkResults] = useState<ILinkResult<LinkFields, ImportableEntity, LinkEntityFields>[]>([]);
  const { locale } = useLocaleContext();

  const onFileSelected = async (file: File) => {
    try {
      setState("loading");
      onFileLoaded && onFileLoaded(file);
      const rows = await loadAndParse({ file, onError, locale });
      if (!rows) {
        throw new Error("'null' returned from loadAndParse!");
      }
      onFileParsed && onFileParsed(rows);
      setState("linking");
      const linkResults = rows.map(row =>
        link({
          row,
          linkableRows,
          linkFields,
          linkOnFields,
        })
      );
      onLinked && onLinked(linkResults);
      setLinkResults(linkResults);

      //If linkOnFields and linkFields are both empty, we skip the next step since the imported entity in this case has all the necessary information.
      const skipLinking = linkOnFields.length === 0 && linkFields.length === 0;
      if (linkResults.filter(r => r.type !== "one").length === 0 || skipLinking) {
        setState("matching");
        // Skip the modal if no missing links!!
        onManualLink(linkResults);
      } else {
        setState("resolving-links");
      }
    } catch (e) {
      if (e instanceof Error) {
        onError(e.message);
      } else {
        onError("Something went wrong!");
      }
      console.error(e);
      setState(null);
    }
  };

  const onManualLink = (linkResults: ILinkResult<LinkFields, ImportableEntity, LinkEntityFields>[]) => {
    let matchResults = linkResults
      .filter(r => r.type === "one" || r.type === "NA")
      .map(({ mergedRow }) =>
        match({
          row: mergedRow as LinkEntityFields & ImportableEntity,
          matchableRows,
          fields: matchFields,
        })
      );

    if (validate) {
      matchResults = validate(matchResults as IMatchResult<LinkEntityFields & ImportableEntity, AdditionalDisplayFields>[]);
    }

    setState("matching");
    onMatched({
      singleMatches: (matchResults as IMatchResult<LinkEntityFields & ImportableEntity, AdditionalDisplayFields>[]).filter(
        m => m.type === "one"
      ),
      failedMatches: (matchResults as IMatchResult<LinkEntityFields & ImportableEntity, AdditionalDisplayFields>[]).filter(
        m => m.type === "none"
      ),
      manyMatches: (matchResults as IMatchResult<LinkEntityFields & ImportableEntity, AdditionalDisplayFields>[]).filter(
        m => m.type === "many"
      ),
    });
    setState(null);
  };

  const handleFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onFileSelected(e.target.files[0]);
    }

    e.target.value = ""; // fixes file reupload chrome bug
  };

  return (
    <Button component="label" sx={sx}>
      {label}
      {state !== null ? <CircularProgress size={25} className={classes.rightIcon} /> : null}
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChanged} />
      {state === "resolving-links" && (
        <ImportLinkModal<LinkFields, ImportableEntity, LinkEntityFields>
          linkableRows={linkableRows}
          onClose={() => setState(null)}
          open={state === "resolving-links"}
          linkFromEntityName={linkFromEntityName}
          linkToEntityName={linkToEntityName}
          linkOnFields={linkOnFields}
          linkFields={linkFields}
          linkResults={linkResults}
          onManualLink={onManualLink}
        />
      )}
    </Button>
  );
};

export default ImportButton;

import { makeStyles } from "@mui/styles";
import React, { ReactNode, useEffect, useRef, useState } from "react";

import { Orientation, SCALE_FACTOR } from "./utils";
import pagify, { PageBreak } from "./utils/pagify";

const useStyles = makeStyles({
  landscapePage: {
    height: `${7.5 * SCALE_FACTOR}in`,
    width: `${10 * SCALE_FACTOR}in`,
    paddingTop: `${0.3 * SCALE_FACTOR}in`,
    padding: `${0.5 * SCALE_FACTOR}in`,
  },
  portraitPage: {
    height: `${10 * SCALE_FACTOR}in`,
    width: `${7.5 * SCALE_FACTOR}in`,
    paddingTop: `${0.3 * SCALE_FACTOR}in`,
    padding: `${0.5 * SCALE_FACTOR}in`,
  },
  hidden: {
    position: "fixed",
    top: "-10000px",
  },
  hiddenHeader: {
    position: "fixed",
    top: "-10000px",
    width: "100%",
  },
});

interface PagedContainerProps {
  header: ReactNode;
  sortedComponents: ReactNode[];
  orientation: Orientation;
  onChangePageDivs: (divs: HTMLDivElement[]) => void;
}

const PagedContainer = ({ header, sortedComponents, orientation, onChangePageDivs }: PagedContainerProps) => {
  const classes = useStyles();
  const [pageBreaks, setPageBreaks] = useState<PageBreak[]>([]);
  const dummyPageRef = useRef<HTMLDivElement>(null);
  const dummyHeaderRef = useRef<HTMLDivElement>(null);
  const pageDivsRef = useRef<HTMLDivElement[]>();
  const calculatingPageDivsRef = useRef(true);

  useEffect(() => {
    if (dummyPageRef.current && dummyHeaderRef.current) {
      const newPageBreaks = pagify({
        rootNodeElement: dummyPageRef.current,
        headerElement: dummyHeaderRef.current,
        orientation,
      });
      pageDivsRef.current = new Array(newPageBreaks.length).fill(undefined);
      calculatingPageDivsRef.current = true;
      setPageBreaks(newPageBreaks);
    }
  }, [header, sortedComponents, orientation, setPageBreaks]);

  const renderPage = (firstComponentIdx: number, lastComponentIdx: number) => {
    return (
      <div key={`page-components-${firstComponentIdx}-${lastComponentIdx}`}>
        {header}
        {sortedComponents.slice(firstComponentIdx, lastComponentIdx)}
      </div>
    );
  };

  const updatePageDivs = (div: HTMLDivElement, idx: number) => {
    if (calculatingPageDivsRef.current && pageDivsRef.current) {
      pageDivsRef.current[idx] = div;
      if (pageDivsRef.current.every(d => !!d)) {
        calculatingPageDivsRef.current = false;
        onChangePageDivs(pageDivsRef.current);
      }
    }
  };

  const pageClassName = orientation === "portrait" ? classes.portraitPage : classes.landscapePage;

  return (
    <>
      <div>
        <div className={classes.hiddenHeader} ref={dummyHeaderRef}>
          {header}
        </div>
        <div className={classes.hidden} ref={dummyPageRef}>
          {sortedComponents}
        </div>
      </div>
      {pageBreaks
        .reduce((currPages: React.ReactElement[], nextComponentBreak: PageBreak, i) => {
          return [...currPages, renderPage(pageBreaks[i - 1] ? pageBreaks[i - 1].componentIndex : 0, nextComponentBreak.componentIndex)];
        }, [])
        .map((page, idx) => (
          <div key={`page${idx}`}>
            <div className={pageClassName} ref={d => d && updatePageDivs(d, idx)}>
              {page}
            </div>
            <hr />
          </div>
        ))}
    </>
  );
};

export default PagedContainer;

import { isSameDay } from "date-fns";
import { z } from "zod";

export type MatchType = "one" | "many" | "none";

export interface IMatchResult<T, A> {
  matchedRows: (T & A & { id: string })[];
  row: T;
  type: MatchType;
  matchFields: (keyof T)[];
  error?: z.ZodError;
}

export interface IMatchProps<T, A> {
  row: T;
  matchableRows: (T & A & { id: string })[];
  fields: (keyof T)[];
}

const simplify = (val1: string) => {
  const s = val1.toLowerCase();
  const punctuationless = s.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
  return punctuationless.replace(/\s{2,}/g, " ");
};

export const isEqual = <T>(val1: T, val2: T): boolean => {
  if (!val1 || !val2) {
    return false;
  }
  // TODO: We only compare 'dates' b/c we don't have any case of caring about time right now
  // All of the imports we handle do not capture a timestamp.
  if (typeof (val1 as unknown as Date).getMonth === "function") {
    // return String(val1) === String(val2)
    return isSameDay(val1 as unknown as Date, val2 as unknown as Date);
  } else if (typeof (val1 as unknown as string) === "string") {
    return simplify(val1 as unknown as string).trim() === simplify(val2 as unknown as string).trim();
  } else {
    return val1 === val2;
  }
};

export const match = <T, A>({ row, matchableRows, fields }: IMatchProps<T, A>): IMatchResult<T, A> => {
  const matchedRows = matchableRows.filter(r => fields.every(field => isEqual(row[field], r[field])));
  const matchType = matchedRows.length === 0 ? "none" : matchedRows.length === 1 ? "one" : "many";
  return { matchedRows, row, type: matchType, matchFields: fields };
};

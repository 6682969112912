import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { useAthleteTransferOrgGroupsQuery } from "../../../../types";

interface TransferAthleteModalProps {
  onClose: () => void;
  open: boolean;
  onTransfer: (note: string, orgGroupId: string) => void;
}

export const TransferAthleteModal = ({ onClose, open, onTransfer }: TransferAthleteModalProps) => {
  const [note, setNote] = useState("");
  const [selectedOrgGroupId, setSelectedOrgGroupId] = useState<string | null>(null);
  const { data: athleteTransferOrgGroupsData } = useAthleteTransferOrgGroupsQuery();
  const orgGroupOptions = athleteTransferOrgGroupsData?.athleteTransferOrgGroups ?? [];
  const hasMultipleOrgGroupOptions = orgGroupOptions.length > 1;
  const disableContinue = hasMultipleOrgGroupOptions && !selectedOrgGroupId;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Transfer Athlete" onClose={onClose} />
      <DialogContent sx={{ paddingBottom: theme => theme.spacing(5) }}>
        <Typography variant="body1">
          Are you sure you want to initiate the transfer of this athlete? You will no longer have access to any of the athlete's data once
          they are claimed by another org.
        </Typography>
        {hasMultipleOrgGroupOptions && (
          <>
            <Typography variant="body1" sx={{ marginBottom: 1 }}>
              If so, please select the org group that you would like to move this athlete to.
            </Typography>
            <FormControl fullWidth size="small">
              <InputLabel id="org-group">Org Group*</InputLabel>
              <Select
                id="org-group"
                placeholder="Org Group"
                variant="outlined"
                value={selectedOrgGroupId}
                label="Org Group*"
                onChange={event => setSelectedOrgGroupId(event.target.value)}
              >
                {orgGroupOptions.map(orgGroup => (
                  <MenuItem value={orgGroup.id} key={orgGroup.id}>
                    {orgGroup.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <TextField
          placeholder="Add note here (optional)"
          value={note}
          onChange={e => setNote(e.target.value)}
          multiline
          minRows={3} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={disableContinue ? "Please select an Org Group" : null}>
          <div>
            <Button
              sx={{ marginLeft: 1 }}
              disabled={disableContinue}
              onClick={() => onTransfer(note, selectedOrgGroupId || orgGroupOptions[0].id)}
            >
              {hasMultipleOrgGroupOptions ? "Continue" : "Yes"}
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

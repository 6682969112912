import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import React, { useState } from "react";

interface ActivationLinkModalProps {
  onClose: () => void;
  open: boolean;
  activationLink: string | null;
  onCopyLink: () => void;
}

export const ActivationLinkModal = ({ onClose, open, activationLink, onCopyLink }: ActivationLinkModalProps) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(activationLink || "");
    setLinkCopied(true);
    onCopyLink();
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Enable Mobile App" onClose={onClose} />
      <DialogContent>
        <TextField
          label="Activation Link"
          disabled={true}
          value={`${activationLink}`}
          sx={{
            pb: 4,
            "& .MuiInputBase-input": {
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <TWTooltip
          title="Link copied"
          subcopy=""
          open={linkCopied}
          onClose={() => setLinkCopied(false)}
          leaveDelay={1000}>
          <Button variant="contained" onClick={handleCopyLink}>
            Copy
          </Button>
        </TWTooltip>
      </DialogActions>
    </Dialog>
  );
};

import ChevronRight from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { MealPlanBulkCopyTeamPreviewFragment } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    column: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    columnTitle: {
      padding: theme.spacing(1.2, 0),
      flexGrow: 0,
      flexShrink: 0,
    },
    searchBody: {
      flexGrow: 1,
      flexShrink: 1,
      height: "80%",
      width: "100%",
      border: "1px solid lightgray",
      borderRadius: "4px",
    },

    // Search Row
    searchTextField: {
      padding: theme.spacing(1, 1),
      margin: theme.spacing(),
    },

    // List
    listRoot: {
      overflowY: "scroll",
      flexGrow: 1,
      flexShrink: 1,
      height: "85%",
      width: "100%",
    },
    listItem: {
      cursor: "pointer",
      padding: theme.spacing(0, 2),
      width: "100%",
    },
    disabledListItemText: {
      color: theme.palette.text.disabled,
    },
  })
);

interface ChooseTeamListProps {
  teamId: string;
  teams: readonly MealPlanBulkCopyTeamPreviewFragment[];
  onSelectTeam: (teamId: string) => void;
}

const ChooseTeamList = ({ teamId, teams, onSelectTeam }: ChooseTeamListProps) => {
  const classes = useStyles();
  const [searchTeamValue, setSearchTeamValue] = useState("");
  const searchTeams = teams.filter(team => {
    if (team.name) {
      const cleanTeamName = team.name.toLowerCase();
      const cleanSearchValue = searchTeamValue.trim().toLowerCase();
      return cleanTeamName.includes(cleanSearchValue);
    } else {
      return false;
    }
  });

  return (
    <div className={classes.column}>
      <Typography
        variant="h3"
        className={classes.columnTitle}
        color="textSecondary"
        align="left">
        Choose Team to Copy To
      </Typography>
      <div className={classes.searchBody}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" disablePointerEvents>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Search Teams"}
          className={classes.searchTextField}
          value={searchTeamValue}
          onChange={e => setSearchTeamValue(e.target.value)}
        />
        <List classes={{ root: classes.listRoot }}>
          {searchTeams.map(team => {
            return (
              <ListItem
                key={team.id}
                button
                className={classes.listItem}
                onClick={() => onSelectTeam(team.id)}>
                <ListItemText primary={team.name} />
                {team.id === teamId && (
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                )}
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
};

export default ChooseTeamList;

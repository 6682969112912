import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Chip, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ExchangeTarget } from "@notemeal/shared/ui/ExchangeAmount/useExchangeTargets";
import { useServingAmountCallbacks } from "@notemeal/shared/ui/ServingAmount/utils";
import classnames from "classnames";
import React, { useState } from "react";
import { FullServingAmountFragment } from "../../../../types";
import ServingAmountsEditChip from "../../../ServingAmounts/Edit/Chip";
import { useServingIdToExpanded } from "../../../ServingAmounts/Edit/utils";
import PickListDialog from "./PickListDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exchangeAmountChip: {
      color: "white",
    },
    root: {
      paddingBottom: theme.spacing(),
    },
    chipOuterDiv: {
      display: "flex",
    },
    chipInnerDiv: {
      flexGrow: 1,
      flexShrink: 1,
      minWidth: 0,
    },
    servingAmountExchangeAmountChip: {
      marginLeft: theme.spacing(),
      marginTop: theme.spacing(1.5),
    },
  })
);

interface MealOptionPickListExchangeTargetProps {
  exchangeTarget: ExchangeTarget;
  onEditServingAmounts: (servingAmounts: readonly FullServingAmountFragment[]) => void;
}

const MealOptionPickListExchangeTarget = ({ exchangeTarget, onEditServingAmounts }: MealOptionPickListExchangeTargetProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  const classes = useStyles();
  const [pickListModalOpen, setPickListModalOpen] = useState(false);

  const currentAmount = exchangeTarget.servingAmountsWithExchangeAmount.reduce((sum, next) => sum + next.exchangeAmount, 0);

  const { servingIdToExpanded, onChangeExpanded, onSetIngredientsCount } = useServingIdToExpanded();
  const { onDecrementByDefaultAmount, onDelete, onIncrementByDefaultAmount, onSetAmount, onReplaceServing } = useServingAmountCallbacks({
    servingAmounts: exchangeTarget.servingAmountsWithExchangeAmount,
    onChange: onEditServingAmounts,
  });

  return (
    <div className={classes.root}>
      <ListItem
        dense
        button
        divider
        onClick={() => setPickListModalOpen(true)}>
        <ListItemText
          primary={exchangeTarget.exchange.name}
          primaryTypographyProps={{
            style: {
              color: greyscale[500],
            },
          }}
        />
        <Chip
          slot="end"
          className={classes.exchangeAmountChip}
          style={{
            backgroundColor: greyscale[500],
          }}
          size="small"
          label={`${currentAmount} / ${exchangeTarget.amount}`}
        />
        <ListItemSecondaryAction>
          <IconButton size="small" onClick={() => setPickListModalOpen(true)}>
            <NavigateNextIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {/* TODO: How to show exchange amounts here? */}
      {exchangeTarget.servingAmountsWithExchangeAmount.map(sa => (
        <div key={sa.serving.id} className={classes.chipOuterDiv}>
          <div className={classes.chipInnerDiv}>
            <ServingAmountsEditChip
              servingAmount={sa}
              onIncrement={() => onIncrementByDefaultAmount(sa)}
              onDecrement={() => onDecrementByDefaultAmount(sa)}
              onDelete={() => onDelete(sa)}
              onReplaceServing={serving => onReplaceServing(sa, serving)}
              onSetAmount={amount => onSetAmount(sa, amount)}
              expanded={servingIdToExpanded(sa.serving.id).isExpanded}
              onChangeExpanded={expanded => onChangeExpanded(sa.serving.id, expanded)}
              onLoadIngredients={count => onSetIngredientsCount(sa.serving.id, count)}
              // onDeconstructRecipe={handleDeconstructRecipe}
              recipeIngredient={false}
            />
          </div>
          <Chip
            slot="end"
            className={classnames(classes.exchangeAmountChip, classes.servingAmountExchangeAmountChip)}
            style={{
              backgroundColor: greyscale[500],
            }}
            size="small"
            label={sa.exchangeAmount}
          />
        </div>
      ))}
      {pickListModalOpen && (
        <PickListDialog
          open={pickListModalOpen}
          onClose={() => setPickListModalOpen(false)}
          exchangeTarget={exchangeTarget}
          onPick={onEditServingAmounts}
        />
      )}
    </div>
  );
};

export default MealOptionPickListExchangeTarget;

import { ApolloCache } from "@apollo/client";
import newId from "@notemeal/shared/ui/utils/newId";
import React, { createContext, ReactNode, useContext } from "react";
import { CheckInKioskAthletesDocument, CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables } from "../../../types";

interface KioskOrderContextType {
  setCheckInsForAthleteInCache: (cache: ApolloCache<any>, athleteId: string, selectedMealMenuIds: readonly string[]) => void;
}

const KioskCheckInContext = createContext<KioskOrderContextType>({
  setCheckInsForAthleteInCache: () => {},
});

interface KioskCheckInContextProviderProps {
  mealMenuIds: readonly string[];
  children: ReactNode;
}

export const KioskCheckInContextProvider = ({ mealMenuIds, children }: KioskCheckInContextProviderProps) => {
  return (
    <KioskCheckInContext.Provider
      value={{
        setCheckInsForAthleteInCache: (cache, athleteId, selectedMealMenuIds) => {
          const cacheQuery = cache.readQuery<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>({
            query: CheckInKioskAthletesDocument,
            variables: { mealMenuIds },
          });
          if (cacheQuery) {
            const checkInsForMealMenus = selectedMealMenuIds.map(mmId => {
              return {
                id: newId(),
                mealMenuId: mmId,
                athleteId,
                __typename: "MealMenuCheckIn",
              };
            });
            cache.writeQuery<CheckInKioskAthletesQuery, CheckInKioskAthletesQueryVariables>({
              query: CheckInKioskAthletesDocument,
              variables: { mealMenuIds },
              data: {
                ...cacheQuery,
                athletesForMealMenus: cacheQuery.athletesForMealMenus.map(a => {
                  if (a.id === athleteId) {
                    return {
                      ...a,
                      checkInsForMealMenus,
                    };
                  } else {
                    return a;
                  }
                }),
              },
            });
          }
        },
      }}
    >
      {children}
    </KioskCheckInContext.Provider>
  );
};

export const useKioskCheckInContext = () => {
  return useContext(KioskCheckInContext);
};

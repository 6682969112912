import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemText } from "@mui/material";
import { useDraggable } from "@notemeal/shared/ui/hooks/useDraggable";
import { sortByKey } from "@notemeal/utils/sort";
import React, { useState } from "react";
import { animated } from "react-spring";
import DialogTitle from "../../componentLibrary/DialogTitle";
import { MenuItemAppearanceState } from "./reducer";

interface MenuItemAppearanceReorderDialogProps {
  open: boolean;
  onClose: () => void;
  onDone: (menuItemAppearances: readonly MenuItemAppearanceState[]) => void;
  menuItemAppearances: readonly MenuItemAppearanceState[];
}

const MenuItemAppearanceReorderDialog = ({
  open,
  onClose,
  onDone,
  menuItemAppearances: _menuItemAppearances,
}: MenuItemAppearanceReorderDialogProps) => {
  const [menuItemAppearances, setMenuItemAppearances] = useState(_menuItemAppearances);
  const sortedMenuItemAppearances = sortByKey(menuItemAppearances, "position");

  // TODO: Deleted ones handled?
  const handleChangeOrder = (ids: string[]) => {
    const reorderedMIAs = ids.flatMap((id, index) => {
      const matchingMIA = menuItemAppearances.find(mia => mia.menuItem.id === id);
      return matchingMIA ? [{ ...matchingMIA, position: index + 1 }] : [];
    });
    setMenuItemAppearances(reorderedMIAs);
  };

  const handleDone = () => {
    onDone(menuItemAppearances);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Drag menu items to re-order" onClose={onClose} />
      <MenuItemAppearanceReorderDialogContent
        key={sortedMenuItemAppearances.map(mia => mia.menuItem.id).join(",")}
        sortedMenuItemAppearances={sortedMenuItemAppearances}
        onChangeOrder={handleChangeOrder}
      />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleDone}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

interface MenuItemAppearanceReorderDialogContentProps {
  sortedMenuItemAppearances: readonly MenuItemAppearanceState[];
  onChangeOrder: (ids: string[]) => void;
}

const MenuItemAppearanceReorderDialogContent = ({
  sortedMenuItemAppearances,
  onChangeOrder,
}: MenuItemAppearanceReorderDialogContentProps) => {
  const draggables = sortedMenuItemAppearances.map(({ menuItem: { id } }, index) => ({
    id,
    index,
    height: 48.1,
  }));
  const { springs, bind } = useDraggable(draggables, onChangeOrder);

  return (
    <DialogContent>
      <List>
        {sortedMenuItemAppearances.map(({ menuItem: { id: miId, name: miName } }) => {
          const springObject = draggables.find(({ id }) => id === miId);
          if (!springObject) {
            return null;
          }

          const spring = springs[springObject.index];
          const bindProps = bind(springObject.index);

          if (!spring || !bindProps) {
            return null;
          }

          const { zIndex, y, shadow } = spring;

          return (
            <animated.div
              key={miId}
              {...bindProps}
              style={{
                zIndex,
                boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px 0px ${s}px 0px`),
                transform: y.to(y => `translate3d(0, ${y}px, 0)`),
              }}
            >
              <ListItem sx={{ cursor: "pointer" }}>
                <ListItemText primary={miName} />
                <DragIndicatorIcon />
              </ListItem>
            </animated.div>
          );
        })}
      </List>
    </DialogContent>
  );
};

export default MenuItemAppearanceReorderDialog;

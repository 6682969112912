import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import EditMealPlanTemplate from "../../components/MealPlanTemplate/EditMealPlanTemplate";
import LoadingBackdrop from "../../components/universal/LoadingBackdrop";
import {
  FullMealPlanTemplateFragment,
  GoalTypeFragment,
  useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation,
  useEditOrgGroupMacroMealPlanTemplateMutation,
  useMealPlanTemplateQuery,
  usePublishOrgGroupMacroMealPlanTemplateMutation,
} from "../../types";

interface EditOrgGroupMealPlanTemplateProps {
  open: boolean;
  onEditSuccess: (mealPlanTemplate: FullMealPlanTemplateFragment) => void;
  onDiscardEditSuccess: () => void;
  onPublishSuccess: (mealPlanTemplate: FullMealPlanTemplateFragment) => void;
  onClose: () => void;
  goalTypes: readonly GoalTypeFragment[];
  id: string;
}

const EditOrgGroupMealPlanTemplate = ({
  open,
  onEditSuccess,
  onDiscardEditSuccess,
  onPublishSuccess,
  onClose,
  goalTypes,
  id,
}: EditOrgGroupMealPlanTemplateProps) => {
  const [editMealPlanTemplate] = useEditOrgGroupMacroMealPlanTemplateMutation({
    onCompleted: data => onEditSuccess(data.editOrgGroupMacroMealPlanTemplate.macroMealPlanTemplate),
  });
  const [publishMealPlanTemplate] = usePublishOrgGroupMacroMealPlanTemplateMutation({
    onCompleted: data => onPublishSuccess(data.publishOrgGroupMacroMealPlanTemplate.macroMealPlanTemplate),
  });
  const [deletePendingState] = useDeleteOrgGroupMacroMealPlanTemplatePendingStateMutation({
    onCompleted: data => onDiscardEditSuccess(),
  });
  const { data, loading } = useMealPlanTemplateQuery({ variables: { id } });

  return loading || !data?.mealPlanTemplate ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogContent>
        <EditMealPlanTemplate
          mealPlanTemplate={data.mealPlanTemplate}
          editMealPlanTemplate={input => editMealPlanTemplate({ variables: { input } })}
          publishMealPlanTemplate={input => publishMealPlanTemplate({ variables: { input } })}
          deletePendingState={input => deletePendingState({ variables: { input } })}
          onClose={onClose}
          goalTypes={goalTypes}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditOrgGroupMealPlanTemplate;

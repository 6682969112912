import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import Form from "./Form";
import {
  RestaurantLocationContactFormState,
  buildContactFormTooltips,
  buildInitialCreateFormState,
  restaurantLocationContactFormReducer,
} from "./Form/utils";

interface CreateDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (contactFormState: RestaurantLocationContactFormState) => void;
}

const CreateDialog = ({ open, onClose, onCreate }: CreateDialogProps) => {
  const [state, dispatch] = useReducer(restaurantLocationContactFormReducer, buildInitialCreateFormState());

  const canSaveTooltips = buildContactFormTooltips(state);
  const handleSave = () => {
    if (canSaveTooltips.length > 0) {
      return;
    }
    onCreate(state);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Create Contact" onClose={onClose} />
      <DialogContent>
        <Form state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;

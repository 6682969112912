import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { MacrosProgress } from "apps/web/src/components/MacrosProgress/MacrosProgress";
import React, { useState } from "react";

const regularCurrentMacros = {
  cho: 93,
  pro: 42,
  fat: 25,
  kcal: 765,
};
const regularTargetMacros = {
  cho: 505,
  pro: 162,
  fat: 127,
  kcal: 3812,
};

const overflowCurrentMacros = {
  cho: 339,
  pro: 186,
  fat: 110,
  kcal: 3002,
};

const overflowTargetMacros = {
  cho: 281,
  pro: 211,
  fat: 94,
  kcal: 2807,
};

export const zerosMacros = {
  cho: 0,
  pro: 0,
  fat: 0,
  kcal: 0,
};

export const ThemeViewerMacrosProgress = () => {
  const [overflow, setOverflow] = useState(false);
  const [hideGrams, setHideGrams] = useState(false);
  const [noTargetMacros, setNoTargetMacros] = useState(false);
  const [zeroMacros, setZeroMacros] = useState(false);

  const currentMacros = zeroMacros ? zerosMacros : overflow ? overflowCurrentMacros : regularCurrentMacros;
  const targetMacros = zeroMacros ? zerosMacros : noTargetMacros ? null : overflow ? overflowTargetMacros : regularTargetMacros;
  const macroProgressViews = hideGrams ? "hideNumbers" : "all";

  return (
    <>
      <Typography variant="h2">Macros Progress</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <FormControlLabel control={<Checkbox checked={overflow} onChange={() => setOverflow(!overflow)} />} label="Overflow" />
        <FormControlLabel control={<Checkbox checked={hideGrams} onChange={() => setHideGrams(!hideGrams)} />} label="Hide grams" />
        <FormControlLabel
          control={<Checkbox checked={noTargetMacros} onChange={() => setNoTargetMacros(!noTargetMacros)} />}
          label="No target macros"
        />
        <FormControlLabel control={<Checkbox checked={zeroMacros} onChange={() => setZeroMacros(!zeroMacros)} />} label="Zero macros" />
      </Box>
      <Box sx={{ display: "flex", gap: 6 }}>
        <MacrosProgress
          currentMacros={currentMacros}
          targetMacros={targetMacros}
          macroProgressViews={macroProgressViews} />
        <MacrosProgress
          variant="md"
          currentMacros={currentMacros}
          targetMacros={targetMacros}
          macroProgressViews={macroProgressViews} />
        <MacrosProgress
          variant="sm"
          currentMacros={currentMacros}
          targetMacros={targetMacros}
          macroProgressViews={macroProgressViews} />
      </Box>
    </>
  );
};

import { MenuItem, SxProps, TextField } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { MealType } from "../../types";

const useStyles = makeStyles(() =>
  createStyles({
    selected: {
      fontWeight: "bold",
    },
  })
);

interface MealTypeMultiSelectProps {
  value: readonly MealType[];
  onChange: (value: readonly MealType[]) => void;
  className?: string;
  sx?: SxProps;
  disabled?: boolean;
}

const MealTypeMultiSelect = ({ value, onChange, className, sx, disabled }: MealTypeMultiSelectProps) => {
  const classes = useStyles();

  const getClassName = (mealType: MealType) => (value.includes(mealType) ? classes.selected : undefined);

  return (
    <TextField
      disabled={disabled}
      select
      SelectProps={{
        multiple: true,
      }}
      label="Meal Types"
      inputProps={{
        "aria-label": "Meal Types",
      }}
      value={value || ""}
      sx={sx}
      className={className}
      onChange={e => onChange(e.target.value as unknown as MealType[])}
    >
      <MenuItem value={"breakfast"} className={getClassName("breakfast")}>
        Breakfast
      </MenuItem>
      <MenuItem value={"lunch"} className={getClassName("lunch")}>
        Lunch
      </MenuItem>
      <MenuItem value={"dinner"} className={getClassName("dinner")}>
        Dinner
      </MenuItem>
      <MenuItem value={"snack"} className={getClassName("snack")}>
        Snack
      </MenuItem>
    </TextField>
  );
};

export default MealTypeMultiSelect;

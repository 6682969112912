import {
  ExchangeMealTemplateViewFragment,
  ActivityTemplateFragment,
  FullExchangeSetFragment,
  ExchangeSummaryLabelFragment,
  MacroMealTemplateViewFragment,
  FullMacroProtocolFragment,
  PublicMealPlanFragment,
  FullMacroMealTemplateFragment,
  FullExchangeMealTemplateFragment,
  EditExchangeAmountFragment,
  FullMealPlanFragment,
} from "../../../types";

interface BaseMealPlan {
  id: string;
  name: string;
  activityTemplates: readonly ActivityTemplateFragment[];
  macroProtocol: FullMacroProtocolFragment;
  athlete: {
    id: string;
    birthDate: string;
  };
}
interface ExchangeMealPlan extends BaseMealPlan {
  type: "exchange";
  mealTemplates: readonly ExchangeMealTemplateViewFragment[];
  exchangeSet: FullExchangeSetFragment;
  exchangeTargets: readonly ExchangeSummaryLabelFragment[];
}

interface MacroMealPlan extends BaseMealPlan {
  type: "macro";
  mealTemplates: readonly MacroMealTemplateViewFragment[];
}

export type MealPlan = MacroMealPlan | ExchangeMealPlan;

// TODO: Check if these conversions are missing something
export const getTypedMealPlanFromQuery = (mealPlan: PublicMealPlanFragment): MealPlan | null => {
  if (mealPlan.type === "exchange") {
    const exchangeTemplatesCheck = mealPlan.mealTemplates.some(mt => mt.__typename === "ExchangeMealTemplate");
    const hasExchangeSetAndTargets = !!mealPlan.exchangeSet && !!mealPlan.exchangeTargets;
    if (exchangeTemplatesCheck && hasExchangeSetAndTargets) {
      //need cast here as the ExchangeSetFragment uses
      //foodOrRecipeExchangeRatios client resolver resolver
      return mealPlan as unknown as ExchangeMealPlan;
    }
  } else {
    const macroTemplatesCheck = mealPlan.mealTemplates.some(mt => mt.__typename === "MacroMealTemplate");
    if (macroTemplatesCheck) {
      return mealPlan as unknown as MacroMealPlan;
    }
  }
  return null;
};

interface FullExchangeMealPlan extends BaseMealPlan {
  type: "exchange";
  mealTemplates: readonly FullExchangeMealTemplateFragment[];
  exchangeSet: FullExchangeSetFragment;
  exchangeTargets: readonly EditExchangeAmountFragment[];
}

interface FullMacroMealPlan extends BaseMealPlan {
  type: "macro";
  mealTemplates: readonly FullMacroMealTemplateFragment[];
}

export const getTypedMealPlanFromFullMealPlanFragment = (
  mealPlan: FullMealPlanFragment
): FullMacroMealPlan | FullExchangeMealPlan | null => {
  if (mealPlan.type === "exchange") {
    const exchangeTemplatesCheck = mealPlan.mealTemplates.some(mt => mt.__typename === "ExchangeMealTemplate");
    const hasExchangeSetAndTargets = !!mealPlan.exchangeSet && !!mealPlan.exchangeTargets;
    if (exchangeTemplatesCheck && hasExchangeSetAndTargets) {
      return mealPlan as FullExchangeMealPlan;
    }
  } else {
    const macroTemplatesCheck = mealPlan.mealTemplates.some(mt => mt.__typename === "MacroMealTemplate");
    if (macroTemplatesCheck) {
      return mealPlan as FullMacroMealPlan;
    }
  }
  return null;
};

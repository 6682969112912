import { ListItemSecondaryAction } from "@mui/material";
import React from "react";

import { FullServingFragment } from "../../../../types";
import ServingUnitsConversionButton from "../../../Serving/Units/ConversionsButton";
import { useConvertToUnits } from "./hooks";
import Item from "./Item";

interface ServingAmountsServingsMenuItemWithUnitConversionsProps {
  onClose: () => void;
  onSelect: (serving: FullServingFragment) => void;
  serving: FullServingFragment;
  unitId: string;
  hideProductionOnlyUnits: boolean;
}

const ServingAmountsServingsMenuItemWithUnitConversions = ({
  onClose,
  onSelect,
  serving,
  unitId,
  hideProductionOnlyUnits,
}: ServingAmountsServingsMenuItemWithUnitConversionsProps) => {
  const { onConvertToUnits } = useConvertToUnits({
    servingId: serving.id,
    onSelectServing: onSelect,
  });

  return (
    <Item
      onClose={onClose}
      onSelect={onSelect}
      serving={serving}>
      <ListItemSecondaryAction>
        <ServingUnitsConversionButton
          unitId={unitId}
          onClick={u => onConvertToUnits(u.id)}
          hideProductionOnlyUnits={hideProductionOnlyUnits}
          tooltipTitle="More Units"
          menuTitle="More Units"
        />
      </ListItemSecondaryAction>
    </Item>
  );
};

export default ServingAmountsServingsMenuItemWithUnitConversions;

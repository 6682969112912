import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Fragment, useState } from "react";

import { differenceInDays } from "date-fns";
import { Athlete } from "../../../components/Athlete/types";
import ComplianceEventList from "../../../components/global/Compliance/EventList";
import ComplianceKeyMetric from "../../../components/global/Compliance/KeyMetric";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    header: {
      display: "flex",
      flexGrow: 0,
    },
    mealPlanViewNumber: {
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      textAlign: "center",
    },
    mealPlanViewHistory: {
      display: "flex",
      height: 142, // Only show last 3 events
      overflowY: "auto",
      paddingLeft: theme.spacing(1),
    },
  })
);

interface AthleteSummaryComplianceProps {
  athlete: Athlete;
}

const AthleteSummaryCompliance = ({ athlete }: AthleteSummaryComplianceProps) => {
  const classes = useStyles();
  const [daysAgo, setDaysAgo] = useState(30);

  const hasSharedMealPlans = athlete.mealPlans.some(mpt => mpt.isShared);
  const eventsAndMealPlans = athlete.mealPlans.flatMap(mpt => {
    return mpt.events.map(e => ({ ...e, mealPlanName: mpt.name }));
  });

  const eventsFilteredOnDate = eventsAndMealPlans.filter(({ datetime }) => differenceInDays(new Date(), new Date(datetime)) <= daysAgo);

  return (
    <Fragment>
      <div className={classes.header}>
        <Typography variant="h3">Compliance</Typography>
      </div>
      <div className={classes.root}>
        {hasSharedMealPlans ? (
          <Fragment>
            <ComplianceKeyMetric
              events={eventsFilteredOnDate}
              daysAgo={daysAgo}
              setDaysAgo={setDaysAgo} />
            <div className={classes.mealPlanViewHistory}>
              <ComplianceEventList eventsAndMealPlans={eventsFilteredOnDate} />
            </div>
          </Fragment>
        ) : (
          <Typography variant="body1">No Meal Plan Shared with Athlete</Typography>
        )}
      </div>
    </Fragment>
  );
};

export default AthleteSummaryCompliance;

import { useApolloClient } from "@apollo/client";
import { Macros, scaleMacros, sumMacros } from "@notemeal/shared/utils/macro-protocol";
import { sortByKey } from "@notemeal/utils/sort";
import {
  CreateServingAmountInput,
  FullServingAmountFragment,
  FullServingFragment,
  RecipeWithFullIngredientsDocument,
  RecipeWithFullIngredientsQuery,
  RecipeWithFullIngredientsQueryVariables,
} from "../types";
import newId from "../utils/newId";
import { round } from "../utils/numbers";
import { getNextPosition } from "../utils/getNextPosition";

// TODO: pull from one spot?
const DUMMY_SERVING_WEIGHT = 1.234;

interface ServingAmountWithMacros {
  serving: { macros: Macros; id: string };
  amount: number;
}

export const getServingAmountMacros = ({ serving, amount }: ServingAmountWithMacros): Macros => {
  const { macros } = serving;
  return scaleMacros(macros, amount);
};

export const getServingAmountsMacros = (servingAmounts: readonly ServingAmountWithMacros[]): Macros => {
  return sumMacros(servingAmounts.map(getServingAmountMacros));
};

export const formatServingAmount = ({ serving, amount }: { serving: { units: string }; amount: number }): string => {
  return formatServing(amount, serving.units);
};

interface ServingAmountWithWeightArgs {
  amount: number;
  serving: {
    weight: number | null; // single serving weight in grams
    units: string; // unit name
  };
}

const addWeightToServingString = (baseString: string, _weight: number | null): string => {
  if (!_weight || _weight === DUMMY_SERVING_WEIGHT) {
    return baseString;
  }
  const weight = round(_weight, 2);
  return `${baseString} (${weight}g)`;
};

export const addWeightToAmbiguousServingString = (units: string, weight: number | null): string => {
  if (isAmbiguousServingUnit(units)) {
    return addWeightToServingString(units, weight);
  }
  return units;
};

const isAmbiguousServingUnit = (servingUnit: string): boolean => {
  return servingUnit.toLowerCase().includes("serving");
};

export const formatAmbiguousServingAmountWithTotalWeight = ({ amount, serving }: ServingAmountWithWeightArgs): string => {
  if (isAmbiguousServingUnit(serving.units)) {
    return formatServingAmountWithTotalWeight({ amount, serving });
  }
  return formatServing(amount, serving.units);
};

export const formatServingAmountWithTotalWeight = ({ amount, serving: { weight, units } }: ServingAmountWithWeightArgs): string => {
  if (!weight || weight === DUMMY_SERVING_WEIGHT) {
    return formatServing(amount, units);
  }
  const totalWeight = round(amount * weight, 2);
  return `${formatServing(amount, units)} (${totalWeight}g)`;
};

export const formatAmbiguousServingWithSingleServingWeight = ({ amount, serving }: ServingAmountWithWeightArgs): string => {
  if (isAmbiguousServingUnit(serving.units)) {
    return formatServingWithSingleServingWeight({ amount, serving });
  }
  return formatServing(amount, serving.units);
};

export const formatServingWithSingleServingWeight = ({ amount, serving: { weight, units } }: ServingAmountWithWeightArgs): string => {
  if (!weight || weight === DUMMY_SERVING_WEIGHT) {
    return formatServing(amount, units);
  }
  return `${formatServing(amount, units)} (${weight}g)`;
};

export const formatServing = (servingAmountInDecimal: number, servingUnit: string): string => {
  const servingAmount = round(servingAmountInDecimal, 2);
  switch (servingAmount) {
    case 0.25:
      return `1/4 ${servingUnit}`;
    case 0.33:
      return `1/3 ${servingUnit}`;
    case 0.5:
      return `1/2 ${servingUnit}`;
    case 0.67:
      return `2/3 ${servingUnit}`;
    case 0.75:
      return `3/4 ${servingUnit}`;
    case 1:
      return `1 ${servingUnit}`;
    case 1.25:
      return `1 1/4 ${servingUnit}`;
    case 1.33:
      return `1 1/3 ${servingUnit}`;
    case 1.5:
      return `1 1/2 ${servingUnit}`;
    case 1.67:
      return `1 2/3 ${servingUnit}`;
    case 1.75:
      return `1 3/4 ${servingUnit}`;
    case 2:
      return `2 ${servingUnit}`;
    case 2.25:
      return `2 1/4 ${servingUnit}`;
    case 2.33:
      return `2 1/3 ${servingUnit}`;
    case 2.5:
      return `2 1/2 ${servingUnit}`;
    case 2.67:
      return `2 2/3 ${servingUnit}`;
    case 2.75:
      return `2 3/4 ${servingUnit}`;
    case 3:
      return `3 ${servingUnit}`;
    default:
      return `${servingAmount} ${servingUnit}`;
  }
};

export const newServingAmount = (serving: FullServingFragment, position: number): FullServingAmountFragment => ({
  id: newId(),
  __typename: "ServingAmount",
  amount: serving.defaultAmount || 1,
  serving,
  position,
});

export const newServingAmountWithAmount = (serving: FullServingFragment, position: number, amount: number): FullServingAmountFragment => ({
  id: newId(),
  __typename: "ServingAmount",
  amount,
  serving,
  position,
});

interface getServingAmountCallbacksProps {
  servingAmounts: readonly FullServingAmountFragment[];
  onChange: (servingAmounts: readonly FullServingAmountFragment[]) => void;
}

const squareRootDecrementAmount = (servingAmount: FullServingAmountFragment) => {
  const decrementAmt = Math.min(servingAmount.serving.defaultAmount, 1);
  if (servingAmount.amount - decrementAmt > 0) {
    return decrementAmt;
  } else {
    return servingAmount.amount / 2;
  }
};

const squareIncrementAmount = (servingAmount: FullServingAmountFragment) => {
  const incrementAmt = Math.min(servingAmount.serving.defaultAmount, 1);
  if (incrementAmt > servingAmount.amount) {
    return servingAmount.amount;
  } else {
    return incrementAmt;
  }
};

export const useServingAmountCallbacks = ({ servingAmounts, onChange }: getServingAmountCallbacksProps) => {
  const client = useApolloClient();

  const replaceServingAmountWith = (
    servingAmount: FullServingAmountFragment,
    servingAmounts: readonly FullServingAmountFragment[],
    replaceWith: (sa: FullServingAmountFragment) => FullServingAmountFragment | null
  ): FullServingAmountFragment[] => {
    const toReplaceIndex = servingAmounts.findIndex(sa => sa.id === servingAmount.id);
    const toReplaceServingAmount = replaceWith(servingAmount);
    return [
      ...servingAmounts.slice(0, toReplaceIndex),
      ...(toReplaceServingAmount ? [toReplaceServingAmount] : []),
      ...servingAmounts.slice(toReplaceIndex + 1, servingAmounts.length),
    ];
  };

  const onAddServingAmount = (servingAmount: FullServingAmountFragment) => {
    if (servingAmounts.map(sa => sa.serving.foodOrRecipe.id).includes(servingAmount.serving.foodOrRecipe.id)) return;
    onChange([
      ...servingAmounts,
      {
        ...servingAmount,
        position: getNextPosition(servingAmounts),
      },
    ]);
  };

  const onChangeOrder = (servingAmountIdsOrder: string[]) => {
    const reorderedServingAmount = servingAmountIdsOrder.flatMap((saId, index) => {
      const matchingServingAmount = servingAmounts.find(sa => sa.id === saId);
      return matchingServingAmount ? [{ ...matchingServingAmount, position: index + 1 }] : [];
    });
    onChange(reorderedServingAmount);
  };

  const onDelete = (servingAmount: FullServingAmountFragment) => {
    onChange(replaceServingAmountWith(servingAmount, servingAmounts, sa => null));
  };

  const onIncrement = (servingAmount: FullServingAmountFragment) => {
    onChange(
      replaceServingAmountWith(servingAmount, servingAmounts, sa => ({
        ...sa,
        amount: sa.amount + 1,
      }))
    );
  };

  const onIncrementByDefaultAmount = (servingAmount: FullServingAmountFragment) => {
    const amount = squareIncrementAmount(servingAmount);
    onChange(
      replaceServingAmountWith(servingAmount, servingAmounts, sa => ({
        ...sa,
        amount: sa.amount + amount,
      }))
    );
  };

  const onDecrement = (servingAmount: FullServingAmountFragment) => {
    onChange(
      replaceServingAmountWith(servingAmount, servingAmounts, sa => ({
        ...sa,
        amount: Math.max(1, sa.amount - 1),
      }))
    );
  };

  const onDecrementByDefaultAmount = (servingAmount: FullServingAmountFragment) => {
    const decrementAmount = squareRootDecrementAmount(servingAmount);
    onChange(
      replaceServingAmountWith(servingAmount, servingAmounts, sa => {
        const amount = Math.max(sa.amount - decrementAmount);
        return {
          ...sa,
          amount,
        };
      })
    );
  };

  const onReplaceServing = (servingAmount: FullServingAmountFragment, serving: FullServingFragment, newAmount?: number) => {
    onChange(
      replaceServingAmountWith(servingAmount, servingAmounts, () => ({
        ...servingAmount,
        serving,
        amount: newAmount ?? serving.defaultAmount ?? servingAmount.amount,
      }))
    );
  };
  const onSetAmount = (servingAmount: FullServingAmountFragment, amount: number) => {
    onChange(
      replaceServingAmountWith(servingAmount, servingAmounts, () => ({
        ...servingAmount,
        amount,
      }))
    );
  };

  const onDeconstructRecipe = async (servingAmount: FullServingAmountFragment) => {
    const recipeId = servingAmount.serving.foodOrRecipe.__typename === "Recipe" && servingAmount.serving.foodOrRecipe.id;
    const recipeServingYield = servingAmount.serving.perRecipeYield;
    if (!recipeId || !recipeServingYield) {
      return;
    }
    const { data } = await client.query<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>({
      query: RecipeWithFullIngredientsDocument,
      variables: { id: recipeId },
    });
    if (!data) {
      return;
    }
    const newServingAmounts = [...servingAmounts.filter(sa => sa.id !== servingAmount.id), ...data.recipe.ingredients];
    onChange(
      scaleServingAmounts(
        newServingAmounts.map((sa, index) => ({ ...sa, position: index + 1 })),
        servingAmount.amount / recipeServingYield
      )
    );
  };

  const sortedServingAmounts = sortByKey(servingAmounts, "position");

  return {
    onChangeOrder,
    onAddServingAmount,
    onDelete,
    onIncrement,
    onIncrementByDefaultAmount,
    onDecrement,
    onDecrementByDefaultAmount,
    onSetAmount,
    onReplaceServing,
    onDeconstructRecipe,
    sortedServingAmounts,
  };
};

export const areServingAmountsEqual = (sa1: readonly FullServingAmountFragment[], sa2: readonly FullServingAmountFragment[]): boolean => {
  if (sa1.length !== sa2.length) return false;
  return sa1.every(({ serving, amount, position }) => {
    const matchingServingAmount = sa2.find(sa => sa.serving.id === serving.id);
    return matchingServingAmount && matchingServingAmount.amount === amount && matchingServingAmount.position === position;
  });
};

export const servingAmountsToInputs = (servingAmounts: readonly FullServingAmountFragment[]): CreateServingAmountInput[] => {
  return servingAmounts.map(({ serving, amount, position }) => ({
    servingId: serving.id,
    amount,
    position,
  }));
};

interface ScalableServingAmount {
  __typename: "ServingAmount";
  amount: number;
}

export const scaleServingAmount = <SA extends ScalableServingAmount>(servingAmount: SA, scale: number): SA => {
  return { ...servingAmount, amount: servingAmount.amount * scale };
};

export const scaleServingAmounts = <SA extends ScalableServingAmount>(servingAmounts: readonly SA[], scale: number): readonly SA[] => {
  return servingAmounts.map(sa => scaleServingAmount(sa, scale));
};

export const mergeServingAmounts = (servingAmounts: readonly FullServingAmountFragment[]): readonly FullServingAmountFragment[] => {
  const merged = servingAmounts.reduce<readonly FullServingAmountFragment[]>((mergedSAs, nextSA) => {
    const matchingSA = mergedSAs.find(sa => sa.serving.id === nextSA.serving.id);
    if (matchingSA) {
      return [...mergedSAs.filter(sa => sa.id !== matchingSA.id), { ...matchingSA, amount: matchingSA.amount + nextSA.amount }];
    } else {
      return [...mergedSAs, nextSA];
    }
  }, []);
  return sortByKey(merged, "position").map((sa, position) => ({
    ...sa,
    position,
  }));
};

export const servingAmountsUseOldRecipe = (servingAmounts: readonly FullServingAmountFragment[]): boolean => {
  return servingAmounts.some(sa => sa.serving.foodOrRecipe.__typename === "Recipe" && !sa.serving.foodOrRecipe.isCurrent);
};

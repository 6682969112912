import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, TextField, Tooltip } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { serializeDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import { DurationSelectType, TimeDurationSelectSchema } from "apps/web/src/componentLibrary/DurationSelect/FormSchema";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import ActivityTypeSelect from "apps/web/src/components/Activity/TypeSelect";
import { CalendarEvent } from "apps/web/src/components/Calendar/Week/utils";
import MealTypeSelect from "apps/web/src/components/Meal/TypeSelect";
import { ActivityType, MealType } from "apps/web/src/types";
import addMinutes from "date-fns/addMinutes";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NewEvent } from "../../../Form/utils";
import ActionPopoverBase from "../ActionBase";

interface MealPlanCalendarEventNewContentProps {
  newEvent: CalendarEvent;
  anchorEl: HTMLElement;
  onClose: () => void;
  onConfirm: (event: NewEvent) => void;
}

const MealPlanCalendarEventNewContent = ({ newEvent, anchorEl, onClose, onConfirm }: MealPlanCalendarEventNewContentProps) => {
  const MEAL = "Meal";
  const ACTIVITY = "Activity";
  const [selected, setSelected] = useState(MEAL);
  const tabs = [MEAL, ACTIVITY];
  const [mealType, setMealType] = useState<MealType>("breakfast");
  const [activityType, setActivityType] = useState<ActivityType>("practice");
  const [eventName, setEventName] = useState<string>("");

  const form = useForm<DurationSelectType>({
    defaultValues: {
      start: newEvent.start,
      end: addMinutes(newEvent.start, newEvent.durationInMinutes),
    },
    resolver: zodResolver(TimeDurationSelectSchema()),
  });

  const hasFormErrors = form.formState.isSubmitted && !form.formState.isValid;

  const handleConfirm = (duration: DurationSelectType) => {
    const { start, end } = duration;
    const startDatetime = new Date(newEvent.start);
    startDatetime.setHours(start.getHours(), start.getMinutes());
    const endDatetime = new Date(newEvent.start);
    endDatetime.setHours(end.getHours(), end.getMinutes());

    const eventPayload = {
      name: eventName ? eventName : selected === MEAL ? mealType : activityType,
      start: serializeDateTime(startDatetime),
      end: serializeDateTime(endDatetime),
    };

    onClose();
    if (selected === MEAL) {
      onConfirm({
        ...eventPayload,
        type: mealType,
        __typename: "Meal",
      });
    } else {
      onConfirm({
        ...eventPayload,
        type: activityType,
        __typename: "Activity",
      });
    }
  };

  return (
    <ActionPopoverBase
      anchorEl={anchorEl}
      onClose={onClose}
      title={"Add Event"}
      variant="xl"
      buttons={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
            <Button onClick={form.handleSubmit(handleConfirm)}>Save</Button>
          </Tooltip>
        </>
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TWTabGroup tabs={tabs} onSelected={selected => setSelected(selected)} />
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: 2, pt: 2 }}>
          <TextField
            sx={{ width: "50%" }}
            label={selected === MEAL ? "Meal Name" : "Activity Name"}
            value={eventName}
            onChange={e => setEventName(e.currentTarget.value)}
          />
          {selected === MEAL ? (
            <MealTypeSelect
              sx={{ width: "50%" }}
              value={mealType}
              onChange={setMealType} />
          ) : (
            <ActivityTypeSelect
              sx={{ width: "50%" }}
              value={activityType}
              onChange={setActivityType} />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 2, mt: 5, mb: 4 }}>
          <Controller
            control={form.control}
            name="start"
            render={({ field }) => (
              <DesktopTimePicker
                {...field}
                label="Start Time"
                slotProps={{
                  textField: {
                    helperText: form.formState.errors.start?.message,
                    error: Boolean(form.formState.errors.start),
                  },
                }}
              />
            )}
          />
          <Controller
            control={form.control}
            name="end"
            render={({ field }) => (
              <DesktopTimePicker
                {...field}
                label="End Time"
                slotProps={{
                  textField: {
                    helperText: form.formState.errors.end?.message,
                    error: Boolean(form.formState.errors.end),
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </ActionPopoverBase>
  );
};

export default MealPlanCalendarEventNewContent;

import React from "react";

import { NoteType } from "../../types";
import LabeledSelect from "../universal/LabeledSelect";

interface NoteTypeSelectProps {
  selectedType: NoteType | null;
  onChange: (type: NoteType) => void;
  options: NoteType[];
}

const NoteTypeSelect = ({ selectedType, onChange, options }: NoteTypeSelectProps) => {
  return (
    <LabeledSelect<NoteType>
      placeholder="Select Note Type"
      options={options}
      selectedOption={selectedType}
      onChange={onChange}
      optionToName={(opt: NoteType) => opt.name}
    />
  );
};

export default NoteTypeSelect;

import { Box, Grid, IconButton, SxProps, Typography, useTheme } from "@mui/material";
import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";

interface ErrorBoxProps {
  errorMessage: string;
  onClose: () => void;
  sx?: SxProps;
}

export const ErrorBox = ({ errorMessage, onClose, sx }: ErrorBoxProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.error.lighter,
        padding: theme.spacing(2),
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(1),
        borderRadius: "6px",
        ...sx,
      }}
    >
      <Grid
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        gap={theme.spacing(1)}
        width="100%">
        <Grid
          item
          gap={theme.spacing(1)}
          display="flex"
          flexDirection="row">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="flex-start">
            <ErrorIcon sx={{ color: theme.palette.error.main }} />
          </Box>
          <Box gap={theme.spacing(1)}>
            <Box height="18px" lineHeight="18px">
              <Typography
                variant="body1Semibold"
                fontSize="14px"
                lineHeight="18px">
                Error
              </Typography>
            </Box>
            <Typography
              variant="body1"
              fontSize="14px"
              lineHeight="18px">
              {errorMessage}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <IconButton sx={{ padding: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export const CHO_FDC_NUTRIENT_ID = 1005;
export const PRO_FDC_NUTRIENT_ID = 1003;
export const FAT_FDC_NUTRIENT_ID = 1004;
export const ENERGY_FDC_NUTRIENT_ID = 1008;

export const MACRO_FDC_NUTRIENT_IDS: number[] = [CHO_FDC_NUTRIENT_ID, PRO_FDC_NUTRIENT_ID, FAT_FDC_NUTRIENT_ID, ENERGY_FDC_NUTRIENT_ID];

export const APP_KEY_TO_MACRO_FDC_NUTRIENT_ID_MAP: Record<string, number> = {
  kcal: ENERGY_FDC_NUTRIENT_ID,
  cho: CHO_FDC_NUTRIENT_ID,
  pro: PRO_FDC_NUTRIENT_ID,
  fat: FAT_FDC_NUTRIENT_ID,
};

interface Macros {
  cho: number;
  pro: number;
  fat: number;
}

export const caloriesFromMacros = (macros: Macros): number => {
  return macros.cho * 4 + macros.pro * 4 + macros.fat * 9;
};

export type NutrientName =
  | "Nitrogen"
  | "Protein"
  | "Total lipid (fat)"
  | "Carbohydrate, by difference"
  | "Ash"
  | "Energy"
  | "Starch"
  | "Sucrose"
  | "Glucose (dextrose)"
  | "Fructose"
  | "Lactose"
  | "Maltose"
  | "Alcohol, ethyl"
  | "Specific Gravity"
  | "Acetic acid"
  | "Lactic acid"
  | "Carbohydrate, by summation"
  | "Water"
  | "Sorbitol"
  | "Caffeine"
  | "Theobromine"
  | "Sugars, Total NLEA"
  | "Carbohydrate, other"
  | "Galactose"
  | "Xylitol"
  | "Fiber, total dietary"
  | "Ribose"
  | "Fiber, soluble"
  | "Fiber, insoluble"
  | "Total fat (NLEA)"
  | "Total sugar alcohols"
  | "Calcium, Ca"
  | "Chlorine, Cl"
  | "Iron, Fe"
  | "Magnesium, Mg"
  | "Phosphorus, P"
  | "Potassium, K"
  | "Sodium, Na"
  | "Sulfur, S"
  | "Zinc, Zn"
  | "Chromium, Cr"
  | "Cobalt, Co"
  | "Copper, Cu"
  | "Fluoride, F"
  | "Iodine, I"
  | "Manganese, Mn"
  | "Molybdenum, Mo"
  | "Selenium, Se"
  | "Vitamin A, IU"
  | "Retinol"
  | "Vitamin A, RAE"
  | "Carotene, beta"
  | "Carotene, alpha"
  | "Vitamin E (alpha-tocopherol)"
  | "Vitamin D (D2 + D3), International Units"
  | "Vitamin D2 (ergocalciferol)"
  | "Vitamin D3 (cholecalciferol)"
  | "25-hydroxycholecalciferol"
  | "Vitamin D (D2 + D3)"
  | "Phytoene"
  | "Phytofluene"
  | "Zeaxanthin"
  | "Cryptoxanthin, beta"
  | "Lutein"
  | "Lycopene"
  | "Lutein + zeaxanthin"
  | "Vitamin E (label entry primarily)"
  | "Tocopherol, beta"
  | "Tocopherol, gamma"
  | "Tocopherol, delta"
  | "Tocotrienol, alpha"
  | "Tocotrienol, beta"
  | "Tocotrienol, gamma"
  | "Tocotrienol, delta"
  | "Boron, B"
  | "Nickel, Ni"
  | "Vitamin E"
  | "cis-beta-Carotene"
  | "cis-Lycopene"
  | "cis-Lutein/Zeaxanthin"
  | "Vitamin C, total ascorbic acid"
  | "Thiamin"
  | "Riboflavin"
  | "Niacin"
  | "Pantothenic acid"
  | "Vitamin B-6"
  | "Biotin"
  | "Folate, total"
  | "Vitamin B-12"
  | "Choline, total"
  | "Inositol"
  | "Vitamin K (Menaquinone-4)"
  | "Vitamin K (Dihydrophylloquinone)"
  | "Vitamin K (phylloquinone)"
  | "Folic acid"
  | "Folate, food"
  | "5-methyl tetrahydrofolate (5-MTHF)"
  | "Folate, DFE"
  | "10-Formyl folic acid (10HCOFA)"
  | "5-Formyltetrahydrofolic acid (5-HCOH4"
  | "Choline, free"
  | "Choline, from phosphocholine"
  | "Choline, from phosphotidyl choline"
  | "Choline, from glycerophosphocholine"
  | "Betaine"
  | "Choline, from sphingomyelin"
  | "Tryptophan"
  | "Threonine"
  | "Isoleucine"
  | "Leucine"
  | "Lysine"
  | "Methionine"
  | "Cystine"
  | "Phenylalanine"
  | "Tyrosine"
  | "Valine"
  | "Arginine"
  | "Histidine"
  | "Alanine"
  | "Aspartic acid"
  | "Glutamic acid"
  | "Glycine"
  | "Proline"
  | "Serine"
  | "Hydroxyproline"
  | "Cysteine"
  | "Glutamine"
  | "Taurine"
  | "Sugars, added"
  | "Vitamin E, added"
  | "Vitamin B-12, added"
  | "Cholesterol"
  | "Fatty acids, total trans"
  | "Fatty acids, total saturated"
  | "4:0"
  | "6:0"
  | "8:0"
  | "10:0"
  | "12:0"
  | "14:0"
  | "16:0"
  | "18:0"
  | "20:0"
  | "18:1"
  | "18:2"
  | "18:3"
  | "20:4"
  | "22:6 n-3 (DHA)"
  | "22:0"
  | "14:1"
  | "16:1"
  | "18:4"
  | "20:1"
  | "20:5 n-3 (EPA)"
  | "22:1"
  | "22:5 n-3 (DPA)"
  | "14:1 t"
  | "Phytosterols"
  | "Stigmasterol"
  | "Campesterol"
  | "Brassicasterol"
  | "Beta-sitosterol"
  | "Campestanol"
  | "Fatty acids, total monounsaturated"
  | "Fatty acids, total polyunsaturated"
  | "Beta-sitostanol"
  | "Delta-5-avenasterol"
  | "Phytosterols, other"
  | "15:0"
  | "17:0"
  | "24:0"
  | "16:1 t"
  | "18:1 t"
  | "22:1 t"
  | "18:2 t not further defined"
  | "18:2 i"
  | "18:2 t,t"
  | "18:2 CLAs"
  | "24:1 c"
  | "20:2 n-6 c,c"
  | "16:1 c"
  | "18:1 c"
  | "18:2 n-6 c,c"
  | "22:1 c"
  | "18:3 n-6 c,c,c"
  | "17:1"
  | "20:3"
  | "Fatty acids, total trans-monoenoic"
  | "Fatty acids, total trans-dienoic"
  | "Fatty acids, total trans-polyenoic"
  | "13:0"
  | "15:1"
  | "22:2"
  | "11:0"
  | "Epigallocatechin-3-gallate"
  | "Inulin"
  | "18:3 n-3 c,c,c (ALA)"
  | "20:3 n-3"
  | "20:3 n-6"
  | "20:4 n-6"
  | "18:3i"
  | "21:5"
  | "22:4"
  | "18:1-11 t (18:1t n-7)"
  | "20:3 n-9"
  | "Sugars, total including NLEA"
  | "5:0"
  | "7:0"
  | "9:0"
  | "21:0"
  | "23:0"
  | "12:1"
  | "14:1 c"
  | "17:1 c"
  | "20:1 c"
  | "20:1 t"
  | "22:1 n-9"
  | "22:1 n-11"
  | "18:2 c"
  | "18:3 c"
  | "18:3 t"
  | "20:3 c"
  | "22:3"
  | "20:4 c"
  | "20:5 c"
  | "22:5 c"
  | "22:6 c"
  | "20:2 c"
  | "trans-beta-Carotene"
  | "trans-Lycopene"
  | "Cryptoxanthin, alpha";

export type CommonNutrientName =
  | "Fat"
  | "Monounsaturated Fat"
  | "Polyunsaturated Fat"
  | "ALA"
  | "EPA"
  | "DPA"
  | "DHA"
  | "Saturated Fat"
  | "Trans Fat"
  | "Cholesterol"
  | "Linoleic Acid"
  // Carbs
  | "Total Sugars"
  | "Added Sugars"
  | "Fiber"
  | "Starch"
  | "Total Carbs"
  // Amino Acids
  | "Protein"
  // Essential
  | "Histidine"
  | "Isoleucine"
  | "Leucine"
  | "Methionine"
  | "Phenylalanine"
  | "Threonine"
  | "Tryptophan"
  | "Valine"
  | "Lysine"
  // Non-essential
  | "Cystine"
  | "Tyrosine"
  | "Arginine"
  | "Alanine"
  | "Glutamate"
  | "Glutamine"
  | "Aspartic Acid"
  | "Glycine"
  | "Proline"
  | "Serine"
  // Vitamins
  | "Vitamin A"
  | "Vitamin E"
  | "Vitamin D"
  | "Vitamin C"
  | "Thiamin"
  | "Riboflavin"
  | "Niacin"
  | "Vitamin B6"
  | "Vitamin B12"
  | "Choline"
  | "Vitamin K"
  | "Folate"
  // Minerals
  | "Calcium"
  | "Iron"
  | "Magnesium"
  | "Phosphorus"
  | "Potassium"
  | "Sodium"
  | "Zinc"
  | "Copper"
  | "Manganese"
  | "Selenium";

export const lipids = [
  "Fat",
  "Monounsaturated Fat",
  "Polyunsaturated Fat",
  "Saturated Fat",
  "Trans Fat",
  "Cholesterol",
  "EPA",
  "DPA",
  "DHA",
  "ALA",
  "Linoleic Acid",
] as const;

export const minerals = [
  "Calcium",
  "Iron",
  "Magnesium",
  "Phosphorus",
  "Potassium",
  "Sodium",
  "Zinc",
  "Copper",
  "Manganese",
  "Selenium",
] as const;

export const carbs = ["Total Carbs", "Fiber", "Total Sugars", "Starch"] as const;

export const proteins = [
  "Protein",
  "Histidine",
  "Isoleucine",
  "Leucine",
  "Methionine",
  "Phenylalanine",
  "Threonine",
  "Tryptophan",
  "Valine",
  "Lysine",
  // Non-essential
  "Cystine",
  "Tyrosine",
  "Arginine",
  "Alanine",
  "Glutamate",
  "Glutamine",
  "Aspartic Acid",
  "Glycine",
  "Proline",
  "Serine",
] as const;

export const vitamins = [
  "Vitamin A",
  "Vitamin E",
  "Vitamin D",
  "Vitamin C",
  "Thiamin",
  "Riboflavin",
  "Niacin",
  "Vitamin B6",
  "Vitamin B12",
  "Choline",
  "Vitamin K",
  "Folate",
] as const;

export const usdaNameToUnit = (nutrientName: NutrientName): string | null => {
  switch (nutrientName) {
    case "Total lipid (fat)":
    case "Fatty acids, total saturated":
    case "Fatty acids, total trans":
    case "Carbohydrate, by difference":
    case "Sugars, added":
    case "Fiber, total dietary":
    case "Sugars, total including NLEA":
    case "Protein":
      return "g";
    case "Calcium, Ca":
    case "Iron, Fe":
    case "Potassium, K":
    case "Sodium, Na":
    case "Cholesterol":
      return "mg";
    case "Vitamin D (D2 + D3), International Units":
      return "mcg";
    default:
      return null;
  }
};

export const usdaNameToCommonName = (nutrientName: NutrientName): CommonNutrientName | null => {
  switch (nutrientName) {
    // Lipids
    case "Total lipid (fat)":
      return "Fat";
    case "Fatty acids, total monounsaturated":
      return "Monounsaturated Fat";
    case "Fatty acids, total polyunsaturated":
      return "Polyunsaturated Fat";
    case "Fatty acids, total saturated":
      return "Saturated Fat";
    case "Fatty acids, total trans":
      return "Trans Fat";
    case "Cholesterol":
      return "Cholesterol";
    case "18:3 n-3 c,c,c (ALA)":
      return "ALA";
    case "18:2 n-6 c,c":
      return "Linoleic Acid";
    case "20:5 n-3 (EPA)":
      return "EPA";
    case "22:5 n-3 (DPA)":
      return "DPA";
    case "22:6 n-3 (DHA)":
      return "DHA";
    //Carbs
    case "Carbohydrate, by difference":
      //case "Carbohydrate, by summation":
      return "Total Carbs";
    case "Sugars, added":
      return "Added Sugars";
    case "Starch":
      return "Starch";
    case "Fiber, total dietary":
      return "Fiber";
    //case "Sugars, Total NLEA":
    case "Sugars, total including NLEA":
      return "Total Sugars";
    //Protein
    case "Protein":
      return "Protein";
    case "Aspartic acid":
      return "Aspartic Acid";
    case "Glutamic acid":
      return "Glutamate";
    case "Arginine":
    case "Alanine":
    case "Glutamine":
    case "Glycine":
    case "Proline":
    case "Serine":
    case "Cystine":
    case "Tyrosine":
    case "Histidine": // Essential Amino Acids below here
    case "Isoleucine":
    case "Leucine":
    case "Methionine":
    case "Phenylalanine":
    case "Threonine":
    case "Tryptophan":
    case "Valine":
    case "Lysine":
      return nutrientName as CommonNutrientName;
    // Minerals
    case "Calcium, Ca":
      return "Calcium";
    case "Iron, Fe":
      return "Iron";
    case "Magnesium, Mg":
      return "Magnesium";
    case "Phosphorus, P":
      return "Phosphorus";
    case "Potassium, K":
      return "Potassium";
    case "Sodium, Na":
      return "Sodium";
    case "Zinc, Zn":
      return "Zinc";
    case "Copper, Cu":
      return "Copper";
    case "Manganese, Mn":
      return "Manganese";
    case "Selenium, Se":
      return "Selenium";
    // Vitamins
    case "Vitamin A, RAE":
      return "Vitamin A";
    case "Vitamin E (alpha-tocopherol)":
      return "Vitamin E";
    case "Vitamin D (D2 + D3), International Units":
      return "Vitamin D";
    case "Vitamin C, total ascorbic acid":
      return "Vitamin C";
    case "Thiamin":
      return "Thiamin";
    case "Riboflavin":
      return "Riboflavin";
    case "Niacin":
      return "Niacin";
    case "Vitamin B-6":
      return "Vitamin B6";
    case "Vitamin B-12":
      return "Vitamin B12";
    case "Choline, total":
      return "Choline";
    case "Vitamin K (phylloquinone)":
      return "Vitamin K";
    case "Folate, DFE":
      return "Folate";
    default:
      return null;
  }
};

//// **** Creating Nutrient Categories for API **** ////

type NutrientCategory = "Lipid" | "Mineral" | "Carbohydrate" | "Protein" | "Vitamin" | "Phytonutrient" | "Other";

const lipidsForCategorizing = [
  "Total lipid (fat)",
  "Total fat (NLEA)",
  "Cholesterol",
  "Fatty acids, total trans",
  "Fatty acids, total saturated",
  "Fatty acids, total monounsaturated",
  "Fatty acids, total polyunsaturated",
  "Fatty acids, total trans-monoenoic",
  "Fatty acids, total trans-dienoic",
  "Fatty acids, total trans-polyenoic",
  "Linoleic Acid",
  "10:0",
  "11:0",
  "12:0",
  "12:1",
  "13:0",
  "14:0",
  "14:1",
  "14:1 c",
  "14:1 t",
  "15:0",
  "15:1",
  "16:0",
  "16:1",
  "16:1 c",
  "16:1 t",
  "17:0",
  "17:1",
  "17:1 c",
  "18:0",
  "18:1",
  "18:1 c",
  "18:1 t",
  "18:1-11 t (18:1t n-7)",
  "18:2",
  "18:2 CLAs",
  "18:2 c",
  "18:2 i",
  "18:2 n-6 c,c",
  "18:2 t not further defined",
  "18:2 t,t",
  "18:3",
  "18:3 c",
  "18:3 n-3 c,c,c (ALA)",
  "18:3 n-6 c,c,c",
  "18:3 t",
  "18:3i",
  "18:4",
  "20:0",
  "20:1",
  "20:1 c",
  "20:1 t",
  "20:2 c",
  "20:2 n-6 c,c",
  "20:3",
  "20:3 c",
  "20:3 n-3",
  "20:3 n-6",
  "20:3 n-9",
  "20:4",
  "20:4 c",
  "20:4 n-6",
  "20:5 c",
  "20:5 n-3 (EPA)",
  "21:0",
  "21:5",
  "22:0",
  "22:1",
  "22:1 c",
  "22:1 n-11",
  "22:1 n-9",
  "22:1 t",
  "22:2",
  "22:3",
  "22:4",
  "22:5 c",
  "22:5 n-3 (DPA)",
  "22:6 c",
  "22:6 n-3 (DHA)",
  "23:0",
  "24:0",
  "24:1 c",
  "Stigmasterol",
  "Campesterol",
  "Brassicasterol",
  "Beta-sitosterol",
  "Campestanol",
  "Beta-sitostanol",
  "Delta-5-avenasterol",
  "Phytosterols",
  "Phytosterols, other",
] as const;

const mineralsForCategorizing = [
  "Boron, B",
  "Calcium, Ca",
  "Chlorine, Cl",
  "Chromium, Cr",
  "Cobalt, Co",
  "Copper, Cu",
  "Fluoride, F",
  "Iodine, I",
  "Iron, Fe",
  "Magnesium, Mg",
  "Manganese, Mn",
  "Molybdenum, Mo",
  "Nickel, Ni",
  "Phosphorus, P",
  "Potassium, K",
  "Selenium, Se",
  "Sodium, Na",
  "Sulfur, S",
  "Zinc, Zn",
] as const;

const carbsForCategorizing = [
  "Carbohydrate, by difference",
  "Carbohydrate, by summation",
  "Carbohydrate, other",
  "Starch",
  "Sucrose",
  "Glucose (dextrose)",
  "Fructose",
  "Lactose",
  "Maltose",
  "Galactose",
  "Xylitol",
  "Sorbitol",
  "Sugars, Total NLEA",
  "Sugars, added",
  "Sugars, total including NLEA",
  "Total sugar alcohols",
  "Fiber, total dietary",
  "Fiber, soluble",
  "Fiber, insoluble",
  "Ribose",
  "Inulin",
  "Inositol",
] as const;

const proteinsForCategorizing = [
  "Protein",
  "Histidine",
  "Isoleucine",
  "Leucine",
  "Lysine",
  "Methionine",
  "Phenylalanine",
  "Threonine",
  "Tryptophan",
  "Valine",
  "Alanine",
  "Arginine",
  "Aspartic acid",
  "Cysteine",
  "Cystine",
  "Glutamic acid",
  "Glutamine",
  "Glycine",
  "Proline",
  "Serine",
  "Hydroxyproline",
  "Tyrosine",
] as const;

const vitaminsForCategorizing = [
  "10-Formyl folic acid (10HCOFA)",
  "25-hydroxycholecalciferol",
  "5-Formyltetrahydrofolic acid (5-HCOH4)",
  "5-methyl tetrahydrofolate (5-MTHF)",
  "Biotin",
  "Epigallocatechin-3-gallate",
  "Folate, DFE",
  "Folate, food",
  "Folate, total",
  "Folic acid",
  "Niacin",
  "Pantothenic acid",
  "Retinol",
  "Riboflavin",
  "Tocopherol, beta",
  "Tocopherol, delta",
  "Tocopherol, gamma",
  "Tocotrienol, alpha",
  "Tocotrienol, beta",
  "Tocotrienol, delta",
  "Tocotrienol, gamma",
  "Vitamin A, IU",
  "Vitamin A, RAE",
  "Vitamin B-12",
  "Vitamin B-12, added",
  "Vitamin B-6",
  "Vitamin C, total ascorbic acid",
  "Vitamin D (D2 + D3)",
  "Vitamin D (D2 + D3), International Units",
  "Vitamin D2 (ergocalciferol)",
  "Vitamin D3 (cholecalciferol)",
  "Vitamin E",
  "Vitamin E (alpha-tocopherol)",
  "Vitamin E (label entry primarily)",
  "Vitamin E, added",
  "Vitamin K (Dihydrophylloquinone)",
  "Vitamin K (Menaquinone-4)",
  "Vitamin K (phylloquinone)",
  "Choline, total",
  "Choline, free",
  "Choline, from phosphocholine",
  "Choline, from phosphotidyl choline",
  "Choline, from glycerophosphocholine",
  "Choline, from sphingomyelin",
  "Thiamin",
] as const;

const phytonutrientsForCategorizing = [
  "Carotene, alpha",
  "Carotene, beta",
  "Cryptoxanthin, alpha",
  "Cryptoxanthin, beta",
  "Lutein",
  "Lutein + zeaxanthin",
  "Lycopene",
  "Zeaxanthin",
  "cis-Lutein/Zeaxanthin",
  "cis-Lycopene",
  "cis-beta-Carotene",
  "trans-Lycopene",
  "trans-beta-Carotene",
  "Phytoene",
  "Phytofluene",
] as const;

const othersForCategorizing = [
  "Nitrogen",
  "Ash",
  "Energy",
  "Alcohol, ethyl",
  "Specific Gravity",
  "Acetic acid",
  "Lactic acid",
  "Water",
  "Caffeine",
  "Theobromine",
  "Betaine",
  "Taurine",
] as const;

const nutrientCategoryMapping: { [key in NutrientCategory]: readonly string[] } = {
  Lipid: lipidsForCategorizing,
  Mineral: mineralsForCategorizing,
  Carbohydrate: carbsForCategorizing,
  Protein: proteinsForCategorizing,
  Vitamin: vitaminsForCategorizing,
  Phytonutrient: phytonutrientsForCategorizing,
  Other: othersForCategorizing,
};

export const getCategoryFromName = (nutrientName: string): NutrientCategory => {
  for (const [category, nutrients] of Object.entries(nutrientCategoryMapping)) {
    if (nutrients.includes(nutrientName)) {
      return category as NutrientCategory;
    }
  }
  return "Other";
};

import { Box, TextField, Typography } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { ScoreSelect } from "libs/shared/ui/src/lib/Score/ScoreSelect";
import React, { Dispatch } from "react";
import { FoodCategoryNameFragment, MenuItemSuggestionCategory, ScoreAssignmentFragment, ScoringSystemFragment } from "../../../types";
import { FoodCategorySelect } from "../../FoodCategory/FoodCategorySelect";
import { MenuItemAction, MenuItemState } from "../reducer";
import { MenuItemSuggestionCategorySelect } from "./SuggestionCategory/Select";

const DIGITAL_DISPLAY_ITEM_NAME_MAYBE_TRUNCATED = 45;
const DIGITAL_DISPLAY_ITEM_NAME_TRUNCATED = 65;

const DIGITAL_DISPLAY_DESCRIPTION_MAYBE_TRUNCATED = 55;
const DIGITAL_DISPLAY_DESCRIPTION_TRUNCATED = 90;

interface ItemDetailsProps {
  state: MenuItemState;
  dispatch: Dispatch<MenuItemAction>;
  scoringSystem?: ScoringSystemFragment | null;
}

export const ItemDetails = ({ dispatch, state, scoringSystem }: ItemDetailsProps) => {
  const { menuItem } = state;
  const { name, description, servingName, suggestionCategory, score, foodCategory } = menuItem;
  const servingNameStartsWithNumber = !isNaN(parseFloat(servingName));
  const hasFoodCategory = useHasFeature("foodCategory");
  const hasDigitalDisplays = useHasFeature("digitalDisplays");

  return (
    <Box sx={{ flexDirection: "column" }}>
      <ItemDetailsHeader />
      <TextField
        label="Menu Item Name"
        placeholder='e.g. "Spinach and Tomato Omelette"'
        autoFocus={!name}
        fullWidth
        sx={{ pb: 2 }}
        value={name}
        onChange={({ target: { value } }) =>
          dispatch({
            type: "MenuItemChangeNameAction",
            payload: { name: value },
          })
        }
        helperText={
          hasDigitalDisplays &&
          (name.length > DIGITAL_DISPLAY_ITEM_NAME_MAYBE_TRUNCATED
            ? name.length > DIGITAL_DISPLAY_ITEM_NAME_TRUNCATED
              ? "Menu item name will be truncated on menu display"
              : "Menu item name may be truncated on menu display"
            : undefined)
        }
      />
      <TextField
        label="Description"
        multiline
        maxRows={4}
        fullWidth
        sx={{ pb: 2 }}
        value={description || ""}
        onChange={({ target: { value } }) =>
          dispatch({
            type: "MenuItemChangeDescriptionAction",
            payload: { description: value || null },
          })
        }
        helperText={
          hasDigitalDisplays &&
          description &&
          (description.length > DIGITAL_DISPLAY_DESCRIPTION_MAYBE_TRUNCATED
            ? description.length > DIGITAL_DISPLAY_DESCRIPTION_TRUNCATED
              ? "Description will be truncated on menu display"
              : "Description may be truncated on menu display"
            : undefined)
        }
      />

      <Box sx={{ display: "flex", flexWrap: "wrap", rowGap: 2, columnGap: 2 }}>
        <TextField
          label="Portion Name"
          placeholder='e.g. "omelette"'
          autoFocus={!!name && !servingName}
          value={servingName}
          error={servingNameStartsWithNumber}
          onChange={({ target: { value } }) =>
            dispatch({
              type: "MenuItemChangeServingNameAction",
              payload: { servingName: value },
            })
          }
          helperText="e.g. serving, piece, omelette"
          FormHelperTextProps={{
            sx: { ml: 0 },
          }}
        />
        <MenuItemSuggestionCategorySelect
          value={suggestionCategory}
          onChange={category =>
            dispatch({
              type: "MenuItemChangeSuggestionCategoryAction",
              payload: { suggestionCategory: category },
            })
          }
        />
        {scoringSystem && (
          <ScoreSelect
            scoreValue={score?.value ?? null}
            scoringSystem={scoringSystem}
            onChange={scoreValue =>
              dispatch({
                type: "MenuItemChangeScoreAction",
                payload: { scoreValue, scoringSystem },
              })
            }
          />
        )}
        {hasFoodCategory && (
          <FoodCategorySelect
            category={foodCategory?.category ?? null}
            onChange={value =>
              dispatch({
                type: "MenuItemChangeFoodCategoryAction",
                payload: { foodCategory: value },
              })
            }
          />
        )}
      </Box>
    </Box>
  );
};

interface ReadonlyItemDetailsProps {
  name: string;
  servingName?: string;
  description?: string | null;
  suggestionCategory: MenuItemSuggestionCategory | null;
  foodCategory: FoodCategoryNameFragment | null;
  score: ScoreAssignmentFragment | null;
  scoringSystem?: ScoringSystemFragment | null;
}

export const ReadonlyItemDetails = ({
  name,
  servingName,
  description,
  suggestionCategory,
  foodCategory,
  score,
  scoringSystem,
}: ReadonlyItemDetailsProps) => {
  const hasFoodCategory = useHasFeature("foodCategory");

  return (
    <>
      <ItemDetailsHeader />
      <TextField
        label="Menu Item Name"
        disabled
        fullWidth
        sx={{ pb: 2 }}
        value={name} />
      <TextField
        label="Description"
        disabled
        multiline
        maxRows={4}
        fullWidth
        sx={{ pb: 2 }}
        value={description || ""} />
      <Box sx={{ display: "flex", flexWrap: "wrap", rowGap: 2, columnGap: 2 }}>
        <TextField
          disabled
          label="Portion Name"
          value={servingName}
          helperText="e.g. serving, piece, omelette"
          FormHelperTextProps={{
            sx: { ml: 0 },
          }}
        />
        <MenuItemSuggestionCategorySelect
          value={suggestionCategory}
          onChange={() => {}}
          disabled />
        {scoringSystem && <ScoreSelect
          scoreValue={score?.value ?? null}
          scoringSystem={scoringSystem}
          onChange={() => {}}
          disabled />}
        {hasFoodCategory && <FoodCategorySelect
          category={foodCategory?.category ?? null}
          onChange={() => {}}
          disabled />}
      </Box>
    </>
  );
};

const ItemDetailsHeader = () => {
  return (
    <Typography
      variant="h3"
      color="textSecondary"
      sx={{ pb: 2 }}>
      Menu Item Details
    </Typography>
  );
};

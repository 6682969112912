import { sortByKey } from "@notemeal/utils/sort";

type MealPlanWithDates = {
  id: string;
  createdAt: string;
};

/**
 * The default meal plan at the moment is determined by:
 * If there is only 1 shared meal plan, that's the default.
 * If there's more than one shared meal plan, we take the first shared Meal Plan by date.
 */
export const getDefaultMealPlan = (mealPlans: readonly MealPlanWithDates[]): MealPlanWithDates | null => {
  if (mealPlans.length === 0) {
    return null;
  }

  if (mealPlans.length === 1) {
    return mealPlans[0];
  }

  const sortedMealPlans = sortByKey(mealPlans, "createdAt", { reverse: true });
  return sortedMealPlans[0];
};

import React from "react";
import DisplayCard, { DisplayCardProps } from "../../shared/DisplayCard";
import { NotemealTeam, PendingTeam, TeamworksTeam } from "../reducer";

export type TeamLike = TeamworksTeam | PendingTeam | NotemealTeam;

type TeamCardProps<T> = Omit<DisplayCardProps<T>, "primaryText" | "secondaryElement">;

const TeamCard = <T extends TeamLike>(props: TeamCardProps<T>) => {
  const { element } = props;
  const primaryText = element.name;
  const secondaryElement = element.sportName;
  return <DisplayCard
    {...props}
    primaryText={primaryText}
    secondaryElement={secondaryElement ?? "No Sport"} />;
};

export default TeamCard;

import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import { NamedTagForEditFragment, useCreateCustomTagMutation, useCreateSmartTagMutation } from "../../../../../types";
import {
  CombinedTagState,
  buildInitialCreateState,
  combinedTagStateReducer,
  useValidateCombinedStateForCreate,
} from "../../../reducers/combinedTagReducers";
import { buildSaveCustomTagInput } from "../../../reducers/customTagReducers";
import { buildSaveSmartTagInput } from "../../../reducers/smartTagReducers";
import BaseMutationContent from "../BaseContent/BaseMutationContent";

interface CreateDialogContentProps {
  onClose: () => void;
  initialState?: CombinedTagState;
  resetTable: () => void;
}

const CreateDialogContent = ({ onClose, initialState: _initialState, resetTable }: CreateDialogContentProps) => {
  const initialState = _initialState ?? buildInitialCreateState();
  const [state, dispatch] = useReducer(combinedTagStateReducer, initialState);
  const [canSaveTooltips, isTagNameUnique] = useValidateCombinedStateForCreate(state);
  const { setMessage } = useSnackbar();

  const onCompleted = (namedTag: NamedTagForEditFragment) => {
    setMessage("success", `Successfully Created Tag: ${namedTag.tagName.name}`);
    resetTable();
    onClose();
  };
  const onError = () => {
    setMessage("error", `Error Creating Tag: ${state.name}`);
  };

  const [createCustomTag, { loading: loadingCustom }] = useCreateCustomTagMutation({
    // Update case is handled by the reset table in onCompleted
    onCompleted: data => {
      const namedTag = data.createCustomTag;
      onCompleted(namedTag);
    },
    onError,
  });

  const [createSmartTag, { loading: loadingSmart }] = useCreateSmartTagMutation({
    // Update case is handled by the reset table in onCompleted
    onCompleted: data => {
      const namedTag = data.createSmartTag;
      onCompleted(namedTag);
    },
    onError,
  });

  const loading = loadingCustom || loadingSmart;

  const handleSave = () => {
    if (loading) {
      return;
    }
    if (state.__typeName === "Custom") {
      const input = buildSaveCustomTagInput(state);
      createCustomTag({ variables: { input } });
    } else {
      const input = buildSaveSmartTagInput(state);
      if (!input) {
        return;
      }
      createSmartTag({ variables: { input } });
    }
  };

  return (
    <BaseMutationContent
      state={state}
      dispatch={dispatch}
      onClose={onClose}
      handleSave={handleSave}
      loading={loading}
      canSaveTooltips={canSaveTooltips}
      isTagNameUnique={isTagNameUnique}
      tagId={null}
    />
  );
};

export default CreateDialogContent;

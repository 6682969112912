import { ResolverContext } from "./types";
import {
  EditFullMealPlanFragmentDoc,
  EditFullMealPlanFragment,
  MutationChangeExchangeTargetTotalArgs,
  EditMealPlanExchangeTargetsMutationVariables,
  EditMealPlanExchangeTargetsMutation,
  EditMealPlanExchangeTargetsDocument,
  FullMealPlanFragment,
  EditMealPlanFragment,
  EditMealPlanFragmentDoc,
  MutationCalculateExchangeTotalsArgs,
  FullMealPlanFragmentDoc,
} from "../../types";
import { makeFragmentFuncs, makeFragmentFuncsWithInit } from "./utils";
import { initiEditExchangeAmountFragment, mergeExchangeTargetsWithInputs, getExchangeTargetDiffAsInputs } from "./ExchangeAmount";
import { inputToNumber } from "../../utils/inputUtils";
import { DataProxy } from "@apollo/client";
import { ExchangeMealPlan } from "../../MealPlan/types";
import { createExchangeTargetTotalMutationVars, calculateChoExchangeServings } from "../../utils/calculateExchangeTotals";

const initEditMealPlanFragment = (src: FullMealPlanFragment): EditMealPlanFragment => {
  return {
    ...src,
    isAutosaving: false,
    exchangeTargets: (src.exchangeTargets || []).map(et => ({
      ...et,
      ...initiEditExchangeAmountFragment(et),
    })),
  };
};

const { getFragmentOrInit } = makeFragmentFuncsWithInit("MealPlan", "EditMealPlan", EditMealPlanFragmentDoc, initEditMealPlanFragment);

const { getFragment, writeFragment } = makeFragmentFuncs<EditFullMealPlanFragment>(
  "MealPlan",
  "EditFullMealPlan",
  EditFullMealPlanFragmentDoc
);

export const getEditFullMealPlanFragment = getFragment;
export const writeEditFullMealPlanFragment = writeFragment;

const { getFragment: getFullMealPlanFragment, writeFragment: writeFullMealPlanFragment } = makeFragmentFuncs<FullMealPlanFragment>(
  "MealPlan",
  "FullMealPlan",
  FullMealPlanFragmentDoc
);

export { getFullMealPlanFragment, writeFullMealPlanFragment };

export const resolverMap = {
  isAutosaving: (src: FullMealPlanFragment, args: any, context: ResolverContext): boolean => {
    return getFragmentOrInit(src, context).isAutosaving;
  },
};

export const mutationResolverMap = {
  changeExchangeTargetTotal: (
    src: any,
    { mealPlanId, exchangeId, target }: MutationChangeExchangeTargetTotalArgs,
    context: ResolverContext
  ) => {
    const { isAutosaving, ...editFragment } = getFragment(mealPlanId, context);
    const amount = inputToNumber(target);

    const exchangeTargets = editFragment.exchangeTargets || [];
    const exchangeInputs = [{ exchangeId, amountInput: target, pickListServingIds: null }];

    writeFragment(
      {
        ...editFragment,
        exchangeTargets: mergeExchangeTargetsWithInputs(exchangeTargets, exchangeInputs, context),
        isAutosaving: amount !== null,
      },
      context
    );

    if (!isAutosaving && amount !== null) {
      runEditMealPlanExchangeTargetsMutation(context, {
        input: {
          mealPlanId,
          exchangeTargets: [{ exchangeId, amount, pickListServingIds: null }],
        },
      });
    }
  },
  calculateExchangeTotals: (src: any, { mealPlanId }: MutationCalculateExchangeTotalsArgs, context: ResolverContext) => {
    const { isAutosaving, ...editFragment } = getFragment(mealPlanId, context);
    if (editFragment.type !== "exchange") return;
    const exchangeMealPlan = editFragment as ExchangeMealPlan;
    const calculatedExchangeTotals = createExchangeTargetTotalMutationVars({
      mealPlan: exchangeMealPlan,
      ...calculateChoExchangeServings(exchangeMealPlan),
    });
    const exchangeTargets = exchangeMealPlan.exchangeTargets || [];
    const exchangeInputs = calculatedExchangeTotals.map(({ exchangeId, amount, pickListServingIds }) => ({
      exchangeId,
      pickListServingIds,
      amountInput: String(amount),
    }));

    writeFragment(
      {
        ...editFragment,
        exchangeTargets: mergeExchangeTargetsWithInputs(exchangeTargets, exchangeInputs, context),
        isAutosaving: true,
      },
      context
    );

    if (!isAutosaving) {
      runEditMealPlanExchangeTargetsMutation(context, {
        input: {
          mealPlanId,
          exchangeTargets: calculatedExchangeTotals,
        },
      });
    }
  },
};

const runEditMealPlanExchangeTargetsMutation = (context: ResolverContext, variables: EditMealPlanExchangeTargetsMutationVariables) => {
  const { mealPlanId } = variables.input;
  const mutation = context.client.mutate;
  mutation<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>({
    mutation: EditMealPlanExchangeTargetsDocument,
    variables,
    updateQueries: { GetEditMealPlan: result => result },
    update: (cache: DataProxy, { data, errors }) => {
      if (errors) {
        console.error("Mutation failed!!!");
        return;
      }

      if (data) {
        const editFragment = getFragment(mealPlanId, context);
        const currentExchangeTargets = editFragment.exchangeTargets || [];
        const exchangeTargetInputs = getExchangeTargetDiffAsInputs({
          currentExchangeTargets,
          serverExchangeTargets: data.editMealPlanExchangeTargets.mealPlan.exchangeTargets || [],
        });

        if (exchangeTargetInputs.length) {
          const newVariables = {
            input: {
              mealPlanId,
              exchangeTargets: exchangeTargetInputs.flatMap(({ exchangeId, amountInput, pickListServingIds }) => {
                const amount = inputToNumber(amountInput);
                return amount === null ? [] : [{ exchangeId, amount, pickListServingIds }];
              }),
            },
          };
          writeFragment(
            {
              ...editFragment,
              exchangeTargets: mergeExchangeTargetsWithInputs(currentExchangeTargets, exchangeTargetInputs, context),
            },
            context
          );
          runEditMealPlanExchangeTargetsMutation(context, newVariables);
        } else {
          writeFragment(
            {
              ...editFragment,
              isAutosaving: false,
            },
            context
          );
        }
      }
    },
  });
};

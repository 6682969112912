import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { ViewAttendanceRowInput } from "./utils";

interface ExportRow {
  Date: string;
  Name: string;
  Type: string;
  "Expected Check Ins": number;
  "Unexpected Check Ins": number;
  "Total Check Ins": number;
  "Total Expected": number;
  "Percentage of Expected Check Ins": string;
}

type ExportKey = "date" | "name" | "type" | "expectedCheckIns" | "unexpectedCheckIns" | "totalCheckIns" | "totalExpected" | "percentage";

interface ExportColumnDef {
  header: keyof ExportRow;
  key: ExportKey;
  width: number;
}

export const exportColumns: ExportColumnDef[] = [
  { header: "Date", key: "date", width: 20 },
  { header: "Name", key: "name", width: 20 },
  { header: "Type", key: "type", width: 20 },
  { header: "Unexpected Check Ins", key: "unexpectedCheckIns", width: 20 },
  { header: "Expected Check Ins", key: "expectedCheckIns", width: 20 },
  { header: "Total Check Ins", key: "totalCheckIns", width: 20 },
  { header: "Total Expected", key: "totalExpected", width: 20 },
  { header: "Percentage of Expected Check Ins", key: "percentage", width: 20 },
];

interface CrossMealToExcelProps {
  fileName: string;
  rows: ViewAttendanceRowInput[];
}

export const exportCrossMealReportToExcel = async ({ fileName, rows }: CrossMealToExcelProps) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  worksheet.columns = exportColumns;
  worksheet.getRow(1).alignment = { horizontal: "center", vertical: "middle" };

  rows.forEach(data => {
    worksheet.addRow({
      date: data.start,
      name: data.name,
      type: data.type,
      unexpectedCheckIns: data.attendeeUnlistedCount,
      expectedCheckIns: data.attendeeListedCount,
      totalCheckIns: data.mealMenuActualAttendeeCount,
      totalExpected: data.mealMenuExpectedAttendeeCount,
      percentage: `${
        data.mealMenuExpectedAttendeeCount === 0 ? 0 : Math.round(data.mealMenuActualAttendeeCount / data.mealMenuExpectedAttendeeCount)
      }`,
    });
  });

  worksheet.views = [{ state: "frozen", xSplit: undefined, ySplit: 1 }];
  saveAs(new Blob([await workbook.xlsx.writeBuffer()]), fileName + ".xlsx");
};

import { ListSubheader, MenuItem, TextField } from "@mui/material";
import React from "react";
import { ScheduleBasicFragment } from "../../types";

interface ScheduleSelectProps {
  className?: string;
  disabled?: boolean;
  schedules: readonly ScheduleBasicFragment[];
  teamSchedules: readonly ScheduleBasicFragment[];
  selectedSchedule: ScheduleBasicFragment | null;
  onChange: (schedule: ScheduleBasicFragment) => void;
}

const ScheduleSelect = ({ className, schedules, teamSchedules, selectedSchedule, onChange, disabled }: ScheduleSelectProps) => {
  const allSchedules = schedules.concat(teamSchedules);
  const handleChange = (scheduleId: string) => {
    const newSchedule = allSchedules.find(s => s.id === scheduleId);
    if (newSchedule) {
      onChange(newSchedule);
    }
  };

  return (
    <TextField
      select
      className={className}
      label="Select schedule"
      fullWidth={true}
      value={selectedSchedule ? selectedSchedule.id : ""}
      onChange={e => handleChange(e.target.value)}
      margin="dense"
      disabled={disabled}
    >
      <ListSubheader>Standard Schedules</ListSubheader>
      {schedules.map(s => (
        <MenuItem key={s.id} value={s.id}>
          {s.name}
        </MenuItem>
      ))}
      <ListSubheader>Team Schedules {teamSchedules.length === 0 ? "(none)" : ""}</ListSubheader>
      {teamSchedules.map(s => (
        <MenuItem key={s.id} value={s.id}>
          {s.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ScheduleSelect;

import { useApolloClient } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, useTheme } from "@mui/material";
import { onCreate, onEdit } from "apps/web/src/components/MenuItem/Dialog/utils";
import { MenuItemState } from "apps/web/src/components/MenuItem/reducer";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";

import {
  useCreateReusableMenuItemMutation,
  useEditReusableMenuItemMutation,
  useEditReusableMenuItemPartialMutation,
} from "apps/web/src/types";
import React, { useRef, useState } from "react";
import MenuItemEditDialog, { OnSaveMenuItemArgs } from "../../../MenuItems/Dialog/Edit/MenuItemEditDialog";
import { MenuItemDuplicateDialog } from "../../../MenuItems/Dialog/MenuItemDuplicateDialog";
import { useMenuBuilderContext } from "../MenuBuilderProvider";
import { MenuBuilderMealRowItemIndexedType, MenuBuilderMealRowItemType, MenuBuilderMenuItemType } from "./MenuBuilderMealSchema";
import { MenuBuilderMenuItemSearch } from "./MenuBuilderMenuItemSearch";
import { MenuBuilderRowItemList } from "./MenuBuilderRowItemList";
import { MenuBuilderToolbar } from "./MenuBuilderToolbar";
import { DAY_CELL_HEIGHT, DAY_CELL_WIDTH } from "./constants";

interface MenuBuilderMealGridRowDayProps {
  addRowItem: (menuItem: MenuBuilderMenuItemType) => void;
  editRowItem: (oldMenuItemId: string, menuItem: MenuBuilderMenuItemType) => void;
  removeRowItem: (index: number) => void;
  replaceRowItems: (newItems: MenuBuilderMealRowItemType[]) => void;
  rowItemsForDay: readonly MenuBuilderMealRowItemIndexedType[];
  formPath: string;
  isDayEnabled: boolean;
}

export const MenuBuilderMealGridRowDay = ({
  addRowItem,
  editRowItem,
  removeRowItem,
  replaceRowItems,
  rowItemsForDay,
  formPath,
  isDayEnabled,
}: MenuBuilderMealGridRowDayProps) => {
  const [showMenuItemSearch, setShowMenuItemSearch] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState<{ top: number; left: number } | undefined>(undefined);
  const [menuItemSearchValue, setMenuItemSearchValue] = useState("");
  const [editMenuItemId, setEditMenuItemId] = useState<string | null>(null);
  const [duplicateMenuItemId, setDuplicateMenuItemId] = useState<string | null>(null);
  const { setMessage } = useSnackbar();
  const [isSelected, setIsSelected] = useState(false);
  const { menuId, clipboard, setClipboard, isEditable } = useMenuBuilderContext();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const {
    palette: { info, greyscale },
  } = useTheme();
  const { cache } = useApolloClient();

  const [editReusableMenuItem, { loading: savingEdit }] = useEditReusableMenuItemMutation({
    onError: e => {
      if (e.message.includes("duplicate key value violates unique constraint")) {
        setMessage(
          "error",
          "We cannot save the new revision of this menu item at this time since it is outdated. Please close the edit dialog and try again."
        );
      } else {
        setMessage("error", "Failed to save, please try again.");
      }
    },
    // when a revision is made we need to update the cache for search so the user can select the latest revision
    onCompleted: data => {
      cache.evict({ id: `MenuItem:${editMenuItemId}` });
      editRowItem(editMenuItemId!, data.editReusableMenuItem.menuItem);
      setEditMenuItemId(null);
      setMessage("success", `Edited menu item`);
    },
  });
  const [editReusableMenuItemPartial, { loading: savingEditPartial }] = useEditReusableMenuItemPartialMutation({
    onError: e => setMessage("error", e.message),
    onCompleted: data => {
      const { id } = data.editReusableMenuItemPartial.menuItem;
      // the id doesn't change, but we want to update the name in case that was edited
      editRowItem(id, data.editReusableMenuItemPartial.menuItem);
      setEditMenuItemId(null);
      setMessage("success", `Edited menu item`);
    },
    // make searches reflect name changes
    update: cache => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "menuItemCursorConnection" });
    },
  });

  const [createReusableMenuItem, { loading: savingCreate }] = useCreateReusableMenuItemMutation({
    // make sure new items show up in search on other days
    refetchQueries: ["MenuItemSearch"],
    onCompleted: ({ createReusableMenuItem }) => {
      addRowItem({
        name: createReusableMenuItem.menuItem.name,
        id: createReusableMenuItem.menuItem.id,
        isMissingIngredients: createReusableMenuItem.menuItem.isMissingIngredients,
      });
      setDuplicateMenuItemId(null);
      setMessage("success", `Created ${createReusableMenuItem.menuItem.name}`);
      // empties the search bar if they hit enter to create a new item, so they never lost focus on the original search
      setMenuItemSearchValue("");
    },
    // make searches include the new item
    update: cache => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "menuItemCursorConnection" });
    },
  });

  const handleCreate = (state: MenuItemState) => {
    onCreate({ state, createReusableMenuItem });
  };

  const handleEdit = (state: OnSaveMenuItemArgs) => {
    onEdit({ state, editReusableMenuItem, editReusableMenuItemPartial });
    // to avoid revision conflicts where they copy an item and then edit it before pasting
    setClipboard(undefined);
  };

  const getCellBorder = () => {
    if (clipboard?.formPath === formPath) {
      return {
        borderWidth: "1px",
        borderStyle: "dashed",
        borderColor: info.main,
      };
    } else if (isSelected) {
      return {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: info.main,
      };
    }
    return {
      borderWidth: "0px 1px 1px 0px",
      borderStyle: "solid",
      borderColor: greyscale[300],
    };
  };

  const copy = () => {
    datadogRum.addAction("menu_builder.copied_cell", { menuId });
    setClipboard({
      type: "cell",
      formPath,
      items: [...rowItemsForDay],
    });
  };

  const paste = () => {
    if (clipboard?.type === "cell") {
      datadogRum.addAction("menu_builder.pasted_cell", { menuId });
      replaceRowItems(clipboard.items);
    }
  };

  const handleMoreIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    datadogRum.addAction("menu_builder.opened_cell_toolbar", { menuId });
    setToolbarPosition({ top: e.clientY + 15, left: e.clientX - 110 });
    setIsSelected(true);
  };

  const isDayEditable = isDayEnabled && isEditable;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        width: DAY_CELL_WIDTH,
        p: 1,
        pt: 3.5,
        gap: 1,
        flexDirection: "column",
        minHeight: DAY_CELL_HEIGHT,
        "&:hover": isDayEditable ? { cursor: "pointer" } : {},
        ...getCellBorder(),
      }}
      // menu item search textfield
      onMouseEnter={() => {
        if (isDayEditable) {
          setShowMenuItemSearch(true);
        }
      }}
      onMouseLeave={() => {
        if (isDayEditable && menuItemSearchValue === "") {
          setShowMenuItemSearch(false);
        }
      }}
      onBlur={() => {
        // allow for double clicking without losing focus
        if (menuItemSearchValue !== "") {
          setShowMenuItemSearch(false);
        }
      }}
      // close copy/paste toolbar when clicking outside of the opened toolbar
      // focus menu item search textfield when clicking inside the cell
      onClick={e => {
        if (toolbarPosition) {
          setToolbarPosition(undefined);
          setIsSelected(false);
        }
        // only steal focus if you are clicking on this day, i.e. don't interfere with open modals
        if (isDayEditable && e.target === e.currentTarget) {
          searchInputRef.current?.focus();
        }
      }}
    >
      {isDayEditable && (
        <Box>
          <IconButton
            size="small"
            onClick={handleMoreIconClick}
            sx={{ position: "absolute", right: "0px", top: "4px" }}>
            <MoreVertIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </Box>
      )}

      <MenuBuilderRowItemList
        rowItemsForDay={rowItemsForDay}
        removeRowItem={removeRowItem}
        replaceRowItems={replaceRowItems}
        setEditMenuItemId={setEditMenuItemId}
      />

      {!showMenuItemSearch && <Box sx={{ minHeight: "24px" }} />}
      <MenuBuilderMenuItemSearch
        showMenuItemSearch={showMenuItemSearch}
        usedMenuItemIds={rowItemsForDay.map(item => item.menuItem.id)}
        addMenuItem={addRowItem}
        inputValue={menuItemSearchValue}
        setInputValue={setMenuItemSearchValue}
        onCreateMenuItem={handleCreate}
        searchInputRef={searchInputRef}
      />
      {editMenuItemId && (
        <MenuItemEditDialog
          menuItemId={editMenuItemId}
          open={!!editMenuItemId}
          onClose={() => setEditMenuItemId(null)}
          saving={savingEdit || savingEditPartial}
          onSave={handleEdit}
          onDuplicate={() => setDuplicateMenuItemId(editMenuItemId)}
        />
      )}
      {duplicateMenuItemId && (
        <MenuItemDuplicateDialog
          originalMenuItemId={duplicateMenuItemId}
          open={!!duplicateMenuItemId}
          onClose={() => setDuplicateMenuItemId(null)}
          saving={savingCreate}
          onSave={handleCreate}
        />
      )}
      <MenuBuilderToolbar
        position={toolbarPosition}
        onClickCopy={copy}
        disablePaste={clipboard?.type !== "cell"}
        onClickPaste={paste} />
    </Box>
  );
};

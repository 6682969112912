import BackIcon from "@mui/icons-material/ArrowBack";
import { Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import LabeledSelect from "../../components/universal/LabeledSelect";
import { WebPrinter } from "./WebPrinter";
import { PrinterType } from "./WebPrinter/PrinterType";
import { BrotherQL820NWBAirprint, EPSONTML100, EPSONTML90, EPSONTMT88VI, STARSML300, STARSP700 } from "./WebPrinter/printers";
//import { BROTHERQL820NWB } from "./WebPrinter/brotherql820nwb";

interface KdsPrinterSettingsDialogProps {
  onClose: () => void;
  printerType: PrinterType | null;
  setPrinterName: (t: string) => void;
  autoprintEnabled: boolean;
  setAutoprintEnabled: (b: boolean) => void;
  printer: WebPrinter | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingLeft: {
      paddingLeft: theme.spacing(2),
    },
    fullHeight: { height: "100%" },
    paddingRight: {
      paddingRight: theme.spacing(2),
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    dialogTitle: {
      paddingBottom: 0,
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  })
);
const idify = (pt: PrinterType) => ({
  ...pt,
  id: pt.name,
});

const KdsPrinterSettingsDialog = ({
  onClose,
  printerType,
  setPrinterName,
  autoprintEnabled,
  setAutoprintEnabled,
  printer,
}: KdsPrinterSettingsDialogProps) => {
  const classes = useStyles();

  const enableAutoprint = (e: React.ChangeEvent<HTMLElement>, printer: WebPrinter) => {
    try {
      printer.connect(e);
    } catch (e) {
      console.error(e);
      alert("Failed to enable autoprint");
    } finally {
      setAutoprintEnabled(true);
    }
  };
  const disableAutoprint = (e: React.ChangeEvent<HTMLElement>, printer: WebPrinter) => {
    printer.disconnect();
    setAutoprintEnabled(false);
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <div className={classes.header}>
          <IconButton onClick={onClose} size="large">
            <BackIcon className={classes.paddingRight} />
            <Typography variant="h3">Printer Settings</Typography>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <LabeledSelect
          placeholder="Select printer"
          optionToName={(o: PrinterType) => o.name}
          selectedOption={idify(printerType || BrotherQL820NWBAirprint)}
          options={[
            EPSONTMT88VI,
            EPSONTML90,
            EPSONTML100,
            STARSP700,
            STARSML300,
            //BROTHERQL820NWB,
            BrotherQL820NWBAirprint,
          ].map(idify)}
          onChange={(o: PrinterType) => (o.name !== BrotherQL820NWBAirprint.name ? setPrinterName(o.name) : setPrinterName(""))}
        />
        {printer && (
          <FormControlLabel
            value="top"
            control={
              <Switch
                onChange={e => (autoprintEnabled ? disableAutoprint(e, printer) : enableAutoprint(e, printer))}
                checked={autoprintEnabled}
              />
            }
            label="Autoprint"
            labelPlacement="top"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KdsPrinterSettingsDialog;

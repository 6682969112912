import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { LinkedProfile, NotemealProfile } from "@notemeal/profile-sync";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import React, { useReducer, useState } from "react";
import { ChangeContactInfoContent } from "../ChangeContactInfoContent";
import { RelinkProfileDialog } from "../RelinkProfile/Dialog";
import { contactInfoReducer, getInitState } from "../updateContactInfoReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpIcon: {
      color: theme.palette.greyscale[400],
      fontSize: "14px",
    },
  })
);

interface UnlinkProfileDialogProps {
  open: boolean;
  onClose: () => void;
  profileToUnlink: LinkedProfile;
  setProfileToUnlink: (profile: LinkedProfile | null) => void;
  onUnlink: (profile: LinkedProfile, newEmail?: string, newPhone?: string) => void;
  onUnlinkRelink: (profileToUnlink: LinkedProfile, profileToRelink: NotemealProfile, newEmail?: string, newPhone?: string) => void;
  unlinkedNotemealProfiles: readonly NotemealProfile[];
}

export const UnlinkProfileDialog = ({
  open,
  onClose,
  profileToUnlink,
  setProfileToUnlink,
  onUnlink,
  onUnlinkRelink,
  unlinkedNotemealProfiles,
}: UnlinkProfileDialogProps) => {
  const classes = useStyles();

  const [state, dispatch] = useReducer(contactInfoReducer, getInitState());
  const [shouldRelink, setShouldRelink] = useState(false);
  const [relinkProfileOpen, setRelinkProfileOpen] = useState(false);

  const profileName = `${profileToUnlink.teamworks.firstName} ${profileToUnlink.teamworks.lastName}`;
  const currentEmail = profileToUnlink.teamworks.email;
  const currentPhone = profileToUnlink.teamworks.cellPhone;

  const handleClose = () => {
    setProfileToUnlink(null);
    onClose();
  };

  const handleUnlink = () => {
    if (profileToUnlink.notemeal) {
      if (shouldRelink) {
        setRelinkProfileOpen(true);
        return;
      } else {
        onUnlink(profileToUnlink, state.newEmail ?? undefined, state.newPhone ?? undefined);
      }
      handleClose();
    }
  };

  const handleUnlinkRelink = (profileToRelink: NotemealProfile) => {
    onUnlinkRelink(profileToUnlink, profileToRelink, state.newEmail ?? undefined, state.newPhone ?? undefined);
    setRelinkProfileOpen(false);
    handleClose();
  };

  const unlinkDisabled = (currentEmail && !state.newEmail) || !!state.emailError || !!state.phoneError;

  const getTooltipText = () => {
    const tooltipText: string[] = [];
    if (currentEmail && !state.newEmail) {
      tooltipText.push("Email is required.");
    }
    if (state.emailError) {
      tooltipText.push(state.emailError);
    }
    if (state.phoneError) {
      tooltipText.push(state.phoneError);
    }

    return tooltipText;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Unlink Teamworks Profile</DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            <Typography variant="h4">{profileName}</Typography>
            <Typography>
              {profileToUnlink.teamworks.email}, {profileToUnlink.teamworks.cellPhone}
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="body2">
          By unlinking, the Nutrition account for <strong>{profileName}</strong> will no longer be linked with a Teamworks profile.
        </Typography>
        <Typography variant="body2">
          If the user has a username and password set, the Nutrition account for <strong>{profileName}</strong> will remain active and the
          user's level of access will be unchanged, but you must change the email and/or phone number associated with the Nutrition account
          to prevent future autolinking and collisions if relinking. If the user does not have a username and password, the user account and
          org membership will be marked is inactive.
        </Typography>
        <Typography variant="body2">
          Note if the account’s username is the email, the username will remain unchanged. If no phone number is provided, the existing
          phone number for the Nutrition account will be removed.
        </Typography>
        <ChangeContactInfoContent
          currentEmail={currentEmail}
          currentPhone={currentPhone}
          state={state}
          dispatch={dispatch} />
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={0}>
          <FormControlLabel
            style={{ marginRight: 4 }}
            control={<Checkbox onClick={() => setShouldRelink(!shouldRelink)} />}
            label={<Typography variant="body2">Relink Teamworks Profile</Typography>}
          />
          <TWTooltip
            title="Relink Teamworks Profile"
            subcopy="Relink the unlinked Teamworks profile to a different Nutrition account on the next screen"
          >
            <HelpOutlineIcon className={classes.helpIcon} />
          </TWTooltip>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setProfileToUnlink(null);
            onClose();
          }}
        >
          Cancel
        </Button>
        <div style={{ flexGrow: 1 }} />
        <TWItemizedTooltip
          title={"Please fix the following:"}
          items={getTooltipText()}
          placement="bottom">
          <span>
            <Button disabled={unlinkDisabled} onClick={handleUnlink}>
              Unlink
            </Button>
          </span>
        </TWItemizedTooltip>
      </DialogActions>
      {profileToUnlink && (
        <RelinkProfileDialog
          open={relinkProfileOpen}
          onClose={() => {
            setRelinkProfileOpen(false);
            handleClose();
          }}
          unlinkedNotemealProfiles={unlinkedNotemealProfiles}
          profileToUnlink={profileToUnlink}
          onUnlinkRelink={handleUnlinkRelink}
        />
      )}
    </Dialog>
  );
};

import { useHasFeature } from "@notemeal/shared/ui/Feature";
import React from "react";
import { useParams } from "react-router-dom-v5-compat";
import { MenuBuilderGrid } from "../../../../views/MenuBuilder/Builder/MenuBuilderGrid";
import { MenuBuilderProvider } from "../../../../views/MenuBuilder/Builder/MenuBuilderProvider";

export const MenuBuilderPage = () => {
  const { plannedMenuId } = useParams();
  const showMenuBuilder = useHasFeature("performanceKitchen");

  if (showMenuBuilder) {
    return (
      <MenuBuilderProvider plannedMenuId={plannedMenuId || ""}>
        <MenuBuilderGrid />
      </MenuBuilderProvider>
    );
  }
  return null;
};

import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { OrgMultiSelect } from "./OrgMultiSelect";

interface TerritoryDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string, orgIds: string[]) => void;
}

export const CreateTerritoryDialog = ({ open, onClose, onSave }: TerritoryDialogProps) => {
  const [territoryName, setTerritoryName] = useState<string>("");
  const [selectedOrgIds, setSelectedOrgIds] = useState<string[]>([]);

  const handleSave = () => {
    onSave(territoryName, selectedOrgIds);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title={`Create Territory`} onClose={onClose} />
      <DialogContent>
        <TextField
          autoFocus
          value={territoryName}
          onChange={e => {
            setTerritoryName(e.target.value);
          }}
          placeholder="Territory"
        ></TextField>
        <OrgMultiSelect
          values={selectedOrgIds}
          onChange={setSelectedOrgIds}
          error={false} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

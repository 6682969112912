import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Typography } from "@mui/material";
import React from "react";

interface ExternalCalendarWarningProps {
  isPartialSuccess: boolean;
}

const ExternalCalendarWarning = ({ isPartialSuccess }: ExternalCalendarWarningProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 1 }}>
      {isPartialSuccess ? (
        <>
          <WarningIcon color="warning" />
          <Typography sx={{ pr: 2 }} variant="body2">
            Some External Events Failed to Load
          </Typography>
        </>
      ) : (
        <>
          <CloseIcon color="error" />
          <Typography
            sx={{ pr: 2 }}
            variant="body2"
            color="error">
            All External Events Failed to Load
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ExternalCalendarWarning;

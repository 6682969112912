import { Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import {
  ExchangeMealPlanDisplaySettingsFragment,
  ExchangeProgressViews,
  MacroMealPlanDisplaySettingsFragment,
  MacroProgressViews,
  NoTargetsDisplaySettingsFragment,
} from "../../types";
import LabeledSelectNoId from "../universal/LabeledSelectNoId";
import { MacrosAndExchangesDisplaySettingsPreview, MacrosDisplaySettingsPreview } from "./DisplaySettingsPreviews";
import { exchangeProgressViewsTypeToName, macroProgressViewsTypeToName } from "./utils";

interface DisplaySettingsTableProps {
  hideAnthropometry: boolean;
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
  noTargetsDisplaySettings: NoTargetsDisplaySettingsFragment;
  onChangeMacroDisplaySettings: (settings: MacroMealPlanDisplaySettingsFragment) => void;
  onChangeExchangeDisplaySettings: (settings: ExchangeMealPlanDisplaySettingsFragment) => void;
  onChangeNoTargetsDisplaySettings: (settings: NoTargetsDisplaySettingsFragment) => void;
  onChangeHideAnthropometry: (value: boolean) => void;
  disabled?: boolean;
}

const DisplaySettingsTable = ({
  hideAnthropometry,
  macroDisplaySettings,
  exchangeDisplaySettings,
  noTargetsDisplaySettings,
  onChangeExchangeDisplaySettings,
  onChangeMacroDisplaySettings,
  onChangeNoTargetsDisplaySettings,
  onChangeHideAnthropometry,
  disabled,
}: DisplaySettingsTableProps) => {
  const handleChangeMacroSetting = (key: keyof MacroMealPlanDisplaySettingsFragment, value: MacroProgressViews) => {
    onChangeMacroDisplaySettings({
      ...macroDisplaySettings,
      [key]: value,
    });
  };

  const handleChangeExchangeSetting = <K extends keyof ExchangeMealPlanDisplaySettingsFragment>(
    key: K,
    value: ExchangeMealPlanDisplaySettingsFragment[K]
  ) => {
    onChangeExchangeDisplaySettings({
      ...exchangeDisplaySettings,
      [key]: value,
    });
  };

  const handleChangeNoTargetsSetting = (key: keyof NoTargetsDisplaySettingsFragment, value: MacroProgressViews | null) => {
    onChangeNoTargetsDisplaySettings({
      ...noTargetsDisplaySettings,
      [key]: value,
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell sx={{ width: "300px" }} align="center">
              <b>Daily</b>
            </TableCell>
            <TableCell sx={{ width: "300px" }} align="center">
              <b>Meal</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ p: 2 }}>
            <TableCell align="center">
              <b>Macro Meal Plans</b>
            </TableCell>
            <TableCell sx={{ verticalAlign: "top" }}>
              <LabeledSelectNoId<MacroProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "hideNumbers", "none"]}
                optionToName={macroProgressViewsTypeToName}
                selectedOption={macroDisplaySettings.dailyProgressViews}
                placeholder="Select progress views"
                onChange={e => handleChangeMacroSetting("dailyProgressViews", e)}
              />
              <MacrosDisplaySettingsPreview macroProgressViews={macroDisplaySettings.dailyProgressViews} />
            </TableCell>
            <TableCell sx={{ verticalAlign: "top", maxWidth: "250px" }}>
              <LabeledSelectNoId<MacroProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "hideNumbers", "none"]}
                optionToName={macroProgressViewsTypeToName}
                selectedOption={macroDisplaySettings.mealProgressViews}
                placeholder="Select progress views"
                onChange={e => handleChangeMacroSetting("mealProgressViews", e)}
              />
              <MacrosDisplaySettingsPreview macroProgressViews={macroDisplaySettings.mealProgressViews} />
            </TableCell>
          </TableRow>
          <TableRow sx={{ p: 2 }}>
            <TableCell align="center">
              <b>Exchange Meal Plans</b>
            </TableCell>
            <TableCell>
              <LabeledSelectNoId<ExchangeProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "none"]}
                optionToName={exchangeProgressViewsTypeToName}
                selectedOption={exchangeDisplaySettings.dailyProgressViewsForExchanges}
                placeholder="Select exchange progress views"
                onChange={e => handleChangeExchangeSetting("dailyProgressViewsForExchanges", e)}
              />
              <LabeledSelectNoId<MacroProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "hideNumbers", "none"]}
                optionToName={macroProgressViewsTypeToName}
                selectedOption={exchangeDisplaySettings.dailyProgressViewsForMacros}
                placeholder="Select macro progress views"
                onChange={e => handleChangeExchangeSetting("dailyProgressViewsForMacros", e)}
              />
              <MacrosAndExchangesDisplaySettingsPreview
                macroProgressViews={exchangeDisplaySettings.dailyProgressViewsForMacros}
                exchangeProgressViews={exchangeDisplaySettings.dailyProgressViewsForExchanges}
              />
            </TableCell>
            <TableCell>
              <LabeledSelectNoId<ExchangeProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "none"]}
                optionToName={exchangeProgressViewsTypeToName}
                selectedOption={exchangeDisplaySettings.mealProgressViewsForExchanges}
                placeholder="Select exchange progress views"
                onChange={e => handleChangeExchangeSetting("mealProgressViewsForExchanges", e)}
              />
              <LabeledSelectNoId<MacroProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "hideNumbers", "none"]}
                optionToName={macroProgressViewsTypeToName}
                selectedOption={exchangeDisplaySettings.mealProgressViewsForMacros}
                placeholder="Select macro progress views"
                onChange={e => handleChangeExchangeSetting("mealProgressViewsForMacros", e)}
              />
              <MacrosAndExchangesDisplaySettingsPreview
                macroProgressViews={exchangeDisplaySettings.mealProgressViewsForMacros}
                exchangeProgressViews={exchangeDisplaySettings.mealProgressViewsForExchanges}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={{ p: 2 }}>
            <TableCell align="center">
              <b>No Meal Plan</b>
            </TableCell>
            <TableCell sx={{ verticalAlign: "top" }}>
              <LabeledSelectNoId<MacroProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "hideNumbers", "none"]}
                optionToName={macroProgressViewsTypeToName}
                selectedOption={noTargetsDisplaySettings.dailyProgressViewsForMacros}
                placeholder="Select progress views"
                onChange={e => handleChangeNoTargetsSetting("dailyProgressViewsForMacros", e)}
              />
              <MacrosDisplaySettingsPreview macroProgressViews={noTargetsDisplaySettings.dailyProgressViewsForMacros} disableTargets />
            </TableCell>
            <TableCell sx={{ verticalAlign: "top" }}>
              <LabeledSelectNoId<MacroProgressViews>
                sx={{ mb: 2 }}
                disabled={disabled}
                options={["all", "hideNumbers", "none"]}
                optionToName={macroProgressViewsTypeToName}
                selectedOption={noTargetsDisplaySettings.mealProgressViewsForMacros}
                placeholder="Select progress views"
                onChange={e => handleChangeNoTargetsSetting("mealProgressViewsForMacros", e)}
              />
              <MacrosDisplaySettingsPreview macroProgressViews={noTargetsDisplaySettings.mealProgressViewsForMacros} disableTargets />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <FormControlLabel
        disabled={disabled}
        sx={{ ml: 1 }}
        control={<Checkbox checked={hideAnthropometry} onChange={(_, value) => onChangeHideAnthropometry(value)} />}
        label="Hide Anthropometry"
      />
    </TableContainer>
  );
};

export default DisplaySettingsTable;

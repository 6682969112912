import { Fab, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import GoalCreateModal from "../../../../components/Goal/CreateModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      flexGrow: 0,
    },
    spacer: {
      flexGrow: 1,
    },
    spacing: {
      margin: theme.spacing(0, 1),
    },
    noGoal: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

interface AthleteSummaryCurrentGoalNoneProps {
  athleteId: string;
}

const AthleteSummaryCurrentGoalNone = ({ athleteId }: AthleteSummaryCurrentGoalNoneProps) => {
  const classes = useStyles();
  const [createOpen, setCreateOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.spacing} variant="h3">
          No Current Weight Target
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.noGoal}>
        <Fab variant="extended" onClick={() => setCreateOpen(true)}>
          Create New Weight Target
        </Fab>
      </div>
      <div className={classes.spacer} />
      {createOpen && <GoalCreateModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        athleteId={athleteId} />}
    </div>
  );
};

export default AthleteSummaryCurrentGoalNone;

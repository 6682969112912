export const standardizeName = ({ firstName, lastName }: { firstName: string; lastName: string }): string => {
  return stripPunctuation(`${lastName && lastName.trim()}${firstName && firstName.trim()}`);
};

export const standardizeAnthroEntryKey = ({
  firstName,
  lastName,
  datetime,
}: {
  firstName: string;
  lastName: string;
  datetime: Date;
}): string => {
  const stdName = standardizeName({ firstName, lastName });
  const stdDt = new Date(datetime).toISOString().split("T")[0];
  return `${stdName}-${stdDt}`;
};

export const stripPunctuation = (name: string): string => {
  const punctuationless = name.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
  const finalString = punctuationless.replace(/\s{2,}/g, " ");
  return finalString;
};

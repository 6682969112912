import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TableCell, TableRow } from "@mui/material";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import TablePage from "../../../../components/universal/TablePage";
import {
  ClientCredentialFragment,
  useClientCredentialOffsetConnectionQuery,
  useCreateClientCredentialMutation,
  useEditClientCredentialMutation,
  useRefreshClientCredentialSecretMutation,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import CreateDialog from "../../../../views/ClientCredentials/Dialog/Create";
import EditDialog from "../../../../views/ClientCredentials/Dialog/Edit";
import DialogWithSecret from "../../../../views/ClientCredentials/Dialog/WithSecret";
import { ClientCredentialWithSecret } from "../../../../views/ClientCredentials/types";
import { NAV_API_DOCS } from "../../../NoAuth/NoAuthPaths";

export const ClientCredentialsPage = () => {
  const navigate = useNavigate();

  const { setMessage } = useSnackbar();

  const [createOpen, setCreateOpen] = useState(false);
  const [editingCredential, setEditingCredential] = useState<ClientCredentialFragment | null>(null);
  const [dataWithSecret, setDataWithSecret] = useState<ClientCredentialWithSecret | null>(null);

  const paginationHooks = useOffsetPagination();
  const { limit, offset } = paginationHooks;

  const { data, loading, refetch } = useClientCredentialOffsetConnectionQuery({
    variables: {
      pagination: {
        limit,
        offset,
      },
    },
    fetchPolicy: "network-only",
  });

  const [createClientCredential, { loading: savingCreate }] = useCreateClientCredentialMutation({
    onCompleted: data => {
      refetch();
      setDataWithSecret(data.createClientCredential);
    },
    onError: () => {
      refetch();
      setMessage("error", "Something went wrong...");
    },
  });

  const [editClientCredential] = useEditClientCredentialMutation({
    onCompleted: data => {
      refetch();
      setMessage("success", "Successfully edited client credentials");
    },
    onError: () => {
      refetch();
      setMessage("error", "Something went wrong...");
    },
  });

  const [refreshClientCredentialSecret, { loading: savingRefresh }] = useRefreshClientCredentialSecretMutation({
    onCompleted: data => {
      refetch();
      setDataWithSecret(data.refreshClientCredentialSecret);
    },
    onError: () => {
      refetch();
      setMessage("error", "Something went wrong...");
    },
  });

  const saving = savingCreate || savingRefresh;

  return (
    <>
      <TablePage
        header={
          <>
            <Button onClick={() => navigate(NAV_API_DOCS)}>View API Docs</Button>
            <Button onClick={() => setCreateOpen(true)}>Create Client Credential</Button>
          </>
        }
        tableHeaderRow={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Is Active</TableCell>
            <TableCell align="right">Client ID</TableCell>
          </TableRow>
        }
        tableBodyRows={
          !data
            ? null
            : data.clientCredentialOffsetConnection.edges.map(row => (
                <TableRow
                  sx={{ cursor: "pointer" }}
                  key={row.id}
                  hover
                  onClick={() => setEditingCredential(row)}>
                  <TableCell scope="row">{row.name}</TableCell>
                  <TableCell>{row.isActive ? <CheckIcon /> : <CloseIcon />}</TableCell>
                  <TableCell align="right">{row.clientId}</TableCell>
                </TableRow>
              ))
        }
        paginationHooks={paginationHooks}
        loading={loading}
        total={data?.clientCredentialOffsetConnection.pageInfo.total || 0}
      />
      {saving && <LoadingBackdrop open onClose={() => {}} />}
      {createOpen && (
        <CreateDialog
          open={createOpen}
          onClose={() => setCreateOpen(false)}
          onDone={name =>
            createClientCredential({
              variables: {
                input: { name },
              },
            })
          }
        />
      )}
      {editingCredential !== null && (
        <EditDialog
          open={editingCredential !== null}
          onClose={() => setEditingCredential(null)}
          clientCredential={editingCredential}
          onEdit={(name, isActive) =>
            editClientCredential({
              variables: {
                input: {
                  id: editingCredential.id,
                  name,
                  isActive,
                },
              },
            })
          }
          onRefresh={() =>
            refreshClientCredentialSecret({
              variables: {
                input: {
                  id: editingCredential.id,
                },
              },
            })
          }
        />
      )}
      {dataWithSecret !== null && (
        <DialogWithSecret
          open={Boolean(dataWithSecret)}
          onClose={() => setDataWithSecret(null)}
          data={dataWithSecret} />
      )}
    </>
  );
};

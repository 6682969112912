import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, Checkbox, FormControlLabel, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

export const ThemeViewerAutocomplete = () => {
  const [disabledChecked, setDisabledChecked] = useState(false);
  const options = ["option1", "option2", "option3"];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">Autocomplete</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={disabledChecked} onChange={() => setDisabledChecked(!disabledChecked)} />}
          label="Disabled"
        />
      </Box>
      <Typography variant="h3">Medium * only medium working so far</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Autocomplete
          options={options}
          sx={{ width: 300 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Search"
              placeholder="Placeholder"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Typography variant="h3">Small</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <Autocomplete
          size="small"
          options={options}
          sx={{ width: 300 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Search"
              placeholder="Placeholder"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

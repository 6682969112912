import { MealPlanSearchFormFragment } from "apps/web/src/types";

export interface MealPlanSearchFormState {
  athleteSearchTerm: string;
  mealPlanSearchTerm: string;
  selectedMealPlan: MealPlanSearchFormFragment | null;
}

interface ChangeAthleteSeachTerm {
  type: "CHANGE_ATHLETE_SEARCH_TERM";
  payload: string;
}

interface ChangeMealPlanSeachTerm {
  type: "CHANGE_MEAL_PLAN_TEMPLATE_SEARCH_TERM";
  payload: string;
}

interface ChangeSelectedMealPlan {
  type: "CHANGE_SELECTED_MEAL_PLAN_TEMPLATE";
  payload: MealPlanSearchFormFragment | null;
}

export type MealPlanSearchFormAction = ChangeAthleteSeachTerm | ChangeMealPlanSeachTerm | ChangeSelectedMealPlan;

export const mealPlanSearchFormReducer = (state: MealPlanSearchFormState, action: MealPlanSearchFormAction): MealPlanSearchFormState => {
  switch (action.type) {
    case "CHANGE_ATHLETE_SEARCH_TERM":
      return { ...state, athleteSearchTerm: action.payload };
    case "CHANGE_MEAL_PLAN_TEMPLATE_SEARCH_TERM":
      return { ...state, mealPlanSearchTerm: action.payload };
    case "CHANGE_SELECTED_MEAL_PLAN_TEMPLATE":
      return { ...state, selectedMealPlan: action.payload };
  }
};

export const mealPlanSearchFormToSaveTooltips = (state: MealPlanSearchFormState): string[] => {
  let tooltips = [];
  if (!state.selectedMealPlan) {
    tooltips.push("Select a meal plan template from the list on the right");
  }
  return tooltips;
};

export const initialMealPlanSearchFormState = (): MealPlanSearchFormState => ({
  athleteSearchTerm: "",
  mealPlanSearchTerm: "",
  selectedMealPlan: null,
});

import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Box, Button } from "@mui/material";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useOffsetPagination } from "apps/web/src/utils/pagination";
import { AthleteGoalTable } from "apps/web/src/views/Athlete/GoalTracker/AthleteGoalTable";
import { GoalConfigurationCreateDialog } from "apps/web/src/views/Athlete/GoalTracker/GoalConfiguration/GoalConfigurationCreateModal";
import { CreateGoalConfigurationInputType } from "apps/web/src/views/Athlete/GoalTracker/GoalConfiguration/GoalConfigurationSchema";
import React, { useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { RefetchQueriesContextProvider } from "../../../../contexts/RefetchQueries";
import {
  GoalConfigurationsForAthleteTableDocument,
  useCreateGoalConfigurationWithDaysOfWeekMutation,
  useCreateGoalConfigurationWithIndividualDatesMutation,
  useGoalConfigurationsForAthleteTableQuery,
} from "../../../../types";

const CURRENT_GOALS = "Current Goals";
const PREVIOUS_GOALS = "Previous Goals";
export type GoalMode = typeof CURRENT_GOALS | typeof PREVIOUS_GOALS;

export const AthleteGoalsPage = () => {
  const { athleteId: maybeAthleteId } = useParams();
  const athleteId = maybeAthleteId || "";
  const [goalMode, setGoalMode] = useState(CURRENT_GOALS);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const { setMessage } = useSnackbar();
  const paginationHooks = useOffsetPagination();
  const { limit, offset } = paginationHooks;

  const today = new Date();
  const getAthleteGoalsVars = {
    athleteId,
    input: { limit, offset },
    current: goalMode === CURRENT_GOALS,
    startDate: null,
    endDate: serializeDate(today),
  };
  const { data, loading } = useGoalConfigurationsForAthleteTableQuery({
    variables: getAthleteGoalsVars,
  });
  const refetchQueries = [{ query: GoalConfigurationsForAthleteTableDocument, variables: getAthleteGoalsVars }];

  const onCreated = (name: string) => setMessage("success", `${name} has been created as a new goal`);

  const [createGoalConfigurationWithDaysOfWeek] = useCreateGoalConfigurationWithDaysOfWeekMutation({
    refetchQueries: refetchQueries,
    onCompleted: data => onCreated(data.createGoalConfigurationWithDaysOfWeek.goalConfiguration.name),
  });
  const [createGoalConfigurationWithIndividualDates] = useCreateGoalConfigurationWithIndividualDatesMutation({
    refetchQueries: refetchQueries,
    onCompleted: data => onCreated(data.createGoalConfigurationWithIndividualDates.goalConfiguration.name),
  });
  const handleCreateGoalConfiguration = (input: CreateGoalConfigurationInputType) => {
    if ("dayOfWeekAssignments" in input) {
      createGoalConfigurationWithDaysOfWeek({ variables: { input } });
    } else {
      createGoalConfigurationWithIndividualDates({ variables: { input } });
    }
  };

  const noCurrentGoalCopy = `The athlete does not have any goals yet.
  Get started by creating and assigning one today.`;
  const noPreviousGoalCopy = `The athlete does not have any goals that
  have been completed yet.`;

  const noResults = (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", pt: 15 }}>
      <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "438px", alignItems: "center", textAlign: "center" }}>
        <WorkspacePremiumIcon />
        <SectionHeader header="No goals" subcopy={goalMode === CURRENT_GOALS ? noCurrentGoalCopy : noPreviousGoalCopy} />
      </Box>
    </Box>
  );

  const hasResults = (data?.goalConfigurationsForAthleteOffsetConnection.pageInfo.total || 0) > 0;

  return (
    <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
      {
        <>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <TWTabGroup
              tabs={[CURRENT_GOALS, PREVIOUS_GOALS]}
              onSelected={selected => {
                setGoalMode(selected);
              }}
            />
            {goalMode === CURRENT_GOALS && <Button onClick={() => setCreateDialogOpen(true)}>Create Goal</Button>}
          </Box>
          {hasResults ? (
            <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
              <AthleteGoalTable
                paginationHooks={paginationHooks}
                rows={data?.goalConfigurationsForAthleteOffsetConnection.edges || []}
                loading={loading}
                totalRows={data?.goalConfigurationsForAthleteOffsetConnection.pageInfo.total || 0}
              />
            </RefetchQueriesContextProvider>
          ) : (
            noResults
          )}
        </>
      }
      {createDialogOpen && (
        <GoalConfigurationCreateDialog
          athleteId={athleteId}
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
          onCreateGoalConfiguration={handleCreateGoalConfiguration}
        />
      )}
    </RefetchQueriesContextProvider>
  );
};

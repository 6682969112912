import { Dialog } from "@mui/material";
import RecipeCreateContent from "apps/web/src/components/Recipe/RecipeCreateContent";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { FullServingAmountFragment, RecipeFullFragment } from "apps/web/src/types";
import React from "react";
import { RecipeFormState } from "./Form/utils";

interface RecipeCreateDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (recipe: RecipeFullFragment) => void;
  forStaff?: boolean;
  initialRecipeState?: RecipeFormState;
  initialIngredients?: readonly FullServingAmountFragment[];
  initialName?: string;
}

const RecipeCreateDialog = ({
  onClose,
  open,
  forStaff,
  initialRecipeState,
  onCreate,
  initialIngredients,
  initialName,
}: RecipeCreateDialogProps) => {
  const { setMessage } = useSnackbar();

  const onSuccess = (obj: RecipeFullFragment) => {
    setMessage("success", `Successfully created Recipe ${obj.name}`);
    onCreate(obj);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xl"}
      fullWidth>
      <RecipeCreateContent
        forStaff={forStaff}
        onClose={onClose}
        onCreate={thing => onSuccess(thing)}
        initialRecipeState={initialRecipeState}
        initialIngredients={initialIngredients}
        initialName={initialName}
        dialogView={true}
      />
    </Dialog>
  );
};

export default RecipeCreateDialog;

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, TextField, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { StaffDashboardOrgPreviewFragment, useOrgsQuery } from "../../../../types";
import { CreateOrgDialog } from "../../../../views/Staff/Orgs/CreateDialog";
import EditDialog from "../../../../views/Staff/Orgs/EditDialog";
import OrgsTable from "../../../../views/Staff/Orgs/Table";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  container: {
    padding: spacing(3, 6),
    display: "flex",
    flexDirection: "column",
    maxHeight: "inherit",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: spacing(3),
  },
  leftHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  link: {
    textDecoration: "None",
  },
  search: {
    marginRight: spacing(4),
  },
}));

export const OrgsPage = () => {
  const classes = useStyles();
  const { data, loading } = useOrgsQuery({
    fetchPolicy: "network-only",
  });
  const [showCreateOrgDialog, setShowCreateOrgDialog] = useState(false);
  const [showEditOrgDialog, setShowEditOrgDialog] = useState<StaffDashboardOrgPreviewFragment | null>(null);
  const [queryText, setQueryText] = useState("");

  const lowercaseQuery = queryText.toLowerCase();
  const filteredOrgs = data?.orgs
    ? queryText !== ""
      ? data.orgs.filter(org => org.name.toLowerCase().includes(lowercaseQuery))
      : [...data.orgs]
    : [];

  return (
    <Box sx={{ height: "100%", overflow: "hidden", display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
        <TextField
          sx={{ mt: 0 }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={queryText}
          onChange={e => setQueryText(e.target.value)}
          className={classes.search}
        />
        <Button onClick={() => setShowCreateOrgDialog(true)}>Create Org</Button>
      </Box>
      {!loading && data && <OrgsTable onClickRow={o => setShowEditOrgDialog(o)} orgs={filteredOrgs} />}
      {showCreateOrgDialog && <CreateOrgDialog onClose={() => setShowCreateOrgDialog(false)} open={showCreateOrgDialog} />}
      {showEditOrgDialog && <EditDialog
        org={showEditOrgDialog}
        onClose={() => setShowEditOrgDialog(null)}
        open={!!showEditOrgDialog} />}
    </Box>
  );
};

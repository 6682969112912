import { Button, Dialog, DialogActions, DialogContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import { DeliveryLocationWithTeamsFragment, useAddDeliveryLocationMutation, useGoogleMapsApiKeyQuery } from "../../../../types";
import Info from "../Form/Info";
import Map from "../Form/Map";
import {
  buildDeliveryLocationFormTooltips,
  buildInitialCreateFormState,
  deliveryLocationFormReducer_Create,
  getAddDeliveryLocationInput,
} from "../Form/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: 0,
      display: "flex",
    },
    paper: {
      height: "calc(100vh - 64px)",
    },
  })
);

interface DeliveryLocationCreateDialogProps {
  open: boolean;
  onClose: () => void;
  onCreateSuccess: (dl: DeliveryLocationWithTeamsFragment) => void;
}

const DeliveryLocationCreateDialog = ({ open, onClose, onCreateSuccess }: DeliveryLocationCreateDialogProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const { data } = useGoogleMapsApiKeyQuery();
  const [state, dispatch] = useReducer(deliveryLocationFormReducer_Create, buildInitialCreateFormState());

  const [addDeliveryLocation, { loading: saving }] = useAddDeliveryLocationMutation({
    onCompleted: data => {
      onCreateSuccess(data.addDeliveryLocation.deliveryLocation);
      onClose();
    },
    onError: e => {
      setMessage("error", e.message);
    },
  });

  if (!data || saving) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const canSaveTooltips = buildDeliveryLocationFormTooltips(state);

  const onSave = () => {
    if (canSaveTooltips.length > 0) {
      return;
    }
    const input = getAddDeliveryLocationInput(state);
    if (!input) {
      return;
    }
    addDeliveryLocation({
      variables: {
        input,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle title="Add Delivery Location" onClose={onClose} />
      <DialogContent className={classes.content}>
        <Map
          googleMapsApiKey={data.googleMapsApiKey}
          onSelectLocation={location =>
            dispatch({
              type: "CHANGE_LOCATION",
              payload: { location },
            })
          }
        />
      </DialogContent>
      {state.location !== null && (
        <Dialog
          open={state.location !== null}
          onClose={() =>
            dispatch({
              type: "CHANGE_LOCATION",
              payload: { location: null },
            })
          }
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle title="Delivery Location Details" onClose={onClose} />
          <DialogContent>
            <Info
              state={{ ...state, cityInfo: null }}
              dispatch={dispatch}
              disableCityInfo />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch({
                  type: "CHANGE_LOCATION",
                  payload: { location: null },
                })
              }
            >
              Close
            </Button>
            <TWItemizedTooltip title="Fix the following:" items={canSaveTooltips}>
              <Button onClick={onSave}>Save</Button>
            </TWItemizedTooltip>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default DeliveryLocationCreateDialog;

import { MenuItem, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemRoot: {
      minHeight: "auto",
    },
  })
);

const ServingAmountsServingsMenuItemLoading = () => {
  const classes = useStyles();

  return (
    <MenuItem classes={{ root: classes.menuItemRoot }} dense>
      <Typography variant="subtitle1">Loading...</Typography>
    </MenuItem>
  );
};

export default ServingAmountsServingsMenuItemLoading;

import { useApolloClient } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { isMealMenuClosed } from "@notemeal/shared/ui/MealMenu/utils";
import { getMealTemplateMacroTargets } from "@notemeal/shared/ui/MealTemplate/utils";
import { reduceStatuses } from "@notemeal/shared/ui/MenuOrder/status";
import { PickupTime, usePickupTimeState } from "@notemeal/shared/ui/MenuOrder/usePickupTimeState";
import {
  MenuOrderItemWithAppearance,
  getEditMenuOrderInputsOrErrors,
  getMenuOrderItemsWithAppearance,
} from "@notemeal/shared/ui/MenuOrder/utils";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../componentLibrary";
import DialogTitle from "../../componentLibrary/DialogTitle";
import {
  MealMenuDiningStationWithItemPreviewsFragment,
  MenuOrderForFormDocument,
  MenuOrderForFormQuery,
  MenuOrderForFormQueryVariables,
  MenuOrderFormFragment,
  useEditMenuOrderMutation,
} from "../../types";
import { AthleteOrderStateAction, OrderFormAthleteOrderState } from "../../views/Kiosk/Order/orderStateReducer";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import MenuOrderDialogContent from "./DialogContent";
import { useContentStyles } from "./useContentStyles";

interface MenuOrderEditDialogContentProps {
  athleteId: string;
  athleteUserId?: string;
  teamId: string;
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[];
  onClose: () => void;
  onRemoveMenuOrder: (menuOrderId: string) => void;
  avoidFoodGroupIds: string[];
  athleteOrderState: OrderFormAthleteOrderState;
  athleteOrderStateDispatch: React.Dispatch<AthleteOrderStateAction>;
  menuOrders: readonly MenuOrderFormFragment[];
  activeMenuOrder: MenuOrderFormFragment;
}

const MenuOrderEditDialogContent = ({
  activeMenuOrder,
  athleteId,
  athleteUserId,
  mealMenuDiningStations,
  teamId,
  onClose,
  onRemoveMenuOrder,
  avoidFoodGroupIds,
  athleteOrderState,
  athleteOrderStateDispatch,
  menuOrders,
}: MenuOrderEditDialogContentProps) => {
  const classes = useContentStyles();
  const [menuOrderItems, setMenuOrderItems] = useState(
    getMenuOrderItemsWithAppearance(
      activeMenuOrder.items,
      mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances)
    )
  );
  const [editedMenuOrderItemIds, setEditedMenuOrderItemIds] = useState<readonly string[]>([]);

  const { setMessage } = useSnackbar();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [fixOrderItemsAlertOpen, setFixOrderItemsAlertOpen] = useState(false);
  const [orderHasChanged, setOrderHasChanged] = useState(false);
  const orderStatus = reduceStatuses(activeMenuOrder.items.map(i => i.status));
  const apolloClient = useApolloClient();

  const handleChangeItems = (items: readonly MenuOrderItemWithAppearance[], editedItemId?: string) => {
    setMenuOrderItems(items);
    setOrderHasChanged(true);
    if (editedItemId && activeMenuOrder.items.map(i => i.id).includes(editedItemId) && !editedMenuOrderItemIds.includes(editedItemId)) {
      setEditedMenuOrderItemIds([...editedMenuOrderItemIds, editedItemId]);
    }
  };

  const handleError = async () => {
    setMessage("error", "Failed to edit order! Try again.");
    const { data } = await apolloClient.query<MenuOrderForFormQuery, MenuOrderForFormQueryVariables>({
      query: MenuOrderForFormDocument,
      variables: { id: activeMenuOrder.id },
    });
    if (data) {
      setMenuOrderItems(
        getMenuOrderItemsWithAppearance(
          data.menuOrder.items,
          mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances)
        )
      );
    }
  };

  const {
    pickupTime,
    setPickupTime: _setPickupTime,
    orderPickupTimes,
  } = usePickupTimeState({
    mealMenuId: activeMenuOrder.mealMenu.id,
    teamId,
    mealMenuStart: activeMenuOrder.mealMenu.start,
    mealMenuDurationInMinutes: activeMenuOrder.mealMenu.durationInMinutes,
    currentMenuOrderPickupTime: activeMenuOrder.pickupTime,
    mealMenuPrepTimeInMinutes: activeMenuOrder.mealMenu.prepTimeInMinutes,
  });

  const setPickupTime = (pickupTime: PickupTime) => {
    _setPickupTime(pickupTime);
    setOrderHasChanged(true);
  };

  const [editMenuOrder, { loading: savingEditItems }] = useEditMenuOrderMutation({
    onCompleted: () => {
      onClose();
      setMessage("success", "Success!");
    },
    onError: handleError,
  });

  const handleClickUpdate = () => {
    const result = getEditMenuOrderInputsOrErrors(
      activeMenuOrder.items,
      menuOrderItems,
      mealMenuDiningStations,
      editedMenuOrderItemIds,
      pickupTime
    );
    if (result.type === "inputs") {
      editMenuOrder({
        variables: {
          input: {
            menuOrderId: activeMenuOrder.id,
            items: null,
            pickupTime: result.pickupTime,
            addOrderItems: result.addOrderItems,
            addLogItems: result.addLogItems,
            editItems: result.editItems,
            removeItemIds: result.removeItemIds,
          },
        },
      });
    } else {
      if (result.invalidOrder) {
        setFixOrderItemsAlertOpen(true);
      } else {
        setMessage("error", result.error || "");
      }
    }
  };

  const menuOrderingClosed = isMealMenuClosed(activeMenuOrder.mealMenu);

  const handleClickCancel = () => {
    if (orderStatus !== "new") {
      setMessage("error", `Can't cancel. Order is being prepared`);
    } else {
      setCancelOpen(true);
    }
  };

  if (savingEditItems) {
    return <Loading />;
  }

  return (
    <>
      <MenuOrderDialogContent
        athleteId={athleteId}
        athleteUserId={athleteUserId}
        menuItemIds={mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances).map(mia => mia.menuItem.id)}
        targetMacros={activeMenuOrder.mealTemplate && getMealTemplateMacroTargets(activeMenuOrder.mealTemplate)}
        mealMenuDiningStations={mealMenuDiningStations}
        pickupTime={pickupTime}
        onChangePickupTime={setPickupTime}
        orderPickupTimes={orderPickupTimes}
        menuOrderingClosed={menuOrderingClosed}
        mealMenu={activeMenuOrder.mealMenu}
        mealMenuTimezone={activeMenuOrder.mealMenu.timezone}
        menuOrderItems={menuOrderItems}
        onChangeMenuOrderItems={handleChangeItems}
        orderStatus={orderStatus}
        avoidFoodGroupIds={avoidFoodGroupIds}
        athleteOrderState={athleteOrderState}
        athleteOrderStateDispatch={athleteOrderStateDispatch}
        menuOrders={menuOrders}
        orderButtons={
          menuOrderingClosed ? (
            orderHasChanged ? (
              <Button className={classes.orderButton} onClick={handleClickUpdate}>
                Update
              </Button>
            ) : null
          ) : orderHasChanged ? (
            <div className={classes.orderButtons}>
              <Button
                variant="containedDestructive"
                className={classes.orderButtonHalf}
                onClick={handleClickCancel}>
                Cancel
              </Button>
              <Button className={classes.orderButtonHalf} onClick={handleClickUpdate}>
                Update
              </Button>
            </div>
          ) : (
            <Button
              variant="containedDestructive"
              className={classes.orderButton}
              onClick={handleClickCancel}>
              Cancel
            </Button>
          )
        }
      />
      <ConfirmationDialog
        open={cancelOpen}
        title="Cancel Order"
        message="Are you sure that you would like to cancel your order?"
        onCancel={() => setCancelOpen(false)}
        onConfirm={() => onRemoveMenuOrder(activeMenuOrder.id)}
        confirmLabel="Yes"
        cancelLabel="No"
      />
      <Dialog open={fixOrderItemsAlertOpen} onClose={() => setFixOrderItemsAlertOpen(false)}>
        <DialogTitle title="Invalid Order" onClose={() => setFixOrderItemsAlertOpen(false)} />
        <DialogContent>
          <DialogContentText>Fix the menu order items highlighted in red to proceed.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFixOrderItemsAlertOpen(false)}> Ok </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuOrderEditDialogContent;

import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React, { Dispatch } from "react";
import { FoodLogNotificationSettingsFormAction, FoodLogNotificationSettingsFormState } from "./utils";

interface FoodLogNotificationSettingsFormProps {
  state: FoodLogNotificationSettingsFormState;
  dispatch: Dispatch<FoodLogNotificationSettingsFormAction>;
}

const FoodLogNotificationSettingsForm = ({ state, dispatch }: FoodLogNotificationSettingsFormProps) => {
  return (
    <Grid justifyContent="space-evenly" container>
      <Grid item md={4}>
        <FormControlLabel
          disabled={state.disabled}
          value={state.messages}
          control={<Checkbox checked={state.messages} />}
          label={"Messages"}
          labelPlacement="top"
          onChange={e => dispatch({ type: "TOGGLE_MESSAGE", payload: !state.messages })}
        />
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          disabled={state.disabled}
          value={state.imageUploads}
          control={<Checkbox checked={state.imageUploads} />}
          label={"Image Uploads"}
          labelPlacement="top"
          onChange={e =>
            dispatch({
              type: "TOGGLE_UPLOAD_IMAGE",
              payload: !state.imageUploads,
            })
          }
        />
      </Grid>
      <Grid item md={4}>
        <FormControlLabel
          disabled={state.disabled}
          value={state.edits}
          control={<Checkbox checked={state.edits} />}
          label={"Food Log Edits"}
          labelPlacement="top"
          onChange={e => dispatch({ type: "TOGGLE_EDIT_FOOD_LOG", payload: !state.edits })}
        />
      </Grid>
    </Grid>
  );
};

export default FoodLogNotificationSettingsForm;

import React from "react";
import { useRestaurantFullQuery } from "../../../../types";
import EditDialog from "./Dialog";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";

interface EditProps {
  open: boolean;
  onClose: () => void;
  onEdit: (restaurantName: string) => void;
  restaurantId: string;
}

const Edit = ({ open, onClose, onEdit, restaurantId }: EditProps) => {
  const { data } = useRestaurantFullQuery({
    variables: { id: restaurantId },
  });

  return data ? (
    <EditDialog
      restaurant={data.restaurant}
      onEdit={onEdit}
      onClose={onClose}
      open={open} />
  ) : (
    <LoadingBackdrop open={!data} onClose={() => {}} />
  );
};

export default Edit;

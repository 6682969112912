import React, { useState } from "react";
import BaseAsyncAutoComplete, { BasicOption } from "../../../../../components/universal/BaseAsyncAutocomplete";
import {
  DeliveryLocationMenuLinkFormFragment,
  TeamLabelFragment,
  useDeliveryLocationSearchQuery,
  useTeamLabelSearchQuery,
} from "../../../../../types";

const useSearchQuery_TeamLabel = (query: string) => {
  const { data, loading } = useTeamLabelSearchQuery({
    variables: { query },
    fetchPolicy: "network-only",
  });
  const options = data ? [...data.allTeamLabels.edges] : undefined;
  return { options, loading };
};

interface DeliveryLocationSelectProps {
  value: readonly DeliveryLocationMenuLinkFormFragment[];
  onChange: (newValue: readonly DeliveryLocationMenuLinkFormFragment[]) => void;
}

const DeliveryLocationSelect = ({ value, onChange }: DeliveryLocationSelectProps) => {
  const [teamLabel, setTeamLabel] = useState<TeamLabelFragment | null>(null);

  const useSearchQuery_DeliveryLocation = (query: string) => {
    const { data, loading } = useDeliveryLocationSearchQuery({
      variables: { query, teamLabelId: teamLabel ? teamLabel.id : "" },
      skip: teamLabel === null,
      fetchPolicy: "network-only",
    });
    const options = data ? [...data.allDeliveryLocations.edges] : undefined;
    return { options, loading };
  };

  const selectedDeliveryLocationIds = value.map(dl => dl.id);
  const filterUsedOptions = (options: DeliveryLocationMenuLinkFormFragment[] | undefined) => {
    if (options === undefined) {
      return undefined;
    }
    return options.filter(tt => !selectedDeliveryLocationIds.includes(tt.id));
  };

  return (
    <>
      <BaseAsyncAutoComplete
        useSearchQuery={useSearchQuery_TeamLabel}
        inputPlaceholder="Team Label for Delivery Locations"
        noOptionsText="No Team Labels Found"
        handleChange={setTeamLabel}
        multiple={false}
        renderOption={({ name }: TeamLabelFragment) => <BasicOption element={name} />}
        filterUsedOptions={o => o}
        getOptionLabel={(teamLabel: TeamLabelFragment) => teamLabel.name}
        value={teamLabel}
      />
      <BaseAsyncAutoComplete
        useSearchQuery={useSearchQuery_DeliveryLocation}
        inputPlaceholder="Add Delivery Locations"
        noOptionsText={teamLabel ? "No Delivery Locations Found" : "Select a Team Label"}
        handleChange={onChange}
        filterUsedOptions={filterUsedOptions}
        multiple
        renderOption={({ name }: DeliveryLocationMenuLinkFormFragment) => <BasicOption element={name} />}
        getOptionLabel={({ name }: DeliveryLocationMenuLinkFormFragment) => name}
        value={value.concat()}
      />
    </>
  );
};

export default DeliveryLocationSelect;

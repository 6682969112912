import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  EditImperialMacroProtocolState,
  getAnthropometrySnapshotFromAnthroInfoState,
} from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import { applyWeightTarget, macroGPerKgToGramsMacrosRounded } from "@notemeal/shared/utils/macro-protocol";
import React from "react";

interface ImperialMacroProtocolViewProps {
  macroProtocol: EditImperialMacroProtocolState;
  onEdit: () => void;
}

export const ImperialMacroProtocolView = ({ macroProtocol, onEdit }: ImperialMacroProtocolViewProps) => {
  const { cho, pro, fat } = macroProtocol;
  const targetAnthroSnapshot = applyWeightTarget(
    getAnthropometrySnapshotFromAnthroInfoState(macroProtocol.anthroInfo),
    macroProtocol.usingWeightTarget ? macroProtocol.weightTarget : null
  );
  const { weight } = targetAnthroSnapshot;
  const { cho: choGrams, pro: proGrams, fat: fatGrams, kcal } = macroGPerKgToGramsMacrosRounded(cho, pro, fat, weight);
  const choText = cho.usesPercent ? `${cho.percent}%` : `${cho.gPerKg} g/kg`;
  const proText = pro.usesPercent ? `${pro.percent}%` : `${pro.gPerKg} g/kg`;
  const fatText = fat.usesPercent ? `${fat.percent}%` : `${fat.gPerKg} g/kg`;

  return (
    <Box sx={{ px: 2, border: 1, borderRadius: 1, borderColor: "#D0D5DD", display: "flex", gap: 2, alignItems: "center" }}>
      <Typography variant="h4">Example Daily Macros</Typography>
      <Typography variant="body2">
        <Box sx={{ color: "cho.main" }} component={"span"}>
          CHO
        </Box>
        : {choGrams}g ({choText})
      </Typography>
      <Typography variant="body2">
        <Box sx={{ color: "pro.main" }} component={"span"}>
          PRO
        </Box>
        : {proGrams}g ({proText})
      </Typography>
      <Typography variant="body2">
        <Box sx={{ color: "fat.main" }} component={"span"}>
          FAT
        </Box>
        : {fatGrams}g ({fatText})
      </Typography>
      <Typography variant="body2">
        <span>Calories:</span>
        <span>{kcal} kcal</span>
      </Typography>
      <Tooltip title="Edit">
        <IconButton size="small" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

import React from "react";
import { useContext, createContext } from "react";
import { PureQueryOptions } from "@apollo/client";

const RefetchQueriesContext = createContext<PureQueryOptions[]>([]);

interface RefetchQueriesContextProviderProps {
  refetchQueries: PureQueryOptions[];
  children: React.ReactNode;
}

export const RefetchQueriesContextProvider = ({ refetchQueries, children }: RefetchQueriesContextProviderProps) => {
  return <RefetchQueriesContext.Provider value={refetchQueries}>{children}</RefetchQueriesContext.Provider>;
};

export const useRefetchQueriesContext = () => {
  return useContext(RefetchQueriesContext);
};

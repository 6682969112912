import { AddAgreementFormInput } from "../../../types";

export const buildAgreementFormTooltips = (input: AddAgreementFormInput): string[] => {
  const tooltips: string[] = [];
  if (!input.title.trim()) {
    tooltips.push("'Name' is required");
  }
  if (!input.url.trim()) {
    tooltips.push("'Url' is required");
  }
  if (!input.acceptAgreementText.trim()) {
    tooltips.push("'Accept Agreement Text' is required");
  }

  return tooltips;
};

import {
  CuisineFragment,
  CursorPaginationInput,
  DeliveryLocationMenuLinkFormFragment,
  DietFragment,
  PaymentMethodFragment,
  RestaurantLocationSearchQueryVariables,
  RestaurantMenuSearchQueryVariables,
  RestaurantPreviewFragment,
  RestaurantTypeFragment,
  TeamLabelFragment,
} from "../../../types";
import { Idable } from "../../../views/Staff/GeneralizedStaffContent/types";

export interface RestaurantSearchState {
  teamLabel: TeamLabelFragment | undefined;
  deliveryLocation: DeliveryLocationMenuLinkFormFragment | undefined;
  cuisines: CuisineFragment[] | undefined;
  diets: DietFragment[] | undefined;
  restaurantTypes: RestaurantTypeFragment[] | undefined;
  paymentMethods: PaymentMethodFragment[] | undefined;
  query: string | undefined;
  restaurants: RestaurantPreviewFragment[] | undefined;
}

interface ChangeTeamAndDeliveryLocation {
  type: "CHANGE_TEAM_AND_DELIVERY_LOCATION";
  payload: {
    teamLabel: TeamLabelFragment | undefined;
    deliveryLocation: DeliveryLocationMenuLinkFormFragment | undefined;
  };
}

interface ChangeCuisines {
  type: "CHANGE_CUISINES";
  payload: {
    cuisines: CuisineFragment[] | undefined;
  };
}

interface ChangeDiets {
  type: "CHANGE_DIETS";
  payload: {
    diets: DietFragment[] | undefined;
  };
}

interface ChangeRestaurantTypes {
  type: "CHANGE_RESTAURANT_TYPES";
  payload: {
    restaurantTypes: RestaurantTypeFragment[] | undefined;
  };
}

interface ChangePaymentMethods {
  type: "CHANGE_PAYMENT_METHODS";
  payload: {
    paymentMethods: PaymentMethodFragment[] | undefined;
  };
}

interface ChangeQuery {
  type: "CHANGE_QUERY";
  payload: {
    query: string | undefined;
  };
}

interface ChangeRestaurants {
  type: "CHANGE_RESTAURANTS";
  payload: {
    restaurants: RestaurantPreviewFragment[] | undefined;
  };
}

interface ResetFilters {
  type: "RESET_FILTERS";
}

export type RestaurantSearchAction =
  | ChangeTeamAndDeliveryLocation
  | ChangeCuisines
  | ChangeDiets
  | ChangeRestaurantTypes
  | ChangePaymentMethods
  | ChangeQuery
  | ChangeRestaurants
  | ResetFilters;

export function restaurantSearchReducer<T extends RestaurantSearchState>(state: T, action: RestaurantSearchAction): RestaurantSearchState {
  switch (action.type) {
    case "CHANGE_TEAM_AND_DELIVERY_LOCATION":
      return {
        ...state,
        teamLabel: action.payload.teamLabel,
        deliveryLocation: action.payload.deliveryLocation,
      };
    case "CHANGE_CUISINES":
      return {
        ...state,
        cuisines: action.payload.cuisines,
      };
    case "CHANGE_DIETS":
      return {
        ...state,
        diets: action.payload.diets,
      };
    case "CHANGE_RESTAURANT_TYPES":
      return {
        ...state,
        restaurantTypes: action.payload.restaurantTypes,
      };
    case "CHANGE_PAYMENT_METHODS":
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods,
      };
    case "CHANGE_QUERY":
      return {
        ...state,
        query: action.payload.query,
      };
    case "CHANGE_RESTAURANTS":
      return {
        ...state,
        restaurants: action.payload.restaurants,
      };
    case "RESET_FILTERS":
      return buildInitialState();
    default:
      return state;
  }
}

export const buildInitialState = (deliveryLocation?: DeliveryLocationMenuLinkFormFragment): RestaurantSearchState => ({
  deliveryLocation: deliveryLocation,
  teamLabel: undefined,
  cuisines: undefined,
  diets: undefined,
  restaurantTypes: undefined,
  paymentMethods: undefined,
  query: undefined,
  restaurants: undefined,
});

const toId = (obj: Idable | null) => (obj ? obj.id : null);
const toIdArrayOrNull = <T extends Idable>(array: T[] | undefined | null): string[] | null => {
  if (array === undefined || array === null || array.length === 0) {
    return null;
  }
  const mappedArray: string[] = array.map(toId).filter(id => id !== null) as string[];
  return mappedArray.length > 0 ? mappedArray : null;
};

export const nullOrEmptyToUndefined = <T extends object>(obj: Array<T> | null) => {
  if (obj === null) {
    return undefined;
  }
  if (Array.isArray(obj) && obj.length === 0) {
    return undefined;
  }
  return obj;
};

export const getLocationSearchVariables = (
  state: RestaurantSearchState,
  pagination: CursorPaginationInput | null
): RestaurantLocationSearchQueryVariables | undefined => {
  if (state.deliveryLocation === undefined) {
    return undefined;
  }
  return {
    pagination,
    deliveryLocationId: state.deliveryLocation.id,
    restaurantIds: toIdArrayOrNull(state.restaurants),
    restaurantTypeIds: toIdArrayOrNull(state.restaurantTypes),
    paymentMethodIds: toIdArrayOrNull(state.paymentMethods),
    cuisineIds: toIdArrayOrNull(state.cuisines),
    dietIds: toIdArrayOrNull(state.diets),
    query: state.query ?? null,
  };
};

export const getMenuSearchVariables = (
  state: RestaurantSearchState,
  pagination: CursorPaginationInput | null
): RestaurantMenuSearchQueryVariables | undefined => {
  return {
    pagination,
    restaurantTypeIds: toIdArrayOrNull(state.restaurantTypes),
    paymentMethodIds: toIdArrayOrNull(state.paymentMethods),
    cuisineIds: toIdArrayOrNull(state.cuisines),
    dietIds: toIdArrayOrNull(state.diets),
    query: state.query ?? null,
  };
};

export const hasLength = (obj: Array<any> | string | undefined): boolean => {
  return !(obj === undefined || obj.length === 0);
};

export const getRestaurantSearchMode = (state: RestaurantSearchState): "Menu" | "Location" | "DeliveryLocationRequired" => {
  if (!!state.deliveryLocation) {
    return "Location";
  } else if (!!state.teamLabel) {
    return "DeliveryLocationRequired";
  }
  return "Menu";
};

import { setReportingUser } from "../../reporting/reporting";
import { UserOrgForReportingQuery, useUserOrgForReportingQuery } from "../../types";
import { useUser } from "../tokens";

export const useReportingUserIdentify = async (
  { forStaff }: { forStaff: boolean },
  callback?: (data: UserOrgForReportingQuery) => void
) => {
  const jwtUser = useUser();

  useUserOrgForReportingQuery({
    fetchPolicy: "no-cache",
    onCompleted: ({ myUser, myOrgForReporting }) => {
      if (callback) {
        callback({ myUser, myOrgForReporting });
      }

      setReportingUser(jwtUser, myUser, myOrgForReporting, forStaff);
    },
  });
};

export const parseMassField = (mass: string | null): number | null => {
  if (!mass) {
    return null;
  } else if (mass.includes("lbs")) {
    // parseFloat will just ignore anything past the number, so we don't need to remove `lbs`
    return parseFloat(mass);
  } else {
    // convert grams to kilograms
    const kgString = mass.replace(".", "").replace(",", ".").replace('"', "");
    // convert kilograms to pounds
    return parseFloat(kgString) * 2.205;
  }
};

export const parsePercentField = (percent: string | null): number | null => {
  if (!percent) {
    return null;
  } else if (percent.includes("%")) {
    return parseFloat(percent);
  } else {
    // if it doesn't include % then assume it's a decimal and convert to percent
    return parseFloat(percent) * 100;
  }
};

import { round } from "./numbers";

export const formatHeight = (height: number) => {
  const feet = Math.floor(height / 12);
  const inches = round(height - 12 * feet, 1);
  return `${feet}'${inches}"`;
};

export const formatWeight = (weight: number) => {
  return `${weight} lbs`;
};

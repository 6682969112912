import { AssignFromMealPlanTemplateFragment, MealPlanTemplateGoalFragment } from "apps/web/src/types";
import { dateAssignmentReducer, initDateAssignmentState, MealPlanDateAssignmentAction } from "../Content/Calendar/Form/reducer";
import { MealPlanDateAssignment } from "../Content/Calendar/Form/types";

export interface AssignFromMealPlanTemplateState {
  dateAssignment: MealPlanDateAssignment;
  name: string;
  note: string;
  selectedTemplate: AssignFromMealPlanTemplateFragment | null;
  selectedGoal: MealPlanTemplateGoalFragment | null;
  isAutoSuggestionsEnabled: boolean;
}

interface ChangeNameAction {
  type: "CHANGE_NAME";
  payload: string;
}
interface ChangeNoteAction {
  type: "CHANGE_NOTE";
  payload: string;
}
interface ChangeSelectedGoalAction {
  type: "CHANGE_SELECTED_GOAL";
  payload: MealPlanTemplateGoalFragment | null;
}
interface ChangeSelectedTemplateAction {
  type: "CHANGE_SELECTED_TEMPLATE";
  payload: AssignFromMealPlanTemplateFragment;
}

interface ToggleAutoSuggestionsAction {
  type: "TOGGLE_AUTO_SUGGESTIONS";
  payload: boolean;
}

export type AssignFromMealPlanTemplateAction =
  | MealPlanDateAssignmentAction
  | ChangeNameAction
  | ChangeNoteAction
  | ChangeSelectedGoalAction
  | ChangeSelectedTemplateAction
  | ToggleAutoSuggestionsAction;

export const assignFromMealPlanTemplateReducer = (
  state: AssignFromMealPlanTemplateState,
  action: AssignFromMealPlanTemplateAction
): AssignFromMealPlanTemplateState => {
  switch (action.type) {
    case "CHANGE_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "CHANGE_NOTE":
      return {
        ...state,
        note: action.payload,
      };
    case "CHANGE_SELECTED_GOAL":
      return {
        ...state,
        selectedGoal: action.payload,
      };
    case "CHANGE_SELECTED_TEMPLATE":
      return {
        ...state,
        name: action.payload.name,
        selectedTemplate: action.payload,
      };
    case "ToggleDayOfWeekAssignment":
    case "EditDateAssignmentMode":
    case "ToggleIndividualDateAssignment":
    case "EditStartDate":
    case "EditEndDate":
    case "ToggleEndDate":
      return {
        ...state,
        dateAssignment: dateAssignmentReducer(state.dateAssignment, action),
      };
    case "TOGGLE_AUTO_SUGGESTIONS":
      return {
        ...state,
        isAutoSuggestionsEnabled: action.payload,
      };
  }
};

export const buildInitAssignFromMealPlanTemplateState = (clientTimezone: string) => {
  return {
    dateAssignment: initDateAssignmentState(clientTimezone),
    name: "",
    note: "",
    selectedGoal: null,
    selectedTemplate: null,
    isAutoSuggestionsEnabled: false,
  };
};

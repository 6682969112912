import CloseIcon from "@mui/icons-material/Close";
import Restaurant from "@mui/icons-material/Restaurant";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { sortByKey, sortByKeys } from "@notemeal/utils/sort";
import React, { Fragment, ReactNode, useMemo, useState } from "react";
import { KioskMealMenuPreviewFragment } from "../../types";
import KioskTeamsList from "./TeamList";
import { KioskAthlete, getLettersForAthleteNavigation } from "./utils";

const appearanceMediaStyle = { height: "120px" };
const cardContentStyle = {
  "&:last-child": {
    pb: 1,
  },
};

const styles = {
  leftPanel: {
    flexShrink: 0,
    width: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    height: "100%",
    gap: 2,
  },
  athleteList: {
    display: "flex",
    overflow: "hidden",
    height: "100%",
    flex: 1,
    mt: 4,
  },
  menuPreviewContent: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    height: "100%",
    ...cardContentStyle,
    pt: 0,
  },
  menuItems: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: 250,
    overflowY: "scroll",
    gap: 2,
  },
  selectAthletesColumn: {
    ml: 2,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "100%",
    overflowX: "auto",
  },
  intro: {
    gridRow: "2 / 2",
    gridColumn: "1 / 1",
  },
  selector: {
    gridRow: "2 / 4",
    gridColumn: "2 / 2",
    display: "flex",
    flexDirection: "column",
    p: 4,
  },
  preview: {
    gridRow: "3 / 3",
    gridColumn: "1 / 1",
  },
};

interface AthleteNavigationContentProps<A extends KioskAthlete> {
  athletes: readonly A[];
  athleteListHeader?: ReactNode;
  renderAthleteListItem: (athlete: A, autoFocus: boolean, backgroundTinted: boolean) => ReactNode;
  searchText: string;
  setSearchText: (value: string) => void;
  selectedTeamId: string | null;
  setSelectedTeamId: (value: string | null) => void;
  mealMenus: readonly KioskMealMenuPreviewFragment[];
}

const AthleteNavigationContent = <A extends KioskAthlete>({
  athletes,
  athleteListHeader,
  mealMenus,
  renderAthleteListItem,
  searchText,
  setSearchText,
  selectedTeamId,
  setSelectedTeamId,
}: AthleteNavigationContentProps<A>) => {
  const [autoFocusItemId, setAutoFocusItemId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);

  const teamInfos = useMemo(() => {
    const seenTeamIds: Set<string> = new Set();
    const teams: KioskAthlete["team"][] = [];
    athletes.forEach(({ team }) => {
      if (!seenTeamIds.has(team.id)) {
        teams.push(team);
      }
      seenTeamIds.add(team.id);
    });
    return sortByKey(teams, "name");
  }, [athletes]);

  const teamFilteredAthletes = useMemo(
    () => sortByKeys(selectedTeamId ? athletes.filter(({ team }) => team.id === selectedTeamId) : athletes, ["lastName", "firstName"]),
    [athletes, selectedTeamId]
  );

  const displayedAthletes = useMemo(
    () =>
      searchText
        ? teamFilteredAthletes.filter(
            a =>
              a.firstName.toUpperCase().startsWith(searchText.toUpperCase(), 0) ||
              a.lastName.toUpperCase().startsWith(searchText.toUpperCase(), 0)
          )
        : teamFilteredAthletes,
    [teamFilteredAthletes, searchText]
  );

  const lettersSpread = useMemo(() => getLettersForAthleteNavigation(displayedAthletes.map(a => a.lastName)), [displayedAthletes]);
  const lettersStack = [...lettersSpread];

  const handleJumpToLetter = (letter: string) => {
    const jumpToAthlete = displayedAthletes.find(a => a.lastName.toLowerCase().startsWith(letter.toLowerCase()));
    setAutoFocusItemId(jumpToAthlete?.id ?? null);
  };

  const resetSearchText = () => setSearchText("");

  return (
    <Fragment>
      <Card sx={styles.intro}>
        <CardMedia
          component="img"
          src="/img/kiosk_home.jpg"
          sx={{ maxHeight: "100px" }} />
        <CardContent sx={cardContentStyle}>
          <Typography variant="h3" gutterBottom>
            Hey there!
          </Typography>
          <Typography>Place your order by following these steps:</Typography>
          <List
            component="ol"
            sx={{
              listStyleType: "decimal",
              pl: 3,
              pb: 0,
              "& li": {
                display: "list-item",
                p: 0,
              },
            }}
          >
            <ListItem disableGutters>
              <ListItemText>Select your name from the list on the right</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText>Choose a menu that you would like to order from</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText>Add item(s) to your order</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText>Once you are ready, choose a pickup time and click “Place Order”</ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText>Pick up your food when it is ready and enjoy!</ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card sx={styles.preview}>
        <CardHeader
          sx={{ pb: 1 }}
          title={
            <Typography variant="h3">
              Menu Preview{" "}
              <Box
                component="span"
                m="{1}"
                sx={({ spacing, palette: { text } }: Theme) => ({ fontSize: spacing(2), color: text.disabled })}
              >
                (View only)
              </Box>
            </Typography>
          }
        />
        <CardContent sx={styles.menuPreviewContent}>
          {mealMenus.length > 1 && (
            <Tabs
              sx={{ pt: 0 }}
              textColor="primary"
              value={activeTab}
              variant="scrollable"
              indicatorColor="primary"
              onChange={(e, value) => {
                setActiveTab(value);
              }}
            >
              {mealMenus.map(mealMenu => (
                <Tab
                  label={mealMenu.name}
                  wrapped
                  key={mealMenu.id} />
              ))}
            </Tabs>
          )}
          {mealMenus.map(
            (mealMenu, index) =>
              activeTab === index && (
                <Box sx={styles.menuItems} key={mealMenu.id}>
                  {mealMenu.mealMenuDiningStations.map(diningStation =>
                    diningStation.menuItemAppearances.map((appearance, index) => (
                      <Card
                        key={index}
                        sx={{ width: "20%" }}
                        elevation={0}>
                        {appearance.menuItem.imageUrl ? (
                          <CardMedia
                            component="img"
                            image={appearance.menuItem.imageUrl}
                            sx={{ ...appearanceMediaStyle }} />
                        ) : (
                          <CardMedia
                            sx={{
                              ...appearanceMediaStyle,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "lightgrey",
                            }}
                          >
                            <Restaurant color="disabled" />
                          </CardMedia>
                        )}
                        <Typography sx={{ pt: 1 }}>{appearance.menuItem.name}</Typography>
                      </Card>
                    ))
                  )}
                </Box>
              )
          )}
        </CardContent>
      </Card>
      <Card sx={styles.selector}>
        <Typography variant="h3" gutterBottom>
          Select Your Name
        </Typography>
        <TextField
          sx={{
            width: "100%",
            "& input": {
              pt: 2,
              pb: 2,
            },
          }}
          margin="dense"
          value={searchText}
          placeholder="Search by Athlete Name"
          onChange={e => setSearchText(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment:
              searchText !== "" ? (
                <InputAdornment
                  position="end"
                  onClick={resetSearchText}
                  sx={{ cursor: "pointer" }}>
                  <CloseIcon />
                </InputAdornment>
              ) : undefined,
          }}
        />
        <Box sx={styles.athleteList}>
          <Box sx={styles.leftPanel}>
            <Box>
              <Typography variant="h3">Filter Teams</Typography>
              <KioskTeamsList
                teams={teamInfos}
                selectedTeamIds={selectedTeamId ? [selectedTeamId] : []}
                onClick={teamId => {
                  selectedTeamId === teamId ? setSelectedTeamId(null) : setSelectedTeamId(teamId);
                }}
                disabledTeamIds={[]}
                sx={{ flexGrow: 1, overflowY: "auto", maxHeight: 150 }}
              />
            </Box>
            <Box>
              <Typography variant="h3" gutterBottom>
                Jump to Letter
              </Typography>
              <Box
                sx={({ spacing }) => ({
                  overflowY: "auto",
                  maxHeight: spacing(28),
                  display: "grid",
                  gap: 2,
                  gridTemplateColumns: `repeat(auto-fill, ${spacing(3)})`,
                })}
              >
                {lettersSpread.map(letter => (
                  <Typography
                    variant="h3"
                    onClick={() => handleJumpToLetter(letter)}
                    sx={{
                      display: "inline",
                      cursor: "pointer",
                      "&:hover": {
                        color: "info.main",
                      },
                    }}
                    key={letter}
                  >
                    {letter.toUpperCase()}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={styles.selectAthletesColumn}>
            {athleteListHeader}
            <Box sx={{ overflowY: "auto" }}>
              {displayedAthletes.map((ath, i) => {
                const autoFocus = autoFocusItemId === ath.id;
                const backgroundTinted = i % 2 === 0;
                return (
                  <Fragment key={ath.id}>
                    {lettersStack[0] === ath.lastName.substring(0, 1) && (
                      <ListSubheader disableSticky>{lettersStack.shift()}</ListSubheader>
                    )}
                    {renderAthleteListItem(ath, autoFocus, backgroundTinted)}
                  </Fragment>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Card>
    </Fragment>
  );
};

export default AthleteNavigationContent;

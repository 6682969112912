import { Dialog, DialogProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";

/**
 * TODO - turn this into a styled component and find uses of action dialogs across codebase and replace with this component
 */

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      height: `calc(100% - 64px)`,
    },
  })
);

interface ActionDialogProps {
  children: ReactNode;
  onClose?: DialogProps["onClose"];
  open: boolean;
}

const ActionDialog = ({ children, onClose, open }: ActionDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      {children}
    </Dialog>
  );
};

export default ActionDialog;

import { Box, Button, DialogActions, DialogContent, Step, StepLabel, Stepper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import Loading from "@notemeal/shared/ui/global/Loading";
import { maybeAthleteBirthDateToAge } from "@notemeal/shared/utils/macro-protocol";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import FoodPreferenceMealPlanForm from "apps/web/src/components/FoodPreference/MealPlan/Form";
import {
  foodPreferenceFormToSaveTooltips,
  foodPreferenceReducer,
  initialFoodPreferenceState,
} from "apps/web/src/components/FoodPreference/MealPlan/reducer";
import { ImperialMacroProtocolForm } from "apps/web/src/components/MacroProtocol/Form/ImperialMacroProtocolForm";
import { MetricMacroProtocolForm } from "apps/web/src/components/MacroProtocol/Form/MetricMacroProtocolForm";
import { macroProtocolStateToSaveTooltips, metricMacroProtocolStateToSaveTooltips } from "apps/web/src/components/MacroProtocol/Form/utils";
import { useImperialMacroProtocolReducer, useMetricMacroProtocolReducer } from "apps/web/src/components/MacroProtocol/utils";
import { initialMealPlanFormState, mealPlanFormReducer, mealPlanFormToSaveTooltips } from "apps/web/src/components/MealPlan/Form/utils";
import { NameAndTypeForm } from "apps/web/src/components/MealPlan/NameAndTypeForm";
import { getInitialMealPlanName, getInitialMetricMealPlanName } from "apps/web/src/components/MealPlan/utils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { getNavOrgAthleteMealPlansMealPlan } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  AnthropometryEntryFragment,
  ExchangeSetFragment,
  FullGoalFragment,
  FullScheduleFragment,
  GoalTypeFragment,
  useCreateMetricMealPlanMutation,
  useMealPlanConflictsQuery,
} from "../../../../types";
import MealPlanDateAssignmentConflictDialog from "../Content/Calendar/Form/ConflictDialog";
import { MealPlanCalendarCreateForm } from "../Content/Calendar/Form/MealPlanCalendarCreateForm";
import { createMealPlanCalendarReducer, initCreateMealPlanCalendarState } from "../Content/Calendar/Form/reducer";
import {
  MealPlanDateAssignmentConflictResolution,
  getDateAssignmentConflicts,
  getMealPlanConflictsQueryVariables,
  mealPlanCalendarStateToSaveTooltips,
} from "../Content/Calendar/Form/utils";
import { evictMealPlanCalendarQueriesForAthlete } from "../Content/Calendar/cache";
import { useCreateMealPlanCalendarInRange } from "../Content/Calendar/loaders";
import {
  activeModal,
  getCreateMetricMealPlanInputFromImperialMacroProtocol,
  getCreateMetricMealPlanInputFromMetricMacroProtocol,
} from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      flexDirection: "column",
    },
    stepper: {
      alignSelf: "center",
      paddingBottom: theme.spacing(0),
    },
  })
);

const steps = ["Calendar", "Macronutrient Protocol", "Name and Type", "Food Preferences"];

interface CreateMealPlanDialogContentProps {
  athleteId: string;
  currentGoal: FullGoalFragment | null;
  mostRecentAnthropometryEntry: AnthropometryEntryFragment;
  athleteBirthdate: string | null;
  exchangeSets: readonly ExchangeSetFragment[];
  goalTypes: readonly GoalTypeFragment[];
  schedules: readonly FullScheduleFragment[];
  teamSchedules: readonly FullScheduleFragment[];
  sportName: string;
  onClose: (step?: number) => void;
  onCompleted: () => void;
  teamId: string;
}

const CreateMealPlanDialogContent = ({
  athleteId,
  currentGoal,
  mostRecentAnthropometryEntry,
  athleteBirthdate,
  goalTypes,
  onClose,
  onCompleted,
  schedules,
  teamSchedules,
  sportName,
  exchangeSets,
  teamId,
}: CreateMealPlanDialogContentProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const clientTimezone = useClientTimezone();
  const { setMessage } = useSnackbar();
  const navigate = useNavigate();
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();

  React.useEffect(() => {
    // could be improved by tracing if form is dirty
    setBrowserBackAndRefreshWarningEnabled(true);
  }, [setBrowserBackAndRefreshWarningEnabled]);

  const { isMetricLocale } = useLocaleContext();

  const athleteAge = maybeAthleteBirthDateToAge(athleteBirthdate);

  const [createMetricMealPlan] = useCreateMetricMealPlanMutation({
    update: (cache, { data }) => {
      if (data) {
        evictMealPlanCalendarQueriesForAthlete({
          athleteId,
          cache,
          type: "Create",
        });
      }
    },
    onCompleted: data => {
      const mealPlanId = data.createMetricMealPlan.mealPlan.id;
      onCompleted();
      navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, mealPlanId));
    },
    onError: () => {
      setBrowserBackAndRefreshWarningEnabled(true);
      setMessage("error", "Something went wrong");
    },
  });

  const [calendarState, calendarDispatch] = useReducer(
    createMealPlanCalendarReducer,
    initCreateMealPlanCalendarState({ athleteId, clientTimezone, schedules, teamSchedules })
  );
  const [mptFormState, mptFormDispatch] = useReducer(mealPlanFormReducer, initialMealPlanFormState(exchangeSets));

  const [imperialMacroProtocolFormState, imperialMacroProtocolFormDispatch] = useImperialMacroProtocolReducer({
    currentGoal,
    mostRecentAnthropometryEntry,
    athleteAge,
    goalTypes,
  });

  const [metricMacroProtocolFormState, metricMacroProtocolFormDispatch] = useMetricMacroProtocolReducer({
    currentGoal,
    mostRecentAnthropometryEntry,
    athleteAge,
    goalTypes,
  });

  const [foodPreferenceFormState, foodPreferenceFormDispatch] = useReducer(foodPreferenceReducer, {}, initialFoodPreferenceState);

  const [conflictDialogOpen, setConflictDialogOpen] = useState(false);
  const { data: conflictsData, variables: conflictsVariables } = useMealPlanConflictsQuery(
    getMealPlanConflictsQueryVariables(athleteId, calendarState)
  );
  const [conflictResolutions, setConflictResolutions] = useState<readonly MealPlanDateAssignmentConflictResolution[] | null>(null);

  const handleOnBack = () => {
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Back", { modalStep: activeModal[activeStep], athleteId, teamId });
    setActiveStep(activeStep - 1);
  };

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case 0:
        return mealPlanCalendarStateToSaveTooltips(calendarState);
      case 1:
        return isMetricLocale
          ? metricMacroProtocolStateToSaveTooltips(metricMacroProtocolFormState)
          : macroProtocolStateToSaveTooltips(imperialMacroProtocolFormState);
      case 2:
        return mealPlanFormToSaveTooltips(mptFormState);
      case 3:
        return foodPreferenceFormToSaveTooltips(foodPreferenceFormState);
      default:
        return [];
    }
  };

  const canSaveTooltips = getCanSaveTooltipItems();

  const runImperialSideEffects = (): { advanceStep: boolean } => {
    const { calorieBudget } = imperialMacroProtocolFormState;
    const { goalSnapshot } = calorieBudget;
    const { type } = goalSnapshot;
    switch (activeStep) {
      case 0:
        console.log({ conflictsData });
        if (!conflictsData) {
          return { advanceStep: false };
        } else {
          const conflicts = getDateAssignmentConflicts(calendarState, conflictsData);
          if (conflicts.length === 0) {
            setConflictResolutions([]);
            return { advanceStep: true };
          } else {
            setConflictDialogOpen(true);
            return { advanceStep: false };
          }
        }
      case 1:
        mptFormDispatch({
          type: "CHANGE_NAME",
          payload: getInitialMealPlanName(
            imperialMacroProtocolFormState,
            { ...mostRecentAnthropometryEntry, age: athleteAge },
            type && { ...goalSnapshot, type },
            sportName
          ),
        });
        return { advanceStep: true };
      case 3:
        // conflictResolutions should be non-null past step 1
        // but this check is still necessary because there is no type narrowing occurring here based on step
        if (!conflictResolutions) {
          return { advanceStep: false };
        }
        const input = getCreateMetricMealPlanInputFromImperialMacroProtocol(
          athleteId,
          currentGoal,
          calendarState,
          conflictResolutions,
          imperialMacroProtocolFormState,
          mptFormState,
          foodPreferenceFormState
        );
        if (!input) {
          return { advanceStep: false };
        }

        setBrowserBackAndRefreshWarningEnabled(false);

        createMetricMealPlan({
          variables: {
            input,
          },
        });
        return { advanceStep: true };
    }
    return { advanceStep: true };
  };

  const runMetricSideEffects = (): { advanceStep: boolean } => {
    const { calorieBudget } = metricMacroProtocolFormState;
    const { goalSnapshot } = calorieBudget;
    const { type } = goalSnapshot;
    switch (activeStep) {
      case 0:
        if (!conflictsData) {
          return { advanceStep: false };
        } else {
          const conflicts = getDateAssignmentConflicts(calendarState, conflictsData);
          if (conflicts.length === 0) {
            setConflictResolutions([]);
            return { advanceStep: true };
          } else {
            setConflictDialogOpen(true);
            return { advanceStep: false };
          }
        }
      case 1:
        mptFormDispatch({
          type: "CHANGE_NAME",
          payload: getInitialMetricMealPlanName(
            metricMacroProtocolFormState,
            { ...mostRecentAnthropometryEntry, age: athleteAge },
            type && { ...goalSnapshot, type },
            sportName
          ),
        });
        return { advanceStep: true };
      case 3:
        // conflictResolutions should be non-null past step 1
        // but this check is still necessary because there is no type narrowing occurring here based on step
        if (!conflictResolutions) {
          return { advanceStep: false };
        }
        const input = getCreateMetricMealPlanInputFromMetricMacroProtocol(
          athleteId,
          currentGoal,
          calendarState,
          conflictResolutions,
          metricMacroProtocolFormState,
          mptFormState,
          foodPreferenceFormState
        );
        if (!input) {
          return { advanceStep: false };
        }

        createMetricMealPlan({
          variables: {
            input,
          },
        });
        return { advanceStep: true };
    }
    return { advanceStep: true };
  };

  const runSideEffects = (): { advanceStep: boolean } => (isMetricLocale ? runMetricSideEffects() : runImperialSideEffects());

  const handleClose = () => {
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Cancel", { modalStep: activeModal[activeStep], athleteId, teamId });
    onClose();
  };

  const handleOnNext = () => {
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Next", { modalStep: activeModal[activeStep], athleteId, teamId });

    if (canSaveTooltips.length) {
      return;
    }
    const { advanceStep } = runSideEffects();
    if (advanceStep) {
      setActiveStep(activeStep + 1);
    }
  };

  useCreateMealPlanCalendarInRange(athleteId, calendarDispatch);

  return (
    <>
      <DialogTitle title="Create Meal Plan" onClose={() => onClose(activeStep)} />
      <DialogContent>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <MealPlanCalendarCreateForm state={calendarState} dispatch={calendarDispatch} />
        ) : activeStep === 1 ? (
          <Box sx={{ mx: 10 }}>
            {isMetricLocale ? (
              <MetricMacroProtocolForm
                state={metricMacroProtocolFormState}
                dispatch={metricMacroProtocolFormDispatch}
                goalTypes={goalTypes}
              />
            ) : (
              <ImperialMacroProtocolForm
                state={imperialMacroProtocolFormState}
                dispatch={imperialMacroProtocolFormDispatch}
                goalTypes={goalTypes}
              />
            )}
          </Box>
        ) : activeStep === 2 ? (
          <NameAndTypeForm state={mptFormState} dispatch={mptFormDispatch} />
        ) : activeStep === 3 ? (
          <FoodPreferenceMealPlanForm state={foodPreferenceFormState} dispatch={foodPreferenceFormDispatch} />
        ) : (
          <Loading progressSize="md" />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={activeStep === steps.length}
          onClick={activeStep === 0 ? handleClose : handleOnBack}>
          {activeStep === 0 ? "Cancel" : "Back"}
        </Button>
        <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
          <Button onClick={handleOnNext} disabled={activeStep === steps.length}>
            {activeStep >= steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
      {conflictDialogOpen && conflictsData && conflictsVariables && (
        <MealPlanDateAssignmentConflictDialog
          state={calendarState}
          conflictsData={conflictsData}
          conflictsVariables={conflictsVariables}
          open={conflictDialogOpen}
          onClose={() => setConflictDialogOpen(false)}
          onDone={resolutions => {
            setConflictResolutions(resolutions);
            setActiveStep(activeStep + 1);
          }}
        />
      )}
    </>
  );
};

export default CreateMealPlanDialogContent;

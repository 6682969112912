import { Box, TextField } from "@mui/material";
import React, { Dispatch } from "react";
import LabeledSelect from "../../../../components/universal/LabeledSelect";
import SportSelect from "../../SportSelect";
import { GenderObject, TeamFormAction, TeamFormState } from "./utils";

interface TeamFormProps {
  state: TeamFormState;
  dispatch: Dispatch<TeamFormAction>;
  editMode?: boolean;
}

const TeamForm = ({ state, dispatch, editMode }: TeamFormProps) => (
  <>
    <Box sx={{ display: "flex", gap: 2 }}>
      <LabeledSelect<GenderObject>
        options={[
          { id: "1", name: "Men's" },
          { id: "2", name: "Women's" },
        ]}
        selectedOption={state.gender}
        placeholder="Select Gender"
        optionToName={o => o.name}
        onChange={gender => dispatch({ type: "CHANGE_GENDER", payload: gender })}
        textFieldProps={{
          margin: "dense",
        }}
      />
      <SportSelect
        selectedSport={state.sport}
        onChange={sport => dispatch({ type: "CHANGE_SPORT", payload: sport })}
        disabled={editMode} />
    </Box>
    <TextField
      value={state.name || ""}
      fullWidth
      margin="dense"
      label="Enter Team Name"
      onChange={e => dispatch({ type: "CHANGE_NAME", payload: e.target.value })}
    />
  </>
);

export default TeamForm;

import React from "react";

import CopyContent from "./Content/CopyContent";
import DeleteContent from "./Content/DeleteContent";
import NotificationContent from "./Content/NotificationContent";
import SharingContent from "./Content/SharingContent";
import TimingContent from "./Content/TimingContent";
import { BulkEditDialogContentProps } from "./types";

const BulkEditDialogContent = ({
  onBulkEdit,
  onBulkDelete,
  onBulkCopy,
  allTeams,
  clientTimezone,
  selectedActionType,
  ...baseProps
}: BulkEditDialogContentProps) => {
  switch (selectedActionType) {
    case "copy":
      return <CopyContent
        {...baseProps}
        clientTimezone={clientTimezone}
        onBulkCopy={onBulkCopy} />;
    case "notification":
      return <NotificationContent {...baseProps} onBulkEdit={onBulkEdit} />;
    case "sharing":
      return <SharingContent
        {...baseProps}
        onBulkEdit={onBulkEdit}
        allTeams={allTeams} />;
    case "timing":
      return <TimingContent
        {...baseProps}
        onBulkEdit={onBulkEdit}
        clientTimezone={clientTimezone} />;
    case "delete":
      return <DeleteContent {...baseProps} onBulkDelete={onBulkDelete} />;
    default:
      return null;
  }
};

export default BulkEditDialogContent;

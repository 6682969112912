import { Box, Card, useTheme } from "@mui/material";
import React from "react";
import { TimelineDateColumnSelect } from "./Select";

interface AthleteTimelineEmptyDateColumnProps {
  onChangeDate: (date: string | null) => void;
  gridColumn: number;
}

const AthleteTimelineEmptyDateColumn = ({ onChangeDate, gridColumn }: AthleteTimelineEmptyDateColumnProps) => {
  const {
    palette: { greyscale },
  } = useTheme();

  return (
    <Box sx={{ gridColumnStart: gridColumn + 1, gridRowStart: 1 }}>
      <Card sx={{ m: 1 }}>
        <Box
          sx={{
            p: 2,
            backgroundColor: greyscale[100],
            display: "flex",
            alignItems: "center",
          }}
        >
          <TimelineDateColumnSelect selectedDate={null} onChangeSelectedDate={onChangeDate} />
        </Box>
      </Card>
    </Box>
  );
};

export default AthleteTimelineEmptyDateColumn;

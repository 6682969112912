import { TextField } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import ActionDialog from "apps/web/src/componentLibrary/ActionDialog/Dialog";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import ActionDialogContent from "apps/web/src/components/universal/ActionDialogContent";
import { getNavOrgAthleteMealPlansMealPlan } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import { AssignFromMealPlanTemplateAthleteFragment, useAssignMetricMealPlanTemplateToAthleteMutation } from "apps/web/src/types";
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { evictMealPlanCalendarQueriesForAthlete } from "../Content/Calendar/cache";
import DateAssignment from "../Content/DateAssignment";
import SearchableMealPlanTemplates from "./SearchableMealPlanTemplates";
import { assignFromMealPlanTemplateReducer, buildInitAssignFromMealPlanTemplateState } from "./reducer";
import { getCanSaveTooltipItems, getInputWithMetricAnthro } from "./utils";

interface AssignFromMealPlanTemplateProps {
  open: boolean;
  onClose: () => void;
  athlete: AssignFromMealPlanTemplateAthleteFragment;
}
const STEPS = ["Search Meal Plan Template", "Date Assignment", "Name and Type"];
const LAST_STEP = 2;

const AssignFromMealPlanTemplate = ({ onClose, open, athlete }: AssignFromMealPlanTemplateProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { setMessage } = useSnackbar();

  const clientTimezone = useClientTimezone();
  const [state, dispatch] = useReducer(assignFromMealPlanTemplateReducer, buildInitAssignFromMealPlanTemplateState(clientTimezone));
  const canSaveTooltips = getCanSaveTooltipItems(activeStep, state);
  const athleteId = athlete.id;

  const [assignMetricMealPlanTemplateToAthlete] = useAssignMetricMealPlanTemplateToAthleteMutation({
    update: (cache, { data }) => {
      if (data) {
        evictMealPlanCalendarQueriesForAthlete({
          athleteId,
          cache,
          type: "Create",
        });
      }
    },
    onCompleted: data => {
      if (data) {
        const mealPlanId = data.assignMetricMealPlanTemplateToAthlete.mealPlan.id;
        onClose();
        navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, mealPlanId));
      }
    },
    onError: () => setMessage("error", "Something went wrong"),
  });

  const handleCreateMealPlanFromMealPlanTemplateForAthlete = () => {
    const input = getInputWithMetricAnthro(state, athlete);
    if (input) {
      assignMetricMealPlanTemplateToAthlete({ variables: { input } });
    }
  };

  const runSideEffects = () => {
    if (activeStep === LAST_STEP) {
      handleCreateMealPlanFromMealPlanTemplateForAthlete();
    }
  };

  return (
    <ActionDialog open={open} onClose={onClose}>
      <ActionDialogContent
        title="Assign From Meal Plan Template"
        steps={STEPS}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        runSideEffects={runSideEffects}
        getCanSaveTooltipItems={() => canSaveTooltips}
        onClose={onClose}
      >
        {activeStep === 0 ? (
          <SearchableMealPlanTemplates
            athlete={athlete}
            state={state}
            dispatch={dispatch} />
        ) : activeStep === 1 ? (
          <DateAssignment
            state={state.dateAssignment}
            dispatch={dispatch}
            isAutoSuggestionsEnabled={state.isAutoSuggestionsEnabled}
            setIsAutoSuggestionsEnabled={() => dispatch({ type: "TOGGLE_AUTO_SUGGESTIONS", payload: !state.isAutoSuggestionsEnabled })}
          />
        ) : (
          <>
            <TextField
              value={state.name}
              sx={{ width: "400px" }}
              onChange={e => dispatch({ type: "CHANGE_NAME", payload: e.target.value })}
              label="Meal Plan Name"
            />
            <TextField
              value={state.note}
              onChange={e => dispatch({ type: "CHANGE_NOTE", payload: e.target.value })}
              label="Note to Athlete"
              multiline={true}
              rows={4}
              maxRows={4}
              fullWidth
            />
          </>
        )}
      </ActionDialogContent>
    </ActionDialog>
  );
};

export default AssignFromMealPlanTemplate;

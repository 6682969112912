import React from "react";

import DraggableChipList from "@notemeal/shared/ui/global/ChipList/Draggable";
import { useServingAmountCallbacks } from "@notemeal/shared/ui/ServingAmount/utils";

import Chip from "./Chip";

import { SxProps } from "@mui/material";
import { LOADING_INGREDIENTS_HEIGHT } from "@notemeal/shared/ui/ServingAmount/IngredientsList";
import { FullServingAmountFragment } from "../../../types";
import { useServingIdToExpanded } from "./utils";

// Overridden by label in getChipProps
const servingAmountToChipName = (sa: FullServingAmountFragment): string => sa.serving.foodOrRecipe.name;

// Note: 40 equals chip height of theme.spacing(4) + marginTop of theme.spacing(1)
const foodChipHeight = 40;
// Note: 64 equals chip height of theme.spacing(7) + marginTop of theme.spacing(1)
const limitedAccessChipHeight = 64;
// Note: 18 is height of div containing Typograph variant='caption' and line-height: 1.5, 8 is theme.spacing(1)
const getRecipeChipHeight = (ingredientsCount: number) => (ingredientsCount + 1) * 24 + foodChipHeight;

interface ServingAmountsEditChipListProps {
  servingAmounts: readonly FullServingAmountFragment[];
  onChange: (servingAmounts: readonly FullServingAmountFragment[]) => void;
  recipeIngredient: boolean;
  loadingChip?: React.ReactNode;
  sx?: SxProps;
  disabled?: boolean;
}

const ServingAmountsEditChipList = ({
  servingAmounts,
  onChange,
  sx,
  recipeIngredient,
  loadingChip,
  disabled,
}: ServingAmountsEditChipListProps) => {
  const { servingIdToExpanded, onChangeExpanded, onSetIngredientsCount } = useServingIdToExpanded();
  const {
    onChangeOrder,
    onDecrementByDefaultAmount,
    onDelete,
    onIncrementByDefaultAmount,
    onSetAmount,
    onReplaceServing,
    onDeconstructRecipe,
    sortedServingAmounts,
  } = useServingAmountCallbacks({ servingAmounts, onChange });

  // Hack level: over 9000
  // Explanation: This key forces a re-render when the servingAmounts change (including order)
  // Without it, the dragging of chips does not work
  const draggableChipListKey = sortedServingAmounts.flatMap(({ serving: { id } }) => [id, servingIdToExpanded(id)]).join(",");

  return (
    <DraggableChipList<FullServingAmountFragment>
      loadingChip={loadingChip}
      key={draggableChipListKey}
      objects={[...sortedServingAmounts]}
      objectToName={servingAmountToChipName}
      renderChip={(sa, bindProps, spring) => (
        <Chip
          disabled={disabled}
          key={sa.serving.id}
          servingAmount={sa}
          onIncrement={() => onIncrementByDefaultAmount(sa)}
          onDecrement={() => onDecrementByDefaultAmount(sa)}
          onDelete={() => onDelete(sa)}
          onReplaceServing={(serving, amount) => onReplaceServing(sa, serving, amount)}
          onSetAmount={amount => onSetAmount(sa, amount)}
          expanded={servingIdToExpanded(sa.serving.id).isExpanded}
          onChangeExpanded={expanded => onChangeExpanded(sa.serving.id, expanded)}
          onLoadIngredients={count => onSetIngredientsCount(sa.serving.id, count)}
          onDeconstructRecipe={sa.serving.foodOrRecipe.__typename === "Recipe" ? () => onDeconstructRecipe(sa) : undefined}
          bindProps={bindProps}
          spring={spring}
          recipeIngredient={recipeIngredient}
        />
      )}
      sx={sx}
      chipHeight={0}
      objectToChipHeight={sa => {
        if (!sa.serving.foodOrRecipe.hasFullAccess) {
          return limitedAccessChipHeight;
        }
        const expandedData = servingIdToExpanded(sa.serving.id);
        if (!expandedData.isExpanded) {
          return foodChipHeight;
        }
        if (expandedData.ingredientsCount === null) {
          return foodChipHeight + LOADING_INGREDIENTS_HEIGHT;
        }
        return getRecipeChipHeight(expandedData.ingredientsCount);
      }}
      onChangeOrder={onChangeOrder}
    />
  );
};

export default ServingAmountsEditChipList;

import { z } from "zod";
import { BrandedFoodSchema, GenericFoodSchema } from "./FoodSchema";
import { IntegerSchema } from "@notemeal/validators";

const NutritionixFoodSchema = z.object({
  source: z.literal("nutritionix"),
  nixItemId: z.string().optional().nullable(),
  nixTagId: z.string().optional().nullable(),
  usdaNdbNo: IntegerSchema.optional(),
});

export const NutritionixBrandedFoodSchema = BrandedFoodSchema.merge(NutritionixFoodSchema).superRefine((state, ctx) => {
  if (!state.nixItemId) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Required",
      path: ["nixItemId"],
    });
  }

  if (!state.brand) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Required",
      path: ["brand"],
    });
  }
});

export const NutritionixGenericFoodSchema = GenericFoodSchema.merge(NutritionixFoodSchema).superRefine((state, ctx) => {
  if (!state.nixTagId) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Required",
      path: ["nixTagId"],
    });
  }
});

interface GroupableAthlete {
  firstName: string;
  lastName: string;
  team: {
    id: string;
    name: string;
  };
}

interface GroupedAthletesTeam<A extends GroupableAthlete> {
  id: string;
  name: string;
  athletes: A[];
}

interface SortGroupAndFilterAthletesByTeamArgs<A extends GroupableAthlete> {
  athletes: readonly A[];
  sortFn?: (athletes: readonly A[]) => readonly A[];
  filterFn?: (athletes: readonly A[]) => readonly A[];
}

export const getCompareString = ({ firstName, lastName, team: { name } }: GroupableAthlete) =>
  `${name.toLowerCase()} ${lastName.toLowerCase()}, ${firstName.toLowerCase()}`;

export const sortGroupAndFilterAthletesByTeam = <A extends GroupableAthlete>({
  athletes,
  sortFn,
  filterFn,
}: SortGroupAndFilterAthletesByTeamArgs<A>): GroupedAthletesTeam<A>[] => {
  const filteredAthletes = filterFn ? filterFn(athletes) : athletes;
  const sortedAthletes = sortFn ? sortFn(filteredAthletes) : filteredAthletes;

  const groupedAthletes = sortedAthletes.reduce((teamGroups, athlete) => {
    const teamName = athlete.team.name;
    const teamId = athlete.team.id;
    const existingGroup: { name: string; athletes: A[] } = teamGroups[teamId] ?? { name: teamName, athletes: [] };
    return {
      ...teamGroups,
      [teamId]: {
        ...existingGroup,
        athletes: existingGroup.athletes.concat(athlete),
      },
    };
  }, {} as Record<string, { name: string; athletes: A[] }>);

  return Object.entries(groupedAthletes).map(([teamId, { name, athletes: athletesForTeam }]) => ({
    id: teamId,
    name,
    athletes: athletesForTeam,
  }));
};

import { Box, Button, Dialog, DialogActions, DialogContent, TablePagination } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import React, { useState } from "react";
import { MenuItemTableFragment, RatingForMenuItemFragment } from "../../../../types";
import { PaginationHooks, getMuiTablePaginationProps } from "../../../../utils/pagination";
import { RatingCountsChart } from "./RatingCounts";
import ReviewCard from "./ReviewCard";

interface MenuItemRatingsContentProps {
  open: boolean;
  onClose: () => void;
  currentReviews: readonly RatingForMenuItemFragment[];
  allReviews: readonly RatingForMenuItemFragment[];
  paginationHooks: PaginationHooks;
  menuItem: MenuItemTableFragment;
  currentRevisionReviewCount: number;
  allRevisionsReviewCount: number;
  goToFirstPage: () => void;
}

const MenuItemRatingsContent = ({
  open,
  onClose,
  allReviews,
  currentReviews,
  menuItem,
  paginationHooks,
  currentRevisionReviewCount,
  allRevisionsReviewCount,
  goToFirstPage,
}: MenuItemRatingsContentProps) => {
  const ALL_REVISIONS = "All Revisions";
  const CURRENT_REVISION = "Current Revision";
  const [selected, setSelected] = useState(ALL_REVISIONS);
  const tabs = [ALL_REVISIONS, CURRENT_REVISION];

  const handleSelected = (value: string) => {
    goToFirstPage();
    setSelected(value);
  };

  const hasReviews = allReviews && allReviews?.length !== 0;

  const reviewsDisplayed = selected === ALL_REVISIONS ? allReviews : currentReviews;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={hasReviews ? true : undefined}>
      <DialogTitle title={`${menuItem.name} Reviews`} onClose={onClose} />
      <DialogContent>
        {currentRevisionReviewCount > 0 && <TWTabGroup tabs={tabs} onSelected={handleSelected} />}
        <RatingCountsChart
          ratingCounts={selected === ALL_REVISIONS ? menuItem.history.ratingCounts : menuItem.ratingCounts}
          sx={{ alignSelf: "center", flexShrink: 0, mr: 2 }}
        />
        {hasReviews && (
          <>
            <Box sx={{ flexGrow: 1, overflowY: "auto", display: "flex", flexDirection: "column" }}>
              {reviewsDisplayed.map(review => {
                return <ReviewCard
                  sx={{ m: 2 }}
                  key={review.id}
                  review={review} />;
              })}
            </Box>
            {hasReviews && (
              <TablePagination
                component="div"
                {...getMuiTablePaginationProps(
                  paginationHooks,
                  selected === ALL_REVISIONS ? allRevisionsReviewCount : currentRevisionReviewCount
                )}
                rowsPerPageOptions={[0]}
                sx={{ borderBottom: "none" }}
              />
            )}
          </>
        )}
      </DialogContent>
      {hasReviews && (
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default MenuItemRatingsContent;

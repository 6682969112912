import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import { Backdrop, Box, IconButton, TableCell, TableRow, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import React, { useState } from "react";
import ConfirmationDialog from "../../../../componentLibrary/ConfirmationDialog/ConfirmationDialog";
import { getMenuItemAppearanceGroupState } from "../../../../components/MenuItemAppearance/reducer";
import TablePage from "../../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../../components/universal/TablePage/DefaultHeader";
import {
  DiningStationTemplateTableFragment,
  useCreateDiningStationTemplateMutation,
  useDeleteDiningStationTemplateMutation,
  useDiningStationTemplateDialogQuery,
  useDiningStationTemplatesTableQuery,
  useEditDiningStationTemplateMutation,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import Dialog from "../../../../views/DiningStationTemplates/Dialog";
import { DiningStationTemplateModalState, getNewDiningStationTemplateModalState } from "../../../../views/DiningStationTemplates/reducer";
import { getCreateDiningStationTemplateInput, getEditDiningStationTemplateInput } from "../../../../views/DiningStationTemplates/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    actionsCellDiv: {
      display: "flex",
    },
    missingTooltip: {
      maxWidth: 150,
    },
    warningIcon: {
      color: theme.palette.warning.light,
    },
    popoverButtonIcon: {
      marginLeft: theme.spacing(),
    },
  })
);

export const DiningStationTemplatesPage = () => {
  const classes = useStyles();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [diningStationTemplateIdForDialog, setDiningStationTemplateIdForDialog] = useState<string | null>(null);
  const [diningStationTemplateForDeleteDialog, setDiningStationTemplateForDeleteDialog] =
    useState<DiningStationTemplateTableFragment | null>(null);

  const paginationHooks = useOffsetPagination();
  const { query, limit, offset, queryText, onChangeQueryText } = paginationHooks;

  const {
    data: tableData,
    refetch,
    loading: tableLoading,
  } = useDiningStationTemplatesTableQuery({
    variables: { query, input: { offset, limit } },
    fetchPolicy: "network-only",
  });

  const { data: dialogData, loading: dialogLoading } = useDiningStationTemplateDialogQuery({
    skip: diningStationTemplateIdForDialog === null,
    variables: {
      id: diningStationTemplateIdForDialog || "",
    },
  });

  const [createDiningStationTemplate, { loading: savingCreate }] = useCreateDiningStationTemplateMutation({
    onCompleted: () => {
      refetch();
      setCreateDialogOpen(false);
    },
  });

  const [editDiningStationTemplate, { loading: savingEdit }] = useEditDiningStationTemplateMutation({
    onCompleted: () => {
      refetch();
      setDiningStationTemplateIdForDialog(null);
    },
  });

  const saving = savingCreate || savingEdit;

  const [deleteDiningStationTemplate] = useDeleteDiningStationTemplateMutation({
    onCompleted: () => {
      refetch();
      setDiningStationTemplateForDeleteDialog(null);
    },
  });
  const handleDelete = (diningStationTemplateId: string) => {
    deleteDiningStationTemplate({
      variables: {
        input: {
          diningStationTemplateId,
        },
      },
    });
  };

  const handleSave = (state: DiningStationTemplateModalState) => {
    if (state.id === null) {
      createDiningStationTemplate({
        variables: { input: getCreateDiningStationTemplateInput(state) },
      });
    } else {
      editDiningStationTemplate({
        variables: {
          input: getEditDiningStationTemplateInput(state, state.id),
        },
      });
    }
  };

  return (
    <>
      <TablePage
        header={
          <TablePageDefaultHeader
            title="Dining Station Template"
            queryText={queryText}
            onChangeQueryText={onChangeQueryText}
            onCreate={() => setCreateDialogOpen(true)}
          />
        }
        tableHeaderRow={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Meals</TableCell>
            <TableCell align="left">Number of Menu Items</TableCell>
            <TableCell padding="checkbox">Actions</TableCell>
          </TableRow>
        }
        tableBodyRows={
          !tableData
            ? null
            : tableData.diningStationTemplateOffsetConnection.edges.map(row => {
                const { name, id, menuItemAppearances, mealTypes } = row;

                const menuItemIdsWithoutIngredients = menuItemAppearances.flatMap(mia =>
                  mia.menuItem.isMissingIngredients && mia.menuItem.isOneOff ? mia.menuItem.id : []
                );
                const isMissingIngredients = menuItemIdsWithoutIngredients.length > 0;

                return (
                  <TableRow
                    key={id}
                    hover
                    onClick={e => setDiningStationTemplateIdForDialog(id)}
                    className={classes.row}>
                    <TableCell scope="row">
                      {isMissingIngredients ? (
                        <Box sx={{ display: "flex" }}>
                          <Tooltip
                            title={
                              <Typography variant="subtitle1">
                                One-off menu items are missing ingredients. This will affect food log data from menu orders.
                              </Typography>
                            }
                          >
                            <WarningIcon fontSize="small" color="warning" />
                          </Tooltip>
                          <Box sx={{ display: "flex", alignItems: "center", pl: 0.5 }}>{name}</Box>
                        </Box>
                      ) : (
                        name
                      )}
                    </TableCell>
                    <TableCell>{mealTypes.map(capitalize).join(", ")}</TableCell>
                    <TableCell align="left">{menuItemAppearances.length}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Delete Dining Station Template"
                        onClick={e => {
                          e.stopPropagation();
                          setDiningStationTemplateForDeleteDialog(row);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
        }
        paginationHooks={paginationHooks}
        loading={tableLoading}
        total={tableData?.diningStationTemplateOffsetConnection.pageInfo.total || 0}
      />
      {dialogLoading && (
        <Backdrop open={dialogLoading} className={classes.backdrop}>
          <Loading progressSize="lg" />
        </Backdrop>
      )}
      {dialogData && !dialogLoading && (
        <Dialog
          open={Boolean(dialogData && !dialogLoading)}
          onClose={() => setDiningStationTemplateIdForDialog(null)}
          onSave={handleSave}
          type={"edit"}
          saving={saving}
          initialDiningStationTemplate={{
            ...dialogData.diningStationTemplate,
            edited: false,
            menuItemAppearances: getMenuItemAppearanceGroupState(dialogData.diningStationTemplate.menuItemAppearances, "edit"),
          }}
        />
      )}
      {createDialogOpen && (
        <Dialog
          open={createDialogOpen}
          type={"create"}
          onClose={() => setCreateDialogOpen(false)}
          onSave={handleSave}
          saving={saving}
          initialDiningStationTemplate={getNewDiningStationTemplateModalState()}
        />
      )}
      {diningStationTemplateForDeleteDialog !== null && (
        <ConfirmationDialog
          open={diningStationTemplateForDeleteDialog !== null}
          title="Delete Dining Station Template"
          message="Are you sure that you would like to delete the dining station template?"
          onCancel={() => setDiningStationTemplateForDeleteDialog(null)}
          onConfirm={() => handleDelete(diningStationTemplateForDeleteDialog.id)}
          variant="containedDestructive"
        />
      )}
    </>
  );
};

import { Box, Link, Typography } from "@mui/material";
import { ImperialAnthropometryEntryForm } from "apps/web/src/components/AnthropometryEntry/Form/ImperialAnthropometryForm";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { AnthropometryEntryType } from "../../../components/AnthropometryEntry/Form/AnthropometryEntryFormSchema";

interface CreateImperialAnthropometryEntryFormProps {
  form: UseFormReturn<AnthropometryEntryType>;
}

export const CreateImperialAnthropometryEntryForm = ({ form }: CreateImperialAnthropometryEntryFormProps) => (
  <>
    <Box sx={{ p: 2, mt: 2, backgroundColor: "grey.200", borderRadius: "5px" }}>
      <Typography variant="body2" color="textSecondary">
        * The anthropometry data can be filled out at a later time or collected from the athlete during{" "}
        <Link
          sx={{ color: "info.main" }}
          target="_blank"
          href="https://teamworks.zendesk.com/hc/en-us/articles/13255660957332-Auto-Onboard-to-Notemeal"
        >
          auto-onboarding
        </Link>
        . However,{" "}
        <Box display="inline" sx={{ fontWeight: 600 }}>
          weight is required
        </Box>{" "}
        if anthropometry data is entered.
      </Typography>
    </Box>
    <ImperialAnthropometryEntryForm form={form} />
  </>
);

import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import InfoIcon from "@mui/icons-material/InfoRounded";
import ReportIcon from "@mui/icons-material/ReportRounded";
import WarningIcon from "@mui/icons-material/WarningRounded";
import { Box, Card, IconButton, Snackbar as MuiSnackbar, SnackbarCloseReason, Typography } from "@mui/material";
import React from "react";

export type SnackbarVariant = "success" | "info" | "warning" | "error";

const DEFAULT_HIDE_DURATION = 5000;

interface SnackbarProps {
  variant: SnackbarVariant;
  message: string;
  setMessage: (variant: SnackbarVariant, message: string) => void;
}

export const Snackbar = ({ variant, message, setMessage }: SnackbarProps) => {
  const snackbarInfo = {
    success: {
      icon: <CheckCircleIcon />,
      color: "success.main",
      label: "Success",
    },
    info: {
      icon: <InfoIcon />,
      color: "info.main",
      label: "Information",
    },
    warning: {
      icon: <WarningIcon />,
      color: "warning.main",
      label: "Warning",
    },
    error: {
      icon: <ReportIcon />,
      color: "error.main",
      label: "Something went wrong",
    },
  };

  const { icon, color, label } = snackbarInfo[variant];

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage("success", "");
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={!!message}
      autoHideDuration={DEFAULT_HIDE_DURATION}
      onClose={handleClose}
    >
      <Card sx={{ borderRadius: "6px", width: "380px", p: 2, display: "flex", gap: 2, justifyContent: "space-between" }}>
        {/* Need this bool no stop rendering when message dissapears because it causes a jarring repaint */}
        {message && (
          <>
            <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
              <IconButton sx={{ color, width: "24px", height: "24px" }}>{icon}</IconButton>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
                <Typography variant="body1Semibold">{label}</Typography>
                <Typography variant="body2">{message}</Typography>
              </Box>
            </Box>
            <IconButton sx={{ color: "common.black", width: "16px", height: "16px", mt: 0.5 }} onClick={() => setMessage("success", "")}>
              <CloseIcon sx={{ height: "16px", width: "16px" }} />
            </IconButton>
          </>
        )}
      </Card>
    </MuiSnackbar>
  );
};

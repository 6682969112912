import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import SaveIcon from "@mui/icons-material/Save";
import { IconButton, IconButtonProps, ListItemIcon, Menu, MenuItem, SvgIconProps, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useServingAmountModeContext } from "../ServingAmounts/contexts/ServingAmountMode";

const iconStyle = { width: 16, height: 16 };

interface MenuItemAppearanceMoreOptionsButtonProps {
  onEdit: () => void;
  onConvert: (isOneOff: boolean) => void;
  disableConvertToOneOff?: boolean;
  disableConvertToReusable?: boolean;
  disableEditReusable?: boolean;
  onDuplicate?: (isOneOff: boolean) => void;
  onRemove?: () => void;
  IconButtonProps?: IconButtonProps;
  SvgIconProps?: SvgIconProps;
}

const MenuItemAppearanceMoreOptionsButton = ({
  onEdit,
  onConvert,
  onDuplicate,
  onRemove,
  disableConvertToOneOff,
  disableConvertToReusable: _disableConvertToReusable,
  disableEditReusable,
  IconButtonProps,
  SvgIconProps,
}: MenuItemAppearanceMoreOptionsButtonProps) => {
  const [convertAnchorEl, setConvertAnchorEl] = useState<HTMLElement | null>(null);
  const [duplicateAnchorEl, setDuplicateAnchorEl] = useState<HTMLElement | null>(null);

  const servingAmountMode = useServingAmountModeContext();

  const forRestaurant = servingAmountMode.type === "restaurant";

  const disableConvertToReusable = _disableConvertToReusable || forRestaurant;

  const convertIsSave = Boolean(disableConvertToOneOff && !disableConvertToReusable);

  const noEditOptions = disableEditReusable && disableConvertToOneOff && disableConvertToReusable;

  return (
    <>
      {!noEditOptions && (
        <>
          <Tooltip title={!convertIsSave ? "Edit Menu Item" : "Save Menu Item"}>
            <IconButton
              {...IconButtonProps}
              onClick={e => setConvertAnchorEl(e.currentTarget)}
              size="small">
              {convertIsSave ? <SaveIcon {...SvgIconProps} sx={iconStyle} /> : <EditIcon {...SvgIconProps} sx={iconStyle} />}
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={convertAnchorEl}
            keepMounted
            open={Boolean(convertAnchorEl)}
            onClose={() => setConvertAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {!disableEditReusable && (
              <MenuItem
                dense
                onClick={() => {
                  setConvertAnchorEl(null);
                  onEdit();
                }}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                Edit Reusable Menu Item
              </MenuItem>
            )}
            {!disableConvertToReusable && (
              <MenuItem
                dense
                onClick={() => {
                  setConvertAnchorEl(null);
                  onConvert(false);
                }}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                {convertIsSave ? "Save as Reusable Menu Item" : "Convert to New Reusable Menu Item"}
              </MenuItem>
            )}
            {!disableConvertToOneOff && (
              <MenuItem
                dense
                onClick={() => {
                  setConvertAnchorEl(null);
                  onConvert(true);
                }}
              >
                <ListItemIcon>
                  <PlusOneIcon />
                </ListItemIcon>
                Convert to One-off Menu Item
              </MenuItem>
            )}
          </Menu>
        </>
      )}

      {onDuplicate && (
        <>
          <Tooltip title="Duplicate Menu Item">
            <IconButton
              {...IconButtonProps}
              onClick={e => setDuplicateAnchorEl(e.currentTarget)}
              size="small">
              <FileCopyIcon {...SvgIconProps} sx={iconStyle} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={duplicateAnchorEl}
            keepMounted
            open={Boolean(duplicateAnchorEl)}
            onClose={() => setDuplicateAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              dense
              onClick={() => {
                setDuplicateAnchorEl(null);
                onDuplicate(false);
              }}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              Duplicate as Reusable Menu Item
            </MenuItem>
            <MenuItem
              dense
              onClick={() => {
                setDuplicateAnchorEl(null);
                onDuplicate(true);
              }}
            >
              <ListItemIcon>
                <PlusOneIcon />
              </ListItemIcon>
              Duplicate as One-off Menu Item
            </MenuItem>
          </Menu>
        </>
      )}

      {onRemove && (
        <Tooltip title="Remove Menu Item">
          <IconButton
            {...IconButtonProps}
            onClick={onRemove}
            size="small">
            <CloseIcon {...SvgIconProps} sx={iconStyle} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default MenuItemAppearanceMoreOptionsButton;

import { ListItem, Tooltip, Typography } from "@mui/material";
import { CompassRecipe } from "@notemeal/graphql/types";
import React, { useEffect, useRef, useState } from "react";
import { ListChildComponentProps } from "react-window";

interface RenderRowProps extends ListChildComponentProps {
  recipe: CompassRecipe;
  listRef: React.MutableRefObject<HTMLDivElement | null>;
  selectedRecipe: CompassRecipe | null;
  setSelectedRecipe: (recipe: CompassRecipe | null) => void;
}

export const RenderRow = ({ index, style, recipe, listRef, selectedRecipe, setSelectedRecipe }: RenderRowProps) => {
  const [overflow, setOverflow] = useState(false);
  const rowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (rowRef && rowRef.current && listRef && listRef.current) {
      setOverflow(rowRef.current.scrollWidth > listRef.current.clientWidth);
    } else {
    }
  }, [listRef, rowRef]);

  if (!recipe) {
    return null;
  }

  const selected = recipe.masterRefNumber === selectedRecipe?.masterRefNumber;

  return (
    <Tooltip title={recipe.name} disableHoverListener={!overflow}>
      <div style={style}>
        <ListItem
          key={index}
          divider
          sx={{ paddingLeft: 0 }}
          selected={selected}
          onClick={() => (selected ? setSelectedRecipe(null) : setSelectedRecipe(recipe))}
        >
          <div>
            <Typography color="textPrimary" sx={{ fontWeight: "500" }}>
              {recipe.shortName}
            </Typography>
            <Typography color="textPrimary">#{recipe.masterRefNumber}</Typography>
            <Typography
              ref={rowRef}
              noWrap
              color="textSecondary">
              {recipe.name}
            </Typography>
          </div>
        </ListItem>
      </div>
    </Tooltip>
  );
};

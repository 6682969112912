import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { useForm } from "react-hook-form";
import {
  useCreateOrgFoodServingsMutation,
  useCreateOrgGenericFoodMutation,
  useEditOrgFoodServingMutation,
  useEditOrgGenericFoodMutation,
  useGramUnitQuery,
  useUnitsWithConversionsQuery,
} from "../../../types";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import { BaseFoodFormType, FoodFormSchema } from "../FoodFormSchema/FoodFormSchema";
import {
  toCreateFoodServingsInput,
  toCreateGenericFoodInput,
  toEditFoodServingInputs,
  toEditGenericFoodInput,
} from "../FoodFormSchema/foodSchemaToInputUtils";
import { FoodForm } from "../Form/FoodForm";

interface FoodModalProps {
  open: boolean;
  onClose: () => void;
  initialFormState: Partial<BaseFoodFormType>;
  resetTable?: () => void;
  readonly?: boolean;
}

// TODO: Org is a bit misleading here now that we're adding support for org group recipes, but opting to not rename for speed
export const OrgFoodModal = ({ open, onClose, initialFormState, resetTable, readonly }: FoodModalProps) => {
  const { setMessage } = useSnackbar();

  const form = useForm<BaseFoodFormType>({
    defaultValues: initialFormState,
    resolver: zodResolver(FoodFormSchema()),
  });

  const {
    watch,
    handleSubmit,
    getValues,
    formState: { isSubmitted, isValid },
  } = form;
  const watchedId = watch("id");
  const hasFormErrors = isSubmitted && !isValid;

  const onCompleted = () => {
    const { name } = getValues();
    setMessage("success", watchedId ? `"${name}" has been updated` : `"${name}" has been added to the org as a new food`);
    if (resetTable) {
      resetTable();
    }
  };

  const [createGenericFood] = useCreateOrgGenericFoodMutation({
    onError: e => setMessage("error", e.message),
    onCompleted,
  });
  const [editGenericFood] = useEditOrgGenericFoodMutation({
    onError: e => setMessage("error", e.message),
    onCompleted,
  });
  const [createServings] = useCreateOrgFoodServingsMutation({
    onError: e => setMessage("error", e.message),
    onCompleted,
  });
  const [editServing] = useEditOrgFoodServingMutation({
    onError: e => setMessage("error", e.message),
    onCompleted,
  });

  const { data: unitsData } = useUnitsWithConversionsQuery();
  const { data: gramUnit } = useGramUnitQuery();

  const handleSave = async (state: BaseFoodFormType) => {
    const { id: editFoodId } = state;

    if (hasFormErrors) {
      // try re-triggering form to force validation
      await form.trigger();
      return;
    }

    if (!editFoodId) {
      const input = toCreateGenericFoodInput(state);
      if (!input) {
        return;
      }
      await createGenericFood({
        variables: { input },
      });
    } else {
      const editServingInputs = toEditFoodServingInputs(state);
      const createServingsInput = toCreateFoodServingsInput(state);
      if (!createServingsInput || !editServingInputs) {
        return;
      }

      const editFoodInput = toEditGenericFoodInput(state);
      if (!editFoodInput) {
        return;
      }
      const editFoodPromise = editGenericFood({
        variables: {
          input: editFoodInput,
        },
      });

      await Promise.all([
        editFoodPromise,
        createServings({
          variables: { input: createServingsInput },
        }),
        Promise.all(
          editServingInputs.map(input =>
            editServing({
              variables: { input },
            })
          )
        ),
      ]);
    }
    onClose();
  };

  return gramUnit && unitsData ? (
    <>
      <Dialog
        maxWidth={false}
        open={open}
        onClose={onClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <DialogTitle title={`${!watchedId ? "Add" : "Edit"} Food`} onClose={onClose} />
        <DialogContent
          sx={{
            p: 3,
            width: "85vw",
            height: "75vh",
            display: "grid",
            gridTemplateColumns: "auto",
            gridTemplateRows: "1fr 16fr 1fr",
          }}
        >
          <FoodForm
            form={form}
            gramUnit={gramUnit.gramUnit}
            unitsData={unitsData.units}
            readonly={readonly} />
        </DialogContent>
        {!readonly && (
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
              <Button onClick={handleSubmit(handleSave)}>Save</Button>
            </Tooltip>
          </DialogActions>
        )}
      </Dialog>
    </>
  ) : (
    <LoadingBackdrop open={open} onClose={onClose} />
  );
};

import { Card, CardActionArea, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    square: {
      height: "120px",
      width: "120px",
      display: "flex",
    },
    flexCenterColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    centerText: {
      textAlign: "center",
    },
  })
);

interface SummaryActionButtonProps {
  icon: JSX.Element | null;
  text: string | null;
  disabled?: boolean;
  action: () => void;
}

const SummaryActionButton = ({ icon, text, disabled, action }: SummaryActionButtonProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.square}>
      <CardActionArea
        disabled={disabled}
        className={classes.flexCenterColumn}
        onClick={() => {
          action();
        }}
      >
        {icon}
        {text && (
          <Typography className={classes.centerText} variant="body1Medium">
            {text}
          </Typography>
        )}
      </CardActionArea>
    </Card>
  );
};

export default SummaryActionButton;

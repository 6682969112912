export interface ContactInfoState {
  newEmail: string | null;
  newPhone: string | null;
  emailError: string | null;
  phoneError: string | null;
}

interface SetNewEmailAction {
  type: "SET_NEW_EMAIL";
  payload: {
    newEmail: string | null;
  };
}
interface SetEmailErrorAction {
  type: "SET_EMAIL_ERROR";
  payload: {
    error: string | null;
  };
}

interface SetNewPhoneAction {
  type: "SET_NEW_PHONE";
  payload: {
    newPhone: string | null;
  };
}

interface SetPhoneErrorAction {
  type: "SET_PHONE_ERROR";
  payload: {
    error: string | null;
  };
}

export type ContactInfoAction = SetNewEmailAction | SetEmailErrorAction | SetNewPhoneAction | SetPhoneErrorAction;

export const contactInfoReducer = (state: ContactInfoState, action: ContactInfoAction): ContactInfoState => {
  switch (action.type) {
    case "SET_NEW_EMAIL":
      return {
        ...state,
        newEmail: action.payload.newEmail,
      };
    case "SET_EMAIL_ERROR":
      return {
        ...state,
        emailError: action.payload.error,
      };
    case "SET_NEW_PHONE":
      return {
        ...state,
        newPhone: action.payload.newPhone,
      };
    case "SET_PHONE_ERROR":
      return {
        ...state,
        phoneError: action.payload.error,
      };
  }
};

export const getInitState = (): ContactInfoState => {
  return { newEmail: null, newPhone: null, emailError: null, phoneError: null };
};

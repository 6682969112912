import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import LinkIcon from "@mui/icons-material/Link";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, ListItem, ListItemText, Menu, MenuItem, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    focusedItem: {
      backgroundColor: theme.palette.info.light,
    },
    clickable: {
      cursor: "pointer",
    },
  })
);

export type CardMode = "Add" | "Link" | "Edit" | "Cancel" | "NoAction" | "Info" | "More";

export interface DisplayCardProps<T> {
  primaryText: string;
  secondaryElement?: React.ReactNode;
  element: T;
  isArchived?: boolean;
  pendingArchive?: boolean;
  disabled?: boolean;
  focused?: boolean;
  mode?: CardMode;
  onAdd?: (element: T) => void;
  onLink?: (element: T) => void;
  onEdit?: (element: T) => void;
  onCancel?: (element: T) => void;
  onUnlink?: (element: T) => void;
  onUnlinkArchive?: (element: T) => void;
  infoTooltip?: string;
  className?: string;
  middleElement?: React.ReactNode;
  onClick?: (element: T) => void;
  hasAdditionalProfileInfo?: boolean;
}

const noOp = () => {};

export interface MoreInfo<T> {
  moreAnchorElement: HTMLElement;
  element: T;
}

const DisplayCard = <T,>({
  primaryText,
  secondaryElement,
  element,
  isArchived,
  pendingArchive,
  disabled = false,
  focused: _focused = false,
  mode = "NoAction",
  onAdd = noOp,
  onLink = noOp,
  onEdit = noOp,
  onCancel = noOp,
  onUnlink = noOp,
  onUnlinkArchive = noOp,
  infoTooltip,
  className,
  middleElement = null,
  onClick,
}: DisplayCardProps<T>) => {
  const classes = useStyles();
  const [moreInfo, setMoreInfo] = useState<MoreInfo<T> | null>(null);

  const addAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onAdd(element);
      }}
      disabled={disabled}
      size="large"
    >
      <AddIcon />
    </IconButton>
  );

  const linkAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onLink(element);
      }}
      disabled={disabled}
      size="large"
    >
      <LinkIcon />
    </IconButton>
  );

  const editAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onEdit(element);
      }}
      disabled={disabled}
      size="large"
    >
      <EditIcon />
    </IconButton>
  );

  const cancelAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        onCancel(element);
      }}
      disabled={disabled}
      size="large"
    >
      <CancelIcon />
    </IconButton>
  );

  const moreAction = (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        setMoreInfo({ moreAnchorElement: e.currentTarget, element });
      }}
      disabled={disabled}
      size="large"
    >
      <MoreVertIcon />
    </IconButton>
  );

  const info = (
    <Tooltip title={infoTooltip ?? ""}>
      <IconButton size="large">
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );

  const actionPicker: Record<CardMode, JSX.Element> = {
    Add: addAction,
    Link: linkAction,
    Edit: editAction,
    Cancel: cancelAction,
    Info: info,
    NoAction: <></>,
    More: moreAction,
  };

  const focused = _focused && !disabled;
  return (
    <>
      <ListItem
        divider
        disabled={disabled}
        className={classNames(className, {
          [classes.focusedItem]: focused,
          [classes.clickable]: !!onClick,
        })}
        onClick={onClick ? () => onClick(element) : undefined}
      >
        <ListItemText primary={primaryText} secondary={secondaryElement} />
        {middleElement}
        {isArchived && (
          <TWTooltip
            title="User is Archived"
            subcopy="This user is archived but you can still unlink the Teamworks profile from the Nutrition account."
          >
            <ArchiveIcon color="disabled" />
          </TWTooltip>
        )}
        {pendingArchive && (
          <TWTooltip
            title="Marked for Archive"
            subcopy="The athlete account associated with the Nutrition account will also be archived in addition to unlinking the Teamworks ID from the Nutrition account and Org membership, and deactivating the Org membership."
          >
            <ArchiveIcon color="disabled" />
          </TWTooltip>
        )}
        {actionPicker[mode]}
      </ListItem>
      <Menu
        anchorEl={moreInfo?.moreAnchorElement}
        open={!!moreInfo}
        onClose={() => {
          setMoreInfo(null);
        }}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          dense
          onClick={() => {
            setMoreInfo(null);
            onUnlink(element);
          }}
        >
          Unlink Teamworks Profile
        </MenuItem>
        {!isArchived && (
          <MenuItem
            dense
            onClick={() => {
              setMoreInfo(null);
              onUnlinkArchive(element);
            }}
          >
            Unlink and Archive Athlete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DisplayCard;

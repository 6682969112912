import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, CardActions, CardContent, IconButton, Popover, Typography } from "@mui/material";
import React from "react";

interface MenusCalendarEventNewProps {
  anchorEl: HTMLElement;
  onClose: () => void;
  onOpenMenuDialog: () => void;
}

const MenusCalendarEventNew = ({ anchorEl, onClose, onOpenMenuDialog }: MenusCalendarEventNewProps) => {
  const handleOpen = () => {
    onOpenMenuDialog();
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      PaperProps={{
        elevation: 24,
      }}
    >
      <Card>
        <CardContent sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Typography>Create Menu?</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleOpen}>Create Menu</Button>
        </CardActions>
      </Card>
    </Popover>
  );
};

export default MenusCalendarEventNew;

import { MealPlanFormState } from "../../../../components/MealPlan/Form/utils";
import { ExchangeSetFragment, PreviewMealPlanFragment } from "../../../../types";

export const initialEditMealPlanFormState = (
  { id, name, description, type, exchangeSet, isShared }: PreviewMealPlanFragment,
  exchangeSets: readonly ExchangeSetFragment[]
): MealPlanFormState => {
  return {
    id,
    name,
    description: description || "",
    type,
    exchangeSet,
    initialExchangeSetId: exchangeSet?.id || null,
    exchangeSets: exchangeSet && !exchangeSets.map(es => es.id).includes(exchangeSet.id) ? [...exchangeSets, exchangeSet] : exchangeSets,
    isShared,
  };
};

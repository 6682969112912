import React from "react";
import { StaffDashboardOrgGroupFragment, useStaffDashboardOrgGroupsQuery } from "../../../../types";
import StaffContent from "../../../../views/Staff/GeneralizedStaffContent";
import { UseFetchQueryArgs } from "../../../../views/Staff/GeneralizedStaffContent/types";
import { CreateOrgGroupModal } from "../../../../views/Staff/OrgGroups/CreateOrgGroupModal";
import { EditOrgGroupModal } from "../../../../views/Staff/OrgGroups/EditOrgGroupModal";

const headerRowInfo = [
  { key: "name", displayName: "Name" },
  { key: "parentOrgGroupName", displayName: "Parent Org Group" },
  { key: "childOrgsCount", displayName: "# Children Org" },
  { key: "createdByName", displayName: "Created By" },
];

const useFetchQuery = (query: UseFetchQueryArgs) => {
  const { data, loading } = useStaffDashboardOrgGroupsQuery();

  return {
    data: {
      edges: data?.orgGroups ?? [],
      pageInfo: { total: 1, hasNextPage: false, endOffset: 1 },
    },
    loading,
  };
};
const getCreatedByName = ({ createdBy }: StaffDashboardOrgGroupFragment): string => {
  if (!createdBy) {
    return `--`;
  }
  if (createdBy.firstName || createdBy.lastName) {
    return `${createdBy.firstName} ${createdBy.lastName}`;
  }
  return createdBy.id;
};
const convertObjToRowData = (orgGroup: StaffDashboardOrgGroupFragment) => ({
  name: orgGroup.name,
  parentOrgGroupName: orgGroup.parentOrgGroup?.name ?? "",
  childOrgsCount: orgGroup.orgsCount,
  createdByName: getCreatedByName(orgGroup),
});

export const OrgGroupsPage = () => (
  <StaffContent
    headerRowInfo={headerRowInfo}
    convertObjToRowData={convertObjToRowData}
    queryCacheKey="allTeamLabels"
    useFetchQuery={useFetchQuery}
    entityName="Organization Group"
    getObjDisplay={tt => tt.name}
    renderEditDialog={({ open, onClose, initialEditInfo }) => (
      <EditOrgGroupModal
        open={open}
        onClose={onClose}
        orgGroupId={initialEditInfo.id} />
    )}
    renderCreateDialog={({ open, onClose }) => <CreateOrgGroupModal open={open} onClose={onClose} />}
  />
);

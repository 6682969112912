import { useTheme } from "@mui/material";
import React from "react";
import ChipListPreview from "../../universal/ChipList/Preview";
import { getDislikeChipDarkerSx, getDislikeChipSx, getLikeChipDarkerSx, getLikeChipSx } from "../FoodPreferenceUtils";
import { FoodPreferenceState } from "./reducer";

interface PreferencePreview {
  id: string;
  name: string;
}

const mapPreference = ({ id, name }: PreferencePreview) => ({
  id,
  label: name,
});

interface FoodPreferenceChipListProps {
  foodPreferenceState: FoodPreferenceState;
}

const FoodPreferenceChipList = ({ foodPreferenceState }: FoodPreferenceChipListProps) => {
  const theme = useTheme();

  return (
    <div>
      <ChipListPreview
        sx={getLikeChipDarkerSx(theme)}
        items={foodPreferenceState.promotedFoodGroups.map(mapPreference)}
        hideItemsAfter={2}
      />
      <br />
      <ChipListPreview
        sx={getLikeChipSx(theme)}
        items={foodPreferenceState.promotedFoods.map(mapPreference)}
        hideItemsAfter={2} />
      <br />
      <ChipListPreview
        sx={getDislikeChipDarkerSx(theme)}
        items={foodPreferenceState.avoidedFoodGroups.map(mapPreference)}
        hideItemsAfter={2}
      />
      <br />
      <ChipListPreview
        sx={getDislikeChipSx(theme)}
        items={foodPreferenceState.avoidedFoods.map(mapPreference)}
        hideItemsAfter={2} />
    </div>
  );
};

export default FoodPreferenceChipList;

import MealTemplateView from ".";
import ExchangeSummaryLabel from "../../Exchange/SummaryLabel";
import ExchangeAmountPreviewCard from "../../ExchangeAmount/PreviewCard";
import MealOptionViewMacro from "../../MealOption/View/Macro";

import { getExchangeAmountsMacros } from "../../ExchangeAmount/utils";
import MacrosSummaryLabel from "../../Macros/SummaryLabel";
import { ExchangeMealTemplateViewFragment, FullExchangeSetFragment } from "../../types";

interface MealTemplateViewOptionsExchangeProps {
  mealTemplate: ExchangeMealTemplateViewFragment;
  exchangeSet: FullExchangeSetFragment;
  containerWidth: number;
  displayExchangeLists: boolean;
  displayMealExchanges: boolean;
  displayMealMacros: boolean;
  displayMealOptions: boolean;
  displayMealOptionMacros: boolean;
}

const MealTemplateViewOptionsExchange = ({
  mealTemplate,
  containerWidth,
  exchangeSet,
  displayMealOptions,
  displayExchangeLists,
  displayMealMacros,
  displayMealExchanges,
  displayMealOptionMacros,
}: MealTemplateViewOptionsExchangeProps) => {
  const exchangeTargets = mealTemplate.exchangeTargets ? mealTemplate.exchangeTargets : [];
  const mealOptions = displayMealOptions ? mealTemplate.mealOptions : [];
  const { exchanges } = exchangeSet;
  const nonZeroExchangeTargets = displayExchangeLists ? exchangeTargets.filter(({ amount }) => amount > 0) : [];

  return (
    <MealTemplateView
      meal={mealTemplate.meal}
      containerWidth={containerWidth}
      label={
        <>
          {displayMealMacros && <MacrosSummaryLabel kcalPrefix variant="sm" macros={getExchangeAmountsMacros(exchangeTargets)} />}
          {displayMealMacros && displayMealExchanges && <br />}
          {displayMealExchanges && <ExchangeSummaryLabel targetExchangeAmounts={exchangeTargets} variant="sm" consolidatePro={false} />}
        </>
      }
      mealOptions={mealOptions}
      renderMealOption={mealOption => <MealOptionViewMacro mealOption={mealOption} displayMacros={displayMealOptionMacros} />}
      exchangeTargets={nonZeroExchangeTargets}
      renderExchangeTarget={et => {
        const matchingExchange = exchanges.find(e => e.id === et.exchange.id);
        return matchingExchange && <ExchangeAmountPreviewCard servingList={matchingExchange.exchangeServingList} exchangeAmount={et} />;
      }}
    />
  );
};

export default MealTemplateViewOptionsExchange;

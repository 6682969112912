import { CalendarEvent } from "apps/web/src/components/Calendar/Week/utils";
import { ActivityTemplateTimeFragment, MealTemplateTimeFragment } from "apps/web/src/types";

export type MealPlanCalendarEvent =
  | MealTemplateCalendarEvent
  | ActivityTemplateCalendarEvent
  | TeamworksCalendarEvent
  | SuggestedMealTemplateCalendarEvent;

// Events derived from the Schedule configuration
export type MealPlanScheduleEvent = MealTemplateCalendarEvent | ActivityTemplateCalendarEvent;

interface BaseMealPlanCalendarEvent extends CalendarEvent {
  mealPlanId: string | null;
}

export type TemplateEventType = "MealTemplate" | "ActivityTemplate" | "Teamworks" | "SuggestedMealTemplate";

export const isMealTemplateCalendarEvent = (event: MealPlanCalendarEvent): event is MealTemplateCalendarEvent =>
  event.type === "MealTemplate";
export const isActivityTemplateCalendarEvent = (event: MealPlanCalendarEvent): event is ActivityTemplateCalendarEvent =>
  event.type === "ActivityTemplate";
export const isSuggestedMealCalendarEvent = (event: MealPlanCalendarEvent): event is SuggestedMealTemplateCalendarEvent =>
  event.type === "SuggestedMealTemplate";

export interface SuggestedMealTemplateCalendarEvent extends BaseMealPlanCalendarEvent {
  type: "SuggestedMealTemplate";
  oldId: string;
  oldStart: Date;
  mealTemplate: MealTemplateTimeFragment;
}

export interface MealTemplateCalendarEvent extends BaseMealPlanCalendarEvent {
  type: "MealTemplate";
  mealTemplate: MealTemplateTimeFragment;
}

export interface ActivityTemplateCalendarEvent extends BaseMealPlanCalendarEvent {
  type: "ActivityTemplate";
  activityTemplate: ActivityTemplateTimeFragment;
}

export interface TeamworksCalendarEvent extends BaseMealPlanCalendarEvent {
  type: "Teamworks";
  name: string;
  location: string | null;
  eventType: string;
  mealPlanId: null;
}

import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { dateAndTimeToIsoInTz, getRangeAroundDateInTz, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import {
  MealPlanCreateCalendarInRangeQuery,
  MealPlanCreateCalendarInRangeQueryVariables,
  MealPlanEditCalendarInRangeQuery,
  MealPlanEditCalendarInRangeQueryVariables,
  useMealPlanCreateCalendarInRangeQuery,
  useMealPlanEditCalendarInRangeQuery,
} from "apps/web/src/types";
import { addMonths } from "date-fns";
import { Dispatch, useEffect, useCallback } from "react";
import { useMealPlanCalendarContext } from "../../contexts/Calendar";
import { LoadEditCalendarByMonth, LoadCreateCalendarByMonth } from "./Form/reducer";
import { QueryResult } from "@apollo/client";

/**
 * Loads date assignments and modifications for a meal plan based on the startOfWeekDate, startOfMonthDate
 * from the MealPlanCalendarContext.
 *
 * Currently only used for the Form components, but if moving to another spot, this hook should take onLoad as an arg
 */
export const useEditMealPlanCalendarInRange = (athleteId: string, mealPlanId: string, dispatch: Dispatch<LoadEditCalendarByMonth>) => {
  const { startOfWeekDate, startOfMonthDate } = useMealPlanCalendarContext();
  const clientTimezone = useClientTimezone();

  const onLoad = useCallback(
    ({ data, variables }: OnLoadArgs<MealPlanEditCalendarInRangeQuery, MealPlanEditCalendarInRangeQueryVariables>) => {
      if (data && variables) {
        dispatch({
          type: "LoadEditCalendarByMonth",
          payload: {
            startOfMonth: variables.startDate,
            individualDatesInRange: data.mealPlan.individualDatesInRange,
            modificationsInRange: data.mealPlan.modificationsInRange,
            mealPlanDateAssignments: data.athlete.mealPlanDateAssignmentsInRange,
            timelineMeals: data.athlete.timelineMealsInRange,
          },
        });
      }
    },
    [dispatch]
  );

  const useQuery = ({ start, end }: SimpleRangeArgs) =>
    useMealPlanEditCalendarInRangeQuery({ variables: { athleteId, mealPlanId, ...getRangeVariables({ start, end }, clientTimezone) } });

  return useCommonCalendarInRange(useQuery, startOfWeekDate, startOfMonthDate, onLoad);
};

export const useCreateMealPlanCalendarInRange = (athleteId: string, dispatch: Dispatch<LoadCreateCalendarByMonth>) => {
  const { startOfWeekDate, startOfMonthDate } = useMealPlanCalendarContext();
  const clientTimezone = useClientTimezone();

  const onLoad = useCallback(
    ({ data, variables }: OnLoadArgs<MealPlanCreateCalendarInRangeQuery, MealPlanCreateCalendarInRangeQueryVariables>) => {
      if (data && variables) {
        dispatch({
          type: "LoadCreateCalendarByMonth",
          payload: {
            startOfMonth: variables.startDate,
            mealPlanDateAssignments: data.athlete.mealPlanDateAssignmentsInRange,
            timelineMeals: data.athlete.timelineMealsInRange,
          },
        });
      }
    },
    [dispatch]
  );

  const useQuery = ({ start, end }: SimpleRangeArgs) =>
    useMealPlanCreateCalendarInRangeQuery({ variables: { athleteId, ...getRangeVariables({ start, end }, clientTimezone) } });

  return useCommonCalendarInRange(useQuery, startOfWeekDate, startOfMonthDate, onLoad);
};

const useCommonCalendarInRange = <TData extends MealPlanCreateCalendarInRangeQuery, TVariables>(
  useQuery: (variables: SimpleRangeArgs) => QueryResult<TData, TVariables>,
  startOfWeekDate: Date,
  startOfMonthDate: Date,
  onLoad: (args: OnLoadArgs<TData, TVariables>) => void
) => {
  // Note: Apollo will dedupe requests for the same variables, but if a week overlaps two months
  // The two sets of requests below will both need to be made

  // Current, previous, and next month based on date for WEEK view
  useQueryInMonthRange(useQuery, startOfWeekDate, onLoad);
  useQueryInMonthRange(useQuery, addMonths(startOfWeekDate, 1), onLoad);
  useQueryInMonthRange(useQuery, addMonths(startOfWeekDate, -1), onLoad);

  // Current, previous, and next month based on date for MONTH view
  useQueryInMonthRange(useQuery, startOfMonthDate, onLoad);
  useQueryInMonthRange(useQuery, addMonths(startOfMonthDate, 1), onLoad);
  useQueryInMonthRange(useQuery, addMonths(startOfMonthDate, -1), onLoad);
};

interface RangeVariables {
  startDate: string;
  endDate: string;
  startDateTime: string;
  endDateTime: string;
}

interface SimpleRangeArgs {
  start: string;
  end: string;
}

interface OnLoadArgs<TData, TVariables> {
  data: TData;
  variables: TVariables;
}

// Runs a useQuery hook with variables for a month around the anchor date, and calls onLoad when the data is loaded
const useQueryInMonthRange = <TData, TVariables>(
  useQuery: (variables: SimpleRangeArgs) => QueryResult<TData, TVariables>,
  anchorDate: Date,
  onLoad: (args: OnLoadArgs<TData, TVariables>) => void
) => {
  const clientTimezone = useClientTimezone();
  const args = getRangeAroundDateInTz(serializeDate(anchorDate), clientTimezone, { format: "date" });
  const result = useQuery(args);

  useEffect(() => {
    if (result.data && result.variables) {
      onLoad?.({
        data: result.data,
        variables: result.variables,
      });
    }
  }, [onLoad, result.data, result.variables]);

  return result;
};

const getRangeVariables = ({ start, end }: SimpleRangeArgs, clientTimezone: string): RangeVariables => ({
  startDate: start,
  endDate: end,
  startDateTime: dateAndTimeToIsoInTz({ date: start, time: "00:00:00" }, clientTimezone),
  // end is last day of the month, e.g. 2023-05-31
  endDateTime: dateAndTimeToIsoInTz({ date: end, time: "23:59:59" }, clientTimezone),
});

import { InputProps, MenuItem, SelectProps, SxProps, TextField, TextFieldProps } from "@mui/material";
import React from "react";

export interface SelectableOption {
  id: string;
}

export type TextBoxVariant = "standard" | "filled" | "outlined";

interface LabeledSelectProps<T extends SelectableOption> {
  placeholder?: string;
  optionToName: (option: T) => string | React.ReactNode;
  selectedOption: T | null;
  options: T[];
  onChange: (option: T) => void;
  textFieldProps?: TextFieldProps;
  SelectProps?: SelectProps;
  InputProps?: InputProps;
  inputProps?: any;
  children?: React.ReactNode;
  renderOption?: (option: T) => React.ReactNode;
  prependChildren?: boolean;
  required?: boolean;
  variant?: TextBoxVariant | undefined;
  error?: boolean;
  helperText?: string;
  className?: string;
  sx?: SxProps;
  disabled?: boolean;
}

const LabeledSelect = <T extends SelectableOption>({
  options,
  selectedOption,
  placeholder,
  optionToName,
  onChange,
  children,
  textFieldProps,
  SelectProps,
  InputProps,
  inputProps,
  renderOption: customRenderOption,
  prependChildren = false,
  required = false,
  variant,
  className,
  error,
  helperText,
  sx,
  disabled,
}: LabeledSelectProps<T>) => {
  const renderOption = (option: T) => {
    return customRenderOption ? (
      customRenderOption(option)
    ) : (
      <MenuItem key={option.id} value={option.id}>
        {optionToName(option)}
      </MenuItem>
    );
  };

  return (
    <TextField
      sx={sx}
      required={required}
      select
      label={placeholder}
      fullWidth={true}
      value={selectedOption ? selectedOption.id : ""}
      onChange={e => {
        const newOption = options.find(o => o.id === e.target.value);
        newOption && onChange(newOption);
      }}
      {...textFieldProps}
      variant={variant}
      SelectProps={SelectProps}
      InputProps={InputProps}
      inputProps={inputProps}
      className={className}
      error={error}
      helperText={helperText}
      disabled={disabled}
    >
      {prependChildren && children}
      {options.map(renderOption)}
      {!prependChildren && children}
    </TextField>
  );
};

export default LabeledSelect;

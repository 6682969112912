import { Box, styled, Typography } from "@mui/material";
import { useStreamChat } from "apps/web/src/contexts/StreamChatContext";
import React, { useRef } from "react";
import { ChannelPreviewUIComponentProps, Avatar as DefaultAvatar } from "stream-chat-react";
import { formatMessageDate } from "./utils";

const UnreadBadge = styled(Box)(({ theme: { palette, spacing } }) => ({
  height: "16px",
  background: palette.info.main,
  color: palette.common.white,
  borderRadius: "8px",
  fontSize: "11px",
  fontWeight: 600,
  textAlign: "center",
  lineHeight: "16px",
  margin: "1px 0", // match height of channel title
  padding: spacing(0, 0.5),
  display: "inline-block",
}));

const UnMemoizedChannelPreview = (props: ChannelPreviewUIComponentProps) => {
  const {
    active,
    Avatar = DefaultAvatar,
    channel,
    displayImage,
    displayTitle,
    latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
  } = props;

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const { last_message_at, messages } = channel.state;
  const avatarName = displayTitle || messages[messages.length - 1]?.user?.id;
  const unreadCount = unread || 0;
  const { setChannel } = useStreamChat();

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    setChannel(channel);
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  return (
    <Box
      sx={({ palette }) => ({
        background: active ? palette.greyscale[100] : palette.common.white,
        width: "100%",
        borderBottom: `1px solid ${palette.divider}`,
        padding: 0,
      })}
      aria-label={`Select Channel: ${displayTitle || ""}`}
      aria-selected={active}
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role="option"
      component={"button"}
    >
      <Box sx={{ px: 1, py: 2, display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 1 }}>
        <Box sx={{ overflow: "hidden", flex: 1, display: "flex", flexDirection: "row" }}>
          <Avatar image={displayImage} name={avatarName} />
          <Box sx={{ overflow: "hidden", display: "flex", flexDirection: "column", textAlign: "left" }}>
            <Typography variant="body2Medium">{displayTitle}</Typography>
            <Typography
              sx={{
                "> p": { margin: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flex: 1, minWidth: 0 },
              }}
              variant="subtitle1"
            >
              {latestMessage}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: "right", display: "flex", flexDirection: "column" }}>
          {unreadCount > 0 && (
            <div>
              <UnreadBadge>{unreadCount}</UnreadBadge>
            </div>
          )}
          {unreadCount === 0 && (
            <div aria-hidden>
              <UnreadBadge sx={{ opacity: 0 }}>0</UnreadBadge>
            </div>
          )}
          <Typography variant="subtitle1">{formatMessageDate(last_message_at)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const ChannelPreview = React.memo(UnMemoizedChannelPreview) as typeof UnMemoizedChannelPreview;

import Loading from "@notemeal/shared/ui/global/Loading";
import { MealMenuLogItemWithAppearance } from "@notemeal/shared/ui/MealMenuLogItem/utils";
import { getMenuItemMaxAmount } from "@notemeal/shared/ui/MenuItem/utils";
import {
  getMenuItemAmountsForSelection,
  getMenuSectionAmountsForSelection,
  MenuSelectionItemWithAppearance,
} from "@notemeal/shared/ui/MenuSelection/utils";
import newId from "@notemeal/shared/ui/utils/newId";
import MenuOrderDiningStationList from "apps/web/src/components/MenuOrder/DiningStation/List";
import { MenuOrderDialogMenuState } from "apps/web/src/components/MenuOrder/useMenuState";
import MenuSelectionItemEdit from "apps/web/src/components/MenuSelectionItem/Edit";
import MenuSelectionItemNew from "apps/web/src/components/MenuSelectionItem/New";
import { MenuSelectionItemFormFragment, useEditMealMenuLogItemMutation, useMealMenuQuery } from "apps/web/src/types";
import React from "react";
import { TimelineMealForModal } from "../../utils";
import TimelineMealMenuLogButtons from "../TimelineMealMenuLogButtons";
import { useTimelineMealModalContext } from "../TimelineMealModalContext";

interface TimelineMealModalOrderTabProps {
  timelineMeal: TimelineMealForModal;
  selectedMealMenuId: string;
  addMenuLogItem: (input: MealMenuLogItemWithAppearance) => void;
  setSelectedMealMenuId: (id: string) => void;
}

const TimelineMealMenuLogTab = ({
  timelineMeal,
  selectedMealMenuId,
  addMenuLogItem,
  setSelectedMealMenuId,
}: TimelineMealModalOrderTabProps) => {
  const { logMenuState: menuState, setLogMenuState: setMenuState } = useTimelineMealModalContext();

  const { data } = useMealMenuQuery({
    variables: {
      mealMenuId: selectedMealMenuId,
    },
  });

  const mealMenuDiningStations = data?.mealMenu.mealMenuDiningStations ?? [];

  const currentOrderMenuItemAmounts = getMenuItemAmountsForSelection([]);
  const currentOrderDiningStationAmounts = getMenuSectionAmountsForSelection([], mealMenuDiningStations);

  const [editMealMenuLogItem] = useEditMealMenuLogItemMutation();
  const handleEditItem = (input: MenuSelectionItemWithAppearance<MenuSelectionItemFormFragment>) => {
    editMealMenuLogItem({
      variables: {
        input: {
          mealMenuLogItemId: input.id,
          amount: input.amount,
          options: input.options.map(o => {
            return { ...o, percentConsumed: null, menuItemChoiceOptionId: o.menuItemChoiceOption.id };
          }),
        },
      },
    });
  };

  const getNextState = (item: MenuSelectionItemWithAppearance) => {
    if (menuState?.type === "editMenuItem") {
      const nextMenuState: MenuOrderDialogMenuState = {
        ...menuState,
        menuOrderItem: {
          ...menuState.menuOrderItem,
          ...item,
        },
      };
      setMenuState(nextMenuState);
    }
  };

  // in this component menu state should never be null but keeping changes as limited to this component as possible

  return (
    <>
      <TimelineMealMenuLogButtons
        timelineMeal={timelineMeal}
        selectedMenuId={selectedMealMenuId}
        setSelectedMealMenuId={setSelectedMealMenuId}
      />
      {!data ? (
        <Loading />
      ) : menuState === null ? null : menuState.type === "mealMenuDiningStations" ? (
        <MenuOrderDiningStationList
          onBack={() => setMenuState(null)}
          timelineMealFlow={true}
          forOrder={false}
          mealMenuDiningStations={mealMenuDiningStations}
          currentOrderMenuItemAmounts={currentOrderMenuItemAmounts}
          currentOrderDiningStationAmounts={currentOrderDiningStationAmounts}
          onSelectItem={(menuItemAppearance, currentAmountForDiningStation, maxAmountForDiningStation) =>
            setMenuState({
              type: "newMenuItem",
              forOrder: false,
              menuItemAppearance,
              newMenuOrderItem: null,
              currentAmountForDiningStation,
              maxAmountForDiningStation,
            })
          }
        />
      ) : menuState.type === "newMenuItem" ? (
        <MenuSelectionItemNew
          menuSelectionItemId={menuState.newMenuOrderItem?.id ?? newId()}
          selectionType={menuState.forOrder ? "Order" : "Log"}
          onChange={newMenuOrderItem => {
            setMenuState({
              ...menuState,
              newMenuOrderItem: {
                ...newMenuOrderItem,
                status: "new",
                forOrder: menuState.forOrder,
                rating: null,
              },
            });
          }}
          maxAmount={getMenuItemMaxAmount({
            currentAmountForMenuItem: currentOrderMenuItemAmounts[menuState.menuItemAppearance.menuItem.id] || 0,
            maxAmountForMenuItem: menuState.menuItemAppearance.maxAmount,
            currentAmountForMenuSection: menuState.currentAmountForDiningStation,
            maxAmountForMenuSection: menuState.maxAmountForDiningStation,
          })}
          availableForOrder={menuState.menuItemAppearance.availableForOrder}
          allowSpecialRequests={menuState.menuItemAppearance.allowSpecialRequests}
          menuItem={menuState.menuItemAppearance.menuItem}
          onDone={menuSelectionItem => {
            addMenuLogItem(menuSelectionItem);
            setMenuState({
              type: "mealMenuDiningStations",
              forOrder: menuState.forOrder,
            });
          }}
          onBack={() =>
            setMenuState({
              type: "mealMenuDiningStations",
              forOrder: menuState.forOrder,
            })
          }
        />
      ) : menuState.type === "editMenuItem" &&
        timelineMeal.mealMenuLogs.flatMap(mml => mml.items).find(mml => mml.id === menuState.menuOrderItem.id) ? (
        <MenuSelectionItemEdit
          menuSelectionItem={menuState.menuOrderItem}
          currentAmountForMenuItem={currentOrderMenuItemAmounts[menuState.menuOrderItem.menuItem.id] || 0}
          menuSections={mealMenuDiningStations}
          menuSectionAmountsForSelection={currentOrderDiningStationAmounts}
          onEdit={getNextState}
          onDone={() => handleEditItem(menuState.menuOrderItem)}
          onBack={() =>
            setMenuState({
              type: "mealMenuDiningStations",
              forOrder: true,
            })
          }
        />
      ) : null}
    </>
  );
};

export default TimelineMealMenuLogTab;

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Box, Button, Paper, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { IMatchResult, MatchType } from "../../../utils/import/match";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import ImportButton, { IFileImport, ImportMatchResults, ValidateFn } from "../../universal/Import/Button";
import { matchFieldsToText } from "./ImportResultList";
import ImportResultListContainer from "./ImportResultList/Container";
import { ImportResultResolveDialog } from "./ImportResultList/ImportResultResolveDialog";
import { ICrudHandler } from "./types";

export type TemplateFile = {
  name: string;
  data: string;
};

export interface ImportTabPanelProps<
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
> {
  children?: React.ReactNode;
  groupKey: keyof (ImportableEntity & LinkEntityFields) | null;
  updateSelectedMatches: (props: ICrudHandler<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>) => void;
  insertSelectedMatches: (props: ICrudHandler<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>) => void;
  matchableRows: (ImportableEntity & LinkEntityFields & AdditionalDisplayFields & { id: string })[];
  matchFields: (keyof ImportableEntity)[];
  linkToEntityName: string;
  entityName: string;
  linkableRows: LinkEntityFields[];
  linkFields: (keyof LinkEntityFields)[];
  linkOnFields: (keyof LinkFields)[];
  loadAndParse: ({ file, onError }: IFileImport) => Promise<ImportableEntity[] | null>;
  validate: ValidateFn<LinkFields, ImportableEntity, LinkEntityFields, AdditionalDisplayFields>;
  disableManualMatch?: boolean;
  disableInsert?: boolean;
  allowShowImportRow?: boolean;
  keyEntries?: string[];
  keyDisclaimer?: React.ReactNode;
  templateFile?: TemplateFile;
}

const BorderedPaper = styled(Paper)(
  ({
    theme: {
      palette: { divider },
    },
  }) => ({ border: `1px solid ${divider}` })
);

const PaperContentWrapper = styled(Box)(({ theme: { spacing } }) => ({ padding: spacing(3) }));

const ResultsHeader = styled(Box)(({ theme: { spacing } }) => ({ margin: spacing(2, 0) }));

export const ImportTabPanel = <
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
>(
  props: ImportTabPanelProps<LinkFields, ImportableEntity, LinkEntityFields, AdditionalDisplayFields>
) => {
  const {
    children,
    groupKey,
    updateSelectedMatches,
    insertSelectedMatches,
    matchableRows,
    matchFields,
    linkableRows,
    linkFields,
    linkOnFields,
    loadAndParse,
    linkToEntityName,
    entityName,
    disableManualMatch,
    disableInsert,
    allowShowImportRow,
    keyEntries,
    keyDisclaimer,
    templateFile,
    validate,
  } = props;
  const { setMessage } = useSnackbar();
  const [loadState, setLoadState] = useState<"done" | "error" | null>(null);
  const [rowsToUpdate, setRowsToUpdate] = useState<Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>>([]);
  const [rowsToInsert, setRowsToInsert] = useState<Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>>([]);
  const [rowsWithManyMatches, setRowsWithManyMatches] = useState<
    Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>
  >([]);
  const [allRows, setAllRows] = useState<Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>>([]);
  const [invalidRows, setInvalidRows] = useState<Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>>([]);
  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);
  const onMatched = ({
    failedMatches,
    manyMatches,
    singleMatches,
  }: ImportMatchResults<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>) => {
    // We filter out duplicates here...this can happen in ImportLinkModal when
    // two teams are aliased to one team, and the Teamworks import file contains
    // many athletes!
    const uniqueFailedMatches = failedMatches.reduce(
      (uniqMap, match) =>
        uniqMap[matchFieldsToText(match.matchFields, match.row)]
          ? uniqMap
          : {
              ...uniqMap,
              [matchFieldsToText(match.matchFields, match.row)]: match,
            },
      {} as {
        [key: string]: IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>;
      }
    );

    const allMatches = [...Object.values(uniqueFailedMatches), ...manyMatches, ...singleMatches];
    const matchesWithErrors = allMatches.filter(row => Boolean(row.error));
    setAllRows(allMatches);
    setInvalidRows(matchesWithErrors);

    if (matchesWithErrors.length > 0 || allMatches.some(row => row.matchedRows.length > 0)) {
      setResolveDialogOpen(true);
    } else {
      onRecordsResolved(allMatches, []);
    }
  };
  const onFileParsed = (rows: ImportableEntity[]) => {};
  const onFileLoaded = () => {};
  const onError = (msg: string) => {
    setMessage("error", msg);
    setLoadState("error");
  };

  const onRecordsResolved = (
    uniqueRows: Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>,
    matchedRows: Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>
  ) => {
    setRowsToInsert(uniqueRows);
    // This loses sort order!
    setRowsToUpdate(matchedRows.filter(row => row.matchedRows.length === 1));
    setRowsWithManyMatches(matchedRows.filter(row => row.matchedRows.length > 1));
    setResolveDialogOpen(false);
    setLoadState("done");
  };

  const moveRecordToMatched = (match: IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>, idx: number) => {
    setRowsToInsert(rowsToInsert.filter(m => matchFieldsToText(match.matchFields, match.row) !== matchFieldsToText(m.matchFields, m.row)));
    setRowsToUpdate([...rowsToUpdate, match]);
  };

  const onChange = (row: ImportableEntity & LinkEntityFields, type: MatchType, newRow: ImportableEntity & LinkEntityFields) => {
    const newMatchResult = {
      row: newRow,
      matchFields,
      type: "none" as const,
      matchedRows: [],
    };
    if (type === "none") {
      setRowsToInsert([...rowsToInsert.filter(matchRows => matchRows.row !== row), newMatchResult]);
    } else {
      setRowsToUpdate([...rowsToUpdate.filter(matchRows => matchRows.row !== row)]);
      setRowsToInsert([...rowsToInsert, newMatchResult]);
    }
  };

  return (
    <Box sx={({ spacing }) => ({ display: "flex", gap: spacing(4) })}>
      {loadState === null && (
        <>
          <Box sx={{ width: "60%" }}>
            <BorderedPaper>
              <Box sx={({ spacing }) => ({ padding: spacing(3) })}>
                <Box
                  sx={({ spacing }) => ({
                    padding: spacing(3),
                    minHeight: "69vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  })}
                >
                  <CloudUploadIcon color="disabled" sx={{ fontSize: "60px" }} />
                  <Typography component="div" role="tabpanel">
                    <Box
                      textAlign={"center"}
                      marginBottom={3}
                      maxWidth={"500px"}>
                      {children}
                    </Box>
                  </Typography>
                  <ImportButton
                    label="Import"
                    matchableRows={matchableRows}
                    matchFields={matchFields}
                    linkToEntityName={linkToEntityName}
                    linkFromEntityName={entityName}
                    linkableRows={linkableRows}
                    linkFields={linkFields}
                    linkOnFields={linkOnFields}
                    loadAndParse={loadAndParse}
                    onMatched={onMatched}
                    onError={onError}
                    onFileLoaded={onFileLoaded}
                    onFileParsed={onFileParsed}
                    validate={validate}
                  />
                </Box>
              </Box>
            </BorderedPaper>
          </Box>
          <Box sx={({ spacing }) => ({ display: "flex", flexDirection: "column", gap: spacing(4), width: "40%" })}>
            {templateFile && (
              <BorderedPaper>
                <PaperContentWrapper>
                  <Typography variant="h3">Templates</Typography>
                  <Button
                    variant="outlined"
                    download={templateFile.name}
                    href={templateFile.data}
                    sx={({ spacing }) => ({ marginTop: spacing(2), textTransform: "none" })}
                    endIcon={<GetAppIcon />}
                    fullWidth
                  >
                    {templateFile.name}
                  </Button>
                </PaperContentWrapper>
              </BorderedPaper>
            )}
            {keyEntries && keyEntries.length && (
              <BorderedPaper>
                <PaperContentWrapper>
                  <Typography variant="h3">Spreadsheet Key</Typography>
                  {keyDisclaimer && (
                    <Box
                      paddingY={1}
                      paddingX={2}
                      marginTop={1}
                      borderRadius={"5px"}
                      bgcolor={"grey.200"}>
                      <Typography variant="body2" color="textSecondary">
                        {keyDisclaimer}
                      </Typography>
                    </Box>
                  )}
                  <Box component="ul" sx={({ spacing }) => ({ paddingInlineStart: spacing(2) })}>
                    {keyEntries.map((entry, i) => (
                      <Typography
                        sx={({ spacing }) => ({ marginBottom: spacing(1) })}
                        key={i}
                        variant="body1"
                        component="li">
                        {entry}
                      </Typography>
                    ))}
                  </Box>
                </PaperContentWrapper>
              </BorderedPaper>
            )}
          </Box>
        </>
      )}
      {loadState === "done" && (
        <Box sx={{ width: "100%", position: "relative" }}>
          <ImportButton
            size={"small"}
            label="Import"
            matchableRows={matchableRows}
            matchFields={matchFields}
            linkToEntityName={linkToEntityName}
            linkFromEntityName={entityName}
            linkableRows={linkableRows}
            linkFields={linkFields}
            linkOnFields={linkOnFields}
            loadAndParse={loadAndParse}
            onMatched={onMatched}
            onError={onError}
            onFileLoaded={onFileLoaded}
            onFileParsed={onFileParsed}
            validate={validate}
            sx={{ position: "absolute", right: 0, top: "-60px" }}
          />
          <BorderedPaper>
            <Box sx={({ spacing }) => ({ display: "flex", gap: spacing(3) })}>
              <Box sx={({ spacing }) => ({ padding: spacing(1, 3, 3), flexBasis: "50%" })}>
                <ResultsHeader>
                  <Typography component="span" variant="h3">
                    Existing Records Matched {rowsToUpdate.length ? `(${rowsToUpdate.length})` : ""}
                  </Typography>
                </ResultsHeader>
                <ImportResultListContainer<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>
                  matches={rowsToUpdate}
                  setMatches={setRowsToUpdate}
                  groupKey={groupKey}
                  onAction={updateSelectedMatches}
                  actionButtonName="Update Selected Records"
                  onChange={onChange}
                  allowShowImportRow={allowShowImportRow}
                />
                {rowsWithManyMatches.length > 0 && (
                  <>
                    <ResultsHeader>
                      <Typography component="span" variant="h3">
                        Multiple Matches {rowsWithManyMatches.length ? `(${rowsWithManyMatches.length})` : ""}
                      </Typography>
                    </ResultsHeader>
                    <ImportResultListContainer<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>
                      matches={rowsWithManyMatches}
                      setMatches={setRowsWithManyMatches}
                      groupKey={groupKey}
                      onAction={updateSelectedMatches} //ad update here
                      actionButtonName="Update Most Recent Matched Record"
                      onChange={onChange}
                      allowShowImportRow={allowShowImportRow}
                    />
                  </>
                )}
              </Box>
              <Box
                sx={({ spacing, palette: { divider } }) => ({
                  borderLeft: `1px solid ${divider}`,
                  padding: spacing(1, 3, 3),
                  flexBasis: "50%",
                })}
              >
                <ResultsHeader>
                  <Typography component="span" variant="h3">
                    New Records {rowsToInsert.length ? `(${rowsToInsert.length})` : ""}
                  </Typography>
                </ResultsHeader>
                <ImportResultListContainer<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>
                  matches={rowsToInsert}
                  setMatches={setRowsToInsert}
                  groupKey={groupKey}
                  onAction={insertSelectedMatches}
                  onMatch={disableManualMatch ? undefined : moveRecordToMatched}
                  actionButtonName="Import Selected Records"
                  existingRows={matchableRows}
                  onChange={onChange}
                  allowShowImportRow={allowShowImportRow}
                  disabled={disableInsert}
                />
              </Box>
            </Box>
          </BorderedPaper>
        </Box>
      )}
      <ImportResultResolveDialog<LinkFields, ImportableEntity, LinkEntityFields, AdditionalDisplayFields>
        open={resolveDialogOpen}
        onContinue={onRecordsResolved}
        onCancel={() => {
          setLoadState(loadState === "done" ? "done" : null);
          setResolveDialogOpen(false);
        }}
        allRows={allRows}
        invalidRows={invalidRows}
        matchableRows={matchableRows}
        groupKey={groupKey}
      />
    </Box>
  );
};

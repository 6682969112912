import { Dialog } from "@mui/material";
import React from "react";

import { useAddAthleteEmailMutation, useAddAthletePhoneNumberMutation } from "../../types";
import UpdateEmailForm from "./UpdateEmailForm";
import UpdatePhoneForm from "./UpdatePhoneForm";

interface MealPlanExportAddPhoneNumberOrEmailDialogProps {
  athleteId: string;
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
  type: "phoneNumber" | "email";
}

const MealPlanExportAddPhoneNumberOrEmailDialog = ({
  athleteId,
  open,
  onClose,
  onAdd,
  type,
}: MealPlanExportAddPhoneNumberOrEmailDialogProps) => {
  const update = () => {
    onClose();
    onAdd();
  };

  const [addAthletePhoneNumber, { called: savingPhone }] = useAddAthletePhoneNumberMutation({ update });
  const [addAthleteEmail, { called: savingEmail }] = useAddAthleteEmailMutation({ update });

  const handleSave = (input: string) => {
    if (type === "phoneNumber") {
      addAthletePhoneNumber({
        variables: { input: { athleteId, phoneNumber: input } },
      });
    } else {
      addAthleteEmail({ variables: { input: { athleteId, email: input } } });
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      {type === "phoneNumber" ? (
        <UpdatePhoneForm handleSave={handleSave} saving={savingPhone} />
      ) : (
        <UpdateEmailForm handleSave={handleSave} saving={savingEmail} />
      )}
    </Dialog>
  );
};

export default MealPlanExportAddPhoneNumberOrEmailDialog;

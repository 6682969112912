import FlagIcon from "@mui/icons-material/Flag";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { useOrgQuery } from "../../../../types";
import CreateUserModal from "../../../../views/Staff/Org/CreateModal";
import { EditOrgMembershipDialog } from "../../../../views/Staff/Org/EditDialog";
import { OrgFeatureFlagsDialog } from "../../../../views/Staff/Org/FeatureFlagsDialog";
import OrgMembershipTable, { AdminOrgMembershipTableRow } from "../../../../views/Staff/Org/Table";

export const StaffOrgPage = () => {
  const { orgId: maybeOrgId } = useParams();
  const id = maybeOrgId || "";
  const [queryText, setQueryText] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [featureFlagsOpen, setFeatureFlagsOpen] = useState(false);
  const [selectedOrgMembership, setSelectedOrgMembership] = useState<AdminOrgMembershipTableRow | null>(null);
  const { data, loading } = useOrgQuery({ variables: { id } });

  if (loading || !data) {
    return <Loading progressSize="lg" />;
  }
  const org = data.org;
  const lowercaseQuery = queryText.toLowerCase();
  const filteredMemberships = org.memberships.filter(om => {
    const searchString =
      `${om.user.firstName} ${om.user.lastName} ${om.user.email} ${om.user.username} ${om.user.phoneNumber}`.toLowerCase();
    return searchString.includes(lowercaseQuery);
  });
  const rows = filteredMemberships.map(({ roles, user, ...rest }) => ({
    ...user,
    ...rest,
    roles: [...roles],
  }));

  const hasTeamworksIdWithSuperuser = org.teamworksId !== null && org.hasTwSuperuserPersonId === true;

  return (
    <Box sx={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{
            "aria-label": `Search Org Memberships`,
          }}
          placeholder={"Search Org Memberships"}
          value={queryText}
          onChange={e => setQueryText(e.target.value)}
          sx={{ minWidth: 260 }}
        />
        <IconButton onClick={() => setFeatureFlagsOpen(true)} size="large">
          <FlagIcon />
        </IconButton>
      </Box>
      <OrgMembershipTable
        tableRows={rows}
        onClickAddEntry={() => setCreateModalOpen(true)}
        onClickRow={(row: AdminOrgMembershipTableRow) => {
          setSelectedOrgMembership(row);
          setEditModalOpen(true);
        }}
      />

      {createModalOpen && <CreateUserModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        org={org} />}

      {editModalOpen && selectedOrgMembership && (
        <EditOrgMembershipDialog
          open={editModalOpen}
          onClose={() => {
            setSelectedOrgMembership(null);
            setEditModalOpen(false);
          }}
          orgMembership={selectedOrgMembership}
          org={org}
        />
      )}

      {featureFlagsOpen && (
        <OrgFeatureFlagsDialog
          open={featureFlagsOpen}
          onClose={() => setFeatureFlagsOpen(false)}
          orgId={org.id}
          hasTeamworksIdWithSuperuser={hasTeamworksIdWithSuperuser}
          featureFlags={org.featureFlags}
        />
      )}
    </Box>
  );
};

import { Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import ChipListPreview from "../../../components/universal/ChipList/Preview";
import { AdvancedSelectionFragment } from "../../../types";
import ShareWithInfo from "../../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import { buildInitialEditAdvancedSelectionState } from "../../Tags/reducers/advancedSelectionReducers";

interface MealMenuWithSharePreview {
  hasAdvancedSelection: boolean;
  teams:
    | readonly {
        id: string;
        name: string;
      }[]
    | null;
}

interface ShareContentProps {
  mealMenu: MealMenuWithSharePreview;
  advancedSelection: AdvancedSelectionFragment | null | undefined;
  loading: boolean; // Loading is just for advancedSelection
  teamChipClassName: string;
  maxTeamChips?: number;
}

const ShareContent = ({ mealMenu, advancedSelection, loading, teamChipClassName, maxTeamChips: _maxTeamChips }: ShareContentProps) => {
  if (mealMenu.hasAdvancedSelection) {
    return (
      <>
        <Typography fontSize="14px" fontWeight="medium">
          Shared With
        </Typography>

        {advancedSelection !== undefined && !loading ? (
          advancedSelection !== null ? (
            <ShareWithInfo
              state={buildInitialEditAdvancedSelectionState(advancedSelection)}
              maxChips={6}
              showAdvancedSelectionClear={false}
            />
          ) : (
            <Typography color="error">Not shared anyone!</Typography>
          )
        ) : (
          <Loading progressSize="xs" />
        )}
      </>
    );
  } else if (mealMenu.teams) {
    // If meal menu does not hasAdvancedSelection teams should be defined
    const sortedMealMenuTeams = sortByKey(mealMenu.teams, "name").map(({ id, name }) => ({ id, label: name }));
    const maxTeamChips = _maxTeamChips ?? mealMenu.teams.length;
    return (
      <>
        <Typography fontSize="14px" fontWeight="medium">
          Shared with
        </Typography>
        {sortedMealMenuTeams.length === 0 ? (
          <Typography color="error">Menu not shared</Typography>
        ) : (
          <ChipListPreview
            items={sortedMealMenuTeams}
            chipClassName={teamChipClassName}
            hideItemsAfter={maxTeamChips} />
        )}
      </>
    );
  }
  return <Typography color="error">Not shared with anyone!</Typography>;
};

export default ShareContent;

import { PaletteMode, ThemeOptions, alpha, createTheme } from "@mui/material";
import { componentsBasedOnTheme } from "./BasedOnTheme/ComponentsBasedOnTheme";
import { greyscaleDark, greyscaleLight, neutral } from "./Colors/Greyscale";
import { componentsCommon } from "./Common/ComponentsCommon";
import { paletteCommon } from "./Common/PaletteCommon";
import { typographyCommon } from "./Common/TypographyCommon";

const commonTheme: ThemeOptions = {
  palette: paletteCommon,
  typography: typographyCommon,
  components: componentsCommon,
};

const lightTheme: ThemeOptions = {
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    mode: "light",
    greyscale: greyscaleLight,
    highEmphasisText: alpha(neutral.black, 0.87),
    mediumEmphasisText: alpha(neutral.black, 0.6),
    lowEmphasisText: alpha(neutral.black, 0.38),
  },
  components: {
    ...commonTheme.components,
  },
};

const darkTheme: ThemeOptions = {
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    mode: "dark",
    greyscale: greyscaleDark,
    highEmphasisText: neutral.white,
    mediumEmphasisText: alpha(neutral.white, 0.6),
    lowEmphasisText: alpha(neutral.white, 0.38),
  },
  components: {
    ...commonTheme.components,
  },
};

const optionsBasedOnTheme = (themeOptions: ThemeOptions) => ({
  typography: {},
  palette: {
    rpeLight: themeOptions.palette?.success,
    rpeModerate: themeOptions.palette?.warning,
    rpeHeavy: themeOptions.palette?.error,
    orderStatusNew: themeOptions.palette?.info,
    orderStatusCooking: themeOptions.palette?.warning,
    orderStatusDone: themeOptions.palette?.success,
    orderStatusCancelled: themeOptions.palette?.error,
  },
  components: componentsBasedOnTheme(themeOptions),
});

export const getTheme = (mode: PaletteMode) => {
  const baseTheme = createTheme(mode === "light" ? lightTheme : darkTheme);
  const theme = createTheme(baseTheme, optionsBasedOnTheme(baseTheme));
  return theme;
};

import { MenuItem, MenuItemProps, SxProps, TextField } from "@mui/material";
import React from "react";

type SelectableOption = string;

interface LabeledSelectNoIdProps<T extends SelectableOption> {
  placeholder: string;
  optionToName: (option: T) => string;
  selectedOption: T | null;
  options: T[];
  onChange: (option: T) => void;
  children?: React.ReactNode;
  error?: boolean;
  required?: boolean;
  // MenuItemProps and MenuItem's props types don't completely match...
  MenuItemProps?: MenuItemProps & { button?: true };
  disabled?: boolean;
  helperText?: React.ReactNode;
  label?: string;
  sx?: SxProps;
}

const LabeledSelectNoId = <T extends SelectableOption>({
  options,
  error,
  required,
  selectedOption,
  placeholder,
  optionToName,
  onChange,
  children,
  MenuItemProps = {},
  disabled,
  helperText,
  label,
  sx,
}: LabeledSelectNoIdProps<T>) => {
  return (
    <TextField
      sx={sx}
      disabled={disabled}
      select
      label={label || placeholder}
      fullWidth={true}
      value={selectedOption ? selectedOption : ""}
      error={error}
      required={required}
      helperText={helperText}
      onChange={e => {
        const newOption = options.find(o => o === e.target.value);
        newOption && onChange(newOption);
      }}
    >
      {options.map(option => (
        <MenuItem
          key={option}
          value={option}
          {...MenuItemProps}>
          {optionToName(option)}
        </MenuItem>
      ))}
      {children}
    </TextField>
  );
};

export default LabeledSelectNoId;

import { Button, ButtonGroup, Checkbox, FormControlLabel, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch } from "react";
import RoleMultiSelect from "./RoleMultiSelect";
import { CreateOrgMembershipFormAction, CreateOrgMembershipFormState } from "./utils";

const useStyles = makeStyles((theme: Theme) => {
  const childStyles = {
    margin: theme.spacing(0, 1),
    alignSelf: "center",
  };
  return createStyles({
    root: {
      height: "100%",
      display: "grid",
      gridTemplateColumns: `1fr 1fr 1fr`,
      gridTemplateRows: "1fr 1fr 1fr",
    },
    firstName: {
      gridColumnStart: 1,
      gridRowStart: 1,
      ...childStyles,
    },
    lastName: {
      gridColumnStart: 2,
      gridRowStart: 1,
      ...childStyles,
    },
    email: {
      gridColumnStart: 3,
      gridRowStart: 1,
      ...childStyles,
    },
    phoneNumber: {
      gridColumnStart: 3,
      gridRowStart: 2,
      ...childStyles,
    },
    roles: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 2,
      ...childStyles,
    },
    inactive: {},
    active: {},
    isAdmin: {
      gridColumnStart: 2,
      gridRowStart: 3,
      ...childStyles,
    },
    method: {
      gridColumnStart: 3,
      gridRowStart: 3,
      ...childStyles,
    },
  });
});

interface CreateOrgMembershipFormProps {
  state: CreateOrgMembershipFormState;
  dispatch: Dispatch<CreateOrgMembershipFormAction>;
}

const CreateOrgMembershipForm = ({ state, dispatch }: CreateOrgMembershipFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        className={classes.firstName}
        label="First Name"
        margin="dense"
        required={true}
        value={state.firstName}
        onChange={e => dispatch({ type: "CHANGE_FIRST_NAME", payload: e.target.value })}
      />
      <TextField
        className={classes.lastName}
        label="Last Name"
        margin="dense"
        required={true}
        value={state.lastName}
        onChange={e => dispatch({ type: "CHANGE_LAST_NAME", payload: e.target.value })}
      />
      <TextField
        className={classes.email}
        margin="dense"
        required={true}
        label="Email"
        onChange={e => dispatch({ type: "CHANGE_EMAIL", payload: e.target.value })}
        value={state.email}
      />
      <TextField
        className={classes.phoneNumber}
        margin="dense"
        label="Phone #"
        onChange={e => dispatch({ type: "CHANGE_PHONE_NUMBER", payload: e.target.value })}
        value={state.phoneNumber}
      />
      <div className={classes.roles}>
        <RoleMultiSelect
          roles={["dietitian", "chef", "foodmanager", "kiosk", "nonRdAdmin"]}
          selectedRoles={state.roles}
          setSelectedRoles={roles => dispatch({ type: "CHANGE_ROLES", payload: roles })}
          required={true}
        />
      </div>
      <FormControlLabel
        className={classes.isAdmin}
        value="admin"
        control={
          <Checkbox
            checked={state.isAdmin}
            onChange={e =>
              dispatch({
                type: "CHANGE_IS_ADMIN",
                payload: !state.isAdmin,
              })
            }
          />
        }
        label="Admin?"
        labelPlacement="top"
      />
      <FormControlLabel
        className={classes.method}
        value="method"
        control={
          <ButtonGroup>
            <Button
              sx={{ width: "50%" }}
              variant={state.method === "txt" ? "contained" : "outlined"}
              onClick={e =>
                dispatch({
                  type: "CHANGE_METHOD",
                  payload: "txt",
                })
              }
            >
              TXT
            </Button>
            <Button
              sx={{ width: "50%" }}
              variant={state.method === "email" ? "contained" : "outlined"}
              onClick={e =>
                dispatch({
                  type: "CHANGE_METHOD",
                  payload: "email",
                })
              }
            >
              Email
            </Button>
          </ButtonGroup>
        }
        label="Method"
        labelPlacement="top"
      />
    </div>
  );
};

export default CreateOrgMembershipForm;

import {
  EditExchangeAmountFragment,
  EditExchangeAmountFragmentDoc,
  ExchangeAmountFragment,
  ExchangeFragment,
  ExchangeFragmentDoc,
} from "../../types";
import { inputToNumber } from "../../utils/inputUtils";
import newId from "../../utils/newId";
import { ResolverContext } from "./types";
import { makeFragmentFuncs, makeFragmentFuncsWithInit } from "./utils";

export const initiEditExchangeAmountFragment = (exchangeAmount: ExchangeAmountFragment): EditExchangeAmountFragment => {
  return { amountInput: String(exchangeAmount.amount), ...exchangeAmount };
};

const { getFragmentOrInit } = makeFragmentFuncsWithInit(
  "ExchangeAmount",
  "EditExchangeAmount",
  EditExchangeAmountFragmentDoc,
  initiEditExchangeAmountFragment
);

const exchangeFragmentFuncs = makeFragmentFuncs<ExchangeFragment>("Exchange", "Exchange", ExchangeFragmentDoc);

export const resolverMap = {
  amountInput: (src: ExchangeAmountFragment, args: any, context: ResolverContext): string => {
    return getFragmentOrInit(src, context).amountInput;
  },
};

type ExchangeTargetInput = {
  exchangeId: string;
  amountInput: string;
  pickListServingIds: ReadonlyArray<string> | null;
};

export const mergeExchangeTargetsWithInputs = (
  exchangeTargets: readonly EditExchangeAmountFragment[],
  inputs: ExchangeTargetInput[],
  context: ResolverContext
): EditExchangeAmountFragment[] => {
  const inputExchangeIds = inputs.map(input => input.exchangeId);
  const unchangedExchangeTargets = exchangeTargets.filter(et => !inputExchangeIds.includes(et.exchange.id));
  const changedExchangeTargets = inputs.map(({ exchangeId, amountInput, pickListServingIds }) => {
    const matchingExchangeTarget = exchangeTargets.find(et => et.exchange.id === exchangeId);
    if (matchingExchangeTarget) {
      return {
        ...matchingExchangeTarget,
        amountInput,
        pickListServingIds,
        amount: inputToNumber(amountInput) || 0,
      };
    } else {
      const exchange = exchangeFragmentFuncs.getFragment(exchangeId, context);
      return {
        id: newId(),
        __typename: "ExchangeAmount" as const,
        exchange,
        amountInput,
        pickListServingIds,
        amount: inputToNumber(amountInput) || 0,
      };
    }
  });

  return [...unchangedExchangeTargets, ...changedExchangeTargets];
};

interface getExchangeTargetDiffAsInputsArgs {
  currentExchangeTargets: readonly EditExchangeAmountFragment[];
  serverExchangeTargets: readonly ExchangeAmountFragment[];
}

export const getExchangeTargetDiffAsInputs = ({
  currentExchangeTargets,
  serverExchangeTargets,
}: getExchangeTargetDiffAsInputsArgs): ExchangeTargetInput[] => {
  return currentExchangeTargets
    .flatMap(et => {
      const matchingServerExchangeTarget = serverExchangeTargets.find(sEt => sEt.exchange.id === et.exchange.id);
      return !matchingServerExchangeTarget ||
        matchingServerExchangeTarget.amount !== inputToNumber(et.amountInput) ||
        String(matchingServerExchangeTarget.pickListServingIds) !== String(et.pickListServingIds)
        ? [
            {
              exchangeId: et.exchange.id,
              amountInput: et.amountInput,
              pickListServingIds: et.pickListServingIds,
            },
          ]
        : [];
    })
    .filter(et => et.amountInput !== "");
};

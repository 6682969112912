import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Dialog, DialogTitle, IconButton, Typography, styled } from "@mui/material";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import { useDigitalDisplaysDialogQuery } from "apps/web/src/types";
import React, { useRef, useState } from "react";
import DiscardChangesDialog from "../../../components/universal/DiscardChangesDialog";
import { DigitalDisplayContent } from "./Content";
import { exportComponentsAsJpeg } from "./utils";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";

const StyledTitle = styled(DialogTitle)(
  ({
    theme: {
      palette: { grey },
    },
  }) => ({
    display: "flex",
    backgroundColor: "background.paper",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: grey[200],
  })
);

interface DigitalDisplaysCreateDialogProps {
  open: boolean;
  onClose: () => void;
  mealMenuId: string;
}

export const DigitalDisplaysCreateDialog = ({ open, onClose, mealMenuId }: DigitalDisplaysCreateDialogProps) => {
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const { formatDateInTimezoneWithLocale } = useDateFormatting();
  const clientTimezone = useClientTimezone();
  const [loading, setLoading] = useState(false);
  const refs = useRef<HTMLDivElement[]>([]);

  const { data } = useDigitalDisplaysDialogQuery({
    variables: {
      mealMenuId,
    },
  });

  const handleClickExport = () => {
    setLoading(true);
    if (data) {
      const mealMenuName = data.mealMenu.name;
      const mealMenuDate = formatDateInTimezoneWithLocale(data.mealMenu.start, clientTimezone, {
        variant: "short",
        format: "ddMMyyyy",
      });
      exportComponentsAsJpeg(refs.current, "".concat(mealMenuDate, "-", mealMenuName, "-"));
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <StyledTitle>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
          <IconButton
            disabled={false}
            onClick={onClose}
            size="large">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h3">Create Menu Display</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <LoadingButton
            loading={loading}
            disabled={!data}
            onClick={handleClickExport}
            buttonText="Export" />
        </Box>
      </StyledTitle>
      {data && <DigitalDisplayContent mealMenu={data.mealMenu} refs={refs} />}
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
    </Dialog>
  );
};

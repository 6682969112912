import { Box } from "@mui/material";
import { sortByKey } from "@notemeal/utils/sort";
import { useLocalStorageValueAsType } from "apps/web/src/utils/localStorage";
import React from "react";
import { Boolean } from "runtypes";
import { MealMenuDiningStationWithItemPreviewsFragment, MenuItemAppearancePreviewFragment } from "../../../types";
import { MenuItemPreview } from "../../MenuSelection/MenuItemPreview";
import Label from "./Label";

interface MenuOrderDiningStationProps {
  mealMenuDiningStation: MealMenuDiningStationWithItemPreviewsFragment;
  currentAmountForDiningStation: number;
  onSelectItem: (appearance: MenuItemAppearancePreviewFragment, forOrder: boolean) => void;
  forOrder: boolean;
}

const MenuOrderDiningStation = ({
  onSelectItem,
  mealMenuDiningStation,
  currentAmountForDiningStation,
  forOrder,
}: MenuOrderDiningStationProps) => {
  const loggingEnabled = useLocalStorageValueAsType("orderKioskLoggingEnabled", Boolean)[0];

  return (
    <>
      <Label
        name={mealMenuDiningStation.name}
        currentAmount={currentAmountForDiningStation}
        maxAmount={mealMenuDiningStation.maxAmount} />
      <Box sx={{ display: "grid", gap: 3, gridTemplateColumns: "repeat(2, 1fr)" }}>
        {sortByKey(mealMenuDiningStation.menuItemAppearances, "position").map(
          mia =>
            (mia.availableForOrder || loggingEnabled) && (
              <MenuItemPreview
                key={mia.menuItem.id}
                sx={{
                  root: {
                    position: "relative",
                    height: "100px",
                  },
                  ...(forOrder && !mia.availableForOrder ? { actionArea: { opacity: 0.6 } } : {}),
                }}
                menuItemAppearance={mia}
                onClick={() => onSelectItem(mia, forOrder)}
              />
            )
        )}
      </Box>
    </>
  );
};

export default MenuOrderDiningStation;

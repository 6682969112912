import { Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { UnmatchedIngredient, UnmatchedIngredients } from "apps/web/src/types";

const WarningBox = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: spacing(1),
  lineHeight: "18px",
}));

const WarningText = styled(Typography)(({ theme: { palette } }) => ({
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 600,
  color: palette.highEmphasisText,
}));

const formatServingAmountsForDisplay = (unmatchedIngredients: readonly UnmatchedIngredient[]) => {
  // round to 2 decimals, only if needed (e.g. 0.5 will stay 0.5, 0.6666666
  // will be rounded to 0.67)
  return unmatchedIngredients.map(
    i => `${i.foodName} (${!!i.amount ? (Math.round(i.amount * 100) / 100).toString().concat(" ") : ""}${i.servingName})`
  );
};

interface UnmatchedIngredientsWarningProps {
  unmatchedIngredients: UnmatchedIngredients;
}

export const UnmatchedIngredientsWarning = ({ unmatchedIngredients }: UnmatchedIngredientsWarningProps) => {
  const theme = useTheme();
  const [hide, setHide] = useState(false);

  const unmatchedName = formatServingAmountsForDisplay(unmatchedIngredients.unmatchedName);
  const unmatchedServings = formatServingAmountsForDisplay(unmatchedIngredients.unmatchedServings);

  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.palette.warning.lighter,
        borderRadius: 1,
        p: 2,
        position: "relative",
        display: "flex",
        flexDirection: "row",
        gap: 1,
      })}
    >
      <Box display="flex" flexDirection="column">
        <WarningRoundedIcon color="warning" />
      </Box>
      <Box width="100%">
        <WarningBox>
          <WarningText>Warning</WarningText>
          <IconButton onClick={() => setHide(!hide)} sx={{ padding: 0.5 }}>
            {hide ? (
              <KeyboardArrowDownIcon fontSize="small" sx={{ color: "black" }} />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" sx={{ color: "black" }} />
            )}
          </IconButton>
        </WarningBox>
        {!hide && (
          <Box
            display="flex"
            flexDirection="column"
            gap={0.5}>
            {unmatchedName.length > 0 && (
              <Typography variant="body2" color={theme.palette.highEmphasisText}>
                Certain {unmatchedServings.length ? <u>ingredient(s)</u> : "ingredient(s)"} could not be found. Please add the following
                ingredient(s) manually: <strong>{unmatchedName.join(", ")}</strong>
              </Typography>
            )}
            {unmatchedServings.length > 0 && (
              <Typography variant="body2" color={theme.palette.highEmphasisText}>
                Certain {unmatchedName.length ? <u>ingredient unit(s)</u> : "ingredient unit(s)"} for the following ingredient(s) could not
                be found. Please add the following ingredient(s) manually: <strong>{unmatchedServings.join(", ")}</strong>
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

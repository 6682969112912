import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { useRefetchQueriesContext } from "../../../../../../contexts/RefetchQueries";
import { useAddPositionMutation } from "../../../../../../types";

interface PositionModalProps {
  sportId: string;
  open: boolean;
  onClose: () => void;
}

const CreatePositionModal = ({ open, onClose, sportId }: PositionModalProps) => {
  const [positionName, setPositionName] = useState("");
  const refetchQueries = useRefetchQueriesContext();

  const [addPosition] = useAddPositionMutation({
    refetchQueries,
  });

  const handleSave = () => {
    addPosition({
      variables: { input: { sportId, name: positionName } },
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Add Position" onClose={onClose} />
      <DialogContent>
        <TextField
          // MUI bug - https://github.com/mui/material-ui/issues/31185
          margin="dense"
          label="Position Name"
          value={positionName}
          onChange={e => setPositionName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePositionModal;

import { ListSubheader, Theme, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      color: theme.palette.common.black,
    },
  })
);

const styles = {
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export interface ListHeaderProps extends TypographyProps {
  title: string;
}

const ListHeader = ({ title, variant, className, children }: ListHeaderProps) => {
  const classes = useStyles();
  return (
    <ListSubheader className={classnames(classes.header, className)}>
      <Typography variant={variant ?? "h3"} sx={styles.title}>
        {title}
      </Typography>
      {children}
    </ListSubheader>
  );
};

export default ListHeader;

import { Box } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { ViewAttendanceFormType } from "./ViewAttendanceFormSchema";

interface ViewAttendanceFormProps {
  form: UseFormReturn<ViewAttendanceFormType>;
}

export const ViewAttendanceForm = ({ form }: ViewAttendanceFormProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Box sx={{ mt: 3, display: "flex", gap: 3 }}>
      <Controller
        name={"startDate"}
        control={control}
        render={({ field }) => (
          <DesktopDatePicker
            {...field}
            sx={{ width: "50%" }}
            label="Start Date"
            value={field.value ?? null}
            onChange={date => {
              field.onChange(date);
              // react-hook-form only notices errors from the field that is changing
              // this forces it to accept errors from our superRefine which spans fields
              form.trigger("dateRangeError");
            }}
            slotProps={{
              textField: {
                error: Boolean(errors.startDate || errors.dateRangeError),
                helperText: errors.startDate?.message ?? errors.dateRangeError?.message,
                placeholder: "Select Date",
              },
            }}
          />
        )}
      />
      <Controller
        name={"endDate"}
        control={control}
        render={({ field }) => (
          <DesktopDatePicker
            {...field}
            sx={{ width: "50%" }}
            label="End Date"
            value={field.value ?? null}
            onChange={date => {
              field.onChange(date);
              // react-hook-form only notices errors from the field that is changing
              // this forces it to accept errors from our superRefine which spans fields
              form.trigger("dateRangeError");
            }}
            slotProps={{
              textField: {
                error: Boolean(errors.endDate || errors.dateRangeError),
                helperText: errors.endDate?.message ?? errors.dateRangeError?.message,
                placeholder: "Select Date",
              },
            }}
          />
        )}
      />
    </Box>
  );
};

import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { ExchangeMealPlanDisplaySettingsFragment } from "../../types";
import { MACROS_PROGRESS_SMALL_HEIGHT, MACROS_PROGRESS_SMALL_WIDTH } from "../MacrosProgress/MacrosProgressUtils";
import { MacrosAndExchangesDisplaySettingsPreview } from "./DisplaySettingsPreviews";
import { exchangeProgressViewsTypeToName, macroProgressViewsTypeToName } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    formRow: {
      padding: theme.spacing(2),
    },
    dropDownGroup: {
      padding: theme.spacing(),
    },
    border: {
      padding: theme.spacing(1),
      width: `${MACROS_PROGRESS_SMALL_WIDTH + 66}px`,
      height: `${MACROS_PROGRESS_SMALL_HEIGHT + 16}px`,
      border: "1px solid grey",
      borderRadius: theme.spacing(0.5),
      backgroundColor: "white",
      marginTop: theme.spacing(),
    },
  })
);

interface MealPlanDisplaySettingsExchangeWithMobileExportFormProps {
  displaySettings: ExchangeMealPlanDisplaySettingsFragment;
}

const MealPlanDisplaySettingsExchangeWithMobileExportForm = ({
  displaySettings,
}: MealPlanDisplaySettingsExchangeWithMobileExportFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.formRow}>
        <Typography>
          <b>Daily:</b>
          <br />
          {macroProgressViewsTypeToName(displaySettings.dailyProgressViewsForMacros)}
          <br />
          {exchangeProgressViewsTypeToName(displaySettings.dailyProgressViewsForExchanges)}
        </Typography>
        <div className={classes.border}>
          <MacrosAndExchangesDisplaySettingsPreview
            macroProgressViews={displaySettings.dailyProgressViewsForMacros}
            exchangeProgressViews={displaySettings.dailyProgressViewsForExchanges}
          />
        </div>
      </div>
      <div className={classes.formRow}>
        <Typography>
          <b>Meal:</b>
          <br />
          {macroProgressViewsTypeToName(displaySettings.mealProgressViewsForMacros)}
          <br />
          {exchangeProgressViewsTypeToName(displaySettings.mealProgressViewsForExchanges)}
        </Typography>

        <div className={classes.border}>
          <MacrosAndExchangesDisplaySettingsPreview
            macroProgressViews={displaySettings.mealProgressViewsForMacros}
            exchangeProgressViews={displaySettings.mealProgressViewsForExchanges}
          />
        </div>
      </div>
    </div>
  );
};

export default MealPlanDisplaySettingsExchangeWithMobileExportForm;

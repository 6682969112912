import { useCallback, useState } from "react";

interface ErrantServingAmountProps {
  amount: number;
}

export const isErrantServingAmount = ({ amount }: ErrantServingAmountProps) => {
  return amount <= 0;
};

export type ExpandedData = {
  isExpanded: boolean;
  ingredientsCount: number | null;
};

interface useServingIdToExpandedPayload {
  servingIdToExpanded: (servingId: string) => ExpandedData;
  onChangeExpanded: (servingId: string, expanded: boolean) => void;
  onSetIngredientsCount: (servingId: string, count: number) => void;
}

export const useServingIdToExpanded = (): useServingIdToExpandedPayload => {
  const [idsToExpandedMap, setIdsToExpandedMap] = useState<{
    [servingId: string]: ExpandedData | undefined;
  }>({});

  const servingIdToExpanded = useCallback(
    (servingId: string): ExpandedData => {
      return (
        idsToExpandedMap[servingId] ?? {
          isExpanded: false,
          ingredientsCount: null,
        }
      );
    },
    [idsToExpandedMap]
  );

  const onChangeExpanded = useCallback((servingId: string, isExpanded: boolean) => {
    setIdsToExpandedMap(idsToExpandedMap => {
      const existing = idsToExpandedMap[servingId];
      if (!existing) {
        return {
          ...idsToExpandedMap,
          [servingId]: { isExpanded, ingredientsCount: null },
        };
      } else {
        return {
          ...idsToExpandedMap,
          [servingId]: { ingredientsCount: existing.ingredientsCount, isExpanded },
        };
      }
    });
  }, []);

  const onSetIngredientsCount = useCallback((servingId: string, ingredientsCount: number) => {
    setIdsToExpandedMap(idsToExpandedMap => {
      const existing = idsToExpandedMap[servingId];
      if (existing) {
        return {
          ...idsToExpandedMap,
          [servingId]: { ingredientsCount, isExpanded: existing.isExpanded },
        };
      }
      return idsToExpandedMap;
    });
  }, []);

  return {
    servingIdToExpanded,
    onChangeExpanded,
    onSetIngredientsCount,
  };
};

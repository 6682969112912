import { PreferenceFoodFunction, PreferenceFoodInfo } from "../../contexts/utils";
import { useAthleteFoodPreferenceContext } from "../../contexts/AthleteFoodPreferences";
import { useMealPlanFoodPreferenceContext } from "../../contexts/MealPlanFoodPreferences";
import { MenuItemPreviewFragment, FoodOrRecipePreferencesFragment } from "../../types";

export type FoodHighlightType = "Red" | "Green" | null;

interface FoodHighlightInfo {
  highlightType: FoodHighlightType;
  reason: string[];
}

interface isGreenArgs {
  foodOrRecipe: FoodOrRecipePreferencesFragment;
  isLikedFood: PreferenceFoodFunction;
  isPromotedFood: PreferenceFoodFunction;
}

interface isRedArgs {
  foodOrRecipe: FoodOrRecipePreferencesFragment;
  isDislikedFood: PreferenceFoodFunction;
  isAvoidedFood: PreferenceFoodFunction;
}

interface hasHighlightArgs {
  foodOrRecipe: FoodOrRecipePreferencesFragment;
  checkFunctions: PreferenceFoodFunction[];
}

const hasHighlight = ({ foodOrRecipe, checkFunctions }: hasHighlightArgs) => {
  const mappedFoods =
    foodOrRecipe.__typename === "Recipe"
      ? foodOrRecipe.descendantFoods.flatMap(food => checkFunctions.map(fn => fn(food.id)))
      : checkFunctions.map(fn => fn(foodOrRecipe.id));
  const filteredFoods = mappedFoods.filter(({ value }) => value);
  const combinedReasons: string[] = [
    ...filteredFoods.reduce((acc, { reason }) => {
      return new Set<string>([...acc, ...reason]);
    }, new Set<string>()),
  ].sort();

  return {
    value: combinedReasons.length > 0,
    reason: combinedReasons,
  };
};

const hasHighlightGreen = ({ foodOrRecipe, isLikedFood, isPromotedFood }: isGreenArgs) => {
  return hasHighlight({
    foodOrRecipe,
    checkFunctions: [isLikedFood, isPromotedFood],
  });
};

const hasHighlightRed = ({ foodOrRecipe, isDislikedFood, isAvoidedFood }: isRedArgs) => {
  return hasHighlight({
    foodOrRecipe,
    checkFunctions: [isDislikedFood, isAvoidedFood],
  });
};

export const useAthleteHighlightFromServingAmount = (foodOrRecipe: FoodOrRecipePreferencesFragment): FoodHighlightInfo => {
  const { isLikedFood, isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isAvoidedFood, isPromotedFood } = useMealPlanFoodPreferenceContext();
  const { value: isRed, reason: redReason } = hasHighlightRed({
    foodOrRecipe,
    isAvoidedFood,
    isDislikedFood,
  });
  if (isRed) {
    return {
      highlightType: "Red",
      reason: redReason,
    };
  }
  const { value: isGreen, reason: greenReason } = hasHighlightGreen({
    foodOrRecipe,
    isLikedFood,
    isPromotedFood,
  });
  return isGreen
    ? {
        highlightType: "Green",
        reason: greenReason,
      }
    : {
        highlightType: null,
        reason: [],
      };
};

export interface PreferenceServingAmount {
  serving: { foodOrRecipe: FoodOrRecipePreferencesFragment };
}

export const useAthleteHighlightFromServingAmounts = (servingAmounts: PreferenceServingAmount[]): FoodHighlightInfo => {
  const { isLikedFood, isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isAvoidedFood, isPromotedFood } = useMealPlanFoodPreferenceContext();
  const isRedInit: { isRed: boolean; redReason: string[] } = {
    isRed: false,
    redReason: [],
  };

  const { isRed, redReason } = servingAmounts
    .map((servingAmount: PreferenceServingAmount): PreferenceFoodInfo => {
      const { foodOrRecipe } = servingAmount.serving;
      return hasHighlightRed({ foodOrRecipe, isAvoidedFood, isDislikedFood });
    })
    .reduce(
      (acc: { isRed: boolean; redReason: string[] }, { value, reason }: PreferenceFoodInfo) => ({
        isRed: acc.isRed || value,
        redReason: [...new Set([...acc.redReason, ...reason])],
      }),
      isRedInit
    );

  if (isRed) {
    return {
      highlightType: "Red",
      reason: redReason,
    };
  }

  const isGreenInit: { isGreen: boolean; greenReason: string[] } = {
    isGreen: false,
    greenReason: [],
  };

  const { isGreen, greenReason } = servingAmounts
    .map(servingAmount => {
      const { foodOrRecipe } = servingAmount.serving;
      return hasHighlightGreen({ foodOrRecipe, isLikedFood, isPromotedFood });
    })
    .reduce(
      (acc: { isGreen: boolean; greenReason: string[] }, { value, reason }: PreferenceFoodInfo) => ({
        isGreen: acc.isGreen || value,
        greenReason: [...new Set([...acc.greenReason, ...reason])],
      }),
      isGreenInit
    );

  return isGreen
    ? {
        highlightType: "Green",
        reason: greenReason,
      }
    : {
        highlightType: null,
        reason: [],
      };
};

export const useAthleteHighlightFromMenuItemPreview = (menuItem: MenuItemPreviewFragment): FoodHighlightInfo => {
  const { servingAmounts } = menuItem;
  return useAthleteHighlightFromServingAmounts([...servingAmounts]);
};

export const useAvoidFoodGroups = () => {
  const { dislikedFoodGroups } = useAthleteFoodPreferenceContext();
  const { avoidedFoodGroups } = useMealPlanFoodPreferenceContext();
  return [...dislikedFoodGroups, ...avoidedFoodGroups];
};

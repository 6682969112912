import { ExchangeProgressViews, MacroProgressViews } from "../../types";

export const macroProgressViewsTypeToName = (type: MacroProgressViews): string => {
  switch (type) {
    case "all":
      return "Show all progress views";
    case "hideNumbers":
      return "Hide gram values";
    case "none":
      return "Hide all progress views";
    default:
      return "";
  }
};

export const exchangeProgressViewsTypeToName = (type: ExchangeProgressViews): string => {
  switch (type) {
    case "all":
      return "Show exchange targets";
    case "none":
      return "Hide exchange targets";
    default:
      return "";
  }
};

import { z } from "zod";

export const ViewAttendanceFormSchema = z
  .object({
    startDate: z.date({ required_error: "Start date is required", invalid_type_error: "Start date is required" }),
    endDate: z.date({ required_error: "End date is required", invalid_type_error: "End date is required" }),
    dateRangeError: z.undefined(),
  })
  .superRefine((dates, ctx) => {
    const { startDate, endDate } = dates;
    if (startDate > endDate) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["dateRangeError"], message: "End date cannot be before start date" });
    }

    if (Math.abs(startDate.getTime() - endDate.getTime()) > 1000 * 60 * 60 * 24 * 7) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["dateRangeError"], message: "Date range cannot be more than 7 days" });
    }
  });

export type ViewAttendanceFormType = z.infer<typeof ViewAttendanceFormSchema>;

import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useParentOrgGroupOptionsQuery } from "../../types";
import { OrgGroupFormType } from "./OrgGroupFormSchema";
import { OrgTransferList } from "./OrgTransferList";

interface OrgGroupFormProps {
  form: UseFormReturn<OrgGroupFormType>;
}

export const OrgGroupForm = ({ form }: OrgGroupFormProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  const { data: parentOrgGroupOptionsData } = useParentOrgGroupOptionsQuery();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Controller
        control={control}
        name={"name"}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            required
            autoFocus={!field.value}
            label="Organization Group Name"
          />
        )}
      />
      <Controller
        control={control}
        name={"parentOrgGroupId"}
        render={({ field: { ref, ...field } }) => (
          <Box sx={{ display: "flex", gap: 6 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="parent-org-group">Parent Org Group</InputLabel>
              <Select
                labelId="parent-org-group"
                label="Parent Org Group"
                {...field}>
                {(parentOrgGroupOptionsData?.orgGroups ?? []).map(({ name, id }) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ width: "100%" }} />
          </Box>
        )}
      />
      <Controller
        control={control}
        name={"orgIds"}
        render={({ field: { onChange, value } }) => <OrgTransferList selectedOrgIds={value} onChange={ids => onChange(ids)} />}
      />
    </Box>
  );
};

import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import { Alert, AlertTitle, Typography } from "@mui/material";
import React from "react";

const RecipeScaleSuccessAlert = ({ open, onClose }: { open: boolean; onClose: () => void }) =>
  open ? (
    <Alert
      severity="success"
      onClose={onClose}
      icon={<CheckCircleIcon />}>
      <AlertTitle>Success</AlertTitle>
      <Typography variant="body2">
        Recipe has been scaled. Please note that you still have to edit the prep time, cook time, and instructions if needed.
      </Typography>
    </Alert>
  ) : null;

export default RecipeScaleSuccessAlert;

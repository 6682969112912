import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import { parseDateTime, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { sortFnByKey } from "@notemeal/utils/sort";
import React, { useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { TeamAthleteNoteFragment, useTeamAthletesNotesQuery } from "../../../../types";
import { getMuiTablePaginationProps, getRowsForTablePage, useOffsetPagination } from "../../../../utils/pagination";

interface RowData {
  athlete: string;
  date: string;
  type: string;
  content: string;
}

const noteToRow = ({ datetime, type, content, athlete: { lastName, firstName } }: TeamAthleteNoteFragment): RowData => ({
  athlete: `${lastName}, ${firstName}`,
  date: serializeDate(parseDateTime(datetime)),
  type: type?.name ?? "",
  content,
});

export const TeamNotesPage = () => {
  const { teamId: maybeTeamId } = useParams();
  const teamId = maybeTeamId || "";

  const { formatDateWithLocale } = useDateFormatting();
  const { data, loading } = useTeamAthletesNotesQuery({
    variables: {
      noteTypeId: null,
      teamId,
    },
    partialRefetch: true,
  });

  const paginationHooks = useOffsetPagination(5);
  const { limit, page } = paginationHooks;

  const [searchText, setSearchText] = useState("");
  const [sortOrderAscending, setSortOrderAscending] = useState(true);
  const [sortBy, setSortBy] = useState<keyof RowData>("athlete");

  const getRows = () =>
    data?.notesForTeam
      .map(noteToRow)
      .sort(sortFnByKey(sortBy, { reverse: !sortOrderAscending }))
      .filter(filterRow) || [];

  const getCsvData = () =>
    ['"Athlete","Date","Type","Content"']
      .concat(getRows().map(row => `"${row.athlete}","${row.date}","${row.type}","${row.content}"`) || [])
      .join("\n");

  const handleExport = () => {
    const blob = new Blob([getCsvData()], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.download = "Notes.csv";
    a.href = url;
    a.textContent = "Download CSV";
    a.click();
  };

  const filterRow = (row: RowData) =>
    row.athlete.toLowerCase().indexOf(searchText) !== -1 ||
    row.content.toLowerCase().indexOf(searchText) !== -1 ||
    row.date.toLowerCase().indexOf(searchText) !== -1 ||
    row.type.toLowerCase().indexOf(searchText) !== -1;

  const getSortOrder = () => (sortOrderAscending ? "asc" : "desc");
  const toggleSortOrder = () => setSortOrderAscending(!sortOrderAscending);

  if (!data || loading) {
    return <Loading progressSize="lg" />;
  }

  const rows = getRows();
  const pageRows = getRowsForTablePage({ rows, limit, page });

  return (
    <>
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end", justifyContent: "flex-end" }}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <Button onClick={handleExport}>Export</Button>
      </Box>
      <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  direction={getSortOrder()}
                  onClick={() => {
                    setSortBy("athlete");
                    toggleSortOrder();
                  }}
                >
                  Athlete
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={getSortOrder()}
                  onClick={() => {
                    setSortBy("date");
                    toggleSortOrder();
                  }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={getSortOrder()}
                  onClick={() => {
                    setSortBy("type");
                    toggleSortOrder();
                  }}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  direction={getSortOrder()}
                  onClick={() => {
                    setSortBy("content");
                    toggleSortOrder();
                  }}
                >
                  Content
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageRows.map(note => {
              const { athlete, date, type, content } = note;
              return (
                <TableRow>
                  <TableCell>{athlete}</TableCell>
                  <TableCell>{formatDateWithLocale(date)}</TableCell>
                  <TableCell>{type}</TableCell>
                  <TableCell>{content}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
        {...getMuiTablePaginationProps(paginationHooks, rows.length)}
      />
    </>
  );
};

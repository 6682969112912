import { Box, Divider, Switch, Typography, styled } from "@mui/material";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { OrgGroupFormType } from "./OrgGroupFormSchema";

interface OrgGroupPermissionFormProps {
  form: UseFormReturn<OrgGroupFormType>;
}

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

const SwitchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}));

const LabelSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const OrgGroupPermissionForm = ({ form }: OrgGroupPermissionFormProps) => {
  const { control } = form;

  return (
    <Container>
      <Divider />
      <Controller
        control={control}
        name={"allowsResourceSharing"}
        render={({ field: { value, ...field } }) => (
          <SwitchContainer>
            <Box>
              <Label>Resources</Label>
              <LabelSubtitle variant="body2">Enable admins to publish resources to this org group</LabelSubtitle>
            </Box>
            <Switch {...field} checked={value} />
          </SwitchContainer>
        )}
      />
      <Divider />
      <Controller
        control={control}
        name={"allowsAthleteTransfer"}
        render={({ field: { value, ...field } }) => (
          <SwitchContainer>
            <Box>
              <Label>Transfer Portal</Label>
              <LabelSubtitle variant="body2">
                Enable admins within this org group to release and receive athlete users from the transfer portal
              </LabelSubtitle>
            </Box>
            <Switch {...field} checked={value} />
          </SwitchContainer>
        )}
      />
      <Divider />
    </Container>
  );
};

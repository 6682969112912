import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, TextField } from "@mui/material";
import { useAthleteFoodPreferenceContext } from "@notemeal/shared/ui/contexts/AthleteFoodPreferences";
import { useMealPlanFoodPreferenceContext } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import useGenerateMealOptions, { MealOptionInput, MealOptionsForMealTemplate } from "./useGenerateMealOptions";

export interface GeneratedMealOptions {
  removeExistingMealOptions: boolean;
  mealOptionsForMealTemplates: MealOptionsForMealTemplate[];
}

interface GenerateMealOptionsDialogProps {
  open: boolean;
  onClose: () => void;
  mealOptionInputs: MealOptionInput[];
  onCompleted: (generatedMealOptions: GeneratedMealOptions) => void;
}

const GenerateMealOptionsDialog = ({ open, onClose, mealOptionInputs, onCompleted }: GenerateMealOptionsDialogProps) => {
  const [optionsPerMealInput, setOptionsPerMealInput] = useState("3");
  const optionsPerMeal = inputToNumber(optionsPerMealInput);
  const [removeExistingMealOptions, setRemoveExistingMealOptions] = useState(false);
  const { dislikedFoods, dislikedFoodGroups } = useAthleteFoodPreferenceContext();
  const { avoidedFoodGroups, avoidedFoods } = useMealPlanFoodPreferenceContext();
  const onDialogCompleted = (mealOptionsForMealTemplates: MealOptionsForMealTemplate[]) =>
    onCompleted({ removeExistingMealOptions, mealOptionsForMealTemplates });
  const { isGeneratingMealOptions, generateMealOptions } = useGenerateMealOptions({
    mealOptionInputs,
    optionsPerMeal: optionsPerMeal || 3,
    avoidFoodIds: [...dislikedFoods.map(f => f.id), ...avoidedFoods.map(f => f.id)],
    avoidFoodGroupIds: [...dislikedFoodGroups.map(fg => fg.id), ...avoidedFoodGroups.map(fg => fg.id)],
    onCompleted: onDialogCompleted,
  });
  const optionsPerMealInputError = optionsPerMeal === null || optionsPerMeal <= 0 || optionsPerMeal > 6;
  const errorText = optionsPerMealInputError ? "Must be between 1 and 6" : "";

  const handleClickGenerate = async () => {
    if (optionsPerMealInputError) {
      return;
    } else {
      generateMealOptions();
    }
  };

  if (isGeneratingMealOptions) {
    return <LoadingBackdrop open={isGeneratingMealOptions} onClose={onClose} />;
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle title="Generate Meal Options" onClose={onClose} />
      <DialogContent sx={{ width: "100%" }}>
        <TextField
          label="Options per meal"
          value={optionsPerMealInput}
          inputMode="numeric"
          type="number"
          error={optionsPerMealInputError}
          helperText={errorText}
          InputProps={{ inputProps: { min: 1, max: 6 } }}
          onChange={e => setOptionsPerMealInput(e.target.value)}
          sx={{ width: "180px" }}
        />
        <FormControlLabel
          control={<Checkbox checked={removeExistingMealOptions} onChange={(_, checked) => setRemoveExistingMealOptions(checked)} />}
          label="Clear Existing Meal Options"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleClickGenerate} disabled={optionsPerMealInputError}>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateMealOptionsDialog;

import { useApolloClient } from "@apollo/client";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Box, Button, IconButton, Table, TableBody, TableContainer, TableHead, TablePagination, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import {
  GoalTrackerGoalsForExportDocument,
  GoalTrackerGoalsForExportQuery,
  GoalTrackerGoalsForExportQueryVariables,
  useAthleteGoalConfigurationCompliancePageQuery,
  useGoalTrackerGoalOffsetConnectionQuery,
} from "apps/web/src/types";
import { getMuiTablePaginationProps, useOffsetPagination } from "apps/web/src/utils/pagination";
import { AthleteGoalComplianceHeaderRow } from "apps/web/src/views/Athlete/GoalTracker/GoalCompliance/AthleteGoalComplianceHeaderRow";
import { AthleteGoalComplianceRow } from "apps/web/src/views/Athlete/GoalTracker/GoalCompliance/AthleteGoalComplianceRow";
import { GoalConfigurationHeaderSummary } from "apps/web/src/views/Athlete/GoalTracker/GoalCompliance/GoalConfigurationHeaderSummary";
import {
  SerializedExportRow,
  exportAthleteGoalColumns,
  serializeAthleteGoalRows,
} from "apps/web/src/views/Athlete/GoalTracker/GoalCompliance/utils";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom-v5-compat";

export const AthleteGoalCompliancePage = () => {
  const { goalConfigurationId: maybeGoalConfigurationId } = useParams();
  const goalConfigurationId = maybeGoalConfigurationId || "";
  const history = useHistory();
  const today = new Date();

  const { data: goalSummaryData, loading: goalSummaryLoading } = useAthleteGoalConfigurationCompliancePageQuery({
    variables: { goalConfigurationId, startDate: null, endDate: serializeDate(today) },
  });
  const paginationHooks = useOffsetPagination();
  const { limit, offset } = paginationHooks;
  const { data, loading } = useGoalTrackerGoalOffsetConnectionQuery({
    variables: { goalConfigurationId, input: { limit, offset } },
  });
  const apolloClient = useApolloClient();

  const { formatDateWithLocale, formatTimeInTimezoneWithLocale } = useDateFormatting();

  const handleExport = async () => {
    if (!data || !goalSummaryData) {
      return;
    }
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    worksheet.name = "Athlete Goal Compliance";
    worksheet.getRow(1).alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.columns = exportAthleteGoalColumns;
    let rows: SerializedExportRow[];
    if (data && data.goalTrackerGoalOffsetConnection.pageInfo.total === data.goalTrackerGoalOffsetConnection.edges.length) {
      rows = serializeAthleteGoalRows(
        [...data.goalTrackerGoalOffsetConnection.edges],
        goalSummaryData.goalConfiguration,
        formatDateWithLocale,
        formatTimeInTimezoneWithLocale
      );
    } else {
      const { data } = await apolloClient.query<GoalTrackerGoalsForExportQuery, GoalTrackerGoalsForExportQueryVariables>({
        query: GoalTrackerGoalsForExportDocument,
        variables: { goalConfigurationId },
      });
      rows = serializeAthleteGoalRows(
        [...data.goalTrackerGoals],
        goalSummaryData.goalConfiguration,
        formatDateWithLocale,
        formatTimeInTimezoneWithLocale
      );
    }
    worksheet.addRows(rows);
    const toExport = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([toExport]), `${worksheet.name}.xlsx`);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "80vh" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h3">View Compliance</Typography>
        </Box>
        <Button onClick={handleExport} sx={{ alignSelf: "flex-end" }}>
          Export
        </Button>
      </Box>
      {!goalSummaryData || goalSummaryLoading ? (
        <Loading />
      ) : (
        <GoalConfigurationHeaderSummary goalConfiguration={goalSummaryData.goalConfiguration} />
      )}
      {goalSummaryData ? (
        <Box sx={{ mx: 6, display: "flex", flexDirection: "column", gap: 2 }}>
          <TableContainer sx={{ display: "flex", flexDirection: "column", height: "50vh" }}>
            <Table size={"small"}>
              <TableHead>{<AthleteGoalComplianceHeaderRow goalConfiguration={goalSummaryData.goalConfiguration} />}</TableHead>
              <TableBody>
                {data?.goalTrackerGoalOffsetConnection.edges.map(edge => (
                  <AthleteGoalComplianceRow row={edge} goalConfiguration={goalSummaryData.goalConfiguration} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {loading && (
            <Box
              sx={{
                zIndex: 1,
                minHeight: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading progressSize="md" />
            </Box>
          )}
          <TablePagination
            component="div"
            sx={{ ".MuiTablePagination-actions": { mr: 8 }, flex: 1 }}
            {...getMuiTablePaginationProps(paginationHooks, data?.goalTrackerGoalOffsetConnection.pageInfo.total ?? 0)}
          />
        </Box>
      ) : null}
    </Box>
  );
};

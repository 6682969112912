import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";

import ServingAmountsSmallChipList from "../SmallChipList";

import { RecipeWithServingsFragment } from "../../../../types";

interface MealPlanExportAppendixRecipeProps {
  recipe: RecipeWithServingsFragment;
}

const MealPlanExportAppendixRecipe = ({ recipe }: MealPlanExportAppendixRecipeProps) => {
  // Pretend all ingredients are just foods and not recipes so recipes don't need to have their own ingredients on them
  const ingredients = recipe.ingredients.map(({ serving: { foodOrRecipe, ...serving }, ...ingredient }) => ({
    ...ingredient,
    serving: {
      ...serving,
      foodOrRecipe: {
        ...foodOrRecipe,
        choPer100g: 0,
        proPer100g: 0,
        fatPer100g: 0,
        kcalPer100g: 0,
        exchangeTypes: [],
        isRecipeIngredientOnly: false,
        usdaFdcDescription: null,
        usdaFdcId: null,
        nixItemId: null,
        nixTagId: null,
        usdaFdcDataType: null,
        usdaFdcFoodCategoryId: null,
        exchangeAmountRatios: [],
        __typename: "GenericFood" as "GenericFood",
        thumbnailUrl: "",
        hasFullAccess: true,
        org: null,
      },
      customUnits: "",
      unitPrefix: null,
      unitSuffix: null,
      unit: null,
    },
  }));

  const stepsComponent =
    recipe.steps && recipe.steps.length ? (
      <Fragment>
        <Typography variant="body2Medium">Directions</Typography>
        {recipe.steps.map((step, index) => (
          <Typography key={`${recipe.id}${index}`} variant="subtitle1">
            {`${index + 1}. ${step}`}
          </Typography>
        ))}
      </Fragment>
    ) : undefined;

  const noteComponent = recipe.note && (
    <Typography variant="subtitle1">
      <strong>*</strong>
      {recipe.note}
    </Typography>
  );

  return (
    <Box sx={{ p: 1, pb: 0, display: "grid", gridTemplateColumns: "30% 3% 64.5%", gridTemplateRows: "auto" }}>
      <Box sx={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
        <Typography variant="h4">{recipe.name}</Typography>
      </Box>
      <Box sx={{ gridColumnStart: 1, gridColumnEnd: 2 }}>
        <Typography variant="body2Medium">Ingredients</Typography>
        <ServingAmountsSmallChipList
          expandRecipes={false}
          servingAmounts={ingredients}
          recipes={[recipe]} />
      </Box>
      <Box sx={{ gridColumnStart: 3, gridColumnEnd: 4, fontSize: "14px", display: "flex", flexDirection: "column", ml: 2 }}>
        {stepsComponent}
      </Box>
      <Box sx={{ gridColumnStart: 1, gridColumnEnd: 4, mt: 1 }}>{noteComponent}</Box>
    </Box>
  );
};

export default MealPlanExportAppendixRecipe;

import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";
import DialogTitle from "../../../componentLibrary/DialogTitle";
import { AthleteOrderStateAction, AthleteOrderStateSelectOrder } from "./orderStateReducer";

interface SelectNewOrEditDialogProps {
  athleteOrderState: AthleteOrderStateSelectOrder;
  athleteOrderStateDispatch: React.Dispatch<AthleteOrderStateAction>;
  open: boolean;
  onClose: () => void;
}

const SelectNewOrEditDialog = ({ athleteOrderState, athleteOrderStateDispatch, open, onClose }: SelectNewOrEditDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="xs"
    fullWidth>
    <DialogTitle title="Create or Edit Order" onClose={onClose} />
    <DialogContent>
      <Typography>A previous order can still be edited. Would you like to add to this order or create a new one?</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => athleteOrderStateDispatch({ type: "StartNewOrder" })} variant="outlined">
        Create New Order
      </Button>
      <Button
        onClick={() =>
          athleteOrderStateDispatch({
            type: "SwitchToEditOrderAction",
            payload: { menuOrderId: athleteOrderState.orderIdToEdit },
          })
        }
        variant="outlined"
      >
        Add to Previous Order
      </Button>
    </DialogActions>
  </Dialog>
);

export default SelectNewOrEditDialog;

import { Box, Theme, Typography, styled, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import { DigiDisplaysMealMenuFragment } from "apps/web/src/types";
import React, { ReactNode, useEffect, useState } from "react";
import { Banner } from "./Banner";
import { DiningStationContent } from "./DiningStationContent";
import { MenuItemWithFullServings, StyledAddOnIcon } from "./MenuItemCard";
import { DigitalDisplaysConfigState } from "./reducer";
import { hasAddOn, pxToNum, splitContentToSlides } from "./utils";

const IPAD_PORTRAIT = { width: "820px", height: "1180px" };
const IPAD_LANDSCAPE = { height: "820px", width: "1180px" };
const TV_PORTRAIT = { height: "1160px", width: "650px" };
const TV_LANDSCAPE = { width: "1160px", height: "650px" };

const BANNER_HEIGHT_NO_DETAILS = "56px";
const BANNER_HEIGHT_DETAILS = "122px";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    addOnBannerContent: {
      display: "flex",
      padding: "12px 16px",
      flexDirection: "row",
      alignItems: "flex-start",
      gap: spacing(1),
      margin: spacing(0, 3),
      backgroundColor: palette.greyscaleLight[100],
      borderRadius: "4px",
    },
    addOnText: {
      color: palette.highEmphasisText,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
    },
  })
);

const StyledBaseFrame = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
}));

const AddOnBanner = () => {
  const classes = useStyles();
  return (
    <Box className={classes.addOnBannerContent}>
      <StyledAddOnIcon />
      <Typography className={classes.addOnText}>
        Nutrition information for menu items with this symbol do not include the add-on nutrients
      </Typography>
    </Box>
  );
};

interface SlideProps {
  refs: React.MutableRefObject<HTMLElement[]>;
  index: number;
  height: string;
  width: string;
  banner: ReactNode;
  hasAddOnItem: boolean;
  children: ReactNode;
}

const Slide = ({ refs, index, height, width, banner, hasAddOnItem, children }: SlideProps) => {
  const theme = useTheme();
  return (
    <StyledBaseFrame
      ref={el => el && (refs.current[index] = el)}
      key={index}
      sx={{
        maxHeight: height,
        minHeight: height,
        width: width,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%">
        <Box>
          {banner}
          {children}
        </Box>
        {hasAddOnItem && (
          <Box marginBottom={theme.spacing(3)}>
            <AddOnBanner />
          </Box>
        )}
      </Box>
    </StyledBaseFrame>
  );
};

const hasAddOnItems = (items: MenuItemWithFullServings[]) => {
  return items.filter(item => hasAddOn(item)).length > 0;
};

interface DisplayContentProps {
  mealMenu: DigiDisplaysMealMenuFragment;
  state: DigitalDisplaysConfigState;
  refs: React.MutableRefObject<HTMLElement[]>;
}

// all of the "splitting" logic for this component is in splitContentToSlides().
// the rendered components are agnostic to what "fits" on a slide.
//
// NOTE: widths of menu item cards are auto-scaled. height is not. adjust
// splitContentToSlides() as necessary when adding components.
export const DisplayContent = ({ refs, mealMenu, state }: DisplayContentProps) => {
  const clientTimezone = useClientTimezone();
  const { formatDateInTimezoneWithLocale } = useDateFormatting();
  const [heightWidth, setHeightWidth] = useState(IPAD_PORTRAIT);

  const menuName = mealMenu.name;
  const mealMenuDate = formatDateInTimezoneWithLocale(mealMenu.start, clientTimezone, {
    variant: "long",
    format: "MMMM d, yyyy",
  });
  const mealMenuDuration = formatTimeRangeInTimezone(mealMenu.start, mealMenu.durationInMinutes, clientTimezone, {
    excludeTimezoneSuffix: true,
  });

  const diningStations = mealMenu.mealMenuDiningStations;
  const checkedDiningStationNames = state.diningStations.filter(ds => ds.isChecked).map(ds => ds.diningStationName);
  const checkedDiningStations = diningStations.filter(ds => checkedDiningStationNames.find(ds2 => ds.name === ds2));

  useEffect(() => {
    if (state.device === "TV" && state.orientation === "Landscape") {
      setHeightWidth(TV_LANDSCAPE);
    } else if (state.device === "TV" && state.orientation === "Portrait") {
      setHeightWidth(TV_PORTRAIT);
    } else if (state.device === "iPad" && state.orientation === "Landscape") {
      setHeightWidth(IPAD_LANDSCAPE);
    } else if (state.device === "iPad" && state.orientation === "Portrait") {
      setHeightWidth(IPAD_PORTRAIT);
    }
  }, [state]);

  const banner = (
    <Banner
      menuName={menuName}
      date={mealMenuDate}
      duration={mealMenuDuration}
      includeDetails={state.banner === "Include meal details"} />
  );
  const bannerHeight = state.banner === "Include meal details" ? BANNER_HEIGHT_DETAILS : BANNER_HEIGHT_NO_DETAILS;
  const whitespacePerSlide = pxToNum(heightWidth.height) - pxToNum(bannerHeight) - 16; // add margin 16px for bottom

  /* split up dining station content for slides to be displayed
   *
   * "full menu view" - allow multiple dining stations to be on same slide.
   * "dining station view" - only a single dining station may be on a slide.
   */
  const getSlides = () => {
    if (state.layout === "Full menu view") {
      const dsContent = splitContentToSlides(state, checkedDiningStations, whitespacePerSlide);
      return dsContent.map(slide => {
        var hasAddOnItem = false;
        const content = slide.map(diningStation => {
          const menuItems = diningStation.menuItemAppearances.map(mia => mia.menuItem);
          hasAddOnItem = hasAddOnItem || hasAddOnItems(menuItems);
          return <DiningStationContent
            diningStation={diningStation}
            menuItems={menuItems}
            state={state} />;
        });
        return { content, hasAddOnItem };
      });
    } else {
      // split items from each dining station into their own slide set
      return checkedDiningStations.flatMap(diningStation => {
        const dsContent = splitContentToSlides(state, [diningStation], whitespacePerSlide);
        return dsContent.map(slide => {
          var hasAddOnItem = false;
          const content = slide.map(ds => {
            const menuItems = ds.menuItemAppearances.map(mia => mia.menuItem);
            hasAddOnItem = hasAddOnItem || hasAddOnItems(menuItems);
            return <DiningStationContent
              diningStation={ds}
              menuItems={menuItems}
              state={state} />;
          });
          return { content, hasAddOnItem };
        });
      });
    }
  };

  const slideContent = getSlides();
  useEffect(() => {
    refs.current = refs.current.slice(0, slideContent.length);
  }, [refs, slideContent]);

  return slideContent.map(({ content, hasAddOnItem }, i) => {
    return (
      <Slide
        refs={refs}
        index={i}
        height={heightWidth.height}
        width={heightWidth.width}
        banner={banner}
        hasAddOnItem={state.macros && hasAddOnItem}
        children={content}
      />
    );
  });
};

import { Box, Checkbox, FormControlLabel, FormGroup, Switch, SxProps, Typography } from "@mui/material";
import { MacroMealPlanLegacyDisplaySettings } from "@notemeal/shared/ui/DisplaySettings/utils";
import React from "react";
import { FormRow } from "./utils";

interface MealPlanDisplaySettingsMacroFormProps {
  displaySettings: MacroMealPlanLegacyDisplaySettings;
  onChange: (displaySettings: MacroMealPlanLegacyDisplaySettings) => void;
  sx?: SxProps;
}

const MealPlanDisplaySettingsMacroForm = ({ displaySettings, sx, onChange }: MealPlanDisplaySettingsMacroFormProps) => {
  const handleChange = (key: keyof MacroMealPlanLegacyDisplaySettings, value: boolean) => {
    onChange({
      ...displaySettings,
      [key]: value,
    });
  };

  return (
    <FormGroup sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", ...sx }} row>
      <Typography variant="body1Medium">Macros</Typography>

      <FormRow sx={{ justifyContent: "flex-start" }}>
        <FormControlLabel
          label="Daily"
          control={
            <Checkbox checked={displaySettings.displayDailyMacros} onChange={e => handleChange("displayDailyMacros", e.target.checked)} />
          }
        />
        <FormControlLabel
          label="Meals"
          control={
            <Checkbox checked={displaySettings.displayMealMacros} onChange={e => handleChange("displayMealMacros", e.target.checked)} />
          }
        />
      </FormRow>
      <Box sx={{ display: "flex", flexDirection: "column", ml: 0.5 }}>
        <FormControlLabel
          control={
            <Switch
              sx={{ ml: 0.5, mr: 1 }}
              size="small"
              checked={displaySettings.displayMealOptions}
              onChange={e => handleChange("displayMealOptions", e.target.checked)}
            />
          }
          label={"Meal Options"}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Switch
              sx={{ ml: 0.5, mr: 1 }}
              size="small"
              checked={displaySettings.displayMealOptionMacros}
              onChange={e => handleChange("displayMealOptionMacros", e.target.checked)}
            />
          }
          disabled={!displaySettings.displayMealOptions}
          label="Meal Option Macros"
          labelPlacement="end"
          sx={{ pt: 1 }}
        />
      </Box>
    </FormGroup>
  );
};

export default MealPlanDisplaySettingsMacroForm;

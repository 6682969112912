import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { round } from "@notemeal/shared/ui/utils/numbers";
import { sortByKey } from "@notemeal/utils/sort";
import { format } from "date-fns";
import { Cell } from "exceljs";
import { abs, mean, std } from "mathjs";
import { AnthropometryEntryType, TeamAnthropometryQuery } from "../../../types";
import { roundToHundredthsFloor } from "@notemeal/shared/utils/macro-protocol";

export interface AthleteAnthropometryRow {
  id: string;
  anthropometryEntryId: string | null;
  bodyFatMass: number | null;
  bodyFatMassChange: number | null;
  leanBodyMass: number | null;
  leanBodyMassChange: number | null;
  percentBodyFat: number | null;
  percentBodyFatChange: number | null;
  weight: number | null;
  weightChange: number | null;
  height: number | null;
  BMI: number | null;
  dryLeanMass: number | null;
  dryLeanMassChange: number | null;
  trunkFat: number | null;
  trunkFatChange: number | null;
  visceralFatArea: number | null;
  visceralFatAreaChange: number | null;
  skeletalMuscleMass: number | null;
  skeletalMuscleMassChange: number | null;
  datetime: string | null;
  comment: string | null;
  boneMineralDensityZScore: number | null;
  boneMineralContent: number | null;
  type: AnthropometryEntryType;

  parentAthleteId?: string;
}

export interface AthleteAnthropometryParentRow extends AthleteAnthropometryRow {
  athleteId?: string;
  name?: string;
  position?: string;
  firstName?: string;
  lastName?: string;
}

export interface AthleteAnthropometryData {
  anthropometryEntries: AthleteAnthropometryParentRow[];
  mostRecentAnthropometryEntry?: AthleteAnthropometryRow;
}

export const emptyAnthropometryEntry = {
  leanBodyMassChange: null,
  leanBodyMass: null,
  percentBodyFatChange: null,
  percentBodyFat: null,
  comment: null,
  bodyFatMass: null,
  bodyFatMassChange: null,
  weightChange: null,
  weight: null,
  height: null,
  BMI: null,
  dryLeanMass: null,
  dryLeanMassChange: null,
  trunkFat: null,
  trunkFatChange: null,
  visceralFatArea: null,
  visceralFatAreaChange: null,
  skeletalMuscleMass: null,
  skeletalMuscleMassChange: null,
  datetime: null,
  boneMineralDensityZScore: null,
  boneMineralContent: null,
  anthropometryEntryId: null,
  type: null,
  heightInCm: null,
  weightInKg: null,
  leanBodyMassInKg: null,
  bodyFatMassInKg: null,
  skeletalMuscleMassInKg: null,
  trunkFatInKg: null,
  dryLeanMassInKg: null,
};

export type TeamPageAthletes = TeamAnthropometryQuery["team"]["athletes"];

export const flattenAthleteAnthropometry = (
  athletes: TeamPageAthletes,
  selectedAnthropometryTypes: AnthropometryEntryType[],
  isMetricLocale: boolean
): AthleteAnthropometryData[] =>
  isMetricLocale
    ? flattenMetricAnthropometry(athletes, selectedAnthropometryTypes)
    : flattenImperialAnthropometry(athletes, selectedAnthropometryTypes);

const flattenImperialAnthropometry = (
  athletes: TeamPageAthletes,
  selectedAnthropometryTypes: AnthropometryEntryType[]
): AthleteAnthropometryData[] => {
  return athletes
    .map(ath => ({
      ...ath,
      anthropometryEntries: ath.anthropometryEntries.filter(ae => selectedAnthropometryTypes.includes(ae.type)),
    }))
    .map(({ id, anthropometryEntries }) => {
      const sortedAnthroEntries = sortByKey(
        anthropometryEntries.map(ae => ({
          ...ae,
          dt: ae.datetime ? new Date(ae.datetime) : null,
        })),
        "dt"
      );
      const anthroEntriesWithChange = sortedAnthroEntries.map((elem, idx) => {
        const prevElem = idx === 0 ? emptyAnthropometryEntry : sortedAnthroEntries[idx - 1];
        const {
          weight: oldWeight,
          bodyFatMass: oldBodyFatMass,
          leanBodyMass: oldLeanBodyMass,
          skeletalMuscleMass: oldSkeletalMuscleMass,
          visceralFatArea: oldVisceralFatArea,
          trunkFat: oldTrunkFat,
          dryLeanMass: oldDryLeanMass,
        } = prevElem;
        const { weight, height, leanBodyMass, bodyFatMass, skeletalMuscleMass, visceralFatArea, trunkFat, dryLeanMass } = elem;

        const BMI = height ? round((weight * 703) / height ** 2) : null;
        const leanBodyMassChange = leanBodyMass && oldLeanBodyMass ? round(leanBodyMass - oldLeanBodyMass) : null;
        const weightChange = weight && oldWeight ? round(weight - oldWeight) : null;
        const bodyFatMassChange = bodyFatMass && oldBodyFatMass ? round(bodyFatMass - oldBodyFatMass) : null;
        const percentBodyFatChange =
          !!elem.percentBodyFat && !!prevElem.percentBodyFat ? round(elem.percentBodyFat - prevElem.percentBodyFat) : null;
        const dryLeanMassChange = !!dryLeanMass && !!oldDryLeanMass ? round(dryLeanMass - oldDryLeanMass) : null;
        const visceralFatAreaChange = !!visceralFatArea && !!oldVisceralFatArea ? round(visceralFatArea - oldVisceralFatArea) : null;
        const trunkFatChange = !!trunkFat && !!oldTrunkFat ? round(trunkFat - oldTrunkFat) : null;
        const skeletalMuscleMassChange =
          !!skeletalMuscleMass && !!oldSkeletalMuscleMass ? round(skeletalMuscleMass - oldSkeletalMuscleMass) : null;

        return {
          athleteId: id + idx,
          ...roundAthleteAnthropometryRowDisplay({
            ...elem,
            id,
            datetime: format(new Date(elem.datetime), "MM/dd/yy"),
            weight,
            BMI,
            bodyFatMass,
            leanBodyMass,
            bodyFatMassChange,
            weightChange,
            leanBodyMassChange,
            percentBodyFatChange,
            skeletalMuscleMass,
            skeletalMuscleMassChange,
            visceralFatArea,
            visceralFatAreaChange,
            trunkFat,
            trunkFatChange,
            dryLeanMass,
            dryLeanMassChange,
            parentAthleteId: id,
            anthropometryEntryId: elem.id,
          }),
        };
      });
      // TODO: only return most recent 'numberEntries'
      return {
        anthropometryEntries: anthroEntriesWithChange,
        mostRecentAnthropometryEntry:
          anthroEntriesWithChange.length > 0 ? anthroEntriesWithChange[anthroEntriesWithChange.length - 1] : undefined,
      };
    });
};

const flattenMetricAnthropometry = (
  athletes: TeamPageAthletes,
  selectedAnthropometryTypes: AnthropometryEntryType[]
): AthleteAnthropometryData[] => {
  return athletes
    .map(ath => ({
      ...ath,
      anthropometryEntries: ath.anthropometryEntries.filter(ae => selectedAnthropometryTypes.includes(ae.type)),
    }))
    .map(({ id, anthropometryEntries }) => {
      const sortedAnthroEntries = sortByKey(
        anthropometryEntries.map(ae => ({
          ...ae,
          dt: ae.datetime ? new Date(ae.datetime) : null,
        })),
        "dt"
      );
      const anthroEntriesWithChange = sortedAnthroEntries.map((elem, idx) => {
        const prevElem = idx === 0 ? emptyAnthropometryEntry : sortedAnthroEntries[idx - 1];
        const {
          weightInKg: oldWeight,
          bodyFatMassInKg: oldBodyFatMass,
          leanBodyMassInKg: oldLeanBodyMass,
          skeletalMuscleMassInKg: oldSkeletalMuscleMass,
          visceralFatArea: oldVisceralFatArea,
          trunkFatInKg: oldTrunkFat,
          dryLeanMassInKg: oldDryLeanMass,
        } = prevElem;
        const {
          weightInKg: weight,
          heightInCm: height,
          bodyFatMassInKg: bodyFatMass,
          leanBodyMassInKg: leanBodyMass,
          skeletalMuscleMassInKg: skeletalMuscleMass,
          visceralFatArea,
          trunkFatInKg: trunkFat,
          dryLeanMassInKg: dryLeanMass,
        } = elem;

        const BMI = height ? round((weight * 703) / height ** 2) : null;
        const leanBodyMassChange = leanBodyMass && oldLeanBodyMass ? round(leanBodyMass - oldLeanBodyMass) : null;
        const weightChange = weight && oldWeight ? round(weight - oldWeight) : null;
        const bodyFatMassChange = bodyFatMass && oldBodyFatMass ? round(bodyFatMass - oldBodyFatMass) : null;
        const percentBodyFatChange =
          !!elem.percentBodyFat && !!prevElem.percentBodyFat ? round(elem.percentBodyFat - prevElem.percentBodyFat) : null;
        const dryLeanMassChange = !!dryLeanMass && !!oldDryLeanMass ? round(dryLeanMass - oldDryLeanMass) : null;
        const visceralFatAreaChange = !!visceralFatArea && !!oldVisceralFatArea ? round(visceralFatArea - oldVisceralFatArea) : null;
        const trunkFatChange = !!trunkFat && !!oldTrunkFat ? round(trunkFat - oldTrunkFat) : null;
        const skeletalMuscleMassChange =
          !!skeletalMuscleMass && !!oldSkeletalMuscleMass ? round(skeletalMuscleMass - oldSkeletalMuscleMass) : null;

        return {
          athleteId: id + idx,
          ...roundAthleteAnthropometryRowDisplay({
            ...elem,
            id,
            datetime: format(new Date(elem.datetime), "MM/dd/yy"),
            weight,
            BMI,
            bodyFatMass,
            leanBodyMass,
            bodyFatMassChange,
            weightChange,
            leanBodyMassChange,
            percentBodyFatChange,
            skeletalMuscleMass,
            skeletalMuscleMassChange,
            visceralFatArea,
            visceralFatAreaChange,
            trunkFat,
            trunkFatChange,
            dryLeanMass,
            dryLeanMassChange,
            parentAthleteId: id,
            anthropometryEntryId: elem.id,
          }),
        };
      });
      // TODO: only return most recent 'numberEntries'
      return {
        anthropometryEntries: anthroEntriesWithChange,
        mostRecentAnthropometryEntry:
          anthroEntriesWithChange.length > 0 ? anthroEntriesWithChange[anthroEntriesWithChange.length - 1] : undefined,
      };
    });
};

const roundAthleteAnthropometryRowDisplay = ({
  weight,
  BMI,
  bodyFatMass,
  leanBodyMass,
  bodyFatMassChange,
  weightChange,
  leanBodyMassChange,
  percentBodyFatChange,
  skeletalMuscleMass,
  skeletalMuscleMassChange,
  visceralFatArea,
  visceralFatAreaChange,
  trunkFat,
  trunkFatChange,
  dryLeanMass,
  dryLeanMassChange,
  ...rest
}: AthleteAnthropometryRow) => ({
  weight: weight && roundToHundredthsFloor(weight),
  BMI: BMI && roundToHundredthsFloor(BMI),
  bodyFatMass: bodyFatMass && roundToHundredthsFloor(bodyFatMass),
  leanBodyMass: leanBodyMass && roundToHundredthsFloor(leanBodyMass),
  bodyFatMassChange: bodyFatMassChange && roundToHundredthsFloor(bodyFatMassChange),
  weightChange: weightChange && roundToHundredthsFloor(weightChange),
  leanBodyMassChange: leanBodyMassChange && roundToHundredthsFloor(leanBodyMassChange),
  percentBodyFatChange: percentBodyFatChange && roundToHundredthsFloor(percentBodyFatChange),
  skeletalMuscleMass: skeletalMuscleMass && roundToHundredthsFloor(skeletalMuscleMass),
  skeletalMuscleMassChange: skeletalMuscleMassChange && roundToHundredthsFloor(skeletalMuscleMassChange),
  visceralFatArea: visceralFatArea && roundToHundredthsFloor(visceralFatArea),
  visceralFatAreaChange: visceralFatAreaChange && roundToHundredthsFloor(visceralFatAreaChange),
  trunkFat: trunkFat && roundToHundredthsFloor(trunkFat),
  trunkFatChange: trunkFatChange && roundToHundredthsFloor(trunkFatChange),
  dryLeanMass: dryLeanMass && roundToHundredthsFloor(dryLeanMass),
  dryLeanMassChange: dryLeanMassChange && roundToHundredthsFloor(dryLeanMassChange),
  ...rest,
});

export interface Aggregations {
  leanBodyMassChangeStdev: number;
  bodyFatMassChangeStdev: number;
  weightChangeStdev: number;
  percentBodyFatChangeStdev: number;
  leanBodyMassStdev: number;
  bodyFatMassStdev: number;
  weightStdev: number;
  percentBodyFatStdev: number;
  visceralFatAreaStdev: number;
  trunkFatStdev: number;
  skeletalMuscleMassStdev: number;
  dryLeanMassStdev: number;
  visceralFatAreaChangeStdev: number;
  trunkFatChangeStdev: number;
  skeletalMuscleMassChangeStdev: number;
  dryLeanMassChangeStdev: number;
  // Averages
  leanBodyMassChangeAvg: number;
  bodyFatMassChangeAvg: number;
  weightChangeAvg: number;
  percentBodyFatChangeAvg: number;
  visceralFatAreaAvg: number;
  trunkFatAvg: number;
  skeletalMuscleMassAvg: number;
  dryLeanMassAvg: number;
  visceralFatAreaChangeAvg: number;
  trunkFatChangeAvg: number;
  skeletalMuscleMassChangeAvg: number;
  dryLeanMassChangeAvg: number;

  bodyFatMassAvg: number;
  leanBodyMassAvg: number;
  weightAvg: number;
  percentBodyFatAvg: number;
}

export const getAggregations = (data: AthleteAnthropometryRow[]): Aggregations => {
  // Expects a SET of athletes
  const leanBodyMassChanges = data.flatMap(ae => (!!ae.leanBodyMassChange ? [ae.leanBodyMassChange] : []));
  const bodyFatMassChanges = data.flatMap(ae => (!!ae.bodyFatMassChange ? [ae.bodyFatMassChange] : []));
  const weightChanges = data.flatMap(ae => (!!ae.weightChange ? [ae.weightChange] : []));
  const percentBodyFatChanges = data.flatMap(ae => (!!ae.percentBodyFatChange ? [ae.percentBodyFatChange] : []));
  const dryLeanMassChanges = data.flatMap(ae => (!!ae.dryLeanMassChange ? [ae.dryLeanMassChange] : []));
  const viceralFatAreaChanges = data.flatMap(ae => (!!ae.visceralFatAreaChange ? [ae.visceralFatAreaChange] : []));
  const skeletalMuscleMassChanges = data.flatMap(ae => (!!ae.skeletalMuscleMassChange ? [ae.skeletalMuscleMassChange] : []));
  const trunkFatChanges = data.flatMap(ae => (!!ae.trunkFatChange ? [ae.trunkFatChange] : []));

  const bodyFatMasses = data.flatMap(ae => (!!ae.bodyFatMass ? [ae.bodyFatMass] : []));
  const leanBodyMasses = data.flatMap(ae => (!!ae.leanBodyMass ? [ae.leanBodyMass] : []));
  const weights = data.flatMap(ae => (!!ae.weight ? [ae.weight] : []));
  const percentBodyFats = data.flatMap(ae => (!!ae.percentBodyFat ? [ae.percentBodyFat] : []));
  const dryLeanMasses = data.flatMap(ae => (!!ae.dryLeanMass ? [ae.dryLeanMass] : []));
  const viceralFatAreas = data.flatMap(ae => (!!ae.visceralFatArea ? [ae.visceralFatArea] : []));
  const skeletalMuscleMasses = data.flatMap(ae => (!!ae.skeletalMuscleMass ? [ae.skeletalMuscleMass] : []));
  const trunkFats = data.flatMap(ae => (!!ae.trunkFat ? [ae.trunkFat] : []));

  return {
    // Stdev
    bodyFatMassChangeStdev: metricStdev(bodyFatMassChanges),
    leanBodyMassChangeStdev: metricStdev(leanBodyMassChanges),
    weightChangeStdev: metricStdev(weightChanges),
    percentBodyFatChangeStdev: metricStdev(percentBodyFatChanges),
    visceralFatAreaChangeStdev: metricStdev(viceralFatAreaChanges),
    trunkFatChangeStdev: metricStdev(trunkFatChanges),
    skeletalMuscleMassChangeStdev: metricStdev(skeletalMuscleMassChanges),
    dryLeanMassChangeStdev: metricStdev(dryLeanMassChanges),
    bodyFatMassStdev: metricStdev(bodyFatMasses),
    leanBodyMassStdev: metricStdev(leanBodyMasses),
    weightStdev: metricStdev(weights),
    percentBodyFatStdev: metricStdev(percentBodyFats),
    visceralFatAreaStdev: metricStdev(viceralFatAreas),
    trunkFatStdev: metricStdev(trunkFats),
    skeletalMuscleMassStdev: metricStdev(skeletalMuscleMasses),
    dryLeanMassStdev: metricStdev(dryLeanMasses),

    // Averages
    bodyFatMassChangeAvg: metricAverage(bodyFatMassChanges),
    leanBodyMassChangeAvg: metricAverage(leanBodyMassChanges),
    weightChangeAvg: metricAverage(weightChanges),
    percentBodyFatChangeAvg: metricAverage(percentBodyFatChanges),
    visceralFatAreaChangeAvg: metricAverage(viceralFatAreaChanges),
    trunkFatChangeAvg: metricAverage(trunkFatChanges),
    skeletalMuscleMassChangeAvg: metricAverage(skeletalMuscleMassChanges),
    dryLeanMassChangeAvg: metricAverage(dryLeanMassChanges),
    visceralFatAreaAvg: metricAverage(viceralFatAreas),
    trunkFatAvg: metricAverage(trunkFats),
    skeletalMuscleMassAvg: metricAverage(skeletalMuscleMasses),
    dryLeanMassAvg: metricAverage(dryLeanMasses),
    bodyFatMassAvg: metricAverage(bodyFatMasses),
    leanBodyMassAvg: metricAverage(leanBodyMasses),
    weightAvg: metricAverage(weights),
    percentBodyFatAvg: metricAverage(percentBodyFats),
  };
};

export const getPercentileBand = (data: AthleteAnthropometryRow[], row: AthleteAnthropometryRow, key: keyof AthleteAnthropometryRow) => {
  const sortedRows = sortByKey(
    data.filter(r => !!r[key]),
    key
  );
  // ^ Filter out cases where only 1 entry exists (no deltas)
  const twentyIdx = Math.ceil(sortedRows.length * 0.2);
  const fourtyIdx = Math.ceil(sortedRows.length * 0.4);
  const sixtyIdx = Math.ceil(sortedRows.length * 0.6);
  const eightyIdx = Math.ceil(sortedRows.length * 0.8);
  const idx = sortedRows.findIndex(r => r.id === row.id);
  if (idx > eightyIdx) {
    return 5;
  }
  if (idx > sixtyIdx) {
    return 4;
  }
  if (idx > fourtyIdx) {
    return 3;
  }
  if (idx > twentyIdx) {
    return 2;
  }
  if (idx >= 0) {
    return 1;
  }
  return null;
};

export const getPercentileExplanation = (band: 1 | 2 | 3 | 4 | 5, positiveIsGood: boolean) => {
  const explanation = "% Body Fat lower than";
  const positivityAwareBand = positiveIsGood ? band : abs(band - 5) + 1;
  switch (positivityAwareBand) {
    case 5:
      return `${explanation} 80% of athletes`;
    case 4:
      return `${explanation} 60% of athletes`;
    case 3:
      return `${explanation} 40% of athletes`;
    case 2:
      return `${explanation} 20% of athletes`;
    case 1:
      return `% Body Fat is higher than 80% of athletes`;
  }
};

interface ReportColors {
  red: string;
  orange: string;
  yellow: string;
  lightYellow: string;
  blue: string;
  green: string;
  grey: string;
  lightGrey: string;
}

const getCellColors = ({
  palette: {
    success,
    info,
    warning,
    error,
    greyscale,
    accents: { yellow },
  },
}: Theme): ReportColors => ({
  red: error.main,
  orange: warning.main,
  yellow: yellow[400],
  lightYellow: yellow[200],
  blue: info.main,
  green: success.main,
  grey: greyscale[300],
  lightGrey: greyscale[200],
});

const getFontColors = ({
  palette: {
    success,
    info,
    warning,
    error,
    common,
    greyscale,
    accents: { yellow },
  },
}: Theme): ReportColors => ({
  red: error.lighter || "",
  orange: warning.lighter || "",
  yellow: yellow[100],
  lightYellow: common.black,
  blue: info.lighter || "",
  green: success.lighter || "",
  grey: greyscale[100],
  lightGrey: greyscale[100],
});

const argbify = (hexColor: string) => {
  return `FF${hexColor.replace("#", "")}`; // For use in exceljs
};

export const applyFillToCell = (theme: Theme, cell: Cell, color: keyof ReportColors): Cell => {
  const {
    palette: { greyscale },
  } = theme;
  const borderColor = argbify(greyscale[400]);
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb: argbify(getCellColors(theme)[color]),
    },
  };
  cell.border = {
    top: { style: "thin", color: { argb: borderColor } },
    bottom: { style: "thin", color: { argb: borderColor } },
    left: { style: "thin", color: { argb: borderColor } },
    right: { style: "thin", color: { argb: borderColor } },
  };
  return cell;
};

export const useStyles = makeStyles((theme: Theme) => {
  const {
    palette: {
      success,
      greyscale,
      accents: { yellow },
    },
  } = theme;
  const cellColors = getCellColors(theme);
  const fontColors = getFontColors(theme);

  return createStyles({
    // For reasons unexplained, MuiTableCell-root overrides these classes on the production build
    // Possible Reason #1: useStyles() is defined in a different file from where it is used
    // Possible Reason #2: Something weird with dynamically rendering MTableCell
    red: {
      backgroundColor: `${cellColors.red} !important`,
      color: `${fontColors.red} !important`,
    },
    orange: {
      backgroundColor: `${cellColors.orange} !important`,
      color: `${fontColors.orange} !important`,
    },
    yellow: {
      backgroundColor: `${cellColors.yellow} !important`,
      color: `${fontColors.yellow} !important`,
    },
    blue: {
      backgroundColor: `${cellColors.blue} !important`,
      color: `${fontColors.blue} !important`,
    },
    green: {
      backgroundColor: `${cellColors.green} !important`,
      color: `${fontColors.green} !important`,
    },
    grey: {
      backgroundColor: `${cellColors.grey} !important`,
      color: `${fontColors.grey} !important`,
    },
    historicalAnthroEntry: {
      color: `${greyscale[500]} !important`,
      fontSize: "10px !important",
      lineHeight: "1.2 !important",
      padding: "3px 8px 3px 8px !important",
    },
    mostRecentAnthroEntry: {
      color: "black",
      fontSize: "1spx !important",
      lineHeight: "1.2 !important",
      padding: "3px 8px 3px 8px !important",
    },
    denseChip: { fontSize: "10px !important", height: "16px !important" },
    denseText: { lineHeight: "1.2 !important" },
    denseChipLabel: {
      paddingLeft: `${theme.spacing(0.75)} !important`,
      paddingRight: `${theme.spacing(0.75)} !important`,
    },
    yellowBg: { backgroundColor: `${yellow[200]} !important` },
    greenBg: { backgroundColor: `${success.light} !important` },
    darkYellowBg: { backgroundColor: `${yellow[400]} !important` },
    darkGreenBg: {
      backgroundColor: `${success.main} !important`,
      color: "white !important",
    },
    whiteBg: { backgroundColor: "white !important" },
    inherit: { backgroundColor: "inherit !important" },
  });
});

interface GetPercentileInfoProps {
  percentile: number;
  positiveIsGood: boolean;
  classes: any;
}

export const getPercentileClass = ({ percentile, positiveIsGood, classes }: GetPercentileInfoProps) => {
  switch (percentile) {
    case 5:
      return positiveIsGood ? classes.green : classes.red;
    case 4:
      return positiveIsGood ? classes.blue : classes.orange;
    case 3:
      return classes.yellow;
    case 2:
      return positiveIsGood ? classes.orange : classes.blue;
    case 1:
      return positiveIsGood ? classes.red : classes.green;
    default:
      return classes.grey;
  }
};

export const getPercentileLabel = ({ percentile, positiveIsGood }: GetPercentileInfoProps) => {
  switch (percentile) {
    case 5:
      return positiveIsGood ? "Excellent" : "Poor";
    case 4:
      return positiveIsGood ? "Good" : "Below Avg";
    case 3:
      return positiveIsGood ? "Avg" : "Avg";
    case 2:
      return positiveIsGood ? "Below Avg" : "Good";
    case 1:
      return positiveIsGood ? "Poor" : "Excellent";
    default:
      return null;
  }
};

type HighlightColor = keyof Record<"greenBg" | "yellowBg" | "darkGreenBg" | "darkYellowBg", string> | undefined;
export type OutlierFlag = 2 | 1 | 0 | -1 | -2;

export const getHighlightColor = (outlierFlag: OutlierFlag): HighlightColor => {
  return outlierFlag === 1
    ? "greenBg"
    : outlierFlag === -1
    ? "yellowBg"
    : outlierFlag === 2
    ? "darkGreenBg"
    : outlierFlag === -2
    ? "darkYellowBg"
    : undefined;
};

export const getOutlierFlag = (rowData: AthleteAnthropometryRow, field: keyof AthleteAnthropometryRow, agg: Aggregations): OutlierFlag => {
  const dataField = rowData[field];
  if (!dataField || isNaN(Number(dataField))) {
    return 0;
  }

  const data = Number(dataField);

  let plusOneStdev: number = 0;
  let plusTwoStdev: number = 0;
  let minusOneStdev: number = 0;
  let minusTwoStdev: number = 0;
  switch (field) {
    case "bodyFatMass":
      plusTwoStdev = agg.bodyFatMassAvg + agg.bodyFatMassStdev * 2;
      minusTwoStdev = agg.bodyFatMassAvg - agg.bodyFatMassStdev * 2;
      plusOneStdev = agg.bodyFatMassAvg + agg.bodyFatMassStdev;
      minusOneStdev = agg.bodyFatMassAvg - agg.bodyFatMassStdev;
      return data > plusTwoStdev ? -2 : data > plusOneStdev ? -1 : data < minusTwoStdev ? 2 : data < minusOneStdev ? 1 : 0;
    case "leanBodyMass":
      plusTwoStdev = agg.leanBodyMassAvg + agg.leanBodyMassStdev * 2;
      minusTwoStdev = agg.leanBodyMassAvg - agg.leanBodyMassStdev * 2;
      plusOneStdev = agg.leanBodyMassAvg + agg.leanBodyMassStdev;
      minusOneStdev = agg.leanBodyMassAvg - agg.leanBodyMassStdev;
      return data > plusTwoStdev ? 2 : data > plusOneStdev ? 1 : data < minusTwoStdev ? -2 : data < minusOneStdev ? -1 : 0;
    case "percentBodyFat":
      plusTwoStdev = agg.percentBodyFatAvg + agg.percentBodyFatStdev * 2;
      minusTwoStdev = agg.percentBodyFatAvg - agg.percentBodyFatStdev * 2;
      plusOneStdev = agg.percentBodyFatAvg + agg.percentBodyFatStdev;
      minusOneStdev = agg.percentBodyFatAvg - agg.percentBodyFatStdev;
      return data > plusTwoStdev ? -2 : data > plusOneStdev ? -1 : data < minusTwoStdev ? 2 : data < minusOneStdev ? 1 : 0;
    default:
      return 0;
  }
};

const metricAverage = (values: number[]): number => {
  return values.length > 0 ? mean(values) : 0;
};

const metricStdev = (values: number[]): number => {
  return values.length > 0 ? std(values) : 0;
};

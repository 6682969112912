import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getMealTemplateMacroTargets } from "@notemeal/shared/ui/MealTemplate/utils";
import { getMenuItemMaxAmount } from "@notemeal/shared/ui/MenuItem/utils";
import { MenuOrderItemWithAppearance } from "@notemeal/shared/ui/MenuOrder/utils";
import {
  MenuSelectionItemWithAppearance,
  getMenuItemAmountsForSelection,
  getMenuSectionAmountsForSelection,
} from "@notemeal/shared/ui/MenuSelection/utils";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { jsDateToDateAndTimeInTz, parseDate } from "@notemeal/shared/ui/utils/dateTimes";
import newId from "@notemeal/shared/ui/utils/newId";
import { ZERO_MACROS_INPUT } from "@notemeal/shared/utils/macro-protocol";
import classnames from "classnames";
import React from "react";
import MenuOrderDiningStationList from "../../../../components/MenuOrder/DiningStation/List";
import MenuOrderMenuCards from "../../../../components/MenuOrder/TimelineMeal/MenuCards";
import { useContentStyles } from "../../../../components/MenuOrder/useContentStyles";
import MenuSelectionItemEdit from "../../../../components/MenuSelectionItem/Edit";
import MenuSelectionItemNew from "../../../../components/MenuSelectionItem/New";
import {
  MealMenuWithItemPreviewsFragment,
  MenuOrderItemStatus,
  MenuSelectionFormFragment,
  useKioskAthleteQuery,
  useMenuOrderSuggestionsAndRecentOrdersQuery,
} from "../../../../types";
import { TimelineMealForModal } from "../utils";
import { TimelineMealCart, useTimelineMealModalContext } from "./TimelineMealModalContext";

const useStyles = makeStyles((theme: Theme) => {
  const BORDER = `1px solid ${theme.palette.grey[300]}`;

  return createStyles({
    card: {
      display: "flex",
      flexDirection: "row",
      margin: theme.spacing(2),
      border: BORDER,
      maxWidth: "70%",
      "&:hover": {
        cursor: "pointer",
      },
    },
    topCard: { marginTop: 0 },
  });
});

interface TimelineMealModalOrderTabContentProps {
  timelineMeal: TimelineMealForModal;
  mealMenuWithAppearances: MealMenuWithItemPreviewsFragment;
  mealMenuClosed: boolean;
  athleteId: string;
  cart: TimelineMealCart;
}

const TimelineMealModalOrderTabContent = ({
  timelineMeal,
  mealMenuWithAppearances,
  mealMenuClosed,
  athleteId,
  cart,
}: TimelineMealModalOrderTabContentProps) => {
  const {
    menuState,
    setMenuState,
    handleClickLogFromMenu: onClickLogFromMenu,
    cartFns: { getAllItemsWithAppearance, onAddMenuOrderItem, onEditMenuOrderItem, onAddMenuOrderItemsToEmptyCart },
  } = useTimelineMealModalContext();
  const contentClasses = useContentStyles();
  const classes = useStyles();
  const clientTimezone = useClientTimezone();
  const { mealMenuDiningStations } = mealMenuWithAppearances;

  const allMenuItemAppearances = mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances)!;
  const menuOrderItems = getAllItemsWithAppearance(allMenuItemAppearances);
  const canOrderItems = allMenuItemAppearances.some(mia => mia.availableForOrder);

  const currentOrderMenuItemAmounts = getMenuItemAmountsForSelection(menuOrderItems);
  const currentOrderDiningStationAmounts = getMenuSectionAmountsForSelection(menuOrderItems, mealMenuDiningStations);

  const handleAddItem = (menuOrderItem: MenuSelectionItemWithAppearance) => {
    onAddMenuOrderItem({
      item: { ...menuOrderItem, forOrder: true, status: "new", rating: null },
      mealMenuId: mealMenuWithAppearances.id,
      order: cart.order,
    });
    setMenuState({
      type: "mealMenuDiningStations",
      forOrder: true,
    });
  };

  const handleEditItem = (menuOrderItem: MenuOrderItemWithAppearance) => {
    onEditMenuOrderItem({ item: menuOrderItem, mealMenuId: mealMenuWithAppearances.id, order: cart.order });
  };

  //recommendations
  const targetMacros = timelineMeal.mealTemplate && getMealTemplateMacroTargets(timelineMeal.mealTemplate);
  const { date } = jsDateToDateAndTimeInTz(parseDate(timelineMeal.start), clientTimezone);

  const { data } = useKioskAthleteQuery({
    variables: {
      athleteId,
      mealMenuId: mealMenuWithAppearances.id,
      date,
      timezone: clientTimezone,
    },
    fetchPolicy: "network-only",
  });

  const mealPlan = data?.athlete.timelineDate.mealPlan ?? null;

  const avoidFoodGroupIds = [
    ...(data ? data.athlete.dislikedFoodGroups.map(fg => fg.id) : []),
    ...(mealPlan ? mealPlan.avoidedFoodGroups.map(fg => fg.id) : []),
  ];

  const { data: suggestionsAndRecentOrdersData, loading: suggestionsAndRecentOrdersLoading } = useMenuOrderSuggestionsAndRecentOrdersQuery({
    variables: {
      targetMacros: targetMacros
        ? {
            cho: targetMacros.cho,
            pro: targetMacros.pro,
            fat: targetMacros.fat,
          }
        : ZERO_MACROS_INPUT,
      mealMenuId: mealMenuWithAppearances.id,
      limit: 3,
      avoidFoodGroupIds,
      athleteId,
      menuItemIds: mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances.map(mia => mia.menuItem.id)),
    },
    skip: !targetMacros || timelineMeal.menuOrders.length > 0 || mealMenuClosed,
  });

  const NEW: MenuOrderItemStatus = "new";

  const handleChooseMenuOrderForQuickAdd = (menuOrder: MenuSelectionFormFragment) => {
    const selectionItems = menuOrder.items.flatMap(item => {
      const matchingAppearance = allMenuItemAppearances.find(mia => mia.menuItem.id === item.menuItem.id);
      if (matchingAppearance) {
        return [
          {
            id: newId(),
            __typename: "MenuOrderItem",
            forOrder: true,
            status: NEW,
            menuItem: item.menuItem,
            menuItemAppearance: {
              id: matchingAppearance.id,
              maxAmount: matchingAppearance.maxAmount,
              availableForOrder: matchingAppearance.availableForOrder,
              allowSpecialRequests: matchingAppearance.allowSpecialRequests,
            },
            amount: item.amount,
            specialRequests: null,
            options: item.options,
            rating: null,
            percentConsumed: item.percentConsumed,
          },
        ];
      } else {
        return [];
      }
    });
    // TODO: could make this happen regardless of empty status.
    onAddMenuOrderItemsToEmptyCart(mealMenuWithAppearances.id, selectionItems);
    setMenuState({ type: "mealMenuDiningStations", forOrder: true });
  };

  const menuOrderItemForEdit =
    menuState?.type === "editMenuItem" ? menuOrderItems.find(i => i.id === menuState.menuOrderItem.id) : undefined;
  return (
    <>
      {menuState === null ? (
        <MenuOrderMenuCards
          className={contentClasses.allCards}
          cardClassName={classnames(classes.card, classes.topCard)}
          menuOrderingClosed={mealMenuClosed}
          canOrderItems={canOrderItems}
          canLogItems={mealMenuClosed}
          onClickLog={onClickLogFromMenu}
          onClickOrder={() =>
            setMenuState({
              type: "mealMenuDiningStations",
              forOrder: true,
            })
          }
          onChooseMenuOrder={handleChooseMenuOrderForQuickAdd}
          targetMacros={targetMacros}
          menuOrderSuggestionsAndRecentOrdersData={suggestionsAndRecentOrdersData}
          menuOrderSuggestionsAndRecentOrdersLoading={suggestionsAndRecentOrdersLoading}
          athleteUserId={athleteId}
          mealMenuId={mealMenuWithAppearances.id}
        />
      ) : menuState.type === "mealMenuDiningStations" ? (
        <MenuOrderDiningStationList
          onBack={() => setMenuState(null)}
          timelineMealFlow={true}
          forOrder={menuState.forOrder}
          mealMenuDiningStations={mealMenuDiningStations}
          currentOrderDiningStationAmounts={currentOrderDiningStationAmounts}
          currentOrderMenuItemAmounts={currentOrderMenuItemAmounts}
          onSelectItem={(menuItemAppearance, currentAmountForDiningStation, maxAmountForDiningStation) =>
            setMenuState({
              type: "newMenuItem",
              forOrder: true,
              menuItemAppearance,
              newMenuOrderItem: null,
              currentAmountForDiningStation,
              maxAmountForDiningStation,
            })
          }
        />
      ) : menuState.type === "newMenuItem" ? (
        <MenuSelectionItemNew
          menuSelectionItemId={menuState.newMenuOrderItem?.id ?? newId()}
          selectionType={menuState.forOrder ? "Order" : "Log"}
          onChange={newMenuOrderItem => {
            setMenuState({
              ...menuState,
              newMenuOrderItem: {
                ...newMenuOrderItem,
                status: "new",
                forOrder: true,
                rating: null,
              },
            });
          }}
          maxAmount={getMenuItemMaxAmount({
            currentAmountForMenuItem: currentOrderMenuItemAmounts[menuState.menuItemAppearance.menuItem.id] || 0,
            maxAmountForMenuItem: menuState.menuItemAppearance.maxAmount,
            currentAmountForMenuSection: menuState.currentAmountForDiningStation,
            maxAmountForMenuSection: menuState.maxAmountForDiningStation,
          })}
          availableForOrder={menuState.menuItemAppearance.availableForOrder}
          allowSpecialRequests={menuState.menuItemAppearance.allowSpecialRequests}
          menuItem={menuState.menuItemAppearance.menuItem}
          onDone={handleAddItem}
          onBack={() =>
            setMenuState({
              type: "mealMenuDiningStations",
              forOrder: true,
            })
          }
        />
      ) : menuState.type === "editMenuItem" && menuOrderItemForEdit ? (
        <MenuSelectionItemEdit
          // can this be assumed...?
          menuSelectionItem={menuOrderItemForEdit}
          currentAmountForMenuItem={currentOrderMenuItemAmounts[menuState.menuOrderItem.menuItem.id] || 0}
          menuSections={mealMenuDiningStations}
          menuSectionAmountsForSelection={currentOrderDiningStationAmounts}
          onEdit={item =>
            handleEditItem({
              ...menuState.menuOrderItem,
              ...item,
            })
          }
          onBack={() =>
            setMenuState({
              type: "mealMenuDiningStations",
              forOrder: true,
            })
          }
        />
      ) : null}
    </>
  );
};

export default TimelineMealModalOrderTabContent;

import { getNextPosition } from "@notemeal/shared/ui/utils/getNextPosition";
import newId from "@notemeal/shared/ui/utils/newId";
import { MenuItemAppearanceGroupAction, menuItemAppearanceGroupReducer } from "../../../../components/MenuItemAppearance/reducer";
import { DietFragment } from "../../../../types";
import { MenuSectionState, RestaurantMenuDialogState } from "../types";

interface MenuSectionChangeNameAction {
  type: "MenuSectionChangeNameAction";
  payload: {
    menuSectionId: string;
    name: string;
  };
}

interface CreateMenuSectionAction {
  type: "CreateMenuSectionAction";
}

interface RemoveMenuSectionAction {
  type: "RemoveMenuSectionAction";
  payload: {
    menuSectionId: string;
  };
}

interface ReorderMenuSectionAction {
  type: "ReorderMenuSectionAction";
  payload: {
    menuSections: readonly MenuSectionState[];
  };
}

interface MenuAllItemsAvailableForOrderAction {
  type: "MenuAllItemsAvailableForOrderAction";
  payload: {
    availableForOrder: boolean;
  };
}

interface MenuAllItemsAllowSpecialRequestsAction {
  type: "MenuAllItemsAllowSpecialRequestsAction";
  payload: {
    allowSpecialRequests: boolean;
  };
}

interface ChangeSupportedDietsAction {
  type: "ChangeSupportedDietsAction";
  payload: {
    supportedDiets: readonly DietFragment[];
  };
}

export type RestaurantMenuDialogAction =
  | MenuSectionChangeNameAction
  | CreateMenuSectionAction
  | RemoveMenuSectionAction
  | ReorderMenuSectionAction
  | MenuAllItemsAvailableForOrderAction
  | MenuAllItemsAllowSpecialRequestsAction
  | ChangeSupportedDietsAction
  | (MenuItemAppearanceGroupAction & {
      payload: {
        menuSectionId: string;
      };
    });

export const restaurantMenuReducer = (state: RestaurantMenuDialogState, action: RestaurantMenuDialogAction): RestaurantMenuDialogState => {
  switch (action.type) {
    case "MenuSectionChangeNameAction":
      return {
        ...state,
        edited: true,
        sections: state.sections.map(section => {
          if (section.id !== action.payload.menuSectionId) {
            return section;
          }
          return {
            ...section,
            name: action.payload.name,
          };
        }),
      };
    case "CreateMenuSectionAction":
      return {
        ...state,
        edited: true,
        sections: [
          ...state.sections,
          {
            id: newId(),
            name: "",
            position: getNextPosition(state.sections),
            menuItemAppearances: [],
          },
        ],
      };
    case "RemoveMenuSectionAction":
      return {
        ...state,
        edited: true,
        sections: state.sections.filter(section => section.id !== action.payload.menuSectionId),
      };
    case "ReorderMenuSectionAction":
      return {
        ...state,
        edited: true,
        sections: action.payload.menuSections,
      };
    case "MenuAllItemsAvailableForOrderAction":
      return {
        ...state,
        edited: true,
        sections: state.sections.map(section => ({
          ...section,
          menuItemAppearances: menuItemAppearanceGroupReducer(section.menuItemAppearances, {
            type: "AllMenuItemsAvailableForOrderAction",
            payload: {
              availableForOrder: action.payload.availableForOrder,
            },
          }),
        })),
      };
    case "MenuAllItemsAllowSpecialRequestsAction":
      return {
        ...state,
        edited: true,
        sections: state.sections.map(section => ({
          ...section,
          menuItemAppearances: menuItemAppearanceGroupReducer(section.menuItemAppearances, {
            type: "AllMenuItemsAllowSpecialRequestsAction",
            payload: {
              allowSpecialRequests: action.payload.allowSpecialRequests,
            },
          }),
        })),
      };
    case "ChangeSupportedDietsAction":
      return {
        ...state,
        edited: true,
        supportedDiets: action.payload.supportedDiets,
      };
    default:
      return {
        ...state,
        edited: true,
        sections: state.sections.map(section => {
          if (section.id !== action.payload.menuSectionId) {
            return section;
          } else {
            return {
              ...section,
              menuItemAppearances: menuItemAppearanceGroupReducer(section.menuItemAppearances, action),
            };
          }
        }),
      };
  }
};

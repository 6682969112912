import React, { useCallback } from "react";
import useInfiniteCursorConnectionScroll from "../../../../../../components/universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import { TeamworksOrgFragment, useTeamworksOrgsQuery } from "../../../../../../types";
import DisplayList, { RenderListItemArgs } from "../../shared/InfiniteScrollDisplayList";
import OrgCard from "./OrgCard";

interface TeamworksOrgsListProps {
  selectedId: string | null;
  searchQuery: string;
  createFromTeamworksOrg: (org: TeamworksOrgFragment) => void;
  linkTeamworksOrg: (org: TeamworksOrgFragment) => void;
}

const TeamworksOrgsList = ({ selectedId, searchQuery, createFromTeamworksOrg, linkTeamworksOrg }: TeamworksOrgsListProps) => {
  const searchResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useTeamworksOrgsQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) => ({
        variables: {
          pagination: { cursor, limit },
          query: searchQuery,
        },
      }),
      [searchQuery]
    ),
    queryKey: "teamworksOrgCursorConnection",
    edgesAreEqual: useCallback((org1: TeamworksOrgFragment, org2: TeamworksOrgFragment) => {
      return org1.id === org2.id;
    }, []),
    limit: 50,
  });

  const renderListItem = ({ key, forwardRef, ...restProps }: RenderListItemArgs<TeamworksOrgFragment>) => {
    return (
      <div ref={forwardRef} key={key}>
        <OrgCard
          onAdd={createFromTeamworksOrg}
          onLink={linkTeamworksOrg}
          {...restProps} />
      </div>
    );
  };

  return (
    <DisplayList
      title="Unlinked Teamworks Orgs"
      columnMode={selectedId ? "Link" : "Add"}
      renderListItem={renderListItem}
      infiniteScrollResults={searchResults}
    />
  );
};

export default TeamworksOrgsList;

import { AutocompleteRenderOptionState, createFilterOptions, FilterOptionsState, SxProps } from "@mui/material";
import React, { useCallback } from "react";
import { BasicOption } from "../../../components/universal/BaseAsyncAutocomplete";
import { GetQueryVariablesFromPaginationAndInputArgs } from "../../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import { AthleteForCustomTagFragment, useAthleteSearchCursorConnectionQuery } from "../../../types";
import BaseTagSelector, { BaseTagSelectorPassThroughProps } from "./BaseTagSelector";

interface AthleteSelectorProps extends BaseTagSelectorPassThroughProps {
  sx?: SxProps;
  selectedAthletes: readonly AthleteForCustomTagFragment[];
  onChange: (newAthletes: AthleteForCustomTagFragment[]) => void;
  filterAthletes?: (_edges: AthleteForCustomTagFragment[]) => AthleteForCustomTagFragment[];
}

const AthleteSelector = ({ sx, selectedAthletes, onChange, filterAthletes, ...passTroughProps }: AthleteSelectorProps) => {
  const getQueryVariablesForAthlete = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
      },
    }),
    []
  );

  const transformAndFilterAthleteOptions = (_edges: AthleteForCustomTagFragment[] | undefined): AthleteForCustomTagFragment[] => {
    const edges = _edges ?? [];
    return filterAthletes ? filterAthletes(edges) : edges;
  };
  const filterOptions = (
    athletes: AthleteForCustomTagFragment[],
    state: FilterOptionsState<AthleteForCustomTagFragment>
  ): AthleteForCustomTagFragment[] =>
    createFilterOptions<AthleteForCustomTagFragment>({
      stringify: () => state.inputValue,
    })(athletes, state);

  const athleteEdgesAreEqual = useCallback((edge1: AthleteForCustomTagFragment, edge2: AthleteForCustomTagFragment) => {
    return edge1.id === edge2.id;
  }, []);

  const handleChange = (_: string, athletes: AthleteForCustomTagFragment[]) => onChange(athletes);

  return (
    <BaseTagSelector
      sx={sx}
      queryKey="athleteSearchCursorConnection"
      useCursorConnectionQuery={useAthleteSearchCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariablesForAthlete}
      transformAndFilterOptions={transformAndFilterAthleteOptions}
      renderOption={(athlete: AthleteForCustomTagFragment, _state: AutocompleteRenderOptionState) => (
        <BasicOption element={`${athlete.lastName}, ${athlete.firstName}`} />
      )}
      handleChange={handleChange}
      getOptionLabel={(athlete: AthleteForCustomTagFragment) => `${athlete.lastName}, ${athlete.firstName}`}
      getCustomOptionKey={(athlete: AthleteForCustomTagFragment) => athlete.id}
      filterOptions={filterOptions}
      noOptionsText="No Athletes Found"
      inputPlaceholder="Athletes"
      edgesAreEqual={athleteEdgesAreEqual}
      value={selectedAthletes.concat()}
      {...passTroughProps}
    />
  );
};

export default AthleteSelector;

import React from "react";
import { PendingPosition } from "../../Sports/utils";
import PositionList from "./PositionList";

interface PendingPositionsListProps {
  selectedId: string | null;
  positions: readonly PendingPosition[];
  onCancel: (position: PendingPosition) => void;
}

const PendingPositionsList = ({ positions, ...props }: PendingPositionsListProps) => {
  const { selectedId } = props;
  return (
    <PositionList
      {...props}
      positions={positions.map(p => ({ ...p, id: p.teamworksId }))}
      title="Pending Positions"
      columnMode={selectedId ? "NoAction" : "Cancel"}
      disableOnSelected
    />
  );
};

export default PendingPositionsList;

import React, { useReducer } from "react";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import { DeliveryLocationWithTeamsFragment, useEditDeliveryLocationMutation, useGoogleMapsApiKeyQuery } from "../../../../types";
import Info from "../Form/Info";
import {
  buildDeliveryLocationFormTooltips,
  buildInitialEditFormState,
  deliveryLocationFormReducer_Edit,
  getEditDeliveryLocationInput,
} from "../Form/utils";

interface DeliveryLocationEditDialogProps {
  open: boolean;
  onClose: () => void;
  onEditSuccess: (dl: DeliveryLocationWithTeamsFragment) => void;
  deliveryLocation: DeliveryLocationWithTeamsFragment;
}

const DeliveryLocationEditDialog = ({ open, onClose, onEditSuccess, deliveryLocation }: DeliveryLocationEditDialogProps) => {
  const { setMessage } = useSnackbar();
  const { data } = useGoogleMapsApiKeyQuery();
  const [state, dispatch] = useReducer(deliveryLocationFormReducer_Edit, buildInitialEditFormState(deliveryLocation));

  const [editDeliveryLocation, { loading: saving }] = useEditDeliveryLocationMutation({
    onCompleted: data => {
      onEditSuccess(data.editDeliveryLocation.deliveryLocation);
      onClose();
    },
    onError: e => {
      setMessage("error", e.message);
    },
  });

  if (!data || saving) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const canSaveTooltips = buildDeliveryLocationFormTooltips(state);

  const onSave = () => {
    if (canSaveTooltips.length > 0) {
      return;
    }
    const input = getEditDeliveryLocationInput(state, deliveryLocation.id);
    if (!input) {
      return;
    }
    editDeliveryLocation({
      variables: {
        input,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Edit Delivery Location" onClose={onClose} />
      <DialogContent>
        <Info state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <TWItemizedTooltip title="Fix the following:" items={canSaveTooltips}>
          <Button onClick={onSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryLocationEditDialog;

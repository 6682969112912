import { Button } from "@mui/material";
import React from "react";
import { getIndexArray, MenuItemRowInfoWithAmount } from ".";
import MenuItemCell from "../Shared/MenuItemCell";

export const MAX_SHOWN_MENU_ITEM_CELLS = 6;

export const getMenuItemCellHeaderElements = (length: number): React.ReactNode[] => {
  if (length > MAX_SHOWN_MENU_ITEM_CELLS) {
    const remainder = length - MAX_SHOWN_MENU_ITEM_CELLS;
    return [...getMenuItemCellHeaderElements(MAX_SHOWN_MENU_ITEM_CELLS), `+${remainder} more`];
  }
  const indexes = getIndexArray(length);
  return indexes.map(index => `Menu Item ${index + 1}`);
};

export const getMenuItemCellBodyElements = (
  _menuItems: readonly MenuItemRowInfoWithAmount[],
  openShowMoreModal: (menuItems: readonly MenuItemRowInfoWithAmount[]) => void,
  length: number
): React.ReactNode[] => {
  const hasOverflow = _menuItems.length > MAX_SHOWN_MENU_ITEM_CELLS;
  const overflowExists = length > MAX_SHOWN_MENU_ITEM_CELLS;
  const extraNeeded = Math.max((overflowExists ? MAX_SHOWN_MENU_ITEM_CELLS + 1 : length) - _menuItems.length, 0);

  const menuItems = hasOverflow ? _menuItems.slice(0, MAX_SHOWN_MENU_ITEM_CELLS) : _menuItems;

  const extraCells = getIndexArray(extraNeeded).map(() => "");
  const menuItemCells = menuItems.map(menuItemCellInfo => (
    <MenuItemCell key={`menu-item-cell-${menuItemCellInfo.id}`} menuItemCellInfo={menuItemCellInfo} />
  ));
  return hasOverflow
    ? [
        ...menuItemCells,
        <Button key={`see-more-button`} onClick={() => openShowMoreModal(_menuItems)}>
          See More
        </Button>,
      ]
    : [...menuItemCells, ...extraCells];
};

import React, { ReactNode } from "react";
import { CheckInKioskAthletePreviewFragment, KioskMealMenuPreviewFragment } from "../../../types";
import AthleteListItem from "../Configure/AthleteListItem";
import AthleteNavigationContent from "../Navigation";

interface CheckInAthleteNavigationProps {
  athletes: readonly CheckInKioskAthletePreviewFragment[];
  selectedAthleteId: string | null;
  setSelectedAthleteId: (athleteId: string | null) => void;
  selectedTeamId: string | null;
  setSelectedTeamId: (value: string | null) => void;
  renderCheckInAthleteListItem: (athlete: CheckInKioskAthletePreviewFragment) => ReactNode;
  header: ReactNode;
  mealMenus: readonly KioskMealMenuPreviewFragment[];
  searchText: string;
  setSearchText: (value: string) => void;
}

const CheckInAthleteNavigation = ({
  athletes,
  mealMenus,
  selectedAthleteId,
  setSelectedAthleteId,
  renderCheckInAthleteListItem,
  header,
  searchText,
  setSearchText,
  selectedTeamId,
  setSelectedTeamId,
}: CheckInAthleteNavigationProps) => {
  return (
    <AthleteNavigationContent
      athletes={athletes}
      athleteListHeader={header}
      mealMenus={mealMenus}
      searchText={searchText}
      setSearchText={setSearchText}
      selectedTeamId={selectedTeamId}
      setSelectedTeamId={setSelectedTeamId}
      renderAthleteListItem={(a, autoFocus, backgroundTinted) => (
        <AthleteListItem
          key={a.id}
          athlete={a}
          selected={selectedAthleteId === a.id}
          onSelect={() => {
            setSelectedAthleteId(a.id);
          }}
          autoFocus={autoFocus}
          listItemDecoration={renderCheckInAthleteListItem && renderCheckInAthleteListItem(a)}
          backgroundTinted={backgroundTinted}
        />
      )}
    />
  );
};

export default CheckInAthleteNavigation;

import { Button, Dialog, DialogActions, DialogContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import TextFieldWithCopy from "../TextFieldWithCopy";
import { ClientCredentialWithSecret } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface ClientCredentialsDialogWithSecretProps {
  open: boolean;
  onClose: () => void;
  data: ClientCredentialWithSecret;
}

const ClientCredentialsDialogWithSecret = ({ open, onClose, data }: ClientCredentialsDialogWithSecretProps) => {
  const classes = useStyles();

  const [verifyingClose, setVerifyingClose] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={() => setVerifyingClose(true)}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title={`Client Credential: ${data.clientCredential.name}`} onClose={() => setVerifyingClose(true)} />
      <DialogContent className={classes.content}>
        <Typography variant="body2" color="textSecondary">
          Do not close this dialog until you have copied the <strong>Client ID</strong> and <strong>Client Secret</strong>
          <br />
          The <strong>Client Secret</strong> will not be available for viewing again, and if lost must be refreshed.
        </Typography>
        <TextFieldWithCopy
          label="Client ID"
          value={data.clientCredential.clientId}
          ariaLabel="Client ID" />
        <TextFieldWithCopy
          label="Client Secret"
          value={data.clientSecret}
          ariaLabel="Client Secret" />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setVerifyingClose(true)}>Close</Button>
      </DialogActions>

      {verifyingClose && (
        <Dialog
          open={verifyingClose}
          onClose={() => setVerifyingClose(false)}
          maxWidth="xs">
          <DialogTitle title="Copy Client Secret" onClose={() => setVerifyingClose(false)} />
          <DialogContent>
            <Typography>
              <strong>Client Secret</strong> will not be available later. Would you still like to close?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setVerifyingClose(false)}>
              Cancel
            </Button>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default ClientCredentialsDialogWithSecret;

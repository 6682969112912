import Restaurant from "@mui/icons-material/Restaurant";
import { Box } from "@mui/material";
import React from "react";

const FoodPlaceholderImage = () => (
  <Box sx={{ backgroundColor: "grey.100", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
    <Restaurant color="disabled" />
  </Box>
);

export default FoodPlaceholderImage;

import {
  EditFullMealOptionPendingFragment,
  EditFullMealOptionPendingFragmentDoc,
  EditMealOptionPendingDocument,
  EditMealOptionPendingMutation,
  EditMealOptionPendingMutationVariables,
  FullMealOptionPendingFragment,
  FullServingAmountFragment,
  FullServingFragment,
  FullServingFragmentDoc,
  MutationEditMealOptionPendingLocalArgs,
  ServingAmountLocalInput,
} from "../../types";
import newId from "../../utils/newId";
import { ResolverContext } from "./types";
import { makeFragmentFuncs, makeFragmentFuncsWithInit } from "./utils";

export const initEditMealOptionPendingFragment = (mealOptionPending: FullMealOptionPendingFragment): EditFullMealOptionPendingFragment => {
  return {
    ...mealOptionPending,
    isAutosaving: false,
  };
};

const { getFragment, getFragmentOrInit, writeFragment } = makeFragmentFuncsWithInit(
  "MealOptionPending",
  "EditFullMealOptionPending",
  EditFullMealOptionPendingFragmentDoc,
  initEditMealOptionPendingFragment
);

const servingFragmentFuncs = makeFragmentFuncs<FullServingFragment>("Serving", "FullServing", FullServingFragmentDoc);

export const resolverMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isAutosaving: (src: FullMealOptionPendingFragment, args: any, context: ResolverContext): boolean => {
    return getFragmentOrInit(src, context).isAutosaving;
  },
};

export const mutationResolverMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editMealOptionPendingLocal: (src: any, { input }: MutationEditMealOptionPendingLocalArgs, context: ResolverContext) => {
    const { mealOptionPendingId, servingAmounts, note, name } = input;
    const editFragment = getFragment(mealOptionPendingId, context);
    const newServingAmounts = servingAmountsFromLocalInputs(servingAmounts, context);
    const upToDate =
      areServingAmountsEqual(editFragment.servingAmounts, newServingAmounts) && note === editFragment.note && name === editFragment.name;

    writeFragment(
      {
        ...editFragment,
        servingAmounts: newServingAmounts,
        note,
        isAutosaving: !upToDate,
        name,
      },
      context
    );

    if (!editFragment.isAutosaving && !upToDate) {
      runEditMealOptionPendingMutation(context, {
        input: {
          mealOptionPendingId,
          mealOptionPending: {
            note,
            name,
            servingAmounts: servingAmounts.map(({ servingId, amount, position }) => ({
              servingId,
              amount,
              position,
            })),
          },
        },
      });
    }
  },
};

const servingAmountsFromLocalInputs = (inputs: readonly ServingAmountLocalInput[], context: ResolverContext) => {
  return inputs.map(({ servingId, ...input }) => ({
    id: newId(),
    __typename: "ServingAmount" as const,
    serving: servingFragmentFuncs.getFragment(servingId, context),
    ...input,
  }));
};

const runEditMealOptionPendingMutation = (context: ResolverContext, variables: EditMealOptionPendingMutationVariables) => {
  const {
    mealOptionPendingId,
    mealOptionPending: { note, name },
  } = variables.input;
  const mutation = context.client.mutate;
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  mutation<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>({
    mutation: EditMealOptionPendingDocument,
    variables,
    // This prevents the mutation result from being used to update local state
    updateQueries: { GetEditMealPlan: record => record },
    update: (_, { data, errors }) => {
      if (errors) {
        console.error("Mutation failed!!!");
        return;
      }

      if (data) {
        const editFragment = getFragment(mealOptionPendingId, context);
        const currentServingAmounts = editFragment.servingAmounts;
        const upToDate =
          areServingAmountsEqual(currentServingAmounts, data.editMealOptionPending.mealOptionPending.servingAmounts) &&
          note === data.editMealOptionPending.mealOptionPending.note &&
          name === data.editMealOptionPending.mealOptionPending.name;

        if (!upToDate) {
          const newVariables = {
            input: {
              mealOptionPendingId,
              mealOptionPending: {
                name,
                note,
                servingAmounts: currentServingAmounts.map(({ serving, position, amount }) => ({
                  servingId: serving.id,
                  position,
                  amount,
                })),
              },
            },
          };
          runEditMealOptionPendingMutation(context, newVariables);
        } else {
          writeFragment(
            {
              ...editFragment,
              isAutosaving: false,
            },
            context
          );
        }
      }
    },
  });
};

const areServingAmountsEqual = (sa1: readonly FullServingAmountFragment[], sa2: readonly FullServingAmountFragment[]): boolean => {
  if (sa1.length !== sa2.length) return false;
  return sa1.every(({ serving, amount, position }) => {
    const matchingServingAmount = sa2.find(sa => sa.serving.id === serving.id);
    return matchingServingAmount && matchingServingAmount.amount === amount && matchingServingAmount.position === position;
  });
};

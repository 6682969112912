import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import CalendarEventLabel from "../../../../components/Calendar/Week/EventLabel";
import { RenderCalendarEventArgs } from "../../../../components/Calendar/Week/utils";
import { MealMenuInstance } from "../../types";
import { getHueForMealMenu, getTextColor } from "../color";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkRoot: {
      alignSelf: "flex-start",
      padding: theme.spacing(0.25, 0.5, 0.25, 0.25),
      fontSize: 16,
    },
  })
);

interface MenusCalendarEventFinishedProps {
  mealMenu: MealMenuInstance;
  args: RenderCalendarEventArgs;
  clientTimezone: string;
  bulkEditSelected: boolean;
  onBulkEditChangeFinished: (selected: boolean) => void;
  inBulkEdit: boolean;
}

const MenusCalendarEventFinishedPaper = ({
  mealMenu,
  args,
  clientTimezone,
  bulkEditSelected,
  onBulkEditChangeFinished,
  inBulkEdit,
}: MenusCalendarEventFinishedProps) => {
  const classes = useStyles();
  const { PaperProps, isAbbreviated, slot } = args;

  const hue = getHueForMealMenu(mealMenu.type, slot);
  const backgroundColor = `hsl(${hue}, 60%, 90%)`;
  const textColor = getTextColor(backgroundColor);

  return (
    <Paper
      {...PaperProps}
      style={{
        ...PaperProps.style,
        backgroundColor,
        border: mealMenu.athleteCount === 0 ? "1px solid red" : "",
        display: "flex",
        justifyContent: "flex-start",
        color: textColor,
      }}
      onClick={inBulkEdit ? () => onBulkEditChangeFinished(!bulkEditSelected) : PaperProps.onClick}
    >
      {inBulkEdit && bulkEditSelected && <CheckBoxIcon className={classes.checkRoot} style={{ color: textColor }} />}
      <CalendarEventLabel
        primary={mealMenu.name}
        isAbbreviated={isAbbreviated}
        start={mealMenu.start}
        durationInMinutes={mealMenu.durationInMinutes}
        clientTimezone={clientTimezone}
      />
    </Paper>
  );
};

export default MenusCalendarEventFinishedPaper;

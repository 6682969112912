import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { useRefetchQueriesContext } from "../../../../../contexts/RefetchQueries";
import { useAddSportMutation } from "../../../../../types";

interface SportModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateSportModal = ({ open, onClose }: SportModalProps) => {
  const [sportName, setSportName] = useState("");
  const refetchQueries = useRefetchQueriesContext();

  const [addSport] = useAddSportMutation({
    refetchQueries,
  });

  const handleSave = () => {
    addSport({
      variables: { input: { name: sportName } },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}>
      <DialogTitle title="Add Sport" onClose={onClose} />
      <DialogContent>
        <TextField
          // MUI bug - https://github.com/mui/material-ui/issues/31185
          margin="dense"
          label="Sport Name"
          value={sportName}
          onChange={e => setSportName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSportModal;

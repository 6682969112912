import { Box, Tooltip, TooltipProps, Typography } from "@mui/material";
import React from "react";

interface TWTooltipProps extends TooltipProps {
  subcopy: string;
}

export const TWTooltip = ({ title, subcopy, ...tooltipProps }: TWTooltipProps) => {
  const tooltipTitle = (
    <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 0.5 }}>
      <Typography sx={{ color: "highEmphasisTextDark" }} variant="subtitle1Semibold">
        {title}
      </Typography>
      <Typography sx={{ color: "mediumEmphasisTextDark" }} variant="subtitle1">
        {subcopy}
      </Typography>
    </Box>
  );
  return <Tooltip {...tooltipProps} title={tooltipTitle} />;
};

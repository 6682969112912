import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import { useRecipesByIdQuery } from "../../../../types";
import { PrintableRecipe } from "./PrintableRecipe";

const useStyles = makeStyles(({ spacing, shape, palette: { grey } }: Theme) =>
  createStyles({
    printPreviewBackground: {
      backgroundColor: grey[300],
      padding: spacing(3),
      borderRadius: shape.borderRadius,
    },
    newPage: {
      pageBreakBefore: "always",
    },
  })
);

interface PrintRecipeDialogProps {
  recipeId: string;
  onClose: () => void;
}

export function RecipePrintDialog({ recipeId, onClose }: PrintRecipeDialogProps) {
  const {
    palette: { warning },
  } = useTheme();
  const classes = useStyles();
  const componentRef = useRef(null);
  const [includeImage, setIncludeImage] = useState(true);

  const { data: recipeData, loading } = useRecipesByIdQuery({
    variables: {
      ids: recipeId,
    },
  });
  const handleClickPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: recipeData?.recipesById[0].name,
    // specify a margin so subsequent pages don't hug the top of the page
    // this overrides the default from react-to-print which sets the margin to 0 to avoid printing the header and footer
    pageStyle: `
      @page {
        margin: 16px 0 0 16px;
      }
    `,
  });

  if (loading || !recipeData) {
    return <LoadingBackdrop open={true} onClose={onClose} />;
  }

  const recipe = recipeData.recipesById[0];
  const hasImage = recipe.images && recipe.images.length > 0;
  const hasNestedRecipes = recipe.ingredients.some(ingredient => ingredient.serving.foodOrRecipe.__typename === "Recipe");

  return (
    <Dialog
      maxWidth="lg"
      open={true}
      onClose={onClose}>
      <DialogTitle title="Print Recipe" onClose={onClose} />
      <DialogContent>
        {hasNestedRecipes && (
          <Box
            sx={{
              backgroundColor: warning.lighter,
              p: 2,
              mb: 2,
              borderRadius: "6px",
            }}
          >
            <Typography>* Additional recipes within this recipe must be printed separately</Typography>
          </Box>
        )}
        <div className={classes.printPreviewBackground}>
          <div ref={componentRef}>
            <PrintableRecipe recipe={recipe} includeImage={includeImage} />
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display={"flex"}>
          <FormControlLabel
            label={"Include image"}
            labelPlacement="end"
            control={<Checkbox
              checked={hasImage && includeImage}
              disabled={!hasImage}
              onChange={(_, value) => setIncludeImage(value)} />}
          />
        </Box>
        <Box display={"flex"} gap={1}>
          <Button
            variant="outlined"
            onClick={onClose}
            aria-label="Cancel">
            Cancel
          </Button>
          <Button onClick={handleClickPrint} aria-label="Print">
            Print
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

import InfoIcon from "@mui/icons-material/Info";
import { Box, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import React, { ChangeEvent, Dispatch } from "react";
import { MacroProtocolAction, MacroProtocolState } from "../reducer/macroProtocolReducer";
import AnthropometryTable from "./AnthropometryTable";

interface AnthropometryProps {
  state: MacroProtocolState;
  dispatch: Dispatch<MacroProtocolAction>;
}

const Anthropometry = ({ state, dispatch }: AnthropometryProps) => {
  const { isMetricLocale } = useLocaleContext();
  const onSwitchAnthroType = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: "SWITCH_ANTHRO_TYPE",
      payload: event.target.value as "formFields" | "template",
      isMetricLocale,
    });
  const onSwitchUseExistingAnthro = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: "EDIT_USE_EXISTING_ANTHRO",
      payload: event.target.value === "true",
    });

  const requiredFieldsTooltipCaption = "Required fields are dependent on the RMR methods listed in the table below";
  const hideAnthropometryTooltipCaption =
    "Athletes with the “Hide Anthropometry” display setting will never be shown a form to enter anthropometry data";
  const useFormFields = state.anthropometry.__typename === "FormFields";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography>
          Use athlete’s most recent anthropometry entry containing all <b>required fields</b>?
          <Tooltip title={requiredFieldsTooltipCaption}>
            <InfoIcon sx={{ pl: 1 }} />
          </Tooltip>
        </Typography>
        <RadioGroup
          sx={{ pt: 0, pl: 2 }}
          value={state.useExistingAnthropometry.toString()}
          onChange={onSwitchUseExistingAnthro}>
          <FormControlLabel
            value="true"
            control={<Radio />}
            label="Yes" />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="No" />
        </RadioGroup>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography>In the case that an athlete does not have an anthropometry entry with all required fields, then:</Typography>
        <RadioGroup
          sx={{ pl: 2 }}
          value={useFormFields ? "formFields" : "template"}
          onChange={onSwitchAnthroType}>
          <FormControlLabel
            value="formFields"
            control={<Radio />}
            label={
              <Typography>
                Collect anthropometry data from athlete
                <Tooltip title={hideAnthropometryTooltipCaption}>
                  <InfoIcon sx={{ pl: 1 }} />
                </Tooltip>
              </Typography>
            }
          />
          <FormControlLabel
            value="template"
            control={<Radio />}
            label="Assign default anthropometry values to athlete" />
        </RadioGroup>
      </Box>
      <Typography>
        {useFormFields
          ? "What anthropometry fields should be collected from athletes?"
          : "What values should be used for default anthropometry?"}
      </Typography>
      <AnthropometryTable state={state.anthropometry} dispatch={dispatch} />
    </Box>
  );
};

export default Anthropometry;

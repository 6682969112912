import { EditImperialMacroProtocolState, EditMetricMacroProtocolState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";

const ATHLETE_EDIT_IMPERIAL_MACRO_PROTOCOL_STATE_KEY = "AthleteEditMacroProtocolState";
const ATHLETE_EDIT_METRIC_MACRO_PROTOCOL_STATE_KEY = "AthleteEditMetricMacroProtocolState";

export const deserializeImperialMacroProtocolState = (athleteId: string, state: string | null): EditImperialMacroProtocolState | null => {
  if (!state) {
    return null;
  }
  try {
    const data: EditImperialMacroProtocolState = JSON.parse(state);
    const requiredFields: (keyof EditImperialMacroProtocolState)[] = [
      "anthroInfo",
      "cho",
      "pro",
      "fat",
      "weightTarget",
      "weightTargetInput",
      "usingWeightTarget",
      "calorieBudget",
      "usingCalorieBudget",
    ];
    requiredFields.forEach(f => {
      if (data[f] === undefined) {
        throw new Error(`'${f}' missing from macroProtocol in localStorage`);
      }
    });
    const { anthroInfo, cho, pro, fat, weightTarget, weightTargetInput, usingWeightTarget, calorieBudget, usingCalorieBudget } = data;
    // Ensure type-checks break this function if the fragment  changes
    return {
      anthroInfo,
      cho,
      pro,
      fat,
      weightTarget,
      weightTargetInput,
      usingWeightTarget,
      calorieBudget,
      usingCalorieBudget,
      __typename: "imperial",
    };
  } catch (e) {
    if (e instanceof Error) {
      console.error("Error parsing macroProtocol state from localStorage:", e.message);
    }
    localStorage.removeItem(`${ATHLETE_EDIT_IMPERIAL_MACRO_PROTOCOL_STATE_KEY}:${athleteId}`);
    return null;
  }
};

export const deserializeMetricMacroProtocolState = (athleteId: string, state: string | null): EditMetricMacroProtocolState | null => {
  if (!state) {
    return null;
  }
  try {
    const data: EditMetricMacroProtocolState = JSON.parse(state);
    const requiredFields: (keyof EditMetricMacroProtocolState)[] = [
      "anthroInfo",
      "cho",
      "pro",
      "fat",
      "weightTargetInKg",
      "weightTargetInput",
      "usingWeightTarget",
      "calorieBudget",
      "usingCalorieBudget",
    ];
    requiredFields.forEach(f => {
      if (data[f] === undefined) {
        throw new Error(`'${f}' missing from macroProtocol in localStorage`);
      }
    });
    const { anthroInfo, cho, pro, fat, weightTargetInKg, weightTargetInput, usingWeightTarget, calorieBudget, usingCalorieBudget } = data;
    // Ensure type-checks break this function if the fragment  changes
    return {
      anthroInfo,
      cho,
      pro,
      fat,
      weightTargetInKg,
      weightTargetInput,
      usingWeightTarget,
      calorieBudget,
      usingCalorieBudget,
      __typename: "metric",
    };
  } catch (e) {
    if (e instanceof Error) {
      console.error("Error parsing macroProtocol state from localStorage:", e.message);
    }
    localStorage.removeItem(`${ATHLETE_EDIT_METRIC_MACRO_PROTOCOL_STATE_KEY}:${athleteId}`);
    return null;
  }
};

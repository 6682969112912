import { MenuOrder, MenuOrderItem, MenuOrderItemStatus } from "../types";

interface StatusifiableOrder {
  itemsByDiningStation: readonly { status: string }[];
}

export const statusToSortOrder = (status: MenuOrderItemStatus): number => {
  switch (status) {
    case "cancelled":
      return 1;
    case "done":
      return 2;
    case "cooking":
      return 3;
    case "new":
      return 4;
    case "%future added value":
      return 5;
  }
};

export const reduceStatuses = (statuses: MenuOrderItemStatus[]): MenuOrderItemStatus => {
  if (statuses.length === 0) {
    return "new";
  }
  const nonCancelledStatuses = statuses.filter(s => s !== "cancelled");
  if (nonCancelledStatuses.length === 0) {
    return "cancelled";
  }

  if (nonCancelledStatuses.every(s => s === "done")) {
    // Everything is done
    return "done";
  } else if (nonCancelledStatuses.some(s => s === "done")) {
    // Some are done (others can be new, others can be cooking)
    return "cooking";
  } else if (nonCancelledStatuses.some(s => s === "cooking")) {
    // Nothing done, but some things are cooking
    return "cooking";
  } else {
    // Nothing is done, nothing is cooking, so order is new!
    return "new";
  }
};

export const reduceStatusesForOrders = (
  orders: readonly (Pick<MenuOrder, "id"> & { readonly items: readonly Pick<MenuOrderItem, "status">[] })[] | undefined
): MenuOrderItemStatus => {
  if (!orders || orders.length === 0) {
    return "new";
  }
  return reduceStatuses(orders.flatMap(order => order.items.map(item => item.status)));
};

export const statusifyOrder = <T>(order: StatusifiableOrder & T): T & { status: MenuOrderItemStatus } => {
  const statuses = order.itemsByDiningStation.map(i => i.status as MenuOrderItemStatus);
  const status = reduceStatuses(statuses);

  return { ...order, status };
};

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import TablePage from ".";
import { OffsetPageInfo } from "../../../types";
import { PaginationHooks } from "../../../utils/pagination";
import TablePageDefaultHeader from "./DefaultHeader";

type SimpleType = {
  id: string;
  name: string;
};

interface SimpleTableProps {
  data?: {
    edges: readonly SimpleType[];
    pageInfo: OffsetPageInfo;
  };
  loading: boolean;
  paginationHooks: PaginationHooks;
  onEdit: (entity: SimpleType) => void;
  onDelete: (id: string) => void;
  onCreate: () => void;
  title: string;
}

const SimpleTable = ({ data, loading, paginationHooks, onEdit, onCreate, onDelete, title }: SimpleTableProps) => {
  const { queryText, onChangeQueryText } = paginationHooks;
  const Header = <TablePageDefaultHeader
    title={title}
    queryText={queryText}
    onChangeQueryText={onChangeQueryText}
    onCreate={onCreate} />;

  const TableBodyRows = data?.edges.map(item => (
    <TableRow key={item.id}>
      <TableCell scope="row">
        <IconButton onClick={() => onEdit(item)} size="large">
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell scope="row">{item.name}</TableCell>
      <TableCell scope="row" align="right">
        <IconButton
          onClick={e => {
            e.stopPropagation();
            onDelete(item.id);
          }}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  const TableHeaderRow = () => (
    <TableRow>
      <TableCell padding="checkbox">Edit</TableCell>
      <TableCell>Name</TableCell>
      <TableCell align="right">Delete</TableCell>
    </TableRow>
  );

  return (
    <TablePage
      header={Header}
      tableHeaderRow={<TableHeaderRow />}
      tableBodyRows={TableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={data?.pageInfo.total || 0}
    />
  );
};

export default SimpleTable;

import React from "react";

import LabeledSelectNoId from "../universal/LabeledSelectNoId";
import { anthropometryEntryTypeToName } from "./utils";

import { AnthropometryEntryType } from "../../types";

interface AnthropometryEntryTypeSelectProps {
  selectedType: AnthropometryEntryType | null;
  onChange: (type: AnthropometryEntryType) => void;
  className?: string;
  error?: boolean;
  helperText?: string;
}

const AnthropometryEntryTypeSelect = ({ selectedType, onChange, error, helperText }: AnthropometryEntryTypeSelectProps) => {
  return (
    <LabeledSelectNoId<AnthropometryEntryType>
      sx={{ flex: "1 1 0", width: 0, maxWidth: "50%" }}
      placeholder="Type"
      options={["bioelectricalImpedance", "bodpod", "dexa", "estimate", "isak", "weighIn"]}
      selectedOption={selectedType}
      onChange={onChange}
      optionToName={anthropometryEntryTypeToName}
      error={error}
      helperText={helperText}
    />
  );
};

export default AnthropometryEntryTypeSelect;

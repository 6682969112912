import { round } from "@notemeal/shared/ui/utils/numbers";
import { UnitWithConversionsFragment } from "../../../types";
import { ServingUnitsState } from "../utils";

export const getGramValueOrNullForUnit = (
  units: readonly UnitWithConversionsFragment[],
  unitId: string,
  gram: UnitWithConversionsFragment
): number | null => {
  const unitConversions = units.find(unit => unit.id === unitId);

  if (unitConversions) {
    if (unitConversions.id === gram.id) {
      return 1;
    } else {
      const maybeUnitConversion = unitConversions.conversionsFrom.find(cf => cf.toUnit.id === gram.id);
      if (!!maybeUnitConversion) {
        return maybeUnitConversion.toQuantity / maybeUnitConversion.fromQuantity;
      }
    }
  }
  return null;
};

interface getWeightAndAmountFromImportedServingProps {
  _weight: number;
  _defaultAmount: number;
  unitsState: ServingUnitsState;
  units: readonly UnitWithConversionsFragment[];
  gram: UnitWithConversionsFragment;
}

interface SimpleServing {
  weight: number;
  defaultAmount: number;
}

export const getWeightAndAmountFromImportedServing = ({
  _weight,
  _defaultAmount,
  unitsState,
  units,
  gram,
}: getWeightAndAmountFromImportedServingProps): SimpleServing => {
  const weight = _weight / _defaultAmount;
  const unitWeight = unitsState.unit && units ? getGramValueOrNullForUnit(units, unitsState.unit.id, gram) : null;
  const validServing = !unitWeight || (unitWeight && withinTenPercent({ expectedValue: unitWeight, value: weight }));
  if (validServing) {
    return { defaultAmount: _defaultAmount, weight };
  } else {
    const roundedDA = round(_defaultAmount * (weight / (unitWeight || 1)), 1);
    return {
      defaultAmount: roundedDA,
      weight: unitWeight || 1,
    };
  }
};

interface withinTenPercentProps {
  value: number;
  expectedValue: number;
}
export const withinTenPercent = ({ value, expectedValue }: withinTenPercentProps) => {
  const lowerBound = expectedValue * 0.9;
  const upperBound = expectedValue * 1.1;
  return value < upperBound && value > lowerBound;
};

import { z } from "zod";

export const AccountVerificationMethodFormSchema = z.object({
  accountVerificationMethod: z.enum(["email", "txt", "skip"]),
});

export type AccountVerificationMethodFormType = z.infer<typeof AccountVerificationMethodFormSchema>;

export const accountVerificationMethodFormDefaultValues = (): AccountVerificationMethodFormType => ({
  accountVerificationMethod: "skip",
});

import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { RoleRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { ExchangeSetsPage } from "./ExchangeSetsPage";
import { MealPlanTemplatesPage } from "./MealPlanTemplatesPage";
import { NAV_MEAL_PLANS_EXCHANGE_SETS, NAV_MEAL_PLANS_MEAL_PLAN_TEMPLATES, mealPlansLink } from "./MealPlansPaths";

const secondaryNavLayoutProps: SecondaryNavLayoutProps = {
  current: mealPlansLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Meal Plan Templates",
      to: NAV_MEAL_PLANS_MEAL_PLAN_TEMPLATES,
    },
    {
      name: "Exchange Sets",
      to: NAV_MEAL_PLANS_EXCHANGE_SETS,
    },
  ],
};

export const MealPlansRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route element={<RoleRoute roles={["dietitian"]} />}>
        <Route index element={<MealPlanTemplatesPage />} />
        <Route path={NAV_MEAL_PLANS_MEAL_PLAN_TEMPLATES} element={<MealPlanTemplatesPage />} />
        <Route path={NAV_MEAL_PLANS_EXCHANGE_SETS} element={<ExchangeSetsPage />} />
      </Route>
    </Route>
  </Routes>
);

import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";

export const darkIconButtonStyle = {
  opacity: 0.85,
  backgroundColor: "grey.700",
  color: "white",
  "&:hover": {
    backgroundColor: "grey.800",
    color: "white",
  },
};

const DarkIconButton = ({ sx, ...props }: IconButtonProps) => {
  return <IconButton
    {...props}
    sx={{ ...darkIconButtonStyle, ...sx }}
    size="large" />;
};

export default DarkIconButton;

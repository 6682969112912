import { loadCsv } from "../../../../../utils/import/load";
import { ImportedField, parseDelimitedFile } from "../../../../../utils/import/parse";
import { IFileImport } from "../../../../universal/Import/Button";
import { ImportableAthlete } from "../types";
import { SexType } from "../../../../../types";
import { stripPunctuation } from "../../../../../utils/import/punctuation";
import { getPhoneNumberGroups } from "@notemeal/utils/phone-number";

interface GeneralCsvRosterFileImportFields {
  "First Name": string;
  "Last Name": string;
  Position: string;
  Team: string;
  Birthdate: string | null; // 01/01/1992
  Email: string;
  Phone: string | null;
  Sex: SexType | null;
  Weight: string | null;
  Height: string | null;
  "Inbody ID": string | null;
}

export type GeneralCsvRosterFileImportFieldsKeys = { [key in keyof GeneralCsvRosterFileImportFields]: string };
type I = ImportableAthlete;
type R = GeneralCsvRosterFileImportFieldsKeys;

const numberFields: ImportedField<R, I>[] = [
  {
    fields: ["Weight"],
    importableField: "weight",
  },
  {
    fields: ["Height"],
    importableField: "height",
  },
];
const stringFields: ImportedField<R, I>[] = [
  {
    fields: ["Team"],
    importableField: "teamName",
    required: true,
  },
  {
    fields: ["First Name"],
    importableField: "firstName",
    required: true,
  },
  {
    fields: ["Last Name"],
    importableField: "lastName",
    required: true,
  },
  {
    fields: ["Position"],
    importableField: "positionName",
  },
  {
    fields: ["Email"],
    importableField: "email",
    required: true,
  },
  {
    fields: ["Phone"],
    importableField: "phoneNumber",
    transform: (phoneNumber: string | null) => {
      if (!phoneNumber) {
        return phoneNumber;
      }
      // must remove spaces to allow for international phone numbers when matching our regex
      const groups = getPhoneNumberGroups(phoneNumber);
      if (!groups) {
        return phoneNumber;
      }
      const { countryCode, areaCode, exchange, subscriberNumber, extension } = groups;
      return `+${countryCode || "1"}(${areaCode}) ${exchange}-${subscriberNumber}${extension || ""}`;
    },
  },
  {
    fields: ["Sex"],
    importableField: "sex",
    transform: (sexish: string) =>
      ["m", "male", "m", "men", "mens"].includes(stripPunctuation(sexish?.toLowerCase() || ""))
        ? "male"
        : ["f", "female", "w", "women", "womens"].includes(stripPunctuation(sexish?.toLowerCase() || ""))
        ? "female"
        : null,
  },
  {
    fields: ["Inbody ID"],
    importableField: "inbodyUid",
    required: false,
  },
];

const dateFields: ImportedField<R, I>[] = [{ fields: ["Birthdate"], importableField: "birthDate" }];

export const loadGeneralCsvImportableAthletes = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  type F = GeneralCsvRosterFileImportFields;
  const rows = await loadCsv<F>({ file, onError });

  if (rows === null) {
    return null;
  }

  const res = parseDelimitedFile<R, I>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return res;
};

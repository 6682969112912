export interface NutrientAmountState {
  [nutrientName: string]: string;
}

export const FORM_NUTRIENTS = [
  "Carbohydrate, by difference",
  "Protein",
  "Total lipid (fat)",
  "Fatty acids, total saturated",
  "Fatty acids, total trans",
  "Cholesterol",
  "Sodium, Na",
  "Fiber, total dietary",
  "Sugars, total including NLEA",
  "Sugars, added",
  "Vitamin D (D2 + D3), International Units",
  "Calcium, Ca",
  "Iron, Fe",
  "Potassium, K",
] as const;

export const FORM_NUTRIENTS_WITHOUT_MACROS = FORM_NUTRIENTS.filter(
  nut => nut !== "Carbohydrate, by difference" && nut !== "Protein" && nut !== "Total lipid (fat)"
);

export const FORM_NUTRIENTS_MACROS_ONLY = FORM_NUTRIENTS.filter(
  nut => nut === "Carbohydrate, by difference" || nut === "Protein" || nut === "Total lipid (fat)"
);

import { Box, Button, Divider, FormControlLabel, Switch } from "@mui/material";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import { NAV_ORG_RECIPE } from "apps/web/src/pages/Auth/Org/FoodManagement/FoodManagementPaths";
import { NAV_STAFF_RECIPE } from "apps/web/src/pages/Auth/Staff/FoodManagement/FoodManagementRouter";
import React from "react";
import { useLocation } from "react-router-dom-v5-compat";

interface CreateRecipeFooterProps {
  handleClose: () => void;
  onChange: () => void;
  handleSave: () => void;
  canSaveTooltips: string[];
  forStaff?: boolean;
  isShared: boolean;
}

export const CreateRecipeFooter = ({ handleClose, onChange, handleSave, canSaveTooltips, forStaff, isShared }: CreateRecipeFooterProps) => {
  const location = useLocation();

  const isPageView = location.pathname.includes(NAV_ORG_RECIPE) || location.pathname.includes(NAV_STAFF_RECIPE);

  return !isPageView ? (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <FormControlLabel
          label={forStaff ? "Share with orgs" : "Share with athletes"}
          labelPlacement="end"
          checked={isShared}
          onChange={onChange}
          control={<Switch sx={{ ml: 1.5, mr: 1 }} />}
        />
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
            <Button onClick={handleSave}>Save</Button>
          </TWItemizedTooltip>
        </Box>
      </Box>
    </Box>
  ) : null;
};

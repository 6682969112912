import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { IconButton, ListItem, Menu, MenuItem, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { UnitFragment, useUnitConversionsFromQuery } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuHeader: {
      fontWeight: "bold",
      padding: theme.spacing(0.5, 2),
    },
    menuItemRoot: {
      minHeight: "auto",
    },
  })
);

interface ServingUnitsConversionButtonProps {
  unitId: string;
  tooltipTitle: string;
  menuTitle: string;
  onClick?: (unit: UnitFragment) => void;
  hideProductionOnlyUnits?: boolean;
  sx?: SxProps;
}

const ServingUnitsConversionButton = ({
  unitId,
  tooltipTitle,
  menuTitle,
  onClick,
  hideProductionOnlyUnits,
  sx,
}: ServingUnitsConversionButtonProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data } = useUnitConversionsFromQuery({
    variables: { id: unitId },
  });
  const allConvertToUnits = data?.unit.conversionsFrom.map(u => u.toUnit) || [];
  const convertToUnits = hideProductionOnlyUnits ? allConvertToUnits.filter(u => !u.isProductionOnly) : allConvertToUnits;

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton
          size="small"
          onClick={e => setAnchorEl(e.currentTarget)}
          sx={sx}>
          <SwapHorizIcon />
        </IconButton>
      </Tooltip>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <Typography variant="subtitle1" className={classes.menuHeader}>
            {menuTitle}
          </Typography>
          {convertToUnits.map(unit =>
            onClick ? (
              <MenuItem
                key={unit.id}
                classes={{ root: classes.menuItemRoot }}
                dense
                onClick={() => onClick(unit)}>
                <Typography variant="subtitle1">{unit.name}</Typography>
              </MenuItem>
            ) : (
              <ListItem
                key={unit.id}
                classes={{ root: classes.menuItemRoot }}
                dense>
                <Typography variant="subtitle1">{unit.name}</Typography>
              </ListItem>
            )
          )}
        </Menu>
      )}
    </>
  );
};

export default ServingUnitsConversionButton;

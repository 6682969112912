import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, MenuItem, TextField } from "@mui/material";
import { getNextPosition } from "@notemeal/shared/ui/utils/getNextPosition";
import { sortByKey } from "@notemeal/utils/sort";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import React, { useState } from "react";
import DialogTitle from "../../../componentLibrary/DialogTitle";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { MenuItemChoiceFormFragment, MenuItemChoiceOptionFormFragment } from "../../../types";
import { getMenuItemChoiceToolTip, newMenuItemChoiceOption } from "../utils";
import OptionForm from "./OptionForm";
import OptionList from "./OptionList";

interface MenuItemAddOnFullDialogProps {
  open: boolean;
  onClose: () => void;
  menuItemChoice: MenuItemChoiceFormFragment;
  onDone: (menuItemChoice: MenuItemChoiceFormFragment) => void;
}

const MenuItemAddOnFullDialog = ({ open, onClose, onDone, menuItemChoice: initMenuItemChoice }: MenuItemAddOnFullDialogProps) => {
  const [menuItemChoice, setMenuItemChoice] = useState(initMenuItemChoice);
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(
    initMenuItemChoice.options.length === 0
      ? null
      : sortByKey(initMenuItemChoice.options, "position")[initMenuItemChoice.options.length - 1].id
  );
  const selectedOption = menuItemChoice.options.find(o => o.id === selectedOptionId);

  const tooltipItems = getMenuItemChoiceToolTip(menuItemChoice);

  const handleDone = () => {
    if (tooltipItems.length) {
      return;
    }
    // Filter out blank options (no name AND no serving amount)
    const options = menuItemChoice.options.filter(o => Boolean(o.name));

    onDone({
      ...menuItemChoice,
      options,
    });
    onClose();
  };

  const handleAddOption = () => {
    const newOption = newMenuItemChoiceOption(getNextPosition(menuItemChoice.options));
    setMenuItemChoice({
      ...menuItemChoice,
      options: [...menuItemChoice.options, newOption],
    });
    setSelectedOptionId(newOption.id);
  };

  const onChangeAddOnOptionType = (addOnOptionType: string) => {
    setMenuItemChoice({
      ...menuItemChoice,
      required: addOnOptionType === "required",
      maxOptionsCount: addOnOptionType === "required" ? 1 : null,
    });
  };

  const handleEditOption = (menuItemChoiceOption: MenuItemChoiceOptionFormFragment, addChoice: boolean) => {
    const options = menuItemChoice.options.map(mico => (mico.id === menuItemChoiceOption.id ? menuItemChoiceOption : mico));
    if (addChoice) {
      const newOption = newMenuItemChoiceOption(getNextPosition(menuItemChoice.options));

      setMenuItemChoice({
        ...menuItemChoice,
        options: [...options, newOption],
      });

      setSelectedOptionId(newOption.id);
    } else {
      setMenuItemChoice({
        ...menuItemChoice,
        options,
      });
    }
  };

  const handleRemoveOption = (menuItemChoiceOptionId: string) => {
    setMenuItemChoice({
      ...menuItemChoice,
      options: menuItemChoice.options.filter(mico => mico.id !== menuItemChoiceOptionId),
    });
  };

  const handleChangeOrder = (menuItemChoiceOptionIds: string[]) => {
    const reorderedMenuItemChoiceOptions = menuItemChoiceOptionIds.flatMap((micoId, index) => {
      const matchingMenuItemChoiceOptions = menuItemChoice.options.find(mico => mico.id === micoId);
      return matchingMenuItemChoiceOptions
        ? [
            {
              ...matchingMenuItemChoiceOptions,
              position: index + 1,
            },
          ]
        : [];
    });
    setMenuItemChoice({
      ...menuItemChoice,
      options: reorderedMenuItemChoiceOptions,
    });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Add-on" onClose={onClose} />
      <DialogContent sx={{ gap: 2, height: "900px", overflowY: "visible" }}>
        <SectionHeader header="Add-on Details" subcopy="Enter the add-on details below" />
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 2 }}>
          <TextField
            autoFocus={!menuItemChoice.name}
            placeholder='e.g. "Veggies"'
            label="Add-on name"
            value={menuItemChoice.name}
            sx={{
              width: 3.5 / 9,
            }}
            helperText='e.g. "Veggies" to add "peppers", "onions" to an omelette'
            FormHelperTextProps={{
              sx: { ml: 0 },
            }}
            onChange={e =>
              setMenuItemChoice({
                ...menuItemChoice,
                name: e.target.value,
              })
            }
          />
          <TextField
            sx={{ width: 2 / 9 }}
            select
            label="Required or Optional"
            helperText=" "
            value={selectedOption}
            onChange={e => onChangeAddOnOptionType(e.target.value)}
          >
            <MenuItem value={"required"}>Required</MenuItem>
            <MenuItem value={"optional"}>Optional</MenuItem>
          </TextField>
          <FormControlLabel
            sx={{ ml: -1.5, mr: 0, mb: 2 }}
            label="Limit options"
            disabled={menuItemChoice.required}
            control={
              <Checkbox
                checked={menuItemChoice.maxOptionsCount !== null}
                onChange={e =>
                  setMenuItemChoice({
                    ...menuItemChoice,
                    maxOptionsCount: e.target.checked ? 1 : null,
                  })
                }
              />
            }
          />

          {menuItemChoice.maxOptionsCount !== null && (
            <TextField
              label="Max options"
              helperText=" "
              type="number"
              disabled={menuItemChoice.required}
              inputProps={{
                min: 1,
                max: menuItemChoice.options.length,
              }}
              sx={{ minWidth: 100 }}
              value={menuItemChoice.maxOptionsCount}
              onChange={e =>
                setMenuItemChoice({
                  ...menuItemChoice,
                  maxOptionsCount: parseInt(e.target.value),
                })
              }
              error={isNaN(menuItemChoice.maxOptionsCount)}
            />
          )}
        </Box>
        <Divider sx={{ my: 3 }} />
        <SectionHeader header="Add-on Choices" subcopy="Athletes will choose from these options while ordering" />
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Box sx={{ width: 1 / 2 }}>
            <OptionList
              // List is draggable, so must be re-rendered (need new hooks) when things change
              key={sortByKey(menuItemChoice.options, "position")
                .map(o => o.id)
                .join(",")}
              options={menuItemChoice.options}
              selectedOptionId={selectedOptionId}
              exclusive={menuItemChoice.required && menuItemChoice.maxOptionsCount === 1}
              onAddOption={handleAddOption}
              onChangeOrder={handleChangeOrder}
              onSelectOption={setSelectedOptionId}
              onRemoveOption={handleRemoveOption}
            />
          </Box>
          <Box sx={{ width: 1 / 2 }}>
            <OptionForm option={selectedOption} onChange={handleEditOption} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following:" items={tooltipItems}>
          <Button onClick={handleDone}>Done</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default MenuItemAddOnFullDialog;

import { Tooltip, Typography } from "@mui/material";
import React from "react";

interface TooltipProps {
  msg: string;
  open?: boolean;
  hidden?: boolean;
  children: React.ReactElement;
}

export const WarningTooltip = ({ msg, children, open, hidden = false }: TooltipProps) => {
  return (
    <Tooltip
      open={open}
      title={
        hidden ? (
          ""
        ) : (
          <>
            <Typography sx={{ color: "warning.light", fontWeight: 600 }}>Warning! </Typography>
            {msg}
          </>
        )
      }
    >
      {children}
    </Tooltip>
  );
};

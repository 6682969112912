import TimerOffIcon from "@mui/icons-material/TimerOff";
import { Button, SxProps, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { MenuOrderRateLimitFragment, TeamMealMenuPreviewFragment } from "../../../../types";
import Dialog from "./Dialog";

interface MenuOrderRateLimitButtonProps {
  orderRateLimit: MenuOrderRateLimitFragment | null;
  onChangeOrderRateLimit: (value: MenuOrderRateLimitFragment | null) => void;
  teams: readonly TeamMealMenuPreviewFragment[];
  sx?: SxProps;
}

const MenuOrderRateLimitButton = ({ orderRateLimit, onChangeOrderRateLimit, teams, sx }: MenuOrderRateLimitButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title="Edit Ordering Rate Limits">
        <Button
          variant="text"
          startIcon={<TimerOffIcon sx={{ color: "greyscale.500" }} />}
          sx={sx}
          onClick={() => setOpen(true)}>
          {orderRateLimit === null ? "No order rate limit" : `${orderRateLimit.limit} per 15m`}
        </Button>
      </Tooltip>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          orderRateLimit={orderRateLimit}
          onChangeOrderRateLimit={onChangeOrderRateLimit}
          teams={teams}
        />
      )}
    </>
  );
};

export default MenuOrderRateLimitButton;

import { ResolverContext } from "./types";

type HasId = { id: string };

export interface fragmentFuncs<F extends HasId> {
  getFragment: (id: string, context: ResolverContext) => F;
  writeFragment: (data: F, context: ResolverContext) => void;
}

export interface fragmentFuncsWithInit<F extends HasId, S extends HasId> extends fragmentFuncs<F> {
  getFragmentOrInit: (src: S, context: ResolverContext) => F;
}

export const makeFragmentFuncs = <F extends HasId>(__typename: string, fragmentName: string, fragmentDoc: any): fragmentFuncs<F> => {
  const getFragment = <F extends HasId>(id: string, { cache, getCacheKey }: ResolverContext): F => {
    const cacheKey = getCacheKey({ __typename, id });
    const fragment: F | null = cache.readFragment({
      fragment: fragmentDoc,
      fragmentName,
      id: cacheKey,
    });
    if (fragment) {
      return fragment;
    } else {
      throw new Error(`No fragment named ${fragmentName} found for ${cacheKey}`);
    }
  };

  const writeFragment = <F extends HasId>(data: F, context: ResolverContext): void => {
    const { client, getCacheKey } = context;
    const cacheKey = getCacheKey({ __typename, id: data.id });

    client.writeFragment({
      fragment: fragmentDoc,
      fragmentName,
      id: cacheKey,
      data: { __typename, ...data },
    });
  };

  return {
    getFragment,
    writeFragment,
  };
};

export const makeFragmentFuncsWithInit = <F extends HasId, S extends HasId>(
  __typename: string,
  fragmentName: string,
  fragmentDoc: any,
  initFragment: (src: S, context: ResolverContext) => F
): fragmentFuncsWithInit<F, S> => {
  const { getFragment, writeFragment } = makeFragmentFuncs<F>(__typename, fragmentName, fragmentDoc);

  const getFragmentOrInit = (src: S, context: ResolverContext): F => {
    try {
      return getFragment(src.id, context);
    } catch (e) {
      // First time being called, need to initialize
      const newFragment = initFragment(src, context);
      writeFragment(newFragment, context);
      return newFragment;
    }
  };

  return {
    getFragment,
    getFragmentOrInit,
    writeFragment,
  };
};

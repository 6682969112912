import { Button, DialogContent, Divider, List, ListItem, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LatLng } from ".";
import { PossibleLocation } from "../../../../../components/Map/types";
import { GoogleMapsAutocompleteStyles, placesLibraries } from "../../../GeneralizedStaffContent/GoogleMapsUtilities";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: 0,
      display: "flex",
    },
    searchDiv: {
      height: "100%",
      width: 225,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    searchBar: {
      boxSizing: "border-box",
      border: "1px solid transparent",
      width: "calc(100% - 18px)",
      height: "32px",
      padding: "0 12px",
      borderRadius: "3px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      fontSize: "14px",
      outline: "none",
      textOverflow: "ellipses",
      margin: theme.spacing(),
    },
    searchResults: {
      flexGrow: 1,
      overflow: "auto",
    },
    mapDiv: {
      flexGrow: 1,
      position: "relative",
    },
    map: {
      height: "100%",
      width: "100%",
    },
    selectedLocation: {
      position: "absolute",
      bottom: theme.spacing(2),
      width: 250,
      marginLeft: -125,
      left: "50%",
      zIndex: 10,
    },
  })
);

interface RestaurantLocationsAddDialogContentProps {
  googleMapsApiKey: string;
  onAddLocation: (location: PossibleLocation) => void;
  placesServiceRef: React.MutableRefObject<google.maps.places.PlacesService | null>;
  findPosiblePlacesFromLatLong: (location: LatLng) => void;
  possibleLocations: PossibleLocation[];
  resetLatLng: () => void;
}

const RestaurantLocationsAddDialogContent = ({
  googleMapsApiKey,
  onAddLocation,
  placesServiceRef,
  findPosiblePlacesFromLatLong,
  possibleLocations,
  resetLatLng,
}: RestaurantLocationsAddDialogContentProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: placesLibraries,
  });

  const CENTRAL_US_LATLNG = { lat: 37, lng: -97 };

  const classes = useStyles();
  const [selectedPossibleLocation, setSelectedPossibleLocation] = useState<PossibleLocation | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const autocompleteTextRef = React.createRef<HTMLInputElement>();

  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoadMap = useCallback(
    (map: google.maps.Map) => {
      placesServiceRef.current = new google.maps.places.PlacesService(map);
      mapRef.current = map;
    },
    [placesServiceRef]
  );
  const onUnmountMap = useCallback(() => {
    placesServiceRef.current = null;
    mapRef.current = null;
  }, [placesServiceRef]);

  const onLoadAutocomplete = useCallback((autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);

  useEffect(() => {
    const autocomplete = autocompleteTextRef.current;
    if (autocomplete && autocomplete.value === "") {
      resetLatLng();
    }
  }, [autocompleteTextRef, resetLatLng]);

  //Handles moving the map when locations are populated
  useEffect(() => {
    if (possibleLocations.length === 0) {
      return;
    } else {
      var bounds = new google.maps.LatLngBounds();
      for (var i in possibleLocations) {
        bounds.union(
          new google.maps.LatLngBounds({
            lat: possibleLocations[i].lat,
            lng: possibleLocations[i].long,
          })
        );
      }
      const map = mapRef.current;
      if (map) {
        map.fitBounds(bounds);
      }
    }
  }, [possibleLocations]);

  const onPlaceChangedAutocomplete = useCallback(() => {
    const autocomplete = autocompleteRef.current;
    if (!autocomplete) {
      return;
    }
    const place = autocomplete.getPlace();
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    const placesService = placesServiceRef.current;
    const map = mapRef.current;
    if (!placesService || !lat || !lng || !map) {
      return;
    }
    map.panTo({ lat, lng });
    findPosiblePlacesFromLatLong({ lat, lng });
  }, [findPosiblePlacesFromLatLong, placesServiceRef]);

  const onClickPossibleLocation = (location: PossibleLocation) => {
    const map = mapRef.current;
    if (map) {
      map.panTo({ lat: location.lat, lng: location.long });
      map.setZoom(15);
    }
    setSelectedPossibleLocation(location);
  };

  return (
    <DialogContent className={classes.content}>
      <GoogleMapsAutocompleteStyles />
      <div className={classes.searchDiv}>
        {isLoaded && (
          <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChangedAutocomplete}>
            <input
              type="text"
              placeholder="Search for Location"
              className={classes.searchBar}
              ref={autocompleteTextRef} />
          </Autocomplete>
        )}
        <Divider />
        <List className={classes.searchResults} dense>
          {possibleLocations.map((location, index) => {
            const { googlePlaceId, name, formatted_address } = location;
            const selected = selectedPossibleLocation?.googlePlaceId === googlePlaceId;
            return (
              <ListItem
                key={googlePlaceId}
                button
                onClick={() => onClickPossibleLocation(location)}
                selected={selected}>
                <ListItemText primary={`${index + 1}. ${name}`} secondary={formatted_address} />
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className={classes.mapDiv}>
        {isLoaded && (
          <GoogleMap
            mapContainerClassName={classes.map}
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              center: CENTRAL_US_LATLNG,
            }}
            zoom={4}
            onLoad={onLoadMap}
            onUnmount={onUnmountMap}
          >
            {possibleLocations.map((location, index) => {
              const { lat, long, name, googlePlaceId } = location;
              const selected = selectedPossibleLocation?.googlePlaceId === googlePlaceId;
              return (
                <Marker
                  key={googlePlaceId}
                  position={{ lat, lng: long }}
                  title={name}
                  label={{
                    text: String(index + 1),
                    fontSize: selected ? "18px" : undefined,
                    fontWeight: selected ? "bold" : undefined,
                  }}
                  onClick={() => onClickPossibleLocation(location)}
                  zIndex={selected ? 1 : 0}
                />
              );
            })}
          </GoogleMap>
        )}
        {/* TODO: Animate, i.e. Fade in */}
        {selectedPossibleLocation && (
          <Button className={classes.selectedLocation} onClick={() => onAddLocation(selectedPossibleLocation)}>
            Add Selected Location
          </Button>
        )}
      </div>
    </DialogContent>
  );
};

export default RestaurantLocationsAddDialogContent;

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import TablePage from "../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../components/universal/TablePage/DefaultHeader";
import { DietFragment, StaffDashboardDietsQuery } from "../../../types";
import { PaginationHooks } from "../../../utils/pagination";

interface DietTableProps {
  offsetConnectionData?: StaffDashboardDietsQuery;
  loading: boolean;
  paginationHooks: PaginationHooks;
  onEditDiet: (diet: DietFragment) => void;
  onDeleteDiet: (dietId: string) => void;
  onCreateDiet: () => void;
}

const DietTable = ({ offsetConnectionData, loading, paginationHooks, onEditDiet, onCreateDiet, onDeleteDiet }: DietTableProps) => {
  const { queryText, onChangeQueryText } = paginationHooks;
  const Header = (
    <TablePageDefaultHeader
      title="Diet"
      queryText={queryText}
      onChangeQueryText={onChangeQueryText}
      onCreate={onCreateDiet} />
  );

  const TableBodyRows = offsetConnectionData
    ? offsetConnectionData.dietOffsetConnection.edges.map(diet => (
        <TableRow key={diet.id}>
          <TableCell scope="row">
            <IconButton onClick={() => onEditDiet(diet)} size="large">
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell scope="row">{diet.name}</TableCell>
          <TableCell scope="row" align="right">
            <IconButton onClick={e => onDeleteDiet(diet.id)} size="large">
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))
    : null;

  const TableHeaderRow = () => (
    <TableRow>
      <TableCell padding="checkbox">Edit</TableCell>
      <TableCell>Name</TableCell>
      <TableCell align="right">Delete</TableCell>
    </TableRow>
  );

  return (
    <TablePage
      header={Header}
      tableHeaderRow={<TableHeaderRow />}
      tableBodyRows={TableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={offsetConnectionData?.dietOffsetConnection.pageInfo.total || 0}
    />
  );
};

export default DietTable;

import CloseIcon from "@mui/icons-material/Close";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { IconButton, TextField, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import MenuItemAppearanceEditCards from "../../../../components/MenuItemAppearance/EditCards";
import MenuItemAppearanceReorderDialog from "../../../../components/MenuItemAppearance/ReorderDialog";
import { MenuItemAppearanceGroupAction } from "../../../../components/MenuItemAppearance/reducer";
import { MenuSectionState } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: theme.spacing(),
      display: "flex",
      alignItems: "center",
    },
    spacer: {
      flexGrow: 1,
    },
    nameTextField: {
      width: 400,
    },
    nameTextFieldInput: {
      fontSize: "1.5em",
    },
  })
);

interface MenuSectionFormProps {
  menuSection: MenuSectionState;
  usedMenuItemIds: readonly string[];
  onChangeName: (name: string) => void;
  onRemove: () => void;
  onChangeMenuItemAppearances: (action: MenuItemAppearanceGroupAction) => void;
}

const MenuSectionForm = ({ menuSection, usedMenuItemIds, onChangeName, onRemove, onChangeMenuItemAppearances }: MenuSectionFormProps) => {
  const classes = useStyles();
  const [reordering, setReordering] = useState(false);

  return (
    <>
      <div className={classes.header}>
        <TextField
          className={classes.nameTextField}
          inputProps={{
            className: classes.nameTextFieldInput,
          }}
          placeholder="Section Name"
          value={menuSection.name}
          onChange={e => onChangeName(e.target.value)}
          autoFocus={!menuSection.name}
        />
        <div className={classes.spacer} />
        <Tooltip title="Re-order menu items">
          <IconButton onClick={() => setReordering(true)} size="large">
            <LowPriorityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove Section from menu">
          <IconButton onClick={onRemove} size="large">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      <MenuItemAppearanceEditCards
        usedMenuItemIds={usedMenuItemIds}
        variant="RestaurantMenu"
        state={menuSection.menuItemAppearances}
        dispatch={onChangeMenuItemAppearances}
      />
      {reordering && (
        <MenuItemAppearanceReorderDialog
          open={reordering}
          onClose={() => setReordering(false)}
          menuItemAppearances={menuSection.menuItemAppearances}
          onDone={menuItemAppearances =>
            onChangeMenuItemAppearances({
              type: "ReorderMenuItemsAction",
              payload: { menuItemAppearances },
            })
          }
        />
      )}
    </>
  );
};

export default MenuSectionForm;

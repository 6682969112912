import Info from "@mui/icons-material/Info";
import { IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import React from "react";
import TablePage from "../../../components/universal/TablePage";
import { GoalConfigurationForTableFragment } from "../../../types";
import { PaginationHooks } from "../../../utils/pagination";
import { AthleteGoalTableRows } from "./AthleteGoalTableRows";

interface AthleteGoalTableProps {
  paginationHooks: PaginationHooks;
  rows: readonly GoalConfigurationForTableFragment[];
  loading: boolean;
  totalRows: number;
}

export const AthleteGoalTable = ({ paginationHooks, loading, totalRows, rows }: AthleteGoalTableProps) => {
  return (
    <TablePage
      tableHeaderRow={
        <TableRow>
          <TableCell>Emoji</TableCell>
          <TableCell>Task</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Completion Criteria</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell>
            Compliance
            <Tooltip
              title={
                <>
                  <Typography sx={{ color: "highEmphasisTextDark" }} variant="h4">
                    Compliance
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: "mediumEmphasisTextDark" }}>
                    {
                      "This number is {# of days that the task has been completed} / {# of days that have passed where the task was assigned}"
                    }
                  </Typography>
                </>
              }
            >
              <IconButton>
                <Info htmlColor="black" />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell padding="checkbox">Actions</TableCell>
        </TableRow>
      }
      tableBodyRows={<AthleteGoalTableRows rows={rows} />}
      paginationHooks={paginationHooks}
      loading={loading}
      total={totalRows}
    />
  );
};

import InfoIcon from "@mui/icons-material/Info";
import PrintIcon from "@mui/icons-material/Print";
import { Box, Card, IconButton, SxProps, Tooltip, styled } from "@mui/material";
import React, { useState } from "react";
import { MenuItemAppearancePreviewFragment } from "../../../types";
import PrintableNutrientInfoDialog from "../../MenuItem/PrintableNutrientInfo/Dialog";
import { darkIconButtonStyle } from "../../universal/DarkIconButton";
import Dialog from "../Readonly/Dialog";
import { MenuItemAppearancePreviewDetail } from "./MenuItemAppearancePreviewDetail";
import { MenuItemAppearancePreviewImage } from "./MenuItemAppearancePreviewImage";
import { MenuItemAppearancePreviewTitle } from "./MenuItemAppearancePreviewTitle";
import { MenuCardActionArea, MenuCardContent } from "./utils";

const CardButton = styled(IconButton)(() => ({ marginTop: 4, marginRight: 4 }));

interface MenuItemAppearancePreviewReadonlyProps {
  menuItemAppearance: MenuItemAppearancePreviewFragment;
  sx?: SxProps;
  orderCount: number | null;
}

export const MenuItemAppearancePreviewReadonly = ({ menuItemAppearance, sx, orderCount }: MenuItemAppearancePreviewReadonlyProps) => {
  const { menuItem, availableForOrder } = menuItemAppearance;
  const { imageUrl, isOneOff, name, isDeleted, score } = menuItem;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);

  const hasOrders = orderCount !== null;
  const orderCountText = availableForOrder ? `Order Count: ${orderCount}` : "Not Available For Order";
  const itemTypeText = isOneOff ? "One-Off" : "Reusable";

  return (
    <Card sx={{ position: "relative", ...sx }} elevation={3}>
      <MenuCardActionArea onClick={() => setDialogOpen(true)}>
        <MenuCardContent imageUrl={imageUrl}>
          <MenuItemAppearancePreviewTitle
            isDeleted={isDeleted}
            title={name}
            score={score} />
          <MenuItemAppearancePreviewDetail isDeleted={isDeleted}>{itemTypeText}</MenuItemAppearancePreviewDetail>
          {hasOrders && <MenuItemAppearancePreviewDetail isDeleted={isDeleted}>{orderCountText}</MenuItemAppearancePreviewDetail>}
        </MenuCardContent>
        <MenuItemAppearancePreviewImage imageUrl={imageUrl} />
      </MenuCardActionArea>

      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
        <Tooltip title="Print Nutrient Info">
          <CardButton
            sx={darkIconButtonStyle}
            size="small"
            onClick={() => setPrintOpen(true)}>
            <PrintIcon />
          </CardButton>
        </Tooltip>
        {isDeleted && (
          <Tooltip title="This menu item has been deleted and won't be included in the new menu.">
            <CardButton size="small">
              <InfoIcon />
            </CardButton>
          </Tooltip>
        )}
      </Box>

      {dialogOpen && <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        menuItemAppearance={menuItemAppearance} />}
      {printOpen && <PrintableNutrientInfoDialog
        menuItemIds={[menuItem.id]}
        open={printOpen}
        onClose={() => setPrintOpen(false)} />}
    </Card>
  );
};

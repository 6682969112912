import React from "react";
import { ReactNode } from "react";
import { animated } from "react-spring";
import { ReactEventHandlers } from "react-use-gesture/dist/types";

import { useDraggable, DraggableSpring } from "../../hooks/useDraggable";

import EntityChip from "./EntityChip";
import { ChipListProps } from ".";

import { Entity } from "./types";

interface DraggableChipListProps<T extends Entity> extends Omit<ChipListProps<T>, "renderChip"> {
  renderChip: (obj: T, bindProps: ReactEventHandlers, spring: DraggableSpring) => ReactNode;
  chipHeight: number;
  objectToChipHeight?: (obj: T) => number;
  onChangeOrder: (ids: string[]) => void;
  // TODO: Loading chip should really just be children
  loadingChip?: React.ReactNode;
}

const AnimatedEntityChip = animated(EntityChip);

const DraggableChipList = <T extends Entity>({
  objects,
  objectToName,
  objectToChipProps,
  className,
  renderChip,
  onDeleteObject,
  chipHeight,
  objectToChipHeight,
  onChangeOrder,
  loadingChip,
}: DraggableChipListProps<T>) => {
  const draggableObjects = objects.map((obj, index) => ({
    id: obj.id,
    index,
    height: objectToChipHeight ? objectToChipHeight(obj) : chipHeight,
  }));
  const { springs, bind } = useDraggable(draggableObjects, onChangeOrder);

  const renderDraggableChip = (obj: T) => {
    const springObject = draggableObjects.find(({ id }) => id === obj.id);
    if (!springObject) return;

    const spring = springs[springObject.index];
    const bindProps = bind(springObject.index);

    return renderChip ? (
      renderChip(obj, bindProps, spring)
    ) : (
      // TODO: A generic entity chip is not fully functional in the drag case yet
      <AnimatedEntityChip
        key={obj.id}
        label={objectToName(obj)}
        onDelete={onDeleteObject ? () => onDeleteObject(obj) : undefined}
        chipProps={objectToChipProps ? objectToChipProps(obj) : {}}
        {...bindProps}
        // spring={spring}
      />
    );
  };

  return (
    <div className={className}>
      {objects.map(obj => renderDraggableChip(obj))}
      {loadingChip}
    </div>
  );
};

export default DraggableChipList;

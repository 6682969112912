import { TableCell, TableRow, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getNavOrgAthlete } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { FoodLogNotificationSettings } from "../../../../types";
import AthleteActions from "./Actions";
import { EnableAthlete } from "./Table";
import { IAthletePreferenceRowInput } from "./utils";

interface AthletePreferenceRowProps {
  row: IAthletePreferenceRowInput;
  onClickEnableAccount: (athlete: EnableAthlete) => void;
  displaySubscribeToAthleteModal: (args: { athleteNotificationSettings: FoodLogNotificationSettings | null; athleteId: string }) => void;
  teamFoodLogNotificationSettings: FoodLogNotificationSettings | null;
  displayEnableAccount: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
    },
  })
);

const AthletePreferenceRow = ({
  row,
  onClickEnableAccount,
  displaySubscribeToAthleteModal,
  teamFoodLogNotificationSettings,
  displayEnableAccount,
}: AthletePreferenceRowProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const athleteSubscriptionProps = {
    athleteNotificationSettings: row.foodLogNotificationSettings,
    teamNotificationSettings: teamFoodLogNotificationSettings,
  };

  const handleClickSubscribeToAthlete = () => {
    displaySubscribeToAthleteModal({
      athleteId: row.id,
      athleteNotificationSettings: athleteSubscriptionProps.athleteNotificationSettings,
    });
  };

  return (
    <TableRow
      hover
      className={classes.row}
      onClick={() => navigate(getNavOrgAthlete(row.id))}>
      <AthleteActions
        onClickEnableAccount={onClickEnableAccount}
        displaySubscribeToAthleteModal={handleClickSubscribeToAthlete}
        teamFoodLogNotificationSettings={teamFoodLogNotificationSettings}
        row={row}
        displayEnableAccount={displayEnableAccount}
      />
      <TableCell>{row.firstName}</TableCell>
      <TableCell>{row.lastName}</TableCell>
      <TableCell>{row.position?.name || ""}</TableCell>
      <TableCell>{row.dislikedFoodGroupNames}</TableCell>
    </TableRow>
  );
};

export default AthletePreferenceRow;

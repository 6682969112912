import { NotificationStubFragment } from "../../types";

// TODO: Should this name change??
// TODO: Distinguish on the backend??
export const isFoodLogNotification = (notification: NotificationStubFragment) => {
  switch (notification.__typename) {
    case "FoodLogMealImageNotification":
    case "FoodLogMealEditNotification":
    case "TimelineMealImageNotification":
    case "TimelineMealEditNotification":
      return true;
    default:
      return false;
  }
};

export const isCommentNotification = (notification: NotificationStubFragment) => {
  switch (notification.__typename) {
    case "MenuOrderCommentNotification":
    case "FoodLogMealCommentNotification":
    case "TimelineMealCommentNotification":
      return true;
    default:
      return false;
  }
};

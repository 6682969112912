import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNavStaffRestaurantPath } from "apps/web/src/pages/Auth/Staff/Restaurant/RestaurantRouter";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { StaffDashboardRestaurantPreviewFragment } from "../../../types";

const useStyles = makeStyles(({ palette: { greyscale } }: Theme) => ({
  onHover: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: greyscale[100],
    },
  },
}));

interface RestaurantTableRowProps {
  restaurant: StaffDashboardRestaurantPreviewFragment;
  onEditRestaurant: (restaurantId: string) => void;
}

export const TableHeaderRow = () => (
  <TableRow>
    <TableCell padding="checkbox">Edit</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Menu</TableCell>
    <TableCell>Nutritionix Link</TableCell>
    <TableCell>Active</TableCell>
    <TableCell>Athlete Visible</TableCell>
  </TableRow>
);

const RestaurantTableRow = ({ restaurant, onEditRestaurant }: RestaurantTableRowProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const hasMenu = restaurant.menu !== null;
  const hasNutritionixLink = restaurant.nutritionixId !== null;
  return (
    <TableRow
      className={classes.onHover}
      onClick={() => navigate(getNavStaffRestaurantPath(restaurant.id))}
      key={restaurant.id}>
      <TableCell scope="row">
        <IconButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onEditRestaurant(restaurant.id);
          }}
          size="large"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell scope="row">{restaurant.name}</TableCell>
      <TableCell>{hasMenu && "✓"}</TableCell>
      <TableCell>{hasNutritionixLink && "✓"}</TableCell>
      <TableCell>{restaurant.isActive && "✓"}</TableCell>
      <TableCell>{restaurant.athleteVisible && "✓"}</TableCell>
    </TableRow>
  );
};

export default RestaurantTableRow;

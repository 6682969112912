import { MenuItem, SxProps, TextField } from "@mui/material";
import React from "react";
import { useGroceryListCategoriesQuery } from "../../../../types";

interface FoodGroceryCategorySelectProps {
  groceryListCategoryId: string | null;
  sx?: SxProps;
  changeGroceryListCategory: (groceryListCategoryId: string | null) => void;
  disabled?: boolean;
}

export const FoodGroceryCategorySelect = ({
  groceryListCategoryId,
  sx,
  changeGroceryListCategory,
  disabled,
}: FoodGroceryCategorySelectProps) => {
  const { data: groceryListCategoriesData } = useGroceryListCategoriesQuery();
  const groceryListCategories = groceryListCategoriesData?.groceryListCategories || [];

  return (
    groceryListCategories && (
      <TextField
        sx={sx}
        disabled={disabled}
        select
        label="Grocery Category"
        value={groceryListCategoryId || ""}
        fullWidth
        onChange={e => changeGroceryListCategory(e.target.value)}
      >
        {groceryListCategories.map(groceryListCategory => (
          <MenuItem
            disabled={disabled}
            value={groceryListCategory.id}
            key={groceryListCategory.id}>
            {groceryListCategory.name}
          </MenuItem>
        ))}
      </TextField>
    )
  );
};

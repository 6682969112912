import { Button, Dialog, DialogActions, DialogContent, Step, StepLabel, Stepper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { CompassMenuInput, ImportCompassMenusInput, TeamMealMenuPreviewFragment, useCompassLocationsQuery } from "../../../../types";
import OtherSettings from "../Common/OtherSettings";
import { ImportMenuOptionsState, getImportMenuOptionsInput, getInitImportMenuOptionsState } from "../Common/utils";
import SelectLocation from "./SelectLocation";
import SelectMenus from "./SelectMenus";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      overflow: "auto",
    },
    menuForm: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    savingDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  })
);

const STEPS = ["Select Location", "Select Menu", "Edit Menu Details"];

interface MenusCompassDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (input: ImportCompassMenusInput) => void;
  clientTimezone: string;
  allTeams: readonly TeamMealMenuPreviewFragment[];
  saving: boolean;
}

const MenusCompassDialog = ({ open, onClose, onImport, clientTimezone, allTeams, saving }: MenusCompassDialogProps) => {
  const classes = useStyles();
  const { data } = useCompassLocationsQuery();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const selectedLocation = data?.compass_locations.find(l => l.locationId === selectedLocationId) || null;

  const [selectedMenuIds, setSelectedMenuIds] = useState<CompassMenuInput[]>([]);
  const [importMenuOptionsState, setImportMenuOptionsState] = useState<ImportMenuOptionsState | null>(null);

  const handleBack = () => setActiveStep(activeStep - 1);

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case 0:
        return !selectedLocation ? ["Select location to look up menus"] : [];
      case 1:
        return selectedMenuIds.length === 0 ? ["Select at least one menu to import"] : [];
      default:
        return [];
    }
  };

  const canSaveTooltips = getCanSaveTooltipItems();

  const onToggleMenu = (menu: CompassMenuInput) => {
    const matchingMenu = selectedMenuIds.find(m => m.date === menu.date && m.mealPeriodId === menu.mealPeriodId);
    if (matchingMenu) {
      setSelectedMenuIds(selectedMenuIds.filter(m => m !== matchingMenu));
    } else {
      setSelectedMenuIds([...selectedMenuIds, menu]);
    }
  };

  const handleNext = async () => {
    if (canSaveTooltips.length) {
      return;
    }
    if (activeStep === 1) {
      if (selectedLocation) {
        setActiveStep(activeStep + 1);
        const selectedMenuNames = new Set(
          selectedLocation.mealPeriods.filter(mp => selectedMenuIds.find(mId => mId.mealPeriodId === mp.mealPeriodId)).map(mp => mp.name)
        );
        setImportMenuOptionsState(getInitImportMenuOptionsState(selectedMenuNames, clientTimezone));
      }
    } else if (activeStep === 2) {
      if (selectedLocationId && importMenuOptionsState) {
        setActiveStep(activeStep + 1);
        onImport({
          options: getImportMenuOptionsInput(importMenuOptionsState),
          locationId: selectedLocationId,
          menus: selectedMenuIds,
        });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={{ paper: classes.paper }}>
      <DialogTitle title="Import Compass Menus" onClose={onClose} />
      <DialogContent>
        <Stepper sx={{ width: "80%", alignSelf: "center" }} activeStep={activeStep}>
          {STEPS.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.content}>
          {activeStep === 0 && data ? (
            <SelectLocation
              locations={data.compass_locations}
              selectedLocationId={selectedLocationId}
              onSelectLocationId={setSelectedLocationId}
            />
          ) : activeStep === 1 && selectedLocation ? (
            <SelectMenus
              location={selectedLocation}
              selectedMenuIds={selectedMenuIds}
              onToggleMenu={onToggleMenu}
              onSelectAllMenus={setSelectedMenuIds}
            />
          ) : activeStep === 2 && importMenuOptionsState ? (
            <div className={classes.menuForm}>
              <OtherSettings
                state={importMenuOptionsState}
                onChange={setImportMenuOptionsState}
                allTeams={allTeams} />
            </div>
          ) : activeStep === 3 && saving ? (
            <div className={classes.savingDiv}>
              <Loading progressSize="md" />
              <Typography variant="h3">Importing Menus - this may take a moment...</Typography>
            </div>
          ) : (
            <Loading progressSize="md" />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={activeStep === STEPS.length || activeStep === 0}
          onClick={handleBack}>
          Back
        </Button>
        {activeStep >= STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before finishing" items={canSaveTooltips}>
            <Button onClick={handleNext} disabled={activeStep > STEPS.length - 1}>
              Finish
            </Button>
          </TWItemizedTooltip>
        )}
        {activeStep < STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
            <Button onClick={handleNext}>Next</Button>
          </TWItemizedTooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MenusCompassDialog;

import { Box, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { getLongDateFormatStingForLocale } from "@notemeal/shared/ui/utils/dateTimes";
import { format } from "date-fns";
import React from "react";
import { TeamworksCalendarEvent } from "../../types";
import PopoverBase from "./Base";
import { LineItem, TimeLineItem } from "./LineItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: 24,
      marginLeft: theme.spacing(),
    },
  })
);

interface TeamworksCalendarEventPreviewLoaderProps {
  event: TeamworksCalendarEvent;
  anchorEl: HTMLElement;
  onClose: () => void;
}

const TeamworksCalendarEventPreviewLoader = ({ event, anchorEl, onClose }: TeamworksCalendarEventPreviewLoaderProps) => {
  const classes = useStyles();
  const name = event.name;
  const { dateFnsLocale } = useDateFormatting();

  // TODO: Locale Cleanup
  const formatString = getLongDateFormatStingForLocale(dateFnsLocale, true);
  const displayDate = format(event.start, formatString);

  return (
    <PopoverBase
      anchorEl={anchorEl}
      onClose={onClose}
      variant="xl">
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography>{name}</Typography>
        <img
          alt="Teamworks black logo"
          src="/img/teamworks_logo_black.svg"
          className={classes.logo} />
      </Box>
      <LineItem variant="body2">Date: {displayDate}</LineItem>
      <TimeLineItem event={event} />
      {event.location && <LineItem variant="body2">Location: {event.location}</LineItem>}
      <LineItem variant="body2">Event Type: {event.eventType}</LineItem>
    </PopoverBase>
  );
};

export default TeamworksCalendarEventPreviewLoader;

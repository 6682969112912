import { Autocomplete, FormControlLabel, Switch, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch } from "react";
import { SportWithPositionMappingsFragment, TeamworksPositionFragment, TeamworksPositionLinkFragment } from "../../../../../types";
import { NotemealTeam, TeamworksTeam } from "../Teams/reducer";
import PositionsDataLoader from "./PositionsDataLoader";
import { hasExistingSportNameError, SportBuilderAction, SportState } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContainer: {
      margin: theme.spacing(2, 0),
    },
    firstContainer: {
      marginTop: 0,
    },
    flexDiv: {
      display: "flex",
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between",
    },
    verticalSpacing: {
      margin: theme.spacing(2),
    },
    nmSport: {
      marginRight: theme.spacing(2),
    },
    switchContainer: {
      marginLeft: theme.spacing(4),
      flex: 1,
    },
    sportSelectionDiv: {
      flex: 2,
    },
  })
);

interface SportContentProps {
  sportState: SportState;
  dispatch: Dispatch<SportBuilderAction>;
  teamworksTeam: TeamworksTeam;
  notemealTeam?: NotemealTeam;
  sports: readonly SportWithPositionMappingsFragment[];
  unfilteredTeamorksPositions: readonly TeamworksPositionFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
}

const SportContent = ({
  teamworksTeam,
  notemealTeam,
  sportState,
  dispatch,
  sports,
  unfilteredTeamorksPositions,
  teamworksPositionLinks,
}: SportContentProps) => {
  const classes = useStyles();

  const indySportState = sportState.sportState ?? undefined;

  const hasExistingNameError = hasExistingSportNameError(sportState, sports);

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.spaceBetween}>
        <div className={classes.sportSelectionDiv}>
          <div className={classes.flexDiv}>
            {notemealTeam && (
              <Typography className={classes.nmSport}>
                Notemeal Sport Name: <strong>{notemealTeam.sport?.name ?? "No Sport Name"}</strong>
              </Typography>
            )}
            <Typography>
              Teamworks Sport Name: <strong>{teamworksTeam.sport}</strong>
            </Typography>
          </div>

          {sportState.createNewSport ? (
            <TextField
              label="Sport Name"
              value={sportState.sportState?.name ?? ""}
              onChange={e =>
                dispatch({
                  type: "NEW_SPORT_NAME_ACTION",
                  payload: e.target.value,
                })
              }
              fullWidth
              disabled={sportState.syncWithoutSport}
              error={hasExistingNameError}
              helperText={hasExistingNameError ? "This name is used by an existing Sport" : undefined}
            />
          ) : (
            <Autocomplete
              options={[...sports]}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label="Sport" />}
              value={sportState.sportState?.__type === "Link" ? sportState.sportState?.selectedSport : null}
              onChange={(e, newValue) => {
                dispatch({
                  type: "SELECT_SPORT_ACTION",
                  payload: newValue,
                });
              }}
              disabled={sportState.syncWithoutSport}
            />
          )}
        </div>
        <div className={classes.switchContainer}>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={sportState.syncWithoutSport}
                  onChange={e =>
                    dispatch({
                      type: "SET_SYNC_WITHOUT_SPORT_ACTION",
                      payload: e.target.checked,
                    })
                  }
                />
              }
              label="Sync without Sport"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={sportState.createNewSport}
                  onChange={e =>
                    dispatch({
                      type: "SET_CREATE_NEW_SPORT_ACTION",
                      payload: e.target.checked,
                    })
                  }
                />
              }
              label="Create New Sport"
            />
          </div>
        </div>
      </div>
      <div className={classes.verticalSpacing} />
      {!sportState.syncWithoutSport && indySportState ? (
        <PositionsDataLoader
          indySportState={indySportState}
          unfilteredTeamorksPositions={unfilteredTeamorksPositions}
          teamworksPositionLinks={teamworksPositionLinks}
          dispatch={dispatch}
        />
      ) : (
        <Typography>
          {sportState.createNewSport ? "Add a sport name to create positions" : "Select a sport to link/create positions"}
        </Typography>
      )}
    </div>
  );
};

export default SportContent;

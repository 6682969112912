import { Box, MenuItem, SxProps, TextField, Typography } from "@mui/material";
import React from "react";
import { FoodCategoryType } from "../../types";
import { FoodCategoryIcon } from "./FoodCategoryIcon";

type FoodCategoryValue = FoodCategoryType | "None";
const FOOD_CATEGORIES: FoodCategoryValue[] = ["carbohydrate", "protein", "fats", "combination", "None"];

interface FoodCategorySelectProps {
  category: FoodCategoryType | null;
  onChange: (category: FoodCategoryType | null) => void;
  disabled?: boolean;
  sx?: SxProps;
}

export const FoodCategorySelect = ({ category, onChange, disabled = false, sx }: FoodCategorySelectProps) => {
  const curCategory = category || "None";

  return (
    <TextField
      sx={{ width: "190px" }}
      select
      label="Food Category"
      disabled={disabled}
      value={curCategory}
      onChange={e => {
        const value = e.target.value;
        onChange(value === "None" ? null : (value as FoodCategoryType));
      }}
    >
      {FOOD_CATEGORIES.map(option => {
        return (
          <MenuItem key={option} value={option}>
            <FoodCategoryOption category={option} />
          </MenuItem>
        );
      })}
    </TextField>
  );
};

const FoodCategoryOption = ({ category }: { category: FoodCategoryValue }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {category !== "None" ? (
        <>
          <FoodCategoryIcon foodCategory={category} iconSize={14} />
          <Typography variant="body1">{category.charAt(0).toUpperCase() + category.slice(1)}</Typography>
        </>
      ) : (
        <Typography variant="body1">None</Typography>
      )}
    </Box>
  );
};

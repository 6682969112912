import React, { ReactNode } from "react";
import { KioskMealMenuPreviewFragment, OrderKioskAthletePreviewFragment } from "../../../types";
import AthleteListItem from "../Configure/AthleteListItem";
import AthleteNavigationContent from "../Navigation";

interface OrderKioskAthleteNavigationProps {
  athletes: readonly OrderKioskAthletePreviewFragment[];
  setSelectedAthleteId: (athleteId: string) => void;
  renderAthleteListItemDecoration: (athlete: OrderKioskAthletePreviewFragment) => ReactNode;
  searchText: string;
  setSearchText: (value: string) => void;
  selectedTeamId: string | null;
  setSelectedTeamId: (value: string | null) => void;
  mealMenus: readonly KioskMealMenuPreviewFragment[];
}

const OrderKioskAthleteNavigation = ({
  athletes,
  mealMenus,
  setSelectedAthleteId,
  renderAthleteListItemDecoration,
  searchText,
  setSearchText,
  selectedTeamId,
  setSelectedTeamId,
}: OrderKioskAthleteNavigationProps) => {
  const handleSelect = (athlete: OrderKioskAthletePreviewFragment) => {
    setSelectedAthleteId(athlete.id);
  };

  return (
    <AthleteNavigationContent
      athletes={athletes}
      searchText={searchText}
      setSearchText={setSearchText}
      selectedTeamId={selectedTeamId}
      setSelectedTeamId={setSelectedTeamId}
      mealMenus={mealMenus}
      renderAthleteListItem={(a, autoFocus) => (
        <AthleteListItem
          key={a.id}
          athlete={a}
          onSelect={() => {
            handleSelect(a);
          }}
          autoFocus={autoFocus}
          listItemDecoration={renderAthleteListItemDecoration(a)}
        />
      )}
    />
  );
};

export default OrderKioskAthleteNavigation;

import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { FoodGroupMultiSelect } from "../../../FoodGroup/FoodGroupMultiSelect";
import { BaseFoodFormType } from "../../FoodFormSchema/FoodFormSchema";

interface FoodGroupsSectionProps {
  form: UseFormReturn<BaseFoodFormType>;
  disabled?: boolean;
}

export const FoodGroupsSection = ({ form }: FoodGroupsSectionProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Controller
      name={"foodGroups"}
      control={control}
      render={({ field: { ref, onChange, ...field } }) => (
        <>
          <FoodGroupMultiSelect
            {...field}
            selectedFoodGroups={field.value ?? []}
            onChange={onChange}
            error={errors} />
        </>
      )}
    />
  );
};

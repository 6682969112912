import { Box, Chip } from "@mui/material";
import React from "react";
import { DateSeparatorProps } from "stream-chat-react";
import { formatMessageDate } from "./utils";

const UnMemoizedMessageDateSeparator = (props: Omit<DateSeparatorProps, "position">) => {
  const { date: messageCreatedAt } = props;
  const formattedDate = formatMessageDate(messageCreatedAt);

  return (
    <Box
      sx={{
        paddingTop: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Chip
        sx={({ palette, spacing }) => ({ backgroundColor: palette.greyscale[500], color: palette.common.white, paddingX: spacing(1) })}
        size="small"
        label={formattedDate}
      />
    </Box>
  );
};

export const MessageDateSeparator = React.memo(UnMemoizedMessageDateSeparator) as typeof UnMemoizedMessageDateSeparator;

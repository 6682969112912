import { Box } from "@mui/material";
import { EditMacroProtocolAction, EditMacroState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import { AnthropometrySnapshot } from "@notemeal/shared/utils/macro-protocol";
import React, { Dispatch } from "react";
import { MacroInput } from "./MacroInput";
import { MacroTotals } from "./MacroTotals";

export const GRAM_WIDTH = 85;
export const PERCENT_WIDTH = 85;

interface MacrosTargetsProps {
  cho: EditMacroState;
  pro: EditMacroState;
  fat: EditMacroState;
  anthropometrySnapshot: AnthropometrySnapshot;
  dispatch: Dispatch<EditMacroProtocolAction>;
  gPerKgOnly: boolean;
}

// Should receive anthro snapshot w/ weight target include already
export const MacrosTargets = ({ cho, pro, fat, anthropometrySnapshot, dispatch, gPerKgOnly }: MacrosTargetsProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      <MacroInput
        macro={"cho"}
        state={cho}
        anthropometrySnapshot={anthropometrySnapshot}
        dispatch={dispatch}
        gPerKgOnly={gPerKgOnly} />
      <MacroInput
        macro={"pro"}
        state={pro}
        anthropometrySnapshot={anthropometrySnapshot}
        dispatch={dispatch}
        gPerKgOnly={gPerKgOnly} />
      <MacroInput
        macro={"fat"}
        state={fat}
        anthropometrySnapshot={anthropometrySnapshot}
        dispatch={dispatch}
        gPerKgOnly={gPerKgOnly} />
      <MacroTotals
        cho={cho}
        pro={pro}
        fat={fat}
        anthropometrySnapshot={anthropometrySnapshot}
        gPerKgOnly={gPerKgOnly} />
    </Box>
  );
};

import { Workbook } from "exceljs";
import { MenuAttendanceRowInput } from "../../pages/Auth/Org/MenuAttendance/utils";
import { saveAs } from "file-saver";

interface ExportRow {
  Name: string;
  Team: string;
  Position: string | null;
  Attended: string;
  Time: string;
  "No Matched TWN Athlete": string;
  "No Menu Access": string;
}

type ExportKey = "fullName" | "team" | "position" | "attended" | "time" | "unlisted" | "noAccess";

interface ExportColumnDef {
  header: keyof ExportRow;
  key: ExportKey;
  width: number;
}

export const exportColumns: ExportColumnDef[] = [
  { header: "Name", key: "fullName", width: 20 },
  { header: "Team", key: "team", width: 20 },
  { header: "Position", key: "position", width: 20 },
  { header: "Attended", key: "attended", width: 20 },
  { header: "No Matched TWN Athlete", key: "unlisted", width: 20 },
  { header: "No Menu Access", key: "noAccess", width: 20 },
  { header: "Time", key: "time", width: 20 },
];

interface AttendanceToExcelProps {
  fileName: string;
  rows: MenuAttendanceRowInput[];
}

export const exportAttendanceToExcel = async ({ fileName, rows }: AttendanceToExcelProps) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();
  worksheet.columns = exportColumns;
  worksheet.getRow(1).alignment = { horizontal: "center", vertical: "middle" };

  rows.forEach(data => {
    worksheet.addRow({
      fullName: data.fullName,
      team: data.teamName,
      position: data.position,
      time: data.checkInDisplayDate || "",
      attended: data.checkedIn ? "x" : "",
      unlisted: data.unlisted ? "x" : "",
      noAccess: !data.hasAccess && !data.unlisted ? "x" : "",
    });
  });

  worksheet.views = [{ state: "frozen", xSplit: undefined, ySplit: 1 }];
  saveAs(new Blob([await workbook.xlsx.writeBuffer()]), fileName + ".xlsx");
};

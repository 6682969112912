import { z } from "zod";
import { EditableOrgGroupFragment } from "../../types";

export const OrgGroupFormSchema = z.object({
  name: z.string({ required_error: "Name is required" }).min(1, { message: "Name is required" }),
  parentOrgGroupId: z.string().uuid().nullable(),
  orgIds: z.array(z.string().uuid()),
  allowsResourceSharing: z.boolean(),
  allowsAthleteTransfer: z.boolean(),
});

export type OrgGroupFormType = z.infer<typeof OrgGroupFormSchema>;

export const orgGroupFormDefaultValues = (orgGroup?: EditableOrgGroupFragment): OrgGroupFormType => ({
  name: orgGroup?.name ?? "",
  parentOrgGroupId: orgGroup?.parentOrgGroup?.id ?? null,
  orgIds: orgGroup?.orgs.map(o => o.id) ?? [],
  allowsResourceSharing: orgGroup?.allowsResourceSharing ?? false,
  allowsAthleteTransfer: orgGroup?.allowsAthleteTransfer ?? false,
});

export const orgGroupFormToInput = (orgGroup: OrgGroupFormType) => ({
  parentOrgGroupId: orgGroup.parentOrgGroupId,
  name: orgGroup.name,
  allowsResourceSharing: orgGroup.allowsResourceSharing,
  allowsAthleteTransfer: orgGroup.allowsAthleteTransfer,
  orgIds: orgGroup.orgIds,
});

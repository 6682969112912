import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { PositionsMappingInput, SportsMappingInput, useAddAndLinkTeamworksTeamsMutation } from "../../../../../types";
import ConfirmDialog from "../shared/ConfirmDialog";
import DisplayCard from "../shared/DisplayCard";
import DisplayList from "../shared/DisplayList";
import ListColumn from "../shared/ListColumn";
import ListsContainer from "../shared/ListsContainer";
import TeamCard from "./DisplayLists/TeamCard";
import { AddAndLinkTeamsState, NotemealTeam, mapStateToInputs } from "./reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
    },
    listContainer: {
      height: "calc(100vh - 300px)",
    },
  })
);

interface ConfirmTeamsLinkDialogProps {
  teamsLinkState: AddAndLinkTeamsState;
  confirmComplete: (notemealTeams: readonly NotemealTeam[]) => void;
  confirmCancel: () => void;
  isOpen: boolean;
}

const ConfirmTeamsLinkDialog = ({ teamsLinkState, confirmComplete, confirmCancel, isOpen }: ConfirmTeamsLinkDialogProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const initialSportsMappings: (SportsMappingInput & {
    notemealName: string;
  })[] = [];
  const initialSportMappingsKeySet: Set<string> = new Set();

  const initialPositionsMappings: (PositionsMappingInput & {
    notemealName: string;
  })[] = [];

  const initialPositionsMappingsKeySet: Set<string> = new Set();

  [...teamsLinkState.linkedTeams, ...teamsLinkState.addedTeams].forEach(team => {
    const sportState = team.sportState;
    if (sportState?.__type === "Link") {
      if (team.teamworksSportName) {
        const sportId = sportState.selectedSport.id;
        const teamworksName = team.teamworksSportName;
        const notemealName = sportState.selectedSport.name;
        const key = `${sportId}:${teamworksName}:${notemealName}`;
        if (!initialSportMappingsKeySet.has(key)) {
          initialSportsMappings.push({
            sportId,
            teamworksName,
            notemealName,
          });
          initialSportMappingsKeySet.add(key);
        }
      }
      sportState.linkedPositions.forEach(position => {
        const teamworksName = position.teamworksPositionName;
        const positionId = position.positionId;
        const notemealName = position.name;
        const key = `${teamworksName}:${positionId}:${notemealName}`;
        if (!initialPositionsMappingsKeySet.has(key)) {
          initialPositionsMappings.push({
            teamworksName,
            positionId,
            notemealName,
          });
          initialPositionsMappingsKeySet.add(key);
        }
      });
    }
  });
  const [addedSportMappings, setAddedSportMappings] = useState(initialSportsMappings);
  const [addedPositionMappings, setAddedPositionMappings] = useState(initialPositionsMappings);

  const [addAndLinkTeams, { loading }] = useAddAndLinkTeamworksTeamsMutation({
    onError: () => {
      setMessage("error", "Error occurred while linking/creating teams");
    },
    onCompleted: data => {
      setMessage("success", "Successfully linked/created Teams");
      confirmComplete(
        data.addAndLinkTeamworksTeams.org.teams.map(t => ({
          ...t,
          __type: "NotemealTeam",
          sportName: t.sport?.name ?? null,
        }))
      );
    },
    update: (cache, { data }) => {
      if (data) {
        cache.evict({
          fieldName: "orgs",
          broadcast: true,
        });
        cache.evict({
          fieldName: "teamworksOrg",
          broadcast: true,
        });
      }
    },
  });

  const { org, addedTeams, linkedTeams } = teamsLinkState;

  const onRemoveSportMapping = (sportMapping: SportsMappingInput) => {
    setAddedSportMappings(
      addedSportMappings.filter(m => !(m.sportId === sportMapping.sportId && m.teamworksName === sportMapping.teamworksName))
    );
  };

  const onRemovePositionMapping = (sportMapping: PositionsMappingInput) => {
    setAddedPositionMappings(
      addedPositionMappings.filter(m => !(m.positionId === sportMapping.positionId && m.teamworksName === sportMapping.teamworksName))
    );
  };

  const onConfirm = () =>
    addAndLinkTeams({
      variables: {
        input: mapStateToInputs(
          teamsLinkState,
          addedSportMappings.map(({ notemealName, ...m }) => ({ ...m })),
          addedPositionMappings.map(({ notemealName, ...m }) => ({ ...m }))
        ),
      },
    });

  const hadTeamsToLink = teamsLinkState.org.teams.length > 0;

  return (
    <ConfirmDialog
      maxWidth={false}
      isOpen={isOpen}
      title={`Link And Create Teams for Org: ${org.name}`}
      classes={{ content: classes.content }}
      content={
        <ListsContainer>
          <ListColumn>
            <DisplayList
              columnMode="NoAction"
              title={`${addedTeams.length} Teams to Add`}
              renderListItem={({ key, ...props }) => {
                return <TeamCard {...props} key={key} />;
              }}
              selectedId={null}
              elements={addedTeams.map(t => ({ ...t, id: t.teamworksId }))}
              loading={false}
            />
          </ListColumn>
          {hadTeamsToLink && (
            <ListColumn>
              <DisplayList
                columnMode="NoAction"
                title={`${linkedTeams.length} Teams to Link`}
                renderListItem={({ key, element, ...props }) => {
                  const primaryText = `Linking Teamworks: ${element.teamworksName} with ${element.name}`;
                  const secondaryElement = element.sportName;
                  return (
                    <DisplayCard
                      {...props}
                      element={element}
                      key={key}
                      primaryText={primaryText}
                      secondaryElement={secondaryElement} />
                  );
                }}
                selectedId={null}
                elements={linkedTeams.map(t => ({ ...t, id: t.teamworksId }))}
                loading={false}
              />
            </ListColumn>
          )}
          {initialSportsMappings.length > 0 && (
            <ListColumn>
              <DisplayList
                columnMode="Cancel"
                title="Added Sport Mappings"
                renderListItem={({ key, element, ...props }) => {
                  const primaryText = `Linking ${element.teamworksName} (TW) with ${element.notemealName} (NM)`;
                  return <DisplayCard
                    {...props}
                    element={element}
                    key={key}
                    primaryText={primaryText}
                    onCancel={onRemoveSportMapping} />;
                }}
                selectedId={null}
                elements={addedSportMappings.map(m => ({
                  ...m,
                  id: `${m.sportId}-${m.teamworksName}`,
                }))}
                loading={false}
              />
            </ListColumn>
          )}
          {initialPositionsMappings.length > 0 && (
            <ListColumn>
              <DisplayList
                columnMode="Cancel"
                title="Added Position Mappings"
                renderListItem={({ key, element, ...props }) => {
                  const primaryText = `Linking ${element.teamworksName} (TW) with ${element.notemealName} (NM)`;
                  return (
                    <DisplayCard
                      {...props}
                      element={element}
                      key={key}
                      primaryText={primaryText}
                      onCancel={onRemovePositionMapping} />
                  );
                }}
                selectedId={null}
                elements={addedPositionMappings.map(m => ({
                  ...m,
                  id: `${m.positionId}-${m.teamworksName}`,
                }))}
                loading={false}
              />
            </ListColumn>
          )}
        </ListsContainer>
      }
      loading={loading}
      onCancel={confirmCancel}
      onConfirm={onConfirm}
    />
  );
};

export default ConfirmTeamsLinkDialog;

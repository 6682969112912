import { Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { MessageTimestampProps, useMessageContext } from "stream-chat-react";

const UnMemoizedMessageTimestamp = (props: MessageTimestampProps) => {
  const { message: propMessage } = props;
  const { message: contextMessage } = useMessageContext("MessageTimestamp");
  const { created_at } = propMessage || contextMessage;
  return <Typography variant="subtitle1">{created_at ? format(new Date(created_at), "p") : ""}</Typography>;
};

export const MessageTimestamp = React.memo(UnMemoizedMessageTimestamp) as typeof UnMemoizedMessageTimestamp;

import { Idable } from "../../../../../views/Staff/GeneralizedStaffContent/types";

interface Namable {
  name: string;
}
export interface BaseFilterState<T extends Idable> {
  visibleFilters: T[];
  query: string;
}

interface UpdateVisibleFilters<T extends Idable> {
  type: "UPDATE_VISIBLE_FILTERS";
  payload: {
    selectedFilters: T[];
  };
}

interface ChangeVisibleFilters<T extends Idable> {
  type: "CHANGE_VISIBLE_FILTERS";
  payload: {
    visibleFilters: T[];
  };
}

interface ChangeQuery {
  type: "CHANGE_QUERY";
  payload: {
    query: string;
  };
}

export type BaseFilterAction<T extends Idable> = UpdateVisibleFilters<T> | ChangeVisibleFilters<T> | ChangeQuery;

export const baseFilterReducer = <T extends Idable, S extends BaseFilterState<T>>(state: S, action: BaseFilterAction<T>): S => {
  switch (action.type) {
    case "UPDATE_VISIBLE_FILTERS": {
      let visibleFilters = state.visibleFilters;
      const visibleFilterIds = new Set(visibleFilters.map(filter => filter.id));
      action.payload.selectedFilters.forEach(filter => {
        if (!visibleFilterIds.has(filter.id)) {
          // TODO: Add to top or Bottom?
          visibleFilters = [...visibleFilters, filter];
        }
      });
      return {
        ...state,
        visibleFilters,
      };
    }
    case "CHANGE_VISIBLE_FILTERS":
      return {
        ...state,
        visibleFilters: action.payload.visibleFilters,
      };
    case "CHANGE_QUERY":
      return {
        ...state,
        query: action.payload.query,
      };
    default:
      return state;
  }
};

export const buildInitialState = <T extends Idable>(initialVisibleFilters: T[]): BaseFilterState<T> => ({
  visibleFilters: initialVisibleFilters,
  query: "",
});

interface ShowMoreButtonArgs<T extends Idable> {
  hasShowMore?: boolean;
  showMoreLimit: number;
  visibleFilters: T[];
  selectedFilters: T[];
}

export const hasShowMoreButton = <T extends Idable>({
  hasShowMore,
  showMoreLimit,
  visibleFilters,
  selectedFilters,
}: ShowMoreButtonArgs<T>) => {
  const isOverLimit = visibleFilters.length > showMoreLimit;
  const hasUnselectedVisibleFilters = visibleFilters.length !== selectedFilters.length;
  const showShowMoreButton = hasShowMore && isOverLimit && hasUnselectedVisibleFilters;

  return showShowMoreButton;
};

interface GetItemListArgs<T extends Idable> {
  hasShowMore?: boolean;
  isOpen: boolean;
  showMoreOpen: boolean;
  showMoreLimit: number;
  visibleFilters: T[];
  selectedFilters: T[];
}

export const getItemList = <T extends Idable>({
  hasShowMore,
  isOpen,
  showMoreOpen,
  showMoreLimit,
  visibleFilters,
  selectedFilters,
}: GetItemListArgs<T>) => {
  const selectedFilterIds = new Set(selectedFilters.map(item => item.id));
  if (!isOpen) {
    // if it is not open show only selected filters in the order they appear in the visibleFilters
    return [...visibleFilters.filter(item => selectedFilterIds.has(item.id))];
  }
  if (!hasShowMore || visibleFilters.length <= showMoreLimit) {
    // if there is no show more button or there are less visibleFilters than the limit show all filters
    return visibleFilters;
  }

  const sortedSelected = visibleFilters.filter(item => selectedFilterIds.has(item.id));
  const sortedUnselected = visibleFilters.filter(item => !selectedFilterIds.has(item.id));

  if (showMoreOpen) {
    // if show more is open show all the filters with the selected filters on top
    return [...sortedSelected, ...sortedUnselected];
  } else {
    // if show more is closed show all selected filters first then show unselected visibleFilters up to the limit
    const leftover = Math.max(showMoreLimit - sortedSelected.length, 0);
    return [...sortedSelected, ...sortedUnselected.slice(0, leftover)];
  }
};

export const getBaseTransformAndFilter =
  <T>(selectedEdges: T[]) =>
  (edges: T[] | undefined): T[] => {
    const filteredEdges = (edges ?? []).filter(c => !(selectedEdges || []).includes(c));
    return filteredEdges;
  };

export const baseEdgesAreEqual = (e1: Idable, e2: Idable) => {
  return e1.id === e2.id;
};

export const baseGetLabel = (obj: Namable) => obj.name;

export const baseGetKey = (obj: Idable) => obj.id;

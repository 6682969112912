import { SvgIcon, SxProps } from "@mui/material";
import { bulletScoreFilled, bulletScoreUnfilled } from "@notemeal/palette";

interface BulletScoreFilledProps {
  iconSize?: number;
  sx?: SxProps;
}

export const BulletScoreUnfilledIcon = ({ iconSize, sx }: BulletScoreFilledProps) => {
  return <BulletIcon iconSize={iconSize} color={bulletScoreUnfilled} sx={sx} />;
};

export const BulletScoreFilledIcon = ({ iconSize, sx }: BulletScoreFilledProps) => {
  return <BulletIcon iconSize={iconSize} color={bulletScoreFilled} sx={sx} />;
};

interface BulletIconProps {
  iconSize?: number;
  color?: string;
  sx?: SxProps;
}

export const BulletIcon = ({ color }: BulletIconProps) => (
  <SvgIcon
    sx={{
      width: "100%",
      height: "100%",
      fill: color,
      stroke: color,
      strokeWidth: "0px",
    }}
  >
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 16">
      <path d="m3.33,6.07v-1.27H.67v1.27l-.54.86h3.75l-.54-.86Z" />
      <path d="m4,7.47H0v5.87h4v-5.87Z" />
      <path d="m2.74,1.32l-.43-1.13c-.04-.11-.17-.19-.32-.19s-.28.08-.32.19l-.43,1.13c-.36.96-.55,1.95-.58,2.94h2.64c-.03-1-.22-1.99-.58-2.94Z" />
      <path d="m3.7,14.56s.02-.04.03-.07l.19-.62H.07l.19.62s.02.04.03.07c-.18.12-.3.3-.3.5v.29c0,.36.37.66.82.66h2.36c.45,0,.82-.29.82-.66v-.29c0-.2-.12-.38-.3-.5Z" />
    </svg>
  </SvgIcon>
);

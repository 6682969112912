import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Fab, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactNode, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useGesture } from "react-use-gesture";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gridTemplateRows: "auto 40px auto auto",
      gridTemplateColumns: "40px calc(100% - 80px) 40px",
      display: "grid",
    },
    content: {
      gridColumnStart: 1,
      gridColumnEnd: 4,
      gridRowStart: 1,
      gridRowEnd: 4,
      marginLeft: theme.spacing(-2.5),
      display: "flex",
    },
    item: {
      minWidth: `calc(100% - ${theme.spacing(2)})`,
      margin: theme.spacing(0, 2),
    },
    prevButton: {
      gridColumnStart: 1,
      gridRowStart: 2,
      marginLeft: theme.spacing(-0.5),
    },
    nextButton: {
      gridColumnStart: 3,
      gridRowStart: 2,
      marginRight: theme.spacing(-1.5),
    },
    dots: {
      gridColumnStart: 1,
      gridColumnEnd: 4,
      gridRowStart: 4,
      alignSelf: "center",
      display: "flex",
      justifyContent: "center",
    },
    selectedDot: {
      height: theme.spacing(),
      width: theme.spacing(),
      borderRadius: theme.spacing(),
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    dot: {
      height: theme.spacing(),
      width: theme.spacing(),
      borderRadius: theme.spacing(),
      margin: theme.spacing(),
      backgroundColor: theme.palette.text.secondary,
    },
  })
);

const V_THRESHOLD = 0.1;
const GESTURE_THRESHOLD = 25;

interface CarouselProps<T extends any> {
  items: T[];
  renderItem: (thing: T, index: number) => ReactNode;
  width: number;
  hideButtons?: boolean;
  hideDots?: boolean;
  noDragging?: boolean;
  index?: number;
  onChangeIndex?: (previousIndex: number) => void;
  classes?: {
    root?: string;
    content?: string;
    item?: string;
    prevButton?: string;
    nextButton?: string;
    dots?: string;
    selectedDot?: string;
    dot?: string;
  };
}

const Carousel = <T extends any>({
  items,
  renderItem,
  width,
  index,
  onChangeIndex,
  classes: propClasses = {},
  hideButtons = false,
  hideDots = false,
  noDragging = false,
}: CarouselProps<T>) => {
  const initialIndex = index || 0;
  const [{ x }, set] = useSpring(() => ({ x: initialIndex }), []);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const workingIndex = index || currentIndex;
  const handleChangeIndex = onChangeIndex || setCurrentIndex;

  useEffect(() => {
    setX(workingIndex);
  }, [workingIndex]);

  const canPreviousIndex = workingIndex !== 0;
  const canNextIndex = items.length > 0 && workingIndex !== items.length - 1;

  const defaultClasses = useStyles();
  const classes = {
    ...defaultClasses,
    ...propClasses,
  };

  const handleNextIndex = () => {
    if (canNextIndex) {
      handleChangeIndex(workingIndex + 1);
    }
  };

  const handlePreviousIndex = () => {
    if (canPreviousIndex) {
      handleChangeIndex(workingIndex - 1);
    }
  };

  const setX = (index: number, xDelta = 0) =>
    set({
      x: -1 * width * index + xDelta,
    });

  // If containerWidth changes, re-calculates animation
  useEffect(() => {
    setX(workingIndex);
  }, [width]);

  const bind = noDragging
    ? // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useGesture(({ down, vxvy: [vx, vy], delta: [xDelta, yDelta] }) => {
        if (Math.abs(xDelta) < GESTURE_THRESHOLD) return;
        if (!down) {
          const X_THRESHOLD = width / 2;
          if (canNextIndex && (vx < -V_THRESHOLD || xDelta < -X_THRESHOLD)) handleNextIndex();
          else if (canPreviousIndex && (vx > V_THRESHOLD || xDelta > X_THRESHOLD)) handlePreviousIndex();
          else setX(workingIndex);
        } else {
          setX(workingIndex, xDelta);
        }
      });

  return (
    <div className={classes.root}>
      <animated.div className={classes.content} {...bind()} style={{ transform: x.to(x => `translateX(${x}px)`) }}>
        {items.map((t, i) => (
          <div key={i} className={classes.item}>
            {renderItem(t, i)}
          </div>
        ))}
      </animated.div>
      {!hideDots && (
        <div className={classes.dots}>
          {items.map((_, index) => (
            <div key={index} className={workingIndex === index ? classes.selectedDot : classes.dot} />
          ))}
        </div>
      )}
      {!hideButtons && canNextIndex && (
        <Fab size="small" onClick={handleNextIndex} className={classes.nextButton}>
          <KeyboardArrowRightIcon />
        </Fab>
      )}
      {!hideButtons && canPreviousIndex && (
        <Fab size="small" onClick={handlePreviousIndex} className={classes.prevButton}>
          <KeyboardArrowLeftIcon />
        </Fab>
      )}
    </div>
  );
};

export default Carousel;

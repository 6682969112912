import ChevronRight from "@mui/icons-material/ChevronRight";
import { Card, CardActionArea, CardActions, CardContent, Icon, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MealMenuLogItemWithAppearance } from "@notemeal/shared/ui/MealMenuLogItem/utils";
import { ServingAmountsEditChipListWithSearchBar } from "apps/web/src/components/ServingAmounts/Edit/ChipListWithSearchBar";
import { FullServingAmountFragment } from "apps/web/src/types";
import classnames from "classnames";
import React, { useState } from "react";
import { TimelineMealForModal } from "../../utils";
import { useTimelineMealModalContext } from "../TimelineMealModalContext";
import TimelineMealMenuLogTab from "./MealMenuLog";

const useStyles = makeStyles((theme: Theme) => {
  const BORDER = `1px solid ${theme.palette.grey[300]}`;

  return createStyles({
    card: {
      display: "flex",
      flexDirection: "row",
      margin: theme.spacing(2),
      border: BORDER,
      maxWidth: "70%",
      "&:hover": {
        cursor: "pointer",
      },
    },
    topCard: { marginTop: 0 },
    header: { paddingBottom: theme.spacing() },
    icon: { color: theme.palette.grey[600] },
    actionArea: { width: theme.spacing(4), margin: theme.spacing(3) },
    grow: { flexGrow: 1 },
    servingChips: { maxWidth: "70%" },
  });
});

interface TimelineMealModalLogTabProps {
  timelineMeal: TimelineMealForModal;
  servingAmounts: readonly FullServingAmountFragment[];
  setServingAmounts: (servingAmounts: readonly FullServingAmountFragment[]) => void;
  addMenuLogItem: (input: MealMenuLogItemWithAppearance, mealMenuId: string) => void;
}

const TimelineMealModalLogTab = ({ timelineMeal, servingAmounts, setServingAmounts, addMenuLogItem }: TimelineMealModalLogTabProps) => {
  const { logType, setLogType } = useTimelineMealModalContext();
  const classes = useStyles();

  const mealMenuIds = timelineMeal.mealMenus.map(({ id }) => id);
  const firstMealMenuId = mealMenuIds.length > 0 ? mealMenuIds[0] : null;
  const [selectedMealMenuId, setSelectedMealMenuId] = useState<string | null>(firstMealMenuId);

  return logType === null ? (
    <div>
      {mealMenuIds.length > 0 && (
        <Card className={classnames(classes.card, classes.topCard)} onClick={() => setLogType("menu")}>
          <div className={classes.grow}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h3"
                component="h2">
                <b>Log from Menu{mealMenuIds.length > 1 ? "s" : ""}</b>
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p">
                View entire menu
                <br />
                Log the plate you built
                <br />
                See nutrient breakdown
              </Typography>
            </CardContent>
          </div>
          <CardActionArea className={classes.actionArea}>
            <CardActions>
              <Icon className={classes.icon}>
                <ChevronRight color="inherit"></ChevronRight>
              </Icon>
            </CardActions>
          </CardActionArea>
        </Card>
      )}
      <Card className={classes.card} onClick={() => setLogType("food")}>
        <div className={classes.grow}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h3"
              component="h2">
              <b>Log from Food, Recipe, and Branded</b>
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p">
              Search certain foods and recipes
              <br />
              Look up branded food items
              <br />
              See nutrient breakdown
            </Typography>
          </CardContent>
        </div>
        <CardActionArea onClick={() => setLogType("menu")} className={classes.actionArea}>
          <CardActions>
            <Icon className={classes.icon}>
              <ChevronRight color="inherit"></ChevronRight>
            </Icon>
          </CardActions>
        </CardActionArea>
      </Card>
    </div>
  ) : logType === "food" ? (
    <ServingAmountsEditChipListWithSearchBar
      enableBranded={true}
      selectedServingAmounts={servingAmounts}
      selected
      noCache
      includeRecipeIngredients
      onChange={setServingAmounts}
      className={classes.servingChips}
    />
  ) : selectedMealMenuId ? (
    <TimelineMealMenuLogTab
      timelineMeal={timelineMeal}
      selectedMealMenuId={selectedMealMenuId}
      addMenuLogItem={input => addMenuLogItem(input, selectedMealMenuId)}
      setSelectedMealMenuId={setSelectedMealMenuId}
    />
  ) : null;
};

export default TimelineMealModalLogTab;

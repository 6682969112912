import { TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { Dispatch } from "react";
import { NoteType } from "../../../types";
import NoteTypeSelect from "../TypeSelect";
import { NoteFormAction, NoteFormState } from "./utils";

const useStyles = makeStyles((theme: Theme) => {
  const childStyles = {
    margin: theme.spacing(1, 1),
    alignSelf: "center",
  };
  return createStyles({
    root: {
      height: "100%",
      display: "grid",
      gridTemplateColumns: `1fr 1fr`,
      gridTemplateRows: "20% auto 20%",
      margin: theme.spacing(1, 0),
    },
    datetime: {
      gridColumnStart: 1,
      gridRowStart: 1,
      ...childStyles,
    },
    type: {
      gridColumnStart: 2,
      gridRowStart: 1,
      marginTop: "-10px",
    },
    content: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 2,
      gridRowEnd: 4,
      ...childStyles,
    },
  });
});

interface NoteFormProps {
  state: NoteFormState;
  dispatch: Dispatch<NoteFormAction>;
  noteTypes: readonly NoteType[];
}

const NoteForm = ({ state, dispatch, noteTypes }: NoteFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DesktopDatePicker
        className={classes.datetime}
        label="Date"
        onChange={datetime => dispatch({ type: "CHANGE_DATE", payload: datetime })}
        value={state.datetime}
      />
      <div className={classes.type}>
        <NoteTypeSelect
          selectedType={state.type}
          onChange={type => dispatch({ type: "CHANGE_TYPE", payload: type })}
          options={[...noteTypes]}
        />
      </div>
      <TextField
        className={classes.content}
        label="Content"
        type="text"
        multiline
        rows="10"
        onChange={e => dispatch({ type: "CHANGE_CONTENT", payload: e.target.value })}
        value={state.content}
      />
    </div>
  );
};

export default NoteForm;

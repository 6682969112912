import React, { Dispatch } from "react";
import { CustomizationSelect } from "../CustomizationSelect";
import { DigitalDisplaysConfigActions } from "../reducer";
import { Device } from "./DeviceSelect";

const ORIENTATION = "Orientation";
export const LANDSCAPE = "Landscape";
export const PORTRAIT = "Portrait";
const ORIENTATION_OPTIONS = [LANDSCAPE, PORTRAIT] as const;
export type Orientation = (typeof ORIENTATION_OPTIONS)[number];
export const DEFAULT_ORIENTATION = LANDSCAPE;

export interface OrientationSelectProps {
  value: Orientation;
  device: Device;
  dispatch: Dispatch<DigitalDisplaysConfigActions>;
  className?: string;
}

export const OrientationSelect = ({ value, device, dispatch, className }: OrientationSelectProps) => {
  const handleSelectOrientation = (value: Orientation) => {
    dispatch({ type: "SET_ORIENTATION", payload: value });
    if (value === "Portrait" && device === "TV") {
      dispatch({ type: "SET_ITEMS_PER_ROW", payload: "1" });
    } else {
      dispatch({ type: "SET_ITEMS_PER_ROW", payload: "2" });
    }
  };

  return (
    <CustomizationSelect
      label={ORIENTATION}
      value={value}
      onChange={handleSelectOrientation}
      options={ORIENTATION_OPTIONS}
      className={className}
    />
  );
};

import { FullExchangeServingListFragment, FullServingAmountFragment } from "../types";
import { sortByKey } from "@notemeal/utils/sort";

export const getExchangePickList = (
  exchangeServingList: FullExchangeServingListFragment,
  pickListServingIds: readonly string[] | null
): readonly FullServingAmountFragment[] => {
  const sortedServingAmounts = sortByKey(exchangeServingList.servingAmounts, "position");
  if (pickListServingIds === null) {
    return sortedServingAmounts;
  } else {
    return sortedServingAmounts.filter(sa => pickListServingIds.includes(sa.serving.id));
  }
};

import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { AthleteTimelineForDateQuery, useAthleteTimelineForDateQuery } from "../../../types";

interface useTimelineForDateArgs {
  athleteId: string;
  date: string | null;
}

export interface useTimelineForDatePayload {
  data: AthleteTimelineForDateQuery | undefined;
  loading: boolean;
  timezone: string;
  date: string;
}

export const useTimelineForDate = ({ athleteId, date: _date }: useTimelineForDateArgs): useTimelineForDatePayload | null => {
  const clientTimezone = useClientTimezone();
  const date = _date ?? "";

  const { data, loading } = useAthleteTimelineForDateQuery({
    variables: { timezone: clientTimezone, date, athleteId },
    skip: _date === null,
  });

  if (_date === null) {
    return null;
  }
  return { data, loading, timezone: clientTimezone, date };
};

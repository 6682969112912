import SearchIcon from "@mui/icons-material/Search";
import { Button, Dialog, DialogActions, DialogContent, InputAdornment, TextField } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { useMealMenuMissingOrdersDialogQuery } from "../../../types";
import MissingOrdersDialogContent from "./Content";

interface MissingOrdersDialogProps {
  mealMenuId: string;
  onClose: () => void;
  open: boolean;
}

const MissingOrdersDialog = ({ mealMenuId, onClose, open }: MissingOrdersDialogProps) => {
  const { data, loading } = useMealMenuMissingOrdersDialogQuery({
    variables: { mealMenuId },
  });

  const [searchQuery, setSearchQuery] = useState("");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Missing Orders" onClose={onClose} />
      <DialogContent>
        <TextField
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder="Search for athletes"
          fullWidth
        />
        {data && !loading ? (
          <MissingOrdersDialogContent mealMenu={data.mealMenu} searchQuery={searchQuery} />
        ) : (
          <Loading progressSize="lg" />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissingOrdersDialog;

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Card, Chip, IconButton, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { useState } from "react";
import { FoodLogActivityTimelineItem } from "../utils";
import FoodLogActivityEditModal from "./Edit/Modal";
import { rpeToColor } from "./FoodLogActivityUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      margin: theme.spacing(2, 1),
    },
    spacer: {
      flexGrow: 1,
    },
    rpeChip: {
      marginLeft: 5,
      marginRight: 5,
      height: 20,
      color: "white",
    },
  })
);

interface TimelineFoodLogActivityExpandedCardProps {
  foodLogActivity: FoodLogActivityTimelineItem;
  onRemove: () => void;
}

const TimelineFoodLogActivityExpandedCard = ({ foodLogActivity, onRemove }: TimelineFoodLogActivityExpandedCardProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const clientTimezone = useClientTimezone();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <Box onClick={() => setEditModalOpen(true)}>
        <Card sx={{ py: 2, pl: 2, display: "flex", gap: 2, justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1Medium">{foodLogActivity.name}</Typography>
            <Typography variant="body2">
              {formatTimeRangeInTimezone(foodLogActivity.start, foodLogActivity.durationInMinutes, foodLogActivity.timezone, {
                excludeTimezoneSuffix: clientTimezone === foodLogActivity.timezone,
              })}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {foodLogActivity.rpe && (
              <Chip
                className={classes.rpeChip}
                style={{ backgroundColor: rpeToColor(theme, foodLogActivity.rpe) }}
                size="small"
                label={`${foodLogActivity.rpe}/10`}
              />
            )}
            <IconButton
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setDeleteModalOpen(true);
              }}
              size="large"
            >
              <DeleteIcon sx={{ color: "error.main" }} />
            </IconButton>
          </Box>
        </Card>
      </Box>
      {deleteModalOpen && (
        <ConfirmationDialog
          open={deleteModalOpen}
          title="Delete Activity"
          message="Are you sure that you would like to delete the activity?"
          onCancel={() => setDeleteModalOpen(false)}
          onConfirm={() => {
            onRemove();
            setDeleteModalOpen(false);
          }}
          variant="containedDestructive"
        />
      )}
      {editModalOpen && (
        <FoodLogActivityEditModal
          onClose={() => setEditModalOpen(false)}
          open={editModalOpen}
          foodLogActivity={foodLogActivity} />
      )}
    </>
  );
};

export default TimelineFoodLogActivityExpandedCard;

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Card, CardActionArea, Typography } from "@mui/material";
import React from "react";

interface AddNewMealOptionProps {
  onClick: () => void;
}

export const AddNewMealOption = ({ onClick }: AddNewMealOptionProps) => (
  <Card sx={{ mx: 1, my: 2, height: 583 }}>
    <CardActionArea
      sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}
      onClick={onClick}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="h3">Add a new meal option</Typography>
        <AddCircleIcon sx={{ fontSize: 70, mt: 3 }} />
      </Box>
    </CardActionArea>
  </Card>
);

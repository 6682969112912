import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Radio, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import { AssignFromMealPlanTemplateAthleteFragment, AssignFromMealPlanTemplateFragment } from "apps/web/src/types";
import React, { useCallback } from "react";
import InfiniteScrollContainer from "../../../../components/universal/InfiniteScroll/InfiniteScrollContainer";
import { UseInfiniteCursorConnectionScrollResults } from "../../../../components/universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import { mealPlanTemplateForAthleteMessage } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      marginLeft: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(1)}px)`,
    },
    header: {
      background: "#FFFFFF",
    },
    list: {
      overflowY: "auto",
      height: "calc(100% - 60px)",
    },
  })
);

interface MealPlanTemplateInfiniteScrollProps {
  onClickItem: (template: AssignFromMealPlanTemplateFragment) => void;
  selectedTemplate: AssignFromMealPlanTemplateFragment | null;
  searchResults: UseInfiniteCursorConnectionScrollResults<
    "allMealPlanTemplatesForDietitianCursorConnection",
    AssignFromMealPlanTemplateFragment
  >;
  athlete: AssignFromMealPlanTemplateAthleteFragment;
}

const MealPlanTemplateInfiniteScroll = ({ onClickItem, selectedTemplate, searchResults, athlete }: MealPlanTemplateInfiniteScrollProps) => {
  const classes = useStyles();
  const rmrErrorsHeader = "The athlete's most recent anthropometry is preventing use of this meal plan's RMR method:";

  const renderItem = useCallback(
    (template: AssignFromMealPlanTemplateFragment, forwardRef?: React.MutableRefObject<HTMLDivElement | null>) => {
      const rmrErrors = mealPlanTemplateForAthleteMessage(template, athlete);
      const disabled = rmrErrors.length > 0;
      return (
        <TWItemizedTooltip title={rmrErrorsHeader} items={rmrErrors}>
          <span ref={forwardRef}>
            <ListItemButton
              key={template.id}
              disabled={disabled}
              onClick={() => onClickItem(template)}>
              <ListItemAvatar>
                <Radio disabled={disabled} checked={template.id === selectedTemplate?.id} />
              </ListItemAvatar>
              <ListItemText
                primary={template.name}
                secondary={`${template.orgId ? "Organization" : template.orgGroupId ? "Org Group" : "TW Nutrition"} Template`}
              />
            </ListItemButton>
          </span>
        </TWItemizedTooltip>
      );
    },
    [onClickItem, athlete, selectedTemplate]
  );

  return (
    <List disablePadding className={classes.list}>
      <InfiniteScrollContainer
        noItemsComponent={
          <ListItem className={classes.listItem}>
            <Typography>No Templates Found</Typography>
          </ListItem>
        }
        renderItem={renderItem}
        infiniteScrollResults={searchResults}
        loadingOffset={10}
      />
    </List>
  );
};

export default MealPlanTemplateInfiniteScroll;

import { z } from "zod";
import { GenericFoodSchema } from "./FoodSchema";

export const MccanceFoodSchema = GenericFoodSchema.merge(
  z.object({
    source: z.literal("mccance"),
    mccanceId: z.string(),
    mccanceName: z.string(),
  })
);

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Card, CardActionArea, Chip, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { getNavOrgKitchenMenuScheduleDate } from "../../../pages/Auth/Org/Kitchen/KitchenPaths";
import { KdsPageMealMenuInDateRangeFragment } from "../../../types";

interface MealMenuColumnProps {
  date: string;
  mealMenus: readonly KdsPageMealMenuInDateRangeFragment[];
  isSelected: (mm: KdsPageMealMenuInDateRangeFragment) => boolean;
  handleClickMealMenu: (mm: KdsPageMealMenuInDateRangeFragment) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    column: {
      display: "flex",
      flexDirection: "column",
    },
    mealMenuCard: {
      padding: theme.spacing(),
      marginTop: theme.spacing(1),
    },
    mealMenuCardActionArea: {
      padding: theme.spacing(),
    },
    iconGridColumn: {
      alignSelf: "center",
      textAlign: "end",
    },
    green: { color: theme.palette.success.main },
    grey: { color: theme.palette.grey[300] },
    emptyColumnTitle: {
      fontWeight: 700,
    },
  })
);

const MealMenuColumn = ({ mealMenus, handleClickMealMenu, isSelected, date }: MealMenuColumnProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.column}>
      {mealMenus.length === 0 ? (
        <Card raised className={classes.mealMenuCard}>
          <CardActionArea className={classes.mealMenuCardActionArea} onClick={() => navigate(getNavOrgKitchenMenuScheduleDate(date))}>
            <Typography variant="h2" className={classes.emptyColumnTitle}>{`No Menus.`}</Typography>
            <Typography color="textSecondary" variant="body1Medium">
              Click to create menus
            </Typography>
          </CardActionArea>
        </Card>
      ) : (
        mealMenus.map(mm => (
          <Card
            key={mm.id}
            raised
            className={classes.mealMenuCard}>
            <CardActionArea className={classes.mealMenuCardActionArea} onClick={() => handleClickMealMenu(mm)}>
              <Grid container alignItems="center">
                <Grid item md={12}>
                  <Typography variant="h3">{mm.name}</Typography>
                  <Typography color="textSecondary" variant="body1Medium">
                    {formatTimeRangeInTimezone(mm.start, mm.durationInMinutes, Intl.DateTimeFormat().resolvedOptions().timeZone)} (
                    {mm.durationInMinutes}min)
                  </Typography>
                </Grid>
                <Grid item md={9}>
                  {mm.mealMenuDiningStations.map(mmds => (
                    <Chip
                      size="small"
                      key={mmds.id}
                      label={mmds.name} />
                  ))}
                </Grid>
                <Grid item md={3}>
                  <CheckCircleIcon fontSize="large" className={isSelected(mm) ? classes.green : classes.grey} />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        ))
      )}
    </div>
  );
};

export default MealMenuColumn;

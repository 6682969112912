import { Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemText, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { NotemealProfile } from "@notemeal/profile-sync";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { EditOrgTeamworksSkippedProfile, useEditUserTeamworksIdToOverwriteMutation } from "../../../../../types";
import { OverwriteProfilesDialog } from "./OverwriteProfilesDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemText: {
      //User can select text (to copy to clipboard)
      "& .MuiTypography-root": { userSelect: "auto" },
    },
    flex: { display: "flex", flexDirection: "column" },
    spacer: { height: theme.spacing(2) },
  })
);

interface SkippedProfilesDialogProps {
  skippedProfiles: readonly EditOrgTeamworksSkippedProfile[];
  open: boolean;
  onClose: () => void;
}

export const SkippedProfilesDialog = ({ skippedProfiles, open, onClose }: SkippedProfilesDialogProps) => {
  const classes = useStyles();
  const title = "Sync Successful";
  const context = "The following profiles were skipped due to a conflict:";

  const [editUserTeamworksIdToOverwrite] = useEditUserTeamworksIdToOverwriteMutation({
    onCompleted: () => {
      setNonOverwrittenProfiles([...nonOverwrittenProfiles.filter(p => p.teamworksUserId !== teamworksUserIdToOverwrite)]);
      setTeamworksUserIdToOverwrite(null);
    },
    onError: () => {
      setTeamworksUserIdToOverwrite(null);
    },
  });

  const [nonOverwrittenProfiles, setNonOverwrittenProfiles] = React.useState<EditOrgTeamworksSkippedProfile[]>([...skippedProfiles]);
  const [teamworksUserIdToOverwrite, setTeamworksUserIdToOverwrite] = React.useState<number | null>(null);
  const [selectedNotemealProfile, setSelectedNotemealProfile] = React.useState<NotemealProfile | null>(null);
  const selectedId = selectedNotemealProfile?.id ?? null;
  const chosenSkippedProfile = nonOverwrittenProfiles.find(p => p.teamworksUserId === teamworksUserIdToOverwrite);

  const handleCloseOverwriteDialog = () => {
    if (chosenSkippedProfile) {
      editUserTeamworksIdToOverwrite({
        variables: { input: { userIds: chosenSkippedProfile.conflictedUserIds, teamworksIdToOverwrite: teamworksUserIdToOverwrite! } },
      });
    } else {
      setTeamworksUserIdToOverwrite(null);
    }
  };

  const handleCancel = () => {
    setSelectedNotemealProfile(null);
    setTeamworksUserIdToOverwrite(null);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle title={title} onClose={onClose} />
        <DialogContent>
          <Typography variant="body2">{context}</Typography>
          <List>
            {nonOverwrittenProfiles.map(({ teamworksUserId, name, reason }) => (
              <ListItem>
                <ListItemText
                  className={classes.listItemText}
                  primary={name}
                  secondary={reason} />
                {reason.includes("MergeUniquenessConflict") && (
                  <Button onClick={() => setTeamworksUserIdToOverwrite(teamworksUserId)}>+</Button>
                )}
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Done</Button>
        </DialogActions>
      </Dialog>
      {teamworksUserIdToOverwrite && chosenSkippedProfile && (
        <OverwriteProfilesDialog
          open={!!teamworksUserIdToOverwrite && !!chosenSkippedProfile}
          onClose={handleCloseOverwriteDialog}
          selectedId={selectedId}
          onCancel={handleCancel}
          conflictedUserIds={[...chosenSkippedProfile.conflictedUserIds]}
        />
      )}
    </>
  );
};

import { WatchQueryFetchPolicy } from "@apollo/client/core";
import AddIcon from "@mui/icons-material/Add";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import {
  AutocompleteRenderOptionState,
  createFilterOptions,
  FilterOptionsState,
  ListItemIcon,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { MenuItemPreviewSearchFragment, useMenuItemSearchQuery } from "../../../types";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "../../universal/InfiniteScroll/InfiniteScrollAutocomplete";
import { CREATE_REUSABLE_OPTION, ONE_OFF_OPTION } from "./utils";

type Option = MenuItemPreviewSearchFragment | typeof ONE_OFF_OPTION | typeof CREATE_REUSABLE_OPTION;

interface MenuItemSearchBarProps {
  usedMenuItemIds: readonly string[];
  variant: "DiningStationTemplate" | "MealMenu" | "MenuBuilder";
  onAdd: (menuItem: MenuItemPreviewSearchFragment) => void;
  onCreate: (name: string, isOneOff: boolean) => void;
  inputValue?: string;
  setInputValue?: (value: string) => void;
  ListboxProps?:
    | (React.HTMLAttributes<HTMLUListElement> & {
        sx?: SxProps<Theme> | undefined;
        ref?: React.Ref<Element> | undefined;
      })
    | undefined;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  sx?: SxProps<Theme> | undefined;
  searchInputRef?: React.RefObject<HTMLInputElement>;
  blurOnSelect?: boolean;
}

const MenuItemSearchBar = ({
  onCreate,
  onAdd,
  usedMenuItemIds,
  variant,
  inputValue,
  setInputValue,
  ListboxProps,
  onKeyUp,
  sx = {},
  searchInputRef,
  blurOnSelect = true,
}: MenuItemSearchBarProps) => {
  const { setMessage } = useSnackbar();
  const isMenuBuilder = variant === "MenuBuilder";

  const renderOption = (option: Option, state: AutocompleteRenderOptionState) => {
    const { inputValue } = state;
    if (option.__typename === "ONE_OFF_OPTION") {
      const text = inputValue ? `Use "${inputValue}" as one-off menu item` : `Add one-off menu item`;
      return (
        <>
          <ListItemIcon sx={{ fontWeight: 500, color: "black" }}>
            <PlusOneIcon />
          </ListItemIcon>
          <Typography sx={{ fontWeight: 500 }}>{text}</Typography>
        </>
      );
    } else if (option.__typename === "CREATE_REUSABLE_OPTION") {
      const text = inputValue ? `Create "${inputValue}" as new reusable menu item` : `Create new reusable menu item`;
      return (
        <>
          <ListItemIcon sx={{ fontWeight: 500, color: "black" }}>
            <AddIcon />
          </ListItemIcon>
          <Typography sx={{ fontWeight: 500 }}>{text}</Typography>
        </>
      );
    } else {
      return <Typography>{option.name}</Typography>;
    }
  };

  const handleSelect = (inputValue: string, option: Option | string | null) => {
    if (typeof option === "string" || option === null) {
      return;
    } else if (option.__typename === "ONE_OFF_OPTION") {
      onCreate(inputValue, true);
    } else if (option.__typename === "CREATE_REUSABLE_OPTION") {
      onCreate(inputValue, false);
    } else if (option.__typename === "MenuItem") {
      if (usedMenuItemIds.includes(option.id)) {
        setMessage(
          "error",
          `Menu Item '${option.name}' already exists on this ${variant === "DiningStationTemplate" ? "dining station template" : "menu"}`
        );
      } else {
        onAdd(option);
      }
    }
  };

  const groupBy = (option: Option) => {
    return option.__typename === "MenuItem" ? "Reusable Menu Items" : "";
  };

  const getOptionLabel = (option: Option | string, inputValue: string) => {
    return typeof option === "string" ? option : option.__typename === "MenuItem" ? option.name : inputValue;
  };

  const getOptionKey = (option: Option | string, state: AutocompleteRenderOptionState): string => {
    return typeof option === "string" ? option : option.__typename === "MenuItem" ? option.id : `${state.index}`;
  };

  const filterOptions = (option: any, state: FilterOptionsState<MenuItemPreviewSearchFragment>): any[] =>
    createFilterOptions<MenuItemPreviewSearchFragment>({
      stringify: () => state.inputValue,
    })(option, state);

  const defaultOptions: Option[] = [CREATE_REUSABLE_OPTION, ONE_OFF_OPTION];

  const transformAndFilterOptions = (edges: MenuItemPreviewSearchFragment[] | undefined): Option[] => {
    if (isMenuBuilder) {
      return [CREATE_REUSABLE_OPTION, ...(edges ? edges.filter(e => !usedMenuItemIds.includes(e.id)) : [])];
    }
    return [...defaultOptions, ...(edges ?? [])];
  };
  const edgesAreEqual = useCallback((edge1: MenuItemPreviewSearchFragment, edge2: MenuItemPreviewSearchFragment) => {
    return edge1.id === edge2.id;
  }, []);

  const fetchPolicy: WatchQueryFetchPolicy = "cache-first";

  const getQueryVariablesFromPaginationAndInput = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: { query: input, pagination: { cursor, limit } },
      fetchPolicy,
    }),
    []
  );

  // allows list width to exceed autocomplete width, and prevents list from flipping
  const componentProps = isMenuBuilder
    ? {
        popper: {
          style: { width: "400px" },
          placement: "bottom-start" as const,
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        },
      }
    : {};

  return (
    <InfiniteScrollAutocomplete
      queryKey="menuItemCursorConnection"
      useCursorConnectionQuery={useMenuItemSearchQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariablesFromPaginationAndInput}
      transformAndFilterOptions={transformAndFilterOptions}
      groupBy={groupBy}
      renderOption={renderOption}
      handleChange={handleSelect}
      getOptionLabel={getOptionLabel}
      getCustomOptionKey={getOptionKey}
      filterOptions={filterOptions}
      multiple={false}
      freeSolo
      noOptionsText="No Menu Items Found"
      inputPlaceholder={isMenuBuilder ? "Type menu item here" : "Add Menu Item"}
      edgesAreEqual={edgesAreEqual}
      value={inputValue ?? null}
      handleInputChange={setInputValue}
      ariaLabel="Add Menu Item"
      showStartAdornment={!isMenuBuilder}
      inputVariant={isMenuBuilder ? "standard" : "outlined"}
      InputProps={{ disableUnderline: isMenuBuilder }}
      componentsProps={componentProps}
      disableClearable={isMenuBuilder}
      ListboxProps={ListboxProps}
      onKeyUp={onKeyUp}
      sx={sx}
      inputRef={searchInputRef}
      blurOnSelect={blurOnSelect}
    />
  );
};

export default MenuItemSearchBar;

import { useApolloClient } from "@apollo/client";
import UndoIcon from "@mui/icons-material/Undo";
import { Box, Card, CardActionArea, IconButton, SxProps, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { MenuItemDialogDocument, MenuItemDialogQuery, MenuItemDialogQueryVariables, MenuItemFormFragment } from "../../../types";
import { darkIconButtonStyle } from "../../universal/DarkIconButton";
import EditDialog from "../EditDialog/EditDialog";
import MoreOptionsButton from "../MoreOptionsButton";
import {
  BaseMenuItemAppearanceCardProps,
  getMenuItemAppearanceEditOptions,
  getMenuItemAppearanceStateText,
  menuItemAppearanceIsDeleted,
} from "../utils";
import { MenuItemAppearancePreviewDetail } from "./MenuItemAppearancePreviewDetail";
import { MenuItemAppearancePreviewImage } from "./MenuItemAppearancePreviewImage";
import { MenuItemAppearancePreviewTitle } from "./MenuItemAppearancePreviewTitle";
import { MenuCardContent } from "./utils";

interface MenuItemAppearancePreviewProps extends BaseMenuItemAppearanceCardProps {
  usedMenuItemIds: readonly string[];
  onRemove: () => void;
  onRestore: () => void;
  sx?: SxProps;
  orderCount: number | null;
}

export const MenuItemAppearancePreview = ({
  usedMenuItemIds,
  menuItemAppearance,
  sx,
  onCreate,
  onEdit,
  onConvert,
  onEditAppearance,
  onRemove,
  onRestore,
  orderCount,
}: MenuItemAppearancePreviewProps) => {
  const apolloClient = useApolloClient();

  const { menuItem, type, maxAmount, allowSpecialRequests, availableForOrder } = menuItemAppearance;
  const { id: menuItemId, imageUrl, name, score } = menuItem;

  const stateText = getMenuItemAppearanceStateText(menuItemAppearance);
  const [editOpen, setEditOpen] = useState(false);

  const deleted = menuItemAppearanceIsDeleted(menuItemAppearance);
  // Hide deleted menu items that are in usedMenuItemIds, this means they've been moved to another dining station
  // Don't want to show the menu item twice or allow the "restore" button to be hit
  if (deleted && usedMenuItemIds.includes(menuItemId)) {
    return null;
  }

  const fetchMenuItemFormFragment = async (): Promise<MenuItemFormFragment> => {
    if (type === "Add" || type === "Move") {
      const { data } = await apolloClient.query<MenuItemDialogQuery, MenuItemDialogQueryVariables>({
        query: MenuItemDialogDocument,
        variables: {
          menuItemId,
        },
      });
      return data.currentMenuItem;
    } else {
      return menuItem;
    }
  };

  const handleDuplicate = async (isOneOff: boolean) => {
    const menuItemForm = await fetchMenuItemFormFragment();
    onCreate(menuItemForm, maxAmount, availableForOrder, allowSpecialRequests, isOneOff);
  };

  const handleEdit = async () => {
    const menuItemForm = await fetchMenuItemFormFragment();
    onEdit(menuItemForm, maxAmount, availableForOrder, allowSpecialRequests, menuItemForm);
    setEditOpen(true);
  };

  const handleConvert = async (isOneOff: boolean) => {
    const menuItemForm = await fetchMenuItemFormFragment();
    onConvert(menuItemForm, maxAmount, availableForOrder, allowSpecialRequests, isOneOff, menuItemForm);
    setEditOpen(true);
  };

  const styleForButton = { marginTop: 0.5, marginRight: 0.5, ...(!!imageUrl ? darkIconButtonStyle : {}) };
  const hasOrders = orderCount !== null;
  const orderCountText = !availableForOrder ? "Not Available For Order" : hasOrders ? `Order Count: ${orderCount}` : "Available For Order";
  const specialRequestsText = allowSpecialRequests ? "Special Requests Enabled" : "Special Requests Disabled";

  return (
    <Card sx={{ position: "relative", ...sx }} elevation={3}>
      <CardActionArea
        sx={{ height: "100%", display: "flex", alignItems: "stretch" }}
        disabled={deleted}
        onClick={() => setEditOpen(true)}>
        <MenuCardContent imageUrl={imageUrl}>
          <MenuItemAppearancePreviewTitle
            isDeleted={deleted}
            title={name}
            score={score} />
          <MenuItemAppearancePreviewDetail isDeleted={deleted}>{stateText}</MenuItemAppearancePreviewDetail>
          <MenuItemAppearancePreviewDetail isDeleted={deleted}>{orderCountText}</MenuItemAppearancePreviewDetail>
          <MenuItemAppearancePreviewDetail isDeleted={deleted}>{specialRequestsText}</MenuItemAppearancePreviewDetail>
        </MenuCardContent>
        <MenuItemAppearancePreviewImage imageUrl={imageUrl} usePlaceHolderForImage={true} />
      </CardActionArea>

      <Box sx={{ position: "absolute", top: 0, right: 0 }}>
        {deleted ? (
          <Tooltip title="Restore Menu Item">
            <IconButton
              sx={styleForButton}
              size="small"
              onClick={e => {
                e.stopPropagation();
                onRestore();
              }}
            >
              <UndoIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <MoreOptionsButton
            {...getMenuItemAppearanceEditOptions(menuItemAppearance)}
            onDuplicate={handleDuplicate}
            onEdit={handleEdit}
            onConvert={handleConvert}
            onRemove={onRemove}
            IconButtonProps={{
              sx: styleForButton,
              size: "small",
            }}
            SvgIconProps={{ fontSize: "small" }}
          />
        )}
      </Box>
      {editOpen && (
        <EditDialog
          open={editOpen}
          onClose={() => setEditOpen(false)}
          onCreate={onCreate}
          onEdit={onEdit}
          onConvert={onConvert}
          onEditAppearance={onEditAppearance}
          menuItemAppearance={menuItemAppearance}
        />
      )}
    </Card>
  );
};

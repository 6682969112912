import { QueryComponentOptions } from "@apollo/client/react/components";
import { Chip, InputAdornment, MenuItem, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import React, { useState } from "react";
import SearchBar, { SearchBarProps } from ".";
import { Omit } from "../../../types";
import { ConditionalRenderAddItemButtonProps, Entity } from "./type";
import { renderInput } from "./utils";

export interface SearchBarWithChipProps<T extends Entity, Q, V> extends SearchBarProps<T> {
  queryComponent: (props: Omit<QueryComponentOptions<Q, V>, "query"> & { variables: V }) => JSX.Element | null;
  inputToQueryVariables: (inputValue: string) => V;
  queryResultToObjects: (data: Q) => T[];
  getQueryProps?: () => Omit<QueryComponentOptions<Q, V>, "query" | "children">;
  menuClassName?: string;
  debounce?: boolean;
  selectedChipLabel: string | null;
  handleDelete: () => void;
  renderAddItemButton?: (props: ConditionalRenderAddItemButtonProps) => React.ReactElement;
  disabled?: boolean;
}

const DEBOUNCE_TIME = 175;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholder: {
      width: theme.spacing(2.5), // So we don't get a twitchy search bar!
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
      width: "80%", // TODO we only use this in the USDA Search Bar right now
      justifyContent: "space-between",
    },
    chipLabel: {
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  })
);

/*
 * DEPRECATED: Use MUI's Autocomplete or make a new version of this similar to how
 * we did for AutocompleteQuerySearchBar. Thank you!
 */

const SearchBarWithChip = <T extends Entity, Q, V>({
  renderAddItemButton,
  selectedChipLabel,
  handleDelete,
  onSelectObject,
  inputToQueryVariables,
  queryResultToObjects,
  queryComponent,
  getQueryProps,
  debounce = false,
  disabled,
  ...props
}: SearchBarWithChipProps<T, Q, V>) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [displayAddItemButton, setDisplayAddItemButton] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const deboundedValue = useDebounce(inputValue, DEBOUNCE_TIME);

  return (
    <SearchBar<T>
      fullWidth
      renderInput={({ InputProps, ...props }) =>
        renderInput({
          InputProps: {
            ...InputProps,
            disabled: disabled || !!selectedChipLabel,
            startAdornment: (
              <>
                {InputProps && InputProps.startAdornment}
                {selectedChipLabel && (
                  <Chip
                    size="small"
                    tabIndex={-1}
                    classes={{ label: classes.chipLabel }}
                    label={selectedChipLabel}
                    className={classes.chip}
                    onDelete={() => handleDelete()}
                  />
                )}
              </>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? <Loading progressSize="xs" /> : <span className={classes.loadingPlaceholder} />}
              </InputAdornment>
            ),
          },
          ...props,
        })
      }
      onSelectObject={(obj: T) => {
        setInputValue("");
        onSelectObject(obj);
      }}
      renderSuggestions={renderSuggestionProps => {
        const { renderSuggestion } = renderSuggestionProps;
        if (inputValue !== renderSuggestionProps.inputValue) {
          setInputValue(renderSuggestionProps.inputValue || "");
        }
        if (!inputValue) {
          setDisplayAddItemButton(false);
        }

        const queryProps = getQueryProps ? getQueryProps() : {};
        const value = debounce ? deboundedValue : inputValue;

        return (
          value &&
          queryComponent({
            variables: inputToQueryVariables(value),
            ...queryProps,
            notifyOnNetworkStatusChange: true,
            children: ({ data, loading, error }) => {
              if (loading !== isLoading) {
                setIsLoading(loading);
                setDisplayAddItemButton(!loading);
              }
              if (!data || !Object.keys(data).length) {
                return null;
              }
              const objects = queryResultToObjects(data);
              if (objects.length === 0) {
                return (
                  <React.Fragment>
                    <MenuItem component="div">No results found.</MenuItem>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment>
                  {objects.map((obj, idx) => {
                    return renderSuggestion(obj, idx, inputValue);
                  })}
                </React.Fragment>
              );
            },
          })
        );
      }}
      renderAddItemButton={displayAddItemButton ? renderAddItemButton : undefined}
      {...props}
    />
  );
};

export default SearchBarWithChip;

import { CompassRecipe } from "@notemeal/graphql/types";

export type QueryLabel = "Name" | "Reference #";

export const queryLabels: QueryLabel[] = ["Name", "Reference #"];

export enum InvalidSearch {
  NonNumeric = "Reference Number search may only contain numeric characters",
  TooLarge = "Invalid Reference Number search",
}

export interface SearchProps {
  isImporting: boolean;
  setSelectedRecipe: (recipe: CompassRecipe | null) => void;
  selectedRecipe: CompassRecipe | null;
  setSelectedLabel: (label: QueryLabel) => void;
  selectedLabel: QueryLabel;
}

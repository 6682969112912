import React from "react";
import BaseAsyncAutoComplete, { BasicOption } from "../../../../components/universal/BaseAsyncAutocomplete";
import { PaymentMethodFragment, useStaffDashboardPaymentMethodsQuery } from "../../../../types";

interface RestaurantMenuFormPaymentMethodsProps {
  paymentMethods: readonly PaymentMethodFragment[];
  onChange: (paymentMethods: PaymentMethodFragment[]) => void;
}

const useSearchQuery = (query: string) => {
  const { data, loading } = useStaffDashboardPaymentMethodsQuery({
    variables: { input: { limit: 25, offset: 0 }, query },
  });
  const options = data ? [...data.paymentMethodOffsetConnection.edges] : undefined;
  return {
    options,
    loading,
  };
};

const RestaurantMenuFormPaymentMethods = ({ paymentMethods, onChange }: RestaurantMenuFormPaymentMethodsProps) => {
  const selectedPaymentMethodIds = paymentMethods.map(paymentMethod => paymentMethod.id);
  const filterUsedOptions = (options: PaymentMethodFragment[] | undefined) => {
    if (options === undefined) {
      return undefined;
    }
    return options.filter(option => !selectedPaymentMethodIds.includes(option.id));
  };

  return (
    <BaseAsyncAutoComplete
      useSearchQuery={useSearchQuery}
      inputPlaceholder="Add Payment Method"
      noOptionsText="No Payment Methods Found"
      handleChange={onChange}
      filterUsedOptions={filterUsedOptions}
      multiple
      renderOption={({ name }: PaymentMethodFragment) => <BasicOption element={name} />}
      getOptionLabel={(pm: PaymentMethodFragment) => pm.name}
      value={paymentMethods.concat()}
    />
  );
};

export default RestaurantMenuFormPaymentMethods;

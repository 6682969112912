import { useApolloClient } from "@apollo/client";
import { servingAmountsToInputs } from "@notemeal/shared/ui/ServingAmount/utils";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { splitArrayIntoNChunks } from "@notemeal/shared/ui/utils/Array/index";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import { CreateServingAmountInput } from "libs/shared/ui/src/lib/types";
import { useState } from "react";
import {
  MealOptionSuggestionCursorConnectionDocument,
  MealOptionSuggestionCursorConnectionQuery,
  MealOptionSuggestionCursorConnectionQueryVariables,
  MealType,
} from "../../../types";
import { getReducedSuggestionQueryProps } from "./utils";

export interface MealOptionInput {
  mealTemplateId: string;
  mealType: MealType;
  macros: Macros;
}

export interface MealOptionsForMealTemplate {
  mealTemplateId: string;
  mealOptions: {
    servingAmounts: CreateServingAmountInput[];
    position: number;
    note: null;
    name: null;
  }[];
}

interface useGenerateMealOptionsProps {
  mealOptionInputs: MealOptionInput[];
  optionsPerMeal: number;
  avoidFoodIds: readonly string[];
  avoidFoodGroupIds: readonly string[];
  onCompleted: (mealOptionsForMealTemplates: MealOptionsForMealTemplate[]) => void;
}

interface useGenerateMealOptionsPayload {
  generateMealOptions: () => void;
  isGeneratingMealOptions: boolean;
}

export const useGenerateMealOptions = ({
  mealOptionInputs,
  optionsPerMeal,
  avoidFoodIds,
  avoidFoodGroupIds,
  onCompleted,
}: useGenerateMealOptionsProps): useGenerateMealOptionsPayload => {
  const apolloClient = useApolloClient();
  const [isGeneratingMealOptions, setIsGeneratingMealOptions] = useState(false);
  const { locale } = useLocaleContext();

  const generateMealOptions = async () => {
    setIsGeneratingMealOptions(true);

    const suggestionQueryPropsForMealPlan = getReducedSuggestionQueryProps(mealOptionInputs);

    const setMealOptionsPromise = suggestionQueryPropsForMealPlan.map(async suggestionQueryPropsForMeal => {
      const { macros, mealType, count } = suggestionQueryPropsForMeal;
      const { data } = await apolloClient.query<
        MealOptionSuggestionCursorConnectionQuery,
        MealOptionSuggestionCursorConnectionQueryVariables
      >({
        query: MealOptionSuggestionCursorConnectionDocument,
        variables: {
          macros: {
            cho: macros.cho,
            pro: macros.pro,
            fat: macros.fat,
          },
          mealType,
          maxErrorPercent: 20,
          minErrorPercent: 0,
          avoidFoodIds,
          avoidFoodGroupIds,
          pagination: {
            cursor: null,
            limit: optionsPerMeal * count,
          },
          localeCode: locale,
        },
      });

      if (data) {
        const servingAmountGroups = [...data.mealOptionSuggestionCursorConnection.edges];

        const mealTemplatesForAdd = mealOptionInputs.filter(mealOptionInput =>
          suggestionQueryPropsForMeal.mealTemplateIds.includes(mealOptionInput.mealTemplateId)
        );

        const chunkedServingAmountGroups = splitArrayIntoNChunks(servingAmountGroups, mealTemplatesForAdd.length);

        return mealTemplatesForAdd.flatMap(({ mealTemplateId }, index) => {
          const noMealOptionSuggestionsRemaining = chunkedServingAmountGroups.length <= index;
          if (noMealOptionSuggestionsRemaining) {
            return [];
          }

          return {
            mealTemplateId,
            mealOptions: chunkedServingAmountGroups[index].map((sag, i) => {
              return {
                servingAmounts: servingAmountsToInputs(sag.servingAmounts),
                position: i + 1,
                note: null,
                name: null,
              };
            }),
          };
        });
      }
    });

    const mealOptions = await Promise.all(setMealOptionsPromise);
    const mealOptionsForMealTemplates = mealOptions.flatMap(i => i ?? []);
    onCompleted(mealOptionsForMealTemplates);

    setIsGeneratingMealOptions(false);
  };

  return {
    generateMealOptions,
    isGeneratingMealOptions,
  };
};

export default useGenerateMealOptions;

import RssFeedIcon from "@mui/icons-material/RssFeed";
import { Button, Card, CardActionArea, CardContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import FoodLogNotificationSubcriptionTopicsModal from "../../../../components/FoodLog/NotificationSettings/AthleteModal";
import FoodLogNotificationSubcriptionTopicsForm from "../../../../components/FoodLog/NotificationSettings/Form";
import { getAthleteNotificationSettings } from "../../../../components/FoodLog/NotificationSettings/utils";
import { FoodLogNotificationSettings } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    card: {},
    notificationsPanelContent: {
      padding: theme.spacing(3),
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    panelText: {},
    panelIcon: {},
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    green: { color: theme.palette.success.main },
    paddingTop: { paddingTop: theme.spacing(3) },
  })
);

interface AthleteSummaryNotificationsProps {
  athleteId: string;
  athleteNotificationSettings: FoodLogNotificationSettings | null;
  teamNotificationSettings: FoodLogNotificationSettings | null;
}

const AthleteSummaryNotifications = ({
  athleteId,
  athleteNotificationSettings,
  teamNotificationSettings,
}: AthleteSummaryNotificationsProps) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const classes = useStyles();
  const readOnlyFormState = getAthleteNotificationSettings({
    athleteNotificationSettings,
    teamNotificationSettings,
  });
  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3">Notifications</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setEditModalOpen(true)}>
          Edit
        </Button>
      </div>
      <div className={classes.notificationsPanelContent}>
        {readOnlyFormState && Object.values(readOnlyFormState).some(x => x === true) ? (
          <div>
            <Card raised className={classes.card}>
              <CardActionArea onClick={() => setEditModalOpen(true)}>
                <CardContent className={classes.row}>
                  <div className={classes.panelText}>
                    <Typography variant="h3">{`Subscribed`}</Typography>
                    <Typography variant="body2">You will receive notifications for checked boxes below</Typography>
                  </div>
                  <div className={classes.panelIcon}>
                    <RssFeedIcon className={classes.green} fontSize="large" />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
            <div className={classes.paddingTop}>
              <FoodLogNotificationSubcriptionTopicsForm state={{ ...readOnlyFormState, disabled: true }} dispatch={() => {}} />
            </div>
          </div>
        ) : (
          <Card raised className={classes.card}>
            <CardActionArea onClick={() => setEditModalOpen(true)}>
              <CardContent className={classes.row}>
                <div className={classes.panelText}>
                  <Typography variant="h3">Not Subscribed</Typography>
                  <Typography variant="body2">Click to subscribe to this athlete's notifications.</Typography>
                </div>
                <div className={classes.panelIcon}>
                  <RssFeedIcon fontSize="large" />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      </div>
      {editModalOpen && (
        <FoodLogNotificationSubcriptionTopicsModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          athleteId={athleteId}
          athleteNotificationSettings={athleteNotificationSettings}
          teamNotificationSettings={teamNotificationSettings}
        />
      )}
    </>
  );
};

export default AthleteSummaryNotifications;

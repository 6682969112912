import { ListItem, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { formatServingAmountWithTotalWeight } from "@notemeal/shared/ui/ServingAmount/utils";
import { round } from "@notemeal/shared/ui/utils/numbers";
import React, { ReactNode } from "react";
import { FullServingFragment } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemRoot: {
      minHeight: "auto",
    },
  })
);

interface ServingAmountsServingsMenuItemProps {
  onClose: () => void;
  onSelect: (serving: FullServingFragment) => void;
  serving: FullServingFragment;
  children?: ReactNode;
}

const ServingAmountsServingsMenuItem = ({ onClose, onSelect, serving, children }: ServingAmountsServingsMenuItemProps) => {
  const classes = useStyles();

  const weight = serving.weight && round(serving.weight, 1);

  const label =
    serving.units === "grams" || serving.units === "gram(s)" || !weight
      ? serving.units
      : formatServingAmountWithTotalWeight({ amount: serving.defaultAmount, serving: { units: serving.units, weight } });

  return (
    <ListItem
      classes={{ root: classes.menuItemRoot }}
      dense
      onClick={() => {
        onSelect(serving);
        onClose();
      }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      {children}
    </ListItem>
  );
};

export default ServingAmountsServingsMenuItem;

import { List, ListItem, SxProps, Typography } from "@mui/material";
import React, { useCallback } from "react";
import InfiniteScrollContainer from "../../../../../components/universal/InfiniteScroll/InfiniteScrollContainer";
import { UseInfiniteCursorConnectionScrollResults } from "../../../../../components/universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import { AthleteForCustomTagFragment } from "../../../../../types";
import AthleteListItem from "./AthleteListItem";

const listItemStyle = { width: `calc(100% - ${1})` };

interface AthleteInfiniteScrollProps {
  onClickItem: (athlete: AthleteForCustomTagFragment) => void;
  selectedAthleteIds: string[];
  sx?: SxProps;
  searchResults: UseInfiniteCursorConnectionScrollResults<"athletesForCustomTagCreationCursorConnection", AthleteForCustomTagFragment>;
}

const AthleteInfiniteScroll = ({ onClickItem, selectedAthleteIds, sx, searchResults }: AthleteInfiniteScrollProps) => {
  const renderItem = useCallback(
    (athlete: AthleteForCustomTagFragment, forwardRef?: React.MutableRefObject<HTMLDivElement | null>) => {
      return (
        <div ref={forwardRef} key={athlete.id}>
          <AthleteListItem
            athlete={athlete}
            onClick={onClickItem}
            sx={listItemStyle} />
        </div>
      );
    },
    [onClickItem]
  );

  const filterEdges = useCallback((athlete: AthleteForCustomTagFragment) => !selectedAthleteIds.includes(athlete.id), [selectedAthleteIds]);

  return (
    <List disablePadding sx={sx}>
      <InfiniteScrollContainer
        noItemsComponent={
          <ListItem sx={listItemStyle}>
            <Typography>No Athletes Found</Typography>
          </ListItem>
        }
        renderItem={renderItem}
        infiniteScrollResults={searchResults}
        loadingOffset={10}
        filterEdges={filterEdges}
      />
    </List>
  );
};

export default AthleteInfiniteScroll;

import { Box, Typography, styled } from "@mui/material";
import React from "react";

export const MACRO_LINE_HEIGHT = "18px";

const MacroBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  height: MACRO_LINE_HEIGHT,
}));

const MacroText = styled(Typography)(
  ({
    theme: {
      palette: { highEmphasisText },
    },
  }) => ({
    color: highEmphasisText,
    fontWeight: 400,
    fontSize: "14px",
    whiteSpace: "pre-wrap",
  })
);

const Cho = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.accents.green[600],
  display: "inline",
  gap: 2,
}));
const Pro = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.accents.blueDark[500],
  display: "inline",
}));
const Fat = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.accents.orangeDark[600],
  display: "inline",
}));

interface MacrosProps {
  cho: number;
  pro: number;
  fat: number;
  kcal: number;
}

export const Macros = ({ cho, pro, fat, kcal }: MacrosProps) => {
  return (
    <MacroBox>
      <MacroText>
        {kcal}kcal{"    "}
        <Cho>CHO</Cho>: {cho}g{"    "}
        <Pro>PRO</Pro>: {pro}g{"    "}
        <Fat>FAT</Fat>: {fat}g
      </MacroText>
    </MacroBox>
  );
};

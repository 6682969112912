import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import React from "react";
import {
  BulkImportFoodFragment,
  OrgFoodsDocument,
  UnitFragment,
  useCreateOrgGenericFoodsMutation,
  useOrgFoodsQuery,
  useUpdateOrgGenericFoodsMutation,
} from "../../../../types";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { ICrudHandler } from "../types";
import { parseImportError } from "../utils";
import BaseFoodImportTabPanel, { ImportFoodProps } from "./BaseFoodTabPanel";
import { ImportableFoodEntry } from "./types";
import { AdditionalFoodInfo, getOrgFoodsInsertInput, getOrgFoodsUpdateInput } from "./utils";

interface OrgFoodImportTabPanelProps extends Pick<ImportFoodProps, "loadAndParse" | "matchFields" | "fields" | "description"> {
  units: readonly UnitFragment[];
}

const OrgFoodImportTabPanel = ({ units, ...restProps }: OrgFoodImportTabPanelProps) => {
  const localeContext = useLocaleContext();

  const useFoodsQuery = () => {
    const { data, loading } = useOrgFoodsQuery();
    return { foods: data?.orgFoods, loading };
  };

  const { setMessage } = useSnackbar();
  const [createGenericFoods] = useCreateOrgGenericFoodsMutation({
    onError: e => setMessage("error", parseImportError(e)),
  });
  const [editGenericFoods] = useUpdateOrgGenericFoodsMutation({
    onError: e => setMessage("error", parseImportError(e)),
  });

  const handleInsertFoods = ({ matches, onCacheUpdate }: ICrudHandler<ImportableFoodEntry, AdditionalFoodInfo>) => {
    const input = {
      foods: getOrgFoodsInsertInput(matches, units, localeContext.locale),
    };

    createGenericFoods({
      variables: { input },
      update: onCacheUpdate,
      refetchQueries: [{ query: OrgFoodsDocument }],
    });
  };

  const handleUpdateFoods = ({
    matches,
    onCacheUpdate,
    foods,
  }: ICrudHandler<ImportableFoodEntry, AdditionalFoodInfo> & {
    foods: readonly BulkImportFoodFragment[] | undefined;
  }) => {
    const input = {
      foods: getOrgFoodsUpdateInput(matches, units, foods),
    };
    editGenericFoods({
      variables: { input },
      update: onCacheUpdate,
      refetchQueries: [{ query: OrgFoodsDocument }],
    });
  };

  return (
    <BaseFoodImportTabPanel
      mode="org"
      useFoodsQuery={useFoodsQuery}
      handleInsertFoods={handleInsertFoods}
      handleUpdateFoods={handleUpdateFoods}
      {...restProps}
    />
  );
};

export default OrgFoodImportTabPanel;

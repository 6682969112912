import { FormControl, InputLabel, MenuItem, Select, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch } from "react";
import PhoneNumberInput from "../../../../../../components/global/PhoneNumberInput";
import { PreferredContactMethodType } from "../../../../../../types";
import { RestaurantLocationContactFormAction, RestaurantLocationContactFormState } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textbox: {
      display: "flex",
      justifyContent: "space-around",
      marginBottom: theme.spacing(2),
    },
    autoCompleteText: {
      width: 250,
    },
  })
);

interface RestaurantLocationContactFormProps {
  state: RestaurantLocationContactFormState;
  dispatch: Dispatch<RestaurantLocationContactFormAction>;
}

const RestaurantLocationContactForm = ({ state, dispatch }: RestaurantLocationContactFormProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.textbox}>
        <TextField
          value={state.title}
          className={classes.autoCompleteText}
          onChange={e =>
            dispatch({
              type: "CHANGE_TITLE",
              payload: { title: e.target.value },
            })
          }
          label="Title"
        />
      </div>
      <div className={classes.textbox}>
        <TextField
          required
          value={state.firstName}
          className={classes.autoCompleteText}
          onChange={e =>
            dispatch({
              type: "CHANGE_FIRST_NAME",
              payload: { firstName: e.target.value },
            })
          }
          label="First Name"
        />
      </div>
      <div className={classes.textbox}>
        <TextField
          required
          value={state.lastName}
          className={classes.autoCompleteText}
          onChange={e =>
            dispatch({
              type: "CHANGE_LAST_NAME",
              payload: { lastName: e.target.value },
            })
          }
          label="Last Name"
        />
      </div>
      <div className={classes.textbox}>
        <TextField
          value={state.email}
          className={classes.autoCompleteText}
          onChange={e =>
            dispatch({
              type: "CHANGE_EMAIL",
              payload: { email: e.target.value },
            })
          }
          label="Email"
        />
      </div>
      <div className={classes.textbox}>
        <PhoneNumberInput
          setInput={input =>
            dispatch({
              type: "CHANGE_PHONE_NUMBER",
              payload: { phoneNumber: input },
            })
          }
          input={state.phoneNumber || ""}
        />
      </div>

      <FormControl className={classes.textbox}>
        <InputLabel>{"Preferred contact method:"}</InputLabel>
        <Select
          value={state.preferredContactMethod}
          onChange={e =>
            dispatch({
              type: "CHANGE_PREFERRED_CONTACT_METHOD",
              payload: {
                preferredContactMethod: e.target.value as PreferredContactMethodType,
              },
            })
          }
          label="Preferred Contact Method"
        >
          <MenuItem value={"email"}>{"Email"}</MenuItem>
          <MenuItem value={"phoneNumber"}>{"Phone Number"}</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default RestaurantLocationContactForm;

import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { sendEmailRecoveryLink, sendSmsRecoveryLink } from "@notemeal/shared/ui/utils/tokens";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

enum PageState {
  ProvideEmailAddressOrPhoneNumber,
  Finished,
}

export const ForgotUsernamePage = () => {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorEmailAddress, setErrorEmailAddress] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [pageState, setPageState] = useState<PageState>(PageState.ProvideEmailAddressOrPhoneNumber);

  const handleChangeEmailAddress = (emailAddress: string) => {
    setEmailAddress(emailAddress);
    setErrorEmailAddress("");
  };

  const handleChangePhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    setErrorPhoneNumber("");
  };

  const handleSendUsername = () => {
    if (emailAddress) {
      sendEmailRecoveryLink({ email: emailAddress })
        .then(() => {
          setPageState(PageState.Finished);
        })
        .catch(e => {
          setErrorEmailAddress(e.response?.data?.errors[0]);
        });
    } else if (phoneNumber) {
      sendSmsRecoveryLink({ phoneNumber })
        .then(() => {
          setPageState(PageState.Finished);
        })
        .catch(e => {
          setErrorPhoneNumber(e.response?.data?.errors[0]);
        });
    }
  };

  const bothEmpty = !emailAddress && !phoneNumber;
  const emailOrPhone = !!emailAddress ? "email" : "phone";

  const provideEmailAddressOrPhoneNumber = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">Forgot username?</Typography>
        <Typography variant="subtitle1">
          Enter the email or phone number associated with your account to send the username reminder.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Email"
          placeholder="john.doe@teamworks.com"
          disabled={!bothEmpty && !emailAddress}
          error={!!errorEmailAddress}
          helperText={errorEmailAddress}
          value={emailAddress}
          onChange={e => handleChangeEmailAddress(e.target.value)}
        />
        <Divider>or</Divider>
        <TextField
          label="Phone Number"
          placeholder="(000) 000-0000"
          disabled={!bothEmpty && !phoneNumber}
          error={!!errorPhoneNumber}
          helperText={errorPhoneNumber}
          value={phoneNumber}
          onChange={e => handleChangePhoneNumber(e.target.value)}
        />
      </Box>
      <Button disabled={bothEmpty} onClick={handleSendUsername}>
        Send Username
      </Button>
    </>
  );

  const finished = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">{`Check your ${emailOrPhone}`}</Typography>
        <Typography variant="subtitle1">{`Check your ${emailOrPhone} for the username reminder.`}</Typography>
      </Box>
      <Button onClick={() => navigate(-1)}>Back to Login</Button>
    </>
  );

  return pageState === PageState.ProvideEmailAddressOrPhoneNumber ? provideEmailAddressOrPhoneNumber : finished;
};

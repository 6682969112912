import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { CompassRecipe } from "@notemeal/graphql/types";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useRef } from "react";
import { FixedSizeList } from "react-window";
import { RenderRow } from "./ListRow";
import { InvalidSearch } from "./types";

const CONTENT_HEIGHT = 430;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      height: CONTENT_HEIGHT,
    },
    contentText: {
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
      fontWeight: "500",
    },
    placeholder: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface ListDisplayProps {
  recipes: readonly CompassRecipe[] | null;
  selectedRecipe: CompassRecipe | null;
  setSelectedRecipe: (recipe: CompassRecipe | null) => void;
  loading: boolean;
  invalidSearch: InvalidSearch | null;
}

export const ListDisplay = ({ recipes, loading, invalidSearch, setSelectedRecipe, selectedRecipe }: ListDisplayProps) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();
  return (
    <>
      <div>
        {recipes !== null && (
          <Typography className={classes.contentText}>
            Search Results: {recipes.length} {recipes.length !== 1 ? "Recipes" : "Recipe"}
          </Typography>
        )}
      </div>
      <div className={classes.content} ref={listRef}>
        {loading ? (
          <Loading />
        ) : (
          recipes !== null && (
            <FixedSizeList
              width="100%"
              height={CONTENT_HEIGHT}
              itemSize={85}
              itemCount={recipes.length}>
              {props => (
                <RenderRow
                  {...props}
                  listRef={listRef}
                  recipe={recipes[props.index]}
                  setSelectedRecipe={setSelectedRecipe}
                  selectedRecipe={selectedRecipe}
                />
              )}
            </FixedSizeList>
          )
        )}
      </div>
    </>
  );
};

import { AutocompleteRenderOptionState, createFilterOptions, FilterOptionsState, SxProps } from "@mui/material";
import React, { useCallback } from "react";
import { BasicOption } from "../../../components/universal/BaseAsyncAutocomplete";
import { GetQueryVariablesFromPaginationAndInputArgs } from "../../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import { PositionWitSportFragment, usePositionBySportCursorConnectionQuery } from "../../../types";
import BaseTagSelector, { BaseTagSelectorPassThroughProps } from "./BaseTagSelector";

interface PositionSelectorProps extends BaseTagSelectorPassThroughProps {
  sx?: SxProps;
  selectedPositions: readonly PositionWitSportFragment[];
  onChange: (newPositions: PositionWitSportFragment[]) => void;
  filterPositions?: (positions: PositionWitSportFragment[]) => PositionWitSportFragment[];
}

const PositionSelector = ({ sx, selectedPositions, onChange, filterPositions, ...passTroughProps }: PositionSelectorProps) => {
  const getQueryVariablesForPosition = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
      },
    }),
    []
  );

  const transformAndFilterPositionOptions = (_edges: PositionWitSportFragment[] | undefined): PositionWitSportFragment[] => {
    const edges = _edges ?? [];
    return filterPositions ? filterPositions(edges) : edges;
  };
  const filterOptions = (
    positions: PositionWitSportFragment[],
    state: FilterOptionsState<PositionWitSportFragment>
  ): PositionWitSportFragment[] =>
    createFilterOptions<PositionWitSportFragment>({
      stringify: () => state.inputValue,
    })(positions, state);

  const positionEdgesAreEqual = useCallback((edge1: PositionWitSportFragment, edge2: PositionWitSportFragment) => {
    return edge1.id === edge2.id;
  }, []);

  const handleChange = (_: string, positions: PositionWitSportFragment[]) => onChange(positions);

  return (
    <BaseTagSelector
      sx={sx}
      queryKey="positionBySportCursorConnection"
      useCursorConnectionQuery={usePositionBySportCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariablesForPosition}
      transformAndFilterOptions={transformAndFilterPositionOptions}
      renderOption={(position: PositionWitSportFragment, _state: AutocompleteRenderOptionState) => <BasicOption element={position.name} />}
      handleChange={handleChange}
      getOptionLabel={(position: PositionWitSportFragment) => position.name}
      getCustomOptionKey={(position: PositionWitSportFragment) => position.id}
      filterOptions={filterOptions}
      noOptionsText="No Positions Found"
      inputPlaceholder="Positions"
      edgesAreEqual={positionEdgesAreEqual}
      value={selectedPositions.concat()}
      groupBy={(position: PositionWitSportFragment) => position.sport.name}
      {...passTroughProps}
    />
  );
};

export default PositionSelector;

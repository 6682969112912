import React from "react";
import { MenuItemRowInfoWithAmount, OrderRowDataInfo } from "../utils";
import Row from "../Shared/Row";
import { getMenuItemCellBodyElements, getMenuItemCellHeaderElements } from "../utils/tableUtils";

interface HeaderRowProps {
  length: number;
}

interface TableRowProps {
  orderRowInfo: OrderRowDataInfo;
  openShowMoreModal: (menuItems: readonly MenuItemRowInfoWithAmount[]) => void;
  length: number;
}

export const HeaderRow = ({ length }: HeaderRowProps) => {
  return <Row>{["Athlete Name", ...getMenuItemCellHeaderElements(length)]}</Row>;
};

const TableRow = ({ orderRowInfo, openShowMoreModal, length }: TableRowProps) => {
  return <Row>{[orderRowInfo.athleteName, ...getMenuItemCellBodyElements(orderRowInfo.menuItems, openShowMoreModal, length)]}</Row>;
};

export default TableRow;

import { Checkbox, FormControlLabel, Input, SxProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { MealMenuUserOrderLimitInput } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      gap: theme.spacing(4),
    },
    input: {
      marginLeft: theme.spacing(1),
      maxWidth: theme.spacing(8),
      "& input": {
        textAlign: "center",
      },
    },
  })
);

interface Props {
  limit: MealMenuUserOrderLimitInput | null;
  onChange: (value: string | null) => void;
  sx?: SxProps;
}

const UserOrderLimit = ({ onChange, limit, sx }: Props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(limit?.limit !== undefined && limit?.limit === null);

  // Takes input value, sanitizes it, then passes to onChange event handler
  const handleInputOnChange = (value: string) => {
    const result = value.replace(/[^0-9]/g, "").replace(/^0(\d)?/g, "$1");
    onChange(result);
  };

  // Takes checked value and resets input value if user unchecks checkbox
  const handleCheckboxOnChange = (checked: boolean) => {
    onChange(checked ? null : "1");
    setChecked(checked);
  };

  return (
    <div className={classes.wrapper}>
      <FormControlLabel
        label={
          <Typography variant="body2" noWrap>
            Order Limit:
          </Typography>
        }
        sx={sx}
        labelPlacement="start"
        control={
          <Input
            disabled={checked}
            className={classes.input}
            value={limit?.limit}
            onChange={e => handleInputOnChange(e.currentTarget.value)}
          />
        }
      />
      <FormControlLabel
        label={
          <Typography variant="body2" noWrap>
            No Limit
          </Typography>
        }
        sx={sx}
        control={<Checkbox checked={checked} onChange={e => handleCheckboxOnChange(e.currentTarget.checked)} />}
      />
    </div>
  );
};

export default UserOrderLimit;

import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { Button, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import MenuItemAppearanceGroupControlLabel from "../../../../components/MenuItemAppearance/GroupControlLabel";
import ServingAmountModeContextProvider from "../../../../components/ServingAmounts/contexts/ServingAmountMode";
import HelpTipIcon from "../../../../components/universal/HelpTipIcon";
import ReorderDialog from "../../../../components/universal/ReorderDialog";
import { NutritionixBrandedFood } from "../../../../types";
import { MenuSectionState } from "../types";
import MenuSectionForm from "./MenuSectionForm";
import { RestaurantMenuDialogAction } from "./restaurantMenuReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2, 1),
      display: "flex",
      alignItems: "center",
    },
    form: {
      paddingBottom: theme.spacing(2),
    },
    spacer: {
      flexGrow: 1,
    },
    quickActions: { display: "flex", flexDirection: "column" },
  })
);

interface RestaurantSectionGroupFormProps {
  menuSections: readonly MenuSectionState[];
  dispatch: (action: RestaurantMenuDialogAction) => void;
  restaurantNutritionixFoods: readonly NutritionixBrandedFood[];
  restaurantId: string;
}

const RestaurantSectionGroupForm = ({
  menuSections,
  dispatch,
  restaurantNutritionixFoods,
  restaurantId,
}: RestaurantSectionGroupFormProps) => {
  const classes = useStyles();
  const [reorderDialogOpen, setReorderDialogOpen] = useState(false);

  const usedMenuItemIds = menuSections.flatMap(section => {
    return [...section.menuItemAppearances.map(mia => mia.menuItem.id)];
  });

  return (
    <ServingAmountModeContextProvider restaurantNutritionixFoods={restaurantNutritionixFoods} restaurantId={restaurantId}>
      <div className={classes.title}>
        <Typography variant="h3" color="textSecondary">
          Edit Sections for Menu
        </Typography>
        <HelpTipIcon tip="Menus are organized by Section. Currently Menu Sections are not reusable" />
        <div className={classes.spacer} />
        <div className={classes.quickActions}>
          <MenuItemAppearanceGroupControlLabel
            field="availableForOrder"
            text="Available For Order"
            menuItemAppearances={menuSections.flatMap(section => section.menuItemAppearances)}
            onChange={availableForOrder =>
              dispatch({
                type: "MenuAllItemsAvailableForOrderAction",
                payload: {
                  availableForOrder,
                },
              })
            }
          />
          <MenuItemAppearanceGroupControlLabel
            field="allowSpecialRequests"
            text="Allow Special Requests"
            menuItemAppearances={menuSections.flatMap(section => section.menuItemAppearances)}
            onChange={allowSpecialRequests =>
              dispatch({
                type: "MenuAllItemsAllowSpecialRequestsAction",
                payload: {
                  allowSpecialRequests,
                },
              })
            }
            disabled={!menuSections.flatMap(section => section.menuItemAppearances).every(mia => mia.availableForOrder)}
          />
        </div>
        <Tooltip title="Re-order sections">
          <IconButton onClick={() => setReorderDialogOpen(true)} size="large">
            <LowPriorityIcon />
          </IconButton>
        </Tooltip>
      </div>
      {menuSections.map(section => (
        <div key={`${section.id}-section-form`} className={classes.form}>
          <MenuSectionForm
            menuSection={section}
            usedMenuItemIds={usedMenuItemIds}
            onChangeName={name =>
              dispatch({
                type: "MenuSectionChangeNameAction",
                payload: {
                  name,
                  menuSectionId: section.id,
                },
              })
            }
            onRemove={() =>
              dispatch({
                type: "RemoveMenuSectionAction",
                payload: {
                  menuSectionId: section.id,
                },
              })
            }
            onChangeMenuItemAppearances={action =>
              dispatch({
                ...action,
                type: action.type,
                payload: {
                  ...action.payload,
                  menuSectionId: section.id,
                },
              } as RestaurantMenuDialogAction)
            }
          />
        </div>
      ))}
      {reorderDialogOpen && (
        <ReorderDialog
          open={reorderDialogOpen}
          onClose={() => setReorderDialogOpen(false)}
          reorderableArray={menuSections}
          onDone={menuSections =>
            dispatch({
              type: "ReorderMenuSectionAction",
              payload: {
                menuSections,
              },
            })
          }
        />
      )}
      <Button
        onClick={() =>
          dispatch({
            type: "CreateMenuSectionAction",
          })
        }
      >
        Create New Section
      </Button>
    </ServingAmountModeContextProvider>
  );
};

export default RestaurantSectionGroupForm;

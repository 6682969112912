import axios, { AxiosResponse } from "axios";
import { AccountVerificationMethod, Role } from "../types";

export interface TokenOrg {
  id: string;
  name: string;
  teamworksId?: number | null;
}

export interface Errors {
  errors: string[];
}

export interface JwtUser {
  id: string;
  teamworksId?: number | null;
  username: string;
  isStaff: boolean;
  isSuperuser?: boolean;
  isActive: boolean;
  orgMembership: {
    id: string;
    teamworksId?: number | null;
    isActive: boolean;
    isAdmin: boolean;
    org: TokenOrg;
    roles: Role[];
  } | null;
  exp: number;
}
export type MaybeJwtUser = JwtUser | null | undefined;

export interface RefreshToken {
  userId: string;
}
export type MaybeRefreshToken = RefreshToken | null | undefined;

export const parseAccessToken = (accessToken: string | null | undefined): MaybeJwtUser => parseBase64Jwt(accessToken);

export const parseRefreshToken = (accessToken: string | null | undefined): MaybeRefreshToken => parseBase64Jwt(accessToken);

const parseBase64Jwt = (base64jwt: string | null | undefined): any => {
  // The cookie is base64 encoded, and stores JSON
  if (!base64jwt) {
    return null;
  }
  const base64encodedPayload = base64jwt.split(".")[1];
  if (!base64encodedPayload) {
    return null;
  }
  const decoded = atob(base64encodedPayload);
  if (!decoded) {
    return null;
  }
  return JSON.parse(decoded);
};

export interface LoginArgs {
  username: string;
  password: string;
  includeCookies?: boolean;
}

export interface LoginPayload {
  accessToken?: string;
  refreshToken: string;
}

const appUrlRoot = `${process.env["REACT_APP_URL_SCHEME"]}${process.env["REACT_APP_SERVER_DOMAIN"]}`;

export const login = async ({ username, password, includeCookies }: LoginArgs): Promise<AxiosResponse<LoginPayload>> => {
  return axios({
    method: "post",
    data: { username, password },
    withCredentials: true,
    url: `${appUrlRoot}/user/${includeCookies ? "web-login" : "login"}`,
  });
};

// TODO: When adding SSO from web, add includeCookies arg like login fn above
export type LoginWithTeamworksArgs = {
  teamworksOrgId?: number;
} & (
  | {
      idToken: string;
      teamworksIdToken?: undefined;
      teamworksDeviceSecret?: undefined;
    }
  | {
      idToken?: undefined;
      teamworksIdToken: string;
      teamworksDeviceSecret: string;
    }
);

export const loginWithTeamworks = async ({
  idToken,
  teamworksIdToken,
  teamworksDeviceSecret,
  teamworksOrgId,
}: LoginWithTeamworksArgs): Promise<AxiosResponse<LoginPayload>> => {
  return axios({
    method: "post",
    data: { idToken, teamworksOrgId, teamworksIdToken, teamworksDeviceSecret },
    withCredentials: true,
    url: `${appUrlRoot}/user/login-with-teamworks/mobile`,
  });
};

export interface RefreshArgs {
  refreshToken?: string;
  orgId?: string;
}

export interface RefreshPayload {
  accessToken: string;
  refreshToken: string;
}

export const refresh = async ({ refreshToken, orgId }: RefreshArgs): Promise<AxiosResponse<RefreshPayload>> => {
  let url: string;
  if (orgId) {
    url = `${appUrlRoot}/user/refresh/${orgId}`;
  } else {
    url = `${appUrlRoot}/user/refresh`;
  }
  return axios({
    method: "post",
    withCredentials: true,
    headers: refreshToken && {
      authorization: `Bearer ${refreshToken}`,
    },
    url,
  });
};

export interface ResetPasswordArgs {
  password: string;
  resetPasswordToken: string;
}

export const resetPassword = ({ password, resetPasswordToken }: ResetPasswordArgs): Promise<AxiosResponse<RefreshPayload>> => {
  return axios({
    method: "post",
    data: { password },
    withCredentials: true,
    url: `${appUrlRoot}/user/password/reset`,
    headers: {
      authorization: `Bearer ${resetPasswordToken}`,
    },
  });
};

export interface ActivateOrgMembershipWithLoginArgs {
  username: string;
  password: string;
  activateToken: string;
}

export const activateOrgMembershipWithLogin = ({
  username,
  password,
  activateToken,
}: ActivateOrgMembershipWithLoginArgs): Promise<AxiosResponse<RefreshPayload>> => {
  return axios({
    method: "post",
    data: { username, password },
    withCredentials: true,
    url: `${appUrlRoot}/org-membership/activate/org-membership-with-login`,
    headers: {
      authorization: `Bearer ${activateToken}`,
    },
  });
};

export interface ActivateOrgMembershipWithRefreshTokenArgs {
  refreshToken?: string;
  activateToken: string;
}

export const activateOrgMembershipWithRefreshToken = ({
  activateToken,
  refreshToken,
}: ActivateOrgMembershipWithRefreshTokenArgs): Promise<AxiosResponse<RefreshPayload>> => {
  return axios({
    method: "post",
    data: { activateToken },
    withCredentials: true,
    url: `${appUrlRoot}/org-membership/activate/org-membership-with-refresh-token`,
    headers: refreshToken && {
      authorization: `Bearer ${refreshToken}`,
    },
  });
};

export interface ActivateUserAndOrgMembershipArgs {
  username: string;
  password: string;
  activateToken: string;
}

export const activateUserAndOrgMembership = ({
  username,
  password,
  activateToken,
}: ActivateUserAndOrgMembershipArgs): Promise<AxiosResponse<RefreshPayload>> => {
  return axios({
    method: "post",
    data: { username, password },
    withCredentials: true,
    url: `${appUrlRoot}/org-membership/activate/user-and-org-membership`,
    headers: {
      authorization: `Bearer ${activateToken}`,
    },
  });
};

export interface AccountVerificationResponse {
  method: AccountVerificationMethod;
}

export interface SendPasswordResetLinkArgs {
  username: string;
}

export const sendPasswordResetLink = ({ username }: SendPasswordResetLinkArgs): Promise<AxiosResponse<AccountVerificationResponse>> => {
  return axios({
    method: "post",
    data: { username },
    withCredentials: true,
    url: `${appUrlRoot}/user/password/send-reset-link`,
  });
};

export interface SendEmailRecoveryLinkArgs {
  email: string;
}

export const sendEmailRecoveryLink = ({ email }: SendEmailRecoveryLinkArgs): Promise<AxiosResponse<AccountVerificationResponse>> => {
  return axios({
    method: "post",
    data: { email },
    withCredentials: true,
    url: `${appUrlRoot}/user/username/send-email-recovery-link`,
  });
};

export interface SendSmsRecoveryLinkArgs {
  phoneNumber: string;
}

export const sendSmsRecoveryLink = ({ phoneNumber }: SendSmsRecoveryLinkArgs): Promise<AxiosResponse<AccountVerificationResponse>> => {
  return axios({
    method: "post",
    data: { phoneNumber },
    withCredentials: true,
    url: `${appUrlRoot}/user/username/send-sms-recovery-link`,
  });
};

interface FetchUserOrgMembershipsArgs {
  refreshToken?: string;
}

interface FetchUserOrgMembershipsPayload {
  memberships: UserOrgMembership[];
}

export interface UserOrgMembership {
  id: string;
  roles: Role[];
  org: { id: string; name: string };
  isActive: boolean;
  isAdmin: boolean;
}

export const fetchUserOrgMemberships = async ({
  refreshToken,
}: FetchUserOrgMembershipsArgs): Promise<AxiosResponse<FetchUserOrgMembershipsPayload>> => {
  return axios({
    method: "get",
    headers: refreshToken && {
      authorization: `Bearer ${refreshToken}`,
    },
    withCredentials: true,
    url: `${appUrlRoot}/user/memberships`,
  });
};

export interface ResetPasswordUrlParams {
  resetPasswordToken: string;
}

export const RESET_PASSWORD_CLIENT_URL = "/reset-password/:resetPasswordToken";

export interface ActivateOrgMembershipUrlParams {
  activateToken: string;
}

export const ACTIVATE_ORG_MEMBERSHIP_CLIENT_URL = "/activate/:activateToken";

export const TEAMWORKS_APP_LAUNCH_CLIENT_URL = "/teamworks-app-launch";

type SelfServiceAthleteFormData = {
  firstName: string;
  lastName: string;
  email: string;
  orgId: string;
};
export type SelfServiceResponse = {
  success: boolean;
  error?: string;
};
export const sendSelfServiceRequest = (data: SelfServiceAthleteFormData): Promise<AxiosResponse<SelfServiceResponse>> => {
  return axios({
    method: "post",
    data,
    withCredentials: true,
    url: `${appUrlRoot}/org-membership/self-service-onboarding`,
  });
};

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { LinkedProfile, TeamworksProfilesState, UnlinkProfile } from "@notemeal/profile-sync";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { Dispatch, useState } from "react";
import ListsColumn from "../../shared/ListColumn";
import ListsContainer from "../../shared/ListsContainer";
import { TeamworksProfilesAction } from "../reducer";
import { LinkedProfilesList } from "../SyncRule/DisplayLists";
import { UnlinkProfilesList } from "../SyncRule/DisplayLists/UnlinkProfilesList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    listColumn: {
      overflow: "hidden",
    },
  })
);

interface UnlinkTeamworksDialogProps {
  open: boolean;
  onClose: () => void;
  state: TeamworksProfilesState;
  dispatch: Dispatch<TeamworksProfilesAction>;
  onUnlink: (profileToUnlink: LinkedProfile) => void;
  onUnlinkArchive: (profileToUnlink: LinkedProfile) => void;
  selectedId: string | null;
}

export const UnlinkTeamworksDialog = ({
  open,
  onClose,
  state,
  dispatch,
  onUnlink,
  onUnlinkArchive,
  selectedId,
}: UnlinkTeamworksDialogProps) => {
  const classes = useStyles();
  const [undoUnlinkProfile, setUndoUnlinkProfile] = useState<UnlinkProfile | null>(null);

  const pendingArchive = (notemealId: string) => {
    return state.unlinkProfiles.find(p => p.notemeal.id === notemealId)?.archiveAthlete ?? false;
  };

  const hasPendingRelink = (teamworksId: number) => {
    return !!state.linkedProfiles.find(p => p.isPending && p.teamworks.id === teamworksId);
  };

  const handleUndoUnlink = (profile: UnlinkProfile) => {
    if (hasPendingRelink(profile.teamworks.id)) {
      setUndoUnlinkProfile(profile);
    } else {
      dispatch({
        type: "UNDO_UNLINK_PROFILE",
        payload: { ...profile },
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        fullWidth
        classes={{ paper: classes.paper }}>
        <DialogTitle>
          Select Linked Teamworks/Nutrition Profiles to Unlink
          <Typography variant="body1Medium">Nutrition accounts linked to Teamworks Profiles that should be unlinked</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <ListsContainer>
            <ListsColumn className={classes.listColumn}>
              <LinkedProfilesList
                selectedId={selectedId}
                profiles={state.linkedProfiles.filter(p => !p.isPending)}
                onUnlink={onUnlink}
                onUnlinkArchive={onUnlinkArchive}
              />
            </ListsColumn>
            <ListsColumn className={classes.listColumn}>
              <UnlinkProfilesList
                profiles={state.unlinkProfiles}
                onCancel={handleUndoUnlink}
                pendingArchive={pendingArchive}
                selectedId={null}
              />
            </ListsColumn>
          </ListsContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Done</Button>
        </DialogActions>
      </Dialog>
      {undoUnlinkProfile && (
        <ConfirmationDialog
          open={!!undoUnlinkProfile}
          title="Profile Has Pending Relink"
          message="The profile being unlinked has a pending relink. If you wish to cancel the profile unlinking, the pending relink will also be cancelled. Do you wish to proceed?"
          onCancel={() => setUndoUnlinkProfile(null)}
          onConfirm={() => {
            dispatch({
              type: "UNDO_UNLINK_RELINK_PROFILE",
              payload: undoUnlinkProfile,
            });
            setUndoUnlinkProfile(null);
          }}
          confirmLabel="Yes"
          variant="containedDestructive"
        />
      )}
    </>
  );
};

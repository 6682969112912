import { useApolloClient } from "@apollo/client";
import { Dialog, DialogContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useEffect, useRef, useState } from "react";
import { Boolean } from "runtypes";
import useSound from "use-sound";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";
import {
  KdsPageMealMenuDiningStationFragment,
  KdsPageMealMenuInDateRangeFragment,
  KdsPageMealMenuPlateOrderPreviewFragment,
  KdsPageMealMenusDocument,
  KdsPageMealMenusQuery,
  KdsPageMealMenusQueryVariables,
  KdsPageMenuOrderPreviewFragment,
  KdsTicketMealMenuPlateOrderFragment,
  KdsTicketMenuOrderFragment,
  KdsTicketsMultipleMenuOrdersAndPlateOrdersDocument,
  KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery,
  KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables,
  MenuOrderItemStatus,
  UpdateMultipleMealMenuPlateItemOrderStatusesInput,
  useKdsPageMealMenusQuery,
  usePublishedBulkOrderSubscription,
  usePublishedMealMenuPlateOrderItemStatusesSubscription,
  usePublishedMenuOrderItemStatusesSubscription,
  usePublishedMenuOrderSubscription,
  useUpdateMenuOrderItemStatusesMutation,
  useUpdateMultipleMealMenuPlateItemOrderStatusesMutation,
  useUpdateMultipleMenuOrderItemStatusesMutation,
} from "../../types";
import { useLocalStorageValueAsType } from "../../utils/localStorage";
import { menuOrderItemIdsToDiningStationName } from "../../utils/menuItems";
import KdsDialogContent from "./Content";
import NewDiningStationDialog, { ChangeType } from "./DiningStationsChangedDialog";
import KdsDialogHeader from "./Header";
import NewOrderSnackbar from "./NewOrderSnackbar";
import KdsPagePrinterSettingsDialog from "./PrinterSettingsDialog";
import { WebPrinter, useWebPrinter } from "./WebPrinter";
import { useSavedPrinterType } from "./WebPrinter/PrinterType";
import { MakeHandleAirPrintFn, WebPrinterTicketOrder } from "./types";
import {
  OrderWithKey,
  getCancelledOrderTickets,
  getFormattedOrderKeyWithTicketHeader,
  getNewIds,
  getXMLForCancelledOrderTicket,
  getXMLForOrderTicket,
  hasNewId,
  parseFormattedOrderKey,
  parseFormattedOrderKeyWithTicketHeader,
} from "./utils";

interface KdsPageDialogProps {
  onClose: () => void;
  selectedMealMenus: KdsPageMealMenuInDateRangeFragment[];
  selectedDiningStationIds: string[];
  mealMenuDiningStations: (KdsPageMealMenuDiningStationFragment & {
    mealMenuId: string;
  })[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullHeight: { height: "100%" },
    paddingRight: {
      paddingRight: theme.spacing(2),
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    noprint: {
      "@media print": {
        display: "none !important",
      },
      "@media screen": {
        display: "block",
      },
    },
  })
);

// 1. KDS on DS 2 (Order existed before edit, but per spec KDS should treat as not having existed)
// Add Menu Order, item A (on DS 1)
// Edit Menu Order, add item B (on DS 2)
// Snapshot 1

// 2. KDS on DS 2 (Order existed before edit, and per spec KDS should treat show updatedAt)
// Add Menu Order, item C (on DS 2) item D (on DS 2)
// Edit Menu Order, remove item C and add item B (on DS 2), item D untouched (on DS 2)
// Snapshot 2

// 3. Add item C on DS 2 -> Show updatedAt

// Fail here by not showing Updated At

// no itemsByDiningStation (aka on DS 2) with updateCount > 0

// If there is more that one updatedAt on the itemsByDiningStation (down to the second? minute? 10s?)

// KDS has seen this order before (via state that you'd lose on refresh, etc.)

// order.createdAt != order.updatedAt

const KdsPageDialog = ({ onClose, selectedMealMenus, selectedDiningStationIds, mealMenuDiningStations }: KdsPageDialogProps) => {
  const { value: printerType, setValue: setPrinterName } = useSavedPrinterType();
  const printer = useWebPrinter(printerType);
  const [autoprintEnabled, setAutoprintEnabled] = useLocalStorageValueAsType("autoprintEnabled", Boolean);
  const classes = useStyles();
  const [newestOrder, setNewestOrder] = useState<KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment | null>(null);
  const { setMessage } = useSnackbar();
  const [userSearch, setUserSearch] = useState<null | string>(null);
  const variables = {
    mealMenuIds: selectedMealMenus.map(mm => mm.id),
    diningStationIds: selectedDiningStationIds,
  };
  const { dateFnsLocale } = useDateFormatting();

  // TODO: how t handle this for for MealMenuPlateOrders?
  const makeInjectHtmlAndHandlePrint: MakeHandleAirPrintFn =
    (getPrintableHtmlSelectorFn: (orderId?: string) => string) => (orderId?: string) => {
      const selector = getPrintableHtmlSelectorFn(orderId);
      const clonedEl = document.getElementById(selector)?.innerHTML;
      if (clonedEl) {
        const el = document.getElementById("print-node");
        if (el) {
          el.innerHTML = clonedEl;
          window.print();
        } else {
          alert("Failure. #print-node DNE!");
        }
      } else {
        alert(`Failure. #${selector} DNE!`);
      }
    };

  const makeHandlePrintOrderViaUsb = (printer: WebPrinter) => async (order: WebPrinterTicketOrder) => {
    return await printer.print(
      getXMLForOrderTicket({
        order,
        mealMenuDiningStations,
        selectedDiningStationIds,
        isAutoprinting: false,
        locale: dateFnsLocale,
      })
    );
  };

  const handleAutoprint = async (newOrderIds: string[], newPlateOrderIds: string[]) => {
    // TODO: double check that this works with empty lists
    const variables: KdsTicketsMultipleMenuOrdersAndPlateOrdersQueryVariables = {
      menuOrderIds: newOrderIds,
      mealMenuPlateOrderIds: newPlateOrderIds,
      diningStationIds: selectedDiningStationIds,
    };
    const { data: fullTicketData, error } = await client.query<KdsTicketsMultipleMenuOrdersAndPlateOrdersQuery>({
      query: KdsTicketsMultipleMenuOrdersAndPlateOrdersDocument,
      variables,
    });
    if (error) {
      setMessage("warning", String(error));
    }
    const menuOrders = fullTicketData.menuOrders;
    const plateOrders = fullTicketData.mealMenuPlateOrders;
    const promises = [...menuOrders, ...plateOrders].map(async mo => {
      if (data) {
        const hasItemsFromSelectedDiningStations = mo.itemsByDiningStation.length > 0;
        if (hasItemsFromSelectedDiningStations) {
          const fullTicket = getXMLForOrderTicket({
            order: mo,
            mealMenuDiningStations,
            selectedDiningStationIds,
            isAutoprinting: true,
            locale: dateFnsLocale,
          });
          playBell();
          console.log("Autoprinting via polling!");
          if (printer) {
            return await printer.print(fullTicket).then(() => {
              console.log("Printed ticket " + mo.id + " via polling");
            });
          }
        }
      }
    });
    await Promise.all(promises);
  };

  const [playBell] = useSound("/sounds/bellz.mp3");
  // TODO: why is this a ref vs state with a dispatch? could that be the cause of phantom deleted orders?
  const processedOrderKeysWithTicketInfoData = useRef<{
    menuOrders: OrderWithKey<KdsPageMenuOrderPreviewFragment>[];
    mealMenuPlateOrders: OrderWithKey<KdsPageMealMenuPlateOrderPreviewFragment>[];
  } | null>(null);
  const diningStationIds = useRef<string[] | null>(null);
  const [diningStationsChangedWarningOpen, setDiningStationsChangedWarningOpen] = useState<ChangeType | null>(null);
  const [printerSettingsDialogOpen, setPrinterSettingsDialogOpen] = useState(false);
  const onClosePrinterSettings = () => setPrinterSettingsDialogOpen(false);
  const { data, loading, stopPolling, startPolling } = useKdsPageMealMenusQuery({
    variables,
    fetchPolicy: "network-only",
    onError: e => {
      console.error(e);
      setMessage("warning", "Failed to fetch order updates. Check internet connection.");
    },
  });

  const client = useApolloClient();

  useEffect(() => {
    const incomingMenuOrderKeysWithTicketInfo = data?.mealMenus.flatMap(mm => mm.allOrders).map(getFormattedOrderKeyWithTicketHeader);
    const incomingPlateOrderKeysWithTicketInfo = data?.mealMenus
      .flatMap(mm => mm.allMealMenuPlateOrders)
      .map(getFormattedOrderKeyWithTicketHeader);
    if (incomingMenuOrderKeysWithTicketInfo !== undefined && incomingPlateOrderKeysWithTicketInfo !== undefined && printer) {
      if (processedOrderKeysWithTicketInfoData.current !== null) {
        const newMenuOrderKeys = getNewIds({
          existingIds: processedOrderKeysWithTicketInfoData.current.menuOrders.map(o => o.key),
          incomingIds: incomingMenuOrderKeysWithTicketInfo.map(o => o.key),
        });

        const newMenuPlateOrderKeys = getNewIds({
          existingIds: processedOrderKeysWithTicketInfoData.current.mealMenuPlateOrders.map(o => o.key),
          incomingIds: incomingPlateOrderKeysWithTicketInfo.map(o => o.key),
        });

        const cancelledMenuOrderTickets = getCancelledOrderTickets(
          {
            existingOrders: processedOrderKeysWithTicketInfoData.current.menuOrders,
            incomingOrders: incomingMenuOrderKeysWithTicketInfo,
          },
          dateFnsLocale
        );

        const cancelledPlateOrderTickets = getCancelledOrderTickets(
          {
            existingOrders: processedOrderKeysWithTicketInfoData.current.mealMenuPlateOrders,
            incomingOrders: incomingPlateOrderKeysWithTicketInfo,
          },
          dateFnsLocale
        );

        cancelledMenuOrderTickets.forEach(async cot => await printer.print(cot));
        cancelledPlateOrderTickets.forEach(async cot => await printer.print(cot));
        processedOrderKeysWithTicketInfoData.current = {
          menuOrders: incomingMenuOrderKeysWithTicketInfo,
          mealMenuPlateOrders: incomingPlateOrderKeysWithTicketInfo,
        };

        if (newMenuOrderKeys.length > 0 || newMenuPlateOrderKeys.length > 0) {
          // We polled, and we found some new orders!
          const newOrderIds = newMenuOrderKeys.map(k => parseFormattedOrderKey(k).orderId);
          const newPlateOrderIds = newMenuOrderKeys.map(k => parseFormattedOrderKey(k).orderId);
          (async () => await handleAutoprint(newOrderIds, newPlateOrderIds))();
        }
      } else {
        processedOrderKeysWithTicketInfoData.current = {
          menuOrders: incomingMenuOrderKeysWithTicketInfo,
          mealMenuPlateOrders: incomingPlateOrderKeysWithTicketInfo,
        };
      }
    }
  }, [data, loading]);

  // TODO: does this even do anything?
  // Do these just make the KDS listen for the status updates?
  usePublishedMenuOrderItemStatusesSubscription({
    variables: {
      scope: { mealMenuIds: selectedMealMenus.map(mm => mm.id) },
    },
  });
  usePublishedMealMenuPlateOrderItemStatusesSubscription({
    variables: {
      diningStationIds: selectedDiningStationIds,
      scope: { mealMenuIds: selectedMealMenus.map(mm => mm.id) },
    },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const { data } = subscriptionData;
      if (data) {
        const kdsPageMealMenusQuery = client.readQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
          query: KdsPageMealMenusDocument,
          variables,
        });
        const payload = data.mealMenuPlateItemOrderStatuses.payload;
        const { mealMenuId } = data.mealMenuPlateItemOrderStatuses.scope;
        switch (payload.__typename) {
          case "UpdateMultipleMealMenuPlateItemOrderStatusesPayload": {
            const { mealMenuPlateItemOrderStatuses } = payload;
            const mealMenuPlateOrders = mealMenuPlateItemOrderStatuses.map(s => s.mealMenuPlateOrder);
            const codes = mealMenuPlateItemOrderStatuses.map(s => s.mealMenuPlateOrder.code);
            console.log("Updating statuses for plate orders: ", codes.toString());
            const formattedIncomingOrders = mealMenuPlateOrders.map(mealMenuPlateOrder =>
              getFormattedOrderKeyWithTicketHeader(mealMenuPlateOrder)
            );
            const currentProcessedOrderKeysWithTicketInfoData = processedOrderKeysWithTicketInfoData.current ?? {
              mealMenuPlateOrders: [],
              menuOrders: [],
            };
            processedOrderKeysWithTicketInfoData.current = {
              ...currentProcessedOrderKeysWithTicketInfoData,
              mealMenuPlateOrders: [...currentProcessedOrderKeysWithTicketInfoData.mealMenuPlateOrders, ...formattedIncomingOrders],
            };

            // ^ writeQuery below will update `data` and `loading`, causing useEffect()
            // hook to run...need to prevent double printing by updating processedOrderKeys.
            if (kdsPageMealMenusQuery) {
              client.writeQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
                query: KdsPageMealMenusDocument,
                variables,
                data: {
                  ...kdsPageMealMenusQuery,
                  mealMenus: kdsPageMealMenusQuery.mealMenus.map(mm => ({
                    ...mm,
                    allMealMenuPlateOrders:
                      mm.id === mealMenuId ? [...mm.allMealMenuPlateOrders, ...mealMenuPlateOrders] : mm.allMealMenuPlateOrders,
                  })),
                },
              });
            }
            if (mealMenuPlateOrders[0]) {
              setNewestOrder(mealMenuPlateOrders[0]);
            }
          }
        }
      }
    },
  });

  // TODO: make the MealMenuPlateVersion of this
  usePublishedMenuOrderSubscription({
    variables: {
      diningStationIds: selectedDiningStationIds,
      scope: { mealMenuIds: selectedMealMenus.map(mm => mm.id) },
    },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const { data } = subscriptionData;
      if (data) {
        const kdsPageMealMenusQuery = client.readQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
          query: KdsPageMealMenusDocument,
          variables,
        });
        const payload = data.menuOrder.payload;
        switch (payload.__typename) {
          case "AddMenuOrderPayload": {
            console.log("Added Order: " + payload.menuOrder.code);
            const formattedIncomingOrder = getFormattedOrderKeyWithTicketHeader(payload.menuOrder);
            const currentProcessedOrderKeysWithTicketInfoData = processedOrderKeysWithTicketInfoData.current ?? {
              mealMenuPlateOrders: [],
              menuOrders: [],
            };
            processedOrderKeysWithTicketInfoData.current = {
              ...currentProcessedOrderKeysWithTicketInfoData,
              menuOrders: [...currentProcessedOrderKeysWithTicketInfoData.menuOrders, formattedIncomingOrder],
            };

            // ^ writeQuery below will update `data` and `loading`, causing useEffect()
            // hook to run...need to prevent double printing by updating processedOrderKeys.
            if (kdsPageMealMenusQuery) {
              client.writeQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
                query: KdsPageMealMenusDocument,
                variables,
                data: {
                  ...kdsPageMealMenusQuery,
                  mealMenus: kdsPageMealMenusQuery.mealMenus.map(mm => ({
                    ...mm,
                    allOrders: mm.id === payload.menuOrder.mealMenu.id ? [...mm.allOrders, payload.menuOrder] : mm.allOrders,
                  })),
                },
              });
            }
            setNewestOrder(payload.menuOrder);

            // TODO: De-duplicate with block in "EditMenuOrderPayload" case below
            const hasItemsFromSelectedDiningStations = payload.menuOrder.itemsByDiningStation.length > 0;
            if (hasItemsFromSelectedDiningStations) {
              autoprint(
                getXMLForOrderTicket({
                  order: payload.menuOrder,
                  mealMenuDiningStations,
                  selectedDiningStationIds,
                  isAutoprinting: true,
                  locale: dateFnsLocale,
                })
              );
              playBell();
            }
            break;
          }
          case "EditMenuOrderPayload": {
            console.log("Edited Order: " + payload.menuOrder.code);

            // Do not re-process a ticket
            const formattedIncomingOrder = getFormattedOrderKeyWithTicketHeader(payload.menuOrder);
            if (
              processedOrderKeysWithTicketInfoData.current?.menuOrders &&
              processedOrderKeysWithTicketInfoData.current.menuOrders.map(k => k.key).includes(formattedIncomingOrder.key)
            ) {
              break;
            }
            const currentProcessedOrderKeysWithTicketInfoData = processedOrderKeysWithTicketInfoData.current ?? {
              mealMenuPlateOrders: [],
              menuOrders: [],
            };

            processedOrderKeysWithTicketInfoData.current = {
              ...currentProcessedOrderKeysWithTicketInfoData,
              menuOrders: [
                ...currentProcessedOrderKeysWithTicketInfoData.menuOrders.filter(
                  k => parseFormattedOrderKeyWithTicketHeader(k).orderId !== payload.menuOrder.id
                ),
                formattedIncomingOrder,
              ],
            };
            // TODO: De-duplicate with block in "AddMenuOrderPayload" case above
            const hasItemsFromSelectedDiningStations = payload.menuOrder.itemsByDiningStation.length > 0;
            playBell();
            if (hasItemsFromSelectedDiningStations) {
              autoprint(
                getXMLForOrderTicket({
                  order: payload.menuOrder,
                  mealMenuDiningStations,
                  selectedDiningStationIds,
                  isAutoprinting: autoprintEnabled,
                  locale: dateFnsLocale,
                })
              );
            } else {
              // since this is an edit and this order key hasn't been processed,
              // this means the order previously had items in this kds context
              // So: order was cancelled for KDS context.
              autoprint(getXMLForCancelledOrderTicket(payload.menuOrder, dateFnsLocale));
            }
            break;
          }
          case "RemoveMenuOrderPayload":
            //don't reprocess ticket
            const currentProcessedOrderKeysWithTicketInfoData = processedOrderKeysWithTicketInfoData.current ?? {
              mealMenuPlateOrders: [],
              menuOrders: [],
            };
            processedOrderKeysWithTicketInfoData.current = {
              ...currentProcessedOrderKeysWithTicketInfoData,
              menuOrders: [
                ...currentProcessedOrderKeysWithTicketInfoData.menuOrders.filter(
                  k => parseFormattedOrderKeyWithTicketHeader(k).orderId !== payload.menuOrderId
                ),
              ],
            };
            if (kdsPageMealMenusQuery) {
              client.writeQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
                query: KdsPageMealMenusDocument,
                variables,
                data: {
                  ...kdsPageMealMenusQuery,
                  mealMenus: kdsPageMealMenusQuery.mealMenus.map(mm => ({
                    ...mm,
                    allOrders: mm.allOrders.filter(mo => mo.id !== payload.menuOrderId),
                  })),
                },
              });
              const removedOrder = kdsPageMealMenusQuery.mealMenus.flatMap(mm => mm.allOrders).find(mo => mo.id === payload.menuOrderId);
              if (removedOrder && removedOrder.itemsByDiningStation.length > 0) {
                autoprint(getXMLForCancelledOrderTicket(removedOrder, dateFnsLocale));
                playBell();
                const name = removedOrder.athlete
                  ? `${removedOrder.athlete.lastName}, ${removedOrder.athlete.firstName}`
                  : removedOrder.userFullName;
                setMessage("success", `Order Cancelled (by '${name}')`);
              }
            }
            // We don't need to delete from kdsTicketMenuOrder b/c the kdsPageMealMenus query dicatates
            // what tickets actually get rendered. The ticket will be removed via the above.
            break;
        }
      }
    },
  });

  usePublishedBulkOrderSubscription({
    variables: {
      diningStationIds: selectedDiningStationIds,
      scope: { mealMenuIds: selectedMealMenus.map(mm => mm.id) },
    },
    onSubscriptionData: ({ subscriptionData, client }) => {
      const { data } = subscriptionData;
      if (data) {
        const kdsPageMealMenusQuery = client.readQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
          query: KdsPageMealMenusDocument,
          variables,
        });
        const payload = data.bulkOrder.payload;
        const { mealMenuId } = data.bulkOrder.scope;
        switch (payload.__typename) {
          case "AddBulkOrderPayload": {
            const { bulkOrder } = payload;
            console.log("Added Bulk Order: " + bulkOrder.code);
            const mealMenuPlateOrders = bulkOrder.mealMenuPlateOrders;
            const formattedIncomingOrders = mealMenuPlateOrders.map(mealMenuPlateOrder =>
              getFormattedOrderKeyWithTicketHeader(mealMenuPlateOrder)
            );
            const currentProcessedOrderKeysWithTicketInfoData = processedOrderKeysWithTicketInfoData.current ?? {
              mealMenuPlateOrders: [],
              menuOrders: [],
            };
            processedOrderKeysWithTicketInfoData.current = {
              ...currentProcessedOrderKeysWithTicketInfoData,
              mealMenuPlateOrders: [...currentProcessedOrderKeysWithTicketInfoData.mealMenuPlateOrders, ...formattedIncomingOrders],
            };

            // ^ writeQuery below will update `data` and `loading`, causing useEffect()
            // hook to run...need to prevent double printing by updating processedOrderKeys.
            if (kdsPageMealMenusQuery) {
              client.writeQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
                query: KdsPageMealMenusDocument,
                variables,
                data: {
                  ...kdsPageMealMenusQuery,
                  mealMenus: kdsPageMealMenusQuery.mealMenus.map(mm => ({
                    ...mm,
                    allMealMenuPlateOrders:
                      mm.id === mealMenuId ? [...mm.allMealMenuPlateOrders, ...mealMenuPlateOrders] : mm.allMealMenuPlateOrders,
                  })),
                },
              });
            }
            if (mealMenuPlateOrders[0]) {
              setNewestOrder(mealMenuPlateOrders[0]);
            }

            // TODO: De-duplicate with block in "EditMenuOrderPayload" case below (This is a prev comment not sure what it means)
            const mealMenuPlateOrdersWithItemsFromSelectedDiningStations = mealMenuPlateOrders.filter(
              mealMenuPlateOrder => mealMenuPlateOrder.itemsByDiningStation.length > 0
            );
            if (mealMenuPlateOrdersWithItemsFromSelectedDiningStations.length > 0) {
              mealMenuPlateOrdersWithItemsFromSelectedDiningStations.forEach(mealMenuPlateOrder => {
                autoprint(
                  getXMLForOrderTicket({
                    order: mealMenuPlateOrder,
                    mealMenuDiningStations,
                    selectedDiningStationIds,
                    isAutoprinting: true,
                    locale: dateFnsLocale,
                  })
                );
              });

              // TODO: one bell per bulk order or per plate order?
              playBell();
            }
            break;
          }
          case "EditBulkOrderPayload": {
            console.log("Not Editing Bulk Order on KDS with ID: " + payload.bulkOrder.id);
            // TODO: implement if needed
            break;
          }
          case "RemoveBulkOrderPayload":
            //don't reprocess ticket
            const { affectedMealMenuPlateOrderIds } = payload;
            const currentProcessedOrderKeysWithTicketInfoData = processedOrderKeysWithTicketInfoData.current ?? {
              mealMenuPlateOrders: [],
              menuOrders: [],
            };
            processedOrderKeysWithTicketInfoData.current = {
              ...currentProcessedOrderKeysWithTicketInfoData,
              mealMenuPlateOrders: [
                ...currentProcessedOrderKeysWithTicketInfoData.mealMenuPlateOrders.filter(
                  k => !affectedMealMenuPlateOrderIds.includes(parseFormattedOrderKeyWithTicketHeader(k).orderId)
                ),
              ],
            };
            if (kdsPageMealMenusQuery) {
              client.writeQuery<KdsPageMealMenusQuery, KdsPageMealMenusQueryVariables>({
                query: KdsPageMealMenusDocument,
                variables,
                data: {
                  ...kdsPageMealMenusQuery,
                  mealMenus: kdsPageMealMenusQuery.mealMenus.map(mm => ({
                    ...mm,
                    allMealMenuPlateOrders: mm.allMealMenuPlateOrders.filter(mo => !affectedMealMenuPlateOrderIds.includes(mo.id)),
                  })),
                },
              });
              const removedOrders = kdsPageMealMenusQuery.mealMenus
                .flatMap(mm => mm.allMealMenuPlateOrders)
                .filter(mo => affectedMealMenuPlateOrderIds.includes(mo.id));

              const removedOrdersWithAffectedItems = removedOrders.filter(mo => mo.itemsByDiningStation.length > 0);
              if (removedOrdersWithAffectedItems.length > 0) {
                removedOrdersWithAffectedItems.forEach(removedOrder => {
                  // TODO: styles
                  autoprint(getXMLForCancelledOrderTicket(removedOrder, dateFnsLocale));
                });
                // TODO: one or many bells
                playBell();
                setMessage("success", `Bulk Order Cancelled that included ${removedOrdersWithAffectedItems.length} orders`);
              }
            }
            // We don't need to delete from kdsTicketMenuOrder b/c the kdsPageMealMenus query dicatates
            // what tickets actually get rendered. The ticket will be removed via the above.
            break;
        }
      }
    },
  });

  const [updateMenuOrderItemStatuses] = useUpdateMenuOrderItemStatusesMutation({
    onError: e => setMessage("warning", "Failed to update order. Check internet connection and try again."),
  });

  const [updateMultipleMenuOrderItemStatuses] = useUpdateMultipleMenuOrderItemStatusesMutation({
    onError: e => setMessage("warning", "Failed to update order. Check internet connection and try again."),
  });

  const [updateMultipleMealMenuPlateOrderItemStatuses] = useUpdateMultipleMealMenuPlateItemOrderStatusesMutation({
    onError: e => setMessage("warning", "Failed to update order. Check internet connection and try again."),
  });

  const updateOrderStatus = (order: KdsTicketMenuOrderFragment, status: MenuOrderItemStatus) => {
    updateMenuOrderItemStatuses({
      variables: {
        input: {
          ids: order.itemsByDiningStation.map(i => i.id),
          status,
          menuOrderId: order.id,
        },
      },
      update: () => setMessage("success", `Updated order '#${order.code}' to ${status}.`),
    });
  };

  const updatePlateOrderStatus = (order: KdsPageMealMenuPlateOrderPreviewFragment, status: MenuOrderItemStatus) => {
    const input: UpdateMultipleMealMenuPlateItemOrderStatusesInput = {
      keyTuples: order.itemsByDiningStation.map(i => ({
        mealMenuPlateItemId: i.id.split(":")[0],
        mealMenuPlateOrderId: order.id,
      })),
      status,
    };
    updateMultipleMealMenuPlateOrderItemStatuses({
      variables: {
        input,
      },
      update: () => setMessage("success", `Updated order '#${order.code}' to ${status}.`),
    });
  };

  const updateMultipleOrderOrPlateOrderStatuses = (
    orders: KdsTicketMenuOrderFragment[],
    plateOrders: KdsTicketMealMenuPlateOrderFragment[],
    status: MenuOrderItemStatus
  ) => {
    const numOrders = orders.length + plateOrders.length;
    if (orders.length > 0) {
      updateMultipleMenuOrderItemStatuses({
        variables: {
          input: {
            ids: orders.flatMap(order => order.itemsByDiningStation.map(i => i.id)),
            status,
            menuOrderIds: orders.map(order => order.id),
          },
        },
        update: () => plateOrders.length === 0 && setMessage("success", `Updated ${orders.length} order(s) to ${status}.`),
      });
    }
    if (plateOrders.length > 0) {
      updateMultipleMealMenuPlateOrderItemStatuses({
        variables: {
          input: {
            keyTuples: plateOrders.flatMap(order =>
              order.itemsByDiningStation.map(i => ({ mealMenuPlateItemId: i.id.split(":")[0], mealMenuPlateOrderId: order.id }))
            ),
            status,
          },
        },
        update: () => setMessage("success", `Updated ${numOrders} order(s) to ${status}.`),
      });
    }
  };

  const filterOnNameSearch = <Order extends KdsPageMenuOrderPreviewFragment | KdsPageMealMenuPlateOrderPreviewFragment>(
    menuOrders: Order[]
  ): Order[] => {
    return menuOrders.filter(mo => {
      if (!userSearch) {
        return true;
      }
      const bulkOrderCodeMatch = mo.__typename === "MenuOrder" ? false : mo.parentBulkOrder.code.includes(userSearch);
      const codeMatch = mo.code.includes(userSearch);
      const positionMatch = (mo.athlete?.position?.name || "").includes(userSearch);
      const nameMatch = mo.userFullName.toLowerCase().includes(userSearch);
      return bulkOrderCodeMatch || codeMatch || positionMatch || nameMatch;
    });
  };

  const totalDiningStationIdsForSelectedMealMenus = Array.from(
    new Set(selectedMealMenus.flatMap(smm => smm.mealMenuDiningStations.map(mmds => mmds.id)))
  );

  // tracks changes in dining stations on the menus, notifies with dialog
  useEffect(() => {
    if (diningStationIds.current !== null) {
      const added = hasNewId({
        existingIds: diningStationIds.current,
        incomingIds: totalDiningStationIdsForSelectedMealMenus,
      });
      const removed = hasNewId({
        incomingIds: selectedDiningStationIds, //only tracking if a selected DS was removed
        existingIds: totalDiningStationIdsForSelectedMealMenus,
      });
      if (added && removed) {
        setDiningStationsChangedWarningOpen("both");
      } else if (added) {
        setDiningStationsChangedWarningOpen("added");
      } else if (removed) {
        setDiningStationsChangedWarningOpen("removed");
      }
    }
    diningStationIds.current = totalDiningStationIdsForSelectedMealMenus;
  }, [totalDiningStationIdsForSelectedMealMenus]);

  const autoprint = (doc: string) => {
    if (autoprintEnabled && printer) {
      printer.print(doc);
    }
  };

  const selectedDiningStationFraction = `(${selectedDiningStationIds.length} / ${totalDiningStationIdsForSelectedMealMenus.length})`;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen
      classes={{ root: classes.noprint }}>
      <KdsDialogHeader
        selectedDiningStationFraction={selectedDiningStationFraction}
        onClose={onClose}
        setPrinterSettingsDialogOpen={setPrinterSettingsDialogOpen}
        setUserSearch={setUserSearch}
        selectedMealMenus={selectedMealMenus}
        startPolling={startPolling}
        stopPolling={stopPolling}
      />
      <DialogContent classes={{ root: classes.fullHeight }}>
        <div className={classes.content}>
          {data && !loading ? (
            <KdsDialogContent
              makeHandleAirPrintFn={makeInjectHtmlAndHandlePrint}
              handleWebPrintOrder={printer ? makeHandlePrintOrderViaUsb(printer) : undefined}
              printMethod={printer ? "WebPrint" : "AirPrint"}
              diningStationIds={selectedDiningStationIds}
              mealMenuIds={selectedMealMenus.map(smm => smm.id)}
              updateOrderStatus={updateOrderStatus}
              updatePlateOrderStatus={updatePlateOrderStatus}
              unfilteredMealMenuPlateOrders={data.mealMenus.flatMap(mm => mm.allMealMenuPlateOrders)}
              mealMenuPlateOrders={filterOnNameSearch(data.mealMenus.flatMap(mm => mm.allMealMenuPlateOrders))}
              updateMultipleOrderOrPlateOrderStatuses={updateMultipleOrderOrPlateOrderStatuses}
              menuOrders={filterOnNameSearch(data.mealMenus.flatMap(mm => mm.allOrders))}
              unfilteredMenuOrders={data.mealMenus.flatMap(mm => mm.allOrders)}
              menuOrderItemIdsToDiningStationName={menuOrderItemIdsToDiningStationName(
                mealMenuDiningStations.filter(mmds => selectedDiningStationIds.includes(mmds.id)),
                [...data.mealMenus.flatMap(mm => mm.allOrders), ...data.mealMenus.flatMap(mm => mm.allMealMenuPlateOrders)]
              )}
            />
          ) : (
            <Loading progressSize="lg" />
          )}
        </div>
      </DialogContent>

      {newestOrder && !printer && data && (
        // TODO: how does handlePrintOrder work with MealMenuPlateOrders?
        <NewOrderSnackbar
          handlePrintOrder={makeInjectHtmlAndHandlePrint((menuOrderId?: string) => `printable-order-${menuOrderId}`)}
          newestOrder={newestOrder}
          setNewestOrder={setNewestOrder}
        />
      )}

      {diningStationsChangedWarningOpen && (
        <NewDiningStationDialog
          changeType={diningStationsChangedWarningOpen}
          onClose={() => setDiningStationsChangedWarningOpen(null)}
          goToDiningStations={onClose}
        />
      )}
      {printerSettingsDialogOpen && (
        <KdsPagePrinterSettingsDialog
          printer={printer}
          setAutoprintEnabled={setAutoprintEnabled}
          setPrinterName={setPrinterName}
          autoprintEnabled={autoprintEnabled}
          printerType={printerType}
          onClose={onClosePrinterSettings}
        />
      )}
    </Dialog>
  );
};

export default KdsPageDialog;

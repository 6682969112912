import CloseIcon from "@mui/icons-material/Close";

import { Box, IconButton, useTheme } from "@mui/material";
import WarningIcon from "apps/web/src/components/universal/WarningIcon";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { useMenuBuilderContext } from "../MenuBuilderProvider";
import { MenuBuilderMealRowItemIndexedType } from "./MenuBuilderMealSchema";

interface Props {
  rowItem: MenuBuilderMealRowItemIndexedType;
  removeRowItem: (index: number) => void;
  setEditMenuItemId: (id: string) => void;
}

export const MenuBuilderRowItem = ({ rowItem, removeRowItem, setEditMenuItemId }: Props) => {
  const {
    palette: { background, greyscale },
  } = useTheme();
  const { isEditable } = useMenuBuilderContext();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: rowItem.menuItem.id });

  return (
    <Box
      key={rowItem.position}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #D2D9DA",
        borderRadius: "6px",
        p: 1,
        position: "relative",
        width: "200px",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: greyscale[100],
        },
        backgroundColor: background.default,
        // drag and drop
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      onClick={() => setEditMenuItemId(rowItem.menuItem.id)}
    >
      <Box
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        sx={{
          fontSize: "14px",
          lineHeight: "18px",
          WebkitLineClamp: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          width: "160px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {rowItem.menuItem.isMissingIngredients && <WarningIcon sx={{ fontSize: "16px" }} tip="Missing Ingredients" />}
          <Box sx={{ pl: 0.5, userSelect: "none" }}>{rowItem.menuItem.name}</Box>
        </Box>
      </Box>
      {isEditable && (
        <IconButton
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
          }}
          onClick={e => {
            e.stopPropagation();
            removeRowItem(rowItem.index);
          }}
        >
          <CloseIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      )}
    </Box>
  );
};

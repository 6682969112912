import { Box, Tooltip, TooltipProps, Typography } from "@mui/material";
import React from "react";

interface TWItemizedTooltipProps extends TooltipProps {
  items: string[];
}

const TWItemizedTooltip = ({ title, items, children, ...tooltipProps }: TWItemizedTooltipProps) => {
  if (items.length === 0) {
    return <>{children}</>;
  }

  const tooltipTitle = (
    <Box sx={{ p: 1, display: "flex", flexDirection: "column", gap: 0.5 }}>
      <Typography sx={{ color: "highEmphasisTextDark" }} variant="subtitle1Semibold">
        {title}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.25 }}>
        {items.map(item => (
          <Typography
            sx={{ color: "mediumEmphasisTextDark" }}
            key={item}
            variant="subtitle1">
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  );
  return (
    // wrap children in span to allow tooltip to show when button is disabled
    <Tooltip {...tooltipProps} title={tooltipTitle}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default TWItemizedTooltip;

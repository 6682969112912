import { ChangeExchangeTargetMutationFn, FullExchangeFragment, Maybe } from "../../types";

export const filterServingIds = (
  exchange: Pick<FullExchangeFragment, "exchangeServingList" | "id"> | undefined,
  isNegativeFood: (foodId: string) => boolean
): string[] => {
  if (!exchange) {
    return [];
  }
  return exchange.exchangeServingList.servingAmounts.filter(sa => !isNegativeFood(sa.serving.foodOrRecipe.id)).map(sa => sa.serving.id);
};

interface DeselectNegativeFoodsProps {
  isNegativeFood: (foodId: string) => boolean;
  exchangeSet: {
    exchanges: Array<Pick<FullExchangeFragment, "exchangeServingList" | "id">>;
  };
  mealTemplates: Array<{
    exchangeTargets: Maybe<
      Array<{
        exchange: {
          id: string;
        };
        amountInput: string;
      }>
    >;
    id: string;
  }>;
  changeExchangeTarget: ChangeExchangeTargetMutationFn;
}
// mealTemplates can be an ExchangeMealTemplateFragment
export const deselectNegativeFoods = ({
  isNegativeFood,
  exchangeSet,
  mealTemplates,
  changeExchangeTarget,
}: DeselectNegativeFoodsProps): void => {
  mealTemplates.map(mt => {
    (mt.exchangeTargets || []).map(et => {
      const {
        exchange: { id: exchangeId },
      } = et;
      changeExchangeTarget({
        variables: {
          exchangeId,
          mealTemplateId: mt.id,
          pickListServingIds: filterServingIds(
            exchangeSet.exchanges.find(ex => ex.id === exchangeId),
            isNegativeFood
          ),
          target: String(et.amountInput),
        },
      });
    });
  });
};

import { useDroppable } from "@dnd-kit/core";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    hourCell: {
      borderBottom: "thin solid lightgray",
      borderRight: "thin solid lightgray",
      boxSizing: "border-box",
      marginRight: spacing(-2),
      display: "flex",
      flexDirection: "column",
    },
    disabled: {
      backgroundColor: grey[100],
    },
    quarterHourCell: {
      flexGrow: 1,
    },
  })
);

const QUARTER_HOUR_MINUTES = ["00", "15", "30", "45"];

interface CalendarHourCellProps {
  hour: string;
  height: number;
  onClick: () => void;
  disableDrag: boolean;
  isDisabled?: boolean;
}

const CalendarHourCell = ({ height, hour, onClick, disableDrag, isDisabled }: CalendarHourCellProps) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.hourCell, {
        [classes.disabled]: isDisabled,
      })}
      style={{ minHeight: height }}
      onClick={onClick}
    >
      {QUARTER_HOUR_MINUTES.map(mins => (
        <CalendarQuarterHourCell
          key={mins}
          time={`${hour}:${mins}`}
          disableDrag={isDisabled ? true : disableDrag} />
      ))}
    </div>
  );
};

export default CalendarHourCell;

interface CalendarQuarterHourCellProps {
  time: string;
  disableDrag: boolean;
}

const CalendarQuarterHourCell = ({ time, disableDrag }: CalendarQuarterHourCellProps) => {
  const classes = useStyles();

  const { setNodeRef } = useDroppable({
    id: time,
    disabled: disableDrag,
  });

  return <div ref={setNodeRef} className={classes.quarterHourCell} />;
};

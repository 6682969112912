import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { IMatchResult, MatchType } from "../../../../utils/import/match";

interface MatchedRowsDialogProps<T, A> {
  open: boolean;
  onClose: () => void;
  onChange?: (row: T, type: MatchType, newRow: T) => void;
  match: IMatchResult<T, A>;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  footer: {
    fontSize: 12,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconButton: { marginTop: theme.spacing(1.5) },
  spacer: { width: theme.spacing(2) },
  padding: { height: theme.spacing(1.5) },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "flex-start",
  },
  divider: { height: "100%" },
  wide: { width: "auto" },
  editDialog: { width: "400px" },
}));

const ViewImportRowDialog = <T extends object, A extends object>({ open, onClose, onChange, match }: MatchedRowsDialogProps<T, A>) => {
  const classes = useStyles();
  const [showEdit, setShowEdit] = useState(false);
  const [edittedRow, setEdittedRow] = useState<T>(match.row);

  const keyToDisplayName = (camelCaseKey: string): string => {
    //"(\\B[A-Z])" finds capital letter, and replace adds a space immediately before
    const keyWithSpaceDelimiter = camelCaseKey.replace(new RegExp(/([A-Z])/g), " $1");
    const capitalizedKey = keyWithSpaceDelimiter.charAt(0).toUpperCase() + keyWithSpaceDelimiter.slice(1);

    return capitalizedKey;
  };

  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <DialogContent>
          <div className={classes.content}>
            {match.matchedRows.length > 0 && (
              <div>
                <Typography variant="subtitle1Semibold">Existing Entity</Typography>
                <div className={classes.padding}></div>
                {Object.keys(match.matchedRows[match.matchedRows.length - 1]).map(key => {
                  if (key === "id") {
                    return null;
                  } else {
                    return (
                      <div className={classes.flex}>
                        <Typography>{`${keyToDisplayName(key)}: `}</Typography>
                        <Typography>{`${match.matchedRows[match.matchedRows.length - 1][key as keyof T]}`}</Typography>
                      </div>
                    );
                  }
                })}
              </div>
            )}
            <Divider className={classes.divider} orientation="vertical" />
            <div className={classes.spacer} />
            <div>
              <div className={classes.titleRow}>
                <Typography variant="subtitle1Semibold">New Entity</Typography>
                <Tooltip title="Renaming an entity will unmatch it from an existing entity, if applicable, creating a new record.">
                  <IconButton onClick={() => setShowEdit(!showEdit)}>
                    <EditIcon fontSize={"small"}></EditIcon>
                  </IconButton>
                </Tooltip>
              </div>
              {Object.keys(match.row).map(keyString => {
                const key = keyString as keyof T;
                return (
                  <div className={classes.flex}>
                    <Typography>{`${keyToDisplayName(keyString)}: `}</Typography>
                    <Typography>{`${edittedRow[key] ?? "-"}`}</Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showEdit}
        onClose={() => setShowEdit(false)}
        maxWidth={"sm"}>
        <DialogTitle className={classes.editDialog}>Edit Match Fields</DialogTitle>
        <DialogContent>
          {match.matchFields.map(field => {
            return (
              <div>
                <Typography>{keyToDisplayName(field as string)}:</Typography>
                <TextField
                  className={classes.editDialog}
                  type="string"
                  value={edittedRow[field]}
                  onChange={e =>
                    setEdittedRow({
                      ...match.row,
                      [field]: e.target.value,
                    })
                  }
                ></TextField>
              </div>
            );
          })}
        </DialogContent>
        {onChange && (
          <DialogActions>
            <Button
              onClick={() => {
                setShowEdit(!showEdit);
                onChange(match.row, match.type, edittedRow);
              }}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ViewImportRowDialog;

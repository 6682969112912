import React from "react";
import DefaultSVGLogo, { OptionalDefaultLogoProps } from "./DefaultSVGLogo";

const TeamworksLogoBlack = ({ alt, ...logoProps }: OptionalDefaultLogoProps) => (
  <DefaultSVGLogo
    src="/img/teamworks_logo_black.svg"
    alt={alt ?? "Teamworks black logo"}
    {...logoProps} />
);

export default TeamworksLogoBlack;

import { Box, TextField } from "@mui/material";
import React from "react";

interface MealPlanTemplateNameAndDescriptionProps {
  onNameChange: (name: string) => void;
  onDescriptionChange: (description: string | null) => void;
  name: string;
  description: string | null;
}

const MealPlanTemplateNameAndDescription = ({
  onNameChange,
  onDescriptionChange,
  name,
  description,
}: MealPlanTemplateNameAndDescriptionProps) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flexGrow: 1 }}>
    <TextField
      autoFocus
      label="Name"
      inputProps={{ maxLength: 50 }}
      value={name}
      onChange={e => onNameChange(e.target.value)} />
    <TextField
      sx={{ flexGrow: 1 }}
      label="Description"
      inputProps={{ maxLength: 2000 }}
      value={description ?? ""}
      multiline
      rows={15}
      onChange={e => onDescriptionChange(e.target.value || null)}
    />
  </Box>
);

export default MealPlanTemplateNameAndDescription;

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import HelpTipIcon from "../../../../components/universal/HelpTipIcon";
import { MenuOrderRateLimitFragment, TeamMealMenuPreviewFragment } from "../../../../types";
import TeamMultiSelect from "../../SelectComponents/TeamMultiSelect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      marginTop: theme.spacing(3),
      display: "flex",
      alignItems: "flex-end",
    },
    marginLeft: {
      marginLeft: theme.spacing(),
      marginBottom: theme.spacing(),
    },
    limitInput: {
      width: 50,
    },
    marginRight: {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  })
);

interface MenuOrderRateLimitDialogProps {
  open: boolean;
  onClose: () => void;
  orderRateLimit: MenuOrderRateLimitFragment | null;
  onChangeOrderRateLimit: (value: MenuOrderRateLimitFragment | null) => void;
  teams: readonly TeamMealMenuPreviewFragment[];
}

const MenuOrderRateLimitDialog = ({
  open,
  onClose,
  orderRateLimit: initOrderRateLimit,
  onChangeOrderRateLimit,
  teams,
}: MenuOrderRateLimitDialogProps) => {
  const classes = useStyles();
  const [noRateLimit, setNoRateLimit] = useState(initOrderRateLimit === null);
  const [limitInput, setLimitInput] = useState(initOrderRateLimit ? String(initOrderRateLimit.limit) : "");
  const [excludedTeamIds, setExcludedTeamIds] = useState(initOrderRateLimit?.excludedTeamIds || []);

  const limit = inputToNumber(limitInput);
  const limitInputError = !noRateLimit && (limit === null || limit <= 0);
  const tip = limitInputError ? "Enter valid rate limit value" : "";

  const handleDone = () => {
    if (noRateLimit) {
      onChangeOrderRateLimit(null);
      onClose();
    } else if (limit !== null && limit > 0) {
      onChangeOrderRateLimit({
        limit,
        excludedTeamIds,
      });
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle title="Edit Order Rate Limit" onClose={onClose} />
      <DialogContent>
        <FormControlLabel
          control={<Checkbox checked={noRateLimit} onChange={(_, checked) => setNoRateLimit(checked)} />}
          label="No Rate Limit"
        />
        <div className={classes.row}>
          <Typography className={classes.marginRight} color={noRateLimit ? "textSecondary" : "textPrimary"}>
            Max of
          </Typography>
          <TextField
            margin="dense"
            value={limitInput}
            disabled={noRateLimit}
            autoFocus={!noRateLimit && limitInput === ""}
            onChange={e => setLimitInput(e.target.value)}
            className={classes.limitInput}
            error={limitInputError}
          />
          <Typography className={classes.marginLeft} color={noRateLimit ? "textSecondary" : "textPrimary"}>
            orders per 15 minutes
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography color={noRateLimit ? "textSecondary" : "textPrimary"}>Excluded Teams</Typography>
          {!noRateLimit && <HelpTipIcon tip="Selected teams will not be subject to order rate limits" />}
        </div>
        <TeamMultiSelect
          selectedTeams={teams.filter(t => excludedTeamIds.includes(t.id))}
          onChange={teams => setExcludedTeamIds(teams.map(t => t.id))}
          displayedTeamsCount={1}
          allTeams={teams}
          fullWidth
          disabled={noRateLimit}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={tip}>
          <Button onClick={handleDone}>Done</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default MenuOrderRateLimitDialog;

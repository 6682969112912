import React from "react";
import BaseDialogTitle from "./BaseDialogTitle";

interface DialogTitleProps {
  title: string;
  onClose: () => void;
}

const DialogTitle = ({ title, onClose }: DialogTitleProps) => <BaseDialogTitle content={title} onClose={onClose} />;

export default DialogTitle;

import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React from "react";

interface ScoreOverrideDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const ScoreOverrideDialog = ({ open, onConfirm, onClose }: ScoreOverrideDialogProps) => {
  return (
    <ConfirmationDialog
      title="Score Recommendation"
      message="The recipe you added has a score. Would you like to override your menu item score with the recipe score?"
      confirmLabel="Yes"
      open={open}
      onCancel={onClose}
      onConfirm={onConfirm}
    />
  );
};

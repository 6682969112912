import React, { Dispatch } from "react";
import { TeamworksPositionLinkFragment, TeamworksPositionFragment } from "../../../../../types";
import PositionModalContent from "../Positions/PositionModalContent";
import { staticEmptyPositions, useAutoLinkPositions, useMergePositions } from "./hooks";
import { IndividualSportState, SportBuilderAction } from "./utils";

interface PositionsDataLoaderProps {
  indySportState: IndividualSportState;
  dispatch: Dispatch<SportBuilderAction>;
  unfilteredTeamorksPositions: readonly TeamworksPositionFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
}

const PositionsDataLoader = ({
  indySportState,
  dispatch,
  unfilteredTeamorksPositions,
  teamworksPositionLinks,
}: PositionsDataLoaderProps) => {
  // Filter out used positions, place them into the proper columns, and sort by name
  const mergedPositions = useMergePositions(indySportState, unfilteredTeamorksPositions, teamworksPositionLinks);

  const { teamworksPositions, notemealPositions, pendingPositions, previouslyLinkedPositions } = mergedPositions;

  const unfilteredNotemealPositions = indySportState.__type === "Link" ? indySportState.selectedSport.positions : staticEmptyPositions;

  // Once positions are loaded/the sport changes autolink positions
  useAutoLinkPositions({
    dispatch,
    unfilteredTeamorksPositions,
    unfilteredNotemealPositions,
  });

  return (
    <>
      {
        <PositionModalContent
          teamworksPositions={teamworksPositions}
          notemealPositions={notemealPositions}
          pendingPositions={pendingPositions}
          previouslyLinkedPositions={previouslyLinkedPositions}
          dispatch={dispatch}
        />
      }
    </>
  );
};

export default PositionsDataLoader;

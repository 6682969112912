import { UserOrgMembership, fetchUserOrgMemberships } from "@notemeal/shared/ui/utils/tokens";
import { useEffect, useState } from "react";
import client from "../../../client";
import { useCookieContext } from "../../../contexts/Cookie";
import { refresh, REFRESH_TOKEN_PAYLOAD_AND_HEADER_COOKIE } from "../../../utils/tokens";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useMultiTabManager } from "apps/web/src/contexts/MultiTabManager";

export const useMemberships = () => {
  const { getCookieValue } = useCookieContext();
  const refreshToken = getCookieValue(REFRESH_TOKEN_PAYLOAD_AND_HEADER_COOKIE);
  const [memberships, setMemberships] = useState<UserOrgMembership[] | null>(null);

  useEffect(() => {
    if (!refreshToken) {
      setMemberships(null);
    } else {
      const membershipsPromise = fetchUserOrgMemberships({});
      membershipsPromise.then(res => {
        if (res.status !== 201) {
          setMemberships(null);
        } else {
          setMemberships(res.data.memberships);
        }
      });
    }
  }, [refreshToken]);

  return memberships;
};

export const useSwitchOrgs = (onSuccess?: () => void, onDone?: () => void) => {
  const { setMessage } = useSnackbar();
  const { emitMultiTabEvent } = useMultiTabManager();

  const switchOrgs = async (orgId: string) => {
    try {
      client.stop();
      await refresh(orgId);
      await client.clearStore();
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      setMessage("error", "An unknown error occurred. Check your network connection or try again in a few minutes.");
      console.error(e);
    }
    if (onDone) {
      onDone();
    }
    emitMultiTabEvent("switched-orgs");
  };

  return switchOrgs;
};

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulletedRounded";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { AgreementFormsPage } from "./AgreementFormsPage";
import { ScoringSystemsPage } from "./ScoringSystemsPage";

// full page path
export const NAV_STAFF_FORMS_AND_SCORES = "/staff/forms-and-scores";

// local child paths
export const NAV_FORMS_AND_SCORES_AGREEMENT_FORMS = "agreement-forms";
export const NAV_FORMS_AND_SCORES_SCORING_SYSTEMS = "scoring-systems";

// full child paths
export const NAV_STAFF_FORMS_AND_SCORES_AGREEMENT_FORMS = `${NAV_STAFF_FORMS_AND_SCORES}/${NAV_FORMS_AND_SCORES_AGREEMENT_FORMS}`;

export const formsAndScoresLink = {
  to: NAV_STAFF_FORMS_AND_SCORES,
  name: "Forms & Scores",
  icon: <FormatListBulletedIcon />,
};

const secondaryNavLayoutProps: SecondaryNavLayoutProps = {
  current: formsAndScoresLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Agreement Forms",
      to: NAV_FORMS_AND_SCORES_AGREEMENT_FORMS,
    },
    {
      name: "Scoring Systems",
      to: NAV_FORMS_AND_SCORES_SCORING_SYSTEMS,
    },
  ],
};

export const FormsAndScoresRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route index element={<AgreementFormsPage />} />
      <Route path={NAV_FORMS_AND_SCORES_AGREEMENT_FORMS} element={<AgreementFormsPage />} />
      <Route path={NAV_FORMS_AND_SCORES_SCORING_SYSTEMS} element={<ScoringSystemsPage />} />
    </Route>
  </Routes>
);

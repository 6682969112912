import { addYears, differenceInYears, parseISO } from "date-fns";
import { AthleteName } from "./types/AthleteTypes";

export const athleteBirthDateToAge = (athleteBirthdate: string): number => {
  const today = new Date();
  const birthdate = parseISO(athleteBirthdate);
  return differenceInYears(today, birthdate);
};

export const maybeAthleteBirthDateToAge = (athleteBirthdate: string | null): number | null => {
  if (athleteBirthdate === null) return null;
  return athleteBirthDateToAge(athleteBirthdate);
};

export const athleteAgeToApproxBirthDate = (age: number): Date => {
  const today = new Date();
  return addYears(today, -age);
};

export const getAthleteFullName = ({ firstName, lastName }: AthleteName) => `${firstName} ${lastName}`;
export const getAthleteListName = ({ firstName, lastName }: AthleteName) => `${lastName}, ${firstName}`;

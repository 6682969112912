import React from "react";
import { useReducer } from "react";

import { scheduleModalEditorReducer, ScheduleModalEditorState } from "../../../reducers/ScheduleModalEditor";
import { ScheduleModalEditorContext, dispatchToCallbacks } from "./utils";

interface ScheduleModalContextProviderProps {
  children?: React.ReactNode;
  initialState: ScheduleModalEditorState;
}

const ScheduleModalContextProvider = ({ children, initialState }: ScheduleModalContextProviderProps) => {
  const [state, dispatch] = useReducer(scheduleModalEditorReducer, initialState);
  const callbacks = dispatchToCallbacks(dispatch);

  return <ScheduleModalEditorContext.Provider value={{ state, ...callbacks }}>{children}</ScheduleModalEditorContext.Provider>;
};

export default ScheduleModalContextProvider;

import { ListItem, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { PickupTime } from "@notemeal/shared/ui/MenuOrder/usePickupTimeState";
import { MenuOrderItemWithAppearance } from "@notemeal/shared/ui/MenuOrder/utils";
import MenuOrderFormForLog from "./ForLog";
import MenuOrderFormForOrder from "./ForOrder";

import { MenuOrderItemStatus } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalSpacer: {
      height: 12,
    },
  })
);

interface MenuOrderFormProps {
  className?: string;
  menuOrderItems: readonly MenuOrderItemWithAppearance[];
  selectedItemId: string | null;
  onAddItem?: (forOrder: boolean) => void;
  onSelectItem: (item: MenuOrderItemWithAppearance) => void;
  onRemoveItem: (id: string) => void;
  canOrderItems: boolean;
  canLogItems: boolean;

  pickupTime: PickupTime | null;
  orderPickupTimes: PickupTime[];
  logPickupTimes: PickupTime[];
  onChangePickupTime: (pickupTime: PickupTime) => void;

  mealMenuTimezone: string;
  menuOrderingClosed: boolean;
  orderStatus?: MenuOrderItemStatus;
  orderCode?: string;

  openTimePicker?: boolean;

  disableOrderItems?: boolean;
  forceOrderTitle?: boolean;
}

const MenuOrderForm = ({
  className,
  canOrderItems,
  canLogItems,
  onRemoveItem,
  onSelectItem,
  selectedItemId,
  onAddItem,
  menuOrderItems,
  menuOrderingClosed,
  orderCode,
  orderStatus,
  onChangePickupTime,
  pickupTime,
  orderPickupTimes,
  logPickupTimes,
  mealMenuTimezone,
  openTimePicker,
  disableOrderItems,
  forceOrderTitle,
}: MenuOrderFormProps) => {
  const classes = useStyles();

  const orderItems = menuOrderItems.filter(moi => moi.forOrder);
  const logItems = menuOrderItems.filter(moi => !moi.forOrder);

  // TODO: What to do if zero items? Should this component even be render-ed in that case?
  return (
    <div className={className}>
      {(forceOrderTitle || orderItems.length > 0) && (
        <>
          <MenuOrderFormForOrder
            menuOrderItems={orderItems}
            onSelectItem={onSelectItem}
            selectedItemId={selectedItemId}
            onRemoveItem={onRemoveItem}
            menuOrderingClosed={menuOrderingClosed}
            orderCode={orderCode}
            orderStatus={orderStatus}
            onChangePickupTime={onChangePickupTime}
            pickupTime={pickupTime}
            orderPickupTimes={orderPickupTimes}
            mealMenuTimezone={mealMenuTimezone}
            openTimePicker={openTimePicker}
            disabled={disableOrderItems || orderItems.length === 0}
          />
          {canOrderItems && onAddItem && (
            <ListItem button onClick={() => onAddItem(true)}>
              <ListItemText primary="Add more items to order" primaryTypographyProps={{ color: "info" }} />
            </ListItem>
          )}
          <div className={classes.verticalSpacer} />
        </>
      )}
      {logItems.length > 0 && (
        <>
          <MenuOrderFormForLog
            menuOrderItems={logItems}
            onSelectItem={onSelectItem}
            selectedItemId={selectedItemId}
            onRemoveItem={onRemoveItem}
            showTimePicker={orderItems.length === 0}
            onChangePickupTime={onChangePickupTime}
            pickupTime={pickupTime}
            logPickupTimes={logPickupTimes}
            mealMenuTimezone={mealMenuTimezone}
            openTimePicker={openTimePicker}
          />
          {canLogItems && onAddItem && (
            <ListItem button onClick={() => onAddItem(false)}>
              <ListItemText primary="Add more items to log" primaryTypographyProps={{ color: "info" }} />
            </ListItem>
          )}
        </>
      )}
    </div>
  );
};

export default MenuOrderForm;

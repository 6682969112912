import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { TWTabGroup } from "./TWTabGroup";

export const TWTabGroupExample = () => {
  const TAB1 = "tab1";
  const TAB2 = "tab2";
  const TAB3 = "tab3";
  const [selected, setSelected] = useState(TAB1);
  const tabs = [TAB1, TAB2, TAB3];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TWTabGroup
        tabs={tabs}
        onSelected={selected => {
          setSelected(selected);
        }}
      />
      <Typography>{`Selected tab: ${selected}`}</Typography>
    </Box>
  );
};

import React from "react";

import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import { MenuItemFormFragment, useMenuItemDialogQuery } from "../../../../types";
import { MenuItemEditDialogContent } from "./MenuItemEditContent";
import { EditMenuItemState } from "./reducer";

export interface OnSaveMenuItemArgs {
  initial: MenuItemFormFragment;
  final: EditMenuItemState;
}

interface MenuItemEditDialogProps {
  open: boolean;
  onClose: () => void;
  onDuplicate: () => void;
  onSave: ({ initial, final }: OnSaveMenuItemArgs) => void;
  saving: boolean;
  menuItemId: string;
}

const MenuItemEditDialog = ({ open, onClose, onSave, onDuplicate, saving, menuItemId }: MenuItemEditDialogProps) => {
  const { data } = useMenuItemDialogQuery({
    variables: { menuItemId },
    //if a menu item has been loaded, then changed in another tab or by another user,
    // we want to make sure we get the latest version next time we open the dialog
    fetchPolicy: "no-cache",
  });

  return (
    <>
      <LoadingBackdrop open={!data || saving} onClose={onClose} />
      {data && (
        <MenuItemEditDialogContent
          open={open}
          onClose={onClose}
          onSave={final => onSave({ initial: data.currentMenuItem, final })}
          menuItem={data.currentMenuItem}
          onDuplicate={onDuplicate}
        />
      )}
    </>
  );
};

export default MenuItemEditDialog;

import LightbulbIcon from "@mui/icons-material/WbIncandescentOutlined";
import { Chip, ChipProps } from "@mui/material";
import React from "react";
import { NamedTagForSelectionFragment } from "../../types";

interface NamedTagChipProps {
  namedTag: NamedTagForSelectionFragment;
  size?: ChipProps["size"];
  className?: string;
  onDelete?: (namedTag: NamedTagForSelectionFragment) => void;
}

const NamedTagChip = ({ namedTag, size, className, onDelete }: NamedTagChipProps) => {
  const Icon = namedTag.tag.__typename === "AthleteTag" ? undefined : <LightbulbIcon sx={{ transform: "rotate(180deg)" }} />;
  return (
    <Chip
      sx={{ bgcolor: namedTag.tagName.color }}
      icon={Icon}
      label={namedTag.tagName.name}
      className={className}
      size={size}
      onDelete={onDelete ? () => onDelete(namedTag) : undefined}
    />
  );
};

export default NamedTagChip;

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Modal, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByKey } from "@notemeal/utils/sort";
import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { ImageFragment } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: theme.spacing(4),
    },
    mainDiv: {
      minHeight: 0,
      flexShrink: 1,
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      maxHeight: "80vh",
    },
    navigateDiv: {
      margin: theme.spacing(),
      color: "lightgrey",
    },
    notHidden: {
      cursor: "pointer",
    },
    hidden: { opacity: 0 },
    dotsDiv: {
      marginTop: theme.spacing(),
      display: "flex",
    },
    dot: {
      margin: theme.spacing(0.5),
      height: theme.spacing(),
      width: theme.spacing(),
      borderRadius: theme.spacing(),
      backgroundColor: "lightgrey",
    },
    selectedDot: {
      margin: theme.spacing(0.5),
      height: theme.spacing(),
      width: theme.spacing(),
      borderRadius: theme.spacing(),
      backgroundColor: theme.palette.info.main,
    },
  })
);

interface FoodLogImageModalProps {
  onClose: () => void;
  open: boolean;
  images: readonly ImageFragment[];
}

const FoodLogImageModal = ({ onClose, open, images }: FoodLogImageModalProps) => {
  const classes = useStyles();
  const sortedImages = sortByKey(images, "position");
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentImage = sortedImages[currentIndex];

  const decrementIndex = useCallback(() => {
    setCurrentIndex(Math.max(0, currentIndex - 1));
  }, [setCurrentIndex, currentIndex]);

  const incrementIndex = useCallback(() => {
    setCurrentIndex(Math.min(images.length - 1, currentIndex + 1));
  }, [setCurrentIndex, currentIndex, images.length]);

  useEffect(() => {
    const keydownHandler = (e?: { key?: string }) => {
      if (e?.key === "ArrowLeft") {
        decrementIndex();
      } else if (e?.key === "ArrowRight") {
        incrementIndex();
      }
    };
    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [decrementIndex, incrementIndex]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}>
      {currentImage && (
        <>
          <div className={classes.mainDiv} onClick={onClose}>
            <div
              onClick={e => {
                e.stopPropagation();
                decrementIndex();
              }}
              className={classnames(classes.navigateDiv, currentIndex === 0 ? classes.hidden : classes.notHidden)}
            >
              <NavigateBeforeIcon color="inherit" fontSize="large" />
            </div>
            <img
              onClick={e => e.stopPropagation()}
              src={currentImage.url}
              alt=""
              className={classes.image} />
            <div
              onClick={e => {
                e.stopPropagation();
                incrementIndex();
              }}
              className={classnames(classes.navigateDiv, currentIndex === images.length - 1 ? classes.hidden : classes.notHidden)}
            >
              <NavigateNextIcon color="inherit" fontSize="large" />
            </div>
          </div>
          {images.length > 1 && (
            <div className={classes.dotsDiv}>
              {images.map((_, i) => (
                <div className={i === currentIndex ? classes.selectedDot : classes.dot} />
              ))}
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default FoodLogImageModal;

import { MenuItem, SxProps, TextField, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import React from "react";
import { MealType } from "../../../types";

const MEAL_TYPES: MealType[] = ["breakfast", "lunch", "dinner", "snack"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warning: {
      "& fieldset": {
        borderColor: "orange",
        color: "orange",
      },
    },
  })
);

interface MenusMealTypeSelectProps {
  value: MealType | null;
  onChange: (mt: MealType) => void;
  menuName: string;
  className?: string;
  sx?: SxProps;
  multiple?: boolean;
}

const MenusMealTypeSelect = ({ value, onChange, className, sx, menuName }: MenusMealTypeSelectProps) => {
  const classes = useStyles();

  const otherMealTypes = MEAL_TYPES.filter(mt => mt !== value);
  const otherMatchingMealType = otherMealTypes.find(mt => menuName.toLowerCase().includes(mt.toLowerCase()));
  const tooltipTitle = !otherMatchingMealType ? "" : `Did you mean to select "${capitalize(otherMatchingMealType)}"?`;

  return (
    <Tooltip placement="right" title={tooltipTitle}>
      <TextField
        select
        label="Type"
        value={value || ""}
        className={className}
        onChange={e => onChange(e.target.value as MealType)}
        sx={sx}
        classes={
          Boolean(otherMatchingMealType)
            ? {
                root: classes.warning,
              }
            : {}
        }
      >
        <MenuItem value={"breakfast"}>Breakfast</MenuItem>
        <MenuItem value={"lunch"}>Lunch</MenuItem>
        <MenuItem value={"dinner"}>Dinner</MenuItem>
        <MenuItem value={"snack"}>Snack</MenuItem>
      </TextField>
    </Tooltip>
  );
};

export default MenusMealTypeSelect;

interface MenusMealTypeMultiSelectProps {
  value: MealType[] | null;
  onChange: (mt: MealType[]) => void;
  sx?: SxProps;
}

export const MenusMealTypeMultiSelect = ({ value, onChange, sx }: MenusMealTypeMultiSelectProps) => {
  return (
    <TextField
      sx={sx}
      select
      multiline
      value={value}
      onChange={e => {
        onChange(e.target.value as unknown as MealType[]);
      }}
      SelectProps={{
        multiple: true,
      }}
      label="Meal Type"
      inputProps={{
        "aria-label": "Meal Type",
      }}
    >
      <MenuItem value={"breakfast"}>Breakfast</MenuItem>
      <MenuItem value={"lunch"}>Lunch</MenuItem>
      <MenuItem value={"dinner"}>Dinner</MenuItem>
      <MenuItem value={"snack"}>Snack</MenuItem>
    </TextField>
  );
};

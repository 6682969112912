import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { OrgsDocument, OrgsQuery, useCreateOrgMutation } from "../../../../types";
import { OrgForm } from "../Form/Create";
import { createOrgFormReducer, createOrgFormToSaveTooltips, initialCreateOrgFormState } from "../Form/utils";

interface OrgModalProps {
  open: boolean;
  onClose: () => void;
}

export const CreateOrgDialog = ({ open, onClose }: OrgModalProps) => {
  const { setMessage } = useSnackbar();
  const [state, dispatch] = useReducer(createOrgFormReducer, initialCreateOrgFormState());
  const canSaveTooltips = createOrgFormToSaveTooltips(state);

  const [createOrg] = useCreateOrgMutation({
    update: (dataProxy, result) => {
      const orgsQuery = dataProxy.readQuery<OrgsQuery>({
        query: OrgsDocument,
      });
      if (result.data && orgsQuery) {
        dataProxy.writeQuery({
          query: OrgsDocument,
          data: {
            ...orgsQuery,
            orgs: [...orgsQuery.orgs, result.data.createOrg.org],
          },
        });
      }
    },
    onError: e => setMessage("error", e.message),
  });

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    const { allowsPublicAccess, isActive, name, localeCode, territoryName } = state;
    createOrg({
      variables: {
        input: {
          isActive,
          name,
          allowsPublicAccess,
          memberships: [],
          localeCode,
          territoryName,
        },
      },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Create Org" onClose={onClose} />
      <DialogContent>
        <OrgForm state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

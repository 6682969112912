import { Card, CardActionArea, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { MealMenuTimelineFragment } from "../../../../types";

interface TimelineMealMenuCardProps {
  mealMenu: MealMenuTimelineFragment;
  onClickComment: () => void;
  onClick: () => void;
}

const TimelineMealMenuCard = ({ mealMenu, onClick, onClickComment }: TimelineMealMenuCardProps) => {
  const clientTimezone = useClientTimezone();
  const timeRange = formatTimeRangeInTimezone(mealMenu.start, mealMenu.durationInMinutes, mealMenu.timezone, {
    excludeTimezoneSuffix: clientTimezone === mealMenu.timezone,
  });

  const {
    palette: { greyscale },
  } = useTheme();

  return (
    <Card sx={{ border: `1px solid ${greyscale[100]}` }}>
      <CardHeader
        title={
          <b>
            {mealMenu.name} ({timeRange})
          </b>
        }
        titleTypographyProps={{ variant: "h3" }}
      />

      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography variant="body2">Click to Place Order / Save Log</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TimelineMealMenuCard;

import { ApolloQueryResult, QueryHookOptions, QueryResult } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { CursorPaginationInput } from "../../../types";

const DEFAULT_LIMIT = 25;
export interface CursorPaginationQueryVariables {
  pagination: CursorPaginationInput | null;
}

type CursorType = CursorPaginationInput["cursor"];

type NonNullableCursorType = NonNullable<CursorPaginationInput["cursor"]>;

export type CursorConnectionQueryResults<K extends string, EdgesType> = {
  [key in K]: {
    edges: readonly EdgesType[];
    pageInfo: {
      endCursor: NonNullableCursorType;
      hasNextPage: boolean;
    };
  };
};

export type CursorConnectionQueryHook<K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables> = (
  baseOptions?: QueryHookOptions<CursorConnectionQueryResults<K, EdgesType>, QueryVariables>
) => QueryResult<CursorConnectionQueryResults<K, EdgesType>, QueryVariables>;

export interface GetQueryVariablesFromPaginationFnArgs {
  cursor: CursorType | null;
  limit: number;
}

type GetQueryVariablesFromPaginationFn<K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables> = ({
  cursor,
  limit,
}: GetQueryVariablesFromPaginationFnArgs) => QueryHookOptions<CursorConnectionQueryResults<K, EdgesType>, QueryVariables>;

export interface UseInfiniteCursorConnectionScrollArgs<K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables> {
  limit?: number;
  startCursor?: CursorType | null;
  useCursorConnectionQuery: CursorConnectionQueryHook<K, EdgesType, QueryVariables>;
  getQueryVariablesFromPagination: GetQueryVariablesFromPaginationFn<K, EdgesType, QueryVariables>;
  queryKey: K;
  edgesAreEqual: (edge1: EdgesType, edge2: EdgesType) => boolean;
}

export interface UseInfiniteCursorConnectionScrollResults<K extends string, EdgesType> {
  edges: readonly EdgesType[] | undefined;
  // endCursor: CursorType | undefined;
  hasNextPage: boolean | undefined;
  next: () => Promise<void> | Promise<ApolloQueryResult<CursorConnectionQueryResults<K, EdgesType>>>;
  loading: boolean;
}

const useInfiniteCursorConnectionScroll = <K extends string, EdgesType, QueryVariables extends CursorPaginationQueryVariables>({
  limit = DEFAULT_LIMIT,
  startCursor = null,
  useCursorConnectionQuery,
  getQueryVariablesFromPagination, // To optimize please use useCallback
  queryKey,
  edgesAreEqual, // To optimize please use useCallback
}: UseInfiniteCursorConnectionScrollArgs<K, EdgesType, QueryVariables>): UseInfiniteCursorConnectionScrollResults<K, EdgesType> => {
  const baseOptions = useMemo(
    () =>
      getQueryVariablesFromPagination({
        cursor: startCursor,
        limit,
      }),
    [getQueryVariablesFromPagination, startCursor, limit]
  );

  const rawResults = useCursorConnectionQuery({
    nextFetchPolicy: "cache-first", // needed to prevent fetch after fetchmore completes
    ...baseOptions,
    notifyOnNetworkStatusChange: true, // needed so loading is properly set by fetchMore
  });

  const { data, loading, fetchMore } = rawResults;

  const {
    pageInfo: { hasNextPage, endCursor },
    edges,
  } = useMemo(
    () =>
      data && data[queryKey]
        ? data[queryKey]
        : {
            pageInfo: { hasNextPage: undefined, endCursor: undefined },
            edges: undefined,
          },
    [data, queryKey]
  );

  const next = useCallback(() => {
    if (!hasNextPage || loading || !endCursor) {
      return Promise.resolve();
    }

    const nextBaseOptions = getQueryVariablesFromPagination({
      cursor: endCursor,
      limit,
    });

    return fetchMore({
      ...nextBaseOptions,
      /*
        The updateQuery callback for fetchMore is deprecated, and will be removed
        in the next major version of Apollo Client.

        Please convert updateQuery functions to field policies with appropriate
        read and merge functions, or use/adapt a helper function (such as
        concatPagination, offsetLimitPagination, or relayStylePagination) from
        @apollo/client/utilities.

        The field policy system handles pagination more effectively than a
        hand-written updateQuery function, and you only need to define the policy
        once, rather than every time you call fetchMore.
      */
      updateQuery: (prev, { fetchMoreResult }) => {
        // TODO: updateQuery is deprecated look into adapting relayStylePagination
        if (!fetchMoreResult) {
          return prev;
        }

        const prevEdges = prev[queryKey]?.edges ?? [];
        const newEdges = fetchMoreResult[queryKey].edges.filter(
          newEdge => prevEdges.find(prevEdge => edgesAreEqual(newEdge, prevEdge)) === undefined
        );
        return {
          ...fetchMoreResult,
          [queryKey]: {
            ...fetchMoreResult[queryKey],
            edges: [...prevEdges, ...newEdges],
          },
        };
      },
    });
  }, [hasNextPage, endCursor, fetchMore, queryKey, limit, getQueryVariablesFromPagination, loading, edgesAreEqual]);

  const result = useMemo(
    () => ({
      edges,
      endCursor,
      hasNextPage,
      next,
      loading,
    }),
    [edges, endCursor, hasNextPage, next, loading]
  );

  return result;
};

export default useInfiniteCursorConnectionScroll;

import { List, ListItem, ListItemText, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { BonAppetitCafeFragment } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    noUnitsDiv: {
      justifyContent: "center",
      alignItems: "center",
    },
    cafe: {
      marginTop: theme.spacing(2),
    },
  })
);

interface SelectBonAppetitCafeProps {
  cafes: readonly BonAppetitCafeFragment[];
  selectedCafeId: string | null;
  onSelectCafe: (cafeId: string) => void;
}

const SelectBonAppetitCafe = ({ cafes, selectedCafeId, onSelectCafe }: SelectBonAppetitCafeProps) => {
  const classes = useStyles();
  return (
    <div className={classes.cafe}>
      {cafes.length > 0 ? (
        <List>
          {cafes.map(({ id, name }) => {
            return (
              <ListItem
                key={id}
                onClick={() => onSelectCafe(id)}
                selected={id === selectedCafeId}
                button>
                <ListItemText primary={name} />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography>No Cafes found.</Typography>
      )}
    </div>
  );
};

export default SelectBonAppetitCafe;

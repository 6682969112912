import { AutocompleteRenderOptionState, Box, Typography } from "@mui/material";
import React, { Reducer, useCallback, useEffect, useReducer } from "react";
import { CursorPaginationInput } from "../../../../../types";
import { Idable } from "../../../../../views/Staff/GeneralizedStaffContent/types";
import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "../../../../universal/InfiniteScroll/InfiniteScrollAutocomplete";
import {
  CursorConnectionQueryHook,
  CursorPaginationQueryVariables,
} from "../../../../universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import FilterCheckList from "./FilterCheckList";
import { BaseFilterAction, BaseFilterState, baseFilterReducer, buildInitialState } from "./utils";

interface FilterCursorPaginationQueryVariables extends CursorPaginationQueryVariables {
  query: string | null;
  pagination: CursorPaginationInput;
}

interface BaseFilterProps<K extends string, EdgesType, T extends Idable> {
  getLabel: (item: T) => string;
  getKey: (item: T) => string;
  selectedFilters: T[];
  filterLabel: string;
  initialVisibleFilters?: T[];
  handleChange: (filters: T[]) => void;
  disabled?: boolean;
  queryKey: string;
  edgesAreEqual: (e1: EdgesType, e2: EdgesType) => boolean;
  transformAndFilterOptions: (edges: EdgesType[] | undefined) => T[] | undefined;
  useCursorConnectionQuery: CursorConnectionQueryHook<K, EdgesType, FilterCursorPaginationQueryVariables>;
  renderOption: (option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
}

const BaseFilter = <K extends string, EdgesType, T extends Idable>({
  getLabel,
  getKey,
  filterLabel,
  initialVisibleFilters = [],
  handleChange,
  selectedFilters,
  disabled = false,
  ...infiniteScrollAutocompleteProps
}: BaseFilterProps<K, EdgesType, T>) => {
  const [state, dispatch] = useReducer<Reducer<BaseFilterState<T>, BaseFilterAction<T>>>(
    baseFilterReducer,
    buildInitialState<T>(initialVisibleFilters)
  );

  useEffect(() => {
    dispatch({
      type: "UPDATE_VISIBLE_FILTERS",
      payload: {
        selectedFilters,
      },
    });
  }, [selectedFilters]);

  const removeOption = (oldSelectFilters: T[], option: T) => {
    handleChange([...oldSelectFilters.filter(filter => filter.id !== option.id)]);
  };

  const addOption = (oldSelectFilters: T[], option: T) => {
    handleChange([...oldSelectFilters, option]);
  };

  const handleFilterCheckChange = (option: T, isSelected: boolean) => {
    if (isSelected) {
      addOption(selectedFilters, option);
    } else {
      removeOption(selectedFilters, option);
    }
  };

  const handleSelect = (_: string, option: T | null) => {
    if (option === null) {
      return;
    }
    dispatch({ type: "CHANGE_QUERY", payload: { query: "" } });
    if (selectedFilters && selectedFilters.includes(option)) {
      removeOption(selectedFilters, option);
    } else {
      addOption(selectedFilters, option);
    }
  };

  const getQueryVariables = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
      },
    }),
    []
  );

  return (
    <Box sx={{ pb: 2 }}>
      <Typography variant="body1Medium">{filterLabel}</Typography>
      <FilterCheckList<T>
        getLabel={getLabel}
        onFilterChange={handleFilterCheckChange}
        selectedFilters={selectedFilters}
        visibleFilters={state.visibleFilters}
        disabled={disabled}
        isOpen={true}
      />
      <InfiniteScrollAutocomplete
        {...infiniteScrollAutocompleteProps}
        getQueryVariablesFromPaginationAndInput={getQueryVariables}
        handleChange={handleSelect}
        getOptionLabel={getLabel}
        getCustomOptionKey={getKey}
        multiple={false}
        inputPlaceholder={filterLabel}
        value={null}
        disabled={disabled}
        useExample
        freeSolo={false}
      />
    </Box>
  );
};

export default BaseFilter;

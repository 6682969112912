import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Box, SxProps, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";

interface AiAugmentedMenuItemNameProps {
  name: string;
  isAiGenerated?: boolean;
  isAiTranscribed?: boolean;
  sx?: SxProps;
}

//using no max width tooltip to prevent the tooltip text from wrapping
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export const AiAugmentedMenuItemName = ({ name, isAiGenerated, isAiTranscribed, sx }: AiAugmentedMenuItemNameProps) => {
  const tooltipText = isAiGenerated ? (isAiTranscribed ? "recipes built and transcribed" : "a recipe built by") : "a recipe transcribed by";
  return (
    <Box>
      <NoMaxWidthTooltip
        title={
          <>
            <strong>{name}</strong>
            <br />
            <span>This menu item contains {tooltipText} AI!</span>
          </>
        }
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Typography sx={sx}>{name}</Typography>
          {isAiGenerated && <AutoAwesomeIcon sx={{ fontSize: "16px", m: 0.5 }} />}
          {isAiTranscribed && <AutoStoriesIcon sx={{ fontSize: "16px", m: 0.5 }} />}
        </Box>
      </NoMaxWidthTooltip>
    </Box>
  );
};

import React from "react";

import Content from "./Content";

import ActionDialog from "apps/web/src/componentLibrary/ActionDialog/Dialog";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import { CopyableMealPlanFragment, useMealPlanBulkCopyQuery } from "../../../../types";

interface BulkCopyMealPlanDialogLoaderProps {
  mealPlan: CopyableMealPlanFragment;
  teamId: string;
  open: boolean;
  onClose: () => void;
}

const BulkCopyMealPlanDialogLoader = ({ mealPlan, teamId, onClose, open }: BulkCopyMealPlanDialogLoaderProps) => {
  const teamsResult = useMealPlanBulkCopyQuery();

  if (teamsResult.loading || !teamsResult.data) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const teams = teamsResult.data.teams;
  const exchangeSets = teamsResult.data.exchangeSets;

  return (
    <ActionDialog open={open} onClose={onClose}>
      <Content
        teamId={teamId}
        teams={teams}
        exchangeSets={exchangeSets}
        mealPlan={mealPlan}
        onClose={onClose} />
    </ActionDialog>
  );
};

export default BulkCopyMealPlanDialogLoader;

import { formatDate } from "@notemeal/shared/ui/utils/dateTimes";
import { TeamAthletesMealPlanFragment, MacroMath } from "../../../../types";
import { enUS } from "date-fns/locale";

export interface TeamAthletesMealPlanRow extends TeamAthletesMealPlanFragment {
  createdAtString: string;

  /* Transformed values */
  athleteName: string;
  lastViewedDate: string | null;
  numberOfViews: number;
  choTarget: string;
  proTarget: string;
  fatTarget: string;

  scheduleName: string | null;
  exchangeSetName: string | null;

  goalType: string;
}

interface HasNames {
  firstName: string | null;
  lastName: string | null;
}

const toFullName = ({ firstName, lastName }: HasNames): string => {
  return firstName ? `${lastName}, ${firstName[0]}` : lastName ? lastName : "Athlete with no name";
};

const formatMacro = ({ gPerKg, leftOverRatio }: MacroMath): string => {
  if (gPerKg === 0) {
    return `${leftOverRatio}%`;
  } else {
    return `${gPerKg} g/kg`;
  }
};
export const formatMealPlans = (mealPlans: readonly TeamAthletesMealPlanFragment[]): TeamAthletesMealPlanRow[] => {
  return mealPlans.map(mp => {
    const {
      athlete,
      createdAt,
      macroProtocol: { calorieBudget, cho, pro, fat },
      schedule,
      exchangeSet,
      mostRecentEvent,
      events,
    } = mp;
    return {
      ...mp,
      createdAtString: String(formatDate(createdAt as unknown as string, undefined, enUS)), // TODO: Local is this safe to change?
      athleteName: toFullName(athlete),
      numberOfViews: events.length,
      choTarget: formatMacro(cho),
      proTarget: formatMacro(pro),
      fatTarget: formatMacro(fat),
      scheduleName: schedule && schedule.name,
      exchangeSetName: exchangeSet?.name || null,
      lastViewedDate: mostRecentEvent?.datetime || null,
      goalType: calorieBudget?.goalSnapshot.type.name || "",
    };
  });
};

export const getNextSelectionState = (visibleRowIds: string[], selectedRowIds: Set<string>): Set<string> => {
  if (visibleRowIds.every(rId => selectedRowIds.has(rId))) {
    //All visible rows are selected, so filter out (deselect) all visible rows
    return new Set([...selectedRowIds].filter(sr => !visibleRowIds.includes(sr)));
  } else {
    //Select any visible rows that are not currently selected
    return new Set([...selectedRowIds, ...visibleRowIds]);
  }
};

import { TWColor } from "./Greyscale";

export const pink: TWColor = {
  900: "#841651",
  800: "#9E165F",
  700: "#C01574",
  600: "#DD2590",
  500: "#EE46BC",
  400: "#F670C7",
  300: "#FAA7E0",
  200: "#FCCEEE",
  100: "#FCE7F6",
  50: "#FDF2FA",
  25: "#FEF6FB",
};

export const violet: TWColor = {
  900: "#491C95",
  800: "#5720B7",
  700: "#6927DA",
  600: "#7839EE",
  500: "#875BF7",
  400: "#A48AFB",
  300: "#C3B4FD",
  200: "#DDD6FE",
  100: "#ECE9FE",
  50: "#F5F3FF",
  25: "#FBFAFF",
};

export const blueDark: TWColor = {
  900: "#00359E",
  800: "#0040C1",
  700: "#004EEB",
  600: "#155EEF",
  500: "#2970FF",
  400: "#528BFF",
  300: "#84ADFF",
  200: "#B2CCFF",
  100: "#D1E0FF",
  50: "#EFF4FF",
  25: "#F5F8FF",
};

export const indigo: TWColor = {
  900: "#2D3282",
  800: "#2D31A6",
  700: "#3538CD",
  600: "#444CE7",
  500: "#6172F3",
  400: "#8098F9",
  300: "#A4BCFD",
  200: "#C6D7FE",
  100: "#E0EAFF",
  50: "#EEF4FF",
  25: "#F5F8FF",
};

export const greyBlue: TWColor = {
  900: "#101323",
  800: "#293056",
  700: "#363F72",
  600: "#3E4784",
  500: "#4E5BA6",
  400: "#717BBC",
  300: "#B3B8DB",
  200: "#D5D9EB",
  100: "#EAECF5",
  50: "#F8F9FC",
  25: "#FCFCFD",
};

export const orangeDark: TWColor = {
  900: "#772917",
  800: "#932F19",
  700: "#B93815",
  600: "#E04F16",
  500: "#EF6820",
  400: "#F38744",
  300: "#F7B27A",
  200: "#F9DBAF",
  100: "#FDEAD7",
  50: "#FEF6EE",
  25: "#FEFAF5",
};

export const greenLight: TWColor = {
  900: "#2B5314",
  800: "#326212",
  700: "#3B7C0F",
  600: "#4CA30D",
  500: "#66C61C",
  400: "#85E13A",
  300: "#A6EF67",
  200: "#D0F8AB",
  100: "#E3FBCC",
  50: "#F3FEE7",
  25: "#FAFFF5",
};

export const green: TWColor = {
  900: "#084C2E",
  800: "#095C37",
  700: "#087443",
  600: "#099250",
  500: "#16B364",
  400: "#3CCB7F",
  300: "#73E2A3",
  200: "#AAF0C4",
  100: "#D3F8DF",
  50: "#EDFDF2",
  25: "#F6FEF9",
};

export const yellow: TWColor = {
  900: "#713B12",
  800: "#854A0E",
  700: "#A15C07",
  600: "#CA8504",
  500: "#EAAA08",
  400: "#FAC515",
  300: "#FEE172",
  200: "#FEEE95",
  100: "#FEF7C3",
  50: "#FEFBE8",
  25: "#FEFDF0",
};

import { Box, Button, CardMedia, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

export interface AppErrorProps {
  title: string;
  subtitle: string;
  body: string;
  imageUrl?: string;
  handleFinished?: () => void;
}

export const AppError = ({ title, subtitle, body, imageUrl, handleFinished }: AppErrorProps) => {
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    if (handleFinished) {
      handleFinished();
    }

    navigate(-1);
  };

  return (
    <Box sx={{ flex: 1, mt: "200px", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ width: "400px", display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
        <Typography variant="h1">{title}</Typography>
        {!imageUrl && (
          <Box sx={{ width: "300px", height: "300px" }}>
            <Loading progressSize="lg" />
          </Box>
        )}
        {imageUrl && <CardMedia
          sx={{ width: "300px", height: "300px" }}
          component="img"
          image={imageUrl} />}
        <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h4">{subtitle}</Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {body}
          </Typography>
        </Box>
        {imageUrl && (
          <Button sx={{ mt: 1 }} onClick={handleNavigateBack}>
            Return to Previous Page
          </Button>
        )}
      </Box>
    </Box>
  );
};

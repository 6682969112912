import { Box } from "@mui/material";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { MenuSelectionFormFragment } from "../../../types";
import MenuPlateCard from "../../MenuSelection/MenuPlateCard";

interface MenuOrderQuickAddCardsProps {
  menuSelections: readonly MenuSelectionFormFragment[];
  onChoose: (suggestion: MenuSelectionFormFragment) => void;
  targetMacros: Macros | null;
  tab?: string;
}

const MenuOrderQuickAddCards = ({ menuSelections, onChoose, tab, targetMacros }: MenuOrderQuickAddCardsProps) => {
  if (menuSelections.length === 0) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "start", gap: 4 }}>
      {menuSelections.map((menuSelection, index) => (
        <MenuPlateCard
          tab={tab}
          index={index + 1}
          key={index}
          menuSelection={menuSelection}
          menuSelectionItems={menuSelection.items}
          sx={{ flex: "calc(1/3)" }}
          onChoose={onChoose}
          targetMacros={targetMacros}
          showNutrientsButton={false}
        />
      ))}
    </Box>
  );
};

export default MenuOrderQuickAddCards;

import { z } from "zod";

export const FoodTypeSchema = z.enum(["GenericFood", "BrandedFood"]);

export const FoodDataSourceSchema = z.enum(
  ["usda", "manual", "sodexo", "compass", "cbord", "nutritionix", "bonAppetit", "restaurant", "mccance", "%future added value"],
  {
    required_error: "Required",
  }
);

export const ExchangeTypeSchema = z.enum(["starch", "fruit", "vegetable", "protein", "dairy", "fat", "%future added value"], {
  required_error: "Required",
});

// NOTE: all foods should inherit from BrandedFoodSchema or GenericFoodSchema, not the base schema
const BaseFoodSchema = z.object({
  // common
  name: z.string(),
  type: FoodTypeSchema,
  exchangeTypes: z.array(ExchangeTypeSchema).optional().nullable(),
  source: FoodDataSourceSchema,
  imageUrl: z.string().optional().nullable(),
  groceryListCategoryId: z.string().uuid().optional().nullable(),

  isRecipeIngredientOnly: z.boolean().optional(),
  excludeFromSuggestions: z.boolean().optional(),
  isDeleted: z.boolean().optional(),

  // generic food
  genericFoodId: z.null().optional(),
  genericFood: z.null().optional(),

  // branded food
  brandId: z.null().optional(),
  brand: z.null().optional(),

  // user food
  exclusiveOwnerId: z.null().optional(),
  deprecatedById: z.null().optional(),

  // org food
  // NOTE: this is handled by createOrgService
  orgId: z.null().optional(),

  // usda
  usdaFdcDescription: z.null().optional(),
  usdaFdcId: z.null().optional(),
  usdaFdcDataType: z.null().optional(),
  usdaFdcFoodCategoryId: z.null().optional(),

  // nutritionix
  nixItemId: z.null().optional(),
  nixTagId: z.null().optional(),
  usdaNdbNo: z.null().optional(),

  // compass
  compassMasterRefNumber: z.null().optional(),

  // cbord
  cbordItemOid: z.null().optional(),
  cbordUpdateCounts: z.null().optional(),

  // sodexo
  sodexoItemId: z.null().optional(),
  sodexoItemHash: z.null().optional(),

  // bonAppetit
  bonAppetitItemId: z.null().optional(),
  bonAppetitItemHash: z.null().optional(),

  // restaurant food
  restaurant: z.null().optional(),

  // mccance food
  mccanceId: z.null().optional(),
  mccanceName: z.null().optional(),
});

export const GenericFoodSchema = BaseFoodSchema.merge(
  z.object({
    type: z.literal("GenericFood"),
  })
);

export const BrandedFoodSchema = BaseFoodSchema.merge(
  z.object({
    type: z.literal("BrandedFood"),
    brand: z.object({ id: z.string() }).optional().nullable(),
    brandId: z.string().uuid().optional().nullable(),
  })
);

export type ZodRawFood = z.infer<typeof BaseFoodSchema>;

import { Button, DialogActions, DialogContent, Step, StepLabel, Stepper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import React, { ReactElement } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      alignSelf: "center",
      paddingBottom: theme.spacing(0),
    },
  })
);

interface ActionDialogContentProps {
  title: string;
  activeStep: number;
  setActiveStep: (step: number) => void;
  steps: readonly string[];
  getCanSaveTooltipItems: () => string[];
  runSideEffects: () => void;
  children: ReactElement;
  onClose: () => void;
}

const ActionDialogContent = ({
  title,
  activeStep,
  setActiveStep,
  steps,
  getCanSaveTooltipItems,
  runSideEffects,
  children,
  onClose,
}: ActionDialogContentProps) => {
  const classes = useStyles();

  const canSaveTooltips = getCanSaveTooltipItems();

  const handleOnBack = () => setActiveStep(activeStep - 1);

  const handleOnNext = () => {
    if (canSaveTooltips.length) {
      return;
    }
    runSideEffects();
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <DialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={activeStep === steps.length}
          onClick={activeStep === 0 ? onClose : handleOnBack}>
          {activeStep === 0 ? "Cancel" : "Back"}
        </Button>
        <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
          <Button onClick={handleOnNext} disabled={activeStep === steps.length}>
            {activeStep >= steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </>
  );
};

export default ActionDialogContent;

import React, { useEffect } from "react";
import { String } from "runtypes";
import { AppError } from "../../components/AppError/AppError";
import {
  getCheckForUpdatesStrings,
  getFetchingLatestUpdatesStrings,
  getFinishedStrings,
  getIsUpdateRequired,
} from "../../components/AppError/AppErrorUtils";
import { useApiCredentialsAndSchemaLazyQuery } from "../../types";
import { useLocalStorageValueAsType } from "../../utils/localStorage";

enum PageState {
  CheckForUpdates,
  FetchLatestUpdates,
  Finished,
}

interface AppUpdateCheckProps {
  wasError?: boolean;
  onUpdated?: () => void;
}

export const AppUpdateCheckPage = ({ wasError, onUpdated }: AppUpdateCheckProps) => {
  const previousVersion = useLocalStorageValueAsType("webSchemaVersion", String)[0];
  const [getSchemaVersion, { data: schemaData, loading: schemaLoading }] = useApiCredentialsAndSchemaLazyQuery({
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    const timeout = setTimeout(() => getSchemaVersion(), 2000);
    return () => clearTimeout(timeout);
  }, [getSchemaVersion]);

  const loading = !schemaData || schemaLoading;
  let pageState;
  let uiStrings;

  if (loading) {
    pageState = PageState.CheckForUpdates;
    uiStrings = getCheckForUpdatesStrings(!!wasError);
  } else {
    if (getIsUpdateRequired(schemaData.webVersion)) {
      pageState = PageState.FetchLatestUpdates;
      uiStrings = getFetchingLatestUpdatesStrings(!!wasError);
    } else {
      pageState = PageState.Finished;
      uiStrings = getFinishedStrings(!!wasError, previousVersion);
    }
  }

  if (pageState === PageState.FetchLatestUpdates) {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      window.location.reload();
    }, 4000);
  }

  return <AppError {...uiStrings} handleFinished={() => onUpdated && onUpdated()} />;
};

import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useState } from "react";
import {
  CopyableMealPlanFragment,
  MealPlanBulkCopyTeamPreviewFragment,
  useMealPlanBulkCopyChooseAthletesContainerQuery,
} from "../../../types";
import ChooseAthletesList from "./ChooseAthletesList";
import ChooseTeamList from "./ChooseTeamList";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      overflowY: "hidden",
      height: "100%",
      width: "100%",
      justifyContent: "space-around",
      paddingBottom: "10",
    },
    column: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      minWidth: "500px",
    },
  })
);

interface MealPlanChooseAthletesFormProps {
  toCopyMealPlan: CopyableMealPlanFragment;
  selectedAthleteIds: string[];
  setSelectedAthleteIds: (ids: string[]) => void;
  teamId: string;
  teams: readonly MealPlanBulkCopyTeamPreviewFragment[];
}

const MealPlanChooseAthletesForm = ({
  selectedAthleteIds,
  setSelectedAthleteIds,
  toCopyMealPlan,
  teamId,
  teams,
}: MealPlanChooseAthletesFormProps) => {
  const classes = useStyles();
  const [selectedTeamId, setSelectedTeamId] = useState(teamId);
  const { loading, data } = useMealPlanBulkCopyChooseAthletesContainerQuery({
    variables: { teamId: selectedTeamId },
  });

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <ChooseTeamList
          teamId={selectedTeamId}
          onSelectTeam={id => {
            setSelectedTeamId(id);
          }}
          teams={teams}
        />
      </div>

      <div className={classes.column}>
        {!loading && data ? (
          <ChooseAthletesList
            selectedAthleteIds={selectedAthleteIds}
            selectedTeamId={selectedTeamId}
            setSelectedAthleteIds={setSelectedAthleteIds}
            athletes={data?.team.athletes}
            toCopyMealPlan={toCopyMealPlan}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default MealPlanChooseAthletesForm;

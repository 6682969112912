import { Box, Grid, InputAdornment, Switch, TextField, Typography, styled } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { useAnthropometryFormatting } from "@notemeal/shared/ui/contexts/useAnthropometryFormatting";
import {
  EditMacroProtocolAction,
  EditMacroProtocolState,
  getAnthropometrySnapshotFromAnthroInfoState,
} from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import {
  applyMetricWeightTarget,
  applyWeightTarget,
  rmrMethodHasLeanBodyMass,
  rmrMethodHasPercentBodyFat,
} from "@notemeal/shared/utils/macro-protocol";
import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { Dispatch } from "react";
import { GoalTypeFragment } from "../../../types";
import { AnthropometryDisplayCard } from "./AnthropometryDisplayCard";
import CalorieBudget from "./CalorieBudget";
import { MacrosTargets } from "./MacrosTargets";

const SubHeader = styled(Box)(({ theme: { spacing } }) => ({
  height: "40px",
  marginBottom: spacing(2),
  display: "flex",
  alignItems: "center",
}));

interface MacroProtocolFormProps {
  state: EditMacroProtocolState;
  dispatch: Dispatch<EditMacroProtocolAction>;
  goalTypes: readonly GoalTypeFragment[];
}

export const MacroProtocolForm = ({ state, dispatch, goalTypes }: MacroProtocolFormProps) => {
  const { isMetricLocale } = useLocaleContext();
  const { formatWeight } = useAnthropometryFormatting();
  const { fat, pro, cho, anthroInfo, usingWeightTarget, usingCalorieBudget, calorieBudget, weightTargetInput } = state;
  const weightTarget = usingWeightTarget ? (state.__typename === "imperial" ? state.weightTarget : state.weightTargetInKg) : null;
  const anthropometrySnapshot = getAnthropometrySnapshotFromAnthroInfoState(anthroInfo);

  const targetAnthroSnapshot = isMetricLocale
    ? applyMetricWeightTarget(anthropometrySnapshot, weightTarget)
    : applyWeightTarget(anthropometrySnapshot, weightTarget);

  const rmrMethod = calorieBudget.rmrMethod;
  const showPercentBodyFat = !!rmrMethod && rmrMethodHasPercentBodyFat(rmrMethod);
  const showLeanBodyMass = !!rmrMethod && rmrMethodHasLeanBodyMass(rmrMethod);

  const { macroProtocolAnthroSnapshot, mostRecentAnthropometrySnapshot } = anthroInfo;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={6}>
        <SubHeader>
          <Typography variant="body1Medium">Calorie Budget</Typography>
          <Switch
            checked={usingCalorieBudget}
            onChange={e => {
              trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Toggled Calorie Budget", {
                calorieBudgetEnabled: e.target.checked,
              });
              dispatch({
                type: "CHANGE_USING_CALORIE_BUDGET",
                payload: e.target.checked,
              });
            }}
            sx={{ ml: 1 }}
          />
        </SubHeader>
        <CalorieBudget
          state={calorieBudget}
          dispatch={dispatch}
          anthropometrySnapshot={targetAnthroSnapshot}
          goalTypes={goalTypes}
          disabled={!usingCalorieBudget}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}>
        <SubHeader sx={{ pl: 1 }}>
          <Typography variant="body1Medium">Macronutrient Targets</Typography>
        </SubHeader>
        <MacrosTargets
          cho={cho}
          pro={pro}
          fat={fat}
          anthropometrySnapshot={targetAnthroSnapshot}
          dispatch={dispatch}
          gPerKgOnly={!usingCalorieBudget}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ height: "40px", my: 2, display: "flex", alignItems: "center" }}>
          <Typography variant="body1Medium" color="textSecondary">
            Weight Target #
          </Typography>
          <Switch
            checked={usingWeightTarget}
            onChange={e => {
              trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Toggled Weight Target", {
                weightTargetEnabled: e.target.checked,
              });
              dispatch({
                type: "CHANGE_USING_WEIGHT_TARGET",
                payload: e.target.checked,
              });
            }}
            sx={{ ml: 1 }}
          />
          {usingWeightTarget && weightTarget !== null && (
            <>
              <TextField
                sx={{ mt: 0, width: 95, mx: 1 }}
                type="number"
                inputProps={{ min: 0, step: 0.1 }}
                value={weightTargetInput}
                onChange={e =>
                  dispatch({
                    type: "CHANGE_WEIGHT_TARGET_INPUT",
                    payload: e.target.value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" variant="filled">
                      {state.__typename === "imperial" ? "lb" : "kg"}
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant="body2" color="textSecondary">
                {`(Current: ${formatWeight(weightTarget)})`}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1Medium" color="textSecondary">
            Anthropometry Data
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <AnthropometryDisplayCard
            anthropometryEntrySnapshot={mostRecentAnthropometrySnapshot}
            onClick={() => {
              dispatch({
                type: "CHANGE_ANTHRO_INFO_MODE",
                payload: "mostRecent",
              });
            }}
            showPercentBodyFat={showPercentBodyFat}
            showLeanBodyMass={showLeanBodyMass}
            isSelected={anthroInfo.mode === "mostRecent"}
            title="Most Recent"
          />
          {macroProtocolAnthroSnapshot && (
            <AnthropometryDisplayCard
              anthropometryEntrySnapshot={macroProtocolAnthroSnapshot}
              onClick={() => {
                dispatch({
                  type: "CHANGE_ANTHRO_INFO_MODE",
                  payload: "macroProtocol",
                });
              }}
              showPercentBodyFat={showPercentBodyFat}
              showLeanBodyMass={showLeanBodyMass}
              isSelected={anthroInfo.mode === "macroProtocol"}
              title="Existing"
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

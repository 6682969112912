import { Button, Dialog, DialogActions, DialogContent, Step, StepLabel, Stepper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { addDays, startOfWeek } from "date-fns";
import React, { useState } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import {
  ImportCbordMenusInput,
  TeamMealMenuPreviewFragment,
  useCbordBusinessUnitsQuery,
  useCbordServiceMenusQuery,
} from "../../../../types";
import OtherSettings from "../Common/OtherSettings";
import { ImportMenuOptionsState, getImportMenuOptionsInput, getInitImportMenuOptionsState } from "../Common/utils";
import SelectServiceMenu from "./SelectServiceMenus";
import SelectServiceUnit from "./SelectServiceUnit";
import { SelectedServiceUnitInfo, getImportCbordMenuGroups, groupServiceMenusByDate } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      overflow: "auto",
    },
    menuForm: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    savingDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  })
);

const STEPS = ["Select Service Unit", "Select Service Menu", "Edit Menu Details"];

interface MenusCbordDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (input: ImportCbordMenusInput) => void;
  clientTimezone: string;
  allTeams: readonly TeamMealMenuPreviewFragment[];
  saving: boolean;
}

const DAYS_IN_WEEK = 7;
// May need to change this if it is not a universal CBORD concept (or even store on the backend per org)
const DEFAULT_BASE_MENU_NAME = "Daily Offerings";

const MenusCbordDialog = ({ open, onClose, onImport, clientTimezone, allTeams, saving }: MenusCbordDialogProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const { data: unitsData } = useCbordBusinessUnitsQuery({
    onError: () => {
      setMessage("error", "An error occurred. Contact CBORD Support for assistance.");
      onClose();
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [selectedServiceUnitInfo, setSelectedServiceUnitInfo] = useState<SelectedServiceUnitInfo | null>(null);
  const [importMenuOptionsState, setImportMenuOptionsState] = useState<ImportMenuOptionsState | null>(null);

  const [startOfWeekDate, setStartOfWeekDate] = useState(startOfWeek(new Date()));
  const weekDates = [...Array(DAYS_IN_WEEK).keys()].map(d => serializeDate(addDays(startOfWeekDate, d)));

  const { data: menusData, loading } = useCbordServiceMenusQuery({
    skip: selectedServiceUnitInfo === null,
    variables: {
      serviceUnitOid: selectedServiceUnitInfo?.oid ?? 0,
      start: serializeDate(startOfWeekDate),
      end: serializeDate(addDays(startOfWeekDate, DAYS_IN_WEEK)),
      facilityOid: selectedServiceUnitInfo?.facilityOid ?? null,
    },
  });
  const serviceMenus = menusData?.cbordServiceMenus || [];
  const datesWithMenus = groupServiceMenusByDate(weekDates, serviceMenus);

  const [baseMenuName, setBaseMenuName] = useState(DEFAULT_BASE_MENU_NAME);

  const [selectedMenuOids, setSelectedMenuOids] = useState<readonly number[]>([]);

  const onToggleMenu = (serviceMenuOid: number) => {
    if (selectedMenuOids.includes(serviceMenuOid)) {
      setSelectedMenuOids(selectedMenuOids.filter(oid => oid !== serviceMenuOid));
    } else {
      setSelectedMenuOids([...selectedMenuOids, serviceMenuOid]);
    }
  };

  const onSelectAllMenus = () => {
    setSelectedMenuOids(serviceMenus.map(m => m.oid));
  };

  const onChangeStartOfWeekDate = (startOfWeekDate: Date) => {
    setStartOfWeekDate(startOfWeekDate);
    // Clear selected menus if changing week
    setSelectedMenuOids([]);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case 0:
        return !selectedServiceUnitInfo ? ["Select service unit to look up menus"] : [];
      case 1:
        const menuGroups = getImportCbordMenuGroups(datesWithMenus, selectedMenuOids, baseMenuName);
        const importMenuCount = menuGroups.flatMap(g => g.serviceMenuOids).length;
        return importMenuCount === 0 ? ["Select at least one menu to import"] : [];
      default:
        return [];
    }
  };

  const canSaveTooltips = getCanSaveTooltipItems();

  const handleNext = async () => {
    if (canSaveTooltips.length) {
      return;
    }
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);

      const selectedMenuNames = new Set(
        serviceMenus.filter(m => selectedMenuOids.includes(m.oid) && m.meal !== baseMenuName).map(m => m.meal)
      );
      setImportMenuOptionsState(getInitImportMenuOptionsState(selectedMenuNames, clientTimezone));
    } else if (activeStep === 2) {
      if (selectedServiceUnitInfo && importMenuOptionsState) {
        setActiveStep(activeStep + 1);
        onImport({
          options: getImportMenuOptionsInput(importMenuOptionsState),
          serviceUnitOid: selectedServiceUnitInfo.oid,
          menuGroups: getImportCbordMenuGroups(datesWithMenus, selectedMenuOids, baseMenuName),
          facilityOid: selectedServiceUnitInfo.facilityOid,
        });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={{ paper: classes.paper }}>
      <DialogTitle title="Import Cbord Menus" onClose={onClose} />
      <DialogContent>
        <Stepper sx={{ width: "80%", alignSelf: "center" }} activeStep={activeStep}>
          {STEPS.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.content}>
          {activeStep === 0 && unitsData ? (
            <SelectServiceUnit
              businessUnits={unitsData.cbordBusinessUnits}
              selectedServiceUnitInfo={selectedServiceUnitInfo}
              onSelectServiceUnitInfo={setSelectedServiceUnitInfo}
            />
          ) : activeStep === 1 && selectedServiceUnitInfo ? (
            <SelectServiceMenu
              loading={loading}
              datesWithMenus={datesWithMenus}
              selectedMenuOids={selectedMenuOids}
              onToggleMenu={onToggleMenu}
              onSelectAllMenus={onSelectAllMenus}
              startOfWeekDate={startOfWeekDate}
              onChangeStartOfWeekDate={onChangeStartOfWeekDate}
              baseMenuName={baseMenuName}
              onChangeBaseMenuName={setBaseMenuName}
            />
          ) : activeStep === 2 && importMenuOptionsState ? (
            <div className={classes.menuForm}>
              <OtherSettings
                state={importMenuOptionsState}
                onChange={setImportMenuOptionsState}
                allTeams={allTeams} />
            </div>
          ) : activeStep === 3 && saving ? (
            <div className={classes.savingDiv}>
              <Loading progressSize="md" />
              <Typography variant="h3">Importing Menus - this may take a moment...</Typography>
            </div>
          ) : (
            <Loading progressSize="md" />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={activeStep === STEPS.length || activeStep === 0}
          onClick={handleBack}>
          Back
        </Button>
        {activeStep >= STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before finishing" items={canSaveTooltips}>
            <Button onClick={handleNext} disabled={activeStep > STEPS.length - 1}>
              Finish
            </Button>
          </TWItemizedTooltip>
        )}
        {activeStep < STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
            <Button onClick={handleNext}>Next</Button>
          </TWItemizedTooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MenusCbordDialog;

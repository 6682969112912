import React from "react";
import { MenuItemRowInfoWithAmount, PlateRowDataInfo } from "../utils";
import Row from "../Shared/Row";
import { getMenuItemCellBodyElements, getMenuItemCellHeaderElements } from "../utils/tableUtils";

interface HeaderRowProps {
  length: number;
}

interface TableRowProps {
  plateRowInfo: PlateRowDataInfo;
  openShowMoreModal: (menuItems: readonly MenuItemRowInfoWithAmount[]) => void;
  length: number;
}

export const HeaderRow = ({ length }: HeaderRowProps) => {
  return <Row>{["Amount", ...getMenuItemCellHeaderElements(length)]}</Row>;
};

const TableRow = ({ plateRowInfo, openShowMoreModal, length }: TableRowProps) => {
  return <Row>{[plateRowInfo.amount, ...getMenuItemCellBodyElements(plateRowInfo.menuItems, openShowMoreModal, length)]}</Row>;
};

export default TableRow;

import { z } from "zod";
import { NutrientAmountSchema } from "./NutrientAmountSchema";
import { ServingUnitsSchema } from "./ServingUnitsSchema";

export const BaseFoodServingSchema = z
  .object({
    id: z.string(),
    isNewServing: z.boolean(),

    // allow these to be nullable to skirt around the issue of
    // mui text fields not allowing the base zero to be deleted
    // when entering a number
    weight: z.number().nonnegative().gt(0).nullable(),
    cho: z.number().nonnegative().nullable(),
    pro: z.number().nonnegative().nullable(),
    fat: z.number().nonnegative().nullable(),
    defaultAmount: z.number().nonnegative().gt(0).nullable(),

    isRecipeServingOnly: z.boolean(),
    units: ServingUnitsSchema,
    isDefault: z.boolean(),
    usdaWeightSeq: z.string().nullable(),
    nutrientAmounts: NutrientAmountSchema.nullable(),
  })
  .superRefine((state, ctx) => {
    const { defaultAmount, weight, cho, pro, fat } = state;

    if (defaultAmount === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Number must be greater than or equal to 0",
        path: ["defaultAmount"],
      });
    }

    if (weight === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Number must be greater than or equal to 0",
        path: ["weight"],
      });
    }

    if (cho === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Number must be greater than or equal to 0",
        path: ["cho"],
      });
    }

    if (pro === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Number must be greater than or equal to 0",
        path: ["pro"],
      });
    }

    if (fat === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Number must be greater than or equal to 0",
        path: ["fat"],
      });
    }
  });

export type FoodServingType = z.infer<typeof BaseFoodServingSchema>;

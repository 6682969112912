import { Box } from "@mui/material";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useMealPlanCalendarContext } from "../../../MealPlans/contexts/Calendar";
import { GoalConfigurationFormType } from "../GoalConfigurationSchema";
import { GoalDateAssignmentCalendar } from "./GoalConfigurationCalendar";
import { GoalConfigurationDateAssignmentModeForm } from "./GoalConfigurationDateAssignmentModeForm";

interface GoalAssignmentCalendarDateAssignmentProps {
  form: UseFormReturn<GoalConfigurationFormType>;
  mode: "create" | "edit" | "duplicate";
}

export const GoalAssignmentCalendarDateAssignment = ({ form, mode }: GoalAssignmentCalendarDateAssignmentProps) => {
  const { onChangeDate } = useMealPlanCalendarContext();

  const { watch } = form;
  const assignmentType = watch("assignments.type");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", pt: 1 }}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          minWidth: "400px",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <GoalConfigurationDateAssignmentModeForm form={form} mode={mode} />
        </Box>
        {assignmentType === "individualDates" && (
          <Box sx={{ width: "50%", height: "100%", minWidth: "400px" }}>
            <GoalDateAssignmentCalendar onClickCalendarDate={onChangeDate} form={form} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

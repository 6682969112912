import { IFileImport } from "../../../../universal/Import/Button";
import { loadCsv } from "../../../../../utils/import/load";
import { parseDelimitedFile } from "../../../../../utils/import/parse";
import { ImportableAnthroEntry } from "../types";
import { subYears } from "date-fns";

interface InbodyFileImportFields {
  "1. ID": string;
  "2. Height": string;
  "3. Gender": string;
  "5. Test Date / Time": string;
  "6. Weight": number;
  "7. TBW (Total Body Water)": number;
  "8. ICW (Intracellular Water)": number;
  "9. ECW (Extracellular Water)": number;
  "10. DLM (Dry Lean Mass)": number;
  "11. BFM (Body Fat Mass)": number;
  "12. LBM (Lean Body Mass)": number;
  "13. SMM (Skeletal Muscle Mass)": number;
  "14. BMI (Body Mass Index)": number;
  "15. PBF (Percent Body Fat)": number;
  "52. BFM of Trunk": number;
  "63. VFA (Visceral Fat Area)": number;
}

type InbodyFileImportFieldsRaw = {
  [key in keyof InbodyFileImportFields]: string;
};
export const msg = `
This imports from the InBody770's exported CSV file (inbody_770_data.csv). The file can be obtained via thumbdrive
`;
const numberFields = [
  {
    fields: ["6. Weight" as const],
    required: true,
    importableField: "weight" as const,
  },
  /*
  { fields: ['7. TBW (Total Body Water)' as const], required: true, importableField: 'totalBodyWater' as const, },
  { fields: ['8. ICW (Intracellular Water)' as const], required: true, importableField: 'intracellularBodyWater' as const, },
  { fields: ['9. ECW (Extracellular Water)' as const], required: true, importableField: 'extracellularBodyWater' as const, },
  */
  {
    fields: ["10. DLM (Dry Lean Mass)" as const],
    required: true,
    importableField: "dryLeanMass" as const,
  },
  {
    fields: ["11. BFM (Body Fat Mass)" as const],
    required: true,
    importableField: "bodyFatMass" as const,
  },
  {
    fields: ["12. LBM (Lean Body Mass)" as const],
    required: true,
    importableField: "leanBodyMass" as const,
  },
  {
    fields: ["13. SMM (Skeletal Muscle Mass)" as const],
    required: true,
    importableField: "skeletalMuscleMass" as const,
  },
  {
    fields: ["15. PBF (Percent Body Fat)" as const],
    required: true,
    importableField: "percentBodyFat" as const,
  },
  {
    fields: ["52. BFM of Trunk" as const],
    required: true,
    importableField: "trunkFat" as const,
  },
  {
    fields: ["63. VFA (Visceral Fat Area)" as const],
    required: true,
    importableField: "visceralFatArea" as const,
  },
];
const stringFields = [
  {
    fields: ["1. ID" as const],
    required: true,
    importableField: "inbodyUid" as const,
    transform: (uid: string) => uid.replace(">", "").replace("<", ""),
  },
  {
    fields: ["2. Height" as const],
    required: true,
    importableField: "height" as const,
    transform: (hgt: string) => {
      const hgtCsv = hgt.replace("ft. ", ",").replace("in.", ","); //6,04.5
      const inches = Number(hgtCsv.split(",")[0]) * 12 + Number(hgtCsv.split(",")[1]);
      return inches;
    },
  },
  // {field: 'Dept ID' as const, importableField: 'teamName' as const},
]; // More on 'as const': https://stackoverflow.com/a/55387357/3923962

const dateFields = [
  {
    fields: ["5. Test Date / Time" as const],
    required: true,
    importableField: "datetime" as const,
    //10.04.2019 06:08:36
  },
];
export const fields = [...numberFields, ...stringFields, ...dateFields];

export const InbodyMatchFields: (keyof ImportableAnthroEntry)[] = ["inbodyUid" as const, "datetime" as const];

type I = ImportableAnthroEntry;

export const loadInbodyRows = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  // type T = InbodyFileImportFields;
  type F = InbodyFileImportFieldsRaw;
  const rows = await loadCsv<F>({ file, onError });
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return parsedRows
    .filter(row => new Date(row.datetime) > subYears(new Date(), 5))
    .map(row => ({
      ...row,
      type: "bioelectricalImpedance",
      percentBodyFat: row.bodyFatMass ? (row.bodyFatMass / row.weight) * 100 : null,
    }));
};

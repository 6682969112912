import React, { useState } from "react";
import { IMatchResult, MatchType } from "../../../../utils/import/match";
import { Entity } from "../../../universal/SearchBar/type";
import { ICrudHandler } from "../types";
import ResultListGroup from "./Group";

interface ImportResultListContainerProps<T, A> {
  matches: IMatchResult<T, A>[];
  setMatches: (matches: IMatchResult<T, A>[]) => void;
  groupKey: keyof T | null;
  actionButtonName: string;
  onAction: (props: ICrudHandler<T, A>) => void;
  onMatch?: (match: IMatchResult<T, A>, idx: number) => void;
  existingRows?: (T & Entity)[];
  onChange: (row: T, type: MatchType, newRow: T) => void;
  allowShowImportRow?: boolean;
  disabled?: boolean;
}

const ImportResultListContainer = <T extends object, A extends object>({
  onAction,
  onMatch,
  actionButtonName,
  existingRows,
  matches,
  groupKey,
  onChange,
  setMatches,
  allowShowImportRow,
  disabled,
}: ImportResultListContainerProps<T, A>) => {
  const [focusedIndex, setFocusedIndex] = useState(0);

  type GroupedMatches = { [key: string]: IMatchResult<T, A>[] };
  const groupMatches = (matches: IMatchResult<T, A>[], groupKey: keyof T): GroupedMatches => {
    const groups = {} as GroupedMatches;
    return matches.reduce((currGroups, match) => {
      const key = String(match.row[groupKey]);
      if (!currGroups[key]) {
        return { ...currGroups, [key]: [match] };
      } else {
        return { ...currGroups, [key]: [...currGroups[key], match] };
      }
    }, groups);
  };

  const groupedMatches = groupKey && groupMatches(matches, groupKey);
  const groupNames = groupedMatches && (Object.keys(groupedMatches).sort() as string[]);

  return (
    <div>
      {groupNames && groupedMatches && groupKey ? (
        groupNames.map((groupName, index) => (
          <ResultListGroup
            key={groupName}
            disabled={disabled}
            setMatches={() => {}}
            onMatch={onMatch}
            matches={groupedMatches[groupName]}
            actionButtonName={actionButtonName}
            onAction={onAction}
            name={groupName || "All" /* If 'undefined', we defer to 'all'*/}
            existingRows={existingRows && existingRows.filter(r => String(r[groupKey]) === groupName)}
            onChange={onChange}
            allowShowImportRow={allowShowImportRow}
            focused={index === focusedIndex}
            onFocusNext={() => setFocusedIndex(index + 1)}
          />
        ))
      ) : matches.length > 0 ? (
        <ResultListGroup
          disabled={disabled}
          onMatch={onMatch}
          matches={matches}
          setMatches={setMatches}
          actionButtonName={actionButtonName}
          onAction={onAction}
          existingRows={existingRows}
          onChange={onChange}
          allowShowImportRow={allowShowImportRow}
          focused={true}
          onFocusNext={() => {}}
        />
      ) : null}
    </div>
  );
};

export default ImportResultListContainer;

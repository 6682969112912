import {
  addSelectionOptionsToMenuItem,
  getMenuItemOptionErrors,
  MenuItemChoiceForModal,
  MenuItemForModal,
  MenuItemWithSelectionOptions,
} from "../MenuItem/utils";
import { getNewMenuSelectionItemWithAppearance, MenuSelectionItemWithAppearance } from "../MenuSelection/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MenuItemFormFragment } from "../types";

export interface useMenuSelectionItemFormArgs {
  menuSelectionItemId: string | null;
  initMenuItem: MenuItemForModal;
  maxAmount: number | null;
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
  initAmount?: number;
  initSpecialRequests?: string | null;
  onDone: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  onChange?: (
    menuItemWithOptions: MenuItemWithSelectionOptions<MenuItemFormFragment>,
    amount: number,
    specialRequests: string | null
  ) => void;
  onError: (errors: string[]) => void;
}

export interface useMenuSelectionItemFormPayload {
  amount: number;
  specialRequests: string | null;
  menuItem: MenuItemForModal;
  onIncrement: () => void;
  onDecrement: () => void;
  onChangeSpecialRequests: (specialRequests: string | null) => void;
  onSubmit: () => void;
  getMenuItemChoiceOptionCallbacks: (menuItemChoice: MenuItemChoiceForModal) => MenuItemChoiceOptionCallbacks;
}

/**
 * Note: maxAmount arg should be result of considering all menu constraint
 *   For example, on a MealMenu considering MenuItemAppearance.maxAmount and MealMenuDiningStation.maxAmount
 * Aka result of calling ../MenuItem/utils#getMenuItemMaxAmount
 */
export const useMenuSelectionItemForm = ({
  menuSelectionItemId,
  initMenuItem,
  initAmount,
  initSpecialRequests,
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
  onDone,
  onChange,
  onError,
}: useMenuSelectionItemFormArgs): useMenuSelectionItemFormPayload => {
  const [amount, setAmount] = useState(initAmount || 1);
  const [specialRequests, setSpecialRequests] = useState(initSpecialRequests || null);
  const [menuItem, setMenuItem] = useState(initMenuItem);

  useEffect(() => {
    if (onChange) {
      onChange(menuItem, amount, specialRequests);
    }
  }, []);

  const handleChangeSpecialRequests = useCallback(
    (newSpecialRequests: string | null) => {
      setSpecialRequests(newSpecialRequests);
      if (onChange) {
        onChange(menuItem, amount, newSpecialRequests);
      }
    },
    [menuItem, amount, onChange]
  );

  const handleChangeAmount = useCallback(
    (newAmount: number) => {
      setAmount(newAmount);
      if (onChange) {
        onChange(menuItem, newAmount, specialRequests);
      }
    },
    [menuItem, specialRequests, onChange]
  );

  const handleChangeMenuItem = useCallback(
    (newMenuItem: MenuItemWithSelectionOptions<MenuItemFormFragment>) => {
      setMenuItem(newMenuItem);
      if (onChange) {
        onChange(newMenuItem, amount, specialRequests);
      }
    },
    [amount, specialRequests, onChange]
  );

  const onSubmit = useCallback(() => {
    const errors = getMenuItemOptionErrors(menuItem);
    if (errors.length === 0) {
      onDone(
        getNewMenuSelectionItemWithAppearance({
          menuSelectionItemId,
          menuItem,
          availableForOrder,
          allowSpecialRequests,
          maxAmount,
          specialRequests,
          amount,
          percentConsumed: 1,
        })
      );
    } else {
      onError(errors);
    }
  }, [menuSelectionItemId, menuItem, amount, specialRequests, maxAmount, onDone, onError, availableForOrder, allowSpecialRequests]);

  const onChangeChoice = (menuItemChoice: MenuItemChoiceForModal) => {
    handleChangeMenuItem({
      ...menuItem,
      choices: menuItem.choices.map(mic => (mic.id === menuItemChoice.id ? menuItemChoice : mic)),
    });
  };

  const onDecrement = useCallback(() => {
    handleChangeAmount(Math.max(amount - 1, 1));
  }, [amount, handleChangeAmount]);

  const onIncrement = useCallback(() => {
    if (maxAmount === null || amount < maxAmount) {
      handleChangeAmount(amount + 1);
    }
  }, [maxAmount, amount, handleChangeAmount]);

  return {
    amount,
    specialRequests,
    menuItem,
    onSubmit,
    onDecrement,
    onIncrement,
    onChangeSpecialRequests: handleChangeSpecialRequests,
    getMenuItemChoiceOptionCallbacks: menuItemChoice => getMenuItemChoiceOptionCallbacks(menuItemChoice, onChangeChoice),
  };
};

export interface useMenuSelectionItemEditFormArgs {
  maxAmount: number | null;
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
  onEdit: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  onError: (errors: string[]) => void;
  menuSelectionItem: MenuSelectionItemWithAppearance;
}

export interface useMenuSelectionItemEditFormPayload {
  menuItem: MenuItemForModal;
  onIncrement: () => void;
  onDecrement: () => void;
  onChangeSpecialRequests: (specialRequests: string | null) => void;
  getMenuItemChoiceOptionCallbacks: (menuItemChoice: MenuItemChoiceForModal) => MenuItemChoiceOptionCallbacks;
}

export const useMenuSelectionItemEditForm = ({
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
  onEdit,
  menuSelectionItem,
  onError,
}: useMenuSelectionItemEditFormArgs): useMenuSelectionItemEditFormPayload => {
  const menuItem = useMemo(
    () => addSelectionOptionsToMenuItem(menuSelectionItem.menuItem, menuSelectionItem.options),
    [menuSelectionItem.menuItem, menuSelectionItem.options]
  );

  const onStateChange = useCallback(
    (amount: number, newSpecialRequests?: string | null, newMenuItem?: MenuItemForModal) => {
      const errors = getMenuItemOptionErrors(menuItem);
      onEdit(
        getNewMenuSelectionItemWithAppearance({
          menuSelectionItemId: menuSelectionItem.id,
          menuItem: newMenuItem ?? menuItem,
          maxAmount,
          availableForOrder,
          allowSpecialRequests,
          specialRequests: newSpecialRequests ?? menuSelectionItem.specialRequests,
          amount,
          percentConsumed: menuSelectionItem.percentConsumed,
        })
      );
      if (errors.length === 0) {
        onError(errors);
      }
    },
    [
      menuSelectionItem.id,
      menuSelectionItem.specialRequests,
      menuSelectionItem.percentConsumed,
      maxAmount,
      availableForOrder,
      allowSpecialRequests,
      menuItem,
      onEdit,
      onError,
    ]
  );

  const onChangeSpecialRequests = useCallback(
    (specialRequests: string | null) => {
      onStateChange(menuSelectionItem.amount, specialRequests);
    },
    [menuSelectionItem.amount, onStateChange]
  );

  const onChangeChoice = (menuItemChoice: MenuItemChoiceForModal) => {
    onStateChange(menuSelectionItem.amount, menuSelectionItem.specialRequests, {
      ...menuItem,
      choices: menuItem.choices.map(mic => (mic.id === menuItemChoice.id ? menuItemChoice : mic)),
    });
  };

  const onDecrement = useCallback(() => {
    onStateChange(Math.max(menuSelectionItem.amount - 1, 1));
  }, [menuSelectionItem.amount, onStateChange]);

  const onIncrement = useCallback(() => {
    if (maxAmount === null || menuSelectionItem.amount < maxAmount) {
      onStateChange(menuSelectionItem.amount + 1);
    }
  }, [onStateChange, maxAmount, menuSelectionItem.amount]);

  return {
    menuItem,
    onDecrement,
    onIncrement,
    onChangeSpecialRequests,
    getMenuItemChoiceOptionCallbacks: menuItemChoice => getMenuItemChoiceOptionCallbacks(menuItemChoice, onChangeChoice),
  };
};

type MenuItemChoiceOptionCallbacks = MenuItemChoiceOptionCallbacks_RadioButton | MenuItemChoiceOptionCallbacks_Checkbox;

interface MenuItemChoiceOptionCallbacks_RadioButton {
  type: "radio_button";
  onChangeOption: (menuItemChoiceOptionId: string) => void;
  onIncrementOption: (menuItemChoiceOptionId: string) => void;
  onDecrementOption: (menuItemChoiceOptionId: string) => void;
}

interface MenuItemChoiceOptionCallbacks_Checkbox {
  type: "checkbox";
  onCheckOption: (menuItemChoiceOptionId: string, checked: boolean) => void;
  onIncrementOption: (menuItemChoiceOptionId: string) => void;
  onDecrementOption: (menuItemChoiceOptionId: string) => void;
}

const getMenuItemChoiceOptionCallbacks = (
  menuItemChoice: MenuItemChoiceForModal,
  onChangeChoice: (menuItemChoice: MenuItemChoiceForModal) => void
): MenuItemChoiceOptionCallbacks => {
  if (menuItemChoice.required && menuItemChoice.maxOptionsCount === 1) {
    return getMenuItemChoiceOptionCallbacks_RadioButton(menuItemChoice, onChangeChoice);
  } else {
    return getMenuItemChoiceOptionCallbacks_Checkbox(menuItemChoice, onChangeChoice);
  }
};

const getMenuItemChoiceOptionCallbacks_RadioButton = (
  menuItemChoice: MenuItemChoiceForModal,
  onChangeChoice: (menuItemChoice: MenuItemChoiceForModal) => void
): MenuItemChoiceOptionCallbacks_RadioButton => {
  const onChangeOption = (menuItemChoiceOptionId: string) => {
    onChangeChoice({
      ...menuItemChoice,
      options: menuItemChoice.options.map(o =>
        o.id !== menuItemChoiceOptionId
          ? { ...o, menuSelectionItemOption: null }
          : { ...o, menuSelectionItemOption: { amount: o.defaultAmount, percentConsumed: 1 } }
      ),
    });
  };

  const onIncrementOption = (menuItemChoiceOptionId: string) => {
    onChangeChoice({
      ...menuItemChoice,
      options: menuItemChoice.options.map(o =>
        o.id !== menuItemChoiceOptionId
          ? o
          : {
              ...o,
              menuSelectionItemOption: {
                amount: o.menuSelectionItemOption ? Math.min(o.menuSelectionItemOption.amount + 1, o.maxAmount) : 1,
                percentConsumed: 1,
              },
            }
      ),
    });
  };

  const onDecrementOption = (menuItemChoiceOptionId: string) => {
    onChangeChoice({
      ...menuItemChoice,
      options: menuItemChoice.options.map(o =>
        o.id !== menuItemChoiceOptionId
          ? o
          : {
              ...o,
              menuSelectionItemOption:
                !o.menuSelectionItemOption || o.menuSelectionItemOption.amount === 1
                  ? null
                  : {
                      amount: o.menuSelectionItemOption.amount - 1,
                      percentConsumed: 1,
                    },
            }
      ),
    });
  };
  return {
    type: "radio_button",
    onChangeOption,
    onIncrementOption,
    onDecrementOption,
  };
};

const getMenuItemChoiceOptionCallbacks_Checkbox = (
  menuItemChoice: MenuItemChoiceForModal,
  onChangeChoice: (menuItemChoice: MenuItemChoiceForModal) => void
): MenuItemChoiceOptionCallbacks_Checkbox => {
  const canIncrementMenuItemChoice = (menuItemChoiceOptionId: string): boolean => {
    const newSelectedOptionsCount = menuItemChoice.options.flatMap(o => {
      return o.menuSelectionItemOption || o.id === menuItemChoiceOptionId ? [o.menuSelectionItemOption] : [];
    }).length;
    if (menuItemChoice.maxOptionsCount) {
      return newSelectedOptionsCount <= menuItemChoice.maxOptionsCount;
    } else {
      return true;
    }
  };

  const onCheckOption = (menuItemChoiceOptionId: string, checked: boolean) => {
    if (checked && !canIncrementMenuItemChoice(menuItemChoiceOptionId)) {
      // Have to call state updating function even if operation is invalid
      // Or else IonCheckbox will become effectively uncontrolled component
      onChangeChoice(menuItemChoice);
    } else {
      const newOptions = menuItemChoice.options.map(o =>
        o.id !== menuItemChoiceOptionId
          ? o
          : {
              ...o,
              menuSelectionItemOption: checked ? { amount: o.defaultAmount, percentConsumed: 1 } : null,
            }
      );
      onChangeChoice({
        ...menuItemChoice,
        options: newOptions,
      });
    }
  };

  const onIncrementOption = (menuItemChoiceOptionId: string) => {
    if (!canIncrementMenuItemChoice(menuItemChoiceOptionId)) {
      // Have to call state updating function even if operation is invalid
      // Or else IonCheckbox will become effectively uncontrolled component
      onChangeChoice(menuItemChoice);
    } else {
      const newOptions = menuItemChoice.options.map(o =>
        o.id !== menuItemChoiceOptionId
          ? o
          : {
              ...o,
              menuSelectionItemOption: {
                amount: o.menuSelectionItemOption ? Math.min(o.menuSelectionItemOption.amount + 1, o.maxAmount) : o.defaultAmount,
                percentConsumed: 1,
              },
            }
      );
      onChangeChoice({
        ...menuItemChoice,
        options: newOptions,
      });
    }
  };

  const onDecrementOption = (menuItemChoiceOptionId: string) => {
    onChangeChoice({
      ...menuItemChoice,
      options: menuItemChoice.options.map(o =>
        o.id !== menuItemChoiceOptionId
          ? o
          : {
              ...o,
              menuSelectionItemOption:
                !o.menuSelectionItemOption || o.menuSelectionItemOption.amount === 1
                  ? null
                  : {
                      amount: o.menuSelectionItemOption.amount - 1,
                      percentConsumed: 1,
                    },
            }
      ),
    });
  };
  return {
    type: "checkbox",
    onCheckOption,
    onDecrementOption,
    onIncrementOption,
  };
};

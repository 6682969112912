import React from "react";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";
import { useMealMenusByIdsQuery } from "../../../types";
import { getMealMenuInstances } from "../utils";
import { BulkEditDialogProps } from "./types";
import BulkEditDialogContent from "./BulkEditDialogContent";

const BulkEditDialog = ({ mealMenuIds, clientTimezone, ...props }: BulkEditDialogProps) => {
  const { data, loading } = useMealMenusByIdsQuery({
    variables: {
      mealMenuIds,
    },
  });
  if (loading) {
    return <LoadingBackdrop open={props.isOpen} onClose={props.onBack} />;
  }
  const mealMenuInstances = getMealMenuInstances(data ? data.mealMenus : [], clientTimezone);
  return <BulkEditDialogContent
    {...props}
    mealMenuInstances={mealMenuInstances}
    clientTimezone={clientTimezone} />;
};

export default BulkEditDialog;

import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";

interface ClientCredentialsCreateDialogProps {
  open: boolean;
  onClose: () => void;
  onDone: (name: string) => void;
}

const ClientCredentialsCreateDialog = ({ open, onClose, onDone }: ClientCredentialsCreateDialogProps) => {
  const [name, setName] = useState("");

  const handleDone = () => {
    if (name) {
      onDone(name);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Create Credentials" onClose={onClose} />
      <DialogContent>
        <Typography variant="body2">
          Enter a name to remember this set of credentials by. This will be used by you to identify who uses this key.
        </Typography>
        <Typography variant="body2">
          After saving, you will receive a <strong>Client ID</strong> and <strong>Client Secret</strong> to use for API requests.
        </Typography>
        <TextField
          label="Name"
          value={name}
          onChange={e => setName(e.target.value)}
          inputProps={{
            "aria-label": "Name",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined">Cancel</Button>
        <Button onClick={handleDone}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientCredentialsCreateDialog;

import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import decamelize from "decamelize";
import React, { useState } from "react";
import { z } from "zod";
import { matchFieldsToText } from ".";
import { IMatchResult } from "../../../../utils/import/match";
import SearchBarDefault from "../../../universal/SearchBar/Default";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      fontWeight: 600,
      marginBottom: theme.spacing(0.5),
    },
    contentWrapper: {
      padding: theme.spacing(2, 0),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    recordWrapper: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(2, 0),
      gap: theme.spacing(2),
    },
    record: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
    },
    searchResultMenu: {
      width: "300px",
      zIndex: 99,
      position: "absolute",
    },
  })
);

interface ImportResultResolveDialogProps<
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
> {
  onContinue: (
    uniqueRows: Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>,
    matchedRows: Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>
  ) => void;
  onCancel: () => void;
  open: boolean;
  allRows: Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>;
  invalidRows: Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>;
  matchableRows: (ImportableEntity & LinkEntityFields & AdditionalDisplayFields & { id: string })[];
  groupKey: keyof (ImportableEntity & LinkEntityFields) | null;
}

export const ImportResultResolveDialog = <
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
>({
  onContinue,
  onCancel,
  open,
  allRows,
  invalidRows,
  matchableRows,
  groupKey,
}: ImportResultResolveDialogProps<LinkFields, ImportableEntity, LinkEntityFields, AdditionalDisplayFields>) => {
  const theme = useTheme();
  const classes = useStyles();
  const [duplicates, setDuplicates] = useState<Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>>([]);

  const rowsWithMatches = allRows.filter(row => row.matchedRows.length > 0);

  type GroupedMatches = Record<string, (ImportableEntity & LinkEntityFields & AdditionalDisplayFields & { id: string })[]>;
  const groupMatches = (
    matches: (ImportableEntity & LinkEntityFields & AdditionalDisplayFields & { id: string })[],
    groupKey: keyof (ImportableEntity & LinkEntityFields)
  ): GroupedMatches => {
    const groups = {} as GroupedMatches;
    return matches.reduce((currGroups, match) => {
      const key = String(match[groupKey]);
      if (!currGroups[key]) {
        return { ...currGroups, [key]: [match] };
      } else {
        return { ...currGroups, [key]: [...currGroups[key], match] };
      }
    }, groups);
  };

  const groupedMatches = groupKey && groupMatches(matchableRows, groupKey);

  const handleClose: DialogProps["onClose"] = (_, reason) => {
    if (reason !== "backdropClick") {
      onCancel();
    }
  };

  const getErrorText = (error?: z.ZodError): React.ReactNode => {
    return error ? error.errors.map(err => `Incorrect ${decamelize(String(err.path[0]), " ")}`).join(", ") : "";
  };

  const confirmDuplicate = (row: IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>) => {
    setDuplicates([...duplicates, row]);
  };

  const handleContinue = () => {
    const uniques = allRows
      .filter(row => {
        return !duplicates.some(dupe => matchFieldsToText(dupe.matchFields, dupe.row) === matchFieldsToText(row.matchFields, row.row));
      })
      .map(row => {
        row.matchedRows = [];
        return row;
      });

    onContinue(uniques, duplicates);
  };

  return (
    <>
      <Dialog
        maxWidth={"md"}
        onClose={handleClose}
        open={open}>
        <DialogTitle title="Resolve Records" onClose={onCancel} />
        <DialogContent>
          <div className={classes.contentWrapper}>
            {invalidRows.length > 0 && (
              <div>
                <Typography className={classes.subtitle}>Incorrect Information</Typography>
                <Typography color="textSecondary" variant="body2">
                  The following records have missing or incorrect information on file. Refer to the key on the previous screen for the
                  correct format. <b>Re-import the file with the correct format to resolve this issue.</b>
                </Typography>
                <div className={classes.recordWrapper}>
                  {invalidRows.map(({ row, matchFields, error }) => (
                    <div className={classes.record}>
                      <Typography>
                        {matchFieldsToText(matchFields, row)}-{" "}
                        <span style={{ color: theme.palette.error.main }}>{getErrorText(error)}</span>
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {rowsWithMatches.length > 0 && (
              <div>
                <Typography className={classes.subtitle}>Potential Duplicates Found</Typography>
                <Typography color="textSecondary" variant="body2">
                  Match or look up the name for potential duplicates. Leaving the record as is will get imported as a new record.
                </Typography>
                <div className={classes.recordWrapper}>
                  {rowsWithMatches.map(row => {
                    const duplicate = duplicates.find(dupe => {
                      return matchFieldsToText(dupe.matchFields, dupe.row) === matchFieldsToText(row.matchFields, row.row);
                    });

                    const identifiableText = matchFieldsToText(row.matchFields, row.row);

                    if (duplicate) {
                      const duplicateText = matchFieldsToText(duplicate.matchFields, duplicate.matchedRows[0]);

                      return (
                        <div className={classes.record}>
                          <Box display={"flex"} flexDirection={"column"}>
                            <Typography>New: {identifiableText}</Typography>
                            <Typography color="error">Existing: {duplicateText}</Typography>
                          </Box>
                        </div>
                      );
                    }

                    let searchableMatches: (ImportableEntity &
                      LinkEntityFields &
                      AdditionalDisplayFields & {
                        id: string;
                      })[] = [];

                    if (groupKey && row.row[groupKey] && groupedMatches) {
                      searchableMatches = groupedMatches[String(row.row[groupKey])];
                    }

                    return (
                      <div className={classes.record}>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}>
                          <div>
                            <Typography>{identifiableText}</Typography>
                            <Typography color="error">Potential duplicate found: {identifiableText}</Typography>
                          </div>
                          <Button onClick={() => confirmDuplicate(row)}>Match Duplicate</Button>
                        </Box>
                        {searchableMatches && Boolean(searchableMatches.length) && (
                          <Box paddingTop={1}>
                            <SearchBarDefault
                              menuClassName={classes.searchResultMenu}
                              fullWidth
                              objects={searchableMatches}
                              objectToName={r => matchFieldsToText(row.matchFields, r)}
                              onSelectObject={r => {
                                const newMatchResult = {
                                  row: row.row,
                                  matchFields: row.matchFields,
                                  type: "one" as const,
                                  matchedRows: [r],
                                };
                                confirmDuplicate(newMatchResult);
                              }}
                              label="Match to Existing Record"
                            />
                          </Box>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={handleContinue}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { TextField } from "@mui/material";
import React, { useState } from "react";
import { MenuItemSuggestionCategory } from "../../../../types";
import SuggestionCategoryDialog from "./Dialog";
import { getSuggestionCategoryShortLabel } from "./utils";

interface MenuItemSuggestionCategorySelectProps {
  value: MenuItemSuggestionCategory | null;
  onChange?: (value: MenuItemSuggestionCategory | null) => void;
  disabled?: boolean;
}

export const MenuItemSuggestionCategorySelect = ({ value, onChange, disabled }: MenuItemSuggestionCategorySelectProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <TextField
        type="text"
        label="Category"
        value={getSuggestionCategoryShortLabel(value)}
        onClick={() => setDialogOpen(true)}
        disabled={disabled}
        inputProps={{
          style: {
            caretColor: "transparent",
          },
        }}
      />
      {dialogOpen && onChange && (
        <SuggestionCategoryDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          value={value}
          onDone={onChange} />
      )}
    </>
  );
};

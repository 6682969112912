import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { LinkedProfile } from "@notemeal/profile-sync";
import React, { useReducer } from "react";
import { ChangeContactInfoContent } from "../ChangeContactInfoContent";
import { contactInfoReducer, getInitState } from "../updateContactInfoReducer";

interface UnlinkArchiveProfileDialogProps {
  open: boolean;
  onClose: () => void;
  profileToUnlink: LinkedProfile;
  onUnlinkArchive: (profile: LinkedProfile, newEmail?: string, newPhone?: string) => void;
}

export const UnlinkArchiveProfileDialog = ({ open, onClose, profileToUnlink, onUnlinkArchive }: UnlinkArchiveProfileDialogProps) => {
  const [state, dispatch] = useReducer(contactInfoReducer, getInitState());

  const profileName = `${profileToUnlink.teamworks.firstName} ${profileToUnlink.teamworks.lastName}`;
  const currentEmail = profileToUnlink.teamworks.email;
  const currentPhone = profileToUnlink.teamworks.cellPhone;

  const handleUnlinkArhive = () => {
    if (profileToUnlink.notemeal && !state.emailError && !state.phoneError) {
      // check email/phone number exist for other users
      onUnlinkArchive(profileToUnlink, state.newEmail ?? undefined, state.newPhone ?? undefined);
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle>Unlink Teamworks Profile and Deactivate Nutrition Account</DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            <Typography variant="h4">{profileName}</Typography>
            <Typography>
              {currentEmail}, {currentPhone}
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="body2">
          Are you sure you want to unlink the Teamworks profile for <strong>{profileName}</strong> and deactivate their Nutrition account?
          The mobile app account will be deactivated and the athlete will archived and hidden from the team roster. The athlete is still
          searchable and nothing will be deleted. Note, however, if the user is tied to any other org memberships, deactivating the account
          will affect login access to these orgs as well.
        </Typography>
        <Typography variant="body2">
          You must also change the email associated with the Nutrition account to prevent future syncing and collisions. If no phone number
          is provided, the existing phone number (if exists) for the Nutrition account will be removed.
        </Typography>
        <ChangeContactInfoContent
          currentEmail={currentEmail}
          currentPhone={currentPhone}
          state={state}
          dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button disabled={!!state.phoneError || !!state.emailError} onClick={handleUnlinkArhive}>
          Unlink and Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

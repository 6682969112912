import { ReactNode } from "react";

import { Box, SxProps } from "@mui/material";
import { genericSort } from "@notemeal/utils/sort";

type HasId = { id: string };

interface ScheduleContainerProps<M extends HasId, A extends HasId> {
  sx?: SxProps;
  meals: readonly M[];
  activities: readonly A[];
  mealToStart: (m: M) => string;
  activityToStart: (a: A) => string;
  renderMeal: (meal: M) => ReactNode;
  renderActivity: (activity: A) => ReactNode;
}

const ScheduleContainer = <M extends HasId, A extends HasId>(props: ScheduleContainerProps<M, A>) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...props.sx }}>
      {sortedScheduleComponents(props).map(({ component, id }) => (
        <Box key={id}>{component}</Box>
      ))}
    </Box>
  );
};

export const sortedScheduleComponents = <M extends HasId, A extends HasId>({
  meals,
  activities,
  mealToStart,
  activityToStart,
  renderMeal,
  renderActivity,
}: ScheduleContainerProps<M, A>) => {
  const mealComponents = meals.map(m => ({
    component: renderMeal(m),
    start: mealToStart(m),
    id: m.id,
  }));
  const activityComponents = activities.map(a => ({
    component: renderActivity(a),
    start: activityToStart(a),
    id: a.id,
  }));
  return [...mealComponents, ...activityComponents].sort((a, b) => genericSort(a.start, b.start));
};

export default ScheduleContainer;

import React, { createContext, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useSnackbar } from "../components/Snackbar/SnackbarContext";
import { NAV_ROOT } from "../pages/Auth/AuthPaths";

export type MultiTabEvent = "switched-orgs";

interface MultiTabManagerContextValue {
  emitMultiTabEvent: (event: MultiTabEvent) => void;
}

const MultiTabManagerContext = createContext<MultiTabManagerContextValue>({
  emitMultiTabEvent: () => {},
});

interface MultiTabManagerProviderProps {
  children: React.ReactNode;
}

export const MultiTabManagerProvider = ({ children }: MultiTabManagerProviderProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const channel = new BroadcastChannel("tw_nutrition_tab_manager");
  const { setMessage } = useSnackbar();
  const navigate = useNavigate();

  const handleEvent = useCallback(
    (event: MultiTabEvent) => {
      switch (event) {
        case "switched-orgs":
          navigate(NAV_ROOT);
          setMessage("info", "You've been navigated to the home page because you've switched organizations in another tab.");
          break;
        default:
          break;
      }
    },
    [navigate, setMessage]
  );

  useEffect(() => {
    channel.onmessage = event => {
      if (event.isTrusted && window.location.origin === event.origin) {
        handleEvent(event.data);
      }
    };

    return () => channel.close();
  }, [channel, handleEvent]);

  const emitMultiTabEvent = (event: MultiTabEvent) => {
    channel.postMessage(event);
  };

  return <MultiTabManagerContext.Provider value={{ emitMultiTabEvent }}>{children}</MultiTabManagerContext.Provider>;
};

export const useMultiTabManager = () => {
  return useContext(MultiTabManagerContext);
};

import React from "react";
import BaseAsyncAutoComplete, { BasicOption } from "../../../../components/universal/BaseAsyncAutocomplete";
import { CuisineFragment, useStaffDashboardCuisinesQuery } from "../../../../types";

const useSearchQuery = (query: string) => {
  const { data, loading } = useStaffDashboardCuisinesQuery({
    variables: { input: { limit: 25, offset: 0 }, query },
  });
  const options = data ? [...data.cuisineOffsetConnection.edges] : undefined;
  return {
    options,
    loading,
  };
};

interface RestaurantMenuFormCuisinesProps {
  cuisines: readonly CuisineFragment[];
  onChange: (cuisines: CuisineFragment[]) => void;
}

const RestaurantMenuFormCuisines = ({ cuisines, onChange }: RestaurantMenuFormCuisinesProps) => {
  const selectedCuisinesIds = cuisines.map(cuisine => cuisine.id);
  const filterUsedOptions = (options: CuisineFragment[] | undefined) => {
    if (options === undefined) {
      return undefined;
    }
    return options.filter(option => !selectedCuisinesIds.includes(option.id));
  };

  return (
    <BaseAsyncAutoComplete
      useSearchQuery={useSearchQuery}
      inputPlaceholder="Add Cuisine"
      noOptionsText="No Cuisines Found"
      handleChange={onChange}
      filterUsedOptions={filterUsedOptions}
      multiple
      renderOption={({ name }: CuisineFragment) => <BasicOption element={name} />}
      getOptionLabel={(pm: CuisineFragment) => pm.name}
      value={cuisines.concat()}
    />
  );
};

export default RestaurantMenuFormCuisines;

import React from "react";
import DisplayCard, { DisplayCardProps } from "../../shared/DisplayCard";

export interface OrgLike<TeamType> {
  id: string | number;
  name: string;
  teams: readonly TeamType[];
}

type OrgCardProps<T> = Omit<DisplayCardProps<T>, "primaryText" | "secondaryElement">;

const OrgCard = <TeamType, T extends OrgLike<TeamType>>(props: OrgCardProps<T>) => {
  const { element } = props;
  const primaryText = element.name;
  const secondaryElement = `${element.teams.length} teams`;
  return <DisplayCard
    {...props}
    primaryText={primaryText}
    secondaryElement={secondaryElement ?? "No Sport"} />;
};

export default OrgCard;

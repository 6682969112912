import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import ImageWithLockedAspectRatio from "./ImageWithLockedAspectRatio";

interface Props {
  imageUrl: string;
  onDismiss: () => void;
  disabled?: boolean;
}

const styles = {
  iconButton: {
    color: "white",
    backgroundColor: "black",
    opacity: 0.6,
    "&:hover": {
      color: "white",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  },
  buttonContainer: {
    position: "absolute",
    top: (theme: Theme) => theme.spacing(1),
    right: (theme: Theme) => theme.spacing(1),
  },
};

const ImageWithDismiss = ({ imageUrl, onDismiss, disabled }: Props) => {
  const { spacing } = useTheme();

  return (
    <Box position="relative">
      <Box>
        <ImageWithLockedAspectRatio imageUrl={imageUrl} borderRadius={spacing(0.5)} />
      </Box>
      <Box sx={styles.buttonContainer}>
        <IconButton disabled={disabled} sx={styles.iconButton} onClick={onDismiss} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ImageWithDismiss;

import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel } from "@mui/material";
import { sortByKey } from "@notemeal/utils/sort";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";
import { FeatureFlagsFragment, useEditOrgFeatureFlagsMutation } from "../../../types";

interface OrgFeatureFlagsDialogProps {
  open: boolean;
  onClose: () => void;
  orgId: string;
  featureFlags: FeatureFlagsFragment;
  hasTeamworksIdWithSuperuser: boolean;
}

export const OrgFeatureFlagsDialog = ({ open, onClose, orgId, featureFlags, hasTeamworksIdWithSuperuser }: OrgFeatureFlagsDialogProps) => {
  const [state, setState] = useState<FeatureFlagsFragment>(featureFlags);
  const { setMessage } = useSnackbar();
  const [editOrgFeatureFlags, { loading }] = useEditOrgFeatureFlagsMutation({
    onCompleted: onClose,
    onError: () => {
      setMessage("error", "Something went wrong, operation failed.");
      onClose();
    },
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          id: `Org:${orgId}`,
          fields: {
            featureFlags: () => data?.editOrgFeatureFlags.featureFlags,
          },
        });
      }
    },
  });

  const handleSave = () => {
    editOrgFeatureFlags({
      variables: {
        input: {
          orgId,
          featureFlags: {
            athleteMenuSuggestions: state.athleteMenuSuggestions,
            clientCredentials: state.clientCredentials,
            hasRestaurantMenus: state.hasRestaurantMenus,
            restaurantLogging: state.restaurantLogging,
            foodCategory: state.foodCategory,
            orgGroupRecipes: state.orgGroupRecipes,
            orgGroupFoods: state.orgGroupFoods,
            performanceKitchen: state.performanceKitchen,
            printableTags: state.printableTags,
            hubMenuCheckIn: state.hubMenuCheckIn,
            dietitianOnlyMenus: state.dietitianOnlyMenus,
            bulkOrdering: state.bulkOrdering,
            ukMealPlanOptionSuggestions: state.ukMealPlanOptionSuggestions,
            digitalDisplays: state.digitalDisplays,
            selfServiceOnboarding: state.selfServiceOnboarding,
            messaging: state.messaging,
            eatingDisorderScreens: state.eatingDisorderScreens,
            profilesV3Api: state.profilesV3Api,
          },
        },
      },
    });
  };

  if (loading) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const flagInfoList: FlagLabelInfo[] = [
    { flag: "athleteMenuSuggestions", displayName: "Athlete Menu Suggestions" },
    { flag: "clientCredentials", displayName: "Client Credentials" },
    { flag: "hasRestaurantMenus", displayName: "Catering Restaurant Menus" },
    { flag: "restaurantLogging", displayName: "Restaurant Logging" },
    { flag: "foodCategory", displayName: "Food Category" },
    { flag: "orgGroupRecipes", displayName: "Org Group Recipes" },
    { flag: "orgGroupFoods", displayName: "Org Group Foods" },
    { flag: "performanceKitchen", displayName: "Performance Kitchen" },
    { flag: "printableTags", displayName: "Printable Tags" },
    { flag: "dietitianOnlyMenus", displayName: "Dietitian Only Menus" },
    { flag: "bulkOrdering", displayName: "Dietitian Bulk Ordering" },
    { flag: "ukMealPlanOptionSuggestions", displayName: "UK Meal Plan Option Suggestions" },
    { flag: "digitalDisplays", displayName: "Digital Displays Export" },
    { flag: "selfServiceOnboarding", displayName: "User Self Service Onboarding" },
    { flag: "messaging", displayName: "Messaging" },
    { flag: "eatingDisorderScreens", displayName: "Eating Disorder Screen For Athletes" },
    { flag: "profilesV3Api", displayName: "Profiles V3 API" },
  ];

  if (hasTeamworksIdWithSuperuser) {
    flagInfoList.push({ flag: "hubMenuCheckIn", displayName: "Hub Kiosk Menu Checkin" });
  }

  const sortedFlagsInfoList = sortByKey(flagInfoList, "displayName");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Edit Feature Flags" onClose={onClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        {sortedFlagsInfoList.map(flagInfo => (
          <FeatureFlagFormControlLabel
            key={flagInfo.flag}
            flags={state}
            onChangeFlags={setState}
            {...flagInfo} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

interface FlagLabelInfo {
  flag: keyof FeatureFlagsFragment;
  displayName: string;
}

interface FeatureFlagFormControlLabelProps extends FlagLabelInfo {
  flags: FeatureFlagsFragment;
  onChangeFlags: (value: FeatureFlagsFragment) => void;
}

const FeatureFlagFormControlLabel = ({ flags, onChangeFlags, flag, displayName }: FeatureFlagFormControlLabelProps) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={flags[flag]} onChange={(_, v) => onChangeFlags({ ...flags, [flag]: v })} />}
      label={displayName}
    />
  );
};

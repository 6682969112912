import React from "react";
import { NewTeamState } from "./reducer";
import BaseTeamDialog, { BaseTeamDialogProps } from "./BaseTeamDialog";

interface TeamCreateDialogProps extends Omit<BaseTeamDialogProps, "onConfirm" | "title" | "notemealTeam"> {
  onConfirm: (payload: Omit<NewTeamState, "__type">) => void;
  titleOverride?: string;
}

const TeamCreateDialog = ({ onConfirm, titleOverride, ...restProps }: TeamCreateDialogProps) => {
  const { teamworksTeam } = restProps;
  return (
    <BaseTeamDialog
      {...restProps}
      title={titleOverride ?? `Create ${teamworksTeam.name} (TW)`}
      onConfirm={({ name, sportState, gender }) =>
        onConfirm({
          teamworksId: teamworksTeam.id,
          teamworksSportName: teamworksTeam.sport,
          gender,
          name,
          ...sportState,
          sportName: sportState.sportState?.name ?? sportState.sportState?.name ?? null,
        })
      }
    />
  );
};

export default TeamCreateDialog;

import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { LipidNutrientGroup } from "@notemeal/shared/ui/NutrientAmount/types";
import { sumAmountsForNutrient } from "@notemeal/shared/ui/NutrientAmount/utils";
import React from "react";
import NutrientTreeItem from "./NutrientTreeItem";
import { CloseSquare, MinusSquare, PlusSquare } from "./StyledTreeItem";
import { generateTreeStyles } from "./styles";

const useStyles = makeStyles((theme: Theme) => createStyles(generateTreeStyles(theme.palette.accents.orangeDark[50], theme)));

export interface LipidTreeProps {
  lipids: LipidNutrientGroup;
  displayDri: boolean;
}

const LipidTree = ({ lipids, displayDri }: LipidTreeProps) => {
  const classes = useStyles();

  const omega3 = sumAmountsForNutrient("Omega-3", lipids["DHA"], [lipids["DPA"], lipids["EPA"]]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Lipids
      </Typography>
      <div className={classes.content}>
        <TreeView
          className={classes.tree}
          defaultExpanded={["1"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <NutrientTreeItem
            isRoot={true}
            nodeId="1"
            displayDri={displayDri}
            nutrientAmount={lipids["Fat"]}>
            <NutrientTreeItem
              nodeId="2"
              displayDri={displayDri}
              nutrientAmount={lipids["Monounsaturated Fat"]} />
            <NutrientTreeItem
              nodeId="3"
              displayDri={displayDri}
              nutrientAmount={lipids["Polyunsaturated Fat"]}>
              <NutrientTreeItem
                nodeId="4"
                displayDri={displayDri}
                nutrientAmount={omega3}>
                <NutrientTreeItem
                  nodeId="5"
                  displayDri={displayDri}
                  nutrientAmount={lipids["ALA"]} />
                <NutrientTreeItem
                  nodeId="6"
                  displayDri={displayDri}
                  nutrientAmount={lipids["EPA"]} />
                <NutrientTreeItem
                  nodeId="7"
                  displayDri={displayDri}
                  nutrientAmount={lipids["DPA"]} />
                <NutrientTreeItem
                  nodeId="8"
                  displayDri={displayDri}
                  nutrientAmount={lipids["DHA"]} />
                <NutrientTreeItem
                  nodeId="9"
                  displayDri={displayDri}
                  nutrientAmount={lipids["Linoleic Acid"]} />
              </NutrientTreeItem>
            </NutrientTreeItem>
            <NutrientTreeItem
              nodeId="10"
              displayDri={displayDri}
              nutrientAmount={lipids["Saturated Fat"]} />
            <NutrientTreeItem
              nodeId="11"
              displayDri={displayDri}
              nutrientAmount={lipids["Trans Fat"]} />
          </NutrientTreeItem>
          <NutrientTreeItem
            isRoot={true}
            nodeId="12"
            displayDri={displayDri}
            nutrientAmount={lipids["Cholesterol"]} />
        </TreeView>
      </div>
    </div>
  );
};

export default LipidTree;

import { AddAndLinkTeamsState, NotemealTeam, PendingTeam, TeamworksTeam } from "./reducer";

interface MergedTeams {
  notemealTeams: NotemealTeam[];
  teamworksTeams: TeamworksTeam[];
  previousLinkedTeams: NotemealTeam[];
  pendingTeams: PendingTeam[];
}

export const mergeTeams = (state: AddAndLinkTeamsState): MergedTeams => {
  const previousLinkedTeams = state.notemealTeams.filter(team => team.teamworksId !== null);

  const pendingTeams = [...state.addedTeams.map(t => ({ ...t, id: t.teamworksId })), ...state.linkedTeams];

  const pendingTeamByTeamworkId = pendingTeams.reduce((acc, pendingTeam) => {
    const existingList = acc[pendingTeam.teamworksId] ?? [];
    return {
      ...acc,
      [pendingTeam.teamworksId]: [...existingList, pendingTeam],
    };
  }, {} as Record<string, PendingTeam[]>);

  const usedNotemealIds = [...state.linkedTeams, ...previousLinkedTeams].map(t => t.id);

  const teamworksTeams = state.teamworksTeams.map(t => ({
    ...t,
    pendingTeams: pendingTeamByTeamworkId[t.id] ?? [],
  }));

  const notemealTeams = state.notemealTeams.filter(t => !usedNotemealIds.includes(t.id));

  return {
    previousLinkedTeams,
    pendingTeams,
    teamworksTeams,
    notemealTeams,
  };
};

import { TextField } from "@mui/material";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import { useIsEmailUniqueQuery, useIsPhoneNumberUniqueQuery } from "apps/web/src/types";
import React, { Dispatch } from "react";
import { ContactInfoAction, ContactInfoState } from "./updateContactInfoReducer";
import { validateEmail, validatePhone } from "./utils";

interface ChangeContactInfoContentProps {
  currentEmail: string | null;
  currentPhone: string | null;
  state: ContactInfoState;
  dispatch: Dispatch<ContactInfoAction>;
}

export const ChangeContactInfoContent = ({ currentEmail, currentPhone, state, dispatch }: ChangeContactInfoContentProps) => {
  const setEmailError = (error: string | null) => {
    dispatch({
      type: "SET_EMAIL_ERROR",
      payload: { error },
    });
  };
  const debouncedEmail = useDebounce(state.newEmail, 200);
  useIsEmailUniqueQuery({
    variables: { email: state.newEmail ?? "" },
    skip: debouncedEmail === "",
    fetchPolicy: "network-only",
    onCompleted: data => {
      validateEmail(state.newEmail ?? "", currentEmail, data.isEmailUnique, setEmailError);
    },
  });

  const setPhoneError = (error: string | null) => {
    dispatch({
      type: "SET_PHONE_ERROR",
      payload: { error },
    });
  };
  const debouncedPhone = useDebounce(state.newPhone, 200);
  useIsPhoneNumberUniqueQuery({
    variables: { phoneNumber: state.newPhone ?? "" },
    skip: debouncedPhone === "",
    fetchPolicy: "network-only",
    onCompleted: data => {
      validatePhone(state.newPhone ?? "", currentPhone, data.isPhoneNumberUnique, setPhoneError);
    },
  });

  return (
    <>
      <TWTooltip
        title="Email is required"
        subcopy="If no email is provided, the user's current email will be appended with '+unlinkedprofile' (e.g. abc+unlinkedprofile@example.com).
        
        If the user does not have a current email, a placeholder email will be stored using the user's first name and last name (e.g. firstlast+unlinkedprofile@example.com)."
      >
        <TextField
          label="Email"
          required
          error={!!state.emailError}
          helperText={state.emailError ?? ""}
          value={state.newEmail}
          fullWidth
          margin="dense"
          onChange={e => dispatch({ type: "SET_NEW_EMAIL", payload: { newEmail: e.target.value } })}
        />
      </TWTooltip>
      {currentPhone && (
        <TWTooltip
          title="Phone Number Optional"
          subcopy="If no phone number is provided, the phone number currently associated with this Nutrition account will be reset."
        >
          <TextField
            label="Phone Number"
            error={!!state.phoneError}
            helperText={state.phoneError ?? ""}
            value={state.newPhone}
            fullWidth
            margin="dense"
            onChange={e => dispatch({ type: "SET_NEW_PHONE", payload: { newPhone: e.target.value } })}
          />
        </TWTooltip>
      )}
    </>
  );
};

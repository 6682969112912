import { Checkbox, ListItem, ListItemIcon, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MenuItemChoiceForModal } from "@notemeal/shared/ui/MenuItem/utils";
import { useAthleteHighlightFromServingAmounts } from "@notemeal/shared/ui/utils/Foods/foods";
import classNames from "classnames";
import React from "react";
import ItemAmountButtons from "../universal/ItemAmountButtons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amountButtons: {
      padding: theme.spacing(),
    },
    redHighlight: {
      color: theme.palette.error.main,
      "&:focus": {
        color: theme.palette.error.light,
      },
    },
  })
);

interface ChoiceCheckboxItemProps {
  onIncrementOption: (menuItemChoiceOptionId: string) => void;
  onDecrementOption: (menuItemChoiceOptionId: string) => void;
  onCheckOption: (menuItemChoiceOptionId: string, checked: boolean) => void;
  mico: MenuItemChoiceForModal["options"][number];
}

const ChoiceCheckboxItem = ({ onIncrementOption, onDecrementOption, onCheckOption, mico }: ChoiceCheckboxItemProps) => {
  const classes = useStyles();
  const { servingAmounts } = mico;
  const { highlightType: highlightColor, reason } = useAthleteHighlightFromServingAmounts([...servingAmounts]);
  const highlightColorClass = classNames({
    [classes.redHighlight]: highlightColor === "Red",
  });

  const containsText = reason.length > 0 && highlightColor === "Red" ? `Contains: ${reason.join(", ")}` : undefined;

  if (mico.canEditAmount) {
    const canClick = mico.menuSelectionItemOption === null;

    return (
      <ListItem
        key={mico.id}
        button={canClick as any}
        onClick={() => {
          if (canClick) {
            onIncrementOption(mico.id);
          }
        }}
      >
        <ListItemIcon>
          <ItemAmountButtons
            onIncrement={() => onIncrementOption(mico.id)}
            onDecrement={() => onDecrementOption(mico.id)}
            className={classes.amountButtons}
            maxAmount={mico.maxAmount}
            amount={mico.menuSelectionItemOption?.amount}
          />
        </ListItemIcon>
        <ListItemText
          primary={mico.name}
          className={highlightColorClass}
          secondary={containsText} />
      </ListItem>
    );
  } else {
    const checked = mico.menuSelectionItemOption !== null;
    return (
      <ListItem
        key={mico.id}
        button
        onClick={() => onCheckOption(mico.id, !checked)}>
        <ListItemIcon>
          <Checkbox checked={checked} disableRipple />
        </ListItemIcon>

        <ListItemText
          primary={mico.name}
          className={highlightColorClass}
          secondary={containsText} />
      </ListItem>
    );
  }
};

export default ChoiceCheckboxItem;

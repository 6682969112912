import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DiscardChangesDialog from "../../../components/universal/DiscardChangesDialog";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";
import { PlannedMenuForm } from "./PlannedMenuForm";
import {
  PlannedMenuFormSchema,
  PlannedMenuFormType,
  plannedMenuFormDefaultValues,
  plannedMenuFormToCreateInput,
} from "./PlannedMenuFormSchema";
import { usePlannedMenuPlannedMenuMutation } from "./PlannedMenusFormProvider";

interface PlannedMenuCreateDialogProps {
  open: boolean;
  onClose: () => void;
}

export const PlannedMenuCreateDialog = ({ open, onClose }: PlannedMenuCreateDialogProps) => {
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const { createPlannedMenu, createPlannedMenuCalled } = usePlannedMenuPlannedMenuMutation();

  const form = useForm<PlannedMenuFormType>({
    defaultValues: plannedMenuFormDefaultValues(),
    resolver: zodResolver(PlannedMenuFormSchema),
    mode: "onChange",
  });

  const { isDirty, isValid } = form.formState;

  const handleClose = () => {
    if (isDirty) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const submit = (newMenu: PlannedMenuFormType) => {
    createPlannedMenu({ variables: { input: plannedMenuFormToCreateInput(newMenu) } });
  };

  return createPlannedMenuCalled ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      PaperProps={{
        sx: { width: "900px", height: "600px" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle title="Create Menu" onClose={handleClose} />
      <DialogContent>
        <PlannedMenuForm form={form} mode="create" />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={!isValid} onClick={form.handleSubmit(submit)}>
            Save
          </Button>
        </Box>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
    </Dialog>
  );
};

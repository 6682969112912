import { Button, Fade, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useRef, useState } from "react";
import { PossibleLocation } from "../../../../components/Map/types";
import { GoogleMapsAutocompleteStyles, placesLibraries } from "../../GeneralizedStaffContent/GoogleMapsUtilities";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapDiv: {
      flexGrow: 1,
      position: "relative",
    },
    map: {
      height: "100%",
      width: "100%",
    },
    selectedLocation: {
      position: "absolute",
      bottom: theme.spacing(2),
      width: 250,
      marginLeft: -125,
      left: "50%",
      zIndex: 10,
    },
    autocomplete: {
      boxSizing: `border-box`,
      border: `1px solid transparent`,
      width: `240px`,
      height: `32px`,
      padding: `0 12px`,
      borderRadius: `3px`,
      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.3)`,
      fontSize: `14px`,
      outline: `none`,
      textOverflow: `ellipses`,
      position: "absolute",
      left: "50%",
      marginLeft: "-120px",
      marginTop: theme.spacing(2),
    },
  })
);

interface DeliveryLocationAddDialogContentProps {
  googleMapsApiKey: string;
  onSelectLocation: (location: PossibleLocation) => void;
}

const DeliveryLocationAddDialogContent = ({ googleMapsApiKey, onSelectLocation }: DeliveryLocationAddDialogContentProps) => {
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: placesLibraries,
  });
  const [possibleLocation, setPossibleLocation] = useState<PossibleLocation | null>(null);

  const mapRef = useRef<google.maps.Map | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const onLoadMap = useCallback((map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    mapRef.current = map;
  }, []);
  const onUnmountMap = useCallback(() => {
    mapRef.current = null;
  }, []);
  const onBoundsChangedMap = useCallback(() => {
    const autocomplete = autocompleteRef.current;
    const map = mapRef.current;
    if (!autocomplete || !map) {
      return null;
    }
    autocomplete.setBounds(map.getBounds());
  }, []);

  const onLoadAutocomplete = useCallback((autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete;
  }, []);
  const onPlaceChangedAutocomplete = useCallback(() => {
    const autocomplete = autocompleteRef.current;
    if (!autocomplete) {
      return;
    }
    const place = autocomplete.getPlace();
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();
    const { place_id, name, formatted_address, website } = place;
    const map = mapRef.current;
    if (!lat || !lng || !map || !place_id || !name || !formatted_address) {
      return;
    }
    setPossibleLocation({
      lat,
      long: lng,
      googlePlaceId: place_id,
      name,
      formatted_address,
      website,
    });
    map.panTo({ lat, lng });
    map.setZoom(15);
  }, []);

  return (
    <div className={classes.mapDiv}>
      <GoogleMapsAutocompleteStyles />
      {isLoaded && (
        <GoogleMap
          mapContainerClassName={classes.map}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
          }}
          zoom={12}
          onLoad={onLoadMap}
          onUnmount={onUnmountMap}
          onBoundsChanged={onBoundsChangedMap}
        >
          <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChangedAutocomplete}>
            <input
              type="text"
              placeholder="Search for Location"
              className={classes.autocomplete} />
          </Autocomplete>
          {possibleLocation && (
            <Marker
              position={{
                lat: possibleLocation.lat,
                lng: possibleLocation.long,
              }}
              title={possibleLocation.name}
            />
          )}
        </GoogleMap>
      )}

      {possibleLocation && (
        <Fade in={!!possibleLocation} timeout={1000}>
          <Button className={classes.selectedLocation} onClick={() => onSelectLocation(possibleLocation)}>
            Use Selected Location?
          </Button>
        </Fade>
      )}
    </div>
  );
};

export default DeliveryLocationAddDialogContent;

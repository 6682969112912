import { MutationFunctionOptions } from "@apollo/client";
import { getNavOrgMenuBuilder } from "apps/web/src/pages/Auth/Org/MenuBuilder/MenuBuilderPaths";
import {
  CreatePlannedMenuInput,
  CreatePlannedMenuMutation,
  DuplicatePlannedMenuInput,
  DuplicatePlannedMenuMutation,
  EditPlannedMenuInput,
  EditPlannedMenuMutation,
  Exact,
  useCreatePlannedMenuMutation,
  useDuplicatePlannedMenuMutation,
  useEditPlannedMenuMutation,
} from "apps/web/src/types";
import React, { ReactNode, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

type PlannedMenusFormContextValue = {
  createPlannedMenu: (
    options?:
      | MutationFunctionOptions<
          CreatePlannedMenuMutation,
          Exact<{
            input: CreatePlannedMenuInput;
          }>
        >
      | undefined
  ) => void;
  createPlannedMenuCalled: boolean;
  editPlannedMenu: (
    options?:
      | MutationFunctionOptions<
          EditPlannedMenuMutation,
          Exact<{
            input: EditPlannedMenuInput;
          }>
        >
      | undefined
  ) => void;
  editPlannedMenuCalled: boolean;
  duplicatePlannedMenu: (
    options?:
      | MutationFunctionOptions<
          DuplicatePlannedMenuMutation,
          Exact<{
            input: DuplicatePlannedMenuInput;
          }>
        >
      | undefined
  ) => void;
  duplicatePlannedMenuCalled: boolean;
};

const PlannedMenusFormContext = createContext<PlannedMenusFormContextValue>({
  createPlannedMenu: async () => {},
  createPlannedMenuCalled: false,
  editPlannedMenu: async () => {},
  editPlannedMenuCalled: false,
  duplicatePlannedMenu: async () => {},
  duplicatePlannedMenuCalled: false,
});

interface PlannedMenusFormProviderProps {
  children: ReactNode;
  onClose: (menuName?: string) => void;
}

export const PlannedMenusFormProvider = ({ children, onClose }: PlannedMenusFormProviderProps) => {
  const navigate = useNavigate();
  const [createPlannedMenu, { called: createPlannedMenuCalled }] = useCreatePlannedMenuMutation({
    onCompleted: data => {
      onClose(data.createPlannedMenu.plannedMenu.name);
      navigate(getNavOrgMenuBuilder(data.createPlannedMenu.plannedMenu.id));
    },
  });
  const [editPlannedMenu, { called: editPlannedMenuCalled }] = useEditPlannedMenuMutation({ onCompleted: () => onClose() });
  const [duplicatePlannedMenu, { called: duplicatePlannedMenuCalled }] = useDuplicatePlannedMenuMutation({ onCompleted: () => onClose() });

  return (
    <PlannedMenusFormContext.Provider
      value={{
        createPlannedMenu,
        createPlannedMenuCalled,
        editPlannedMenu,
        editPlannedMenuCalled,
        duplicatePlannedMenu,
        duplicatePlannedMenuCalled,
      }}
    >
      {children}
    </PlannedMenusFormContext.Provider>
  );
};

export const usePlannedMenuPlannedMenuMutation = () => {
  const state = useContext(PlannedMenusFormContext);
  return state;
};

import { ApolloClient, ApolloLink, DataProxy, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

import typeDefs from "@notemeal/shared/ui/graphql/typeDefs";
import resolvers from "@notemeal/shared/ui/graphql/resolvers";

import { usdaFoodLink } from "../usda";
import { nutritionixLink, nutritionixV1Link } from "./nutritionixLink";
import { errorLink } from "./errorLink";
import { retryLink } from "./retryLink";
import { cache } from "./cache";
import { apiLink } from "./apiLink";

const spiltNutritionixLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    const useNutritionixV1 = ["NutritionixV1RestaurantSearch"].includes(definition.name?.value || "");
    return useNutritionixV1;
  },
  nutritionixV1Link,
  nutritionixLink
);

const splitRestLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    const useUsdaLink = ["USDAFoods", "USDAFoodDetails"].includes(definition.name?.value || "");
    return useUsdaLink;
  },
  usdaFoodLink,
  spiltNutritionixLink
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, retryLink, splitRestLink, apiLink]),
  cache,
  typeDefs,
  resolvers,
});

export default client;

export const safeReadQuery = <TData, TVariables = any>(
  dataProxy: DataProxy,
  { query, variables }: DataProxy.Query<TVariables, TData>
): TData | null | undefined => {
  try {
    return dataProxy.readQuery<TData, TVariables>({ query, variables });
  } catch (err) {
    return undefined;
  }
};

import { CardProps, Theme } from "@mui/material";

export const muiCard = {
  styleOverrides: {
    root: ({
      theme: {
        palette: { greyscale, boxShadowExtraSmall },
      },
    }: {
      ownerState: CardProps;
      theme: Theme;
    }) => {
      return {
        borderColor: greyscale[100],
        borderRadius: "4px",
        boxShadow: boxShadowExtraSmall,
      };
    },
  },
};

import React from "react";
import { PaymentMethodFragment, usePaymentMethodCursorConnectionQuery } from "../../../../types";
import { BasicOption } from "../../../universal/BaseAsyncAutocomplete";
import BaseFilter from "./BaseFilter/BaseFilterWithInfiniteScroll";
import { baseEdgesAreEqual, baseGetKey, baseGetLabel, getBaseTransformAndFilter } from "./BaseFilter/utils";

interface PaymentMethodFilterProps {
  selectedPaymentMethods: PaymentMethodFragment[] | undefined;
  handleChange: (newValue: PaymentMethodFragment[] | null) => void;
  disabled?: boolean;
}

const PaymentMethodFilter = ({ selectedPaymentMethods, handleChange, disabled = false }: PaymentMethodFilterProps) => {
  const transformAndFilterDietOptions = getBaseTransformAndFilter(selectedPaymentMethods ?? []);

  return (
    <BaseFilter
      getLabel={baseGetLabel}
      getKey={baseGetKey}
      filterLabel={"Payment Method"}
      selectedFilters={selectedPaymentMethods ?? []}
      handleChange={filters => handleChange(filters.length === 0 ? null : filters)}
      disabled={disabled}
      queryKey="paymentMethodCursorConnection"
      useCursorConnectionQuery={usePaymentMethodCursorConnectionQuery}
      edgesAreEqual={baseEdgesAreEqual}
      renderOption={({ name }) => <BasicOption element={name} />}
      transformAndFilterOptions={transformAndFilterDietOptions}
    />
  );
};

export default PaymentMethodFilter;

import { Card, CardContent, Modal, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import { ReactNode, useState } from "react";
import ServingAmountViewChipList from "../../ServingAmount/View/ChipList";
import { ViewMealOptionFragment } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(4)})`,
      background: "white",
      display: "flex",
      flexDirection: "column",
    },
    contentRoot: {
      boxSizing: "border-box",
    },
    contentRootWithFooter: {
      paddingBottom: 0,
    },
    spacer: {
      flexGrow: theme.spacing(),
    },
    header: {
      marginTop: theme.spacing(-0.5),
      gridColumnStart: 1,
      gridRowStart: 1,
      overflow: "hidden",
    },
    note: {
      marginTop: theme.spacing(),
      color: theme.palette.text.secondary,
    },
    chipListOuter: {
      overflowY: "hidden",
      gridColumnStart: 1,
      gridRowStart: 2,
    },
    chipListInner: {},
    seeMoreTypography: {
      color: "blue",
      textAlign: "center",
      width: "100%",
      textDecoration: "underline",
      cursor: "pointer",
      marginBottom: theme.spacing(-1.5),
    },
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      padding: theme.spacing(2),
      maxHeight: "75%",
      width: "75%",
      overflowY: "scroll",
    },
  })
);

interface MealOptionViewProps {
  mealOption: ViewMealOptionFragment;
  targets?: ReactNode;
  footer?: ReactNode;
  className?: string;
}

const MealOptionView = ({ targets, mealOption, footer, className }: MealOptionViewProps) => {
  const classes = useStyles();
  const [outerEl, setOuterEl] = useState<HTMLElement | null>(null);
  const [innerEl, setInnerEl] = useState<HTMLElement | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const outerHeight = outerEl !== null ? outerEl.getBoundingClientRect().height : 0;
  const innerHeight = innerEl !== null ? innerEl.getBoundingClientRect().height : 0;

  const { servingAmounts } = mealOption;

  const servingChipsComponent = <ServingAmountViewChipList servingAmounts={servingAmounts} hideLimitedAccessWarning />;

  return (
    <Card className={classnames(classes.root, className)} raised>
      <CardContent
        classes={{
          root: classnames(classes.contentRoot, footer && classes.contentRootWithFooter),
        }}
      >
        {(targets || mealOption.note) && (
          <div className={classes.header}>
            {targets}
            <div className={classes.note}>
              <Typography variant="subtitle1">{mealOption.note}</Typography>
            </div>
          </div>
        )}
        <div className={classes.chipListOuter} ref={ref => setOuterEl(ref)}>
          <div className={classes.chipListInner} ref={ref => setInnerEl(ref)}>
            {servingChipsComponent}
          </div>
        </div>
        {outerHeight < innerHeight && (
          <Typography className={classes.seeMoreTypography} onClick={() => setModalOpen(true)} variant="body2">
            See All
          </Typography>
        )}
        {modalOpen && (
          <Modal open={modalOpen} onClose={() => setModalOpen(false)} className={classes.modal}>
            <Paper className={classes.paper}>{servingChipsComponent}</Paper>
          </Modal>
        )}
      </CardContent>
      <div className={classes.spacer} />
      {footer}
    </Card>
  );
};

export default MealOptionView;

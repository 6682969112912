import { TableCell, TableRow } from "@mui/material";
import { AthleteGoalConfigurationForCompliancePageFragment } from "apps/web/src/types";
import React from "react";

interface AthleteGoalComplianceHeaderRowProps {
  goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment;
}

export const AthleteGoalComplianceHeaderRow = ({ goalConfiguration }: AthleteGoalComplianceHeaderRowProps) => {
  const { target } = goalConfiguration;
  const hasTarget = target !== null;

  return (
    <TableRow key="header">
      <TableCell>Date</TableCell>
      <TableCell>Time</TableCell>
      {hasTarget ? (
        <>
          <TableCell>Input</TableCell>
          <TableCell>Target</TableCell>
          <TableCell>Unit</TableCell>
        </>
      ) : (
        <TableCell>Completion</TableCell>
      )}
    </TableRow>
  );
};

import React from "react";
import { NotemealTeam } from "../reducer";
import TeamsList from "./TeamsList";

interface NotemealTeamsListProps {
  selectedId: string | null;
  onLink: (team: NotemealTeam) => void;
  onCancel: () => void;
  teams: readonly NotemealTeam[];
}

const NotemealTeamsList = (props: NotemealTeamsListProps) => {
  const { selectedId } = props;

  return <TeamsList
    {...props}
    title="Unlinked Nutrition Teams"
    columnMode={selectedId ? "NoAction" : "Link"}
    disableOnSelected />;
};

export default NotemealTeamsList;

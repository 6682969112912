import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { TerritoryFragment } from "../../../types";
import { OrgMultiSelect } from "./OrgMultiSelect";

interface TerritoryDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string, orgIds: string[], removedOrgIds: string[]) => void;
  initialTerritory: TerritoryFragment;
}

export const EditTerritoryDialog = ({ open, onClose, onSave, initialTerritory }: TerritoryDialogProps) => {
  const [territoryName, setTerritoryName] = useState<string>(initialTerritory.name || "");
  const [selectedOrgIds, setSelectedOrgIds] = useState<string[]>(initialTerritory.orgs.map(({ id }) => id));

  const handleSave = () => {
    const removedOrgIds = initialTerritory.orgs.map(({ id }) => id).filter(id => !selectedOrgIds.includes(id));
    onSave(territoryName, selectedOrgIds, removedOrgIds);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title={`Edit Territory`} onClose={onClose} />
      <DialogContent>
        <TextField
          autoFocus
          value={territoryName}
          onChange={e => {
            setTerritoryName(e.target.value);
          }}
          placeholder="Territory"
        ></TextField>
        <OrgMultiSelect
          values={selectedOrgIds}
          onChange={setSelectedOrgIds}
          error={false} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

import { RestLink } from "apollo-link-rest";
import { UsdaFoodServingFragment, UsdaFoodNutrientFragment, UsdaFoodDetail } from "../types";
import { RawUsdaFood, RawUsdaFoodDetail, RawFoodNutrient, RawFoodPortion } from "./types";
import { NutrientName } from "./nutrients";

const stdDataType = (typ: "Branded" | "Survey (FNDDS)" | "SR Legacy" | "Foundation") => {
  switch (typ) {
    case "Survey (FNDDS)":
      return "survey";
    case "Foundation":
      return "foundation";
    case "SR Legacy":
      return "legacy";
    case "Branded":
      return "branded";
  }
};
type NutrientMap = { [key: string]: RawFoodNutrient };
const createNutrientMap = (nutrients: RawFoodNutrient[]): NutrientMap => {
  return nutrients.reduce((map, nxt) => ({ ...map, [nxt.nutrient.name]: nxt }), {} as NutrientMap);
};

interface GetServingsProps {
  choPer100g: number;
  proPer100g: number;
  fatPer100g: number;
  food: RawUsdaFoodDetail;
}

const getServings = ({ choPer100g, proPer100g, fatPer100g, food }: GetServingsProps): UsdaFoodServingFragment[] => {
  const { dataType, foodPortions, servingSize, servingSizeUnit, householdServingFullText } = food;
  if (dataType === "Branded") {
    if (servingSize && servingSizeUnit && servingSizeUnit.toLowerCase() === "g") {
      return [
        {
          __typename: "USDAFoodServing",
          idx: 1,
          cho: (choPer100g * servingSize) / 100,
          pro: (proPer100g * servingSize) / 100,
          fat: (fatPer100g * servingSize) / 100,
          weight: servingSize,
          units: householdServingFullText || servingSizeUnit,
          defaultAmount: 1,
          isDefault: true,
          usdaWeightSeq: "-1",
        },
      ];
    } else {
      return [];
    }
  } else {
    return coerceNullFoodPortions(foodPortions)
      .map(({ id, measureUnit, modifier, gramWeight, amount, portionDescription, sequenceNumber }, idx) => ({
        __typename: "USDAFoodServing" as const,
        idx,
        cho: choPer100g * gramWeight,
        pro: proPer100g * gramWeight,
        fat: fatPer100g * gramWeight,
        initialSize: amount || 1,
        weight: gramWeight,
        defaultAmount: amount || 1,
        units: (!!portionDescription ? portionDescription : modifier).replace("1 ", ""),
        isDefault: idx === 0,
        usdaWeightSeq: String(sequenceNumber),
      }))
      .filter(s => s.units !== "Quantity not specified");
  }
};

const coerceNullFoodPortions = (foodPortions?: Array<RawFoodPortion>): Array<RawFoodPortion> => {
  return foodPortions
    ? foodPortions
    : [
        {
          id: 0,
          measureUnit: {
            id: 0,
            name: "grams",
            abbreviation: "g",
          },
          portionDescription: "gram(s)",
          modifier: "",
          gramWeight: 1,
          amount: 1,
          sequenceNumber: 1,
        },
      ];
};

export const usdaFoodLink = new RestLink({
  endpoints: {
    v1: "https://api.nal.usda.gov/fdc/v1",
  },
  uri: "https://api.nal.usda.gov/fdc/v1",
  headers: {
    Accept: "application/json",
  },
  responseTransformer: async (response, typeName) => {
    switch (typeName) {
      case "[USDAFood]":
        return (response.json() as Promise<{ foods: RawUsdaFood[] }>).then(({ foods }) =>
          foods.map(({ allHighlightFields, dataType, ingredients, fdcId, ...rest }) => {
            return {
              allHighlightFields: (allHighlightFields || "").split(",").map((f: string) => f.trim()),
              ingredients: (ingredients || "").split(",").map((ing: string) => ing.trim()),
              id: fdcId,
              dataType: stdDataType(dataType),
              ...rest,
            };
          })
        );
      case "USDAFoodDetail":
        return (response.json() as Promise<RawUsdaFoodDetail>)
          .then((foodDetail): UsdaFoodDetail => {
            const { foodNutrients, fdcId, dataType } = foodDetail;
            const nutrMap = createNutrientMap(foodNutrients);
            const getNutr = (nutrientName: NutrientName): UsdaFoodNutrientFragment | null => {
              // To leverage the 'NutrientName' union type
              const rawNutr = nutrMap[nutrientName] || null;
              if (!rawNutr) {
                return null;
              }
              return {
                __typename: "USDAFoodNutrient",
                id: String(rawNutr.id),
                name: nutrientName,
                unitName: rawNutr.nutrient.unitName,
                max: rawNutr.max,
                median: rawNutr.median,
                min: rawNutr.min,
                footnote: rawNutr.footnote,
                dataPoints: rawNutr.dataPoints,
                amount: rawNutr.amount,
              };
            };
            const getNutrOrThrow = (nutrientName: NutrientName): UsdaFoodNutrientFragment => {
              const ret = getNutr(nutrientName);
              if (!ret) {
                throw new Error(`Nutrient Name ${nutrientName} not found for food with fdcId: ${fdcId}`);
              }
              return ret;
            };
            const payload = {
              usdaFdcFoodCategoryId: -1,
              usdaFdcDataType: dataType,
              foodNutrients: foodNutrients
                .filter(n => !!n.amount)
                .map(({ amount, dataPoints, max, min, median, footnote, nutrient: { id, name, unitName } }) => ({
                  __typename: "USDAFoodNutrient",
                  id: String(id),
                  amount,
                  dataPoints,
                  max,
                  min,
                  median,
                  name,
                  unitName,
                  footnote,
                })),
              id: String(fdcId),
              cho: getNutr("Carbohydrate, by difference") || getNutr("Carbohydrate, by summation") || getNutrOrThrow("Carbohydrate, other"),
              pro: getNutrOrThrow("Protein"),
              fat: getNutr("Total lipid (fat)") || getNutrOrThrow("Total fat (NLEA)"),
            };
            const ret = {
              ...payload,
              servings: getServings({
                food: foodDetail,
                choPer100g: payload.cho.amount,
                proPer100g: payload.pro.amount,
                fatPer100g: payload.fat.amount,
              }),
            };
            return ret;
          })
          .catch(e => {
            throw new Error(e.message);
          });
      default:
        throw new Error("Couldn't handle typeName " + typeName + " ...exiting.");
    }
  },
});

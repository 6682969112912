import { datadogRum } from "@datadog/browser-rum";
import { TableCell, TableRow } from "@mui/material";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useDeletePlannedMenuMutation } from "apps/web/src/types";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../../componentLibrary";
import TablePage from "../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../components/universal/TablePage/DefaultHeader";
import { PlannedMenuCreateDialog } from "./PlannedMenuCreateDialog";
import { PlannedMenuDuplicateDialog } from "./PlannedMenuDuplicateDialog";
import { PlannedMenuEditDialog } from "./PlannedMenuEditDialog";
import { PlannedMenuRows } from "./PlannedMenuRows";
import { usePlannedMenuTable } from "./PlannedMenuTableProvider";
import { PlannedMenusFormProvider } from "./PlannedMenusFormProvider";

export const PlannedMenuTable = () => {
  const { setMessage } = useSnackbar();

  const {
    paginationHooks,
    loading,
    totalRows,
    refetch,
    deletablePlannedMenu,
    setDeletablePlannedMenu,
    menuToDuplicate,
    setMenuToDuplicate,
    menuToEdit,
    setMenuToEdit,
  } = usePlannedMenuTable();

  const { queryText, onChangeQueryText } = paginationHooks;

  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

  const [deletePlannedMenuMutation] = useDeletePlannedMenuMutation({
    onCompleted: () => {
      deleteMenuSuccess();
    },
  });

  const handleCreateSuccess = (menuName?: string) => {
    datadogRum.addAction("menu_builder.table.created_menu");
    setCreateDialogOpen(false);
    setMessage("success", `${menuName} has been saved`);
    refetch();
  };

  const handleDuplicateSuccess = () => {
    datadogRum.addAction("menu_builder.table.duplicated_menu", { menuId: menuToDuplicate?.id });
    setMessage("success", `${menuToDuplicate?.name} has been duplicated`);
    setMenuToDuplicate(null);
    refetch();
  };

  const handleEditSuccess = () => {
    datadogRum.addAction("menu_builder.table.edited_menu", { menuId: menuToEdit?.id });
    setMessage("success", `Menu edited`);
    setMenuToEdit(null);
    refetch();
  };

  const deleteMenuSuccess = () => {
    setMessage("success", `${deletablePlannedMenu?.name} has been deleted`);
    setDeletablePlannedMenu(null);
    refetch();
  };

  return (
    <>
      <TablePage
        header={
          <TablePageDefaultHeader
            title="Menu"
            queryText={queryText}
            onChangeQueryText={onChangeQueryText}
            onCreate={() => setCreateDialogOpen(true)}
          />
        }
        tableHeaderRow={
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Meal Types</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Date Range</TableCell>
            <TableCell>Last Edited</TableCell>
            <TableCell padding="checkbox">Actions</TableCell>
          </TableRow>
        }
        tableBodyRows={<PlannedMenuRows />}
        paginationHooks={paginationHooks}
        loading={loading}
        total={totalRows}
      />

      {deletablePlannedMenu && (
        <ConfirmationDialog
          open={!!deletablePlannedMenu}
          title="Delete Menu"
          message={`Are you sure you want to delete "${deletablePlannedMenu.name}"? This action cannot be undone.`}
          onCancel={() => {
            datadogRum.addAction("menu_builder.table.cancelled_delete_menu", { menuId: deletablePlannedMenu.id });
            setDeletablePlannedMenu(null);
          }}
          onConfirm={() => {
            datadogRum.addAction("menu_builder.table.deleted_menu", { menuId: deletablePlannedMenu.id });
            deletePlannedMenuMutation({ variables: { input: { id: deletablePlannedMenu.id } } });
          }}
          variant="containedDestructive"
        />
      )}
      {createDialogOpen && (
        <PlannedMenusFormProvider onClose={handleCreateSuccess}>
          <PlannedMenuCreateDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
        </PlannedMenusFormProvider>
      )}
      {menuToEdit && (
        <PlannedMenusFormProvider onClose={handleEditSuccess}>
          <PlannedMenuEditDialog
            plannedMenuId={menuToEdit.id}
            open={Boolean(menuToEdit)}
            onClose={() => setMenuToEdit(null)} />
        </PlannedMenusFormProvider>
      )}
      {menuToDuplicate && (
        <PlannedMenusFormProvider onClose={handleDuplicateSuccess}>
          <PlannedMenuDuplicateDialog
            menuToDuplicate={menuToDuplicate}
            open={Boolean(menuToDuplicate)}
            onClose={() => setMenuToDuplicate(null)}
          />
        </PlannedMenusFormProvider>
      )}
    </>
  );
};

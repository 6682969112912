import { ServerError, fromPromise } from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { RefreshOptions } from "./createDedupedRefresh";

interface createErrorLinkArgs {
  dedupedRefresh: (opts?: RefreshOptions) => Promise<any>;
}

export const createErrorLink = ({ dedupedRefresh }: createErrorLinkArgs) => {
  return onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
    const statusCode = (networkError as ServerError)?.statusCode;
    if (graphQLErrors && statusCode !== 401) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }
    if (networkError) {
      const statusCode = (networkError as ServerError).statusCode;
      const result = (networkError as ServerError).result;
      const didTransferOrgs = result && Array.isArray(result["errors"]) && result["errors"].includes("OrgMembership has transferred");
      switch (statusCode) {
        case 401:
          return fromPromise(
            dedupedRefresh({ didTransferOrgs }).catch(() => {
              return forward(operation);
            })
          ).flatMap(() => {
            return forward(operation);
          });
      }
    }
  });
};

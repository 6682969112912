import { Box, CardActionArea, CardContent, styled } from "@mui/material";

export const IMG_WIDTH_PCT = 33;

export const MenuCardActionArea = styled(CardActionArea)(() => ({ height: "100%", display: "flex", alignItems: "stretch" }));

export const MenuCardContent = styled(CardContent, { shouldForwardProp: prop => prop !== "imageUrl" })<{ imageUrl: string | null }>(
  ({ imageUrl }) => ({
    flexGrow: 1,
    p: 1,
    display: "flex",
    flexDirection: "column",
    ...(imageUrl ? { boxSizing: "border-box", minWidth: `${100 - IMG_WIDTH_PCT}%` } : {}),
  })
);

export const ImageWrapper = styled(Box)(() => ({
  objectFit: "cover",
  textAlign: "center",
}));

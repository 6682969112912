import Warning from "@mui/icons-material/Warning";
import { SxProps, Tooltip } from "@mui/material";
import React, { ReactNode } from "react";
import { warning } from "../../pages/Theme/Colors/Semantic";

interface WarningIconProps {
  tip: NonNullable<ReactNode>;
  sx?: SxProps;
}

const WarningIcon = ({ tip, sx }: WarningIconProps) => {
  return (
    <Tooltip title={tip}>
      <Warning
        sx={{
          color: warning[700],
          fontSize: "24px",
          ...sx,
        }}
      />
    </Tooltip>
  );
};

export default WarningIcon;

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

export const ThemeViewerTextFieldSelect = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [errorText, setErrorText] = useState(" ");
  const [optionChecked, setOptionChecked] = useState(false);
  const [errorChecked, setErrorChecked] = useState(false);
  const [disabledChecked, setDisabledChecked] = useState(false);

  const handleOptionChecked = () => {
    const newOptionChecked = !optionChecked;
    setOptionChecked(newOptionChecked);

    const option = newOptionChecked ? "option1" : "";
    setSelectedOption(option);
  };

  const handleErrorChecked = () => {
    const newErrorChecked = !errorChecked;
    setErrorChecked(newErrorChecked);

    const error = newErrorChecked ? "Fix the error" : " ";
    setErrorText(error);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">Text Field Select</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <FormControlLabel control={<Checkbox checked={optionChecked} onChange={() => handleOptionChecked()} />} label="Option" />
        <FormControlLabel control={<Checkbox checked={errorChecked} onChange={() => handleErrorChecked()} />} label="Error" />
        <FormControlLabel
          control={<Checkbox checked={disabledChecked} onChange={() => setDisabledChecked(!disabledChecked)} />}
          label="Disabled"
        />
      </Box>
      <Typography variant="h3">Medium</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          sx={{ width: 130 }}
          select
          label="Meal"
          placeholder="Placeholder"
          disabled={disabledChecked}
          error={errorChecked}
          helperText={errorText}
          value={selectedOption}
          onChange={e => setSelectedOption(e.target.value)}
        >
          <MenuItem value={"option1"}>Breakfast</MenuItem>
          <MenuItem value={"option2"}>Lunch</MenuItem>
          <MenuItem value={"option3"}>Dinner</MenuItem>
        </TextField>
        <TextField
          sx={{ width: 130 }}
          select
          label="Start Time"
          placeholder="Placeholder"
          disabled={disabledChecked}
          error={errorChecked}
          helperText={errorText}
          SelectProps={{ IconComponent: AccessTimeIcon }}
          value={selectedOption}
          onChange={e => setSelectedOption(e.target.value)}
        >
          <MenuItem value={"option1"}>09:00 am</MenuItem>
          <MenuItem value={"option2"}>10:00 am</MenuItem>
          <MenuItem value={"option3"}>11:00 am</MenuItem>
        </TextField>
      </Box>
      <Typography variant="h3">Small</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <TextField
          sx={{ width: 130 }}
          size="small"
          select
          label="Meal"
          placeholder="Placeholder"
          disabled={disabledChecked}
          error={errorChecked}
          helperText={errorText}
          value={selectedOption}
          onChange={e => setSelectedOption(e.target.value)}
        >
          <MenuItem value={"option1"}>Breakfast</MenuItem>
          <MenuItem value={"option2"}>Lunch</MenuItem>
          <MenuItem value={"option3"}>Dinner</MenuItem>
        </TextField>
        <TextField
          sx={{ width: 130 }}
          size="small"
          select
          label="Start Time"
          placeholder="Placeholder"
          disabled={disabledChecked}
          error={errorChecked}
          helperText={errorText}
          SelectProps={{ IconComponent: AccessTimeIcon }}
          value={selectedOption}
          onChange={e => setSelectedOption(e.target.value)}
        >
          <MenuItem value={"option1"}>09:00 am</MenuItem>
          <MenuItem value={"option2"}>10:00 am</MenuItem>
          <MenuItem value={"option3"}>11:00 am</MenuItem>
        </TextField>
      </Box>
    </Box>
  );
};

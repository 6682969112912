import { FoodLogNotificationSettingsInput } from "../../../types";
import { FoodLogNotificationSettingsFormState } from "./Form/utils";

interface AthleteAndTeamSubscriptionTopicsToFormState {
  athleteNotificationSettings: FoodLogNotificationSettingsInput | null;
  teamNotificationSettings: FoodLogNotificationSettingsInput | null;
  disabled: boolean;
}

export const athleteAndTeamSubscriptionTopicsToFormState = ({
  athleteNotificationSettings,
  teamNotificationSettings,
  disabled,
}: AthleteAndTeamSubscriptionTopicsToFormState): FoodLogNotificationSettingsFormState => {
  const getAthleteNotificationSettingsObj = getAthleteNotificationSettings({
    athleteNotificationSettings,
    teamNotificationSettings,
  });
  return getAthleteNotificationSettingsObj ? { ...getAthleteNotificationSettingsObj, disabled } : emptyFormState;
};

export interface IGetAthleteNotificationSettings {
  athleteNotificationSettings: FoodLogNotificationSettingsInput | null;
  teamNotificationSettings: FoodLogNotificationSettingsInput | null;
}

export const getAthleteNotificationSettings = ({
  athleteNotificationSettings,
  teamNotificationSettings,
}: IGetAthleteNotificationSettings): FoodLogNotificationSettingsInput | null => {
  return athleteNotificationSettings
    ? { ...athleteNotificationSettings }
    : teamNotificationSettings
    ? { ...teamNotificationSettings }
    : null;
};

export const emptyFormState: FoodLogNotificationSettingsFormState = {
  disabled: true,
  imageUploads: false,
  edits: false,
  messages: false,
};

export const defaultFormState: FoodLogNotificationSettingsFormState = {
  disabled: false,
  imageUploads: true,
  edits: true,
  messages: true,
};

export const isSubscribedTo = (props: IGetAthleteNotificationSettings): boolean => {
  const topics = getAthleteNotificationSettings(props);
  if (topics) {
    return Object.values(topics).some(t => t === true);
  } else {
    return false;
  }
};

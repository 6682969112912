import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Semibold } from "../../Theme/Common/TypographyCommon";

export interface NavLink {
  name: string;
  icon?: JSX.Element;
  to: string;
}
export interface TabInfo {
  label: string;
  path: string;
}

export interface BreadCrumbsProps {
  breadCrumbs: NavLink[];
}

export const BreadCrumbs = ({ breadCrumbs }: BreadCrumbsProps) => {
  const {
    palette: { mode, background, text, greyscale },
  } = useTheme();
  // opacities:  0.38 (greater than symbol), 0.60 text names, 0.87 is the last in list
  // button grey background is #eaecf0, center the
  const lastIndex = breadCrumbs.length - 1;
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      {breadCrumbs.map(({ name, icon, to }, index) => {
        const label = <Typography>{name}</Typography>;
        return (
          <Box key={name} sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            {index > 0 && <Typography sx={{ color: text.disabled }}>{">"}</Typography>}
            {index !== lastIndex ? (
              icon ? (
                <Button
                  variant="text"
                  sx={{
                    px: 1,
                    py: 0.5,
                    color: text.secondary,
                    backgroundColor: background.default,
                    "& .MuiButton-startIcon": {
                      m: 0,
                    },
                  }}
                  startIcon={icon}
                  component={Link}
                  to={to}
                />
              ) : (
                <Button
                  variant="text"
                  sx={{
                    px: 1,
                    py: 0.25,
                    "& .MuiTypography-root": {
                      fontWeight: Semibold,
                      color: text.secondary,
                    },
                  }}
                  component={Link}
                  to={to}
                  children={icon || label}
                />
              )
            ) : (
              <Button
                variant="text"
                sx={{
                  px: 1,
                  py: 0.25,
                  backgroundColor: mode === "light" ? greyscale[50] : greyscale[700],
                  "& .MuiTypography-root": {
                    fontWeight: Semibold,
                    color: text.primary,
                  },
                  "&.MuiButton-root:hover": {
                    backgroundColor: greyscale[200],
                  },
                }}
                component={Link}
                to={to}
                children={icon || label}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

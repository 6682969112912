import { Box, MenuItem, SxProps, TextField, Typography } from "@mui/material";
import Loading from "../global/Loading";
import { ScoringSystemFragment } from "../types";
import { Score } from "./Score";
type ScoreOptionValue = string | "None";

interface ScoreSelectProps {
  scoreValue: number | null;
  scoringSystem: ScoringSystemFragment;
  onChange: (scoreValue: number | null) => void;
  disabled?: boolean;
  sx?: SxProps;
}

export const ScoreSelect = ({ scoreValue, scoringSystem, onChange, disabled = false, sx }: ScoreSelectProps) => {
  // [JIRA KS-360] use scoring system to get options. Right now we only support Bullet Scoring
  if (scoringSystem.name !== "Bullet Score") {
    return null;
  }
  const { data, loading } = { data: { scores: [3, 2, 1] }, loading: false };

  const curScore = scoreValue ? String(scoreValue) : "None";
  const scoreOptions: ScoreOptionValue[] = [...(data?.scores.map(x => String(x)) ?? []), "None"];

  return (
    <TextField
      disabled={disabled}
      select
      label="Score"
      value={curScore}
      onChange={e => onChange(e.target.value === "None" ? null : Number(e.target.value))}
      sx={{ ...sx }}
    >
      {loading ? (
        <MenuItem disabled>
          <Loading />
        </MenuItem>
      ) : (
        scoreOptions.map(option => {
          return (
            <MenuItem key={option === "None" ? null : Number(option)} value={option}>
              <ScoreOption scoreValue={option} scoringSystem={scoringSystem} />
            </MenuItem>
          );
        })
      )}
    </TextField>
  );
};

const ScoreOption = ({ scoreValue, scoringSystem }: { scoreValue: ScoreOptionValue; scoringSystem: ScoringSystemFragment }) => {
  const scoreValueNumber = scoreValue === "None" ? null : Number(scoreValue);
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      {scoreValueNumber === null ? (
        <Typography variant="body1">None</Typography>
      ) : (
        <>
          <Score scoreValue={scoreValueNumber} scoringSystem={scoringSystem} size="sm" sx={{ gap: 0.3 }} />
          <Typography variant="body1" sx={{ pl: 2.5 }}>
            {scoreValue} Bullet{scoreValueNumber > 1 ? "s" : ""}
          </Typography>
        </>
      )}
    </Box>
  );
};

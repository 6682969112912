import React from "react";
import CreateMealPlanTemplateStepper from "../../components/MealPlanTemplate/CreateMealPlanTemplateStepper";
import LoadingBackdrop from "../../components/universal/LoadingBackdrop";
import { GoalTypeFragment, useAllSchedulesQuery, useCreateMacroMealPlanTemplateMutation } from "../../types";

interface CreateOrgMealPlanTemplateProps {
  open: boolean;
  onCompleteAction: (action: string, name: string, resetPaging: boolean, mealPlanTemplateId: string) => void;
  onClose: () => void;
  goalTypes: readonly GoalTypeFragment[];
}

const CreateOrgMealPlanTemplate = ({ open, onCompleteAction, onClose, goalTypes }: CreateOrgMealPlanTemplateProps) => {
  const [createMealPlanTemplate, { loading: createLoading }] = useCreateMacroMealPlanTemplateMutation({
    onCompleted: data =>
      onCompleteAction(
        "created",
        data.createMacroMealPlanTemplate.macroMealPlanTemplate.name,
        true,
        data.createMacroMealPlanTemplate.macroMealPlanTemplate.id
      ),
  });
  const { data, loading } = useAllSchedulesQuery();
  const allSchedules = data?.allSchedules;

  return loading || !allSchedules ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <CreateMealPlanTemplateStepper
      open={open}
      onClose={onClose}
      createMealPlanTemplate={input => createMealPlanTemplate({ variables: { input } })}
      createLoading={createLoading}
      goalTypes={goalTypes}
      standardSchedules={allSchedules?.filter(({ team }) => !team)}
      teamSchedules={allSchedules?.filter(({ team }) => team)}
    />
  );
};

export default CreateOrgMealPlanTemplate;

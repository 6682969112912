import { Box, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import MenuOrderFormItems from "./Items";

import { PickupTime } from "@notemeal/shared/ui/MenuOrder/usePickupTimeState";
import { MenuOrderItemWithAppearance } from "@notemeal/shared/ui/MenuOrder/utils";
import { MenuOrderItemStatus } from "../../../types";
import { OrderStatusIcon } from "../OrderStatusIcon";
import TimePicker from "./TimePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timePicker: {
      minWidth: 105,
    },
  })
);

interface MenuOrderFormForOrderProps {
  menuOrderItems: readonly MenuOrderItemWithAppearance[];
  selectedItemId: string | null;
  onSelectItem: (item: MenuOrderItemWithAppearance) => void;
  onRemoveItem: (menuOrderItemId: string) => void;
  pickupTime: PickupTime | null;
  orderPickupTimes: PickupTime[];
  onChangePickupTime: (pickupTime: PickupTime) => void;
  mealMenuTimezone: string;
  menuOrderingClosed: boolean;
  orderStatus?: MenuOrderItemStatus;
  orderCode?: string;
  openTimePicker?: boolean;
  disabled?: boolean;
}

const MenuOrderFormForOrder = ({
  onRemoveItem,
  menuOrderItems,
  menuOrderingClosed,
  orderCode,
  orderStatus,
  onChangePickupTime,
  pickupTime,
  orderPickupTimes,
  mealMenuTimezone,
  selectedItemId,
  onSelectItem,
  openTimePicker,
  disabled,
}: MenuOrderFormForOrderProps) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        {orderCode ? (
          <div>
            <Typography variant="h3">
              <strong>Order #{orderCode}</strong>
            </Typography>
            {orderStatus !== "new" && (
              <Box sx={{ orderStatus: {} }}>
                <OrderStatusIcon orderStatus={orderStatus} />
                <Typography>
                  <em>{orderStatus}</em>
                </Typography>
              </Box>
            )}
          </div>
        ) : (
          <Typography variant="h3">
            <strong>Order Details</strong>
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <TimePicker
          className={classes.timePicker}
          forOrder={true}
          pickupTime={pickupTime}
          onChangePickupTime={onChangePickupTime}
          pickupTimes={orderPickupTimes}
          mealMenuTimezone={mealMenuTimezone}
          disabled={disabled || menuOrderingClosed}
          openTimePicker={openTimePicker}
        />
      </Box>
      <MenuOrderFormItems
        selectedItemId={selectedItemId}
        onSelectItem={onSelectItem}
        onRemoveItem={onRemoveItem}
        menuOrderItems={menuOrderItems}
        disabled={disabled || menuOrderingClosed}
      />
    </>
  );
};

export default MenuOrderFormForOrder;

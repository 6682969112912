export type ButtonSize = "small" | "medium" | "large";
export type ContainedButtonVariant =
  | "contained"
  | "containedSuccess"
  | "containedInformational"
  | "containedWarning"
  | "containedDestructive";
export type OutlinedButtonVariant = "outlined" | "outlinedSuccess" | "outlinedInformational" | "outlinedWarning" | "outlinedDestructive";
export type TextButtonVariant = "text" | "textSuccess" | "textInformational" | "textWarning" | "textDestructive";
export type ButtonVariant = ContainedButtonVariant | OutlinedButtonVariant | TextButtonVariant;

export const containedVariants = new Set([
  "contained",
  "containedSuccess",
  "containedInformational",
  "containedWarning",
  "containedDestructive",
]);
export const outlinedVariants = new Set(["outlined", "outlinedSuccess", "outlinedInformational", "outlinedWarning", "outlinedDestructive"]);
export const textVariants = new Set(["text", "textSuccess", "textInformational", "textWarning", "textDestructive"]);

export const isOutlinedVariant = (variant: ButtonVariant | undefined) => outlinedVariants.has(variant || "");
export const isTextVariant = (variant: ButtonVariant | undefined) => textVariants.has(variant || "");

export const slotProps = {
  textField: {
    // TODO - unable to find spot in theme to provide this for x-date-pickers
    // currently, this is 4px less than muiTextField marginTop
    sx: { mt: "16px" },
  },
};

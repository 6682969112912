import { Box } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import React, { useReducer, useRef, useState } from "react";
import { ConfirmationDialog } from "../../componentLibrary";
import { trackEvent } from "../../reporting/reporting";
import { FullServingAmountFragment, GptRecipeFragment, RecipeFullFragment, useCreateRecipeMutation } from "../../types";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import LoadingBackdrop from "../universal/LoadingBackdrop";
import RecipeForm from "./Form/RecipeForm";
import {
  RecipeFormState,
  canSaveRecipeFormTooltips,
  newRecipeFormState,
  newRecipeFormStateFromGPTRecipe,
  recipeFormReducer,
  recipeFormToInput,
  recipeServingFormToInput,
} from "./Form/utils";
import { GenerateWithGPTDialog } from "./GPT/Generator/GenerateWithGPTDialog";
import { TranscribeWithGPTDialog } from "./GPT/Transcriber/TranscribeWithGPTDialog";
import { TranscribeError, getUserFriendlyMessage } from "./GPT/Transcriber/utils";
import { CreateRecipeFooter } from "./Header/CreateRecipeFooter";
import { CreateRecipeHeader } from "./Header/CreateRecipeHeader";
import { RecipeScaleDialog } from "./Scale/RecipeScaleDialog";
import RecipeScaleSuccessAlert from "./Scale/RecipeScaleSuccessAlert";
interface RecipeCreateDialogProps {
  onClose: () => void;
  onCreate: (recipe: RecipeFullFragment) => void;
  initialIngredients?: readonly FullServingAmountFragment[];
  initialName?: string;
  initialRecipeState?: RecipeFormState;
  forStaff?: boolean;
  setShowCreateRecipeModal?: (show: boolean) => void;
  isSuccessAlert?: boolean;
  dialogView?: boolean;
}

const RecipeCreateContent = ({
  onClose,
  onCreate,
  initialIngredients,
  initialName,
  initialRecipeState,
  forStaff,
  setShowCreateRecipeModal,
  isSuccessAlert = false,
  dialogView,
}: RecipeCreateDialogProps) => {
  const [state, dispatch] = useReducer(recipeFormReducer, initialRecipeState || newRecipeFormState(initialName, initialIngredients));

  const _hasPerformanceKitchenFeatureFlag = useHasFeature("performanceKitchen", { skip: forStaff });
  const { setMessage } = useSnackbar();
  const [transcriberError, setTranscriberError] = useState<TranscribeError | null>(null);
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();
  const showAIRecipes = !forStaff && _hasPerformanceKitchenFeatureFlag;
  const [showScaleSuccessMessage, setShowScaleSuccessMessage] = useState(isSuccessAlert);
  const [openScaleRecipe, setOpenScaleRecipe] = useState(false);
  const [openRecipeUnsavedChangesDialog, setOpenRecipeUnsavedChangesDialog] = useState(false);
  const initialFormState = useRef<RecipeFormState>(state);
  const [showGenerateRecipeModal, setShowGenerateRecipeModal] = useState(false);
  const [showTranscribeRecipeModal, setShowTranscribeRecipeModal] = useState(false);

  const onGenerateError = (error: any) => {
    const userFriendlyMessage = getUserFriendlyMessage(error);
    setMessage("error", userFriendlyMessage);
  };

  const onGPTRecipeComplete = (gptRecipe: GptRecipeFragment, closeModal: () => void) => {
    const newState = newRecipeFormStateFromGPTRecipe(gptRecipe);
    dispatch({
      type: "REPLACE_STATE",
      payload: newState,
    });
    closeModal();
    setShowTranscribeRecipeModal(false);

    setShowCreateRecipeModal && setShowCreateRecipeModal(true);
  };

  const [createRecipe, { called }] = useCreateRecipeMutation({
    onCompleted: data => {
      onCreate(data.createRecipeFull.recipe);
    },
    update: cache => {
      cache.evict({
        fieldName: "recipeOffsetConnection",
        broadcast: true,
      });
    },
  });

  const handleSave = () => {
    dispatch({
      type: "CHANGE_RECIPE_SAVE_CLICK",
      payload: true,
    });
    if (state.isAiTranscribed) {
      trackEvent("Nutrition | Food Management | Recipe | AI Recipe Transcribe | Save Transcribed Recipe", {});
    }
    const recipeInput = recipeFormToInput(state);
    if (canSaveTooltips.length || !recipeInput) {
      return;
    }
    setBrowserBackAndRefreshWarningEnabled(false);
    createRecipe({
      variables: {
        input: {
          recipe: recipeInput,
          servings: [state.serving].map(recipeServingFormToInput),
          copiedFromId: initialRecipeState?.id || null,
        },
      },
    });
  };

  React.useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(state.edited);
  }, [state.edited, setBrowserBackAndRefreshWarningEnabled]);

  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const handleClose = () => {
    setBrowserBackAndRefreshWarningEnabled(false);
    if (state.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const canSaveTooltips = canSaveRecipeFormTooltips(state);

  const showAiButtons = !state.isAiGenerated && !state.isAiTranscribed;
  const onAiGenerateClicked = () => {
    trackEvent("Nutrition | Food Management | Recipe | Enter AI Generate Flow", {});
    setShowGenerateRecipeModal(true);
  };
  const onAiTranscribeClicked = () => {
    trackEvent("Nutrition | Food Management | Recipe | Enter AI Transcription Flow", {});
    setShowTranscribeRecipeModal(true);
  };

  return called ? (
    <LoadingBackdrop open={called} onClose={onClose} />
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column", m: dialogView ? 3 : 0, gap: 3 }}>
      <Box flexGrow={1} sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
        <CreateRecipeHeader
          forStaff={forStaff}
          isShared={state.isShared}
          onChange={() =>
            dispatch({
              type: "CHANGE_IS_SHARED",
              value: !state.isShared,
            })
          }
          handleClose={handleClose}
          handleSave={handleSave}
          canSaveTooltips={canSaveTooltips}
        />
        <RecipeScaleSuccessAlert open={showScaleSuccessMessage} onClose={() => setShowScaleSuccessMessage(false)} />
        <RecipeForm
          forStaff={forStaff}
          state={state}
          dispatch={dispatch}
          onAiGenerateClicked={showAIRecipes && showAiButtons ? onAiGenerateClicked : null}
          onAiTranscribeClicked={showAIRecipes && showAiButtons ? onAiTranscribeClicked : undefined}
          openScaleRecipe={() => setOpenScaleRecipe(true)}
        />
      </Box>
      <CreateRecipeFooter
        forStaff={forStaff}
        isShared={state.isShared}
        onChange={() =>
          dispatch({
            type: "CHANGE_IS_SHARED",
            value: !state.isShared,
          })
        }
        handleClose={handleClose}
        handleSave={handleSave}
        canSaveTooltips={canSaveTooltips}
      />
      <RecipeScaleDialog
        state={state}
        initialFormState={initialFormState.current}
        dispatch={dispatch}
        openScaleChangeDialog={openScaleRecipe}
        setOpenScaleChangeDialog={setOpenScaleRecipe}
        openRecipeUnsavedChangesDialog={openRecipeUnsavedChangesDialog}
        setOpenRecipeUnsavedChangesDialog={setOpenRecipeUnsavedChangesDialog}
        onSuccess={setShowScaleSuccessMessage}
        openScaleRecipe={value => setOpenScaleRecipe(value)}
      />
      {discardChangesOpen && (
        <ConfirmationDialog
          open={discardChangesOpen}
          title="Discard Unsaved Changes"
          message="Are you sure you want to go back? All unsaved changes will be discarded."
          onCancel={() => setDiscardChangesOpen(false)}
          onConfirm={() => {
            if (state.isAiTranscribed) {
              trackEvent("Nutrition | Food Management | Recipe | AI Recipe Transcribe | Discard Transcribed Recipe", {});
            }
            onClose();
          }}
          confirmLabel="Discard"
          variant="containedDestructive"
        />
      )}
      {showGenerateRecipeModal && (
        <GenerateWithGPTDialog
          open={showGenerateRecipeModal}
          onClose={() => setShowGenerateRecipeModal(false)}
          onGenerateComplete={onGPTRecipeComplete}
          onError={onGenerateError}
          initialName={state.name}
        />
      )}
      {showTranscribeRecipeModal && (
        <TranscribeWithGPTDialog
          open={showTranscribeRecipeModal}
          onClose={() => setShowTranscribeRecipeModal(false)}
          onTranscribeComplete={onGPTRecipeComplete}
          transcriberError={transcriberError}
          setTranscriberError={setTranscriberError}
          onError={onGenerateError}
        />
      )}
    </Box>
  );
};

export default RecipeCreateContent;

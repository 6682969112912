import React from "react";
import TablePage from "../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../components/universal/TablePage/DefaultHeader";
import { AllRestaurantsQuery } from "../../../types";
import { PaginationHooks } from "../../../utils/pagination";
import RestaurantTableRow, { TableHeaderRow } from "./TableRow";

interface RestaurantTableProps {
  data?: AllRestaurantsQuery;
  loading: boolean;
  paginationHooks: PaginationHooks;
  onEditRestaurant: (restaurantId: string) => void;
  onCreateRestaurant: () => void;
}

const RestaurantTable = ({ data, loading, paginationHooks, onEditRestaurant, onCreateRestaurant }: RestaurantTableProps) => {
  const { queryText, onChangeQueryText } = paginationHooks;

  const Header = (
    <TablePageDefaultHeader
      title="Restaurant"
      queryText={queryText}
      onChangeQueryText={onChangeQueryText}
      onCreate={onCreateRestaurant} />
  );

  const TableBodyRows = data?.allRestaurants.edges.map(restaurant => (
    <RestaurantTableRow
      key={restaurant.id}
      restaurant={restaurant}
      onEditRestaurant={onEditRestaurant} />
  ));

  return (
    <TablePage
      header={Header}
      tableHeaderRow={<TableHeaderRow />}
      tableBodyRows={TableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={data?.allRestaurants.pageInfo.total || 0}
    />
  );
};

export default RestaurantTable;

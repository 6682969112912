import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { AppleAppStoreIcon, GooglePlayStoreIcon } from "@notemeal/shared/ui/global/Icons";
import { appleAppStoreLink, googlePlayStoreLink } from "@notemeal/shared/ui/utils/links";
import { login } from "@notemeal/shared/ui/utils/tokens";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom-v5-compat";
import { useCookieContext } from "../../contexts/Cookie";
import { NAV_FORGOT_PASSWORD, NAV_FORGOT_USERNAME } from "./NoAuthPaths";

export const LoginPage = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { forceRefresh } = useCookieContext();

  const handleLogin = () => {
    login({ username, password, includeCookies: true })
      .then(res => {
        forceRefresh();
        navigate("/");
      })
      .catch(err => {
        console.error(err);
        const errors = err.response.data.errors;
        if (errors) {
          errors.forEach((eString: string) => {
            console.warn(err);
            const e = eString.replace("AuthenticationError: ", "");
            if (e === "User not found") {
              setError(e);
            } else if (e === "Incorrect password") {
              setError(e);
            } else if (e === "Admin access denied") {
              setError(e);
            } else if (e === "Account inactive") {
              setError(e);
            } else {
              setError("Login failed");
            }
          });
        }
      });
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleLogin();
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">Welcome back!</Typography>
        <Box sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
          <Typography variant="subtitle1">Please enter your details.</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          autoFocus
          label="Username"
          value={username}
          onChange={e => setUsername(e.target.value)}
          error={error !== null} />
        <TextField
          type={showPassword ? "text" : "password"}
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={error !== null}
          helperText={error || ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
          <Button
            variant="text"
            sx={{
              px: 0.5,
              color: "black",
              backgroundColor: "white",
              "&.MuiButton-root:hover": {
                backgroundColor: "#EAECF0",
              },
            }}
            component={Link}
            to={NAV_FORGOT_USERNAME}
          >
            Forgot username?
          </Button>
          <Button
            variant="text"
            sx={{
              px: 0.5,
              color: "black",
              backgroundColor: "white",
              "&.MuiButton-root:hover": {
                backgroundColor: "#EAECF0",
              },
            }}
            component={Link}
            to={NAV_FORGOT_PASSWORD}
          >
            Forgot password?
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Button type="submit">Login</Button>
        {/* <Divider>or</Divider>
        <Button
          variant="outlined"
          startIcon={<TeamworksBadgeIcon sx={{ alignSelf: "center", width: 24, height: 24 }} viewBox="0 0 24 24" />}
          onClick={() => {}}
        >
          Sign in with Teamworks
        </Button> */}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton href={appleAppStoreLink}>
          <AppleAppStoreIcon />
        </IconButton>
        <IconButton href={googlePlayStoreLink}>
          <GooglePlayStoreIcon />
        </IconButton>
      </Box>
    </form>
  );
};

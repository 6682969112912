import { Box, Typography } from "@mui/material";
import React from "react";
import { ScheduleKeyEntry } from "./ScheduleKey";

interface CurrentMealPlanStatusProps {
  mealPlanName: string | undefined;
  mealPlanColor: string;
}

const CurrentMealPlanStatus = ({ mealPlanName, mealPlanColor }: CurrentMealPlanStatusProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 1 }}>
      <>
        <Typography variant="body2">Currently editing:</Typography>
        <ScheduleKeyEntry
          label={mealPlanName ?? "New Meal Plan"}
          color={mealPlanColor}
          textVariant="body2" />
      </>
    </Box>
  );
};

export default CurrentMealPlanStatus;

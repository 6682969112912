import { getNextPosition } from "@notemeal/shared/ui/utils/getNextPosition";
import newId from "@notemeal/shared/ui/utils/newId";
import {
  MenuItemAppearanceFormFragment,
  MenuItemAppearancePreviewFragment,
  MenuItemChoiceFormFragment,
  MenuItemFormFragment,
  MenuItemPreviewFragment,
} from "../../types";
import { menuItemAppearanceCanBeDeleted, menuItemAppearanceIsDeleted } from "./utils";

export type MenuItemAppearanceState =
  | MenuItemAppearanceState_Create
  | MenuItemAppearanceState_Add
  | MenuItemAppearanceState_Edit
  | MenuItemAppearanceState_Conversion
  | MenuItemAppearanceState_Move;

interface MenuItemAppearanceState_Create extends MenuItemAppearanceFormFragment {
  type: "Create";
  partial: false;
}

interface MenuItemAppearanceState_Add extends MenuItemAppearancePreviewFragment {
  type: "Add";
  partial: true;
}

interface MenuItemAppearanceState_Edit extends MenuItemAppearanceFormFragment {
  type: "Edit";
  partial: false;
  initialMenuItem: {
    id: string;
    isOneOff: boolean;
    choices: readonly MenuItemChoiceFormFragment[];
  };
  deleted: boolean;
}

interface MenuItemAppearanceState_Conversion extends MenuItemAppearanceFormFragment {
  type: "Conversion";
  partial: false;
  initialMenuItem: {
    id: string;
    isOneOff: boolean;
    choices: readonly MenuItemChoiceFormFragment[];
  };
  deleted: boolean;
}

interface MenuItemAppearanceState_Move extends MenuItemAppearancePreviewFragment {
  type: "Move";
  partial: true;
  deleted: boolean;
}

export type MenuItemAppearanceGroupState = readonly MenuItemAppearanceState[];

interface AddMenuItemAction {
  type: "AddMenuItemAction";
  payload: {
    menuItem: MenuItemPreviewFragment;
  };
}

interface CreateMenuItemAction {
  type: "CreateMenuItemAction";
  payload: {
    menuItem: MenuItemFormFragment;
    maxAmount: number | null;
    availableForOrder: boolean;
    allowSpecialRequests: boolean;
  };
}

interface ConvertMenuItemAction {
  type: "ConvertMenuItemAction";
  payload: {
    menuItem: MenuItemFormFragment;
    initialMenuItem: MenuItemFormFragment;
    maxAmount: number | null;
    availableForOrder: boolean;
    allowSpecialRequests: boolean;
    isOneOff: boolean;
  };
}

interface EditMenuItemAction {
  type: "EditMenuItemAction";
  payload: {
    menuItem: MenuItemFormFragment;
    initialMenuItem: MenuItemFormFragment;
    maxAmount: number | null;
    availableForOrder: boolean;
    allowSpecialRequests: boolean;
  };
}

interface AllMenuItemsAvailableForOrderAction {
  type: "AllMenuItemsAvailableForOrderAction";
  payload: {
    availableForOrder: boolean;
  };
}

interface AllMenuItemsAllowSpecialRequestsAction {
  type: "AllMenuItemsAllowSpecialRequestsAction";
  payload: {
    allowSpecialRequests: boolean;
  };
}

interface EditMenuItemAppearanceAction {
  type: "EditMenuItemAppearanceAction";
  payload: {
    menuItemId: string;
    maxAmount: number | null;
    availableForOrder: boolean;
    allowSpecialRequests: boolean;
  };
}

interface RemoveMenuItemAction {
  type: "RemoveMenuItemAction";
  payload: {
    menuItemId: string;
  };
}

interface RestoreMenuItemAction {
  type: "RestoreMenuItemAction";
  payload: {
    menuItemId: string;
  };
}

interface ReorderMenuItemsAction {
  type: "ReorderMenuItemsAction";
  payload: {
    menuItemAppearances: readonly MenuItemAppearanceState[];
  };
}

export type MenuItemAppearanceGroupAction =
  | AddMenuItemAction
  | EditMenuItemAction
  | ConvertMenuItemAction
  | EditMenuItemAppearanceAction
  | RemoveMenuItemAction
  | CreateMenuItemAction
  | RestoreMenuItemAction
  | ReorderMenuItemsAction
  | AllMenuItemsAvailableForOrderAction
  | AllMenuItemsAllowSpecialRequestsAction;

export const menuItemAppearanceGroupReducer = (
  state: MenuItemAppearanceGroupState,
  action: MenuItemAppearanceGroupAction
): MenuItemAppearanceGroupState => {
  switch (action.type) {
    case "RemoveMenuItemAction":
      return state.flatMap(mia => {
        if (mia.menuItem.id !== action.payload.menuItemId) {
          return mia;
        } else if (menuItemAppearanceCanBeDeleted(mia)) {
          return {
            ...mia,
            deleted: true,
          };
        } else {
          return [];
        }
      });
    case "RestoreMenuItemAction":
      return state.map(mia => {
        if (mia.menuItem.id !== action.payload.menuItemId) {
          return mia;
        } else {
          if (menuItemAppearanceCanBeDeleted(mia)) {
            return {
              ...mia,
              deleted: false,
            };
          } else {
            return mia;
          }
        }
      });
    case "CreateMenuItemAction":
      return [
        ...state,
        {
          id: newId(),
          type: "Create",
          menuItem: {
            ...action.payload.menuItem,
            id: newId(),
          },
          partial: false,
          position: getNextPosition(state),
          maxAmount: action.payload.maxAmount,
          availableForOrder: action.payload.availableForOrder,
          allowSpecialRequests: action.payload.allowSpecialRequests,
        },
      ];
    case "AddMenuItemAction":
      const matchingDeletedItem = state.find(mia => menuItemAppearanceIsDeleted(mia) && mia.menuItem.id === action.payload.menuItem.id);
      if (matchingDeletedItem) {
        return state.map(mia => (mia !== matchingDeletedItem ? mia : { ...mia, deleted: false }));
      }
      return [
        ...state,
        {
          id: newId(),
          type: "Add",
          menuItem: action.payload.menuItem,
          partial: true,
          position: getNextPosition(state),
          availableForOrder: action.payload.menuItem.defaultAvailableForOrder,
          allowSpecialRequests: action.payload.menuItem.defaultAllowSpecialRequests,
          maxAmount: action.payload.menuItem.defaultMaxAmount,
        },
      ];
    case "EditMenuItemAction":
      return state.map(mia => {
        if (mia.menuItem.id !== action.payload.menuItem.id) {
          return mia;
        } else if (mia.type === "Create" || mia.type === "Edit") {
          return {
            ...mia,
            menuItem: action.payload.menuItem,
            maxAmount: action.payload.maxAmount,
            availableForOrder: action.payload.availableForOrder,
            allowSpecialRequests: action.payload.allowSpecialRequests,
          };
        } else if (mia.menuItem.isOneOff) {
          return mia;
        } else if (mia.type === "Add" || mia.type === "Move") {
          return {
            ...mia,
            type: "Edit",
            initialMenuItem: {
              id: action.payload.initialMenuItem.id,
              isOneOff: action.payload.initialMenuItem.isOneOff,
              choices: action.payload.initialMenuItem.choices,
            },
            menuItem: action.payload.menuItem,
            maxAmount: action.payload.maxAmount,
            availableForOrder: action.payload.availableForOrder,
            allowSpecialRequests: action.payload.allowSpecialRequests,
            partial: false,
            deleted: false,
          };
        } else {
          return mia;
        }
      });
    case "ConvertMenuItemAction":
      return state.map(mia => {
        if (mia.menuItem.id !== action.payload.menuItem.id) {
          return mia;
        } else if (mia.type === "Move" || mia.type === "Add") {
          return {
            ...mia,
            type: "Conversion",
            initialMenuItem: {
              id: action.payload.initialMenuItem.id,
              isOneOff: action.payload.initialMenuItem.isOneOff,
              choices: action.payload.initialMenuItem.choices,
            },
            menuItem: {
              ...action.payload.menuItem,
              isOneOff: action.payload.isOneOff,
              name: !action.payload.menuItem.isOneOff ? `New Version of ${action.payload.menuItem.name}` : action.payload.menuItem.name,
            },
            maxAmount: action.payload.maxAmount,
            availableForOrder: action.payload.availableForOrder,
            allowSpecialRequests: action.payload.allowSpecialRequests,
            partial: false,
            deleted: false,
          };
        } else if (mia.type === "Conversion") {
          return {
            ...mia,
            menuItem: {
              ...action.payload.menuItem,
              isOneOff: action.payload.isOneOff,
            },
            maxAmount: action.payload.maxAmount,
            availableForOrder: action.payload.availableForOrder,
            allowSpecialRequests: action.payload.allowSpecialRequests,
          };
        } else {
          return mia;
        }
      });
    case "EditMenuItemAppearanceAction":
      return state.map(mia => {
        if (mia.menuItem.id !== action.payload.menuItemId) {
          return mia;
        } else {
          return {
            ...mia,
            maxAmount: action.payload.maxAmount,
            availableForOrder: action.payload.availableForOrder,
            allowSpecialRequests: action.payload.allowSpecialRequests,
          };
        }
      });
    case "AllMenuItemsAvailableForOrderAction":
      const availableForOrder = action.payload.availableForOrder;
      return state.map(mia => {
        return {
          ...mia,
          availableForOrder,
          allowSpecialRequests: availableForOrder ? mia.allowSpecialRequests : false,
        };
      });
    case "AllMenuItemsAllowSpecialRequestsAction":
      return state.map(mia => {
        return {
          ...mia,
          allowSpecialRequests: action.payload.allowSpecialRequests,
        };
      });
    case "ReorderMenuItemsAction":
      return action.payload.menuItemAppearances;
  }
};

export const getMenuItemAppearanceGroupState = (
  menuItemAppearances: readonly MenuItemAppearancePreviewFragment[],
  createOrEdit: "create" | "edit"
): MenuItemAppearanceGroupState =>
  menuItemAppearances.flatMap(mia =>
    mia.menuItem.isDeleted
      ? []
      : createOrEdit === "create"
      ? {
          ...mia,
          type: "Add",
          partial: true,
        }
      : {
          ...mia,
          type: "Move",
          partial: true,
          deleted: false,
        }
  );

import { Card, CardContent, Chip, List, ListItem, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getExchangePickList } from "../ExchangeServingList/utils";
import { formatAmbiguousServingAmountWithTotalWeight } from "../ServingAmount/utils";
import { ExchangeAmountFragment, FullExchangeServingListFragment } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(4)})`,
    },
    content: {
      boxSizing: "border-box",
      height: "100%",
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto minmax(0, 1fr)",
    },
    header: {
      display: "flex",
      justifyContent: "space-around",
      gridRowStart: 1,
      gridColumnStart: 1,
    },
    pickList: {
      overflowY: "auto",
      overflowX: "hidden",
      paddingLeft: theme.spacing(2),
      marginBottom: 0,
      gridRowStart: 2,
      gridColumnStart: 1,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "start",
    },
    pickItem: {
      width: `calc(50% - ${theme.spacing(2)})`,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
    },

    ellipses: {
      lineHeight: "12px",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "clip",
    },
    exchangeAmountChip: {
      marginLeft: 5,
      marginRight: 5,
      height: 20,
      color: "white",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
  })
);

interface ExchangeAmountCardProps {
  exchangeAmount: ExchangeAmountFragment;
  servingList: FullExchangeServingListFragment;
}

const ExchangeAmountPreviewCard = ({ exchangeAmount, servingList }: ExchangeAmountCardProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  const classes = useStyles();

  const { exchange, amount, pickListServingIds } = exchangeAmount;
  const pickList = getExchangePickList(servingList, pickListServingIds);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.header}>
          <Typography style={{ color: greyscale[500] }} component="span">
            {exchange.name}
          </Typography>
          <div className={classes.row}>
            <Typography color="textSecondary" component="span">
              {" "}
              pick
            </Typography>
            <Chip className={classes.exchangeAmountChip} style={{ backgroundColor: greyscale[500] }} size="small" label={amount} />
            <Typography color="textSecondary" component="span">
              {" "}
              servings
            </Typography>
          </div>
        </div>
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
        >
          {pickList.map(sa => (
            <ListItem key={sa.id} sx={{ p: 0 }}>
              <Typography variant="subtitle1" sx={{ lineHeight: 1, fontSize: "10px", whiteSpace: "initial" }}>
                {sa.serving.foodOrRecipe.name} - {formatAmbiguousServingAmountWithTotalWeight(sa)}
              </Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default ExchangeAmountPreviewCard;

// full page path
export const NAV_STAFF_SETTINGS = "/staff/settings";

// local child paths
export const NAV_SETTINGS_THEME_PREFERENCE = "theme-preference";

// full child paths
export const NAV_STAFF_SETTINGS_THEME_PREFERENCE = `${NAV_STAFF_SETTINGS}/${NAV_SETTINGS_THEME_PREFERENCE}`;

export const staffSettingsLink = {
  name: "Settings",
  to: NAV_STAFF_SETTINGS,
};

import FileCopyIcon from "@mui/icons-material/FileCopy";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";

interface ClientCredentialsTextFieldWithCopyProps {
  label: string;
  value: string;
  className?: string;
  ariaLabel?: string;
}

const ClientCredentialsTextFieldWithCopy = ({ label, value, className, ariaLabel = "" }: ClientCredentialsTextFieldWithCopyProps) => {
  const { setMessage } = useSnackbar();

  const handleClickCopy = () => {
    navigator.clipboard.writeText(value);
    setMessage("success", `Copied "${label}" to clipboard`);
  };

  return (
    <TextField
      label={label}
      value={value}
      className={className}
      margin="dense"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleClickCopy}>
              <FileCopyIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        "aria-label": ariaLabel,
      }}
    />
  );
};

export default ClientCredentialsTextFieldWithCopy;

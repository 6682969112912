import { alpha } from "@mui/material";
import { grey } from "@mui/material/colors";
import { greyscaleDark, greyscaleLight, neutral } from "../Colors/Greyscale";
import { performance } from "../Colors/ProductLine";
import { error, informational, success, warning } from "../Colors/Semantic";
import { boxShadowExtraSmall, boxShadowSmall, boxShadowMedium, boxShadowLarge } from "../Colors/BoxShadow";
import { cho, fat, pro } from "../Colors/Macros";
import { blueDark, green, greenLight, greyBlue, indigo, orangeDark, pink, violet, yellow } from "../Colors/Accents";

export const paletteCommon = {
  primary: {
    main: performance.primary,
    light: performance.tertiary,
  },
  secondary: {
    main: performance.secondary,
  },
  success: {
    main: success[700],
    light: success[200],
    lighter: success[25],
    contrastText: "#fff",
  },
  info: {
    main: informational[700],
    light: informational[200],
    lighter: informational[25],
    contrastText: "#fff",
  },
  warning: {
    main: warning[800],
    light: warning[200],
    lighter: warning[25],
    contrastText: "#fff",
  },
  error: {
    main: error[700],
    light: error[200],
    lighter: error[25],
    contrastText: "#fff",
  },
  greyscaleDark: greyscaleDark,
  greyscaleLight: greyscaleLight,
  neutral: neutral,
  highEmphasisTextLight: alpha(neutral.black, 0.87),
  mediumEmphasisTextLight: alpha(neutral.black, 0.6),
  lowEmphasisTextLight: alpha(neutral.black, 0.38),
  highEmphasisTextDark: neutral.white,
  mediumEmphasisTextDark: alpha(neutral.white, 0.6),
  lowEmphasisTextDark: alpha(neutral.white, 0.38),
  boxShadowExtraSmall,
  boxShadowSmall,
  boxShadowMedium,
  boxShadowLarge,
  cho,
  pro,
  fat,
  like: {
    main: success[700],
    light: success[200],
    lighter: success[25],
  },
  dislike: {
    main: error[700],
    light: error[200],
    lighter: error[25],
  },
  accents: {
    pink,
    violet,
    blueDark,
    indigo,
    greyBlue,
    orangeDark,
    greenLight,
    green,
    yellow,
  },
  teamworksCalendarEvent: grey[300],
};

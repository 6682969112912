import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, Dialog, DialogActions, DialogContent, styled, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";

const RevisionDiv = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

interface RevisionRecipeDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
}

export const RevisionRecipeDialog = ({ open, onClose, onSubmit }: RevisionRecipeDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title="Create Revision of Recipe?" onClose={onClose} />
      <DialogContent>
        <Typography>Any changes to a recipe that affects its nutrients will create a new revision.</Typography>
        <RevisionDiv>
          <CheckIcon fontSize="small" />
          <Typography>
            Usage on
            <Typography
              component={"span"}
              fontWeight={"fontWeightBold"}
              sx={{ mx: 0.5 }}>
              Meal Plans
            </Typography>
            will preserve existing nutrients.
          </Typography>
        </RevisionDiv>
        <RevisionDiv>
          <WarningIcon fontSize="small" />
          <Typography>
            Usage on
            <Typography
              component={"span"}
              fontWeight={"fontWeightBold"}
              sx={{ mx: 0.5 }}>
              Menus
            </Typography>
            will
            <Typography
              component={"span"}
              fontWeight={"fontWeightBold"}
              sx={{ mx: 0.5 }}>
              NOT
            </Typography>
            be updated to new revision.
          </Typography>
        </RevisionDiv>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Create Revision</Button>
      </DialogActions>
    </Dialog>
  );
};

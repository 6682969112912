import { AdvancedSelectionState } from "../../views/Tags/reducers/advancedSelectionReducers";

export interface AssignedTeam {
  readonly id: string;
  readonly name: string;
}

interface AssignByTeamsState<T extends AssignedTeam> {
  __typename: "Teams";
  teams: readonly T[];
  advancedSelectionState: null;
}

interface AssignByAdvanceSelectionState {
  __typename: "Tags";
  teams: null;
  advancedSelectionState: AdvancedSelectionState;
}

export type AthleteAssignState<T extends AssignedTeam> = AssignByAdvanceSelectionState | AssignByTeamsState<T>;

interface UpdateTeamsAction<T extends AssignedTeam> {
  type: "UPDATE_TEAMS_ACTION";
  payload: readonly T[];
}

interface UpdateAdvancedSelectionAction {
  type: "UPDATE_ADVANCED_SELECTION_ACTION";
  payload: AdvancedSelectionState;
}

export type AthleteAssignAction<T extends AssignedTeam> = UpdateTeamsAction<T> | UpdateAdvancedSelectionAction;

export const athleteAssignReducer = <T extends AssignedTeam>(
  state: AthleteAssignState<T>,
  action: AthleteAssignAction<T>
): AthleteAssignState<T> => {
  switch (action.type) {
    case "UPDATE_TEAMS_ACTION":
      if (state.__typename === "Tags") {
        return state;
      }
      return {
        __typename: "Teams",
        teams: action.payload,
        advancedSelectionState: null,
      };
    case "UPDATE_ADVANCED_SELECTION_ACTION":
      return {
        __typename: "Tags",
        teams: null,
        advancedSelectionState: action.payload,
      };
  }
};

import { TextField, Typography } from "@mui/material";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { OrgLinkerOrgFragment, TeamworksOrgFragment, useCreateOrgFromTeamworksOrgMutation } from "../../../../../types";
import ConfirmDialog from "../shared/ConfirmDialog";

interface ConfirmCreateDialogProps {
  teamworksOrg: TeamworksOrgFragment;
  confirmComplete: (org: OrgLinkerOrgFragment, teamworksOrg: TeamworksOrgFragment) => void;
  confirmCancel: () => void;
  isOpen: boolean;
}

const ConfirmCreateDialog = ({ teamworksOrg, confirmComplete, confirmCancel, isOpen }: ConfirmCreateDialogProps) => {
  const { setMessage } = useSnackbar();
  const [name, setName] = useState(teamworksOrg.name);

  const [createOrg, { loading }] = useCreateOrgFromTeamworksOrgMutation({
    onError: () => {
      setMessage("error", "Error occurred while creating org");
    },
    // Need to just evict cache here because the query is run with a search term.
    update: (cache, { data }) => {
      if (data) {
        cache.evict({
          fieldName: "teamworksOrgCursorConnection",
          broadcast: true,
        });
      }
    },
    onCompleted: data => {
      const org = data.createOrgFromTeamworksOrg.org;
      setMessage("success", `Successfully Created Org with name: ${org.name}`);
      confirmComplete(org, teamworksOrg);
    },
  });

  const onConfirm = () =>
    createOrg({
      variables: {
        input: { name, teamworksId: teamworksOrg.id },
      },
    });

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Create Org From Teamworks Org"
      content={
        <>
          <Typography>Teamworks Name: {teamworksOrg.name}</Typography>
          <TextField
            label="Org Name"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth />
        </>
      }
      loading={loading}
      onCancel={confirmCancel}
      onConfirm={onConfirm}
      confirmDisabled={name === ""}
    />
  );
};

export default ConfirmCreateDialog;

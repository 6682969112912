import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const ExternalLoadingLabel = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 1 }}>
      <CircularProgress size={20} />
      <Typography
        sx={{ pr: 2 }}
        color={"info"}
        variant="body2">
        Loading External Events
      </Typography>
    </Box>
  );
};

export default ExternalLoadingLabel;

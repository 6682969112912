import CorporateFareIcon from "@mui/icons-material/CorporateFareRounded";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { CuisinesPage } from "./CuisinesPage";
import { DeliveryLocationsPage } from "./DeliveryLocationsPage";
import { DietsPage } from "./DietsPage";
import { PaymentMethodsPage } from "./PaymentMethodsPage";
import { RestaurantTypesPage } from "./RestaurantTypesPage";
import { RestaurantsPage } from "./RestaurantsPage";
import { TeamLabelsPage } from "./TeamLabelsPage";

// full page path
export const NAV_STAFF_RESTAURANT_MANAGEMENT = "/staff/restaurant-management";

// local child paths
export const NAV_RESTAURANT_MANAGEMENT_CUISINES = "cuisines";
export const NAV_RESTAURANT_MANAGEMENT_DIETS = "diets";
export const NAV_RESTAURANT_MANAGEMENT_RESTAURANTS = "restaurants";

// full child paths
export const NAV_STAFF_RESTAURANT_MANAGEMENT_RESTAURANTS = `${NAV_STAFF_RESTAURANT_MANAGEMENT}/${NAV_RESTAURANT_MANAGEMENT_RESTAURANTS}`;

export const restaurantManagementLink = {
  name: "Restaurant Management",
  icon: <CorporateFareIcon />,
  to: NAV_STAFF_RESTAURANT_MANAGEMENT,
};

export const restaurantsLink = {
  name: "Restaurants",
  to: NAV_STAFF_RESTAURANT_MANAGEMENT_RESTAURANTS,
};

const secondaryNavLayoutProps: SecondaryNavLayoutProps = {
  current: restaurantManagementLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Cuisines",
      to: NAV_RESTAURANT_MANAGEMENT_CUISINES,
    },
    {
      name: "Diets",
      to: NAV_RESTAURANT_MANAGEMENT_DIETS,
    },
    {
      name: "Restaurants",
      to: NAV_RESTAURANT_MANAGEMENT_RESTAURANTS,
    },
    {
      name: "Restaurant Types",
      to: "restaurant-types",
    },
    {
      name: "Delivery Locations",
      to: "delivery-locations",
    },
    {
      name: "Payment Methods",
      to: "payment-methods",
    },
    {
      name: "Team Labels",
      to: "team-labels",
    },
  ],
};

export const RestaurantManagementRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route index element={<CuisinesPage />} />
      <Route path="cuisines" element={<CuisinesPage />} />
      <Route path="diets" element={<DietsPage />} />
      <Route path="restaurants" element={<RestaurantsPage />} />
      <Route path="restaurant-types" element={<RestaurantTypesPage />} />
      <Route path="delivery-locations" element={<DeliveryLocationsPage />} />
      <Route path="payment-methods" element={<PaymentMethodsPage />} />
      <Route path="team-labels" element={<TeamLabelsPage />} />
    </Route>
  </Routes>
);

import { Box } from "@mui/material";
import { MealPlanFoodPreferenceContextProvider } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import Loading from "@notemeal/shared/ui/global/Loading";
import { sortByKey } from "@notemeal/utils/sort";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { useSelectedMealOptionsContext } from "apps/web/src/contexts/SelectedMealOptions";
import {
  ExchangeMealPlanDisplaySettingsFragment,
  MacroMealPlanDisplaySettingsFragment,
  useMealPlanCommonQuery,
  useSelectMealOptionMutation,
} from "apps/web/src/types";
import React, { useEffect, useState } from "react";
import { MealPlanCalendar as Calendar } from "./Calendar/index";
import { MealPlanHeader } from "./MealPlanHeader";
import { MealPlanOptions } from "./MealPlanOptions";
import { MealPlanTargets } from "./MealPlanTargets";
import { MealPlanTab } from "./utils";

interface MealPlanContentProps {
  athleteId: string;
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
  mealPlanId: string;
}

export const MealPlanContent = ({ athleteId, mealPlanId, macroDisplaySettings, exchangeDisplaySettings }: MealPlanContentProps) => {
  const { setMessage } = useSnackbar();
  const [, setSelectedMealOptions] = useSelectedMealOptionsContext();
  const [selectMealOption] = useSelectMealOptionMutation();
  const [tab, setTab] = useState<MealPlanTab>("targets");
  const [editCalendarOpen, setEditCalendarOpen] = useState(false);
  const [editCalendarRedirect, setEditCalendarRedirect] = useState<MealPlanTab>("calendar");

  const onEditCalendarClose = () => {
    setTab(editCalendarRedirect);
    // reset default redirect to calendar tab
    setEditCalendarRedirect("calendar");
  };

  const { data, loading } = useMealPlanCommonQuery({
    variables: { mealPlanId },
    onError: error => setMessage("error", error.message),
  });

  useEffect(() => {
    if (data && !loading) {
      setSelectedMealOptions(
        data.mealPlan.mealTemplates.flatMap(mt => {
          const selectedMealOption = mt.mealOptions.find(mo => mo.id === mt.selectedMealOptionId);
          return selectedMealOption ? [{ ...selectedMealOption, mealTemplateId: mt.id }] : [];
        })
      );
      if (data.mealPlan.mealTemplates.every(mt => mt.mealOptions.length > 0 && mt.selectedMealOptionId === null)) {
        data.mealPlan.mealTemplates.forEach(mt => {
          const firstMealOption = sortByKey(mt.mealOptions, "position")[0];
          if (firstMealOption) {
            selectMealOption({
              variables: {
                mealTemplateId: mt.id,
                type: mt.__typename,
                mealOptionId: firstMealOption.id,
              },
            });
          }
        });
      }
    }
  }, [data, loading, selectMealOption, setSelectedMealOptions]);

  if (loading || !data || !Object.keys(data).length) {
    return <Loading progressSize="md" />;
  }

  const { mealPlan } = data;
  const { avoidedFoods, promotedFoods, avoidedFoodGroups, promotedFoodGroups } = mealPlan;

  const handleEditCalendar = () => {
    setEditCalendarOpen(true);
    setEditCalendarRedirect(tab);
    setTab("calendar");
  };

  return (
    <MealPlanFoodPreferenceContextProvider
      promotedFoods={promotedFoods}
      avoidedFoods={avoidedFoods}
      promotedFoodGroups={promotedFoodGroups}
      avoidedFoodGroups={avoidedFoodGroups}
    >
      <Box sx={{ minWidth: 810, overflowY: "hidden", display: "flex", flexDirection: "column", gap: 2 }}>
        <MealPlanHeader
          mealPlan={mealPlan}
          macroDisplaySettings={macroDisplaySettings}
          exchangeDisplaySettings={exchangeDisplaySettings}
          tab={tab}
          onChangeTab={setTab}
          onEditCalendar={handleEditCalendar}
        />
        {tab === "targets" ? (
          <Box sx={{ overflowY: "auto" }}>
            <MealPlanTargets mealPlan={mealPlan} />
          </Box>
        ) : tab === "options" ? (
          <Box sx={{ overflowY: "auto" }}>
            <MealPlanOptions mealPlan={mealPlan} />
          </Box>
        ) : (
          <Box sx={{ overflowY: "auto" }}>
            <Calendar
              athleteId={athleteId}
              mealPlan={mealPlan}
              setEditOpen={setEditCalendarOpen}
              editOpen={editCalendarOpen}
              onEditClose={onEditCalendarClose}
            />
          </Box>
        )}
      </Box>
    </MealPlanFoodPreferenceContextProvider>
  );
};

import { useApolloClient } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { useAllRestaurantsQuery } from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import CreateDialog from "../../../../views/Staff/Restaurants/CreateDialog";
import EditDialog from "../../../../views/Staff/Restaurants/EditDialog";
import RestaurantsTable from "../../../../views/Staff/Restaurants/Table";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "inherit",
    width: "100%",
  },
});

export const RestaurantsPage = () => {
  const classes = useStyles();
  const paginationHooks = useOffsetPagination();
  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();
  const { limit, offset, query } = paginationHooks;
  const { data, loading } = useAllRestaurantsQuery({
    variables: {
      query,
      input: { limit, offset },
    },
  });
  const [showCreateRestaurantDialog, setShowCreateRestaurantDialog] = useState(false);
  const [editRestaurantId, setEditRestaurantId] = useState<string | null>(null);

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "allRestaurants",
      broadcast: true,
    });
  };

  return (
    <div className={classes.container}>
      <RestaurantsTable
        onEditRestaurant={restaurantId => setEditRestaurantId(restaurantId)}
        data={data}
        loading={loading}
        paginationHooks={paginationHooks}
        onCreateRestaurant={() => setShowCreateRestaurantDialog(true)}
      />
      {showCreateRestaurantDialog && (
        <CreateDialog
          onCreate={restaurantName => {
            resetTable();
            setMessage("success", `Successfully created Restaurant ${restaurantName}`);
          }}
          onClose={() => setShowCreateRestaurantDialog(false)}
          open={showCreateRestaurantDialog}
        />
      )}
      {!!editRestaurantId && (
        <EditDialog
          restaurantId={editRestaurantId}
          onEdit={restaurantName => {
            resetTable();
            setMessage("success", `Successfully edited Restaurant ${restaurantName}`);
          }}
          onClose={() => setEditRestaurantId(null)}
          open={!!editRestaurantId}
        />
      )}
    </div>
  );
};

import React from "react";
import { Route, Routes, useParams } from "react-router-dom-v5-compat";
import { useTeamNavQuery } from "../../../../types";
import { RoleRoute } from "../../ProtectedRoutes";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { teamManagementLink, teamsLink } from "../TeamManagement/TeamManagementPaths";
import { TeamAnthropometryPage } from "./TeamAnthropometryPage";
import { TeamMealPlansPage } from "./TeamMealPlansPage";
import { TeamNotesPage } from "./TeamNotesPage";
import { TeamPage } from "./TeamPage";
import {
  NAV_TEAM_ANTHROPOMETRY,
  NAV_TEAM_DASHBOARD,
  NAV_TEAM_MEAL_PLANS,
  NAV_TEAM_NOTES,
  NAV_TEAM_ROSTER,
  getNavOrgTeamLink,
} from "./TeamPaths";
import { TeamRosterPage } from "./TeamRosterPage";

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "Team",
  current,
  breadCrumbs: [teamManagementLink, teamsLink],
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Dashboard",
      to: NAV_TEAM_DASHBOARD,
    },
    {
      name: "Roster",
      to: NAV_TEAM_ROSTER,
    },
    {
      name: "Anthropometry",
      to: NAV_TEAM_ANTHROPOMETRY,
    },
    {
      name: "Meal Plans",
      to: NAV_TEAM_MEAL_PLANS,
      allowedRoles: ["dietitian"],
    },
    {
      name: "Notes",
      to: NAV_TEAM_NOTES,
    },
  ],
});

export const TeamRouter = () => {
  const { teamId: maybeTeamId } = useParams();
  const teamId = maybeTeamId || "";
  let teamName = "";
  const { data, loading } = useTeamNavQuery({ variables: { id: teamId } });

  if (!loading && data) {
    const {
      team: { name },
    } = data;
    teamName = name;
  }

  const current = getNavOrgTeamLink(teamId, teamName);
  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  return (
    <Routes>
      <Route element={teamName && <SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route element={<RoleRoute roles={["dietitian", "nonRdAdmin"]} />}>
          <Route index element={<TeamPage />} />
          <Route path={NAV_TEAM_DASHBOARD} element={<TeamPage />} />
          <Route path={NAV_TEAM_ROSTER} element={<TeamRosterPage />} />
          <Route path={NAV_TEAM_ANTHROPOMETRY} element={<TeamAnthropometryPage />} />
          <Route path={NAV_TEAM_MEAL_PLANS} element={<TeamMealPlansPage />} />
          <Route path={NAV_TEAM_NOTES} element={<TeamNotesPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

import { Grid, SxProps } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { useMyScoringSystemQuery } from "apps/web/src/types";
import React, { Dispatch } from "react";
import MenuItemOrderDetails from "../OrderDetails/Details";
import { MenuItemAction, MenuItemState } from "../reducer";
import { AddOns } from "./AddOns";
import { Image } from "./Image";
import { Ingredients } from "./Ingredients";
import { ItemDetails } from "./ItemDetails";

interface MenuItemFullEditFormProps {
  variant: "MenuItem" | "MenuItemAppearance";
  sx?: SxProps;
  state: MenuItemState;
  dispatch: Dispatch<MenuItemAction>;
  onAiGenerateClicked?: null | (() => void);
  onAiTranscribeClicked?: null | (() => void);
}

export const MenuItemFullEditForm = ({
  variant,
  sx,
  state,
  dispatch,
  onAiGenerateClicked,
  onAiTranscribeClicked,
}: MenuItemFullEditFormProps) => {
  const { maxAmount, availableForOrder, allowSpecialRequests } = state;
  const { name, choices, servingAmounts, imageUrl } = state.menuItem;
  const { data: scoringSystemData, loading: loadingScoringSystem } = useMyScoringSystemQuery();
  const scoringSystem = scoringSystemData?.myScoringSystem;

  const hasIngredients = state.menuItem.servingAmounts.length > 0;

  return (
    <>
      <Grid
        container
        spacing={4}
        direction="row"
        sx={sx}>
        {/* Left Panel */}
        <Grid item xs={8}>
          <Grid
            container
            direction={"row"}
            spacing={3}>
            {/** Details and Order Details row */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {loadingScoringSystem ? <Loading /> : <ItemDetails
                    state={state}
                    dispatch={dispatch}
                    scoringSystem={scoringSystem} />}
                </Grid>
                <Grid item xs={6}>
                  <MenuItemOrderDetails
                    maxAmount={maxAmount}
                    availableForOrder={availableForOrder}
                    allowSpecialRequests={allowSpecialRequests}
                    variant={variant}
                    onChangeMaxAmount={ma =>
                      dispatch({
                        type: "MenuItemChangeMaxAmountAction",
                        payload: {
                          maxAmount: ma,
                        },
                      })
                    }
                    onChangeAvailableForOrder={ao =>
                      dispatch({
                        type: "MenuItemChangeAvailableForOrderAction",
                        payload: {
                          availableForOrder: ao,
                        },
                      })
                    }
                    onChangeAllowSpecialRequests={sr =>
                      dispatch({
                        type: "MenuItemChangeAllowSpecialRequestsAction",
                        payload: {
                          allowSpecialRequests: sr,
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Ingredients and Add ons row */}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Ingredients
                    itemName={name}
                    servingAmounts={servingAmounts}
                    onChangeServingAmounts={payload =>
                      dispatch({
                        type: "MenuItemChangeServingAmountsAction",
                        payload,
                      })
                    }
                    onChangeScore={payload =>
                      dispatch({
                        type: "MenuItemChangeScoreAction",
                        payload,
                      })
                    }
                    onAiGenerateClicked={onAiGenerateClicked}
                    onAiTranscribeClicked={onAiTranscribeClicked}
                    hasIngredients={hasIngredients}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AddOns choices={choices} onChangeChoices={payload => dispatch({ type: "MenuItemChangeChoicesAction", payload })} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Image
            imageUrl={imageUrl}
            onChangeImage={payload =>
              dispatch({
                type: "MenuItemChangeImageAction",
                payload,
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

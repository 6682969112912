import { z } from "zod";
import { BrandedFoodSchema } from "./FoodSchema";

export const RestaurantFoodSchema = BrandedFoodSchema.merge(
  z.object({
    source: z.literal("restaurant"),
    restaurantId: z.string().uuid(),

    // restaurant foods dont have a brand
    brandId: z.null().optional(),
    brand: z.null().optional(),
  })
);

import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import { useUser } from "apps/web/src/utils/tokens";
import React, { useState } from "react";
import { OrgFoodModal } from "../../../../components/Food/Org/OrgFoodModal";
import BaseFoodTable, {
  RenderFormModalArgs,
  UseFoodDataOffsetConnectionArgs,
  UseFoodDataOffsetConnectionPayload,
} from "../../../../components/Food/Table/BaseFoodTable";
import {
  useDeleteOrgFoodMutation,
  useOrgEditableFoodsOffsetConnectionQuery,
  useOrgGroupFoodsOffsetConnectionQuery,
} from "../../../../types";

export const FoodsPage = () => {
  const apolloClient = useApolloClient();
  const orgGroupFoodsEnabled = useHasFeature("orgGroupFoods");

  const ORG_FOODS = "Organization Foods";
  const ORG_GROUP_FOODS = "Org Group Foods";
  const [selected, setSelected] = useState(ORG_FOODS);

  const user = useUser();

  const useOrgFoodDataOffsetConnection = ({
    limit,
    offset,
    query,
  }: UseFoodDataOffsetConnectionArgs): UseFoodDataOffsetConnectionPayload => {
    const { data, loading, error } = useOrgEditableFoodsOffsetConnectionQuery({
      variables: { pagination: { limit, offset }, query },
      fetchPolicy: "network-only",
    });

    return {
      foods: data?.orgFoodOffsetConnection.edges,
      pageInfo: data?.orgFoodOffsetConnection.pageInfo,
      loading,
      error,
    };
  };

  const useOrgGroupFoodDataOffsetConnection = ({
    limit,
    offset,
    query,
    ownerIds,
  }: UseFoodDataOffsetConnectionArgs): UseFoodDataOffsetConnectionPayload => {
    const { data, loading, error } = useOrgGroupFoodsOffsetConnectionQuery({
      variables: { pagination: { limit, offset }, query, ownerIds: ownerIds },
      fetchPolicy: "network-only",
    });

    return {
      foods: data?.orgGroupFoodOffsetConnection.edges,
      pageInfo: data?.orgGroupFoodOffsetConnection.pageInfo,
      loading,
      error,
    };
  };

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "orgFoodOffsetConnection",
      broadcast: true,
    });
    apolloClient.cache.evict({
      fieldName: "orgGroupFoodOffsetConnection",
      broadcast: true,
    });
  };

  const [deleteOrgFood] = useDeleteOrgFoodMutation({
    onCompleted: () => {
      resetTable();
    },
  });

  const onRemoveFood = (id: string) => {
    deleteOrgFood({
      variables: {
        input: { id },
      },
    });
  };

  return (
    <BaseFoodTable
      title={
        orgGroupFoodsEnabled ? <TWTabGroup tabs={[ORG_FOODS, ORG_GROUP_FOODS]} onSelected={selected => setSelected(selected)} /> : <Box />
      }
      resetTable={resetTable}
      useFoodDataOffsetConnection={selected === ORG_FOODS ? useOrgFoodDataOffsetConnection : useOrgGroupFoodDataOffsetConnection}
      onRemoveFood={onRemoveFood}
      renderFoodModal={({ initialFormState, onClose, isOpen, resetTable: _resetTable, row }: RenderFormModalArgs) => {
        return (
          <OrgFoodModal
            readonly={row ? row.orgGroupId !== null && row.ownerId !== user?.id : false}
            initialFormState={initialFormState}
            onClose={onClose}
            open={isOpen}
            resetTable={_resetTable}
          />
        );
      }}
      tableMode={selected === ORG_FOODS ? "org" : "org-group"}
      mode="org"
    />
  );
};

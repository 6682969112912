import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { MacroMealPlanDisplaySettingsFragment } from "../../types";
import { MacrosDisplaySettingsPreview } from "./DisplaySettingsPreviews";
import { macroProgressViewsTypeToName } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRow: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      width: "100%",
      flexDirection: "column",
    },
    dropDownGroup: {
      padding: theme.spacing(2),
    },
    progressView: {
      border: "1px solid grey",
      borderRadius: theme.spacing(0.5),
      backgroundColor: "white",
      marginTop: theme.spacing(),
    },
  })
);

interface MealPlanDisplaySettingsMacroWithMobileExportFormProps {
  displaySettings: MacroMealPlanDisplaySettingsFragment;
}

const MealPlanDisplaySettingsExchangeWithMobileExportForm = ({
  displaySettings,
}: MealPlanDisplaySettingsMacroWithMobileExportFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.formRow}>
      <div className={classes.dropDownGroup}>
        <Typography>
          <b>Daily:</b> {macroProgressViewsTypeToName(displaySettings.dailyProgressViews)}
        </Typography>
        <div className={classes.progressView}>
          <MacrosDisplaySettingsPreview macroProgressViews={displaySettings.dailyProgressViews} />
        </div>
      </div>

      <div className={classes.dropDownGroup}>
        <Typography>
          <b>Meal:</b> {macroProgressViewsTypeToName(displaySettings.mealProgressViews)}
        </Typography>
        <div className={classes.progressView}>
          <MacrosDisplaySettingsPreview macroProgressViews={displaySettings.mealProgressViews} />
        </div>
      </div>
    </div>
  );
};

export default MealPlanDisplaySettingsExchangeWithMobileExportForm;

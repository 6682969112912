import gql from "graphql-tag";

export default gql`
  type MealPlan {
    isAutosaving: Boolean!
  }

  interface MealTemplate {
    isAutosaving: Boolean!
    selectedMealOptionId: ID
    newMealOptionIds: [ID!]!
  }

  type MacroMealTemplate {
    choInput: String!
    proInput: String!
    fatInput: String!
    isAutosaving: Boolean!
    selectedMealOptionId: ID
    newMealOptionIds: [ID!]!
  }

  type ExchangeMealTemplate {
    isAutosaving: Boolean!
    selectedMealOptionId: ID
    newMealOptionIds: [ID!]!
  }

  type MealOption {
    isAutosaving: Boolean!
  }

  type MealOptionPending {
    isAutosaving: Boolean!
  }

  type ExchangeAmount {
    amountInput: String!
  }

  enum MacroType {
    cho
    pro
    fat
  }

  input EditMealOptionLocalInput {
    mealOptionId: ID!
    servingAmounts: [ServingAmountLocalInput!]!
    position: Int!
    note: String!
    name: String!
  }

  input EditMealOptionPendingLocalInput {
    mealOptionPendingId: ID!
    servingAmounts: [ServingAmountLocalInput!]!
    note: String!
    name: String!
  }

  input ServingAmountLocalInput {
    servingId: String!
    amount: Int!
    position: Int!
  }

  type Query {
    usdaFoods(searchTerm: String!, apiKey: String!, brandOwner: String!, includedDataTypes: String!): [USDAFood!]!
    usdaFoodDetail(usdaFdcId: ID!, apiKey: String!): USDAFoodDetail!
    nutritionixBrandedInstantSearch(query: String!, region: Int!): NutritionixBrandedInstantSearchPayload!
    nutritionixCommonInstantSearch(query: String!, localeCode: String!): NutritionixCommonInstantSearchPayload!
    nutritionixV1RestaurantSearch(input: NutritionixV1RestaurantSearchInput!): NutritionixV1RestaurantSearchPayload!
    nutritionixRestaurantLocationSearch(input: NutritionixRestaurantLocationSearchInput!): NutritionixRestaurantLocationSearchPayload!
  }

  type Mutation {
    changeMacroTarget(mealTemplateId: ID!, macro: MacroType!, target: String!): ID
    changeExchangeTarget(mealTemplateId: ID!, exchangeId: ID!, target: String!, pickListServingIds: [ID!]): ID
    changeExchangeTargetTotal(mealPlanId: ID!, exchangeId: ID!, target: String!): ID

    distributeMacroTargets(mealPlanId: ID!): ID
    distributeExchangeTargets(mealPlanId: ID!): ID
    calculateExchangeTotals(mealPlanId: ID!): ID
    deselectNegativeFoods(mealPlanId: ID!): ID

    selectMealOption(mealTemplateId: ID!, type: String!, mealOptionId: ID!): ID
    addMealOptionLocal(mealTemplateId: ID!, type: String!): ID
    editMealOptionLocal(input: EditMealOptionLocalInput!): ID
    editMealOptionPendingLocal(input: EditMealOptionPendingLocalInput!): ID
    removeMealOptionLocal(mealTemplateId: ID!, type: String!, mealOptionId: ID!): ID
    removeMealOptionPendingLocal(mealTemplateId: ID!, type: String!, mealOptionPendingId: ID!): ID
    shareMealOptionPendingLocal(mealTemplateId: ID!, mealOptionPendingId: ID!): ID
  }

  type Serving {
    cho: Float!
    pro: Float!
    fat: Float!
  }

  type ExchangeSet {
    foodOrRecipeExchangeRatios: [FoodOrRecipeExchangeRatios!]!
  }

  enum USDAFoodDataType {
    Branded
    Survey
    Legacy
    Foundation
  }

  type USDAFood {
    id: ID!
    allHighlightFields: [String!]!
    brandOwner: String!
    dataType: USDAFoodDataType!
    description: String!
    gtinUpc: String!
    ingredients: [String!]!
    publishedDate: Date!
    score: Float!
  }

  type USDAFoodServing {
    idx: Int!
    cho: Float!
    pro: Float!
    fat: Float!
    weight: Float!
    defaultAmount: Float!
    units: String!
    isDefault: Boolean!
    usdaWeightSeq: String!
  }

  type USDAFoodDetail {
    id: ID!
    usdaFdcFoodCategoryId: Int!
    usdaFdcDataType: String!
    cho: USDAFoodNutrient!
    pro: USDAFoodNutrient!
    fat: USDAFoodNutrient!
    foodNutrients: [USDAFoodNutrient!]!
    servings: [USDAFoodServing!]!
  }

  type USDAFoodNutrient {
    id: ID!
    amount: Float!
    min: Float!
    max: Float!
    median: Float!
    dataPoints: Int!
    name: String!
    unitName: String!
    footnote: String!
  }

  type USDAFoodCategory {
    code: String!
    description: String!
    id: ID!
  }

  type NutritionixCommonFood {
    id: ID!
    name: String!
    nixTagId: String!
    thumbnailUrl: String
  }

  type NutritionixBrandedFood {
    id: ID!
    name: String!
    nixItemId: String!
    brand: NutritionixBrand!
    thumbnailUrl: String
  }

  type NutritionixBrand {
    id: ID!
    name: String!
    type: Int!
  }

  type NutritionixBrandedInstantSearchPayload {
    branded: [NutritionixBrandedFood!]!
  }

  type NutritionixCommonInstantSearchPayload {
    common: [NutritionixCommonFood!]!
  }

  type NutritionixV1RestaurantSearchPayload {
    total: Int!
    branded: [NutritionixBrandedFood!]!
  }

  input NutritionixV1RestaurantSearchFilter {
    brand_id: String!
    item_type: Int!
  }

  input NutritionixV1RestaurantSearchSort {
    field: String!
    order: String!
  }

  input NutritionixV1RestaurantSearchInput {
    appId: String!
    appKey: String!
    fields: [String!]!
    filters: NutritionixV1RestaurantSearchFilter!
    offset: Int!
    limit: Int!
    sort: NutritionixV1RestaurantSearchSort!
  }

  type NutritionixRestaurantLocation {
    id: ID!
    brandId: ID!
    address: String!
    address2: String
    city: String!
    state: String!
    zip: String!
    country: String!
    lat: String
    long: String
    phoneNumber: String
  }

  type NutritionixRestaurantLocationSearchPayload {
    locations: [NutritionixRestaurantLocation!]!
  }

  input NutritionixRestaurantLocationSearchData {
    ll: String!
    distance: Int!
    brandId: String
  }

  input NutritionixRestaurantLocationSearchInput {
    appId: String!
    appKey: String!
    data: NutritionixRestaurantLocationSearchData!
  }

  type FoodOrRecipeExchangeRatios {
    id: ID!
    foodOrRecipeId: ID!
    exchangeRatios: [ExchangeRatio!]!
  }

  type ExchangeRatio {
    exchange: Exchange!
    ratio: Float!
  }
`;

import { CbordServiceMenuFragment, CbordMenuItemFragment, ImportCbordMenusInput } from "../../../../types";

export interface DateWithServiceMenus {
  date: string;
  serviceMenus: {
    oid: number;
    meal: string;
    stations: readonly CbordServiceStation[];
  }[];
}

interface CbordServiceStation {
  oid: number;
  sort: string;
  name: string;
  menuItems: readonly {
    itemOid: number;
    name: string;
    servingName: string;
  }[];
}

export interface SelectedServiceUnitInfo {
  oid: number;
  facilityOid: number | null;
}

export const groupServiceMenusByDate = (
  dates: string[],
  serviceMenus: readonly CbordServiceMenuFragment[]
): readonly DateWithServiceMenus[] => {
  const serviceMenusWithDates = serviceMenus.map(sm => {
    const date = sm.serviceDate.split("T")[0];
    return {
      ...sm,
      date,
    };
  });

  return dates.map(date => {
    const serviceMenus = serviceMenusWithDates.filter(sm => sm.date === date);
    return {
      date,
      serviceMenus: serviceMenus.map(sm => ({
        oid: sm.oid,
        meal: sm.meal,
        stations: groupMenuItems(sm.menuItems),
      })),
    };
  });
};

const groupMenuItems = (menuItems: readonly CbordMenuItemFragment[]): readonly CbordServiceStation[] => {
  return menuItems.reduce<readonly CbordServiceStation[]>((stations, menuItem) => {
    if (!menuItem.serviceStationOid || !menuItem.serviceStationSort || !menuItem.serviceStationName) {
      return stations;
    }
    const matchingStation = stations.find(s => s.oid === menuItem.serviceStationOid);
    if (matchingStation) {
      return [
        ...stations.filter(s => s !== matchingStation),
        {
          ...matchingStation,
          menuItems: [
            ...matchingStation.menuItems,
            {
              itemOid: menuItem.itemOid,
              name: menuItem.serviceName,
              servingName: menuItem.servedUofmName,
            },
          ],
        },
      ];
    } else {
      return [
        ...stations,
        {
          oid: menuItem.serviceStationOid,
          sort: menuItem.serviceStationSort,
          name: menuItem.serviceStationName,
          menuItems: [
            {
              itemOid: menuItem.itemOid,
              name: menuItem.serviceName,
              servingName: menuItem.servedUofmName,
            },
          ],
        },
      ];
    }
  }, []);
};

export const getImportCbordMenuGroups = (
  datesWithServiceMenus: readonly DateWithServiceMenus[],
  selectedMenuOids: readonly number[],
  baseMenuName: string
): ImportCbordMenusInput["menuGroups"] => {
  return datesWithServiceMenus.flatMap(({ serviceMenus }) => {
    const baseMenu = serviceMenus.find(m => m.meal === baseMenuName);
    const importMenus = serviceMenus.filter(m => selectedMenuOids.includes(m.oid) && m.meal !== baseMenuName);
    if (importMenus.length === 0) {
      return [];
    }
    return {
      baseServiceMenuOid: baseMenu?.oid ?? null,
      serviceMenuOids: importMenus.map(m => m.oid),
    };
  });
};

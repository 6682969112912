import axios from "axios";
import { Buffer } from "buffer";
import { FileTypeResult } from "file-type";

interface uploadPhotoProps {
  resData: { signedPutRequest: string; url: string };
  imageUrl: string;
  imageType?: FileTypeResult;
}

/**
 * This function handles the upload for both web and mobile photos.
 * Returns the url of the image on AWS, which we get from the HTTP request
 * @param resData aquired slightly different for web and mobile uploads, the http response
 * @param imageUrl is the base64 image data provided by the user
 */
export const uploadPhotoToAWS = async ({ resData, imageUrl, imageType }: uploadPhotoProps) => {
  const base64Data = Buffer.from(imageUrl.replace(/^data:image\/\w+;base64,/, ""), "base64");
  const { signedPutRequest, url } = resData;
  await axios.put(signedPutRequest, base64Data, {
    headers: {
      "Content-Type": imageType ? imageType.mime : "image/jpeg",
      Accept: "*/*",
      "Content-Encoding": "base64",
    },
  });
  return url;
};

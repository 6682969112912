import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listColumn: {
      flex: 1,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      margin: theme.spacing(0, 2),
      overflow: "auto",
    },
  })
);

interface ListsColumnProps {
  className?: string;
  children: React.ReactNode;
}

const ListsColumn = ({ className, children }: ListsColumnProps) => {
  const classes = useStyles();
  return <div className={classNames(classes.listColumn, className)}>{children}</div>;
};

export default ListsColumn;

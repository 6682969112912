import React from "react";
import { TeamworksPositionFragment } from "../../../../../../types";
import PositionList from "./PositionList";

interface TeamworksPositionsListProps {
  selectedId: string | null;
  positions: readonly TeamworksPositionFragment[];
  onLink: (position: TeamworksPositionFragment) => void;
  onAdd: (position: TeamworksPositionFragment) => void;
}

const TeamworksPositionsList = ({ positions, ...props }: TeamworksPositionsListProps) => {
  const { selectedId } = props;
  return (
    <PositionList
      {...props}
      positions={positions.map(p => ({ ...p, name: p.label }))}
      title="Unlinked Teamworks Positions"
      columnMode={selectedId ? "Link" : "Add"}
    />
  );
};

export default TeamworksPositionsList;

import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useEffect } from "react";
import { SportState } from "../Sports/utils";
import { BaseTeamDialogProps } from "./BaseTeamDialog";
import TeamCreateDialog from "./TeamCreateDialog";
import TeamLinkDialog from "./TeamLinkDialog";
import { LinkedTeamState, NewTeamState, NotemealTeam, PendingTeam, TeamworksTeam } from "./reducer";

interface EditPendingTeamDialogProps extends Omit<BaseTeamDialogProps, "title" | "onConfirm" | "notemealTeam" | "teamworksTeam"> {
  pendingTeam: PendingTeam;
  onConfirm: (payload: NewTeamState | LinkedTeamState) => void;
  notemealTeams: readonly NotemealTeam[];
  teamworksTeams: readonly TeamworksTeam[];
}

const EditPendingTeamDialog = ({
  notemealTeams,
  teamworksTeams,
  pendingTeam,
  onConfirm,
  usedTeamNames: _usedTeamNames,
  ...restProps
}: EditPendingTeamDialogProps) => {
  const { setMessage } = useSnackbar();
  const notemealTeam = notemealTeams.find(notemealTeam => notemealTeam.id === pendingTeam.id) ?? null;
  const teamworksTeam = teamworksTeams.find(teamworksTeam => teamworksTeam.id === pendingTeam.teamworksId);

  const hasInvalidState = !teamworksTeam || (pendingTeam.__type === "Link" && !notemealTeam);
  const { onCancel } = restProps;
  useEffect(() => {
    if (hasInvalidState) {
      setMessage("error", "Unable to fetch edit info needed: Please delete and readd team");
      onCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInvalidState]);
  if (hasInvalidState) {
    return null;
  }

  const title = `Editing Pending Team: ${pendingTeam.name}`;
  const pendingName = pendingTeam.name.trim().toLowerCase();
  const usedTeamNames = _usedTeamNames.filter(name => name !== pendingName);

  const initialName = pendingTeam.name;
  const initialGender = pendingTeam.gender ?? "";
  const initialSportState: SportState =
    pendingTeam.sportState?.__type === "Add"
      ? {
          sportState: pendingTeam.sportState,
          syncWithoutSport: false,
          createNewSport: true,
        }
      : pendingTeam.sportState?.__type === "Link"
      ? {
          sportState: pendingTeam.sportState,
          syncWithoutSport: false,
          createNewSport: false,
        }
      : {
          sportState: null,
          syncWithoutSport: true,
          createNewSport: false,
        };

  const sharedState = {
    teamworksTeam,
    titleOverride: title,
    usedTeamNames,
    initialName,
    initialGender,
    initialSportState,
    ...restProps,
  };

  return pendingTeam.__type === "Link" && notemealTeam ? (
    <TeamLinkDialog
      notemealTeam={notemealTeam}
      onConfirm={linkedTeam => onConfirm({ ...linkedTeam, __type: "Link" })}
      {...sharedState} />
  ) : (
    <TeamCreateDialog onConfirm={newTeam => onConfirm({ ...newTeam, __type: "Add" })} {...sharedState} />
  );
};

export default EditPendingTeamDialog;

import { Avatar, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { roundMacros, scaleMacros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import MacrosSummaryLabel from "../Macros/SummaryLabel";
import { ServingWithFoodOrRecipeMacrosFragment } from "../types";

interface ServingAmountTooltipProps {
  children: React.ReactElement;
  amount: number;
  serving: ServingWithFoodOrRecipeMacrosFragment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    usdaFavicon: {
      marginRight: theme.spacing(),
      width: 16,
      height: 16,
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
  })
);

const ServingAmountTooltip = ({ children, amount, serving }: ServingAmountTooltipProps) => {
  const classes = useStyles();
  const { macros } = serving;
  const scaledMacros = roundMacros(scaleMacros(macros, amount));
  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 2],
            },
          },
        ],
      }}
      title={
        <>
          <MacrosSummaryLabel macros={scaledMacros} kcalPrefix variant="sm" />
          <br />
          {serving.foodOrRecipe.name}
          <br />
          {serving.foodOrRecipe.__typename === "GenericFood" && serving.foodOrRecipe.usdaFdcDescription && (
            <span className={classes.flexRow}>
              <Avatar variant="square" className={classes.usdaFavicon} src="/img/usda_favicon.ico" />
              {serving.foodOrRecipe.usdaFdcDescription}
            </span>
          )}
          {serving.foodOrRecipe.__typename === "Recipe" &&
            `Created by ${serving.foodOrRecipe.createdBy.firstName} ${serving.foodOrRecipe.createdBy.lastName}`}
        </>
      }
    >
      {children}
    </Tooltip>
  );
};

export default ServingAmountTooltip;

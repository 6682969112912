import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import WarningIcon from "@mui/icons-material/Warning";
import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import useSubscriptionClient, { WsClientStatus } from "../../ws";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "center",
    },
    statusName: {
      paddingLeft: theme.spacing(),
    },
  })
);

interface PollingFns {
  start: (ms: number) => void;
  stop: () => void;
}

interface WsClientStatusIndicatorProps {
  pollingFns?: PollingFns;
}

const SHORT_POLL_INTERVAL = 20 * 1000; //20s
const LONG_POLL_INTERVAL = 60 * 2 * 1000; //2m

const WsClientStatusIndicator = ({ pollingFns }: WsClientStatusIndicatorProps) => {
  const status = useSubscriptionClient();
  const [pollingInterval, setPollingInterval] = useState(status === WsClientStatus.CLOSED ? SHORT_POLL_INTERVAL : LONG_POLL_INTERVAL);
  useEffect(() => {
    if (pollingFns) {
      const { start, stop } = pollingFns;
      if (status === WsClientStatus.CLOSED) {
        setPollingInterval(SHORT_POLL_INTERVAL);
      } else if (pollingInterval === SHORT_POLL_INTERVAL) {
        setPollingInterval(LONG_POLL_INTERVAL);
      }
      stop();
      start(pollingInterval);
    }
  }, [status, pollingInterval]);
  const statusToMsg = (wsStatus: WsClientStatus) => {
    if (pollingInterval === SHORT_POLL_INTERVAL) {
      return `Updating orders every ${pollingInterval / 1000}s`;
    }
    switch (wsStatus) {
      case WsClientStatus.CLOSING:
        return "Not connnected.";
      case WsClientStatus.CLOSED:
        return "Not connnected.";
      case WsClientStatus.CONNECTING:
        return "Reconnecting...";
      case WsClientStatus.OPEN:
        return "Showing real-time orders.";
    }
  };
  const classes = useStyles();
  return (
    <div className={classes.flex}>
      {status === WsClientStatus.CLOSED || status === WsClientStatus.CLOSING ? (
        <WarningIcon color="warning" />
      ) : status === WsClientStatus.CONNECTING ? (
        <HourglassEmptyIcon />
      ) : (
        <CheckCircleIcon color="success" />
      )}
      <Typography
        component="em"
        color={status === WsClientStatus.CLOSED ? "error" : undefined}
        className={classes.statusName}
        variant="subtitle1"
      >
        {statusToMsg(status)}
      </Typography>
    </div>
  );
};

export default WsClientStatusIndicator;

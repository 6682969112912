import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import DisplayCard, { DisplayCardProps } from "../../../shared/DisplayCard";

export interface NotemealOnlyProfileForDisplay {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  isPending: boolean;
}

export const PROFILE_CARD_HEIGHT = 75;
export const PROFILE_CARD_ADDITIONAL_INFO_HEIGHT = 85;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: PROFILE_CARD_HEIGHT,
    },
    cardWithAdditionalInfo: {
      height: PROFILE_CARD_ADDITIONAL_INFO_HEIGHT,
    },
  })
);

interface ProfileCardProps<T> extends Omit<DisplayCardProps<T>, "primaryText" | "secondaryElement"> {
  getProfileForDisplay: (profile: T) => NotemealOnlyProfileForDisplay;
}

const ProfileCard = <T extends any>(props: ProfileCardProps<T>) => {
  const { element, getProfileForDisplay } = props;
  const { firstName, lastName, email, phoneNumber, isPending } = getProfileForDisplay(element);
  const classes = useStyles();

  const lastNameText = lastName ?? "<NO LAST NAME>";
  const firstNameText = firstName ?? "<NO FIRST NAME>";
  const isPendingText = isPending ? " (Pending)" : "";
  const primaryText = `${lastNameText}, ${firstNameText}${isPendingText}`;
  const emailText = email ?? "<NO EMAIL>";
  const phoneText = phoneNumber ?? "<NO PHONE>";
  let secondaryElement = `${emailText}, ${phoneText}`;

  return (
    <DisplayCard
      {...props}
      className={props.hasAdditionalProfileInfo ? classes.cardWithAdditionalInfo : classes.card}
      primaryText={primaryText}
      secondaryElement={secondaryElement}
    />
  );
};

export default ProfileCard;

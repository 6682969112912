import { MealPlanFormState } from "apps/web/src/components/MealPlan/Form/utils";
import { CopyMealPlanNewInput } from "apps/web/src/types";
import { MealPlanDateAssignment } from "../Content/Calendar/Form/types";
import { getOverrideMealPlanDateAssignmentInput } from "../Content/Calendar/Form/utils";

export const getCopyMealPlanInput = (
  athleteId: string,
  mealPlanId: string,
  { name, description, isShared }: MealPlanFormState,
  dateAssignmentState: MealPlanDateAssignment,
  isAutoSuggestionsEnabled: boolean
): CopyMealPlanNewInput | null => {
  const dateAssignment = getOverrideMealPlanDateAssignmentInput(dateAssignmentState);
  if (!dateAssignment) {
    return null;
  }

  return {
    athleteId,
    mealPlanId,
    name,
    description,
    isShared,
    isAutoSuggestionsEnabled,
    dateAssignment,
    copyFoodPreferences: true,
    copyPickListServingIds: true,
  };
};

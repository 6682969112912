import CloseIcon from "@mui/icons-material/Close";
import { Box, ListItem, SxProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { AthleteForCustomTagFragment } from "../../../../../types";
import NamedTagChipList from "../../../NamedTagChipList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      marginRight: theme.spacing(1),
    },
    innerContainer: {
      display: "flex",
      flex: 1,
    },
    outerContainer: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    close: {
      color: theme.palette.grey[500],
    },
    chip: {
      cursor: "pointer",
    },
  })
);

interface AthleteListItemProps {
  athlete: AthleteForCustomTagFragment;
  onClick: (athlete: AthleteForCustomTagFragment) => void;
  sx?: SxProps;
  showRemove?: boolean;
}

export const AthleteItemContent = ({ athlete }: Pick<AthleteListItemProps, "athlete">) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.name}>{`${athlete.lastName}, ${athlete.firstName} `}</Typography>
      <NamedTagChipList
        namedTags={athlete.myNamedTagsOffsetConnection.edges}
        namedTagsCount={athlete.myNamedTagsOffsetConnection.pageInfo.total}
        chipClassName={classes.chip}
      />
    </>
  );
};

const AthleteListItem = ({ athlete, onClick, sx, showRemove }: AthleteListItemProps) => {
  return (
    <ListItem onClick={() => onClick(athlete)} sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer", ...sx }}>
      <Box sx={{ display: "flex", flex: 1 }}>
        <AthleteItemContent athlete={athlete} />
      </Box>
      {showRemove && <CloseIcon sx={{ color: "grey.dark" }} />}
    </ListItem>
  );
};

export default React.memo(AthleteListItem);

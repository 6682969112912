import {
  EditFullMealOptionFragment,
  EditFullMealOptionFragmentDoc,
  EditMealOptionDocument,
  EditMealOptionMutation,
  EditMealOptionMutationVariables,
  FullMealOptionFragment,
  FullServingAmountFragment,
  FullServingFragment,
  FullServingFragmentDoc,
  MutationEditMealOptionLocalArgs,
  ServingAmountLocalInput,
} from "../../types";
import newId from "../../utils/newId";
import { ResolverContext } from "./types";
import { makeFragmentFuncs, makeFragmentFuncsWithInit } from "./utils";

export const initEditMealOptionFragment = (mealOption: FullMealOptionFragment): EditFullMealOptionFragment => {
  return {
    ...mealOption,
    isAutosaving: false,
  };
};

export const createMealOption = (position: number, id?: string) => ({
  id: id || newId(),
  __typename: "MealOption" as const,
  isAutosaving: false,
  servingAmounts: [],
  position,
  note: null,
  name: null,
});

const { getFragment, getFragmentOrInit, writeFragment } = makeFragmentFuncsWithInit(
  "MealOption",
  "EditFullMealOption",
  EditFullMealOptionFragmentDoc,
  initEditMealOptionFragment
);

const servingFragmentFuncs = makeFragmentFuncs<FullServingFragment>("Serving", "FullServing", FullServingFragmentDoc);

export const resolverMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isAutosaving: (src: FullMealOptionFragment, args: any, context: ResolverContext): boolean => {
    return getFragmentOrInit(src, context).isAutosaving;
  },
};

export const mutationResolverMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editMealOptionLocal: (src: any, { input }: MutationEditMealOptionLocalArgs, context: ResolverContext) => {
    const { mealOptionId, servingAmounts, position, note, name } = input;
    const editFragment = getFragment(mealOptionId, context);
    const newServingAmounts = servingAmountsFromLocalInputs(servingAmounts, context);
    const upToDate =
      areServingAmountsEqual(editFragment.servingAmounts, newServingAmounts) &&
      position === editFragment.position &&
      note === editFragment.note &&
      name === editFragment.name;

    writeFragment(
      {
        ...editFragment,
        servingAmounts: newServingAmounts,
        note,
        position,
        isAutosaving: !upToDate,
        name,
      },
      context
    );

    if (!editFragment.isAutosaving && !upToDate) {
      runEditMealOptionMutation(context, {
        input: {
          mealOptionId,
          mealOption: {
            note: note || null,
            name: name || null,
            position,
            servingAmounts: servingAmounts.map(({ servingId, amount, position }) => ({
              servingId,
              amount,
              position,
            })),
          },
        },
      });
    }
  },
};

const servingAmountsFromLocalInputs = (inputs: readonly ServingAmountLocalInput[], context: ResolverContext) => {
  return inputs.map(({ servingId, ...input }) => ({
    ...input,
    id: newId(),
    __typename: "ServingAmount" as const,
    serving: servingFragmentFuncs.getFragment(servingId, context),
  }));
};

const runEditMealOptionMutation = (context: ResolverContext, variables: EditMealOptionMutationVariables) => {
  const { mealOptionId, mealOption } = variables.input;
  const mutation = context.client.mutate;
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  mutation<EditMealOptionMutation, EditMealOptionMutationVariables>({
    mutation: EditMealOptionDocument,
    variables,
    // This prevents the mutation result from being used to update local state
    updateQueries: { GetEditMealPlan: record => record },
    update: (_, { data, errors }) => {
      if (errors) {
        console.error("Mutation failed!!!");
        return;
      }

      if (data) {
        const editFragment = getFragment(mealOptionId, context);
        const currentServingAmounts = editFragment.servingAmounts;
        const upToDate =
          areServingAmountsEqual(currentServingAmounts, data.editMealOption.mealOption.servingAmounts) &&
          mealOption.position === data.editMealOption.mealOption.position &&
          mealOption.note === data.editMealOption.mealOption.note &&
          mealOption.name === data.editMealOption.mealOption.name;

        if (!upToDate) {
          const newVariables = {
            input: {
              mealOptionId,
              mealOption: {
                name: mealOption.name || null,
                note: mealOption.note || null,
                position: mealOption.position,
                servingAmounts: currentServingAmounts.map(({ serving, position, amount }) => ({
                  servingId: serving.id,
                  position,
                  amount,
                })),
              },
            },
          };
          runEditMealOptionMutation(context, newVariables);
        } else {
          writeFragment(
            {
              ...editFragment,
              isAutosaving: false,
            },
            context
          );
        }
      }
    },
  });
};

const areServingAmountsEqual = (sa1: readonly FullServingAmountFragment[], sa2: readonly FullServingAmountFragment[]): boolean => {
  if (sa1.length !== sa2.length) return false;
  return sa1.every(({ serving, amount, position }) => {
    const matchingServingAmount = sa2.find(sa => sa.serving.id === serving.id);
    return matchingServingAmount && matchingServingAmount.amount === amount && matchingServingAmount.position === position;
  });
};

import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { IconButton, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import MenuItemAppearanceGroupControlLabel from "../../../components/MenuItemAppearance/GroupControlLabel";
import { MealMenuUserOrderLimitInput } from "../../../types";
import UserOrderLimit from "../SelectComponents/UserOrderLimit";
import { MenuDialogAction } from "../reducer";
import { MealMenuDiningStationState } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quickActions: {
      display: "flex",
    },
  })
);

interface MealMenuDiningStationQuickActionsProps {
  mealMenuDiningStations: readonly MealMenuDiningStationState[];
  dispatch: (action: MenuDialogAction) => void;
  userOrderLimit: MealMenuUserOrderLimitInput | null;
  onReorder?: () => void;
}

const MealMenuDiningStationQuickActions = ({
  mealMenuDiningStations,
  dispatch,
  userOrderLimit,
  onReorder,
}: MealMenuDiningStationQuickActionsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.quickActions}>
      <MenuItemAppearanceGroupControlLabel
        field="availableForOrder"
        text="Available For Order"
        menuItemAppearances={mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances)}
        disabled={!mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances).length}
        onChange={availableForOrder =>
          dispatch({
            type: "MenuAllItemsAvailableForOrderAction",
            payload: {
              availableForOrder,
            },
          })
        }
      />
      <MenuItemAppearanceGroupControlLabel
        field="allowSpecialRequests"
        text="Allow Special Requests"
        menuItemAppearances={mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances)}
        onChange={allowSpecialRequests =>
          dispatch({
            type: "MenuAllItemsAllowSpecialRequestsAction",
            payload: {
              allowSpecialRequests,
            },
          })
        }
        disabled={
          !mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances).length ||
          !mealMenuDiningStations.flatMap(mmds => mmds.menuItemAppearances).every(mia => mia.availableForOrder)
        }
      />
      <UserOrderLimit
        limit={userOrderLimit}
        onChange={value => {
          dispatch({
            type: "MenuAllItemsAllowOrderLimitAction",
            payload: {
              userOrderLimit: value !== null ? parseInt(value) : null,
            },
          });
        }}
      />
      {onReorder && (
        <Tooltip title="Re-order dining stations">
          <IconButton onClick={onReorder}>
            <LowPriorityIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default MealMenuDiningStationQuickActions;

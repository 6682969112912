import { Box, SxProps, Typography, TypographyProps, TypographyTypeMap } from "@mui/material";
import React from "react";

interface ScheduleKeyProps {
  titleProps?: TypographyProps | undefined;
  entries: readonly { id: string; label: string; color: string }[];
  sx?: SxProps;
}

const ScheduleKey = ({ titleProps, entries, sx }: ScheduleKeyProps) => {
  return (
    <Box sx={sx}>
      <Typography
        variant={titleProps?.variant}
        sx={{
          pb: 2,
          ...titleProps?.sx,
        }}
      >
        Schedule Key
      </Typography>
      {entries.map(({ id, label, color }) => (
        <ScheduleKeyEntry
          key={id}
          label={label}
          color={color} />
      ))}
    </Box>
  );
};

export default ScheduleKey;

export const ScheduleKeyEntry = ({
  label,
  color,
  textVariant,
}: {
  label: string;
  color: string;
  textVariant?: TypographyTypeMap["props"]["variant"];
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ mr: 1, height: 10, width: 10, borderRadius: "50%", backgroundColor: color }} />
      <Typography variant={textVariant}>{label}</Typography>
    </Box>
  );
};

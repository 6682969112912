import { Fragment } from "react";

import { sortByKey } from "@notemeal/utils/sort";

import Chip from "./Chip";

import { FullServingAmountFragment } from "../../types";

interface ServingAmountViewChipListProps {
  servingAmounts: readonly FullServingAmountFragment[];
  hideLimitedAccessWarning?: boolean;
  onRemoveServing?: (servingAmountId: string) => void;
}

const ServingAmountViewChipList = ({ servingAmounts, hideLimitedAccessWarning, onRemoveServing }: ServingAmountViewChipListProps) => {
  const sortedServingAmounts = sortByKey(servingAmounts, "position");

  return (
    <Fragment>
      {sortedServingAmounts.map(sa => (
        <Chip
          key={sa.serving.id}
          servingAmount={sa}
          hideLimitedAccessWarning={hideLimitedAccessWarning}
          onRemoveServing={onRemoveServing}
        />
      ))}
    </Fragment>
  );
};

export default ServingAmountViewChipList;

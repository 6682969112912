import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { AthleteSchema, NamedTagsSchema, RequireEmailAthleteSchema } from "@notemeal/validators";
import { z } from "zod";
import {
  AthleteFormInput,
  AthleteSummaryAthleteFragment,
  EditAthleteInput,
  EditSyncedAthleteInput,
  NamedTagForSelectionFragment,
  SyncedAthleteFormInput,
} from "../../../types";
import { coercePhoneNumber } from "../../global/PhoneNumberInput";

export const AthleteFormSchema = AthleteSchema.extend({
  namedTags: NamedTagsSchema,
});
export const AthleteFormRequireEmailSchema = RequireEmailAthleteSchema.extend({
  namedTags: NamedTagsSchema,
});

export type AthleteFormOptionalEmailType = z.infer<typeof AthleteFormSchema>;
export type AthleteFormRequireEmailType = z.infer<typeof AthleteFormRequireEmailSchema>;
export type AthleteFormType = AthleteFormOptionalEmailType | AthleteFormRequireEmailType;

export const athleteFormDefaultValues = (teamId: string, athlete?: AthleteSummaryAthleteFragment): Partial<AthleteFormType> => ({
  firstName: athlete?.firstName ?? "",
  lastName: athlete?.lastName ?? "",
  sex: athlete?.sex,
  birthDate: athlete?.birthDate ? parseDate(athlete.birthDate) : null,
  email: athlete?.email ?? "",
  phoneNumber: athlete?.phoneNumber ?? "",
  position: athlete?.position ?? null,
  inbodyUid: athlete?.inbodyUid ?? "",
  secaUid: athlete?.secaUid ?? "",
  jerseyNumber: athlete?.jerseyNumber ?? "",
  teamId,
  namedTags: athlete?.allOrderedNamedTags.map(({ tag, tagName }) => ({ tag, tagName })) ?? [],
});

export const athleteFormToInput = (
  { position, birthDate, namedTags, ...state }: Omit<AthleteFormType, "teamId">,
  existingTags: readonly NamedTagForSelectionFragment[]
): AthleteFormInput => {
  const existingAthleteTagIds = existingTags.flatMap(({ tag: { __typename, id } }) => (__typename === "AthleteTag" ? id : []));
  const newAthleteTagIds = namedTags.flatMap(({ tag: { __typename, id } }) => (__typename === "AthleteTag" ? id : []));

  const addedTagIds = newAthleteTagIds.filter(id => !existingAthleteTagIds.includes(id));
  const removedTagIds = existingAthleteTagIds.filter(id => !newAthleteTagIds.includes(id));
  return {
    ...state,
    email: state.email || null,
    phoneNumber: coercePhoneNumber(state.phoneNumber) || null,
    positionId: position && position.id,
    birthDate: birthDate && serializeDate(birthDate),
    inbodyUid: state.inbodyUid,
    secaUid: state.secaUid,
    addedTagIds,
    removedTagIds,
  };
};

export const athleteFormToSyncedInput = (
  {
    sex,
    jerseyNumber,
    inbodyUid,
    secaUid,
    namedTags,
    position,
    birthDate,
  }: Pick<AthleteFormType, "sex" | "jerseyNumber" | "inbodyUid" | "secaUid" | "namedTags" | "position" | "birthDate">,
  existingTags: readonly NamedTagForSelectionFragment[]
): SyncedAthleteFormInput => {
  const existingAthleteTagIds = existingTags.flatMap(({ tag: { __typename, id } }) => (__typename === "AthleteTag" ? id : []));
  const newAthleteTagIds = namedTags.flatMap(({ tag: { __typename, id } }) => (__typename === "AthleteTag" ? id : []));

  const addedTagIds = newAthleteTagIds.filter(id => !existingAthleteTagIds.includes(id));
  const removedTagIds = existingAthleteTagIds.filter(id => !newAthleteTagIds.includes(id));
  return {
    sex,
    jerseyNumber,
    inbodyUid,
    secaUid,
    addedTagIds,
    removedTagIds,
    positionId: position && position.id,
    birthDate: birthDate && serializeDate(birthDate),
  };
};

export const athleteFormToEditInput = (
  { teamId, ...state }: AthleteFormType,
  athleteId: string,
  existingTags: readonly NamedTagForSelectionFragment[]
): EditAthleteInput => ({
  athleteId,
  athlete: athleteFormToInput(state, existingTags),
  teamId,
});

export const syncedAthleteForToEditInput = (
  state: Pick<AthleteFormType, "sex" | "jerseyNumber" | "inbodyUid" | "secaUid" | "namedTags" | "birthDate" | "position">,
  athleteId: string,
  existingTags: readonly NamedTagForSelectionFragment[]
): EditSyncedAthleteInput => ({
  athleteId,
  athlete: athleteFormToSyncedInput(state, existingTags),
});

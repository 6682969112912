import axios from "axios";
import { JwtUser } from "@notemeal/shared/ui/utils/tokens";
import { useSlackFoodRequestsUrlQuery } from "../types";
import { useUser } from "./tokens";

interface BaseRequestArgs {
  onSuccess?: (requestText: string) => void;
  onFailure?: (requestText: string) => void;
}

interface SendFoodRequestArgs extends BaseRequestArgs {
  mode: "food";
  foodRequest: string;
}

interface SendServingRequestArgs extends BaseRequestArgs {
  mode: "serving";
  servingRequest: string;
  foodName: string;
}

type SendFoodOrServingRequestArgs = SendFoodRequestArgs | SendServingRequestArgs;

export const useSendFoodOrRecipeRequest = (): ((args: SendFoodOrServingRequestArgs) => void) => {
  const user = useUser();
  const { data } = useSlackFoodRequestsUrlQuery();

  if (!user) {
    console.warn("Must be logged in to useSendFoodOrRecipeRequest() hook");
    return (args: SendFoodOrServingRequestArgs) => {
      console.error("Can't sendFoodOrRecipeRequest to slack when not logged in!");
    };
  }

  return (args: SendFoodOrServingRequestArgs) => {
    if (!data) {
      return;
    }
    runFoodOrServingRequest({
      user,
      slackWebHookUrl: data.slackFoodRequestsUrl,
      ...args,
    });
  };
};

type runFoodOrServingRequestArgs = SendFoodOrServingRequestArgs & {
  slackWebHookUrl: string;
  user: JwtUser;
};

interface Attachment {
  pretext: string;
  text: string;
  color: string;
  ts?: number;
}

const runFoodOrServingRequest = ({
  slackWebHookUrl,
  user,
  onSuccess = () => {},
  onFailure = () => {},
  ...requestInfo
}: runFoodOrServingRequestArgs) => {
  const pretext = requestInfo.mode === "food" ? "*New Food Request*" : "*New Serving Request*";
  const color = requestInfo.mode === "food" ? "#CC0000" : "#0000CC";

  const requestText =
    requestInfo.mode === "food" ? requestInfo.foodRequest : `${requestInfo.servingRequest} for food ${requestInfo.foodName}`;
  const textStrings: string[] = [
    `*User: * ${user.username}`,
    `*Org: * ${user.orgMembership ? user.orgMembership.org.name : "<No OrgMembership>"}`,
    `*Request: * ${requestText}`,
  ];

  const text = textStrings.join("\r\n");

  const attachment: Attachment = {
    pretext,
    text,
    color,
    ts: new Date().getTime(),
  };

  axios({
    url: slackWebHookUrl,
    method: "post",
    data: JSON.stringify({
      attachments: [attachment],
    }),
  })
    .then(res => {
      onSuccess(requestText);
    })
    .catch(err => {
      onFailure(requestText);
      console.error(err);
    });
};

import { Box } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { ViewAttendanceDialog } from "apps/web/src/views/MenuAttendance/ViewAttendance/ViewAttendanceDialog";
import { addDays, addMinutes, startOfWeek } from "date-fns";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";
import CalendarWeek from "../../../../components/Calendar/Week";
import {
  ImportBonAppetitMenusInput,
  ImportCbordMenusInput,
  ImportCompassMenusInput,
  ImportSodexoMenusInput,
  useMealMenusInDateRangeQuery,
  useMenuCalendarQuery,
} from "../../../../types";
import BulkEditDialog from "../../../../views/Menus/BulkEditDialog";
import { BulkEditAction } from "../../../../views/Menus/BulkEditDialog/types";
import { CalendarActions } from "../../../../views/Menus/CalendarActions";
import CalendarDateHeader from "../../../../views/Menus/CalendarDateHeader";
import CalendarEventDefaultPaper from "../../../../views/Menus/CalendarEvent/Default/Paper";
import { MenusCalendarEventDefaultPopover as CalendarEventDefaultPopover } from "../../../../views/Menus/CalendarEvent/Default/Popover";
import CalendarEventFinishedPaper from "../../../../views/Menus/CalendarEvent/Finished/Paper";
import { MenusCalendarEventFinishedPopover as CalendarEventFinishedPopover } from "../../../../views/Menus/CalendarEvent/Finished/Popover";
import CalendarEventNewPaper from "../../../../views/Menus/CalendarEvent/New/Paper";
import CalendarEventNewPopover from "../../../../views/Menus/CalendarEvent/New/Popover";
import { CalendarFilters } from "../../../../views/Menus/CalendarFilters";
import { MenusDialog } from "../../../../views/Menus/Dialog";
import BonAppetitDialog from "../../../../views/Menus/Import/BonAppetit/BonAppetitDialog";
import CbordDialog from "../../../../views/Menus/Import/CbordDialog";
import CompassDialog from "../../../../views/Menus/Import/CompassDialog";
import { PlannedMenuImportProvider } from "../../../../views/Menus/Import/PlannedMenu/ImportPlannedMenuContext";
import { ImportPlannedMenuDialog } from "../../../../views/Menus/Import/PlannedMenu/ImportPlannedMenuDialog";
import SodexoDialog from "../../../../views/Menus/Import/Sodexo/SodexoDialog";
import { ALL as ALL_MENU_TYPES, MenuType } from "../../../../views/Menus/SelectComponents/MenuTypeSelect";
import WeekNavigation from "../../../../views/Menus/WeekNavigation";
import { onSaveMenuArgs, useOnSaveBulkMutations, useOnSaveDeleteMenu } from "../../../../views/Menus/hooks";
import { MealMenuInstance, MenuDialogState } from "../../../../views/Menus/types";
import { getFilteredMenus, getMealMenuInstances, getNewMenuDialogState, getSelectedWeek } from "../../../../views/Menus/utils";
import { getNavOrgKitchenMenuScheduleDate } from "./KitchenPaths";
import { DigitalDisplaysCreateDialog } from "apps/web/src/views/MenuBuilder/DigitalDisplays/CreateDialog";
interface BulkEditState {
  inBulkEdit: boolean;
  bulkEditMealMenuIds: Set<string>;
  finishedBulkEditMealMenuIds: Set<string>;
}

export const MenuSchedulePage = () => {
  const { setMessage } = useSnackbar();
  const { date, importId } = useParams<{ date: string; importId: string }>();
  const today = serializeDate(new Date());
  const [clientTimezone, setClientTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [importPlannedMenuId, setImportPlannedMenuId] = useState<string | null>(importId ?? null);
  const startOfWeekDate = startOfWeek(parseDate(date || today));
  const after = serializeDate(startOfWeekDate);
  const before = serializeDate(addDays(startOfWeekDate, 7));
  const [selectedDate, setSelectedDate] = useState<Date | null>(parseDate(date || today));
  const navigate = useNavigate();
  const location = useLocation();
  const [menuDialogState, setMenuDialogState] = useState<MenuDialogState | null>(null);
  const [showCheckBoxDate, setShowCheckBoxDate] = useState<string | null>(null);

  const variables = { before, after, clientTimezone };
  const { data, loading, refetch } = useMealMenusInDateRangeQuery({
    variables,
    fetchPolicy: "network-only",
  });

  const { data: mcData } = useMenuCalendarQuery();
  const allTeams = mcData ? mcData.teams : [];

  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);
  const [selectedMenuType, setSelectedMenuType] = useState<MenuType>(ALL_MENU_TYPES);

  const [compassDialogOpen, setCompassDialogOpen] = useState(false);
  const [cbordDialogOpen, setCbordDialogOpen] = useState(false);
  const [bonAppetitDialogOpen, setBonAppetitDialogOpen] = useState(false);
  const [sodexoDialogOpen, setSodexoDialogOpen] = useState(false);
  const [digitalDisplaysDialogOpen, setDigitalDisplaysDialogOpen] = useState(false);
  const [viewAttendanceDialogOpen, setViewAttendanceDialogOpen] = useState(false);

  const locationState = (location.state || {}) as any;

  const initialInBulkEdit = locationState.inBulkEdit || false;
  const [inBulkEdit, setInBulkEdit] = useState(initialInBulkEdit);

  const initialBulkEditMealMenuIds = locationState.bulkEditMealMenuIds ? new Set([...locationState.bulkEditMealMenuIds]) : new Set([]);
  const [bulkEditMealMenuIds, setBulkEditMealMenuIds] = useState<Set<string>>(initialBulkEditMealMenuIds);

  const initialFinishedBulkEditMealMenuIds = locationState.finishedBulkEditMealMenuIds
    ? new Set([...locationState.finishedBulkEditMealMenuIds])
    : new Set([]);
  const [finishedBulkEditMealMenuIds, setFinishedBulkEditMealMenuIds] = useState<Set<string>>(initialFinishedBulkEditMealMenuIds);

  const [selectedActionType, setSelectedActionType] = useState<BulkEditAction | null>(null);
  const [mealMenuIdForDigitalDisplay, setMealMenuIdForDigitalDisplay] = useState<string | null>(null);

  const {
    saving: baseSaving,
    onCreate,
    onEdit,
    onImportCompass,
    onImportCbord,
    onImportBonAppetit,
    onImportSodexo,
    onDelete,
  } = useOnSaveDeleteMenu(variables);

  const { saving: bulkSaving, onBulkEdit, onBulkDelete, onBulkCopy } = useOnSaveBulkMutations();

  const saving = baseSaving || bulkSaving;

  const handleCreate = (start?: Date) => {
    setMenuDialogState(getNewMenuDialogState(clientTimezone, start));
  };

  const handleSave = async (args: onSaveMenuArgs) => {
    if (args.state.__typename === "New") {
      await onCreate(args.state);
    } else {
      await onEdit({
        ...args,
        state: args.state,
      });
    }
    setMenuDialogState(null);
  };
  const handleImportCompass = async (input: ImportCompassMenusInput) => {
    await onImportCompass(input);
    setCompassDialogOpen(false);
  };
  const handleImportCbord = async (input: ImportCbordMenusInput) => {
    await onImportCbord(input);
    setCbordDialogOpen(false);
  };
  const handleImportBonAppetit = async (input: ImportBonAppetitMenusInput) => {
    await onImportBonAppetit(input);
    setBonAppetitDialogOpen(false);
  };
  const handleImportSodexo = async (input: ImportSodexoMenusInput) => {
    await onImportSodexo(input);
    setSodexoDialogOpen(false);
  };

  const handleDelete = async (menuDialogState: MenuDialogState) => {
    await onDelete(menuDialogState);
    setMenuDialogState(null);
  };

  const handleCloseImportPlannedMenu = () => {
    setImportPlannedMenuId(null);
  };

  const successImportPlannedMenu = (menuName: string) => {
    setMessage("success", `${menuName} has been published`);
    setImportPlannedMenuId(null);
  };

  const handleCloseEdit = () => setMenuDialogState(null);

  const handleSelectedDateChange = (date: Date) => {
    setSelectedDate(date);

    const state: BulkEditState = {
      inBulkEdit,
      bulkEditMealMenuIds,
      finishedBulkEditMealMenuIds,
    };
    navigate(getNavOrgKitchenMenuScheduleDate(serializeDate(date)), { state });
  };

  const weekRange = getSelectedWeek(selectedDate);

  const mealMenuInstances = getMealMenuInstances(!loading && data ? data.mealMenusInDateRange : [], clientTimezone);

  const updateHistoryState = (newState: BulkEditState) => {
    navigate(location.pathname, { state: newState });
  };

  const resetBulkState = () => {
    setBulkEditMealMenuIds(new Set([]));
    setFinishedBulkEditMealMenuIds(new Set([]));
    setInBulkEdit(false);
    updateHistoryState({
      inBulkEdit: false,
      bulkEditMealMenuIds: new Set([]),
      finishedBulkEditMealMenuIds: new Set([]),
    });
  };

  const updateBulkEditMealMenuIds = (newBulkEditMealMenuIds: Set<string>, newFinishedBulkEditMealMenuIds: Set<string>) => {
    setBulkEditMealMenuIds(newBulkEditMealMenuIds);
    setFinishedBulkEditMealMenuIds(newFinishedBulkEditMealMenuIds);
    updateHistoryState({
      inBulkEdit,
      bulkEditMealMenuIds: newBulkEditMealMenuIds,
      finishedBulkEditMealMenuIds: newFinishedBulkEditMealMenuIds,
    });
  };

  const updateInBulkEdit = (value: boolean) => {
    setInBulkEdit(value);
    updateHistoryState({
      inBulkEdit: value,
      bulkEditMealMenuIds,
      finishedBulkEditMealMenuIds,
    });
  };

  const handleBulkEditModalCancel = () => {
    setSelectedActionType(null);
    resetBulkState();
  };

  const handleBulkEditModalBack = () => {
    setSelectedActionType(null);
  };

  const handleBulkEditSelect = (mealMenu: MealMenuInstance, selected: boolean) => {
    if (selected) {
      const newBulkEditMealMenuIds = new Set([...bulkEditMealMenuIds, mealMenu.id]);
      updateBulkEditMealMenuIds(newBulkEditMealMenuIds, finishedBulkEditMealMenuIds);
    } else {
      const newBulkEditMealMenuIds = new Set([...bulkEditMealMenuIds].filter(id => id !== mealMenu.id));
      const newFinishedBulkEditMealMenuIds = new Set([...finishedBulkEditMealMenuIds].filter(id => id !== mealMenu.id));
      updateBulkEditMealMenuIds(newBulkEditMealMenuIds, newFinishedBulkEditMealMenuIds);
    }
  };

  const handleBulkEditSelectFinished = (mealMenu: MealMenuInstance, selected: boolean) => {
    if (selected) {
      const newBulkEditMealMenuIds = new Set([...bulkEditMealMenuIds, mealMenu.id]);
      const newFinishedBulkEditMealMenuIds = new Set([...finishedBulkEditMealMenuIds, mealMenu.id]);
      updateBulkEditMealMenuIds(newBulkEditMealMenuIds, newFinishedBulkEditMealMenuIds);
    } else {
      const newBulkEditMealMenuIds = new Set([...bulkEditMealMenuIds].filter(id => id !== mealMenu.id));
      const newFinishedBulkEditMealMenuIds = new Set([...finishedBulkEditMealMenuIds].filter(id => id !== mealMenu.id));
      updateBulkEditMealMenuIds(newBulkEditMealMenuIds, newFinishedBulkEditMealMenuIds);
    }
  };

  const getEndsBeforeCurrentTime = (mealMenu: MealMenuInstance) => {
    const currentTime = new Date();

    const mealMenuEnd = addMinutes(mealMenu.start, mealMenu.durationInMinutes);
    return mealMenuEnd.toISOString() < currentTime.toISOString();
  };

  const handleBulkEditMultiSelectMealMenuInstances = (mealMenus: readonly MealMenuInstance[], selected: boolean) => {
    if (selected) {
      const newBulkEditMealMenuIds = new Set([...bulkEditMealMenuIds, ...mealMenus.map(mm => mm.id)]);
      const newFinishedBulkEditMealMenuIds = new Set([
        ...finishedBulkEditMealMenuIds,
        ...mealMenus.filter(mm => getEndsBeforeCurrentTime(mm)).map(mm => mm.id),
      ]);
      updateBulkEditMealMenuIds(newBulkEditMealMenuIds, newFinishedBulkEditMealMenuIds);
    } else {
      const mealMenuIds = mealMenus.map(mm => mm.id);
      const newBulkEditMealMenuIds = new Set([...bulkEditMealMenuIds].filter(id => !mealMenuIds.includes(id)));
      const newFinishedBulkEditMealMenuIds = new Set([...finishedBulkEditMealMenuIds].filter(id => !mealMenuIds.includes(id)));
      updateBulkEditMealMenuIds(newBulkEditMealMenuIds, newFinishedBulkEditMealMenuIds);
    }
  };

  const handleViewAttendanceDialogClose = () => {
    setViewAttendanceDialogOpen(false);
  };

  const handleOpenDigitalDisplays = (mealMenuId: string) => {
    setMealMenuIdForDigitalDisplay(mealMenuId);
    setDigitalDisplaysDialogOpen(true);
  };

  // TODO: Filters on calendar view how will we handle this
  const filteredEvents = getFilteredMenus(mealMenuInstances, selectedTeamIds, selectedMenuType);

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Box
        sx={{
          width: 320,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <WeekNavigation
          startOfWeekDate={startOfWeekDate}
          sx={{ width: "fit-content", gap: 3, alignSelf: "stretch" }}
          onChange={handleSelectedDateChange}
        />
        <CalendarActions
          bulkEditMealMenuIds={bulkEditMealMenuIds}
          finishedBulkEditMealMenuIds={finishedBulkEditMealMenuIds}
          inEditMode={inBulkEdit}
          cancelBulkEdit={() => resetBulkState()}
          enableBulkEdit={() => updateInBulkEdit(true)}
          handleCreate={handleCreate}
          onOpenCompassDialog={() => setCompassDialogOpen(true)}
          onOpenCbordDialog={() => setCbordDialogOpen(true)}
          onOpenBonAppetitDialog={() => setBonAppetitDialogOpen(true)}
          onOpenSodexoDialog={() => setSodexoDialogOpen(true)}
          onOpenViewAttendanceDialog={() => setViewAttendanceDialogOpen(true)}
          setSelectedActionType={setSelectedActionType}
        />
        <CalendarFilters
          timezoneSelectProps={{
            value: clientTimezone,
            onChange: setClientTimezone,
          }}
          teamSelectProps={{
            allTeams: allTeams,
            selectedTeams: allTeams.filter(t => selectedTeamIds.includes(t.id)),
            onChange: teams => setSelectedTeamIds(teams.map(t => t.id)),
            displayedTeamsCount: 1,
          }}
          menuTypeSelectProps={{
            value: selectedMenuType,
            onChange: setSelectedMenuType,
          }}
        />
        <DateCalendar
          slotProps={{
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "info.main",
                },
              },
            },
          }}
          views={["year", "month", "day"]}
          value={selectedDate}
          onChange={date => {
            if (date !== null) {
              handleSelectedDateChange(date);
            }
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CalendarWeek
          sx={{ height: "100%" }}
          startOfWeek={weekRange.start}
          events={filteredEvents}
          clientTimezone={clientTimezone}
          renderEvent={{
            renderPaper: (mealMenu, args) => {
              const currentTime = new Date();
              const mealMenuEnd = addMinutes(mealMenu.start, mealMenu.durationInMinutes);
              const endBeforeCurrentTime = mealMenuEnd.toISOString() < currentTime.toISOString();

              return endBeforeCurrentTime ? (
                <CalendarEventFinishedPaper
                  key={mealMenu.id}
                  mealMenu={mealMenu}
                  args={args}
                  clientTimezone={clientTimezone}
                  inBulkEdit={inBulkEdit}
                  bulkEditSelected={bulkEditMealMenuIds.has(mealMenu.id)}
                  onBulkEditChangeFinished={selected => handleBulkEditSelectFinished(mealMenu, selected)}
                />
              ) : (
                <CalendarEventDefaultPaper
                  key={mealMenu.id}
                  mealMenu={mealMenu}
                  args={args}
                  clientTimezone={clientTimezone}
                  inBulkEdit={inBulkEdit}
                  onBulkEditChange={selected => handleBulkEditSelect(mealMenu, selected)}
                  bulkEditSelected={bulkEditMealMenuIds.has(mealMenu.id)}
                />
              );
            },
            renderPopover: (mealMenu, { anchorEl, onClose }) => {
              const currentTime = new Date();
              const mealMenuEnd = addMinutes(mealMenu.start, mealMenu.durationInMinutes);
              const endBeforeCurrentTime = mealMenuEnd.toISOString() < currentTime.toISOString();

              return endBeforeCurrentTime ? (
                <CalendarEventFinishedPopover
                  key={mealMenu.id}
                  mealMenu={mealMenu}
                  anchorEl={anchorEl}
                  onClose={onClose}
                  onOpenMenuDialogForCopy={setMenuDialogState}
                  clientTimezone={clientTimezone}
                />
              ) : (
                <CalendarEventDefaultPopover
                  key={mealMenu.id}
                  mealMenu={mealMenu}
                  anchorEl={anchorEl}
                  onClose={onClose}
                  onOpenMenuDialog={setMenuDialogState}
                  clientTimezone={clientTimezone}
                  onOpenDigitalDisplaysDialog={handleOpenDigitalDisplays}
                />
              );
            },
          }}
          renderNewEvent={{
            renderPaper: (newEvent, args) => <CalendarEventNewPaper
              newEvent={newEvent}
              args={args}
              clientTimezone={clientTimezone} />,
            renderPopover: (newEvent, { anchorEl, onClose }) => (
              <CalendarEventNewPopover
                anchorEl={anchorEl}
                onClose={onClose}
                onOpenMenuDialog={() => handleCreate(newEvent.start)} />
            ),
          }}
          renderDateHeader={props => (
            <CalendarDateHeader
              {...props}
              inBulkEdit={inBulkEdit}
              bulkEditMealMenuIds={bulkEditMealMenuIds}
              filteredMealMenuInstances={filteredEvents}
              handleBulkEditMultiSelectMealMenuInstances={handleBulkEditMultiSelectMealMenuInstances}
              showCheckBoxDate={showCheckBoxDate}
              setShowCheckBoxDate={setShowCheckBoxDate}
              clientTimezone={clientTimezone}
            />
          )}
        />
      </Box>
      {selectedActionType && (
        <BulkEditDialog
          isOpen={selectedActionType !== null}
          mealMenuIds={[...bulkEditMealMenuIds]}
          onCancel={handleBulkEditModalCancel}
          onBack={handleBulkEditModalBack}
          allTeams={allTeams}
          saving={saving}
          onBulkEdit={onBulkEdit}
          onBulkDelete={onBulkDelete}
          onBulkCopy={onBulkCopy}
          clientTimezone={clientTimezone}
          selectedActionType={selectedActionType}
        />
      )}
      {menuDialogState !== null && (
        <MenusDialog
          open={menuDialogState !== null}
          onClose={handleCloseEdit}
          onSave={handleSave}
          onDelete={handleDelete}
          saving={saving}
          initialState={menuDialogState}
          allTeams={allTeams}
        />
      )}
      {compassDialogOpen && (
        <CompassDialog
          open={compassDialogOpen}
          onClose={() => setCompassDialogOpen(false)}
          onImport={handleImportCompass}
          allTeams={allTeams}
          clientTimezone={clientTimezone}
          saving={saving}
        />
      )}
      {cbordDialogOpen && (
        <CbordDialog
          open={cbordDialogOpen}
          onClose={() => setCbordDialogOpen(false)}
          onImport={handleImportCbord}
          allTeams={allTeams}
          clientTimezone={clientTimezone}
          saving={saving}
        />
      )}
      {bonAppetitDialogOpen && (
        <BonAppetitDialog
          open={bonAppetitDialogOpen}
          onClose={() => setBonAppetitDialogOpen(false)}
          onImport={handleImportBonAppetit}
          allTeams={allTeams}
          clientTimezone={clientTimezone}
          saving={saving}
        />
      )}
      {sodexoDialogOpen && (
        <SodexoDialog
          open={sodexoDialogOpen}
          onClose={() => setSodexoDialogOpen(false)}
          onImport={handleImportSodexo}
          allTeams={allTeams}
          clientTimezone={clientTimezone}
          saving={saving}
        />
      )}
      {importPlannedMenuId && (
        <PlannedMenuImportProvider
          plannedMenuId={importPlannedMenuId}
          onClose={handleCloseImportPlannedMenu}
          onSuccess={successImportPlannedMenu}
          allTeams={allTeams}
        >
          <ImportPlannedMenuDialog refetch={() => refetch(variables)} />
        </PlannedMenuImportProvider>
      )}
      {digitalDisplaysDialogOpen && mealMenuIdForDigitalDisplay && (
        <DigitalDisplaysCreateDialog
          open={digitalDisplaysDialogOpen}
          onClose={() => {
            setMealMenuIdForDigitalDisplay(null);
            setDigitalDisplaysDialogOpen(false);
          }}
          mealMenuId={mealMenuIdForDigitalDisplay}
        />
      )}
      {viewAttendanceDialogOpen && <ViewAttendanceDialog open={viewAttendanceDialogOpen} onClose={handleViewAttendanceDialogClose} />}
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
import React, { memo } from "react";

import { sortByKey } from "@notemeal/utils/sort";
import { MenuItemAppearancePreviewReadonly } from "../../../components/MenuItemAppearance/MenuItemAppearancePreview/MenuItemAppearancePreviewReadonly";

import { MenuOrderItemCounts } from "apps/web/src/components/MenuItemAppearance/utils";
import { MealMenuDiningStationFormFragment } from "../../../types";

interface MealMenuDiningStationReadonlyFormProps {
  mealMenuDiningStation: MealMenuDiningStationFormFragment;
  orderItemCounts: MenuOrderItemCounts;
}

const MealMenuDiningStationReadonlyForm = ({ mealMenuDiningStation, orderItemCounts }: MealMenuDiningStationReadonlyFormProps) => {
  return (
    <>
      <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
        <Typography variant="h3">{mealMenuDiningStation.name}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {sortByKey(mealMenuDiningStation.menuItemAppearances, "position").map(mia => (
          <MenuItemAppearancePreviewReadonly
            key={mia.menuItem.id}
            sx={{ width: "calc(50% - 16px)", m: 1 }}
            menuItemAppearance={mia}
            orderCount={orderItemCounts[mia.menuItem.id] || 0}
          />
        ))}
      </Box>
    </>
  );
};

export default memo(MealMenuDiningStationReadonlyForm);

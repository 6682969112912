import { servingAmountsToInputs } from "@notemeal/shared/ui/ServingAmount/utils";
import { serializeDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import { OnSaveMenuItemArgs } from "apps/web/src/views/MenuItems/Dialog/Edit/MenuItemEditDialog";
import { getMenuItemChoiceDiffInputs, getMenuItemChoiceInput } from "../../MenuItemAddOns/utils";
import { MutationFunctionOptions } from "@apollo/client";
import {
  CreateReusableMenuItemInput,
  CreateReusableMenuItemMutation,
  EditReusableMenuItemInput,
  EditReusableMenuItemMutation,
  EditReusableMenuItemPartialInput,
  EditReusableMenuItemPartialMutation,
  Exact,
} from "apps/web/src/types";
import { MenuItemState } from "../reducer";

interface OnEditProps {
  state: OnSaveMenuItemArgs;
  editReusableMenuItem: (
    options?:
      | MutationFunctionOptions<
          EditReusableMenuItemMutation,
          Exact<{
            input: EditReusableMenuItemInput;
          }>
        >
      | undefined
  ) => void;
  editReusableMenuItemPartial: (
    options?:
      | MutationFunctionOptions<
          EditReusableMenuItemPartialMutation,
          Exact<{
            input: EditReusableMenuItemPartialInput;
          }>
        >
      | undefined
  ) => void;
}

export const onEdit = ({ editReusableMenuItem, editReusableMenuItemPartial, state }: OnEditProps) => {
  const { initial, final } = state;
  if (final.isRevision) {
    editReusableMenuItem({
      variables: {
        input: {
          menuItemId: final.menuItem.id,
          after: serializeDateTime(new Date()),
          name: final.menuItem.name,
          servingName: final.menuItem.servingName,
          description: final.menuItem.description,
          defaultMaxAmount: final.maxAmount,
          defaultAvailableForOrder: final.availableForOrder,
          defaultAllowSpecialRequests: final.allowSpecialRequests,
          servingAmounts: servingAmountsToInputs(final.menuItem.servingAmounts),
          ...getMenuItemChoiceDiffInputs({
            initial: initial.choices,
            final: final.menuItem.choices,
          }),
          imageUrl: final.menuItem.imageUrl,
          suggestionCategory: final.menuItem.suggestionCategory,
          scoreValue: final.menuItem.score?.value ?? null,
          foodCategory: final.menuItem.foodCategory?.category ?? null,
        },
      },
    });
  } else {
    editReusableMenuItemPartial({
      variables: {
        input: {
          id: final.menuItem.id,
          name: final.menuItem.name,
          servingName: final.menuItem.servingName,
          description: final.menuItem.description,
          defaultMaxAmount: final.maxAmount,
          defaultAvailableForOrder: final.availableForOrder,
          defaultAllowSpecialRequests: final.allowSpecialRequests,
          imageUrl: final.menuItem.imageUrl,
          suggestionCategory: final.menuItem.suggestionCategory,
          scoreValue: final.menuItem.score?.value ?? null,
          foodCategory: final.menuItem.foodCategory?.category ?? null,
        },
      },
    });
  }
};

interface OnCreateProps {
  createReusableMenuItem: (
    options?:
      | MutationFunctionOptions<
          CreateReusableMenuItemMutation,
          Exact<{
            input: CreateReusableMenuItemInput;
          }>
        >
      | undefined
  ) => void;
  state: MenuItemState;
}

export const onCreate = ({ createReusableMenuItem, state }: OnCreateProps) => {
  const { menuItem, maxAmount, availableForOrder, allowSpecialRequests } = state;
  createReusableMenuItem({
    variables: {
      input: {
        name: menuItem.name,
        servingName: menuItem.servingName,
        description: menuItem.description,
        defaultMaxAmount: maxAmount,
        defaultAvailableForOrder: availableForOrder,
        defaultAllowSpecialRequests: allowSpecialRequests,
        servingAmounts: servingAmountsToInputs(menuItem.servingAmounts),
        choices: menuItem.choices.map(getMenuItemChoiceInput),
        imageUrl: menuItem.imageUrl,
        suggestionCategory: menuItem.suggestionCategory,
        scoreValue: menuItem.score?.value ?? null,
        foodCategory: menuItem.foodCategory?.category ?? null,
      },
    },
  });
};

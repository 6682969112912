import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import ConfirmationDialog from "./ConfirmationDialog";

const ConfirmationDialogExample = () => {
  const [showStandardDialog, setShowStandardDialog] = useState(false);
  const [showDestructiveDialog, setShowDestructiveDialog] = useState(false);

  const closeStandardDialog = () => setShowStandardDialog(false);
  const closeDestructiveDialog = () => setShowDestructiveDialog(false);

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Button onClick={() => setShowStandardDialog(true)}>show standard Dialog</Button>
      <Button onClick={() => setShowDestructiveDialog(true)}>show destructive Dialog</Button>
      <ConfirmationDialog
        title="Standard Dialog"
        message="Use this dialog to confirm actions that we need to double check"
        onCancel={closeStandardDialog}
        onConfirm={closeStandardDialog}
        open={showStandardDialog}
      />
      <ConfirmationDialog
        title="Destructive Dialog"
        message="Use this dialog to confirm actions that will destroy data"
        onCancel={closeDestructiveDialog}
        onConfirm={closeDestructiveDialog}
        open={showDestructiveDialog}
        variant="containedDestructive"
      />
    </Box>
  );
};

export default ConfirmationDialogExample;

import { TeamLabelFragment } from "../../../../types";

export interface TeamLabelFormState {
  name: string;
  isActive: boolean;
}

interface ChangeName {
  type: "CHANGE_NAME";
  payload: {
    name: string;
  };
}

interface ChangeIsActive {
  type: "CHANGE_IS_ACTIVE";
  payload: {
    isActive: boolean;
  };
}

export type TeamLabelFormAction = ChangeName | ChangeIsActive;

export function teamLabelFormReducer<T extends TeamLabelFormState>(state: T, action: TeamLabelFormAction): T {
  switch (action.type) {
    case "CHANGE_NAME":
      return { ...state, name: action.payload.name };
    case "CHANGE_IS_ACTIVE":
      return { ...state, isActive: action.payload.isActive };
    default:
      return state;
  }
}

export const buildTeamLabelFormTooltips = (state: TeamLabelFormState): string[] => {
  const tooltips: string[] = [];
  if (!state.name.trim()) {
    tooltips.push("'Name' is required");
  }

  return tooltips;
};

export const buildInitialCreateFormState = (): TeamLabelFormState => ({
  name: "",
  isActive: true,
});

export const buildInitialEditFormState = ({ name, isActive }: TeamLabelFragment): TeamLabelFormState => ({
  name,
  isActive,
});

import React from "react";
import { LinkedTeamState, NotemealTeam } from "./reducer";
import BaseTeamDialog, { BaseTeamDialogProps } from "./BaseTeamDialog";

interface TeamLinkDialogProps extends Omit<BaseTeamDialogProps, "title" | "onConfirm" | "notemealTeam"> {
  onConfirm: (payload: Omit<LinkedTeamState, "__type">) => void;
  notemealTeam: NotemealTeam;
  titleOverride?: string;
}

const TeamLinkDialog = ({ onConfirm, titleOverride, ...restProps }: TeamLinkDialogProps) => {
  const { teamworksTeam, notemealTeam } = restProps;
  return (
    <BaseTeamDialog
      {...restProps}
      title={titleOverride ?? `Link ${notemealTeam.name} (NM) and ${teamworksTeam.name} (TW)`}
      onConfirm={({ name, sportState, gender }) =>
        onConfirm({
          id: notemealTeam.id,
          name,
          teamworksId: teamworksTeam.id,
          teamworksName: teamworksTeam.name,
          teamworksSportName: teamworksTeam.sport,
          notemealName: notemealTeam.name,
          ...sportState,
          sportName: sportState.sportState?.name ?? sportState.sportState?.name ?? null,
          gender,
        })
      }
    />
  );
};

export default TeamLinkDialog;

import React, { Dispatch } from "react";
import { CustomizationSelect } from "../CustomizationSelect";
import { DigitalDisplaysConfigActions } from "../reducer";

const LAYOUT = "Layout";
export const FULL_MENU = "Full menu view";
export const DINING_STATION = "Dining station view";
const LAYOUT_OPTIONS = [FULL_MENU, DINING_STATION] as const;
export type Layout = (typeof LAYOUT_OPTIONS)[number];
export const DEFAULT_LAYOUT = FULL_MENU;

export interface LayoutSelectProps {
  value: Layout;
  dispatch: Dispatch<DigitalDisplaysConfigActions>;
  className?: string;
}

export const LayoutSelect = ({ value, dispatch, className }: LayoutSelectProps) => {
  const handleSelectLayout = (value: Layout) => {
    dispatch({ type: "SET_LAYOUT", payload: value });
  };
  return <CustomizationSelect
    label={LAYOUT}
    value={value}
    onChange={handleSelectLayout}
    options={LAYOUT_OPTIONS}
    className={className} />;
};

import React from "react";
import { PendingTeam } from "../reducer";
import TeamsList from "./TeamsList";

interface PendingTeamsListProps {
  teams: PendingTeam[];
  onCancel: (team: PendingTeam) => void;
  selectedId: string | null;
  onClick: (team: PendingTeam) => void;
}

const PendingTeamsList = (props: PendingTeamsListProps) => {
  const { selectedId } = props;

  return <TeamsList
    {...props}
    title="Pending Teams"
    columnMode={selectedId ? "NoAction" : "Cancel"}
    disableOnSelected />;
};

export default PendingTeamsList;

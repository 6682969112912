import { Box, LinearProgress, Rating, SxProps, Typography } from "@mui/material";
import { RatingValue } from "../../../../types";

import StarIcon from "@mui/icons-material/Star";
import { menuItemRatingColor } from "@notemeal/palette";
import React from "react";
import { RatingCounts } from "../../../../types";
import { getAverageRating, getNumberOfRatings, getRatingPercents, ratingValueToNumber } from "./utils";

interface RatingCountsChartProps {
  ratingCounts: RatingCounts;
  sx?: SxProps;
}

export const RatingCountsChart = ({ ratingCounts, sx }: RatingCountsChartProps) => {
  const averageRating = getAverageRating(ratingCounts);
  const totalRatings = getNumberOfRatings(ratingCounts);
  const ratingPercents = getRatingPercents(ratingCounts);

  return (
    <Box sx={sx}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Rating
          size="large"
          precision={0.1}
          readOnly
          value={averageRating} />
        <Typography sx={{ pl: 1.5 }} variant="h3">
          {averageRating} out of 5
        </Typography>
      </Box>
      <Typography color="textSecondary" sx={{ mt: 1, mb: 3 }}>
        {totalRatings} total ratings
      </Typography>
      <StarRow rating="five" ratingPercent={ratingPercents["five"]} />
      <StarRow rating="four" ratingPercent={ratingPercents["four"]} />
      <StarRow rating="three" ratingPercent={ratingPercents["three"]} />
      <StarRow rating="two" ratingPercent={ratingPercents["two"]} />
      <StarRow rating="one" ratingPercent={ratingPercents["one"]} />
    </Box>
  );
};

interface StarRowProps {
  rating: RatingValue;
  ratingPercent: number;
}

const StarRow = ({ rating, ratingPercent }: StarRowProps) => {
  return rating === "%future added value" ? null : (
    <Box sx={{ display: "flex", alignContent: "center" }}>
      <Typography variant="body1" sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        {ratingValueToNumber(rating)}
        <StarIcon fontSize="medium" sx={{ pl: 1, color: menuItemRatingColor }} />
      </Typography>

      <LinearProgress
        variant="determinate"
        value={ratingPercent}
        sx={{
          ".MuiLinearProgress-bar": { backgroundColor: menuItemRatingColor },
          width: 150,
          height: "unset",
          mx: 0.5,
          my: 1,
          backgroundColor: "grey.300",
        }}
      />
      <Typography variant="body2" sx={{ textAlign: "right", width: 40 }}>
        {ratingPercent}%
      </Typography>
    </Box>
  );
};

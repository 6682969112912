import { MealPlanFoodPreferenceContextProvider } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import { useFoodGroupsByIdQuery, useFoodsByIdQuery } from "apps/web/src/types";
import React, { useMemo } from "react";
import { FoodPreferenceState } from "../../FoodPreference/MealPlan/reducer";

interface MealPlanTemplateFoodPreferencesContextProviderProps {
  foodPreferences: FoodPreferenceState;
  children: React.ReactNode;
}

const MealPlanTemplateFoodPreferencesContextProvider = ({
  foodPreferences,
  children,
}: MealPlanTemplateFoodPreferencesContextProviderProps) => {
  const foodIds = [...foodPreferences.promotedFoods, ...foodPreferences.avoidedFoods].map(f => f.id).sort();
  const foodGroupIds = [...foodPreferences.promotedFoodGroups, ...foodPreferences.avoidedFoodGroups].map(f => f.id).sort();

  const foodsResult = useFoodsByIdQuery({
    variables: {
      ids: foodIds,
    },
  });
  const foodGroupsResult = useFoodGroupsByIdQuery({
    variables: {
      ids: foodGroupIds,
    },
  });

  const { promotedFoods, avoidedFoods, promotedFoodGroups, avoidedFoodGroups } = useMemo(() => {
    const foodsMap = new Map(foodsResult.data?.foodsById.map(f => [f.id, f]) ?? []);
    const promotedFoods = foodPreferences.promotedFoods.flatMap(f => foodsMap.get(f.id) ?? []);
    const avoidedFoods = foodPreferences.avoidedFoods.flatMap(f => foodsMap.get(f.id) ?? []);

    const foodGroupsMap = new Map(foodGroupsResult.data?.foodGroupsById.map(f => [f.id, f]) ?? []);
    const promotedFoodGroups = foodPreferences.promotedFoodGroups.flatMap(f => foodGroupsMap.get(f.id) ?? []);
    const avoidedFoodGroups = foodPreferences.avoidedFoodGroups.flatMap(f => foodGroupsMap.get(f.id) ?? []);

    return {
      promotedFoods,
      avoidedFoods,
      promotedFoodGroups,
      avoidedFoodGroups,
    };
  }, [foodPreferences, foodsResult.data, foodGroupsResult.data]);

  return (
    <MealPlanFoodPreferenceContextProvider
      promotedFoods={promotedFoods}
      avoidedFoods={avoidedFoods}
      promotedFoodGroups={promotedFoodGroups}
      avoidedFoodGroups={avoidedFoodGroups}
    >
      {children}
    </MealPlanFoodPreferenceContextProvider>
  );
};

export default MealPlanTemplateFoodPreferencesContextProvider;

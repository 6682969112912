import { ApolloError } from "@apollo/client";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Dialog, DialogContent, InputAdornment, TextField } from "@mui/material";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { ReactNode, useState } from "react";
import { FoodOwnerPreviewFragment, OffsetPageInfo, OrgEditableFoodFragment, SharedEditableFoodFragment } from "../../../types";
import { useOffsetPagination } from "../../../utils/pagination";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import TablePage from "../../universal/TablePage";
import RestaurantFoodImporter from "../../views/Import/Food/RestaurantFoodImporter";
import { BaseFoodFormType, getBaseFoodFormDefaultValues } from "../FoodFormSchema/FoodFormSchema";
import { Mode } from "../FoodFormSchema/utils";
import { FoodManagedByMultiSelect } from "../Org/FoodManagedByMultiSelect";
import { MoveOrgFoodToOrgGroupDialog } from "../Org/MoveOrgFoodToOrgGroupDialog";
import { MoveOrgGroupFoodToMyOrgDialog } from "../Org/MoveOrgGroupFoodToMyOrgDialog";
import FoodTableRow, { IFoodRow, TableHeaderRow } from "./TableRow";
import { foodsToRows } from "./populateFoodRows";
import { FoodTableMode, getInitialFormStateFromIFoodRow } from "./utils";

export interface RenderFormModalArgs {
  row: IFoodRow | null;
  initialFormState: Partial<BaseFoodFormType>;
  onClose: () => void;
  isOpen: boolean;
  resetTable: () => void;
}

export interface UseFoodDataOffsetConnectionArgs {
  limit: number;
  offset: number;
  query: string | null;
  ownerIds: string[] | null;
}

export interface UseFoodDataOffsetConnectionPayload {
  foods: readonly (OrgEditableFoodFragment | SharedEditableFoodFragment)[] | undefined;
  pageInfo: Pick<OffsetPageInfo, "hasNextPage" | "total" | "endOffset"> | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

interface BaseFoodTableProps {
  resetTable: () => void;
  useFoodDataOffsetConnection: (obj: UseFoodDataOffsetConnectionArgs) => UseFoodDataOffsetConnectionPayload;
  onRemoveFood: (foodId: string) => void;
  renderFoodModal: (args: RenderFormModalArgs) => JSX.Element;
  mode: Mode;
  tableMode: FoodTableMode;
  hasImporter?: boolean;
  restaurantId?: string;
  showTitle?: boolean;
  title?: ReactNode;
  filters?: ReactNode;
}

const BaseFoodTable = ({
  resetTable,
  useFoodDataOffsetConnection,
  onRemoveFood,
  renderFoodModal,
  mode,
  tableMode,
  restaurantId,
  filters,
  showTitle = true,
  title: customTitle,
}: BaseFoodTableProps) => {
  const [foodModalOpen, setFoodModalOpen] = useState(false);
  const [deleteableFood, setDeleteableFood] = useState<IFoodRow | null>(null);
  const [moveOrgFoodToOrgGroup, setMoveOrgFoodToOrgGroup] = useState<IFoodRow | null>(null);
  const [moveOrgGroupFoodToMyOrg, setMoveOrgGroupFoodToMyOrg] = useState<IFoodRow | null>(null);
  const [initialFormState, setInitialFormState] = useState(getBaseFoodFormDefaultValues(mode));
  const [selectedRow, setSelectedRow] = useState<IFoodRow | null>(null);
  const [importOpen, setImportOpen] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState<FoodOwnerPreviewFragment[]>([]);

  const { setMessage } = useSnackbar();

  const paginationHooks = useOffsetPagination();
  const { limit, offset, query, queryText, onChangeQueryText } = paginationHooks;

  const {
    foods: _foods,
    loading,
    error,
    pageInfo,
  } = useFoodDataOffsetConnection({ limit, offset, query, ownerIds: selectedOwners.length ? selectedOwners.map(o => o.id) : null });

  const onClickRow = (foodRow: IFoodRow) => {
    setInitialFormState(getInitialFormStateFromIFoodRow(foodRow, mode));
    setSelectedRow(foodRow);
    setFoodModalOpen(true);
  };

  const onClickAddFood = () => {
    setInitialFormState(getBaseFoodFormDefaultValues(mode));
    setFoodModalOpen(true);
  };

  const isRestaurantMode = mode === "restaurant";

  const header = (
    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: mode === "org" ? "space-between" : "flex-end", alignItems: "flex-end" }}>
      {showTitle && customTitle}
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end", minHeight: "60px" }}>
        {tableMode === "org-group" && (
          <FoodManagedByMultiSelect
            selectedUsers={selectedOwners}
            onChangeSelectedUsers={setSelectedOwners}
            searchText={queryText}
            sx={{ minWidth: 150 }}
          />
        )}
        {filters}
        <TextField
          sx={{ minWidth: 300, mt: 0.5 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          inputProps={{
            "aria-label": "Search Food",
          }}
          placeholder="Search"
          value={queryText}
          onChange={e => onChangeQueryText(e.target.value)}
        />
        {tableMode !== "org-group" && (
          <Button sx={{ whiteSpace: "nowrap", minWidth: "auto" }} onClick={() => onClickAddFood()}>
            Add Food
          </Button>
        )}
        {isRestaurantMode && (
          <Button onClick={() => setImportOpen(true)} endIcon={<CloudUploadIcon />}>
            Import
          </Button>
        )}
      </Box>
    </Box>
  );

  const foods = _foods ?? [];

  return (
    <>
      {error ? (
        <div>Error! {error.message}</div>
      ) : (
        <TablePage
          header={header}
          tableHeaderRow={<TableHeaderRow tableMode={tableMode} />}
          tableBodyRows={foodsToRows(foods).map(foodRow => (
            <FoodTableRow
              key={foodRow.id}
              foodRow={foodRow}
              onClick={onClickRow}
              onDeleteFood={foodRow => {
                setDeleteableFood(foodRow);
              }}
              onMoveOrgFoodToOrgGroup={foodRow => setMoveOrgFoodToOrgGroup(foodRow)}
              onMoveOrgGroupFoodToMyOrg={foodRow => setMoveOrgGroupFoodToMyOrg(foodRow)}
              tableMode={tableMode}
            />
          ))}
          loading={loading}
          paginationHooks={paginationHooks}
          total={pageInfo?.total || 0}
        />
      )}
      {foodModalOpen &&
        renderFoodModal({
          initialFormState,
          onClose: () => {
            setFoodModalOpen(false);
            setSelectedRow(null);
          },
          isOpen: foodModalOpen,
          resetTable,
          row: selectedRow,
        })}
      {deleteableFood && (
        <ConfirmationDialog
          open={!!deleteableFood}
          title={`Delete '${deleteableFood.name}' ?`}
          message={
            "This will remove the food from all food-groups and athlete preferences. The food will remain on old meal plans, but it will not be available for future use."
          }
          onCancel={() => setDeleteableFood(null)}
          onConfirm={() => {
            onRemoveFood(deleteableFood.id);
            setDeleteableFood(null);
          }}
          variant="containedDestructive"
        />
      )}
      {moveOrgFoodToOrgGroup && (
        <MoveOrgFoodToOrgGroupDialog
          moveOrgFoodToOrgGroup={moveOrgFoodToOrgGroup}
          onClose={() => setMoveOrgFoodToOrgGroup(null)}
          setSuccessMsg={msg => setMessage("success", msg || "")}
        />
      )}
      {moveOrgGroupFoodToMyOrg && (
        <MoveOrgGroupFoodToMyOrgDialog
          moveOrgGroupFoodToMyOrg={moveOrgGroupFoodToMyOrg}
          onClose={() => setMoveOrgGroupFoodToMyOrg(null)}
          setSuccessMsg={msg => setMessage("success", msg || "")}
        />
      )}
      {isRestaurantMode && importOpen && !!restaurantId && (
        <Dialog
          open={importOpen}
          onClose={() => setImportOpen(false)}
          fullWidth
          maxWidth="xl">
          <DialogTitle title="Restaurant Foods Importer" onClose={() => setImportOpen(false)} />
          <DialogContent>
            <RestaurantFoodImporter restaurantId={restaurantId} resetTable={resetTable} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default BaseFoodTable;

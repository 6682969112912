import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { CropArea } from "./cropperUtils";
import { ASPECT_16_9 } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    content: {
      backgroundColor: grey[900],
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "center",
    },
    spacer: {
      flexGrow: 1,
    },
    cropperContainer: {
      margin: spacing(2),
      border: `thick solid ${grey[100]}`,
    },
  })
);

interface ImageCropperProps {
  imageUrl: string;
  setCropArea: (cropArea: CropArea) => void;
}

const ImageCropper = ({ imageUrl, setCropArea }: ImageCropperProps) => {
  const classes = useStyles();

  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [mountCropper, setMountCropper] = useState(false);
  const [cropperTimein, setCropperTimein] = useState(false);

  useEffect(() => {
    // Helps prevent cropper not loading bug
    const timer = setTimeout(() => {
      setCropperTimein(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={classes.content}>
      {mountCropper && cropperTimein && (
        <Cropper
          image={imageUrl}
          crop={crop}
          onCropChange={setCrop}
          zoom={zoom}
          onZoomChange={setZoom}
          aspect={ASPECT_16_9}
          onCropComplete={(_, cropArea) => setCropArea(cropArea)}
        />
      )}
      {/* Maybe helps prevent cropper not loading bug */}
      <img
        alt=""
        style={{ maxHeight: 0, maxWidth: 0 }}
        src={imageUrl}
        onLoad={() => {
          setMountCropper(true);
        }}
      />
    </div>
  );
};

export default ImageCropper;

import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import { Orientation } from "../utils";
import { MutationResult } from "@apollo/client";

export const mealPlanPageDivsToJpegs = (pageDivs: HTMLDivElement[]): Promise<string[]> => {
  return Promise.all(
    pageDivs.map(async pageDiv => {
      const jpeg = await domtoimage.toJpeg(pageDiv, {
        bgcolor: "white",
        height: pageDiv.offsetHeight * 2,
        width: pageDiv.offsetWidth * 2,
        style: {
          transform: `scale(${2})`,
          transformOrigin: "top left",
        },
      });
      return jpeg;
    })
  );
};

export const mealPlanJpegsToPdf = (jpegs: string[], orientation: Orientation) => {
  const pdf = new jsPDF({
    unit: "in",
    format: "letter",
    orientation,
  });
  jpegs.forEach((jpeg, idx) => {
    if (orientation === "portrait") {
      pdf.addImage(jpeg, "JPEG", 0, 0, 8.5, 11);
    } else {
      pdf.addImage(jpeg, "JPEG", 0, 0, 11, 8.5);
    }
    if (idx < jpegs.length - 1) {
      pdf.addPage();
    }
  });
  return pdf;
};

export const mutationResultToStatus = ({ called, loading, error }: MutationResult): "called" | "error" | "success" | undefined =>
  !called ? undefined : loading ? "called" : error ? "error" : "success";

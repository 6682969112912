import { addMinutes, subMinutes } from "date-fns";
import { MealEvent, MealModification } from "./types";

export const MINIMUM_MEAL_DURATION_IN_MIN = 30;
export const MINIMUM_MEAL_DURATION_IN_MIN_FALLBACK = 15;

export const MAXIMUM_TIME_OFFSET_IN_MIN = 120;

export const getIntervalForMaxMealTimeOffset = (mealStart: Date): Interval => {
  return {
    start: subMinutes(mealStart, MAXIMUM_TIME_OFFSET_IN_MIN),
    end: addMinutes(mealStart, MAXIMUM_TIME_OFFSET_IN_MIN),
  };
};

/**
 * Find the corresponding meal modification for a meal event, or undefined if one doesn't exist
 */
export const findMealModificationForMeal = (mealMods: MealModification[], mealEvent: MealEvent): MealModification | undefined => {
  return mealMods.find(
    mod =>
      mod.mealEvent.id === mealEvent.id &&
      // check for start since events representing the same meal across days can have the same id
      mod.mealEvent.start === mealEvent.start
  );
};

import React from "react";
import { OrderRowDataInfo } from "../utils";
import TableRow, { HeaderRow } from "./TableRow";
import SelectionTable from "../Shared/SelectionTable";

interface TableProps {
  orderRowInfoArray: OrderRowDataInfo[];
}

const Table = ({ orderRowInfoArray }: TableProps) => {
  return (
    <SelectionTable
      selectionRowInfoArray={orderRowInfoArray}
      renderTableHeader={length => {
        return <HeaderRow length={length} />;
      }}
      renderTableRow={(orderRowInfo, openShowMoreModal, length) => (
        <TableRow
          key={`order-table-row-${orderRowInfo.id}`}
          orderRowInfo={orderRowInfo}
          openShowMoreModal={openShowMoreModal}
          length={length}
        />
      )}
    />
  );
};

export default Table;

import { MenuItem, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { FullServingFragment, useUnitConversionsFromQuery } from "../../../../types";
import { useConvertToUnits } from "./hooks";
import Item from "./Item";
import ItemLoading from "./ItemLoading";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(1, 0),
    },
    requestServingButton: {
      width: "100%",
    },
    menuItemRoot: {
      minHeight: "auto",
    },
  })
);

interface ServingAmountsServingsMenuUnitExpandedContentProps {
  serving: FullServingFragment;
  unitId: string;
  onClose: () => void;
  onSelect: (serving: FullServingFragment) => void;
  includeRecipeOnlyIngredients: boolean;
}

const ServingAmountsServingsMenuUnitExpandedContent = ({
  serving,
  unitId,
  onClose,
  onSelect,
  includeRecipeOnlyIngredients,
}: ServingAmountsServingsMenuUnitExpandedContentProps) => {
  const classes = useStyles();
  const { data, loading } = useUnitConversionsFromQuery({
    variables: { id: unitId },
  });
  const { onConvertToUnits } = useConvertToUnits({
    servingId: serving.id,
    onSelectServing: onSelect,
  });

  return (
    <>
      <Item
        serving={serving}
        onClose={onClose}
        onSelect={onSelect} />
      {loading || !data ? (
        <ItemLoading />
      ) : (
        data.unit.conversionsFrom
          .filter(uc => (includeRecipeOnlyIngredients ? true : !uc.toUnit.isProductionOnly))
          .map(uc => (
            <MenuItem
              key={uc.id}
              classes={{ root: classes.menuItemRoot }}
              dense
              onClick={() => onConvertToUnits(uc.toUnit.id)}>
              <Typography variant="subtitle1">1 {uc.toUnit.name}</Typography>
            </MenuItem>
          ))
      )}
    </>
  );
};

export default ServingAmountsServingsMenuUnitExpandedContent;

import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { LinkedProfile, NotemealProfile } from "@notemeal/profile-sync";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { useState } from "react";
import ListsColumn from "../../shared/ListColumn";
import ListsContainer from "../../shared/ListsContainer";
import { NotemealProfilesList } from "../SyncRule/DisplayLists";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listColumn: {
      overflow: "hidden",
    },
  })
);

interface RelinkProfileDialogProps {
  open: boolean;
  onClose: () => void;
  unlinkedNotemealProfiles: readonly NotemealProfile[];
  profileToUnlink: LinkedProfile;
  onUnlinkRelink: (profileToRelink: NotemealProfile) => void;
}

export const RelinkProfileDialog = ({
  open,
  onClose,
  unlinkedNotemealProfiles,
  profileToUnlink,
  onUnlinkRelink,
}: RelinkProfileDialogProps) => {
  const classes = useStyles();
  const [notemealProfileForRelink, setNotemealProfileForRelink] = useState<NotemealProfile | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const selectedId = notemealProfileForRelink?.id ?? null;

  const { firstName, lastName, email, cellPhone } = profileToUnlink.teamworks;

  const handleRelink = () => {
    if (notemealProfileForRelink) {
      onUnlinkRelink(notemealProfileForRelink);
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth>
      <DialogTitle>Relink Teamworks Profile</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="row"
          height="500px">
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            paddingTop={1.5}>
            <Typography variant="body2">
              To relink the Teamworks Profile for{" "}
              <strong>
                {firstName} {lastName}
              </strong>{" "}
              to a Nutrition account, select an unlinked Nutrition Profile.
            </Typography>
            <Card>
              <CardContent>
                <Typography variant="h4">
                  {firstName} {lastName}
                </Typography>
                <Typography>
                  {email}, {cellPhone}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box width="50%">
            <ListsContainer>
              <ListsColumn className={classes.listColumn}>
                <NotemealProfilesList
                  selectedId={selectedId}
                  onLink={setNotemealProfileForRelink}
                  onCancel={() => setNotemealProfileForRelink(null)}
                  profiles={unlinkedNotemealProfiles}
                />
              </ListsColumn>
            </ListsContainer>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowAlert(true)}>
          Cancel
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button disabled={!notemealProfileForRelink} onClick={handleRelink}>
          Link Accounts
        </Button>
      </DialogActions>
      <ConfirmationDialog
        open={showAlert}
        title="Confirm Cancel Unlink Profile"
        message="Are you sure you wish to cancel? The profile previously selected to be unlinked will remain linked and no profile will be relinked."
        onCancel={() => setShowAlert(false)}
        onConfirm={onClose}
        confirmLabel="Confirm"
        variant="contained"
      />
    </Dialog>
  );
};

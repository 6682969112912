import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, MenuItem, Select, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch } from "react";
import SelectWithPlaceHolder from "../../../../../components/universal/SelectWithPlaceHolder";
import NamedTagSelector from "../../../Selectors/NamedTagSelector";
import PositionSelector from "../../../Selectors/PositionSelector";
import TeamSelector from "../../../Selectors/TeamSelector";
import {
  AdvancedSelectionCondition,
  AdvancedSelectionConditionType,
  EditConditionAction,
  IsComplementString,
  RemoveConditionAction,
} from "../../../reducers/advancedSelectionReducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      whiteSpace: "nowrap",
      margin: theme.spacing(0, 1),
    },
    baseSelect: {
      background: "#FFFFFF",
      padding: 0,
    },
    select: {
      background: "#FFFFFF",
      "&:focus": {
        background: "#FFFFFF",
      },
    },
    staticSelect: {
      width: theme.spacing(31),
    },
    deleteIcon: {
      marginLeft: theme.spacing(2),
    },
  })
);

interface TagConditionProps {
  groupId: string;
  conditionState: AdvancedSelectionCondition;
  dispatch: Dispatch<EditConditionAction | RemoveConditionAction>;
  existingIncludes: AdvancedSelectionConditionType[];
  existingExcludes: AdvancedSelectionConditionType[];
  usedPositionIds: string[];
  usedTagIds: string[];
  usedTeamIds: string[];
}

type NonEmptyAdvancedSelectionConditionType = Exclude<AdvancedSelectionConditionType, "Empty">;

const attributeOptions: NonEmptyAdvancedSelectionConditionType[] = ["Positions", "Teams", "Tags"];

const getIncludeDisabled = ({ conditionState, existingIncludes }: Pick<TagConditionProps, "conditionState" | "existingIncludes">) => {
  if (conditionState.__typename === "Empty" || conditionState.__typename === "Tags") {
    return false;
  }
  return existingIncludes.includes(conditionState.__typename);
};

const getExcludeDisabled = ({ conditionState, existingExcludes }: Pick<TagConditionProps, "conditionState" | "existingExcludes">) => {
  if (conditionState.__typename === "Empty") {
    return attributeOptions.every(attribute => existingExcludes.includes(attribute));
  }
  return existingExcludes.includes(conditionState.__typename);
};

const getAttributeDisabledInclude = (
  attribute: NonEmptyAdvancedSelectionConditionType,
  { existingIncludes }: Pick<TagConditionProps, "existingIncludes">
) => {
  if (attribute === "Tags") {
    return false;
  }
  return existingIncludes.includes(attribute);
};

const getAttributeDisabledExclude = (
  attribute: NonEmptyAdvancedSelectionConditionType,
  { existingExcludes }: Pick<TagConditionProps, "existingExcludes">
) => {
  return existingExcludes.includes(attribute);
};

const getAttributeDisabled = (
  attribute: NonEmptyAdvancedSelectionConditionType,
  {
    conditionState,
    existingExcludes,
    existingIncludes,
  }: Pick<TagConditionProps, "conditionState" | "existingExcludes" | "existingIncludes">
) => {
  if (conditionState.isComplement === null) {
    return getAttributeDisabledExclude(attribute, { existingExcludes }) && getAttributeDisabledInclude(attribute, { existingIncludes });
  } else if (conditionState.isComplement) {
    return getAttributeDisabledExclude(attribute, { existingExcludes });
  } else {
    return getAttributeDisabledInclude(attribute, { existingIncludes });
  }
};

const selectWithPlaceHolderSX = {
  background: "white",
  padding: 0,
  width: 80,
};

const textSX = {
  whiteSpace: "nowrap",
  margin: 1,
};

const selectorSX = {
  mt: 0,
  width: "100%",
};

export const TagCondition = ({
  conditionState,
  dispatch,
  groupId,
  existingIncludes,
  existingExcludes,
  usedPositionIds,
  usedTagIds,
  usedTeamIds,
}: TagConditionProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  const classes = useStyles();
  const handleAttributeChange = (attribute: AdvancedSelectionConditionType) => {
    dispatch({ type: "EDIT_CONDITION_TYPE", payload: { groupId, conditionId: conditionState.id, __typename: attribute } });
  };
  const handleIsComplementChange = (isComplementString: IsComplementString) => {
    dispatch({ type: "EDIT_IS_COMPLEMENT", payload: { groupId, conditionId: conditionState.id, isComplementString } });
  };

  const includeDisabled = getIncludeDisabled({ conditionState, existingIncludes });
  const excludeDisabled = getExcludeDisabled({ conditionState, existingExcludes });
  const selectedPositionIds = conditionState.__typename === "Positions" ? conditionState.positions.map(({ id }) => id) : [];
  const selectedTeamIds = conditionState.__typename === "Teams" ? conditionState.teams.map(({ id }) => id) : [];
  const selectedTagIds = conditionState.__typename === "Tags" ? conditionState.tags.map(({ tag: { id } }) => id) : [];
  return (
    <Box
      sx={{
        p: 2,
        background: greyscale[200],
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
      }}
    >
      <SelectWithPlaceHolder
        value={conditionState.isComplement === null ? "" : conditionState.isComplement ? "Exclude" : "Include"}
        placeholderText="Include/Exclude"
        onChange={e => handleIsComplementChange(e.target.value as IsComplementString)}
        sx={selectWithPlaceHolderSX}
        isEmpty={conditionState.isComplement === null}
        classes={{ select: classes.select }}
      >
        <MenuItem value={"Include"} disabled={includeDisabled}>
          {"Include"}
        </MenuItem>
        <MenuItem value={"Exclude"} disabled={excludeDisabled}>
          {"Exclude"}
        </MenuItem>
      </SelectWithPlaceHolder>
      <Typography sx={textSX}>any of the following</Typography>
      <SelectWithPlaceHolder
        value={conditionState.__typename === "Empty" ? "" : conditionState.__typename}
        placeholderText="Attribute"
        onChange={e => handleAttributeChange(e.target.value as AdvancedSelectionConditionType)}
        sx={selectWithPlaceHolderSX}
        isEmpty={conditionState.__typename === "Empty"}
        classes={{ select: classes.select }}
      >
        {attributeOptions.map(option => (
          <MenuItem
            key={option}
            value={option}
            disabled={getAttributeDisabled(option, { conditionState, existingExcludes, existingIncludes })}
          >
            {option}
          </MenuItem>
        ))}
      </SelectWithPlaceHolder>
      <Typography sx={textSX}>:</Typography>
      {conditionState.__typename === "Empty" && (
        <Select
          sx={selectorSX}
          disabled={true}
          displayEmpty
          renderValue={() => "Select Attribute First"} />
      )}
      {conditionState.__typename === "Positions" && (
        <PositionSelector
          sx={selectorSX}
          selectedPositions={conditionState.positions}
          onChange={newPositions =>
            dispatch({ type: "EDIT_POSITIONS_ACTION", payload: { groupId, conditionId: conditionState.id, positions: newPositions } })
          }
          filterPositions={positions =>
            positions.filter(position => !usedPositionIds.includes(position.id) || selectedPositionIds.includes(position.id))
          }
        />
      )}
      {conditionState.__typename === "Tags" && (
        <NamedTagSelector
          sx={selectorSX}
          selectedNamedTags={conditionState.tags}
          onChange={newTags => dispatch({ type: "EDIT_TAGS_ACTION", payload: { groupId, conditionId: conditionState.id, tags: newTags } })}
          filterNamedTags={namedTags =>
            namedTags.filter(namedTag => !usedTagIds.includes(namedTag.tag.id) || selectedTagIds.includes(namedTag.tag.id))
          }
        />
      )}
      {conditionState.__typename === "Teams" && (
        <TeamSelector
          sx={selectorSX}
          selectedTeams={conditionState.teams}
          onChange={newTeams =>
            dispatch({ type: "EDIT_TEAMS_ACTION", payload: { groupId, conditionId: conditionState.id, teams: newTeams } })
          }
          filterTeams={teams => teams.filter(team => !usedTeamIds.includes(team.id) || selectedTeamIds.includes(team.id))}
        />
      )}
      <IconButton
        onClick={() => dispatch({ type: "REMOVE_CONDITION", payload: { groupId, conditionId: conditionState.id } })}
        size="small"
        className={classes.deleteIcon}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import BaseDialogTitle from "./BaseDialogTitle";

interface DialogTitleWithTooltipProps {
  title: string;
  tooltipText: string;
  onClose: () => void;
}

const DialogTitleWithTooltip = ({ title, onClose, tooltipText }: DialogTitleWithTooltipProps) => {
  const content = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {title}
      <Tooltip title={<Typography variant="subtitle1">{tooltipText}</Typography>}>
        <InfoIcon fontSize="small" sx={{ fontSize: "1.5rem", pl: 1 }} />
      </Tooltip>
    </Box>
  );
  return <BaseDialogTitle content={content} onClose={onClose} />;
};

export default DialogTitleWithTooltip;

import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

interface ImportDiningStationCheckboxProps {
  value: "all" | "some" | "none";
  text: string;
  onChange: (value: boolean) => void;
}

const ImportDiningStationCheckbox = ({ value, text, onChange }: ImportDiningStationCheckboxProps) => {
  const label = `${value === "all" ? "All" : value === "some" ? "Some" : "No"} Items ${text}`;

  return (
    <FormControlLabel
      label={label}
      sx={{ minWidth: "270px" }}
      control={
        <Checkbox
          sx={{ fontSize: "12px" }}
          checked={value !== "none"}
          indeterminate={value === "some"}
          onChange={e => onChange(e.target.checked)}
        />
      }
    />
  );
};

export default ImportDiningStationCheckbox;

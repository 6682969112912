import { Checkbox, ListItem, ListItemIcon, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      minWidth: "unset",
    },
    container: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  })
);

interface FilterCheckListItemProps {
  label: string;
  isSelected: boolean;
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
}

const FilterCheckListItem = ({ label, isSelected, onChange, disabled = false }: FilterCheckListItemProps) => {
  const classes = useStyles();
  const labelId = `checkbox-label-${label}`;
  return (
    <ListItem
      button
      role={undefined}
      onClick={() => onChange(!isSelected)}
      dense
      disabled={disabled}
      classes={{ root: classes.container }}>
      <ListItemIcon classes={{ root: classes.checkbox }}>
        <Checkbox
          edge="start"
          checked={isSelected}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
          size="small"
          classes={{ root: classes.checkbox }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};

export default FilterCheckListItem;

import { ExchangeAmountInput } from "../types";
import { sortFnByKey } from "@notemeal/utils/sort";
import { getMacroProtocolMacros } from "../MacroProtocol/utils";
import { ExchangeMealPlan } from "../MealPlan/types";

// TODO: Modularize this so we can implement methods to support different types of "total calculations"
// This currently supports
// 1. 1 or No dairy exchange (though our exchangeSet requires one)
// 2. 1 or 2 protein exchanges
// 3. 1 starch, veg, fruit, fat exchange
//
interface ChoExchangeServings {
  fruitServings: number;
  vegetableServings: number;
  dairyServings: number;
}
export const calculateChoExchangeServings = (mealPlan: ExchangeMealPlan): ChoExchangeServings => {
  const {
    exchangeSet: { exchanges },
    macroProtocol: { calorieBudget },
  } = mealPlan as ExchangeMealPlan;
  const goalSnapshot = calorieBudget && calorieBudget.goalSnapshot;
  const goalType = goalSnapshot ? goalSnapshot.type.name : null;

  const actFact = calorieBudget ? calorieBudget.activityFactor : 1.2;
  const dairyExchange = exchanges.find(ex => ex.type === "dairy");
  const vegExchange = exchanges.find(ex => ex.type === "vegetable");
  const fruitExchange = exchanges.find(ex => ex.type === "fruit");
  if (!dairyExchange || !vegExchange || !fruitExchange) {
    throw new Error("Must have dairy, veg, fruit exchanges");
  }
  // TODO: These can vary based off of kcalOffset of each athlete
  const dairyServings = Math.floor(36 / dairyExchange.pro);
  const vegetableServings =
    goalType === "Weight Loss"
      ? Math.floor(45 / vegExchange.cho)
      : goalType === "Weight Gain"
      ? Math.floor(15 / vegExchange.cho)
      : Math.floor(30 / vegExchange.cho);
  // ^ "Subtle Automation'
  const goalFruitGramsOffset = goalType === "Weight Gain" ? 15 : goalType === "Weight Loss" ? -15 : 0;
  const fruitChoGrams = actFact > 1.8 ? 210 : actFact > 1.6 ? 150 : 90 + goalFruitGramsOffset;
  const fruitServings = Math.floor(fruitChoGrams / fruitExchange.cho);
  return { dairyServings, vegetableServings, fruitServings };
};

interface CreateExchangeTargetTotalMutationProps {
  mealPlan: ExchangeMealPlan;
  vegetableServings: number;
  fruitServings: number;
  dairyServings: number;
}
export const createExchangeTargetTotalMutationVars = ({
  mealPlan,
  fruitServings,
  vegetableServings,
  dairyServings,
}: CreateExchangeTargetTotalMutationProps): ExchangeAmountInput[] => {
  const { macroProtocol, exchangeSet } = mealPlan;

  const exchanges = exchangeSet.exchanges;
  const { cho, pro, fat } = getMacroProtocolMacros(macroProtocol, mealPlan.athlete.birthDate);
  // 1. CHO exchanges first
  // 1a. Vegetable Exchanges
  const vegExchange = exchanges.find(e => e.type === "vegetable");
  if (!vegExchange) {
    throw new Error("Can't determine default exchanges w/o veg");
  }
  const vegExchangeTgtObj = {
    exchangeId: vegExchange.id,
    amount: vegetableServings,
    pickListServingIds: null,
  };
  const fruitExchange = exchanges.find(e => e.type === "fruit");
  if (!fruitExchange) {
    throw new Error("Can't determine default exchanges w/o fruit");
  }
  const fruitExchangeTgtObj = {
    exchangeId: fruitExchange.id,
    amount: fruitServings,
    pickListServingIds: null,
  };
  // 1c. Dairy Exchanges
  const dairyExchange = exchanges.filter(e => e.type === "dairy").sort(sortFnByKey("fat"))[0];
  const dairyExchangeTgtObj = dairyExchange
    ? {
        exchangeId: dairyExchange.id,
        amount: dairyServings,
        pickListServingIds: null,
      }
    : null;
  const choRemaining = [
    { ...vegExchangeTgtObj, cho: vegExchange.cho },
    { ...fruitExchangeTgtObj, cho: fruitExchange.cho },
    dairyExchangeTgtObj ? { ...dairyExchangeTgtObj, cho: dairyExchange.cho } : null,
  ].reduce((sum, tgt) => (tgt ? sum + tgt.amount * -1 * tgt.cho : sum), cho);

  // 1d. Rest Starch (Starch gets the remaining CHO)
  // TODO: Support additional starch exchanges ?
  const starchExchange = exchanges.filter(e => e.type === "starch")[0];
  if (!starchExchange) {
    throw new Error("Can't determine default exchanges w/o starch");
  }
  const starchExchangeTgt = Math.floor(choRemaining / starchExchange.cho);
  const starchExchangeTgtObj = {
    exchangeId: starchExchange.id,
    amount: starchExchangeTgt,
    pickListServingIds: null,
  };
  const proRemaining = [
    { ...vegExchangeTgtObj, pro: vegExchange.pro },
    { ...fruitExchangeTgtObj, pro: fruitExchange.pro },
    dairyExchangeTgtObj ? { ...dairyExchangeTgtObj, pro: dairyExchange.pro } : null,
    { ...starchExchangeTgtObj, pro: starchExchange.pro },
  ].reduce((sum, tgt) => (tgt ? sum + tgt.amount * -1 * tgt.pro : sum), pro);
  // 2a. IF highFatProtein, maybe 2?
  const proteinExchanges = exchanges.filter(e => e.type === "protein").sort(sortFnByKey("fat"));
  if (proteinExchanges.length === 0) {
    throw new Error("Can't determine default exchanges w/o Pro");
  }
  let newProRemaining = proRemaining;
  let highFatProExchangeTgtObj;
  if (proteinExchanges.length === 2) {
    const highFatProTgt = 0;
    highFatProExchangeTgtObj = {
      // TODO: Currently 0 High Fat Protein. mauybe support this?
      exchangeId: proteinExchanges[1].id,
      amount: highFatProTgt,
      pickListServingIds: null,
    };
    newProRemaining = newProRemaining - proteinExchanges[1].pro * highFatProTgt;
  } else if (proteinExchanges.length === 1) {
    highFatProExchangeTgtObj = null;
  } else {
    throw new Error(`Can't determine macros with '${proteinExchanges.length}' PRO exchanges`);
  }
  // 2b. Rest leanProtein
  const leanProExchangeTgt = Math.round(newProRemaining / proteinExchanges[0].pro);
  const leanProExchangeTgtObj = {
    // 2 High Fat Proteins
    exchangeId: proteinExchanges[0].id,
    amount: leanProExchangeTgt,
    pickListServingIds: null,
  };
  // 3. FAT exchanges next
  const fatExchange = exchanges.find(e => e.type === "fat");
  if (!fatExchange) {
    throw new Error("Can't determine default exchanges w/o fat");
  }
  // 3a. Fill with Fat
  const fatRemaining = [
    { ...vegExchangeTgtObj, fat: vegExchange.fat },
    { ...fruitExchangeTgtObj, fat: fruitExchange.fat },
    dairyExchangeTgtObj ? { ...dairyExchangeTgtObj, fat: dairyExchange.fat } : null,
    { ...starchExchangeTgtObj, fat: starchExchange.fat },
    highFatProExchangeTgtObj ? { ...highFatProExchangeTgtObj, fat: proteinExchanges[1].fat } : null,
    { ...leanProExchangeTgtObj, fat: proteinExchanges[0].fat },
  ].reduce((sum, tgt) => (tgt ? sum + tgt.amount * -1 * tgt.fat : sum), fat);
  const fatExchangeTgt = Math.max(0, Math.floor(fatRemaining / fatExchange.fat));
  // ^ Prevent negative Fat
  const fatExchangeTgtObj = {
    exchangeId: fatExchange.id,
    amount: fatExchangeTgt,
    pickListServingIds: null,
  };
  return [
    vegExchangeTgtObj,
    fruitExchangeTgtObj,
    dairyExchangeTgtObj,
    starchExchangeTgtObj,
    highFatProExchangeTgtObj,
    leanProExchangeTgtObj,
    fatExchangeTgtObj,
  ].flatMap(tgtObj => (tgtObj ? [tgtObj] : [])); // Get rid of 'null' exchange targets
};

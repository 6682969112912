import { List, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { MenuItemChoiceForModal } from "@notemeal/shared/ui/MenuItem/utils";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import ChoiceCheckboxItem from "./ChoiceCheckboxItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: theme.spacing(2),
    },
    amountButtons: {
      padding: theme.spacing(),
    },
  })
);

interface MenuItemChoiceCheckboxListProps {
  menuItemChoice: MenuItemChoiceForModal;
  onCheckOption: (menuItemChoiceOptionId: string, checked: boolean) => void;
  onIncrementOption: (menuItemChoiceOptionId: string) => void;
  onDecrementOption: (menuItemChoiceOptionId: string) => void;
}

const MenuItemChoiceCheckboxList = ({
  menuItemChoice,
  onCheckOption,
  onIncrementOption,
  onDecrementOption,
}: MenuItemChoiceCheckboxListProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.label}>
        <div>
          <Typography>
            <strong>{menuItemChoice.name}</strong>
          </Typography>
          <Typography>Choose up to {menuItemChoice.maxOptionsCount || menuItemChoice.options.length}</Typography>
        </div>
        <Typography>{menuItemChoice.required ? <strong>Required</strong> : <em>Optional</em>}</Typography>
      </div>
      <List dense>
        {sortByKey(menuItemChoice.options, "position").map(mico => (
          <ChoiceCheckboxItem
            key={mico.id}
            onIncrementOption={onIncrementOption}
            onDecrementOption={onDecrementOption}
            onCheckOption={onCheckOption}
            mico={mico}
          />
        ))}
      </List>
    </div>
  );
};

export default MenuItemChoiceCheckboxList;

import { checkForSchemaVersionUpdate } from "@notemeal/shared/ui/utils/schemaVersion";
import { getLocalStorageValue, setLocalStorageValue } from "../../utils/localStorage";
import { String } from "runtypes";
import { AppErrorProps } from "./AppError";

export const getCheckForUpdatesStrings = (wasError: boolean): AppErrorProps => ({
  title: "Check for Updates",
  subtitle: wasError ? "Something went wrong!" : "Checking for updates",
  body: "Checking for updates...",
});

export const getFetchingLatestUpdatesStrings = (wasError: boolean): AppErrorProps => ({
  title: "Check for Updates",
  subtitle: "Fetching the latest updates",
  body: wasError ? "The previous error may be fixed after this update." : "Fetching updates...",
});

export const getFinishedStrings = (wasError: boolean, previousVersion: string): AppErrorProps => {
  const [currentMajor, currentMinor] = process.env.REACT_APP_WEB_SCHEMA_VERSION.split(".");
  const [previousMajor, previousMinor] = getLocalStorageValue("webSchemaVersion", String).split(".");
  setLocalStorageValue("webSchemaVersion", process.env.REACT_APP_WEB_SCHEMA_VERSION);
  const wasUpdated = currentMajor > previousMajor || currentMinor > previousMinor;

  const imageUrl = wasError ? "/img/check_updates_error.svg" : "/img/check_updates_success.svg";
  const title = "Check for Updates";
  const subtitle = wasError ? "Something went wrong" : "All up-to-date";
  const errorText = wasUpdated
    ? "After the recent update, the previous error may be fixed. "
    : "Looks like something went wrong. Our support staff has been notified and will be looking into the issue to help resolve it. ";
  const maybePreviousVersion = wasUpdated ? `v${previousVersion} -> ` : "";
  const version = `${maybePreviousVersion}v${process.env.REACT_APP_WEB_SCHEMA_VERSION}`;
  const versionPrefix = wasUpdated ? "Notemeal was updated" : "Notemeal is already up-to-date";
  const versionText = `${versionPrefix} (${version})`;
  const body = `${wasError ? errorText : ""}${versionText}`;

  return {
    title,
    subtitle,
    body,
    imageUrl,
  };
};

export const getNoAuthErrorStrings = (): AppErrorProps => ({
  title: "Internal Server Error",
  subtitle: "Something went wrong",
  body: "Looks like something went wrong. Please reach out to our support staff for assistance.",
  imageUrl: "/img/check_updates_error.svg",
});

export const getIsUpdateRequired = (webVersion: string) => {
  console.log(`webVer: ${webVersion}, REACT_APP_WEB_SCHEMA_VERSION: ${process.env.REACT_APP_WEB_SCHEMA_VERSION}`);
  const update = checkForSchemaVersionUpdate({
    serverSchemaVersion: webVersion,
    clientSchemaVersion: process.env.REACT_APP_WEB_SCHEMA_VERSION,
  });

  return update === "majorUpdate" || update === "minorUpdate";
};

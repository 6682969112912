import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    specialRequests: {
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
    },
  })
);

interface MenuSelectionItemSpecialRequestsCaptionProps {
  specialRequests: string | null;
}

const MenuSelectionItemSpecialRequestsCaption = ({ specialRequests }: MenuSelectionItemSpecialRequestsCaptionProps) => {
  const classes = useStyles();
  return !!specialRequests ? (
    <Typography
      variant="subtitle1"
      color="textSecondary"
      component="p"
      className={classes.specialRequests}>
      {specialRequests}
    </Typography>
  ) : null;
};

export default MenuSelectionItemSpecialRequestsCaption;

import { Box, Card, CardContent, Checkbox, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { EXCHANGE_AMOUNT_SELECTED_CARD_WIDTH } from "@notemeal/shared/ui/ExchangeAmount/utils";
import { formatAmbiguousServingAmountWithTotalWeight } from "@notemeal/shared/ui/ServingAmount/utils";
import { useAthleteFoodPreferenceContext } from "@notemeal/shared/ui/contexts/AthleteFoodPreferences";
import { useMealPlanFoodPreferenceContext } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import {
  ChangeExchangeTargetMutationVariables,
  EditExchangeAmountFragment,
  FullExchangeServingListFragment,
  useChangeExchangeTargetMutation,
} from "../../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(),
      width: EXCHANGE_AMOUNT_SELECTED_CARD_WIDTH,
      flexGrow: 1,
      flexShrink: 0,
      background: "white",
      "&:hover": {
        cursor: "default",
      },
    },
    content: {
      boxSizing: "border-box",
      height: "100%",
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto minmax(0, 1fr)",
    },
    textField: {
      padding: theme.spacing(0, 1),
      margin: 0,
    },
    pickList: {
      overflowY: "auto",
      overflowX: "hidden",
      marginTop: theme.spacing(),
      marginBottom: 0,
      gridRowStart: 2,
      gridColumnStart: 1,
    },
    flex: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(1),
    },
    checkboxRoot: {
      padding: theme.spacing(0.5),
    },
    serving: {
      width: "100%",
    },
    redServing: {
      width: "100%",
      color: theme.palette.error.dark,
    },
    greenServing: {
      width: "100%",
      color: theme.palette.success.dark,
    },
    darkGreyText: {
      color: theme.palette.grey[700],
    },
  })
);

interface ExchangeAmountSelectedCardProps {
  mealTemplateId: string;
  exchangeAmount: EditExchangeAmountFragment;
  servingList: FullExchangeServingListFragment;
}

export const SelectedExchangeAmountCard = ({ mealTemplateId, exchangeAmount, servingList }: ExchangeAmountSelectedCardProps) => {
  const classes = useStyles();
  const [changeExchangeTarget] = useChangeExchangeTargetMutation();
  const { isLikedFood, isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isAvoidedFood, isPromotedFood } = useMealPlanFoodPreferenceContext();

  const { exchange, amount, pickListServingIds, amountInput } = exchangeAmount;
  const pickList = sortByKey(servingList.servingAmounts, "position");
  const servingListServingIds = servingList.servingAmounts.map(sa => sa.serving.id);

  const commonVariables: ChangeExchangeTargetMutationVariables = {
    exchangeId: exchange.id,
    target: amountInput,
    pickListServingIds,
    mealTemplateId,
  };

  const isNegativeFood = (foodId: string) => {
    return isDislikedFood(foodId).value || isAvoidedFood(foodId).value;
  };
  const isPositiveFood = (foodId: string) => {
    return isLikedFood(foodId).value || isPromotedFood(foodId).value;
  };

  return (
    <Card className={classes.root} raised>
      <CardContent className={classes.content}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", gridRowStart: 1, gridColumnStart: 1 }}>
          <Typography>{exchange.shortName}:</Typography>
          <TextField
            key={exchange.id}
            type="number"
            inputProps={{ min: 0, step: 0.5 }}
            value={amountInput}
            error={inputToNumber(amountInput) !== amount}
            className={classes.textField}
            margin="dense"
            onChange={e =>
              changeExchangeTarget({
                variables: {
                  ...commonVariables,
                  target: e.target.value,
                },
              })
            }
          />
        </Box>
        <div className={classes.pickList}>
          {pickList.map(sa => {
            // const servingAmountLabel = `${} ${sa.serving.foodOrRecipe.name}`;
            const servingId = sa.serving.id;
            const checked = pickListServingIds ? pickListServingIds.includes(servingId) : true;
            const nonNullPickListServingIds = pickListServingIds || servingListServingIds;
            return (
              <div key={sa.id} className={classes.flex}>
                <Checkbox
                  checked={checked}
                  classes={{
                    root: classes.checkboxRoot,
                  }}
                  onChange={e =>
                    changeExchangeTarget({
                      variables: {
                        ...commonVariables,
                        pickListServingIds: e.target.checked
                          ? [...nonNullPickListServingIds, servingId]
                          : nonNullPickListServingIds.filter(sId => sId !== servingId),
                      },
                    })
                  }
                />
                <div>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    className={
                      isNegativeFood(sa.serving.foodOrRecipe.id)
                        ? classes.redServing
                        : isPositiveFood(sa.serving.foodOrRecipe.id)
                        ? classes.greenServing
                        : classes.serving
                    }
                  >
                    {sa.serving.foodOrRecipe.name}
                    <br />
                    <span className={classes.darkGreyText}>{formatAmbiguousServingAmountWithTotalWeight(sa)}</span>
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { getNavOrgTeam } from "apps/web/src/pages/Auth/Org/Team/TeamPaths";
import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { useTeamsPageCreateTeamMutation } from "../../../types";
import Form from "../Form/Create";
import { teamFormReducer, teamFormToSaveTooltips } from "../Form/Create/utils";
import { initialCreateTeamFormState, teamFormToCreateInput } from "./utils";

interface TeamCreateModalProps {
  open: boolean;
  onClose: () => void;
}

export const CreateTeamModal = ({ open, onClose }: TeamCreateModalProps) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(teamFormReducer, {}, initialCreateTeamFormState);
  const canSaveTooltips = teamFormToSaveTooltips(state);

  const [createTeam, { loading }] = useTeamsPageCreateTeamMutation({
    onCompleted: data => navigate(getNavOrgTeam(data.createTeam.team.id)),
  });

  const handleSave = async () => {
    const { gender, sport, name } = state;
    if (name) {
      await createTeam({
        variables: {
          input: teamFormToCreateInput({ ...state, gender, sport, name }),
        },
      });
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Create Team" onClose={onClose} />
      <DialogContent>
        {loading && <Loading progressSize="lg" />}
        {!loading && <Form state={state} dispatch={dispatch} />}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

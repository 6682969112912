import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { useState } from "react";
import {
  DietFragment,
  useCreateDietMutation,
  useDeleteDietMutation,
  useEditDietMutation,
  useStaffDashboardDietsQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import DietDialog from "../../../../views/Staff/Diets/Dialog";
import DietTable from "../../../../views/Staff/Diets/Table";

export const DietsPage = () => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading, refetch } = useStaffDashboardDietsQuery({
    variables: {
      input: { limit, offset },
      query,
    },
    fetchPolicy: "network-only",
  });

  const [editDiet] = useEditDietMutation({ onCompleted: () => refetch() });
  const [createDiet] = useCreateDietMutation({ onCompleted: () => refetch() });
  const [deleteDiet] = useDeleteDietMutation({ onCompleted: () => refetch() });
  const handleEditDiet = (name: string) => {
    if (editingDiet) {
      editDiet({
        variables: { input: { name, id: editingDiet.id } },
      });
    }
  };
  const handleCreateDiet = (name: string) => {
    createDiet({ variables: { input: { name } } });
  };
  const handleDeleteDiet = () => {
    if (deleteDietId) {
      deleteDiet({ variables: { input: { id: deleteDietId } } });
      setDeleteDietId(null);
    }
  };
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [editingDiet, setEditingDiet] = useState<DietFragment | null>(null);
  const [deleteDietId, setDeleteDietId] = useState<string | null>(null);

  return (
    <>
      <DietTable
        onEditDiet={setEditingDiet}
        onDeleteDiet={setDeleteDietId}
        offsetConnectionData={data}
        loading={loading}
        paginationHooks={paginationHooks}
        onCreateDiet={() => setShowCreate(true)}
      />
      {showCreate && <DietDialog
        open={showCreate}
        onClose={() => setShowCreate(false)}
        onSave={handleCreateDiet} />}
      {!!editingDiet && (
        <DietDialog
          open={editingDiet !== null}
          onClose={() => setEditingDiet(null)}
          onSave={handleEditDiet}
          diet={editingDiet} />
      )}
      {!!deleteDietId && (
        <ConfirmationDialog
          open={deleteDietId !== null}
          title={"Delete Diet"}
          message="Are you sure that you would like to delete the diet?"
          onCancel={() => setDeleteDietId(null)}
          onConfirm={handleDeleteDiet}
          variant="containedDestructive"
        />
      )}
    </>
  );
};

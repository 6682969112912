import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";

import Loading from "@notemeal/shared/ui/global/Loading";
import { PublicMealPlanFragment } from "../../../types";
import Content from "./Content";
import { getTypedMealPlanFromQuery } from "./types";

type usePublicMealPlanQueryResult = {
  data: { mealPlan: PublicMealPlanFragment } | undefined;
  error: string | undefined;
  loading: boolean;
};

const usePublicMealPlanQuery = (mealPlanId: String) => {
  const [payload, setPayload] = useState<usePublicMealPlanQueryResult>({
    data: undefined,
    error: undefined,
    loading: true,
  });

  useEffect(() => {
    const mealPlanApiUrl = `${process.env.REACT_APP_URL_SCHEME}${process.env.REACT_APP_SERVER_DOMAIN}/public/meal-plan/${mealPlanId}`;
    axios({
      method: "get",
      withCredentials: true,
      url: mealPlanApiUrl,
    })
      .then(res => {
        setPayload({ data: res.data, error: undefined, loading: false });
      })
      .catch(err => {
        setPayload({ data: undefined, error: err, loading: false });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return payload;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    error: {
      padding: theme.spacing(8, 4),
      textAlign: "center",
    },
  })
);

interface MealPlanViewProps {
  mealPlanId: string;
}

const MealPlanView = ({ mealPlanId }: MealPlanViewProps) => {
  const classes = useStyles();

  const { loading, data, error } = usePublicMealPlanQuery(mealPlanId);

  if (loading) {
    return (
      <div className={classes.root}>
        <Loading progressSize="lg" />
      </div>
    );
  } else if (error || !data) {
    return (
      <div className={classes.root}>
        <Typography variant="h3" className={classes.error}>
          No meal plan found!
        </Typography>
      </div>
    );
  } else {
    const mealPlan = getTypedMealPlanFromQuery(data.mealPlan);

    if (mealPlan) {
      return (
        <Content
          mealPlan={mealPlan}
          macroDisplaySettings={data.mealPlan.athlete.macroDisplaySettings}
          exchangeDisplaySettings={data.mealPlan.athlete.exchangeDisplaySettings}
        />
      );
    } else {
      return (
        <div className={classes.root}>
          <Typography variant="h3" className={classes.error}>
            An error occurred loading this meal plan.
          </Typography>
        </div>
      );
    }
  }
};

export default MealPlanView;

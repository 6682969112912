import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ThemePreference } from "../../Theme/ThemePreference";
import { ThemeViewerAutocomplete } from "./ThemeViewer/Autocomplete";
import { ThemeViewerButton } from "./ThemeViewer/Button";
import { ThemeViewerButtonGroup } from "./ThemeViewer/ButtonGroup";
import { ThemeViewerDateTime } from "./ThemeViewer/DateTime";
import { ThemeViewerIcon } from "./ThemeViewer/Icon";
import { ThemeViewerIconButton } from "./ThemeViewer/IconButton";
import { ThemeViewerMacrosProgress } from "./ThemeViewer/MacrosProgress";
import { ThemeViewerSelect } from "./ThemeViewer/Select";
import { ThemeViewerSnackbar } from "./ThemeViewer/Snackbar";
import { ThemeViewerSwitch } from "./ThemeViewer/Switch";
import { ThemeViewerTabGroup } from "./ThemeViewer/TabGroup";
import { ThemeViewerTextField } from "./ThemeViewer/TextField";
import { ThemeViewerTextFieldSelect } from "./ThemeViewer/TextFieldSelect";
import { ThemeViewerTooltip } from "./ThemeViewer/Tooltip";

export const ThemeViewerPage = () => {
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [sliderValue, setSliderValue] = useState(50);
  const [activeStep, setActiveStep] = useState(1);

  return (
    <Box sx={{ m: 4, display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 4, overflow: "hidden" }}>
      <ThemePreference />
      <Box
        sx={{ fill: 1, width: "100%", m: 4, display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 4, overflowY: "auto" }}
      >
        <ThemeViewerTabGroup />
        <ThemeViewerButtonGroup />
        <ThemeViewerSnackbar />
        <ThemeViewerMacrosProgress />
        <ThemeViewerButton />
        <ThemeViewerIconButton />
        <ThemeViewerIcon />
        <ThemeViewerTextField />
        <ThemeViewerTextFieldSelect />
        <ThemeViewerAutocomplete />
        <ThemeViewerSelect />
        <ThemeViewerDateTime />
        <ThemeViewerSwitch />
        <ThemeViewerTooltip />
        <Typography variant="h2">Misc</Typography>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">Checkbox</Typography>
            <Checkbox checked={checkboxChecked} onChange={() => setCheckboxChecked(!checkboxChecked)} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">Radio</Typography>
            <RadioGroup row defaultValue="a">
              <FormControlLabel
                value="a"
                control={<Radio />}
                label="A" />
              <FormControlLabel
                value="b"
                control={<Radio />}
                label="B" />
            </RadioGroup>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">Slider</Typography>
            <Slider
              sx={{ width: "100px" }}
              value={sliderValue}
              onChange={(e, value) => setSliderValue(value as number)} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">Stepper</Typography>
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel>One</StepLabel>
              </Step>
              <Step>
                <StepLabel>Two</StepLabel>
              </Step>
              <Step>
                <StepLabel>Three</StepLabel>
              </Step>
            </Stepper>
            <Box sx={{ display: "flex", gap: 2 }}>
              <IconButton onClick={() => setActiveStep(activeStep > 0 ? activeStep - 1 : activeStep)}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton onClick={() => setActiveStep(activeStep < 2 ? activeStep + 1 : activeStep)}>
                <ChevronRightIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";

export const ThemeViewerSnackbar = () => {
  const { setMessage } = useSnackbar();
  const [shortMessageChecked, setShortMessageChecked] = useState(false);
  const [longMessageChecked, setLongMessageChecked] = useState(false);
  const shortMessage = "This is a short message.";
  const regularMessage = "This is an example message for a snackbar.";
  const longMessage = "This is an extra long message for a snackbar.  It's just to see what happens when a large message is displayed.";
  const message = longMessageChecked ? longMessage : shortMessageChecked ? shortMessage : regularMessage;
  const handleMessageSize = (short: boolean) => {
    setShortMessageChecked(short ? !shortMessageChecked : false);
    setLongMessageChecked(!short ? !longMessageChecked : false);
  };

  return (
    <>
      <Typography variant="h2">Snackbar</Typography>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Button onClick={() => setMessage("success", message)}>Success</Button>
        <Button onClick={() => setMessage("info", message)}>Info</Button>
        <Button onClick={() => setMessage("warning", message)}>Warning</Button>
        <Button onClick={() => setMessage("error", message)}>Error</Button>
        <FormControlLabel
          control={<Checkbox checked={shortMessageChecked} onChange={() => handleMessageSize(true)} />}
          label="Short message"
        />
        <FormControlLabel
          control={<Checkbox checked={longMessageChecked} onChange={() => handleMessageSize(false)} />}
          label="Long message"
        />
      </Box>
    </>
  );
};

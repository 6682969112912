import React from "react";
import Button from "./Button";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PrintIcon from "@mui/icons-material/Print";
import RestoreIcon from "@mui/icons-material/Restore";
import WhatshotIcon from "@mui/icons-material/Whatshot";

interface ActionButtonsProps {
  action: () => void;
  onClose: () => void;
}

export const PrintButton = ({ action, onClose }: ActionButtonsProps) => {
  return (
    <Button
      key="print-button"
      onClose={onClose}
      onClick={() => action()}
      label="Print">
      <PrintIcon sx={{ fontSize: 96 }} />
    </Button>
  );
};

export const PrintAndDoneButton = ({ action, onClose }: ActionButtonsProps) => {
  return (
    <Button
      key="print-done-button"
      onClose={onClose}
      onClick={() => action()}
      label="Print + Done">
      <PrintIcon sx={{ fontSize: 96 }} />
      <CheckCircleIcon sx={{ fontSize: 96, color: "orderStatusDone.main" }} />
    </Button>
  );
};

export const PrintAndCookButton = ({ action, onClose }: ActionButtonsProps) => {
  return (
    <Button
      key="print-cook-button"
      onClose={onClose}
      onClick={() => action()}
      label="Print + Cook">
      <PrintIcon sx={{ fontSize: 96 }} />
      <WhatshotIcon sx={{ fontSize: 96, color: "orderStatusCooking.main" }} />
    </Button>
  );
};
export const MarkDoneButton = ({ action, onClose }: ActionButtonsProps) => {
  return (
    <Button
      key="mark-done-button"
      onClose={onClose}
      onClick={() => action()}
      label="Done">
      <CheckCircleIcon sx={{ fontSize: 96, color: "orderStatusDone.main" }} />
    </Button>
  );
};
export const MarkCookButton = ({ action, onClose }: ActionButtonsProps) => {
  return (
    <Button
      key="mark-cook-button"
      onClose={onClose}
      onClick={() => action()}
      label="Cook">
      <WhatshotIcon sx={{ fontSize: 96, color: "orderStatusCooking.main" }} />
    </Button>
  );
};
export const MarkNewButton = ({ action, onClose }: ActionButtonsProps) => {
  return (
    <Button
      key="mark-new-button"
      onClose={onClose}
      onClick={() => action()}
      label="Mark New">
      <RestoreIcon sx={{ fontSize: 96, color: "orderStatusNew.main" }} />
    </Button>
  );
};
export const MarkCancelButton = ({ action, onClose }: ActionButtonsProps) => {
  return (
    <Button
      key="mark-cancel-button"
      onClose={onClose}
      onClick={() => action()}
      label="Cancel">
      <CancelIcon sx={{ fontSize: 96, color: "orderStatusCancelled.main" }} />
    </Button>
  );
};

import { Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";
import CalendarEventLabel from "../../../../components/Calendar/Week/EventLabel";
import { CalendarEvent, RenderCalendarEventArgs } from "../../../../components/Calendar/Week/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.info.light,
      display: "flex",
    },
  })
);

interface MenusCalendarEventNewPaperProps {
  newEvent: CalendarEvent;
  args: RenderCalendarEventArgs;
  clientTimezone: string;
}

const MenusCalendarEventNewPaper = ({ newEvent, args, clientTimezone }: MenusCalendarEventNewPaperProps) => {
  const classes = useStyles();

  const { PaperProps, isAbbreviated } = args;

  return (
    <Paper {...PaperProps} className={classnames(classes.root, PaperProps.className)}>
      <CalendarEventLabel
        primary="New Menu"
        isAbbreviated={isAbbreviated}
        start={newEvent.start}
        durationInMinutes={newEvent.durationInMinutes}
        clientTimezone={clientTimezone}
      />
    </Paper>
  );
};

export default MenusCalendarEventNewPaper;

import { Button, Card, CardContent, CardHeader, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortExchanges } from "@notemeal/shared/ui/Exchange/utils";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { initMacros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { ExchangeSetWithExchangesFragment } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 2),
      minWidth: 200,
    },
    actionArea: {
      display: "flex",
      alignItems: "start",
    },
    title: {
      paddingBottom: theme.spacing(),
    },
    exchangeDiv: {
      display: "flex",
      flexGrow: 0,
    },
    spacer: {
      flexGrow: 1,
      minWidth: theme.spacing(2),
    },
    flexSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    actionButton: {
      fontWeight: 700,
    },
  })
);

interface ExchangeSetPreviewCardProps {
  exchangeSet: ExchangeSetWithExchangesFragment;
  onClick: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const ExchangeSetPreviewCard = ({ exchangeSet, onClick, onCopy, onDelete }: ExchangeSetPreviewCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={null}
        action={
          <>
            <Button
              variant="text"
              className={classes.actionButton}
              onClick={onClick}>
              Edit
            </Button>
            <Button
              variant="text"
              className={classes.actionButton}
              onClick={onCopy}>
              Copy
            </Button>
            <Button
              variant="text"
              className={classes.actionButton}
              onClick={onDelete}>
              Delete
            </Button>
          </>
        }
        title={exchangeSet.name}
        titleTypographyProps={{ variant: "body1Medium" }}
        subheader={
          exchangeSet.createdBy && `Created by: ${exchangeSet.createdBy.firstName[0].toUpperCase()}. ${exchangeSet.createdBy.lastName}`
        }
      />
      <CardContent>
        {sortExchanges(exchangeSet.exchanges).map(({ id, name, cho, pro, fat }) => (
          <div className={classes.exchangeDiv} key={id}>
            <Typography variant="body2Medium">{name}:</Typography>
            <div className={classes.spacer} />
            <MacrosSummaryLabel variant="lg" macros={initMacros(cho, pro, fat)} />
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default ExchangeSetPreviewCard;

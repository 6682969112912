import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface DeconstructionDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const DeconstructionDialog = ({ open, onConfirm, onClose }: DeconstructionDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Recipe Deconstruction </DialogTitle>
      <DialogContent>
        <Typography>
          Deconstructing this recipe may result in different nutrient values since it has manually set macros. Are you sure you want to
          proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No
        </Button>
        <Button onClick={onConfirm}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeconstructionDialog;

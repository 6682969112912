import { AutocompleteRenderOptionState, createFilterOptions, FilterOptionsState, SxProps } from "@mui/material";
import React, { useCallback } from "react";
import { BasicOption } from "../../components/universal/BaseAsyncAutocomplete";
import { GetQueryVariablesFromPaginationAndInputArgs } from "../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import { useSharedFoodGroupsCursorConnectionQuery } from "../../types";
import BaseTagSelector, { BaseTagSelectorPassThroughProps } from "../../views/Tags/Selectors/BaseTagSelector";
import { SimpleFoodGroup } from "../Food/Form/FoodDetails/FoodDetailsSection";

interface FoodGroupSelectorProps extends BaseTagSelectorPassThroughProps {
  sx?: SxProps;
  selectedFoodGroups: readonly SimpleFoodGroup[];
  onChange: (newFoodGroups: SimpleFoodGroup[]) => void;
  error: any;
  filterFoodGroups?: (foodGroups: SimpleFoodGroup[]) => SimpleFoodGroup[];
}

export const FoodGroupMultiSelect = ({ sx, selectedFoodGroups, onChange, filterFoodGroups }: FoodGroupSelectorProps) => {
  const getQueryVariables = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        searchText: input,
        pagination: { cursor, limit },
      },
    }),
    []
  );

  const transformAndFilterFoodGroupOptions = (_edges: SimpleFoodGroup[] | undefined): SimpleFoodGroup[] => {
    const edges = _edges ?? [];
    return filterFoodGroups ? filterFoodGroups(edges) : edges;
  };
  const filterOptions = (foodGroups: SimpleFoodGroup[], state: FilterOptionsState<SimpleFoodGroup>): SimpleFoodGroup[] =>
    createFilterOptions<SimpleFoodGroup>({
      stringify: () => state.inputValue,
    })(foodGroups, state);

  const foodGroupEdgesAreEqual = useCallback((edge1: SimpleFoodGroup, edge2: SimpleFoodGroup) => {
    return edge1.id === edge2.id;
  }, []);

  const handleChange = (_: string, foodGroups: SimpleFoodGroup[]) => onChange(foodGroups);

  return (
    <BaseTagSelector
      sx={sx}
      queryKey="sharedFoodGroupsCursorConnection"
      useCursorConnectionQuery={useSharedFoodGroupsCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariables}
      transformAndFilterOptions={transformAndFilterFoodGroupOptions}
      renderOption={(foodGroup: SimpleFoodGroup, _state: AutocompleteRenderOptionState) => <BasicOption element={foodGroup.name} />}
      handleChange={handleChange}
      getOptionLabel={(foodGroup: SimpleFoodGroup) => foodGroup.name}
      getCustomOptionKey={(foodGroup: SimpleFoodGroup) => foodGroup.id}
      filterOptions={filterOptions}
      noOptionsText="No Food Groups Found"
      edgesAreEqual={foodGroupEdgesAreEqual}
      value={selectedFoodGroups.concat()}
      textInputLabel="Food Groups"
      size="small"
    />
  );
};

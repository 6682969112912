import React from "react";
import { PositionWithMappingsFragment } from "../../../../../../types";
import PositionList from "./PositionList";

interface NotemealPositionsListProps {
  selectedId: string | null;
  positions: readonly PositionWithMappingsFragment[];
  onLink: (position: PositionWithMappingsFragment) => void;
  onCancel: (position: PositionWithMappingsFragment) => void;
}

const NotemealPositionsList = (props: NotemealPositionsListProps) => {
  const { selectedId } = props;
  return <PositionList
    {...props}
    title="Unlinked Nutrition Positions"
    columnMode={selectedId ? "NoAction" : "Link"}
    disableOnSelected />;
};

export default NotemealPositionsList;

import { Theme } from "@mui/material";

export const generateTreeStyles = (color: string | undefined, theme: Theme) => ({
  root: {
    flexGrow: 1,
    background: color,
    margin: theme.spacing(1),
    borderRadius: 25,
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  tree: {
    padding: theme.spacing(2),
  },
  content: {
    height: "calc(100% - 75px)",
    overflowY: "auto" as "auto",
  },
});

import { FullMealTemplateFragment, FullMacroMealTemplateFragment, FullExchangeMealTemplateFragment } from "../../../../types";

export type Orientation = "portrait" | "landscape";

export const SCALE_FACTOR = 6.7 / 8;

// An ingredient is 8px high, the chip is 18px tall

export function isMacro(mealTemplate: FullMealTemplateFragment): mealTemplate is FullMacroMealTemplateFragment {
  return (mealTemplate as FullMacroMealTemplateFragment).cho !== undefined;
}

export function isExchange(mealTemplate: FullMealTemplateFragment): mealTemplate is FullExchangeMealTemplateFragment {
  return !isMacro(mealTemplate);
}

export const getMealPlanFilename = (mp: { name: string; athlete: { firstName: string; lastName: string } }) => {
  return `${mp.athlete.firstName} ${mp.athlete.lastName} - ${mp.name}`;
};

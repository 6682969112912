import { CreateDiningStationTemplateInput, EditDiningStationTemplateInput } from "../../types";
import { getEditMenuItemInputs, getCreateMenuItemInputs } from "../../components/MenuItemAppearance/utils";
import { DiningStationTemplateModalState } from "./reducer";

export const getCreateDiningStationTemplateInput = ({
  name,
  mealTypes,
  defaultMaxAmount,
  menuItemAppearances,
}: DiningStationTemplateModalState): CreateDiningStationTemplateInput => ({
  name,
  mealTypes,
  defaultMaxAmount,
  ...getCreateMenuItemInputs(menuItemAppearances),
});

export const getEditDiningStationTemplateInput = (
  diningStationTemplate: DiningStationTemplateModalState,
  diningStationTemplateId: string
): EditDiningStationTemplateInput => ({
  diningStationTemplateId,
  name: diningStationTemplate.name,
  mealTypes: diningStationTemplate.mealTypes,
  defaultMaxAmount: diningStationTemplate.defaultMaxAmount,
  after: new Date().toISOString(),
  ...getEditMenuItemInputs(diningStationTemplate.menuItemAppearances),
});

import React, { useReducer } from "react";
import { TeamLabelFragment, useAddTeamLabelMutation, useEditTeamLabelMutation, useTeamLabelsQuery } from "../../../../types";
import StaffContent from "../../../../views/Staff/GeneralizedStaffContent";
import MutationDialog from "../../../../views/Staff/GeneralizedStaffContent/MutationDialog";
import { UseFetchQueryArgs, UseFormReducer, UseMutation } from "../../../../views/Staff/GeneralizedStaffContent/types";
import Form from "../../../../views/Staff/TeamLabels/Form";
import {
  TeamLabelFormAction,
  TeamLabelFormState,
  buildInitialCreateFormState,
  buildInitialEditFormState,
  buildTeamLabelFormTooltips,
  teamLabelFormReducer,
} from "../../../../views/Staff/TeamLabels/Form/utils";

const headerRowInfo = [{ key: "name", displayName: "Name" }];
const convertObjToRowData = (teamLabel: TeamLabelFragment) => {
  return {
    name: teamLabel.name,
  };
};

const useFetchQuery = (query: UseFetchQueryArgs) => {
  const { data, loading } = useTeamLabelsQuery(query);
  return {
    data: data?.allTeamLabels,
    loading,
  };
};

const useEditMutation: UseMutation<TeamLabelFragment, TeamLabelFormState, "Edit"> = ({ setErrorMsg, onClose, onSaveSuccess }) => {
  const [editTeamLabel] = useEditTeamLabelMutation({
    onCompleted: data => {
      onSaveSuccess(data.editTeamLabel.teamLabel);
      onClose();
    },
    onError: e => setErrorMsg(e.message),
  });
  return async ({ obj: teamLabel, state }) => {
    await editTeamLabel({
      variables: {
        input: {
          teamLabelId: teamLabel.id,
          name: state.name,
          isActive: state.isActive,
        },
      },
    });
  };
};

const useEditFormReducer: UseFormReducer<TeamLabelFragment, TeamLabelFormState, TeamLabelFormAction, "Edit"> = teamLabel => {
  return useReducer(teamLabelFormReducer, buildInitialEditFormState(teamLabel));
};

const useCreateMutation: UseMutation<TeamLabelFragment, TeamLabelFormState, "Create"> = ({ setErrorMsg, onClose, onSaveSuccess }) => {
  const [addTeamLabel] = useAddTeamLabelMutation({
    onCompleted: data => {
      onSaveSuccess(data.addTeamLabel.teamLabel);
      onClose();
    },
    onError: e => setErrorMsg(e.message),
  });
  return async ({ state }) => {
    await addTeamLabel({
      variables: {
        input: {
          name: state.name,
          isActive: state.isActive,
        },
      },
    });
  };
};

const useCreateFormReducer: UseFormReducer<TeamLabelFragment, TeamLabelFormState, TeamLabelFormAction, "Create"> = teamLabel => {
  return useReducer(teamLabelFormReducer, buildInitialCreateFormState());
};

export const TeamLabelsPage = () => {
  return (
    <StaffContent
      headerRowInfo={headerRowInfo}
      convertObjToRowData={convertObjToRowData}
      queryCacheKey="allTeamLabels"
      useFetchQuery={useFetchQuery}
      entityName="Team Label"
      getObjDisplay={tt => tt.name}
      renderEditDialog={({ open, onClose, onEditSuccess, initialEditInfo }) => (
        <MutationDialog
          maxWidth="xs"
          open={open}
          onClose={onClose}
          onSaveSuccess={onEditSuccess}
          obj={initialEditInfo}
          renderForm={({ state, dispatch }) => <Form state={state} dispatch={dispatch} />}
          useFormReducer={useEditFormReducer}
          useMutation={useEditMutation}
          buildFormToolTips={buildTeamLabelFormTooltips}
          dialogTitle="Edit Team Label"
        />
      )}
      renderCreateDialog={({ open, onClose, onCreateSuccess }) => (
        <MutationDialog
          maxWidth="xs"
          open={open}
          onClose={onClose}
          onSaveSuccess={onCreateSuccess}
          obj={undefined}
          renderForm={({ state, dispatch }) => <Form state={state} dispatch={dispatch} />}
          useFormReducer={useCreateFormReducer}
          useMutation={useCreateMutation}
          buildFormToolTips={buildTeamLabelFormTooltips}
          dialogTitle="Create Team Label"
        />
      )}
    />
  );
};

import { datadogRum } from "@datadog/browser-rum";

export interface DatadogRumOptions {
  applicationId: string;
  clientToken: string;
  service: string;
  version: string;
  datadogEnv: string;
  commit: string;
  sessionReplaySampleRate?: number;
}

export const initDatadogRum = ({
  applicationId,
  clientToken,
  service,
  version,
  datadogEnv,
  commit,
  sessionReplaySampleRate = 20,
}: DatadogRumOptions) => {
  console.info("initializing datadog RUM");

  datadogRum.init({
    applicationId,
    clientToken,
    service,
    version,
    env: datadogEnv,
    site: "datadoghq.com",
    sessionSampleRate: 100,
    sessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "allow", // still masks sensitive inputs
    allowedTracingUrls: [
      {
        //connects datadog server traces to rum/ux monitoring
        match: (url: string) => url.startsWith("https://api.notemeal.io/"),
        propagatorTypes: ["datadog"],
      },
    ],
  });

  datadogRum.startSessionReplayRecording();
  datadogRum.setGlobalContextProperty("commit", commit);

  console.info("done initializing datadog RUM");
};

import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { MenuItemAppearancePreviewFragment } from "../../types";

type MenuItemAppearancePreview = Omit<MenuItemAppearancePreviewFragment, "id" | "position" | "maxAmount">;

type BooleanKeyOf<T> = {
  [key in keyof T]: T[key] extends boolean ? key : never;
}[keyof T];

interface MenuItemAppearanceGroupControlLabelProps<MIA extends MenuItemAppearancePreview> {
  menuItemAppearances: readonly MIA[];
  field: BooleanKeyOf<MIA>;
  text: string;
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
}

const MenuItemAppearanceGroupControlLabel = <MIA extends MenuItemAppearancePreview>({
  menuItemAppearances,
  field,
  text,
  onChange,
  disabled,
  className,
}: MenuItemAppearanceGroupControlLabelProps<MIA>) => {
  const value = menuItemAppearances.every(mia => mia[field]) ? "all" : menuItemAppearances.every(mia => !mia[field]) ? "none" : "some";

  const label = `${value === "all" ? "All" : value === "some" ? "Some" : "No"} Items ${text}`;

  return (
    <FormControlLabel
      label={label}
      className={className}
      disabled={disabled}
      control={<Checkbox
        checked={value !== "none"}
        indeterminate={value === "some"}
        onChange={e => onChange(e.target.checked)} />}
    />
  );
};

export default MenuItemAppearanceGroupControlLabel;

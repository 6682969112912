import { sortByFn } from "@notemeal/utils/sort";
import { AthleteForCustomTagFragment, CreateCustomTagInput } from "../../../types";
import { CustomTagCombinedState } from "./combinedTagReducers";

type CustomTagStateType = "Athlete";

export interface CustomTagState {
  type: CustomTagStateType;
  athletes: readonly AthleteForCustomTagFragment[];
}

interface EditAthletesAction {
  type: "EDIT_ATHLETES_ACTION";
  payload: AthleteForCustomTagFragment[];
}

interface AddAthletesAction {
  type: "ADD_ATHLETE_ACTION";
  payload: AthleteForCustomTagFragment;
}

interface RemoveAthletesAction {
  type: "REMOVE_ATHLETE_ACTION";
  payload: AthleteForCustomTagFragment;
}

export type CustomTagAction = EditAthletesAction | AddAthletesAction | RemoveAthletesAction;

export const customTagReducer = (state: CustomTagState, action: CustomTagAction): CustomTagState => {
  switch (action.type) {
    case "EDIT_ATHLETES_ACTION":
      return {
        ...state,
        athletes: action.payload,
      };
    case "ADD_ATHLETE_ACTION":
      return {
        ...state,
        athletes: sortByFn([...state.athletes, action.payload], ath => `${ath.lastName}, ${ath.firstName}`),
      };
    case "REMOVE_ATHLETE_ACTION":
      return {
        ...state,
        athletes: state.athletes.filter(athlete => athlete.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export const isEmptyCustomTagState = (state: CustomTagState | null): boolean => {
  if (state === null) {
    return true;
  }
  return state.athletes.length === 0;
};

export const buildInitialCustomTagCreateState = (): CustomTagState => ({
  type: "Athlete",
  athletes: [],
});

export const buildInitialCustomTagEditState = (athletes: readonly AthleteForCustomTagFragment[]): CustomTagState => ({
  type: "Athlete",
  athletes,
});

export const useValidateCustomTagStateForSave = (state: CustomTagState | null): string[] => {
  return [];
};

export const buildSaveCustomTagInput = ({
  name,
  description,
  isPrintable,
  customState: state,
}: CustomTagCombinedState): CreateCustomTagInput => {
  return {
    name,
    description,
    athleteIds: state.athletes.map(({ id }) => id),
    isPrintable,
  };
};

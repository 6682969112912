import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { subDays } from "date-fns";
import React from "react";
import { EventFragment } from "../../../types";
interface ComplianceKeyMetricProps {
  events: readonly EventFragment[];
  daysAgo: number;
  setDaysAgo: (daysAgo: number) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      flexGrow: 0,
    },
    mealPlanViewNumber: {
      display: "flex",
      flexFlow: "column wrap",
      alignItems: "center",
      textAlign: "center",
    },
  })
);

const ComplianceKeyMetric = ({ events, daysAgo, setDaysAgo }: ComplianceKeyMetricProps) => {
  const classes = useStyles();

  return (
    <div className={classes.mealPlanViewNumber}>
      <Typography variant="h1">{events.filter(e => new Date(e.datetime) > subDays(new Date(), daysAgo)).length}</Typography>
      <Typography variant="body1Medium">Meal Plan Views</Typography>
      <Typography variant="subtitle1">(Last {daysAgo} Days)</Typography>
    </div>
  );
};

export default ComplianceKeyMetric;

import { useGesture } from "react-use-gesture";
import { useSprings, SpringUpdate, SpringValue } from "react-spring";
import { ReactEventHandlers } from "react-use-gesture/dist/types";

export interface DraggableObject {
  id: string;
  index: number;
  height: number;
}

interface Draggable {
  zIndex: number;
  shadow: number;
  y: number;
  scale: number;
}

export interface DraggableProps {
  bindProps: ReactEventHandlers;
  styleProps: any;
}

export interface DraggableSpring {
  zIndex: SpringValue<number>;
  shadow: SpringValue<number>;
  y: SpringValue<number>;
  scale: SpringValue<number>;
}

interface OffsetData {
  cutoffHeight: number;
  totalHeight: number;
  index: number;
}

interface GestureData {
  [originalIndex: number]: {
    dragChipHeight: number;
    asc: OffsetData[];
    desc: OffsetData[];
  };
}

const precomputeGestureData = (order: DraggableObject[]): GestureData => {
  const results: GestureData = {};
  order.forEach(({ index: originalIndex, height: dragChipHeight }) => {
    let asc: OffsetData[] = [];
    let ascHeight = 0;
    order.slice(originalIndex + 1).forEach(({ height, index }) => {
      const cutoffHeight = ascHeight + height / 2;
      ascHeight += height;
      asc.push({ cutoffHeight, totalHeight: ascHeight, index });
    });

    let desc: OffsetData[] = [];
    let descHeight = 0;
    order
      .slice()
      .reverse()
      .slice(order.length - originalIndex)
      .forEach(({ height, index }) => {
        const cutoffHeight = descHeight - height / 2;
        descHeight -= height;
        desc.push({ cutoffHeight, totalHeight: descHeight, index });
      });

    results[originalIndex] = { dragChipHeight, asc, desc };
  });
  return results;
};

interface useDraggableValue {
  springs: DraggableSpring[];
  bind: (...args: any[]) => ReactEventHandlers;
}

/*
  useDraggable hook relies on useSprings hook, which only works with a static number of springs
  Therefore, if the number of objects changes (i.e. order.length), the component on which this hook is used
  needs to be re-rendered or this will not work.
*/

export const useDraggable = (order: DraggableObject[], onChangeOrder: (ids: string[]) => void): useDraggableValue => {
  const gestureData = precomputeGestureData(order);

  const getUpdatedSpringState = (newOrder: DraggableObject[], down: boolean, originalIndex: number, y: number, dragChipHeight?: number) => {
    const newIndexes = newOrder.map(({ index }) => index);
    const getInitSpringStateByIndex = getInitSpringState(newIndexes, dragChipHeight);

    const onRest = () => {
      const newIds = newOrder.map(({ id }) => id).join(",");
      const oldIds = order.map(({ id }) => id).join(",");
      if (newIds !== oldIds) {
        onChangeOrder(newOrder.map(({ id }) => id));
      }
    };

    return (index: number): SpringUpdate<Draggable> => {
      if (index === originalIndex) {
        if (down) {
          return {
            y,
            scale: 1,
            zIndex: 1,
            shadow: 8,
            immediate: n => n === "y" || n === "zIndex",
          };
        } else {
          const nextIndex = newIndexes.indexOf(originalIndex);
          const { asc, desc } = gestureData[originalIndex];

          let notDownY = 0;
          if (nextIndex < originalIndex) {
            const swappedWith = desc.find(({ index }) => index === nextIndex);
            if (swappedWith) notDownY = swappedWith.totalHeight;
          } else if (nextIndex > originalIndex) {
            const swappedWith = asc.find(({ index }) => index === nextIndex);
            if (swappedWith) notDownY = swappedWith.totalHeight;
          }

          return {
            y: notDownY,
            scale: 1,
            zIndex: 1,
            shadow: 1,
            immediate: false,
            config: { tension: 300, clamp: true },
            onRest,
          };
        }
      } else {
        return {
          ...getInitSpringStateByIndex(index),
          immediate: false,
        };
      }
    };
  };

  const getInitSpringState =
    (indexOrder: number[], dragChipHeight?: number) =>
    (startingIndex: number): Draggable => {
      const currentIndex = indexOrder.indexOf(startingIndex);
      let y: number;
      if (!dragChipHeight || startingIndex === currentIndex) {
        y = 0;
      } else if (startingIndex < currentIndex) {
        y = dragChipHeight;
      } else {
        y = -dragChipHeight;
      }

      return { y, scale: 1, zIndex: 0, shadow: 1 };
    };

  const [springs, setSprings] = useSprings<Draggable>(order.length, getInitSpringState(order.map(({ index }) => index)) as any);

  const bind = useGesture(({ args: [originalIndex], down, delta: [, y] }) => {
    const { dragChipHeight, asc, desc } = gestureData[originalIndex];
    let nextIndex = originalIndex;
    if (y > 0) {
      // Asc
      asc.every(({ cutoffHeight, index }) => {
        const overCutoff = y > cutoffHeight;
        if (overCutoff) nextIndex = index;
        return overCutoff;
      });
    } else if (y < 0) {
      // Desc
      desc.every(({ cutoffHeight, index }) => {
        const underCutoff = y < cutoffHeight;
        if (underCutoff) nextIndex = index;
        return underCutoff;
      });
    }

    let newOrder: DraggableObject[];
    if (originalIndex < nextIndex) {
      newOrder = [
        ...order.slice(0, originalIndex),
        ...order.slice(originalIndex + 1, nextIndex + 1),
        order[originalIndex],
        ...order.slice(nextIndex + 1),
      ];
    } else if (originalIndex > nextIndex) {
      newOrder = [
        ...order.slice(0, nextIndex),
        order[originalIndex],
        ...order.slice(nextIndex, originalIndex),
        ...order.slice(originalIndex + 1),
      ];
    } else {
      newOrder = order;
    }

    setSprings(getUpdatedSpringState(newOrder, down, originalIndex, y, dragChipHeight));
  });

  return {
    springs,
    bind,
  };
};

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, ListItemButton, ListItemText, Tooltip, Typography } from "@mui/material";
import React from "react";
import { ContactFragment } from "../../../../../types";

interface ContactsListItemProps {
  contact: ContactFragment;
  setEditContactId: (id: string) => void;
}

const ContactListItem = ({ contact, setEditContactId }: ContactsListItemProps) => {
  const { title, firstName, lastName, email, preferredContactMethod } = contact;
  const primaryText = title ? `${title} ${firstName} ${lastName}` : `${firstName} ${lastName}`;
  const emailPreferred = preferredContactMethod === "email";
  const phonePreferred = preferredContactMethod === "phoneNumber";
  const emailRow = (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <EmailIcon color={emailPreferred ? "info" : "inherit"} />
      <Typography>{email}</Typography>
    </Box>
  );
  const phoneRow = (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <PhoneIcon color={phonePreferred ? "info" : "inherit"} />
      {contact.phoneNumber}
    </Box>
  );

  const secondaryText = (
    <>
      {contact.phoneNumber && (phonePreferred ? <Tooltip title={"Preferred Contact Method"}>{phoneRow}</Tooltip> : phoneRow)}
      {contact.email && (emailPreferred ? <Tooltip title={"Preferred Contact Method"}>{emailRow}</Tooltip> : emailRow)}
    </>
  );

  return (
    <ListItemButton key={contact.id} onClick={() => setEditContactId(contact.id)}>
      <ListItemText primary={primaryText} secondary={secondaryText} />
    </ListItemButton>
  );
};

export default ContactListItem;

import { Box } from "@mui/material";
import { carbohydrateColor, combinationColor, fatsColor, proteinColor } from "@notemeal/palette";
import React from "react";
import { FoodCategoryType } from "../../types";

interface FoodCategoryIconProps {
  foodCategory: FoodCategoryType;
  iconSize: number;
}

export const FoodCategoryIcon = ({ foodCategory, iconSize }: FoodCategoryIconProps) => {
  const color = getColorForCategory(foodCategory);
  return <Box sx={{ mr: 1, height: iconSize, width: iconSize, borderRadius: "50%", backgroundColor: color }} />;
};

const getColorForCategory = (category: FoodCategoryType): string => {
  switch (category) {
    case "carbohydrate":
      return carbohydrateColor;
    case "protein":
      return proteinColor;
    case "fats":
      return fatsColor;
    case "combination":
      return combinationColor;
  }
  return combinationColor;
};

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { initMacros } from "@notemeal/shared/utils/macro-protocol";
import classnames from "classnames";
import React from "react";
import { ExchangeType } from "../../../types";
import { ExchangeState, capitalizeExchangeType } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { info, grey, greyscale } }: Theme) =>
  createStyles({
    root: {
      margin: spacing(1, 1),
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      paddingRight: spacing(),
    },
    exchangeSummary: {
      display: "flex",
      flexFlow: "column wrap",
    },
    exchange: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexGrow: 0,
      borderRadius: spacing(2),
      padding: spacing(),
      "&:hover": {
        backgroundColor: greyscale[200],
      },
    },
    selectedExchange: {
      backgroundColor: info.light,
      "&:hover": {
        backgroundColor: info.light,
      },
    },
    shortName: {
      marginLeft: spacing(2),
    },
    spacer: {
      flexGrow: 1,
    },
    grey: {
      color: grey[600],
    },
  })
);

interface ExchangeSetFormExchangeInputGroupProps {
  type: ExchangeType;
  exchanges: ExchangeState[];
  selectedExchangeId: string | null;
  onAdd: (type: ExchangeType) => void;
  onSelect: (id: string) => void;
  onRemove: (id: string) => void;
}

const ExchangeSetFormExchangeInputGroup = ({
  exchanges,
  type,
  selectedExchangeId,
  onSelect,
  onAdd,
  onRemove,
}: ExchangeSetFormExchangeInputGroupProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          {capitalizeExchangeType(type)} Exchanges
        </Typography>
        <IconButton onClick={() => onAdd(type)} size="large">
          <AddIcon />
        </IconButton>
      </div>
      <div>
        {exchanges
          .filter(ex => ex.type === type)
          .map(({ id, name, shortName, cho, pro, fat, exchangeServingList }) => (
            <div
              key={id}
              onClick={() => onSelect(id)}
              className={classnames(classes.exchange, id === selectedExchangeId && classes.selectedExchange)}
            >
              <IconButton onClick={() => onRemove(id)} size="small">
                <CloseIcon />
              </IconButton>
              <div className={classes.exchangeSummary}>
                <Typography variant="body1Medium">
                  {name} <span className={classes.grey}>({shortName})</span>
                </Typography>
                <MacrosSummaryLabel variant="sm" macros={initMacros(cho, pro, fat)} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExchangeSetFormExchangeInputGroup;

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactNode } from "react";

import MealOptionView from ".";
import MacrosSummaryLabel from "../../Macros/SummaryLabel";

import { getServingAmountsMacros } from "../../ServingAmount/utils";

import { ViewMealOptionFragment } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    targets: {
      marginTop: theme.spacing(),
      textAlign: "center",
    },
  })
);

interface MealOptionViewMacroProps {
  mealOption: ViewMealOptionFragment;
  displayMacros: boolean;
  footer?: ReactNode;
  className?: string;
}

const MealOptionViewMacro = ({ mealOption, displayMacros, footer, className }: MealOptionViewMacroProps) => {
  const classes = useStyles();

  return (
    <MealOptionView
      mealOption={mealOption}
      className={className}
      targets={
        !displayMacros ? undefined : (
          <div className={classes.targets}>
            <MacrosSummaryLabel macros={getServingAmountsMacros(mealOption.servingAmounts)} variant="sm" kcalPrefix />
          </div>
        )
      }
      footer={footer}
    />
  );
};

export default MealOptionViewMacro;

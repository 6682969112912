import { Box, Chip } from "@mui/material";
import { isMealMenuClosed } from "@notemeal/shared/ui/MealMenu/utils";
import { parseDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import { addHours } from "date-fns";
import React from "react";
import { KioskMealMenuPreviewFragment } from "../../../types";

// Is between start time and end time
const isMealActive = (mealMenu: KioskMealMenuPreviewFragment) => {
  const currentTime = new Date();
  return !isMealMenuClosed(mealMenu) && currentTime > parseDateTime(mealMenu.start);
};

// Is between start time and 1 hour before start time
const isMealActiveSoon = (mealMenu: KioskMealMenuPreviewFragment) => {
  const currentTime = new Date();
  return !isMealActive(mealMenu) && currentTime > addHours(parseDateTime(mealMenu.start), -1);
};

interface MenuTimingChipProps {
  mealMenu: KioskMealMenuPreviewFragment;
}

const MenuTimingChip = ({ mealMenu }: MenuTimingChipProps) => {
  const activeNow = isMealActive(mealMenu);
  const activeSoon = isMealActiveSoon(mealMenu);

  return (
    <Box sx={{ my: 1 }}>
      {activeNow ? (
        <Chip
          sx={{ backgroundColor: "activeNow.main", color: "common.white" }}
          size="small"
          label="Active Now" />
      ) : activeSoon ? (
        <Chip
          sx={{ backgroundColor: "activeSoon.main" }}
          size="small"
          label="Active Soon" />
      ) : null}
    </Box>
  );
};

export default MenuTimingChip;

export interface FoodLogNotificationSettingsFormState {
  edits: boolean;
  imageUploads: boolean;
  messages: boolean;
  disabled: boolean;
}

interface ToggleEditFoodLog {
  type: "TOGGLE_EDIT_FOOD_LOG";
  payload: boolean;
}

interface ToggleUploadImage {
  type: "TOGGLE_UPLOAD_IMAGE";
  payload: boolean;
}

interface ToggleMessage {
  type: "TOGGLE_MESSAGE";
  payload: boolean;
}

export type FoodLogNotificationSettingsFormAction = ToggleEditFoodLog | ToggleUploadImage | ToggleMessage;

export const foodLogNotificationSettingsFormReducer = (
  state: FoodLogNotificationSettingsFormState,
  action: FoodLogNotificationSettingsFormAction
): FoodLogNotificationSettingsFormState => {
  switch (action.type) {
    case "TOGGLE_EDIT_FOOD_LOG":
      return { ...state, edits: action.payload };
    case "TOGGLE_MESSAGE":
      return { ...state, messages: action.payload };
    case "TOGGLE_UPLOAD_IMAGE":
      return { ...state, imageUploads: action.payload };
    default:
      return state;
  }
};

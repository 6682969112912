import { Box, SxProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { addDays } from "date-fns";
import React, { Fragment } from "react";
import { CalendarDateHeader, CalendarDateLabel, CalendarDayOfWeekLabel } from "./DateHeader";
import DayColumn from "./DayColumn";
import HoursColumn from "./HoursColumn";
import {
  CalendarDisplaySettings,
  CalendarDragSettings,
  CalendarEvent,
  DAY_HEADER_HEIGHT,
  RenderCalendarDateHeader,
  RenderCalendarDayColumn,
  RenderCalendarEvent,
  RenderCalendarOverlay,
  getCalendarEventsOnDate,
} from "./utils";

export const HOURS_COLUMN_WIDTH = 70;

const useStyles = makeStyles(() =>
  createStyles({
    loadingRoot: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  })
);

const DAYS_IN_WEEK = 7;
const DAY_OF_WEEK_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

interface CalendarWeekProps<E extends CalendarEvent> {
  startOfWeek: string;
  clientTimezone: string;
  events: readonly E[];
  renderEvent: RenderCalendarEvent<E>;
  renderNewEvent?: RenderCalendarEvent<CalendarEvent>;
  dragSettings?: CalendarDragSettings<E>;
  displaySettings?: CalendarDisplaySettings;
  renderOverlay?: RenderCalendarOverlay;
  renderDateHeader?: RenderCalendarDateHeader;
  renderDayColumn?: RenderCalendarDayColumn;
  sx?: SxProps;
  loading?: boolean;
}

const CalendarWeek = <E extends CalendarEvent>({
  displaySettings,
  startOfWeek,
  clientTimezone,
  events: _events,
  renderEvent,
  renderNewEvent,
  dragSettings,
  renderDateHeader,
  renderDayColumn,
  renderOverlay,
  sx,
  loading = false,
}: CalendarWeekProps<E>) => {
  const classes = useStyles();
  const startOfWeekDate = parseDate(startOfWeek);
  const weekDates = [...Array(DAYS_IN_WEEK).keys()].map(d => serializeDate(addDays(startOfWeekDate, d)));

  const today = serializeDate(new Date());
  const events = loading ? [] : _events;
  const hasAllDayEvents = events.some(e => e.isAllDay);

  return (
    <Box sx={{ ...sx, display: "flex", flexDirection: "row", position: "relative" }}>
      <Box>
        <Box sx={{ height: DAY_HEADER_HEIGHT, borderRight: "thin solid lightgray", boxSizing: "border-box" }} />
        <HoursColumn hasAllDayEvents={hasAllDayEvents} />
      </Box>
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <Fragment>{renderOverlay?.()}</Fragment>
        <Box sx={{ display: "flex", flexGrow: 0, flexShrink: 0 }}>
          {weekDates.map((date, dayOfWeek) => {
            const isToday = date === today;
            const dayOfWeekLabelNode = <CalendarDayOfWeekLabel dayOfWeekText={DAY_OF_WEEK_NAMES[dayOfWeek]} isToday={isToday} />;
            const dateLabelNode = <CalendarDateLabel date={date} isToday={isToday} />;

            return (
              <Fragment key={date}>
                {renderDateHeader?.({
                  date,
                  dayOfWeekLabelNode,
                  dateLabelNode,
                  showAllDayEvents: hasAllDayEvents,
                }) ?? (
                  <CalendarDateHeader>
                    {dayOfWeekLabelNode}
                    {dateLabelNode}
                  </CalendarDateHeader>
                )}
              </Fragment>
            );
          })}
        </Box>
        <Box sx={{ display: "flex", flexGrow: 1, flexShrink: 1, alignItems: "flex-start" }}>
          {weekDates.map(date => {
            const eventsForDate = getCalendarEventsOnDate(events, date, clientTimezone);
            return (
              renderDayColumn?.({
                date,
                clientTimezone,
                events: eventsForDate,
                renderEvent,
                renderNewEvent,
                dragSettings,
                displaySettings,
                showAllDayCell: hasAllDayEvents,
              }) ?? (
                <DayColumn
                  key={date}
                  date={date}
                  clientTimezone={clientTimezone}
                  events={eventsForDate}
                  renderEvent={renderEvent}
                  renderNewEvent={renderNewEvent}
                  dragSettings={dragSettings}
                  displaySettings={displaySettings}
                  showAllDayCell={hasAllDayEvents}
                />
              )
            );
          })}
        </Box>
      </Box>
      {loading && (
        <Loading
          progressSize="lg"
          classes={{
            root: classes.loadingRoot,
          }}
        />
      )}
    </Box>
  );
};

export default CalendarWeek;

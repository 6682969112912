import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import RecipeCreateDialog from "../../Recipe/RecipeCreateDialog";
import { ServingAmountsEditChipListWithSearchBar } from "../../ServingAmounts/Edit/ChipListWithSearchBar";
import { MealOptionMode, MealOptionModeComponentProps } from "./mode";

const MealOptionSearchModeComponent = ({
  renderTotals,
  selected,
  mealOption,
  mealTemplateTypename,
  contentClassName,
  totalsClassName,
  onEditServingAmounts,
  recipeDialogOpen,
  onCloseRecipeDialog,
  onCreateRecipe,
}: MealOptionModeComponentProps) => {
  return (
    <>
      <div className={totalsClassName}>{renderTotals(mealOption.servingAmounts)}</div>
      <ServingAmountsEditChipListWithSearchBar
        selectedServingAmounts={mealOption.servingAmounts}
        selected={selected}
        onChange={onEditServingAmounts}
        className={contentClassName}
        sx={{ overflowY: "auto" }}
        enableRecipes={mealTemplateTypename !== "StaffMealPlanTemplateMealTemplate"}
        enableBranded={mealTemplateTypename !== "ExchangeMealTemplate"}
      />
      {recipeDialogOpen && (
        <RecipeCreateDialog
          open={recipeDialogOpen}
          onClose={onCloseRecipeDialog}
          onCreate={onCreateRecipe}
          initialIngredients={mealOption.servingAmounts}
        />
      )}
    </>
  );
};

export const SearchMode: MealOptionMode = {
  name: "Search",
  icon: <SearchIcon />,
  component: MealOptionSearchModeComponent,
};

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, List, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { CardMode } from "./DisplayCard";
import ListHeader, { ListHeaderProps } from "./ListHeader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      height: "unset",
      marginTop: theme.spacing(1),
    },
    listHeaderVirtualized: {
      height: LIST_HEADER_HEIGHT,
    },
    listHeaderVirtualizedWithSearch: {
      height: LIST_HEADER_HEIGHT_WITH_SEARCH,
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(),
    },
  })
);

const LIST_HEADER_HEIGHT = 60;
const LIST_HEADER_HEIGHT_WITH_SEARCH = 105;

export interface IDAbleStringOrNumber {
  id: string | number;
}

export interface RenderListItemArgs<T extends IDAbleStringOrNumber> {
  element: T;
  disabled: boolean;
  focused: boolean;
  mode: CardMode;
  key: string;
}

interface DisplayListProps<EdgeType extends IDAbleStringOrNumber> {
  title: string;
  renderListItem: (args: RenderListItemArgs<EdgeType>) => JSX.Element;
  columnMode: CardMode;
  selectedId: string | null;
  disabled?: boolean;
  elements: readonly EdgeType[];
  loading: boolean;
  //search is currently only implemented for the virtualized list implementation.
  searchProps?: {
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
  };
  headerVariant?: ListHeaderProps["variant"];
  className?: string;
  virtualizeProps?: {
    itemSize: number;
  };
}

const DisplayList = <EdgeType extends IDAbleStringOrNumber>({
  title,
  renderListItem,
  columnMode,
  selectedId,
  disabled: _disabled = false,
  elements,
  loading,
  searchProps,
  headerVariant,
  className,
  virtualizeProps,
}: DisplayListProps<EdgeType>) => {
  const classes = useStyles();
  const listHeaderHeight = searchProps ? LIST_HEADER_HEIGHT_WITH_SEARCH : LIST_HEADER_HEIGHT;
  const renderItem = (element: EdgeType) => {
    const focused = element.id === selectedId;
    const disabled = !focused && _disabled;
    const mode = focused ? "Cancel" : columnMode;

    return renderListItem({
      element,
      disabled,
      focused,
      mode,
      key: `listItem-${element.id}`,
    });
  };

  if (virtualizeProps) {
    return (
      <>
        <ListHeader
          title={title}
          variant={headerVariant}
          className={searchProps ? classes.listHeaderVirtualizedWithSearch : classes.listHeaderVirtualized}
        >
          {searchProps && (
            <TextField
              sx={{ mt: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              value={searchProps.searchTerm}
              onChange={e => searchProps.setSearchTerm(e.target.value)}
              fullWidth
            />
          )}
        </ListHeader>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              // Subtract ListHeader height so that this scrolls to the end properly
              height={height - listHeaderHeight}
              width={width}
              itemSize={virtualizeProps.itemSize}
              itemCount={elements.length}
            >
              {({ style, index }) => (
                <div key={index} style={style}>
                  {renderItem(elements[index])}
                </div>
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </>
    );
  }

  return (
    <List disablePadding className={className}>
      <ListHeader title={title} variant={headerVariant} />
      {loading ? <Loading progressSize="xs" classes={{ root: classes.loading }} /> : elements.map(renderItem)}
    </List>
  );
};

export default DisplayList;

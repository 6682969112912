import { blueDark, green, orangeDark } from "./Accents";

export interface MacrosColorOptions {
  light: string;
  main: string;
  dark: string;
}

export interface MacrosColors {
  cho: MacrosColorOptions;
  pro: MacrosColorOptions;
  fat: MacrosColorOptions;
}

export const cho: MacrosColorOptions = {
  light: green[100],
  main: green[600],
  dark: green[900],
};

export const pro: MacrosColorOptions = {
  light: blueDark[100],
  main: blueDark[500],
  dark: blueDark[900],
};

export const fat: MacrosColorOptions = {
  light: orangeDark[100],
  main: orangeDark[600],
  dark: orangeDark[900],
};

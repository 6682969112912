import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch, useState } from "react";
import { PositionWithMappingsFragment, TeamworksPositionFragment } from "../../../../../types";
import ListsColumn from "../shared/ListColumn";
import ListsContainer from "../shared/ListsContainer";
import { PendingPosition, SportBuilderAction } from "../Sports/utils";
import LinkedPositionsList from "./DisplayLists/LinkedPositionsList";
import NotemealPositionsList from "./DisplayLists/NotemealPositionsList";
import PendingPositionsList from "./DisplayLists/PendingPositionsList";
import TeamworksPositionsList from "./DisplayLists/TeamworksPositionsList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listColumn: {
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
    container: {
      padding: theme.spacing(2, 0, 0, 0),
      height: "calc(100vh - 400px)",
    },
  })
);

interface PositionModalContentProps {
  notemealPositions?: readonly PositionWithMappingsFragment[];
  teamworksPositions: readonly TeamworksPositionFragment[];
  pendingPositions: readonly PendingPosition[];
  previouslyLinkedPositions?: readonly PositionWithMappingsFragment[];
  dispatch: Dispatch<SportBuilderAction>;
}

const PositionModalContent = ({
  notemealPositions,
  teamworksPositions,
  pendingPositions,
  previouslyLinkedPositions,
  dispatch,
}: PositionModalContentProps) => {
  const classes = useStyles();
  const [selectedNotemealPosition, setSelectedNotemealPosition] = useState<PositionWithMappingsFragment | null>(null);

  const onLinkTeamworks = (teamworksPosition: TeamworksPositionFragment) => {
    if (selectedNotemealPosition) {
      dispatch({
        type: "LINK_POSITION_ACTION",
        payload: {
          notemealPosition: selectedNotemealPosition,
          teamworksPosition,
        },
      });
      setSelectedNotemealPosition(null);
    }
  };

  const onAddTeamworks = (teamworksPosition: TeamworksPositionFragment) => {
    dispatch({
      type: "NEW_POSITION_ACTION",
      payload: {
        teamworksPosition,
      },
    });
  };

  const selectedId = selectedNotemealPosition?.id ?? null;

  return (
    <>
      <Typography variant="h3">Positons</Typography>
      <ListsContainer className={classes.container}>
        {notemealPositions && (
          <ListsColumn className={classes.listColumn}>
            <NotemealPositionsList
              positions={notemealPositions}
              selectedId={selectedId}
              onLink={position => setSelectedNotemealPosition(position)}
              onCancel={() => setSelectedNotemealPosition(null)}
            />
          </ListsColumn>
        )}
        <ListsColumn className={classes.listColumn}>
          <TeamworksPositionsList
            positions={teamworksPositions}
            selectedId={selectedId}
            onLink={onLinkTeamworks}
            onAdd={onAddTeamworks} />
        </ListsColumn>
        <ListsColumn className={classes.listColumn}>
          <PendingPositionsList
            positions={pendingPositions}
            selectedId={selectedId}
            onCancel={position =>
              dispatch({
                type: "REMOVE_POSITION_ACTION",
                payload: { position },
              })
            }
          />
        </ListsColumn>
        {previouslyLinkedPositions && (
          <ListsColumn className={classes.listColumn}>
            <LinkedPositionsList positions={previouslyLinkedPositions} selectedId={selectedId} />
          </ListsColumn>
        )}
      </ListsContainer>
    </>
  );
};

export default PositionModalContent;

import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import { FullServingAmountFragment, MenuItemChoiceOptionFormFragment } from "../../../types";
import { ServingAmountsEditChipListWithSearchBar } from "../../ServingAmounts/Edit/ChipListWithSearchBar";

interface MenuItemChoiceOptionFormProps {
  option?: MenuItemChoiceOptionFormFragment | null;
  editServingAmountsOnly?: boolean;
  onChange: (option: MenuItemChoiceOptionFormFragment, addChoice: boolean) => void;
}

const MenuItemChoiceOptionForm = ({ option, editServingAmountsOnly = false, onChange }: MenuItemChoiceOptionFormProps) => {
  const handleChangeServingAmounts = (servingAmounts: readonly FullServingAmountFragment[]) => {
    if (!option) {
      return;
    }
    if (option.servingAmounts.length === 0 && servingAmounts.length === 1 && option.name === "") {
      onChange(
        {
          ...option,
          servingAmounts,
          name: servingAmounts[0].serving.foodOrRecipe.name,
        },
        true
      );
    } else {
      onChange({ ...option, servingAmounts }, false);
    }
  };

  return (
    <>
      {option && !editServingAmountsOnly && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            py: 3,
            px: 2,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "grey.300",
            borderRadius: 1,
          }}
        >
          <TextField
            label="Name"
            placeholder='e.g. "Peppers"'
            value={option.name}
            onChange={e =>
              onChange(
                {
                  ...option,
                  name: e.target.value,
                },
                false
              )
            }
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              label="Can Edit Amount"
              sx={{ flex: 3 }}
              control={
                <Checkbox
                  checked={option.canEditAmount}
                  onChange={e =>
                    onChange(
                      {
                        ...option,
                        canEditAmount: e.target.checked,
                        defaultAmount: 1,
                        maxAmount: 1,
                      },
                      false
                    )
                  }
                />
              }
            />
            {option.canEditAmount && (
              <>
                <TextField
                  label="Max"
                  type="number"
                  inputProps={{
                    min: 1,
                  }}
                  sx={{ flex: 1 }}
                  value={option.maxAmount}
                  onChange={e =>
                    onChange(
                      {
                        ...option,
                        maxAmount: parseInt(e.target.value),
                      },
                      false
                    )
                  }
                  error={isNaN(option.maxAmount)}
                />
                <TextField
                  label="Default"
                  type="number"
                  inputProps={{
                    min: 1,
                    max: option.maxAmount,
                  }}
                  sx={{ flex: 1, ml: 3 }}
                  value={option.defaultAmount}
                  onChange={e =>
                    onChange(
                      {
                        ...option,
                        defaultAmount: parseInt(e.target.value),
                      },
                      false
                    )
                  }
                  error={isNaN(option.defaultAmount)}
                />
              </>
            )}
          </Box>
          <ServingAmountsEditChipListWithSearchBar
            key={option ? option.id : ""}
            enableBranded
            includeRecipeIngredients
            autoFocus={option?.servingAmounts.length === 0}
            onChange={handleChangeServingAmounts}
            selected={Boolean(option)}
            selectedServingAmounts={option ? option.servingAmounts : []}
          />
        </Box>
      )}
    </>
  );
};

export default MenuItemChoiceOptionForm;

import { IAthleteProfileRowInput } from "../utils";

type Header =
  | "First Name"
  | "Last Name"
  | "Username"
  | "Position"
  | "Birth Date"
  | "Height"
  | "Weight"
  | "Lean Mass"
  | "Weight Target Type"
  | "Goal Kcal Offset"
  | "Phone Number"
  | "Email"
  | "SECA ID"
  | "Inbody ID";
export interface SerializedExportRow {
  firstName: string;
  lastName: string;
  username: string;
  position: string;
  birthdate: string;
  height: string;
  weight: string;
  leanBodyMass: string;
  goalType: string;
  kcalOffset: string;
  phoneNumber: string;
  email: string;
  secaUid: string;
  inbodyUid: string;
}
interface ExportColumnDef {
  header: Header;
  key: keyof SerializedExportRow;
  width: number;
}

export const exportColumns: ExportColumnDef[] = [
  { header: "First Name", key: "firstName", width: 10 },
  { header: "Last Name", key: "lastName", width: 15 },
  { header: "Position", key: "position", width: 10 },
  { header: "Username", key: "username", width: 22 },
  { header: "Birth Date", key: "birthdate", width: 10 },
  { header: "Height", key: "height", width: 7 },
  { header: "Weight", key: "weight", width: 7 },
  { header: "Lean Mass", key: "leanBodyMass", width: 7 },
  { header: "Weight Target Type", key: "goalType", width: 15 },
  { header: "Goal Kcal Offset", key: "kcalOffset", width: 7 },
  { header: "Phone Number", key: "phoneNumber", width: 15 },
  { header: "Email", key: "email", width: 22 },
  { header: "SECA ID", key: "secaUid", width: 10 },
  { header: "Inbody ID", key: "inbodyUid", width: 10 },
];

export const serializeRows = (rows: readonly IAthleteProfileRowInput[]): SerializedExportRow[] => {
  return rows.map(r => {
    return {
      firstName: r.firstName,
      lastName: r.lastName,
      username: r.username,
      position: r.position?.name || "",
      birthdate: r.birthDate,
      height: r.height,
      weight: r.weight,
      leanBodyMass: r.leanBodyMass,
      goalType: r.goalType?.name || "",
      kcalOffset: r.kcalOffset,
      phoneNumber: r.phoneNumber,
      email: r.email,
      secaUid: r.secaUid,
      inbodyUid: r.inbodyUid,
    };
  });
};

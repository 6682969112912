import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  FilterOptionsState,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { ReactNode } from "react";

interface BaseMenuItemProps<Option> {
  loading: boolean;
  inputValue: string;
  setInputValue: (value: string) => void;
  getOptionLabel: (option: Option | string) => string;
  options: Option[];
  renderOption: (option: Option) => ReactNode;
  filterOptions: (option: Option[], state: FilterOptionsState<Option>) => Option[];
  handleSelect: (option: Option | string | null) => void;
  groupBy?: (option: Option) => string;
}

const BaseMenuItem = <Option extends object>({
  loading,
  inputValue,
  setInputValue,
  getOptionLabel,
  options,
  renderOption,
  filterOptions,
  handleSelect,
  groupBy,
}: BaseMenuItemProps<Option>) => {
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            <InputAdornment position="start">{loading ? <CircularProgress size={24} /> : <SearchIcon />}</InputAdornment>
          </>
        ),
        endAdornment: params.InputProps.endAdornment,
      }}
      placeholder="Add Menu Item"
      fullWidth
    />
  );

  return (
    <Autocomplete
      freeSolo
      value={null}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      getOptionLabel={getOptionLabel}
      options={options}
      renderOption={(props, option) => {
        return <li {...props}>{renderOption(option)}</li>;
      }}
      renderInput={renderInput}
      filterOptions={filterOptions}
      noOptionsText="No Menu Items Found"
      groupBy={groupBy}
      blurOnSelect
      onChange={(e, o) => {
        handleSelect(o);
      }}
      onClose={() => setInputValue("")}
    />
  );
};

export default BaseMenuItem;

import { Box, Chip, Typography, useTheme } from "@mui/material";
import React from "react";
import { ExchangeType, FullServingAmountFragment, RecipeWithServingsFragment } from "../../../../types";
import SmallServingAmountsChipList from "../SmallChipList";

interface ExchangeServingListBoxProps {
  servingAmounts: FullServingAmountFragment[];
  name: string;
  exchangeType: ExchangeType;
  exchangeTargetAmount: number | null;
  secondaryServingAmounts?: FullServingAmountFragment[];
  twoColumns?: boolean;
  secondaryExchangeName?: string;
  recipes: readonly RecipeWithServingsFragment[];
}

const ExchangeServingListBox = ({ secondaryServingAmounts = [], recipes, ...props }: ExchangeServingListBoxProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  return (
    <Box
      sx={{
        border: `3px solid ${greyscale[500]}`,
        borderRadius: 3,
        height: "inherit",
        width: "inherit",
        overflow: "hidden",
      }}
    >
      <Typography sx={{ pl: 0.5, pt: 0.5 }} variant="body2Semibold">
        {props.name}
        {props.exchangeTargetAmount && (
          <>
            <Typography
              component="span"
              color="textSecondary"
              variant="subtitle1">
              {" "}
              pick
            </Typography>
            <Chip
              sx={{ ml: 0.5, height: 20, fontSize: 10 }}
              size="small"
              color="info"
              label={props.exchangeTargetAmount} />
          </>
        )}
        {props.secondaryExchangeName && secondaryServingAmounts.length > 0 && (
          <em style={{ fontSize: 8 }}>*{props.secondaryExchangeName}</em>
        )}
      </Typography>
      <SmallServingAmountsChipList
        negativeFoodStrategy="highlight"
        positiveFoodStrategy="highlight"
        twoColumns={!!props.twoColumns}
        recipes={recipes}
        servingAmounts={props.servingAmounts
          .concat(
            secondaryServingAmounts.map(sa => ({
              ...sa,
              serving: {
                ...sa.serving,
                foodOrRecipe: {
                  ...sa.serving.foodOrRecipe,
                  name: `*${sa.serving.foodOrRecipe.name}`,
                },
              },
            }))
          )
          .map((sa, i) => ({ ...sa, position: i }))}
        expandRecipes={false}
      />
    </Box>
  );
};

export default ExchangeServingListBox;

import React from "react";
import ExternalLoadingLabel from "./ExternalCalendarLoading";
import ExternalCalendarWarning from "./ExternalCalendarWarning";

interface ExternalCalendarStatusProps {
  isLoading: boolean;
  isPartialSuccess: boolean;
  isErrored: boolean;
}

const ExternalCalendarStatus = ({ isLoading, isPartialSuccess, isErrored }: ExternalCalendarStatusProps) => {
  if (isLoading) {
    return <ExternalLoadingLabel />;
  } else if (isErrored || isPartialSuccess) {
    return <ExternalCalendarWarning isPartialSuccess={isPartialSuccess} />;
  } else {
    return null;
  }
};

export default ExternalCalendarStatus;

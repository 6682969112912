import React from "react";

import { useMenuItemDialogQuery } from "../../../types";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import { BaseMenuItemAppearanceCardProps, isExistingReusableMenuItem } from "../utils";
import EditDialogFull from "./Full";
import EditDialogReusable from "./Reusable";

interface MenuItemAppearanceEditDialogProps extends BaseMenuItemAppearanceCardProps {
  open: boolean;
  onClose: () => void;
}

const MenuItemAppearanceEditDialog = ({
  open,
  onClose,
  onEdit,
  onConvert,
  onEditAppearance,
  onCreate,
  menuItemAppearance,
}: MenuItemAppearanceEditDialogProps) => {
  const { type } = menuItemAppearance;

  const { data } = useMenuItemDialogQuery({
    variables: { menuItemId: menuItemAppearance.menuItem.id },
    skip: type === "Create" || type === "Edit" || type === "Conversion",
  });

  const menuItem = type === "Create" || type === "Edit" || type === "Conversion" ? menuItemAppearance.menuItem : data?.currentMenuItem;

  return !menuItem ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : isExistingReusableMenuItem(menuItemAppearance) ? (
    <EditDialogReusable
      open={open}
      onClose={onClose}
      onCreate={onCreate}
      onEdit={onEdit}
      onConvert={onConvert}
      onEditAppearance={onEditAppearance}
      menuItem={menuItem}
      menuItemAppearance={menuItemAppearance}
    />
  ) : (
    <EditDialogFull
      open={open}
      onClose={onClose}
      onEdit={onEdit}
      onConvert={onConvert}
      menuItem={menuItem}
      menuItemAppearance={menuItemAppearance}
    />
  );
};

export default MenuItemAppearanceEditDialog;

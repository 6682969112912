import { Dialog, DialogContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getRestaurantMenuLinkPlateItemsWithAppearance } from "@notemeal/shared/ui/RestaurantMenuLinkPlate/utils";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { RestaurantMenuFormFragment, RestaurantMenuLinkPlateItemFormFragment } from "../../../../../types";
import { RestaurantMenuLinkPlateState } from "../../types";
import Content from "./Content";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: `calc(100% - 64px)`,
    },
  })
);

interface RestauranMenuLinkPlateEditDialogProps {
  restaurantMenuLinkPlate: RestaurantMenuLinkPlateState;
  onEditPlate: (items: readonly RestaurantMenuLinkPlateItemFormFragment[], editedItemIds: readonly string[]) => void;
  restaurantMenu: RestaurantMenuFormFragment;
  open: boolean;
  onClose: () => void;
}

const RestauranMenuLinkPlateEditDialog = ({
  restaurantMenuLinkPlate,
  onEditPlate,
  restaurantMenu,
  open,
  onClose,
}: RestauranMenuLinkPlateEditDialogProps) => {
  const classes = useStyles();
  const [items, setItems] = useState(restaurantMenuLinkPlate.items);
  const [editedItemIds, setEditedItemIds] = useState(restaurantMenuLinkPlate.editedItemIds);

  const handleSave = () => {
    onEditPlate(items, editedItemIds);
    onClose();
  };

  const handleChange = (items: readonly RestaurantMenuLinkPlateItemFormFragment[], editedItemId?: string) => {
    setItems(items);
    if (editedItemId && !editedItemIds.includes(editedItemId)) {
      setEditedItemIds([...editedItemIds, editedItemId]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle title="Edit Plate for Bulk Order" onClose={onClose} />
      <DialogContent>
        <Content
          plateItems={getRestaurantMenuLinkPlateItemsWithAppearance(items)}
          onChangePlateItems={handleChange}
          onSave={handleSave}
          restaurantMenuSections={restaurantMenu.sections}
        />
      </DialogContent>
    </Dialog>
  );
};

export default RestauranMenuLinkPlateEditDialog;

import { parseDateTime } from "../utils/dateTimes";
import { addMinutes } from "date-fns";
import { MealMenuWithTimesFragment } from "../types";

/**
 *
 * @param mealMenu
 * @returns boolean true if the meal menu is no longer accepting orders
 */
export const isMealMenuClosed = (mealMenu: MealMenuWithTimesFragment): boolean => {
  const lastOrderTime = addMinutes(parseDateTime(mealMenu.start), mealMenu.durationInMinutes - mealMenu.lastOrderTimeBeforeEndInMinutes);
  const currentTime = new Date();
  return lastOrderTime < currentTime;
};

/**
 *
 * @param mealMenu
 * @returns boolean true if the current time is past the meal menus end time
 */
export const isMealMenuAfterEnd = (mealMenu: MealMenuWithTimesFragment): boolean => {
  const endTime = addMinutes(parseDateTime(mealMenu.start), mealMenu.durationInMinutes);
  const currentTime = new Date();
  return endTime < currentTime;
};

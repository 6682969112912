import { useApolloClient } from "@apollo/client";
import { RecipeEdit } from "apps/web/src/components/Recipe/EditDialog/RecipeEdit";
import { RecipeFormState } from "apps/web/src/components/Recipe/Form/utils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { NAV_STAFF_RECIPE_CREATE } from "../FoodManagement/FoodManagementRouter";

const RecipeCreatePage = () => {
  const { id: editRecipeId } = useParams<{ id: string }>();
  const { setMessage } = useSnackbar();

  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "recipeOffsetConnection",
      broadcast: true,
    });
  };

  const onSuccess = () => {
    resetTable();
    setMessage("success", `Edited recipe`);
    navigate(-1);
  };

  const onClose = () => {
    navigate(-1);
  };

  const onDuplicate = () => {
    navigate(NAV_STAFF_RECIPE_CREATE, { replace: true, state: { duplicatedRecipeId: editRecipeId, isDuplicate: true } });
  };

  const onScaleAndCreateNewRecipe = (state: RecipeFormState) => {
    navigate(NAV_STAFF_RECIPE_CREATE, {
      replace: true,
      state: { duplicatedRecipeId: state.id, isDuplicate: false, newYield: state.serving.perRecipeYield },
    });
  };

  return (
    !!editRecipeId && (
      <RecipeEdit
        forStaff={true}
        onEdit={onSuccess}
        onClose={onClose}
        recipeId={editRecipeId}
        onDuplicate={onDuplicate}
        onScaleAndCreateNewRecipe={onScaleAndCreateNewRecipe}
      />
    )
  );
};

export default RecipeCreatePage;

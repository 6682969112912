import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { invalidPhoneNumber } from "@notemeal/utils/phone-number";
import React, { useState } from "react";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import PhoneNumberInput from "./PhoneNumberInput";

interface UpdatePhoneFormProps {
  handleSave: (phoneNumber: string) => void;
  saving: boolean;
}

const UpdatePhoneForm = ({ handleSave, saving }: UpdatePhoneFormProps) => {
  const { setMessage } = useSnackbar();
  const [input, setInput] = useState("");
  const onSave = () => {
    if (invalidPhoneNumber(input)) {
      setMessage("error", "Invalid phone number.");
    } else {
      handleSave(input);
    }
  };

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h3">Missing athlete phone number</Typography>
      {saving && <CircularProgress size={35} />}
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
        <PhoneNumberInput setInput={setInput} disabled={saving} />
        <Button onClick={e => onSave()} disabled={saving}>
          Add Phone Number
        </Button>
      </Box>
    </Box>
  );
};

export default UpdatePhoneForm;

import React from "react";
import { Route, Routes, useParams } from "react-router-dom-v5-compat";
import { useRestaurantNavQuery } from "../../../../types";
import { getLink } from "../../../NavUtils";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { restaurantManagementLink, restaurantsLink } from "../RestaurantManagement/RestaurantManagementRouter";
import { RestaurantPage } from "./RestaurantPage";

export const getNavStaffRestaurantPath = (restaurantId: string) => `/staff/restaurants/${restaurantId}`;
export const getNavStaffRestaurantLink = (restaurantId: string, restaurantName: string) =>
  getLink(restaurantName, getNavStaffRestaurantPath(restaurantId));

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "Restaurant",
  current,
  breadCrumbs: [restaurantManagementLink, restaurantsLink],
  tabs: [],
  enableAthleteSearch: true,
});

export const RestaurantRouter = () => {
  const { restaurantId: maybeRestaurantId } = useParams();
  const restaurantId = maybeRestaurantId || "";
  let restaurantName = "";
  const { data, loading } = useRestaurantNavQuery({ variables: { id: restaurantId } });

  if (!loading && data) {
    const {
      restaurant: { name },
    } = data;
    restaurantName = name;
  }

  const current = getNavStaffRestaurantLink(restaurantId, restaurantName);
  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  return (
    <Routes>
      <Route element={restaurantName && <SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route index element={<RestaurantPage />} />
      </Route>
    </Routes>
  );
};

import { makeStyles } from "@mui/styles";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { useState } from "react";
import RestaurantTypeDialog from "../../../../components/universal/RenameDialog";
import RestaurantTypeTable from "../../../../components/universal/TablePage/NameOnlyTablePage";
import {
  RestaurantTypeFragment,
  useCreateRestaurantTypeMutation,
  useDeleteRestaurantTypeMutation,
  useEditRestaurantTypeMutation,
  useStaffDashboardRestaurantTypesQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "inherit",
    width: "100%",
  },
});

const RESTAURANT_TYPE = "Restaurant Type";

export const RestaurantTypesPage = () => {
  const classes = useStyles();
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading, refetch } = useStaffDashboardRestaurantTypesQuery({
    variables: {
      input: { limit, offset },
      query,
    },
    fetchPolicy: "network-only",
  });

  const [editRestaurantType] = useEditRestaurantTypeMutation({
    onCompleted: () => refetch(),
  });
  const [createRestaurantType] = useCreateRestaurantTypeMutation({
    onCompleted: () => refetch(),
  });
  const [deleteRestaurantType] = useDeleteRestaurantTypeMutation({
    onCompleted: () => refetch(),
  });
  const handleEditRestaurantType = (name: string) => {
    if (editingRestaurantType) {
      editRestaurantType({
        variables: { input: { name, id: editingRestaurantType.id } },
      });
    }
  };
  const handleCreateRestaurantType = (name: string) => {
    createRestaurantType({ variables: { input: { name } } });
  };
  const handleDeleteRestaurantType = () => {
    if (deleteRestaurantTypeId) {
      deleteRestaurantType({
        variables: { input: { id: deleteRestaurantTypeId } },
      });
      setDeleteRestaurantTypeId(null);
    }
  };
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [editingRestaurantType, setEditingRestaurantType] = useState<RestaurantTypeFragment | null>(null);
  const [deleteRestaurantTypeId, setDeleteRestaurantTypeId] = useState<string | null>(null);

  return (
    <div className={classes.container}>
      <RestaurantTypeTable
        onEdit={setEditingRestaurantType}
        onDelete={setDeleteRestaurantTypeId}
        data={data?.restaurantTypeOffsetConnection}
        loading={loading}
        paginationHooks={paginationHooks}
        onCreate={() => setShowCreate(true)}
        title={RESTAURANT_TYPE}
      />

      {showCreate && (
        <RestaurantTypeDialog
          open={showCreate}
          onClose={() => setShowCreate(false)}
          onSave={handleCreateRestaurantType}
          type={RESTAURANT_TYPE}
        />
      )}
      {!!editingRestaurantType && (
        <RestaurantTypeDialog
          open={editingRestaurantType !== null}
          onClose={() => setEditingRestaurantType(null)}
          onSave={handleEditRestaurantType}
          entity={editingRestaurantType}
          type={RESTAURANT_TYPE}
        />
      )}
      {!!deleteRestaurantTypeId && (
        <ConfirmationDialog
          open={deleteRestaurantTypeId !== null}
          title={`Delete ${RESTAURANT_TYPE}`}
          message="Are you sure that you would like to delete the restaurant type?"
          onCancel={() => setDeleteRestaurantTypeId(null)}
          onConfirm={handleDeleteRestaurantType}
          variant="containedDestructive"
        />
      )}
    </div>
  );
};

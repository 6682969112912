import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Fab } from "@mui/material";
import { sortByKey } from "@notemeal/utils/sort";
import Carousel, { CarouselProps } from "nuka-carousel";
import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useSelectedMealOptionsContext } from "../../../../contexts/SelectedMealOptions";
import { EditFullMealTemplateFragment, useAddMealOptionLocalMutation } from "../../../../types";
import { AddNewMealOption } from "../../../MealOption/Edit/AddNewMealOption";
import { MEAL_OPTION_SLIDE_WIDTH, MealOptionPreview } from "../../../MealOption/Edit/EditMealOption";

// TODO: update nuka-carousel to latest version and reformat with new props
type CarouselWithChildrenProps<CarouselProps> = PropsWithChildren<CarouselProps>;
const CarouselWithChildren: FC<CarouselWithChildrenProps<CarouselProps>> = ({ children, ...props }) => (
  <Carousel {...props}>{children}</Carousel>
);

interface EditMealOptionsProps<T extends EditFullMealTemplateFragment> {
  mealTemplate: T;
  renderMealOptionPreview: (mealOptionPreview: MealOptionPreview, index: number) => ReactNode;
}

export const EditMealOptions = <T extends EditFullMealTemplateFragment>({
  mealTemplate,
  renderMealOptionPreview,
}: EditMealOptionsProps<T>) => {
  const [mealOptionPreviewIndex, setMealOptionPreviewIndex] = useState<number>(0);
  const [addMealOption] = useAddMealOptionLocalMutation();
  const [selectedMealOptions, setSelectedMealOptions] = useSelectedMealOptionsContext();
  const mealOptionsToShow = 2;

  const { __typename, selectedMealOptionId, mealOptions, pendingMealOptions } = mealTemplate;
  const selectedMealOptionIdRef = useRef(selectedMealOptionId);

  // We are merging 2 lists into one here for UI display.  pendingMealOptions (the ones that couldn't scale their macros)
  // don't have a position field.  We want them at the front of the list and we want the regular mealOptions to maintain
  // their position field values.  So we provide negative position values (such that we don't need to sort because already in
  // position value sort order) for the pendingMealOptions.
  const mealTemplateId = mealTemplate.id;
  let mealOptionPreviews: MealOptionPreview[] = pendingMealOptions.map((pendingMealOption, index) => ({
    ...pendingMealOption,
    position: -pendingMealOptions.length + index,
  }));
  const sortedMealOptions = sortByKey(mealOptions, "position");
  mealOptionPreviews = mealOptionPreviews.concat(sortedMealOptions);

  useEffect(() => {
    // Update the 'Selected Meal Options' context so we can calculate nutrients elsewhere!
    if (selectedMealOptionId === selectedMealOptionIdRef.current) {
      return;
    }
    const newlySelectedMealOption = mealOptionPreviews.find(mo => mo.id === selectedMealOptionId);
    if (!newlySelectedMealOption) {
      return;
    }
    setSelectedMealOptions([
      ...selectedMealOptions.filter(mo => mo.mealTemplateId !== mealTemplate.id),
      { ...newlySelectedMealOption, mealTemplateId: mealTemplate.id },
    ]);
  }, [selectedMealOptions, selectedMealOptionId, mealTemplate.id, mealOptionPreviews, setSelectedMealOptions]);

  useEffect(() => {
    if (selectedMealOptionId !== selectedMealOptionIdRef.current) {
      selectedMealOptionIdRef.current = selectedMealOptionId;
      const selectedMealOptionPreviewIndex = mealOptionPreviews.findIndex(mo => mo.id === selectedMealOptionId);
      if (selectedMealOptionPreviewIndex > 0 && selectedMealOptionPreviewIndex !== mealOptionPreviewIndex) {
        setMealOptionPreviewIndex(selectedMealOptionPreviewIndex - 1);
      }
    }
  }, [selectedMealOptionId, mealOptionPreviewIndex, mealOptionPreviews]);

  const handleAddOption = useCallback(() => {
    addMealOption({ variables: { mealTemplateId, type: __typename } });
    setMealOptionPreviewIndex(Math.max(mealOptionPreviewIndex, mealOptionPreviews.length - mealOptionsToShow + 1));
  }, [mealTemplateId, mealOptionPreviews.length, __typename, addMealOption, mealOptionPreviewIndex]);

  return (
    <Box
      sx={{
        display: "grid",
        // Hard coded to Fab h/w of 40px and margin of 8
        gridTemplateColumns: `${40 / 2 - 8}px ${40 / 2 + 8}px 1fr ${56 - 40}px ${40 / 2 + 8}px ${40 / 2 - 8}px`,
        gridTemplateRows: "auto 40px auto 56px",
      }}
    >
      <Box sx={{ display: "flex", overflow: "hidden", gridColumnStart: 2, gridColumnEnd: 6, gridRowStart: 1, gridRowEnd: 5 }}>
        <CarouselWithChildren
          slideIndex={mealOptionPreviewIndex}
          afterSlide={slideIndex => setMealOptionPreviewIndex(slideIndex)}
          heightMode="max"
          transitionMode="scroll"
          speed={750}
          slidesToShow={mealOptionsToShow}
          slideWidth={MEAL_OPTION_SLIDE_WIDTH}
          withoutControls
          dragging={false}
        >
          {mealOptionPreviews.length > 0 ? (
            mealOptionPreviews.map((mealOption, index) => renderMealOptionPreview(mealOption, index))
          ) : (
            <AddNewMealOption onClick={handleAddOption} />
          )}
        </CarouselWithChildren>
      </Box>
      {mealOptionPreviewIndex > 0 && (
        <Fab
          sx={
            mealOptionPreviewIndex > 0
              ? {
                  gridColumnStart: 1,
                  gridColumnEnd: 3,
                  gridRowStart: 2,
                  backgroundColor: "greyscale.500",
                  "&:hover": {
                    backgroundColor: "greyscale.600",
                  },
                }
              : { display: "none" }
          }
          onClick={() => setMealOptionPreviewIndex(mealOptionPreviewIndex - 1)}
          size="small"
        >
          <KeyboardArrowLeftIcon />
        </Fab>
      )}
      {mealOptionPreviewIndex < mealOptionPreviews.length - 1 && (
        <Fab
          sx={{
            gridColumnStart: 5,
            gridColumnEnd: 7,
            gridRowStart: 2,
            backgroundColor: "greyscale.500",
            "&:hover": {
              backgroundColor: "greyscale.600",
            },
          }}
          onClick={() => setMealOptionPreviewIndex(mealOptionPreviewIndex + 1)}
          size="small"
        >
          <KeyboardArrowRightIcon />
        </Fab>
      )}
      {mealOptionPreviews.length > 0 && (
        <Fab
          sx={{ gridColumnStart: 4, gridColumnEnd: 7, gridRowStart: 4 }}
          onClick={handleAddOption}
          size="large">
          <AddIcon fontSize="large" />
        </Fab>
      )}
    </Box>
  );
};

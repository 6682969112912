import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { ContactFragment } from "../../../../../types";
import Form from "./Form";
import {
  RestaurantLocationContactFormState,
  buildContactFormTooltips,
  buildInitialEditFormState,
  restaurantLocationContactFormReducer,
} from "./Form/utils";

interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  initialState: ContactFragment;
  onEdit: (contactFormState: RestaurantLocationContactFormState) => void;
}

const EditDialog = ({ open, onClose, onEdit, initialState }: EditDialogProps) => {
  const [state, dispatch] = useReducer(restaurantLocationContactFormReducer, buildInitialEditFormState(initialState));

  const handleSave = () => {
    if (canSaveTooltips.length > 0) {
      return;
    }
    onEdit(state);
  };

  const canSaveTooltips = buildContactFormTooltips(state);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Edit Contact" onClose={onClose} />
      <DialogContent>
        <Form state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

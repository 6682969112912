import { weightToKg } from "./Anthropometry";
import { MacroGPerKg, MacroMath, MacroTarget, MacroType, Macros, MaybeMacros } from "./types/MacrosTypes";

export const roundMacros = ({ cho, pro, fat, kcal }: Macros) => ({
  cho: Math.round(cho),
  pro: Math.round(pro),
  fat: Math.round(fat),
  kcal: Math.round(kcal),
});

export const scaleMacros = ({ cho, pro, fat, kcal }: Macros, amount: number): Macros => ({
  cho: cho * amount,
  pro: pro * amount,
  fat: fat * amount,
  kcal: kcal * amount,
});

export const getMacrosInput = (macros: Macros): Omit<Macros, "kcal"> => {
  return { cho: macros.cho, pro: macros.pro, fat: macros.fat };
};

export const addMacros = (ma1: Macros, ma2: Macros): Macros => ({
  cho: ma1.cho + ma2.cho,
  pro: ma1.pro + ma2.pro,
  fat: ma1.fat + ma2.fat,
  kcal: ma1.kcal + ma2.kcal,
});

export const sumMacros = (macros: Macros[]): Macros => macros.reduce(addMacros, ZERO_MACROS);

export const ZERO_MACROS: Macros = {
  cho: 0,
  pro: 0,
  fat: 0,
  kcal: 0,
};

export const ZERO_MACROS_INPUT = {
  cho: 0,
  pro: 0,
  fat: 0,
};

export const maybeMacrosToMacros = ({ cho, pro, fat, kcal }: MaybeMacros): Macros => ({
  cho: cho || 0,
  pro: pro || 0,
  fat: fat || 0,
  kcal: kcal || 0,
});

export const initMacros = (cho: number, pro: number, fat: number) => ({ cho, pro, fat, kcal: getCalories(cho, pro, fat) });
export const getMacrosHash = ({ cho, pro, fat, kcal }: Macros): string => `cho:${cho},pro:${pro},fat${fat},kcal${kcal}`;
export const macrosAreEqual = (macros1: Macros, macros2: Macros) => getMacrosHash(macros1) === getMacrosHash(macros2);

//
// Calories per gram
//

export const getMacroCaloriesPerGram = (macro: MacroType): number => {
  switch (macro) {
    case "cho":
    case "pro":
      return 4;
    case "fat":
      return 9;
    default:
      return 0;
  }
};

export const getCalories = (cho: number, pro: number, fat: number) =>
  cho * getMacroCaloriesPerGram("cho") + pro * getMacroCaloriesPerGram("pro") + fat * getMacroCaloriesPerGram("fat");

export const getMacrosCalories = ({ cho, pro, fat }: Macros): number => getCalories(cho, pro, fat);

export const getMacrosCaloriesRounded = (macros: Macros) => Math.round(getMacrosCalories(macros));

export const getCaloriesPerMacro = ({ cho, pro, fat }: Macros): Macros => ({
  cho: getMacroCaloriesPerGram("cho") * cho,
  pro: getMacroCaloriesPerGram("pro") * pro,
  fat: getMacroCaloriesPerGram("fat") * fat,
  kcal: 0,
});

//
// MacroMath specific
//

export const macroMathLeftOverRatioTotal = (cho: MacroMath, pro: MacroMath, fat: MacroMath) =>
  cho.leftOverRatio + pro.leftOverRatio + fat.leftOverRatio;

//
// MacroTarget specific
//

export const macroTargetPercentTotal = (cho: MacroTarget, pro: MacroTarget, fat: MacroTarget) => cho.percent + pro.percent + fat.percent;

//
// MacroMath/MacrosTarget shared gPerKg
//

export const gPerKgToGrams = (gPerKg: number, weight: number): number => gPerKg * weightToKg(weight);

export const gPerKgToCalories = (gPerKg: number, weight: number, macro: MacroType): number =>
  gPerKgToGrams(gPerKg, weight) * getMacroCaloriesPerGram(macro);

export const macroGPerKgToGrams = (macro: MacroGPerKg, weight: number): number => gPerKgToGrams(macro.gPerKg, weight);

export const macroGPerKgToGramsRounded = (macro: MacroGPerKg, weight: number): number => Math.round(macroGPerKgToGrams(macro, weight));

export const macroGPerKgToGramsMacros = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weight: number): Macros =>
  initMacros(macroGPerKgToGrams(cho, weight), macroGPerKgToGrams(pro, weight), macroGPerKgToGrams(fat, weight));

export const macroGPerKgToGramsMacrosRounded = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weight: number): Macros =>
  roundMacros(macroGPerKgToGramsMacros(cho, pro, fat, weight));

export const macroGPerKgCaloriesTotal = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weight: number) =>
  getMacrosCalories(macroGPerKgToGramsMacros(cho, pro, fat, weight));

export const macroGPerKgCaloriesTotalRounded = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weight: number) =>
  Math.round(macroGPerKgCaloriesTotal(cho, pro, fat, weight));

//
// Metric Update
// alternative implementation could be to pass isMetric around until calculations reach weightToKg()
//

export const macroMetricGPerKgToGramsRounded = (macro: MacroGPerKg, weightInKg: number): number => Math.round(macro.gPerKg * weightInKg);

export const metricGPerKgToCalories = (gPerKg: number, weightInKg: number, macro: MacroType): number =>
  gPerKg * weightInKg * getMacroCaloriesPerGram(macro);

export const macroGPerKgToGramsMetricMacros = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weightInKg: number): Macros =>
  initMacros(cho.gPerKg * weightInKg, pro.gPerKg * weightInKg, fat.gPerKg * weightInKg);

export const macroGPerKgCaloriesMetricTotal = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weightInKg: number) =>
  getMacrosCalories(macroGPerKgToGramsMetricMacros(cho, pro, fat, weightInKg));

export const macroGPerKgCaloriesMetricTotalRounded = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weightInKg: number) =>
  Math.round(macroGPerKgCaloriesMetricTotal(cho, pro, fat, weightInKg));

export const macroGPerKgToGramsMetricMacrosRounded = (cho: MacroGPerKg, pro: MacroGPerKg, fat: MacroGPerKg, weightInKg: number): Macros =>
  roundMacros(macroGPerKgToGramsMetricMacros(cho, pro, fat, weightInKg));

import React from "react";
import { TeamworksTeam } from "../reducer";
import TeamsList from "./TeamsList";

interface TeamworksTeamsListProps {
  selectedId: string | null;
  teams: TeamworksTeam[];
  onLink: (team: TeamworksTeam) => void;
  onAdd: (team: TeamworksTeam) => void;
}

const TeamworksTeamsList = (props: TeamworksTeamsListProps) => {
  const { selectedId, teams } = props;

  return <TeamsList
    {...props}
    teams={teams}
    title="Teamworks Teams"
    columnMode={selectedId ? "Link" : "Add"}
    showLinkedAndPendingCount />;
};

export default TeamworksTeamsList;

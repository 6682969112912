import React, { ReactNode } from "react";
import TablePage from "../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../components/universal/TablePage/DefaultHeader";
import { PaginationHooks } from "../../../utils/pagination";
import TableRow, { MenuAction, TableHeaderRow, TogglableRowProps } from "./TableRow";
import { HeaderRowInfo, Idable, OffsetConnectionData } from "./types";

interface TableProps<T extends Idable> {
  offsetConnectionData?: OffsetConnectionData<T>;
  loading: boolean;
  paginationHooks: PaginationHooks;
  onEdit?: (obj: T) => void;
  onDelete?: (obj: T) => void;
  onRowClick?: (obj: T) => void;
  onCreate: () => void;
  entityName: string;
  headerRowInfo: HeaderRowInfo[];
  convertObjToRowData: (obj: T) => Record<string, ReactNode>;
  showSearchQuery?: boolean;
  togglable?: TogglableRowProps<T>;
  actionMenuItems?: MenuAction<T>[];
}

const Table = <T extends Idable>({
  offsetConnectionData,
  loading,
  paginationHooks,
  onEdit,
  onDelete,
  onCreate,
  onRowClick,
  entityName,
  headerRowInfo,
  convertObjToRowData,
  showSearchQuery = true,
  togglable,
  actionMenuItems,
}: TableProps<T>) => {
  const hasEdit = onEdit !== undefined;
  const hasDelete = onDelete !== undefined;
  const hasActionMenu = Boolean(actionMenuItems && actionMenuItems.length > 0);
  const { queryText, onChangeQueryText } = paginationHooks;

  const Header = (
    <TablePageDefaultHeader
      title={entityName}
      queryText={showSearchQuery ? queryText : undefined}
      onChangeQueryText={showSearchQuery ? onChangeQueryText : undefined}
      onCreate={onCreate}
    />
  );

  const TableBodyRows = offsetConnectionData
    ? offsetConnectionData.edges.map(obj => (
        <TableRow<T>
          key={obj.id}
          obj={obj}
          togglable={togglable}
          onEdit={onEdit}
          onDelete={onDelete}
          onRowClick={onRowClick}
          actionMenuItems={actionMenuItems}
          headerRowInfo={headerRowInfo}
          rowData={convertObjToRowData(obj)}
        />
      ))
    : null;

  return (
    <TablePage
      header={Header}
      tableHeaderRow={
        <TableHeaderRow
          hasEdit={hasEdit}
          hasDelete={hasDelete}
          hasActionMenu={hasActionMenu}
          togglable={togglable}
          headerRowInfo={headerRowInfo}
        />
      }
      tableBodyRows={TableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={offsetConnectionData?.pageInfo.total || 0}
      size="medium"
    />
  );
};

export default Table;

import { createContext, useContext } from "react";
import { EditFullMealOptionFragment, EditFullMealOptionPendingFragment } from "../types";

export interface MealOptionWithMealTemplateId extends EditFullMealOptionFragment {
  mealTemplateId: string;
}

export interface MealOptionPendingWithMealTemplateId extends EditFullMealOptionPendingFragment {
  mealTemplateId: string;
}

export type MealOptionPreviewWithMealTemplateId = MealOptionWithMealTemplateId | MealOptionPendingWithMealTemplateId;

type SelectedMealOptionsContextType = [
  readonly MealOptionPreviewWithMealTemplateId[],
  (mealOptionPreviews: readonly MealOptionPreviewWithMealTemplateId[]) => void
];

export const SelectedMealOptionsContext = createContext<SelectedMealOptionsContextType | null>(null);

export const useSelectedMealOptionsContext = () => {
  const value = useContext(SelectedMealOptionsContext);
  if (value === null) {
    throw Error("Missing SelectedMealOptionsContext");
  }
  return value;
};

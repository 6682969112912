import React from "react";
import { PlateRowDataInfo } from "../utils";
import TableRow, { HeaderRow } from "./TableRow";
import SelectionTable from "../Shared/SelectionTable";

interface TableProps {
  plateRowInfoArray: PlateRowDataInfo[];
}

const Table = ({ plateRowInfoArray }: TableProps) => {
  return (
    <SelectionTable
      selectionRowInfoArray={plateRowInfoArray}
      renderTableHeader={length => {
        return <HeaderRow length={length} />;
      }}
      renderTableRow={(plateRowInfo, openShowMoreModal, length) => (
        <TableRow
          key={`plate-table-row-${plateRowInfo.id}`}
          plateRowInfo={plateRowInfo}
          openShowMoreModal={openShowMoreModal}
          length={length}
        />
      )}
    />
  );
};

export default Table;

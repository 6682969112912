import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { ExchangeSetFragment, ExchangeSetsDocument, ExchangeSetsQuery, useCopyExchangeSetMutation } from "../../../types";

interface CopyConfirmModalProps {
  onClose: () => void;
  open: boolean;
  exchangeSetToCopy: ExchangeSetFragment;
}

const CopyConfirmModal = ({ onClose, open, exchangeSetToCopy }: CopyConfirmModalProps) => {
  const [name, setName] = useState(`${exchangeSetToCopy.name} [Copy]`);
  const [copyExchangeSet] = useCopyExchangeSetMutation({
    update: (dataProxy, result) => {
      const exchangeSetsQuery = dataProxy.readQuery<ExchangeSetsQuery>({
        query: ExchangeSetsDocument,
      });
      if (result.data && exchangeSetsQuery) {
        dataProxy.writeQuery({
          query: ExchangeSetsDocument,
          data: {
            ...exchangeSetsQuery,
            exchangeSets: exchangeSetsQuery.exchangeSets.concat([result.data.copyExchangeSet.exchangeSet]),
          },
        });
      }
      onClose();
    },
  });

  const onCopy = () => {
    copyExchangeSet({
      variables: {
        input: {
          id: exchangeSetToCopy.id,
          name,
        },
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs">
      <DialogTitle title="Copy Exchange Set" onClose={onClose} />
      <DialogContent>
        <TextField
          value={name}
          onChange={e => setName(e.target.value)}
          autoFocus
          margin="dense"
          label="Enter name of new exchange set"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onCopy}>Copy</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyConfirmModal;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AthleteForLinking } from "@notemeal/profile-sync";
import React from "react";
import ListsColumn from "../../shared/ListColumn";
import ListsContainer from "../../shared/ListsContainer";
import ProfilesList from "../SyncRule/DisplayLists/ProfilesList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    listColumn: {
      overflow: "hidden",
    },
  })
);

interface ArchiveAthleteDialogProps {
  open: boolean;
  onClose: () => void;
  unlinkedNotemealAthletes: readonly AthleteForLinking[];
  archiveAthletes: readonly AthleteForLinking[];
  onArchive: (athleteId: string) => void;
  onUnarchive: (athleteId: string) => void;
}

const ArchiveAthleteDialog = ({
  open,
  onClose,
  unlinkedNotemealAthletes,
  archiveAthletes,
  onArchive,
  onUnarchive,
}: ArchiveAthleteDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      classes={{ paper: classes.paper }}>
      <DialogTitle>
        Archive Non-User Account Athletes
        <Typography variant="body1Medium">Archive athletes without user accounts that should not be synced with Teamworks</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ListsContainer>
          <ListsColumn className={classes.listColumn}>
            <ProfilesList
              title="Unlinked Athletes"
              columnMode="Add"
              onAdd={a => onArchive(a.id)}
              profiles={unlinkedNotemealAthletes}
              getProfileForDisplay={p => ({ ...p, missingTeamworksRequiredFields: false })}
              selectedId={null}
            />
          </ListsColumn>

          <ListsColumn className={classes.listColumn}>
            <ProfilesList
              title="Archive Athletes"
              columnMode="Cancel"
              onCancel={a => onUnarchive(a.id)}
              profiles={archiveAthletes}
              getProfileForDisplay={p => ({ ...p, missingTeamworksRequiredFields: false })}
              selectedId={null}
            />
          </ListsColumn>
        </ListsContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveAthleteDialog;

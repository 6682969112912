import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, Button, InputAdornment, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { FullServingAmountFragment } from "../../../types";
import ServingAmountsMultiColumnChipList from "../../ServingAmounts/MultiColumnChipList";
import { WarningTooltip } from "../../universal/Tooltip";
import { ExchangeState } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { primary, common } }: Theme) =>
  createStyles({
    nameTextField: {
      flexGrow: 2,
    },
    shortNameTextField: {
      flexGrow: 1,
    },
    servingsListTitle: {
      marginTop: spacing(1),
    },
    servingsListContent: {
      width: "100%",
      minHeight: "200px",
    },
    autoscaleServingsButton: {
      flexGrow: 1,
      margin: spacing(1, 0),
    },
    chipList: {
      height: 290,
    },
  })
);

interface ExchangeSetFormExchangeInputProps {
  exchange: ExchangeState;
  onEditName: (name: string) => void;
  onEditShortName: (shortName: string) => void;
  onEditMacroInput: (macroInput: string, macro: "cho" | "pro" | "fat") => void;
  //onAddServingList: () => void;
  //onEditServingListName: (servingListId: string, name: string) => void;
  onEditMasterServingListServingAmounts: (servingAmounts: readonly FullServingAmountFragment[]) => void;
  onEditMasterServingListAutoScale: () => void;
}

const ExchangeSetFormExchangeInput = ({
  exchange,
  onEditName,
  onEditShortName,
  onEditMacroInput,
  onEditMasterServingListAutoScale /*onAddServingList, onEditServingListName, */,
  onEditMasterServingListServingAmounts,
}: ExchangeSetFormExchangeInputProps) => {
  const classes = useStyles();
  const PRO_EXCHANGES = ["protein", "vegetable", "starch", "dairy"];
  const firstServingList = exchange.exchangeServingList;

  const makeMacroTextField = (macro: "cho" | "pro" | "fat") => {
    const macroInputKey = macro === "cho" ? "choInput" : macro === "pro" ? "proInput" : "fatInput";

    return (
      <WarningTooltip
        open={macro === "pro" && exchange[macroInputKey] === "0" && PRO_EXCHANGES.includes(exchange.type)}
        msg={`Most "${exchange.type}" servings contain more than 0g of ${macro}`}
      >
        <TextField
          value={exchange[macroInputKey]}
          onChange={e => onEditMacroInput(e.target.value, macro)}
          label={macro.toUpperCase()}
          type="number"
          inputProps={{ min: 0, step: 0.5 }}
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
        />
      </WarningTooltip>
    );
  };

  return (
    <Box sx={{ ml: 2 }}>
      <Typography variant="h3">General</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          className={classes.nameTextField}
          label="Name"
          value={exchange.name}
          onChange={e => onEditName(e.target.value)}
          margin="dense"
        />
        <TextField
          className={classes.shortNameTextField}
          label="Short Name"
          value={exchange.shortName}
          onChange={e => onEditShortName(e.target.value)}
          margin="dense"
        />
      </Box>
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
        {makeMacroTextField("cho")}
        {makeMacroTextField("pro")}
        {makeMacroTextField("fat")}
        <Button
          variant="outlined"
          onClick={onEditMasterServingListAutoScale}
          startIcon={<AutorenewIcon />}>
          Autoscale Servings
        </Button>
      </Box>
      <Typography variant="h3" className={classes.servingsListTitle}>
        Servings List
      </Typography>
      <ServingAmountsMultiColumnChipList
        selectedServingAmounts={firstServingList.servingAmounts.map(sa => ({
          ...sa,
          expanded: true,
        }))}
        onChange={sa => onEditMasterServingListServingAmounts(sa)}
        className={classes.chipList}
      />
    </Box>
  );
};

export default ExchangeSetFormExchangeInput;

import { MaybeJwtUser } from "@notemeal/shared/ui/utils/tokens";
import { MyFeatureFlagsQuery, Role } from "../../types";
import { NAV_ROOT, NAV_STAFF, NAV_SWITCH_ORG } from "./AuthPaths";
import { NAV_ORG_TEAM_MANAGEMENT } from "./Org/TeamManagement/TeamManagementPaths";
import { NAV_ORG_KITCHEN, NAV_ORG_KITCHEN_KIOSK } from "./Org/Kitchen/KitchenPaths";
import { NAV_ORG_FOOD_MANAGEMENT } from "./Org/FoodManagement/FoodManagementPaths";
import { NAV_LOGIN } from "../NoAuth/NoAuthPaths";
import { NAV_MOBILE_WELCOME } from "./AuthPaths";

export interface OrgUserDetails {
  userId: string | undefined;
  userName: string | undefined;
  isStaff: boolean;
  isSuperuser: boolean;
  hasOrgMembership: boolean;
  orgMembershipId: string | undefined;
  orgId: string | undefined;
  orgName: string | undefined;
  isAdmin: boolean;
  hasRoleDietitian: boolean;
  hasRoleChef: boolean;
  hasRoleFoodManager: boolean;
  hasRoleAthlete: boolean;
  hasRoleKiosk: boolean;
  hasRoleNonRdAdmin: boolean;
  roles: Role[];
}

export const getOrgUserDetails = (user: MaybeJwtUser): OrgUserDetails => ({
  userId: user?.id,
  userName: user?.username,
  isStaff: !!user?.isStaff,
  isSuperuser: !!user?.isSuperuser,
  hasOrgMembership: !!user?.orgMembership,
  orgMembershipId: user?.orgMembership?.id,
  orgId: user?.orgMembership?.org.id,
  orgName: user?.orgMembership?.org.name,
  isAdmin: !!user?.orgMembership?.isAdmin,
  hasRoleDietitian: !!user?.orgMembership?.roles.includes("dietitian"),
  hasRoleChef: !!user?.orgMembership?.roles.includes("chef"),
  hasRoleFoodManager: !!user?.orgMembership?.roles.includes("foodmanager"),
  hasRoleAthlete: !!user?.orgMembership?.roles.includes("athlete"),
  hasRoleKiosk: !!user?.orgMembership?.roles.includes("kiosk"),
  hasRoleNonRdAdmin: !!user?.orgMembership?.roles.includes("nonRdAdmin"),
  roles: user?.orgMembership?.roles || [],
});

export const getPriorityRole = ({
  hasRoleDietitian,
  hasRoleChef,
  hasRoleFoodManager,
  hasRoleAthlete,
  hasRoleKiosk,
  hasRoleNonRdAdmin,
}: OrgUserDetails) =>
  hasRoleDietitian
    ? "dietitian"
    : hasRoleNonRdAdmin
    ? "nonRdAdmin"
    : hasRoleChef
    ? "chef"
    : hasRoleFoodManager
    ? "foodmanager"
    : hasRoleAthlete
    ? "athlete"
    : hasRoleKiosk
    ? "kiosk"
    : "";

export const getOrgFallbackUri = (orgUserDetails: OrgUserDetails) => {
  const priorityRole = getPriorityRole(orgUserDetails);
  switch (priorityRole) {
    case "dietitian":
    case "nonRdAdmin":
      return NAV_ORG_TEAM_MANAGEMENT;
    case "chef":
      return NAV_ORG_KITCHEN;
    case "foodmanager":
      return NAV_ORG_FOOD_MANAGEMENT;
    case "kiosk":
      return NAV_ORG_KITCHEN_KIOSK;
    case "athlete":
      return NAV_MOBILE_WELCOME;
    default:
      // This should only be hit if a user has no roles
      return NAV_LOGIN;
  }
};

export const getFallbackUri = (hasRefreshToken: boolean, orgUserDetails: OrgUserDetails) => {
  const { isStaff, hasOrgMembership } = orgUserDetails;
  return isStaff ? NAV_STAFF : hasOrgMembership ? getOrgFallbackUri(orgUserDetails) : hasRefreshToken ? NAV_SWITCH_ORG : NAV_ROOT;
};

export interface FeatureFlagsDetails {
  hasPerformanceKitchen: boolean;
  hasMessaging: boolean;
}

export const getFeatureFlagsDetails = (featureFlagsData?: MyFeatureFlagsQuery): FeatureFlagsDetails => ({
  hasPerformanceKitchen: !!featureFlagsData?.myFeatureFlags["performanceKitchen"],
  hasMessaging: !!featureFlagsData?.myFeatureFlags["messaging"],
});

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

interface AttendanceErrorProps {
  title: string;
  description: string;
}

export const AttendanceError = ({ title, description }: AttendanceErrorProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ flex: 1, mt: "20%", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
      <Box sx={{ width: 400, display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}>
        <ErrorOutlineIcon fontSize="large" sx={{ color: theme => theme.palette.error.main }} />
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
        <Button sx={{ mt: 2 }} onClick={() => navigate(-1)}>
          Okay
        </Button>
      </Box>
    </Box>
  );
};

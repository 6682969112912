import CommentIcon from "@mui/icons-material/Comment";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

interface CommentButtonProps {
  onClick: () => void;
  commentCount: number;
}
const CommentButton = ({ onClick, commentCount }: CommentButtonProps) => {
  return (
    <Button
      sx={{ m: 2, mt: 1, backgroundColor: "grey.100", width: "93%" }}
      variant="text"
      onClick={onClick}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <CommentIcon fontSize="small" color="disabled" />
        <Typography color="black" variant="body2">{`${commentCount} Comments`}</Typography>
      </Box>
    </Button>
  );
};

export default CommentButton;

import { EditMacroProtocolAction, EditMetricMacroProtocolState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import React, { Dispatch } from "react";
import { GoalTypeFragment } from "../../../types";
import { MacroProtocolForm } from "./MacroProtocolForm";

interface MetricMacroProtocolFormProps {
  state: EditMetricMacroProtocolState;
  dispatch: Dispatch<EditMacroProtocolAction>;
  goalTypes: readonly GoalTypeFragment[];
}

export const MetricMacroProtocolForm = ({ state, dispatch, goalTypes }: MetricMacroProtocolFormProps) => {
  return <MacroProtocolForm
    state={state}
    dispatch={dispatch}
    goalTypes={goalTypes} />;
};

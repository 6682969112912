import { eachDayOfInterval } from "date-fns";
import { dateToIsoInTz, serializeDate } from "../utils/dateTimes";
import { NotificationTimelineFragment, TimelineMealForGroupByDateFragment } from "../types";

export interface TimelineMealsAndItemsByDate {
  date: string;
  mealsAndItemsCount: number;
  notifications: readonly NotificationTimelineFragment[];
}

// TODO: Move this to the backend
/**
 * Groups timeline meals items by date, and counts the notifications on that date.
 * Does not currently handle MealMenus.
 *
 * @param items Timeline items to group into dates by occurrence. MealMenus are ignored.
 * @param start Start of range to group items into dates on
 * @param end End of range to group items into dates on
 * @param clientTimezone Timezone being used
 */
export const groupTimelineMealsByDate = (
  timelineMeals: readonly TimelineMealForGroupByDateFragment[],
  start: Date,
  end: Date,
  clientTimezone: string
): readonly TimelineMealsAndItemsByDate[] => {
  return eachDayOfInterval({
    start,
    end,
  }).flatMap((jsDate, i, arr) => {
    const nextJsDate = arr[i + 1] || end;

    const date = serializeDate(jsDate);
    const nextDate = serializeDate(nextJsDate);

    const isoDate = dateToIsoInTz(date, clientTimezone);
    const nextIsoDate = dateToIsoInTz(nextDate, clientTimezone);

    const mealIds = timelineMeals.flatMap(({ id, start }) => {
      if (start >= isoDate && start < nextIsoDate) {
        return [id];
      } else {
        return [];
      }
    });

    const notifications = timelineMeals.flatMap(({ id, start, notifications }) => {
      if (start >= isoDate && start < nextIsoDate) {
        return [notifications];
      } else {
        return [];
      }
    });

    const mealsAndItemsCount = mealIds.length;
    if (mealsAndItemsCount === 0) {
      return [];
    }

    return {
      date,
      mealsAndItemsCount,
      notifications: notifications.flatMap(n => n ?? []),
    };
  });
};

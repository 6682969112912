import React, { useState } from "react";
import {
  TerritoryFragment,
  useCreateTerritoryMutation,
  useEditTerritoryMutation,
  useTerritoryOffsetConnectionQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import { CreateTerritoryDialog } from "../../../../views/Staff/Territory/CreateDialog";
import { EditTerritoryDialog } from "../../../../views/Staff/Territory/EditDialog";
import { TerritoryTable } from "../../../../views/Staff/Territory/Table";

export const TerritoryPage = () => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading, refetch } = useTerritoryOffsetConnectionQuery({
    variables: {
      input: { limit, offset },
      query,
    },
    fetchPolicy: "network-only",
  });

  const [editTerritory] = useEditTerritoryMutation({ onCompleted: () => refetch() });
  const [createTerritory] = useCreateTerritoryMutation({ onCompleted: () => refetch() });
  const handleEditTerritory = (name: string, orgIds: string[], removeOrgIds: string[]) => {
    if (editingTerritory) {
      editTerritory({
        variables: { input: { name, id: editingTerritory.id, orgIds, removeOrgIds } },
      });
    }
  };
  const handleCreateTerritory = (name: string, orgIds: string[]) => {
    createTerritory({ variables: { input: { name, orgIds } } });
  };
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [editingTerritory, setEditingTerritory] = useState<TerritoryFragment | null>(null);

  return (
    <>
      <TerritoryTable
        onEditTerritory={setEditingTerritory}
        offsetConnectionData={data}
        loading={loading}
        paginationHooks={paginationHooks}
        onCreateTerritory={() => setShowCreate(true)}
      />
      {showCreate && <CreateTerritoryDialog
        open={showCreate}
        onClose={() => setShowCreate(false)}
        onSave={handleCreateTerritory} />}
      {!!editingTerritory && (
        <EditTerritoryDialog
          open={editingTerritory !== null}
          onClose={() => setEditingTerritory(null)}
          onSave={handleEditTerritory}
          initialTerritory={editingTerritory}
        />
      )}
    </>
  );
};

import { Box, SxProps, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { getNextPosition } from "@notemeal/shared/ui/utils/getNextPosition";
import newId from "@notemeal/shared/ui/utils/newId";
import React, { Dispatch, useState } from "react";
import CropperModal from "../../../universal/Image/CropModal";
import ImageFileDragAndDrop from "../../../universal/Image/ImageFileDragAndDrop";
import { useUploadPhoto } from "../../../universal/Image/uploadPhoto";
import { Image, RecipeFormAction, RecipeFormState } from "../utils";
import { ImageStackPreview, UploadImage } from "./Image";

interface ImagesProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  disabled?: boolean;
}

const Images = ({ state, dispatch, disabled }: ImagesProps) => {
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);

  const handleDrop = (url: string) => {
    setImageToCrop(url);
  };

  const handleCropComplete = async (croppedImage: string) => {
    uploadPhoto(croppedImage);
  };

  const [uploadPhoto, imageUploading] = useUploadPhoto({
    onComplete: (imageUrl: string): void => {
      if (imageUrl) {
        const incomingImage: Image = {
          url: imageUrl,
          position: getNextPosition(state.images),
          id: newId(),
        };
        const images = [...state.images, incomingImage];
        dispatch({
          type: "CHANGE_IMAGES",
          value: images,
        });
      }
    },
  });

  const imageBoxStyle: SxProps = { width: 282, height: 282 };

  return (
    <>
      {state.images.length === 0 ? (
        <Box sx={imageBoxStyle}>
          <ImageFileDragAndDrop
            disabled={disabled}
            onUpload={handleDrop}
            sx={{ py: 2, px: 3 }}>
            {imageUploading ? <Loading progressSize="xs" /> : <Typography variant="subtitle1">Click to upload or drop an image</Typography>}
          </ImageFileDragAndDrop>
        </Box>
      ) : (
        <Box sx={{ flexDirection: "column" }}>
          <ImageStackPreview
            disabled={disabled}
            images={state.images}
            sx={imageBoxStyle}
            onChangeImages={images => {
              dispatch({ type: "CHANGE_IMAGES", value: images });
            }}
            imageUploading={imageUploading}
          />
          <UploadImage disabled={disabled} handleDrop={handleDrop} />
        </Box>
      )}
      {imageToCrop && (
        <CropperModal
          open={!!imageToCrop}
          onClose={() => setImageToCrop(null)}
          imageUrl={imageToCrop}
          onSave={handleCropComplete}
        ></CropperModal>
      )}
    </>
  );
};

export default Images;

import { Chip, DialogContent, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByFn } from "@notemeal/utils/sort";
import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { AthleteForCustomTagFragment } from "../../../../../../types";
import { AddExcludeAthleteAction } from "../../../../reducers/advancedSelectionReducers";
import { getCompareString, sortGroupAndFilterAthletesByTeam } from "../../../../utils";
import SelectedAthleteRow from "./SelectedAthleteRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    teamChip: {
      width: "100%",
      justifyContent: "flex-start",
    },
    athleteSearch: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  })
);

interface ViewSelectedAthletesContentProps {
  athletes: readonly AthleteForCustomTagFragment[];
  dispatch: Dispatch<AddExcludeAthleteAction>;
}

const ViewSelectedAthletesContent = ({ athletes, dispatch }: ViewSelectedAthletesContentProps) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  const groupedAthletes = useMemo(
    () =>
      sortGroupAndFilterAthletesByTeam({
        athletes,
        sortFn: athleteList => sortByFn(athleteList, getCompareString),
        filterFn: athletesList =>
          athletesList.filter(athlete => {
            return getCompareString(athlete).includes(searchText.toLowerCase());
          }),
      }),
    [athletes, searchText]
  );

  const onRemoveAthlete = useCallback(
    (athlete: AthleteForCustomTagFragment) => dispatch({ type: "ADD_EXCLUDE_ATHLETE", payload: athlete }),
    [dispatch]
  );

  return (
    <DialogContent sx={{ maxHeight: "100%", overflowY: "auto" }}>
      <TextField
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        placeholder="Search Athletes"
        className={classes.athleteSearch}
      />
      {groupedAthletes.map(({ id, name: teamName, athletes: athletesForTeam }) => {
        return (
          <div key={id}>
            <Chip
              label={teamName}
              className={classes.teamChip}
              size="medium" />
            {athletesForTeam.map(athlete => (
              <SelectedAthleteRow
                athlete={athlete}
                onClick={onRemoveAthlete}
                key={athlete.id} />
            ))}
          </div>
        );
      })}
    </DialogContent>
  );
};

export default ViewSelectedAthletesContent;

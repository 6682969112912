import { Box, useTheme } from "@mui/material";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { MultiColorDial } from "./MultiColorDial";

interface MultiColorDialsProps {
  current: Macros;
  target: Macros;
  variant?: "lg" | "md" | "sm";
  hideNumbers?: boolean;
}

export const MultiColorDials = ({ current, target, variant, hideNumbers }: MultiColorDialsProps) => {
  const {
    palette: { cho, pro, fat },
  } = useTheme();
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <MultiColorDial
        hideNumbers={hideNumbers}
        variant={variant}
        current={current.cho}
        target={target.cho}
        label="CHO"
        color={cho} />
      <MultiColorDial
        hideNumbers={hideNumbers}
        variant={variant}
        current={current.pro}
        target={target.pro}
        label="PRO"
        color={pro} />
      <MultiColorDial
        hideNumbers={hideNumbers}
        variant={variant}
        current={current.fat}
        target={target.fat}
        label="FAT"
        color={fat} />
    </Box>
  );
};

import { DateSchema, GoalTypeSchema, IntegerSchema } from "@notemeal/validators";
import { CreateGoalInput, FullGoalFragment, GoalFormInput } from "../../../types";
import { z } from "zod";
import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";

const BaseGoalSchema = z.object({
  start: DateSchema,
  end: DateSchema.nullable(),
  kcalOffset: IntegerSchema,
  type: GoalTypeSchema,
});

export const GoalSchema = (options?: { overrides: z.ZodObject<{}> }) =>
  BaseGoalSchema.merge(options?.overrides ?? z.object({})).superRefine((state, ctx) => {
    if (state.end && state.start && state.end < state.start) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid",
        path: ["end"],
      });
    }
  });

export type GoalType = z.infer<typeof BaseGoalSchema>;

export const goalFormDefaultValues = (): Partial<GoalType> => ({
  start: new Date(),
  end: null,
});

export const goalFormToInput = (state: GoalType & { start: Date }): GoalFormInput => ({
  goalTypeId: state.type.id,
  start: serializeDate(state.start),
  end: state.end ? serializeDate(state.end) : null,
  kcalOffset: state.kcalOffset,
});

export const goalFormToCreateInput = (state: GoalType & { start: Date }, athleteId: string): CreateGoalInput => ({
  athleteId,
  goal: goalFormToInput(state),
});

export const goalFragmentToGoalForm = (goal: FullGoalFragment): GoalType => ({
  start: parseDate(goal.start),
  end: goal.end === null ? null : parseDate(goal.end),
  type: goal.type,
  kcalOffset: goal.kcalOffset,
});

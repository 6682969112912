import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Card, CardActionArea, Divider, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useSnackbar } from "../../components/Snackbar/SnackbarContext";
import { getNavOrgKitchenMenuScheduleDate } from "../../pages/Auth/Org/Kitchen/KitchenPaths";
import { KdsPageMealMenuDiningStationFragment, KdsPageMealMenuInDateRangeFragment } from "../../types";

interface DiningStationsProps {
  diningStations: readonly KdsPageMealMenuDiningStationFragment[];
  selectedDiningStationIds: string[];
  setSelectedDiningStationIds: (diningStationIds: string[]) => void;
  onDone: () => void;
  onBack: () => void;
  mealMenus: readonly KdsPageMealMenuInDateRangeFragment[];
}

const useStyles = makeStyles(({ spacing, palette: { success, info, error, grey } }: Theme) =>
  createStyles({
    root: {
      padding: spacing(2),
    },
    marginBottom: {
      marginBottom: spacing(3),
    },
    iconGridColumn: {
      alignSelf: "center",
      textAlign: "end",
    },
    red: { color: error.main },
    green: { color: success.main },
    grey: { color: grey[300] },
    diningStationCard: {
      padding: spacing(),
    },
    selectedMealMenuCard: {
      padding: spacing(),
      backgroundColor: info.lighter,
    },
    diningStationCardActionArea: {
      padding: spacing(1),
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
    },
    bulkSelect: {
      padding: spacing(3),
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
    },
    emptyDiningStationCardActionArea: {
      padding: spacing(1),
      display: "flex",
      flexFlow: "column",
    },
    container: {
      paddingTop: spacing(4),
    },
    backButton: {
      marginRight: spacing(),
      alignSelf: "center",
    },
    doneButton: {
      marginRight: spacing(),
      alignSelf: "center",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: spacing(),
    },
  })
);

export const DiningStations = ({
  mealMenus,
  diningStations,
  selectedDiningStationIds,
  setSelectedDiningStationIds,
  onDone,
  onBack,
}: DiningStationsProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { setMessage } = useSnackbar();

  const isSelected = (ds: KdsPageMealMenuDiningStationFragment) => {
    return selectedDiningStationIds.includes(ds.id);
  };

  const handleSelectAll = () => {
    setSelectedDiningStationIds(diningStations.map(ds => ds.id));
  };

  const handleDeselectAll = () => {
    setSelectedDiningStationIds([]);
  };

  const handleClick = (ds: KdsPageMealMenuDiningStationFragment) => {
    if (isSelected(ds)) {
      setSelectedDiningStationIds(selectedDiningStationIds.filter(dsId => dsId !== ds.id));
    } else {
      setSelectedDiningStationIds([...selectedDiningStationIds, ds.id]);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => onBack()}>
          Back to Menus
        </Button>
        <Typography variant="h4">Select Stations</Typography>
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIcon />}
          onClick={() => (selectedDiningStationIds.length > 0 ? onDone() : setMessage("error", "Select at least 1 dining station."))}
        >
          Continue to KDS
        </Button>
      </div>
      <Grid
        container
        spacing={4}
        className={classes.container}>
        {diningStations.length === 0 ? (
          <Card raised className={classes.diningStationCard}>
            <CardActionArea
              className={classes.emptyDiningStationCardActionArea}
              onClick={() => navigate(getNavOrgKitchenMenuScheduleDate(mealMenus[0].start))}
            >
              <Typography variant="h4">{`No Dining Stations for Menu!`}</Typography>
              <Typography color="textSecondary" variant="body1Medium">
                Click here to add dining stations to the menu.
              </Typography>
            </CardActionArea>
          </Card>
        ) : (
          sortByKey(diningStations, "position").map(ds => (
            <Grid
              key={ds.id}
              item
              md={4}>
              <Card className={isSelected(ds) ? classes.selectedMealMenuCard : classes.diningStationCard} raised>
                <CardActionArea className={classes.diningStationCardActionArea} onClick={() => handleClick(ds)}>
                  <Grid container>
                    <Grid item md={9}>
                      <Typography variant="h4">{ds.name}</Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      className={classes.iconGridColumn}>
                      <CheckCircleIcon fontSize="large" className={isSelected(ds) ? classes.green : classes.grey} />
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        )}
        <Grid item md={12}>
          {" "}
          <Divider className={classes.marginBottom} />{" "}
        </Grid>
        <Grid item md={3}>
          <Card raised>
            <CardActionArea className={classes.bulkSelect} onClick={() => handleDeselectAll()}>
              <Grid container>
                <Grid item md={9}>
                  <Typography color="textSecondary" variant="h4">
                    De-select All
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  className={classes.iconGridColumn}>
                  <CancelIcon fontSize="large" className={classes.red} />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card raised>
            <CardActionArea className={classes.bulkSelect} onClick={() => handleSelectAll()}>
              <Grid container>
                <Grid item md={9}>
                  <Typography color="textSecondary" variant="h4">
                    Select All
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  className={classes.iconGridColumn}>
                  <CheckCircleIcon fontSize="large" className={classes.green} />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

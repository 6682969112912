import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, DialogContentTextProps } from "@mui/material";
import React, { ReactNode } from "react";
import LoadingButton from "../../components/universal/LoadingButton";
import DialogTitle from "../DialogTitle";

interface ConfirmationDialogProps {
  message: string | ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  title: string;
  cancelLabel?: string;
  confirmLabel?: string;
  variant?: ButtonProps["variant"];
  loading?: boolean;
  messageSx?: DialogContentTextProps["sx"];
}

const ConfirmationDialog = ({
  title,
  open,
  message,
  onCancel,
  onConfirm,
  cancelLabel = "Cancel",
  confirmLabel,
  variant,
  loading,
  messageSx,
}: ConfirmationDialogProps) => (
  <Dialog
    maxWidth="xs"
    fullWidth
    open={open}
    onClose={(_: Object, reason: string) => {
      if (reason !== "backdropClick") {
        onCancel();
      }
    }}
  >
    <DialogTitle title={title} onClose={onCancel} />
    <DialogContent>
      <DialogContentText sx={messageSx}>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onCancel}
        variant="outlined"
        disabled={loading}>
        {cancelLabel}
      </Button>
      <LoadingButton
        variant={variant}
        onClick={onConfirm}
        autoFocus
        buttonText={confirmLabel ? confirmLabel : variant === "containedDestructive" ? "Delete" : "Ok"}
        loading={loading}
      />
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;

import { Typography } from "@mui/material";
import { formatTimeInTimezone, formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import React, { ReactNode } from "react";

interface CalendarEventLabelProps {
  primary: ReactNode;
  // secondary?: ReactNode;
  start: Date;
  durationInMinutes: number;
  isAllDay?: boolean;
  isAbbreviated: boolean;
  clientTimezone: string;
  style?: React.CSSProperties;
}

const CalendarEventLabel = ({
  primary,
  // secondary,
  start,
  durationInMinutes,
  isAllDay,
  isAbbreviated,
  clientTimezone,
  style,
}: CalendarEventLabelProps) => {
  const options = { excludeTimezoneSuffix: true };
  const startText = formatTimeInTimezone(start, clientTimezone, options);
  const startToEndText = formatTimeRangeInTimezone(start, durationInMinutes, clientTimezone, options);

  if (isAllDay || isAbbreviated) {
    return (
      <Typography
        variant="subtitle1"
        sx={{ lineHeight: "1.5em", fontWeight: "bold" }}
        style={style}
        noWrap
        component="div">
        {primary} {isAbbreviated && !isAllDay ? `, ${startText}` : null}
      </Typography>
    );
  }

  return (
    <Typography
      variant="subtitle1"
      sx={{ lineHeight: "1.5em" }}
      style={style}
      noWrap
      component="div">
      <strong>{primary}</strong>
      <>
        <br />
        {startToEndText}
      </>
    </Typography>
  );
};

export default CalendarEventLabel;

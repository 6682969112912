import { Typography } from "@mui/material";
import React from "react";
import { RecipeWithServingsFragment } from "../../../../types";

interface PrintRecipeSummaryLineProps {
  recipe: RecipeWithServingsFragment;
}

function renderLine(prefix: string, text: string) {
  return (
    <div>
      <Typography
        variant="body2"
        display="inline"
        fontWeight={500}>
        {prefix}
      </Typography>
      <Typography variant="body2" display="inline">
        {` ${text}`}
      </Typography>
    </div>
  );
}

export function PrintRecipeSummaryLine({ recipe }: PrintRecipeSummaryLineProps) {
  return (
    <>
      {renderLine("Meal Type:", recipe.mealTypes?.[0] ? recipe.mealTypes?.join(", ") : "")}
      {renderLine("Prep Time:", recipe.prepTimeInMinutes ? `${recipe.prepTimeInMinutes} mins` : "")}
      {renderLine("Cook Time:", recipe.cookTimeInMinutes ? `${recipe.cookTimeInMinutes} mins` : "")}
      {renderLine("Yields:", `${recipe.servings[0].perRecipeYield} ${recipe.servings[0].units}`)}
      {recipe.note && renderLine("Notes:", recipe.note)}
    </>
  );
}

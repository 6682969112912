import { Button, DialogActions, DialogContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useReducer, useState } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import {
  FullExchangeSetFragment as ExchangeSet,
  ExchangeSetsDocument,
  ExchangeSetsQuery,
  useCreateExchangeSetMutation,
  useEditExchangeSetMutation,
} from "../../../types";
import Form from "../Form";
import {
  exchangeSetFormCantSaveTooltips,
  exchangeSetFormReducer,
  exchangeSetFormToCreateInput,
  exchangeSetFormToEditInput,
  exchangeSetToFormState,
  newExchangeSetFormState,
} from "../Form/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "minmax(0, 1fr) auto",
      height: "560px",
    },
    form: {
      gridColumnStart: 1,
      gridRowStart: 1,
      // height: '100%',
    },
  })
);

interface ExchangeSetModalContentProps {
  exchangeSet: ExchangeSet | null;
  open: boolean;
  onClose: () => void;
}

const ExchangeSetModalContent = ({ exchangeSet, open, onClose }: ExchangeSetModalContentProps) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(
    exchangeSetFormReducer,
    exchangeSet ? exchangeSetToFormState(exchangeSet) : newExchangeSetFormState()
  );
  const [saving, setSaving] = useState(false);

  const [createExchangeSet] = useCreateExchangeSetMutation({
    update: (dataProxy, result) => {
      const exchangeSetsQuery = dataProxy.readQuery<ExchangeSetsQuery>({
        query: ExchangeSetsDocument,
      });
      if (result.data && exchangeSetsQuery) {
        dataProxy.writeQuery({
          query: ExchangeSetsDocument,
          data: {
            ...exchangeSetsQuery,
            exchangeSets: exchangeSetsQuery.exchangeSets.concat([result.data.createExchangeSet.exchangeSet]),
          },
        });
      }
      onClose();
    },
  });

  const [editExchangeSet] = useEditExchangeSetMutation({
    update: () => onClose(),
  });

  const cantSaveTooltips = exchangeSetFormCantSaveTooltips(state);

  const handleSave = () => {
    if (cantSaveTooltips.length) {
      return;
    }
    setSaving(true);
    if (state.id) {
      editExchangeSet({
        variables: {
          input: exchangeSetFormToEditInput({
            ...state,
            id: state.id,
          }),
        },
      });
    } else {
      createExchangeSet({
        variables: { input: exchangeSetFormToCreateInput(state) },
      });
    }
  };

  return (
    <>
      <DialogContent className={classes.root}>
        <div className={classes.form}>{saving ? <Loading progressSize="md" /> : <Form state={state} dispatch={dispatch} />}</div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={saving}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={cantSaveTooltips}>
          <Button onClick={handleSave} disabled={saving || cantSaveTooltips.length > 0}>
            Save
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </>
  );
};

export default ExchangeSetModalContent;

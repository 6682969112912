import { Box, SxProps, Tab, Tabs } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { Semibold } from "../../pages/Theme/Common/TypographyCommon";

export interface IconButton {
  label: string;
  icon: ReactElement;
  iconPosition?: "start" | "end";
}

export interface ElementButton {
  label: string;
  element: ReactElement;
}
export type ButtonType = string | IconButton | ElementButton;

const isIconButton = (button: ButtonType): button is IconButton => (button as IconButton).icon !== undefined;
const isElementButton = (button: ButtonType): button is ElementButton => (button as ElementButton).element !== undefined;
const getLabel = (button: ButtonType) => (isIconButton(button) || isElementButton(button) ? button.label : button);

interface TWButtonGroupProps {
  sx?: SxProps;
  buttons: ButtonType[];
  onSelected: (selected: string) => void;
  outsideSelected?: string;
}

export const TWButtonGroup = ({ sx, buttons, onSelected, outsideSelected }: TWButtonGroupProps) => {
  const [selected, setSelected] = useState(0);
  const handleSelected = (selected: number) => {
    setSelected(selected);
    const selectedTab = buttons[selected];
    const selectedString = getLabel(selectedTab);
    onSelected(selectedString);
  };
  const outsideSelectedIndex = buttons.findIndex(button => getLabel(button) === outsideSelected);

  return (
    <Box sx={{ display: "flex", ...sx }}>
      <Tabs
        sx={{
          height: 28,
          minHeight: 28,
          "& .MuiTabs-scrollButtons": {
            "&.Mui-disabled": {
              opacity: 0.8,
            },
          },
          border: 1,
          borderColor: "greyscale.300",
          borderRadius: "6px",
          backgroundColor: "greyscale.100",
        }}
        TabIndicatorProps={{ sx: { height: 0 } }}
        value={outsideSelected ? outsideSelectedIndex : selected}
        onChange={(e, value) => handleSelected(value)}
      >
        {buttons.map((button, index) => {
          const labelString = isIconButton(button) || isElementButton(button) ? button.label : button;
          const label = isIconButton(button) ? button.label : isElementButton(button) ? button.element : button;
          const icon = isIconButton(button) ? button.icon : undefined;
          const iconPosition = isIconButton(button) ? button.iconPosition || "start" : undefined;
          const lastButton = index === buttons.length - 1;

          return (
            <Tab
              sx={{
                height: 26,
                minHeight: 26,
                border: 1,
                borderRightWidth: lastButton ? 0 : 1,
                borderTopWidth: 0,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderColor: "greyscale.300",
                fontSize: 12,
                fontWeight: Semibold,
                lineHeight: "20px",
                px: 1,
                "&.Mui-selected": {
                  borderColor: "greyscale.300",
                  color: "highEmphasisText",
                  backgroundColor: "common.white",
                },
                "&:hover": {
                  color: "text.secondary",
                  backgroundColor: "greyscale.200",
                },
              }}
              icon={icon}
              iconPosition={iconPosition}
              key={labelString}
              label={label}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import { FullServingFragment } from "../types";

interface ServingAmountLimitedAccessWarningProps {
  serving: FullServingFragment;
}

const ServingAmountLimitedAccessWarning = ({ serving }: ServingAmountLimitedAccessWarningProps) => {
  return (
    <Tooltip
      title={
        <>
          {getLimitedAccessTooltipText(serving.foodOrRecipe)}
          {serving.foodOrRecipe.__typename === "Recipe" && (
            <>
              <br />
              <br />
              {`Created by ${serving.foodOrRecipe.createdBy.firstName} ${serving.foodOrRecipe.createdBy.lastName}`}
            </>
          )}
        </>
      }
    >
      <ErrorIcon fontSize="small" color="primary" />
    </Tooltip>
  );
};

const getLimitedAccessTooltipText = (foodOrRecipe: FullServingFragment["foodOrRecipe"]): string => {
  const orgText = foodOrRecipe.org ? ` (${foodOrRecipe.org.name})` : " ";
  if (foodOrRecipe.__typename === "Recipe") {
    return `This recipe is part of another organization${orgText} and will not be added to your organization’s recipes. You and your athletes can only view the recipe name and its nutrients.`;
  } else {
    return `This custom food is part of another organization${orgText} and will not be added to your organization’s foods. You and your athletes can only view the food name and its nutrients.`;
  }
};

export default ServingAmountLimitedAccessWarning;

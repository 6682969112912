import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { DietFragment } from "../../../types";

interface DietDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
  diet?: DietFragment;
}

const DietDialog = ({ open, onClose, onSave, diet }: DietDialogProps) => {
  const [dietName, setDietName] = useState<string>(diet?.name || "");
  const handleSave = () => {
    onSave(dietName);
    onClose();
  };

  const title = diet ? "Edit" : "Create";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title={`${title} Diet`} onClose={onClose} />
      <DialogContent>
        <TextField
          autoFocus
          value={dietName}
          onChange={e => {
            setDietName(e.target.value);
          }}
          placeholder="Diet"
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DietDialog;

import { Box, ChipProps, SxProps } from "@mui/material";
import { ReactNode } from "react";
import EntityChip from "./EntityChip";
import { Entity } from "./types";

export interface ChipListProps<T extends Entity> {
  objects: T[];
  objectToName: (obj: T) => string;
  objectToChipProps?: (obj: T) => ChipProps;
  onDeleteObject?: (obj: T) => void;
  renderChip?: (obj: T) => ReactNode;
  className?: string;
  sx?: SxProps;
}

const ChipList = <T extends Entity>({
  objects,
  className,
  objectToName,
  sx,
  objectToChipProps,
  onDeleteObject,
  renderChip,
}: ChipListProps<T>) => {
  return (
    <Box sx={sx} className={className}>
      {objects.map(obj =>
        renderChip ? (
          renderChip(obj)
        ) : (
          <EntityChip<T>
            key={obj.id}
            label={objectToName(obj)}
            onDelete={
              onDeleteObject
                ? () => {
                    onDeleteObject(obj);
                  }
                : undefined
            }
            chipProps={objectToChipProps ? objectToChipProps(obj) : {}}
          />
        )
      )}
    </Box>
  );
};

export default ChipList;

import { addMinutes, areIntervalsOverlapping, differenceInMinutes, max } from "date-fns";
import { AdjacentMeals, MealEvent, MealType, StaticEvent } from "./types";

const REQUIRED_MINUTES_BETWEEN_MEALS = 30;

// Meal type words: if an event title contains one then it is considered a meal
const MEAL_WORDS = [
  "breakfast",
  "breakfasts",
  "brunch",
  "brunches",
  "lunch",
  "lunches",
  "dinner",
  "dinners",
  "meal",
  "meals",
  "dining",
  "hydrate",
  "hydrating",
  "eat",
  "snack",
  "snacks",
  "fuel",
  "fueling",
];

/**
 * Checks if a given time offset is valid for a meal in relation to the boundary meals and static events.
 */
export const isValidTimeSlot = (
  mealEvent: MealEvent,
  adjacentMeals: AdjacentMeals,
  staticEventsInRange: StaticEvent[],
  timeOffset: number,
  minMealDurationInMinutes: number
) => {
  const mealStart = addMinutes(mealEvent.start, timeOffset);
  const mealEnd = addMinutes(mealEvent.start, timeOffset + mealEvent.durationInMinutes);

  // If the meal event has an invalid overlap with either of the adjacent meal events
  // and those meals and the current meal aren't in the original positions --> invalid slot
  const { prevMeal, isPreviousMealModified, nextMeal, isNextMealModified } = adjacentMeals;
  if (
    prevMeal &&
    !areMealsInOriginalPositions(timeOffset, isPreviousMealModified) &&
    isInvalidOverlapForMealIntervals(
      { start: mealStart, end: mealEnd },
      { start: prevMeal.start, end: addMinutes(prevMeal.start, prevMeal.durationInMinutes) },
      mealEvent.mealType,
      prevMeal.mealType
    )
  ) {
    return false;
  }

  if (
    nextMeal &&
    !areMealsInOriginalPositions(timeOffset, isNextMealModified) &&
    isInvalidOverlapForMealIntervals(
      { start: mealStart, end: mealEnd },
      { start: nextMeal.start, end: addMinutes(nextMeal.start, nextMeal.durationInMinutes) },
      mealEvent.mealType,
      nextMeal.mealType
    )
  ) {
    return false;
  }

  return isValidOverlapForStaticEvents(staticEventsInRange, mealStart, mealEnd, timeOffset, minMealDurationInMinutes);
};

/**
 * An invalid overlap between a meal and another meal is if the meal overlaps with the other meal
 * or there isn't at least 30 minutes between the meals
 */
const isInvalidOverlapForMealIntervals = (
  mealInterval: Interval,
  otherMealInterval: Interval,
  mealType: MealType,
  otherMealType: MealType
): boolean => {
  const isEitherMealASnack = mealType === "snack" || otherMealType === "snack";
  // If either event is a snack, it is invalid only if the events overlap.
  // We check intervals exclusively bc snacks can be placed back to back with other meals/snacks
  if (isEitherMealASnack) {
    return areIntervalsOverlapping(mealInterval, otherMealInterval);
  }

  // If the meals overlap or are back to back then it's invalid
  if (areIntervalsOverlapping(mealInterval, otherMealInterval, { inclusive: true })) {
    return true;
  }

  if (mealInterval.end < otherMealInterval.start) {
    return differenceInMinutes(otherMealInterval.start, mealInterval.end) < REQUIRED_MINUTES_BETWEEN_MEALS;
  } else {
    return differenceInMinutes(mealInterval.start, otherMealInterval.end) < REQUIRED_MINUTES_BETWEEN_MEALS;
  }
};

/**
 * Returns whether the meal is valid in relation to the positioning of the static events.
 */
const isValidOverlapForStaticEvents = (
  staticEvents: StaticEvent[],
  mealStart: Date,
  mealEnd: Date,
  timeOffset: number,
  minMealDurationInMinutes: number
) => {
  const staticIntervals: Interval[] = [];
  const mealInterval = { start: mealStart, end: mealEnd };

  // sort static events by start time
  const sortedStaticEvents = staticEvents.sort((a, b) => a.start.getTime() - b.start.getTime());

  // Find the intervals of the static events in range that overlap with the meal event
  for (const event of sortedStaticEvents) {
    const eventStart = event.start;
    const eventEnd = addMinutes(event.start, event.durationInMinutes);

    // if the static event represents a meal
    // or the meal is in its original location and the static event is an activity (overlap was user configured)
    // --> allow overlap
    if (isStaticEventAMeal(event) || (event.__typename === "activity" && timeOffset === 0)) {
      continue;
    }

    // if the static event overlaps with the meal event add the interval and combine it with previous interval if possible
    const eventInterval = { start: eventStart, end: eventEnd };
    const doesOverlapMeal = areIntervalsOverlapping(eventInterval, mealInterval);
    if (doesOverlapMeal) {
      const prevInterval = staticIntervals.pop();
      if (prevInterval === undefined) {
        // nothing to merge with
        staticIntervals.push(eventInterval);
      } else if (areIntervalsOverlapping(prevInterval, eventInterval)) {
        // merge overlapping intervals
        staticIntervals.push({ start: prevInterval.start, end: max([eventInterval.end, prevInterval.end]) });
      } else {
        // no overlap, push both intervals
        staticIntervals.push(prevInterval);
        staticIntervals.push(eventInterval);
      }
    }
  }

  return hasValidGapForMeal(mealInterval, staticIntervals, minMealDurationInMinutes);
};

/**
 * Returns whether there is a valid gap in between the static events for a meal event.
 *
 * @param mealInterval
 * @param eventIntervals
 * @returns
 */
const hasValidGapForMeal = (mealInterval: Interval, eventIntervals: Interval[], minMealDurationInMinutes: number): boolean => {
  // if there aren't any static intervals that overlap with the meal event --> valid slot
  if (eventIntervals.length === 0) {
    return true;
  }

  // if only one static interval, check if there is the minimum duration available for the meal event
  if (eventIntervals.length === 1) {
    return isValidEventIntervalOverlap(eventIntervals[0], mealInterval, minMealDurationInMinutes);
  }

  // if there are multiple static intervals
  // check if there is the minimum duration available for the meal event in between any intervals
  for (let i = 1; i < eventIntervals.length; i++) {
    const prevInterval = eventIntervals[i - 1];
    const currInterval = eventIntervals[i];

    // check if there is at least the minimum duration between the intervals
    // and that the meal contains the gap
    const minutesBetweenIntervals = differenceInMinutes(currInterval.start, prevInterval.end);
    if (minutesBetweenIntervals >= minMealDurationInMinutes) {
      const gapStart = prevInterval.end;
      const gapEnd = currInterval.start;
      if (gapStart <= mealInterval.start && gapEnd >= mealInterval.end) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Returns whether an event is valid or not in relation to the meal event.
 * This means that there must be at least the minimum meal duration
 * not overlapped with the event.
 */
const isValidEventIntervalOverlap = (eventInterval: Interval, mealInterval: Interval, minMealDurationInMinutes: number) => {
  const { start: eventStart, end: eventEnd } = eventInterval;
  const { start: mealStart, end: mealEnd } = mealInterval;

  // check if at least min meal duration minutes of the first interval is not contained in the second interval
  if (mealStart >= eventStart && eventEnd >= mealEnd) {
    // meal interval fully contained in event interval
    return false;
  } else if (mealStart <= eventStart && mealEnd >= eventEnd) {
    // event interval is fully contained in meal interval
    // check if the meal has the min duration before or after the event
    return (
      differenceInMinutes(eventStart, mealStart) >= minMealDurationInMinutes ||
      differenceInMinutes(mealEnd, eventEnd) >= minMealDurationInMinutes
    );
  } else if (mealStart <= eventStart) {
    // the meal starts before the other interval but ends before it or at same time
    return differenceInMinutes(eventStart, mealStart) >= minMealDurationInMinutes;
  } else {
    // the meal starts after the other interval but ends after it or at same time
    return differenceInMinutes(mealEnd, eventEnd) >= minMealDurationInMinutes;
  }
};

/**
 * Returns whether an event name contains a word that would
 * designate it as a meal.
 *
 * Ex: if an event was named "Lunch Window", we would treat
 *  this as a meal since it contains a "meal word"
 */
const isStaticEventAMeal = (event: StaticEvent) => {
  const wordsInEventName = event.name.toLowerCase().split(/[\s//]+/);
  return MEAL_WORDS.some(word => wordsInEventName.includes(word));
};

const areMealsInOriginalPositions = (timeOffsetForCurrentMeal: number, isOtherMealModified: boolean) => {
  return timeOffsetForCurrentMeal === 0 && !isOtherMealModified;
};

import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import EmailIcon from "@mui/icons-material/Email";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, CircularProgress, IconButton, Popover, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ExchangeMealPlanLegacyDisplaySettings, MacroMealPlanLegacyDisplaySettings } from "@notemeal/shared/ui/DisplaySettings/utils";
import React, { Dispatch, useRef, useState } from "react";
import { ExportPdfAction, ExportPdfState } from "../utils/pdfReducer";
import FormPdf from "./PdfToolbarForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: theme.spacing(2),
      width: 345, // To stop flexbox from expanding/contracting when tooggle switch is flipped to hide others
    },
    formRow: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      width: "100%",
      gap: 2,
    },
    downloadPopoverButton: {
      margin: theme.spacing(),
    },
  })
);

interface ExportToolbarBulkProps {
  state: ExportPdfState;
  dispatch: Dispatch<ExportPdfAction>;
  disabled: boolean;
  onClickEmail: () => void;
  onClickDownload: () => void;
  onClickBulkDownload: () => void;
  displaySettings: MacroMealPlanLegacyDisplaySettings | ExchangeMealPlanLegacyDisplaySettings;
  onChangeDisplaySettings: (displaySettings: MacroMealPlanLegacyDisplaySettings | ExchangeMealPlanLegacyDisplaySettings) => void;
  selectedMealPlanNumber: number;
  mealPlanCount: number;
  incrementMealPlanNumber: () => void;
  decrementMealPlanNumber: () => void;
  emailButtonStatus?: "called" | "success" | "error";
}

const ExportToolbarBulk = ({
  state,
  dispatch,
  onClickEmail,
  onClickDownload,
  onClickBulkDownload,
  emailButtonStatus,
  disabled,
  selectedMealPlanNumber,
  mealPlanCount,
  incrementMealPlanNumber,
  decrementMealPlanNumber,
  displaySettings,
  onChangeDisplaySettings,
}: ExportToolbarBulkProps) => {
  const classes = useStyles();
  const [downloadPopoverAnchorEl, setDownloadPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const downloadButtonRef = useRef<HTMLButtonElement>(null);
  const handleClosePopover = () => setDownloadPopoverAnchorEl(null);

  return (
    <div className={classes.toolbar}>
      <div className={classes.formRow}>
        <IconButton
          onClick={decrementMealPlanNumber}
          disabled={disabled}
          size="large">
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography>
          {selectedMealPlanNumber} / {mealPlanCount}
        </Typography>
        <IconButton
          onClick={incrementMealPlanNumber}
          disabled={disabled}
          size="large">
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          variant="outlined"
          sx={{ backgroundColor: "background.default" }}
          startIcon={<DownloadIcon />}
          ref={downloadButtonRef}
          onClick={() => setDownloadPopoverAnchorEl(downloadButtonRef.current)}
        >
          Download
        </Button>
        <Button
          onClick={onClickEmail}
          startIcon={
            !emailButtonStatus ? (
              <EmailIcon />
            ) : emailButtonStatus === "called" ? (
              <CircularProgress size={20} />
            ) : emailButtonStatus === "success" ? (
              <CheckIcon color="success" />
            ) : (
              <ErrorIcon color="error" />
            )
          }
          disabled={!!emailButtonStatus}
        >
          Email
        </Button>
        <Popover
          open={downloadPopoverAnchorEl !== null}
          anchorEl={downloadPopoverAnchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Button
            onClick={() => {
              handleClosePopover();
              onClickBulkDownload();
            }}
            className={classes.downloadPopoverButton}
            size="small"
          >
            One File
          </Button>
          <Button
            onClick={() => {
              handleClosePopover();
              onClickDownload();
            }}
            className={classes.downloadPopoverButton}
            size="small"
          >
            Individual Files
          </Button>
        </Popover>
      </Box>
      <FormPdf
        type={"exchange"}
        state={state}
        dispatch={dispatch}
        displaySettings={displaySettings}
        onChangeDisplaySettings={onChangeDisplaySettings}
      />
    </div>
  );
};

export default ExportToolbarBulk;

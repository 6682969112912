import { Box, Typography } from "@mui/material";
import React from "react";

interface TextWithLabelProps {
  label: string;
  text: string;
  icon?: React.ReactNode;
}

export const TextWithLabel = ({ label, text, icon }: TextWithLabelProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Typography variant="body2" sx={{ color: theme => theme.palette.text.secondary }}>
          {label}
        </Typography>
        {icon}
      </Box>
      <Typography variant="h4">{text}</Typography>
    </Box>
  );
};

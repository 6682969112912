import { Fab, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortByKey } from "@notemeal/utils/sort";
import { useEffect, useState } from "react";
import { animated } from "react-spring";
import { DeconstructIcon } from "../global/Icons";
import Loading from "../global/Loading";
import { useRecipeWithFullIngredientsQuery } from "../types";
import DeconstructionDialog from "./DeconstructionDialog";
import { formatAmbiguousServingAmountWithTotalWeight } from "./utils";

export const LOADING_INGREDIENTS_HEIGHT = 96;

const useStyles = makeStyles(({ spacing, palette: { primary, secondary } }: Theme) =>
  createStyles({
    root: {
      borderBottomLeftRadius: spacing(),
      borderBottomRightRadius: spacing(),
      cursor: "pointer",
      backgroundColor: "#eaeaea",
    },
    ingredients: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      paddingLeft: spacing(9),
      borderBottomLeftRadius: spacing(),
      borderBottomRightRadius: spacing(),
    },
    ingredient: {
      margin: spacing(0, 1),
      lineHeight: 2,
    },
    yield: {
      margin: spacing(0, 1),
      lineHeight: 2,
      fontWeight: "bold",
    },
    expandIngredients: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: primary.light,
      borderBottomLeftRadius: spacing(),
      borderBottomRightRadius: spacing(),
      fontSize: 16,
      height: spacing(2),
    },
    collapseIngredients: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: secondary.light,
      borderBottomLeftRadius: spacing(),
      borderBottomRightRadius: spacing(),
      fontSize: 16,
      height: spacing(2),
    },
    deconstructButton: {
      position: "absolute",
      bottom: spacing(),
      right: spacing(),
    },
    loading: {
      height: LOADING_INGREDIENTS_HEIGHT,
    },
  })
);

interface ServingAmountsIngredientsListProps {
  recipeId: string;
  perRecipeYield: number;
  units: string;
  onClose: () => void;
  onDeconstruct?: () => void;
  hasOwnNutrients?: boolean;
  onLoad?: (ingredientsCount: number) => void;
  style?: any;
}

const ServingAmountsIngredientsList = ({
  recipeId,
  style,
  onClose,
  onDeconstruct,
  perRecipeYield,
  units,
  onLoad,
  hasOwnNutrients = false,
}: ServingAmountsIngredientsListProps) => {
  const classes = useStyles();

  const { data } = useRecipeWithFullIngredientsQuery({
    variables: {
      id: recipeId,
    },
  });
  const ingredients = data?.recipe.ingredients ?? [];

  useEffect(() => {
    if (ingredients?.length !== undefined && onLoad) {
      onLoad(ingredients.length);
    }
  }, [ingredients?.length]);

  const [deconstructing, setDeconstructing] = useState(false);
  const [showRecipeDeconstructionWarning, setShowRecipeDeconstructionWarning] = useState<boolean>(false);

  const handleDeconstructRecipe = () => {
    if (onDeconstruct) {
      setDeconstructing(true);
      onDeconstruct();
    }
  };

  const ingredientComponents = ingredients ? (
    <div className={classes.ingredients}>
      {sortByKey(ingredients, "position").map(({ id, serving, amount }) => {
        const totalAmount = amount;
        return (
          <Typography key={id} variant="subtitle1" className={classes.ingredient} noWrap>
            {serving.foodOrRecipe.name} (
            {formatAmbiguousServingAmountWithTotalWeight({
              amount: totalAmount,
              serving,
            })}
            )
          </Typography>
        );
      })}
      <Typography variant="subtitle1" className={classes.yield} noWrap>
        Yields {perRecipeYield} {units}
      </Typography>
      {onDeconstruct && (
        <Tooltip title={"Deconstruct recipe"}>
          <Fab
            disabled={deconstructing}
            size="small"
            color={"primary"}
            onClick={e => {
              e.stopPropagation();
              if (!hasOwnNutrients) {
                handleDeconstructRecipe();
              } else {
                setShowRecipeDeconstructionWarning(true);
              }
            }}
            className={classes.deconstructButton}
          >
            {deconstructing ? <Loading progressSize="xs" /> : <DeconstructIcon />}
          </Fab>
        </Tooltip>
      )}
      {showRecipeDeconstructionWarning && (
        <DeconstructionDialog
          open={showRecipeDeconstructionWarning}
          onClose={() => setShowRecipeDeconstructionWarning(false)}
          onConfirm={handleDeconstructRecipe}
        />
      )}
    </div>
  ) : (
    <div className={classes.loading}>
      <Loading progressSize="xs" />
    </div>
  );

  return (
    <animated.div className={classes.root} style={style} onClick={onClose}>
      {ingredientComponents}
    </animated.div>
  );
};

export default ServingAmountsIngredientsList;

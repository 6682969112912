import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import ChipListPreview from "../../../universal/ChipList/Preview";
import { MealPlanTemplateGoalState } from "./goalsReducer";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(2),
    },
    goalChip: {
      backgroundColor: grey[200],
      color: grey[700],
      fontWeight: 600,
    },
  })
);

interface GoalsPreviewProps {
  goals: MealPlanTemplateGoalState[];
}

const GoalsChipList = ({ goals }: GoalsPreviewProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ChipListPreview
        items={goals.map(goal => ({ id: goal.id, label: goal.name }))}
        chipClassName={classes.goalChip}
        hideItemsAfter={3} />
    </div>
  );
};

export default GoalsChipList;

import CloseIcon from "@mui/icons-material/Close";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useProgressViews } from "@notemeal/shared/ui/Macros/hooks";
import ServingAmountViewChipList from "@notemeal/shared/ui/ServingAmount/View/ChipList";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import classnames from "classnames";
import React, { createElement, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { FullMealOptionFragment, FullServingAmountFragment, MealType, useMealOptionsServingsQuery } from "../../../types";
import { MealOptionMacrosProgress } from "../../MealOption/Edit/MealOptionMacrosProgress";
import { SearchMode } from "../../MealOption/Edit/SearchMode";
import { SuggestionMode } from "../../MealOption/Edit/SuggestionMode";
import { MealOptionMode, MealOptionModeComponentProps } from "../../MealOption/Edit/mode";
import { shouldShowMealOptionNoteBadge } from "../../MealOption/Edit/utils";
import { MealOptionInputItemWithId } from "./scheduleReducer";

export const MEAL_OPTION_SLIDE_WIDTH = "350px";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    root: {
      margin: spacing(2, 1),
      flexGrow: 0,
      flexShrink: 0,
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr",
      background: grey[100],
      "&:hover": {
        cursor: "pointer",
        background: grey[200],
      },
    },
    selectedRoot: {
      margin: spacing(2, 1),
      flexGrow: 0,
      flexShrink: 0,
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr",
      background: "white",
      "&:hover": {
        cursor: "default",
        background: "white",
      },
    },
    cardDiv: {
      gridRowStart: 1,
      gridColumnStart: 1,
    },
    contentRoot: {
      display: "grid",
      rowGap: spacing(1),
      gridTemplateColumns: "260px 40px",
      gridTemplateRows: "100px 393px",
      paddingBottom: 0,
    },
    loading: {
      gridColumnStart: 1,
      gridRowStart: 1,
      gridRowEnd: 3,
      gridColumnEnd: 3,
      // height: 637, // If you update this you need to update AddNew.tsx and ServingAmounts/ChipList.tsx#height
    },
    cardActionRoot: {
      padding: "0px",
      display: "block",
      gridColumnStart: 2,
      gridRowStart: 1,
    },
    totals: {
      gridColumn: 1,
      gridRowStart: 1,
      height: "fit-content",
    },
    chipListFront: {
      gridColumn: "1 / 3",
      marginTop: 0,
    },
    chipListFrontReadOnly: {
      gridColumn: "1 / 3",
      overflowY: "auto",
    },
    mealOptionFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 48,
    },
    mealOptionFooterSingleOption: {
      justifyContent: "center",
    },
    bottomNavActionButton: {
      "&:hover": {
        backgroundColor: grey[200],
      },
      transition: "0.3s",
    },
    mealOptionName: {
      gridColumn: "1 / 3",
      gridRow: 2,
    },
    mealOptionNameReadOnly: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  })
);

const styles = {
  closeButton: {
    width: 40,
    height: 40,
    p: 0,
    m: 0,
    minWidth: "auto",
    backgroundColor: "grey.300",
    color: "common.black",
    "&:hover": {
      backgroundColor: "grey.400",
    },
  },
  chipListBack: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: 3,
    display: "flex",
    flexDirection: "column",
    gap: (theme: Theme) => theme.spacing(2),
  },
};

const AnimatedCard = animated(Card);

interface EditMealOptionProps {
  mealOption: MealOptionInputItemWithId;
  viewOnly?: boolean;
  isShared: boolean;
  mealType: MealType;
  targetMacros: Macros;
  selected: boolean;
  onDelete: () => void;
  onSelect: () => void;
  onEditServingAmounts: (servingAmounts: readonly FullServingAmountFragment[]) => void;
  onEditNote: (note: string) => void;
  onChangeMealOptionName: (name: string) => void;
  index: number;
}

export const EditMealOption = ({
  index,
  mealOption,
  viewOnly,
  isShared,
  mealType,
  targetMacros,
  selected,
  onDelete,
  onSelect,
  onEditServingAmounts,
  onEditNote,
  onChangeMealOptionName,
}: EditMealOptionProps) => {
  const classes = useStyles();
  const [currentMode, setCurrentMode] = useState<MealOptionMode>(SearchMode);
  const [flipped, setFlipped] = useState(false);
  const { opacity } = useSpring({ opacity: Number(flipped) });
  const inverseOpacity = opacity.to(o => 1 - o);
  const transform = opacity.to(o => `rotateY(${180 * o}deg)`);

  const modes = [SearchMode, SuggestionMode];
  const { data } = useMealOptionsServingsQuery({
    variables: {
      ids: mealOption.servingAmounts.map(({ servingId }) => servingId),
    },
  });
  const servingsMap = new Map(data?.servings.map(serving => [serving.id, serving])) || new Map();
  const fullMealOption: FullMealOptionFragment = {
    ...mealOption,
    __typename: "MealOption",
    servingAmounts: mealOption.servingAmounts.flatMap(servingAmount => {
      const serving = servingsMap.get(servingAmount.servingId);
      return serving
        ? {
            ...servingAmount,
            __typename: "ServingAmount",
            id: servingAmount.servingId,
            serving,
          }
        : [];
    }),
  };

  useEffect(() => {
    setCurrentMode(SearchMode);
  }, [selected]);

  useEffect(() => {
    if (!selected && flipped) {
      setFlipped(false);
    }
  }, [selected, flipped, setFlipped]);

  const { mode } = useProgressViews({
    macroProgressViews: "all",
    hasTargets: true,
  });
  const renderTotals = (servingAmounts: readonly FullServingAmountFragment[]) => (
    <MealOptionMacrosProgress
      servingAmounts={servingAmounts}
      targetMacros={targetMacros}
      mode={mode} />
  );

  const mealOptionFront = (
    <CardContent classes={{ root: classes.contentRoot }} onClick={onSelect}>
      <CardActions classes={{ root: classes.cardActionRoot }}>
        <Button
          sx={styles.closeButton}
          size="small"
          onClick={onDelete}>
          <CloseIcon />
        </Button>
      </CardActions>
      {createElement<MealOptionModeComponentProps>(currentMode.component, {
        renderTotals,
        totalsClassName: classes.totals,
        contentClassName: classes.chipListFront,
        mealTemplateTypename: isShared ? "StaffMealPlanTemplateMealTemplate" : "OrgMealPlanTemplateMealTemplate",
        mealType,
        mealOption: { ...fullMealOption, isAutosaving: false },
        selected,
        targetMacros,
        recipeDialogOpen: false,
        onCloseRecipeDialog: () => {},
        onCreateRecipe: () => {},
        onEditServingAmounts,
        toSearchMode: () => setCurrentMode(SearchMode),
        onChangeMealOptionName: () => {},
      })}
    </CardContent>
  );

  const mealOptionFrontReadOnly = (
    <CardContent classes={{ root: classes.contentRoot }} onClick={onSelect}>
      <div className={classes.totals}>{renderTotals(fullMealOption.servingAmounts)}</div>
      <div className={classes.chipListFrontReadOnly}>
        {mealOption.name && (
          <Typography variant="h3" className={classes.mealOptionNameReadOnly}>
            {mealOption.name}
          </Typography>
        )}
        <div>
          <ServingAmountViewChipList servingAmounts={fullMealOption.servingAmounts} />
        </div>
      </div>
    </CardContent>
  );

  const mealOptionBack = (
    <CardContent classes={{ root: classes.contentRoot }} onClick={onSelect}>
      <Box sx={styles.chipListBack}>
        <TextField
          disabled={viewOnly}
          className={classes.mealOptionName}
          variant="outlined"
          label="Name"
          onChange={e => onChangeMealOptionName(e.currentTarget.value)}
          value={mealOption.name}
          placeholder={`Option #${index + 1}`}
        />
        <TextField
          disabled={viewOnly}
          value={mealOption.note}
          fullWidth
          multiline
          rows={5}
          label="Meal Option Note"
          placeholder="Let the athlete know any additional info about this meal option"
          onChange={e => onEditNote(e.target.value)}
        />
      </Box>
    </CardContent>
  );

  const getMealOptionFooter = (isFoodsSide: boolean) =>
    selected ? (
      <BottomNavigation
        value={null}
        showLabels
        className={classnames(classes.mealOptionFooter, { [classes.mealOptionFooterSingleOption]: modes.length === 1 })}
      >
        <BottomNavigationAction
          classes={{ root: classes.bottomNavActionButton }}
          label={isFoodsSide ? "Name & Notes" : "To Foods"}
          icon={
            <Badge variant="dot" invisible={!shouldShowMealOptionNoteBadge(mealOption.note || "", flipped)}>
              <FlipToFrontIcon />
            </Badge>
          }
          disabled={isFoodsSide === flipped}
          onClick={() => setFlipped(!flipped)}
        />
        {modes
          .filter(mode => mode.name !== currentMode?.name)
          .map(mode => (
            <BottomNavigationAction
              key={mode.name}
              classes={{ root: classes.bottomNavActionButton }}
              label={mode.name}
              icon={mode.icon}
              onClick={() => setCurrentMode(mode)}
            />
          ))}
      </BottomNavigation>
    ) : (
      <div className={classes.mealOptionFooter} />
    );
  console.log("modes.length", modes.length);

  return (
    <AnimatedCard
      className={selected ? classes.selectedRoot : classes.root}
      raised={selected}
      style={{ transform }}>
      <animated.div style={{ opacity: inverseOpacity, zIndex: inverseOpacity }} className={classes.cardDiv}>
        <>
          {viewOnly ? mealOptionFrontReadOnly : mealOptionFront}
          {getMealOptionFooter(true)}
        </>
      </animated.div>
      <animated.div style={{ opacity, zIndex: opacity, transform }} className={classes.cardDiv}>
        <>
          {mealOptionBack}
          {getMealOptionFooter(false)}
        </>
      </animated.div>
    </AnimatedCard>
  );
};

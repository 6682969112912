import { FabProps, Theme, alpha } from "@mui/material";

export const muiFab = {
  styleOverrides: {
    root: ({
      theme: {
        palette: { mode, text, neutral, greyscale, highEmphasisTextDark, highEmphasisTextLight, lowEmphasisText },
      },
    }: {
      ownerState: FabProps;
      theme: Theme;
    }) => {
      return {
        backgroundColor: mode === "light" ? neutral.black : greyscale[700],
        color: mode === "light" ? neutral.white : text.primary,
        "&:hover": {
          backgroundColor: mode === "light" ? alpha(neutral.black, 0.9) : greyscale[700],
        },
        "&:disabled": {
          color: mode === "light" ? highEmphasisTextDark : highEmphasisTextLight,
          backgroundColor: lowEmphasisText,
        },
      };
    },
  },
};

import { Box, FormControlLabel, FormGroup, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import { ExchangeMealPlanLegacyDisplaySettings, MacroMealPlanLegacyDisplaySettings } from "@notemeal/shared/ui/DisplaySettings/utils";
import React, { Dispatch } from "react";
import { MealPlanType } from "../../../../types";
import { ExportPdfAction, ExportPdfState } from "../utils/pdfReducer";
import MealPlanDisplaySettingsExchangeForm from "./PdfExchangeForm";
import MealPlanDisplaySettingsMacroForm from "./PdfMacroForm";
import { FormRow } from "./utils";

interface ExportToolbarFormPdfProps {
  state: ExportPdfState;
  dispatch: Dispatch<ExportPdfAction>;
  type: MealPlanType;
  displaySettings: ExchangeMealPlanLegacyDisplaySettings | MacroMealPlanLegacyDisplaySettings;
  onChangeDisplaySettings: (displaySettings: ExchangeMealPlanLegacyDisplaySettings | MacroMealPlanLegacyDisplaySettings) => void;
  hasDates?: boolean;
}

const ExportToolbarFormPdf = ({ state, dispatch, type, displaySettings, onChangeDisplaySettings }: ExportToolbarFormPdfProps) => {
  return (
    <FormGroup sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", p: 1, minWidth: 400 }} row>
      <Typography variant="h3" sx={{ pt: 2 }}>
        Meal Plan Display Options
      </Typography>
      <FormRow sx={{ pt: 3 }}>
        <Box>
          <Typography variant="body1Medium">Orientation</Typography>
          <RadioGroup sx={{ display: "flex", flexDirection: "row" }} defaultValue={"portrait"}>
            <FormControlLabel
              value="portrait"
              label="Portrait"
              control={<Radio size="small" />}
              onChange={() => dispatch({ type: "SET_ORIENTATION", payload: "portrait" })}
            />
            <FormControlLabel
              value="landscape"
              label="Landscape"
              control={<Radio size="small" />}
              onChange={() => dispatch({ type: "SET_ORIENTATION", payload: "landscape" })}
            />
          </RadioGroup>
        </Box>
      </FormRow>
      <FormRow sx={{ pt: 3 }}>
        <Box>
          <Typography variant="body1Medium">View Setting</Typography>
          <RadioGroup sx={{ display: "flex", flexDirection: "row" }} defaultValue={"expanded"}>
            <FormControlLabel
              value="expanded"
              label="Expanded"
              control={<Radio size="small" />}
              onChange={() => dispatch({ type: "SET_CONDENSED", payload: false })}
            />
            <FormControlLabel
              value="condensed"
              label="Condensed"
              control={<Radio size="small" />}
              onChange={() => dispatch({ type: "SET_CONDENSED", payload: true })}
            />
          </RadioGroup>
        </Box>
      </FormRow>
      <FormRow sx={{ pt: 3 }}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <Typography variant="body1Medium">Meal Card View</Typography>
          <Box sx={{ pl: 1, pt: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  sx={{ mr: 1 }}
                  checked={state.displayTitle}
                  onChange={() =>
                    dispatch({
                      type: "SET_DISPLAY_TITLE",
                      payload: !state.displayTitle,
                    })
                  }
                  value="displayTitle"
                />
              }
              label={"Title"}
              labelPlacement="end"
            />
            <FormControlLabel
              sx={{ pt: 1 }}
              control={
                <Switch
                  size="small"
                  sx={{ mr: 1 }}
                  checked={state.expandRecipes}
                  onChange={() =>
                    dispatch({
                      type: "SET_EXPAND_RECIPES",
                      payload: !state.expandRecipes,
                    })
                  }
                  value="expandRecipes"
                />
              }
              label={"Expand Recipes"}
            />
          </Box>
        </Box>
      </FormRow>
      {type === "macro" && displaySettings.__typename === "MacroMealPlanLegacyDisplaySettings" && (
        <MealPlanDisplaySettingsMacroForm
          sx={{ pt: 3 }}
          displaySettings={displaySettings}
          onChange={onChangeDisplaySettings} />
      )}
      {type === "exchange" && displaySettings.__typename === "ExchangeMealPlanLegacyDisplaySettings" && (
        <>
          <MealPlanDisplaySettingsExchangeForm
            sx={{ pt: 3 }}
            displaySettings={displaySettings}
            onChange={onChangeDisplaySettings} />
          <Box sx={{ display: "flex", flexDirection: "column", ml: 0.5 }}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 0.5, mr: 1 }}
                  size="small"
                  checked={state.expandExchanges}
                  onChange={() =>
                    dispatch({
                      type: "SET_EXPAND_EXCHANGES",
                      payload: !state.expandExchanges,
                    })
                  }
                  value="expandExchanges"
                />
              }
              sx={{ pt: 1 }}
              label={"Expand Exchange Lists"}
              labelPlacement="end"
            />
          </Box>
        </>
      )}
      {!state.condensed && (
        <>
          <Typography variant="body1Medium" sx={{ pt: 3 }}>
            Appendix Options
          </Typography>
          <Box sx={{ pt: 1, pl: 1, display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  sx={{ mr: 1 }}
                  checked={state.displayRecipes}
                  onChange={() =>
                    dispatch({
                      type: "SET_DISPLAY_RECIPES",
                      payload: !state.displayRecipes,
                    })
                  }
                  value="displayRecipes"
                />
              }
              sx={{ pt: 1 }}
              label={"Recipes"}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  sx={{ mr: 1 }}
                  checked={state.displayFoodGroups}
                  onChange={() =>
                    dispatch({
                      type: "SET_DISPLAY_FOOD_GROUPS",
                      payload: !state.displayFoodGroups,
                    })
                  }
                  value="displayFoodGroups"
                />
              }
              sx={{ pt: 1 }}
              label={"Food Groups"}
              labelPlacement="end"
            />
          </Box>
        </>
      )}
    </FormGroup>
  );
};

export default ExportToolbarFormPdf;

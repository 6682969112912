import { Box, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import LabeledSelect from "apps/web/src/components/universal/LabeledSelect";
import { useGoalCategoriesQuery } from "apps/web/src/types";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { GoalAssignmentCalendarDateAssignment } from "./Form/GoalConfigurationFormFrequency";
import { GoalConfigurationRemindersForm } from "./Form/GoalConfigurationReminders";
import { GoalConfigurationFormType } from "./GoalConfigurationSchema";

interface GoalConfigurationFormProps {
  form: UseFormReturn<GoalConfigurationFormType>;
  mode: "create" | "edit" | "duplicate";
}

export const GoalConfigurationForm = ({ form, mode }: GoalConfigurationFormProps) => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = form;

  const goalWithTarget = watch("completionCriteria.markAsComplete") === false;
  const { data } = useGoalCategoriesQuery();
  const categories = data?.goalCategories ? [...data?.goalCategories] : [];

  const taskHelperText = (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="caption" sx={{ color: theme => theme.palette.error.main }}>
        {errors.task && errors.task?.type !== "max" ? errors.task.message : " "}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          color: theme => (watch("task").length > 40 ? theme.palette.error.main : theme.palette.text.secondary),
        }}
      >
        {watch("task").length} / 40
      </Typography>
    </Box>
  );

  return (
    <>
      <Box>
        <SectionHeader header="Goal Details" subcopy="Enter the goal details below. Task will be visible to the athlete." />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Controller
          name={"task"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              placeholder="e.g., Drink 3 liters of water"
              error={Boolean(errors.task)}
              helperText={taskHelperText}
              label="Task"
              fullWidth
            />
          )}
        />
        {mode === "create" ? (
          <Controller
            name={"type"}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <LabeledSelect
                {...field}
                optionToName={option => {
                  return `${option.defaultEmoji} ${option.name}`;
                }}
                sx={{ maxWidth: "240px" }}
                placeholder={"Type"}
                selectedOption={field.value ?? null}
                options={categories}
                error={Boolean(errors.type)}
                helperText={errors.type?.message}
              />
            )}
          />
        ) : (
          <TextField
            disabled={true}
            sx={{ minWidth: "240px" }}
            value={`${getValues("type.defaultEmoji")} ${getValues("type.name")}`} />
        )}
      </Box>
      <Divider />
      <SectionHeader header="Completion Criteria" subcopy="Select the type of action that the athlete can take on the mobile app" />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Controller
          name={"completionCriteria.markAsComplete"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {errors.completionCriteria?.markAsComplete && (
                <Typography variant="body2" sx={{ pb: 2, color: theme => theme.palette.error.main }}>
                  {errors.completionCriteria?.markAsComplete?.message}
                </Typography>
              )}
              <RadioGroup value={field.value}>
                <FormControlLabel
                  label={
                    <>
                      <Typography>Mark as complete</Typography>
                      <Typography
                        variant="body1"
                        sx={{ pb: 2, fontSize: "14px", lineHeight: "18px", color: theme => theme.palette.text.secondary }}
                      >
                        Check yes or no (e.g., eat a snack before lift)
                      </Typography>
                    </>
                  }
                  control={
                    <Radio
                      {...field}
                      sx={{ pb: 3 }}
                      value={true}
                      onChange={() => {
                        field.onChange(true);
                        setValue("completionCriteria.target", undefined);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <>
                      <Typography>Update progress</Typography>
                      <Typography
                        variant="body1"
                        sx={{ pb: 2, fontSize: "14px", lineHeight: "18px", color: theme => theme.palette.text.secondary }}
                      >
                        Specify the quantity (e.g., drink 3 liters of water)
                      </Typography>
                    </>
                  }
                  labelPlacement="end"
                  control={<Radio
                    sx={{ pb: 3 }}
                    value={false}
                    checked={field.value === false}
                    onChange={() => field.onChange(false)} />}
                />
              </RadioGroup>
            </Box>
          )}
        />
      </Box>
      {goalWithTarget && (
        <Box sx={{ display: "flex", gap: 2, pl: 4 }}>
          <Controller
            name={"completionCriteria.target.value"}
            control={control}
            render={({ field: { ref, onChange, ...field }, fieldState }) => (
              <TextField
                {...field}
                onChange={v => onChange(parseInt(v.target.value))}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Quantity"
                placeholder="#"
                type="number"
              />
            )}
          />
          <Controller
            name={"completionCriteria.target.unit"}
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Unit"
                placeholder="e.g. liters"
              />
            )}
          />
        </Box>
      )}
      <Divider />
      <Box>
        <SectionHeader header="Frequency" subcopy="Select the frequency of the goal" />
      </Box>
      <Box sx={{ display: "flex", gap: 2, pb: 2 }}>
        <GoalAssignmentCalendarDateAssignment form={form} mode={mode} />
      </Box>
      <Divider />
      <GoalConfigurationRemindersForm form={form} />
    </>
  );
};

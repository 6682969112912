import { Box } from "@mui/material";
import { MacrosProgressMode } from "@notemeal/shared/ui/Macros/hooks";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { MacrosBar } from "./Bars/MacrosBar";
import { MacrosDials } from "./Dials/MacrosDials";
import {
  MACROS_PROGRESS_LARGE_HEIGHT,
  MACROS_PROGRESS_LARGE_WIDTH,
  MACROS_PROGRESS_MEDIUM_HEIGHT,
  MACROS_PROGRESS_MEDIUM_WIDTH,
  MACROS_PROGRESS_SMALL_HEIGHT,
  MACROS_PROGRESS_SMALL_WIDTH,
} from "./MacrosProgressUtils";

interface MacrosProgressBarAndDialsProps {
  mode: "ExchangeTargets" | MacrosProgressMode;
  onChangeMode: () => void;
  currentMacros: Macros;
  targetMacros: Macros | null;
  variant?: "lg" | "md" | "sm";
}

export const MacrosProgressBarAndDials = ({
  mode,
  onChangeMode,
  currentMacros,
  targetMacros,
  variant = "lg",
}: MacrosProgressBarAndDialsProps) => {
  const width =
    variant === "lg" ? MACROS_PROGRESS_LARGE_WIDTH : variant === "md" ? MACROS_PROGRESS_MEDIUM_WIDTH : MACROS_PROGRESS_SMALL_WIDTH;
  const height =
    variant === "lg" ? MACROS_PROGRESS_LARGE_HEIGHT : variant === "md" ? MACROS_PROGRESS_MEDIUM_HEIGHT : MACROS_PROGRESS_SMALL_HEIGHT;

  return (
    <>
      {mode !== "ExchangeTargets" && (
        <Box
          sx={{ width, height, "&:hover": { cursor: "pointer" }, display: "flex", flexDirection: "column", gap: 1 }}
          onClick={onChangeMode}
        >
          <MacrosBar
            mode={mode}
            macros={currentMacros}
            targetMacros={targetMacros || undefined} />
          <MacrosDials
            mode={mode}
            variant={variant}
            macros={currentMacros}
            targetMacros={targetMacros || undefined} />
        </Box>
      )}
    </>
  );
};

import moment from "moment-timezone";
import {
  setHours,
  setMinutes,
  format,
  parseISO,
  addMinutes,
  roundToNearestMinutes,
  addDays,
  parse,
  endOfMonth,
  startOfMonth,
  setSeconds,
  setMilliseconds,
  differenceInMinutes,
  eachDayOfInterval,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  startOfDay,
  endOfDay,
} from "date-fns";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";
import { enUS } from "date-fns/locale";
import { DayOfWeek } from "@notemeal/graphql/types";

export interface Event {
  start: string;
  end: string;
}
// TODO: Locale add locale support Lower Priority
export const formatDateRange = (startDate: Date, endDate: Date) => {
  const startMonth = format(startDate, "MMMM");
  const startDay = format(startDate, "d");
  const endMonth = format(endDate, "MMMM");
  const endDay = format(endDate, "d");
  if (startMonth === endMonth) {
    return startDay === endDay ? `${startMonth} ${startDay}` : `${startMonth} ${startDay}-${endDay}`;
  } else {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }
};

export const setHoursMinutes = (date: Date, hours: number, minutes: number): Date => {
  const _date = setMilliseconds(setSeconds(date, 0), 0);
  const dateHours = setHours(_date, hours);
  return setMinutes(dateHours, minutes);
};

export const canSaveDate = (date: Date | null): boolean => {
  return !!date && date.toString() !== "Invalid Date";
};

export const serializeDate = (date: Date): string => {
  return format(date, "yyyy-MM-dd");
};

export const safeSerializeDate = (date: Date | null): string | null => {
  return date && canSaveDate(date) ? serializeDate(date) : null;
};

export const parseDateWithoutTz = (dateString: string): Date => {
  const newDate = new Date(dateString);
  const tzDifference = newDate.getTimezoneOffset();
  return new Date(newDate.getTime() + tzDifference * 60 * 1000);
};

export const parseDate = (dateString: string): Date => {
  return parseISO(dateString);
};

export const serializeDateTime = (datetime: Date): string => {
  return datetime.toISOString();
};

export const parseDateTime = (dateString: string): Date => {
  return new Date(dateString);
};

export const serializeTime = (datetime: Date): string => {
  return format(datetime, "HH:mm:00");
};

export const safeSerializeTime = (datetime: Date | null): string | null => {
  return datetime && canSaveDate(datetime) ? serializeTime(datetime) : null;
};

export const parseTime = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(":");
  return setHoursMinutes(new Date(), +hours, +minutes);
};

export const parseStartEndProps = <T extends { start: any; end: any }>(toParse: T): T => ({
  ...toParse,
  start: toParse.start ? parseDateTime(toParse.start) : null,
  end: toParse.end ? parseDateTime(toParse.end) : null,
});

export const serializeStartEndProps = <T extends { start: any; end: any }>(toSerialize: T): T => ({
  ...toSerialize,
  start: toSerialize.start ? serializeDateTime(toSerialize.start) : null,
  end: toSerialize.end ? serializeDateTime(toSerialize.end) : null,
});

interface hasDateValue {
  [key: string]: any;
}

export function getMostRecent<T extends hasDateValue>(objectsWithDate: T[], by: keyof T): T | null {
  return (
    objectsWithDate.sort((a, b) => {
      if (a[by] > b[by]) {
        return -1;
      } else if (a[by] < b[by]) {
        return 1;
      } else {
        return 0;
      }
    })[0] || null
  );
}

export interface FormatEventDateTimeOptions {
  formatLong?: boolean;
  timeZone?: string;
}

export const formatEventDatetime = (dt: Date | string, locale?: Locale, options: FormatEventDateTimeOptions = {}): string => {
  const datetime = typeof dt === "string" ? parseISO(dt) : dt;
  const dateFnsLocale = locale || enUS;
  const { formatLong = false, timeZone } = options;

  const datePart = timeZone
    ? formatDateInTimezone(datetime, timeZone, dateFnsLocale, { variant: formatLong ? "long" : "short" })
    : format(datetime, formatLong ? "PP" : "P", { locale: dateFnsLocale });
  const timePart = timeZone ? formatTz(datetime, "p", { timeZone }) : format(datetime, "p");

  return `${datePart} at ${timePart}`;
};

export const getDefaultEndTime = (startTime: string): string => {
  return serializeTime(addMinutes(parseTime(startTime), 30));
};

export const HOURS = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
export const AWAKE_HOURS = [
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const TIME_SLOTS = HOURS.flatMap(h => [`${h}:00:00`, `${h}:30:00`]);

export const getEventTimeSlots = ({ start, end }: Event): string[] => {
  if (end) {
    const startTimeSlotIndex = TIME_SLOTS.findIndex(t => start < t);
    const endTimeSlotIndex = TIME_SLOTS.findIndex(t => end < t);
    if (startTimeSlotIndex && endTimeSlotIndex) {
      if (startTimeSlotIndex === endTimeSlotIndex) {
        return [TIME_SLOTS[startTimeSlotIndex]];
      }
      return TIME_SLOTS.slice(startTimeSlotIndex, endTimeSlotIndex);
    }
  } else {
    const startTimeSlot = TIME_SLOTS.find(t => start > t);
    if (startTimeSlot) {
      return [startTimeSlot];
    }
  }
  return [];
};

export const getAwakeEventTimeSlots = ({ start, end }: Event): string[] => {
  if (end) {
    const startTimeSlotIndex = TIME_SLOTS.findIndex(t => start < t);
    const endTimeSlotIndex = TIME_SLOTS.findIndex(t => end < t);
    if (startTimeSlotIndex && endTimeSlotIndex) {
      if (startTimeSlotIndex === endTimeSlotIndex) {
        return [TIME_SLOTS[startTimeSlotIndex]];
      }
      return TIME_SLOTS.slice(startTimeSlotIndex, endTimeSlotIndex);
    }
  } else {
    const startTimeSlot = TIME_SLOTS.find(t => start > t);
    if (startTimeSlot) {
      return [startTimeSlot];
    }
  }
  return [];
};

export const roundTime = (time: string, nearestMinutes: number) => {
  const date = parseTime(time);
  return serializeTime(
    roundToNearestMinutes(date, {
      nearestTo: nearestMinutes,
    })
  );
};

interface FormatStringOptions {
  excludeTimezoneSuffix?: boolean;
  excludeAmPm?: boolean;
  alwaysShowMinutes?: boolean;
}

const AM_PM_TOKEN = " aaaaa'm'";

const getTimeFormatString = (date: Date, options?: FormatStringOptions) => {
  const excludeAmPm = Boolean(options && options.excludeAmPm);
  const excludeTimezoneSuffix = Boolean(options && options.excludeTimezoneSuffix);
  const alwaysShowMinutes = Boolean(options && options.alwaysShowMinutes);

  let formatString = "h";
  if (alwaysShowMinutes || date.getMinutes() !== 0) {
    formatString += ":mm";
  }
  if (!excludeAmPm) {
    formatString += AM_PM_TOKEN;
  }
  if (!excludeTimezoneSuffix) {
    formatString += " (z)";
  }
  return formatString;
};

interface FormatTimeOptions {
  alwaysShowMinutes?: boolean;
}

export const formatTime = (time: Date | string, options?: FormatTimeOptions): string => {
  const datetime = typeof time === "string" ? parse(time, "HH:mm:ss", new Date()) : time;
  const formatString = getTimeFormatString(datetime, {
    excludeTimezoneSuffix: true,
    ...options,
  });
  return format(datetime, formatString);
};

interface TimeInTimezoneOptions extends FormatTimeOptions {
  excludeTimezoneSuffix?: boolean;
}

export const formatTimeInTimezone = (datetime: Date | string, timeZone: string, options?: TimeInTimezoneOptions) => {
  const dateInTz = utcToZonedTime(datetime, timeZone);
  const formatString = getTimeFormatString(dateInTz, options);
  return formatTz(dateInTz, formatString, { timeZone });
};

export const formatTimeRangeInTimezone = (
  datetime: Date | string,
  durationInMinutes: number,
  timeZone: string,
  options?: TimeInTimezoneOptions
) => {
  const startInTz = utcToZonedTime(datetime, timeZone);
  const endInTz = addMinutes(startInTz, durationInMinutes);

  const sameAmPm = formatTz(startInTz, AM_PM_TOKEN, { timeZone }) === formatTz(endInTz, AM_PM_TOKEN, { timeZone });
  const startFormatString = getTimeFormatString(startInTz, {
    ...options,
    excludeTimezoneSuffix: true,
    excludeAmPm: sameAmPm,
  });
  const endFormatString = getTimeFormatString(endInTz, options);
  return `${formatTz(startInTz, startFormatString, {
    timeZone,
  })} – ${formatTz(endInTz, endFormatString, { timeZone })}`;
};

export interface DateInTimezoneOptions {
  variant?: "long" | "short";
  format?: string;
  useAbbreviations?: boolean;
}

const getShortDateFormatStingForLocale = (locale: Locale) => {
  switch (locale.code) {
    case "en-US":
      return "M/d/y";
    case "en-GB":
      return "d/M/y";
    default:
      return "M/d/y";
  }
};

export const getLongDateFormatStingForLocale = (locale: Locale, isCurrentYear: boolean) => {
  switch (locale.code) {
    case "en-US":
      return isCurrentYear ? "EEEE, MMMM d" : "EEEE, MMMM d, y";
    case "en-GB":
      return isCurrentYear ? "EEEE, d MMMM" : "EEEE, d MMMM, y";
    default:
      return isCurrentYear ? "EEEE, MMMM d" : "EEEE, MMMM d, y";
  }
};

export const getTimeFormatStringForLocale = (locale: Locale) => {
  switch (locale.code) {
    case "en-US":
      return "h:mm aaaaa'm'";
    case "en-GB":
      return "HH:mm";
    default:
      return "h:mm aaaaa'm'";
  }
};

/**
 * if @param variant and @param format are both passed, variant will be ignored
 * if neither are passed, the default value is "M/d/y"
 */
export const formatDateInTimezone = (datetime: Date | string, timeZone: string, locale: Locale, options?: DateInTimezoneOptions) => {
  const dateInTz = utcToZonedTime(datetime, timeZone);
  const useAbbreviations = Boolean(options && options.useAbbreviations);
  const isCurrentYear = new Date().getFullYear() === dateInTz.getFullYear();

  let formatString;
  if (options) {
    if (options.format) {
      formatString = options.format;
    } else {
      if (options.variant === "short") {
        formatString = getShortDateFormatStingForLocale(locale);
      } else {
        formatString = getLongDateFormatStingForLocale(locale, isCurrentYear);
      }
    }
  } else {
    formatString = getShortDateFormatStingForLocale(locale);
  }

  if (!useAbbreviations) {
    return formatTz(dateInTz, formatString, { timeZone });
  }

  const todayDate = new Date();
  const today = serializeDate(todayDate);
  const yesterday = serializeDate(addDays(todayDate, -1));
  const tomorrow = serializeDate(addDays(todayDate, 1));
  const toAbbrevDate = serializeDate(dateInTz);

  return toAbbrevDate === today
    ? "Today"
    : toAbbrevDate === yesterday
    ? "Yesterday"
    : toAbbrevDate === tomorrow
    ? "Tomorrow"
    : formatTz(dateInTz, formatString, { timeZone });
};

const getShortDateYearlessFormatStingForLocale = (locale: Locale) => {
  switch (locale.code) {
    case "en-US":
      return "M/d";
    case "en-GB":
      return "d/M";
    default:
      return "M/d";
  }
};

//returns M/d and time in timezone
export const formatDatetimeInTimezone = (datetime: Date | string, timeZone: string, locale: Locale) => {
  const dateInTz = utcToZonedTime(datetime, timeZone);
  const timeFormatString = getTimeFormatStringForLocale(locale);

  const datetimeFormatString = `${getShortDateYearlessFormatStingForLocale(locale)} ${timeFormatString}`;
  return formatTz(dateInTz, datetimeFormatString, { timeZone });
};

export const formatTimeInTimezoneWithLocale = (datetime: Date | string, timeZone: string, locale: Locale) => {
  const dateInTz = utcToZonedTime(datetime, timeZone);
  const timeFormatString = getTimeFormatStringForLocale(locale);
  return formatTz(dateInTz, timeFormatString, { timeZone });
};

export const formatYearlessDate = (date: Date | string, locale: Locale) => {
  return formatDate(date, { formatVariant: getShortDateYearlessFormatStingForLocale(locale) }, locale);
};

export const formatEventTime = ({ start, end }: Event) => formatTimeRange(start, end);

export const formatTimeRange = (start: string, end: string) => {
  const startDateTime = parse(start, "HH:mm:ss", new Date());
  const endDateTime = parse(end, "HH:mm:ss", new Date());

  const sameAmPm = format(startDateTime, AM_PM_TOKEN) === formatTz(endDateTime, AM_PM_TOKEN);
  const startFormatString = getTimeFormatString(startDateTime, {
    excludeTimezoneSuffix: true,
    excludeAmPm: sameAmPm,
  });
  const endFormatString = getTimeFormatString(endDateTime, {
    excludeTimezoneSuffix: true,
  });
  return `${formatTz(startDateTime, startFormatString)} – ${formatTz(endDateTime, endFormatString)}`;
};

export interface FormatDateOptions {
  useAbbreviations?: boolean;
  formatVariant?: string;
}
// TODO: Locale make sure we are using the locale version of this everywhere
/**
 *
 * @param date {Date|string} date - The date to format, can be a Date object or an ISO string.
 * @param options {FormatDateOptions} options - useAbbreviations and formatVariant (i.e. "P" | "PP" | "PPP" | "PPPP", etc.)
 * See: https://date-fns.org/v2.28.0/docs/format for more options (defaults to "P")
 * @param locale {Locale} - locale to use for formatting, defaults to enUS if not passed in.
 * @returns a formatted date string
 */
export const formatDate = (date: Date | string, options: FormatDateOptions = {}, locale: Locale) => {
  const dateFnsLocale = locale || enUS;
  const datetime = typeof date === "string" ? parseISO(date) : date;
  const { useAbbreviations = false, formatVariant = "P" } = options;

  if (useAbbreviations) {
    const todayDate = new Date();
    const today = serializeDate(todayDate);
    const yesterday = serializeDate(addDays(todayDate, -1));
    const tomorrow = serializeDate(addDays(todayDate, 1));
    const toAbbrevDate = serializeDate(datetime);

    if (toAbbrevDate === today) return "Today";
    if (toAbbrevDate === yesterday) return "Yesterday";
    if (toAbbrevDate === tomorrow) return "Tomorrow";
  }

  return format(datetime, formatVariant, { locale: dateFnsLocale });
};

interface DateAndTime {
  date: string;
  time: string;
}

export const dateAndTimeToIsoInTz = ({ date, time }: DateAndTime, timezone: string): string => {
  return moment.tz(`${date} ${time}`, timezone).utc().format();
};

export const jsDateToDateAndTimeInTz = (jsDate: Date, timezone: string): DateAndTime => {
  const inTz = moment.tz(jsDate, timezone).tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true).toDate();
  return {
    date: serializeDate(inTz),
    time: serializeTime(inTz),
  };
};

export const dateToIsoInTz = (date: string, timezone: string): string => dateAndTimeToIsoInTz({ date, time: "00:00:00" }, timezone);

export const getRangeAroundDateInTz = (date: string, timezone: string, options?: { range?: "month"; format?: "date" | "datetime" }) => {
  // TODO: Check options to determine which "start" or "end" functions to use (i.e. startOfMonth, startOfYear, etc.)
  const jsDate = parseDate(date);
  const startOfMonthJsDate = startOfMonth(jsDate);
  const endOfMonthJsDate = endOfMonth(jsDate);

  if (options?.format === "date") {
    return {
      start: serializeDate(startOfMonthJsDate),
      end: serializeDate(endOfMonthJsDate),
    };
  }

  return {
    start: dateAndTimeToIsoInTz(
      {
        date: serializeDate(startOfMonthJsDate),
        time: serializeTime(startOfMonthJsDate),
      },
      timezone
    ),
    end: dateAndTimeToIsoInTz(
      {
        date: serializeDate(endOfMonthJsDate),
        time: serializeTime(endOfMonthJsDate),
      },
      timezone
    ),
  };
};

export const getSurroundingDays = (dateString: string): { prevDate: string; nextDate: string } => {
  const date = parseDate(dateString);
  return {
    prevDate: serializeDate(addDays(date, -1)),
    nextDate: serializeDate(addDays(date, 1)),
  };
};

type DatetimeEvent = {
  start: string;
  durationInMinutes: number;
};

export const eventToIsoOnDateInTz = (event: Event, date: string, timezone: string, options?: { minDuration?: number }): DatetimeEvent => {
  const start = dateAndTimeToIsoInTz(
    {
      date,
      time: event.start,
    },
    timezone
  );
  const end = dateAndTimeToIsoInTz(
    {
      date,
      time: event.end,
    },
    timezone
  );
  const minDuration = options?.minDuration ?? 0;
  const rawDurationInMinutes = differenceInMinutes(new Date(end), new Date(start));
  const durationInMinutes = rawDurationInMinutes > minDuration ? rawDurationInMinutes : minDuration;

  return {
    start,
    durationInMinutes,
  };
};

export const today = new Date();
export const yesterday = addDays(today, -1);
export const twoDaysAgo = addDays(today, -2);
export const fourDaysAgo = addDays(today, -4);
export const tomorrow = addDays(today, 1);

export const inTwoDays = addDays(today, 2);
export const inThreeDays = addDays(today, 3);
export const inFourDays = addDays(today, 4);

export const eachDateOfInterval = ({ start, end }: { start: string; end: string }): string[] => {
  return eachDayOfInterval({
    start: parseDate(start),
    end: parseDate(end),
  }).map(serializeDate);
};

export const ORDERED_DAYS_OF_WEEK: readonly DayOfWeek[] = [
  DayOfWeek.monday,
  DayOfWeek.tuesday,
  DayOfWeek.wednesday,
  DayOfWeek.thursday,
  DayOfWeek.friday,
  DayOfWeek.saturday,
  DayOfWeek.sunday,
];

export const getFormattedTimeDifference = (updatedDate: string, timezone: string): string => {
  const now = new Date();
  const zonedDate = utcToZonedTime(updatedDate, timezone);
  const zonedNow = utcToZonedTime(now, timezone);

  const daysAgo = differenceInDays(zonedNow, zonedDate);
  const monthsAgo = differenceInMonths(zonedNow, zonedDate);
  const yearsAgo = differenceInYears(zonedNow, zonedDate);

  if (daysAgo === 0) {
    return "Today";
  }
  if (yearsAgo >= 1) {
    return yearsAgo === 1 ? "1y ago" : `${yearsAgo}y ago`;
  }
  if (monthsAgo >= 1) {
    return monthsAgo === 1 ? "1m ago" : `${monthsAgo}m ago`;
  }

  return `${daysAgo}d ago`;
};

const parseHub12HourDisplayDate = (hubDisplayDate: string) => {
  const date = hubDisplayDate.substring(0, 10);
  const time = hubDisplayDate.substring(11, 19);
  const beforeNoon = hubDisplayDate.toLowerCase().substring(19, 21) === "am";
  const isNoon = time.substring(0, 2) === "12" && !beforeNoon;
  const isMidnight = time.substring(0, 2) === "12" && beforeNoon;
  return { date, time, beforeNoon, isNoon, isMidnight };
};

// This always assumes the timezones are the same when comparing dates because hub sends over a non parsable timezone
export const compareHubDisplayDate = (a: string | null, b: string | null): number => {
  const aIsBeforeB = -1;
  const aIsAfterB = 1;
  const aIsSameAsB = 0;

  // put nulls and "" first
  if (!a && !b) {
    return aIsSameAsB;
  } else if (!a) {
    return aIsBeforeB;
  } else if (!b) {
    return aIsAfterB;
  }

  const is12Hour = a.toLowerCase().includes("am") || a.toLowerCase().includes("pm");
  if (is12Hour) {
    const aParsed = parseHub12HourDisplayDate(a);
    const bParsed = parseHub12HourDisplayDate(b);

    // Check Date First
    if (aParsed.date !== bParsed.date) {
      if (aParsed.date < bParsed.date) {
        return aIsBeforeB;
      } else if (aParsed.date > bParsed.date) {
        return aIsAfterB;
      }
    }
    // The date is the same check AM/PM
    if (aParsed.beforeNoon !== bParsed.beforeNoon) {
      if (aParsed.beforeNoon) {
        return aIsBeforeB;
      } else {
        return aIsAfterB;
      }
    }

    // Handle the case where noon and midnight are 12 but come first in order of 12, 1, 2, 3, etc.
    if (aParsed.isNoon !== bParsed.isNoon || aParsed.isMidnight !== bParsed.isMidnight) {
      if (aParsed.isNoon || aParsed.isMidnight) {
        return aIsBeforeB;
      } else {
        return aIsAfterB;
      }
    }

    if (aParsed.time < bParsed.time) {
      return aIsBeforeB;
    } else if (aParsed.time > bParsed.time) {
      return aIsAfterB;
    } else {
      return aIsSameAsB;
    }
  } else {
    // IF 24 Hour strings should be directly comparable CHECK ORDER
    if (a < b) {
      return aIsBeforeB;
    } else if (a > b) {
      return aIsAfterB;
    } else {
      return aIsSameAsB;
    }
  }
};

export const startOfDayUTC = (date: Date): Date => {
  const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  return utcDate;
};

// Custom function to get the end of the day in UTC
export const endOfDayUTC = (date: Date): Date => {
  const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999));
  return utcDate;
};

export const serializeDateInUTC = (date: Date): string => {
  const utcDate = utcToZonedTime(date, "UTC"); // Convert to UTC-based time
  return format(utcDate, "yyyy-MM-dd");
};

export const generateFullDateRangeInUTC = (start: Date, end: Date): string[] => {
  const utcStart = startOfDay(utcToZonedTime(start, "UTC"));
  const utcEnd = endOfDay(utcToZonedTime(end, "UTC"));

  return eachDayOfInterval({
    start: utcStart,
    end: utcEnd,
  }).map(date => serializeDateInUTC(date));
};

import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, SxProps, Typography } from "@mui/material";
import ImageWithLockedAspectRatio from "@notemeal/shared/ui/Image/ImageWithLockedAspectRatio";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useState } from "react";
import CropperModal from "../../universal/Image/CropModal";
import ImageFileDragAndDrop from "../../universal/Image/ImageFileDragAndDrop";
import { useUploadPhoto } from "../../universal/Image/uploadPhoto";
import { MenuItemChangeImageAction } from "../reducer";

interface ImageProps {
  imageUrl: string | null;
  onChangeImage: (args: MenuItemChangeImageAction["payload"]) => void;
}

export const Image = ({ imageUrl, onChangeImage }: ImageProps) => {
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);

  const [uploadPhotos, imagesUploading] = useUploadPhoto({
    onComplete: (imageUrl: string): void => {
      if (imageUrl) {
        onChangeImage({ imageUrl });
      }
    },
  });

  const handleCropComplete = async (croppedImage: string) => {
    uploadPhotos(croppedImage);
  };

  if (imagesUploading) {
    return <Loading />;
  }
  return imageUrl ? (
    <Box sx={{ position: "relative" }}>
      <Box>
        <ImageWithLockedAspectRatio imageUrl={imageUrl} borderRadius={"1"} />
      </Box>
      <Box sx={{ position: "absolute", top: 0.5, left: 1.5 }}>
        <IconButton
          sx={{
            color: "white",
            backgroundColor: "black",
            opacity: 0.6,
            "&:hover": {
              color: "white",
              backgroundColor: "black",
            },
          }}
          onClick={() => onChangeImage({ imageUrl: null })}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  ) : (
    <>
      <ImageFileDragAndDrop onUpload={setImageToCrop}>
        <ImageUploadLabel />
      </ImageFileDragAndDrop>
      {imageToCrop && (
        <CropperModal
          open={!!imageToCrop}
          onClose={() => setImageToCrop(null)}
          imageUrl={imageToCrop}
          onSave={handleCropComplete}
        ></CropperModal>
      )}
    </>
  );
};

interface ReadonlyImageProps {
  imageUrl: string | null;
}

export const ReadonlyImage = ({ imageUrl }: ReadonlyImageProps) => {
  return imageUrl ? (
    <Box sx={{ position: "relative" }}>
      <ImageWithLockedAspectRatio imageUrl={imageUrl} borderRadius={"1"} />
    </Box>
  ) : (
    <>
      <ImageFileDragAndDrop disabled={true} onUpload={() => {}}>
        <ImageUploadLabel sx={{ opacity: 0.5 }} />
      </ImageFileDragAndDrop>
    </>
  );
};

interface ImageUploadLabelProps {
  sx?: SxProps;
}

const ImageUploadLabel = ({ sx }: ImageUploadLabelProps) => {
  return (
    <Typography variant="h3" sx={{ textAlign: "center", pb: 3, ...sx }}>
      Drop Image or Click to Upload
    </Typography>
  );
};

import { useAthleteFoodPreferenceContext } from "@notemeal/shared/ui/contexts/AthleteFoodPreferences";
import { useMealPlanFoodPreferenceContext } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import { sortFnByKey } from "@notemeal/utils/sort";
import React from "react";
import { FullExchangeFragment, FullExchangeSetFragment, FullMealTemplateFragment, RecipeWithServingsFragment } from "../../../../types";
import { Orientation, isExchange } from "../utils";
import ExchangeServingListBox from "./ExchangeServingListBox";
import { useExpandedStyles, useNoPlateStyles } from "./styles";

export interface ExportMealExchangeListsProps {
  mealTemplate: FullMealTemplateFragment;
  orientation: Orientation;
  exchangeSet: FullExchangeSetFragment | null;
  displaySecondaryExchanges: boolean;
  expandExchanges: boolean;
  recipes: readonly RecipeWithServingsFragment[];
}

type ServingAmount = FullExchangeSetFragment["exchanges"][0]["exchangeServingList"]["servingAmounts"][0];

interface GetNumberServingsProps {
  allPrimaryServings: ServingAmount[];
  allSecondaryServings: ServingAmount[];
}

const ExportMealExchangeLists = ({
  exchangeSet,
  displaySecondaryExchanges,
  orientation,
  mealTemplate,
  expandExchanges,
  recipes,
}: ExportMealExchangeListsProps) => {
  const noPlateClasses = useNoPlateStyles();
  const expandedClasses = useExpandedStyles();
  const { isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isAvoidedFood } = useMealPlanFoodPreferenceContext();
  const isNegativeFood = (foodId: string) => {
    return isDislikedFood(foodId).value || isAvoidedFood(foodId).value;
  };
  const getServForEx = (exchangeIdToServingLists: { [id: string]: null | readonly string[] }, exchange?: FullExchangeFragment) => {
    if (!exchange) {
      return { servingAmounts: [] };
    }
    const servingIdList = exchange.exchangeServingList.servingAmounts
      .filter(sa => !isNegativeFood(sa.serving.foodOrRecipe.id))
      .map(sa => sa.serving.id);
    // If the servingIdList is null, use the exchange list!
    return {
      servingAmounts: exchange.exchangeServingList.servingAmounts.filter(sa =>
        servingIdList.some(servingId => servingId === sa.serving.id)
      ),
      exchangeId: exchange.id,
    };
  };

  if (!isExchange(mealTemplate) || !exchangeSet) {
    return null;
  }
  const exchangeTargets = mealTemplate.exchangeTargets;
  if (!exchangeTargets) {
    return null;
  }
  const exchangeIdToServingLists: {
    [id: string]: null | readonly string[];
  } = exchangeSet.exchanges.reduce(
    (cur: { [id: string]: null | readonly string[] }, next) => ({
      ...cur,
      [next.id]: next.exchangeServingList.servingAmounts.map(sa => sa.serving.id),
    }),
    {}
  );
  const exchanges = exchangeSet.exchanges;

  const numberServingsPerBox = orientation === "portrait" ? 22 : 16;
  const maxNumSecondaryEx = numberServingsPerBox / 2;
  const getNumSecondaryServings = ({ allPrimaryServings, allSecondaryServings }: GetNumberServingsProps): number => {
    if (!displaySecondaryExchanges) {
      return 0;
    }
    return allSecondaryServings.length > maxNumSecondaryEx ? maxNumSecondaryEx : allSecondaryServings.length;
  };

  const getSecondaryServings = ({ allSecondaryServings, ...props }: GetNumberServingsProps): ServingAmount[] => {
    const num = getNumSecondaryServings({ allSecondaryServings, ...props });
    return allSecondaryServings.slice(0, num);
  };

  const getPrimaryServings = ({ allPrimaryServings, allSecondaryServings }: GetNumberServingsProps): ServingAmount[] => {
    const num = numberServingsPerBox - getNumSecondaryServings({ allPrimaryServings, allSecondaryServings });
    return allPrimaryServings.slice(0, num);
  };

  const fruit = getServForEx(
    exchangeIdToServingLists,
    exchanges.find(e => e.type === "fruit")
  );
  const fat = getServForEx(
    exchangeIdToServingLists,
    exchanges.find(e => e.type === "fat")
  );
  const vegetable = getServForEx(
    exchangeIdToServingLists,
    exchanges.find(e => e.type === "vegetable")
  );

  const proteinExchanges = exchanges.filter(e => e.type === "protein").sort(sortFnByKey("fat"));
  const dairyExchanges = exchanges.filter(e => e.type === "dairy").sort(sortFnByKey("fat"));
  // Sort by fat, as "sports fuel" will have 0 fat
  const starchExchanges = exchanges
    .filter(e => e.type === "starch")
    .sort(sortFnByKey("pro"))
    .reverse();

  /* NOTE: To support more than "1" protein types, we bucket "all protein exchanges but the leanest"
   * into one group of "hi fat proteins"
   * */
  const leanestProtein = getServForEx(exchangeIdToServingLists, proteinExchanges[0]);
  const dairy = getServForEx(exchangeIdToServingLists, dairyExchanges[0]);
  const starch = getServForEx(exchangeIdToServingLists, starchExchanges[0]);

  return (
    <div className={expandedClasses.mealPlateContainer}>
      <div className={noPlateClasses.topLeft}>
        <ExchangeServingListBox
          servingAmounts={getPrimaryServings({
            allPrimaryServings: starch.exchangeId ? starch.servingAmounts : [],
            allSecondaryServings: starchExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
          })}
          secondaryServingAmounts={getSecondaryServings({
            allPrimaryServings: starch.servingAmounts,
            allSecondaryServings: starchExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
          })}
          secondaryExchangeName={starchExchanges[1] ? starchExchanges[1].shortName : undefined}
          name="Starches"
          exchangeType={"starch"}
          exchangeTargetAmount={null}
          recipes={recipes}
        />
      </div>
      <div className={noPlateClasses.topLeftMid}>
        <ExchangeServingListBox
          servingAmounts={getPrimaryServings({
            allPrimaryServings: dairy.exchangeId ? dairy.servingAmounts : [],
            allSecondaryServings: dairyExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
          })}
          secondaryServingAmounts={getSecondaryServings({
            allPrimaryServings: dairy.exchangeId ? dairy.servingAmounts : [],
            allSecondaryServings: dairyExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
          })}
          secondaryExchangeName={dairyExchanges[1] ? dairyExchanges[1].shortName : undefined}
          twoColumns={false}
          name="Dairy"
          exchangeType={"dairy"}
          exchangeTargetAmount={null}
          recipes={recipes}
        />
      </div>
      <div className={noPlateClasses.topRightMid}>
        <ExchangeServingListBox
          servingAmounts={fruit.servingAmounts.slice(0, numberServingsPerBox)}
          name="Fruits"
          exchangeType={"fruit"}
          exchangeTargetAmount={null}
          recipes={recipes}
        />
      </div>
      <div className={noPlateClasses.topRight}>
        <ExchangeServingListBox
          servingAmounts={vegetable.servingAmounts.slice(0, numberServingsPerBox)}
          name="Veggies"
          exchangeType={"vegetable"}
          exchangeTargetAmount={null}
          recipes={recipes}
        />
      </div>
      <div className={noPlateClasses.bottomLeft}>
        <ExchangeServingListBox
          servingAmounts={getPrimaryServings({
            allPrimaryServings: leanestProtein.exchangeId ? leanestProtein.servingAmounts : [],
            allSecondaryServings: proteinExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
          })}
          secondaryServingAmounts={getSecondaryServings({
            allPrimaryServings: leanestProtein.exchangeId ? leanestProtein.servingAmounts : [],
            allSecondaryServings: proteinExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
          })}
          secondaryExchangeName={proteinExchanges[1] ? proteinExchanges[1].shortName : undefined}
          twoColumns={true}
          name="Protein"
          exchangeType={"protein"}
          exchangeTargetAmount={null}
          recipes={recipes}
        />
      </div>
      <div className={noPlateClasses.bottomRight}>
        <ExchangeServingListBox
          twoColumns
          servingAmounts={fat.servingAmounts.slice(0, numberServingsPerBox)}
          name="Healthy Fat"
          exchangeType={"fat"}
          exchangeTargetAmount={null}
          recipes={recipes}
        />
      </div>
    </div>
  );
};

export default ExportMealExchangeLists;

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, IconButton, Typography } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { getKeyForSuggestion } from "../../Suggestions/utils";
import { SuggestedMealTemplateCalendarEvent } from "../../types";
import PopoverBase from "./Base";

interface SuggestionCalendarEventPreviewLoaderProps {
  event: SuggestedMealTemplateCalendarEvent;
  anchorEl: HTMLElement;
  onClose: () => void;
  onAccept: (key: string) => void;
  onReject: (key: string) => void;
}

const SuggestionCalendarEventPreviewLoader = ({
  event,
  anchorEl,
  onClose,
  onAccept,
  onReject,
}: SuggestionCalendarEventPreviewLoaderProps) => {
  const name = event.mealTemplate.meal.name;
  const clientTimezone = useClientTimezone();
  const options = { excludeTimezoneSuffix: true };
  const startToEndText = formatTimeRangeInTimezone(event.start, event.durationInMinutes, clientTimezone, options);

  return (
    <PopoverBase
      anchorEl={anchorEl}
      onClose={onClose}
      variant="lg">
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Typography>{name}</Typography>

        <div>
          <IconButton
            onClick={() => {
              onReject(getKeyForSuggestion(event));
              onClose();
            }}
          >
            <CancelIcon color="error" />
          </IconButton>
          <IconButton
            onClick={() => {
              onAccept(getKeyForSuggestion(event));
              onClose();
            }}
          >
            <CheckCircleIcon color="success" />
          </IconButton>
        </div>
      </Box>
      <Typography variant="body2">
        Move {name} to {startToEndText}?
      </Typography>
    </PopoverBase>
  );
};

export default SuggestionCalendarEventPreviewLoader;

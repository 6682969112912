import { DiningStationTemplateFullFragment, MealType } from "../../types";
import {
  getMenuItemAppearanceGroupState,
  menuItemAppearanceGroupReducer,
  MenuItemAppearanceGroupAction,
  MenuItemAppearanceGroupState,
} from "../../components/MenuItemAppearance/reducer";

export type DiningStationTemplateModalState = Omit<DiningStationTemplateFullFragment, "id" | "menuItemAppearances"> & {
  readonly id: string | null;
  readonly edited: boolean;
  readonly menuItemAppearances: MenuItemAppearanceGroupState;
};

interface DiningStationChangeNameAction {
  type: "DiningStationChangeNameAction";
  payload: {
    name: string;
  };
}

interface DiningStationChangeDefaultMaxAmountAction {
  type: "DiningStationChangeDefaultMaxAmountAction";
  payload: {
    defaultMaxAmount: number | null;
  };
}

interface DiningStationChangeMealTypesAction {
  type: "DiningStationChangeMealTypesAction";
  payload: {
    mealTypes: readonly MealType[];
  };
}

type DiningStationTemplateModalAction =
  | DiningStationChangeNameAction
  | DiningStationChangeDefaultMaxAmountAction
  | DiningStationChangeMealTypesAction
  | MenuItemAppearanceGroupAction;

export const diningStationTemplateReducer = (
  state: DiningStationTemplateModalState,
  action: DiningStationTemplateModalAction
): DiningStationTemplateModalState => {
  switch (action.type) {
    case "DiningStationChangeNameAction":
      return {
        ...state,
        edited: true,
        name: action.payload.name,
      };
    case "DiningStationChangeDefaultMaxAmountAction":
      return {
        ...state,
        edited: true,
        defaultMaxAmount: action.payload.defaultMaxAmount,
      };
    case "DiningStationChangeMealTypesAction":
      return {
        ...state,
        edited: true,
        mealTypes: action.payload.mealTypes,
      };
    default:
      return {
        ...state,
        edited: true,
        menuItemAppearances: menuItemAppearanceGroupReducer(state.menuItemAppearances, action),
      };
  }
};

export const getNewDiningStationTemplateModalState = (): DiningStationTemplateModalState => ({
  id: null,
  name: "",
  mealTypes: [],
  defaultMaxAmount: null,
  edited: false,
  menuItemAppearances: getMenuItemAppearanceGroupState([], "create"),
  __typename: "DiningStationTemplate",
});

import React from "react";

import Content, { AthleteTimelineDateColumnContentProps } from "./Content";
import Empty from "./Empty";
import { useTimelineForDatePayload } from "../useTimelineForDate";

interface AthleteTimelineDateColumnProps extends Omit<AthleteTimelineDateColumnContentProps, "date" | "datePayload"> {
  date: string | null;
  datePayload: useTimelineForDatePayload | null;
}

const AthleteTimelineDateColumn = ({ date, datePayload, ...props }: AthleteTimelineDateColumnProps) => {
  return date && datePayload ? (
    <Content
      {...props}
      date={date}
      datePayload={datePayload} />
  ) : (
    <Empty gridColumn={props.gridColumn} onChangeDate={props.onChangeDate} />
  );
};

export default AthleteTimelineDateColumn;

import { Card, CardContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ProfileSyncRuleWithEntities } from "@notemeal/profile-sync";
import React from "react";
import { TeamworksTeamFragment } from "../../../../../types";
import { getUnusedNotemealTeamNames } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listColumn: {
      overflow: "hidden",
    },
    paper: {
      height: "calc(100% - 64px)",
    },
    cardContent: {
      display: "flex",
      justifyContent: "center",
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
    },
    dialogContent: { padding: 0 },
  })
);

interface UnusedNotemealTeamWarningProps {
  className?: string;
  linkedTeamworksTeams: readonly TeamworksTeamFragment[];
  syncRulesWithProfiles: readonly ProfileSyncRuleWithEntities[];
}

const UnusedNotemealTeamWarning = ({ className, linkedTeamworksTeams, syncRulesWithProfiles }: UnusedNotemealTeamWarningProps) => {
  const classes = useStyles();

  const unusedTeamNames = getUnusedNotemealTeamNames({
    linkedTeamworksTeams,
    syncRulesWithProfiles,
  });

  if (unusedTeamNames.length === 0) {
    return null;
  }
  const cardText = `No rule found for the Nutrition Team(s) ${unusedTeamNames.join(", ")}`;

  return (
    <Card raised className={className}>
      <CardContent className={classes.cardContent}>
        <Typography>{cardText}</Typography>
      </CardContent>
    </Card>
  );
};

export default UnusedNotemealTeamWarning;

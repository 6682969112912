import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { GroupedMappedChoice } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tinyFont: {
      fontSize: 10,
    },
    choiceName: {
      fontSize: 10,
      fontWeight: 600,
    },
  })
);

interface ChoiceCellProps {
  mappedChoices: readonly GroupedMappedChoice[];
}

const ChoiceCell = ({ mappedChoices }: ChoiceCellProps) => {
  const classes = useStyles();
  return (
    <>
      {mappedChoices.map(choice => {
        const options = choice.options;
        if (options.length === 0) {
          return null;
        }
        return (
          <Typography key={`${choice.menuItemChoice.id}`} className={classes.tinyFont}>
            <span className={classes.choiceName}>{choice.menuItemChoice.name}: </span>
            {options
              .map(option => {
                const optionAmount = option.canEditAmount ? ` (${option.amount}x)` : "";
                return `${option.menuItemChoiceOption.name}${optionAmount}`;
              })
              .join(", ")}
            <br />
          </Typography>
        );
      })}
    </>
  );
};

export default ChoiceCell;

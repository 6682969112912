import { datadogRum } from "@datadog/browser-rum";
import MenuItemDialogContent from "apps/web/src/components/MenuItem/Dialog/Content";
import MenuItemSearchBar from "apps/web/src/components/MenuItem/SearchBar/MenuItem";
import { MenuItemState } from "apps/web/src/components/MenuItem/reducer";
import { newMenuItemState } from "apps/web/src/components/MenuItem/utils";
import React, { useState } from "react";
import { MenuBuilderMenuItemType } from "./MenuBuilderMealSchema";

interface MenuBuilderMenuItemSearchProps {
  showMenuItemSearch: boolean;
  usedMenuItemIds: readonly string[];
  addMenuItem: (menuItem: MenuBuilderMenuItemType) => void;
  onCreateMenuItem: (menuItem: MenuItemState) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  searchInputRef: React.RefObject<HTMLInputElement>;
}

export const MenuBuilderMenuItemSearch = ({
  showMenuItemSearch,
  addMenuItem,
  onCreateMenuItem,
  usedMenuItemIds,
  inputValue,
  setInputValue,
  searchInputRef,
}: MenuBuilderMenuItemSearchProps) => {
  const [createDialogState, setCreateDialogState] = useState<MenuItemState | null>(null);

  return (
    <>
      {showMenuItemSearch && (
        <MenuItemSearchBar
          searchInputRef={searchInputRef}
          sx={{
            "& .MuiInputBase-root": {
              padding: 0,
            },
            "& .MuiInput-root": {
              ".MuiInput-input": {
                height: "18px",
                padding: 0,
              },
            },
          }}
          usedMenuItemIds={usedMenuItemIds}
          variant={"MenuBuilder"}
          onAdd={addMenuItem}
          onCreate={name => setCreateDialogState(newMenuItemState(name, false))}
          inputValue={inputValue}
          setInputValue={setInputValue}
          ListboxProps={{ sx: { minHeight: "400px" } }}
          onKeyUp={event => {
            if (event.key === "Enter" && inputValue) {
              datadogRum.addAction("menu_builder.quick_added_menu_item");
              onCreateMenuItem(newMenuItemState(inputValue, false));
            }
          }}
          blurOnSelect={false}
        />
      )}
      {createDialogState && (
        <MenuItemDialogContent
          open={createDialogState !== null}
          onClose={() => setCreateDialogState(null)}
          title={"Create Reusable Menu Item"}
          initState={createDialogState}
          variant="MenuItem"
          onSave={({ menuItem, maxAmount, availableForOrder, allowSpecialRequests }) => {
            onCreateMenuItem({
              menuItem,
              maxAmount,
              availableForOrder,
              allowSpecialRequests,
              edited: false,
            });
            setCreateDialogState(null);
          }}
        />
      )}
    </>
  );
};

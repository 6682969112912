import { DayOfWeek } from "@notemeal/graphql/types";
import { MealTypeInferredType, MealTypeSchema } from "@notemeal/validators";
import { PlannedMenuGridMealFragment, PlannedMenuMealGridRowFragment } from "apps/web/src/types";
import { z } from "zod";

const MenuBuilderMenuItemSchema = z.object({
  id: z.string().min(1),
  name: z.string(),
  isMissingIngredients: z.boolean(),
});

const MenuBuilderMealRowItemSchema = z.object({
  id: z.string().nullable(),
  dayOfWeek: z.nativeEnum(DayOfWeek),
  position: z.number().min(0, { message: "Position must be greater than or equal to 0" }),
  menuItem: MenuBuilderMenuItemSchema,
});

const MenuBuilderMealRowSchema = z.object({
  id: z.string().nullable(), // rows created on frontend won't have an ID
  includeInAutoComplete: z.boolean(), // prevent names of templates from showing up in freetext autocomplete
  diningStationName: z.string().min(1, { message: "Required" }),
  foodType: z.string().nullable(),
  position: z.number().min(0, { message: "Position must be greater than or equal to 0" }),
  items: z.array(MenuBuilderMealRowItemSchema),
});

const MenuBuilderThemeSchema = z.string();

export const MenuBuilderMealSchema = z.object({
  id: z.string().nullable(), // meals created on frontend won't have an ID
  mealName: z.string().min(1, { message: "Required" }),
  mealType: MealTypeSchema,
  startTime: z.string().min(1, { message: "Required" }),
  endTime: z.string().min(1, { message: "Required" }),
  weekId: z.string().min(1),
  rows: z.array(MenuBuilderMealRowSchema),
  themes: z.array(MenuBuilderThemeSchema),
});

export const MenuBuilderSchema = z.object({
  weeks: z.array(
    z.object({
      meals: z.array(MenuBuilderMealSchema),
    })
  ),
});

export type MenuBuilderMenuItemType = z.infer<typeof MenuBuilderMenuItemSchema>;
export type MenuBuilderMealRowItemIndexedType = MenuBuilderMealRowItemType & { index: number };
export type MenuBuilderMealRowItemType = z.infer<typeof MenuBuilderMealRowItemSchema>;
export type MenuBuilderMealRowType = z.infer<typeof MenuBuilderMealRowSchema>;
export type MenuBuilderMealType = z.infer<typeof MenuBuilderMealSchema>;
export type MenuBuilderType = z.infer<typeof MenuBuilderSchema>;

export const getPlannedMenuMealFormValues = (weekId: string, meal?: PlannedMenuGridMealFragment): MenuBuilderMealType => {
  if (meal) {
    return {
      id: meal.id,
      mealName: meal.name,
      mealType: meal.type,
      startTime: meal.startTime,
      endTime: meal.endTime,
      weekId,
      rows: meal.plannedMenuMealRows.map(getPlannedMenuMealRowFormValues),
      themes: [...meal.themes],
    };
  }

  return {
    id: "",
    mealName: "",
    // works around a limitation with react-hook-form, where I need specify a default value that isn't valid for the form
    // react-hook-form useFieldArray methods require valid inputs, so I can't just pass in an empty object
    // see https://github.com/orgs/react-hook-form/discussions/10211
    mealType: "" as MealTypeInferredType,
    startTime: "",
    endTime: "",
    weekId,
    rows: [getPlannedMenuMealRowFormValues(null, 0)],
    themes: ["", "", "", "", "", "", ""],
  };
};

export const getPlannedMenuMealRowFormValues = (row: PlannedMenuMealGridRowFragment | null, position?: number): MenuBuilderMealRowType => {
  if (row) {
    return {
      id: row.id,
      // on load we can't tell what came from template versus freetext, so default to excluding from autocomplete
      includeInAutoComplete: false,
      diningStationName: row.diningStationName,
      foodType: row.foodType,
      position: row.position,
      items: row.plannedMenuMealRowItems.map(item => ({
        id: item.id,
        dayOfWeek: item.dayOfWeek as DayOfWeek,
        position: item.position,
        menuItem: {
          id: item.menuItem.id,
          name: item.menuItem.name,
          isMissingIngredients: item.menuItem.isMissingIngredients,
        },
      })),
    };
  }
  return {
    id: "",
    includeInAutoComplete: false,
    diningStationName: "",
    foodType: "",
    position: position ?? 0,
    items: [],
  };
};

import { FullFoodGroupFragment, FoodPreviewFragment } from "../types";

export interface PreferenceFoodInfo {
  value: boolean;
  reason: string[];
}

export type PreferenceFoodFunction = (foodId: string) => PreferenceFoodInfo;

export const combineFoodsAndFoodGroupsIntoIndex = (
  foods: readonly FoodPreviewFragment[],
  foodGroups: readonly FullFoodGroupFragment[]
): { [key: string]: Set<string> } => {
  const allFoods = [
    ...foods.map(food => ({ food, displayName: food.name })),
    ...foodGroups.flatMap(fg => fg.foods.map(food => ({ food, displayName: fg.name }))),
  ];
  const initDict: { [key: string]: Set<string> } = {};
  const foodsIndex: {
    [key: string]: Set<string>;
  } = allFoods.reduce(
    (acc, { food, displayName }) => ({
      ...acc,
      [food.id]: (acc[food.id] || new Set()).add(displayName),
    }),
    initDict
  );

  return foodsIndex;
};

import { enUS, enGB } from "date-fns/locale";

/**
 * Supported date-fns locales.
 *
 * We explicitly write them here to avoid importing all locales from date-fns
 */
const dateFnsSupportedLocales: Record<string, Locale> = { "en-US": enUS, "en-GB": enGB };
const DEFAULT_DATE_FNS_LOCALE = enUS;

/**
 * Returns date-fns locale for given locale code.
 *
 * @param code - locale code from db (we store locale codes like en-US, en-GB, etc.)
 */
export const getDateFnsLocaleForCode = (code: string): Locale => {
  const locale = dateFnsSupportedLocales[code];
  if (!locale) {
    return DEFAULT_DATE_FNS_LOCALE;
  }
  return locale;
};

export const US_LOCALE_CODE = "en-US";
export const GB_LOCALE_CODE = "en-GB";

export type LocaleType = typeof US_LOCALE_CODE | typeof GB_LOCALE_CODE;

// nutritionix seems to require a slightly different format
export type NutritionixLocale = "en_US" | "en_GB";
const DEFAULT_NUTRITIONIX_LOCALE: NutritionixLocale = "en_US";

type NutritionixBrandedRegion = 1 | 2;
const DEFAULT_NUTRITIONIX_BRANDED_REGION: NutritionixBrandedRegion = 1;

export const getNutritionixBrandedRegionForLocale = (localeCode: string | null): NutritionixBrandedRegion => {
  switch (localeCode) {
    case US_LOCALE_CODE:
      return 1;
    case GB_LOCALE_CODE:
      return 2;
    default:
      return DEFAULT_NUTRITIONIX_BRANDED_REGION;
  }
};

export const getNutritionixLocaleForLocale = (localeCode: string | null): NutritionixLocale => {
  switch (localeCode) {
    case US_LOCALE_CODE:
      return "en_US";
    case GB_LOCALE_CODE:
      return "en_GB";
    default:
      return DEFAULT_NUTRITIONIX_LOCALE;
  }
};

import { PaperProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import { addDays, addMinutes, differenceInMinutes } from "date-fns";
import { CalendarEvent } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    event: {
      position: "absolute",
      cursor: "pointer",
      overflowX: "hidden",
      paddingLeft: theme.spacing(0.5),
    },
    eventOutlined: {
      border: "1px solid white",
      marginTop: -1,
      marginLeft: -1,
    },
  })
);

interface getBasePaperPropsArgs {
  event: CalendarEvent;
  hourHeight: number;
  dateStart: Date;
  maxSlot: number;
  slot: number;
  selected: boolean;
  newStart?: Date;
  hasAllDayCell?: boolean;
}

export const useBasePaperProps = ({
  event,
  hourHeight,
  dateStart,
  maxSlot,
  slot,
  selected,
  hasAllDayCell = false,
}: getBasePaperPropsArgs): Pick<PaperProps, "style" | "className" | "elevation"> => {
  const classes = useStyles();

  let top, height, minHeight;

  if (event.isAllDay) {
    top = 10;
    height = hourHeight / 2;
    minHeight = hourHeight / 2;
  } else {
    // get start and duration of event on that day, ignore parts of events that happen on other days
    const startOnDay = event.start < dateStart ? dateStart : event.start;
    const dateEnd = addDays(dateStart, 1);
    const eventEnd = addMinutes(event.start, event.durationInMinutes);
    const durationInMinutes = differenceInMinutes(eventEnd > dateEnd ? dateEnd : eventEnd, startOnDay);

    top = (differenceInMinutes(startOnDay, dateStart) / 60) * hourHeight + (hasAllDayCell ? hourHeight : 0);
    height = (durationInMinutes / 60) * hourHeight;
    minHeight = hourHeight / 2;
  }

  const left = `calc(100% / ${maxSlot} * ${slot - 1})`;
  const width = `calc(100% / ${maxSlot} * ${slot === maxSlot ? 1 : 1.7})`;

  const zIndex = selected ? maxSlot + 1 : slot;
  const className = classnames(classes.event, { [classes.eventOutlined]: (selected && maxSlot > 1) || slot > 1 });

  return {
    style: {
      top,
      height: height - 2,
      minHeight,
      left,
      width,
      zIndex,
    },
    className,
    elevation: selected ? 3 : 0,
  };
};

import HistoryIcon from "@mui/icons-material/History";
import { Box, Card, CardContent, Rating, SxProps, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import React from "react";
import { RatingForMenuItemFragment } from "../../../../types";
import { ratingValueToNumber } from "./utils";

interface ReviewCardProps {
  sx?: SxProps;
  review: RatingForMenuItemFragment;
}

const ReviewCard = ({ review, sx }: ReviewCardProps) => {
  const { formatDateWithLocale } = useDateFormatting();

  if (!review.createdBy) {
    return null;
  }

  return (
    <Card
      key={review.id}
      sx={sx}
      raised>
      <CardContent>
        <Typography>{`${review.createdBy.firstName} ${review.createdBy.lastName}`}</Typography>
        <Box sx={{ display: "flex" }}>
          <Rating value={ratingValueToNumber(review.value)} readOnly />
        </Box>

        <Typography variant="subtitle1" color="textSecondary">
          {review.menuOrderItem?.order.pickupTime
            ? `Ordered on ${formatDateWithLocale(review.menuOrderItem.order.pickupTime)}`
            : `Reviewed on ${formatDateWithLocale(review.createdAt)}`}
        </Typography>

        <Typography variant="body2">{review.review}</Typography>
        {!review.menuItem.isCurrent && (
          <Box sx={{ pt: 1, display: "flex", alignContent: "center" }}>
            <HistoryIcon fontSize="small" />
            <Typography sx={{ pl: 0.5 }} variant="subtitle1">{`Revision created on ${formatDateWithLocale(
              review.menuItem.createdAt
            )}`}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ReviewCard;

import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CookingIcon from "@mui/icons-material/Whatshot";
import { useTheme } from "@mui/material";
import React from "react";
import { MenuOrderItemStatus } from "../../types";

interface OrderStatusIconProps {
  orderStatus?: MenuOrderItemStatus | null;
  fontSize?: "small" | "medium" | "large";
}

export const OrderStatusIcon = ({ orderStatus, fontSize }: OrderStatusIconProps) => {
  const {
    palette: { orderStatusNew, orderStatusCooking, orderStatusDone, orderStatusCancelled },
  } = useTheme();

  switch (orderStatus) {
    case "done":
      return <CheckIcon fontSize={fontSize || "inherit"} sx={{ color: orderStatusDone.main }} />;
    case "new":
      return <ReceiptIcon fontSize={fontSize || "inherit"} sx={{ color: orderStatusNew.main }} />;
    case "cooking":
      return <CookingIcon fontSize={fontSize || "inherit"} sx={{ color: orderStatusCooking.main }} />;
    case "cancelled":
      return <CancelIcon fontSize={fontSize || "inherit"} sx={{ color: orderStatusCancelled.main }} />;
    default:
      return null;
  }
};

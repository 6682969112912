import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      height: "100%",
    },
    menu: {
      flexGrow: 1,
      overflowY: "auto",
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    allCards: {
      display: "flex",
      gap: theme.spacing(4),
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
    order: {
      minWidth: 280,
      maxWidth: 280,
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      "& > *:last-child": {
        flex: 1,
        alignItems: "flex-end",
      },
    },
    orderForm: {
      overflowX: "hidden",
      overflowY: "auto",
    },
    orderButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(1, 0),
    },
    orderButton: {
      margin: theme.spacing(2, 0),
      width: "100%",
    },
    orderButtonHalf: {
      margin: theme.spacing(0, 1),
      width: "calc(50% - 16px)",
    },
    orderContainer: {
      overflowY: "auto",
      gap: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
    },
    arrow: {
      position: "absolute",
      right: theme.spacing(2),
      top: 0,
      bottom: 0,
      margin: "auto",
    },
    orderLimit: {
      background: theme.palette.greyscale[100],
      padding: theme.spacing(1),
      color: theme.palette.mediumEmphasisText,
    },
    backContainer: {
      display: "flex",
      alignItems: "center",
    },
    header: {
      display: "flex",
    },
    timePicker: {
      minWidth: 105,
      "& input": {
        paddingLeft: 0,
        marginLeft: 0,
        borderLeftWidth: 0,
        paddingRight: 0,
        marginRight: 0,
        borderRightWidth: 0,
      },
    },
    editConfirmationModalActions: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(2),
    },
    orderStatus: {
      display: "flex",
      gap: theme.spacing(0.5),
      fontSize: theme.spacing(1.5),
    },
    orderStatusIcon: {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  })
);

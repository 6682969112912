import { EditFullMealOptionFragment, EditFullMealOptionPendingFragment } from "../../../types";

export const shouldShowMealOptionNoteBadge = (mealOptionNote: string, flipped: boolean) => {
  const hasMealOptionNote = !!mealOptionNote;
  const showMealOptionNoteBadge = hasMealOptionNote && !flipped;
  return showMealOptionNoteBadge;
};

export const getMealOptionInput = ({ id: mealOptionId, servingAmounts, note, name, position }: EditFullMealOptionFragment) => ({
  variables: {
    input: {
      mealOptionId,
      servingAmounts: servingAmounts.map(({ serving, ...sa }) => ({
        ...sa,
        servingId: serving.id,
      })),
      note: note || "",
      name: name || "",
      position,
    },
  },
});

export const getMealOptionPendingInput = ({ id: mealOptionPendingId, servingAmounts, note, name }: EditFullMealOptionPendingFragment) => ({
  variables: {
    input: {
      mealOptionPendingId,
      servingAmounts: servingAmounts.map(({ serving, ...sa }) => ({
        servingId: serving.id,
        ...sa,
      })),
      note: note || "",
      name: name || "",
    },
  },
});

export const getRemoveMealOptionVariables = (mealTemplateId: string, type: string, mealOptionId: string) => ({
  variables: { mealTemplateId, type, mealOptionId },
});

export const getRemoveMealOptionPendingVariables = (mealTemplateId: string, type: string, mealOptionPendingId: string) => ({
  variables: { mealTemplateId, type, mealOptionPendingId },
});

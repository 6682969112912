import ContactsIcon from "@mui/icons-material/ContactsRounded";
import { OrgsSyncPage } from "apps/web/src/pages/Auth/Staff/OrgManagement/OrgsSyncPage";
import { SportsPage } from "apps/web/src/pages/Auth/Staff/OrgManagement/SportsPage";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { useUser } from "../../../../utils/tokens";
import { SuperuserRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { OrgGroupsPage } from "./OrgGroupsPage";
import { OrgsPage } from "./OrgsPage";
import { TerritoryPage } from "./TerritoryPage";
import { UserSearchPage } from "./UserSearchPage";

// full page path
export const NAV_STAFF_ORG_MANAGEMENT = "/staff/org-management";

// local child paths
export const NAV_ORG_MANAGEMENT_ORGS = "orgs";
export const NAV_ORG_MANAGEMENT_TERRITORIES = "territories";
export const NAV_ORG_MANAGEMENT_ORG_GROUPS = "org-groups";
export const NAV_ORG_MANAGEMENT_ORGS_SYNC = "orgs-sync";
export const NAV_ORG_MANAGEMENT_USER_SEARCH = "user-search";
export const NAV_ORG_MANAGEMENT_SPORTS = "sports";

// full child paths
export const NAV_STAFF_ORG_MANAGEMENT_ORGS = `${NAV_STAFF_ORG_MANAGEMENT}/${NAV_ORG_MANAGEMENT_ORGS}`;

export const orgManagementLink = {
  name: "Org Management",
  icon: <ContactsIcon />,
  to: NAV_STAFF_ORG_MANAGEMENT,
};

export const orgsLink = {
  name: "Organizations",
  to: NAV_STAFF_ORG_MANAGEMENT_ORGS,
};

const getSecondaryNavLayoutProps = (): SecondaryNavLayoutProps => ({
  current: orgManagementLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Organizations",
      to: NAV_ORG_MANAGEMENT_ORGS,
      requiresSuperuser: true,
    },
    {
      name: "Territories",
      to: NAV_ORG_MANAGEMENT_TERRITORIES,
      requiresSuperuser: true,
    },
    {
      name: "Organizations Sync",
      to: NAV_ORG_MANAGEMENT_ORGS_SYNC,
      requiresSuperuser: true,
    },
    {
      name: "Organization Groups",
      to: NAV_ORG_MANAGEMENT_ORG_GROUPS,
      requiresSuperuser: true,
    },
    {
      name: "Users",
      to: NAV_ORG_MANAGEMENT_USER_SEARCH,
      requiresSuperuser: true,
    },
    {
      name: "Sports",
      to: NAV_ORG_MANAGEMENT_SPORTS,
    },
  ],
});

export const OrgManagementRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...getSecondaryNavLayoutProps()} />}>
      <Route
        index
        element={
          !!useUser()?.isSuperuser ? (
            <SuperuserRoute>
              <OrgsPage />
            </SuperuserRoute>
          ) : (
            <SportsPage />
          )
        }
      />
      <Route
        path={NAV_ORG_MANAGEMENT_TERRITORIES}
        element={
          <SuperuserRoute>
            <TerritoryPage />
          </SuperuserRoute>
        }
      />
      <Route
        path={NAV_ORG_MANAGEMENT_ORGS}
        element={
          <SuperuserRoute>
            <OrgsPage />
          </SuperuserRoute>
        }
      />
      <Route
        path={NAV_ORG_MANAGEMENT_ORGS_SYNC}
        element={
          <SuperuserRoute>
            <OrgsSyncPage />
          </SuperuserRoute>
        }
      />
      <Route
        path={NAV_ORG_MANAGEMENT_ORG_GROUPS}
        element={
          <SuperuserRoute>
            <OrgGroupsPage />
          </SuperuserRoute>
        }
      />
      <Route
        path={NAV_ORG_MANAGEMENT_USER_SEARCH}
        element={
          <SuperuserRoute>
            <UserSearchPage />
          </SuperuserRoute>
        }
      />
      <Route path={NAV_ORG_MANAGEMENT_SPORTS} element={<SportsPage />} />
    </Route>
  </Routes>
);

import { PrintableNutrientInfoMenuItemFragment } from "../../../types";

interface MenuItemPrintWarnings {
  hasNoIngredients: boolean;
  hasAddOnNutrients: boolean;
}

export const getMenuItemPrintWarnings = (menuItem: PrintableNutrientInfoMenuItemFragment): MenuItemPrintWarnings => {
  const hasNoIngredients = menuItem.servingAmounts.length === 0;
  const hasAddOnNutrients = menuItem.choices.flatMap(c => c.options).some(o => o.servingAmounts.length > 0);

  return {
    hasNoIngredients,
    hasAddOnNutrients,
  };
};

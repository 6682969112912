import React from "react";
import { MenuItemAppearancePreviewFragment, useReadonlyMenuItemDialogQuery } from "../../../types";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import DialogContent from "./DialogContent";

interface MenuItemAppearanceReadonlyPanelProps {
  open: boolean;
  onClose: () => void;
  menuItemAppearance: MenuItemAppearancePreviewFragment;
}

const MenuItemAppearanceReadonlyPanel = ({ open, onClose, menuItemAppearance }: MenuItemAppearanceReadonlyPanelProps) => {
  const { data } = useReadonlyMenuItemDialogQuery({
    variables: {
      menuItemId: menuItemAppearance.menuItem.id,
    },
  });

  return !data ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <DialogContent
      open={open}
      onClose={onClose}
      menuItemAppearance={{
        ...menuItemAppearance,
        menuItem: data.menuItem,
      }}
    />
  );
};

export default MenuItemAppearanceReadonlyPanel;

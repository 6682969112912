import { Box, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import CalendarMonth, { CalendarMonthRendDayProps } from "apps/web/src/components/Calendar/Month";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useMealPlanCalendarContext } from "../../../MealPlans/contexts/Calendar";
import { GoalConfigurationFormType } from "../GoalConfigurationSchema";

const useStyles = makeStyles(() =>
  createStyles({
    day: {
      position: "relative",
    },
    baseDot: {
      position: "absolute",
      height: 6,
      width: 6,
      borderRadius: "50%",
      bottom: 2,
    },
    singleDot: {
      left: "calc(50% - 3px)",
    },
    doubleDot1: {
      left: "calc(50% - 7.5px)",
    },
    doubleDot2: {
      left: "calc(50% + 1.5px)",
    },
  })
);

interface GoalDateAssignmentCalendarProps {
  form: UseFormReturn<GoalConfigurationFormType>;
  onClickCalendarDate: (date: string) => void;
}

export const GoalDateAssignmentCalendar = ({ onClickCalendarDate, form }: GoalDateAssignmentCalendarProps) => {
  const classes = useStyles();
  const { startOfMonthDate, onChangeStartOfMonthDate } = useMealPlanCalendarContext();

  const {
    palette: { info },
  } = useTheme();

  const { watch, control } = form;
  const assignments = watch("assignments");
  const individualDates = assignments.type === "individualDates" && assignments.dates ? assignments.dates : [];
  const handleToggleDay = (isoDate: string, onChange: (...event: any[]) => void) => {
    const selected = individualDates.includes(isoDate);
    const newDates = selected ? individualDates.filter(date => date !== isoDate) : [...individualDates, isoDate];
    onChange(newDates);
  };

  const renderDay = (props: PickersDayProps<Date> & CalendarMonthRendDayProps) => {
    const { isoDate, dayText, isCurrentMonth, ...other } = props;
    const selected = individualDates.includes(isoDate);
    const backgroundColor = selected ? info.main : undefined;
    const color = selected ? info.contrastText : undefined;
    const disabled = isoDate < serializeDate(new Date());

    return (
      <Controller
        name={"assignments.dates"}
        control={control}
        render={({ field: { onChange } }) => (
          <Box
            className={classes.day}
            onClick={() => {
              handleToggleDay(isoDate, onChange);
              onClickCalendarDate(isoDate);
            }}
          >
            <PickersDay
              {...other}
              disabled={disabled}
              selected={false}
              sx={{
                backgroundColor,
                color,
                ":hover": {
                  backgroundColor,
                },
                ":focus": {
                  backgroundColor,
                },
              }}
            >
              {dayText}
            </PickersDay>
          </Box>
        )}
      />
    );
  };
  return <CalendarMonth
    renderDay={renderDay}
    calendarDate={startOfMonthDate}
    onChangeCalendarDate={onChangeStartOfMonthDate} />;
};

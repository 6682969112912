import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

import {
  ExchangeMealPlanDisplaySettingsFragment,
  MacroMealPlanDisplaySettingsFragment,
  NoTargetsDisplaySettingsFragment,
} from "../../types";
import { exchangeProgressViewsTypeToName, macroProgressViewsTypeToName } from "./utils";

interface DisplaySettingsPreviewProps {
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment | null;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment | null;
  noTargetsDisplaySettings: NoTargetsDisplaySettingsFragment | null;
}

const DEFAULT_MACRO_PROGRESS_VIEWS = "hideNumbers";
const DEFAULT_EXCHANGE_PROGRESS_VIEWS = "all";

const DisplaySettingsPreview = ({
  macroDisplaySettings: maybeMacroSettings,
  exchangeDisplaySettings: maybeExchangeSettings,
  noTargetsDisplaySettings: maybeNoTargetsSettings,
}: DisplaySettingsPreviewProps) => {
  return (
    <Box sx={{ display: "flex", gridTemplateColumns: `1fr 1fr`, gridTemplateRows: "auto", flexGrow: 1 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">
              <b>Daily</b>
            </TableCell>
            <TableCell align="center">
              <b>Meal</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell scope="row">Macro Meal Plans</TableCell>
            <TableCell align="center">
              {macroProgressViewsTypeToName(maybeMacroSettings?.dailyProgressViews || DEFAULT_MACRO_PROGRESS_VIEWS)}
            </TableCell>
            <TableCell align="center">
              {macroProgressViewsTypeToName(maybeMacroSettings?.mealProgressViews || DEFAULT_MACRO_PROGRESS_VIEWS)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Exchange Meal Plans</TableCell>
            <TableCell align="center">
              {exchangeProgressViewsTypeToName(maybeExchangeSettings?.dailyProgressViewsForExchanges || DEFAULT_EXCHANGE_PROGRESS_VIEWS)}
              <br></br>
              {macroProgressViewsTypeToName(maybeExchangeSettings?.dailyProgressViewsForMacros || DEFAULT_MACRO_PROGRESS_VIEWS)}
            </TableCell>
            <TableCell align="center">
              {exchangeProgressViewsTypeToName(maybeExchangeSettings?.mealProgressViewsForExchanges || DEFAULT_EXCHANGE_PROGRESS_VIEWS)}
              <br></br>
              {macroProgressViewsTypeToName(maybeExchangeSettings?.mealProgressViewsForMacros || DEFAULT_MACRO_PROGRESS_VIEWS)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">No Meal Plan</TableCell>
            <TableCell align="center">
              {macroProgressViewsTypeToName(maybeNoTargetsSettings?.dailyProgressViewsForMacros || DEFAULT_MACRO_PROGRESS_VIEWS)}
            </TableCell>
            <TableCell align="center">
              {macroProgressViewsTypeToName(maybeNoTargetsSettings?.mealProgressViewsForMacros || DEFAULT_MACRO_PROGRESS_VIEWS)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default DisplaySettingsPreview;

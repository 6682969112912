import React from "react";

import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useReducer } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { Food, FoodGroupQuery } from "../../../types";
import FoodGroupFoodTable from "../FoodTable";
import { FoodGroupFormState, foodGroupFormReducer, getCanSaveTooltips } from "./reducer";

type FoodFragment = Pick<Food, "id" | "name">;

interface FoodGroupFormProps {
  foodGroup?: FoodGroupQuery["foodGroup"];
  onSave: (state: FoodGroupFormState) => void;
  onCancel: () => void;
  loading: boolean;
  readOnly?: boolean;
}

const emptyState = {
  foods: [],
  name: "",
  source: "",
  id: undefined,
  addedFoods: [],
  removedFoods: [],
};

const FoodGroupForm = ({ foodGroup, onSave, onCancel, loading, readOnly }: FoodGroupFormProps) => {
  const [state, dispatch] = useReducer(foodGroupFormReducer, {
    ...foodGroup,
    ...emptyState,
  });

  useEffect(() => {
    // We may be waiting on the foodGroup to be loaded from a query
    if (foodGroup !== undefined) {
      dispatch({
        type: "INIT_FROM_QUERY",
        payload: { ...foodGroup, addedFoods: [], removedFoods: [] },
      });
    }
    // eslint-disable-next-line
  }, [foodGroup && foodGroup.id]);

  const canSaveTooltips = getCanSaveTooltips(state);
  return (
    <>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", mt: 2, gap: 2 }}>
          <TextField
            fullWidth
            value={state.name || ""}
            disabled={!!readOnly}
            onChange={e => dispatch({ type: "CHANGE_NAME", payload: e.target.value })}
            inputProps={{
              "aria-label": "Food Group Name",
            }}
            label="Name"
            placeholder="Enter Name"
          />
          <TextField
            fullWidth
            value={state.source || ""}
            disabled={!!readOnly}
            onChange={e => dispatch({ type: "CHANGE_SOURCE", payload: e.target.value })}
            inputProps={{
              "aria-label": "Food Group Source",
            }}
            label="Source"
            placeholder="Enter Source (URL, Reference, etc.)"
          />
        </Box>
        <FoodGroupFoodTable
          readOnly={!!readOnly}
          foods={[...state.foods]}
          loading={loading}
          addFood={(food: FoodFragment) => dispatch({ type: "ADD_FOOD", payload: food })}
          removeFood={(food: FoodFragment) => dispatch({ type: "REMOVE_FOOD", payload: food })}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onCancel()}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <Button disabled={readOnly || canSaveTooltips.length > 0} onClick={() => onSave(state)}>
            Save
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </>
  );
};

export default FoodGroupForm;

import { Box, Select, SelectProps, Theme } from "@mui/material";
import React from "react";

interface SelectWithPlaceHolderProps extends Omit<SelectProps, "placeholder" | "displayEmpty"> {
  placeholderText: string;
  isEmpty: boolean;
}

const SelectWithPlaceHolder = ({ placeholderText, isEmpty, renderValue, ...props }: SelectWithPlaceHolderProps) => {
  return (
    <Select
      {...props}
      sx={{ width: "500px" }}
      displayEmpty
      renderValue={
        isEmpty ? () => <Box sx={({ palette: { greyscale } }: Theme) => ({ color: greyscale[400] })}>{placeholderText}</Box> : renderValue
      }
    />
  );
};

export default SelectWithPlaceHolder;

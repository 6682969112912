import {
  CreateTeamworksSportInput,
  LinkedSportInput,
  PositionWithMappingsFragment,
  SportWithPositionMappingsFragment,
  TeamworksPositionFragment,
} from "../../../../../types";
import { NotemealTeam, TeamworksTeam } from "../Teams/reducer";

export interface BasePendingPosition {
  name: string;
  teamworksId: number;
  teamworksTeamId: number;
}

export interface NewPositionState extends BasePendingPosition {
  __type: "Add";
}

export interface LinkedPositionState extends BasePendingPosition {
  __type: "Link";
  positionId: string;
  teamworksPositionName: string;
}

export type PendingPosition = NewPositionState | LinkedPositionState;

interface BaseSportState {
  name: string;
  newPositions: NewPositionState[];
}

export interface LinkedSportState extends BaseSportState {
  __type: "Link";
  linkedPositions: LinkedPositionState[];
  selectedSport: SportWithPositionMappingsFragment;
}

export interface NewSportState extends BaseSportState {
  __type: "Add";
}

export type IndividualSportState = LinkedSportState | NewSportState;

export interface BaseCombinedSportState {
  sportState: NewSportState | LinkedSportState | null;
}

export interface SportState extends BaseCombinedSportState {
  syncWithoutSport: boolean;
  createNewSport: boolean;
}

interface LinkPositionAction {
  type: "LINK_POSITION_ACTION";
  payload: {
    notemealPosition: PositionWithMappingsFragment;
    teamworksPosition: TeamworksPositionFragment;
  };
}

interface NewPositionAction {
  type: "NEW_POSITION_ACTION";
  payload: {
    teamworksPosition: TeamworksPositionFragment;
  };
}

interface RemovePositionAction {
  type: "REMOVE_POSITION_ACTION";
  payload: {
    position: PendingPosition;
  };
}

interface SelectSportAction {
  type: "SELECT_SPORT_ACTION";
  payload: SportWithPositionMappingsFragment | null;
}

interface NewSportNameAction {
  type: "NEW_SPORT_NAME_ACTION";
  payload: string;
}

interface SetSyncWithoutSportAction {
  type: "SET_SYNC_WITHOUT_SPORT_ACTION";
  payload: boolean;
}

interface SetCreateNewSportAction {
  type: "SET_CREATE_NEW_SPORT_ACTION";
  payload: boolean;
}

interface AutoLinkPositionsAction {
  type: "AUTO_LINK_POSITIONS";
  payload: {
    notemealPositions: readonly PositionWithMappingsFragment[];
    teamworksPositions: readonly TeamworksPositionFragment[];
  };
}

export type SportBuilderAction =
  | LinkPositionAction
  | NewPositionAction
  | SelectSportAction
  | NewSportNameAction
  | SetSyncWithoutSportAction
  | SetCreateNewSportAction
  | RemovePositionAction
  | AutoLinkPositionsAction;

const buildNewPosition = ({ teamworksPosition }: NewPositionAction["payload"]): NewPositionState => ({
  __type: "Add",
  name: teamworksPosition.label,
  teamworksId: teamworksPosition.id,
  teamworksTeamId: teamworksPosition.teamId ?? -1, //TODO: make it not null at some point before
});

const buildLinkedPosition = ({ notemealPosition, teamworksPosition }: LinkPositionAction["payload"]): LinkedPositionState => ({
  __type: "Link",
  positionId: notemealPosition.id,
  name: notemealPosition.name,
  teamworksId: teamworksPosition.id,
  teamworksTeamId: teamworksPosition.teamId ?? -1, //TODO: make it not null at some point before
  teamworksPositionName: teamworksPosition.label,
});

const baseNewSportState: NewSportState = {
  __type: "Add",
  name: "",
  newPositions: [],
};

export const sportBuilderReducer = (state: SportState, action: SportBuilderAction): SportState => {
  switch (action.type) {
    case "NEW_POSITION_ACTION":
      // if syncWithoutSport or state.sportState is null it is invalid op return state
      if (state.syncWithoutSport || !state.sportState) {
        return state;
      }
      return {
        ...state,
        sportState: {
          ...state.sportState,
          newPositions: [...state.sportState.newPositions, buildNewPosition(action.payload)],
        },
      };
    case "LINK_POSITION_ACTION":
      // if sport state is not defined or if it is not a link state it is invalid op return state
      if (state.sportState?.__type !== "Link") {
        return state;
      }
      return {
        ...state,
        sportState: {
          ...state.sportState,
          linkedPositions: [...state.sportState.linkedPositions, buildLinkedPosition(action.payload)],
        },
      };
    case "REMOVE_POSITION_ACTION":
      const position = action.payload.position;
      const sportState = state.sportState;
      // if sport state is null it is invalid op return state
      if (!sportState) {
        return state;
      }
      return {
        ...state,
        sportState: {
          ...sportState,
          ...(sportState.__type === "Link" && position.__type === "Link"
            ? {
                linkedPositions: sportState.linkedPositions.filter(p => p.positionId !== position.positionId),
              }
            : {}),
          ...(position.__type === "Add"
            ? {
                newPositions: sportState.newPositions.filter(p => p.teamworksId !== position.teamworksId),
              }
            : {}),
        },
      };
    case "NEW_SPORT_NAME_ACTION":
      const prevSportState = state.sportState ?? { ...baseNewSportState };
      // if sport state is type Link it is invalid op return state
      if (prevSportState.__type === "Link") {
        return state;
      }

      return {
        ...state,
        sportState: {
          ...prevSportState,
          name: action.payload,
        },
      };
    case "SELECT_SPORT_ACTION":
      // if sport state is type Link it is invalid op return state
      if (state.sportState?.__type === "Add") {
        return state;
      }
      return {
        ...state,
        //TODO: would we want to preserve new/linked positions
        sportState: action.payload ? buildLinkedSport(action.payload) : null,
      };
    // TODO: What state should be reset here?
    case "SET_SYNC_WITHOUT_SPORT_ACTION":
      return {
        ...state,
        syncWithoutSport: action.payload,
        sportState: null,
      };
    case "SET_CREATE_NEW_SPORT_ACTION":
      return {
        ...state,
        createNewSport: action.payload,
        syncWithoutSport: false, // TODO: do we want to auto toggle this?
        // if true set up new sport if false clear state so we can add linked sport
        sportState: action.payload ? { ...baseNewSportState } : null,
      };
    case "AUTO_LINK_POSITIONS":
      // OP can only be performed if sportState is type Link
      if (state.sportState?.__type !== "Link") {
        return state;
      }
      return {
        ...state,
        sportState: {
          ...state.sportState,
          linkedPositions: autoLinkPositions(action.payload),
        },
      };
  }
};

interface SportPayload {
  linkedSport: LinkedSportInput | null;
  newSport: CreateTeamworksSportInput | null;
}

export const mapSportStateToInputs = (combinedSportState: BaseCombinedSportState): SportPayload => {
  const { sportState } = combinedSportState;
  return {
    linkedSport:
      sportState?.__type === "Link"
        ? {
            sportId: sportState.selectedSport.id,
            newPositions: sportState.newPositions.map(({ __type, ...rest }) => ({
              ...rest,
            })),
            linkedPositions: sportState.linkedPositions.map(({ teamworksPositionName, name, __type, ...rest }) => ({
              ...rest,
            })),
          }
        : null,
    newSport:
      sportState?.__type === "Add"
        ? {
            name: sportState.name,
            newPositions: sportState.newPositions.map(({ __type, ...rest }) => ({
              ...rest,
            })),
          }
        : null,
  };
};

const blankInitialState: SportState = {
  sportState: null,
  syncWithoutSport: false,
  createNewSport: false,
};

interface BuildLinkedPositionsArgs {
  notemealPositions: readonly PositionWithMappingsFragment[];
  teamworksPositions: readonly TeamworksPositionFragment[];
}

/**
 * This function attemps to auto link positions by itterating over each
 * teamworksPosition and seeing if its name matches the name or position mappings
 * of exactly one of the notemeal positions. If it does it automatically creates the link.
 *
 */
export const autoLinkPositions = ({ teamworksPositions, notemealPositions }: BuildLinkedPositionsArgs): LinkedPositionState[] => {
  return teamworksPositions.flatMap(teamworksPosition => {
    const possibleLinks = notemealPositions.filter(notemealPosition => {
      return (
        teamworksPosition.label.toLowerCase() === notemealPosition.name.toLowerCase() ||
        notemealPosition.teamworksMappings.map(m => m.teamworksName.toLowerCase()).includes(teamworksPosition.label.toLowerCase())
      );
    });
    if (possibleLinks.length !== 1) {
      return [];
    }
    const notemealPosition = possibleLinks[0];
    return [buildLinkedPosition({ notemealPosition, teamworksPosition })];
  });
};

const buildLinkedSport = (selectedSport: SportWithPositionMappingsFragment): LinkedSportState => {
  return {
    __type: "Link",
    name: selectedSport.name,
    newPositions: [],
    linkedPositions: [],
    selectedSport: selectedSport,
  };
};

const buildSportStateFromInitialSport = (initialSport: SportWithPositionMappingsFragment): SportState => {
  return {
    ...blankInitialState,
    sportState: buildLinkedSport(initialSport),
  };
};

/**
 *
 * This function takes in the Teamworks team we are Adding or Linking,
 * the possible sports, and the Notemeal team to attempt to auto link
 * the team with a sport. First it checks the Teamworks team sport mappings
 * (All sport mappings that match with the sportName) to see if there is a
 * unique sport amoung all the possible mappings. If so it uses that sport.
 * Next it checks the name of the sport against the name of all the possible sports
 * If there is an exact case insenitive match it will use that sport as a match.
 * Finally if there is no match but a Link is occuring it will default to the Notemeal
 * sport if one exists.
 *
 * @param teamworksTeam TeamworksTeam
 * @param sports readonly SportWithPositionMappingsFragment[]
 * @param notemealTeam NotemealTeam | undefied
 * @returns SportState
 */
export const buildInitialSportState = (
  teamworksTeam: TeamworksTeam,
  sports: readonly SportWithPositionMappingsFragment[],
  notemealTeam?: NotemealTeam
): SportState => {
  const uniqueMappings: SportWithPositionMappingsFragment[] = Object.values(
    teamworksTeam.sportMappings.reduce((prevObj, mapping) => {
      return {
        ...prevObj,
        [mapping.sport.id]: mapping.sport,
      };
    }, {})
  );
  if (uniqueMappings.length === 1) {
    return buildSportStateFromInitialSport(uniqueMappings[0]);
  }
  const possibleMatchingTeamworksSport = sports.find(sport => sport.name.toLowerCase() === teamworksTeam.sport.toLowerCase());
  if (possibleMatchingTeamworksSport) {
    return buildSportStateFromInitialSport(possibleMatchingTeamworksSport);
  }
  if (notemealTeam?.sport) {
    return buildSportStateFromInitialSport(notemealTeam.sport);
  }
  return blankInitialState;
};

export const hasExistingSportNameError = (state: SportState, existingSports: readonly SportWithPositionMappingsFragment[]) => {
  const { sportState } = state;
  if (state.createNewSport && sportState?.__type === "Add" && sportState.name) {
    const existingSportNames = existingSports.map(({ name }) => name.trim().toLowerCase());
    return existingSportNames.includes(sportState.name.trim().toLowerCase());
  }
  return false;
};

export const hasValidSubmitState = (state: SportState, existingSports: readonly SportWithPositionMappingsFragment[]) => {
  if (state.syncWithoutSport) {
    return true;
  }
  const { sportState } = state;
  if (state.createNewSport && sportState?.__type === "Add" && sportState.name) {
    return !hasExistingSportNameError(state, existingSports);
  }
  if (!state.createNewSport && sportState?.__type === "Link" && sportState.selectedSport) {
    return true;
  }
  return false;
};

import { subYears } from "date-fns";
import React from "react";
import { loadTsv } from "../../../../../utils/import/load";
import { parseDelimitedFile } from "../../../../../utils/import/parse";
import { IFileImport } from "../../../../universal/Import/Button";
import { ImportableAnthroEntry } from "../types";

interface BoneDensityFileImportFields {
  "Last Name": string;
  "First Name": string;
  "Measure Date": string;
  Height: string;
  Weight: string;
  "Total BMD": number;
  "Total BMC": number;
  "Total Area": number;
  "Total T-Score": number;
  "Total Z-Score": number;
}

type BoneDensityFileImportFieldsRaw = {
  [key in keyof BoneDensityFileImportFields]: string;
};

export const iDXAMatchFields: (keyof ImportableAnthroEntry)[] = ["firstName" as const, "lastName" as const, "datetime" as const];

export const msg = (
  <>
    This tool imports GE Lunar iDXA Bone Density data from the "Total Body" export file. The file may be called "export1_Total Body.txt"
    <br />
    <br />
    <b>NOTE:</b> This importer cannot insert new anthropometry records. Use "GE IDXA (ALL DATA)" to insert new anthropometry records.
  </>
);

type I = ImportableAnthroEntry;
const transformGramsToLbs = (grams: string | null): any => {
  if (grams) {
    const kgString = grams.replace(".", "").replace(",", ".").replace('"', "");
    const ret = parseFloat(kgString) * 2.205;
    return ret;
  } else {
    return null;
  }
};

const numberFields = [
  {
    fields: ["Total BMD" as const],
    required: true,
    importableField: "boneMineralDensity" as const,
  },
  {
    fields: ["Total BMC" as const],
    transform: transformGramsToLbs,
    required: true,
    importableField: "boneMineralContent" as const,
  },
  {
    fields: ["Total Area" as const],
    transform: transformGramsToLbs,
    required: true,
    importableField: "boneArea" as const,
  },
  {
    fields: ["Total T-Score" as const],
    required: false,
    importableField: "boneMineralDensityTScore" as const,
  },
  {
    fields: ["Total Z-Score" as const],
    required: false,
    importableField: "boneMineralDensityZScore" as const,
  },
  {
    fields: ["Height" as const],
    importableField: "height" as const,
    transform: (hgt: string | null) => (hgt ? parseFloat(hgt.replace("in.", "").trim()) : null),
  },
];

const stringFields = [
  {
    fields: ["Last Name" as const],
    required: true,
    importableField: "lastName" as const,
  },
  {
    fields: ["First Name" as const],
    required: true,
    importableField: "firstName" as const,
  },
  // {field: 'Dept ID' as const, importableField: 'teamName' as const},
]; // More on 'as const': https://stackoverflow.com/a/55387357/3923962

const dateFields = [
  {
    fields: ["Measure Date" as const],
    required: true,
    importableField: "datetime" as const,
  },
];
export const fields = [...numberFields, ...stringFields, ...dateFields];

export const loadBoneDensityIdxaRows = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  // type T = BoneDensityFileImportFields;
  type F = BoneDensityFileImportFieldsRaw;
  const rows = await loadTsv<F>({ file, onError });
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return parsedRows
    .filter(row => new Date(row.datetime) > subYears(new Date(), 5))
    .map(r => ({
      ...r,
      type: "dexa",
    }));
};

import { Chip, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
import { NamedTagForSelectionFragment } from "../../types";
import NamedTagChip from "./NamedTagChip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0, 1, 0, 0),
    },
  })
);

const HIDE_AFTER = 2;

interface NamedTagChipListProps {
  namedTags: readonly NamedTagForSelectionFragment[];
  namedTagsCount: number;
  hideAfter?: number;
  chipClassName?: string;
}

const NamedTagChipList = ({ namedTags, namedTagsCount, hideAfter = HIDE_AFTER, chipClassName }: NamedTagChipListProps) => {
  const classes = useStyles();
  // named tags can be shorter than the hideAfter count even if the total count is larger
  const hiddenLength = namedTagsCount - Math.min(namedTags.length, hideAfter);
  return (
    <>
      {namedTags.slice(0, hideAfter).map(namedTag => (
        <NamedTagChip
          size="small"
          key={namedTag.tag.id}
          namedTag={namedTag}
          className={classNames(chipClassName, classes.chip)} />
      ))}
      {hiddenLength > 0 && <Chip
        size="small"
        label={`+${hiddenLength}`}
        className={classNames(chipClassName, classes.chip)} />}
    </>
  );
};

export default NamedTagChipList;

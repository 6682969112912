import { Box } from "@mui/material";
import { useBrowserBackAndRefreshWarning } from "apps/web/src/hooks/useBrowserBackAndRefreshWarning";
import { RecipePrintDialog } from "apps/web/src/views/Recipes/components/Printing/RecipePrintDialog";
import React, { useReducer, useRef, useState } from "react";
import { EditRecipeFullInput, EditRecipePartialInput, RecipeFullFragment } from "../../../types";
import DiscardChangesDialog from "../../universal/DiscardChangesDialog";
import RecipeForm from "../Form/RecipeForm";
import { canSaveRecipeFormTooltips, RecipeFormState } from "../Form/utils";
import { EditRecipeHeader } from "../Header/EditRecipeHeader";
import { RecipeScaleDialog } from "../Scale/RecipeScaleDialog";
import RecipeScaleSuccessAlert from "../Scale/RecipeScaleSuccessAlert";
import { RevisionRecipeDialog } from "./RevisionRecipeDialog";
import { editRecipeReducer, editRecipeStateToInput, recipeToEditState } from "./utils";

interface RecipeEditContentProps {
  onClose: () => void;
  onEditFull: (input: EditRecipeFullInput) => void;
  onEditPartial: (input: EditRecipePartialInput) => void;
  recipe: RecipeFullFragment;
  onDuplicate: () => void;
  forStaff?: boolean;
  readonly?: boolean;
  onScaleAndCreateNewRecipe: (state: RecipeFormState) => void;
}

export const RecipeEditContent = ({
  onClose,
  onEditFull,
  onEditPartial,
  recipe,
  onDuplicate,
  forStaff,
  readonly,
  onScaleAndCreateNewRecipe,
}: RecipeEditContentProps) => {
  const [state, dispatch] = useReducer(editRecipeReducer, recipeToEditState(recipe));
  const { setBrowserBackAndRefreshWarningEnabled } = useBrowserBackAndRefreshWarning();
  const canSaveTooltips = canSaveRecipeFormTooltips(state.form);
  const [showScaleSuccessMessage, setShowScaleSuccessMessage] = useState(false);
  const [openScaleRecipe, setOpenScaleRecipe] = useState(false);
  const [openRecipeUnsavedChangesDialog, setOpenRecipeUnsavedChangesDialog] = useState(false);
  const initialFormState = useRef<RecipeFormState>(state.form);

  const handleSave = () => {
    if (!readonly) {
      const maybeInput = editRecipeStateToInput(state);
      if (maybeInput?.type === "full") {
        onEditFull(maybeInput.input);
      } else if (maybeInput?.type === "partial") {
        onEditPartial(maybeInput.input);
      }
    }
  };

  const cancelRevision = () => dispatch({ type: "CANCEL_REVISION" });

  React.useEffect(() => {
    setBrowserBackAndRefreshWarningEnabled(state.form.edited);
  }, [state.form.edited, setBrowserBackAndRefreshWarningEnabled]);

  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const handleClose = () => {
    if (state.form.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const [printOpen, setPrintOpen] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <EditRecipeHeader
        readonly={readonly}
        handleClose={handleClose}
        setPrintOpen={setPrintOpen}
        handleDuplicate={onDuplicate}
        forStaff={forStaff}
        isShared={state.form.isShared}
        onChange={() =>
          dispatch({
            type: "CHANGE_IS_SHARED",
            value: !state.form.isShared,
          })
        }
        handleSave={handleSave}
        canSaveTooltips={canSaveTooltips}
      />
      <Box>
        <RecipeScaleSuccessAlert open={showScaleSuccessMessage} onClose={() => setShowScaleSuccessMessage(false)} />
        <RecipeForm
          state={state.form}
          dispatch={dispatch}
          sx={{ flexGrow: 1 }}
          disabled={readonly}
          onAiGenerateClicked={null}
          openScaleRecipe={() => {
            if (state.form.edited) {
              setOpenRecipeUnsavedChangesDialog(true);
            } else {
              setOpenScaleRecipe(true);
            }
          }}
          forStaff={forStaff}
        />
      </Box>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={() => {
            setBrowserBackAndRefreshWarningEnabled(false);
            onClose();
          }}
        />
      )}
      <RecipeScaleDialog
        state={state.form}
        initialFormState={initialFormState.current}
        dispatch={dispatch}
        openScaleChangeDialog={openScaleRecipe}
        setOpenScaleChangeDialog={setOpenScaleRecipe}
        openRecipeUnsavedChangesDialog={openRecipeUnsavedChangesDialog}
        setOpenRecipeUnsavedChangesDialog={setOpenRecipeUnsavedChangesDialog}
        onSuccess={setShowScaleSuccessMessage}
        openScaleRecipe={value => setOpenScaleRecipe(value)}
        onScaleAndCreateNewRecipe={onScaleAndCreateNewRecipe}
      />
      {state.pendingRevisionAction !== null && (
        <RevisionRecipeDialog
          open={state.pendingRevisionAction !== null}
          onClose={cancelRevision}
          onSubmit={() =>
            dispatch({
              type: "MAKE_REVISION",
            })
          }
        />
      )}

      {printOpen && <RecipePrintDialog recipeId={state.id} onClose={() => setPrintOpen(false)} />}
    </Box>
  );
};

import { Typography } from "@mui/material";
import React from "react";
import { RecipeWithServingsFragment, ServingAmountWithFoodOrRecipeFragment } from "../../../../types";

interface PrintRecipeIngredientsProps {
  recipe: RecipeWithServingsFragment;
}

const getServingName = (ingredient: ServingAmountWithFoodOrRecipeFragment) => {
  if (ingredient.serving.unit) {
    return ingredient.serving.unit.name;
  }
  if (ingredient.serving.customUnits) {
    return ingredient.serving.customUnits;
  }
  return "";
};

export function PrintRecipeIngredients({ recipe }: PrintRecipeIngredientsProps) {
  return (
    <div>
      <Typography variant="h4" gutterBottom={true}>
        Ingredients
      </Typography>
      {recipe.ingredients.map(ingredient => {
        return (
          <Typography
            key={ingredient.serving.foodOrRecipe.name}
            variant="body2"
            gutterBottom={true}
            fontWeight={ingredient.serving.foodOrRecipe.__typename === "Recipe" ? 600 : undefined}
          >
            {`${ingredient.amount} ${getServingName(ingredient)} ${ingredient.serving.foodOrRecipe.name}`}
          </Typography>
        );
      })}
    </div>
  );
}

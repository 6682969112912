import BarCharIcon from "@mui/icons-material/BarChart";
import { Box, IconButton, Tooltip } from "@mui/material";
import { getServingAmountsMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import React, { useState } from "react";
import { FullServingAmountFragment } from "../../types";
import { useDriParams } from "../../views/Athlete/FoodLogs/contexts/useDriParams";
import { useMacroProgressViews } from "../../views/Athlete/FoodLogs/contexts/useMacroProgressViews";
import { MacrosProgress } from "../MacrosProgress/MacrosProgress";
import NutrientAmountDialog from "../NutrientAmount/Modal";

interface MealMacrosAndNutrientsProps {
  mealServingAmounts: readonly FullServingAmountFragment[];
  targetMacros: Macros | null;
  showNutrientsButton?: boolean;
}

const MealMacrosAndNutrients = ({ mealServingAmounts, targetMacros, showNutrientsButton = true }: MealMacrosAndNutrientsProps) => {
  const macroProgressModes = useMacroProgressViews();
  const [nutrientAmountModalOpen, setNutrientAmountModalOpen] = useState(false);
  const { age, sex, kcal } = useDriParams();

  const currentMacros = getServingAmountsMacros(mealServingAmounts);

  const nutrientsButton = (
    <Tooltip title="View Meal Nutrients">
      <IconButton
        sx={{ width: "20px", height: "20px", color: "accents.yellow.400" }}
        onClick={() => setNutrientAmountModalOpen(true)}
        size="small"
      >
        <BarCharIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <Box sx={{ px: 1, display: "flex", gap: 2, justifyContent: "flex-start", alignItems: "center" }}>
        <MacrosProgress
          variant="sm"
          currentMacros={currentMacros}
          targetMacros={targetMacros}
          macroProgressViews={macroProgressModes} />

        {macroProgressModes === "all" && showNutrientsButton ? nutrientsButton : null}
      </Box>
      {nutrientAmountModalOpen && (
        <NutrientAmountDialog
          sex={sex}
          kcal={kcal}
          age={age}
          open={nutrientAmountModalOpen}
          onClose={() => setNutrientAmountModalOpen(false)}
          servingAmounts={mealServingAmounts}
        />
      )}
    </>
  );
};

export default MealMacrosAndNutrients;

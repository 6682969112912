import { Button, DialogActions, DialogContent } from "@mui/material";
import React, { Dispatch } from "react";
import TWItemizedTooltip from "../../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import LoadingButton from "../../../../../components/universal/LoadingButton";
import { CombinedTagAction, CombinedTagState } from "../../../reducers/combinedTagReducers";
import BaseMutationTabs from "./BaseMutationTabs";

interface BaseMutationContentProps {
  state: CombinedTagState;
  dispatch: Dispatch<CombinedTagAction>;
  onClose: () => void;
  handleSave: () => void;
  loading: boolean;
  canSaveTooltips: string[];
  isTagNameUnique: boolean;
  tagId: string | null;
}

const BaseMutationContent = ({
  state,
  dispatch,
  onClose,
  handleSave: _handleSave,
  loading,
  canSaveTooltips,
  isTagNameUnique,
  tagId,
}: BaseMutationContentProps) => {
  const handleSave = canSaveTooltips.length === 0 ? _handleSave : () => {};
  return (
    <>
      <DialogContent sx={{ pt: 0 }}>
        <BaseMutationTabs
          state={state}
          dispatch={dispatch}
          isTagNameUnique={isTagNameUnique}
          tagId={tagId} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <LoadingButton
            onClick={handleSave}
            loading={loading}
            buttonText="Save"
            disabled={canSaveTooltips.length > 0} />
        </TWItemizedTooltip>
      </DialogActions>
    </>
  );
};

export default BaseMutationContent;

import { FullServingAmountFragment, FullServingFragment } from "../types";
import { useState } from "react";
import { sortByKey } from "@notemeal/utils/sort";
import { ExchangeTarget, ServingAmountWithExchangeAmount } from "./useExchangeTargets";

interface usePickListControllerArgs {
  exchangeTarget: ExchangeTarget;
}

interface usePickListControllerPayload {
  onIncrement: (serving: FullServingFragment) => void;
  onDecrement: (serving: FullServingFragment) => void;
  servingAmounts: readonly ServingAmountWithExchangeAmount[];
  pickList: readonly ServingAmountForPickList[];
  exchangeAmountTotal: number;
}

interface ServingAmountForPickList extends FullServingAmountFragment {
  fromPickList: boolean;
}

type ServingExchangeAmount =
  | {
      fromPickList: true;
      data: {
        serving: FullServingFragment;
        exchangeAmount: number;
      };
    }
  | {
      fromPickList: false;
      data: {
        servingAmount: ServingAmountWithExchangeAmount;
        included: boolean;
      };
    };

const INCREMENT_AMOUNT = 0.5;

export const usePickListController = ({ exchangeTarget }: usePickListControllerArgs): usePickListControllerPayload => {
  const pickListServingIds = exchangeTarget.pickList.map(sa => sa.serving.id);
  const [servingExchangeAmounts, setServingExchangeAmounts] = useState<ServingExchangeAmount[]>(
    exchangeTarget.servingAmountsWithExchangeAmount.map(sa => {
      if (pickListServingIds.includes(sa.serving.id)) {
        return {
          fromPickList: true,
          data: {
            serving: sa.serving,
            exchangeAmount: sa.exchangeAmount,
          },
        };
      } else {
        return {
          fromPickList: false,
          data: {
            servingAmount: sa,
            included: true,
          },
        };
      }
    })
  );

  const initSelectedServingIds = exchangeTarget.servingAmountsWithExchangeAmount.map(sa => sa.serving.id);
  const notPickListInitSelected = exchangeTarget.servingAmountsWithExchangeAmount
    .filter(sa => !pickListServingIds.includes(sa.serving.id))
    .map(sa => ({ ...sa, fromPickList: false }));
  const pickListInitSelected = exchangeTarget.pickList
    .filter(sa => initSelectedServingIds.includes(sa.serving.id))
    .map(sa => ({ ...sa, fromPickList: true }));
  const pickListNotInitSelected = exchangeTarget.pickList
    .filter(sa => !initSelectedServingIds.includes(sa.serving.id))
    .map(sa => ({ ...sa, fromPickList: true }));
  const pickList: readonly ServingAmountForPickList[] = [
    ...sortByKey([...notPickListInitSelected, ...pickListInitSelected], "position"),
    ...sortByKey(pickListNotInitSelected, "position"),
  ].map((sa, position) => {
    return {
      ...sa,
      position,
    };
  });

  const servingAmounts: readonly ServingAmountWithExchangeAmount[] = servingExchangeAmounts.flatMap(esa => {
    if (!esa.fromPickList) {
      return !esa.data.included
        ? {
            ...esa.data.servingAmount,
            amount: 0,
            exchangeAmount: 0,
          }
        : esa.data.servingAmount;
    }
    const matchingServingAmount = exchangeTarget.pickList.find(sa => sa.serving.id === esa.data.serving.id);
    if (!matchingServingAmount) {
      return [];
    }
    return {
      ...matchingServingAmount,
      exchangeAmount: esa.data.exchangeAmount,
      amount: matchingServingAmount.amount * esa.data.exchangeAmount,
    };
  });

  const exchangeAmountTotal = servingAmounts.reduce((sum, next) => sum + next.exchangeAmount, 0);

  const onIncrement = (serving: FullServingFragment) => {
    const matchingServingAmount = servingExchangeAmounts.find(sea => {
      return getServingExchangeAmountServingId(sea) === serving.id;
    });
    const nonMatchingServingAmounts = servingExchangeAmounts.filter(sea => {
      return getServingExchangeAmountServingId(sea) !== serving.id;
    });

    if (matchingServingAmount) {
      if (matchingServingAmount.fromPickList) {
        const newExchangeAmount =
          matchingServingAmount.data.exchangeAmount === 0
            ? Math.max(exchangeTarget.amount - exchangeAmountTotal, 1)
            : matchingServingAmount.data.exchangeAmount + INCREMENT_AMOUNT;
        setServingExchangeAmounts([
          ...nonMatchingServingAmounts,
          {
            fromPickList: true,
            data: {
              serving,
              exchangeAmount: newExchangeAmount,
            },
          },
        ]);
      } else {
        setServingExchangeAmounts([
          ...nonMatchingServingAmounts,
          {
            fromPickList: false,
            data: {
              servingAmount: matchingServingAmount.data.servingAmount,
              included: true,
            },
          },
        ]);
      }
    } else {
      const newExchangeAmount = Math.max(exchangeTarget.amount - exchangeAmountTotal, 1);
      setServingExchangeAmounts([
        ...nonMatchingServingAmounts,
        {
          fromPickList: true,
          data: {
            serving,
            exchangeAmount: newExchangeAmount,
          },
        },
      ]);
    }
  };

  const onDecrement = (serving: FullServingFragment) => {
    const matchingServingAmount = servingExchangeAmounts.find(sea => {
      return getServingExchangeAmountServingId(sea) === serving.id;
    });
    const nonMatchingServingAmounts = servingExchangeAmounts.filter(sea => {
      return getServingExchangeAmountServingId(sea) !== serving.id;
    });

    if (matchingServingAmount) {
      if (matchingServingAmount.fromPickList) {
        const newExchangeAmount = Math.max(matchingServingAmount.data.exchangeAmount - INCREMENT_AMOUNT, 0);
        setServingExchangeAmounts([
          ...nonMatchingServingAmounts,
          {
            fromPickList: true,
            data: {
              serving,
              exchangeAmount: newExchangeAmount,
            },
          },
        ]);
      } else {
        setServingExchangeAmounts([
          ...nonMatchingServingAmounts,
          {
            fromPickList: false,
            data: {
              servingAmount: matchingServingAmount.data.servingAmount,
              included: false,
            },
          },
        ]);
      }
    } else {
      setServingExchangeAmounts(nonMatchingServingAmounts);
    }
  };

  return {
    servingAmounts,
    pickList,
    onDecrement,
    onIncrement,
    exchangeAmountTotal,
  };
};

const getServingExchangeAmountServingId = (servingExchangeAmount: ServingExchangeAmount): string => {
  return servingExchangeAmount.fromPickList ? servingExchangeAmount.data.serving.id : servingExchangeAmount.data.servingAmount.serving.id;
};

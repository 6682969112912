import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { getTotalPercentConsumedForMenuSelectionItem } from "@notemeal/shared/ui/MenuSelectionItem/utils";
import React from "react";
import { MenuSelectionItemFormFragment } from "../../types";
import { MultiColorDial } from "../MacrosProgress/Dials/MultiColorDial";
interface MenuItemPercentConsumedProps {
  menuSelectionItem: MenuSelectionItemFormFragment;
  readonly: boolean;
}

const formatPercent = (value: number) => value * 100;

export const PercentConsumedDial = ({ menuSelectionItem, readonly }: MenuItemPercentConsumedProps) => {
  const {
    palette: { info },
  } = useTheme();

  const weightedAverage = getTotalPercentConsumedForMenuSelectionItem(menuSelectionItem);

  if (weightedAverage === null) {
    return <NAProgressDial />;
  }
  return (
    <MultiColorDial
      label=""
      hideNumbers={true}
      variant="xs"
      current={formatPercent(weightedAverage) || 0}
      target={100}
      color={info} />
  );
};

const NAProgressDial = () => {
  return (
    <div>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr", margin: 4 }}>
        <CircularProgress
          sx={{ gridColumnStart: 1, gridRowStart: 1, color: "info.dark" }}
          variant="determinate"
          value={100}
          thickness={3}
          size={40}
        />
        <Box
          sx={{
            gridColumnStart: 1,
            gridRowStart: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "text.primary",
          }}
          aria-hidden="true"
        >
          <Typography variant="subtitle1">{"N/A"}</Typography>
        </Box>
      </Box>
    </div>
  );
};

import React from "react";
import {
  AthleteSummaryAthleteFragment,
  useAddAthleteDislikedFoodGroupMutation,
  useAddAthleteDislikedFoodMutation,
  useAddAthleteLikedFoodGroupMutation,
  useAddAthleteLikedFoodMutation,
  useRemoveAthleteDislikedFoodGroupMutation,
  useRemoveAthleteDislikedFoodMutation,
  useRemoveAthleteLikedFoodGroupMutation,
  useRemoveAthleteLikedFoodMutation,
} from "../../../../types";

import { Typography } from "@mui/material";
import {
  AddOrRemoveFoodGroupPreferenceProps,
  AddOrRemoveFoodPreferenceProps,
  FoodPreferenceForm,
} from "../../../../components/FoodPreference/Form";

export const makeFoodMutationInputs = (athleteId: string, foodId: string) => ({
  variables: { input: { athleteId, foodId } },
});

export const makeFoodGroupMutationInputs = (athleteId: string, foodGroupId: string) => ({
  variables: { input: { athleteId, foodGroupId } },
});

export const inputToFoodsQueryVariables = (inputValue: string) => ({
  searchTerm: inputValue,
  limit: 5,
});

export const inputToFoodGroupsQueryVariables = (inputValue: string) => ({
  searchTerm: inputValue,
  limit: 5,
});

interface AthleteSummaryFoodPreferencesProps {
  athlete: AthleteSummaryAthleteFragment;
}

export type FoodPreferenceType = "Food" | "FoodGroup";

const AthleteSummaryFoodPreferences = ({ athlete }: AthleteSummaryFoodPreferencesProps) => {
  // Remove Mutation Hooks
  const [removeLikedFoodMutation] = useRemoveAthleteLikedFoodMutation({});
  const [removeLikedFoodGroupMutation] = useRemoveAthleteLikedFoodGroupMutation({});
  const [removeDislikedFoodMutation] = useRemoveAthleteDislikedFoodMutation({});
  const [removeDislikedFoodGroupMutation] = useRemoveAthleteDislikedFoodGroupMutation({});
  // Add Mutation Hooks
  const [addLikedFoodMutation] = useAddAthleteLikedFoodMutation({});
  const [addLikedFoodGroupMutation] = useAddAthleteLikedFoodGroupMutation({});
  const [addDislikedFoodMutation] = useAddAthleteDislikedFoodMutation({});
  const [addDislikedFoodGroupMutation] = useAddAthleteDislikedFoodGroupMutation({});
  //
  // Remove Handlers
  //
  const removeLikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    removeLikedFoodMutation(makeFoodMutationInputs(id, food.id));
  };
  const removeDislikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    removeDislikedFoodMutation(makeFoodMutationInputs(id, food.id));
  };
  const removeLikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    removeLikedFoodGroupMutation(makeFoodGroupMutationInputs(id, foodGroup.id));
  };
  const removeDislikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    removeDislikedFoodGroupMutation(makeFoodGroupMutationInputs(id, foodGroup.id));
  };
  const removeActions = {
    removeLikedFood,
    removeDislikedFood,
    removeLikedFoodGroup,
    removeDislikedFoodGroup,
  };
  //
  // Add Handlers
  //
  const addLikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    addLikedFoodMutation(makeFoodMutationInputs(id, food.id));
  };
  const addDislikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    addDislikedFoodMutation(makeFoodMutationInputs(id, food.id));
  };
  const addLikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    addLikedFoodGroupMutation(makeFoodGroupMutationInputs(id, foodGroup.id));
  };
  const addDislikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    addDislikedFoodGroupMutation(makeFoodGroupMutationInputs(id, foodGroup.id));
  };
  const addActions = {
    addLikedFood,
    addDislikedFood,
    addLikedFoodGroup,
    addDislikedFoodGroup,
  };
  const { likedFoods, dislikedFoods, likedFoodGroups, dislikedFoodGroups } = athlete;
  return (
    <>
      <Typography variant="h3">Food Preferences</Typography>
      <FoodPreferenceForm
        type="Athlete"
        preferrerId={athlete.id}
        preferences={{
          likedFoodGroups,
          dislikedFoodGroups,
          likedFoods,
          dislikedFoods,
        }}
        actions={{ ...addActions, ...removeActions }}
      />
    </>
  );
};

export default AthleteSummaryFoodPreferences;

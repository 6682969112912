import { Typography } from "@mui/material";
import React from "react";
import { RecipeWithServingsFragment } from "../../../../types";

interface PrintRecipeDirectionsProps {
  recipe: RecipeWithServingsFragment;
}

export function PrintRecipeDirections({ recipe }: PrintRecipeDirectionsProps) {
  if (!recipe.steps) {
    return null;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom={true}>
        Directions
      </Typography>
      {recipe.steps.map((step, i) => (
        <Typography
          key={step}
          variant="body2"
          gutterBottom={true}>
          {i + 1}. {step}
        </Typography>
      ))}
    </div>
  );
}

import { AnthropometryImportTabPanelProps } from "apps/web/src/components/views/Import/Anthropometry/AnthropometryTabPanel";
import { fields as bodPodFields, msg as bodPodMsg, loadBodPodRows } from "../../../../components/views/Import/Anthropometry/BodPod";
import { fields as allFields, msg as allIdxaMsg, loadAllIdxaRows } from "../../../../components/views/Import/Anthropometry/GE/all";
import {
  fields as boneDensityFields,
  msg as boneDensityMsg,
  loadBoneDensityIdxaRows,
} from "../../../../components/views/Import/Anthropometry/GE/boneDensity";
import {
  loadMostRecentIdxaRows,
  fields as mostRecentFields,
  msg as mostRecentIdxaMsg,
} from "../../../../components/views/Import/Anthropometry/GE/mostRecent";
import { fields as generalFields, msg as generalMsg, loadAnthroEntries } from "../../../../components/views/Import/Anthropometry/General";
import { fields as inbodyFields, msg as inbodyMsg, loadInbodyRows } from "../../../../components/views/Import/Anthropometry/InBody";
import { loadSecaRows, fields as secaFields, msg as secaMsg } from "../../../../components/views/Import/Anthropometry/SECA";

export const GENERAL = "General (.csv)";
export const GE_IDXA_ALL = "GE iDXA (all data)";
export const GE_IDXA_MOST_RECENT = "GE iDXA (most recent)";
export const BOD_POD = "BodPod";
export const GE_IDXA_BONE_DENSITY = "GE iDXA (Bone Density)";
export const SECA = "SECA";
export const INBODY = "Inbody 770";
export const anthroImportTabs = [GENERAL, GE_IDXA_ALL, GE_IDXA_MOST_RECENT, BOD_POD, GE_IDXA_BONE_DENSITY, SECA, INBODY];

export type AnthroImportType =
  | typeof GENERAL
  | typeof GE_IDXA_ALL
  | typeof GE_IDXA_MOST_RECENT
  | typeof BOD_POD
  | typeof GE_IDXA_BONE_DENSITY
  | typeof SECA
  | typeof INBODY;

const general: AnthropometryImportTabPanelProps = {
  matchFields: ["firstName", "lastName", "datetime"],
  linkFields: ["sex", "athleteId", "teamName", "firstName", "lastName", "mostRecentHeight"],
  linkOnFields: ["firstName", "lastName"],
  loadAndParse: loadAnthroEntries,
  msg: generalMsg,
  fields: [...generalFields],
};

const geIdxaAll: AnthropometryImportTabPanelProps = {
  matchFields: ["lastName", "firstName", "datetime"],
  linkFields: ["sex", "athleteId", "teamName", "firstName", "lastName", "mostRecentHeight"],
  linkOnFields: ["lastName", "firstName"],
  loadAndParse: loadAllIdxaRows,
  msg: allIdxaMsg,
  fields: allFields,
};

const geIdxaMostRecent: AnthropometryImportTabPanelProps = {
  matchFields: ["lastName", "firstName", "datetime"],
  linkFields: ["sex", "athleteId", "teamName", "firstName", "lastName", "mostRecentHeight"],
  linkOnFields: ["lastName", "firstName"],
  loadAndParse: loadMostRecentIdxaRows,
  msg: mostRecentIdxaMsg,
  fields: mostRecentFields,
};

const bodPod: AnthropometryImportTabPanelProps = {
  matchFields: ["lastName", "firstName", "datetime"],
  linkFields: ["sex", "athleteId", "teamName", "firstName", "lastName", "mostRecentHeight"],
  linkOnFields: ["lastName", "firstName"],
  loadAndParse: loadBodPodRows,
  msg: bodPodMsg,
  fields: bodPodFields,
};

const geIdxaBoneDensity: AnthropometryImportTabPanelProps = {
  matchFields: ["lastName", "firstName", "datetime"],
  linkFields: ["sex", "athleteId", "teamName", "firstName", "lastName", "mostRecentHeight"],
  linkOnFields: ["lastName", "firstName"],
  loadAndParse: loadBoneDensityIdxaRows,
  msg: boneDensityMsg,
  fields: boneDensityFields,
};

const seca: AnthropometryImportTabPanelProps = {
  matchFields: ["secaUid", "datetime"],
  linkFields: ["sex", "athleteId", "teamName", "firstName", "lastName", "mostRecentHeight", "secaUid"],
  linkOnFields: ["secaUid"],
  loadAndParse: loadSecaRows,
  msg: secaMsg,
  fields: secaFields,
};

const inbody: AnthropometryImportTabPanelProps = {
  matchFields: ["inbodyUid", "datetime"],
  linkFields: ["sex", "athleteId", "teamName", "firstName", "lastName", "mostRecentHeight", "inbodyUid"],
  linkOnFields: ["inbodyUid"],
  loadAndParse: loadInbodyRows,
  msg: inbodyMsg,
  fields: inbodyFields,
};

export const getAnthroImportProps = (importType: AnthroImportType): AnthropometryImportTabPanelProps => {
  let anthroImportProps: AnthropometryImportTabPanelProps;

  switch (importType) {
    case GENERAL:
      anthroImportProps = general;
      break;
    case GE_IDXA_ALL:
      anthroImportProps = geIdxaAll;
      break;
    case GE_IDXA_MOST_RECENT:
      anthroImportProps = geIdxaMostRecent;
      break;
    case BOD_POD:
      anthroImportProps = bodPod;
      break;
    case GE_IDXA_BONE_DENSITY:
      anthroImportProps = geIdxaBoneDensity;
      break;
    case SECA:
      anthroImportProps = seca;
      break;
    case INBODY:
      anthroImportProps = inbody;
      break;
    default:
      throw new Error("unknown anthro import type");
  }

  return anthroImportProps;
};

import { Paper, SxProps, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import React, { CSSProperties } from "react";
import { MealFragment } from "../types";
import { formatEventTime } from "../utils/dateTimes";

const useStyles = makeStyles(({ palette: { greyscale } }: Theme) => ({
  mealItemContainer: {
    backgroundColor: greyscale[25],
    padding: 10,
    margin: 10,
  },
  denseMealItemContainer: {
    backgroundColor: greyscale[25],
    padding: 0,
    margin: 3,
    textAlign: "center",
  },
  denseSnackItemContainer: {
    backgroundColor: greyscale[25],
    padding: 0,
    margin: 3,
    textAlign: "center",
  },
  exportedMealItemContainer: {
    backgroundColor: greyscale[25],
    marginTop: `5px`,
    marginBottom: `5px`,
    padding: `5px`,
  },
  exportedSnackItemContainer: {
    backgroundColor: greyscale[25],
    marginTop: `5px`,
    marginBottom: `5px`,
    padding: `5px`,
  },
}));

interface MealItemContainerProps {
  children?: React.ReactNode;
  className?: string;
  sx?: SxProps;
  style?: CSSProperties;
}

export const MealItemContainer = ({ children, className, sx, style }: MealItemContainerProps) => {
  const classes = useStyles();
  return (
    <Paper sx={sx} className={classnames(classes.mealItemContainer, className)} style={style}>
      {children}
    </Paper>
  );
};

const DenseMealItemContainer = ({ children }: MealItemContainerProps) => {
  const classes = useStyles();
  return <Paper className={classes.denseMealItemContainer}>{children}</Paper>;
};

const DenseSnackItemContainer = ({ children }: MealItemContainerProps) => {
  const classes = useStyles();
  return <Paper className={classes.denseSnackItemContainer}>{children}</Paper>;
};

export const ExportedMealItemContainer = ({ children }: MealItemContainerProps) => {
  const classes = useStyles();
  return <Paper className={classes.exportedMealItemContainer}>{children}</Paper>;
};

export const ExportedSnackItemContainer = ({ children }: MealItemContainerProps) => {
  const classes = useStyles();
  return <Paper className={classes.exportedSnackItemContainer}>{children}</Paper>;
};

interface MealItemProps {
  meal: MealFragment;
  className?: string;
}

export const MealItem = ({ meal, className }: MealItemProps) => {
  return (
    <MealItemContainer className={className}>
      <Typography align="center">
        {meal.name} ({formatEventTime(meal)})
      </Typography>
    </MealItemContainer>
  );
};

export const DenseMealItem = ({ meal }: MealItemProps) => {
  return meal.type === "meal" ? (
    <DenseMealItemContainer>
      <Typography variant="subtitle1" align="center">
        {meal.name} ({formatEventTime(meal)})
      </Typography>
    </DenseMealItemContainer>
  ) : (
    <DenseSnackItemContainer>
      <Typography variant="subtitle1" align="center">
        {meal.name} ({formatEventTime(meal)})
      </Typography>
    </DenseSnackItemContainer>
  );
};

import Loading from "@notemeal/shared/ui/global/Loading";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import React, { useState } from "react";
import OrgFoodImportTabPanel from "../../../../components/views/Import/Food/OrgFoodTabPanel";
import { fields, loadFoodEntriesFromCSV, loadFoodEntriesFromExcel } from "../../../../components/views/Import/Food/loadFile";
import { useUnitsQuery } from "../../../../types";

export const ImportFoodPage = () => {
  const EXCEL = "General (.xlsx)";
  const CSV = "General (.csv)";
  const [selected, setSelected] = useState(EXCEL);
  const tabs = [EXCEL, CSV];

  const { data: unitsData } = useUnitsQuery();

  return !unitsData ? (
    <Loading />
  ) : (
    <>
      <TWTabGroup tabs={tabs} onSelected={selected => setSelected(selected)} />
      <OrgFoodImportTabPanel
        matchFields={["name"]}
        loadAndParse={selected === EXCEL ? loadFoodEntriesFromExcel : loadFoodEntriesFromCSV}
        fields={fields}
        description={selected === EXCEL ? "Imports foods from an excel file." : "Imports foods from a CSV file."}
        units={unitsData.units}
      />
    </>
  );
};

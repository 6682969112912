import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";

interface ReclaimAthleteModalProps {
  onClose: () => void;
  open: boolean;
  onReclaim: () => void;
}

export const ReclaimAthleteModal = ({ onClose, open, onReclaim }: ReclaimAthleteModalProps) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Reclaim Athlete" onClose={onClose} />
      <DialogContent sx={{ paddingBottom: theme => theme.spacing(5) }}>
        <Typography variant="body2">
          The athlete is currently available in the Transfer Portal but has not yet been claimed by another org. Would you like to reclaim
          this athlete and remove them from the Transfer Portal?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onReclaim}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

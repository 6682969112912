import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { sortByKey } from "@notemeal/utils/sort";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { FullSportFragment } from "libs/shared/ui/src/lib/types";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { getNavOrgAthleteMealPlansMealPlan } from "../../../pages/Auth/Org/Athlete/AthletePaths";
import { PreviewMealPlanFragment } from "../../../types";

const useStyles = makeStyles({
  card: {
    minWidth: 300,
    maxWidth: 500,
    marginLeft: 24,
    marginTop: 20,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface MealPlanEmptyProps {
  athleteId: string;
  teamId: string;
  teamName: string;
  sport: FullSportFragment | null;
  mealPlans: readonly PreviewMealPlanFragment[];
  onNew: () => void;
  onCopy: () => void;
}

const MealPlanEmpty = ({ athleteId, teamId, teamName, sport, mealPlans, onCopy, onNew }: MealPlanEmptyProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const sortedMealPlans = sortByKey(
      mealPlans.map(({ updatedAt, ...mp }) => ({
        ...mp,
        updatedAt,
        updatedAtDt: new Date(updatedAt).getTime(),
      })),
      "updatedAtDt"
    );

    const mostRecentMealPlan = sortedMealPlans.slice(-1)[0] || null;
    if (mostRecentMealPlan) {
      navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, mostRecentMealPlan.id), { replace: true });
    }
  });

  return (
    <div className={classes.root}>
      <Box sx={{ ml: 3, mt: 3 }}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          Create Meal Plan
        </Typography>
        <Typography variant="body1">
          The athlete does not have any meal plans yet. Create one today with one of the following options!
        </Typography>
      </Box>

      <Card className={classes.card} variant="outlined">
        <CardActionArea
          onClick={() => {
            trackEvent("Nutrition | Team | Athlete | Build First Meal Plan", { athleteId, teamId, teamName, sport });
            onNew();
          }}
        >
          <CardContent>
            <Typography variant="h3">Build a New Meal Plan</Typography>
            <Typography variant="body1">Start from scratch and build a new meal plan.</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card className={classes.card} variant="outlined">
        <CardActionArea
          onClick={() => {
            trackEvent("Nutrition | Team | Athlete | Copy First Meal Plan", { athleteId, teamId, teamName, sport });
            onCopy();
          }}
        >
          <CardContent>
            <Typography variant="h3">Copy an Existing Meal Plan</Typography>
            <Typography variant="body1">Use an existing meal plan template as a starting point.</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default MealPlanEmpty;

import { MenuItem, Typography } from "@mui/material";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import LabeledSelect from "apps/web/src/components/universal/LabeledSelect";
import React, { useState } from "react";
import { ScoringSystemFragment, useScoringSystemsQuery } from "../../../../types";

interface ScoringSystemSelectProps {
  scoringSystem: ScoringSystemFragment | null;
  setScoringSystem: (scoringSystem: ScoringSystemFragment | null) => void;
}

const ScoringSystemSelect = ({ scoringSystem, setScoringSystem }: ScoringSystemSelectProps) => {
  const { data } = useScoringSystemsQuery();
  const [scoreDialogOpen, setScoreDialogOpen] = useState(false);

  return (
    <>
      <LabeledSelect
        placeholder="Scoring System"
        options={data ? [...data.scoringSystems] : []}
        selectedOption={scoringSystem}
        optionToName={ss => ss.name}
        onChange={e => setScoringSystem(e)}
        textFieldProps={{
          fullWidth: true,
        }}
      >
        <MenuItem
          onClick={() => {
            setScoreDialogOpen(true);
          }}
        >
          <Typography>None</Typography>
        </MenuItem>
      </LabeledSelect>
      {
        <ConfirmationDialog
          title="Remove Score"
          open={scoreDialogOpen}
          message="Are you sure you want to remove the score? The score will no longer be visible to the org but the data will be saved in case the org wants to re-enable at a later time."
          onConfirm={() => {
            setScoringSystem(null);
            setScoreDialogOpen(false);
          }}
          onCancel={() => setScoreDialogOpen(false)}
        />
      }
    </>
  );
};

export default ScoringSystemSelect;

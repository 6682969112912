import { Box, SxProps, Theme, Typography } from "@mui/material";
import { AnthropometryEntrySnapshot } from "@notemeal/shared/ui/AnthropometryEntry/types";
import { useAnthropometryFormatting } from "@notemeal/shared/ui/contexts/useAnthropometryFormatting";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import React from "react";

interface AnthropometryDisplayCardProps {
  onClick: () => void;
  anthropometryEntrySnapshot: AnthropometryEntrySnapshot;
  title: string;
  showPercentBodyFat: boolean;
  showLeanBodyMass: boolean;
  isSelected: boolean;
}

export const AnthropometryDisplayCard = ({
  anthropometryEntrySnapshot,
  onClick,
  showPercentBodyFat,
  showLeanBodyMass,
  isSelected,
  title,
}: AnthropometryDisplayCardProps) => {
  const { formatDateWithLocale } = useDateFormatting();
  const { formatHeight, formatWeight, extractValuesFromAnthropometryEntrySnapshot } = useAnthropometryFormatting();

  const { weight, leanBodyMass, percentBodyFat, height, datetime } =
    extractValuesFromAnthropometryEntrySnapshot(anthropometryEntrySnapshot);

  const cardStyles: SxProps<Theme> = ({ palette: { info, greyscale } }) =>
    isSelected
      ? {
          border: `2px solid ${info.main}`,
          backgroundColor: info.lighter,
          p: "15px",
        }
      : {
          border: `1px solid ${greyscale[400]}`,
          p: 2,
        };

  return (
    <Box
      sx={theme => ({ display: "flex", flexDirection: "column", p: 2, minWidth: "236px", borderRadius: 1, ...cardStyles(theme) })}
      onClick={() => onClick()}
    >
      <Typography variant="body1Medium" sx={{ pb: 1.5 }}>
        {title}
      </Typography>
      <Typography variant="body1">Date: {formatDateWithLocale(datetime)}</Typography>
      <Typography variant="body1">Height: {height ? formatHeight(height) : "none"}</Typography>
      <Typography variant="body1">Weight: {formatWeight(weight)}</Typography>
      {showPercentBodyFat && <Typography variant="body1">Percent Body Fat: {percentBodyFat ? `${percentBodyFat}%` : "none"}</Typography>}
      {showLeanBodyMass && <Typography variant="body1">Lean Mass: {leanBodyMass ? formatWeight(leanBodyMass) : "none"}</Typography>}
    </Box>
  );
};

import CloseIcon from "@mui/icons-material/Close";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { Box, Checkbox, FormControlLabel, IconButton, TextField, Tooltip } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import MenuItemAppearanceEditCards from "../../../components/MenuItemAppearance/EditCards";
import MenuItemAppearanceReorderDialog from "../../../components/MenuItemAppearance/ReorderDialog";
import { MenuItemAppearanceGroupAction } from "../../../components/MenuItemAppearance/reducer";
import { MenuOrderItemCounts } from "../../../components/MenuItemAppearance/utils";
import { MenuDialogAction } from "../reducer";
import { MealMenuDiningStationState } from "./types";
import { idArraysAreEqual } from "./utils";

interface MealMenuDiningStationFormProps {
  usedMenuItemIds: readonly string[];
  mealMenuDiningStation: MealMenuDiningStationState;
  orderItemCounts: MenuOrderItemCounts;
  dispatch: (action: MenuDialogAction) => void;
}

const propsAreEqual = (props: MealMenuDiningStationFormProps, prevProps: MealMenuDiningStationFormProps): boolean => {
  if (!idArraysAreEqual(props.usedMenuItemIds, prevProps.usedMenuItemIds)) {
    return false;
  } else {
    return (
      props.dispatch === prevProps.dispatch &&
      props.orderItemCounts === prevProps.orderItemCounts &&
      props.mealMenuDiningStation === prevProps.mealMenuDiningStation
    );
  }
};

const MealMenuDiningStationForm = ({
  mealMenuDiningStation,
  usedMenuItemIds,
  orderItemCounts,
  dispatch,
}: MealMenuDiningStationFormProps) => {
  const [reordering, setReordering] = useState(false);

  const onChangeName = (name: string) =>
    dispatch({
      type: "MenuDiningStationChangeNameAction",
      payload: {
        name,
        mealMenuDiningStationId: mealMenuDiningStation.id,
      },
    });

  const onChangeMaxAmount = (maxAmount: number | null) =>
    dispatch({
      type: "MenuDiningStationChangeMaxAmountAction",
      payload: {
        maxAmount,
        mealMenuDiningStationId: mealMenuDiningStation.id,
      },
    });

  const onChangeMenuItemAppearances = useCallback(
    (action: MenuItemAppearanceGroupAction) =>
      dispatch({
        ...action,
        type: action.type,
        payload: {
          ...action.payload,
          mealMenuDiningStationId: mealMenuDiningStation.id,
        },
      } as MenuDialogAction),
    [mealMenuDiningStation.id, dispatch]
  );

  const onRemove = () =>
    dispatch({
      type: "RemoveMenuDiningStationAction",
      payload: {
        mealMenuDiningStationId: mealMenuDiningStation.id,
      },
    });

  return (
    <Box sx={{ p: 2, border: "1px lightgrey solid", display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            sx={{ width: 400 }}
            label="Dining Station Name"
            value={mealMenuDiningStation.name}
            onChange={e => onChangeName(e.target.value)}
            autoFocus={!mealMenuDiningStation.name}
          />
          <FormControlLabel
            sx={{ alignSelf: "flex-end", mr: 0 }}
            label="Limit per Order"
            labelPlacement="end"
            control={
              <Checkbox checked={mealMenuDiningStation.maxAmount !== null} onChange={e => onChangeMaxAmount(e.target.checked ? 1 : null)} />
            }
          />
          {mealMenuDiningStation.maxAmount !== null && (
            <TextField
              sx={{ width: 110 }}
              label="Max per Order"
              type="number"
              inputProps={{
                min: 1,
              }}
              value={mealMenuDiningStation.maxAmount}
              onChange={e => onChangeMaxAmount(parseInt(e.target.value))}
              error={isNaN(mealMenuDiningStation.maxAmount)}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", gap: 0 }}>
          <Tooltip title="Re-order menu items">
            <IconButton onClick={() => setReordering(true)}>
              <LowPriorityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove dining station from menu">
            <IconButton onClick={onRemove}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <MenuItemAppearanceEditCards
        usedMenuItemIds={usedMenuItemIds}
        variant="MealMenu"
        state={mealMenuDiningStation.menuItemAppearances}
        dispatch={onChangeMenuItemAppearances}
        orderItemCounts={orderItemCounts}
      />
      {reordering && (
        <MenuItemAppearanceReorderDialog
          open={reordering}
          onClose={() => setReordering(false)}
          menuItemAppearances={mealMenuDiningStation.menuItemAppearances}
          onDone={menuItemAppearances =>
            onChangeMenuItemAppearances({
              type: "ReorderMenuItemsAction",
              payload: { menuItemAppearances },
            })
          }
        />
      )}
    </Box>
  );
};

export default memo(MealMenuDiningStationForm, propsAreEqual);

import { MealMenuAttendeeNotemealAthlete, MealMenuAttendeeNonNotemealAthlete } from "@notemeal/graphql/types";
import { AthleteForMenuAttendanceFragment } from "apps/web/src/types";

type CheckedInNotemealAthletePayload = (Pick<MealMenuAttendeeNotemealAthlete, "checkedIn"> & {
  checkInDisplayDate: string | null;
  athlete: AthleteForMenuAttendanceFragment;
  hasAccess: boolean | null;
})[];

type CheckedInNonNotemealAthletePayload = Omit<MealMenuAttendeeNonNotemealAthlete, "checkInDate"> & {
  checkInDisplayDate: string | null;
};

export interface MenuAttendanceRowInput {
  id: string;
  fullName: string;
  teamId: string;
  teamName: string;
  position: string;
  positionId: string;
  checkedIn: boolean;
  hubCheckInDate: string | null;
  checkInDisplayDate: string | null;
  unlisted: boolean;
  hasAccess: boolean;
}

export const MealMenuAttendeesToAttendanceRows = ({
  notemealAttendees,
  nonNotemealAttendees,
  formatFn,
}: {
  formatFn: (date: string) => string;
  notemealAttendees: CheckedInNotemealAthletePayload;
  nonNotemealAttendees: CheckedInNonNotemealAthletePayload[];
}) => {
  const nonNotemealAttendeeRowInputs: MenuAttendanceRowInput[] = nonNotemealAttendees.map(
    ({ firstName, lastName, checkedIn, checkInDisplayDate }) => ({
      id: "",
      fullName: `${lastName}, ${firstName}`,
      teamId: "",
      teamName: "",
      position: "",
      positionId: "",
      checkedIn,
      checkInDisplayDate: checkInDisplayDate ? formatFn(checkInDisplayDate) : checkInDisplayDate,
      hubCheckInDate: checkInDisplayDate,
      unlisted: true,
      hasAccess: false,
    })
  );

  const notemealAttendeeRowInputs = notemealAttendees.map(({ athlete, checkedIn, checkInDisplayDate, hasAccess }) => ({
    id: athlete.id,
    fullName: `${athlete.lastName}, ${athlete.firstName}`,
    teamId: athlete.team.id,
    teamName: athlete.team.name,
    position: athlete.position ? athlete.position?.name : "",
    positionId: athlete.position ? athlete.position?.id : "",
    checkedIn,
    hubCheckInDate: checkInDisplayDate,
    checkInDisplayDate: checkInDisplayDate ? formatFn(checkInDisplayDate) : checkInDisplayDate,
    unlisted: false,
    hasAccess: hasAccess === true,
  }));

  return [...nonNotemealAttendeeRowInputs, ...notemealAttendeeRowInputs];
};

import { useApolloClient } from "@apollo/client";
import React from "react";
import { useDeprecateRestaurantBrandedFoodMutation, useRestaurantEditableFoodsOffsetConnectionQuery } from "../../../types";
import BaseFoodTable, {
  RenderFormModalArgs,
  UseFoodDataOffsetConnectionArgs,
  UseFoodDataOffsetConnectionPayload,
} from "../Table/BaseFoodTable";
import { RestaurantFoodModal } from "./RestaurantFoodModal";

interface RestaurantFoodDashboardProps {
  restaurantId: string;
}

export const RestaurantFoodDashboard = ({ restaurantId }: RestaurantFoodDashboardProps) => {
  const apolloClient = useApolloClient();

  const useFoodDataOffsetConnection = ({ limit, offset, query }: UseFoodDataOffsetConnectionArgs): UseFoodDataOffsetConnectionPayload => {
    const { data, loading, error } = useRestaurantEditableFoodsOffsetConnectionQuery({
      variables: { pagination: { limit, offset }, query, restaurantId },
      fetchPolicy: "network-only",
    });

    return {
      foods: data?.restaurantFoodOffsetConnection.edges,
      pageInfo: data?.restaurantFoodOffsetConnection.pageInfo,
      loading,
      error,
    };
  };

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "restaurantFoodOffsetConnection",
      broadcast: true,
    });
  };

  const [deleteFood] = useDeprecateRestaurantBrandedFoodMutation({
    onCompleted: () => {
      resetTable();
    },
  });

  const onRemoveFood = (id: string) => {
    deleteFood({
      variables: {
        input: { id, restaurantId },
      },
    });
  };

  const renderFoodModal = ({ initialFormState, onClose, isOpen, resetTable: _resetTable }: RenderFormModalArgs) => {
    return (
      <RestaurantFoodModal
        initialFormState={initialFormState}
        onClose={onClose}
        open={isOpen}
        resetTable={_resetTable}
        restaurantId={restaurantId}
      />
    );
  };

  return (
    <BaseFoodTable
      resetTable={resetTable}
      useFoodDataOffsetConnection={useFoodDataOffsetConnection}
      onRemoveFood={onRemoveFood}
      renderFoodModal={renderFoodModal}
      mode="restaurant"
      tableMode="restaurant"
      restaurantId={restaurantId}
      showTitle={false}
    />
  );
};

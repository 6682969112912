import { reduceStatuses } from "@notemeal/shared/ui/MenuOrder/status";
import React from "react";
import { KdsPageMenuOrderPreviewFragment, useKdsTicketsMenuOrderQuery } from "../../../types";
import { WebPrinterTicketOrder } from "../types";
import { getMenuOrderUpdatedAt } from "../utils";
import { BaseKdsTicket } from "./BaseKdsTicket";
import { convertToOrderWithDiningStationItems } from "./utils";

interface KdsTicketProps {
  menuOrderItemIdsToDiningStationName: Record<string, string>;
  diningStationIds: string[];
  orderPreview: KdsPageMenuOrderPreviewFragment;
  setSelectedWebPrinterTicketOrder: (o: WebPrinterTicketOrder) => void;
  inBulkSelect: boolean;
  selectForBulkSelect: (orderId: string, value: boolean) => void;
  isSelected: boolean;
}
const KdsTicket = ({
  orderPreview,
  diningStationIds,
  menuOrderItemIdsToDiningStationName,
  setSelectedWebPrinterTicketOrder,
  inBulkSelect,
  selectForBulkSelect,
  isSelected,
}: KdsTicketProps) => {
  const orderStatus = reduceStatuses(orderPreview.itemsByDiningStation.map(i => i.status));
  //const [runQuery, {data, loading}] = useKdsTicketsMenuOrderLazyQuery({
  const { data, loading } = useKdsTicketsMenuOrderQuery({
    variables: { menuOrderId: orderPreview.id, diningStationIds },
    //fetchPolicy: 'network-only'
    // TODO: We are going to be issuing too many requests if we set this to network-only
    // ...there is an off-chance that when a ticket is re-rendered it has stale choices,
    // but we are relying on the `subscription` to handle updates. This is a compromise.
  });
  const menuOrderWithItems =
    data && data.menuOrder
      ? convertToOrderWithDiningStationItems({
          order: data.menuOrder,
          menuOrderItemIdsToDiningStationName,
        })
      : null;

  const menuOrderUpdatedAt = data && getMenuOrderUpdatedAt(data.menuOrder, false);
  return (
    <BaseKdsTicket
      athlete={data?.menuOrder.athlete}
      orderStatus={orderStatus}
      orderPreview={orderPreview}
      setSelectedWebPrinterTicketOrder={setSelectedWebPrinterTicketOrder}
      inBulkSelect={inBulkSelect}
      selectForBulkSelect={selectForBulkSelect}
      isSelected={isSelected}
      menuOrderUpdatedAt={menuOrderUpdatedAt}
      orderWithItems={menuOrderWithItems}
      loading={loading}
      bulkOrderCode={null}
    />
  );
};

export default KdsTicket;

import { MacroMealPlanDisplaySettingsFragment, ExchangeMealPlanDisplaySettingsFragment, NoTargetsDisplaySettingsFragment } from "../types";

export const DEFAULT_EXCHANGE_DISPLAY_SETTINGS: ExchangeMealPlanDisplaySettingsFragment = {
  __typename: "ExchangeMealPlanDisplaySettings",
  dailyProgressViewsForMacros: "hideNumbers",
  mealProgressViewsForMacros: "hideNumbers",
  dailyProgressViewsForExchanges: "all",
  mealProgressViewsForExchanges: "all",
};

export const DEFAULT_MACRO_DISPLAY_SETTINGS: MacroMealPlanDisplaySettingsFragment = {
  __typename: "MacroMealPlanDisplaySettings",
  dailyProgressViews: "hideNumbers",
  mealProgressViews: "hideNumbers",
};

export const DEFAULT_NO_TARGETS_DISPLAY_SETTINGS: NoTargetsDisplaySettingsFragment = {
  dailyProgressViewsForMacros: "hideNumbers",
  mealProgressViewsForMacros: "hideNumbers",
};

export interface ExchangeMealPlanLegacyDisplaySettings {
  __typename: "ExchangeMealPlanLegacyDisplaySettings";
  displayMealOptions: boolean;
  displayMealOptionMacros: boolean;
  displayDailyMacros: boolean;
  displayMealMacros: boolean;
  displayDailyExchanges: boolean;
  displayMealExchanges: boolean;
  displayExchangeLists: boolean;
}

export const initialExchangeMealPlanLegacyDisplaySettings: ExchangeMealPlanLegacyDisplaySettings = {
  __typename: "ExchangeMealPlanLegacyDisplaySettings",
  displayMealOptions: true,
  displayMealOptionMacros: true,
  displayDailyMacros: true,
  displayMealMacros: true,
  displayDailyExchanges: true,
  displayMealExchanges: true,
  displayExchangeLists: true,
};

export interface MacroMealPlanLegacyDisplaySettings {
  __typename: "MacroMealPlanLegacyDisplaySettings";
  displayMealOptions: boolean;
  displayMealOptionMacros: boolean;
  displayDailyMacros: boolean;
  displayMealMacros: boolean;
}

export const initialMacroMealPlanLegacyDisplaySettings: MacroMealPlanLegacyDisplaySettings = {
  __typename: "MacroMealPlanLegacyDisplaySettings",
  displayMealOptions: true,
  displayMealOptionMacros: true,
  displayDailyMacros: true,
  displayMealMacros: true,
};

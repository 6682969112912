/// <reference types="w3c-web-usb" />
/*
 ************************************************************************************
 * Copyright (C) 2019 Openbravo S.L.U.
 * Licensed under the Apache Software License version 2.0
 * You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to  in writing,  software  distributed
 * under the License is distributed  on  an  "AS IS"  BASIS,  WITHOUT  WARRANTIES  OR
 * CONDITIONS OF ANY KIND, either  express  or  implied.  See  the  License  for  the
 * specific language governing permissions and limitations under the License.
 ************************************************************************************
 */

import { PrintChunk, arrays8print } from "./printerchunk";
import { WebDevice } from "./PrinterType";

export interface USBPrinterType {
  vendorId: number;
  productId: number;
}

export class USB implements WebDevice {
  printertype: USBPrinterType;
  device: USBDevice | null;
  locked: boolean;

  constructor(printertype: USBPrinterType) {
    this.printertype = printertype;
    this.device = null;
    this.locked = false;

    if (navigator.usb && navigator.usb.addEventListener) {
      navigator.usb.addEventListener("disconnect", async (event: USBConnectionEvent) => {
        if (event.device === this.device) {
          await this.onDisconnected();
        }
      });
    }
  }

  connected(): boolean {
    return this.device !== null;
  }

  async request(): Promise<void> {
    if (!navigator.usb || !navigator.usb.requestDevice) {
      alert("USB not supported.");
    }

    this.device = await navigator.usb.requestDevice({
      filters: [
        {
          vendorId: this.printertype.vendorId,
          productId: this.printertype.productId,
        },
      ],
    });
  }

  async sendData(data: Uint8Array): Promise<void> {
    if (!this.device) {
      alert("Device is not connected.");
      return;
    }
    if (this.locked) {
      console.log("Locked! Sleeping for 500ms...");
      setTimeout(async () => await this.sendData(data), 500);
      return;
    } else {
      try {
        this.locked = true;
        await this.device.open();
        await this.device.selectConfiguration(1);
        await this.device.claimInterface(0);
        await arrays8print(this.printChunk(), 64, data);
      } catch (e) {
        console.error(e);
      } finally {
        await this.device.releaseInterface(0);
        await this.device.close();
        this.locked = false;
        console.log("Done sending data");
      }
    }
  }

  printChunk(): PrintChunk {
    return async (chunk: Uint8Array) => {
      if (this.device === null) {
        alert("Device is null");
        return;
      }
      await this.device.transferOut(1, chunk.buffer);
      // "2" for Brother..
      // https://download.brother.com/welcome/docp100306/cv_ql820_eng_escp_101.pdf
    };
  }

  async onDisconnected(): Promise<void> {
    await this.device?.close();
    this.device = null;
  }
}

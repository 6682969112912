import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactNode } from "react";

import { sortByKey } from "@notemeal/utils/sort";
import { sortExchangeFn } from "../../Exchange/utils";
import Carousel from "../../global/Carousel";
import { MealItemContainer } from "../../Meal";
import { formatMeal } from "../../Meal/utils";

import { ExchangeAmountFragment, ExportMealTemplateFragment } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: `100%`,
      boxSizing: "border-box",
      margin: 0,
    },
  })
);

interface MealTemplateViewProps<T extends ExportMealTemplateFragment> {
  meal: T["meal"];
  containerWidth: number;
  // containerHeight: number;
  mealOptions?: T["mealOptions"];
  renderMealOption?: (mo: T["mealOptions"][0]) => ReactNode;
  exchangeTargets?: ExchangeAmountFragment[];
  renderExchangeTarget?: (list: ExchangeAmountFragment) => ReactNode;
  label?: ReactNode;
}

const MealTemplateView = <T extends ExportMealTemplateFragment>({
  meal,
  label,
  containerWidth,
  mealOptions = [],
  renderMealOption = () => null,
  exchangeTargets = [],
  renderExchangeTarget = () => null,
}: MealTemplateViewProps<T>) => {
  const classes = useStyles();

  // Public meal plan query does not hit local resolvers, so we calculate the macros for that case
  // getServingMacros returns macros on serving if present, so don't have to worry about this being expensive in the other case.
  const sortedMealOptions = sortByKey(mealOptions, "position").map(mo => ({
    ...mo,
    servingAmounts: mo.servingAmounts.map(sa => ({
      ...sa,
      serving: {
        ...sa.serving,
      },
    })),
  }));
  const sortedExchangeTargets = exchangeTargets.sort(({ exchange: e1 }, { exchange: e2 }) => sortExchangeFn(e1, e2));

  return (
    <MealItemContainer className={classes.root}>
      <div>
        <Typography variant="body1Medium" color="inherit">
          {formatMeal(meal)}
        </Typography>
        {label}
      </div>
      {!!sortedMealOptions.length && <Carousel items={sortedMealOptions} renderItem={renderMealOption} width={containerWidth} />}
      {!!sortedExchangeTargets.length && (
        <Carousel items={sortedExchangeTargets} renderItem={renderExchangeTarget} width={containerWidth} />
      )}
    </MealItemContainer>
  );
};

export default MealTemplateView;

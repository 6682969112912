import { Button, Dialog, DialogActions, DialogContent, Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import {
  ExchangeMealPlanDisplaySettingsFragment,
  FullMealPlanFragment,
  MacroMealPlanDisplaySettingsFragment,
  useTextMealPlanToAthleteMutation,
} from "../../../../types";
import AddPhoneNumberOrEmailDialog from "../../../global/AddPhoneNumberOrEmailDialog";
import MealPlanViewPreview from "../../View/Preview";
import ToolbarSingleMobile from "../Toolbar/SingleMobile";
import { mutationResultToStatus } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    container: {
      padding: "10px",
      display: "flex",
      flexDirection: "row",
      backgroundColor: grey[300],
      maxHeight: "100%",
      overflow: "auto",
    },
    leftModeButton: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    rightModeButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    pdfOrMobileDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: spacing(2),
    },
  })
);

interface MealPlanExportModalSingleMobileProps {
  open: boolean;
  onClose: () => void;
  mealPlan: FullMealPlanFragment;
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
}

const MealPlanExportModalSingleMobile = ({
  mealPlan,
  open,
  onClose,
  macroDisplaySettings,
  exchangeDisplaySettings,
}: MealPlanExportModalSingleMobileProps) => {
  const classes = useStyles();

  const [phoneNumberDialogOpen, setPhoneNumberDialogOpen] = useState(false);

  const [textMealPlanToAthlete, textMealPlanToAthleteStatus] = useTextMealPlanToAthleteMutation({
    variables: {
      input: {
        mealPlanId: mealPlan.id,
      },
    },
  });

  const textButtonStatus = mutationResultToStatus(textMealPlanToAthleteStatus);

  const handleClickText = () => {
    if (mealPlan.athlete.phoneNumber === null) {
      setPhoneNumberDialogOpen(true);
    } else {
      textMealPlanToAthlete();
    }
  };

  const contentComponent = (
    <Paper
      elevation={15}
      style={{
        overflowY: "auto",
        overflowX: "auto",
      }}
    >
      <MealPlanViewPreview
        mealPlan={mealPlan}
        macroDisplaySettings={macroDisplaySettings}
        exchangeDisplaySettings={exchangeDisplaySettings}
      />
    </Paper>
  );

  const toolbarComponent = (
    <ToolbarSingleMobile
      onClickText={handleClickText}
      textButtonStatus={textButtonStatus}
      mealPlanId={mealPlan.id}
      macroDisplaySettings={macroDisplaySettings}
      exchangeDisplaySettings={exchangeDisplaySettings}
      type={mealPlan.type}
    />
  );

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}>
      <DialogTitle title="Send Meal Plan" onClose={onClose} />
      <DialogContent>
        <Paper elevation={1} className={classes.container}>
          {contentComponent}
          <div>{toolbarComponent}</div>
          {phoneNumberDialogOpen && (
            <AddPhoneNumberOrEmailDialog
              open={phoneNumberDialogOpen}
              onClose={() => setPhoneNumberDialogOpen(false)}
              onAdd={textMealPlanToAthlete}
              athleteId={mealPlan.athlete.id}
              type="phoneNumber"
            />
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealPlanExportModalSingleMobile;

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import FoodPreferenceMealPlanForm from "./Form";
import { FoodPreferenceState, foodPreferenceFormToSaveTooltips, foodPreferenceReducer } from "./reducer";

interface FoodPreferenceModalProps {
  open: boolean;
  onClose: () => void;
  initialFoodPreferenceState: FoodPreferenceState;
  onDone: (newState: FoodPreferenceState) => void;
}

const FoodPreferenceModal = ({ open, onClose, initialFoodPreferenceState, onDone }: FoodPreferenceModalProps) => {
  const [foodPreferenceFormState, foodPreferenceFormDispatch] = useReducer(foodPreferenceReducer, initialFoodPreferenceState);
  const canSaveTooltips = foodPreferenceFormToSaveTooltips(foodPreferenceFormState);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Promoted / Avoided Foods" onClose={onClose} />
      <DialogContent>
        <FoodPreferenceMealPlanForm state={foodPreferenceFormState} dispatch={foodPreferenceFormDispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
          <Button onClick={() => onDone(foodPreferenceFormState)}>Done</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default FoodPreferenceModal;

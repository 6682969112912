import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { Button, IconButton, InputAdornment, TableCell, TableRow, TableSortLabel, TextField, Tooltip } from "@mui/material";
import { sortFnByKey } from "@notemeal/utils/sort";
import { getRowsForTablePage, useOffsetPagination } from "apps/web/src/utils/pagination";
import React, { Fragment, useState } from "react";
import { BrandFragment } from "../../../../types";
import TablePage from "../../../universal/TablePage";

interface BrandTableProps {
  rows: BrandFragment[];
  onRowAdd: (row: Pick<BrandFragment, "name" | "usdaManufacturerName">) => Promise<void>;
  onRowUpdate: (newRow: BrandFragment) => Promise<void>;
}

const BrandTable = ({ rows: brands, onRowAdd, onRowUpdate }: BrandTableProps) => {
  const paginationHooks = useOffsetPagination();
  const { limit, page, queryText, onChangeQueryText } = paginationHooks;
  const [sortOrderAscending, setSortOrderAscending] = useState(true);
  const filterRow = (row: BrandFragment) => row.name.toLowerCase().indexOf(queryText.toLowerCase()) !== -1;
  const getSortOrder = () => (sortOrderAscending ? "asc" : "desc");
  const toggleSortOrder = () => setSortOrderAscending(!sortOrderAscending);
  const rows = brands.sort(sortFnByKey("name", { reverse: !sortOrderAscending })).filter(filterRow) || [];
  const pageRows = getRowsForTablePage({ rows, limit, page });
  const [adding, setAdding] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [brandName, setBrandName] = useState<string | null>(null);
  const [manufacturerName, setManufacturerName] = useState<string | null>(null);

  const startEdit = (editingId: string) => {
    const editRow = pageRows.find(brand => brand.id === editingId);
    if (editRow) {
      setBrandName(editRow.name);
      setManufacturerName(editRow.usdaManufacturerName || null);
      setEditingId(editingId);
    }
  };

  const saveEdit = () => {
    if (editingId && brandName) {
      const editRow = pageRows.find(brand => brand.id === editingId);
      if (editRow && (editRow.name !== brandName || editRow?.usdaManufacturerName !== manufacturerName)) {
        onRowUpdate({ ...editRow, name: brandName, usdaManufacturerName: manufacturerName });
      }
    } else if (brandName) {
      onRowAdd({ name: brandName, usdaManufacturerName: manufacturerName });
    }

    cancelEdit();
  };

  const cancelEdit = () => {
    setEditingId(null);
    setBrandName(null);
    setManufacturerName(null);
    setAdding(false);
  };

  const editRow = (
    <TableRow>
      <TableCell>
        <Tooltip title="Ok">
          <IconButton size="small" onClick={saveEdit}>
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton size="small" onClick={cancelEdit}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell>
        <TextField
          placeholder="Brand Name"
          value={brandName}
          onChange={e => setBrandName(e.target.value)} />
      </TableCell>
      <TableCell>
        <TextField
          placeholder="USDA Manufacturer Name"
          value={manufacturerName}
          onChange={e => setManufacturerName(e.target.value)} />
      </TableCell>
    </TableRow>
  );

  const header = (
    <>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Clear">
                <IconButton onClick={() => onChangeQueryText("")} size="large">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        placeholder="Search"
        value={queryText}
        onChange={e => onChangeQueryText(e.target.value)}
      />
      <Button size="large" onClick={() => setAdding(true)}>
        Add Brand
      </Button>
    </>
  );

  const tableHeaderRow = (
    <>
      <TableRow>
        <TableCell>
          <b>Actions</b>
        </TableCell>
        <TableCell>
          <TableSortLabel direction={getSortOrder()} onClick={toggleSortOrder}>
            <b>Name</b>
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <b>USDA Manufacturer Name</b>
        </TableCell>
        <TableCell>
          <b># Foods</b>
        </TableCell>
      </TableRow>
    </>
  );

  const getTableRow = (brand: BrandFragment) => (
    <Fragment key={brand.id}>
      <TableRow>
        <TableCell>
          <Tooltip title="Edit">
            <IconButton size="small" onClick={() => startEdit(brand.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>{brand.name}</TableCell>
        <TableCell>{brand.usdaManufacturerName}</TableCell>
        <TableCell>{brand.foodCount}</TableCell>
      </TableRow>
    </Fragment>
  );

  const getTableRows = () => (
    <>
      {adding && editRow}
      {pageRows.map(brand => (editingId === brand.id ? editRow : getTableRow(brand)))}
    </>
  );

  return (
    <TablePage
      header={header}
      tableHeaderRow={tableHeaderRow}
      tableBodyRows={getTableRows()}
      loading={false}
      paginationHooks={paginationHooks}
      total={brands.length}
    />
  );
};

export default BrandTable;

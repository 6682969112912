import { Reference } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { useForm } from "react-hook-form";
import { useCreateGoalMutation } from "../../../types";
import Form from "../Form";
import { GoalSchema, GoalType, goalFormDefaultValues, goalFormToCreateInput } from "../Form/GoalFormSchema";

interface GoalCreateModalProps {
  athleteId: string;
  open: boolean;
  onClose: () => void;
}

const GoalCreateModal = ({ athleteId, open, onClose }: GoalCreateModalProps) => {
  const form = useForm<GoalType>({
    defaultValues: goalFormDefaultValues(),
    resolver: zodResolver(GoalSchema()),
  });

  const hasFormErrors = form.formState.isSubmitted && !form.formState.isValid;

  const [createGoal] = useCreateGoalMutation({
    update: (cache, { data }) => {
      if (data) {
        const __ref = cache.identify(data.createGoal.goal);
        if (__ref) {
          cache.modify({
            id: `Athlete:${athleteId}`,
            fields: {
              currentGoal: (): Reference => ({
                __ref,
              }),
            },
          });
        }
      }
    },
  });

  const submit = (goal: GoalType) => {
    const { type, start } = goal;
    if (type && start) {
      createGoal({
        variables: {
          input: goalFormToCreateInput({ ...goal, start, type }, athleteId),
        },
      });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Create New Weight Target" onClose={onClose} />
      <DialogContent sx={{ width: 500 }}>
        <Form form={form} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
          <Button onClick={form.handleSubmit(submit)}>Save</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default GoalCreateModal;

import { MenuOrderItemWithAppearance } from "@notemeal/shared/ui/MenuOrder/utils";
import { useState } from "react";
import { MenuItemAppearancePreviewFragment } from "../../types";

export type MenuOrderDialogMenuState =
  | {
      type: "newMenuItem";
      menuItemAppearance: MenuItemAppearancePreviewFragment;
      forOrder: boolean;
      newMenuOrderItem: MenuOrderItemWithAppearance | null;
      currentAmountForDiningStation: number;
      maxAmountForDiningStation: number | null;
    }
  | {
      type: "editMenuItem";
      menuOrderItem: MenuOrderItemWithAppearance;
    }
  | {
      type: "mealMenuDiningStations";
      forOrder: boolean;
    };

// TODO: Could evolve into a reducer if that'd be helpful
export const useMenuState = (startState?: MenuOrderDialogMenuState) => {
  return useState<MenuOrderDialogMenuState | null>(startState ? startState : null);
};

import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { MealPlanConflictsQuery, MealPlanConflictsQueryVariables } from "apps/web/src/types";
import React, { useState } from "react";
import { MealPlanCalendarState } from "./types";
import { MealPlanDateAssignmentConflictResolution, getDateAssignmentConflicts, getMealPlanName } from "./utils";

interface MealPlanCalendarConflictDialogProps {
  state: MealPlanCalendarState;
  conflictsData: MealPlanConflictsQuery;
  conflictsVariables: MealPlanConflictsQueryVariables;
  open: boolean;
  onClose: () => void;
  onDone: (conflictResolutions: readonly MealPlanDateAssignmentConflictResolution[]) => void;
}

const MealPlanCalendarConflictDialog = ({
  onDone,
  onClose,
  open,
  state,
  conflictsData,
  conflictsVariables,
}: MealPlanCalendarConflictDialogProps) => {
  const [conflicts, setConflicts] = useState<readonly MealPlanDateAssignmentConflictResolution[]>(
    getDateAssignmentConflicts(state, conflictsData).map(c => ({ ...c, override: c.initOverride }))
  );

  const allChecked = conflicts.every(c => c.override);
  const indeterminate = conflicts.some(c => c.override === true) && conflicts.some(c => c.override === false);

  const onToggleConflict = (conflict: MealPlanDateAssignmentConflictResolution) => {
    const overRiddenConflicts = conflicts.map(c => (c !== conflict ? c : { ...c, override: !c.override }));
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Conflict Override | Select Weekday", { overRiddenConflicts });
    setConflicts(overRiddenConflicts);
  };

  const onToggleSelectAll = () => {
    const allConflictsOverRidden = conflicts.map(c => ({ ...c, override: c.override }));
    const updatedConflicts = allConflictsOverRidden.map(c => ({ ...c, override: !allChecked }));
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Conflict Override | Override Select All", {
      overRiddenConflicts: updatedConflicts,
    });
    setConflicts(updatedConflicts);
  };

  const onlyConflictingMealPlanName = getOnlyConflictingMealPlanName(conflicts);
  const daysText = conflicts.every(c => c.conflicting.dayOfWeekPriority) ? "day(s) of the week" : "day(s)";

  const { formatDateWithLocale } = useDateFormatting();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Conflicting Meal Plans" onClose={onClose} />
      <DialogContent>
        <Typography sx={{ mb: 0 }}>
          <b>{getMealPlanName(state)}</b> conflicts with <b>{onlyConflictingMealPlanName ?? "other meal plan(s)"}</b> on the following{" "}
          {daysText} from{" "}
          <b>
            {formatDateWithLocale(conflictsVariables.start)}
            {conflictsVariables.end && ` - ${formatDateWithLocale(conflictsVariables.end)}`}.
          </b>
        </Typography>

        <Typography sx={{ mb: 0 }}>
          If you would like to proceed with overriding <b>{onlyConflictingMealPlanName ?? "the meal plan(s)"}</b>, choose the {daysText}{" "}
          that you would like to override.
        </Typography>
        <FormGroup>
          <FormControlLabel
            label="Select All"
            sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            control={<Checkbox
              onChange={onToggleSelectAll}
              checked={allChecked}
              indeterminate={indeterminate} />}
          />
          {conflicts.map(conflict => (
            <ConflictCheckbox
              displayMealPlanName={!onlyConflictingMealPlanName}
              conflict={conflict}
              onToggle={() => onToggleConflict(conflict)}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Conflict Override | Cancel", {});
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDone(conflicts);
            trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Conflict Override | Save", {});
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealPlanCalendarConflictDialog;

const ConflictCheckbox = ({
  conflict,
  onToggle,
  displayMealPlanName,
}: {
  conflict: MealPlanDateAssignmentConflictResolution;
  onToggle: () => void;
  displayMealPlanName: boolean;
}) => {
  const { formatDateWithLocale } = useDateFormatting();
  const mealPlanNameText = displayMealPlanName ? ` (${conflict.mealPlan.name})` : "";
  if (conflict.conflicting.dayOfWeekPriority) {
    return (
      <FormControlLabel
        key={`${conflict.mealPlan.id}-${conflict.conflicting.dayOfWeekPriority.dayOfWeek}`}
        // TODO: Is there a better function for this already aka formatDayOfWeek?
        label={`${capitalize(conflict.conflicting.dayOfWeekPriority.dayOfWeek)}${mealPlanNameText}`}
        onChange={onToggle}
        control={<Checkbox checked={conflict.override} />}
      />
    );
  } else {
    return (
      <FormControlLabel
        key={`${conflict.mealPlan.id}-${conflict.conflicting.date}`}
        label={`${formatDateWithLocale(conflict.conflicting.date)}${mealPlanNameText}`}
        onChange={onToggle}
        control={<Checkbox checked={conflict.override} />}
      />
    );
  }
};

const getOnlyConflictingMealPlanName = (conflicts: readonly MealPlanDateAssignmentConflictResolution[]): string | null | undefined => {
  const uniqueMealPlans = new Set(conflicts.map(c => c.mealPlan.id));
  if (uniqueMealPlans.size === 1) {
    return conflicts[0]?.mealPlan.name;
  }
  return null;
};

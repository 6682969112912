import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Box, Card, Typography } from "@mui/material";
import { formatEventTime } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { ActivityFragment } from "../../types";

interface ActivityProps {
  activity: ActivityFragment;
  showTime?: boolean;
}

export const Activity = ({ activity, showTime = true }: ActivityProps) => {
  return (
    <Card
      sx={{
        height: "72px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box sx={{ height: "100%", width: "6px", backgroundColor: "accents.orangeDark.400" }} />
        <DirectionsRunIcon fontSize="small" sx={{ color: "accents.orangeDark.400" }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1Medium">{activity.name}</Typography>
          {showTime && (
            <Typography sx={{ color: "mediumEmphasisText" }} variant="body2">
              {formatEventTime(activity)}
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};

import { useState } from "react";
import { parseDateTime } from "../utils/dateTimes";
import { addMinutes } from "date-fns";
import { MealMenuOrderRateLimitQuery, useMealMenuOrderRateLimitQuery, MenuOrderRateLimitRemainder } from "../types";
import { sortByKey } from "@notemeal/utils/sort";

interface usePickupTimeStateArgs {
  mealMenuId: string;
  teamId: string | null;
  mealMenuStart: string;
  mealMenuDurationInMinutes: number;
  mealMenuPrepTimeInMinutes: number;
  currentMenuOrderPickupTime: string | null;
}

const PICKUP_TIME_INTERVAL = 15;
const POLL_INTERVAL_MS = 5000;

type PickupTime_WithoutRemaining =
  | {
      type: "Time";
      value: string;
    }
  | {
      type: "ASAP";
    };

export type PickupTime =
  | {
      type: "Time";
      value: string;
      remaining: number | null;
    }
  | {
      type: "ASAP";
      value: string;
      remaining: number | null;
      mealMenuPrepTimeInMinutes: number;
    };

interface usePickupTimeStatePayload {
  pickupTime: PickupTime | null;
  setPickupTime: (pickupTime: PickupTime | null) => void;
  orderPickupTimes: PickupTime[];
  logPickupTimes: PickupTime[];
}

export const usePickupTimeState = ({
  mealMenuId,
  teamId,
  mealMenuStart,
  mealMenuPrepTimeInMinutes,
  mealMenuDurationInMinutes,
  currentMenuOrderPickupTime,
}: usePickupTimeStateArgs): usePickupTimeStatePayload => {
  const { data } = useMealMenuOrderRateLimitQuery({
    variables: { mealMenuId },
    fetchPolicy: "network-only",
    pollInterval: POLL_INTERVAL_MS,
  });

  // "remaining" value is not stored in state, but rather added via getPickupTimes below
  // This prevents stale data from being stored in useState hook even after an update is polled
  const [pickupTimeWithoutRemaining, setPickupTimeWithoutRemaining] = useState<PickupTime_WithoutRemaining | null>(
    currentMenuOrderPickupTime === null ? null : { type: "Time", value: currentMenuOrderPickupTime }
  );

  const { orderPickupTimes, logPickupTimes, pickupTime } = getPickupTimes({
    mealMenuDurationInMinutes,
    mealMenuPrepTimeInMinutes,
    mealMenuStart,
    teamId,
    data,
    pickupTimeWithoutRemaining,
    currentMenuOrderPickupTime,
    // Don't need to put this into hook that updates periodically because
    // of usage of pollInterval above.
    // If we switch to subscribing to updates for the query above, would need to change this
    // so that "ASAP" values do not become out of sync
    currentTime: new Date(),
  });

  return {
    pickupTime,
    setPickupTime: setPickupTimeWithoutRemaining,
    orderPickupTimes,
    logPickupTimes,
  };
};

type getPickupTimesArgs = Pick<
  usePickupTimeStateArgs,
  "mealMenuDurationInMinutes" | "mealMenuPrepTimeInMinutes" | "mealMenuStart" | "teamId" | "currentMenuOrderPickupTime"
> & {
  currentTime: Date;
  pickupTimeWithoutRemaining: PickupTime_WithoutRemaining | null;
  data?: MealMenuOrderRateLimitQuery;
};

type getPickupTimesPayload = Pick<usePickupTimeStatePayload, "logPickupTimes" | "orderPickupTimes" | "pickupTime">;

export const getPickupTimes = ({
  mealMenuDurationInMinutes,
  mealMenuPrepTimeInMinutes,
  mealMenuStart,
  teamId,
  currentTime,
  currentMenuOrderPickupTime,
  data,
  pickupTimeWithoutRemaining,
}: getPickupTimesArgs): getPickupTimesPayload => {
  const remainders = data && getOrderRemainders(data, teamId);

  const mealMenuStartJSDate = parseDateTime(mealMenuStart);

  const intervalsInDuration = Math.floor(mealMenuDurationInMinutes / PICKUP_TIME_INTERVAL) + 1;
  const intervalTimes = Array.from(Array(intervalsInDuration).keys()).map(i =>
    addMinutes(mealMenuStartJSDate, i * PICKUP_TIME_INTERVAL).toISOString()
  );
  const logPickupTimes = intervalTimes.map(value => {
    return {
      type: "Time" as const,
      value,
      remaining: null,
    };
  });

  const soonestPickupTimeJSDate = addMinutes(currentTime, mealMenuPrepTimeInMinutes);
  const orderPickupTimesWithoutASAP = logPickupTimes
    .filter(pt => pt.value >= soonestPickupTimeJSDate.toISOString())
    .map(pt => {
      const remaining = getRemainingForTime(remainders, pt.value);
      return {
        ...pt,
        remaining,
      };
    });

  const _orderPickupTimes: PickupTime[] = [
    ...(orderPickupTimesWithoutASAP.length === logPickupTimes.length
      ? []
      : [getAsapPickupTime(remainders, currentTime, mealMenuPrepTimeInMinutes)]),
    ...orderPickupTimesWithoutASAP,
  ];

  const pickupTime =
    pickupTimeWithoutRemaining === null
      ? null
      : pickupTimeWithoutRemaining.type === "Time"
      ? // If pickupTime matches currentMenuOrderPickupTime
        // Then user has not edited their order and
        // can always use that time slot (so ignore remaining)
        pickupTimeWithoutRemaining.value === currentMenuOrderPickupTime
        ? { ...pickupTimeWithoutRemaining, remaining: null }
        : // Otherwise, fill in remaining value using remainders
          {
            ...pickupTimeWithoutRemaining,
            remaining: getRemainingForTime(remainders, pickupTimeWithoutRemaining.value),
          }
      : getAsapPickupTime(remainders, currentTime, mealMenuPrepTimeInMinutes);

  const orderPickupTimes =
    pickupTime && _orderPickupTimes.some(opt => opt.value === currentMenuOrderPickupTime) && pickupTime.value === currentMenuOrderPickupTime
      ? [..._orderPickupTimes.filter(opt => opt.value !== currentMenuOrderPickupTime), pickupTime]
      : _orderPickupTimes;

  return {
    pickupTime,
    orderPickupTimes,
    logPickupTimes,
  };
};

const getRemainingForTime = (remainders: readonly MenuOrderRateLimitRemainder[] | null | undefined, value: string): number | null => {
  if (!remainders) {
    return null;
  }

  const remainder = remainders?.find(({ start }) => start === value);
  return remainder?.remaining || 0;
};

const getAsapPickupTime = (
  remainders: readonly MenuOrderRateLimitRemainder[] | null | undefined,
  currentTime: Date,
  mealMenuPrepTimeInMinutes: number
): Extract<PickupTime, { type: "ASAP" }> => {
  const asapPickupTimeValue = addMinutes(currentTime, mealMenuPrepTimeInMinutes + 1).toISOString();
  if (!remainders) {
    return {
      type: "ASAP",
      remaining: null,
      mealMenuPrepTimeInMinutes,
      value: asapPickupTimeValue,
    };
  }
  // Last remainder where asapPickupTime exceeds remainder.start is the matching one
  const reversedRemainders = sortByKey(remainders, "start", { reverse: true });
  const remainingForASAP = reversedRemainders.find(({ start }) => asapPickupTimeValue > start);
  return {
    type: "ASAP",
    remaining: remainingForASAP?.remaining || 0,
    mealMenuPrepTimeInMinutes,
    value: asapPickupTimeValue,
  };
};

const getOrderRemainders = (data: MealMenuOrderRateLimitQuery, teamId: string | null): readonly MenuOrderRateLimitRemainder[] | null => {
  if (!data.mealMenu.orderRateLimit) {
    return null;
  }
  if (teamId && data.mealMenu.orderRateLimit.excludedTeamIds.includes(teamId)) {
    return null;
  }
  return data.menuOrderRateLimitRemainders;
};

import { Box, TextField, Typography } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { ScoreSelect } from "libs/shared/ui/src/lib/Score/ScoreSelect";
import React, { Dispatch } from "react";
import { MealType, ScoringSystemFragment } from "../../../types";
import { FoodCategorySelect } from "../../FoodCategory/FoodCategorySelect";
import MealTypeMultiSelect from "../../Meal/TypeMultiSelect";
import { RecipeFormTimeInMinutesInput } from "./TimeInMinutesInput";
import { canSaveRecipeFormTooltips, formValidationMessages, RecipeFormAction, RecipeFormState } from "./utils";

interface DetailsProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  scoringSystem?: ScoringSystemFragment | null;
  disabled?: boolean;
  forStaff?: boolean;
}

const Details = ({ dispatch, state, scoringSystem, disabled, forStaff }: DetailsProps) => {
  const hasFoodCategory = useHasFeature("foodCategory");
  const canSaveTooltips = canSaveRecipeFormTooltips(state);
  const { name } = state;

  const handleChangeMealTypes = (mealTypes: readonly MealType[]) => {
    dispatch({
      type: "CHANGE_MEAL_TYPES",
      value: mealTypes,
    });
  };

  const isValidRecipeName = canSaveTooltips.includes(formValidationMessages.RECIPE_NAME) && state.saveClicked;

  return (
    <Box sx={{ gap: 1, flexDirection: "column", display: "flex" }}>
      <Typography variant="h4">Recipe Details</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          disabled={disabled}
          label="Recipe Name"
          value={name}
          sx={{ width: 360 }}
          onChange={e =>
            dispatch({
              type: "CHANGE_NAME",
              value: e.target.value,
            })
          }
          error={isValidRecipeName}
          helperText={isValidRecipeName ? "Required" : undefined}
        />
        <MealTypeMultiSelect
          disabled={disabled}
          value={state.mealTypes}
          onChange={handleChangeMealTypes}
          sx={{
            width: 200,
          }}
        />
        <RecipeFormTimeInMinutesInput
          disabled={disabled}
          sx={{
            width: 120,
          }}
          label="Prep Time"
          value={state.prepTimeInMinutesInput}
          onChange={value =>
            dispatch({
              type: "CHANGE_PREP_TIME",
              value,
            })
          }
        />
        <RecipeFormTimeInMinutesInput
          disabled={disabled}
          label="Cook Time"
          value={state.cookTimeInMinutesInput}
          sx={{
            width: 120,
          }}
          onChange={value =>
            dispatch({
              type: "CHANGE_COOK_TIME",
              value,
            })
          }
        />
        {!forStaff && scoringSystem && (
          <ScoreSelect
            disabled={disabled}
            scoreValue={state.scoreValue}
            scoringSystem={scoringSystem}
            onChange={value =>
              dispatch({
                type: "CHANGE_SCORE",
                value,
              })
            }
          />
        )}
        {!forStaff && hasFoodCategory && (
          <FoodCategorySelect
            disabled={disabled}
            category={state.foodCategory || null}
            onChange={value =>
              dispatch({
                type: "CHANGE_FOOD_CATEGORY",
                foodCategory: value,
              })
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default Details;

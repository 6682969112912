import { getCreateMenuItemInputs, getEditMenuItemInputs } from "../../../../components/MenuItemAppearance/utils";
import {
  EditRestaurantMenuInput,
  StaffDashboardRestaurantMenuSectionFragment,
  useCreateRestaurantMenuMutation,
  useEditRestaurantMenuMutation,
} from "../../../../types";
import { EditRestaurantMenuDialogState, MenuSectionState, NewRestaurantMenuDialogState } from "../types";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";

interface onCreateRestaurantMenuArgs {
  restaurantId: string;
  state: NewRestaurantMenuDialogState;
}

interface onEditRestaurantMenuArgs {
  restaurantId: string;
  state: EditRestaurantMenuDialogState;
  initialSections: readonly StaffDashboardRestaurantMenuSectionFragment[];
}

interface getEditMenuSectionsInputsArgs {
  initial: readonly StaffDashboardRestaurantMenuSectionFragment[];
  final: readonly MenuSectionState[];
}

const getCreateRestaurantMenuSectionInput = ({ name, position, menuItemAppearances }: MenuSectionState) => ({
  name,
  position,
  ...getCreateMenuItemInputs(menuItemAppearances),
});

const getCreateRestaurantMenuSectionsInput = (sections: readonly MenuSectionState[]) => {
  return sections.map(getCreateRestaurantMenuSectionInput);
};

const getEditRestaurantMenuSectionsInput = ({
  initial,
  final,
}: getEditMenuSectionsInputsArgs): Pick<EditRestaurantMenuInput, "addSections" | "editSections" | "removeSections"> => {
  const finalIds = final.map(section => section.id);
  const initialIds = initial.map(section => section.id);

  return {
    addSections: final.filter(section => !initialIds.includes(section.id)).map(getCreateRestaurantMenuSectionInput),
    editSections: final
      .filter(section => initialIds.includes(section.id))
      .map(section => {
        const initialSections = initial.find(initialSection => section.id === initialSection.id);
        if (!initialSections) {
          // TODO: Could refactor to avoid this error but it'd be a pain, should never reach this point
          throw new Error("Invariant violation");
        }
        return {
          restaurantMenuSectionId: section.id,
          name: section.name,
          position: section.position,
          ...getEditMenuItemInputs(section.menuItemAppearances),
        };
      }),
    removeSections: initialIds.filter(id => !finalIds.includes(id)).map(id => ({ restaurantMenuSectionId: id })),
  };
};

// TODO: Add in error and success snacks

export const useCreateEditDeleteMenu = () => {
  const { setMessage } = useSnackbar();
  const [createRestaurantMenu, { loading: savingCreate }] = useCreateRestaurantMenuMutation({
    onError: () => {
      setMessage("error", "Error occurred while creating Meal Menu");
    },
    onCompleted: () => {
      setMessage("success", "Successfully created Meal Menu");
    },
  });

  const [editRestaurantMenu, { loading: savingEdit }] = useEditRestaurantMenuMutation({
    onError: () => {
      setMessage("error", "Error occurred while editing Meal Menu(s)");
    },
    onCompleted: () => {
      setMessage("success", "Successfully edited Meal Menu");
    },
  });

  const saving = savingCreate || savingEdit;

  const handleCreate = ({ state, restaurantId }: onCreateRestaurantMenuArgs) => {
    return createRestaurantMenu({
      variables: {
        input: {
          restaurantId,
          sections: getCreateRestaurantMenuSectionsInput(state.sections),
          supportedDietIds: state.supportedDiets.map(d => d.id),
        },
      },
      update: (cache, { data }) => {
        const restaurantCacheId = cache.identify({
          __typename: "Restaurant",
          id: restaurantId,
        });
        if (restaurantCacheId && data) {
          cache.modify({
            id: restaurantCacheId,
            fields: {
              menu: () => data.createRestaurantMenu.restaurantMenu,
            },
          });
        }
      },
    });
  };

  const handleEdit = ({ state, initialSections, restaurantId }: onEditRestaurantMenuArgs) => {
    return editRestaurantMenu({
      variables: {
        input: {
          restaurantMenuId: state.id,
          supportedDietIds: state.supportedDiets.map(d => d.id),
          ...getEditRestaurantMenuSectionsInput({
            initial: initialSections,
            final: state.sections,
          }),
        },
      },
      update: (cache, { data }) => {
        const restaurantCacheId = cache.identify({
          __typename: "Restaurant",
          id: restaurantId,
        });
        if (restaurantCacheId && data) {
          cache.modify({
            id: restaurantCacheId,
            fields: {
              menu: () => data.editRestaurantMenu.restaurantMenu,
            },
          });
        }
      },
    });
  };

  return { saving, handleCreate, handleEdit };
};

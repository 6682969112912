import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useMenusStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectRoot: {
      paddingRight: theme.spacing(4),
    },
  })
);

export const splitArrayIntoNChunks = <T>(array: T[], chunks: number) => {
  if (chunks < 1 || array.length < 1) return [];
  let result: T[][] = [];
  Array.from(Array(chunks + 1).keys())
    .reverse()
    .forEach(e => {
      result.push(array.splice(0, Math.ceil(array.length / e)));
    });
  return result.filter(r => r.length);
};

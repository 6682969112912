import { SxProps, TextField, Theme } from "@mui/material";
import React, { useState } from "react";
import { ServingUnitsState, getServingUnits } from "../utils";
import InputDialog from "./InputDialog";

interface ServingUnitsInputProps {
  value: ServingUnitsState;
  onChange: (units: ServingUnitsState) => void;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
  className?: string;
  disabled?: boolean;
  error?: boolean;
}

const ServingUnitsInput = ({ value, fullWidth, onChange, sx, className, disabled, error }: ServingUnitsInputProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const servingsUnits = getServingUnits(value);

  return (
    <>
      <TextField
        disabled={disabled}
        label="Units"
        sx={sx}
        className={className}
        fullWidth={fullWidth}
        value={servingsUnits ?? "No Units"}
        onClick={() => setDialogOpen(true)}
        inputProps={{
          style: {
            caretColor: "transparent",
          },
        }}
        error={error}
      />
      {dialogOpen && <InputDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        value={value}
        onChange={onChange} />}
    </>
  );
};

export default ServingUnitsInput;

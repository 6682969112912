import { SxProps } from "@mui/material";
import { BasicOption } from "apps/web/src/components/universal/BaseAsyncAutocomplete";
import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "apps/web/src/components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import {
  OrgGroupRecipeOwnerCursorConnectionQueryVariables,
  RecipeOwnerPreviewFragment,
  useOrgGroupRecipeOwnerCursorConnectionQuery,
} from "apps/web/src/types";
import React, { useCallback } from "react";

interface ManagedByMultiSelectProps {
  selectedUsers: RecipeOwnerPreviewFragment[];
  searchText: string;
  onChangeSelectedUsers: (users: RecipeOwnerPreviewFragment[]) => void;
  sx?: SxProps;
}

export const RecipeManagedByMultiSelect = ({ onChangeSelectedUsers, selectedUsers, searchText, sx }: ManagedByMultiSelectProps) => {
  const getQueryVariables = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
        searchText,
      },
    }),
    [searchText]
  );

  const transformAndFilterOptions = useCallback(
    (edges: RecipeOwnerPreviewFragment[] | undefined): RecipeOwnerPreviewFragment[] => {
      const selectedUserIds = selectedUsers.map(u => u.id);
      return edges?.filter(u => !selectedUserIds.includes(u.id)) || [];
    },
    [selectedUsers]
  );

  return (
    <InfiniteScrollAutocomplete<
      "orgGroupRecipeOwnerCursorConnection",
      RecipeOwnerPreviewFragment,
      OrgGroupRecipeOwnerCursorConnectionQueryVariables,
      RecipeOwnerPreviewFragment,
      true,
      undefined
    >
      sx={sx}
      multiple
      getOptionSelected={usersAreEqual}
      disableCloseOnSelect
      blurOnSelect={false}
      showStartAdornment={false}
      disableRootPadding
      edgesAreEqual={usersAreEqual}
      queryKey="orgGroupRecipeOwnerCursorConnection"
      useCursorConnectionQuery={useOrgGroupRecipeOwnerCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariables}
      transformAndFilterOptions={transformAndFilterOptions}
      renderOption={user => <BasicOption element={getUserLabel(user)} />}
      handleChange={(_, users) => onChangeSelectedUsers(users)}
      getOptionLabel={getUserLabel}
      getCustomOptionKey={(u: RecipeOwnerPreviewFragment) => u.id}
      noOptionsText="No Users Found"
      inputPlaceholder="Managed By"
      value={selectedUsers}
      size="medium"
    />
  );
};

const getUserLabel = (user: RecipeOwnerPreviewFragment): string => {
  return `${user.firstName} ${user.lastName}`;
};

const usersAreEqual = (user1: RecipeOwnerPreviewFragment, user2: RecipeOwnerPreviewFragment) => {
  return user1.id === user2.id;
};

import { Meal, MealType, ScheduleWithTeamFragment } from "../../../types";
import { MealInputWithId } from "./scheduleReducer";

export const getDefaultMealRatio = (type: MealType) => (type === "snack" ? 1 : 2);

export const makeMeal = (meal: Meal): MealInputWithId => {
  const { name, start, end, type } = meal;
  const ratio = getDefaultMealRatio(type);
  const stringRatio = ratio.toString();
  return {
    id: `TempID-${name}`,
    choRatio: ratio,
    choRatioInput: stringRatio,
    fatRatio: ratio,
    fatRatioInput: stringRatio,
    meal: { name, start, end, type },
    mealOptions: [],
    proRatio: ratio,
    proRatioInput: stringRatio,
  };
};

export const standardSchedules: ScheduleWithTeamFragment[] = [
  {
    team: null,
    meals: [
      {
        id: "TempID-Afternoon Snack",
        name: "Afternoon Snack",
        start: "14:00:00",
        end: "15:00:00",
        type: "snack",
      },
      {
        id: "TempID-Breakfast",
        name: "Breakfast",
        start: "07:00:00",
        end: "08:00:00",
        type: "breakfast",
      },
      {
        id: "TempID-Lunch",
        name: "Lunch",
        start: "11:30:00",
        end: "12:30:00",
        type: "lunch",
      },
      {
        id: "TempID-Dinner",
        name: "Dinner",
        start: "17:30:00",
        end: "18:30:00",
        type: "dinner",
      },
    ],
    activities: [],
    id: "Temp-ID-3-Meals-1-Snack",
    name: "3 Meals, 1 Snack",
  },
  {
    team: null,
    meals: [
      {
        id: "TempID-Morning Snack",
        name: "Morning Snack",
        start: "09:30:00",
        end: "10:30:00",
        type: "snack",
      },
      {
        id: "TempID-Afternoon Snack",
        name: "Afternoon Snack",
        start: "14:00:00",
        end: "15:00:00",
        type: "snack",
      },
      {
        id: "TempID-Breakfast",
        name: "Breakfast",
        start: "07:00:00",
        end: "08:00:00",
        type: "breakfast",
      },
      {
        id: "TempID-Lunch",
        name: "Lunch",
        start: "11:30:00",
        end: "12:30:00",
        type: "lunch",
      },
      {
        id: "TempID-Dinner",
        name: "Dinner",
        start: "17:30:00",
        end: "18:30:00",
        type: "dinner",
      },
    ],
    activities: [],
    id: "Temp-ID-3-Meals-2-Snacks",
    name: "3 Meals, 2 Snacks",
  },
  {
    team: null,
    meals: [
      {
        id: "TempID-Breakfast",
        name: "Breakfast",
        start: "07:00:00",
        end: "08:00:00",
        type: "breakfast",
      },
      {
        id: "TempID-Morning Snack",
        name: "Morning Snack",
        start: "09:30:00",
        end: "10:30:00",
        type: "lunch",
      },
      {
        id: "TempID-Afternoon Snack",
        name: "Afternoon Snack",
        start: "14:00:00",
        end: "15:00:00",
        type: "dinner",
      },
      {
        id: "TempID-Before Bed Snack",
        name: "Before Bed Snack",
        start: "20:00:00",
        end: "21:00:00",
        type: "snack",
      },
      {
        id: "TempID-Lunch",
        name: "Lunch",
        start: "11:30:00",
        end: "12:30:00",
        type: "lunch",
      },
      {
        id: "TempID-Dinner",
        name: "Dinner",
        start: "17:30:00",
        end: "18:30:00",
        type: "dinner",
      },
    ],
    activities: [],
    id: "Temp-ID-3-Meals-3-Snacks",
    name: "3 Meals, 3 Snacks",
  },
  {
    team: null,
    meals: [
      {
        id: "TempID-Early Morning Snack",
        name: "Early Morning Snack",
        start: "05:30:00",
        end: "06:30:00",
        type: "snack",
      },
      {
        id: "TempID-Mid Morning Snack",
        name: "Mid Morning Snack",
        start: "09:30:00",
        end: "10:30:00",
        type: "snack",
      },
      {
        id: "TempID-Afternoon Snack",
        name: "Afternoon Snack",
        start: "14:00:00",
        end: "15:00:00",
        type: "snack",
      },
      {
        id: "TempID-Before Bed Snack",
        name: "Before Bed Snack",
        start: "20:00:00",
        end: "21:00:00",
        type: "snack",
      },
      {
        id: "TempID-Breakfast",
        name: "Breakfast",
        start: "07:00:00",
        end: "08:00:00",
        type: "breakfast",
      },
      {
        id: "TempID-Lunch",
        name: "Lunch",
        start: "11:30:00",
        end: "12:30:00",
        type: "lunch",
      },
      {
        id: "TempID-Dinner",
        name: "Dinner",
        start: "17:30:00",
        end: "18:30:00",
        type: "dinner",
      },
    ],
    activities: [],
    id: "Temp-ID-3-Meals-4-Snacks",
    name: "3 Meals, 4 Snacks",
  },
];

import React from "react";
import { OrgLinkerOrgFragment } from "../../../../../../types";
import BaseNotemealOrgsList from "./BaseNotemealOrgsList";

interface LinkedOrgsListProps {
  selectedId: string | null;
  searchQuery: string;
  onEdit: (org: OrgLinkerOrgFragment) => void;
  orgs: readonly OrgLinkerOrgFragment[];
  loading: boolean;
}

const LinkedOrgsList = (props: LinkedOrgsListProps) => {
  const { selectedId } = props;

  return (
    <BaseNotemealOrgsList
      {...props}
      title="Linked Orgs"
      columnMode={selectedId ? "NoAction" : "Edit"}
      filterOrg={({ isLinked, hasSearchQueryMatch }) => hasSearchQueryMatch && isLinked}
    />
  );
};

export default LinkedOrgsList;

import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { AssignedTeam, AthleteAssignState } from "../../components/AthleteAssignment/athleteAssignReducer";
import ChipListPreview from "../../components/universal/ChipList/Preview";
import { AdvancedSelectionFragment } from "../../types";
import ShareWithInfo from "../Tags/Dialogs/Mutation/AdvancedSelection/ShareWithInfo";
import {
  buildInitialCreateAdvancedSelectionStateFromTeams,
  buildInitialEditAdvancedSelectionState,
} from "../Tags/reducers/advancedSelectionReducers";

const HIDE_SHARE_CHIPS_AFTER = 4;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipGrey: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    chipDiv: {
      maxWidth: theme.spacing(80),
      display: "flex",
    },
    notAssigned: {
      marginRight: theme.spacing(1),
    },
    container: {
      display: "flex",
      alignItems: "center",
    },
    shareWithContainer: {
      flexWrap: "wrap",
    },
    teamsContainer: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      marginTop: theme.spacing(-1),
    },
  })
);

interface RequiredMealPlanTemplate<T extends AssignedTeam> {
  id: string;
  teams: readonly T[] | null;
  advancedSelection: AdvancedSelectionFragment | null;
  hasAdvancedSelection: boolean;
}

interface AssignInfoCellProps<T extends AssignedTeam> {
  mealPlanTemplate: RequiredMealPlanTemplate<T>;
  setEditMPTAssignInfo: (macroMealPlanTemplateId: string, initialState: AthleteAssignState<T>) => void;
}

const AssignInfoCell = <T extends AssignedTeam>({
  mealPlanTemplate: { id: mealPlanTemplateId, teams, advancedSelection, hasAdvancedSelection },
  setEditMPTAssignInfo,
}: AssignInfoCellProps<T>) => {
  const classes = useStyles();
  // TODO: Tags MPT for menus we check athlete count here.
  const isNotAssigned = hasAdvancedSelection ? advancedSelection === null : teams === null || teams.length === 0;
  const initialState: AthleteAssignState<T> = hasAdvancedSelection
    ? {
        __typename: "Tags",
        teams: null,
        advancedSelectionState: advancedSelection
          ? buildInitialEditAdvancedSelectionState(advancedSelection)
          : buildInitialCreateAdvancedSelectionStateFromTeams(teams ?? []),
      }
    : {
        __typename: "Teams",
        teams: teams ?? [],
        advancedSelectionState: null,
      };

  return (
    <TableCell>
      <div className={classes.container}>
        {isNotAssigned && <span className={classes.notAssigned}>Not Shared</span>}
        {initialState.__typename === "Teams" && (
          <div className={classes.chipDiv}>
            <div className={classes.teamsContainer}>
              <ChipListPreview
                items={initialState.teams.map(({ id, name }) => ({ id, label: name }))}
                hideItemsAfter={HIDE_SHARE_CHIPS_AFTER}
                chipClassName={classes.chipGrey}
                getToolTipText={hiddenItems => hiddenItems.map(({ label }) => label).join(", ")}
              />
            </div>
          </div>
        )}
        {initialState.__typename === "Tags" && (
          <div className={classes.chipDiv}>
            <ShareWithInfo
              state={initialState.advancedSelectionState}
              maxChips={HIDE_SHARE_CHIPS_AFTER}
              containerClassName={classes.shareWithContainer}
              includeTrailingPeriod={false}
              showAdvancedSelectionClear={false}
            />
          </div>
        )}
        <Tooltip title="Assign Meal Plan Template to Athletes">
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              setEditMPTAssignInfo(mealPlanTemplateId, initialState);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </div>
    </TableCell>
  );
};

export default AssignInfoCell;

import { RestLink } from "apollo-link-rest";
import {
  RawNutritionixBrandedFood,
  RawNutritionixCommonFood,
  NixPhoto,
  RawNutritionixV1RestaurantSearchPayload,
  RawNutritionixV1RestaurantSearchHit,
  RawNutritionixBrandedInstantSearchPayload,
  RawNutritionixCommonInstantSearchPayload,
} from "./types";
import { NutritionixBrandedFoodFragment, NutritionixCommonFoodFragment } from "../types";

export const nutritionixRestLink = new RestLink({
  endpoints: {
    v2: "https://trackapi.nutritionix.com/v2",
  },
  responseTransformer: async (response, typeName) => {
    switch (typeName) {
      case "NutritionixBrandedInstantSearchPayload": {
        const data = await (response.json() as Promise<RawNutritionixBrandedInstantSearchPayload>);
        return {
          branded: data.branded?.map(getNutritionixBrandedFood).filter(f => f !== null) ?? [],
        };
      }
      case "NutritionixCommonInstantSearchPayload": {
        const data = await (response.json() as Promise<RawNutritionixCommonInstantSearchPayload>);
        return {
          common: getNutritionixCommonFoods(data.common ?? []),
        };
      }
      default:
        throw new Error("Couldn't handle typeName " + typeName + " ...exiting.");
    }
  },
});

export const nutritionixV1RestLink = new RestLink({
  endpoints: {
    v1_search: "https://api.nutritionix.com/v1_1/search",
  },
  responseTransformer: async (response, typeName) => {
    switch (typeName) {
      case "NutritionixV1RestaurantSearchPayload": {
        const data = await (response.json() as Promise<RawNutritionixV1RestaurantSearchPayload>);
        return {
          total: data.total,
          branded: data.hits.map(getNutritionixBrandedFoodFromV1RestaurantSearch).filter(f => f !== null),
        };
      }
      default:
        throw new Error("Couldn't handle typeName " + typeName + " ...exiting.");
    }
  },
});

export const getNutritionixBrandedFoodFromV1RestaurantSearch = ({
  fields: { item_id, item_name, brand_name, brand_id, item_type },
}: RawNutritionixV1RestaurantSearchHit): NutritionixBrandedFoodFragment | null => {
  return getNutritionixBrandedFood({
    food_name: item_name,
    nix_item_id: item_id,
    nix_brand_id: brand_id,
    brand_name: brand_name,
    brand_type: item_type,
    photo: null,
  });
};

const getNutritionixBrandedFood = ({
  food_name,
  nix_item_id,
  nix_brand_id,
  brand_name,
  brand_type,
  photo,
}: RawNutritionixBrandedFood): NutritionixBrandedFoodFragment | null => {
  try {
    const foodId = `${nix_brand_id}:${nix_item_id}`;
    return {
      id: foodId,
      __typename: "NutritionixBrandedFood",
      name: food_name,
      nixItemId: nix_item_id,
      brand: {
        id: nix_brand_id,
        __typename: "NutritionixBrand",
        name: brand_name,
        type: brand_type,
      },
      thumbnailUrl: coerceImageUrl(photo),
    };
  } catch (e) {
    console.error(`BrandedFood ${brand_name} ${food_name} parse failed with error: ${e}`);
    return null;
  }
};

const getNutritionixCommonFoods = (commonFoods: RawNutritionixCommonFood[]): NutritionixCommonFoodFragment[] => {
  const initReduceAggValue: {
    notemealFoods: NutritionixCommonFoodFragment[];
    tagIds: string[];
  } = { notemealFoods: [], tagIds: [] };

  const result = commonFoods.reduce(({ notemealFoods, tagIds }, nextFood) => {
    try {
      if (tagIds.includes(nextFood.tag_id)) {
        return { notemealFoods, tagIds };
      } else {
        return {
          notemealFoods: [...notemealFoods, getNutritionixCommonFood(nextFood)],
          tagIds: [...tagIds, nextFood.tag_id],
        };
      }
    } catch (e) {
      console.error(`CommonFood ${nextFood.food_name} parse failed with error: ${e}`);
      return { notemealFoods, tagIds };
    }
  }, initReduceAggValue);
  return result.notemealFoods;
};

const getNutritionixCommonFood = ({ food_name, tag_id, photo }: RawNutritionixCommonFood): NutritionixCommonFoodFragment => {
  const foodId = `${food_name}:${tag_id}`;
  return {
    id: foodId,
    __typename: "NutritionixCommonFood",
    name: food_name,
    nixTagId: tag_id,
    thumbnailUrl: coerceImageUrl(photo),
  };
};

//image logic below copied from server/src/services/integrations/nix/deserialize.ts -> could be moved to shared loc
export const COERCE_TO_NULL_IMAGE_SUBSTRING = "nix-apple-grey";

const coerceImageUrl = (photo: NixPhoto | null | undefined) => {
  if (photo) {
    return photo.thumb ? (photo.thumb.includes(COERCE_TO_NULL_IMAGE_SUBSTRING) ? null : photo.thumb) : null;
  } else {
    return null;
  }
};

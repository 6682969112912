import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import Loading from "@notemeal/shared/ui/global/Loading";
import { FloatSchema, GoalTypeSchema, IntegerSchema, isFormDirty } from "@notemeal/validators";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ConfirmationDialog } from "../../componentLibrary";
import DialogTitle from "../../componentLibrary/DialogTitle";
import DialogTitleWithTooltip from "../../componentLibrary/DialogTitleWithToolTip";
import {
  AnthropometryEntrySchema,
  AnthropometryEntryType,
  anthropometryEntryFormDefaultValues,
  anthropometryEntryFormStateToMetricFormInput,
} from "../../components/AnthropometryEntry/Form/AnthropometryEntryFormSchema";
import {
  MetricAnthropometryEntrySchema,
  MetricAnthropometryEntryType,
  metricAnthropometryEntryFormDefaultValues,
  metricAnthropometryEntryFormStateToMetricFormInput,
} from "../../components/AnthropometryEntry/Form/MetricAnthropometryEntryFormSchema";
import {
  AthleteFormRequireEmailSchema,
  AthleteFormType,
  athleteFormDefaultValues,
  athleteFormToInput,
} from "../../components/Athlete/Form/AthleteFormSchema";
import GoalForm from "../../components/Goal/Form";
import { GoalSchema, GoalType, goalFormDefaultValues, goalFormToInput } from "../../components/Goal/Form/GoalFormSchema";
import {
  MetricAnthropometryEntryFormInput,
  SexType,
  TeamAnthropometryDocument,
  TeamPageDocument,
  TeamWithSportFragment,
  useCreateMetricAthleteMutation,
  useMyOrgFullySyncedQuery,
} from "../../types";
import { CreateImperialAnthropometryEntryForm } from "./CreateAnthropometryEntryForm/ImperialAnthropometryEntryForm";
import { CreateMetricAnthropometryEntryForm } from "./CreateAnthropometryEntryForm/MetricAnthropometryEntryForm";
import CreateAthleteForm from "./CreateAthleteForm";
import {
  AccountVerificationMethodFormSchema,
  AccountVerificationMethodFormType,
  accountVerificationMethodFormDefaultValues,
} from "./CreateAthleteForm/AccountVerificationMethodFormSchema";

const steps = ["General", "Anthropometry", "Weight Target"];

const isStepOptional = (step: number): boolean => {
  return step !== 0;
};

interface TeamCreateAthleteModalProps {
  teams?: readonly TeamWithSportFragment[];
  teamId: string;
  open: boolean;
  onClose: () => void;
  onFinish: (athleteId: string) => void;
}

const CreateAthleteModalAnthropometryEntryFormSchema = AnthropometryEntrySchema({
  overrides: z.object({
    weight: FloatSchema.optional(),
  }),
});

const CreateMetricAthleteModalAnthropometryEntryFormSchema = MetricAnthropometryEntrySchema({
  overrides: z.object({
    weightInKg: FloatSchema.optional(),
  }),
});

const CreateAthleteModalGoalFormSchema = GoalSchema({
  overrides: z.object({
    kcalOffset: IntegerSchema.optional(),
    type: GoalTypeSchema.optional(),
  }),
}).superRefine(({ type, kcalOffset }, ctx) => {
  if (!!type && (kcalOffset === null || kcalOffset === undefined)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Required",
      path: ["kcalOffset"],
    });
  }

  if (!!kcalOffset && !type?.id) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid",
      path: ["type"],
    });
  }
});

const TeamCreateAthleteModal = ({ teams, teamId, onClose, open, onFinish }: TeamCreateAthleteModalProps) => {
  const { isMetricLocale } = useLocaleContext();
  const [confirmModalOpen, setConfirmModalOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const athleteForm = useForm<AthleteFormType>({
    defaultValues: athleteFormDefaultValues(teamId),
    resolver: zodResolver(AthleteFormRequireEmailSchema),
  });

  const accountVerificationMethodForm = useForm<AccountVerificationMethodFormType>({
    defaultValues: accountVerificationMethodFormDefaultValues(),
    resolver: zodResolver(AccountVerificationMethodFormSchema),
  });

  const imperialAnthroForm = useForm<AnthropometryEntryType>({
    defaultValues: anthropometryEntryFormDefaultValues(),
    resolver: zodResolver(CreateAthleteModalAnthropometryEntryFormSchema),
  });

  const metricAnthroForm = useForm<MetricAnthropometryEntryType>({
    defaultValues: metricAnthropometryEntryFormDefaultValues(),
    resolver: zodResolver(CreateMetricAthleteModalAnthropometryEntryFormSchema),
  });

  const goalForm = useForm<GoalType>({
    defaultValues: goalFormDefaultValues(),
    resolver: zodResolver(CreateAthleteModalGoalFormSchema),
  });

  const { data: orgData, loading } = useMyOrgFullySyncedQuery();

  const [createMetricAthlete] = useCreateMetricAthleteMutation({
    refetchQueries: [
      { query: TeamPageDocument, variables: { teamId } },
      { query: TeamAnthropometryDocument, variables: { id: teamId } },
    ],
    onCompleted: data => onFinish(data.createMetricAthlete.athlete.id),
  });

  const isStepSkipped = (step: number) => {
    switch (step) {
      case 1:
        return isMetricLocale ? !isFormDirty(metricAnthroForm) : !isFormDirty(imperialAnthroForm);
      case 2:
        return !isFormDirty(goalForm);
      default:
        return false;
    }
  };

  const handleOnBack = () => setActiveStep(activeStep - 1);

  let hasFormErrors = false;

  if (activeStep === 0) {
    hasFormErrors =
      (athleteForm.formState.isSubmitted && !athleteForm.formState.isValid) ||
      (accountVerificationMethodForm.formState.isSubmitted && !accountVerificationMethodForm.formState.isValid);
  } else if (activeStep === 1) {
    hasFormErrors = isMetricLocale
      ? metricAnthroForm.formState.isSubmitted && !metricAnthroForm.formState.isValid
      : imperialAnthroForm.formState.isSubmitted && !imperialAnthroForm.formState.isValid;
  } else if (activeStep === 2) {
    hasFormErrors = goalForm.formState.isSubmitted && !goalForm.formState.isValid;
  }

  const getAnthroInput = (athleteSex: SexType): MetricAnthropometryEntryFormInput[] | null => {
    if (isStepSkipped(1)) {
      return null;
    }

    const imperialData = CreateAthleteModalAnthropometryEntryFormSchema.parse(imperialAnthroForm.getValues());
    const metricData = CreateMetricAthleteModalAnthropometryEntryFormSchema.parse(metricAnthroForm.getValues());

    const input = isMetricLocale
      ? metricAnthropometryEntryFormStateToMetricFormInput(metricData, athleteSex)
      : anthropometryEntryFormStateToMetricFormInput(imperialData, athleteSex);

    return [input];
  };

  const incrementActiveStep = () => setActiveStep(activeStep + 1);
  const handleAnthroSubmit = () => {
    return isMetricLocale
      ? metricAnthroForm.handleSubmit(() => setActiveStep(activeStep + 1))
      : imperialAnthroForm.handleSubmit(() => setActiveStep(activeStep + 1));
  };

  const handleOnNext = (isOrgNotemealLinked: boolean) => async (e: React.FormEvent) => {
    e.preventDefault();

    switch (activeStep) {
      case 0:
        return await athleteForm.handleSubmit(async () => {
          await accountVerificationMethodForm.handleSubmit(incrementActiveStep)();
        })();
      case 1:
        return handleAnthroSubmit()();
      case 2:
        return goalForm.handleSubmit(goal => {
          const athlete = AthleteFormRequireEmailSchema.parse(athleteForm.getValues());
          const { accountVerificationMethod } = AccountVerificationMethodFormSchema.parse(accountVerificationMethodForm.getValues());
          const athleteSex = athlete.sex;
          const { teamId: notUsed, ...restOfState } = athlete;
          const isNotemealOnly = isOrgNotemealLinked;
          if (athleteSex) {
            createMetricAthlete({
              variables: {
                input: {
                  teamId: teamId,
                  athlete: athleteFormToInput(
                    {
                      ...restOfState,
                      sex: athleteSex,
                    },
                    []
                  ),
                  isNotemealOnly,
                  goal: isStepSkipped(2) ? null : goalFormToInput(goal),
                  anthropometryEntries: getAnthroInput(athleteSex),
                  accountVerificationMethod: accountVerificationMethod === "skip" ? null : accountVerificationMethod,
                },
              },
            });
          }
        })();
    }
  };

  const stepContent =
    activeStep === 0 ? (
      <CreateAthleteForm
        teams={teams}
        athleteForm={athleteForm}
        accountVerificationMethodForm={accountVerificationMethodForm} />
    ) : activeStep === 1 ? (
      isMetricLocale ? (
        <CreateMetricAnthropometryEntryForm form={metricAnthroForm} />
      ) : (
        <CreateImperialAnthropometryEntryForm form={imperialAnthroForm} />
      )
    ) : activeStep === 2 ? (
      <GoalForm form={goalForm} />
    ) : (
      <Loading progressSize="md" />
    );

  if (loading || !orgData) {
    return <Loading progressSize="lg" />;
  }

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}>
        {orgData.myOrg.isNotemealLinked ? (
          <DialogTitleWithTooltip
            title="Create Nutrition-Only Athlete"
            tooltipText="This athlete will be added as a Nutrition-only user and will not have a profile created in Teamworks. Nutrition-only users will not be able to use the Teamworks SSO to sign in or have their profile information synced with Teamworks."
            onClose={onClose}
          />
        ) : (
          <DialogTitle title="Create Athlete" onClose={onClose} />
        )}
        <DialogContent sx={{ height: 740 }}>
          <Stepper activeStep={activeStep} sx={{ mt: 2, alignSelf: "center", "&": { width: "75%" } }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="subtitle1">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {stepContent}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={activeStep === 0 || activeStep === steps.length}
            onClick={handleOnBack}>
            Back
          </Button>
          <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
            <Button onClick={handleOnNext(orgData.myOrg.isNotemealLinked)} disabled={activeStep === steps.length}>
              {activeStep >= steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
      {open && confirmModalOpen && orgData.myOrg.isNotemealLinked && (
        <ConfirmationDialog
          open={confirmModalOpen}
          onCancel={onClose}
          onConfirm={() => setConfirmModalOpen(false)}
          title="Notemeal-Only Athlete"
          messageSx={{ whiteSpace: "pre-line" }}
          message={`This athlete will be added as a Nutrition-only user and will not have a profile created in Teamworks.
          
          Nutrition-only users will not be able to use the Teamworks SSO to sign in or have their profile information synced with Teamworks.`}
        />
      )}
    </>
  );
};

export default TeamCreateAthleteModal;

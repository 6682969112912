import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import TablePage from "../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../components/universal/TablePage/DefaultHeader";
import { TerritoryFragment, TerritoryOffsetConnectionQuery } from "../../../types";
import { PaginationHooks } from "../../../utils/pagination";

interface TerritoryTableProps {
  offsetConnectionData?: TerritoryOffsetConnectionQuery;
  loading: boolean;
  paginationHooks: PaginationHooks;
  onEditTerritory: (territory: TerritoryFragment) => void;
  onCreateTerritory: () => void;
}

export const TerritoryTable = ({
  offsetConnectionData,
  loading,
  paginationHooks,
  onEditTerritory,
  onCreateTerritory,
}: TerritoryTableProps) => {
  const { queryText, onChangeQueryText } = paginationHooks;
  const Header = (
    <TablePageDefaultHeader
      title="Territory"
      queryText={queryText}
      onChangeQueryText={onChangeQueryText}
      onCreate={onCreateTerritory} />
  );

  const TableBodyRows = offsetConnectionData
    ? offsetConnectionData.territoryOffsetConnection.edges.map(territory => (
        <TableRow key={territory.id}>
          <TableCell scope="row">
            <IconButton onClick={() => onEditTerritory(territory)} size="large">
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell scope="row">{territory.name}</TableCell>
          <TableCell scope="row">{territory.orgs.length}</TableCell>
        </TableRow>
      ))
    : null;

  const TableHeaderRow = () => (
    <TableRow>
      <TableCell padding="checkbox">Edit</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Org Count</TableCell>
    </TableRow>
  );

  return (
    <TablePage
      header={Header}
      tableHeaderRow={<TableHeaderRow />}
      tableBodyRows={TableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={offsetConnectionData?.territoryOffsetConnection.pageInfo.total || 0}
    />
  );
};

import { Box, Button, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";

import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { useSendFoodOrRecipeRequest } from "../../../utils/sendFoodOrServingRequest";

interface AddFoodRequestFieldProps {
  foodName?: string;
  onSuccess: (foodName: string) => void;
  onFailure: (foodName: string) => void;
}

export const AddFoodRequestField = ({ onSuccess, onFailure }: AddFoodRequestFieldProps) => {
  const [foodName, setFoodName] = useState("");
  const sendFoodRequest = useSendFoodOrRecipeRequest();

  const cantSubmitRequestTooltips = !foodName
    ? [`Enter 'food name' before submitting`]
    : foodName.length < 3
    ? [`Food must be at least 3 characters`]
    : [];

  return (
    <FormControl sx={{ alignSelf: "center", width: "100%", m: 1 }} component={"form"}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          autoFocus
          label={`Food Name`}
          type="text"
          value={foodName}
          onChange={e => setFoodName(e.target.value)}
          fullWidth />

        <TWItemizedTooltip items={cantSubmitRequestTooltips} title="Fix following before submitting">
          <Button
            type="submit"
            sx={{ minWidth: "auto", whiteSpace: "nowrap" }}
            onClick={e => {
              e.preventDefault();
              if (cantSubmitRequestTooltips.length) {
                return;
              } else {
                sendFoodRequest({
                  mode: "food",
                  foodRequest: foodName,
                  onSuccess,
                  onFailure,
                });
              }
            }}
          >
            Send Request
          </Button>
        </TWItemizedTooltip>
      </Box>
    </FormControl>
  );
};

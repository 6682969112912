import { ExchangeMealPlan } from "../../MealPlan/types";
import {
  EditExchangeMealTemplateDocument,
  EditExchangeMealTemplateFragment,
  EditExchangeMealTemplateFragmentDoc,
  EditExchangeMealTemplateInput,
  EditExchangeMealTemplateMutation,
  EditExchangeMealTemplateMutationVariables,
  EditExchangeMealTemplatesDocument,
  EditExchangeMealTemplatesMutation,
  EditExchangeMealTemplatesMutationVariables,
  EditFullExchangeMealTemplateFragment,
  EditFullExchangeMealTemplateFragmentDoc,
  FullExchangeMealTemplateFragment,
  MutationChangeExchangeTargetArgs,
  MutationDistributeExchangeTargetsArgs,
} from "../../types";
import { createDeselectNegativeFoodsMutationVars } from "../../utils/deselectNegativeFoods";
import { createDistributeExchangeMutationVars } from "../../utils/distributeExchanges";
import { inputToNumber } from "../../utils/inputUtils";
import { getExchangeTargetDiffAsInputs, initiEditExchangeAmountFragment, mergeExchangeTargetsWithInputs } from "./ExchangeAmount";
import { getEditFullMealPlanFragment, writeEditFullMealPlanFragment } from "./MealPlan";
import { initEditMealTemplateFragment } from "./MealTemplate";
import { ResolverContext } from "./types";
import { makeFragmentFuncs, makeFragmentFuncsWithInit } from "./utils";

const initEditExchangeMealTemplateFragment = (src: FullExchangeMealTemplateFragment): EditExchangeMealTemplateFragment => {
  const initFragment = initEditMealTemplateFragment(src);
  return {
    ...initFragment,
    exchangeTargets: (src.exchangeTargets || []).map(et => ({
      ...et,
      ...initiEditExchangeAmountFragment(et),
    })),
  };
};

const { getFragmentOrInit } = makeFragmentFuncsWithInit(
  "ExchangeMealTemplate",
  "EditExchangeMealTemplate",
  EditExchangeMealTemplateFragmentDoc,
  initEditExchangeMealTemplateFragment
);

const { getFragment, writeFragment } = makeFragmentFuncs<EditFullExchangeMealTemplateFragment>(
  "ExchangeMealTemplate",
  "EditFullExchangeMealTemplate",
  EditFullExchangeMealTemplateFragmentDoc
);

export const resolverMap = {
  isAutosaving: (src: FullExchangeMealTemplateFragment, args: any, context: ResolverContext): boolean => {
    return getFragmentOrInit(src, context).isAutosaving;
  },
  selectedMealOptionId: (src: FullExchangeMealTemplateFragment, args: any, context: ResolverContext): string | null => {
    return getFragmentOrInit(src, context).selectedMealOptionId;
  },
  newMealOptionIds: (src: FullExchangeMealTemplateFragment, args: any, context: ResolverContext): readonly string[] => {
    return getFragmentOrInit(src, context).newMealOptionIds;
  },
};

export const mutationResolverMap = {
  changeExchangeTarget: (
    src: any,
    { mealTemplateId, exchangeId, target, pickListServingIds }: MutationChangeExchangeTargetArgs,
    context: ResolverContext
  ) => {
    const { isAutosaving, ...editFragment } = getFragment(mealTemplateId, context);
    const amount = inputToNumber(target);

    const exchangeTargets = editFragment.exchangeTargets || [];

    writeFragment(
      {
        ...editFragment,
        exchangeTargets: mergeExchangeTargetsWithInputs(
          exchangeTargets,
          [{ exchangeId, amountInput: target, pickListServingIds }],
          context
        ),
        isAutosaving: amount !== null,
      },
      context
    );

    if (!isAutosaving && amount !== null) {
      runEditExchangeMealTemplateMutation(context, {
        input: {
          mealTemplateId,
          exchangeTargets: [{ exchangeId, amount, pickListServingIds }],
        },
      });
    }
  },
  distributeExchangeTargets: (src: any, { mealPlanId }: MutationDistributeExchangeTargetsArgs, context: ResolverContext) => {
    const mealPlan = getEditFullMealPlanFragment(mealPlanId, context);
    if (mealPlan.type !== "exchange") return;
    const exchangeMealPlan = mealPlan as ExchangeMealPlan;

    const mutationVars = createDistributeExchangeMutationVars(exchangeMealPlan);
    writeEditFullMealPlanFragment(
      {
        ...exchangeMealPlan,
        mealTemplates: mergeExchangeMealTemplateWithMutationVars(exchangeMealPlan.mealTemplates, mutationVars, context),
      },
      context
    );
    runEditExchangeMealTemplatesMutation(context, mutationVars);
  },
  deselectNegativeFoods: (src: any, { mealPlanId }: MutationDistributeExchangeTargetsArgs, context: ResolverContext) => {
    const mealPlan = getEditFullMealPlanFragment(mealPlanId, context);
    if (mealPlan.type !== "exchange") return;
    const exchangeMealPlan = mealPlan as ExchangeMealPlan;

    const mutationVars = createDeselectNegativeFoodsMutationVars(exchangeMealPlan);
    writeEditFullMealPlanFragment(
      {
        ...exchangeMealPlan,
        mealTemplates: mergeExchangeMealTemplateWithMutationVars(exchangeMealPlan.mealTemplates, mutationVars, context),
      },
      context
    );
    runEditExchangeMealTemplatesMutation(context, mutationVars);
  },
};

const runEditExchangeMealTemplateMutation = (context: ResolverContext, variables: EditExchangeMealTemplateMutationVariables) => {
  const { mealTemplateId } = variables.input;
  const mutation = context.client.mutate;
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  mutation<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>({
    mutation: EditExchangeMealTemplateDocument,
    variables,
    updateQueries: { GetEditMealPlan: result => result },
    update: (_, { data, errors }) => {
      if (errors) {
        console.error("Mutation failed!!!");
        return;
      }

      if (data) {
        updateOrFinishMealTemplateMutation(mealTemplateId, data.editExchangeMealTemplate.exchangeMealTemplate, context);
      }
    },
  });
};

const getMealTemplateIdsFromVariables = (variables: EditExchangeMealTemplatesMutationVariables): readonly string[] => {
  const input = variables.input;
  return isEditExchangeMealTemplateInputArray(input) ? input.map(({ mealTemplateId }) => mealTemplateId) : [input.mealTemplateId];
};

const isEditExchangeMealTemplateInputArray = (obj: any): obj is readonly EditExchangeMealTemplateInput[] => {
  return Array.isArray(obj);
};

const runEditExchangeMealTemplatesMutation = (context: ResolverContext, variables: EditExchangeMealTemplatesMutationVariables) => {
  const mealTemplateIds = getMealTemplateIdsFromVariables(variables);
  const mutation = context.client.mutate;
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  mutation<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>({
    mutation: EditExchangeMealTemplatesDocument,
    variables,
    updateQueries: { GetEditMealPlan: result => result },
    update: (_, { data, errors }) => {
      if (errors) {
        console.error("Mutation failed!!!");
        return;
      }

      if (data) {
        mealTemplateIds.forEach(mtId => {
          const serverMealTemplate = data.editExchangeMealTemplates.exchangeMealTemplates.find(mt => mt.id === mtId);
          if (serverMealTemplate) {
            updateOrFinishMealTemplateMutation(mtId, serverMealTemplate, context);
          }
        });
      }
    },
  });
};

const mergeExchangeMealTemplateWithMutationVars = (
  mealTemplates: readonly EditFullExchangeMealTemplateFragment[],
  vars: EditExchangeMealTemplatesMutationVariables,
  context: ResolverContext
): EditFullExchangeMealTemplateFragment[] => {
  return mealTemplates.map(mt => {
    const input = vars.input;
    const matchingVars = isEditExchangeMealTemplateInputArray(input)
      ? input.find(({ mealTemplateId }) => mealTemplateId === mt.id)
      : input.mealTemplateId === mt.id
      ? input
      : null;
    if (!matchingVars) return mt;
    return {
      ...mt,
      exchangeTargets: mergeExchangeTargetsWithInputs(
        mt.exchangeTargets || [],
        matchingVars.exchangeTargets.map(v => ({
          ...v,
          amountInput: String(v.amount),
        })),
        context
      ),
      isAutosaving: true,
    };
  });
};

// Compares meal template in local cache vs. mutation result. If different, send subsequent mutation, else set isAutosaving to false.
const updateOrFinishMealTemplateMutation = (
  mealTemplateId: string,
  serverMealTemplate: FullExchangeMealTemplateFragment,
  context: ResolverContext
) => {
  const editFragment = getFragment(mealTemplateId, context);
  const currentExchangeTargets = editFragment.exchangeTargets || [];
  const exchangeTargetInputs = getExchangeTargetDiffAsInputs({
    currentExchangeTargets,
    serverExchangeTargets: serverMealTemplate.exchangeTargets || [],
  });

  if (exchangeTargetInputs.length) {
    const newVariables = {
      input: {
        mealTemplateId,
        exchangeTargets: exchangeTargetInputs.flatMap(({ exchangeId, amountInput, pickListServingIds }) => {
          const amount = inputToNumber(amountInput);
          return amount === null ? [] : [{ exchangeId, amount, pickListServingIds }];
        }),
      },
    };
    writeFragment(
      {
        ...editFragment,
        exchangeTargets: mergeExchangeTargetsWithInputs(currentExchangeTargets, exchangeTargetInputs, context),
      },
      context
    );
    runEditExchangeMealTemplateMutation(context, newVariables);
  } else {
    writeFragment(
      {
        ...editFragment,
        isAutosaving: false,
      },
      context
    );
  }
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent, Step, StepLabel, Stepper } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { OrgGroupForm } from "apps/web/src/components/OrgGroup/OrgGroupForm";
import { OrgGroupFormSchema, OrgGroupFormType, orgGroupFormDefaultValues } from "apps/web/src/components/OrgGroup/OrgGroupFormSchema";
import { OrgGroupPermissionForm } from "apps/web/src/components/OrgGroup/OrgGroupPermissionForm";
import { EditableOrgGroupFragment } from "apps/web/src/types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface BaseOrgGroupModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (orgGroup: OrgGroupFormType) => void;
  title: string;
  orgGroup?: EditableOrgGroupFragment;
}

const steps = ["Org Selection", "Permissions"];

export const BaseOrgGroupModal = ({ open, onClose, onSubmit, title, orgGroup }: BaseOrgGroupModalProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const form = useForm<OrgGroupFormType>({
    defaultValues: orgGroupFormDefaultValues(orgGroup),
    resolver: zodResolver(OrgGroupFormSchema),
  });

  const handleInfoSubmit = form.handleSubmit(() => setActiveStep(1), console.error);
  const handlePermissionSubmit = form.handleSubmit(orgGroup => {
    onSubmit(orgGroup);
    onClose();
  });

  const onNext = () => {
    if (activeStep === 0) {
      return handleInfoSubmit();
    }

    return handlePermissionSubmit();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={title} onClose={onClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Stepper activeStep={activeStep} sx={{ mb: 2, alignSelf: "center", "&": { width: "50%" } }}>
          {steps.map(label => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box height="485px">
          {activeStep === 0 && <OrgGroupForm form={form} />}
          {activeStep === 1 && <OrgGroupPermissionForm form={form} />}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="outlined"
            disabled={activeStep === 0}
            onClick={() => setActiveStep(0)}>
            Back
          </Button>
          <Button onClick={onNext}>{activeStep === 0 ? "Next" : "Save"}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";
import { Route, Routes, useParams } from "react-router-dom-v5-compat";
import { useOrgNavQuery } from "../../../../types";
import { getLink } from "../../../NavUtils";
import { SuperuserRoute } from "../../ProtectedRoutes";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { orgManagementLink, orgsLink } from "../OrgManagement/OrgManagementRouter";
import { StaffOrgPage } from "./StaffOrgPage";

export const getNavStaffOrgPath = (orgId: string) => `/staff/orgs/${orgId}`;
export const getNavStaffOrgLink = (orgId: string, orgName: string) => getLink(orgName, getNavStaffOrgPath(orgId));

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "Org",
  current,
  breadCrumbs: [orgManagementLink, orgsLink],
  tabs: [],
  enableAthleteSearch: true,
});

export const OrgRouter = () => {
  const { orgId: maybeOrgId } = useParams();
  const orgId = maybeOrgId || "";
  let orgName = "";
  const { data, loading } = useOrgNavQuery({ variables: { id: orgId } });

  if (!loading && data) {
    const {
      org: { name },
    } = data;
    orgName = name;
  }

  const current = getNavStaffOrgLink(orgId, orgName);
  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  return (
    <Routes>
      <Route element={orgName && <SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route
          index
          element={
            <SuperuserRoute>
              <StaffOrgPage />
            </SuperuserRoute>
          }
        />
      </Route>
    </Routes>
  );
};

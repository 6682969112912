import { SwitchProps, Theme } from "@mui/material";

export const muiSwitch = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: SwitchProps; theme: Theme }) => {
      const { size } = ownerState;
      return {
        width: size === "medium" ? 44 : 36,
        height: size === "medium" ? 24 : 20,
        padding: 0,
        margin: 0,
      };
    },
    switchBase: ({ ownerState, theme }: { ownerState: SwitchProps; theme: Theme }) => {
      const { size } = ownerState;
      const {
        palette: { info },
      } = theme;
      return {
        padding: 0,
        margin: size === "medium" ? 2 : "-2px",
        "&.Mui-checked": {
          color: "white",
          transform: size === "medium" ? "translateX(20px)" : "translateX(18px)",
          "& + .MuiSwitch-track": {
            backgroundColor: info.main,
            opacity: 1,
          },
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.6,
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color: "white",
          opacity: 1,
        },
      };
    },
    thumb: ({ ownerState, theme }: { ownerState: SwitchProps; theme: Theme }) => {
      const { size } = ownerState;

      return {
        width: size === "medium" ? 20 : 16,
        height: size === "medium" ? 20 : 16,
      };
    },
    track: ({ ownerState, theme }: { ownerState: SwitchProps; theme: Theme }) => {
      const { size } = ownerState;
      const {
        palette: { greyscale },
      } = theme;
      return {
        borderRadius: size === "medium" ? 24 / 2 : 20 / 2,
        backgroundColor: greyscale[500],
        opacity: 1,
      };
    },
  },
};

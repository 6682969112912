import { IFileImport } from "../../../../universal/Import/Button";
import { loadTsv } from "../../../../../utils/import/load";
import { parseDelimitedFile } from "../../../../../utils/import/parse";
import { ImportableAnthroEntry } from "../types";
import { subYears } from "date-fns";
import { parseMassField, parsePercentField } from "./utils";

interface MostRecentIDxaFileImportFields {
  "Last Name": string;
  "First Name": string;
  "Dept ID": string;
  "Most Recent Measurement Date": string;
  "Measure Date": string;
  Height: string;
  "Fat Mass - Total": number;
  "Lean Mass - Total": number;
  "Bone Mass - Total": number;
  "Tissue %Fat - Total": number;
}

type MostRecentIDxaFileImportFieldsRaw = {
  [key in keyof MostRecentIDxaFileImportFields]: string;
};

export const msg = `
This imports the "Most Recent" entry for every selected athlete on the GE Lunar iDXA machine. Data in the file should be in lbs.
`;

export const iDXAMatchFields: (keyof ImportableAnthroEntry)[] = ["firstName" as const, "lastName" as const, "datetime" as const];

type I = ImportableAnthroEntry;

const numberFields = [
  {
    fields: ["Fat Mass - Total" as const],
    required: true,
    importableField: "bodyFatMass" as const,
    transform: parseMassField,
  },
  {
    fields: ["Lean Mass - Total" as const],
    required: true,
    importableField: "leanBodyMass" as const,
    transform: parseMassField,
  },
  {
    fields: ["Bone Mass - Total" as const],
    required: true,
    importableField: "boneMass" as const,
    transform: parseMassField,
  },
  {
    fields: ["Tissue %Fat - Total" as const],
    required: true,
    importableField: "percentBodyFat" as const,
    transform: parsePercentField,
  },
  { fields: ["Height" as const], importableField: "height" as const },
];
const stringFields = [
  {
    fields: ["Last Name" as const],
    required: true,
    importableField: "lastName" as const,
  },
  {
    fields: ["First Name" as const],
    required: true,
    importableField: "firstName" as const,
  },
  // {field: 'Dept ID' as const, importableField: 'teamName' as const},
]; // More on 'as const': https://stackoverflow.com/a/55387357/3923962

const dateFields = [
  {
    fields: ["Measure Date" as const, "Most Recent Measurement Date" as const],
    required: true,
    importableField: "datetime" as const,
  },
];
export const fields = [...numberFields, ...stringFields, ...dateFields];

export const loadMostRecentIdxaRows = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  // type T = MostRecentIDxaFileImportFields;
  type F = MostRecentIDxaFileImportFieldsRaw;
  const rows = await loadTsv<F>({ file, onError });
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I & { boneMass: number }>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return parsedRows
    .filter(row => new Date(row.datetime) > subYears(new Date(), 5))
    .map(({ boneMass, bodyFatMass, leanBodyMass, ...rest }) => ({
      bodyFatMass,
      leanBodyMass,
      boneMass,
      ...rest,
      type: "dexa",
      weight: boneMass + (bodyFatMass as number) + (leanBodyMass as number),
    }));
};

import { sortByKey } from "@notemeal/utils/sort";
import {
  AddMealOptionDocument,
  AddMealOptionMutation,
  AddMealOptionMutationVariables,
  EditFullMealTemplateFragment,
  EditFullMealTemplateFragmentDoc,
  FullMealOptionFragment,
  FullMealOptionPendingFragment,
  FullMealTemplateFragment,
  MutationAddMealOptionLocalArgs,
  MutationRemoveMealOptionLocalArgs,
  MutationRemoveMealOptionPendingLocalArgs,
  MutationSelectMealOptionArgs,
  MutationShareMealOptionPendingLocalArgs,
  RemoveMealOptionDocument,
  RemoveMealOptionMutation,
  RemoveMealOptionMutationVariables,
  RemoveMealOptionPendingDocument,
  RemoveMealOptionPendingMutation,
  RemoveMealOptionPendingMutationVariables,
  ShareMealOptionPendingDocument,
  ShareMealOptionPendingMutation,
  ShareMealOptionPendingMutationVariables,
} from "../../types";
import { getNextPosition } from "../../utils/getNextPosition";
import { createMealOption, initEditMealOptionFragment } from "./MealOption";
import { ResolverContext } from "./types";
import { makeFragmentFuncsWithInit } from "./utils";
import { initEditMealOptionPendingFragment } from "./MealOptionPending";

const mealOptionPreviewsToSelectedId = (
  mealOptions: readonly FullMealOptionFragment[],
  pendingMealOptions: readonly FullMealOptionPendingFragment[]
): string | null => {
  const sortedMealOptions = sortByKey(mealOptions, "position");
  return pendingMealOptions.length ? pendingMealOptions[0].id : sortedMealOptions.length ? sortedMealOptions[0].id : null;
};

export const initEditMealTemplateFragment = ({
  mealOptions,
  pendingMealOptions,
  ...mealTemplate
}: FullMealTemplateFragment): EditFullMealTemplateFragment => {
  return {
    ...mealTemplate,
    selectedMealOptionId: mealOptionPreviewsToSelectedId(mealOptions, pendingMealOptions),
    isAutosaving: false,
    newMealOptionIds: [],
    mealOptions: mealOptions.map(initEditMealOptionFragment),
    pendingMealOptions: pendingMealOptions.map(initEditMealOptionPendingFragment),
  };
};

export const macroEditFragmentFuncs = makeFragmentFuncsWithInit(
  "MacroMealTemplate",
  "EditFullMealTemplate",
  EditFullMealTemplateFragmentDoc,
  initEditMealTemplateFragment
);

export const exchangeEditFragmentFuncs = makeFragmentFuncsWithInit(
  "ExchangeMealTemplate",
  "EditFullMealTemplate",
  EditFullMealTemplateFragmentDoc,
  initEditMealTemplateFragment
);

const getEditFragmentFuncs = (type: string) => {
  if (type === "MacroMealTemplate") return macroEditFragmentFuncs;
  if (type === "ExchangeMealTemplate") return exchangeEditFragmentFuncs;
  throw Error("Invalid type for getEditFragmentFuncs");
};

export const mutationResolverMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectMealOption: (src: any, { mealTemplateId, mealOptionId, type }: MutationSelectMealOptionArgs, context: ResolverContext) => {
    const { getFragment, writeFragment } = getEditFragmentFuncs(type);
    const editFragment = getFragment(mealTemplateId, context);
    writeFragment(
      {
        ...editFragment,
        selectedMealOptionId: mealOptionId,
      },
      context
    );
  },

  shareMealOptionPendingLocal: (
    src: any,
    { mealTemplateId, mealOptionPendingId }: MutationShareMealOptionPendingLocalArgs,
    context: ResolverContext
  ) => {
    const { getFragment, writeFragment } = getEditFragmentFuncs("MacroMealTemplate");
    const { client } = context;
    const { pendingMealOptions, mealOptions, selectedMealOptionId, ...editFragment } = getFragment(mealTemplateId, context);
    const newPendingMealOptions = pendingMealOptions.filter(pendingMealOption => pendingMealOption.id !== mealOptionPendingId);

    writeFragment(
      {
        ...editFragment,
        mealOptions,
        pendingMealOptions: newPendingMealOptions,
        selectedMealOptionId: mealOptionPreviewsToSelectedId(mealOptions, newPendingMealOptions),
      },
      context
    );

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.mutate<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>({
      mutation: ShareMealOptionPendingDocument,
      variables: {
        input: {
          mealOptionPendingId,
        },
      },
      update: (_, { data, errors }) => {
        if (errors) {
          console.error("Mutation failed!!!");
          return;
        }

        if (data) {
          const editFragment = getFragment(mealTemplateId, context);
          const { mealOptions } = editFragment;
          const { id, position, name, note, servingAmounts } = data.shareMealOptionPending.mealOption;

          // In place edit of newMealOptionIds
          const removeMealOptionId = editFragment.newMealOptionIds[0];
          const replaceMealOption = { ...createMealOption(position, id), name, note, servingAmounts };
          writeFragment(
            {
              ...editFragment,
              newMealOptionIds: editFragment.newMealOptionIds.filter(moId => moId !== removeMealOptionId),
              mealOptions: mealOptions.filter(mo => mo.id !== removeMealOptionId).concat([replaceMealOption]),
              selectedMealOptionId: replaceMealOption.id,
            },
            context
          );
        }
      },
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addMealOptionLocal: (src: any, { mealTemplateId, type }: MutationAddMealOptionLocalArgs, context: ResolverContext) => {
    const { getFragment, writeFragment } = getEditFragmentFuncs(type);
    const { client } = context;
    const { mealOptions, newMealOptionIds, ...editFragment } = getFragment(mealTemplateId, context);
    const nextPosition = getNextPosition(mealOptions);
    const newMealOption = createMealOption(nextPosition);

    writeFragment(
      {
        ...editFragment,
        newMealOptionIds: newMealOptionIds.concat([newMealOption.id]),
        mealOptions: mealOptions.concat([newMealOption]),
      },
      context
    );

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.mutate<AddMealOptionMutation, AddMealOptionMutationVariables>({
      mutation: AddMealOptionDocument,
      variables: {
        input: {
          mealTemplateId,
          mealOption: {
            name: null,
            note: null,
            position: nextPosition,
            servingAmounts: [],
          },
        },
      },
      update: (_, { data, errors }) => {
        if (errors) {
          console.error("Mutation failed!!!");
          return;
        }

        if (data) {
          const editFragment = getFragment(mealTemplateId, context);
          const { mealOptions } = editFragment;
          const { id, position } = data.addMealOption.mealOption;

          // In place edit of newMealOptionIds
          const removeMealOptionId = editFragment.newMealOptionIds[0];
          const replaceMealOption = createMealOption(position, id);
          writeFragment(
            {
              ...editFragment,
              newMealOptionIds: editFragment.newMealOptionIds.filter(moId => moId !== removeMealOptionId),
              mealOptions: mealOptions.filter(mo => mo.id !== removeMealOptionId).concat([replaceMealOption]),
              selectedMealOptionId: replaceMealOption.id,
            },
            context
          );
        }
      },
    });
  },

  removeMealOptionLocal: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    src: any,
    { mealTemplateId, type, mealOptionId }: MutationRemoveMealOptionLocalArgs,
    context: ResolverContext
  ) => {
    const { getFragment, writeFragment } = getEditFragmentFuncs(type);
    const { client } = context;
    const { mealOptions, pendingMealOptions, selectedMealOptionId, ...editFragment } = getFragment(mealTemplateId, context);
    const newMealOptions = mealOptions.filter(mo => mo.id !== mealOptionId);

    writeFragment(
      {
        ...editFragment,
        mealOptions: newMealOptions,
        pendingMealOptions,
        selectedMealOptionId:
          selectedMealOptionId === mealOptionId ? mealOptionPreviewsToSelectedId(newMealOptions, pendingMealOptions) : selectedMealOptionId,
      },
      context
    );

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.mutate<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>({
      mutation: RemoveMealOptionDocument,
      variables: {
        input: {
          mealOptionId,
        },
      },
      update: (_, { errors }) => {
        if (errors) {
          console.error("Mutation failed!!!");
          return;
        }
      },
    });
  },

  removeMealOptionPendingLocal: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    src: any,
    { mealTemplateId, type, mealOptionPendingId }: MutationRemoveMealOptionPendingLocalArgs,
    context: ResolverContext
  ) => {
    const { getFragment, writeFragment } = getEditFragmentFuncs(type);
    const { client } = context;
    const { pendingMealOptions, mealOptions, selectedMealOptionId, ...editFragment } = getFragment(mealTemplateId, context);
    const newPendingMealOptions = pendingMealOptions.filter(pendingMealOption => pendingMealOption.id !== mealOptionPendingId);

    writeFragment(
      {
        ...editFragment,
        mealOptions,
        pendingMealOptions: newPendingMealOptions,
        selectedMealOptionId: mealOptionPreviewsToSelectedId(mealOptions, newPendingMealOptions),
      },
      context
    );

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.mutate<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>({
      mutation: RemoveMealOptionPendingDocument,
      variables: {
        input: {
          mealOptionPendingId,
        },
      },
      update: (_, { errors }) => {
        if (errors) {
          console.error("Mutation failed!!!");
          return;
        }
      },
    });
  },
};

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { StaffDashboardRestaurantFullFragment, useEditRestaurantMutation } from "../../../../types";
import Form from "../Form";
import { buildInitialEditFormState, buildRestaurantFormTooltips, restaurantFormReducer } from "../Form/utils";

interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  onEdit: (restaurantName: string) => void;
  restaurant: StaffDashboardRestaurantFullFragment;
}

const EditDialog = ({ open, onClose, onEdit, restaurant }: EditDialogProps) => {
  const { setMessage } = useSnackbar();

  const [state, dispatch] = useReducer(restaurantFormReducer, buildInitialEditFormState(restaurant));

  const canSaveTooltips = buildRestaurantFormTooltips(state);

  const [editRestaurant] = useEditRestaurantMutation({
    onCompleted: data => {
      onEdit(data.editRestaurant.restaurant.name);
    },
    onError: e => setMessage("error", e.message),
  });

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    const { restaurantType, paymentMethods, cuisines, ...rest } = state;
    editRestaurant({
      variables: {
        input: {
          restaurantId: restaurant.id,
          restaurantTypeId: restaurantType?.id || null,
          paymentMethodIds: paymentMethods.map(pm => pm.id),
          cuisineIds: cuisines.map(c => c.id),
          ...rest,
        },
      },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={`Edit ${restaurant.name}`} onClose={onClose} />
      <DialogContent>
        <Form state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

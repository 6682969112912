import { Box, SxProps, Tab, Tabs } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { Semibold } from "../../pages/Theme/Common/TypographyCommon";

export interface IconTab {
  label: string;
  icon: ReactElement;
  iconPosition?: "start" | "end";
}

export interface ElementTab {
  label: string;
  element: ReactElement;
}
export type TabType = string | IconTab | ElementTab;

const isIconTab = (tab: TabType): tab is IconTab => (tab as IconTab).icon !== undefined;
const isElementTab = (tab: TabType): tab is ElementTab => (tab as ElementTab).element !== undefined;
const getLabel = (tab: TabType) => (isIconTab(tab) || isElementTab(tab) ? tab.label : tab);

interface TWTabGroupProps {
  sx?: SxProps;
  tabs: TabType[];
  onSelected: (selected: string) => void;
  outsideSelected?: string;
}

export const TWTabGroup = ({ sx, tabs, onSelected, outsideSelected }: TWTabGroupProps) => {
  const [selected, setSelected] = useState(0);
  const handleSelected = (selected: number) => {
    setSelected(selected);
    const selectedTab = tabs[selected];
    const selectedString = getLabel(selectedTab);
    onSelected(selectedString);
  };
  const outsideSelectedIndex = tabs.findIndex(tab => getLabel(tab) === outsideSelected);

  return (
    <Box sx={{ display: "flex", ...sx }}>
      <Tabs
        sx={{
          "& .MuiTabs-scrollButtons": {
            "&.Mui-disabled": {
              opacity: 0.8,
            },
          },
          "& .MuiTabs-flexContainer": {
            height: 44,
            minHeight: 44,
          },
          border: 1,
          borderColor: "greyscale.200",
          borderRadius: 1.5,
          backgroundColor: "greyscale.50",
        }}
        TabIndicatorProps={{ sx: { height: 0 } }}
        variant="scrollable"
        scrollButtons="auto"
        value={outsideSelected ? outsideSelectedIndex : selected}
        onChange={(e, value) => handleSelected(value)}
      >
        {tabs.map(tab => {
          const labelString = isIconTab(tab) || isElementTab(tab) ? tab.label : tab;
          const label = isIconTab(tab) ? tab.label : isElementTab(tab) ? tab.element : tab;
          const icon = isIconTab(tab) ? tab.icon : undefined;
          const iconPosition = isIconTab(tab) ? tab.iconPosition || "start" : undefined;

          return (
            <Tab
              sx={{
                height: 40,
                minHeight: 40,
                m: 0.25,
                px: 2,
                py: 0.75,
                fontSize: 14,
                fontWeight: Semibold,
                backgroundColor: "greyscale.50",
                borderRadius: 1,
                "&.Mui-selected": {
                  boxShadow: 1,
                  border: 1,
                  borderColor: "greyscale.200",
                  backgroundColor: "white",
                },
                "&:hover": {
                  color: "text.secondary",
                  backgroundColor: "greyscale.200",
                },
              }}
              icon={icon}
              iconPosition={iconPosition}
              key={labelString}
              label={label}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { TWButtonGroup } from "./TWButtonGroup";

export const TWButtonGroupExample = () => {
  const BUTTON1 = "button1";
  const BUTTON2 = "button2";
  const BUTTON3 = "button3";
  const [selected, setSelected] = useState(BUTTON1);
  const buttons = [BUTTON1, BUTTON2, BUTTON3];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TWButtonGroup
        buttons={buttons}
        onSelected={selected => {
          setSelected(selected);
        }}
      />
      <Typography>{`Selected button: ${selected}`}</Typography>
    </Box>
  );
};

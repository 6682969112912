import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import MenuOrderFormItems from "./Items";

import { PickupTime } from "@notemeal/shared/ui/MenuOrder/usePickupTimeState";
import { MenuOrderItemWithAppearance } from "@notemeal/shared/ui/MenuOrder/utils";
import TimePicker from "./TimePicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    timePicker: {
      minWidth: 100,
    },
  })
);

interface MenuOrderFormForLogProps {
  menuOrderItems: readonly MenuOrderItemWithAppearance[];
  selectedItemId: string | null;
  onSelectItem: (item: MenuOrderItemWithAppearance) => void;
  onRemoveItem: (menuOrderItemId: string) => void;
  showTimePicker: boolean;
  pickupTime: PickupTime | null;
  logPickupTimes: PickupTime[];
  onChangePickupTime: (pickupTime: PickupTime) => void;
  mealMenuTimezone: string;
  openTimePicker?: boolean;
}

const MenuOrderFormForLog = ({
  onRemoveItem,
  menuOrderItems,
  showTimePicker,
  onChangePickupTime,
  pickupTime,
  logPickupTimes,
  mealMenuTimezone,
  onSelectItem,
  selectedItemId,
  openTimePicker,
}: MenuOrderFormForLogProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h3">
          <strong>Logged Items</strong>
        </Typography>
        {showTimePicker && (
          <TimePicker
            className={classes.timePicker}
            forOrder={false}
            pickupTime={pickupTime}
            onChangePickupTime={onChangePickupTime}
            pickupTimes={logPickupTimes}
            mealMenuTimezone={mealMenuTimezone}
            disabled={false}
            openTimePicker={openTimePicker}
          />
        )}
      </div>
      <MenuOrderFormItems
        onSelectItem={onSelectItem}
        onRemoveItem={onRemoveItem}
        selectedItemId={selectedItemId}
        menuOrderItems={menuOrderItems}
        disabled={false}
      />
    </>
  );
};

export default MenuOrderFormForLog;

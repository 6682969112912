import { Dialog, DialogContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { NamedTagForEditFragment, useNamedTagForEditQuery } from "../../../../types";

const useStyles = makeStyles((theme: Theme) => createStyles({ paper: { height: "calc(100vh - 64px)" } }));

interface NamedTagDataLoaderDialogProps {
  tagId: string;
  open: boolean;
  onClose: () => void;
  renderContent: (namedTag: NamedTagForEditFragment) => JSX.Element;
  title: string;
}

const NamedTagDataLoaderDialog = ({ tagId, open, onClose, title, renderContent }: NamedTagDataLoaderDialogProps) => {
  const classes = useStyles();
  const { data, loading } = useNamedTagForEditQuery({ variables: { id: tagId } });

  const namedTag = loading || !data ? null : data.namedTag;

  return (
    <Dialog
      sx={{ height: "100%" }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={classes}>
      <DialogTitle title={title} onClose={onClose} />
      {namedTag ? (
        renderContent(namedTag)
      ) : (
        <DialogContent>
          <Loading />
        </DialogContent>
      )}
    </Dialog>
  );
};

export default NamedTagDataLoaderDialog;

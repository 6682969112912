import { Box, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
import MenuTypeSelect, { MenuTypeSelectProps } from "./SelectComponents/MenuTypeSelect";
import TeamMultiSelect, { MenusTeamMultiSelectProps } from "./SelectComponents/TeamMultiSelect";
import TimezoneSelect, { MenuTimezoneSelectProps } from "./SelectComponents/TimezoneSelect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: "100%",
    },
    top: { marginTop: theme.spacing(2) },
    bottom: { marginBottom: theme.spacing(2) },
  })
);

interface CalendarFiltersProps {
  timezoneSelectProps: MenuTimezoneSelectProps;
  teamSelectProps: MenusTeamMultiSelectProps;
  menuTypeSelectProps: MenuTypeSelectProps;
}

export const CalendarFilters = ({ timezoneSelectProps, teamSelectProps, menuTypeSelectProps }: CalendarFiltersProps) => {
  const classes = useStyles();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TimezoneSelect {...timezoneSelectProps} className={classNames([classes.select, timezoneSelectProps.className, classes.top])} />
      <TeamMultiSelect {...teamSelectProps} className={classNames([classes.select, teamSelectProps.className])} />
      <MenuTypeSelect {...menuTypeSelectProps} className={classNames([classes.select, menuTypeSelectProps.className, classes.bottom])} />
    </Box>
  );
};

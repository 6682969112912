import { Avatar, Button, Paper, TextField, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { sortByKey } from "@notemeal/utils/sort";
import classnames from "classnames";
import React from "react";
import WeekNavigation from "../../WeekNavigation";
import { DateWithServiceMenus } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { info, warning, common, text, getContrastText } }: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto 1fr",
      height: "100%",
      overflow: "auto",
    },
    header: {
      position: "sticky",
      gridRowStart: 1,
      gridColumnStart: 1,
      zIndex: 1,
      top: 0,
      backgroundColor: common.white,
    },
    subHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerDate: {
      flex: 1,
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
    },
    dateAvatar: {
      backgroundColor: common.white,
      color: text.secondary,
    },
    todayAvatar: {
      backgroundColor: info.main,
      color: getContrastText(info.main),
    },
    spacer: {
      flexGrow: 1,
    },
    fixedSpacer: {
      width: 175,
    },
    date: {
      flex: 1,
    },
    content: {
      display: "flex",
      gridRowStart: 2,
      gridColumnStart: 1,
    },
    serviceMenu: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      margin: spacing(0.5),
      padding: spacing(0.5),
    },
    ignoreServiceMenu: {
      backgroundColor: info.light,
    },
    importServiceMenu: {
      backgroundColor: info.main,
    },
    baseServiceMenu: {
      backgroundColor: warning.main,
    },
    station: {
      marginTop: spacing(1.5),
    },
    menuItem: {
      marginTop: spacing(0.5),
    },
    legendItem: {
      display: "flex",
      alignItems: "center",
      marginTop: spacing(),
    },
    legendColor: {
      height: 24,
      width: 24,
      borderRadius: 12,
      marginRight: spacing(),
    },
    selectAllButton: {
      marginBottom: spacing(),
    },
  })
);

const DAY_OF_WEEK_NAMES = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

interface MenusCbordDialogSelectServiceMenusProps {
  loading: boolean;
  datesWithMenus: readonly DateWithServiceMenus[];
  selectedMenuOids: readonly number[];
  onToggleMenu: (serviceMenuOid: number) => void;
  onSelectAllMenus: () => void;
  startOfWeekDate: Date;
  onChangeStartOfWeekDate: (startOfWeekDate: Date) => void;
  baseMenuName: string;
  onChangeBaseMenuName: (baseMenuName: string) => void;
}

const MenusCbordDialogSelectServiceMenus = ({
  loading,
  datesWithMenus,
  selectedMenuOids,
  onToggleMenu,
  onSelectAllMenus,
  startOfWeekDate,
  onChangeStartOfWeekDate,
  baseMenuName,
  onChangeBaseMenuName,
}: MenusCbordDialogSelectServiceMenusProps) => {
  const classes = useStyles();
  const today = serializeDate(new Date());

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.subHeader}>
          <div className={classes.fixedSpacer}>
            <div className={classes.legendItem}>
              <div className={classnames(classes.legendColor, classes.importServiceMenu)} />
              <Typography>Import</Typography>
            </div>
            <div className={classes.legendItem}>
              <div className={classnames(classes.legendColor, classes.ignoreServiceMenu)} />
              <Typography>Ignore</Typography>
            </div>
            <Tooltip title="Base menus will be added to the end of each imported menu on that day">
              <div className={classes.legendItem}>
                <div className={classnames(classes.legendColor, classes.baseServiceMenu)} />
                <Typography>Base</Typography>
              </div>
            </Tooltip>
          </div>
          <div className={classes.spacer} />
          <WeekNavigation startOfWeekDate={startOfWeekDate} onChange={onChangeStartOfWeekDate} />
          <div className={classes.spacer} />
          <div className={classes.fixedSpacer}>
            <Button onClick={onSelectAllMenus} className={classes.selectAllButton}>
              Select All Menus
            </Button>
            <TextField
              label="Base Menu Name"
              value={baseMenuName}
              onChange={e => onChangeBaseMenuName(e.target.value)} />
          </div>
        </div>
        <div className={classes.subHeader}>
          {datesWithMenus.map(({ date }, dayOfWeek) => {
            const isToday = date === today;
            const dayOfWeekColor = isToday ? "info" : ("textSecondary" as const);
            const avatarClassName = isToday ? classes.todayAvatar : classes.dateAvatar;

            return (
              <div key={date} className={classes.headerDate}>
                <Typography variant="subtitle1" color={dayOfWeekColor}>
                  {DAY_OF_WEEK_NAMES[dayOfWeek]}
                </Typography>
                <Avatar className={avatarClassName}>
                  <Typography variant="h3" color="inherit">
                    {parseDate(date).getDate()}
                  </Typography>
                </Avatar>
              </div>
            );
          })}
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className={classes.content}>
          {datesWithMenus.map(({ date, serviceMenus }) => {
            return (
              <div key={date} className={classes.date}>
                {serviceMenus.map(serviceMenu => {
                  const isBaseMenu = serviceMenu.meal === baseMenuName;
                  const selected = selectedMenuOids.includes(serviceMenu.oid);
                  return (
                    <Paper
                      key={serviceMenu.oid}
                      className={classnames(classes.serviceMenu, {
                        [classes.baseServiceMenu]: isBaseMenu,
                        [classes.importServiceMenu]: selected && !isBaseMenu,
                        [classes.ignoreServiceMenu]: !selected && !isBaseMenu,
                      })}
                      onClick={() => onToggleMenu(serviceMenu.oid)}
                    >
                      <Typography>{serviceMenu.meal}</Typography>
                      {sortByKey(serviceMenu.stations, "sort").map(station => (
                        <div className={classes.station} key={station.oid}>
                          <Typography variant="body2">{station.name}</Typography>
                        </div>
                      ))}
                    </Paper>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenusCbordDialogSelectServiceMenus;

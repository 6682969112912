import { addSelectionOptionsToMenuItem, MenuItemWithSelectionOptions } from "@notemeal/shared/ui/MenuItem/utils";
import { sortByKey } from "@notemeal/utils/sort";
import { getAthleteDisplayNameForOrder } from "../../Content/Table/utils";
import {
  OrderPageMenuItemFragment,
  OrderPageRestaurantMenuLinkDeliveryLocationFragment,
  OrderPageRestaurantMenuLinkOrderFragment,
  OrderPageRestaurantMenuLinkPlateFragment,
} from "../../../../types";
import { OrderPageMenuSelectionItem, OrderPageMenuSelectionItemOption } from "../types";

export type MenuItemRowInfo = {
  id: string;
  name: string;
  specialRequests: string | null;
  mappedChoices: readonly GroupedMappedChoice[];
};

export type MenuItemRowInfoWithAmount = MenuItemRowInfo & { amount: number };

export type MenuSelectionItemWithPossibleSpecialRequests = OrderPageMenuSelectionItem & {
  specialRequests?: string | null;
};

export type MenuSelectionRowInfo = {
  id: string;
  menuItems: readonly MenuItemRowInfoWithAmount[];
};

export interface OrderRowDataInfo extends MenuSelectionRowInfo {
  athleteName: string;
}

export interface PlateRowDataInfo extends MenuSelectionRowInfo {
  amount: number;
}

export interface CoverSheetInfo {
  deliveryLocation: OrderPageRestaurantMenuLinkDeliveryLocationFragment | null;
  instructions: string;
}

export type MenuItemChoice = MenuItemWithSelectionOptions<OrderPageMenuItemFragment>["choices"][0];
export type MenuItemChoiceOption = MenuItemChoice["options"][0];

export interface MappedOption {
  amount: number;
  menuItemChoiceOption: MenuItemChoiceOption;
  menuItemChoiceOptionPosition: number;
  canEditAmount: boolean;
}

export interface GroupedMappedChoice {
  menuItemChoice: MenuItemChoice;
  menuItemChoicePosition: number;
  options: readonly MappedOption[];
}

interface SerializeMenuItemFromMenuSelectionItemArgs {
  menuItem: OrderPageMenuItemFragment;
  specialRequests: string | null;
  amount: number;
  options: readonly OrderPageMenuSelectionItemOption[];
}

export interface SerializedMenuItem {
  menuItemRowInfo: MenuItemRowInfo;
  amount: number;
  key: string;
}

interface RestaurantMenuLinkSelections {
  orders: readonly OrderPageRestaurantMenuLinkOrderFragment[];
  plates: readonly OrderPageRestaurantMenuLinkPlateFragment[];
}

export const sortMapAndGroupOptions = (choices: readonly MenuItemChoice[]): readonly GroupedMappedChoice[] => {
  return sortByKey(
    choices.map((menuItemChoice: MenuItemChoice): GroupedMappedChoice => {
      const options = menuItemChoice.options.flatMap(option => {
        return option.menuSelectionItemOption
          ? [
              {
                amount: option.menuSelectionItemOption.amount,
                menuItemChoiceOption: option,
                menuItemChoiceOptionPosition: option.position,
                canEditAmount: option.canEditAmount,
              },
            ]
          : [];
      });
      return {
        menuItemChoicePosition: menuItemChoice.position,
        menuItemChoice,
        options: sortByKey(options, "menuItemChoiceOptionPosition"),
      };
    }),
    "menuItemChoicePosition"
  );
};

export const serializeMappedOption = (mappedOption: MappedOption): string => {
  return `${mappedOption.amount}:${mappedOption.menuItemChoiceOption.id}`;
};

export const serializeMappedOptions = (mappedOptions: readonly MappedOption[]): string => {
  return mappedOptions.map(serializeMappedOption).join(":");
};

export const serializeGroupedMappedChoice = (mappedChoice: GroupedMappedChoice): string => {
  return `${mappedChoice.menuItemChoice.id}:${serializeMappedOptions(mappedChoice.options)}`;
};

export const serializeGroupedMappedChoices = (mappedChoices: readonly GroupedMappedChoice[]) => {
  return mappedChoices.map(serializeGroupedMappedChoice).join(":");
};

const _serializeMenuItemFromMenuSelectionItem = ({
  menuItem,
  specialRequests,
  amount,
  options,
}: SerializeMenuItemFromMenuSelectionItemArgs): [MenuItemRowInfo, number, string] => {
  const menuItemWithOptions = addSelectionOptionsToMenuItem(menuItem, options);
  const menuItemId = menuItem.id;
  const sortedGroupedMappedChoices = sortMapAndGroupOptions(menuItemWithOptions.choices);
  const serializedChoices = serializeGroupedMappedChoices(sortedGroupedMappedChoices);

  const serializedMenuOrderItem = `${menuItemId}:${specialRequests}:${serializedChoices}`;

  return [
    {
      id: serializedMenuOrderItem,
      name: menuItem.name,
      specialRequests,
      mappedChoices: sortedGroupedMappedChoices,
    },
    amount,
    serializedMenuOrderItem,
  ];
};

export const serializeMenuItemFromMenuSelectionItem = (
  menuSelectionItem: MenuSelectionItemWithPossibleSpecialRequests
): [MenuItemRowInfo, number, string] => {
  const menuItem = menuSelectionItem.menuItem;
  const specialRequests = menuSelectionItem.specialRequests || null;
  const amount = menuSelectionItem.amount;
  const options = menuSelectionItem.options;

  return _serializeMenuItemFromMenuSelectionItem({
    menuItem,
    specialRequests,
    amount,
    options,
  });
};

export const getSerializedMenuItemsFromRestaurantMenuLinkOrder = (
  order: OrderPageRestaurantMenuLinkOrderFragment
): SerializedMenuItem[] => {
  return order.items
    .map(item => {
      const [menuItemRowInfo, amount, key] = serializeMenuItemFromMenuSelectionItem(item);
      return {
        menuItemRowInfo,
        amount,
        key,
      };
    })
    .filter(({ amount }) => amount > 0);
};

export const getSerializedMenuItemsFromRestaurantMenuLinkOrders = (orders: readonly OrderPageRestaurantMenuLinkOrderFragment[]) => {
  return orders.flatMap(getSerializedMenuItemsFromRestaurantMenuLinkOrder);
};

//considers bulk orders and athlete plate orders
export const getSerializedMenuItemsFromRestaurantMenuLinkPlate = (
  plate: OrderPageRestaurantMenuLinkPlateFragment
): SerializedMenuItem[] => {
  const bulkOrderPlateItems = plate.items
    .map(item => {
      const [menuItemRowInfo, amount, key] = serializeMenuItemFromMenuSelectionItem(item);
      return {
        menuItemRowInfo,
        amount: amount * ((plate.bulkOrderAmount || 0) + plate.orders.length),
        key,
      };
    })
    .filter(({ amount }) => amount > 0);
  return bulkOrderPlateItems;
};

export const getSerializedMenuItemsFromRestaurantMenuLinkPlates = (plates: readonly OrderPageRestaurantMenuLinkPlateFragment[]) => {
  return plates.flatMap(getSerializedMenuItemsFromRestaurantMenuLinkPlate);
};

export const combineSerializedMenuItems = (items: SerializedMenuItem[]): SerializedMenuItem[] => {
  const initAcc: Record<SerializedMenuItem["key"], SerializedMenuItem> = {};
  return Object.values(
    items.reduce(
      (acc, item) => ({
        ...acc,
        [item.key]: {
          ...item,
          amount: item.amount + (acc[item.key]?.amount ?? 0),
        },
      }),
      initAcc
    )
  );
};

export const getSerializedMenuItemsFromRMLOrdersAndPlates = ({ orders, plates }: RestaurantMenuLinkSelections) => {
  const serializedMenuItems = [
    ...getSerializedMenuItemsFromRestaurantMenuLinkPlates(plates),
    ...getSerializedMenuItemsFromRestaurantMenuLinkOrders(orders),
  ];

  return combineSerializedMenuItems(serializedMenuItems);
};

export const getMenuItemsMaxLength = (selectionRowDataInfoArray: readonly MenuSelectionRowInfo[]) => {
  const menuItemLengths = selectionRowDataInfoArray.map(({ menuItems }) => menuItems.length);
  const maxLength = Math.max(...menuItemLengths, 1);

  return maxLength;
};

export const getIndexArray = (length: number): number[] => {
  if (length <= 0) {
    return [];
  }
  return [...Array(length).keys()];
};

export const mapItems = (items: readonly MenuSelectionItemWithPossibleSpecialRequests[]) => {
  return sortByKey(
    items.map(item => {
      const [menuItemRowInfo, amount] = serializeMenuItemFromMenuSelectionItem(item);
      return {
        ...menuItemRowInfo,
        amount,
      };
    }),
    "name"
  ).filter(({ amount }) => amount > 0);
};

export const formatRestaurantMenuLinkOrderRow = (menuOrder: OrderPageRestaurantMenuLinkOrderFragment): OrderRowDataInfo => {
  const menuItems = mapItems(menuOrder.items);
  const athlete = menuOrder.athlete;
  return {
    id: menuOrder.id,
    athleteName: athlete ? getAthleteDisplayNameForOrder(athlete) : menuOrder.userFullName,
    menuItems,
  };
};
export const formatRestaurantMenuLinkPlateOrderRow = (plate: OrderPageRestaurantMenuLinkPlateFragment): OrderRowDataInfo[] => {
  const plateItems = mapItems(plate.items);
  return plate.orders.map(o => {
    const athlete = o.athlete;
    return {
      id: plate.id,
      athleteName: athlete ? getAthleteDisplayNameForOrder(athlete) : o.userFullName,
      menuItems: plateItems,
    };
  });
};

export const formatRestaurantMenuLinkOrderRows = (
  menuOrders: readonly OrderPageRestaurantMenuLinkOrderFragment[],
  plates: readonly OrderPageRestaurantMenuLinkPlateFragment[]
): OrderRowDataInfo[] => {
  const pos = plates.flatMap(formatRestaurantMenuLinkPlateOrderRow);
  const mos = menuOrders.map(formatRestaurantMenuLinkOrderRow).filter(({ menuItems }) => menuItems.length > 0);
  return [...mos, ...pos];
};

export const formatRestaurantMenuLinkPlateRow = (plate: OrderPageRestaurantMenuLinkPlateFragment): PlateRowDataInfo => {
  const menuItems = mapItems(plate.items);

  return {
    id: plate.id,
    amount: plate.bulkOrderAmount || 0,
    menuItems,
  };
};

export const formatRestaurantMenuLinkPlateRows = (plates: readonly OrderPageRestaurantMenuLinkPlateFragment[]): PlateRowDataInfo[] => {
  return plates.map(formatRestaurantMenuLinkPlateRow).filter(({ menuItems, amount }) => menuItems.length > 0 && amount > 0);
};

export const formatMenuItemsRow = ({ amount, menuItemRowInfo }: SerializedMenuItem): MenuItemRowInfoWithAmount => ({
  ...menuItemRowInfo,
  amount,
});

export const filterAndJoin = (array: Array<string | null>, delimeter?: string) =>
  array.filter(element => element !== "" && element !== null).join(delimeter);

export const getOrderExportDetails = (
  deliveryLocation: OrderPageRestaurantMenuLinkDeliveryLocationFragment | null,
  instructions?: string
) => {
  const location = deliveryLocation ? formatDeliveryLocation(deliveryLocation) : null;
  const instructionsRow = instructions ? ["Instructions", instructions] : null;

  const orderSpecificRows = formatOrderExportSpecificRows(location, instructionsRow);

  return [
    ["Notemeal Restaurant Order Export"],
    ...orderSpecificRows,
    ["Sheets"],
    ordersByAthleteText,
    platesText,
    ordersAndPlatesByMenuItem,
  ];
};

const formatDeliveryLocation = (deliveryLocation: OrderPageRestaurantMenuLinkDeliveryLocationFragment) => [
  "Delivery Location",
  filterAndJoin([deliveryLocation.name, deliveryLocation.address ? deliveryLocation.address.displayName : ""], "\n"),
];

const formatOrderExportSpecificRows = (locationRow: string[] | null, instructionsRow: string[] | null) => {
  if (instructionsRow && locationRow) {
    return [[], locationRow, instructionsRow, []];
  } else if (locationRow) {
    return [[], locationRow, []];
  } else if (instructionsRow) {
    return [[], instructionsRow, []];
  } else {
    return [[]];
  }
};

const ordersByAthleteText = [
  "Orders by Athlete",
  "Each row shows an athlete with each item they've ordered. Athletes not listed have not placed an order.",
];
const platesText = [
  "Plates",
  "A 'plate' is a preset combination of menu items that are ordered together. Each row shows menu items included in the plate and the number ordered",
];
const ordersAndPlatesByMenuItem = [
  "Orders and Plates by Menu Item",
  "Each row shows a unique menu item with options and special requests, and the amount ordered. This includes both bulk orders by the staff as well as athlete orders.",
];

import { isNotemealProfileActive, LinkedProfile, teamworksProfileHasRequiredFields } from "@notemeal/profile-sync";
import React, { useState } from "react";
import { filterProfileForSearchTerm } from "../../utils";
import ProfilesList from "./ProfilesList";

interface LinkedProfilesListProps {
  profiles: readonly LinkedProfile[];
  selectedId: string | null;
  onUnlink?: (profile: LinkedProfile) => void;
  onUnlinkArchive?: (profile: LinkedProfile) => void;
}

const LinkedProfilesList = (props: LinkedProfilesListProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const searchProps = { searchTerm, setSearchTerm };
  const filteredProfiles = props.profiles.filter(p => {
    if (p.notemeal) {
      const { firstName, lastName } = p.notemeal.__typename === "Athlete" ? p.notemeal : p.notemeal.user;
      return filterProfileForSearchTerm({
        firstName,
        lastName,
        searchTerm,
      });
    }
    return false;
  });

  return (
    <ProfilesList
      {...props}
      searchProps={searchProps}
      title="Linked Profiles"
      columnMode={props.onUnlink ? "More" : "NoAction"}
      onUnlink={props.onUnlink}
      onUnlinkArchive={props.onUnlinkArchive}
      disableOnSelected
      profiles={filteredProfiles.map(p => ({ ...p, id: p.teamworks.id }))}
      getProfileForDisplay={({ teamworks, notemeal }) => ({
        firstName: teamworks.firstName,
        lastName: teamworks.lastName,
        email: teamworks.email,
        phoneNumber: teamworks.cellPhone,
        missingTeamworksRequiredFields: !teamworksProfileHasRequiredFields(teamworks),
        isArchived: notemeal ? !isNotemealProfileActive(notemeal) : false,
      })}
    />
  );
};

export default LinkedProfilesList;

import { z } from "zod";
import { MACRO_PROTOCOL_MIN_MACRO } from "../Utils";
import { rmrErrorLookup } from "./RmrValidation";

export const ERROR_MACRO_MINIMUM = "ERROR_MACRO_MINIMUM";

const MacroSchema = z.number().min(MACRO_PROTOCOL_MIN_MACRO, { message: ERROR_MACRO_MINIMUM });
export const MacroProtocolMacrosSchema = z.object({ cho: MacroSchema, pro: MacroSchema, fat: MacroSchema, kcal: z.number() });

export const DEFAULT_ERROR_MACRO_MINIMUM = "Macro nutrients cannot be negative.";
const defaultMacroProtocolErrorLookup = new Map<string, string>([[ERROR_MACRO_MINIMUM, DEFAULT_ERROR_MACRO_MINIMUM]]);
export const macroProtocolErrorLookup = new Map([...rmrErrorLookup, ...defaultMacroProtocolErrorLookup]);

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, MenuList, Paper, SxProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import Downshift, { ControllerStateAndHelpers, StateChangeTypes } from "downshift";
import React, { useState } from "react";
import { ConditionalRenderAddItemButtonProps, Entity } from "./type";
import {
  renderInput as renderInputDefault,
  renderInputProps,
  renderSuggestion as renderSuggestionDefault,
  renderSuggestionProps,
} from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    paper: {
      position: "absolute",
      zIndex: 100,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
    },
    resultsMenuList: {
      overflow: "auto",
      flexBasis: "auto",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    addItemButton: {
      flexGrow: 0,
      flexBasis: theme.spacing(4),
    },
    noPaddingList: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  })
);

export type renderSuggestionsProps<T extends Entity> = Pick<ControllerStateAndHelpers<T>, "inputValue"> & {
  renderSuggestion: (obj: T, index: number, inputValue: string | null) => React.ReactNode;
};

export interface SearchBarProps<T extends Entity> {
  objectToName: (obj: T) => string;
  onSelectObject: (obj: T) => void;
  renderSuggestion?: (props: renderSuggestionProps<T>, idx: number, inputValue: string | null) => React.ReactNode;
  renderInput?: (props: renderInputProps, helpers: ControllerStateAndHelpers<T>) => React.ReactNode;
  label?: string;
  menuClassName?: string;
  fullWidth?: boolean;
  renderAddItemButton?: (props: ConditionalRenderAddItemButtonProps) => React.ReactElement;
  autoFocus?: boolean;
  dropdown?: boolean;
  allowEmptyQuery?: boolean;
  iconColor?: string;
  sx?: SxProps;
}

interface RealSearchBarProps<T extends Entity> extends SearchBarProps<T> {
  renderSuggestions: (props: renderSuggestionsProps<T>) => React.ReactNode;
}

/*
 * DEPRECATED: Use MUI's Autocomplete or make a new version of this similar to how
 * we did for AutocompleteQuerySearchBar. Thank you!
 */

const SearchBar = <T extends Entity>({
  renderSuggestions,
  onSelectObject,
  label,
  objectToName,
  menuClassName,
  sx,
  fullWidth = false,
  renderSuggestion = renderSuggestionDefault,
  renderInput = renderInputDefault,
  renderAddItemButton = undefined,
  autoFocus = false,
  dropdown = false,
  allowEmptyQuery = false,
  iconColor,
}: RealSearchBarProps<T>) => {
  const classes = useStyles();
  const [inputValueSequence, setInputValueSequence] = useState([0, 0, 0, 0]);
  const objToString = (obj: T | null): string => {
    return obj === null ? "" : objectToName(obj);
  };

  const handleSelect = (obj: T, { setState }: { setState: (stateToSet: object) => void }) => {
    obj && onSelectObject(obj);
    setState({ inputValue: "" });
    setInputValueSequence([0, 0, 0, 0]);
  };

  const makeRenderSuggestion = (
    { getItemProps, highlightedIndex }: Pick<ControllerStateAndHelpers<T>, "getItemProps" | "highlightedIndex">,
    inputValue: string | null
  ) => {
    return (obj: T, index: number) =>
      renderSuggestion(
        {
          key: obj.id,
          value: objectToName(obj),
          obj,
          index,
          highlightedIndex,
          itemProps: getItemProps({ item: obj }),
        },
        index,
        inputValue
      );
  };

  const handleOuterClick = ({ setState }: ControllerStateAndHelpers<T>) => {
    setState({ inputValue: "" });
    setState({ isOpen: false });
  };

  const handleStateChange = (change: { type: StateChangeTypes }, { setState }: ControllerStateAndHelpers<T>) => {
    if (change.type === Downshift.stateChangeTypes.blurInput) {
      setState({ inputValue: "" });
    }
  };

  const handleInputValueChange = (inputValue: string | null) => {
    setInputValueSequence([...inputValueSequence.slice(1, 4), inputValue ? inputValue.length : 0]);
  };
  /*
  const userIsDeletingSearch = (): boolean => {
    const seq = inputValueSequence
    const deleting = seq.every((length, i) => i === 0 || (seq[i - 1] > length))
    return deleting
  }*/

  return (
    <Downshift
      itemToString={objToString}
      onSelect={handleSelect}
      onOuterClick={handleOuterClick}
      onStateChange={handleStateChange}
      onInputValueChange={handleInputValueChange}
    >
      {args => {
        const { getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex, setState } = args;
        return (
          <div className={classes.root}>
            {renderInput(
              {
                InputProps: getInputProps({
                  autoFocus,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: iconColor }} />
                    </InputAdornment>
                  ),
                }),
                onClick: () =>
                  setState({
                    // This is how we simulate a dropdown!
                    inputValue: dropdown && inputValue === "" ? "\x0d" : inputValue,
                    isOpen: isOpen || dropdown || allowEmptyQuery,
                  }),
                label,
                margin: "dense",
                variant: "outlined",
                fullWidth,
              } as renderInputProps,
              args
            )}
            {isOpen ? (
              <Paper
                {...getMenuProps()}
                sx={sx}
                className={classnames(classes.paper, menuClassName)}
                square>
                <div className={classes.resultsMenuList}>
                  <MenuList className={classes.noPaddingList}>
                    {renderSuggestions({
                      inputValue,
                      renderSuggestion: makeRenderSuggestion({ getItemProps, highlightedIndex }, inputValue),
                    })}
                  </MenuList>
                </div>
                {/*userIsDeletingSearch()*/}
                {renderAddItemButton && inputValue !== "" && (
                  <div className={classes.addItemButton}>
                    {renderAddItemButton({ hidden: inputValue === "" })} {/*!userIsDeletingSearch()})*/}
                  </div>
                )}
              </Paper>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

export default SearchBar;

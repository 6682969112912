import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { sortExchangeAmounts } from "../ExchangeAmount/utils";
import { ExchangeSummaryLabelFragment } from "../types";

export const useStyles = makeStyles({
  xs: {
    fontSize: ".6rem",
    lineHeight: 1.1,
  },
  padding: {
    padding: "4px",
  },
});

interface ExchangeSummaryLabelProps {
  targetExchangeAmounts: readonly ExchangeSummaryLabelFragment[];
  variant: "xs" | "sm" | "lg";
  consolidatePro: boolean;
  hideZeros?: boolean;
  intralabelPadding?: boolean;
}

const ExchangeSummaryLabel = ({
  targetExchangeAmounts,
  variant,
  consolidatePro,
  hideZeros,
  intralabelPadding = false,
}: ExchangeSummaryLabelProps) => {
  const classes = useStyles();
  const firstProTgt = targetExchangeAmounts.find(et => et.exchange.type === "protein");

  const consolidatedExchangeTargets =
    consolidatePro && firstProTgt
      ? [
          ...targetExchangeAmounts.filter(et => et.exchange.type !== "protein"),
          targetExchangeAmounts
            .filter(et => et.exchange.type === "protein" && et.id !== firstProTgt.id)
            .reduce((a, b) => ({ ...a, amount: a.amount + b.amount }), {
              ...firstProTgt,
              exchange: { ...firstProTgt.exchange, shortName: "Pro" },
            }),
        ]
      : targetExchangeAmounts;

  const sortedExchangeTargets = sortExchangeAmounts(consolidatedExchangeTargets);
  const finalExchangeTargets = sortedExchangeTargets.filter(et => !hideZeros || et.amount > 0);

  return (
    <Typography variant={variant === "lg" ? "subtitle1" : "caption"} classes={variant === "xs" ? { root: classes.xs } : {}} color="inherit">
      {finalExchangeTargets.map(et => (
        <span key={et.id} className={intralabelPadding ? classes.padding : undefined}>
          <span>{et.exchange.shortName}</span>: {et.amount}{" "}
        </span>
      ))}
    </Typography>
  );
};

export default ExchangeSummaryLabel;

export const inputToNumber = (input: string): number | null => {
  const inputNumber = Number(input);
  return !isNaN(inputNumber) && input !== "" ? inputNumber : null;
};

export const strictInputToNumber = (input: string): number => {
  const result = inputToNumber(input);
  if (result === null) {
    throw Error("Null not allowed in strictInputToNumber");
  }
  return result;
};

export const maybeNumberToString = (value: number | null): string => {
  return value ? value.toString() : "";
};

export const alphanumericRegex = /^[A-Za-z0-9]*$/;

import React from "react";
import { NotemealTeam } from "../reducer";
import TeamsList from "./TeamsList";

interface LinkedTeamsListProps {
  teams: NotemealTeam[];
  selectedId: string | null;
  onEdit: (team: NotemealTeam) => void;
}

const LinkedTeamsList = (props: LinkedTeamsListProps) => {
  const { selectedId } = props;
  return <TeamsList
    {...props}
    title="Linked Teams"
    columnMode={selectedId ? "NoAction" : "Edit"}
    disableOnSelected />;
};

export default LinkedTeamsList;

import Info from "@mui/icons-material/Info";
import Search from "@mui/icons-material/Search";
import {
  Checkbox,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import decamelize from "decamelize";
import React, { useState } from "react";
import { z } from "zod";
import { matchFieldsToText } from ".";
import { IMatchResult } from "../../../../utils/import/match";
import SearchBarDefault from "../../../universal/SearchBar/Default";
import { Entity } from "../../../universal/SearchBar/type";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchWrapper: {
      padding: theme.spacing(0, 2, 0, 3),
      position: "relative",
    },
    searchResultMenu: {
      width: "300px",
      zIndex: 99,
      position: "absolute",
    },
  })
);

const getPrimaryText = <T extends object>(row: T, matchedFields: (keyof T)[]): React.ReactNode => {
  return <Typography variant="body1">{matchFieldsToText(matchedFields, row)}</Typography>;
};

const getSecondaryText = <T extends object>(row: T, matchedRows: T[], matchedFields: (keyof T)[], error?: z.ZodError): React.ReactNode => {
  const ignoredKeys = ["teamName", "teamId"];

  let entries = Object.entries(row).filter(([key, value]) => Boolean(value) && !ignoredKeys.includes(key));
  const matchedValuesText = matchedRows.length > 0 ? matchFieldsToText(matchedFields, matchedRows[0]) : null;

  if (matchedRows.length) {
    entries = entries.filter(([key, value]) => {
      // do not show height and weight for athletes if they will not be saved (their match already has a height or weight)
      if (
        ["height", "weight"].includes(key) &&
        value &&
        matchedRows.some(matched => Object.entries(matched).some(([k, v]) => ["height", "weight"].includes(k) && v))
      ) {
        return false;
      }

      return matchedRows.some(matched => (matched as Record<string, unknown>)[key] !== value);
    });
  }

  if (!entries.length) {
    return "New data record matches existing";
  }

  const updatedFields = entries.map(([key]) => {
    const text = decamelize(key, " ")
      .split(" ")
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");

    return (
      <Typography component="span" variant="body2">
        {text}
      </Typography>
    );
  });

  if (error) {
    error.errors.forEach(err => {
      let text = decamelize(String(err.path), " ")
        .split(" ")
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");

      updatedFields.push(
        <Typography
          color={"error"}
          component="span"
          variant="body2">
          {text}
        </Typography>
      );
    });
  }

  const updatedFieldsText = updatedFields.reduce((accu, elem) => {
    return accu === null ? [elem] : [...accu, ", ", elem];
  }, null as React.ReactNode[] | null);

  return (
    <>
      <Typography variant="body2" color={"error"}>
        {matchedValuesText}
      </Typography>{" "}
      <Typography variant="body2">{updatedFieldsText}</Typography>
    </>
  );
};

interface ImportResultListItemProps<T, A> {
  index: number;
  match: IMatchResult<T, A>;
  selected: boolean;
  selectMatchIndex: () => void;
  onMatch?: (match: IMatchResult<T, A>, index: number) => void;
  existingRows?: (T & Entity)[];
  allowShowImportRow?: boolean;
  onMatchForPeekImportRow: () => void;
}

export const ImportResultListItem = <T extends object, A extends object>({
  index,
  match,
  selectMatchIndex,
  selected,
  onMatch,
  existingRows,
  allowShowImportRow,
  onMatchForPeekImportRow,
}: ImportResultListItemProps<T, A>) => {
  const classes = useStyles();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const canMatch = onMatch && Boolean(existingRows?.length);

  return (
    <div>
      <ListItem
        button
        onClick={() => selectMatchIndex()}
        key={matchFieldsToText(match.matchFields, match.row)}>
        <ListItemIcon>
          <Checkbox checked={selected || false} />
        </ListItemIcon>
        <ListItemText
          primary={getPrimaryText(match.row, match.matchFields)}
          secondary={getSecondaryText(match.row, match.matchedRows, match.matchFields, match.error)}
        />
        {allowShowImportRow && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              onClick={e => {
                e.stopPropagation();
                onMatchForPeekImportRow();
              }}
            >
              <Info fontSize={"small"} />
            </IconButton>
          </ListItemSecondaryAction>
        )}
        {canMatch && (
          <ListItemSecondaryAction>
            <IconButton
              color={isSearchOpen ? "info" : undefined}
              edge={"end"}
              onClick={() => setIsSearchOpen(!isSearchOpen)}>
              <Search />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {canMatch && (
        <Collapse in={isSearchOpen}>
          <div className={classes.searchWrapper}>
            <SearchBarDefault<T & Entity>
              menuClassName={classes.searchResultMenu}
              fullWidth
              objects={existingRows ?? []}
              objectToName={r => matchFieldsToText(match.matchFields, r)}
              onSelectObject={r => {
                const newMatchResult = {
                  row: match.row,
                  matchFields: match.matchFields,
                  type: "one" as const,
                  matchedRows: [r as T & Entity & A],
                };
                onMatch(newMatchResult, index);
              }}
              label="Match to Existing Record"
            />
          </div>
        </Collapse>
      )}
    </div>
  );
};

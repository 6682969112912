import { Dialog, DialogContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { RestaurantMenuLinkPlateItemWithAppearance } from "@notemeal/shared/ui/RestaurantMenuLinkPlate/utils";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { RestaurantMenuFormFragment, RestaurantMenuLinkPlateItemFormFragment } from "../../../../../types";
import Content from "./Content";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: `calc(100% - 64px)`,
    },
  })
);

interface RestauranMenuLinkPlateAddDialogProps {
  onAddPlate: (items: readonly RestaurantMenuLinkPlateItemFormFragment[]) => void;
  restaurantMenu: RestaurantMenuFormFragment;
  open: boolean;
  onClose: () => void;
}

const RestauranMenuLinkPlateAddDialog = ({ onAddPlate, restaurantMenu, open, onClose }: RestauranMenuLinkPlateAddDialogProps) => {
  const classes = useStyles();
  const [items, setItems] = useState<readonly RestaurantMenuLinkPlateItemWithAppearance[]>([]);

  const handleSave = () => {
    onAddPlate(items);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle title="Add Plate to Bulk Order" onClose={onClose} />
      <DialogContent>
        <Content
          plateItems={items}
          onChangePlateItems={setItems}
          onSave={handleSave}
          restaurantMenuSections={restaurantMenu.sections} />
      </DialogContent>
    </Dialog>
  );
};

export default RestauranMenuLinkPlateAddDialog;

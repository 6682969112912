import { Box, Card, CardActionArea, CardContent, Typography, useTheme } from "@mui/material";
import React from "react";

import { TeamGoalConfigurationFragment, TeamPageTeamFragment } from "../../../../types";
import DashboardCard from "../Card";
import DownloadButton from "./DownloadButton";
import TeamGoalsSummaryEmptyContent from "./TeamGoalsSummaryEmptyContent";

interface TeamGoalsSummaryProps {
  teamGoalConfiguration?: readonly TeamGoalConfigurationFragment[];
  team: TeamPageTeamFragment;
}

const TeamGoalsSummary = ({ teamGoalConfiguration = [], team }: TeamGoalsSummaryProps) => {
  const { spacing } = useTheme();

  const uniqueAthleteCount = new Set(teamGoalConfiguration.map(goal => goal.athlete?.id)).size;

  return (
    <DashboardCard title="Goals" options={<DownloadButton data={teamGoalConfiguration} teamName={team.name} />}>
      <Box sx={{ width: "100%", height: "100%" }}>
        {teamGoalConfiguration.length > 0 ? (
          <Card>
            <CardActionArea>
              <CardContent sx={{ width: "100%", p: spacing(2) }}>
                <Typography variant="body2">
                  {uniqueAthleteCount}/{team.athletes.length} athletes on the team have goals
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ) : (
          <TeamGoalsSummaryEmptyContent />
        )}
      </Box>
      <div />
    </DashboardCard>
  );
};

export default TeamGoalsSummary;

import { Dialog, Table as MuiTable, TableBody, TableHead } from "@mui/material";
import React, { useState } from "react";
import MenuItemsTable from "../MenuItemsTable";
import { getMenuItemsMaxLength, MenuItemRowInfoWithAmount, MenuSelectionRowInfo } from "../utils";

interface TableProps<T extends MenuSelectionRowInfo> {
  selectionRowInfoArray: readonly T[];
  renderTableHeader: (length: number) => JSX.Element;
  renderTableRow: (rowInfo: T, openShowMoreModal: (menuItems: readonly MenuItemRowInfoWithAmount[]) => void, length: number) => JSX.Element;
}

const Table = <T extends MenuSelectionRowInfo>({ selectionRowInfoArray, renderTableHeader, renderTableRow }: TableProps<T>) => {
  const [modalMenuItems, setModalMenuItems] = useState<readonly MenuItemRowInfoWithAmount[] | null>(null);

  const maxLength = getMenuItemsMaxLength(selectionRowInfoArray);

  return (
    <>
      <MuiTable>
        <TableHead>{renderTableHeader(maxLength)}</TableHead>
        <TableBody>
          {selectionRowInfoArray.map(selectionRowInfo => renderTableRow(selectionRowInfo, setModalMenuItems, maxLength))}
        </TableBody>
      </MuiTable>

      {modalMenuItems && (
        <Dialog open={!!modalMenuItems} onClose={() => setModalMenuItems(null)}>
          <MenuItemsTable menuItemRowInfos={modalMenuItems} />
        </Dialog>
      )}
    </>
  );
};

export default Table;

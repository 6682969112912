import { MacrosProgressMode } from "@notemeal/shared/ui/Macros/hooks";
import { initMacros, Macros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { MacrosColorBar } from "./MacrosColorBar";
import { MacrosGreyBar } from "./MacrosGreyBar";

interface MacrosBarProps {
  macros: Macros;
  targetMacros?: Macros;
  mode: MacrosProgressMode;
}

export const MacrosBar = ({ macros, targetMacros, mode }: MacrosBarProps) => {
  const targetMacrosWithKcal = targetMacros ? initMacros(targetMacros.cho, targetMacros.pro, targetMacros.fat) : null;
  if ((mode === "HasTargets_HideNumbers" || mode === "HasTargets_ShowNumbers") && targetMacros) {
    return (
      <MacrosGreyBar
        hideNumbers={mode !== "HasTargets_ShowNumbers"}
        unit="kcal"
        current={macros.kcal}
        target={targetMacrosWithKcal?.kcal || 0}
      />
    );
  } else if (mode === "NoTargets_ShowNumbers" || mode === "NoTargets_HideNumbers") {
    return <MacrosColorBar macros={macros} showKcals={mode === "NoTargets_ShowNumbers"} />;
  } else {
    return null;
  }
};

import { Checkbox, FormControlLabel, TextField, Typography, styled } from "@mui/material";
import { LocaleSelect } from "apps/web/src/components/Locale/LocaleSelect";
import { TerritorySelect } from "apps/web/src/components/Territory/TerritorySelect";
import React, { Dispatch } from "react";
import AgreementFormSelect from "./AgreementFormSelect";
import ScoringSystemSelect from "./ScoringSystemSelect";
import { OrgFormAction, OrgFormState } from "./utils";

const Row = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  gap: spacing(2),
}));

const IntegrationFormControlLabel = styled(FormControlLabel)(() => ({
  width: "50%",
}));

interface CreateOrgMembershipFormProps {
  state: OrgFormState;
  dispatch: Dispatch<OrgFormAction>;
}

export const OrgForm = ({ state, dispatch }: CreateOrgMembershipFormProps) => (
  <>
    <Row sx={{ flexDirection: "column" }}>
      <Typography variant="body2Medium">Org Details</Typography>
      <TextField
        sx={{ width: "75%" }}
        autoFocus
        label="Org Name"
        value={state.name}
        onChange={e => dispatch({ type: "CHANGE_NAME", payload: e.target.value })}
        placeholder="Name"
      />
    </Row>
    <Row>
      <FormControlLabel
        value="public access"
        control={
          <Checkbox
            checked={state.allowsPublicAccess}
            onChange={e =>
              dispatch({
                type: "CHANGE_ALLOWS_PUBLIC_ACCESS",
                payload: !state.allowsPublicAccess,
              })
            }
          />
        }
        label={<Typography variant="body2">Public Access?</Typography>}
        labelPlacement="end"
      />
      <FormControlLabel
        value="active"
        control={
          <Checkbox
            checked={state.isActive}
            onChange={e =>
              dispatch({
                type: "CHANGE_IS_ACTIVE",
                payload: !state.isActive,
              })
            }
          />
        }
        label={<Typography variant="body2">Is Active?</Typography>}
        labelPlacement="end"
      />
    </Row>
    <Typography variant="body2Medium">Locale</Typography>
    <LocaleSelect
      value={state.localeCode}
      onChange={e =>
        dispatch({
          type: "CHANGE_LOCALE",
          payload: e,
        })
      }
    />
    <TerritorySelect
      value={state.territoryName}
      onChange={e =>
        dispatch({
          type: "CHANGE_TERRITORY",
          payload: e,
        })
      }
    />
    {state.id && (
      <>
        <Typography variant="body2Medium">Integrations</Typography>
        <Row>
          <IntegrationFormControlLabel
            value="enableSodexoId"
            control={
              <Checkbox
                checked={state.allowSodexoId}
                onChange={e =>
                  dispatch({
                    type: "CHANGE_ALLOW_SODEXO_ID",
                    payload: !state.allowSodexoId,
                  })
                }
              />
            }
            label={<Typography variant="body2">Enable Sodexo</Typography>}
            labelPlacement="end"
          />
          {state.allowSodexoId && (
            <TextField
              fullWidth
              label="Sodexo Id"
              value={state.sodexoId}
              onChange={e =>
                dispatch({
                  type: "CHANGE_SODEXO_ID",
                  payload: e.target.value,
                })
              }
            />
          )}
        </Row>
        <Row>
          <IntegrationFormControlLabel
            value="enableBonAppetitId"
            control={
              <Checkbox
                checked={state.allowBonAppetitId}
                onChange={e =>
                  dispatch({
                    type: "CHANGE_ALLOW_BONAPPETIT_ID",
                    payload: !state.allowBonAppetitId,
                  })
                }
              />
            }
            label={<Typography variant="body2">Enable Bon Appetit</Typography>}
            labelPlacement="end"
          />
          {state.allowBonAppetitId && (
            <TextField
              fullWidth
              label="Bon Appetit Id"
              value={state.bonAppetitId}
              onChange={e =>
                dispatch({
                  type: "CHANGE_BONAPPETIT_ID",
                  payload: e.target.value,
                })
              }
            />
          )}
        </Row>
        <Row>
          <IntegrationFormControlLabel
            value="enableCbordId"
            control={
              <Checkbox
                checked={state.allowCbordId}
                onChange={e =>
                  dispatch({
                    type: "CHANGE_ALLOW_CBORD_ID",
                    payload: !state.allowCbordId,
                  })
                }
              />
            }
            label={<Typography variant="body2">Enable CBORD</Typography>}
            labelPlacement="end"
          />
          {state.allowCbordId && (
            <TextField
              fullWidth
              label="CBORD Id"
              value={state.cbordId}
              onChange={e =>
                dispatch({
                  type: "CHANGE_CBORD_ID",
                  payload: e.target.value,
                })
              }
            />
          )}
        </Row>
        <Row>
          <IntegrationFormControlLabel
            value="enableCompassId"
            control={
              <Checkbox
                checked={state.allowCompassId}
                onChange={e =>
                  dispatch({
                    type: "CHANGE_ALLOW_COMPASS_ID",
                    payload: !state.allowCompassId,
                  })
                }
              />
            }
            label={<Typography variant="body2">Enable Compass</Typography>}
            labelPlacement="end"
          />
          {state.allowCompassId && (
            <TextField
              fullWidth
              label="Compass Id"
              value={state.compassId}
              onChange={e =>
                dispatch({
                  type: "CHANGE_COMPASS_ID",
                  payload: e.target.value,
                })
              }
            />
          )}
        </Row>
        <Row sx={{ flexDirection: "column" }}>
          <Typography variant="body2Medium">Agreement Forms</Typography>
          <AgreementFormSelect
            agreementForm={state.agreementForm}
            setAgreementForm={e =>
              dispatch({
                type: "CHANGE_AGREEMENT_FORM",
                payload: e,
              })
            }
          />
          <Typography variant="body2Medium">Scoring System</Typography>
          <ScoringSystemSelect
            scoringSystem={state.scoringSystem}
            setScoringSystem={e =>
              dispatch({
                type: "CHANGE_SCORING_SYSTEM",
                payload: e,
              })
            }
          />
        </Row>
      </>
    )}
  </>
);

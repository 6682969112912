import { ApolloQueryResult } from "@apollo/client";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { getFormattedTimeDifference } from "@notemeal/shared/ui/utils/dateTimes";
import { PlannedMenuTableItemFragment, PlannedMenuTableQuery, usePlannedMenuTableQuery } from "apps/web/src/types";
import { PaginationHooks, useOffsetPagination } from "apps/web/src/utils/pagination";
import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

export interface MoreInfo {
  moreAnchorElement: HTMLElement;
  plannedMenu: PlannedMenuTableItemFragment;
}

type PlannedMenusPageContextValue = {
  rows: readonly PlannedMenuTableItemFragment[];
  paginationHooks: PaginationHooks;
  loading: boolean;
  totalRows: number;
  editPlannedMenu: PlannedMenuTableItemFragment | null;
  setEditPlannedMenu: Dispatch<SetStateAction<PlannedMenuTableItemFragment | null>>;
  moreInfo: MoreInfo | null;
  setMoreInfo: Dispatch<SetStateAction<MoreInfo | null>>;
  deletablePlannedMenu: PlannedMenuTableItemFragment | null;
  setDeletablePlannedMenu: Dispatch<SetStateAction<PlannedMenuTableItemFragment | null>>;
  menuToDuplicate: PlannedMenuTableItemFragment | null;
  setMenuToDuplicate: Dispatch<SetStateAction<PlannedMenuTableItemFragment | null>>;
  menuToEdit: PlannedMenuTableItemFragment | null;
  setMenuToEdit: Dispatch<SetStateAction<PlannedMenuTableItemFragment | null>>;
  getFormattedNameForUser: (user: { firstName: string; lastName: string }) => string;
  getFormattedDateRange: ({ startDate, endDate, timezone }: { startDate: string; endDate: string; timezone: string }) => string;
  getFormattedTimeDifference: (updatedDate: string, timezone: string) => string;
  refetch: () => Promise<ApolloQueryResult<PlannedMenuTableQuery>>;
};

const PlannedMenusTablePageContext = createContext<PlannedMenusPageContextValue>({
  rows: [],
  paginationHooks: {} as PaginationHooks,
  loading: true,
  totalRows: 0,
  editPlannedMenu: null,
  setEditPlannedMenu: () => {},
  deletablePlannedMenu: null,
  setDeletablePlannedMenu: () => {},
  menuToDuplicate: null,
  setMenuToDuplicate: () => {},
  menuToEdit: null,
  setMenuToEdit: () => {},
  moreInfo: null,
  setMoreInfo: () => {},
  getFormattedNameForUser: () => "",
  getFormattedDateRange: () => "",
  getFormattedTimeDifference: () => "",
  refetch: () => Promise.resolve({} as ApolloQueryResult<PlannedMenuTableQuery>),
});

export const PlannedMenusPageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading, refetch } = usePlannedMenuTableQuery({
    variables: { input: { limit, offset }, query },
    fetchPolicy: "network-only",
  });
  const { formatDateInTimezoneWithLocale } = useDateFormatting();

  const [editPlannedMenu, setEditPlannedMenu] = useState<PlannedMenuTableItemFragment | null>(null);
  const [deletablePlannedMenu, setDeletablePlannedMenu] = useState<PlannedMenuTableItemFragment | null>(null);
  const [menuToDuplicate, setMenuToDuplicate] = useState<PlannedMenuTableItemFragment | null>(null);
  const [menuToEdit, setMenuToEdit] = useState<PlannedMenuTableItemFragment | null>(null);
  const [moreInfo, setMoreInfo] = useState<MoreInfo | null>(null);

  const getFormattedNameForUser = (user: { firstName: string; lastName: string }) => `${user.firstName} ${user.lastName}`;
  const getFormattedDateRange = ({ startDate, endDate, timezone }: { startDate: string; endDate: string; timezone: string }) =>
    `${formatDateInTimezoneWithLocale(startDate, timezone)} - ${formatDateInTimezoneWithLocale(endDate, timezone)}`;

  return (
    <PlannedMenusTablePageContext.Provider
      value={{
        rows: data?.plannedMenuOffsetConnection.edges ?? [],
        paginationHooks,
        loading,
        totalRows: data?.plannedMenuOffsetConnection.pageInfo.total ?? 0,
        editPlannedMenu,
        setEditPlannedMenu,
        moreInfo,
        setMoreInfo,
        getFormattedNameForUser,
        getFormattedDateRange,
        deletablePlannedMenu,
        setDeletablePlannedMenu,
        menuToDuplicate,
        setMenuToDuplicate,
        menuToEdit,
        setMenuToEdit,
        refetch,
        getFormattedTimeDifference,
      }}
    >
      {children}
    </PlannedMenusTablePageContext.Provider>
  );
};

export const usePlannedMenuTable = () => {
  const state = useContext(PlannedMenusTablePageContext);
  return state;
};

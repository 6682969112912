interface PhoneNumberGroups {
  countryCode: string;
  areaCode: string;
  exchange: string;
  subscriberNumber: string;
  extension: string;
}

/**
 * Parses the parts of phone numbers. cases handled
 * "+129787585715" non USA country code
 * "9787585715" no country code
 * "+19787585715" US country code
 * "(978) 758 5715" parentheses around area code and spaces
 * "(978) 758-5715" hyphens
 *  It can also handle combinations of the above.
 */
export const phoneMatchRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const getPhoneNumberGroups = (phoneNumber: string): PhoneNumberGroups | null => {
  /**
   * Parses the parts of phone numbers. cases handled
   * "+129787585715" non USA country code
   * "9787585715" no country code
   * "+19787585715" US country code
   * "(978) 758 5715" parentheses around area code and spaces
   * "(978) 758-5715" hyphens
   *  It can also handle combinations of the above.
   */
  // removing spaces aids parsing of international formats
  const groups = phoneNumber.replace(/\s/g, '').match(phoneMatchRegex);
  if (!groups) {
    return null;
  }

  /*
  Group1: Country Code (ex: 1 or 86)
  Group2: Area Code (ex: 800)
  Group3: Exchange (ex: 555)
  Group4: Subscriber Number (ex: 1234)
  Group5: Extension (ex: 5678)
  */
  const [countryCode, areaCode, exchange, subscriberNumber, extension] = groups.slice(1);

  return {
    countryCode: countryCode || "1",
    areaCode,
    exchange,
    subscriberNumber,
    extension: extension || "",
  };
};

const parsePhoneNumberGroups = (groups: PhoneNumberGroups): string => {
  const { countryCode, areaCode, exchange, subscriberNumber, extension } = groups;

  return `+${countryCode}${areaCode}${exchange}${subscriberNumber}${extension}`;
};

export interface PhoneNumber {
  countryCode: string;
  phoneNumber: string;
}

export const parsePhoneNumber = (phoneNumber: string): PhoneNumber | null => {
  const groups = getPhoneNumberGroups(phoneNumber);
  if (!groups) {
    return null;
  }
  const { countryCode, areaCode, exchange, subscriberNumber } = groups;
  return {
    countryCode,
    phoneNumber: `${areaCode}${exchange}${subscriberNumber}`,
  };
};

export const standardizePhoneNumber = (phoneNumber: string): string => {
  const groups = getPhoneNumberGroups(phoneNumber);
  if (!groups) {
    throw new Error(`Phone number ${phoneNumber} is invalid!`);
  }
  return parsePhoneNumberGroups(groups);
};

export const standardizePhoneNumberOrNull = (phoneNumber: string): string | null => {
  const groups = getPhoneNumberGroups(phoneNumber);
  if (!groups) {
    return null;
  }
  return parsePhoneNumberGroups(groups);
};

export const invalidPhoneNumber = (phoneNumber: string): boolean => {
  const groups = getPhoneNumberGroups(phoneNumber);
  return groups === null;
};

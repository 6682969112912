import { z } from "zod";
import { BrandedFoodSchema, GenericFoodSchema } from "./FoodSchema";

const ManualFoodSchema = z.object({
  source: z.literal("manual"),
});

export const ManualGenericFoodSchema = GenericFoodSchema.merge(ManualFoodSchema);
export const ManualBrandedFoodSchema = BrandedFoodSchema.merge(ManualFoodSchema).merge(
  z.object({
    exclusiveOwnerId: z.string().uuid().optional(),
    deprecatedById: z.string().uuid().optional(),
  })
);

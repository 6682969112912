// full page path
export const NAV_ORG_SETTINGS = "/org/settings";

// local child paths
export const NAV_SETTINGS_THEME_PREFERENCE = "theme-preference";

// full child paths
export const NAV_ORG_SETTINGS_THEME_PREFERENCE = `${NAV_ORG_SETTINGS}/${NAV_SETTINGS_THEME_PREFERENCE}`;

export const orgSettingsLink = {
  name: "Settings",
  to: NAV_ORG_SETTINGS,
};

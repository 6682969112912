import { Box, Button } from "@mui/material";
import { SectionHeaderExample } from "apps/web/src/componentLibrary/SectionHeader.example";
import { TWButtonGroupExample } from "apps/web/src/componentLibrary/TWButtonGroup/TWButtonGroup.example";
import { TWIconButtonExample } from "apps/web/src/componentLibrary/TWIconButton/TWIconButton.example";
import { TWTooltipExample } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip.example";
import React from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import ConfirmationDialogExample from "../../../componentLibrary/ConfirmationDialog/ConfirmationDialog.example";
import DialogTitleExample from "../../../componentLibrary/DialogTitle.example";
import { TWTabGroupExample } from "../../../componentLibrary/TWTabGroup/TWTabGroup.example";
import { getNavOrgDevComponentLibraryComponent, getNavStaffDevComponentLibraryComponent } from "./DevRouter";

// Name to render function
type DemoComponentDictionary = { [name: string]: (() => React.ReactNode) | undefined };

const demoComponents: DemoComponentDictionary = {
  ConfirmationDialog: () => <ConfirmationDialogExample />,
  DialogTitle: () => <DialogTitleExample />,
  SectionHeader: () => <SectionHeaderExample />,
  TWTabGroup: () => <TWTabGroupExample />,
  TWButtonGroup: () => <TWButtonGroupExample />,
  TWIconButton: () => <TWIconButtonExample />,
  TWTooltip: () => <TWTooltipExample />,
};

const demoComponentNames = Object.keys(demoComponents).sort();

export interface LibraryDemoProps {
  org: boolean;
}

export const LibraryDemoPage = ({ org }: LibraryDemoProps) => {
  const { componentName } = useParams();
  const navigate = useNavigate();

  return (
    <Box sx={{ m: 4, display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 4 }}>
      {demoComponentNames.map(name => {
        const activeComponent = name === componentName && demoComponents[componentName] ? demoComponents[componentName] : null;

        return (
          <Box key={name} sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start" }}>
            <Button
              onClick={() =>
                navigate(org ? getNavOrgDevComponentLibraryComponent(name) : getNavStaffDevComponentLibraryComponent(name), {
                  replace: true,
                })
              }
              variant={componentName === name ? "contained" : "outlined"}
            >
              <>{name}</>
            </Button>
            <Box sx={{ ml: 2 }}>{activeComponent && activeComponent()}</Box>
          </Box>
        );
      })}
    </Box>
  );
};

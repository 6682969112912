import { Macros } from "@notemeal/shared/utils/macro-protocol";
import { getServingAmountsMacros, mergeServingAmounts, scaleServingAmounts } from "../ServingAmount/utils";
import { FullServingAmountFragment, MenuSelectionItemWithMacrosFragment } from "../types";

export const getMenuSelectionItemMacros = ({ amount, menuItem, options, percentConsumed }: MenuSelectionItemWithMacrosFragment): Macros => {
  const servingAmounts = getMenuSelectionItemServingAmounts({ amount, menuItem, options, percentConsumed });
  return getServingAmountsMacros(servingAmounts);
};

export const getMenuSelectionItemServingAmounts = ({
  amount,
  menuItem,
  options,
  percentConsumed,
}: MenuSelectionItemWithMacrosFragment): readonly FullServingAmountFragment[] => {
  const unscaledServingAmounts = mergeServingAmounts([
    ...scaleServingAmounts(menuItem.servingAmounts, coercePercentConsumed(percentConsumed)),
    ...options.flatMap(o =>
      scaleServingAmounts(o.menuItemChoiceOption.servingAmounts, o.amount * coercePercentConsumed(o.percentConsumed))
    ),
  ]);
  return scaleServingAmounts(unscaledServingAmounts, amount);
};

export const getTotalPercentConsumedForMenuSelectionItem = (menuSelection: MenuSelectionItemWithMacrosFragment): number | null => {
  const total = getMenuSelectionItemMacros({
    ...menuSelection,
    percentConsumed: 1,
    options: [
      ...menuSelection.options.map(o => {
        return { ...o, percentConsumed: 1 };
      }),
    ],
  });

  if (total.kcal === 0) {
    return null;
  }

  const consumed = getMenuSelectionItemMacros(menuSelection);

  return consumed.kcal && total.kcal ? consumed.kcal / total.kcal : 0;
};

export const coercePercentConsumed = (value: number | null): number => (value !== null ? value : 1);

import { Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Switch } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer, useState } from "react";
import { FoodLogNotificationSettings, useEditTeamFoodLogNotificationSettingsMutation } from "../../../../types";
import Form from "../Form";
import { FoodLogNotificationSettingsFormAction, foodLogNotificationSettingsFormReducer } from "../Form/utils";
import { emptyFormState } from "../utils";

interface TeamFoodLogNotificationSettingsModalProps {
  open: boolean;
  onClose: () => void;
  teamId: string;
  teamNotificationSettings: FoodLogNotificationSettings | null;
}

const TeamFoodLogNotificationSettingsModal = ({
  teamId,
  open,
  onClose,
  teamNotificationSettings,
}: TeamFoodLogNotificationSettingsModalProps) => {
  const [enableTeamNotifications, setEnableTeamNotifications] = useState(!!teamNotificationSettings);
  const [editTeamFoodLogSubscription, { loading: loadingEditSettings }] = useEditTeamFoodLogNotificationSettingsMutation({});

  const loading = loadingEditSettings;

  const initState = teamNotificationSettings ? teamNotificationSettings : emptyFormState;
  const [foodLogNotificationSettingsFormState, foodLogNotificationSettingsFormDispatch] = useReducer(
    foodLogNotificationSettingsFormReducer,
    {
      ...initState,
      disabled: !teamNotificationSettings,
    }
  );

  const handleSave = () => {
    const { imageUploads, messages, edits } = foodLogNotificationSettingsFormState;
    editTeamFoodLogSubscription({
      variables: {
        input: {
          teamId,
          settings: enableTeamNotifications ? { imageUploads, messages, edits } : null,
        },
      },
      update: () => onClose(),
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Notification Subscriptions" onClose={onClose} />
      <DialogContent>
        {loading ? (
          <Loading progressSize="lg" />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              value={enableTeamNotifications}
              control={
                <Switch
                  checked={enableTeamNotifications}
                  onChange={e => {
                    const isEnabled = !enableTeamNotifications;
                    setEnableTeamNotifications(isEnabled);

                    type ActionType = FoodLogNotificationSettingsFormAction["type"];
                    const actions: Array<ActionType> = ["TOGGLE_MESSAGE", "TOGGLE_EDIT_FOOD_LOG", "TOGGLE_UPLOAD_IMAGE"];
                    actions.forEach(action => foodLogNotificationSettingsFormDispatch({ type: action, payload: isEnabled }));
                  }}
                />
              }
              label={"Subscribe to Team Notifications"}
              labelPlacement="start"
            />
            <Box sx={{ mt: 2, alignSelf: "stretch" }}>
              <Form
                state={{
                  ...foodLogNotificationSettingsFormState,
                  disabled: !enableTeamNotifications,
                }}
                dispatch={foodLogNotificationSettingsFormDispatch}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamFoodLogNotificationSettingsModal;

import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { getNavOrgAthlete } from "../../pages/Auth/Org/Athlete/AthletePaths";
import { AthleteFragment, AthleteSearchDocument, AthleteSearchQuery, AthleteSearchQueryVariables } from "../../types";
import { AutocompleteQuerySearchBar } from "../universal/AutocompleteQuerySearchBar/AutocompleteQuerySearchBar";

const AthleteSearchBar = () => {
  const navigate = useNavigate();

  return (
    <AutocompleteQuerySearchBar<AthleteFragment, AthleteSearchQuery, AthleteSearchQueryVariables>
      query={AthleteSearchDocument}
      placeholder="Search Athletes"
      getOptionLabel={a => `${a.lastName}, ${a.firstName}${a.isArchived ? " (Archived)" : ""}`}
      getOptionsFromQueryData={data => [...data.athleteSearch]}
      getQueryOptions={query => ({
        variables: { query, limit: 25 },
      })}
      onChange={a => (a ? navigate(getNavOrgAthlete(a.id)) : null)}
      getUserFriendlyQueryErrorMessage={() => "Error searching athletes, please try again."}
      groupBy={{
        label: a => (a.isArchived ? "Archived" : ""),
        sort: (a, b) => (a.isArchived === b.isArchived ? 0 : a.isArchived ? 1 : -1),
      }}
    />
  );
};

export default AthleteSearchBar;

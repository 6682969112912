import { MacrosProgressMode } from "@notemeal/shared/ui/Macros/hooks";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { MultiColorDials } from "./MultiColorDials";
import { SingleColorDials } from "./SingleColorDials";

interface ToggleableMacroDialsProps {
  mode: MacrosProgressMode;
  macros: Macros;
  targetMacros?: Macros;
  variant?: "sm" | "md" | "lg";
}

export const MacrosDials = ({ macros, targetMacros, variant, mode }: ToggleableMacroDialsProps) => {
  if ((mode === "HasTargets_HideNumbers" || mode === "HasTargets_ShowNumbers") && targetMacros) {
    return <MultiColorDials
      hideNumbers={mode === "HasTargets_HideNumbers"}
      variant={variant}
      current={macros}
      target={targetMacros} />;
  } else if (mode === "NoTargets_HideNumbers" || mode === "NoTargets_ShowNumbers") {
    return <SingleColorDials
      hideNumbers={mode === "NoTargets_HideNumbers"}
      variant={variant}
      macros={macros} />;
  } else {
    return null;
  }
};

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React from "react";
import HelpTipIcon from "../HelpTipIcon";

interface TablePageDefaultHeaderProps {
  title: string;
  searchTip?: string;
  queryText?: string;
  onChangeQueryText?: (queryText: string) => void;
  searchPlaceHolder?: string;
  onCreate?: () => void;
}

const TablePageDefaultHeader = ({
  title,
  searchTip,
  queryText,
  onChangeQueryText,
  onCreate,
  searchPlaceHolder = "Search",
}: TablePageDefaultHeaderProps) => {
  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
      {queryText !== undefined && onChangeQueryText !== undefined && (
        <TextField
          sx={{ mt: 0 }}
          placeholder={searchPlaceHolder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => onChangeQueryText(e.target.value)}
          value={queryText}
        />
      )}
      {searchTip && <HelpTipIcon tip={searchTip} tipMarginTop={1} />}
      {onCreate && <Button onClick={onCreate}>Create {title}</Button>}
    </Box>
  );
};

export default TablePageDefaultHeader;

import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Step, StepLabel, Stepper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "start",
      paddingTop: theme.spacing(4),
    },
    spacer: {
      flexGrow: 1,
    },
    stepper: {
      paddingLeft: 0,
      width: 250,
    },
    closeButton: {
      marginRight: theme.spacing(2),
    },
  })
);

interface LinkModalHeaderProps {
  activeStep: 0 | 1;
  nextDisabled: boolean;
  onNextClicked: () => void;
  onClose: () => void;
  orgName: string;
  children?: ReactNode;
}

const LinkModalHeader = ({ activeStep, nextDisabled, onNextClicked, onClose, orgName, children }: LinkModalHeaderProps) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <IconButton
        onClick={() => onClose()}
        className={classes.closeButton}
        size="large">
        <CloseIcon fontSize="large" />
      </IconButton>
      <div>
        <Typography variant="h4">Teamworks Org Sync: {orgName}</Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          <Step completed={activeStep === 1}>
            <StepLabel>Teams</StepLabel>
          </Step>
          <Step completed={false}>
            <StepLabel>Profiles</StepLabel>
          </Step>
        </Stepper>
      </div>
      <div className={classes.spacer} />

      {children}
      <Button disabled={nextDisabled} onClick={() => onNextClicked()}>
        Next
      </Button>
    </div>
  );
};

export default LinkModalHeader;

import ScheduleSelect from "apps/web/src/components/Schedule/Select";
import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { Dispatch } from "react";
import { MealPlanCalendarForm } from "./MealPlanCalendarForm";
import SchedulePreview from "./SchedulePreview";
import { CreateMealPlanCalendarAction } from "./reducer";
import { CreateMealPlanCalendarState } from "./types";

interface MealPlanCalendarCreateFormProps {
  state: CreateMealPlanCalendarState;
  dispatch: Dispatch<CreateMealPlanCalendarAction>;
}

export const MealPlanCalendarCreateForm = ({ state, dispatch }: MealPlanCalendarCreateFormProps) => {
  const selectedSchedule = state.schedules.concat(state.teamSchedules).find(s => s.id === state.selectedScheduleId) ?? null;

  return (
    <MealPlanCalendarForm
      state={state}
      dispatch={dispatch}
      startDateInPastBehavior="error"
      renderScheduleMenuSection={({ onEditSchedule }) => (
        <>
          <ScheduleSelect
            schedules={state.schedules}
            teamSchedules={state.teamSchedules}
            selectedSchedule={selectedSchedule}
            onChange={schedule => {
              trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Select Schedule", { schedule, athleteId: state.athleteId });
              dispatch({
                type: "SelectSchedule",
                payload: { schedule },
              });
            }}
          />
          {state.selectedScheduleId && <SchedulePreview state={state} onEditSchedule={onEditSchedule} />}
        </>
      )}
    />
  );
};

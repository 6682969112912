import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";

interface ArchiveModalProps {
  onClose: () => void;
  open: boolean;
  onArchive: () => void;
  archived: boolean;
  synced: boolean;
}

const ArchiveModal = ({ onClose, open, onArchive, archived, synced }: ArchiveModalProps) => {
  const handleArchive = () => {
    onArchive();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title={`${archived ? "Unarchive" : "Archive"} athlete`} onClose={onClose} />
      <DialogContent>
        <Typography>{`Are you sure that you want to ${archived ? "unarchive" : "archive"} the athlete?`}</Typography>
        {synced && (
          <Box sx={{ p: 2, mt: 2, backgroundColor: "grey.200", borderRadius: "5px", display: "flex", alignItems: "center", gap: 2 }}>
            <WarningIcon />
            <Typography variant="body2">This will cause your org to lose fully synced status.</Typography>
          </Box>
        )}
        {!archived && (
          <Typography>
            The mobile app account will be deactivated and the athlete will be hidden from the team roster. The athlete is still searchable
            and nothing will be deleted.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleArchive}>{archived ? "Unarchive" : "Archive"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveModal;

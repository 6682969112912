import { Box, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import React from "react";
import { SodexoMenuSourceFragment } from "../../../../types";

interface SelectSodexoMenuSourceProps {
  menuSources: readonly SodexoMenuSourceFragment[];
  selectedMenuSource: SodexoMenuSourceFragment | null;
  onSelectMenuSource: (menuSource: SodexoMenuSourceFragment) => void;
}

const SelectSodexoMenuSource = ({ menuSources, selectedMenuSource, onSelectMenuSource }: SelectSodexoMenuSourceProps) => {
  return (
    <Box>
      {menuSources.length > 0 ? (
        <List>
          {menuSources.map((menuSource: SodexoMenuSourceFragment) => {
            const { menuSourceKey, menuSourceName } = menuSource;
            return (
              <ListItemButton
                key={menuSourceKey}
                onClick={() => onSelectMenuSource(menuSource)}
                selected={menuSource === selectedMenuSource}
              >
                <ListItemText primary={menuSourceName} />
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        <Typography>No Menu Sources found.</Typography>
      )}
    </Box>
  );
};

export default SelectSodexoMenuSource;

import React from "react";
import { DeliveryLocationWithTeamsFragment, useDeliveryLocationsQuery } from "../../../../types";
import CreateDialog from "../../../../views/Staff/DeliveryLocations/Dialog/Create";
import EditDialog from "../../../../views/Staff/DeliveryLocations/Dialog/Edit";
import StaffContent from "../../../../views/Staff/GeneralizedStaffContent";
import { UseFetchQueryArgs } from "../../../../views/Staff/GeneralizedStaffContent/types";

const headerRowInfo = [
  { key: "name", displayName: "Name" },
  { key: "address", displayName: "Address" },
  { key: "teamLabels", displayName: "Team Labels" },
];

const convertObjToRowData = (deliveryLocation: DeliveryLocationWithTeamsFragment) => {
  const { teamLabels, ...rest } = deliveryLocation;
  const teamLabelsString = teamLabels.map(tt => tt.name).join(", ");
  return {
    ...rest,
    teamLabels: teamLabelsString,
    address: rest.address?.displayName,
  };
};

const useFetchQuery = (query: UseFetchQueryArgs) => {
  const { data, loading } = useDeliveryLocationsQuery(query);
  return {
    loading,
    data: data?.allDeliveryLocations,
  };
};

const getObjDisplay = (deliveryLocation: DeliveryLocationWithTeamsFragment) => deliveryLocation.name;

export const DeliveryLocationsPage = () => {
  return (
    <StaffContent
      headerRowInfo={headerRowInfo}
      convertObjToRowData={convertObjToRowData}
      queryCacheKey="allDeliveryLocations"
      useFetchQuery={useFetchQuery}
      entityName="Delivery Location"
      getObjDisplay={getObjDisplay}
      renderEditDialog={({ open, onClose, onEditSuccess, initialEditInfo }) => (
        <EditDialog
          open={open}
          onClose={onClose}
          onEditSuccess={onEditSuccess}
          deliveryLocation={initialEditInfo} />
      )}
      renderCreateDialog={({ open, onClose, onCreateSuccess }) => (
        <CreateDialog
          open={open}
          onClose={onClose}
          onCreateSuccess={onCreateSuccess} />
      )}
    />
  );
};

import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import LoadingButton from "../../components/universal/LoadingButton";
import DialogTitle from "../DialogTitle";

interface SomethingWentWrongDialogProps {
  onClose: () => void;
  open: boolean;
  content: string,
  loading?: boolean;
}

const SomethingWentWrongDialog = ({ open, onClose, content, loading }: SomethingWentWrongDialogProps) => (
  <Dialog
    maxWidth="xs"
    fullWidth
    open={open}
    onClose={onClose}>
    <DialogTitle title="Something Went Wrong" onClose={onClose} />
    <DialogContent>
      <DialogContentText>
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <LoadingButton
        onClick={onClose}
        autoFocus
        buttonText={"Ok"}
        loading={loading} />
    </DialogActions>
  </Dialog>
);

export default SomethingWentWrongDialog;

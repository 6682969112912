import { MealMenuDiningStationForDigitalDisplayFragment, MenuItemForDigitalDisplayFragment } from "apps/web/src/types";
import { MACRO_LINE_HEIGHT } from "./MacrosContent";
import { DESCRIPTION_NO_WRAP_HEIGHT, DESCRIPTION_WITH_WRAP_HEIGHT, MenuItemWithFullServings } from "./MenuItemCard";
import { DigitalDisplaysConfigState } from "./reducer";
import { toJpeg } from "html-to-image";

const DINING_STATION_NAME_HEIGHT = "56px";
const ADD_ON_BANNER_WRAP_HEIGHT = "72px";
const ADD_ON_BANNER_NO_WRAP_HEIGHT = "48px";

export const pxToNum = (pixString: string) => {
  return parseInt(pixString.split("px")[0]);
};

export const shouldWrapText = (state: DigitalDisplaysConfigState) => {
  return (
    (state.device === "iPad" && state.orientation === "Portrait") ||
    (state.device === "iPad" && state.orientation === "Landscape" && state.itemsPerRow === "3") ||
    (state.device === "TV" && state.orientation === "Landscape" && state.itemsPerRow === "3")
  );
};

export const hasAddOn = (item: MenuItemWithFullServings) => {
  return item.choices.length > 0;
};

// Split dining stations into list of dining stations that can fit onto one
// digital display "slide". Slide whitespace size changes based on configuration
// (i.e. iPad/TV, portrait/landscape).
//
// This function pre-calculates the estimated remaining whitespace to determine
// how much vertical whitespace is left. Note that it is NOT an actual
// representation of the remaining whitespace as the rendered offset values are
// not used.
//
// Returns a list where each element represents the content that will
// be displayed on a "slide". Each element is a list of dining stations
// containing the full or partial list of menu items for that dining station
// depending on what fits on that slide.
export const splitContentToSlides = (
  state: DigitalDisplaysConfigState,
  diningStations: MealMenuDiningStationForDigitalDisplayFragment[],
  whitespacePerSlide: number
) => {
  const itemsPerRow = parseInt(state.itemsPerRow);
  const textWrap = shouldWrapText(state);
  const titleHeight = pxToNum(DINING_STATION_NAME_HEIGHT);
  const addOnBannerWrap = state.orientation === "Portrait" && state.device === "TV";
  // NOTE: any time any card components are added, this needs to be updated
  const cardHeight =
    pxToNum("58px") + // overall padding
    pxToNum(textWrap ? DESCRIPTION_WITH_WRAP_HEIGHT : DESCRIPTION_NO_WRAP_HEIGHT) + // title same size as description
    pxToNum(state.description ? (textWrap ? DESCRIPTION_WITH_WRAP_HEIGHT : DESCRIPTION_NO_WRAP_HEIGHT) : "0px") + // description
    pxToNum(state.macros ? MACRO_LINE_HEIGHT : "0px"); // macros

  const hasAddOnItems = (items: MenuItemForDigitalDisplayFragment[]) => {
    const hasAddOn = (item: MenuItemForDigitalDisplayFragment) => {
      return item.menuItem.choices.length > 0;
    };
    return items.filter(item => hasAddOn(item)).length > 0;
  };

  let curSlide: MealMenuDiningStationForDigitalDisplayFragment[] = [];
  const slides = [curSlide];
  let remainingWhitespacePerSlide = whitespacePerSlide;
  let hasAddOnItem = false;

  const addSlide = () => {
    slides.push([]);
    remainingWhitespacePerSlide = whitespacePerSlide;
    curSlide = slides[slides.length - 1];
    hasAddOnItem = false;
  };

  // for each dining station, fit as many items on the current slide as
  // possible. when there is no more room, create a new slide
  diningStations.forEach(ds => {
    let menuItems = ds.menuItemAppearances;
    let dsName = ds.name;
    remainingWhitespacePerSlide -= titleHeight;
    while (menuItems.length > 0) {
      let rowsThatFit = Math.max(0, Math.floor(remainingWhitespacePerSlide / cardHeight));
      if (!rowsThatFit) {
        addSlide();
        if (slides.length > 1 && slides[slides.length - 2].find(ds2 => ds2.name === ds.name)) {
          dsName = dsName.concat(" (cont.)");
        }
        remainingWhitespacePerSlide -= titleHeight;
        rowsThatFit = Math.max(0, Math.floor(remainingWhitespacePerSlide / cardHeight));
      }
      let numItemsThatFit = Math.min(rowsThatFit * itemsPerRow, menuItems.length);
      let itemSubset = menuItems.slice(0, numItemsThatFit);
      // account for space needed for add-on info banner but only the first
      // time an item with add-on is added to the slide
      if (state.macros && !hasAddOnItem) {
        hasAddOnItem = hasAddOnItems(itemSubset);
        if (hasAddOnItem) {
          remainingWhitespacePerSlide -= addOnBannerWrap ? pxToNum(ADD_ON_BANNER_WRAP_HEIGHT) : pxToNum(ADD_ON_BANNER_NO_WRAP_HEIGHT);
          rowsThatFit = Math.max(0, Math.floor(remainingWhitespacePerSlide / cardHeight));
          numItemsThatFit = Math.min(rowsThatFit * itemsPerRow, menuItems.length);
          itemSubset = menuItems.slice(0, numItemsThatFit);
        }
      }
      const numAddedRows = Math.ceil(Math.min(numItemsThatFit, menuItems.length) / itemsPerRow);
      remainingWhitespacePerSlide -= numAddedRows * cardHeight;
      curSlide.push({ ...ds, name: dsName, menuItemAppearances: itemSubset });
      if (numItemsThatFit >= menuItems.length) {
        menuItems = [];
      } else {
        menuItems = menuItems.slice(numItemsThatFit);
      }
    }
  });
  return slides;
};

/* NOTE: this is a replicated version of 'react-component-export-image' package
 * re-worked so that we could feed it elements rather than the react references
 */
const saveAs = (uri: string, filename: string) => {
  const link = document.createElement("a");

  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};

const exportComponentAsJpeg = async (element: HTMLElement, fileName: string) => {
  if (!element) {
    throw new Error("'element' must be an HTMLElement");
  }

  return toJpeg(element, {}).then(canvas => {
    saveAs(canvas, fileName);
  });
};

export const exportComponentsAsJpeg = (elements: HTMLElement[], baseFileName: string) => {
  elements.forEach((element, i) => {
    const fileName = baseFileName.concat(i.toString());
    exportComponentAsJpeg(element, fileName);
  });
};

import CopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, TableCell, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { AthleteAssignState } from "../../components/AthleteAssignment/athleteAssignReducer";
import {
  DietitianStaffMealPlanTemplateListItemFragment,
  GoalTypeFragment,
  TeamsPickerTeamFragment,
  useCopyMacroMealPlanTemplateMutation,
  useDietitianSharedMealPlanTemplateOffsetConnectionQuery,
} from "../../types";
import { PaginationHooks } from "../../utils/pagination";
import AssignInfoCell from "./AssignInfoCell";
import BaseMealPlanTemplates from "./BaseMealPlanTemplate";
import ShareTableCell from "./ShareTableCell";
import ViewOnlyMealPlanTemplate from "./ViewOnlyMealPlanTemplate";

interface StaffMealPlanTemplateHeaderRowProps {
  direction: "desc" | "asc";
  toggleSortOrder: () => void;
}

const StaffMealPlanTemplateHeaderRow = ({ direction, toggleSortOrder }: StaffMealPlanTemplateHeaderRowProps) => (
  <TableRow>
    <TableCell sx={{ width: 100 }}>Shared</TableCell>
    <TableCell>
      <TableSortLabel direction={direction} onClick={() => toggleSortOrder()}>
        Name
      </TableSortLabel>
    </TableCell>
    <TableCell>Share With</TableCell>
    <TableCell>Actions</TableCell>
  </TableRow>
);

interface StaffMealPlanTemplateRowProps {
  mealPlanTemplate: DietitianStaffMealPlanTemplateListItemFragment;
  setSelectedTemplateId: (selectedMealPlanTemplate: string) => void;
  updateIsShared: (macroMealPlanTemplateId: string, isShared: boolean) => void;
  copyMealPlanTemplate: () => void;
  setEditMPTAssignInfo: (macroMealPlanTemplateId: string, initialState: AthleteAssignState<TeamsPickerTeamFragment>) => void;
}

const StaffMealPlanTemplateRow = ({
  mealPlanTemplate,
  setSelectedTemplateId,
  updateIsShared,
  copyMealPlanTemplate,
  setEditMPTAssignInfo,
}: StaffMealPlanTemplateRowProps) => {
  const { id, name, isShared } = mealPlanTemplate;
  return (
    <TableRow
      id={id}
      onClick={() => setSelectedTemplateId(mealPlanTemplate.id)}
      hover
      sx={{
        cursor: "pointer",
      }}
    >
      <ShareTableCell
        mealPlanTemplateId={id}
        isShared={isShared}
        updateIsShared={updateIsShared} />
      <TableCell>{name}</TableCell>
      <AssignInfoCell mealPlanTemplate={mealPlanTemplate} setEditMPTAssignInfo={setEditMPTAssignInfo} />
      <TableCell>
        <Tooltip title={"Duplicate template to your org"}>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              copyMealPlanTemplate();
            }}
            size="large"
          >
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface StaffMealPlanTemplateProps {
  pagination: PaginationHooks;
  goalTypes: readonly GoalTypeFragment[];
  onCopiedTemplate: (mealPlanTemplateId: string) => void;
}

export const StaffMealPlanTemplates = ({ pagination, goalTypes, onCopiedTemplate }: StaffMealPlanTemplateProps) => {
  const [sortAscending, setSortAscending] = useState(true);
  const { limit, offset, query: debouncedSearchText } = pagination;
  const { data, loading } = useDietitianSharedMealPlanTemplateOffsetConnectionQuery({
    variables: { pagination: { offset, limit }, searchText: debouncedSearchText, sortAscending },
  });
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const [copyMealPlanTemplate] = useCopyMacroMealPlanTemplateMutation({
    onCompleted: ({ copyMacroMealPlanTemplate: { macroMealPlanTemplate } }) => onCopiedTemplate(macroMealPlanTemplate.id),
  });
  const mealPlanTemplateRows = data?.sharedMealPlanTemplateOffsetConnection.edges || [];
  const getSortOrder = () => (sortAscending ? "asc" : "desc");
  const toggleSortOrder = () => setSortAscending(!sortAscending);

  return (
    <>
      <BaseMealPlanTemplates
        pagination={pagination}
        renderHeaderRow={() => <StaffMealPlanTemplateHeaderRow direction={getSortOrder()} toggleSortOrder={toggleSortOrder} />}
        mealPlanTemplateRows={mealPlanTemplateRows.concat()}
        loading={loading}
        total={data?.sharedMealPlanTemplateOffsetConnection.pageInfo.total || 0}
        renderMealPlanTemplateRow={(mealPlanTemplate, updateIsShared, setEditMPTAssignInfo) => (
          <StaffMealPlanTemplateRow
            key={mealPlanTemplate.id}
            mealPlanTemplate={mealPlanTemplate}
            setSelectedTemplateId={setSelectedTemplateId}
            updateIsShared={updateIsShared}
            copyMealPlanTemplate={() => copyMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId: mealPlanTemplate.id } } })}
            setEditMPTAssignInfo={setEditMPTAssignInfo}
          />
        )}
      />
      {selectedTemplateId && (
        <ViewOnlyMealPlanTemplate
          open={selectedTemplateId !== null}
          onClose={() => setSelectedTemplateId(null)}
          goalTypes={goalTypes}
          id={selectedTemplateId}
        />
      )}
    </>
  );
};

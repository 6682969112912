import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useReducer, useState } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import DiscardChangesDialog from "../../../../components/universal/DiscardChangesDialog";
import LoadingButton from "../../../../components/universal/LoadingButton";
import { RestaurantMenuDialogState } from "../types";
import { useNutritionixRestaurantFoods } from "../useNutritionixRestaurantAllBrandedItems";
import RestaurantMenuFormDiets from "./Diets";
import RestaurantSectionGroupForm from "./RestaurantSectionGroupForm";
import { restaurantMenuReducer } from "./restaurantMenuReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(),
    },
    spacer: {
      flexGrow: 1,
    },
    spacing: {
      marginLeft: theme.spacing(2),
    },
    content: {
      padding: `0 15%`,
    },
    diets: {
      marginLeft: theme.spacing(2),
      width: 400,
    },
  })
);

interface RestaurantMenuDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (state: RestaurantMenuDialogState) => void;
  // TODO: Delete is not yet implemented on server
  // onDelete: (state: RestaurantMenuDialogState) => void;
  saving: boolean;
  initialState: RestaurantMenuDialogState;
  nutritionixRestaurantId: string | null;
  restaurantName: string;
  restaurantId: string;
}

const RestaurantMenuDialog = ({
  open,
  onClose,
  initialState,
  onSave,
  saving,
  nutritionixRestaurantId,
  restaurantName,
  restaurantId,
}: RestaurantMenuDialogProps) => {
  const classes = useStyles();
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);
  const [state, dispatch] = useReducer(restaurantMenuReducer, initialState);

  const { data, error, loading } = useNutritionixRestaurantFoods(nutritionixRestaurantId);

  const { branded: restaurantNutritionixFoods } = data;

  // TODO: figure out what should go here.
  const tooltipItems: string[] = [];

  const handleSave = () => {
    onSave(state);
  };

  const handleClose = () => {
    if (state.edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen>
      <DialogTitle>
        <div className={classes.header}>
          <IconButton
            disabled={saving}
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton>
          <Typography variant="h3" className={classes.spacing}>
            {restaurantName}
          </Typography>
          <RestaurantMenuFormDiets
            className={classes.diets}
            supportedDiets={state.supportedDiets}
            onChange={supportedDiets =>
              dispatch({
                type: "ChangeSupportedDietsAction",
                payload: {
                  supportedDiets: supportedDiets || [],
                },
              })
            }
          />
          <div className={classes.spacer} />
          <TWItemizedTooltip title="Fix the following:" items={tooltipItems}>
            <div>
              <LoadingButton
                disabled={!state.edited}
                loading={saving}
                className={classes.spacing}
                onClick={handleSave}
                buttonText="Save" />
            </div>
          </TWItemizedTooltip>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {loading ? (
          <Loading />
        ) : !!error ? (
          // TODO: what should we do on loadError, probably something simple.
          <div>{error}</div>
        ) : (
          <RestaurantSectionGroupForm
            menuSections={state.sections}
            dispatch={dispatch}
            restaurantNutritionixFoods={restaurantNutritionixFoods}
            restaurantId={restaurantId}
          />
        )}
      </DialogContent>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
    </Dialog>
  );
};

export default RestaurantMenuDialog;

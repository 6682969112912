const pick = <T, K extends keyof T>(pickObj: T | undefined, propertyNames: K[]): { [key in K]: T[key] } => {
  if (!pickObj) {
    return {} as { [key in K]: T[key] };
  }
  return propertyNames.reduce((obj, prop) => ({ ...obj, [prop]: pickObj[prop] }), {} as T);
};

export const pickIfTruthy = <T, K extends keyof T>(pickObj: T | undefined, propertyNames: K[]): { [key in K]: T[key] } => {
  if (!pickObj) {
    return {} as { [key in K]: T[key] };
  }
  const newObj = propertyNames.reduce(
    (obj, prop) => ({
      ...obj,
      ...(!!pickObj[prop] ? { [prop]: pickObj[prop] } : {}),
    }),
    {} as T
  );
  return newObj;
};

export default pick;

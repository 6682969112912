import { Typography } from "@mui/material";
import React from "react";

interface MenuItemAppearancePreviewDetailProps {
  isDeleted?: boolean;
  children: React.ReactNode;
}

export const MenuItemAppearancePreviewDetail = ({ isDeleted, children }: MenuItemAppearancePreviewDetailProps) => {
  return (
    <Typography
      sx={isDeleted ? { textDecoration: "line-through" } : {}}
      variant="body2"
      color="textSecondary">
      {children}
    </Typography>
  );
};

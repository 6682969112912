import { MealPlanFormState } from "../Form/utils";

export const nameAndTypeFormToSaveTooltips = (state: MealPlanFormState): string[] => {
  let tooltips = [];
  if (!state.name) {
    tooltips.push("Name is required");
  }
  if (state.type === "exchange" && !state.exchangeSet) {
    tooltips.push("Must select an exchange set");
  }
  return tooltips;
};

import { MenuItem, MenuItemProps, SxProps, TextField, TextFieldProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";

type SelectableOption = string;

interface LabeledSelectWithPlaceholderProps<T extends SelectableOption> {
  placeholder: string;
  optionToName: (option: T) => string;
  selectedOption: T | null;
  options: T[];
  onChange: (option: T) => void;
  textFieldProps?: TextFieldProps;
  children?: React.ReactNode;
  error?: boolean;
  required?: boolean;
  margin?: "dense" | "normal";
  // MenuItemProps and MenuItem's props types don't completely match...
  MenuItemProps?: MenuItemProps & { button?: true };
  disabled?: boolean;
  helperText?: React.ReactNode;
  label?: string;
  sx?: SxProps<Theme>;
}

export const LabeledSelectWithPlaceholder = <T extends SelectableOption>({
  options,
  error,
  required,
  selectedOption,
  placeholder,
  optionToName,
  onChange,
  children,
  textFieldProps = {},
  MenuItemProps = {},
  margin = "dense",
  disabled,
  helperText,
  sx,
}: LabeledSelectWithPlaceholderProps<T>) => {
  return (
    <TextField
      sx={sx}
      disabled={disabled}
      select
      label={placeholder}
      fullWidth={true}
      margin={margin}
      value={selectedOption ? selectedOption : ""}
      error={error}
      required={required}
      helperText={helperText}
      onChange={e => {
        const newOption = options.find(o => o === e.target.value);
        newOption && onChange(newOption);
      }}
      {...textFieldProps}
    >
      {options.map(option => (
        <MenuItem
          key={option}
          value={option}
          {...MenuItemProps}>
          {optionToName(option)}
        </MenuItem>
      ))}
      {children}
    </TextField>
  );
};

import { sortByKey } from "@notemeal/utils/sort";
import React from "react";

import LabeledSelect from "../../components/universal/LabeledSelect";

import { FullSportFragment } from "../../types";
import { useSportsQuery } from "../../types";

interface SportSelectProps {
  selectedSport: FullSportFragment | null;
  onChange: (type: FullSportFragment) => void;
  disabled?: boolean;
}

const SportSelect = ({ selectedSport, onChange, disabled }: SportSelectProps) => {
  const { data, loading } = useSportsQuery();
  const sports = !loading && data ? sortByKey(data.sports, "name") : [];

  return (
    <LabeledSelect<FullSportFragment>
      placeholder="Select Sport"
      options={[...sports]}
      selectedOption={selectedSport}
      onChange={onChange}
      optionToName={o => o.name}
      textFieldProps={{
        disabled,
        margin: "dense",
      }}
    />
  );
};

export default SportSelect;

import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { EditOrgTeamworksSkippedProfile, OrgLinkerOrgFragment, TeamworksOrgFragment, useOrgsForOrgLinkerQuery } from "../../../../types";
import ConfirmCreateDialog from "../../../../views/Staff/Orgs/Sync/Orgs/ConfirmCreateDialog";
import ConfirmLinkDialog from "../../../../views/Staff/Orgs/Sync/Orgs/ConfirmLinkDialog";
import TeamworksOrgLinkDialog from "../../../../views/Staff/Orgs/Sync/Orgs/Dialog";
import { LinkedOrgsList, NotemealOrgsList, TeamworksOrgsList } from "../../../../views/Staff/Orgs/Sync/Orgs/DisplayLists";
import { SkippedProfilesDialog } from "../../../../views/Staff/Orgs/Sync/Profiles/SkippedProfilesDialog";
import StatusDialog from "../../../../views/Staff/Orgs/Sync/StatusDialog";
import ListsColumn from "../../../../views/Staff/Orgs/Sync/shared/ListColumn";

interface CreateConfirmState {
  teamworksOrg: TeamworksOrgFragment;
}

interface LinkConfirmState {
  teamworksOrg: TeamworksOrgFragment;
  org: OrgLinkerOrgFragment;
}

interface DialogOrg {
  orgId: string;
  teamworksOrgId: number;
}

export const OrgsSyncPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNotemealOrg, setSelectedNotemealOrg] = useState<OrgLinkerOrgFragment | null>(null);

  const [confirmCreateState, setConfirmCreateState] = useState<CreateConfirmState | null>(null);
  const [confirmLinkState, setConfirmLinkState] = useState<LinkConfirmState | null>(null);

  const [dialogOrg, setDialogOrg] = useState<DialogOrg | null>(null);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [showSuccessfulSyncDetails, setShowSuccessfulSyncDetails] = useState<readonly EditOrgTeamworksSkippedProfile[] | null>(null);

  const { data, loading } = useOrgsForOrgLinkerQuery();

  const baseListProps = {
    selectedId: selectedNotemealOrg?.id ?? null,
    searchQuery: searchTerm,
  };

  const baseNotemealOrgProps = {
    ...baseListProps,
    orgs: data?.orgs ?? [],
    loading,
  };

  const confirmComplete = (org: OrgLinkerOrgFragment, teamworksOrg: TeamworksOrgFragment) => {
    setSelectedNotemealOrg(null);
    setDialogOrg({
      teamworksOrgId: teamworksOrg.id,
      orgId: org.id,
    });
  };

  const createFromTeamworksOrg = (teamworksOrg: TeamworksOrgFragment) => {
    setConfirmCreateState({ teamworksOrg });
  };

  const linkTeamworksOrg = (teamworksOrg: TeamworksOrgFragment) => {
    if (selectedNotemealOrg) {
      setConfirmLinkState({ org: selectedNotemealOrg, teamworksOrg });
    }
  };

  const linkNotemealOrg = (org: OrgLinkerOrgFragment) => {
    setSelectedNotemealOrg(org);
  };
  const onCancel = () => {
    setSelectedNotemealOrg(null);
  };

  const onEdit = (org: OrgLinkerOrgFragment) => {
    if (org.teamworksId) {
      setDialogOrg({
        teamworksOrgId: org.teamworksId,
        orgId: org.id,
      });
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%", overflow: "hidden", flex: 1 }}>
      <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", alignItems: "flex-end" }}>
        <TextField
          sx={{ width: 350 }}
          label="Search Orgs"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)} />
        <Button onClick={() => setStatusDialogOpen(true)}>Sync Status</Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          height: "100%",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <ListsColumn>
          <NotemealOrgsList
            {...baseNotemealOrgProps}
            onLink={linkNotemealOrg}
            onCancel={onCancel} />
        </ListsColumn>
        <ListsColumn>
          <TeamworksOrgsList
            {...baseListProps}
            createFromTeamworksOrg={createFromTeamworksOrg}
            linkTeamworksOrg={linkTeamworksOrg} />
        </ListsColumn>
        <ListsColumn>
          <LinkedOrgsList {...baseNotemealOrgProps} onEdit={onEdit} />
        </ListsColumn>
      </Box>
      {confirmLinkState && !dialogOrg && (
        <ConfirmLinkDialog
          isOpen={confirmLinkState !== null}
          org={confirmLinkState.org}
          teamworksOrg={confirmLinkState.teamworksOrg}
          confirmComplete={(org, teamworksOrg) => {
            confirmComplete(org, teamworksOrg);
            setConfirmLinkState(null);
          }}
          confirmCancel={() => setConfirmLinkState(null)}
        />
      )}
      {confirmCreateState && !dialogOrg && (
        <ConfirmCreateDialog
          isOpen={confirmCreateState !== null}
          teamworksOrg={confirmCreateState.teamworksOrg}
          confirmComplete={(org, teamworksOrg) => {
            confirmComplete(org, teamworksOrg);
            setConfirmCreateState(null);
          }}
          confirmCancel={() => setConfirmCreateState(null)}
        />
      )}
      {dialogOrg && (
        <TeamworksOrgLinkDialog
          orgId={dialogOrg.orgId}
          teamworksOrgId={dialogOrg.teamworksOrgId}
          open={!!dialogOrg}
          onClose={() => setDialogOrg(null)}
          onSuccess={setShowSuccessfulSyncDetails}
        />
      )}
      {statusDialogOpen && (
        <StatusDialog
          open={statusDialogOpen}
          onClose={() => setStatusDialogOpen(false)}
          onEdit={(orgId, teamworksOrgId) => setDialogOrg({ orgId, teamworksOrgId })}
        />
      )}
      {showSuccessfulSyncDetails && showSuccessfulSyncDetails.length > 0 && (
        <SkippedProfilesDialog
          open={!!showSuccessfulSyncDetails}
          onClose={() => {
            setShowSuccessfulSyncDetails(null);
          }}
          skippedProfiles={showSuccessfulSyncDetails}
        />
      )}
    </Box>
  );
};

interface SortOptions {
  reverse?: boolean;
}

type SortFn<T> = (s1: T, s2: T) => number;

export const genericSort = <T>(s1: T, s2: T, options?: SortOptions) => {
  const sortFactor = options?.reverse ? -1 : 1;
  if (s1 < s2) return -1 * sortFactor;
  if (s1 > s2) return 1 * sortFactor;
  return 0;
};

export const sortFnByKey = <T>(key: keyof T, options?: SortOptions): SortFn<T> => {
  return (s1, s2) => genericSort(s1[key], s2[key], options);
};

export const sortFnByFn = <T>(fn: (item: T) => string | number, options?: SortOptions): SortFn<T> => {
  return (s1, s2) => genericSort(fn(s1), fn(s2), options);
};

export const sortByKey = <T>(objects: ReadonlyArray<T>, key: keyof T, options?: SortOptions): ReadonlyArray<T> => {
  return [...objects].sort(sortFnByKey(key, options));
};

export const sortByFn = <T>(objects: ReadonlyArray<T>, fn: (item: T) => string | number, options?: SortOptions): ReadonlyArray<T> => {
  return [...objects].sort(sortFnByFn(fn, options));
};

export const sortByKeys = <T>(objects: ReadonlyArray<T>, keys: Array<keyof T>, options?: SortOptions): ReadonlyArray<T> => {
  return [...objects].sort((a, b) => {
    return keys.reduce((sortResult, key) => sortResult || sortFnByKey(key, options)(a, b), 0);
  });
};

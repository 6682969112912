import { SxProps } from "@mui/material";
import { ScoreAssignmentFragment } from "../types";
import { Score } from "./Score";

interface ScoreBadgeProps {
  score?: ScoreAssignmentFragment | null;
  iconSize?: number;
  sx?: SxProps;
  size?: "sm" | "lg";
}

export const ScoreBadge = ({ score, size = "sm", sx }: ScoreBadgeProps) => {
  // [JIRA KS-360] use scoring system to determine icon display
  if (!score || score.scoringSystem.name !== "Bullet Score") {
    return null;
  }

  return <Score scoreValue={score.value} scoringSystem={score.scoringSystem} size={size} sx={{ ...sx }} />;
};

import { ApolloCache, Reference } from "@apollo/client";
import { NotificationStubFragment, TimelineMealForNotificationWebFragment } from "../../types";

interface AddTimelineMealNotificationToCacheArgs {
  cache: ApolloCache<object>;
  timelineMeal: TimelineMealForNotificationWebFragment;
  notification: NotificationStubFragment;
}

export const addTimelineMealNotificationToCache = ({ cache, timelineMeal, notification }: AddTimelineMealNotificationToCacheArgs) => {
  const timelineMealCacheId = cache.identify(timelineMeal);
  const athleteCacheId = timelineMeal.athlete && cache.identify(timelineMeal.athlete);
  const teamCacheId = timelineMeal.athlete && cache.identify(timelineMeal.athlete.team);
  const __ref = cache.identify(notification);
  if (timelineMealCacheId && athleteCacheId && teamCacheId && __ref) {
    const timelineMealResult = cache.modify({
      id: timelineMealCacheId,
      fields: {
        notifications: (notifications: Reference[]): Reference[] => {
          return [...notifications, { __ref }];
        },
      },
    });
    const athleteResult = cache.modify({
      id: athleteCacheId,
      fields: {
        timelineMealNotifications: (notifications: Reference[]): Reference[] => {
          return [...notifications, { __ref }];
        },
      },
    });
    const teamResult = cache.modify({
      id: teamCacheId,
      fields: {
        recentNotifications: (notifications: Reference[]): Reference[] => {
          return [...notifications, { __ref }];
        },
      },
    });
    if (!teamResult || !athleteResult || !timelineMealResult) {
      console.error(`At least one notification cache update failed:`, {
        timelineMealResult,
        athleteResult,
        teamResult,
      });
    }
  } else {
    console.error(`At least one entity failed to be identified by cache:`, {
      timelineMealCacheId,
      athleteCacheId,
      teamCacheId,
      notificationCacheId: __ref,
    });
  }
};

import { US_LOCALE_CODE } from "@notemeal/locale/utils";
import {
  AgreementFormFragment,
  IntegrationFragment,
  IntegrationInput,
  IntegrationType,
  ScoringSystem,
  StaffDashboardOrgPreviewFragment,
} from "../../../../types";

export interface OrgFormState {
  id: string | null;
  isActive: boolean;
  allowsPublicAccess: boolean;
  name: string;
  allowCompassId: boolean;
  allowCbordId: boolean;
  allowBonAppetitId: boolean;
  allowSodexoId: boolean;
  compassId: string;
  cbordId: string;
  bonAppetitId: string;
  sodexoId: string;
  agreementForm: AgreementFormFragment | null;
  scoringSystem: ScoringSystem | null;
  localeCode: string;
  territoryName: string | null;
}

export interface CreateOrgFormState extends OrgFormState {}

interface ChangeIsActive {
  type: "CHANGE_IS_ACTIVE";
  payload: boolean;
}

interface ChangeName {
  type: "CHANGE_NAME";
  payload: string;
}

interface ChangeAllowsPublicAccess {
  type: "CHANGE_ALLOWS_PUBLIC_ACCESS";
  payload: boolean;
}

interface ChangeCompassId {
  type: "CHANGE_COMPASS_ID";
  payload: string | null;
}

interface ChangeAllowCompassId {
  type: "CHANGE_ALLOW_COMPASS_ID";
  payload: boolean;
}

interface ChangeCbordId {
  type: "CHANGE_CBORD_ID";
  payload: string | null;
}

interface ChangeAllowCbordId {
  type: "CHANGE_ALLOW_CBORD_ID";
  payload: boolean;
}

interface ChangeBonAppetitId {
  type: "CHANGE_BONAPPETIT_ID";
  payload: string | null;
}

interface ChangeAllowBonAppetitId {
  type: "CHANGE_ALLOW_BONAPPETIT_ID";
  payload: boolean;
}

interface ChangeSodexoId {
  type: "CHANGE_SODEXO_ID";
  payload: string | null;
}

interface ChangeAllowSodexoId {
  type: "CHANGE_ALLOW_SODEXO_ID";
  payload: boolean;
}

interface ChangeAgreementForm {
  type: "CHANGE_AGREEMENT_FORM";
  payload: AgreementFormFragment | null;
}

interface ChangeScoringSystem {
  type: "CHANGE_SCORING_SYSTEM";
  payload: ScoringSystem | null;
}

interface ChangeLocale {
  type: "CHANGE_LOCALE";
  payload: string | null;
}

interface ChangeTerritory {
  type: "CHANGE_TERRITORY";
  payload: string | null;
}

export type OrgFormAction =
  | ChangeIsActive
  | ChangeName
  | ChangeAllowsPublicAccess
  | ChangeCompassId
  | ChangeAllowCompassId
  | ChangeCbordId
  | ChangeAllowCbordId
  | ChangeBonAppetitId
  | ChangeAllowBonAppetitId
  | ChangeSodexoId
  | ChangeAllowSodexoId
  | ChangeAgreementForm
  | ChangeScoringSystem
  | ChangeLocale
  | ChangeTerritory;

export function editOrgFormReducer<T extends OrgFormState>(state: T, action: OrgFormAction): T {
  switch (action.type) {
    case "CHANGE_IS_ACTIVE":
      return { ...state, isActive: action.payload };
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
    case "CHANGE_ALLOWS_PUBLIC_ACCESS":
      return { ...state, allowsPublicAccess: action.payload };
    case "CHANGE_COMPASS_ID":
      return { ...state, compassId: action.payload };
    case "CHANGE_CBORD_ID":
      return { ...state, cbordId: action.payload };
    case "CHANGE_BONAPPETIT_ID":
      return { ...state, bonAppetitId: action.payload };
    case "CHANGE_SODEXO_ID":
      return { ...state, sodexoId: action.payload };
    case "CHANGE_ALLOW_COMPASS_ID":
      return {
        ...state,
        allowCompassId: action.payload,
        compassId: "",
      };
    case "CHANGE_ALLOW_CBORD_ID":
      return {
        ...state,
        allowCbordId: action.payload,
        cbordId: "",
      };
    case "CHANGE_ALLOW_BONAPPETIT_ID":
      return {
        ...state,
        allowBonAppetitId: action.payload,
        bonAppetitId: "",
      };
    case "CHANGE_ALLOW_SODEXO_ID":
      return {
        ...state,
        allowSodexoId: action.payload,
        sodexoId: "",
      };
    case "CHANGE_AGREEMENT_FORM":
      return {
        ...state,
        agreementForm: action.payload,
      };
    case "CHANGE_SCORING_SYSTEM":
      return {
        ...state,
        scoringSystem: action.payload,
      };
    case "CHANGE_LOCALE":
      return {
        ...state,
        // don't update localeCode if payload is null
        localeCode: action.payload ?? state.localeCode,
      };
    case "CHANGE_TERRITORY":
      return {
        ...state,
        // don't update territoryName if payload is null
        territoryName: action.payload ?? state.territoryName,
      };
    default:
      return state;
  }
}
export function createOrgFormReducer<T extends CreateOrgFormState>(state: T, action: OrgFormAction): T {
  switch (action.type) {
    case "CHANGE_IS_ACTIVE":
      return { ...state, isActive: action.payload };
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
    case "CHANGE_ALLOWS_PUBLIC_ACCESS":
      return { ...state, allowsPublicAccess: action.payload };
    case "CHANGE_LOCALE":
      return {
        ...state,
        // don't update localeCode if payload is null
        localeCode: action.payload ?? state.localeCode,
      };
    case "CHANGE_TERRITORY":
      return {
        ...state,
        // don't update territoryName if payload is null
        territoryName: action.payload ?? state.territoryName,
      };
    default:
      return state;
  }
}

export const createOrgFormToSaveTooltips = (state: CreateOrgFormState): string[] => {
  const tooltips: string[] = [];
  if (!state?.name.trim()) {
    tooltips.push("'Name' is required");
  }
  return tooltips;
};
export const editOrgFormToSaveTooltips = (state: OrgFormState): string[] => {
  const tooltips: string[] = [];
  if (!state.name.trim()) {
    tooltips.push("'Name' is required");
  }
  //Stops " " from being saved, makes " " -> null and turns the integration off
  if (state.allowSodexoId && !state.sodexoId?.trim()) {
    tooltips.push("Enter a valid 'Sodexo Id' or disable Sodexo integration");
  }
  if (state.allowBonAppetitId && !state.bonAppetitId?.trim()) {
    tooltips.push("Enter a valid 'BonAppetit Id' or disable BonAppetit integration");
  }
  if (state.allowCbordId && !state.cbordId?.trim()) {
    tooltips.push("Enter a valid 'CBORD Id' or disable CBORD integration");
  }
  if (state.allowCompassId && !state.compassId.trim()) {
    tooltips.push("Enter a valid 'Compass Id' or disable Compass integration");
  }
  return tooltips;
};

export const initialCreateOrgFormState = (): CreateOrgFormState => ({
  id: null,
  allowsPublicAccess: true,
  isActive: true,
  name: "",
  compassId: "",
  cbordId: "",
  bonAppetitId: "",
  sodexoId: "",
  allowCompassId: false,
  allowCbordId: false,
  allowBonAppetitId: false,
  allowSodexoId: false,
  agreementForm: null,
  scoringSystem: null,
  localeCode: US_LOCALE_CODE,
  territoryName: null,
});

export const initialEditOrgFormState = ({
  id,
  allowsPublicAccess,
  isActive,
  name,
  agreementForm,
  integrations,
  scoringSystem,
  localeCode,
  territoryName,
}: StaffDashboardOrgPreviewFragment): OrgFormState => ({
  id,
  allowsPublicAccess,
  isActive,
  name,
  agreementForm,
  ...integrationToFormState(integrations),
  scoringSystem,
  localeCode,
  territoryName,
});

type integrationInputProps = Pick<
  OrgFormState,
  "compassId" | "cbordId" | "bonAppetitId" | "sodexoId" | "allowCompassId" | "allowCbordId" | "allowBonAppetitId" | "allowSodexoId"
>;

export const integrationInput = ({
  compassId,
  cbordId,
  bonAppetitId,
  sodexoId,
  allowCompassId,
  allowCbordId,
  allowBonAppetitId,
  allowSodexoId,
}: integrationInputProps): IntegrationInput[] => {
  const compassIntegration = allowCompassId ? { type: COMPASS, externalOrgId: compassId } : [];
  const cbordIntegration = allowCbordId ? { type: CBORD, externalOrgId: cbordId } : [];
  const bonAppetitIntegration = allowBonAppetitId ? { type: BONAPPETIT, externalOrgId: bonAppetitId } : [];
  const sodexoIntegration = allowSodexoId ? { type: SODEXO, externalOrgId: sodexoId } : [];
  return [compassIntegration, cbordIntegration, bonAppetitIntegration, sodexoIntegration].flatMap(i => i);
};

export const integrationToFormState = (integrations: readonly IntegrationFragment[]) => {
  const compassId = integrations.find(i => i.type === COMPASS)?.externalOrgId ?? "";
  const cbordId = integrations.find(i => i.type === CBORD)?.externalOrgId ?? "";
  const bonAppetitId = integrations.find(i => i.type === BONAPPETIT)?.externalOrgId ?? "";
  const sodexoId = integrations.find(i => i.type === SODEXO)?.externalOrgId ?? "";
  return {
    compassId,
    allowCompassId: !!compassId,
    cbordId,
    allowCbordId: !!cbordId,
    bonAppetitId,
    allowBonAppetitId: !!bonAppetitId,
    sodexoId,
    allowSodexoId: !!sodexoId,
  };
};

const COMPASS: IntegrationType = "compass";
const CBORD: IntegrationType = "cbord";
const BONAPPETIT: IntegrationType = "bonAppetit";
const SODEXO: IntegrationType = "sodexo";

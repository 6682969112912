import { TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import classNames from "classnames";
import React, { useReducer, useState } from "react";
import { SportWithPositionMappingsFragment, TeamworksPositionLinkFragment, useTeamworksPositionsQuery } from "../../../../../types";
import ConfirmDialog from "../shared/ConfirmDialog";
import SportContent from "../Sports/SportContent";
import { BaseCombinedSportState, buildInitialSportState, hasValidSubmitState, sportBuilderReducer, SportState } from "../Sports/utils";
import { NotemealTeam, TeamworksTeam } from "./reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      overflowY: "hidden",
    },
    sectionContainer: {
      margin: theme.spacing(2, 0),
    },
    firstContainer: {
      marginTop: 0,
      display: "flex",
    },
    nameField: {
      flex: 2,
      marginRight: theme.spacing(2),
    },
    genderField: {
      flex: 1,
      marginLeft: theme.spacing(2),
    },
    header: {
      paddingBottom: theme.spacing(1),
    },
    root: {
      height: "100%",
    },
  })
);

interface OnConfirmArgs {
  sportState: BaseCombinedSportState;
  gender: string | null;
  name: string;
}

export interface BaseTeamDialogProps {
  onConfirm: (args: OnConfirmArgs) => void;
  onCancel: () => void;
  teamworksOrgId: number;
  teamworksTeam: TeamworksTeam;
  notemealTeam?: NotemealTeam;
  isOpen: boolean;
  sports: readonly SportWithPositionMappingsFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
  title: string;
  usedTeamNames: string[];
  initialName?: string;
  initialGender?: string;
  initialSportState?: SportState;
}

const hasValidName = (name: string, usedTeamNames: string[]) => {
  return name !== "" && !usedTeamNames.includes(name.trim().trim().toLowerCase());
};

const BaseTeamDialog = ({
  onConfirm,
  onCancel,
  teamworksTeam,
  notemealTeam,
  isOpen,
  sports,
  teamworksPositionLinks,
  title,
  teamworksOrgId,
  usedTeamNames,
  initialName,
  initialGender,
  initialSportState,
}: BaseTeamDialogProps) => {
  const classes = useStyles();
  const [name, setName] = useState(initialName ?? teamworksTeam.name);
  const [gender, setGender] = useState(initialGender ?? notemealTeam?.gender ?? "");
  const [sportState, dispatch] = useReducer(
    sportBuilderReducer,
    initialSportState ?? buildInitialSportState(teamworksTeam, sports, notemealTeam)
  );
  const { data: unfilteredTeamorksPositionsData, loading } = useTeamworksPositionsQuery({
    variables: {
      teamId: teamworksTeam.id,
      teamworksOrgId,
    },
  });

  const hasValidNameValue = hasValidName(name, usedTeamNames);

  const hasValidPayload = hasValidSubmitState(sportState, sports) && hasValidName(name, usedTeamNames);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title={title}
      maxWidth="lg"
      fullWidth
      classes={{
        root: classes.root,
        header: classes.header,
        content: classes.content,
      }}
      content={
        <>
          <div className={classNames(classes.sectionContainer, classes.firstContainer)}>
            <TextField
              label="Team Name"
              value={name}
              onChange={e => setName(e.target.value)}
              fullWidth
              className={classes.nameField}
              error={!hasValidNameValue}
              helperText={hasValidNameValue ? null : "Team Names must be defined and must be unique for an Org"}
            />
            <TextField
              label="Gender"
              value={gender}
              onChange={e => {
                setGender(e.target.value);
              }}
              fullWidth
              className={classes.genderField}
            />
          </div>
          {!loading && unfilteredTeamorksPositionsData ? (
            <SportContent
              sportState={sportState}
              dispatch={dispatch}
              teamworksTeam={teamworksTeam}
              notemealTeam={notemealTeam}
              sports={sports}
              unfilteredTeamorksPositions={unfilteredTeamorksPositionsData.teamworksOrgGroups}
              teamworksPositionLinks={teamworksPositionLinks}
            />
          ) : (
            <Loading />
          )}
        </>
      }
      onCancel={() => onCancel()}
      onConfirm={() =>
        onConfirm({
          name,
          sportState,
          gender: gender ?? null,
        })
      }
      confirmDisabled={!hasValidPayload}
    />
  );
};

export default BaseTeamDialog;

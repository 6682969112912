import { Card, CardActionArea, CardContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      overflow: "visible",
      borderLeft: `${theme.spacing(1)} solid ${theme.palette.info.light}`,
    },
    content: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
    },
    countSpan: {
      marginRight: theme.spacing(),
    },
  })
);

interface ArchiveAthleteItemProps {
  onClick: () => void;
  className?: string;
  unlinkedAthleteCount: number;
  archiveAthleteCount: number;
}

const ArchiveAthleteItem = ({ onClick, className, unlinkedAthleteCount, archiveAthleteCount }: ArchiveAthleteItemProps) => {
  const classes = useStyles();

  return (
    <Card raised className={classnames(className, classes.root)}>
      <CardActionArea onClick={onClick}>
        <CardContent className={classes.content}>
          <div>
            <Typography>Non-User Account Athletes</Typography>
            <Typography>Archive athletes without user accounts that should not be synced with Teamworks</Typography>
            <Typography variant="body2" color="textSecondary">
              <span className={classes.countSpan}>Non-User Account: {unlinkedAthleteCount}</span>
              <span className={classes.countSpan}>Pending Archives: {archiveAthleteCount}</span>
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ArchiveAthleteItem;

import React from "react";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";

import { MenuItemTableFragment, useMenuItemRatingsQuery } from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import Content from "./MenuItemRatingsContent";

interface MenuItemRatingsDialogProps {
  open: boolean;
  onClose: () => void;
  menuItem: MenuItemTableFragment;
}

const MenuItemRatingsDialog = ({ open, onClose, menuItem }: MenuItemRatingsDialogProps) => {
  const paginationHooks = useOffsetPagination();
  const { offset, onChangePage } = paginationHooks;
  const { data } = useMenuItemRatingsQuery({
    variables: {
      menuItemHistoryId: menuItem.history.id,
      input: {
        offset,
        limit: 25,
      },
    },
  });

  return !data ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Content
      open={open}
      onClose={onClose}
      menuItem={menuItem}
      currentReviews={data.currentReviews.edges}
      allReviews={data.allReviews.edges}
      paginationHooks={paginationHooks}
      currentRevisionReviewCount={data.currentReviews.pageInfo.total}
      allRevisionsReviewCount={data.allReviews.pageInfo.total}
      goToFirstPage={() => onChangePage(0)}
    />
  );
};

export default MenuItemRatingsDialog;

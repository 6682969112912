import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import React, { Dispatch, useMemo } from "react";
import { AdvancedSelectionGroup, EditGroupAction, RemoveGroupAction } from "../../../reducers/advancedSelectionReducers";
import { TagCondition } from "./TagCondition";

interface TagGroupProps {
  group: AdvancedSelectionGroup;
  dispatch: Dispatch<EditGroupAction | RemoveGroupAction>;
}

const TagGroup = ({ group, dispatch }: TagGroupProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  const [existingIncludes, existingExcludes, usedPositionIds, usedTagIds, usedTeamIds] = useMemo(() => {
    return [
      group.conditions.flatMap(condition =>
        condition.isComplement === false && condition.__typename !== "Empty" ? condition.__typename : []
      ),
      group.conditions.flatMap(condition =>
        condition.isComplement === true && condition.__typename !== "Empty" ? condition.__typename : []
      ),
      group.conditions.flatMap(condition => (condition.__typename === "Positions" ? condition.positions.map(({ id }) => id) : [])),
      group.conditions.flatMap(condition => (condition.__typename === "Tags" ? condition.tags.map(({ tag: { id } }) => id) : [])),
      group.conditions.flatMap(condition => (condition.__typename === "Teams" ? condition.teams.map(({ id }) => id) : [])),
    ];
  }, [group]);

  return (
    <Box sx={{ p: 2, border: `1px solid ${greyscale[400]}`, borderRadius: "5px", mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography>
          If <strong>all</strong> of the conditions are met:
        </Typography>
        <IconButton size="small" onClick={() => dispatch({ type: "REMOVE_GROUP", payload: { groupId: group.id } })}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>
      {group.conditions.map(condition => (
        <TagCondition
          groupId={group.id}
          conditionState={condition}
          dispatch={dispatch}
          key={condition.id}
          existingIncludes={existingIncludes}
          existingExcludes={existingExcludes}
          usedPositionIds={usedPositionIds}
          usedTagIds={usedTagIds}
          usedTeamIds={usedTeamIds}
        />
      ))}
      <Button
        variant="text"
        startIcon={<AddIcon />}
        onClick={() => dispatch({ type: "ADD_CONDITION", payload: { groupId: group.id } })}>
        Add Condition
      </Button>
    </Box>
  );
};

export default TagGroup;

import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { genericSort } from "@notemeal/utils/sort";
import React, { useState } from "react";
import { IMatchResult, MatchType } from "../../../../utils/import/match";
import SearchBarDefault from "../../../universal/SearchBar/Default";
import { Entity } from "../../../universal/SearchBar/type";
import ViewImportRowDialog from "./ViewMatchedRow";

interface ImportResultListProps<T, A> {
  matches: IMatchResult<T, A>[];
  selectedMatchIndices: boolean[];
  selectMatchIndex: (idx: number) => void;
  onMatch?: (match: IMatchResult<T, A>, idx: number) => void;
  existingRows?: (T & Entity)[];
  onChange?: (row: T, type: MatchType, newRow: T, idx: number) => void;
  allowShowImportRow?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "inherit",
      overflowY: "auto",
    },
    wideResults: {
      width: 300,
    },
    thinListItem: {
      width: "60%",
    },
  })
);

const valToText = (val: any) => {
  if (!!(val as Date) && (val as Date).getMonth && typeof (val as Date).getMonth === "function") {
    return serializeDate(val as Date);
  } else {
    return val;
  }
};

export const matchFieldsToText = <T extends object>(matchFields: (keyof T)[], row: T): string => {
  return matchFields.reduce((curr, field) => `${curr}${valToText(row[field])}, `, "").slice(0, -2);
};

export const matchFieldsToVerboseText = <T extends object, A extends object>(match: IMatchResult<T, A>): React.ReactNode => {
  const rowMatchFields = matchFieldsToText(match.matchFields, match.row);
  const matchedRowMatchFields = match.matchedRows[0] && matchFieldsToText(match.matchFields, match.matchedRows[0]);
  return (
    <>
      <Typography variant="body1Medium">{matchFieldsToText(match.matchFields, match.row)}</Typography>
      <Typography variant="subtitle1" color="error">
        {matchedRowMatchFields && matchedRowMatchFields !== rowMatchFields && matchedRowMatchFields}
      </Typography>
    </>
  );
  // TODO: Do some cool red font to show that this has been matched!
};

const ImportResultList = <T extends object, A extends object>({
  existingRows,
  matches,
  onMatch,
  selectedMatchIndices,
  selectMatchIndex,
  onChange,
  allowShowImportRow,
}: ImportResultListProps<T, A>) => {
  const classes = useStyles();
  const sortMatches = (a: IMatchResult<T, A>, b: IMatchResult<T, A>) =>
    genericSort(matchFieldsToText(a.matchFields, b.row), matchFieldsToText(b.matchFields, b.row));
  const [matchForPeekImportRow, setMatchForPeekImportRow] = useState<[IMatchResult<T, A>, number] | null>(null);

  const handleChange = (row: T, type: MatchType, newRow: T) => {
    onChange && matchForPeekImportRow && onChange(row, type, newRow, matchForPeekImportRow[1]);
    setMatchForPeekImportRow(null);
  };

  return (
    <>
      <List className={classes.root}>
        {matches.sort(sortMatches).map((match, idx) => (
          <div key={idx}>
            <ListItem
              className={onMatch && existingRows ? classes.thinListItem : ""}
              button
              onClick={() => selectMatchIndex(idx)}
              key={matchFieldsToText(match.matchFields, match.row)}
            >
              <ListItemIcon>
                <Checkbox checked={selectedMatchIndices[idx] || false} />
              </ListItemIcon>
              <ListItemText
                primary={matchFieldsToVerboseText<T, A>({
                  matchFields: match.matchFields,
                  row: match.row,
                  matchedRows: match.matchedRows,
                  type: "one",
                })}
              />
              {allowShowImportRow && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={e => {
                      e.stopPropagation();
                      setMatchForPeekImportRow([match, idx]);
                    }}
                    size="large"
                  >
                    <InfoIcon fontSize={"small"} color={"info"}></InfoIcon>
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {onMatch && existingRows && (
              <SearchBarDefault<T & Entity>
                menuClassName={classes.wideResults}
                fullWidth={false}
                objects={existingRows}
                objectToName={r => matchFieldsToText(match.matchFields, r)}
                onSelectObject={r => {
                  const newMatchResult = {
                    row: match.row,
                    matchFields: match.matchFields,
                    type: "one" as const,
                    matchedRows: [r as T & Entity & A],
                  };
                  onMatch(newMatchResult, idx);
                }}
                label="Match to Existing Record"
              />
            )}
          </div>
        ))}
      </List>
      {matchForPeekImportRow && (
        <ViewImportRowDialog
          open={!!matchForPeekImportRow}
          onClose={() => setMatchForPeekImportRow(null)}
          match={matchForPeekImportRow[0]}
          onChange={handleChange}
        ></ViewImportRowDialog>
      )}
    </>
  );
};

export default ImportResultList;

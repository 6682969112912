import { Chip, ChipProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Entity } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0.5, 0),
    },
  })
);

interface EntityChipProps<T extends Entity> {
  chipProps: ChipProps;
  label: string;
  onDelete?: (obj: T) => void;
}

const EntityChip = <T extends Entity>({ onDelete, label, chipProps, ...props }: EntityChipProps<T>) => {
  const classes = useStyles();

  return <Chip label={label} onDelete={onDelete} className={classes.root} {...chipProps} {...props} />;
};

export default EntityChip;

import React from "react";

import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import { useMenuItemDialogQuery } from "../../../../types";
import { RestaurantMenuItemAppearanceState } from "../types";
import { RestaurantMenuItemAppearanceDialogContent } from "./RestaurantMenuItemAppearanceDialogContent";

interface RestaurantMenuItemAppearanceDialogProps {
  open: boolean;
  onClose: () => void;
  menuItemAppearance: RestaurantMenuItemAppearanceState;
  onDone: (menuItemAppearance: RestaurantMenuItemAppearanceState) => void;
}

export const RestaurantMenuItemAppearanceDialog = ({
  open,
  onClose,
  onDone,
  menuItemAppearance,
}: RestaurantMenuItemAppearanceDialogProps) => {
  const { data } = useMenuItemDialogQuery({
    variables: { menuItemId: menuItemAppearance.menuItem.id },
  });

  return !data ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <RestaurantMenuItemAppearanceDialogContent
      open={open}
      onClose={onClose}
      onDone={onDone}
      menuItem={data?.currentMenuItem}
      menuItemAppearance={menuItemAppearance}
    />
  );
};

import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { Dispatch } from "react";
import { TeamLabelFormAction, TeamLabelFormState } from "./utils";

interface TeamLabelFormProps {
  state: TeamLabelFormState;
  dispatch: Dispatch<TeamLabelFormAction>;
}

const TeamLabelForm = ({ state, dispatch }: TeamLabelFormProps) => (
  <>
    <TextField
      sx={{ width: 250 }}
      autoFocus
      margin="dense"
      value={state.name}
      onChange={e => dispatch({ type: "CHANGE_NAME", payload: { name: e.target.value } })}
      label="Name"
    />
    <FormControlLabel
      control={
        <Checkbox checked={state.isActive} onChange={(_, isActive) => dispatch({ type: "CHANGE_IS_ACTIVE", payload: { isActive } })} />
      }
      label="Is Active"
    />
  </>
);

export default TeamLabelForm;

import { addMinutes } from "date-fns";
import {
  MealPlanCalendarEvent,
  MealPlanScheduleEvent,
  SuggestedMealTemplateCalendarEvent,
  TeamworksCalendarEvent,
  isMealTemplateCalendarEvent,
} from "../types";
import { serializeTime } from "@notemeal/shared/ui/utils/dateTimes";
import { getNameForMealPlanCalendarEvent } from "../utils";
import { SuggestedMealPlanProps, getSuggestedMealEventsForCalendarEvents } from "@notemeal/meal-plan-suggestions/service";
import { ActivityEvent, MealEvent, SuggestedEvent, MealType } from "@notemeal/meal-plan-suggestions/core";

interface GetSuggestedEventsArgs {
  twCalendarEvents: readonly TeamworksCalendarEvent[];
  events: readonly MealPlanScheduleEvent[];
  mealPlanId: string | null;
  modifiedMealTemplateIds: string[];
}

export const getSuggestedEvents = ({ twCalendarEvents, events, mealPlanId, modifiedMealTemplateIds }: GetSuggestedEventsArgs) => {
  // only generate suggestions for non-past events
  const nonPastEvents = events.filter(e => e.start >= new Date());

  // don't fetch suggestions if there are no events to schedule around or no meal plan events
  if (nonPastEvents.length === 0 || twCalendarEvents.length === 0) {
    return [];
  }

  return getSuggestedMealEventsForCalendarEvents(
    getPayloadForSuggestionsQuery(twCalendarEvents, nonPastEvents, mealPlanId, modifiedMealTemplateIds)
  ).flatMap(event => getSuggestedMealTemplateCalendarEventForEvent(event, events));
};

export const getPayloadForSuggestionsQuery = (
  externalEvents: readonly TeamworksCalendarEvent[],
  mealPlanEvents: readonly MealPlanScheduleEvent[],
  mealPlanId: string | null,
  modifiedMealTemplateIds: string[]
): SuggestedMealPlanProps => {
  const eventsForMealPlan = mealPlanEvents.filter(event => event.mealPlanId === mealPlanId);
  return {
    mealPlanEvents: eventsForMealPlan.map(event => {
      if (isMealTemplateCalendarEvent(event)) {
        const mealEvent: MealEvent = {
          id: event.id,
          durationInMinutes: event.durationInMinutes,
          isAllDay: event.isAllDay ?? false,
          start: event.start,
          mealType: event.mealTemplate.meal.type as MealType,
          name: getNameForMealPlanCalendarEvent(event),
          __typename: "meal",
        };
        return mealEvent;
      } else {
        const newEvent: ActivityEvent = {
          id: event.id,
          durationInMinutes: event.durationInMinutes,
          isAllDay: event.isAllDay ?? false,
          start: event.start,
          name: getNameForMealPlanCalendarEvent(event),
          __typename: "activity",
        };
        return newEvent;
      }
    }),
    externalEvents: externalEvents.map(event => ({
      id: event.id,
      durationInMinutes: event.durationInMinutes,
      isAllDay: event.isAllDay ?? false,
      start: event.start,
      name: getNameForMealPlanCalendarEvent(event),
      __typename: "external",
    })),
    modifiedMealTemplateIds,
  };
};

export const getSuggestedMealTemplateCalendarEventForEvent = (
  event: SuggestedEvent,
  mealPlanEvents: readonly MealPlanCalendarEvent[]
): SuggestedMealTemplateCalendarEvent | [] => {
  const matchingMealEvent = mealPlanEvents.find(mealEvent => mealEvent.id === event.oldId);
  if (!matchingMealEvent || !isMealTemplateCalendarEvent(matchingMealEvent)) {
    return [];
  }

  const end = addMinutes(new Date(event.start), event.durationInMinutes);
  return {
    type: "SuggestedMealTemplate",
    id: event.id,
    oldId: event.oldId,
    oldStart: event.oldStart,
    start: event.start,
    durationInMinutes: event.durationInMinutes,
    isAllDay: matchingMealEvent.isAllDay,
    mealPlanId: matchingMealEvent.mealPlanId,
    mealTemplate: {
      ...matchingMealEvent.mealTemplate,
      meal: {
        ...matchingMealEvent.mealTemplate.meal,
        id: event.id,
        start: serializeTime(event.start),
        end: serializeTime(end),
      },
    },
  };
};

export const resolveEventsWithSuggestions = (
  isAutoSuggestionsEnabled: boolean,
  events: readonly MealPlanCalendarEvent[],
  suggestedEvents: SuggestedMealTemplateCalendarEvent[],
  acceptedSuggestionKeys: string[],
  rejectedSuggestionKeys: string[]
): MealPlanCalendarEvent[] => {
  let acceptedSuggestions: SuggestedMealTemplateCalendarEvent[] = [];
  let remainingSuggestions: SuggestedMealTemplateCalendarEvent[] = [];

  if (isAutoSuggestionsEnabled) {
    acceptedSuggestions = suggestedEvents;
    remainingSuggestions = [];
  } else {
    // separate out the accepted and remaining suggestions
    for (const suggestion of suggestedEvents) {
      if (acceptedSuggestionKeys.includes(getKeyForSuggestion(suggestion))) {
        acceptedSuggestions.push(suggestion);
      } else {
        remainingSuggestions.push(suggestion);
      }
    }
  }

  // get meal events for accepted suggestions
  const mealEventsForResolvedSuggestions: MealPlanCalendarEvent[] = acceptedSuggestions.map(se => {
    return {
      id: se.oldId,
      type: "MealTemplate",
      start: se.start,
      durationInMinutes: se.durationInMinutes,
      isAllDay: se.isAllDay,
      mealTemplate: se.mealTemplate,
      mealPlanId: se.mealPlanId,
    };
  });
  // filter out the events that accepted suggestions will replace
  const resolvedOriginalEvents: MealPlanCalendarEvent[] = events.filter(e => {
    const acceptedSuggestion = acceptedSuggestions.find(sg => {
      return sg.oldStart.getTime() === e.start.getTime() && sg.oldId === e.id;
    });
    return acceptedSuggestion === undefined;
  });

  return [
    ...mealEventsForResolvedSuggestions,
    ...resolvedOriginalEvents,
    // filter out rejected suggestions
    ...remainingSuggestions.filter(s => !rejectedSuggestionKeys.includes(getKeyForSuggestion(s))),
  ];
};

export const getKeyForSuggestion = (event: SuggestedMealTemplateCalendarEvent): string => {
  return `${event.oldId}-${event.start.getTime()}`;
};

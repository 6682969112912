import React, { useState } from "react";
import PaymentMethodTable from "../../../../components/universal/TablePage/NameOnlyTablePage";
import {
  PaymentMethodFragment,
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useEditPaymentMethodMutation,
  useStaffDashboardPaymentMethodsQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";

import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import PaymentMethodDialog from "../../../../components/universal/RenameDialog";

const PAYMENT_METHOD = "Payment Method";

export const PaymentMethodsPage = () => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading, refetch } = useStaffDashboardPaymentMethodsQuery({
    variables: {
      input: { limit, offset },
      query,
    },
    fetchPolicy: "network-only",
  });

  const [editPaymentMethod] = useEditPaymentMethodMutation({
    onCompleted: () => refetch(),
  });
  const [createPaymentMethod] = useCreatePaymentMethodMutation({
    onCompleted: () => refetch(),
  });
  const [deletePaymentMethod] = useDeletePaymentMethodMutation({
    onCompleted: () => refetch(),
  });
  const handleEditPaymentMethod = (name: string) => {
    if (editingPaymentMethod) {
      editPaymentMethod({
        variables: { input: { name, id: editingPaymentMethod.id } },
      });
    }
  };
  const handleCreatePaymentMethod = (name: string) => {
    createPaymentMethod({ variables: { input: { name } } });
  };
  const handleDeletePaymentMethod = () => {
    if (deletePaymentMethodId) {
      deletePaymentMethod({
        variables: { input: { id: deletePaymentMethodId } },
      });
      setDeletePaymentMethodId(null);
    }
  };
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [editingPaymentMethod, setEditingPaymentMethod] = useState<PaymentMethodFragment | null>(null);
  const [deletePaymentMethodId, setDeletePaymentMethodId] = useState<string | null>(null);

  return (
    <>
      <PaymentMethodTable
        onEdit={setEditingPaymentMethod}
        onDelete={setDeletePaymentMethodId}
        data={data?.paymentMethodOffsetConnection}
        loading={loading}
        paginationHooks={paginationHooks}
        onCreate={() => setShowCreate(true)}
        title={PAYMENT_METHOD}
      />

      {showCreate && (
        <PaymentMethodDialog
          open={showCreate}
          onClose={() => setShowCreate(false)}
          onSave={handleCreatePaymentMethod}
          type={PAYMENT_METHOD}
        />
      )}
      {!!editingPaymentMethod && (
        <PaymentMethodDialog
          open={editingPaymentMethod !== null}
          onClose={() => setEditingPaymentMethod(null)}
          onSave={handleEditPaymentMethod}
          entity={editingPaymentMethod}
          type={PAYMENT_METHOD}
        />
      )}
      {!!deletePaymentMethodId && (
        <ConfirmationDialog
          open={deletePaymentMethodId !== null}
          title={`Delete ${PAYMENT_METHOD}`}
          message="Are you sure that you would like to delete the payment method?"
          onCancel={() => setDeletePaymentMethodId(null)}
          onConfirm={handleDeletePaymentMethod}
          variant="containedDestructive"
        />
      )}
    </>
  );
};

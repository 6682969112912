import PrintIcon from "@mui/icons-material/Print";
import { Checkbox, Dialog, DialogContent, Divider, Fab, FormControlLabel, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useMenuItemsForPrintableNutrientInfoQuery } from "../../../types";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import PrintableNutrientInfo from "./MenuItemPrintableNutrientInfo";
import { getMenuItemPrintWarnings } from "./utils";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    paper: {
      height: `calc(100% - 64px)`,
    },
    content: {
      display: "flex",
      padding: 0,
      margin: 0,
      "&:first-child": {
        padding: 0,
      },
    },
    pages: {
      backgroundColor: grey[300],
      padding: spacing(2),
      overflow: "auto",
    },
    pagesDiv: {
      width: "8.5in",
      backgroundColor: "white",
    },
    pageBreak: { marginTop: ".25in" },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: spacing(2),
    },
    printButton: {
      margin: spacing(2, 2, 4, 2),
      display: "flex",
      justifyContent: "space-around",
    },
    itemWrapper: {
      maxHeight: "calc(11in / 2)",
      minHeight: "calc(11in / 2)",
      display: "flex",
    },
    item: {
      width: "100%",
      margin: spacing(3),
    },
  })
);

interface MenuItemPrintableNutrientInfoDialogProps {
  menuItemIds: readonly string[];
  open: boolean;
  onClose: () => void;
}

const MenuItemPrintableNutrientInfoDialog = ({ menuItemIds, open, onClose }: MenuItemPrintableNutrientInfoDialogProps) => {
  const classes = useStyles();
  const { data, loading } = useMenuItemsForPrintableNutrientInfoQuery({
    variables: { menuItemIds },
  });
  const [showNoIngredients, setShowNoIngredients] = useState(true);
  const [showAddOns, setShowAddOns] = useState(true);
  // const [pageDivs, setPageDivs] = useState<HTMLDivElement[]>([]);

  const componentRef = useRef(null);
  const handleClickPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (loading || !data) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const menuItems = data.menuItems.filter(mi => {
    const { hasNoIngredients, hasAddOnNutrients } = getMenuItemPrintWarnings(mi);
    const ingredientViolation = !showNoIngredients && hasNoIngredients;
    const addOnViolation = !showAddOns && hasAddOnNutrients;
    return !(ingredientViolation || addOnViolation);
  });

  const itemWrapperStyle = (index: number) =>
    index === 0 || index % 2 !== 0 ? classes.itemWrapper : classNames(classes.pageBreak, classes.itemWrapper);

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}>
      <DialogContent className={classes.content}>
        <div className={classes.pages}>
          <div ref={componentRef} className={classes.pagesDiv}>
            {menuItems.map((menuItem, index) => (
              <div key={menuItem.id} className={itemWrapperStyle(index)}>
                <div className={classes.item}>
                  <PrintableNutrientInfo menuItem={menuItem} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <Divider flexItem />
        <div className={classes.form}>
          <Fab
            variant="extended"
            className={classes.printButton}
            onClick={handleClickPrint}>
            <PrintIcon />
            Print
          </Fab>
          <Typography>Show Items with:</Typography>
          <FormControlLabel
            control={<Checkbox checked={showNoIngredients} onChange={(_, value) => setShowNoIngredients(value)} />}
            label="No Ingredients"
          />
          <FormControlLabel
            control={<Checkbox checked={showAddOns} onChange={(_, value) => setShowAddOns(value)} />}
            label="Add-Ons Nutrients"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MenuItemPrintableNutrientInfoDialog;

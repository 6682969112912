import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React from "react";
import { ExchangeSetFragment, ExchangeSetsDocument, ExchangeSetsQuery } from "../../../types";
import { useDeleteExchangeSetMutation } from "../../../types/index";

interface DeleteConfirmModalProps {
  onClose: () => void;
  open: boolean;
  exchangeSetToDelete: ExchangeSetFragment;
}

const DeleteConfirmModal = ({ onClose, open, exchangeSetToDelete }: DeleteConfirmModalProps) => {
  const [deleteExchangeSet] = useDeleteExchangeSetMutation({
    update: (dataProxy, result) => {
      const exchangeSetsQuery = dataProxy.readQuery<ExchangeSetsQuery>({
        query: ExchangeSetsDocument,
      });
      if (result.data && exchangeSetsQuery) {
        dataProxy.writeQuery({
          query: ExchangeSetsDocument,
          data: {
            ...exchangeSetsQuery,
            exchangeSets: exchangeSetsQuery.exchangeSets.filter(es => es.id !== result.data?.deleteExchangeSet.id),
          },
        });
      }
      onClose();
    },
  });

  const onDelete = () => {
    deleteExchangeSet({
      variables: {
        input: {
          id: exchangeSetToDelete.id,
        },
      },
    });
  };

  return (
    <ConfirmationDialog
      open={open}
      title="Delete Exchange Set"
      message={`Are you sure you want to delete '${exchangeSetToDelete.name}'?`}
      onCancel={onClose}
      onConfirm={onDelete}
      variant="containedDestructive"
    />
  );
};

export default DeleteConfirmModal;

import { WebSocketLink } from "@apollo/client/link/ws";
import { createHttpLink, split } from "@apollo/client";

import { getMainDefinition } from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getUser } from "../utils/tokens";
import { addSeconds } from "date-fns";
import { dedupedRefresh } from "./dedupedRefresh";
import { initReporting } from "../reporting/reporting";

const graphqlUrl = `${process.env.REACT_APP_URL_SCHEME}${process.env.REACT_APP_SERVER_DOMAIN}/graphql`;
const wsUrl = graphqlUrl.replace("https", "wss").replace("http", "ws");

// datadog has to be initialized before apollo client or we won't get the datadog headers appended to our requests
// see https://github.com/apollographql/apollo-client/issues/7130
initReporting();

export const wsClient = new SubscriptionClient(wsUrl, {
  reconnect: true,
  lazy: true,
  timeout: 45000,
  inactivityTimeout: 1000,
  connectionParams: async () => {
    const accessToken = getUser();
    if (!accessToken || new Date(accessToken.exp * 1000) < addSeconds(new Date(), 15)) {
      const result = await dedupedRefresh();
      if (result) {
        return {
          jwtToken: result.data.accessToken,
        };
      }
    }
  },
});

const httpLink = createHttpLink({
  uri: graphqlUrl,
  credentials: "include",

  headers: {
    "x-notemeal-web-schema-version": process.env.REACT_APP_WEB_SCHEMA_VERSION,
  },
});

const wsLink = new WebSocketLink(wsClient);

export const apiLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);

import React from "react";
import { buildInitialDuplicateState } from "../../../reducers/combinedTagReducers";
import CreateDialogContent from "../Create/CreateDialogContent";
import NamedTagDataLoaderDialog from "../NamedTagDataLoader";

interface DuplicateDialogProps {
  tagId: string;
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
}

const DuplicateDialog = ({ resetTable, ...props }: DuplicateDialogProps) => {
  return (
    <NamedTagDataLoaderDialog
      {...props}
      title="Duplicate Tag"
      renderContent={namedTag => {
        const initialState = buildInitialDuplicateState(namedTag);
        return <CreateDialogContent
          onClose={props.onClose}
          initialState={initialState}
          resetTable={resetTable} />;
      }}
    />
  );
};

export default DuplicateDialog;

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import React from "react";
import { TWIconButton } from "./TWIconButton";

export const TWIconButtonExample = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TWIconButton icon={<MoreVertIcon />} onClick={() => {}} />
    </Box>
  );
};

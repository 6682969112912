import { makeStyles } from "@mui/styles";

export const useNoPlateStyles = () => {
  return makeStyles(noPlateStyles)();
};

export const useWithPlateStyles = () => {
  return makeStyles(withPlateStyles)();
};

export const useExpandedStyles = () => {
  return makeStyles(expandedStyles)();
};

const parentStyles = {
  display: "flex",
  flexFlow: "column",
  width: "98%",
};
const tallExchangeBox = {
  marginBottom: 6,
  // TODO: A bit of a hack, but prevent "half of a serving" to be displayed
  // ...better solution is to set a fixed height for the chip list within
};

const expandedStyles = {
  mealPlateContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(16, minmax(0, 1fr))",
    gridTemplateRows: "repeat(36, minmax(0, 1fr))",
    width: `inherit`,
    height: `732px`,
  },
};

const noPlateStyles = {
  mealPlateContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(16, minmax(0, 1fr))",
    gridTemplateRows: "repeat(36, minmax(0, 1fr))",
    width: `inherit`,
    height: `483px`,
  },
  topLeft: {
    ...parentStyles,
    gridRowStart: 1,
    gridRowEnd: 24,
    gridColumnStart: 1,
    gridColumnEnd: 5,
    zIndex: 1,
  },
  topLeftMid: {
    ...parentStyles,
    gridRowStart: 1,
    gridRowEnd: 24,
    gridColumnStart: 5,
    gridColumnEnd: 9,
    zIndex: 1,
  },
  topRightMid: {
    ...parentStyles,
    gridRowStart: 1,
    gridRowEnd: 24,
    gridColumnStart: 9,
    gridColumnEnd: 13,
    zIndex: 1,
  },
  topRight: {
    ...parentStyles,
    gridRowStart: 1,
    gridRowEnd: 24,
    gridColumnStart: 13,
    gridColumnEnd: 17,
    zIndex: 1,
  },
  bottomLeft: {
    ...parentStyles,
    width: "99%",
    gridRowStart: 24,
    gridRowEnd: 37,
    gridColumnStart: 1,
    gridColumnEnd: 9,
    zIndex: 1,
  },
  bottomRight: {
    ...parentStyles,
    width: "99%",
    gridRowStart: 24,
    gridRowEnd: 37,
    gridColumnStart: 9,
    gridColumnEnd: 17,
    zIndex: 1,
  },
};

const withPlateStyles = {
  mealPlateContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(16, minmax(0, 1fr))",
    gridTemplateRows: "repeat(18, minmax(0, 1fr))",
    width: `inherit`,
    height: `468px`,
  },
  topLeft: {
    ...parentStyles,
    ...tallExchangeBox,
    gridRowStart: 1,
    gridRowEnd: 10,
    gridColumnStart: 1,
    gridColumnEnd: 6,
    zIndex: 1,
  },
  topMid: {
    ...parentStyles,
    gridRowStart: 1,
    gridRowEnd: 6,
    gridColumnStart: 6,
    gridColumnEnd: 12,
    zIndex: 1,
  },
  topRight: {
    ...parentStyles,
    ...tallExchangeBox,
    gridRowStart: 1,
    gridRowEnd: 10,
    gridColumnStart: 12,
    gridColumnEnd: 17,
    zIndex: 1,
  },
  athletePlateColumn: {
    gridRowStart: 7,
    gridRowEnd: 13,
    gridColumnStart: 6,
    gridColumnEnd: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomLeft: {
    ...parentStyles,
    ...tallExchangeBox,
    gridRowStart: 10,
    gridRowEnd: 19,
    gridColumnStart: 1,
    gridColumnEnd: 6,
    zIndex: 1,
  },
  bottomMid: {
    ...parentStyles,
    gridRowStart: 14,
    gridRowEnd: 19,
    gridColumnStart: 6,
    gridColumnEnd: 12,
    zIndex: 1,
  },
  bottomRight: {
    ...parentStyles,
    ...tallExchangeBox,
    gridRowStart: 10,
    gridRowEnd: 19,
    gridColumnStart: 12,
    gridColumnEnd: 17,
    zIndex: 1,
  },
};

import { useState, useEffect } from "react";
import axios from "axios";
import { sortByKey } from "@notemeal/utils/sort";

export interface RestaurantInfo {
  name: string;
  id: string;
}

interface UseGetNutritionixRestaurantsPayload {
  data: RestaurantInfo[];
  error: string | null;
  loading: boolean;
}

export const useNutritionixRestaurants = (): UseGetNutritionixRestaurantsPayload => {
  const [data, setData] = useState<RestaurantInfo[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setloading] = useState<boolean>(true);

  const fetchData = () => {
    axios
      .get<RestaurantInfo[]>("https://d1gvlspmcma3iu.cloudfront.net/restaurants-3d-party.json.gz")
      .then(res => {
        const sortedData = sortByKey(res.data, "name");
        setData([...sortedData]);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, error, loading };
};

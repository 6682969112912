import { TypographyOptions } from "@mui/material/styles/createTypography";

// Font Weights
export const Light = 300;
export const Regular = 400;
export const Medium = 500;
export const Semibold = 600;
export const Bold = 700;

export const typographyCommon: TypographyOptions = {
  fontFamily: `"InterVariable", "sans-serif"`,
  fontSize: 14,
  fontWeightLight: Light,
  fontWeightRegular: Regular,
  fontWeightMedium: Medium,
  fontWeightBold: Bold,
  h1: {
    fontSize: 34,
    fontWeight: Semibold,
    lineHeight: "40px",
  },
  h2: {
    fontSize: 24,
    fontWeight: Semibold,
    lineHeight: "32px",
  },
  h3: {
    fontSize: 20,
    fontWeight: Semibold,
    lineHeight: "28px",
  },
  h4: {
    fontSize: 16,
    fontWeight: Semibold,
    lineHeight: "24px",
  },
  body1: {
    fontSize: 16,
    fontWeight: Regular,
    lineHeight: "24px",
  },
  body1Medium: {
    fontSize: 16,
    fontWeight: Medium,
    lineHeight: "24px",
  },
  body1Semibold: {
    fontSize: 16,
    fontWeight: Semibold,
    lineHeight: "24px",
  },
  body2: {
    fontSize: 14,
    fontWeight: Regular,
    lineHeight: "18px",
  },
  body2Medium: {
    fontSize: 14,
    fontWeight: Medium,
    lineHeight: "18px",
  },
  body2Semibold: {
    fontSize: 14,
    fontWeight: Semibold,
    lineHeight: "18px",
  },
  subtitle1: {
    fontSize: 12,
    fontWeight: Regular,
    lineHeight: "16px",
  },
  subtitle1Medium: {
    fontSize: 12,
    fontWeight: Medium,
    lineHeight: "16px",
  },
  subtitle1Semibold: {
    fontSize: 12,
    fontWeight: Semibold,
    lineHeight: "16px",
  },
  caption: {
    fontSize: 11,
    fontWeight: Regular,
    lineHeight: "14px",
  },
  captionMedium: {
    fontSize: 11,
    fontWeight: Medium,
    lineHeight: "14px",
  },
  footnotes: {
    fontSize: 10,
    fontWeight: Regular,
    lineHeight: "12px",
  },
  footnotesSemibold: {
    fontSize: 10,
    fontWeight: Semibold,
    lineHeight: "12px",
  },
  footnotesBold: {
    fontSize: 10,
    fontWeight: Bold,
    lineHeight: "12px",
  },
  tinylabels: {
    fontSize: 8,
    fontWeight: Bold,
    lineHeight: "12px",
  },
  button: {
    textTransform: "none",
    fontWeight: Semibold,
  },

  // Disable MUI defaults that we will not use
  h5: undefined,
  h6: undefined,
  subtitle2: undefined,
  //button: undefined,
  overline: undefined,
};

import { MenuItemSuggestionCategory } from "../../../../types";

export const getSuggestionCategoryShortLabel = (suggestionCategory: MenuItemSuggestionCategory | null): string => {
  switch (suggestionCategory) {
    case "combo":
      return "Combo";
    case "main":
      return "Main";
    case "protein":
      return "Protein";
    case "starch":
      return "Starch";
    case "vegetable":
      return "Vegetable";
    case "fruit":
      return "Fruit";
    case "other":
      return "Other";
    case null:
      return "Automatic";
    default:
      return "";
  }
};

export const getSuggestionCategoryLabel = (suggestionCategory: MenuItemSuggestionCategory | null): string => {
  switch (suggestionCategory) {
    case "combo":
      return "Combo / Plate / Build-Your-Own";
    case "main":
      return "Main / Entree";
    case "protein":
      return "Protein (A La Carte)";
    case "starch":
      return "Starch (A La Carte)";
    case "vegetable":
      return "Vegetable (A La Carte)";
    case "fruit":
      return "Fruit (A La Carte)";
    case "other":
      return "Other / Misc.";
    case null:
      return "Automatic";
    default:
      return "";
  }
};

export const getSuggestionCategoryTooltip = (suggestionCategory: MenuItemSuggestionCategory | null): string => {
  switch (suggestionCategory) {
    case "combo":
      return "Core item of a meal with lots of add-ons, i.e. a plate with choices of an entree and sides or a Build-Your-Own salad.";
    case "main":
      return "Core item of a meal with no or few add-ons, i.e. an entree with pre-set sides or a sandwich.";
    case "protein":
      return "A la carte protein item, i.e. a grilled chicken breast, tofu, or even a pre-set smoothie. Should have no or few add-ons.";
    case "starch":
      return "A la carte starch item, i.e. rice or potatoes. Should have no or few add-ons.";
    case "vegetable":
      return "A la carte vegetable item, i.e. broccoli or a side salad. Should have no or few add-ons.";
    case "fruit":
      return "A la carte fruit item, i.e. an apple or a fruit cup. Should have no or few add-ons.";
    case "other":
      return "Items that do not fit into any of the above categories, i.e. condiments, beverages, and some snacks. Will be excluded from suggestions.";
    case null:
      return "Item will be categorized automatically by suggestion engine.";
    default:
      return "";
  }
};

import { Box, DialogContent, styled } from "@mui/material";
import { DigiDisplaysMealMenuFragment } from "apps/web/src/types";
import React, { useReducer } from "react";
import { CustomizationsContent } from "./CustomizationsPanelContent";
import { DisplayContent } from "./DisplayContent";
import { digitalDisplaysConfigReducer, initDigitalDisplaysConfigState } from "./reducer";

const ContentContainer = styled(Box)(
  ({
    theme: {
      spacing,
      palette: { grey },
    },
  }) => ({
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    alignSelf: "stretch",
    justifyContent: "flex-end",
    backgroundColor: grey[100],
    height: "100vh",
    padding: spacing(0),
  })
);
const DisplayBox = styled(DialogContent)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "flex-start",
  width: "100%",
  padding: spacing(3),
  gap: spacing(3),
}));

const CustomizationsBox = styled(Box)(
  ({
    theme: {
      spacing,
      palette: { grey },
    },
  }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "300px",
    alignItems: "left",
    margin: spacing(0),
    borderTopColor: grey[100],
    borderTop: 1,
    backgroundColor: "white",
  })
);

interface DigitalDisplayContentProps {
  mealMenu: DigiDisplaysMealMenuFragment;
  refs: React.MutableRefObject<HTMLDivElement[]>;
}

export const DigitalDisplayContent = ({ mealMenu, refs }: DigitalDisplayContentProps) => {
  const [state, dispatch] = useReducer(digitalDisplaysConfigReducer, initDigitalDisplaysConfigState(mealMenu.mealMenuDiningStations));

  return (
    <ContentContainer>
      <DisplayBox>{mealMenu && <DisplayContent
        state={state}
        mealMenu={mealMenu}
        refs={refs} />}</DisplayBox>
      <CustomizationsBox>{mealMenu && <CustomizationsContent state={state} dispatch={dispatch} />}</CustomizationsBox>
    </ContentContainer>
  );
};

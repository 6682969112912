import MenuIcon from "@mui/icons-material/Menu";
import { Dialog, DialogContent, IconButton, ListItemButton, ListItemText, Popover, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "grid",
      gap: theme.spacing(4),
      padding: `${theme.spacing(4)}px !important`,
      gridAutoRows: "auto auto 1fr",
      gridAutoColumns: "1fr minmax(500px, 1fr)",
    },
    navigation: {
      padding: 0,
      marginBottom: theme.spacing(-2),
      justifySelf: "flex-end",
      gridRow: "1 / 1",
      gridColumn: "2 / 2",
    },
    headerRow: {
      display: "flex",
      paddingLeft: theme.spacing(30),
    },
    item: {
      width: theme.spacing(25),
      textAlign: "center",
    },
  })
);

interface KioskProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Kiosk = ({ open, onClose, children }: KioskProps) => {
  const classes = useStyles();
  const [showHomeMenu, setShowHomeMenu] = useState<HTMLElement | null>(null);

  const handleNavigateBack = () => {
    setShowHomeMenu(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogContent className={classes.content}>
        <IconButton
          onClick={e => {
            setShowHomeMenu(e.currentTarget);
          }}
          className={classes.navigation}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        {children}
        {showHomeMenu && (
          <Popover
            open={!!showHomeMenu}
            onClose={() => setShowHomeMenu(null)}
            anchorEl={showHomeMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ListItemButton onClick={handleNavigateBack}>
              <ListItemText>Back To Configuration</ListItemText>
            </ListItemButton>
          </Popover>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Kiosk;

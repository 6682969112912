import { TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { GenerateWithGPTFormType } from "./GenerateWithGPTForm";

interface GenerateWithGPTContentProps {
  form: UseFormReturn<GenerateWithGPTFormType>;
}

export const GenerateWithGPTContent = ({ form }: GenerateWithGPTContentProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <>
      <Typography variant="body1" color="textSecondary">
        Be as specific as possible in order for our AI-powered recipe generator to give you the best results!
      </Typography>
      <Controller
        name={"name"}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            autoFocus={!field.value}
            label="Recipe Description"
            placeholder="e.g., Vegan pesto pasta"
          />
        )}
      />
      <Controller
        name={"servingYield"}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            value={field.value ?? ""}
            sx={{ width: "25%" }}
            error={Boolean(errors.servingYield)}
            helperText={errors.servingYield?.message}
            label="Servings"
            placeholder="#"
            type="number"
          />
        )}
      />
    </>
  );
};

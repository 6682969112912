import { TableCell, TableRow } from "@mui/material";
import TablePage from "apps/web/src/components/universal/TablePage";
import TablePageDefaultHeader from "apps/web/src/components/universal/TablePage/DefaultHeader";
import { useStaffScoringSystemsQuery } from "apps/web/src/types";
import { useOffsetPagination } from "apps/web/src/utils/pagination";
import React from "react";

export const ScoringSystemsPage = () => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, queryText, query: debouncedSearchText, onChangeQueryText } = paginationHooks;
  const { data, loading } = useStaffScoringSystemsQuery({
    variables: { pagination: { offset, limit }, searchText: debouncedSearchText },
  });

  const Header = <TablePageDefaultHeader
    title={"Scoring System"}
    queryText={queryText}
    onChangeQueryText={onChangeQueryText} />;

  const TableBodyRows = data?.scoringSystemOffsetConnection?.edges.map(system => (
    <TableRow key={system.id} sx={{ py: 1 }}>
      <TableCell scope="row">{system.name}</TableCell>
      <TableCell scope="row">WIP</TableCell>
      <TableCell scope="row">3</TableCell>
    </TableRow>
  ));

  const TableHeaderRow = () => (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>Icon</TableCell>
      <TableCell># of Options</TableCell>
    </TableRow>
  );

  return (
    <TablePage
      header={Header}
      tableHeaderRow={<TableHeaderRow />}
      tableBodyRows={TableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={data?.scoringSystemOffsetConnection?.pageInfo.total || 0}
    />
  );
};

import React from "react";
import ExportMealExchangeLists, { ExportMealExchangeListsProps } from "./ExchangeLists";
import ExportMealRow, { ExportMealRowProps } from "./Row";

type ExportMealPageProps = ExportMealRowProps & ExportMealExchangeListsProps & { condensed: boolean };

const ExportMealPage = ({
  mealTemplate,
  exchangeSet,
  displayMacros,
  displayMealOptions,
  displayExchanges,
  displayMealOptionMacros,
  displaySecondaryExchanges,
  expandRecipes,
  orientation,
  condensed,
  expandExchanges,
  recipes,
}: ExportMealPageProps) => {
  return (
    <div>
      <ExportMealRow
        mealTemplate={mealTemplate}
        expandRecipes={expandRecipes}
        orientation={orientation}
        displayMacros={displayMacros}
        displayMealOptions={displayMealOptions}
        displayMealOptionMacros={displayMealOptionMacros}
        displayExchanges={displayExchanges}
        recipes={recipes}
      />
      {!condensed && (
        <ExportMealExchangeLists
          displaySecondaryExchanges={displaySecondaryExchanges}
          exchangeSet={exchangeSet}
          mealTemplate={mealTemplate}
          expandExchanges={expandExchanges}
          recipes={recipes}
        />
      )}
    </div>
  );
};

export default ExportMealPage;

import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import {
  PlannedMenuWeekForImportFragment,
  PlannedMenuWithWeeksFragment,
  TeamMealMenuPreviewFragment,
  usePlannedMenuWeeksForImportLazyQuery,
  usePlannedMenuWeeksQuery,
} from "apps/web/src/types";
import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useReducer, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { MenuBuilderWeek, transformWeeks } from "../../../MenuBuilder/Builder/utils";
import { ShareMealMenuAction, menuSharingReducer } from "../../reducer";
import { ShareMealMenuState } from "../../types";
import { getEmptyShareState } from "../../utils";
import { ImportPlannedMenuFormSchema, ImportPlannedMenuType, getImportPlannedMenuDefaultValues } from "./ImportMenuSchema";
type ImportPlannedMenuContextValue = {
  plannedMenuId: string | null;
  setPlannedMenuId: Dispatch<SetStateAction<string | null>>;

  plannedMenu?: PlannedMenuWithWeeksFragment;
  weeksLoading: boolean;
  mealsLoading: boolean;

  plannedMenuWeeksWithMeals: readonly PlannedMenuWeekForImport[];

  onClose: () => void;
  onSuccess: (menuName: string) => void;

  allTeams: readonly TeamMealMenuPreviewFragment[];
  allWeeks: MenuBuilderWeek[];

  getSelectedWeeksData: (selectedWeekIds: string[]) => void;
  form: UseFormReturn<ImportPlannedMenuType>;
  shareState: ShareMealMenuState;
  setShareState: React.Dispatch<ShareMealMenuAction>;
  selectedWeeks: MenuBuilderWeek[];
};

export type PlannedMenuWeekForImport = PlannedMenuWeekForImportFragment & { weekStartDate: Date };

const ImportPlannedMenusContext = createContext<ImportPlannedMenuContextValue>({
  plannedMenuId: null,
  setPlannedMenuId: () => {},

  plannedMenu: undefined,
  weeksLoading: false,
  mealsLoading: false,

  plannedMenuWeeksWithMeals: [],

  onClose: () => {},
  onSuccess: () => {},
  allTeams: [],
  allWeeks: [],

  getSelectedWeeksData: () => {},
  form: {} as any,
  shareState: getEmptyShareState(),
  setShareState: () => {},
  selectedWeeks: [],
});

interface PlannedMenusImportProviderProviderProps {
  children: ReactNode;
  onClose: () => void;
  onSuccess: (menuName: string) => void;
  plannedMenuId: string;
  allTeams: readonly TeamMealMenuPreviewFragment[];
}

export const PlannedMenuImportProvider = ({
  children,
  plannedMenuId,
  onClose,
  onSuccess,
  allTeams,
}: PlannedMenusImportProviderProviderProps) => {
  const { setMessage } = useSnackbar();
  const { data, loading: weeksLoading } = usePlannedMenuWeeksQuery({
    variables: { plannedMenuId: plannedMenuId },
    onCompleted: data => {
      setAllWeeks(transformWeeks(data.plannedMenu.weeks, data.plannedMenu));
    },
  });

  const [shareState, dispatch] = useReducer(menuSharingReducer, getEmptyShareState());

  const [allWeeks, setAllWeeks] = useState<MenuBuilderWeek[]>([]);
  const [plannedMenuWeeksWithMeals, setPlannedMenuWeeksWithMeals] = useState<
    readonly (PlannedMenuWeekForImportFragment & { weekStartDate: Date })[]
  >([]);

  const getSelectedWeeksData = (selectedWeekIds: string[]) => {
    getWeeksDataForImport({ variables: { plannedMenuWeekIds: selectedWeekIds } });
  };

  const form = useForm<ImportPlannedMenuType>({
    resolver: zodResolver(ImportPlannedMenuFormSchema),
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  const [getWeeksDataForImport, { loading: mealsLoading }] = usePlannedMenuWeeksForImportLazyQuery({
    onCompleted: data => {
      setPlannedMenuWeeksWithMeals(
        data.plannedMenuWeeks.map((w, i) => {
          return { ...w, weekStartDate: selectedWeeks[i].startDate };
        })
      );
      form.setValue("weeks", getImportPlannedMenuDefaultValues(data.plannedMenuWeeks).weeks);
      form.reset({}, { keepValues: true });
    },
    onError: () => {
      setMessage("error", "An unknown error occurred. Check your network connection or try again in a few minutes.");
    },
  });

  const selectedWeekIds = form.watch("weeks") && form.watch("weeks").map(week => week.id);
  let selectedWeeks: MenuBuilderWeek[] = [];
  selectedWeeks = selectedWeekIds ? allWeeks.filter(week => selectedWeekIds.includes(week.id)) : [];

  return (
    <ImportPlannedMenusContext.Provider
      value={{
        plannedMenuId,
        setPlannedMenuId: () => {},
        plannedMenu: data?.plannedMenu,
        allTeams,
        allWeeks,
        weeksLoading,
        mealsLoading,
        onClose,
        onSuccess,
        plannedMenuWeeksWithMeals,
        getSelectedWeeksData,
        form,
        shareState,
        setShareState: dispatch,
        selectedWeeks,
      }}
    >
      {children}
    </ImportPlannedMenusContext.Provider>
  );
};

export const useImportPlannedMenu = () => {
  const state = useContext(ImportPlannedMenusContext);
  return state;
};

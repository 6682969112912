import { Box, Typography } from "@mui/material";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { getServingAmountMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { scaleMacros, sumMacros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { RecipeWithServingsFragment } from "../../../../types";

interface PrintRecipeMacrosProps {
  recipe: RecipeWithServingsFragment;
}

export function PrintRecipeMacros({ recipe }: PrintRecipeMacrosProps) {
  const perRecipeYield = recipe.servings[0].perRecipeYield ?? 1;
  const { cho: totalCho, pro: totalPro, fat: totalFat, kcal: totalKcal } = sumMacros(recipe.ingredients.map(getServingAmountMacros));
  const macros = scaleMacros(
    {
      cho: totalCho,
      pro: totalPro,
      fat: totalFat,
      kcal: totalKcal,
    },
    recipe.servings[0].defaultAmount / perRecipeYield
  );

  return (
    <Box sx={theme => ({ pb: theme.spacing(1) })}>
      <MacrosSummaryLabel
        macros={macros}
        kcalPrefix
        variant="sm"
        sx={{ display: "inline" }} />
      <Typography variant="body2" sx={{ display: "inline" }}>
        {" (per serving)"}
      </Typography>
    </Box>
  );
}

import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { Card, CardActionArea, CardContent, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ProfileSyncRuleWithEntities, TeamworksTeam, getIsValidSyncRule } from "@notemeal/profile-sync";
import { DraggableSpring } from "@notemeal/shared/ui/hooks/useDraggable";
import classnames from "classnames";
import React from "react";
import { animated } from "react-spring";
import { ReactEventHandlers } from "react-use-gesture/dist/types";
import { getItemsDescriptionWithLimit, getNonObjectItemsDescriptionWithLimit } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      overflow: "visible",
      height: 100,
      margin: `${theme.spacing(1, 0)} !important`,
    },
    animatedDiv: {
      marginRight: 16,
      marginLeft: 16,
      borderRadius: 4,
    },
    content: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    action: {
      margin: theme.spacing(-2, 0),
      padding: theme.spacing(2, 0),
    },
    removeButton: {
      marginLeft: theme.spacing(1),
      cursor: "pointer",
    },
    countSpan: {
      marginRight: theme.spacing(),
    },
    priorityDiv: {
      width: theme.spacing(5),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    draggable: {
      cursor: "grab",
    },
  })
);

interface ProfileSyncRuleItemProps {
  profileSyncRule: ProfileSyncRuleWithEntities;
  onClick: () => void;
  onRemove: () => void;
  className?: string;
  allLinkedTeamworkTeams: readonly TeamworksTeam[];
  bindProps?: ReactEventHandlers;
  spring?: DraggableSpring;
}

const ProfileSyncRuleItem = ({
  profileSyncRule,
  onClick,
  onRemove,
  className,
  allLinkedTeamworkTeams,
  spring,
  bindProps,
}: ProfileSyncRuleItemProps) => {
  const classes = useStyles();

  const unlinkedCount = profileSyncRule.unlinkedProfiles.length;
  const pendingCount = profileSyncRule.linkedProfiles.filter(p => p.isPending).length;
  const linkedCount = profileSyncRule.linkedProfiles.filter(p => !p.isPending).length;

  const { isValidSyncRule } = getIsValidSyncRule(profileSyncRule, allLinkedTeamworkTeams);
  let style: any = {};
  if (spring) {
    const { zIndex, y, shadow } = spring;
    style = {
      zIndex,
      transform: y.to(y => `translate3d(0, ${y}px, 0)`),
      position: "relative",
      boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
    };
  }

  return (
    <animated.div style={style} className={classes.animatedDiv}>
      <Card raised className={classnames(className, classes.root)}>
        <CardContent className={classes.content}>
          <div className={classnames(classes.priorityDiv, { [classes.draggable]: !!spring })} {...(bindProps || {})}>
            <Typography>{profileSyncRule.priority}</Typography>
          </div>
          <CardActionArea onClick={onClick} className={classes.action}>
            <div>
              <Typography>Sync Teamworks Profiles{getSyncRuleDescription(profileSyncRule)}</Typography>
              <Typography>Notemeal Account Type: {profileSyncRule.notemealAccountType}</Typography>
              <Typography variant="body2" color="textSecondary">
                <span className={classes.countSpan}>Unlinked: {unlinkedCount}</span>
                <span className={classes.countSpan}>Pending: {pendingCount}</span>
                <span className={classes.countSpan}>Linked: {linkedCount}</span>
              </Typography>
            </div>
            {!isValidSyncRule && (
              <Tooltip title="Set Teamworks Selection Criteria to Link Profiles">
                <ErrorIcon fontSize="large" />
              </Tooltip>
            )}
          </CardActionArea>
          <CloseIcon className={classes.removeButton} onClick={onRemove} />
        </CardContent>
      </Card>
    </animated.div>
  );
};

const getSyncRuleDescription = (profileSyncRule: ProfileSyncRuleWithEntities) => {
  if (profileSyncRule.matchOnProfiles) {
    return `: ${getItemsDescriptionWithLimit(profileSyncRule.profiles, "email")}`;
  } else {
    const teamsText = profileSyncRule.teams.length > 0 ? ` on ${getItemsDescriptionWithLimit(profileSyncRule.teams, "name")}` : "";
    const userTypesText =
      profileSyncRule.userTypes.length > 0 ? ` with user type ${getItemsDescriptionWithLimit(profileSyncRule.userTypes, "label")}` : "";
    const athleteStatusesText =
      profileSyncRule.athleteStatuses.length > 0
        ? ` with athlete status ${getItemsDescriptionWithLimit(profileSyncRule.athleteStatuses, "label")}`
        : "";
    const positionsText =
      profileSyncRule.positions.length > 0 ? ` with position ${getItemsDescriptionWithLimit(profileSyncRule.positions, "label")}` : "";
    const genderText =
      profileSyncRule.genders.length > 0 ? ` with gender ${getNonObjectItemsDescriptionWithLimit(profileSyncRule.genders)}` : "";
    return [teamsText, userTypesText, athleteStatusesText, positionsText, genderText].filter(t => !!t).join(",");
  }
};

export default ProfileSyncRuleItem;

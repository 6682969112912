import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useEffect, useRef, useState } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";
import { MutationType, RenderForm, TOrUndefined, UseFormReducer, UseMutation } from "./types";

export interface MutationDialogProps<T, FormState, FormAction, MT extends MutationType> {
  maxWidth: "xl" | "xs";
  open: boolean;
  onClose: () => void;
  onSaveSuccess: (obj: T) => void;
  obj: TOrUndefined<T, MT>;
  renderForm: RenderForm<FormState, FormAction>;
  useMutation: UseMutation<T, FormState, MT>;
  useFormReducer: UseFormReducer<T, FormState, FormAction, MT>;
  buildFormToolTips: (state: FormState) => string[];
  dialogTitle: string;
}

const MutationDialog = <T, FormState, FormAction, MT extends MutationType>({
  maxWidth,
  open,
  onClose,
  onSaveSuccess,
  obj,
  renderForm,
  useMutation,
  useFormReducer,
  buildFormToolTips,
  dialogTitle,
}: MutationDialogProps<T, FormState, FormAction, MT>) => {
  const isMountedRef = useRef(false);
  const { setMessage } = useSnackbar();
  const [state, dispatch] = useFormReducer(obj);
  const handleMutation = useMutation({ setErrorMsg: message => setMessage("error", message || ""), onClose, onSaveSuccess });
  const [isSaving, setIsSaving] = useState(false);

  const canSaveTooltips = buildFormToolTips(state);

  const handleSave = async () => {
    if (canSaveTooltips.length) {
      return;
    }
    setIsSaving(true);
    await handleMutation({ obj, state });
    if (isMountedRef.current) {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  if (isSaving) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth>
      <DialogTitle title={dialogTitle} onClose={onClose} />
      <DialogContent>
        {renderForm({
          state,
          dispatch,
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default MutationDialog;

import React from "react";
import { OrgLinkerOrgFragment } from "../../../../../../types";
import { CardMode } from "../../shared/DisplayCard";
import DisplayList, { RenderListItemArgs } from "../../shared/DisplayList";
import OrgCard from "./OrgCard";

interface OrgFilterArgs {
  isLinked: boolean;
  hasSearchQueryMatch: boolean;
  isSelected: boolean;
}

interface BaseNotemealOrgsListProps {
  selectedId: string | null;
  searchQuery: string;
  onAdd?: (org: OrgLinkerOrgFragment) => void;
  onLink?: (org: OrgLinkerOrgFragment) => void;
  onEdit?: (org: OrgLinkerOrgFragment) => void;
  onCancel?: (org: OrgLinkerOrgFragment) => void;
  orgs: readonly OrgLinkerOrgFragment[];
  loading: boolean;
  title: string;
  columnMode: CardMode;
  filterOrg: (args: OrgFilterArgs) => boolean;
}

const BaseNotemealOrgsList = ({
  selectedId,
  searchQuery,
  onAdd,
  onLink,
  onEdit,
  onCancel,
  orgs: _orgs,
  loading,
  title,
  columnMode,
  filterOrg,
}: BaseNotemealOrgsListProps) => {
  const orgs = _orgs.filter(org => {
    const hasSearchQueryMatch = org.name.toLowerCase().includes(searchQuery.toLowerCase());
    const isLinked = org.teamworksId !== null;
    const isSelected = org.id === selectedId;
    return filterOrg({ isLinked, hasSearchQueryMatch, isSelected });
  });

  const renderListItem = ({ key, ...restProps }: RenderListItemArgs<OrgLinkerOrgFragment>) => {
    return <OrgCard
      onAdd={onAdd}
      onLink={onLink}
      onEdit={onEdit}
      onCancel={onCancel}
      key={key}
      {...restProps} />;
  };

  return (
    <DisplayList
      title={title}
      columnMode={columnMode}
      renderListItem={renderListItem}
      elements={orgs}
      disabled={selectedId !== null}
      loading={loading}
      selectedId={selectedId}
    />
  );
};

export default BaseNotemealOrgsList;

import { Button, Dialog, DialogActions, DialogContent, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import TWItemizedTooltip from "../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { useSendFoodOrRecipeRequest } from "../../utils/sendFoodOrServingRequest";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCopy: {
      marginBottom: theme.spacing(1),
      color: theme.palette.grey[700],
    },
    dialogContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textField: {
      marginBottom: theme.spacing(12),
    },
  })
);

interface ServingAmountsRequestServingModalProps {
  open: boolean;
  onClose: () => void;
  foodName: string;
  onRequestSuccess: (msg: string) => void;
  onRequestFailure: (msg: string) => void;
}

const ServingAmountsRequestServingModal = ({
  open,
  onClose,
  foodName,
  onRequestSuccess,
  onRequestFailure,
}: ServingAmountsRequestServingModalProps) => {
  const classes = useStyles();
  const [servingName, setServingName] = useState("");
  const sendFoodOrServingRequest = useSendFoodOrRecipeRequest();

  const cantSubmitRequestTooltips = !servingName
    ? [`Enter 'serving name' before submitting`]
    : servingName.length < 3
    ? [`Serving must be at least 3 characters`]
    : [];

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Request Serving" onClose={onClose} />
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2" className={classes.subCopy}>
          Enter the serving name that you would like to request and our support team will add the serving for you.
        </Typography>
        <TextField
          autoFocus
          label={`Serving Name`}
          type="text"
          value={servingName}
          onChange={e => setServingName(e.target.value)}
          onKeyDown={e => {
            e.stopPropagation();
          }}
          fullWidth
          className={classes.textField}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <TWItemizedTooltip items={cantSubmitRequestTooltips} title="Fix following before submitting">
          <Button
            type="submit"
            onClick={e => {
              e.preventDefault();
              if (cantSubmitRequestTooltips.length) {
                return;
              } else {
                sendFoodOrServingRequest({
                  mode: "serving",
                  servingRequest: servingName,
                  foodName,
                  onSuccess: () => {
                    onRequestSuccess(
                      `Request for serving "${servingName}" on ${foodName} has been sent and will be added by the Nutrition staff shortly!`
                    );
                  },
                  onFailure: () => {
                    onRequestFailure(`Failed to send request for serving "${servingName}" on ${foodName}.`);
                  },
                });
                onClose();
              }
            }}
          >
            Send Request
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ServingAmountsRequestServingModal;

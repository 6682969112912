import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import { devEnv } from "../NavUtils";
import { getTheme } from "./Theme";

export type ThemeMode = "light" | "system" | "dark";

const THEME_PREFERENCE_KEY = "teamworks.performance.theme-preference";
export const readThemePreferenceFromLocalStorage = () => (localStorage.getItem(THEME_PREFERENCE_KEY) || "light") as ThemeMode;
export const writeThemePreferenceToLocalStorage = (themePreference: ThemeMode) =>
  localStorage.setItem(THEME_PREFERENCE_KEY, themePreference);

export interface ThemeModeContextType {
  themeMode: ThemeMode;
  setThemeMode: (themeMode: ThemeMode) => void;
}

const ThemeModeContext = createContext<ThemeModeContextType>({ themeMode: "light", setThemeMode: () => {} });

export const ThemeModeContextProvider = ({ children }: PropsWithChildren) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(readThemePreferenceFromLocalStorage());
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(() => {
    const systemTheme = devEnv ? (prefersDarkMode ? "dark" : "light") : "light";
    const appliedTheme = themeMode === "system" ? systemTheme : themeMode;
    return getTheme(appliedTheme);
  }, [themeMode, prefersDarkMode]);

  return (
    <ThemeModeContext.Provider value={{ themeMode, setThemeMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export const useThemeModeContext = () => {
  return useContext(ThemeModeContext);
};

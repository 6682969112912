import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { Dispatch } from "react";
import { FullServingAmountFragment, RecipeWithServingsFragment } from "../../../types";
import { ServingAmountsEditChipListWithSearchBar } from "../../ServingAmounts/Edit/ChipListWithSearchBar";
import { NewChip } from "../../universal/NewChip";
import { canSaveRecipeFormTooltips, formValidationMessages, RecipeFormAction, RecipeFormState } from "./utils";

interface IngredientsProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  setNutrientsOpen: (value: boolean) => void;
  disabled?: boolean;
  onAiGenerateClicked: null | (() => void);
  onAiTranscribeClicked?: () => void;
}

export const Ingredients = ({
  state,
  dispatch,
  setNutrientsOpen,
  disabled,
  onAiGenerateClicked,
  onAiTranscribeClicked,
}: IngredientsProps) => {
  const canSaveTooltips = canSaveRecipeFormTooltips(state);

  const handleChangeIngredients = (servingAmounts: readonly FullServingAmountFragment[], recipe?: RecipeWithServingsFragment) => {
    const containsSelf = servingAmounts.some(sa => sa.serving.foodOrRecipe.id === state.id);
    let containsDescendant = false;
    if (recipe && state.id) {
      containsDescendant = recipe.descendantRecipes.map(r => r.id).includes(state.id);
    }
    if (containsSelf || containsDescendant) {
      return;
    }
    dispatch({
      type: "CHANGE_INGREDIENTS",
      value: servingAmounts,
    });
  };

  const isValidIngredients = canSaveTooltips.includes(formValidationMessages.INGREDIENTS) && state.saveClicked;

  return (
    <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4">Ingredients</Typography>
        <IconButton size="small" onClick={() => setNutrientsOpen(true)}>
          <BarChartIcon sx={{ color: "accents.yellow.400" }} />
        </IconButton>
      </Box>
      {isValidIngredients && (
        <Typography variant="body2" color="error">
          Please add at least one ingredient
        </Typography>
      )}
      <ServingAmountsEditChipListWithSearchBar
        disabled={disabled}
        enableBranded={true}
        selectedServingAmounts={state.ingredients}
        selected
        noCache
        includeRecipeIngredients
        onChange={handleChangeIngredients}
        sx={{ overflowY: "auto" }}
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        {onAiGenerateClicked && (
          <Button
            variant="outlined"
            fullWidth
            onClick={() => onAiGenerateClicked()}>
            <AutoAwesomeIcon sx={{ fontSize: 16, marginRight: 1 }} />
            AI Recipe Generator
          </Button>
        )}
        {onAiTranscribeClicked && (
          <Button
            variant="outlined"
            fullWidth
            onClick={() => onAiTranscribeClicked()}>
            <AutoStoriesIcon sx={{ fontSize: 16, marginRight: 1 }} />
            AI Image Transcriber
            <NewChip feature="transcribeRecipe" />
          </Button>
        )}
      </Box>
    </Box>
  );
};

import { Box, Card, CardActionArea, Checkbox, Divider, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { addSelectionOptionsToMenuItem } from "@notemeal/shared/ui/MenuItem/utils";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import { formatTimeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import { sortByFn, sortByKey } from "@notemeal/utils/sort";
import { OrderStatusIcon } from "apps/web/src/components/MenuOrder/OrderStatusIcon";
import { differenceInMinutes, formatRelative } from "date-fns";
import React from "react";
import { KdsTicketOrderAthleteFragment, MenuOrderItemStatus } from "../../../types";
import NamedTagChipList from "../../Tags/NamedTagChipList";
import { WebPrinterTicketOrder } from "../types";
import KdsTicketPrintableLabel from "./PrintableLabel";
import { GenericOrderPreview, GenericOrderWithDiningStation, GenericOrderWithItems } from "./types";
import { getTicketDisplayNameForAthlete } from "./utils";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    orderContainer: {
      padding: spacing(),
      maxHeight: "calc(100vh - 128px)",
      overflowY: "auto",
    },
    checkbox: {
      width: 30,
      display: "flex",
      alignSelf: "center",
    },
    mealOptionFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 64,
    },
    spacer: {
      flexGrow: 1,
    },
    choiceRow: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: spacing(0.5, 0.5, 0, 0.5),
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      margin: spacing(2),
    },
    menuItemRow: {
      display: "flex",
      flexFlow: "column",
      alignItems: "baseline",
      lineHeight: 1,
      justifyContent: "space-between",
    },
    strikethroughIndent: {
      paddingLeft: spacing(),
      display: "flex",
      alignItems: "center",
      textDecoration: "line-through",
    },
    indent: {
      paddingLeft: spacing(),
    },
    contentRoot: {
      padding: spacing(1.5),
    },
    orderCardActionArea: {
      display: "flex",
      flexDirection: "column",
    },
    nameAndStatusRow: {
      display: "flex",
      flexFlow: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
      lineHeight: 1,
    },
    spaceBetween: {
      display: "flex",
      flexFlow: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
    flexEnd: {
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end",
    },
    paddingLeft: {
      marginLeft: spacing(2),
    },
    padding: {
      paddingLeft: spacing(0.5),
      marginTop: -spacing(0.5),
    },
    denseLineHeight: { lineHeight: 1.2 },
    specialRequests: { display: "flex" },
    tagChip: {
      marginTop: spacing(1),
    },
  })
);

interface KdsTicketProps<I extends GenericOrderWithItems> {
  athlete?: KdsTicketOrderAthleteFragment | null;
  orderStatus: MenuOrderItemStatus;
  orderPreview: GenericOrderPreview;
  setSelectedWebPrinterTicketOrder: (o: WebPrinterTicketOrder) => void;
  inBulkSelect: boolean;
  selectForBulkSelect: (orderId: string, value: boolean) => void;
  isSelected: boolean;
  menuOrderUpdatedAt: string | null | undefined;
  orderWithItems: (GenericOrderWithDiningStation & I) | null;
  loading: boolean;
  bulkOrderCode: string | null;
}
export const BaseKdsTicket = <I extends GenericOrderWithItems>({
  athlete,
  orderStatus,
  orderPreview,
  setSelectedWebPrinterTicketOrder,
  inBulkSelect,
  selectForBulkSelect,
  isSelected,
  menuOrderUpdatedAt,
  orderWithItems,
  loading,
  bulkOrderCode,
}: KdsTicketProps<I>) => {
  const {
    palette: { orderStatusNew, orderStatusCooking, orderStatusDone, orderStatusCancelled },
  } = useTheme();
  const hasPrintableTags = useHasFeature("printableTags");
  const classes = useStyles();
  const { dateFnsLocale } = useDateFormatting();

  const handleTapOrder = (orderWithItems: GenericOrderWithDiningStation & I, orderPreview: GenericOrderPreview) => {
    if (inBulkSelect) {
      selectForBulkSelect(orderPreview.id, !isSelected);
    } else {
      setSelectedWebPrinterTicketOrder(orderWithItems);
    }
  };

  const pickupTime = orderPreview.__typename === "MenuOrder" ? orderPreview.pickupTime : orderPreview.parentBulkOrder.pickupTime;

  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const minutesUntilPickupTime = differenceInMinutes(new Date(pickupTime), new Date());
  const pickupTimeForDisplay = formatTimeInTimezone(pickupTime, clientTimezone, { excludeTimezoneSuffix: true });

  const ordererName = orderPreview.athlete ? getTicketDisplayNameForAthlete(orderPreview.athlete) : orderPreview.userFullName;

  const cardBorderColor =
    orderStatus === "done"
      ? orderStatusDone.light
      : orderStatus === "cooking"
      ? orderStatusCooking.light
      : orderStatus === "cancelled"
      ? orderStatusCancelled.light
      : orderStatusNew.light;

  return (
    <div className={classes.orderContainer}>
      {orderWithItems && <KdsTicketPrintableLabel order={orderWithItems} />}
      <Card raised sx={{ border: `4px solid ${cardBorderColor}` }}>
        <CardActionArea
          onClick={() => orderWithItems && handleTapOrder(orderWithItems, orderPreview)}
          classes={{ root: classes.contentRoot }}
        >
          <div className={classes.orderCardActionArea}>
            {inBulkSelect && <Checkbox className={classes.checkbox} checked={isSelected} />}
            {/* TODO: Styles */}
            {bulkOrderCode && (
              <div className={classes.nameAndStatusRow}>
                <Typography classes={{ root: classes.denseLineHeight }} variant="h3">
                  {`${bulkOrderCode} (Bulk Order)`}
                </Typography>
              </div>
            )}
            <div className={classes.nameAndStatusRow}>
              <Typography classes={{ root: classes.denseLineHeight }} variant="h3">
                {ordererName}
              </Typography>

              <Typography variant="h3">#{orderPreview.code} </Typography>
              <Typography
                component="em"
                sx={{ color: "info.main" }}
                variant="subtitle1">
                {orderStatus}
              </Typography>
            </div>
            <Typography classes={{ root: classes.denseLineHeight }} variant="body1Medium">
              {orderPreview.athlete?.team.name} {orderPreview.athlete?.position?.name && `(${orderPreview.athlete.position.name})`}
            </Typography>
            {hasPrintableTags && athlete?.printableTagsForAthlete && athlete.printableTagsForAthlete.length > 0 && (
              <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", mb: 1 }}>
                <NamedTagChipList
                  namedTags={athlete.printableTagsForAthlete}
                  namedTagsCount={athlete.printableTagsForAthlete.length}
                  hideAfter={8}
                  chipClassName={classes.tagChip}
                />
              </Box>
            )}
            <div className={classes.spaceBetween}>
              <Typography
                classes={{ root: classes.denseLineHeight }}
                variant="h3"
                color={minutesUntilPickupTime <= 0 ? "error" : minutesUntilPickupTime <= 15 ? "warning" : "info"}
              >
                {pickupTimeForDisplay}
                <span className={classes.paddingLeft}>
                  {minutesUntilPickupTime <= 0 ? (
                    <Typography
                      variant="subtitle1"
                      color="error"
                      component="em">
                      (due {Math.abs(minutesUntilPickupTime)}m ago)
                    </Typography>
                  ) : minutesUntilPickupTime <= 15 ? (
                    <Typography variant="subtitle1" component="em">
                      (due in {minutesUntilPickupTime}m)
                    </Typography>
                  ) : undefined}
                </span>
              </Typography>
              {menuOrderUpdatedAt && (
                <>
                  <div className={classes.spacer} />
                  <Typography variant="h3">
                    Updated At {formatTimeInTimezone(menuOrderUpdatedAt, clientTimezone, { excludeTimezoneSuffix: true })}
                  </Typography>
                </>
              )}
            </div>
            <Typography
              classes={{ root: classes.denseLineHeight }}
              variant="body1Medium"
              color="error">
              {(athlete?.dislikedFoodGroups || [])
                .concat(athlete?.dislikedFoods || [])
                .map(f => f.name)
                .join(", ")}
            </Typography>
            <Divider className={classes.divider} />
            {!orderWithItems || loading ? (
              <Loading progressSize="lg" />
            ) : (
              <>
                {Object.keys(orderWithItems.diningStationsToMenuOrderItems)
                  .sort()
                  .map((dsName, idx) => (
                    <div key={`${dsName}-${idx}`}>
                      <Typography
                        variant="h3"
                        noWrap
                        color="textSecondary">
                        {dsName}
                      </Typography>
                      {sortByFn(orderWithItems.diningStationsToMenuOrderItems[dsName], item => item.menuItem.name).map(
                        ({ id, amount, status, specialRequests, menuItem, options, updateCount }) => (
                          <div key={id}>
                            <div className={classes.menuItemRow}>
                              <Typography
                                className={status !== "new" ? classes.strikethroughIndent : classes.indent}
                                classes={{ root: classes.denseLineHeight }}
                                variant="h3"
                              >
                                {status !== "new" && <OrderStatusIcon orderStatus={status} />}
                                {updateCount > 0 ? `Updates: ${updateCount} => ` : ""}
                                {amount} x {menuItem.name}
                              </Typography>
                              <div className={classes.spacer} />
                              <Typography
                                color="textSecondary"
                                classes={{ root: classes.denseLineHeight }}
                                variant="body2Medium"
                                noWrap>
                                {` (${menuItem.servingName})`}
                              </Typography>
                            </div>
                            <div className={classes.choiceRow}>
                              {sortByKey(addSelectionOptionsToMenuItem(menuItem, options).choices, "position").map(
                                c =>
                                  c.options.filter(o => !!o.menuSelectionItemOption).length > 0 && (
                                    <div key={`${menuItem.id}-${c.name}`}>
                                      <Typography
                                        classes={{
                                          root: classes.denseLineHeight,
                                        }}
                                        variant="body1Medium"
                                      >
                                        {c.name}
                                      </Typography>
                                      {sortByKey(c.options, "position").map(
                                        o =>
                                          o.menuSelectionItemOption && (
                                            <Typography
                                              color="textSecondary"
                                              key={o.id}
                                              classes={{
                                                root: classes.denseLineHeight,
                                              }}
                                              variant="body2Medium"
                                            >
                                              {o.menuSelectionItemOption.amount} x {o.name}
                                            </Typography>
                                          )
                                      )}
                                    </div>
                                  )
                              )}
                            </div>
                            {specialRequests && (
                              <div className={classes.specialRequests}>
                                <Typography variant="body2Medium" color="error">
                                  Special Requests:
                                </Typography>
                                <div className={classes.padding} />
                                <Typography variant="body2Medium">{specialRequests}</Typography>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ))}
                <div className={classes.flexEnd}>
                  {/* TODO: Locale Cleanup make function */}
                  <Typography variant="subtitle1">
                    Order placed {formatRelative(new Date(orderPreview.createdAt), new Date(), { locale: dateFnsLocale })}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
};

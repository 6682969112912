import { GB_LOCALE_CODE, LocaleType, US_LOCALE_CODE } from "@notemeal/locale/utils";
import { ReactNode, createContext, useContext } from "react";
import { useGetUserLocaleQuery } from "../../types";

export interface LocaleContextType {
  locale: LocaleType;
  isMetricLocale: boolean;
}

export const LocaleContext = createContext<LocaleContextType | null>(null);

const getLocaleFromCode = (code?: string) => {
  switch (code) {
    case GB_LOCALE_CODE:
      return GB_LOCALE_CODE;
    default:
      return US_LOCALE_CODE;
  }
};

export const LocaleContextProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useGetUserLocaleQuery();

  // TODO: default and enum for locale code in DB?
  const locale: LocaleType = getLocaleFromCode(data?.myUser?.locale.code);
  // TODO: more accurate method of defining a metric locale
  const isMetricLocale = locale !== US_LOCALE_CODE;

  return <LocaleContext.Provider value={{ locale, isMetricLocale }}>{children}</LocaleContext.Provider>;
};

export const useLocaleContext = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error("useLocaleContext must be used within a LocaleProvider");
  }
  return context;
};

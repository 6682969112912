import React from "react";
import { CardMode } from "../../shared/DisplayCard";
import DisplayList, { RenderListItemArgs } from "../../shared/DisplayList";

import PositionCard, { PositionLike } from "./PositionCard";

interface PositionListProps<T extends PositionLike> {
  positions: readonly T[];
  onAdd?: (position: T) => void;
  onLink?: (position: T) => void;
  onEdit?: (position: T) => void;
  onCancel?: (position: T) => void;
  selectedId: string | null;
  title: string;
  columnMode: CardMode;
  disableOnSelected?: boolean;
}

const PositionList = <T extends PositionLike>({
  disableOnSelected,
  positions,
  selectedId,
  title,
  columnMode,
  ...cardActions
}: PositionListProps<T>) => {
  const renderListItem = ({ key, ...props }: RenderListItemArgs<T>) => <PositionCard
    {...cardActions}
    key={key}
    {...props} />;

  return (
    <DisplayList
      title={title}
      columnMode={columnMode}
      renderListItem={renderListItem}
      elements={positions}
      disabled={disableOnSelected && selectedId !== null}
      loading={false}
      selectedId={selectedId}
      headerVariant="body1"
    />
  );
};

export default PositionList;

import Close from "@mui/icons-material/Close";
import Info from "@mui/icons-material/Info";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";

interface InfoBoxProps {
  title: string;
  info: string;
  onClose: () => void;
}

export const InfoBox = ({ title, info, onClose }: InfoBoxProps) => {
  const { palette, spacing } = useTheme();

  return (
    <Box sx={{ backgroundColor: palette.info.lighter, display: "flex", p: spacing(2), gap: 1, borderRadius: "6px" }}>
      <Info color="info" fontSize="small" />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="body2Semibold">{title}</Typography>
        <Typography variant="body2">{info}</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", flex: 1, alignItems: "flex-start" }}>
        <IconButton color="info" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

import { ListItem, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { RestaurantMenuSearchResultFragment } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    restaurantType: {
      textAlign: "right",
    },
  })
);

interface RestaurantSearchMenuResultItemProps {
  result: RestaurantMenuSearchResultFragment;
  onClick: () => void;
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
}

const RestaurantSearchMenuResultItem = ({ result, onClick, forwardRef, disabled }: RestaurantSearchMenuResultItemProps) => {
  const classes = useStyles();
  const restaurantTypeName = result.restaurant?.restaurantType?.name;

  return (
    <ListItem
      button
      onClick={onClick}
      ref={forwardRef}
      disabled={disabled}>
      <ListItemText primary={result.restaurant.name} />
      {restaurantTypeName && <ListItemText secondary={restaurantTypeName} classes={{ secondary: classes.restaurantType }} />}
    </ListItem>
  );
};

export default RestaurantSearchMenuResultItem;

import { ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { CardMode } from "../../shared/DisplayCard";
import DisplayList, { RenderListItemArgs } from "../../shared/DisplayList";
import TeamCard, { TeamLike } from "./TeamCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    existingTeamsDiv: {
      width: 100,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
);

interface TeamsListProps<T extends TeamLike> {
  teams: readonly T[];
  onAdd?: (team: T) => void;
  onLink?: (team: T) => void;
  onEdit?: (team: T) => void;
  onCancel?: (team: T) => void;
  onClick?: (team: T) => void;
  selectedId: string | null;
  title: string;
  columnMode: CardMode;
  disableOnSelected?: boolean;
  showLinkedAndPendingCount?: boolean;
}

const TeamsList = <T extends TeamLike>({
  teams,
  onAdd,
  onLink,
  onEdit,
  onCancel,
  onClick,
  selectedId,
  title,
  columnMode,
  disableOnSelected,
  showLinkedAndPendingCount = false,
}: TeamsListProps<T>) => {
  const classes = useStyles();
  const renderListItem = ({ key, element, ...props }: RenderListItemArgs<T>) => (
    <TeamCard
      onAdd={onAdd}
      onLink={onLink}
      onEdit={onEdit}
      onCancel={onCancel}
      onClick={onClick}
      element={element}
      key={key}
      {...props}
      middleElement={
        showLinkedAndPendingCount &&
        element.__type === "TeamworksTeam" && (
          <div className={classes.existingTeamsDiv}>
            {element.notemealTeams.length > 0 && <ListItemText secondary={`Linked: ${element.notemealTeams.length}`} />}
            {element.pendingTeams.length > 0 && <ListItemText secondary={`Pending: ${element.pendingTeams.length}`} />}
          </div>
        )
      }
    />
  );

  return (
    <DisplayList
      title={title}
      columnMode={columnMode}
      renderListItem={renderListItem}
      elements={teams}
      disabled={disableOnSelected && selectedId !== null}
      loading={false}
      selectedId={selectedId}
    />
  );
};

export default TeamsList;

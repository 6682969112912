import { Box, TextField, Typography } from "@mui/material";
import { usdaNameToCommonName, usdaNameToUnit } from "@notemeal/nutrient/utils";
import { FORM_NUTRIENTS, FORM_NUTRIENTS_WITHOUT_MACROS } from "@notemeal/shared/ui/NutrientAmount/customNutrientUtils";
import { round } from "@notemeal/shared/ui/utils/numbers";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { getServingUnits } from "../../Serving/utils";
import { BaseFoodFormType } from "../FoodFormSchema/FoodFormSchema";
import { FoodServingType } from "../FoodFormSchema/FoodServingSchema";
import { changeNutrientAmounts } from "../FoodFormSchema/foodServingsUtils";

const getDefaultServingLabel = (serving: FoodServingType) => {
  const { defaultAmount, units, weight } = serving;
  const { unit, customUnits, unitPrefix, unitSuffix } = units;
  const servingUnits =
    !unit && !customUnits
      ? "serving(s)"
      : getServingUnits({
          unit,
          customUnits,
          unitPrefix,
          unitSuffix,
        });
  return `${defaultAmount || ""} ${servingUnits}, ${(weight || 0) * (defaultAmount || 1)} g`;
};

interface FoodServingNutrientsProps {
  defaultServing?: FoodServingType;
  form: UseFormReturn<BaseFoodFormType>;
  disabled?: boolean;
}

export const FoodServingNutrients = ({ defaultServing, form, disabled }: FoodServingNutrientsProps) => {
  return (
    <Box
      sx={{
        border: "1px solid lightgray",
        borderRadius: 0.5,
        p: 2,
        width: "100%",
      }}
    >
      <Typography variant="body1">Default Serving Nutrients</Typography>
      {defaultServing && (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            gutterBottom>
            {getDefaultServingLabel(defaultServing)}
          </Typography>
          {FORM_NUTRIENTS_WITHOUT_MACROS.map(nutrient => (
            <NutrientRow
              key={nutrient}
              disabled={disabled}
              nutrient={nutrient}
              serving={defaultServing}
              form={form} />
          ))}
        </>
      )}
    </Box>
  );
};

interface NutrientRowProps {
  nutrient: (typeof FORM_NUTRIENTS)[number];
  serving: FoodServingType;
  form: UseFormReturn<BaseFoodFormType>;
  disabled?: boolean;
}

const NutrientRow = ({ nutrient, serving, form, disabled }: NutrientRowProps) => {
  const { control, watch, setValue, getValues } = form;
  const [watchedUsdaFdcId] = watch(["usdaFdcId"]);
  const { id, nutrientAmounts } = serving;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }} key={`${nutrient}_${id}`}>
      <Typography variant="caption" sx={{ display: "flex", alignSelf: "center" }}>{`${usdaNameToCommonName(nutrient)} (${usdaNameToUnit(
        nutrient
      )})`}</Typography>

      <Controller
        name={"nutrientAmountsManualEntry"}
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <TextField
            {...field}
            variant="standard"
            value={nutrientAmounts && nutrientAmounts[nutrient] ? round(nutrientAmounts[nutrient] as unknown as number) : ""}
            type="number"
            placeholder="0"
            disabled={disabled || watchedUsdaFdcId !== null}
            onChange={e => {
              const { newNutrientAmountsManualEntry, newServings } = changeNutrientAmounts({
                foodForm: getValues(),
                nutrientName: nutrient,
                nutrientValueInput: e.target.value,
              });

              setValue("nutrientAmountsManualEntry", newNutrientAmountsManualEntry);
              setValue("servings", newServings);
            }}
            sx={{ width: "60px" }}
          />
        )}
      />
    </Box>
  );
};

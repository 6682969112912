import { Button, ButtonProps } from "@mui/material";
import React, { ReactElement } from "react";
import { isOutlinedVariant } from "../../pages/Theme/ThemeUtils";

interface TWButtonGroupProps extends ButtonProps {
  icon: ReactElement;
}

export const TWIconButton = ({ icon, size, variant, ...props }: TWButtonGroupProps) => {
  const fontSize = size === "large" ? 24 : size === "small" ? 16 : 20;
  const outlined = isOutlinedVariant(variant);
  const smallPadding = outlined ? "5px" : "6px";
  const mediumPadding = outlined ? "7px" : "8px";
  const largePadding = outlined ? "9px" : "10px";
  const padding = size === "large" ? largePadding : size === "small" ? smallPadding : mediumPadding;

  return (
    <Button
      {...props}
      sx={{
        p: padding,
        whiteSpace: "nowrap",
        minWidth: "auto",
        "& .MuiButton-startIcon": {
          m: 0,
          "& > *:first-child": {
            fontSize: fontSize,
          },
        },
      }}
      startIcon={icon}
      variant={variant}
      size={size}
    />
  );
};

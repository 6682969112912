import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Avatar, Collapse, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import OptionsList from "./OptionsList";
import { MenuOrderItemSummary } from "./types";

interface MenuItemDialogListItemProps {
  menuOrderItemSummary: MenuOrderItemSummary;
}

const MenuItemDialogListItem = ({ menuOrderItemSummary }: MenuItemDialogListItemProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton key={menuOrderItemSummary.menuItem.id} onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Avatar sx={{ backgroundColor: "common.black" }}>{menuOrderItemSummary.amount}</Avatar>
        </ListItemIcon>
        <ListItemText primary={menuOrderItemSummary.menuItem.name} secondary={menuOrderItemSummary.menuItem.servingName} />
        {menuOrderItemSummary.choices && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit>
        <OptionsList choices={menuOrderItemSummary.choices} />
      </Collapse>
    </>
  );
};

export default MenuItemDialogListItem;

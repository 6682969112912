import { getDateFnsLocaleForCode } from "@notemeal/locale/utils";
import { addHours } from "date-fns";
import { useCallback } from "react";
import {
  DateInTimezoneOptions,
  FormatDateOptions,
  FormatEventDateTimeOptions,
  formatTimeInTimezoneWithLocale as _formatTimeInTimezoneWithLocale,
  formatDate,
  formatDateInTimezone,
  formatDatetimeInTimezone,
  formatEventDatetime,
  formatYearlessDate,
} from "../../utils/dateTimes";
import { useLocaleContext } from "./LocaleContext";

export const useDateFormatting = () => {
  const { locale } = useLocaleContext();
  const dateFnsLocale = getDateFnsLocaleForCode(locale);

  const formatDateWithLocale = useCallback(
    (date: Date | string, options?: FormatDateOptions) => formatDate(date, options, dateFnsLocale),
    [dateFnsLocale]
  );

  const formatYearlessDateWithLocale = useCallback((date: Date | string) => formatYearlessDate(date, dateFnsLocale), [dateFnsLocale]);

  const formatDateInTimezoneWithLocale = useCallback(
    (datetime: Date | string, timeZone: string, options?: DateInTimezoneOptions) =>
      formatDateInTimezone(datetime, timeZone, dateFnsLocale, options),
    [dateFnsLocale]
  );
  const formatDatetimeInTimezoneWithLocale = useCallback(
    (datetime: Date | string, timeZone: string) => formatDatetimeInTimezone(datetime, timeZone, dateFnsLocale),
    [dateFnsLocale]
  );

  const formatTimeInTimezoneWithLocale = useCallback(
    (datetime: Date | string, timeZone: string) => _formatTimeInTimezoneWithLocale(datetime, timeZone, dateFnsLocale),
    [dateFnsLocale]
  );

  const formatEventDatetimeWithLocale = useCallback(
    (datetime: Date | string, options?: FormatEventDateTimeOptions) => formatEventDatetime(datetime, dateFnsLocale, options),
    [dateFnsLocale]
  );

  const formatHubDisplayDate = useCallback(
    (hubDateString: string, options?: FormatEventDateTimeOptions) => {
      const hubDateTime = new Date(hubDateString.substring(0, 19));
      const isPM = hubDateString.substring(19, 21).toLowerCase() === "pm";
      const isNoon = hubDateString.substring(11, 13) === "12";

      const hubDate = formatEventDatetime(isPM && !isNoon ? addHours(hubDateTime, 12) : hubDateTime, dateFnsLocale, options);

      return hubDate + " " + hubDateString.substring(hubDateString.length - 3);
    },
    [dateFnsLocale]
  );

  return {
    formatDateWithLocale,
    formatYearlessDateWithLocale,
    formatEventDatetimeWithLocale,
    formatDateInTimezoneWithLocale,
    formatDatetimeInTimezoneWithLocale,
    formatTimeInTimezoneWithLocale,
    formatHubDisplayDate,
    dateFnsLocale,
  };
};

import { TextFieldProps, Theme, alpha } from "@mui/material";

export const muiInputBase = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: TextFieldProps; theme: Theme }) => {
      const {
        palette: { mode, background, greyscale, common },
      } = theme;
      return {
        backgroundColor: background.default, // So Autocomplete looks good on grey background parents
        "& fieldset": {
          borderRadius: "6px",
          borderColor: greyscale[300],
        },
        "&:hover fieldset": {
          borderColor: greyscale[300],
        },
        "&.Mui-disabled fieldset": {
          borderColor: mode === "light" ? greyscale[300] : greyscale[700],
          backgroundColor: mode === "light" ? alpha(common.black, 0.05) : alpha(common.white, 0.2),
        },
      };
    },
    input: ({ ownerState, theme }: { ownerState: TextFieldProps; theme: Theme }) => {
      return {
        fontSize: {
          small: "12px",
          medium: "14px",
        }[ownerState.size as "small" | "medium"],
        height: "24px",
        lineHeight: "24px",
      };
    },
  },
};

import { Macros, macrosAreEqual } from "@notemeal/shared/utils/macro-protocol";
import { MealType } from "../../../types";
import { MealOptionInput } from "./useGenerateMealOptions";

interface ReducedSuggestionQueryProps {
  macros: Macros;
  mealType: MealType;
  mealTemplateIds: string[];
  count: number;
}

export const getReducedSuggestionQueryProps = (mealOptionInputs: MealOptionInput[]) => {
  let dedupedMacrosAndMealTemplateIds: ReducedSuggestionQueryProps[] = [];
  mealOptionInputs.forEach(mealOptionInput => {
    const mealMacrosIndex = dedupedMacrosAndMealTemplateIds.findIndex(ddmmt => suggestionTemplatesAreEqual(mealOptionInput, ddmmt));
    if (mealMacrosIndex !== -1) {
      dedupedMacrosAndMealTemplateIds[mealMacrosIndex] = {
        ...dedupedMacrosAndMealTemplateIds[mealMacrosIndex],
        count: dedupedMacrosAndMealTemplateIds[mealMacrosIndex].count + 1,
        mealTemplateIds: [...dedupedMacrosAndMealTemplateIds[mealMacrosIndex].mealTemplateIds, mealOptionInput.mealTemplateId],
      };
    } else {
      dedupedMacrosAndMealTemplateIds.push({
        ...mealOptionInput,
        count: 1,
        mealTemplateIds: [mealOptionInput.mealTemplateId],
      });
    }
  });
  return dedupedMacrosAndMealTemplateIds;
};

const suggestionMealTypesAreEqual = (mealType1: MealType, mealType2: MealType): boolean => {
  if ((mealType1 === "lunch" || mealType1 === "dinner") && (mealType2 === "lunch" || mealType2 === "dinner")) {
    return true;
  }
  return mealType1 === mealType2;
};

type MealMacrosAndType = Omit<MealOptionInput, "mealTemplateId">;

// At the moment, we don't differentiate between lunch and dinner foods for meal option suggestions.
const suggestionTemplatesAreEqual = (mealTemplate1: MealMacrosAndType, mealTemplate2: MealMacrosAndType): boolean => {
  const equalMealTypes = suggestionMealTypesAreEqual(mealTemplate1.mealType, mealTemplate2.mealType);
  const equalMacros = macrosAreEqual(mealTemplate1.macros, mealTemplate2.macros);
  return equalMealTypes && equalMacros;
};

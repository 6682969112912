import { Theme } from "@mui/material";

export const muiTooltip = {
  styleOverrides: {
    // arrow, popper, tooltip, transition are the choices
    tooltip: ({
      theme: {
        palette: { mode, highEmphasisTextDark, highEmphasisTextLight, boxShadowMedium, greyscaleDark, greyscaleLight },
      },
    }: {
      theme: Theme;
    }) => ({
      maxWidth: 316,
      backgroundColor: mode === "light" ? greyscaleDark[800] : greyscaleLight[800],
      color: mode === "light" ? highEmphasisTextDark : highEmphasisTextLight,
      boxShadow: boxShadowMedium,
    }),
    arrow: ({
      theme: {
        palette: { mode, greyscaleDark, greyscaleLight },
      },
    }: {
      theme: Theme;
    }) => ({
      color: mode === "light" ? greyscaleDark[800] : greyscaleLight[800],
    }),
  },
};

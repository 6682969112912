import { CircularProgress, CircularProgressProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      margin: theme.spacing(2),
    },
  })
);

type ProgressSize = "xs" | "sm" | "md" | "lg";

interface LoadingProps {
  progressSize?: ProgressSize;
  classes?: {
    root?: string;
    spinner?: string;
  };
}

const circularProgressPropsBySize: {
  [key in ProgressSize]: CircularProgressProps;
} = {
  xs: {
    size: 20,
    thickness: 5,
  },
  sm: {
    size: 50,
  },
  md: {
    size: 75,
  },
  lg: {
    size: 100,
  },
};

const Loading = ({ classes, progressSize }: LoadingProps) => {
  const baseClasses = useStyles();
  const DEFAULT_SIZE = "md";
  return (
    <div className={classNames(baseClasses.root, classes?.root)}>
      <CircularProgress className={classes?.spinner} {...circularProgressPropsBySize[progressSize || DEFAULT_SIZE]} />
    </div>
  );
};

export default Loading;

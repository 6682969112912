import { EditExchangeMealTemplatesMutationVariables, ExchangeFragment, ExchangeAmountFragment } from "../types";
import { getLeftOverStopIndices, StopIndices } from "./distributeMacros";
import { ExchangeMealPlan } from "../MealPlan/types";

// TODO: Set to "1" and ".5" to allow for "half exchanges"
const EXCHANGE_SNACK_FACTOR = 1;
export const EXCHANGE_MEAL_FACTOR = 2;

export const createDistributeExchangeMutationVars = (mealPlan: ExchangeMealPlan): EditExchangeMealTemplatesMutationVariables => {
  const exchanges = mealPlan.exchangeSet.exchanges;
  const meals = mealPlan.mealTemplates.map(mt => mt.meal);
  const snackCount = meals.filter(m => m.type === "snack").length;
  const mealCount = meals.length - snackCount;

  const { snackExchanges, mealExchanges, leftOverExchanges } = getDefaultExchanges({
    exchanges,
    exchangeTargets: mealPlan.exchangeTargets || [],
    mealCount,
    snackCount,
  });

  const stopExchangeIndices = getLeftOverExchangeStopIndices({
    leftOverExchanges,
    snackCount,
    mealCount,
  });

  let currentSnackCount = 0;
  let currentMealCount = 0;

  const mealTemplateInput = mealPlan.mealTemplates.map(({ id, meal, exchangeTargets }) => {
    const exchangeTargetsInput = exchanges.map(({ id: exchangeId }) => {
      const matchingExchangeTarget = exchangeTargets.find(et => et.exchange.id === exchangeId);
      const pickListServingIds = matchingExchangeTarget ? matchingExchangeTarget.pickListServingIds : null;
      let exchangeAmount = (meal.type === "snack" ? snackExchanges[exchangeId] : mealExchanges[exchangeId]) || 0;
      if (meal.type === "snack") {
        if (currentSnackCount < stopExchangeIndices[exchangeId].stopExtraSnack) {
          exchangeAmount += EXCHANGE_SNACK_FACTOR;
        }
      } else {
        if (currentMealCount < stopExchangeIndices[exchangeId].stopTwoExtraMeal) {
          exchangeAmount += EXCHANGE_SNACK_FACTOR;
        }
        if (currentMealCount < stopExchangeIndices[exchangeId].stopOneExtraMeal) {
          exchangeAmount += EXCHANGE_SNACK_FACTOR;
        }
      }
      return { exchangeId, amount: exchangeAmount, pickListServingIds };
    });

    if (meal.type === "snack") {
      currentSnackCount += 1;
    } else {
      currentMealCount += 1;
    }

    return { mealTemplateId: id, exchangeTargets: exchangeTargetsInput };
  });
  return { input: mealTemplateInput };
};

interface ExchangeStopIndices {
  [exchangeId: string]: StopIndices;
}

interface getLeftOverExchangeStopIndicesArgs {
  leftOverExchanges: DefaultExchanges;
  snackCount: number;
  mealCount: number;
}

// This "top-off exchanges" solution is related to SNACK_FACTOR / MEAL_FACTOR above
// If changing those, would need more general solution here
// Notably, the values can be scaled up and down such that 2 * SNACK_FACTOR = MEAL_FACTOR
const getLeftOverExchangeStopIndices = ({
  leftOverExchanges,
  mealCount,
  snackCount,
}: getLeftOverExchangeStopIndicesArgs): {
  [exchangeId: string]: {
    stopOneExtraMeal: number;
    stopExtraSnack: number;
    stopTwoExtraMeal: number;
  };
} => {
  const exchangeStopIndices: ExchangeStopIndices = {};

  Object.keys(leftOverExchanges).forEach(exchangeId => {
    exchangeStopIndices[exchangeId] = getLeftOverStopIndices(
      leftOverExchanges[exchangeId] || 0,
      snackCount,
      mealCount,
      EXCHANGE_SNACK_FACTOR
    );
  });

  return exchangeStopIndices;
};

interface DefaultExchanges {
  [exchangeId: string]: number | undefined;
}

interface DefaultExchangesResult {
  snackExchanges: DefaultExchanges;
  mealExchanges: DefaultExchanges;
  leftOverExchanges: DefaultExchanges;
}

interface getDefaultExchangesArgs {
  exchanges: readonly ExchangeFragment[];
  exchangeTargets: readonly ExchangeAmountFragment[];
  mealCount: number;
  snackCount: number;
}

// RATIOS need to be multiples of 0.5 to match allowed exchange targets
const getDefaultExchanges = ({ exchanges, exchangeTargets, mealCount, snackCount }: getDefaultExchangesArgs): DefaultExchangesResult => {
  const basisTotal = mealCount * EXCHANGE_MEAL_FACTOR + snackCount * EXCHANGE_SNACK_FACTOR;
  const snackExchanges: DefaultExchanges = {};
  const mealExchanges: DefaultExchanges = {};
  const leftOverExchanges: DefaultExchanges = {};

  exchanges.forEach(exchange => {
    const dailyExchangeTarget = exchangeTargets.find(ea => ea.exchange.id === exchange.id);
    const dailyExchangeAmount = dailyExchangeTarget ? dailyExchangeTarget.amount : 0;
    const basisValue = Math.floor(dailyExchangeAmount / basisTotal);
    snackExchanges[exchange.id] = basisValue * EXCHANGE_SNACK_FACTOR;
    mealExchanges[exchange.id] = basisValue * EXCHANGE_MEAL_FACTOR;
    leftOverExchanges[exchange.id] = dailyExchangeAmount - basisValue * basisTotal;
  });

  return {
    snackExchanges,
    mealExchanges,
    leftOverExchanges,
  };
};

import React from "react";

import { Table as MuiTable, TableBody, TableHead } from "@mui/material";
import { MenuItemRowInfoWithAmount } from "../utils";
import TableRow, { HeaderRow } from "./TableRow";

interface TableProps {
  menuItemRowInfos: readonly MenuItemRowInfoWithAmount[];
}

const Table = ({ menuItemRowInfos }: TableProps) => {
  return (
    <MuiTable>
      <TableHead>
        <HeaderRow />
      </TableHead>
      <TableBody>
        {menuItemRowInfos.map(menuItemRowInfo => (
          <TableRow key={`menu-item-table-row${menuItemRowInfo.id}`} menuItemRowInfo={menuItemRowInfo} />
        ))}
      </TableBody>
    </MuiTable>
  );
};

export default Table;

import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { Dispatch } from "react";
import { AddOrRemoveFoodGroupPreferenceProps, AddOrRemoveFoodPreferenceProps, FoodPreferenceForm } from "../Form";
import { FoodPreferenceAction, FoodPreferenceState } from "./reducer";

interface FoodPreferenceMealPlanFormProps {
  state: FoodPreferenceState;
  dispatch: Dispatch<FoodPreferenceAction>;
}

const FoodPreferenceMealPlanForm = ({ state, dispatch }: FoodPreferenceMealPlanFormProps) => {
  //
  // Remove Handlers
  //
  const removeLikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    dispatch({ type: "REMOVE_PROMOTED_FOOD", payload: food });
  };
  const removeDislikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    dispatch({ type: "REMOVE_AVOIDED_FOOD", payload: food });
  };
  const removeLikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    dispatch({ type: "REMOVE_PROMOTED_FOOD_GROUP", payload: foodGroup });
  };
  const removeDislikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    dispatch({ type: "REMOVE_AVOIDED_FOOD_GROUP", payload: foodGroup });
  };
  const removeActions = {
    removeLikedFood,
    removeDislikedFood,
    removeLikedFoodGroup,
    removeDislikedFoodGroup,
  };
  //
  // Add Handlers
  //
  const addLikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Add Promoted Foods", { foodId: food.id, foodName: food.name });
    dispatch({ type: "ADD_PROMOTED_FOOD", payload: food });
  };
  const addDislikedFood = ({ type, id, food }: AddOrRemoveFoodPreferenceProps) => {
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Add Avoided Foods", { foodId: food.id, foodName: food.name });
    dispatch({ type: "ADD_AVOIDED_FOOD", payload: food });
  };
  const addLikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Add Promoted Food Groups", {
      foodGroupId: foodGroup.id,
      foodGroupName: foodGroup.name,
    });
    dispatch({ type: "ADD_PROMOTED_FOOD_GROUP", payload: foodGroup });
  };
  const addDislikedFoodGroup = ({ type, id, foodGroup }: AddOrRemoveFoodGroupPreferenceProps) => {
    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Add Avoided Food Groups", {
      foodGroupId: foodGroup.id,
      foodGroupName: foodGroup.name,
    });
    dispatch({ type: "ADD_AVOIDED_FOOD_GROUP", payload: foodGroup });
  };
  const addActions = {
    addLikedFood,
    addDislikedFood,
    addLikedFoodGroup,
    addDislikedFoodGroup,
  };

  const { promotedFoods, avoidedFoods, promotedFoodGroups, avoidedFoodGroups } = state;
  return (
    <FoodPreferenceForm
      type="MealPlan"
      preferrerId={""}
      preferences={{
        likedFoodGroups: promotedFoodGroups,
        dislikedFoodGroups: avoidedFoodGroups,
        likedFoods: promotedFoods,
        dislikedFoods: avoidedFoods,
      }}
      actions={{ ...addActions, ...removeActions }}
    />
  );
};

export default FoodPreferenceMealPlanForm;

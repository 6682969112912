import { Avatar, Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { SodexoDayMealTimes, SodexoMealTime } from "apps/web/src/types";
import { addDays, eachDayOfInterval, format, isSameDay, parseISO } from "date-fns";
import React, { ChangeEvent } from "react";
import WeekNavigation from "../../WeekNavigation";

const getEmptyMealTimesByDay = (startOfWeekDate: Date) => {
  const dayDates = eachDayOfInterval({ start: startOfWeekDate, end: addDays(startOfWeekDate, 6) });
  return dayDates.map(dayDate => ({ date: format(dayDate, "yyyy-MM-dd"), mealTimes: [] })); // TODO: Locale evaluate if this is an an input
};

interface AvatarProps {
  backgroundColor: string;
  color: string;
}

interface DayProps {
  weekDayName: string;
  dayOfTheMonth: string;
  dayOfWeekColor: string;
  avatarProps: AvatarProps;
}

const getDaysProps = (mealTimesByDay: readonly SodexoDayMealTimes[]): DayProps[] => {
  const today = new Date();

  return mealTimesByDay.map(({ date }) => {
    const day = parseISO(date);
    const weekDayName = format(day, "EEE");
    const dayOfTheMonth = format(day, "dd");
    const isToday = isSameDay(day, today);
    const dayOfWeekColor = isToday ? "info" : ("textSecondary" as const);
    const todayAvatar = { backgroundColor: "info.main", color: "info.contrastText" };
    const notTodayAvatar = { backgroundColor: "common.white", color: "text.secondary" };
    const avatarProps = isToday ? todayAvatar : notTodayAvatar;

    return { weekDayName, dayOfTheMonth, dayOfWeekColor, avatarProps };
  });
};

interface SelectSodexoMealTimesProps {
  loading: boolean;
  error: boolean;
  mealTimesByDay: readonly SodexoDayMealTimes[];
  selectedMealTimes: readonly SodexoMealTime[];
  onToggleMealTime: (mealTime: SodexoMealTime) => void;
  onToggleAllMealTimes: (event: ChangeEvent<HTMLInputElement>) => void;
  startOfWeekDate: Date;
  onChangeStartOfWeekDate: (startOfWeekDate: Date) => void;
}

const SelectSodexoMealTimes = ({
  loading,
  error,
  mealTimesByDay,
  selectedMealTimes,
  onToggleMealTime,
  onToggleAllMealTimes,
  startOfWeekDate,
  onChangeStartOfWeekDate,
}: SelectSodexoMealTimesProps) => {
  const hasError = error && mealTimesByDay.length === 0;
  let displayMealTimesByDay = mealTimesByDay.length ? mealTimesByDay : getEmptyMealTimesByDay(startOfWeekDate);
  const totalCount = displayMealTimesByDay.flatMap(({ mealTimes }) => mealTimes).length;
  const selectedCount = selectedMealTimes.length;
  const checked = totalCount === selectedCount;
  const indeterminate = selectedCount > 0 && !checked;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "auto" }}>
      <Box sx={{ position: "sticky", zIndex: 1, top: 0 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mx: 3 }}>
          <WeekNavigation startOfWeekDate={startOfWeekDate} onChange={onChangeStartOfWeekDate} />
          <FormControlLabel
            control={<Checkbox
              checked={checked}
              indeterminate={indeterminate}
              onChange={onToggleAllMealTimes} />}
            label="Select All Meal Times"
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {getDaysProps(displayMealTimesByDay).map(({ weekDayName, dayOfTheMonth, dayOfWeekColor, avatarProps }) => (
            <Box
              sx={{
                flex: 1,
                height: 80,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
              }}
            >
              <Typography variant="subtitle1" color={dayOfWeekColor}>
                {weekDayName}
              </Typography>
              <Avatar sx={avatarProps}>
                <Typography variant="h4" color="inherit">
                  {dayOfTheMonth}
                </Typography>
              </Avatar>
            </Box>
          ))}
        </Box>
      </Box>

      {loading && (
        <Box sx={{ flex: 1 }}>
          <Loading />
        </Box>
      )}
      {!loading && hasError && <Typography sx={{ color: "error.main" }}>Error getting meal times for the week.</Typography>}
      {!loading && !hasError && (
        <Box sx={{ display: "flex" }}>
          {displayMealTimesByDay.map(({ date, mealTimes }) => {
            return (
              <Box sx={{ flex: 1 }} key={date}>
                {mealTimes.map(mealTime => {
                  const selected = selectedMealTimes.includes(mealTime);
                  const { name, start, end } = mealTime;
                  return (
                    <Box sx={{ m: 0.5, p: 0.5, backgroundColor: "grey.200" }}>
                      <FormControlLabel
                        key={`${start}-${end}-${name}`}
                        control={<Checkbox
                          sx={{ py: 0.5 }}
                          size="small"
                          checked={selected}
                          onChange={() => onToggleMealTime(mealTime)} />}
                        label={name}
                      />
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default SelectSodexoMealTimes;

import { ImportMenuOptionsInput } from "../../../../types";
import { buildAdvancedSelectionInput } from "../../../Tags/reducers/advancedSelectionReducers";
import { ShareMealMenuState } from "../../types";

export type ImportMenuOptionsState = Omit<ImportMenuOptionsInput, "teamIds" | "advancedSelectionInput"> & {
  shareState: ShareMealMenuState;
};

export const getInitImportMenuOptionsState = (selectedMenuNames: Set<string>, timezone: string): ImportMenuOptionsState => {
  return {
    shareState: {
      __typename: "Teams",
      teams: [],
      advancedSelectionState: null,
    },
    timezone,
    prepTimeInMinutes: 15,
    lastOrderTimeBeforeEndInMinutes: 0,
    notificationSentBeforeOrderDueInMinutes: 15,
    orderRateLimit: null,
    availableForOrder: true,
    allowSpecialRequests: false,
    menuTimes: Array.from(selectedMenuNames).map(menuName => ({
      menuName,
      mealType: menuName === "Breakfast" ? "breakfast" : menuName === "Lunch" ? "lunch" : menuName === "Dinner" ? "dinner" : "snack",
      startTime:
        menuName === "Breakfast" ? "07:00:00" : menuName === "Lunch" ? "12:00:00" : menuName === "Dinner" ? "18:00:00" : "15:00:00",
      durationInMinutes: 120,
    })),
    overrideIdentity: false,
    userOrderLimit: null,
    isHubCheckInEnabled: false,
    isOrderAndLogRestricted: false,
    theme: "",
  };
};

export const getImportMenuOptionsInput = ({
  menuTimes,
  timezone,
  prepTimeInMinutes,
  lastOrderTimeBeforeEndInMinutes,
  notificationSentBeforeOrderDueInMinutes,
  orderRateLimit,
  availableForOrder,
  allowSpecialRequests,
  userOrderLimit,
  overrideIdentity,
  shareState,
  isOrderAndLogRestricted,
  isHubCheckInEnabled,
  theme,
}: ImportMenuOptionsState): ImportMenuOptionsInput => {
  return {
    teamIds: shareState.__typename === "Teams" ? shareState.teams.map(({ id }) => id) : null,
    advancedSelectionInput: shareState.__typename === "Tags" ? buildAdvancedSelectionInput(shareState.advancedSelectionState) : null,
    menuTimes,
    timezone,
    prepTimeInMinutes,
    lastOrderTimeBeforeEndInMinutes,
    notificationSentBeforeOrderDueInMinutes,
    orderRateLimit,
    availableForOrder,
    allowSpecialRequests: availableForOrder && allowSpecialRequests,
    userOrderLimit,
    overrideIdentity,
    isHubCheckInEnabled,
    isOrderAndLogRestricted,
    theme,
  };
};

import { Box, Typography } from "@mui/material";
import ServingAmountsChipList from "@notemeal/shared/ui/ServingAmount/View/ChipList";
import React from "react";
import { MenuItemChoiceOptionFormFragment } from "../../../types";

interface ReadonlyMenuItemChoiceOptionFormProps {
  option?: MenuItemChoiceOptionFormFragment | null;
}

const ReadonlyMenuItemChoiceOptionForm = ({ option }: ReadonlyMenuItemChoiceOptionFormProps) => {
  return (
    <Box sx={{ ml: 1, width: 300, height: "100%", overflow: "auto", display: "flex", flexDirection: "column" }}>
      {option?.canEditAmount && (
        <Box sx={{ pb: 1 }}>
          <Typography color="textSecondary">Max amount: {option.maxAmount}</Typography>
          <Typography color="textSecondary">Default amount: {option.defaultAmount}</Typography>
        </Box>
      )}
      {option && (
        <>
          <Typography variant="h3">Ingredients</Typography>
          <ServingAmountsChipList servingAmounts={option.servingAmounts} />
        </>
      )}
    </Box>
  );
};

export default ReadonlyMenuItemChoiceOptionForm;

import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";

export type renderInputProps = TextFieldProps;

export const renderInput = ({ InputProps, ...other }: TextFieldProps) => <TextField InputProps={InputProps} {...other} />;

export interface renderSuggestionProps<T> {
  key: string;
  value: string;
  highlightedIndex: number | null;
  index: number;
  itemProps?: any;
  obj: T;
}

export const renderSuggestion = <T extends any>(
  { key, value, index, highlightedIndex, itemProps }: renderSuggestionProps<T>,
  idx: number,
  inputValue: string | null
) => {
  const matches = match(value, inputValue || "");
  const parts = parse(value, matches);

  return (
    <MenuItem
      {...itemProps}
      dense
      key={key}
      selected={highlightedIndex === index}
      component="div">
      <div
        style={{
          /* Great reference: https://stackoverflow.com/questions/7993067/text-overflow-ellipsis-not-working */
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "block",
        }}
      >
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? "bold" : "normal" }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
};

interface ICheckForSchemaVersionUpdate {
  serverSchemaVersion: string;
  clientSchemaVersion: string;
}

// Assumes serverSchemaVersion matches semantic versioning format
// Returns false if primary version is different, true otherwise
export const checkForSchemaVersionUpdate = ({
  serverSchemaVersion,
  clientSchemaVersion,
}: ICheckForSchemaVersionUpdate): "majorUpdate" | "minorUpdate" | undefined => {
  const [_clientMajor, _clientMinor] = clientSchemaVersion.split(".");
  const [_serverMajor, _serverMinor] = serverSchemaVersion.split(".");
  const clientMajor = _clientMajor && parseInt(_clientMajor);
  const clientMinor = _clientMinor && parseInt(_clientMinor);
  const serverMajor = _serverMajor && parseInt(_serverMajor);
  const serverMinor = _serverMinor && parseInt(_serverMinor);

  if ([clientMajor, clientMinor, serverMajor, serverMinor].some(s => s === undefined)) {
    return undefined;
  }

  if (serverMajor > clientMajor) {
    return "majorUpdate";
  } else if (clientMajor === serverMajor && serverMinor > clientMinor) {
    return "minorUpdate";
  }
  return undefined;
};

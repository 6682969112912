import { OrgGroupFormType, orgGroupFormToInput } from "apps/web/src/components/OrgGroup/OrgGroupFormSchema";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import {
  StaffDashboardOrgGroupsDocument,
  StaffDashboardOrgGroupsQuery,
  useEditableOrgGroupQuery,
  useStaffDashboardEditOrgGroupMutation,
} from "apps/web/src/types";
import React from "react";
import { BaseOrgGroupModal } from "./BaseOrgGroupModal";

interface EditOrgGroupModalProps {
  open: boolean;
  onClose: () => void;
  orgGroupId: string;
}

export const EditOrgGroupModal = ({ open, onClose, orgGroupId }: EditOrgGroupModalProps) => {
  const { setMessage } = useSnackbar();
  const { loading, data, error } = useEditableOrgGroupQuery({ variables: { id: orgGroupId } });
  const [editOrgGroup] = useStaffDashboardEditOrgGroupMutation({
    update: (dataProxy, result) => {
      const dashboardQuery = dataProxy.readQuery<StaffDashboardOrgGroupsQuery>({
        query: StaffDashboardOrgGroupsDocument,
      });
      if (result.data && dashboardQuery) {
        const updated = result.data.editOrgGroup.orgGroup;
        dataProxy.writeQuery({
          query: StaffDashboardOrgGroupsDocument,
          data: {
            ...dashboardQuery,
            orgGroups: dashboardQuery.orgGroups.map(og => (og.id === updated.id ? updated : og)),
          },
        });
      }
    },
    onError: e => setMessage("error", e.message),
  });

  const handleSubmit = (orgGroup: OrgGroupFormType) => {
    editOrgGroup({
      variables: {
        input: {
          id: orgGroupId,
          ...orgGroupFormToInput(orgGroup),
        },
      },
    });
  };

  if (loading || !data?.orgGroup) {
    return <></>;
  }

  if (error) {
    setMessage("error", error.message);
  }

  return <BaseOrgGroupModal
    open={open}
    onClose={onClose}
    title={"Edit Org Group"}
    orgGroup={data.orgGroup}
    onSubmit={handleSubmit} />;
};

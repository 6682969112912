import React from "react";
import { Location, Route, Routes, useLocation } from "react-router-dom-v5-compat";
import { getLink } from "../../../NavUtils";
import { SuperuserRoute } from "../../ProtectedRoutes";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { NAV_STAFF_RECIPE, staffFoodManagementLink, staffFoodManagementRecipeLink } from "../FoodManagement/FoodManagementRouter";
import RecipeCreatePage from "./RecipeCreatePage";
import RecipeEditPage from "./RecipeEditPage";

const getNavStaffRecipeLink = (location?: Location) => {
  if (!location?.pathname.includes("/create")) {
    const id = location?.pathname.replace(`${NAV_STAFF_RECIPE}/`, "");
    return getLink("Edit Recipe", `${NAV_STAFF_RECIPE}/${id}`);
  }

  return getLink("Create Recipe", NAV_STAFF_RECIPE);
};

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "Recipe",
  current,
  breadCrumbs: [staffFoodManagementLink, staffFoodManagementRecipeLink],
  tabs: [],
  enableAthleteSearch: true,
});

export const RecipeRouter = () => {
  const location = useLocation();
  const current = getNavStaffRecipeLink(location);

  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  return (
    <Routes>
      <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route
          index
          element={
            <SuperuserRoute>
              <RecipeCreatePage />
            </SuperuserRoute>
          }
        />
        <Route
          path={"/create"}
          element={
            <SuperuserRoute>
              <RecipeCreatePage />
            </SuperuserRoute>
          }
        />
        <Route
          path={"/:id"}
          element={
            <SuperuserRoute>
              <RecipeEditPage />
            </SuperuserRoute>
          }
        />
      </Route>
    </Routes>
  );
};

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { parseDate, safeSerializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { NAV_ORG_KITCHEN_KDS } from "../../../pages/Auth/Org/Kitchen/KitchenPaths";
import { KdsPageMealMenuInDateRangeFragment } from "../../../types";
import MealMenuColumn from "./Column";

interface MealMenusProps {
  mealMenus: readonly KdsPageMealMenuInDateRangeFragment[];
  selectedMealMenuIds: string[];
  setSelectedMealMenuIds: (id: string[]) => void;
  date: string;
  onDone: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(2),
    },
    backButton: {
      marginRight: theme.spacing(),
      alignSelf: "center",
    },
    columnTitle: {
      fontWeight: 700,
    },
    nextButtonLabel: {
      fontWeight: 700,
    },
    dateTextField: {
      width: 170,
      marginLeft: theme.spacing(2),
    },
    doneButton: {
      marginRight: theme.spacing(),
      alignSelf: "center",
    },
  })
);

const MealMenus = ({ onDone, mealMenus, selectedMealMenuIds, setSelectedMealMenuIds, date }: MealMenusProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { setMessage } = useSnackbar();

  const isSelected = (mm: KdsPageMealMenuInDateRangeFragment) => {
    return selectedMealMenuIds.includes(mm.id);
  };
  const handleClickMealMenu = (mm: KdsPageMealMenuInDateRangeFragment) => {
    if (isSelected(mm)) {
      setSelectedMealMenuIds(selectedMealMenuIds.filter(smmid => smmid !== mm.id));
    } else {
      setSelectedMealMenuIds([...selectedMealMenuIds, mm.id]);
    }
  };
  const breakfastMeals = mealMenus.filter(mm => mm.type === "breakfast");
  const lunchMeals = mealMenus.filter(mm => mm.type === "lunch");
  const dinnerMeals = mealMenus.filter(mm => mm.type === "dinner");
  const snackMeals = mealMenus.filter(mm => mm.type === "snack");

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", pb: 1 }}>
        <DesktopDatePicker
          className={classes.dateTextField}
          label="Select date"
          onChange={date => {
            setSelectedMealMenuIds([]);
            const serializedDate = safeSerializeDate(date);
            serializedDate && navigate(`${NAV_ORG_KITCHEN_KDS}/${serializedDate}`);
          }}
          value={parseDate(date)}
        />
        <Button
          variant="outlined"
          endIcon={<ArrowForwardIcon />}
          onClick={() => (selectedMealMenuIds.length > 0 ? onDone() : setMessage("error", "Select at least 1 menu."))}
        >
          Continue to Dining Stations
        </Button>
      </Box>
      <Grid
        container
        spacing={1}
        className={classes.container}>
        <Grid item md={3}>
          <Typography variant="h3" className={classes.columnTitle}>
            Snacks
          </Typography>
          <MealMenuColumn
            mealMenus={snackMeals}
            handleClickMealMenu={handleClickMealMenu}
            isSelected={isSelected}
            date={date} />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h3" className={classes.columnTitle}>
            Breakfast
          </Typography>
          <MealMenuColumn
            mealMenus={breakfastMeals}
            handleClickMealMenu={handleClickMealMenu}
            isSelected={isSelected}
            date={date} />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h3" className={classes.columnTitle}>
            Lunch
          </Typography>
          <MealMenuColumn
            mealMenus={lunchMeals}
            handleClickMealMenu={handleClickMealMenu}
            isSelected={isSelected}
            date={date} />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h3" className={classes.columnTitle}>
            Dinner
          </Typography>
          <MealMenuColumn
            mealMenus={dinnerMeals}
            handleClickMealMenu={handleClickMealMenu}
            isSelected={isSelected}
            date={date} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MealMenus;

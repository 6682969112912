import { Button, Dialog, DialogContent, Divider, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer, useState } from "react";
import { MealMenuIngredientsAnalyzerFragment, useUnitConversionsQuery } from "../../../../types";
import GroupedServingAmountList from "../GroupedServingAmountList";
import MenuItemInput from "../MenuItemInput";
import MenuItemOutput from "../MenuItemOutput";
import { getCombinedMenuItemServingAmounts, getMenuIngredientsAnalyzerState, menuIngredientsAnalyzerReducer } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
    },
    menu: {
      width: "30%",
      overflow: "auto",
    },
    diningStation: {
      marginBottom: theme.spacing(2),
    },
    spacer: {
      flexGrow: 1,
    },
    output: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(2),
    },
    outputHeader: {
      display: "flex",
      marginBottom: theme.spacing(),
    },
    outputContent: {
      flexGrow: 1,
      flexShrink: 1,
      overflow: "auto",
    },
    outputButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

interface MenuIngredientsAnalyzerDialogProps {
  mealMenu: MealMenuIngredientsAnalyzerFragment;
  onClose: () => void;
  open: boolean;
}

const MenuIngredientsAnalyzerDialog = ({ mealMenu, onClose, open }: MenuIngredientsAnalyzerDialogProps) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(menuIngredientsAnalyzerReducer, getMenuIngredientsAnalyzerState(mealMenu));

  const [outputMode, setOutputMode] = useState<"combined" | "separate">("separate");
  const { data } = useUnitConversionsQuery();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth>
      <DialogTitle title="Menu Ingredients Analyzer" onClose={onClose} />
      <DialogContent className={classes.content}>
        <div className={classes.menu}>
          {state.diningStations.map(ds => (
            <div key={ds.id} className={classes.diningStation}>
              <Typography variant="h3">{ds.name}</Typography>
              {ds.menuItems.map(menuItem => (
                <MenuItemInput
                  key={menuItem.id}
                  menuItem={menuItem}
                  onChangeAmount={amountInput =>
                    dispatch({
                      type: "ChangeMenuItemAmount",
                      payload: {
                        menuItemId: menuItem.id,
                        amountInput,
                      },
                    })
                  }
                  onToggleExpanded={() =>
                    dispatch({
                      type: "ToggleMenuItemExpanded",
                      payload: {
                        menuItemId: menuItem.id,
                      },
                    })
                  }
                  onChangeOptionAmount={({ menuItemChoiceOptionId, amountInput }) =>
                    dispatch({
                      type: "ChangeMenuItemChoiceOptionAmount",
                      payload: {
                        menuItemId: menuItem.id,
                        menuItemChoiceOptionId,
                        amountInput,
                      },
                    })
                  }
                />
              ))}
            </div>
          ))}
        </div>
        <Divider orientation="vertical" />
        <div className={classes.output}>
          <div className={classes.outputHeader}>
            <Typography variant="h3">Choose Output Method:</Typography>
            <div className={classes.spacer} />
            <Button
              variant={outputMode === "separate" ? "contained" : "outlined"}
              className={classes.outputButton}
              onClick={() => setOutputMode("separate")}
            >
              Separate
            </Button>
            <Button
              variant={outputMode === "combined" ? "contained" : "outlined"}
              onClick={() => setOutputMode("combined")}
              className={classes.outputButton}
            >
              Combined
            </Button>
          </div>
          <div className={classes.outputContent}>
            {!data ? (
              <Loading />
            ) : outputMode === "combined" ? (
              <GroupedServingAmountList servingAmounts={getCombinedMenuItemServingAmounts(state)} unitConversions={data.unitConversions} />
            ) : (
              state.diningStations
                .flatMap(ds => ds.menuItems)
                .map(menuItem => <MenuItemOutput
                  key={menuItem.id}
                  menuItem={menuItem}
                  unitConversions={data.unitConversions} />)
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MenuIngredientsAnalyzerDialog;

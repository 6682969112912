import { sortByFn } from "@notemeal/utils/sort";
import { maybeNumberToString } from "@notemeal/shared/ui/utils/inputUtils";
import newId from "@notemeal/shared/ui/utils/newId";
import {
  CreateMealMenusInput,
  EditMealMenuInput,
  MealMenuDiningStationFormFragment,
  RestaurantMenuFormFragment,
  RestaurantMenuLinkFormFragment,
  RestaurantMenuLinkPlateFormFragment,
  RestaurantMenuSectionLinkInput,
  RestaurantPreviewFragment,
  DeliveryLocationMenuLinkFormFragment,
  RestaurantLocationPreviewFragment,
} from "../../../types";
import { getAddRestaurantMenuLinkPlateInput, getEditRestaurantMenuLinkPlateInputs } from "./Plates/utils";
import { RestaurantMenuLinkPlateState, RestaurantMenuLinkState, RestaurantMenuSectionLinkState } from "./types";

type getCreateRestaurantMenuLinkInputsValue = Pick<CreateMealMenusInput, "restaurantMenuLinks">;

export const getCreateRestaurantMenuLinkInputs = (
  restaurantMenuLinks: readonly RestaurantMenuLinkState[]
): getCreateRestaurantMenuLinkInputsValue => {
  return {
    restaurantMenuLinks: restaurantMenuLinks.map(
      ({ restaurant, restaurantLocation, restaurantMenu, deliveryLocation, plates, allowCustomOrders, allowPlateOrders }) => ({
        deliveryLocationId: deliveryLocation?.id ?? null,
        restaurantId: restaurant.id,
        restaurantMenuId: restaurantMenu.id,
        restaurantLocationId: restaurantLocation?.id ?? null,
        sections: getRestaurantMenuSectionLinkInputs(restaurantMenu.sections),
        plates: plates.map(getAddRestaurantMenuLinkPlateInput),
        allowCustomOrders,
        allowPlateOrders,
      })
    ),
  };
};

interface getEditRestaurantMenuLinkInputsArgs {
  initial: readonly RestaurantMenuLinkState[];
  final: readonly RestaurantMenuLinkState[];
}

type getEditRestaurantMenuLinkInputsValue = Pick<
  EditMealMenuInput,
  "addRestaurantMenuLinks" | "editRestaurantMenuLinks" | "removeRestaurantMenuLinks"
>;

export const getEditRestaurantMenuLinkInputs = ({
  initial,
  final,
}: getEditRestaurantMenuLinkInputsArgs): getEditRestaurantMenuLinkInputsValue => {
  const finalIds = final.map(l => l.id);
  const initialIds = initial.map(l => l.id);

  return {
    addRestaurantMenuLinks: final
      .filter(l => !initialIds.includes(l.id))
      .map(({ restaurant, restaurantMenu, restaurantLocation, deliveryLocation, plates, allowCustomOrders, allowPlateOrders }) => ({
        deliveryLocationId: deliveryLocation?.id ?? null,
        restaurantId: restaurant.id,
        restaurantMenuId: restaurantMenu.id,
        restaurantLocationId: restaurantLocation?.id ?? null,
        sections: getRestaurantMenuSectionLinkInputs(restaurantMenu.sections),
        plates: plates.map(getAddRestaurantMenuLinkPlateInput),
        allowCustomOrders,
        allowPlateOrders,
      })),
    editRestaurantMenuLinks: final
      .filter(l => initialIds.includes(l.id))
      .map(link => {
        return {
          restaurantMenuLinkId: link.id,
          sections: link.allowCustomOrders ? getRestaurantMenuSectionLinkInputs(link.restaurantMenu.sections) : [],
          ...getEditRestaurantMenuLinkPlateInputs(link),
          allowCustomOrders: link.allowCustomOrders,
          allowPlateOrders: link.allowPlateOrders,
        };
      }),
    removeRestaurantMenuLinks: initialIds.filter(id => !finalIds.includes(id)).map(id => ({ restaurantMenuLinkId: id })),
  };
};

const getRestaurantMenuSectionLinkInputs = (
  sections: readonly RestaurantMenuSectionLinkState[]
): readonly RestaurantMenuSectionLinkInput[] =>
  sections
    .filter(s => s.menuItemAppearances.some(mia => mia.included))
    .map(({ id, maxAmount, menuItemAppearances }) => ({
      restaurantMenuSectionId: id,
      maxAmount,
      menuItems: menuItemAppearances
        .filter(mia => mia.included)
        .map(({ menuItem, maxAmount, availableForOrder, allowSpecialRequests, position }) => ({
          menuItemId: menuItem.id,
          appearance: {
            position,
            maxAmount,
            availableForOrder,
            allowSpecialRequests,
          },
        })),
    }));

export const getAddRestaurantMenuLinkState = (
  restaurant: RestaurantPreviewFragment,
  restaurantMenu: RestaurantMenuFormFragment,
  deliveryLocation: DeliveryLocationMenuLinkFormFragment | null,
  restaurantLocation: RestaurantLocationPreviewFragment | null
): RestaurantMenuLinkState => ({
  id: newId(),
  restaurant,
  restaurantLocation,
  deliveryLocation,
  restaurantMenu: {
    id: restaurantMenu.id,
    sections: restaurantMenu.sections.map(section => ({
      ...section,
      maxAmount: null,
      menuItemAppearances: section.menuItemAppearances.map(mia => ({
        id: mia.id,
        position: mia.position,
        maxAmount: null,
        availableForOrder: true,
        allowSpecialRequests: false,
        menuItem: mia.menuItem,
        included: true,
      })),
    })),
  },
  initialPlateIds: [],
  plates: [],
  allowCustomOrders: false,
  allowPlateOrders: false,
});

export const getEditRestaurantMenuLinkState = ({
  id,
  restaurant,
  restaurantMenu,
  restaurantLocation,
  deliveryLocation,
  sections: restaurantMenuSectionLinks,
  plates,
  allowCustomOrders,
  allowPlateOrders,
}: RestaurantMenuLinkFormFragment): RestaurantMenuLinkState => ({
  id,
  restaurant,
  restaurantLocation,
  deliveryLocation,
  allowCustomOrders,
  allowPlateOrders,
  restaurantMenu: {
    id: restaurantMenu.id,
    sections: restaurantMenu.sections.map(section => {
      const matchingSectionLink = restaurantMenuSectionLinks.find(sl => sl.restaurantMenuSection.id === section.id);
      if (!matchingSectionLink) {
        return {
          ...section,
          maxAmount: null,
          menuItemAppearances: section.menuItemAppearances.map(({ id, menuItem, position }) => ({
            id,
            position,
            maxAmount: null,
            availableForOrder: true,
            allowSpecialRequests: false,
            included: false,
            menuItem,
          })),
        };
      }

      const includedMenuItemIds = matchingSectionLink.menuItemAppearances.map(mia => mia.menuItem.id);
      return {
        ...section,
        maxAmount: matchingSectionLink.maxAmount,
        menuItemAppearances: section.menuItemAppearances.map(({ menuItem, position }) => {
          const matchingAppearance = matchingSectionLink.menuItemAppearances.find(mia => mia.menuItem.id === menuItem.id);
          return {
            id: matchingAppearance ? matchingAppearance.id : newId(),
            position,
            maxAmount: matchingAppearance ? matchingAppearance.maxAmount : null,
            availableForOrder: matchingAppearance ? matchingAppearance.availableForOrder : true,
            allowSpecialRequests: matchingAppearance ? matchingAppearance.allowSpecialRequests : false,
            included: includedMenuItemIds.includes(menuItem.id),
            menuItem,
          };
        }),
      };
    }),
  },
  initialPlateIds: plates.map(p => p.id),
  plates: plates.map(getEditRestaurantMenuLinkPlateState),
});

export const getEditRestaurantMenuLinkPlateState = ({
  id,
  items,
  bulkOrderAmount,
  isDefault,
  position,
  orders,
}: RestaurantMenuLinkPlateFormFragment): RestaurantMenuLinkPlateState => ({
  id,
  isDefault,
  items,
  initialItemIds: items.map(i => i.id),
  editedItemIds: [],
  position,
  bulkOrderAmountInput: maybeNumberToString(bulkOrderAmount),
  orders,
});

export const getInitialSelectedRestaurantMenuLinkId = (
  restaurantMenuLinks: readonly Pick<RestaurantMenuLinkFormFragment, "id" | "restaurant" | "deliveryLocation">[],
  mealMenuDiningStations: readonly Pick<MealMenuDiningStationFormFragment, "id">[]
): string | null => {
  const noRestaurants = restaurantMenuLinks.length === 0;
  const anyDiningStations = mealMenuDiningStations.length > 0;
  if (noRestaurants || anyDiningStations) {
    return null;
  } else {
    const firstRestaurantMenuLink = sortByFn(restaurantMenuLinks, l => l.restaurant.name)[0];
    return firstRestaurantMenuLink.id;
  }
};

import {
  AnthropometrySnapshot,
  MacroTarget,
  applyMetricWeightTarget,
  applyWeightTarget,
  gPerKgToPercent,
  metricGPerKgToPercent,
  percentToGPerKg,
  percentToMetricGPerKg,
} from "@notemeal/shared/utils/macro-protocol";
import { AnthropometryEntrySnapshot } from "../AnthropometryEntry/types";
import { FullCalorieBudgetFragment, FullGoalFragment, GoalTypeFragment, RmrMethod } from "../types";
import { inputToNumber } from "../utils/inputUtils";
import newId from "../utils/newId";

export interface AnthroInfoState {
  mostRecentAnthropometrySnapshot: AnthropometryEntrySnapshot;
  macroProtocolAnthroSnapshot: AnthropometryEntrySnapshot | null;
  mode: "mostRecent" | "macroProtocol";
}

interface BaseMacroProtocolState {
  anthroInfo: AnthroInfoState;
  cho: EditMacroState;
  pro: EditMacroState;
  fat: EditMacroState;
  weightTargetInput: string;
  usingWeightTarget: boolean;
  calorieBudget: EditCalorieBudgetState;
  usingCalorieBudget: boolean;
}

// TODO: metric update - deprecate and use EditMetricMacroProtocolState?
export interface EditImperialMacroProtocolState extends BaseMacroProtocolState {
  weightTarget: number;
  __typename: "imperial";
}

export interface EditMetricMacroProtocolState extends BaseMacroProtocolState {
  weightTargetInKg: number;
  __typename: "metric";
}

export type EditMacroProtocolState = EditImperialMacroProtocolState | EditMetricMacroProtocolState;

export type SaveableMacroProtocolState = EditImperialMacroProtocolState & {
  calorieBudget: SaveableCalorieBudgetState;
};

export interface EditMacroState extends MacroTarget {
  percentInput: string;
  gPerKGInput: string;
}

export interface EditCalorieBudgetState extends Omit<FullCalorieBudgetFragment, "goalSnapshot"> {
  activityFactor: number;
  activityFactorInput: string;
  kcalOffsetInput: string;
  goalSnapshot: GoalEditState;
  rmrCaloriesInput: string;
  goalTypes: readonly GoalTypeFragment[];
}

export type SaveableCalorieBudgetState = EditCalorieBudgetState & {
  goalSnapshot: SaveableGoalEditState;
};

export interface GoalEditState extends Omit<FullGoalFragment, "type"> {
  type: GoalTypeFragment | null;
  kcalOffsetInput: string;
}

type SaveableGoalEditState = GoalEditState & { type: GoalTypeFragment };

type MacroType = "cho" | "pro" | "fat";

interface ChangeMacroPercentAction {
  type: "CHANGE_MACRO_PERCENT";
  payload: {
    macro: MacroType;
    value: string;
  };
}

interface ChangeMacroGPerKGAction {
  type: "CHANGE_MACRO_G_PER_KG";
  payload: {
    macro: MacroType;
    value: string;
  };
}

interface ChangeMacroWithCalorieBudgetAction {
  type: "CHANGE_MACRO_W_CALORIE_BUDGET";
  payload: {
    macro: MacroType;
    value: EditCalorieBudgetState;
  };
}

interface ChangeUsingCalorieBudgetAction {
  type: "CHANGE_USING_CALORIE_BUDGET";
  payload: boolean;
}

interface ChangeUsingWeightTargetAction {
  type: "CHANGE_USING_WEIGHT_TARGET";
  payload: boolean;
}

interface ChangeWeightTargetInputAction {
  type: "CHANGE_WEIGHT_TARGET_INPUT";
  payload: string;
}

const createMacroCalorieAction = (macro: MacroType, calorieBudget: EditCalorieBudgetState): ChangeMacroWithCalorieBudgetAction => ({
  type: "CHANGE_MACRO_W_CALORIE_BUDGET",
  payload: { macro: macro, value: calorieBudget },
});

interface ChangeRMRMethodAction {
  type: "CHANGE_RMR_METHOD";
  payload: RmrMethod | null;
}

interface ChangeRMRCaloriesAction {
  type: "CHANGE_RMR_CALORIES";
  payload: string;
}

interface ChangeKcalOffsetAction {
  type: "CHANGE_KCAL_OFFSET";
  payload: string;
}

interface ChangeActivityFactorAction {
  type: "CHANGE_ACTIVITY_FACTOR";
  payload: string;
}

interface ChangeGoalKcalOffsetAction {
  type: "CHANGE_GOAL_KCAL_OFFSET";
  payload: string;
}

interface ChangeGoalTypeAction {
  type: "CHANGE_GOAL_TYPE";
  payload: GoalTypeFragment;
}

interface ChangeAnthroInfoModeAction {
  type: "CHANGE_ANTHRO_INFO_MODE";
  payload: AnthroInfoState["mode"];
}

export type EditMacroProtocolAction =
  | ChangeMacroPercentAction
  | ChangeMacroGPerKGAction
  | ChangeRMRMethodAction
  | ChangeActivityFactorAction
  | ChangeKcalOffsetAction
  | ChangeGoalKcalOffsetAction
  | ChangeGoalTypeAction
  | ChangeUsingCalorieBudgetAction
  | ChangeRMRCaloriesAction
  | ChangeUsingWeightTargetAction
  | ChangeWeightTargetInputAction
  | ChangeAnthroInfoModeAction;

export const getAnthropometrySnapshotFromAnthroInfoState = (anthroInfo: AnthroInfoState): AnthropometryEntrySnapshot => {
  switch (anthroInfo.mode) {
    case "mostRecent":
      return anthroInfo.mostRecentAnthropometrySnapshot;
    case "macroProtocol":
      return anthroInfo.macroProtocolAnthroSnapshot ?? anthroInfo.mostRecentAnthropometrySnapshot;
    default:
      return anthroInfo.mostRecentAnthropometrySnapshot;
  }
};

export const editMacroProtocolReducer = (
  state: EditImperialMacroProtocolState,
  action: EditMacroProtocolAction
): EditImperialMacroProtocolState => {
  const targetAnthroSnapshot = applyWeightTarget(
    getAnthropometrySnapshotFromAnthroInfoState(state.anthroInfo),
    state.usingWeightTarget ? state.weightTarget : null
  );

  switch (action.type) {
    case "CHANGE_MACRO_PERCENT":
    case "CHANGE_MACRO_G_PER_KG": {
      const macro = action.payload.macro;
      return {
        ...state,
        [macro]: macroReducer(state, action, targetAnthroSnapshot),
      };
    }
    case "CHANGE_RMR_METHOD":
    case "CHANGE_RMR_CALORIES":
    case "CHANGE_ACTIVITY_FACTOR":
    case "CHANGE_KCAL_OFFSET":
    case "CHANGE_GOAL_KCAL_OFFSET":
    case "CHANGE_GOAL_TYPE": {
      const calorieBudgetState = calorieBudgetReducer(state.calorieBudget, action);
      return {
        ...state,
        cho: macroReducer(state, createMacroCalorieAction("cho", calorieBudgetState), targetAnthroSnapshot),
        pro: macroReducer(state, createMacroCalorieAction("pro", calorieBudgetState), targetAnthroSnapshot),
        fat: macroReducer(state, createMacroCalorieAction("fat", calorieBudgetState), targetAnthroSnapshot),
        calorieBudget: calorieBudgetState,
      };
    }
    case "CHANGE_USING_CALORIE_BUDGET":
      return {
        ...state,
        usingCalorieBudget: action.payload,
      };
    case "CHANGE_USING_WEIGHT_TARGET": {
      const toggleTargetAnthroSnapshot = applyWeightTarget(
        getAnthropometrySnapshotFromAnthroInfoState(state.anthroInfo),
        action.payload ? state.weightTarget : null
      );
      return {
        ...state,
        usingWeightTarget: action.payload,
        cho: macroReducer(state, createMacroCalorieAction("cho", state.calorieBudget), toggleTargetAnthroSnapshot),
        pro: macroReducer(state, createMacroCalorieAction("pro", state.calorieBudget), toggleTargetAnthroSnapshot),
        fat: macroReducer(state, createMacroCalorieAction("fat", state.calorieBudget), toggleTargetAnthroSnapshot),
      };
    }
    case "CHANGE_WEIGHT_TARGET_INPUT": {
      const weightTargetInput = inputToNumber(action.payload);
      const newWeightTarget = weightTargetInput === null ? state.weightTarget : weightTargetInput;
      const newTargetAnthroSnapshot = applyWeightTarget(getAnthropometrySnapshotFromAnthroInfoState(state.anthroInfo), newWeightTarget);
      return {
        ...state,
        weightTargetInput: action.payload,
        weightTarget: newWeightTarget,
        cho: macroReducer(state, createMacroCalorieAction("cho", state.calorieBudget), newTargetAnthroSnapshot),
        pro: macroReducer(state, createMacroCalorieAction("pro", state.calorieBudget), newTargetAnthroSnapshot),
        fat: macroReducer(state, createMacroCalorieAction("fat", state.calorieBudget), newTargetAnthroSnapshot),
      };
    }
    case "CHANGE_ANTHRO_INFO_MODE": {
      const newAnthroInfo = {
        ...state.anthroInfo,
        mode: action.payload,
      };
      const newTargetAnthroSnapshot = applyWeightTarget(
        getAnthropometrySnapshotFromAnthroInfoState(newAnthroInfo),
        state.usingWeightTarget ? state.weightTarget : null
      );
      return {
        ...state,
        anthroInfo: newAnthroInfo,
        cho: macroReducer(state, createMacroCalorieAction("cho", state.calorieBudget), newTargetAnthroSnapshot),
        pro: macroReducer(state, createMacroCalorieAction("pro", state.calorieBudget), newTargetAnthroSnapshot),
        fat: macroReducer(state, createMacroCalorieAction("fat", state.calorieBudget), newTargetAnthroSnapshot),
      };
    }
  }
};

export const editMetricMacroProtocolReducer = (
  state: EditMetricMacroProtocolState,
  action: EditMacroProtocolAction
): EditMetricMacroProtocolState => {
  const targetAnthroSnapshot = applyMetricWeightTarget(
    getAnthropometrySnapshotFromAnthroInfoState(state.anthroInfo),
    state.usingWeightTarget ? state.weightTargetInKg : null
  );

  switch (action.type) {
    case "CHANGE_MACRO_PERCENT":
    case "CHANGE_MACRO_G_PER_KG": {
      const macro = action.payload.macro;
      return {
        ...state,
        [macro]: metricMacroReducer(state, action, targetAnthroSnapshot),
      };
    }
    case "CHANGE_RMR_METHOD":
    case "CHANGE_RMR_CALORIES":
    case "CHANGE_ACTIVITY_FACTOR":
    case "CHANGE_KCAL_OFFSET":
    case "CHANGE_GOAL_KCAL_OFFSET":
    case "CHANGE_GOAL_TYPE": {
      const calorieBudgetState = calorieBudgetReducer(state.calorieBudget, action);
      return {
        ...state,
        cho: metricMacroReducer(state, createMacroCalorieAction("cho", calorieBudgetState), targetAnthroSnapshot),
        pro: metricMacroReducer(state, createMacroCalorieAction("pro", calorieBudgetState), targetAnthroSnapshot),
        fat: metricMacroReducer(state, createMacroCalorieAction("fat", calorieBudgetState), targetAnthroSnapshot),
        calorieBudget: calorieBudgetState,
      };
    }
    case "CHANGE_USING_CALORIE_BUDGET":
      return {
        ...state,
        usingCalorieBudget: action.payload,
      };
    case "CHANGE_USING_WEIGHT_TARGET": {
      const toggleTargetAnthroSnapshot = applyMetricWeightTarget(
        getAnthropometrySnapshotFromAnthroInfoState(state.anthroInfo),
        action.payload ? state.weightTargetInKg : null
      );
      return {
        ...state,
        usingWeightTarget: action.payload,
        cho: metricMacroReducer(state, createMacroCalorieAction("cho", state.calorieBudget), toggleTargetAnthroSnapshot),
        pro: metricMacroReducer(state, createMacroCalorieAction("pro", state.calorieBudget), toggleTargetAnthroSnapshot),
        fat: metricMacroReducer(state, createMacroCalorieAction("fat", state.calorieBudget), toggleTargetAnthroSnapshot),
      };
    }
    case "CHANGE_WEIGHT_TARGET_INPUT": {
      const weightTargetInput = inputToNumber(action.payload);
      const newWeightTarget = weightTargetInput === null ? state.weightTargetInKg : weightTargetInput;
      const newTargetAnthroSnapshot = applyMetricWeightTarget(
        getAnthropometrySnapshotFromAnthroInfoState(state.anthroInfo),
        newWeightTarget
      );
      return {
        ...state,
        weightTargetInput: action.payload,
        weightTargetInKg: newWeightTarget,
        cho: metricMacroReducer(state, createMacroCalorieAction("cho", state.calorieBudget), newTargetAnthroSnapshot),
        pro: metricMacroReducer(state, createMacroCalorieAction("pro", state.calorieBudget), newTargetAnthroSnapshot),
        fat: metricMacroReducer(state, createMacroCalorieAction("fat", state.calorieBudget), newTargetAnthroSnapshot),
      };
    }
    case "CHANGE_ANTHRO_INFO_MODE": {
      const newAnthroInfo = {
        ...state.anthroInfo,
        mode: action.payload,
      };
      const newTargetAnthroSnapshot = applyMetricWeightTarget(
        getAnthropometrySnapshotFromAnthroInfoState(newAnthroInfo),
        state.usingWeightTarget ? state.weightTargetInKg : null
      );

      return {
        ...state,
        anthroInfo: newAnthroInfo,
        cho: metricMacroReducer(state, createMacroCalorieAction("cho", state.calorieBudget), newTargetAnthroSnapshot),
        pro: metricMacroReducer(state, createMacroCalorieAction("pro", state.calorieBudget), newTargetAnthroSnapshot),
        fat: metricMacroReducer(state, createMacroCalorieAction("fat", state.calorieBudget), newTargetAnthroSnapshot),
      };
    }
  }
};

const calorieBudgetReducer = (
  state: EditCalorieBudgetState,
  action:
    | ChangeRMRMethodAction
    | ChangeRMRCaloriesAction
    | ChangeActivityFactorAction
    | ChangeKcalOffsetAction
    | ChangeGoalKcalOffsetAction
    | ChangeGoalTypeAction
): EditCalorieBudgetState => {
  switch (action.type) {
    case "CHANGE_RMR_METHOD":
      return {
        ...state,
        rmrMethod: action.payload,
      };
    case "CHANGE_RMR_CALORIES":
      return {
        ...state,
        rmrCaloriesInput: action.payload,
        rmrCalories: inputToNumber(action.payload),
      };
    case "CHANGE_ACTIVITY_FACTOR": {
      const activityFactorInput = inputToNumber(action.payload);
      return {
        ...state,
        activityFactorInput: action.payload,
        activityFactor: activityFactorInput === null ? state.activityFactor : activityFactorInput,
      };
    }
    case "CHANGE_KCAL_OFFSET": {
      const kcalOffsetInput = inputToNumber(action.payload);
      return {
        ...state,
        kcalOffsetInput: action.payload,
        kcalOffset: kcalOffsetInput === null ? state.kcalOffset : kcalOffsetInput,
      };
    }
    case "CHANGE_GOAL_KCAL_OFFSET": {
      const goalKcalOffsetInput = inputToNumber(action.payload);
      return {
        ...state,
        goalSnapshot: {
          id: state.goalSnapshot ? state.goalSnapshot.id : newId(),
          __typename: "Goal",
          start: "",
          end: "",
          type: state.goalSnapshot && state.goalSnapshot.type ? state.goalSnapshot.type : null,
          kcalOffsetInput: action.payload,
          kcalOffset: goalKcalOffsetInput === null ? (state.goalSnapshot ? state.goalSnapshot.kcalOffset : 0) : goalKcalOffsetInput,
        },
      };
    }
    case "CHANGE_GOAL_TYPE":
      return {
        ...state,
        goalSnapshot: {
          id: state.goalSnapshot ? state.goalSnapshot.id : newId(),
          __typename: "Goal",
          start: "",
          end: "",
          type: action.payload,
          kcalOffsetInput: String(action.payload.defaultKcalOffset),
          kcalOffset: action.payload.defaultKcalOffset,
        },
      };
  }
};

// TODO: metric update - deprecate and use metricMacroReducer?
const macroReducer = (
  state: EditImperialMacroProtocolState,
  action: ChangeMacroPercentAction | ChangeMacroGPerKGAction | ChangeMacroWithCalorieBudgetAction,
  targetAnthroSnapshot: AnthropometrySnapshot
): EditMacroState => {
  const macro = action.payload.macro;
  const macroState = state[macro];

  let percent: number = macroState.percent;
  let percentInput: string = macroState.percentInput;
  let gPerKg: number = macroState.gPerKg;
  let gPerKGInput: string = macroState.gPerKGInput;
  let usesPercent: boolean = macroState.usesPercent;

  switch (action.type) {
    case "CHANGE_MACRO_PERCENT":
      percentInput = action.payload.value;
      if (!isNaN(Number(percentInput)) && percentInput !== "") {
        percent = Number(percentInput);
        gPerKg = percentToGPerKg(percent, macro, targetAnthroSnapshot, state.calorieBudget);
        gPerKGInput = gPerKg.toString();
        usesPercent = true;
      }
      break;
    case "CHANGE_MACRO_G_PER_KG":
      gPerKGInput = action.payload.value;
      if (!isNaN(Number(gPerKGInput)) && gPerKGInput !== "") {
        gPerKg = Number(gPerKGInput);
        percent = gPerKgToPercent(gPerKg, macro, targetAnthroSnapshot, state.calorieBudget);
        percentInput = percent.toString();
        usesPercent = false;
      }
      break;
    case "CHANGE_MACRO_W_CALORIE_BUDGET": {
      const calorieBudget = action.payload.value;
      if (usesPercent) {
        gPerKg = percentToGPerKg(percent, macro, targetAnthroSnapshot, calorieBudget);
        gPerKGInput = gPerKg.toString();
      } else {
        percent = gPerKgToPercent(gPerKg, macro, targetAnthroSnapshot, calorieBudget);
        percentInput = percent.toString();
      }
    }
  }
  return {
    percent: percent,
    percentInput: percentInput,
    gPerKg: gPerKg,
    gPerKGInput: gPerKGInput,
    usesPercent: usesPercent,
  };
};

const metricMacroReducer = (
  state: EditMetricMacroProtocolState,
  action: ChangeMacroPercentAction | ChangeMacroGPerKGAction | ChangeMacroWithCalorieBudgetAction,
  targetAnthroSnapshot: AnthropometrySnapshot
): EditMacroState => {
  const macro = action.payload.macro;
  const macroState = state[macro];

  let percent: number = macroState.percent;
  let percentInput: string = macroState.percentInput;
  let gPerKg: number = macroState.gPerKg;
  let gPerKGInput: string = macroState.gPerKGInput;
  let usesPercent: boolean = macroState.usesPercent;

  switch (action.type) {
    case "CHANGE_MACRO_PERCENT":
      percentInput = action.payload.value;
      if (!isNaN(Number(percentInput)) && percentInput !== "") {
        percent = Number(percentInput);
        gPerKg = percentToMetricGPerKg(percent, macro, targetAnthroSnapshot, state.calorieBudget);
        gPerKGInput = gPerKg.toString();
        usesPercent = true;
      }
      break;
    case "CHANGE_MACRO_G_PER_KG":
      gPerKGInput = action.payload.value;
      if (!isNaN(Number(gPerKGInput)) && gPerKGInput !== "") {
        gPerKg = Number(gPerKGInput);
        percent = metricGPerKgToPercent(gPerKg, macro, targetAnthroSnapshot, state.calorieBudget);
        percentInput = percent.toString();
        usesPercent = false;
      }
      break;
    case "CHANGE_MACRO_W_CALORIE_BUDGET": {
      const calorieBudget = action.payload.value;
      if (usesPercent) {
        gPerKg = percentToMetricGPerKg(percent, macro, targetAnthroSnapshot, calorieBudget);
        gPerKGInput = gPerKg.toString();
      } else {
        percent = metricGPerKgToPercent(gPerKg, macro, targetAnthroSnapshot, calorieBudget);
        percentInput = percent.toString();
      }
    }
  }
  return {
    percent: percent,
    percentInput: percentInput,
    gPerKg: gPerKg,
    gPerKGInput: gPerKGInput,
    usesPercent: usesPercent,
  };
};

import React, { createContext, useContext } from "react";
import { SexType } from "../../../../types";

const DriParamsContext = createContext<DriParamsContextProviderProps>({
  age: null,
  sex: null,
  kcal: null,
});

interface DriParamsContextProviderProps {
  children?: React.ReactNode;
  age: number | null;
  sex: SexType | null;
  kcal: number | null;
}

export const DriParamsContextProvider = ({ children, age, sex, kcal }: DriParamsContextProviderProps) => {
  return <DriParamsContext.Provider value={{ age, sex, kcal }}>{children}</DriParamsContext.Provider>;
};

export const useDriParams = () => useContext(DriParamsContext);

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { StaffDashboardOrgPreviewFragment, useEditOrgMutation } from "../../../../types";
import { OrgForm } from "../Form/Create";
import { editOrgFormReducer, editOrgFormToSaveTooltips, initialEditOrgFormState, integrationInput } from "../Form/utils";

interface OrgModalProps {
  open: boolean;
  onClose: () => void;
  org: StaffDashboardOrgPreviewFragment;
}

const EditDialog = ({ open, onClose, org }: OrgModalProps) => {
  const { setMessage } = useSnackbar();
  const [state, dispatch] = useReducer(editOrgFormReducer, initialEditOrgFormState(org));
  const canSaveTooltips = editOrgFormToSaveTooltips(state);

  const [editOrg] = useEditOrgMutation({
    onError: e => setMessage("error", e.message),
  });

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    const {
      allowsPublicAccess,
      isActive,
      name,
      compassId,
      allowCompassId,
      cbordId,
      allowCbordId,
      bonAppetitId,
      allowBonAppetitId,
      sodexoId,
      allowSodexoId,
      agreementForm,
      scoringSystem,
      localeCode,
      territoryName,
    } = state;
    editOrg({
      variables: {
        input: {
          id: org.id,
          teamworksId: org.teamworksId,
          isActive,
          name,
          allowsPublicAccess,
          createMemberships: [],
          editMemberships: [],
          integrations: integrationInput({
            compassId,
            cbordId,
            bonAppetitId,
            sodexoId,
            allowCompassId,
            allowCbordId,
            allowBonAppetitId,
            allowSodexoId,
          }),
          agreementFormId: agreementForm?.id || null,
          scoringSystemId: scoringSystem?.id || null,
          localeCode,
          territoryName,
        },
      },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={`Edit Org`} onClose={onClose} />
      <DialogContent>
        <OrgForm state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

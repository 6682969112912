import React from "react";
import {
  BulkImportFoodFragment,
  UnitFragment,
  useCreateRestaurantFoodsMutation,
  useRestaurantFoodsQuery,
  useUpdateRestaurantFoodsMutation,
} from "../../../../types";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { ICrudHandler } from "../types";
import { parseImportError } from "../utils";
import BaseFoodImportTabPanel, { ImportFoodProps } from "./BaseFoodTabPanel";
import { ImportableFoodEntry } from "./types";
import { AdditionalFoodInfo, getRestaurantFoodsInsertInput, getRestaurantFoodsUpdateInput } from "./utils";

export interface RestaurantFoodImportTabPanelProps
  extends Pick<ImportFoodProps, "loadAndParse" | "matchFields" | "fields" | "description"> {
  units: readonly UnitFragment[];
  restaurantId: string;
  resetTable: () => void;
}

const RestaurantFoodImportTabPanel = ({ units, restaurantId, resetTable, ...restProps }: RestaurantFoodImportTabPanelProps) => {
  const useFoodsQuery = () => {
    const { data, loading } = useRestaurantFoodsQuery({
      variables: { restaurantId, limit: null, searchTerm: null },
    });
    return { foods: data?.restaurantFoods, loading };
  };

  const { setMessage } = useSnackbar();
  const [createGenericFoods] = useCreateRestaurantFoodsMutation({
    onError: e => setMessage("error", parseImportError(e)),
    onCompleted: () => {
      resetTable();
    },
  });
  const [editGenericFoods] = useUpdateRestaurantFoodsMutation({
    onError: e => setMessage("error", parseImportError(e)),
    onCompleted: () => {
      resetTable();
    },
  });

  const handleInsertFoods = ({ matches, onCacheUpdate }: ICrudHandler<ImportableFoodEntry, AdditionalFoodInfo>) => {
    const input = {
      foods: getRestaurantFoodsInsertInput(matches, units, restaurantId),
    };

    createGenericFoods({
      variables: { input },
      update: onCacheUpdate,
    });
  };

  const handleUpdateFoods = ({
    matches,
    onCacheUpdate,
    foods,
  }: ICrudHandler<ImportableFoodEntry, AdditionalFoodInfo> & {
    foods: readonly BulkImportFoodFragment[] | undefined;
  }) => {
    const input = {
      foods: getRestaurantFoodsUpdateInput(matches, units, foods, restaurantId),
    };
    editGenericFoods({
      variables: { input },
      update: onCacheUpdate,
    });
  };

  return (
    <BaseFoodImportTabPanel
      mode="restaurant"
      useFoodsQuery={useFoodsQuery}
      handleInsertFoods={handleInsertFoods}
      handleUpdateFoods={handleUpdateFoods}
      {...restProps}
    />
  );
};

export default RestaurantFoodImportTabPanel;

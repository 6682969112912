import { Button, Dialog, DialogActions, DialogTitle, FormControlLabel, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import MenuItemReadonlyDialogContent from "../../../../components/MenuItem/Form/ReadonlyForm";
import MenuItemOrderDetails from "../../../../components/MenuItem/OrderDetails/Details";
import DiscardChangesDialog from "../../../../components/universal/DiscardChangesDialog";
import { MenuItemFormFragment } from "../../../../types";
import { RestaurantMenuItemAppearanceState } from "../types";
import { INCLUDE_ON_MENU_LABEL } from "./utils";

interface RestaurantMenuItemAppearanceDialogContentProps {
  open: boolean;
  onClose: () => void;
  menuItem: MenuItemFormFragment;
  menuItemAppearance: RestaurantMenuItemAppearanceState;
  onDone: (menuItemAppearance: RestaurantMenuItemAppearanceState) => void;
}

export const RestaurantMenuItemAppearanceDialogContent = ({
  open,
  onClose,
  onDone,
  menuItemAppearance,
  menuItem,
}: RestaurantMenuItemAppearanceDialogContentProps) => {
  const [maxAmount, setMaxAmount] = useState(menuItemAppearance.maxAmount);
  const [availableForOrder, setAvailableForOrder] = useState(menuItemAppearance.availableForOrder);
  const [allowSpecialRequests, setAllowSpecialRequests] = useState(menuItemAppearance.allowSpecialRequests);
  const [included, setIncluded] = useState(menuItemAppearance.included);
  const [edited, setEdited] = useState(false);
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const handleClose = () => {
    if (edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3">{menuItem.name}</Typography>

        <FormControlLabel
          control={<Switch checked={included} onChange={(_, value) => setIncluded(value)} />}
          label={INCLUDE_ON_MENU_LABEL}
        />
      </DialogTitle>
      <MenuItemReadonlyDialogContent
        menuItem={menuItem}
        orderDetails={
          <MenuItemOrderDetails
            variant="RestaurantLinkMenuItemAppearance"
            onChangeMaxAmount={_maxAmount => {
              setMaxAmount(_maxAmount);
              setEdited(true);
            }}
            onChangeAvailableForOrder={_availableForOrder => {
              setAvailableForOrder(_availableForOrder);
              setEdited(true);
            }}
            onChangeAllowSpecialRequests={_allowSpecialRequests => {
              setAllowSpecialRequests(_allowSpecialRequests);
              setEdited(true);
            }}
            availableForOrder={availableForOrder}
            allowSpecialRequests={allowSpecialRequests}
            maxAmount={maxAmount}
            sx={{ pr: 2 }}
          />
        }
      />
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button
          onClick={() => {
            onDone({
              ...menuItemAppearance,
              maxAmount,
              availableForOrder,
              allowSpecialRequests,
              included,
            });
            onClose();
          }}
        >
          Done
        </Button>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
    </Dialog>
  );
};

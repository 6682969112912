import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, InputAdornment, TextField, Typography } from "@mui/material";
import { formatTimeRange } from "@notemeal/shared/ui/utils/dateTimes";
import React, { memo } from "react";
import { EditFullMacroMealTemplateFragment, useChangeMacroTargetMutation } from "../../../../types";

const MACRO_WIDTH = "120px";

interface EditMacroTargetsProps {
  mealTemplate: EditFullMacroMealTemplateFragment;
}

export const EditMacroTargets = memo(({ mealTemplate }: EditMacroTargetsProps) => {
  const [changeMacroTarget] = useChangeMacroTargetMutation();
  const { meal, cho, choInput, pro, proInput, fat, fatInput } = mealTemplate;
  const mealTemplateId = mealTemplate.id;
  const calories = Math.round((cho || 0) * 4 + (pro || 0) * 4 + (fat || 0) * 9);

  return (
    <Card
      sx={{
        height: "72px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box sx={{ height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
        <RestaurantIcon fontSize="small" sx={{ color: "greyscale.400" }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1Medium">{meal.name}</Typography>
          <Typography sx={{ color: "mediumEmphasisText" }} variant="body2">
            {formatTimeRange(meal.start, meal.end)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ py: 1, px: 2, display: "flex", gap: 1, alignItems: "center" }}>
        <TextField
          sx={{ width: MACRO_WIDTH }}
          type="number"
          inputProps={{ min: 0, step: 1 }}
          value={choInput}
          error={cho === null}
          label="CHO"
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
          onChange={e =>
            changeMacroTarget({
              variables: {
                macro: "cho",
                target: e.target.value,
                mealTemplateId,
              },
            })
          }
        />
        <TextField
          sx={{ width: MACRO_WIDTH }}
          type="number"
          inputProps={{ min: 0, step: 1 }}
          value={proInput}
          error={pro === null}
          label="PRO"
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
          onChange={e =>
            changeMacroTarget({
              variables: {
                macro: "pro",
                target: e.target.value,
                mealTemplateId,
              },
            })
          }
        />
        <TextField
          sx={{ width: MACRO_WIDTH }}
          type="number"
          inputProps={{ min: 0, step: 1 }}
          value={fatInput}
          error={fat === null}
          label="FAT"
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
          }}
          onChange={e =>
            changeMacroTarget({
              variables: {
                macro: "fat",
                target: e.target.value,
                mealTemplateId,
              },
            })
          }
        />
        <Typography
          sx={{ width: "80px", alignSelf: "flex-end" }}
          align="right"
          variant="body1Medium">
          {calories} kcal
        </Typography>
      </Box>
    </Card>
  );
});

import { BaseFoodFormType } from "../FoodFormSchema/FoodFormSchema";
import { Mode, scaleNutrientValues } from "../FoodFormSchema/utils";
import { IFoodRow } from "./TableRow";
export type FoodTableMode = Mode | "org-group";

export const getInitialFormStateFromIFoodRow = (
  { servings, exchangeTypes, id, nutrientAmounts, groceryListCategory, locales, foodGroups, ...initState }: IFoodRow,
  mode: Mode
): Partial<BaseFoodFormType> => ({
  ...initState,
  mode,
  groceryListCategoryId: groceryListCategory?.id || null,
  id,
  servings: servings.map(serving => {
    return {
      ...serving,
      cho: (serving.defaultAmount || 1) * (serving.cho || 0),
      pro: (serving.defaultAmount || 1) * (serving.pro || 0),
      fat: (serving.defaultAmount || 1) * (serving.fat || 0),
      nutrientAmounts: scaleNutrientValues(nutrientAmounts, (serving.weight || 0) * (serving.defaultAmount || 1), 100),
    };
  }),
  exchangeTypes,
  isFreeFood: !exchangeTypes || exchangeTypes.length === 0,
  nutrientAmounts: null, // We don't want to load up all of these in the table, it is to much.
  nutrientAmountsManualEntry: nutrientAmounts,
  locales,
  foodGroups,
});

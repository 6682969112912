import React, { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom-v5-compat";
import { Role } from "../../types";
import { useUser } from "../../utils/tokens";
import { devEnv } from "../NavUtils";
import { OrgUserDetails, getOrgUserDetails } from "./OrgUtils";

export type ProptectedRouteProps = { isAllowed: (details: OrgUserDetails) => boolean; children?: ReactElement };
export const ProtectedRoute = ({ isAllowed, children }: ProptectedRouteProps): ReactElement | null => {
  return isAllowed(getOrgUserDetails(useUser())) ? children || <Outlet /> : <Navigate to="/" />;
};

export const DevRoute = ({ children }: { children?: ReactElement }): ReactElement => (
  <ProtectedRoute isAllowed={() => devEnv}>{children}</ProtectedRoute>
);

export const SuperuserRoute = ({ children }: { children?: ReactElement }): ReactElement => (
  <ProtectedRoute isAllowed={({ isSuperuser }) => isSuperuser}>{children}</ProtectedRoute>
);

export const StaffRoute = ({ children }: { children?: ReactElement }): ReactElement => (
  <ProtectedRoute isAllowed={({ isStaff }) => isStaff}>{children}</ProtectedRoute>
);

export const AdminRoute = ({ children }: { children?: ReactElement }): ReactElement => (
  <ProtectedRoute isAllowed={({ isAdmin, hasRoleDietitian }) => isAdmin && hasRoleDietitian}>{children}</ProtectedRoute>
);

export const RoleRoute = ({ roles: allowedRoles, children }: { roles: Role[]; children?: ReactElement }): ReactElement => {
  return <ProtectedRoute isAllowed={({ roles }) => allowedRoles.some(role => roles.includes(role))}>{children}</ProtectedRoute>;
};

export const AuthRoute = ({ children }: { children?: ReactElement }): ReactElement => (
  <ProtectedRoute isAllowed={({ userId }) => !!userId}>{children}</ProtectedRoute>
);

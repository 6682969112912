import React from "react";
import BaseAsyncAutoComplete, { BasicOption } from "../../../../components/universal/BaseAsyncAutocomplete";
import { RestaurantTypeFragment, useStaffDashboardRestaurantTypesQuery } from "../../../../types";

const useSearchQuery = (query: string) => {
  const { data, loading } = useStaffDashboardRestaurantTypesQuery({
    variables: { query, input: { limit: 25, offset: 0 } },
  });
  const options = data ? [...data.restaurantTypeOffsetConnection.edges] : undefined;
  return { options, loading };
};

interface RestaurantMenuFormRestaurantTypeProps {
  className?: string;
  restaurantType: RestaurantTypeFragment | null;
  onChange: (restaurantType: RestaurantTypeFragment | null) => void;
}

const RestaurantMenuFormRestaurantType = ({ restaurantType, onChange }: RestaurantMenuFormRestaurantTypeProps) => {
  return (
    <BaseAsyncAutoComplete
      useSearchQuery={useSearchQuery}
      inputPlaceholder="Restaurant Type"
      noOptionsText="No Restaurant Types Found"
      handleChange={onChange}
      multiple={false}
      renderOption={({ name }: RestaurantTypeFragment) => <BasicOption element={name} />}
      filterUsedOptions={o => o}
      getOptionLabel={(t: RestaurantTypeFragment) => t.name}
      value={restaurantType}
    />
  );
};

export default RestaurantMenuFormRestaurantType;

import { ApolloCache } from "@apollo/client";

export const evictRecentAnthropometryEntriesForAthlete = (athleteId: string, cache: ApolloCache<object>) => {
  const RECENT_ANTHRO_EVICT_FIELD_NAMES = [
    "mostRecentAnthropometryEntry",
    "mostRecentAnthropometryEntryForRmrMethod",
    "mostRecentAnthropometryEntryWith",
  ];
  const id = `Athlete:${athleteId}`;

  RECENT_ANTHRO_EVICT_FIELD_NAMES.map(fieldName =>
    cache.evict({
      id,
      broadcast: true,
      fieldName,
    })
  );
};

import React from "react";
import { TimelineMealForModal } from "../utils";

import { Typography } from "@mui/material";
import { isMealMenuClosed } from "@notemeal/shared/ui/MealMenu/utils";
import Loading from "@notemeal/shared/ui/global/Loading";
import { useMealMenuQuery } from "apps/web/src/types";
import TimelineMealModalOrderTabContent from "./OrderTabContent";
import TimelineMealMenuOrderButtons from "./TimelineMealMenuOrderButtons";
import { useTimelineMealModalContext } from "./TimelineMealModalContext";

interface TimelineMealModalOrderTabProps {
  timelineMeal: TimelineMealForModal;
  athleteId: string;
}

const TimelineMealModalOrderTab = ({ timelineMeal, athleteId }: TimelineMealModalOrderTabProps) => {
  const { cart } = useTimelineMealModalContext();

  const mealMenuId = cart?.mealMenuId ?? null;

  const { data, loading } = useMealMenuQuery({
    variables: { mealMenuId: mealMenuId ?? "" },
    skip: mealMenuId === null,
  });

  const mealMenu = data?.mealMenu;
  return (
    <>
      <TimelineMealMenuOrderButtons selectedMenuId={cart?.mealMenuId ?? null} timelineMeal={timelineMeal} />
      {cart && mealMenu ? (
        <TimelineMealModalOrderTabContent
          timelineMeal={timelineMeal}
          mealMenuWithAppearances={mealMenu}
          mealMenuClosed={isMealMenuClosed(mealMenu)}
          athleteId={athleteId}
          cart={cart}
        />
      ) : loading && cart !== null ? (
        <Loading />
      ) : (
        // The user should never see this but adding just in case
        <Typography>Please Select a Meal Menu to order from. If no menu is orderable please use the Log tab.</Typography>
      )}
    </>
  );
};

export default TimelineMealModalOrderTab;

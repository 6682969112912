import { Box, SxProps } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import Loading from "../global/Loading";
import { ASPECT_16_9 } from "./utils";

interface ImageWithLockedAspectRatioProps {
  imageUrl: string;
  borderRadius?: string;
  onClick?: () => void;
  sx?: SxProps;
}

const Image = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  objectFit: "cover",
  display: "none",
}));

const ImageWithLockedAspectRatio = ({ imageUrl, onClick, borderRadius, sx }: ImageWithLockedAspectRatioProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [imageUrl]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        paddingTop: `${(1 / ASPECT_16_9) * 100}%`,
        height: 0,
        overflow: "hidden",
        borderRadius: borderRadius,
        ...sx,
      }}
      onClick={onClick}
    >
      {loading && <Loading progressSize="xs" />}
      <Image src={imageUrl} alt="img" onLoad={handleImageLoad} style={{ display: loading ? "none" : "block" }} />
    </Box>
  );
};

export default ImageWithLockedAspectRatio;

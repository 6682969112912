import { ApolloError } from "@apollo/client";

export const parseCompassImportPermissionError = (error: ApolloError): string | null => {
  const permissionError = error.graphQLErrors.filter(e => e.message.includes("not found"));
  if (permissionError.length > 0) {
    //this removes the "not found" text
    return permissionError[0].message.split(" ")[0];
  }
  return null;
};

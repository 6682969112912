import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import AllowSpecialRequestsInput from "./Input/AllowSpecialRequestsInput";
import AvailableForOrderInput from "./Input/AvailableForOrderInput";
import MaxAmountInput from "./Input/MaxAmountInput";

interface MenuItemOrderDetailsLabelProps {
  sx?: SxProps;
  maxAmount: number | null;
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
  onChangeMaxAmount: (maxAmount: number | null) => void;
  onChangeAvailableForOrder: (availableForOrder: boolean) => void;
  onChangeAllowSpecialRequests: (allowSpecialRequests: boolean) => void;
  variant: "MenuItem" | "MenuItemAppearance" | "RestaurantLinkMenuItemAppearance";
}

const MenuItemOrderDetails = ({
  sx,
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
  variant,
  onChangeMaxAmount,
  onChangeAvailableForOrder,
  onChangeAllowSpecialRequests,
}: MenuItemOrderDetailsLabelProps) => {
  const handleSetAvailableForOrder = (_availableForOrder: boolean) => {
    onChangeAvailableForOrder(_availableForOrder);
    if (!_availableForOrder) {
      onChangeAllowSpecialRequests(false);
    }
  };

  return (
    <>
      <Box sx={{ ...sx, display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h3"
          color="textSecondary"
          sx={{ mb: 1 }}>
          Order Details
        </Typography>
        <AvailableForOrderInput
          variant={variant}
          value={availableForOrder}
          onChange={handleSetAvailableForOrder} />
        <AllowSpecialRequestsInput
          disabled={!availableForOrder}
          variant={variant}
          value={allowSpecialRequests}
          onChange={onChangeAllowSpecialRequests}
        />
        <MaxAmountInput
          defaultLabel={variant === "MenuItem"}
          value={maxAmount}
          onChange={onChangeMaxAmount} />
      </Box>
    </>
  );
};

export default MenuItemOrderDetails;

import { MenuItem, SxProps, TextField } from "@mui/material";
import { formatTime, parseTime } from "@notemeal/shared/ui/utils/dateTimes";
import { addMinutes } from "date-fns";
import React from "react";
import { useMenusStyles } from "../styles";
import { getDurationOptions } from "./utils";

interface EndTimeSelectProps {
  value: number;
  startTime: string;
  onChange: (value: number) => void;
  sx?: SxProps;
}

export const EndTimeSelect = ({ value, onChange, startTime, sx }: EndTimeSelectProps) => {
  const menuClasses = useMenusStyles();
  const startDateTime = parseTime(startTime);

  return (
    <>
      <TextField
        select
        label="End"
        value={value}
        sx={sx}
        onChange={e => onChange(parseInt(e.target.value))}
        SelectProps={{
          classes: {
            select: menuClasses.selectRoot,
          },
          MenuProps: { sx: { maxHeight: "calc(100vh - 300px)" } },
        }}
      >
        {getDurationOptions(startDateTime, value).map(dur => {
          return (
            <MenuItem key={dur} value={dur}>
              {formatTime(addMinutes(startDateTime, dur), {
                alwaysShowMinutes: true,
              })}
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};

import React from "react";
import { RestaurantTypeFragment, useRestaurantTypeCursorConnectionQuery } from "../../../../types";
import { BasicOption } from "../../../universal/BaseAsyncAutocomplete";
import BaseFilter from "./BaseFilter/BaseFilterWithInfiniteScroll";
import { baseEdgesAreEqual, baseGetKey, baseGetLabel, getBaseTransformAndFilter } from "./BaseFilter/utils";

interface RestaurantTypeFilterProps {
  selectedRestaurantTypes: RestaurantTypeFragment[] | undefined;
  handleChange: (newValue: RestaurantTypeFragment[] | null) => void;
  disabled?: boolean;
}

const RestaurantTypeFilter = ({ selectedRestaurantTypes, handleChange, disabled = false }: RestaurantTypeFilterProps) => {
  const transformAndFilterDietOptions = getBaseTransformAndFilter(selectedRestaurantTypes ?? []);

  return (
    <BaseFilter
      getLabel={baseGetLabel}
      getKey={baseGetKey}
      filterLabel={"Restaurant Type"}
      selectedFilters={selectedRestaurantTypes ?? []}
      handleChange={filters => handleChange(filters.length === 0 ? null : filters)}
      disabled={disabled}
      queryKey="restaurantTypeCursorConnection"
      useCursorConnectionQuery={useRestaurantTypeCursorConnectionQuery}
      edgesAreEqual={baseEdgesAreEqual}
      renderOption={({ name }) => <BasicOption element={name} />}
      transformAndFilterOptions={transformAndFilterDietOptions}
    />
  );
};

export default RestaurantTypeFilter;

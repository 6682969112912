import AddIcon from "@mui/icons-material/Add";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import LoadingButton from "../../../../../components/universal/LoadingButton";
import { AdvancedSelectionState, assignReducer, validateCanSaveTooltips } from "../../../reducers/advancedSelectionReducers";
import { SelectAthletes } from "./SelectAthletes";
import ShareWithContent from "./ShareWithContent";
import TagGroup from "./TagGroup";

interface AssignContentProps {
  onCancel: () => void;
  onSave: (state: AdvancedSelectionState) => void;
  initialState: AdvancedSelectionState;
  loading?: boolean;
}

const AssignContent = ({ onCancel, onSave, loading = false, initialState }: AssignContentProps) => {
  const [state, dispatch] = useReducer(assignReducer, initialState);
  const canSaveTooltips = validateCanSaveTooltips(state);
  return (
    <>
      <DialogContent>
        <ShareWithContent state={state} dispatch={dispatch} />
        <Typography variant="h3">Groupings</Typography>
        {state.groups.map(group => (
          <TagGroup
            key={group.id}
            group={group}
            dispatch={dispatch} />
        ))}
        <Button
          startIcon={<AddIcon />}
          variant="text"
          onClick={() => dispatch({ type: "ADD_GROUP" })}>
          Add Grouping
        </Button>
        <SelectAthletes
          includeAthletes={state.includeAthletes}
          excludeAthletes={state.excludeAthletes}
          dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <LoadingButton
            onClick={canSaveTooltips.length > 0 ? () => {} : () => onSave(state)}
            loading={loading}
            buttonText="Save"
            disabled={canSaveTooltips.length > 0}
          />
        </TWItemizedTooltip>
      </DialogActions>
    </>
  );
};

export default AssignContent;

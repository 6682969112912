import React, { useReducer } from "react";
import Form from "../../../../components/ExchangeSet/Form";
import {
  ExchangeSetFormAction,
  ExchangeSetFormState,
  exchangeSetFormCantSaveTooltips,
  exchangeSetFormReducer,
  exchangeSetFormToCreateInput,
  exchangeSetFormToEditInput,
  exchangeSetToFormState,
  newExchangeSetFormState,
} from "../../../../components/ExchangeSet/Form/utils";
import {
  FullExchangeSetFragment,
  useCreateSharedExchangeSetMutation,
  useEditSharedExchangeSetMutation,
  useExchangeSetTableQuery,
} from "../../../../types";
import StaffContent from "../../../../views/Staff/GeneralizedStaffContent";
import MutationDialog from "../../../../views/Staff/GeneralizedStaffContent/MutationDialog";
import { UseFetchQueryArgs, UseFormReducer, UseMutation } from "../../../../views/Staff/GeneralizedStaffContent/types";

const headerRowInfo = [{ key: "name", displayName: "Name" }];

const convertObjToRowData = (exchangeSet: FullExchangeSetFragment) => {
  return {
    name: exchangeSet.name,
  };
};

const useFetchQuery = (query: UseFetchQueryArgs) => {
  const { data, loading } = useExchangeSetTableQuery(query);
  return {
    data: data?.sharedExchangeSetOffsetConnection,
    loading,
  };
};

const useEditMutation: UseMutation<FullExchangeSetFragment, ExchangeSetFormState, "Edit"> = ({ setErrorMsg, onClose, onSaveSuccess }) => {
  const [editSharedExchangeSet] = useEditSharedExchangeSetMutation({
    onCompleted: data => {
      onSaveSuccess(data.editSharedExchangeSet.exchangeSet);
      onClose();
    },
    onError: e => setErrorMsg(e.message),
  });
  return async ({ obj: exchangeSet, state }) => {
    await editSharedExchangeSet({
      variables: {
        input: exchangeSetFormToEditInput({
          ...state,
          id: exchangeSet.id,
        }),
      },
    });
  };
};

const useCreateMutation: UseMutation<FullExchangeSetFragment, ExchangeSetFormState, "Create"> = ({
  setErrorMsg,
  onClose,
  onSaveSuccess,
}) => {
  const [createSharedExchangeSet] = useCreateSharedExchangeSetMutation({
    onCompleted: data => {
      onSaveSuccess(data.createSharedExchangeSet.exchangeSet);
      onClose();
    },
    onError: e => setErrorMsg(e.message),
  });
  return async ({ state }) => {
    const input = exchangeSetFormToCreateInput(state);
    await createSharedExchangeSet({
      variables: {
        input,
      },
    });
  };
};

const useCreateFormReducer: UseFormReducer<FullExchangeSetFragment, ExchangeSetFormState, ExchangeSetFormAction, "Edit"> = exchangeSet => {
  return useReducer(exchangeSetFormReducer, newExchangeSetFormState());
};

const useEditFormReducer: UseFormReducer<FullExchangeSetFragment, ExchangeSetFormState, ExchangeSetFormAction, "Edit"> = exchangeSet => {
  return useReducer(exchangeSetFormReducer, exchangeSetToFormState(exchangeSet));
};

export const ExchangeSetsPage = () => {
  return (
    <StaffContent
      headerRowInfo={headerRowInfo}
      convertObjToRowData={convertObjToRowData}
      queryCacheKey="sharedExchangeSetOffsetConnection"
      useFetchQuery={useFetchQuery}
      entityName="Exchange Set"
      getObjDisplay={es => es.id}
      renderEditDialog={({ open, onClose, onEditSuccess, initialEditInfo }) => (
        <MutationDialog
          maxWidth="xl"
          open={open}
          onClose={onClose}
          onSaveSuccess={onEditSuccess}
          obj={initialEditInfo}
          renderForm={({ state, dispatch }) => <Form state={state} dispatch={dispatch} />}
          useFormReducer={useEditFormReducer}
          useMutation={useEditMutation}
          buildFormToolTips={exchangeSetFormCantSaveTooltips}
          dialogTitle="Edit Exchange Set"
        />
      )}
      renderCreateDialog={({ open, onClose, onCreateSuccess }) => (
        <MutationDialog
          maxWidth="xl"
          open={open}
          onClose={onClose}
          onSaveSuccess={onCreateSuccess}
          obj={undefined}
          renderForm={({ state, dispatch }) => <Form state={state} dispatch={dispatch} />}
          useFormReducer={useCreateFormReducer}
          useMutation={useCreateMutation}
          buildFormToolTips={exchangeSetFormCantSaveTooltips}
          dialogTitle="Create Exchange Set"
        />
      )}
    />
  );
};

import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { getNavStaffOrgPath } from "apps/web/src/pages/Auth/Staff/Org/OrgRouter";
import { formatDistanceToNow } from "date-fns";
import { enUS } from "date-fns/locale";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  IntegrationType,
  StaffDashboardOrgPreviewFragment,
  useOrgsStatusSyncQuery,
  useRefreshTeamworksSyncStatusMutation,
} from "../../../types";

const useStyles = makeStyles(({ palette: { greyscale } }: Theme) => ({
  wrapper: {
    overflowY: "auto",
    maxHeight: "inherit",
    height: "calc(100vh - 176px)",
  },
}));

interface OrgsTableProps {
  orgs: StaffDashboardOrgPreviewFragment[];
  onClickRow: (o: StaffDashboardOrgPreviewFragment) => void;
}

const OrgsTable = ({ orgs, onClickRow }: OrgsTableProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const BONAPPETIT: IntegrationType = "bonAppetit";
  const CBORD: IntegrationType = "cbord";
  const COMPASS: IntegrationType = "compass";

  const [refreshingOrgIds, setRefreshingOrgIds] = useState<string[]>([]);
  const { data, loading } = useOrgsStatusSyncQuery();
  const [refreshSyncStatus] = useRefreshTeamworksSyncStatusMutation({
    onCompleted: data => {
      setRefreshingOrgIds(ids => ids.filter(id => id !== data.refreshTeamworksSyncStatus.org.id));
    },
  });

  if (!data || loading) {
    return <Loading progressSize="lg" />;
  }

  return (
    <Paper elevation={5}>
      <TableContainer className={classes.wrapper}>
        <Table size="small" aria-label="Organizations">
          <TableHead>
            <TableRow>
              <TableCell>Edit</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Locale</TableCell>
              <TableCell>Territory</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Public Access</TableCell>
              <TableCell>Teamworks</TableCell>
              <TableCell>Notemeal Linked</TableCell>
              <TableCell>Teamworks Linked</TableCell>
              <TableCell>Fully Synced</TableCell>
              <TableCell>Compass</TableCell>
              <TableCell>CBORD</TableCell>
              <TableCell>Bon Appetit</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell>Refresh Sync Status</TableCell>
              <TableCell>Agreement Form</TableCell>
              <TableCell>Scoring System</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgs.map(org => {
              const compassIntegration = !!org.integrations.find(i => i.type === COMPASS);
              const cbordIntegration = !!org.integrations.find(i => i.type === CBORD);
              const bonAppetitIntegration = !!org.integrations.find(i => i.type === BONAPPETIT);
              const cacheOrgData = data.orgs.find(cacheOrg => cacheOrg.id === org.id);
              let teamworksSyncStatus = cacheOrgData?.teamworksSyncStatus;

              return (
                <TableRow
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "greyscale.100",
                    },
                  }}
                  onClick={() => navigate(getNavStaffOrgPath(org.id))}
                  key={org.id}
                >
                  <TableCell scope="row">
                    <IconButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClickRow(org);
                      }}
                      size="large"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell scope="row">{org.name}</TableCell>
                  <TableCell>{org.localeCode}</TableCell>
                  <TableCell>{org.territoryName}</TableCell>
                  <TableCell>{org.isActive && "✓"}</TableCell>
                  <TableCell>{org.allowsPublicAccess && "✓"}</TableCell>
                  <TableCell>{org.teamworksId !== null && "✓"}</TableCell>
                  <TableCell>{org.isNotemealLinked && "✓"}</TableCell>
                  <TableCell>{teamworksSyncStatus && teamworksSyncStatus.isTeamworksLinked && "✓"}</TableCell>
                  <TableCell>{teamworksSyncStatus && teamworksSyncStatus.isFullySynced && "✓"}</TableCell>
                  <TableCell>{compassIntegration && "✓"}</TableCell>
                  <TableCell>{cbordIntegration && "✓"}</TableCell>
                  <TableCell>{bonAppetitIntegration && "✓"}</TableCell>
                  <TableCell>
                    {/* TODO: Locale Cleanup Staff page */}
                    {teamworksSyncStatus && formatDistanceToNow(new Date(teamworksSyncStatus.updatedAt), { addSuffix: true, locale: enUS })}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (refreshingOrgIds.includes(org.id)) {
                          return;
                        }
                        setRefreshingOrgIds(ids => [...ids, org.id]);
                        refreshSyncStatus({
                          variables: {
                            input: {
                              orgId: org.id,
                            },
                          },
                        });
                      }}
                    >
                      {refreshingOrgIds.includes(org.id) ? <Loading progressSize="xs" /> : <RefreshIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{org.agreementForm && "✓"}</TableCell>
                  <TableCell>{org.scoringSystem?.name && "✓"}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OrgsTable;

import { Workbook, Worksheet } from "exceljs";
import saveAs from "file-saver";
import {
  filterAndJoin,
  getIndexArray,
  getMenuItemsMaxLength,
  getOrderExportDetails,
  GroupedMappedChoice,
  MappedOption,
  MenuItemRowInfoWithAmount,
  OrderRowDataInfo,
  PlateRowDataInfo,
  SerializedMenuItem,
  CoverSheetInfo,
} from ".";

interface GetOrderExportRowArgs {
  orderRowDataInfo: OrderRowDataInfo;
  length: number;
}

interface GetPlateExportRowArgs {
  plateRowDataInfo: PlateRowDataInfo;
  length: number;
}

export const stringifyMappedOption = (mappedOption: MappedOption) => {
  const optionsAmount = mappedOption.canEditAmount ? ` (${mappedOption.amount}x)` : "";
  return `${mappedOption.menuItemChoiceOption.name}${optionsAmount}`;
};

export const getOptionsExportCell = (mappedOptions: readonly MappedOption[]): string => {
  return filterAndJoin(mappedOptions.map(stringifyMappedOption), ", ");
};

export const stringifyGroupedMappedChoice = (mappedChoice: GroupedMappedChoice) => {
  if (mappedChoice.options.length === 0) {
    return "";
  }
  return `${mappedChoice.menuItemChoice.name}: ${getOptionsExportCell(mappedChoice.options)}`;
};

export const getChoicesExportCell = (mappedChoices: readonly GroupedMappedChoice[]): string => {
  return filterAndJoin(mappedChoices.map(stringifyGroupedMappedChoice), "\n");
};

export const getMenuItemExportCell = (menuRowInfo: MenuItemRowInfoWithAmount): string => {
  const choices = getChoicesExportCell(menuRowInfo.mappedChoices);
  return filterAndJoin([`${menuRowInfo.name} (${menuRowInfo.amount}x)`, choices, menuRowInfo.specialRequests], "\n\n");
};

export const getMenuItemExportHeaderRow = () => ["Amount", "Name", "Options", "Special Requests"];

export const getMenuItemExportRow = ({ amount: _amount, menuItemRowInfo }: SerializedMenuItem): string[] => {
  const { name, mappedChoices, specialRequests: _specialRequests } = menuItemRowInfo;
  const amount = _amount.toString();
  const options = getChoicesExportCell(mappedChoices);
  const specialRequests = _specialRequests ?? "";
  return [amount, name, options, specialRequests];
};

export const getMenuItemExportSheetRows = (serializedMenuItems: SerializedMenuItem[]): string[][] => {
  const headerRow = getMenuItemExportHeaderRow();
  const menuItemRows = serializedMenuItems.map(getMenuItemExportRow);
  return [headerRow, ...menuItemRows];
};

export const getMenuSelectionMenuItemHeaderRowCells = (length: number): string[] => {
  const indexArray = getIndexArray(length);
  return indexArray.map(index => `Menu Item ${index + 1}`);
};

export const getOrderExportHeaderRow = (length: number): string[] => {
  return ["Athlete Name", ...getMenuSelectionMenuItemHeaderRowCells(length)];
};

export const getPaddingCells = (length: number): string[] => {
  const indexArray = getIndexArray(length);
  return indexArray.map(() => "");
};

export const getMenuSelectionMenuItemExportCells = (menuItems: readonly MenuItemRowInfoWithAmount[], length: number): string[] => {
  const menuItemExportCells = menuItems.map(getMenuItemExportCell);

  const remainder = length - menuItemExportCells.length;
  const paddingCells = getPaddingCells(remainder);

  return [...menuItemExportCells, ...paddingCells];
};

export const getOrderExportRow = ({ orderRowDataInfo: { athleteName, menuItems }, length }: GetOrderExportRowArgs): string[] => {
  const menuItemExportCells = getMenuSelectionMenuItemExportCells(menuItems, length);
  return [athleteName, ...menuItemExportCells];
};

export const getOrderExportSheetRows = (orderRowInfoArray: OrderRowDataInfo[]): string[][] => {
  const maxLength = getMenuItemsMaxLength(orderRowInfoArray);
  const headerRow = getOrderExportHeaderRow(maxLength);
  const orderRows = orderRowInfoArray.map(orderRowDataInfo => getOrderExportRow({ orderRowDataInfo, length: maxLength }));
  return [headerRow, ...orderRows];
};

export const getPlateExportHeaderRow = (length: number): string[] => {
  return ["Amount", ...getMenuSelectionMenuItemHeaderRowCells(length)];
};

export const getPlateExportRow = ({ plateRowDataInfo: { amount: _amount, menuItems }, length }: GetPlateExportRowArgs) => {
  const amount = _amount.toString();
  const menuItemExportCells = getMenuSelectionMenuItemExportCells(menuItems, length);
  return [amount, ...menuItemExportCells];
};

export const getPlateExportSheetRows = (plateRowInfoArray: PlateRowDataInfo[]): string[][] => {
  const maxLength = getMenuItemsMaxLength(plateRowInfoArray);
  const headerRow = getPlateExportHeaderRow(maxLength);
  const plateRows = plateRowInfoArray.map(plateRowDataInfo => getPlateExportRow({ plateRowDataInfo, length: maxLength }));
  return [headerRow, ...plateRows];
};

interface ExportWorkbookArgs {
  ordersDescriptionInfo: CoverSheetInfo;
  orderRowInfoArray: OrderRowDataInfo[];
  plateRowInfoArray: PlateRowDataInfo[];
  serializedMenuItems: SerializedMenuItem[];
}

const COLUMN_PADDING = 2;

const autoSizeColumns = (worksheet: Worksheet): void => {
  worksheet.columns.forEach(column => {
    const columnLengths: number[] =
      column?.values?.flatMap(value => {
        if (!value) {
          return [];
        }
        return value
          .toString()
          .split("\n")
          .map(cellLine => cellLine.length);
      }) ?? [];
    const maxLength = Math.max(...columnLengths, 0);
    column.width = maxLength + COLUMN_PADDING;
  });
};

const addRowsToWorksheet = (rows: string[][], worksheet: Worksheet): void => {
  rows.forEach(row => {
    worksheet.addRow(row);
  });
  autoSizeColumns(worksheet);
};

export const buildWorkbook = ({
  ordersDescriptionInfo,
  orderRowInfoArray,
  plateRowInfoArray,
  serializedMenuItems,
}: ExportWorkbookArgs): Workbook => {
  const workbook = new Workbook();
  const infoSheet = workbook.addWorksheet("Info");
  const ordersSheet = workbook.addWorksheet("Orders by Athlete");
  const platesSheet = workbook.addWorksheet("Plates");
  const menuItemsSheet = workbook.addWorksheet("Orders And Plates By Menu Item");

  const ordersDescriptionInfoRows = getOrderExportDetails(ordersDescriptionInfo.deliveryLocation, ordersDescriptionInfo.instructions);
  addRowsToWorksheet(ordersDescriptionInfoRows, infoSheet);

  const orderRows = getOrderExportSheetRows(orderRowInfoArray);
  addRowsToWorksheet(orderRows, ordersSheet);

  const plateRows = getPlateExportSheetRows(plateRowInfoArray);
  addRowsToWorksheet(plateRows, platesSheet);

  const menuItemRows = getMenuItemExportSheetRows(serializedMenuItems);
  addRowsToWorksheet(menuItemRows, menuItemsSheet);

  return workbook;
};

export const saveWorkbook = async (workbook: Workbook, fileName: string) => {
  saveAs(new Blob([await workbook.xlsx.writeBuffer()]), fileName);
};

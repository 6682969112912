import { Box, FormLabel, SxProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      minWidth: theme.spacing(30),
    },
    innerContainer: {
      padding: theme.spacing(1.5, 1, 1, 1),
      borderRadius: 4,
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
    label: {
      transform: `translate(${theme.spacing(1)}, -6px) scale(0.75)`,
      zIndex: 1,
      pointerEvents: "none",
      transformOrigin: "top left",
      top: 0,
      left: 0,
      position: "absolute",
      display: "block",
      background: "#FFFFFF",
      padding: theme.spacing(0, 0.5),
    },
  })
);

interface TextFieldWrapperProps {
  children: React.ReactNode;
  label?: string;
  className?: string;
  sx?: SxProps;
}

const TextFieldWrapper = ({ children, label, className, sx }: TextFieldWrapperProps) => {
  const classes = useStyles();
  return (
    <Box className={classNames(classes.container, className)} sx={sx}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <div className={classes.innerContainer}>{children}</div>
    </Box>
  );
};

export default TextFieldWrapper;

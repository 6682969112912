import { AthleteGoalConfigurationForCompliancePageFragment, GoalTrackerGoalForComplianceFragment } from "apps/web/src/types";

export const getRowDataFromGoalForTable = (
  goal: GoalTrackerGoalForComplianceFragment,
  goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment,
  formatDateWithLocale: (date: string) => string,
  formatTimeInTimezoneWithLocale: (time: string, timezone: string) => string
) => {
  const { id, date, completedAtInUTC, value } = goal;
  const { target, timezone } = goalConfiguration;
  const hasTarget = target !== null;

  return {
    id,
    date: formatDateWithLocale(date),
    completedTime: completedAtInUTC && formatTimeInTimezoneWithLocale(completedAtInUTC, timezone),
    completed: isGoalComplete(goal, goalConfiguration) ? "Y" : "N",
    target: hasTarget ? target.value : null,
    value,
    targetUnit: hasTarget ? target.unit : null,
  };
};

const isGoalComplete = (
  goal: GoalTrackerGoalForComplianceFragment,
  goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment
) => {
  const { value } = goal;
  const { target } = goalConfiguration;
  if (target) {
    if (value) {
      return value >= target.value;
    } else {
      return false;
    }
  } else {
    return Boolean(value);
  }
};

export const getRowDataFromGoalForExport = (
  goal: GoalTrackerGoalForComplianceFragment,
  goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment,
  formatDateWithLocale: (date: string) => string,
  formatTimeInTimezoneWithLocale: (time: string, timezone: string) => string
): SerializedExportRow => {
  const { date, completedAtInUTC, value } = goal;
  const { target, timezone } = goalConfiguration;

  const completeGoal = isGoalComplete(goal, goalConfiguration);

  return {
    category: goalConfiguration.category.name,
    task: goalConfiguration.name,
    date: formatDateWithLocale(date),
    completion: completeGoal ? "Y" : "N",
    time: completedAtInUTC ? formatTimeInTimezoneWithLocale(completedAtInUTC, timezone) : "",
    input: value ? String(value) : "0",
    target: target ? String(target.value) : "",
    unit: target ? target.unit : "",
  };
};

type ExcelExportHeader = "Category" | "Task" | "Date" | "Time" | "Completion (Y/N)" | "Target" | "Input" | "Unit";
export interface SerializedExportRow {
  category: string;
  task: string;
  date: string;
  time: string;
  completion: string;
  target: string;
  input: string;
  unit: string;
}

interface ExportColumnDef {
  header: ExcelExportHeader;
  key: keyof SerializedExportRow;
  width: number;
}

export const exportAthleteGoalColumns: ExportColumnDef[] = [
  { header: "Category", key: "category", width: 20 },
  { header: "Task", key: "task", width: 25 },
  { header: "Date", key: "date", width: 15 },
  { header: "Time", key: "time", width: 15 },
  { header: "Completion (Y/N)", key: "completion", width: 15 },
  { header: "Target", key: "target", width: 7 },
  { header: "Input", key: "input", width: 7 },
  { header: "Unit", key: "unit", width: 15 },
];

export const serializeAthleteGoalRows = (
  goals: GoalTrackerGoalForComplianceFragment[],
  goalConfiguration: AthleteGoalConfigurationForCompliancePageFragment,
  formatDateWithLocale: (date: string) => string,
  formatTimeInTimezoneWithLocale: (time: string, timezone: string) => string
): SerializedExportRow[] => {
  return goals.map(goal => getRowDataFromGoalForExport(goal, goalConfiguration, formatDateWithLocale, formatTimeInTimezoneWithLocale));
};

import { IFileImport } from "../../../../universal/Import/Button";
import { loadTsv } from "../../../../../utils/import/load";
import { parseDelimitedFile } from "../../../../../utils/import/parse";
import { ImportableAnthroEntry } from "../types";
import { subYears } from "date-fns";
import { parseMassField, parsePercentField } from "./utils";

interface AllIdxaIDxaFileImportFields {
  "Last Name": string;
  "First Name": string;
  "Dept ID": string;
  "Most Recent Measurement Date": string;
  "Measure Date": string;
  Height: string;
  "Total Bone Mass": number;
  "Total Fat Mass": number;
  "Total Lean Mass": number;
  "Total Total Mass": number;
  "Total Tissue %Fat": number;
}

type AllIdxaFieldsRaw = { [key in keyof AllIdxaIDxaFileImportFields]: string };

export const iDXAMatchFields: (keyof ImportableAnthroEntry)[] = ["firstName" as const, "lastName" as const, "datetime" as const];

export const msg = `
  Expects an exported ".txt" file from the GE Lunar iDXA machine, composed of all historical athlete records. The file may be named "export1_Total Body Comp.txt".
`;

type I = ImportableAnthroEntry;

const massFields = [
  {
    fields: ["Total Fat Mass" as const],
    required: true,
    importableField: "bodyFatMass" as const,
    transform: parseMassField,
  },
  {
    fields: ["Total Lean Mass" as const],
    required: true,
    importableField: "leanBodyMass" as const,
    transform: parseMassField,
  },
  {
    fields: ["Total Total Mass" as const],
    required: true,
    importableField: "weight" as const,
    transform: parseMassField,
  },
  {
    fields: ["Total Bone Mass" as const],
    required: true,
    importableField: "boneMass" as const,
    transform: parseMassField,
  },
];
const numberFields = [
  ...massFields,
  { fields: ["Height" as const], importableField: "height" as const },
  {
    fields: ["Total Tissue %Fat" as const],
    required: true,
    importableField: "percentBodyFat" as const,
    transform: parsePercentField,
  },
];
const stringFields = [
  {
    fields: ["Last Name" as const],
    required: true,
    importableField: "lastName" as const,
  },
  {
    fields: ["First Name" as const],
    required: true,
    importableField: "firstName" as const,
  },
  // {field: 'Dept ID' as const, importableField: 'teamName' as const},
]; // More on 'as const': https://stackoverflow.com/a/55387357/3923962

const dateFields = [
  {
    fields: ["Measure Date" as const, "Most Recent Measurement Date" as const],
    required: true,
    importableField: "datetime" as const,
  },
];
export const fields = [...numberFields, ...stringFields, ...dateFields];
export const loadAllIdxaRows = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  // type T = AllIdxaIDxaFileImportFields;
  type F = AllIdxaFieldsRaw;
  const rows = await loadTsv<F>({ file, onError });
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return parsedRows
    .filter(row => new Date(row.datetime) > subYears(new Date(), 5))
    .map(row => ({
      ...row,
      type: "dexa",
    }));
};

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, IconButton, SxProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    amount: {
      minWidth: 24,
      textAlign: "center",
      padding: theme.spacing(0, 1),
    },
    iconButton: {},
  })
);

interface ItemAmountButtonsProps {
  amount?: number | null;
  text?: string;
  minAmount?: number | null;
  maxAmount?: number | null;
  onDecrement: () => void;
  onIncrement: () => void;
  className?: string;
  sx?: SxProps;
}

const ItemAmountButtons = ({ amount, text, minAmount, maxAmount, onDecrement, onIncrement, className, sx }: ItemAmountButtonsProps) => {
  const classes = useStyles();

  return amount ? (
    <Box
      slot="start"
      className={classnames(classes.root, className)}
      sx={sx}>
      <IconButton
        sx={{ color: "common.black" }}
        disabled={Boolean(minAmount && amount <= minAmount)}
        onClick={onDecrement}>
        <RemoveCircleIcon fontSize="large" />
      </IconButton>
      <Typography className={classes.amount}>
        {amount}
        {text}
      </Typography>
      <IconButton
        sx={{ color: "common.black" }}
        disabled={Boolean(maxAmount && amount >= maxAmount)}
        onClick={onIncrement}>
        <AddCircleIcon fontSize="large" />
      </IconButton>
    </Box>
  ) : (
    <div slot="start" className={classnames(classes.root, className)}>
      <IconButton sx={{ color: "common.black" }} onClick={onIncrement}>
        <AddCircleIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

export default ItemAmountButtons;

import { ApolloClient, useApolloClient } from "@apollo/client";
import { useState, useEffect } from "react";
import {
  NutritionixV1RestaurantSearchInput,
  NutritionixV1RestaurantSearchPayload,
  NutritionixV1RestaurantSearchDocument,
  NutritionixV1RestaurantSearchQuery,
  NutritionixV1RestaurantSearchQueryVariables,
} from "../../../types";
import { getLocalStorageValue } from "apps/web/src/utils/localStorage";
import { String } from "runtypes";

interface UseNutritionixRestaurantFoodsPayload {
  data: NutritionixV1RestaurantSearchPayload;
  loading: boolean;
  error: string | null;
}

interface BodyParams {
  offset: number;
  limit: number;
}

const MAX_QUERY_SIZE = 50;

const getBuildBodyData = (brandId: string) => {
  const nixAppId = getLocalStorageValue("nixAppId", String);
  const nixAppKey = getLocalStorageValue("nixAppKey", String);

  if (!nixAppId || !nixAppKey) {
    throw new Error("Can't fetch nutritionix foods w/o nixAppId/key!");
  }

  return ({ offset, limit }: BodyParams): NutritionixV1RestaurantSearchInput => ({
    appId: nixAppId,
    appKey: nixAppKey,
    fields: ["item_name", "brand_name", "item_id", "brand_id", "item_type"],
    filters: {
      brand_id: brandId,
      item_type: 1,
    },
    offset,
    limit,
    sort: {
      field: "item_name.sortable_na",
      order: "asc",
    },
  });
};

const fetchAndParseData = async (
  apolloClient: ApolloClient<any>,
  input: NutritionixV1RestaurantSearchInput
): Promise<NutritionixV1RestaurantSearchPayload> => {
  const result = await apolloClient.query<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>({
    query: NutritionixV1RestaurantSearchDocument,
    variables: { input },
  });
  return result.data.nutritionixV1RestaurantSearch;
};

const getNeededQueries = (total: number): number => {
  return Math.ceil((total - MAX_QUERY_SIZE) / MAX_QUERY_SIZE);
};

const getOffsetLimitArray = (neededQueries: number): BodyParams[] => {
  const baseArray = Array.from(new Array(neededQueries), (_, i) => i + 1);
  const offsetLimitArray = baseArray.map(multiplier => ({
    offset: multiplier * MAX_QUERY_SIZE,
    limit: MAX_QUERY_SIZE,
  }));
  return offsetLimitArray;
};

// TODO: We should add cacheing to this.
export const useNutritionixRestaurantFoods = (nutritionixRestaurantId: string | null): UseNutritionixRestaurantFoodsPayload => {
  const [data, setData] = useState<NutritionixV1RestaurantSearchPayload>({
    total: 0,
    branded: [],
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const apolloClient = useApolloClient();

  const fetchData = async (brandId: string) => {
    try {
      const buildBodyData = getBuildBodyData(brandId);
      const baseResults = await fetchAndParseData(apolloClient, buildBodyData({ offset: 0, limit: MAX_QUERY_SIZE }));
      const { total } = baseResults;
      const neededQueries = getNeededQueries(total);
      if (total <= MAX_QUERY_SIZE) {
        setData(baseResults);
      } else if (neededQueries > 20) {
        setError("Restaurant Menu is too large to successfully display");
      } else {
        const offsetLimitArray = getOffsetLimitArray(neededQueries);
        const results = await Promise.all(offsetLimitArray.map(params => fetchAndParseData(apolloClient, buildBodyData(params))));

        const branded = [...baseResults.branded, ...results.flatMap(result => result.branded)];
        setData({
          total,
          branded,
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("Something went wrong!");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (nutritionixRestaurantId === null) {
      setData({
        total: 0,
        branded: [],
      });
      setLoading(false);
    } else {
      fetchData(nutritionixRestaurantId);
    }
  }, [nutritionixRestaurantId]);

  return { data, error, loading };
};

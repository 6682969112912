import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { Box, Switch, Typography, useTheme } from "@mui/material";
import React, { Dispatch } from "react";
import Calendar from "../Content/Calendar/Form/DateAssignment/Calendar";
import ModeForm from "../Content/Calendar/Form/DateAssignment/ModeForm";
import { MealPlanDateAssignmentAction } from "../Content/Calendar/Form/reducer";
import { MealPlanDateAssignment } from "../Content/Calendar/Form/types";
import { useMealPlanCalendarContext } from "../contexts/Calendar";

interface MealPlanCalendarDateAssignmentProps {
  state: MealPlanDateAssignment;
  isAutoSuggestionsEnabled: boolean;
  warning?: string;
  dispatch: Dispatch<MealPlanDateAssignmentAction>;
  setIsAutoSuggestionsEnabled: (value: boolean) => void;
}

const MealPlanCalendarDateAssignment = ({
  state,
  isAutoSuggestionsEnabled,
  warning,
  dispatch,
  setIsAutoSuggestionsEnabled,
}: MealPlanCalendarDateAssignmentProps) => {
  const {
    palette: { info, grey },
  } = useTheme();
  const { onChangeDate } = useMealPlanCalendarContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-around", pt: 2 }}>
      {warning && (
        <Box sx={{ display: "flex", justifyContent: "space-around", pb: 5, width: "100%" }}>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "920px",
              minHeight: "51px",
              background: grey[100],
            }}
          >
            {warning}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Box sx={{ width: "50%", height: "100%", minHeight: "500px", minWidth: "400px", display: "flex", justifyContent: "space-around" }}>
          <Calendar
            state={state}
            dispatch={dispatch}
            onClickCalendarDate={onChangeDate}
            otherMealPlanDateAssignments={[]}
            mealPlanColor={info.main}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            minHeight: "400px",
            minWidth: "400px",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", height: "70%", minHeight: "250px" }}>
            <Typography variant="body1Medium" sx={{ pb: 1 }}>
              Date Assignment
            </Typography>
            <ModeForm
              state={state}
              dispatch={dispatch}
              calendarLocation="on left"
              startDateInPastBehavior="error" />
          </Box>
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, pb: 1 }}>
              <AutoFixHighOutlinedIcon sx={{ width: 20, height: 20 }} />
              <Typography variant="body1Medium">Time Suggestions</Typography>
            </Box>
            <Box
              sx={{
                p: 1,
                backgroundColor: "grey.100",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "50%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography variant="body1Semibold">Auto-accept all suggestions</Typography>
                <Typography variant="subtitle1">For meals on all applicable days/weeks</Typography>
              </Box>
              <Switch checked={isAutoSuggestionsEnabled} onChange={() => setIsAutoSuggestionsEnabled(!isAutoSuggestionsEnabled)} />
            </Box>
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default MealPlanCalendarDateAssignment;

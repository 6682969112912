import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getDateFnsLocaleForCode } from "@notemeal/locale/utils";
import { useLocaleContext } from "libs/shared/ui/src/lib/contexts/Locale/LocaleContext";

import React, { ReactNode } from "react";

export const MuiLocalizationProvider = ({ children }: { children: ReactNode }) => {
  const { locale } = useLocaleContext();
  const dateFnsLocale = getDateFnsLocaleForCode(locale);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
      {children}
    </LocalizationProvider>
  );
};

import CheckIcon from "@mui/icons-material/Check";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import WarningIcon from "@mui/icons-material/Warning";
import { TableCell, TableRow, TableSortLabel, Theme, Tooltip, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { MenuAttendanceRowInput } from "../../pages/Auth/Org/MenuAttendance/utils";

const TOOL_TIP_ALERT_UNMATCHED = "Unable to match athlete from Teamworks Hub";
const TOOL_TIP_ALERT_NO_ACCESS = "Meal is not shared with athlete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
    },
    headerCell: { padding: theme.spacing(0.5) },
    paleGrey: {
      opacity: theme.palette.action.disabledOpacity,
    },
    noWrap: { whiteSpace: "nowrap" },
    lastChild: { "&:last-child": { marginRight: theme.spacing(0) } },
    warningIcon: {
      color: theme.palette.warning.main,
      paddingLeft: 1,
      paddingTop: theme.spacing(0.5),
      fontSize: "18px",
    },
    personOffIcon: {
      color: "red",
      paddingLeft: 1,
      paddingTop: theme.spacing(0.5),
      fontSize: "18px",
    },
  })
);

export const MealMenuAttendanceTableRow = ({ input }: { input: MenuAttendanceRowInput }) => {
  const classes = useStyles();
  const {
    palette: { error, warning },
  } = useTheme();
  const { fullName, teamName, position, checkedIn, checkInDisplayDate, unlisted, hasAccess } = input;

  const errorRow = !unlisted && !hasAccess;
  const warningRow = unlisted;

  return (
    <TableRow sx={{ backgroundColor: errorRow ? error.light : warningRow ? warning.light : undefined }}>
      <TableCell>
        {fullName}
        {warningRow && (
          <Tooltip title={TOOL_TIP_ALERT_UNMATCHED}>
            <WarningIcon classes={{ root: classes.warningIcon }} fontSize="medium" />
          </Tooltip>
        )}
        {errorRow && (
          <Tooltip title={TOOL_TIP_ALERT_NO_ACCESS}>
            <PersonOffIcon classes={{ root: classes.personOffIcon }} fontSize="medium" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{teamName}</TableCell>
      <TableCell>{position}</TableCell>
      <TableCell align="left">{checkedIn && <CheckIcon />}</TableCell>
      <TableCell align="left">{checkInDisplayDate}</TableCell>
    </TableRow>
  );
};

interface MealMenuAttendanceTableHeaderRowProps {
  sortOrderAscending: boolean | null;
  onChangeSortOrder: () => void;
}

export const MealMenuAttendanceTableHeaderRow = ({ sortOrderAscending, onChangeSortOrder }: MealMenuAttendanceTableHeaderRowProps) => {
  const sortDirection = sortOrderAscending ? "asc" : "desc";

  return (
    <TableRow>
      <TableCell>Athlete Name</TableCell>
      <TableCell>Team</TableCell>
      <TableCell>Position</TableCell>
      <TableCell align="left">Checked In?</TableCell>
      <TableCell align="left">
        <TableSortLabel direction={sortDirection} onClick={() => onChangeSortOrder()}>
          Check-in Time
        </TableSortLabel>
      </TableCell>
    </TableRow>
  );
};

import React from "react";
import DialogContent from "./Content";
import { useMealMenuIngredientsAnalyzerQuery } from "../../../../types";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";

interface MenuIngredientsAnalyzerDialogProps {
  mealMenuId: string;
  onClose: () => void;
  open: boolean;
}

const MenuIngredientsAnalyzerDialog = ({ mealMenuId, onClose, open }: MenuIngredientsAnalyzerDialogProps) => {
  const { data } = useMealMenuIngredientsAnalyzerQuery({
    variables: { mealMenuId },
  });

  return !data ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <DialogContent
      open={open}
      onClose={onClose}
      mealMenu={data.mealMenu} />
  );
};

export default MenuIngredientsAnalyzerDialog;

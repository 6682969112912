import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, FormControlLabel, IconButton, Switch, Typography } from "@mui/material";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import { NAV_ORG_RECIPE } from "apps/web/src/pages/Auth/Org/FoodManagement/FoodManagementPaths";
import { NAV_STAFF_RECIPE } from "apps/web/src/pages/Auth/Staff/FoodManagement/FoodManagementRouter";
import React from "react";
import { useLocation } from "react-router-dom-v5-compat";

interface RecipeHeaderProps {
  handleClose: () => void;
  onChange: () => void;
  handleSave: () => void;
  canSaveTooltips: string[];
  forStaff?: boolean;
  isShared: boolean;
}

export const CreateRecipeHeader = ({ handleClose, onChange, handleSave, canSaveTooltips, forStaff, isShared }: RecipeHeaderProps) => {
  const location = useLocation();

  const isPageView = location.pathname.includes(NAV_ORG_RECIPE) || location.pathname.includes(NAV_STAFF_RECIPE);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2, overflowY: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"h2"}>Create Recipe</Typography>
        {!isPageView && (
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        )}
        {isPageView && (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <FormControlLabel
              label={forStaff ? "Share with orgs" : "Share with athletes"}
              labelPlacement="end"
              checked={isShared}
              onChange={onChange}
              control={<Switch sx={{ ml: 1.5, mr: 1 }} />}
            />
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
              <Button onClick={handleSave}>Save</Button>
            </TWItemizedTooltip>
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

import { useEffect } from "react";
import {
  SportWithPositionMappingsFragment,
  OrgForLinkingFragment,
  TeamworksGroupFragment,
  TeamworksOrgFragment,
  TeamworksProfileFragment,
  TeamworksPositionLinkFragment,
  useTeamworksPositionLinksQuery,
  useOrgForLinkingQuery,
  useTeamworksOrgQuery,
  useTeamworksProfileCursorConnectionQuery,
  useSportsWithPositionMappingsQuery,
} from "../../../../types";
import { useTeamworksDisabledProfileIdsQuery } from "../../../../types";

interface useDataArgs {
  teamworksOrgId: number;
  orgId: string;
  // Add optional pre-fetched data, that will get returned and skip those queries
}

// interface useDataPayload_Error {
//   __type: "error";
// }

interface useDataPayload_Loading {
  __type: "loading";
}

interface useDataPayload_Done {
  __type: "done";
  org: OrgForLinkingFragment;
  sports: readonly SportWithPositionMappingsFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
  allTeamworksProfiles: readonly TeamworksProfileFragment[];
  teamworksOrg: TeamworksOrgFragment;
  teamworksUserTypes: readonly TeamworksGroupFragment[];
  teamworksAthleteStatuses: readonly TeamworksGroupFragment[];
  teamworksPositions: readonly TeamworksGroupFragment[];
  disabledProfileIds: readonly number[] | null;
}

type useDataPayload = useDataPayload_Loading | useDataPayload_Done;

export const TEAMWORKS_PROFILE_LIMIT = 300;

export const useAllTeamworksOrgData = ({ teamworksOrgId, orgId }: useDataArgs): useDataPayload => {
  const sportsResult = useSportsWithPositionMappingsQuery();
  const positionLinkResult = useTeamworksPositionLinksQuery();
  const teamworksOrgResult = useTeamworksOrgQuery({
    variables: { teamworksId: teamworksOrgId },
  });
  const orgForLinkingResult = useOrgForLinkingQuery({
    variables: { orgId },
  });

  const disabledProfilesResult = useTeamworksDisabledProfileIdsQuery({ variables: { teamworksOrgId } });

  const teamworksProfileResult = useTeamworksProfileCursorConnectionQuery({
    variables: {
      teamworksOrgId,
      pagination: {
        limit: TEAMWORKS_PROFILE_LIMIT,
        cursor: null,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const doneLoadingProfiles = teamworksProfileResult.data?.teamworksProfileCursorConnection.pageInfo.hasNextPage === false;

  useEffect(() => {
    const { data, loading, fetchMore } = teamworksProfileResult;
    if (!loading && data?.teamworksProfileCursorConnection.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          teamworksOrgId,
          pagination: {
            limit: TEAMWORKS_PROFILE_LIMIT,
            cursor: data.teamworksProfileCursorConnection.pageInfo.endCursor,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // TODO: updateQuery is deprecated look into adapting relayStylePagination
          if (!fetchMoreResult) {
            return prev;
          }
          const newEdges = fetchMoreResult.teamworksProfileCursorConnection.edges;
          return {
            ...fetchMoreResult,
            teamworksProfileCursorConnection: {
              ...fetchMoreResult.teamworksProfileCursorConnection,
              edges: [...prev.teamworksProfileCursorConnection.edges, ...newEdges],
            },
          };
        },
      });
    }
  }, [teamworksProfileResult, teamworksOrgId]);

  if (
    !sportsResult.loading &&
    sportsResult.data &&
    !positionLinkResult.loading &&
    positionLinkResult.data &&
    !orgForLinkingResult.loading &&
    orgForLinkingResult.data &&
    !teamworksOrgResult.loading &&
    teamworksOrgResult.data?.teamworksOrg &&
    doneLoadingProfiles &&
    teamworksProfileResult.data &&
    !disabledProfilesResult.loading &&
    disabledProfilesResult.data
  ) {
    return {
      __type: "done",
      sports: sportsResult.data.sports,
      teamworksPositionLinks: positionLinkResult.data.teamworksPositionLinks,
      org: orgForLinkingResult.data.org,
      teamworksOrg: teamworksOrgResult.data.teamworksOrg,
      teamworksUserTypes: teamworksOrgResult.data.teamworksOrgGroups.filter(g => g.type === "user_type"),
      teamworksAthleteStatuses: teamworksOrgResult.data.teamworksOrgGroups.filter(g => g.type === "athlete_status"),
      teamworksPositions: teamworksOrgResult.data.teamworksOrgGroups.filter(g => g.type === "position"),
      allTeamworksProfiles: teamworksProfileResult.data.teamworksProfileCursorConnection.edges,
      disabledProfileIds: disabledProfilesResult.data.teamworksDisabledProfileIds,
    };
  } else {
    return {
      __type: "loading",
    };
  }
};

import AssignmentIcon from "@mui/icons-material/AssignmentRounded";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { ExchangeSetsPage } from "./ExchangeSetsPage";
import { MealPlanTemplatesPage } from "./MealPlanTemplatesPage";

// full page path
export const NAV_STAFF_MEAL_PLAN_MANAGEMENT = "/staff/meal-plan-management";

// local child paths
export const NAV_MEAL_PLAN_MANAGEMENT_EXCHANGE_SETS = "exchange-sets";
export const NAV_MEAL_PLAN_MANAGEMENT_MEAL_PLAN_TEMPLATES = "meal-plan-templates";

// full child paths
export const NAV_STAFF_MEAL_PLAN_MANAGEMENT_EXCHANGE_SETS = `${NAV_STAFF_MEAL_PLAN_MANAGEMENT}/${NAV_MEAL_PLAN_MANAGEMENT_EXCHANGE_SETS}`;

export const mealPlanManagementLink = {
  to: NAV_STAFF_MEAL_PLAN_MANAGEMENT,
  name: "Meal Plan Management",
  icon: <AssignmentIcon />,
};

const secondaryNavLayoutProps: SecondaryNavLayoutProps = {
  current: mealPlanManagementLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Meal Plan Templates",
      to: NAV_MEAL_PLAN_MANAGEMENT_MEAL_PLAN_TEMPLATES,
    },
    {
      name: "Exchange Sets",
      to: NAV_MEAL_PLAN_MANAGEMENT_EXCHANGE_SETS,
    },
  ],
};

export const MealPlanManagementRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route index element={<MealPlanTemplatesPage />} />
      <Route path={NAV_MEAL_PLAN_MANAGEMENT_MEAL_PLAN_TEMPLATES} element={<MealPlanTemplatesPage />} />
      <Route path={NAV_MEAL_PLAN_MANAGEMENT_EXCHANGE_SETS} element={<ExchangeSetsPage />} />
    </Route>
  </Routes>
);

import { Box, Button } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useState } from "react";
import ExchangeSetModal from "../../../../components/ExchangeSet/Modal";
import CopyConfirmModal from "../../../../components/ExchangeSet/Modal/CopyConfirm";
import DeleteConfirmModal from "../../../../components/ExchangeSet/Modal/DeleteConfirm";
import ExchangeSetPreview from "../../../../components/ExchangeSet/PreviewCard";
import { ExchangeSetFragment, useExchangeSetsQuery } from "../../../../types";

export const ExchangeSetsPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedExchangeSet, setSelectedExchangeSet] = useState<ExchangeSetFragment | null>(null);
  const { data, loading } = useExchangeSetsQuery();

  const handleCreate = () => {
    setSelectedExchangeSet(null);
    setModalOpen(true);
  };

  const makeHandleEdit = (es: ExchangeSetFragment) => () => {
    setSelectedExchangeSet(es);
    setModalOpen(true);
  };

  const makeHandleCopy = (es: ExchangeSetFragment) => () => {
    setSelectedExchangeSet(es);
    setCopyModalOpen(true);
  };

  const makeHandleDelete = (es: ExchangeSetFragment) => () => {
    setSelectedExchangeSet(es);
    setDeleteModalOpen(true);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleCreate}>Create Exchange Set</Button>
      </Box>
      {loading || !data ? (
        <Loading progressSize="lg" />
      ) : (
        <Box sx={{ display: "flex", flexFlow: "row wrap" }}>
          {data.exchangeSets.map(es => (
            <ExchangeSetPreview
              exchangeSet={es}
              key={es.id}
              onCopy={makeHandleCopy(es)}
              onClick={makeHandleEdit(es)}
              onDelete={makeHandleDelete(es)}
            />
          ))}
        </Box>
      )}
      {modalOpen && (
        <ExchangeSetModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          exchangeSetId={selectedExchangeSet ? selectedExchangeSet.id : null}
        />
      )}
      {copyModalOpen && selectedExchangeSet && (
        <CopyConfirmModal
          open={copyModalOpen}
          onClose={() => setCopyModalOpen(false)}
          exchangeSetToCopy={selectedExchangeSet} />
      )}
      {deleteModalOpen && selectedExchangeSet && (
        <DeleteConfirmModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          exchangeSetToDelete={selectedExchangeSet} />
      )}
    </Box>
  );
};

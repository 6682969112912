import { AnthropometrySnapshot, getTotalCalories, getTotalMetricCalories } from "@notemeal/shared/utils/macro-protocol";
import { sortByKey } from "@notemeal/utils/sort";
import { EditImperialMacroProtocolState, EditMetricMacroProtocolState } from "../reducers/EditMacroProtocol";
import {
  NutrientAmountFragment,
  NutrientWithDriFragment,
  ServingWithNutrientAmountsFragment,
  SexType,
  useNutrientsWithDriQuery,
  useServingsWithNutrientAmountsQuery,
} from "../types";

import { AllNutrientGroups, FoodOrRecipeWithNutrientAmount, NutrientAmountWithFoodOrRecipes, NutrientGroup } from "./types";
import {
  CommonNutrientName,
  NutrientName,
  carbs,
  lipids,
  minerals,
  proteins,
  usdaNameToCommonName,
  vitamins,
} from "@notemeal/nutrient/utils";

export interface NutrientReadyServingAmount {
  amount: number;
  serving: {
    id: string;
  };
}

/**
 * All the nutrientAmounts passed here should refer to the same nutrient
 *
 * @param commonNutrientName
 * @param firstNutrient
 * @param restOfNutrients
 * @returns
 */
export const sumAmountsForNutrient = (
  commonNutrientName: string,
  firstNutrientAmount: NutrientAmountWithFoodOrRecipes,
  restOfNutrientAmounts: readonly NutrientAmountWithFoodOrRecipes[]
): NutrientAmountWithFoodOrRecipes => {
  const nutrients = [firstNutrientAmount, ...restOfNutrientAmounts];
  const reportingFoodOrRecipes = nutrients
    .flatMap(n => n.reportingFoodOrRecipes)
    .reduce<readonly FoodOrRecipeWithNutrientAmount[]>((acc, next) => {
      const match = acc.find(f => f.id === next.id);

      if (match) {
        return [
          ...acc.filter(f => f.id !== next.id),
          {
            ...next,
            nutrientAmount: match.nutrientAmount + next.nutrientAmount,
          },
        ];
      } else {
        return [...acc, next];
      }
    }, []);

  return {
    nutrient: {
      commonName: commonNutrientName,
      unitName: firstNutrientAmount.nutrient.unitName,
      rdiAmount: null,
      rdiType: null,
    },
    amount: nutrients.reduce((sum, n) => sum + n.amount, 0),
    totalFoodOrRecipesCount: firstNutrientAmount.totalFoodOrRecipesCount,
    reportingFoodOrRecipes,
    nonReportingFoodOrRecipes: firstNutrientAmount.nonReportingFoodOrRecipes,
  };
};

const sumNutrientAmounts = (
  nutrientAmounts1: readonly NutrientAmountFragment[],
  nutrientAmounts2: readonly NutrientAmountFragment[]
): readonly NutrientAmountFragment[] => {
  return nutrientAmounts1.reduce((accNutrientAmounts, { nutrient, amount }) => {
    const match = accNutrientAmounts.find(na => na.nutrient.id === nutrient.id);
    if (match) {
      return [
        ...accNutrientAmounts.filter(na => na !== match),
        {
          nutrient,
          amount: match.amount + amount,
        },
      ];
    } else {
      return [...accNutrientAmounts, { nutrient, amount }];
    }
  }, nutrientAmounts2);
};

const scaleNutrientAmounts = (nutrientAmounts: readonly NutrientAmountFragment[], scale: number): readonly NutrientAmountFragment[] => {
  return nutrientAmounts.map(({ nutrient, amount }) => ({
    nutrient,
    amount: amount * scale,
  }));
};

export const getDriKcal = (
  anthropometrySnapshot: AnthropometrySnapshot | null,
  macroProtocolState: EditImperialMacroProtocolState | null
): number | null => {
  try {
    return anthropometrySnapshot && macroProtocolState ? getTotalCalories(anthropometrySnapshot, macroProtocolState.calorieBudget) : null;
  } catch (e) {
    // We can get here if (1) dietitian sets macroProtocol in food logs w/ Cunningham, (2) athlete gets new anthro
    // w/o leanMass, (3) dietitian logs back in
    console.warn(e);
    return null;
  }
};

export const getMetricDriKcal = (
  anthropometrySnapshot: AnthropometrySnapshot | null,
  macroProtocolState: EditMetricMacroProtocolState | null
): number | null => {
  try {
    return anthropometrySnapshot && macroProtocolState
      ? getTotalMetricCalories(anthropometrySnapshot, macroProtocolState.calorieBudget)
      : null;
  } catch (e) {
    // We can get here if (1) dietitian sets macroProtocol in food logs w/ Cunningham, (2) athlete gets new anthro
    // w/o leanMass, (3) dietitian logs back in
    console.warn(e);
    return null;
  }
};

interface useNutrientAmountGroupsArgs {
  servingAmounts: readonly NutrientReadyServingAmount[];
  age: number | null;
  sex: SexType | null;
  kcal: number | null;
}

interface useNutrientAmountGroupsPayload {
  loading: boolean;
  allNutrientGroups: AllNutrientGroups;
}

export const useNutrientAmountGroups = ({
  servingAmounts,
  age,
  sex,
  kcal,
}: useNutrientAmountGroupsArgs): useNutrientAmountGroupsPayload => {
  const { data: sData, loading: sLoading } = useServingsWithNutrientAmountsQuery({
    variables: {
      servingIds: servingAmounts.map(sa => sa.serving.id),
    },
  });

  const { data: nData, loading: nLoading } = useNutrientsWithDriQuery({
    variables: {
      age,
      sex,
      kcal,
    },
    fetchPolicy: "cache-first",
  });

  const servings = !sData ? [] : sData.servings;
  const foodOrRecipesWithNutrientAmounts = getFoodOrRecipesWithNutrientAmounts(servingAmounts, servings);

  const nutrients = !nData ? [] : nData.nutrients;
  const nutrientsWithCommonName = nutrients.map(getNutrientWithCommonName);

  return {
    loading: nLoading || sLoading,
    allNutrientGroups: groupNutrients(nutrientsWithCommonName, foodOrRecipesWithNutrientAmounts),
  };
};

interface FoodOrRecipeWithNutrientAmounts {
  id: string;
  name: string;
  nutrientAmounts: readonly NutrientAmountFragment[];
}

const getFoodOrRecipesWithNutrientAmounts = (
  servingAmounts: readonly NutrientReadyServingAmount[],
  servings: readonly ServingWithNutrientAmountsFragment[]
): readonly FoodOrRecipeWithNutrientAmounts[] => {
  return servingAmounts.reduce<readonly FoodOrRecipeWithNutrientAmounts[]>((foodOrRecipes, { serving, amount }) => {
    const matchingServing = servings.find(s => s.id === serving.id);
    if (!matchingServing) {
      return foodOrRecipes;
    }
    const matchingServingNutrientAmounts = scaleNutrientAmounts(matchingServing.nutrientAmounts, amount);

    const matchingFoodOrRecipe = foodOrRecipes.find(f => f.id === matchingServing.foodOrRecipe.id);
    if (matchingFoodOrRecipe) {
      return [
        ...foodOrRecipes.filter(f => f !== matchingFoodOrRecipe),
        {
          ...matchingFoodOrRecipe,
          nutrientAmounts: sumNutrientAmounts(matchingFoodOrRecipe.nutrientAmounts, matchingServingNutrientAmounts),
        },
      ];
    }

    return [
      ...foodOrRecipes,
      {
        id: matchingServing.foodOrRecipe.id,
        name: matchingServing.foodOrRecipe.name,
        nutrientAmounts: matchingServingNutrientAmounts,
      },
    ];
  }, []);
};

interface NutrientWithCommonName extends NutrientWithDriFragment {
  commonName: string | null;
}

const groupNutrients = (
  nutrientsWithCommonName: readonly NutrientWithCommonName[],
  foodOrRecipes: readonly FoodOrRecipeWithNutrientAmounts[]
): AllNutrientGroups => {
  return {
    carbs: pluckNutrientGroup(carbs, nutrientsWithCommonName, foodOrRecipes),
    proteins: pluckNutrientGroup(proteins, nutrientsWithCommonName, foodOrRecipes),
    lipids: pluckNutrientGroup(lipids, nutrientsWithCommonName, foodOrRecipes),
    vitamins: pluckNutrientGroup(vitamins, nutrientsWithCommonName, foodOrRecipes),
    minerals: pluckNutrientGroup(minerals, nutrientsWithCommonName, foodOrRecipes),
  };
};

const pluckNutrientGroup = <NK extends CommonNutrientName>(
  commonNutrientNames: readonly NK[],
  nutrientsWithCommonName: readonly NutrientWithCommonName[],
  foodOrRecipes: readonly FoodOrRecipeWithNutrientAmounts[]
): NutrientGroup<NK> => {
  return commonNutrientNames.reduce<NutrientGroup<NK>>((group, nextName) => {
    return {
      ...group,
      [nextName]: pluckNutrient(nextName, nutrientsWithCommonName, foodOrRecipes),
    };
  }, {} as NutrientGroup<NK>);
};

const pluckNutrient = (
  commonNutrientName: CommonNutrientName,
  nutrientsWithCommonName: readonly NutrientWithCommonName[],
  foodOrRecipes: readonly FoodOrRecipeWithNutrientAmounts[]
): NutrientAmountWithFoodOrRecipes => {
  const matchingNutrient = nutrientsWithCommonName.find(n => n.commonName === commonNutrientName);
  if (!matchingNutrient) {
    return {
      nutrient: {
        commonName: commonNutrientName,
        unitName: "N/A",
        rdiAmount: null,
        rdiType: null,
      },
      amount: 0,
      totalFoodOrRecipesCount: foodOrRecipes.length,
      reportingFoodOrRecipes: [],
      nonReportingFoodOrRecipes: foodOrRecipes,
    };
  }

  const { reportingFoodOrRecipes, amount, nonReportingFoodOrRecipes } = foodOrRecipes.reduce<
    Pick<NutrientAmountWithFoodOrRecipes, "reportingFoodOrRecipes" | "nonReportingFoodOrRecipes" | "amount">
  >(
    ({ reportingFoodOrRecipes, nonReportingFoodOrRecipes, amount }, { id, name, nutrientAmounts }) => {
      const matchingNutrientAmount = nutrientAmounts.find(na => na.nutrient.id === matchingNutrient.id);
      if (matchingNutrientAmount?.amount) {
        return {
          reportingFoodOrRecipes: [...reportingFoodOrRecipes, { id, name, nutrientAmount: matchingNutrientAmount.amount }],
          amount: amount + matchingNutrientAmount.amount,
          nonReportingFoodOrRecipes,
        };
      } else {
        return {
          reportingFoodOrRecipes,
          amount,
          nonReportingFoodOrRecipes: [...nonReportingFoodOrRecipes, { id, name }],
        };
      }
    },
    { reportingFoodOrRecipes: [], nonReportingFoodOrRecipes: [], amount: 0 }
  );

  return {
    nutrient: {
      commonName: commonNutrientName,
      unitName: matchingNutrient.unitName,
      rdiAmount: matchingNutrient.defaultDietaryReferenceIntake?.amount || null,
      rdiType: matchingNutrient.defaultDietaryReferenceIntake?.type || null,
    },
    totalFoodOrRecipesCount: foodOrRecipes.length,
    amount,
    reportingFoodOrRecipes,
    nonReportingFoodOrRecipes,
  };
};

const getNutrientWithCommonName = (nutrient: NutrientWithDriFragment): NutrientWithCommonName => {
  return {
    ...nutrient,
    commonName: usdaNameToCommonName(nutrient.name as NutrientName),
  };
};

export const LISTED_FOOD_OR_RECIPE_COUNT = 5;

export const getTopReportingFoodOrRecipes = (
  nutrientAmount: NutrientAmountWithFoodOrRecipes
): readonly FoodOrRecipeWithNutrientAmount[] => {
  return sortByKey(nutrientAmount.reportingFoodOrRecipes, "nutrientAmount", { reverse: true }).slice(0, LISTED_FOOD_OR_RECIPE_COUNT);
};

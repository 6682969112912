import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { Box, Button, Switch, Typography, styled, useTheme } from "@mui/material";
import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { Dispatch, useState } from "react";
import { SuggestedMealTemplateCalendarEvent } from "../types";
import AutoSuggestionsWarningDialog, { AutoSuggestionStrategy } from "./AutoSuggestionsWarning";
import { MenuSectionBox, MenuSectionHeader } from "./Sections";
import { MealPlanCalendarAction } from "./reducer";
import { MealPlanSuggestions } from "./types";

const SuggestionButton = styled(Button)({
  flexGrow: 1,
  height: 25,
});

const SubSectionBox = styled(Box)(
  ({
    theme: {
      spacing,
      palette: { grey },
    },
  }) => ({
    padding: spacing(1.5),
    backgroundColor: grey[100],
    borderRadius: "4px",
  })
);

interface SuggestionsSectionProps {
  isSectionExpanded: boolean;
  startOfWeekDate: Date;
  startOfNextWeekDate: Date;
  state: MealPlanSuggestions;
  suggestedEvents: readonly SuggestedMealTemplateCalendarEvent[];
  hasMealModifications: boolean;
  onRevertWeek: () => void;
  onAcceptWeek: () => void;
  dispatch: Dispatch<MealPlanCalendarAction>;
  onToggleSection: () => void;
}

const SuggestionsSection = ({
  isSectionExpanded,
  startOfWeekDate,
  startOfNextWeekDate,
  state,
  suggestedEvents,
  hasMealModifications,
  onRevertWeek,
  onAcceptWeek,
  dispatch,
  onToggleSection,
}: SuggestionsSectionProps) => {
  const {
    palette: { grey },
  } = useTheme();

  // get accepted and rejected suggestions state
  const acceptedSuggestionsForWeek = state.acceptedSuggestions.filter(e => e.start >= startOfWeekDate && e.start < startOfNextWeekDate);
  const rejectedSuggestionsForWeek = state.rejectedSuggestions.filter(e => e.start >= startOfWeekDate && e.start < startOfNextWeekDate);
  const isRevertable = rejectedSuggestionsForWeek.length > 0 || acceptedSuggestionsForWeek.length > 0;
  const isAcceptable = acceptedSuggestionsForWeek.length + rejectedSuggestionsForWeek.length < suggestedEvents.length;

  const [isAutoSuggestionsWarningOpen, setIsAutoSuggestionsWarningOpen] = useState(false);

  const handleAutoSuggestionsWarning = () => {
    if (!state.isAutoSuggestionsEnabled) {
      setIsAutoSuggestionsWarningOpen(true);
    } else {
      handleAutoSuggestions();
    }
  };

  const handleAutoSuggestionsWithStrategy = (strategy: AutoSuggestionStrategy) => {
    if (strategy === "override") {
      // remove all future mods
      dispatch({
        type: "RemoveAllModifications",
      });
    }
    // else if save mods, we don't need to do anything since they will be left in place
    handleAutoSuggestions();
    setIsAutoSuggestionsWarningOpen(false);
  };

  const handleAutoSuggestions = () => {
    const newToggle = !state.isAutoSuggestionsEnabled;

    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Toggled Auto Accept Time Suggestions", {
      acceptTimeSuggestionsEnabled: newToggle,
    });

    dispatch({
      type: "ToggleAutoSuggestions",
      payload: {
        isAutoSuggestionsEnabled: newToggle,
      },
    });
  };

  return (
    <>
      <MenuSectionBox sx={{ pt: 0 }}>
        <MenuSectionHeader>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AutoFixHighOutlinedIcon sx={{ width: 15, height: 15 }} />
            <Typography>Time Suggestions</Typography>
          </Box>
          <Switch checked={isSectionExpanded} onChange={onToggleSection} />
        </MenuSectionHeader>
        {isSectionExpanded && (
          <>
            {!state.isAutoSuggestionsEnabled && (
              <SubSectionBox sx={{ mb: 2 }}>
                <Typography variant="body2Semibold" sx={{ pb: 0.5 }}>
                  Accept this week's suggestions
                </Typography>
                {suggestedEvents.length > 0 ? (
                  <>
                    <Typography variant="subtitle1" sx={{ pb: 1, color: grey[700] }}>
                      For meals on this week only
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {isRevertable && (
                        <SuggestionButton
                          variant={"outlined"}
                          sx={{ backgroundColor: "white" }}
                          onClick={() => {
                            onRevertWeek();
                          }}
                        >
                          Revert
                        </SuggestionButton>
                      )}
                      {isAcceptable && (
                        <SuggestionButton
                          onClick={() => {
                            onAcceptWeek();
                          }}
                        >
                          Accept
                        </SuggestionButton>
                      )}
                    </Box>
                  </>
                ) : (
                  <Typography variant="subtitle1" sx={{ color: grey[700] }}>
                    No suggestions for this week
                  </Typography>
                )}
              </SubSectionBox>
            )}
            <SubSectionBox sx={{ pr: 0, display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="body2Semibold" sx={{ pb: 0.5 }}>
                  Auto-accept all suggestions
                </Typography>
                <Typography variant="subtitle1" sx={{ color: grey[700] }}>
                  For meals on all applicable days/weeks
                </Typography>
              </Box>
              <Switch
                checked={state.isAutoSuggestionsEnabled}
                onChange={hasMealModifications ? handleAutoSuggestionsWarning : handleAutoSuggestions}
              />
            </SubSectionBox>
          </>
        )}
      </MenuSectionBox>
      <AutoSuggestionsWarningDialog
        open={isAutoSuggestionsWarningOpen}
        onClose={() => setIsAutoSuggestionsWarningOpen(false)}
        onContinue={handleAutoSuggestionsWithStrategy}
      ></AutoSuggestionsWarningDialog>
    </>
  );
};

export default SuggestionsSection;

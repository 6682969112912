import React, { createContext, useContext } from "react";
import { MacroProgressViews } from "../../../../types";

const MacroProgressViewsContext = createContext<MacroProgressViews>("all");

interface MacroProgressViewsContextProviderProps {
  children?: React.ReactNode;
  macrosProgressViews: MacroProgressViews;
}

export const MacroProgressViewsContextProvider = ({ children, macrosProgressViews }: MacroProgressViewsContextProviderProps) => {
  return <MacroProgressViewsContext.Provider value={macrosProgressViews}>{children}</MacroProgressViewsContext.Provider>;
};

export const useMacroProgressViews = () => useContext(MacroProgressViewsContext);

import SaveIcon from "@mui/icons-material/Save";
import { Button, Divider, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useState } from "react";
import ImportResultList from ".";
import { IMatchResult, MatchType } from "../../../../utils/import/match";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { Entity } from "../../../universal/SearchBar/type";
import { ICrudHandler } from "../types";
interface ResultListGroupProps<T, A> {
  matches: IMatchResult<T, A>[];
  setMatches: (matches: IMatchResult<T, A>[]) => void;
  name?: string;
  actionButtonName: string;
  onAction: (props: ICrudHandler<T, A>) => void;
  onMatch?: (match: IMatchResult<T, A>, idx: number) => void;
  existingRows?: (Entity & T)[];
  onChange?: (row: T, type: MatchType, newRow: T) => void;
  allowShowImportRow?: boolean;
  // if provided, this component always removes records from the group before calling onMatch()
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexFlow: "column nowrap",
      maxHeight: "100%",
      overflowY: "auto",
      //NOTE: To get rid of "sticky" insert button and double scroll, comment out maxHeight and overflow
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
  })
);

type ResolutionState = "unresolved" | "loading" | "resolved" | "partiallyResolved";

const ResultListGroup = <T extends object, A extends object>({
  existingRows,
  matches,
  setMatches,
  name,
  actionButtonName,
  onAction,
  onMatch,
  onChange,
  allowShowImportRow,
  disabled,
}: ResultListGroupProps<T, A>) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const [resolutionState, setResolutionState] = useState<ResolutionState>("unresolved");
  const [selectedMatchIndices, setSelectedMatchIndices] = useState<boolean[]>(new Array(matches.length).fill(true));
  const selectMatchIndex = (idx: number) => {
    setSelectedMatchIndices([...selectedMatchIndices.slice(0, idx), !selectedMatchIndices[idx], ...selectedMatchIndices.slice(idx + 1)]);
  };
  const removeAndHandleMatch = (match: IMatchResult<T, A>, i: number) => {
    if (onMatch) {
      setSelectedMatchIndices([...selectedMatchIndices.slice(0, i), ...selectedMatchIndices.slice(i + 1)]);
      onMatch(match, i);
    }
  };

  const handleChange = (row: T, type: MatchType, newRow: T, idx: number) => {
    onChange?.(row, type, newRow);
    setSelectedMatchIndices([...selectedMatchIndices.slice(0, idx), ...selectedMatchIndices.slice(idx + 1)]);
  };
  const listOrSuccess =
    resolutionState !== "resolved" ? (
      <>
        <Tooltip title={disabled ? "Action not permitted for this importer" : ""}>
          <div>
            <Button
              variant="outlined"
              disabled={disabled || resolutionState === "loading"}
              fullWidth
              onClick={() => {
                try {
                  setResolutionState("loading");
                  onAction({
                    matches: selectedMatchIndices.flatMap((selected, idx) => (selected ? [matches[idx]] : [])),
                    onCacheUpdate: () => {
                      setResolutionState("resolved");
                      setMessage("success", `Resolved ${selectedMatchIndices.filter(m => m === true).length} Records.`);
                      setMatches([]);
                    },
                  });
                } catch (e) {
                  if (e instanceof Error) {
                    setMessage("error", e.message);
                  } else {
                    setMessage("error", "Something went wrong!");
                  }
                  setResolutionState("unresolved");
                }
              }}
            >
              {actionButtonName}
              {resolutionState === "loading" ? (
                <Loading progressSize="xs" classes={{ root: classes.marginLeft }} />
              ) : (
                <SaveIcon className={classes.marginLeft} />
              )}
            </Button>
          </div>
        </Tooltip>
        <ImportResultList
          existingRows={existingRows}
          matches={matches}
          onMatch={onMatch ? removeAndHandleMatch : undefined}
          selectMatchIndex={selectMatchIndex}
          selectedMatchIndices={selectedMatchIndices}
          onChange={handleChange}
          allowShowImportRow={allowShowImportRow}
        />
      </>
    ) : null;

  return (
    <div className={classes.root}>
      <Divider />
      {name && <Typography variant="subtitle1">{name}</Typography>}
      {listOrSuccess}
    </div>
  );
};

export default ResultListGroup;

import { Row } from "exceljs";
import { ImportedField } from "./parse";

export const checkFirstRowForHeaders = <R, T>(row: Row, iFields: ImportedField<R, T>[]) => {
  if (!row.values) {
    throw new Error("First row has no header data");
  }
  (row.values as any[]).forEach((val: any) => {
    if (typeof val !== "string") {
      throw new Error("Headers must be strings");
    }
  });

  const columns = (row.values as string[]).filter((h: string) => h !== "") as string[];

  iFields.forEach(({ fields, required }) => {
    if (required && !fields.some(f => columns.some(c => c === f))) {
      throw new Error(`Missing required header *${fields.join(",")}*`);
    }
  });
};

export const notemealTertiary = "#E9F1FE";
export const notemealSecondary = "#BCD6FC";

export const tagsCommonColors: readonly string[] = [
  "#E3CDF9",
  "#C1D7FB",
  "#AFE4CB",
  "#CBF1CA",
  "#F6F7BB",
  "#F5D5A8",
  "#F4C7B8",
  "#ECCFE0",
  "#DADFEC",
  "#E0E5E5",
  "#E5CDCD",
  "#D0E7F0",
  "#D0F0EB",
  "#E8EFCC",
  "#DDCFB0",
];

export const mealPlanCommonColors: readonly string[] = [
  "#E3CDF9",
  "#EEA2A2",
  "#CBF1CA",
  "#F6F7BB",
  "#F5D5A8",
  "#F4C7B8",
  "#ECCFE0",
  "#DADFEC",
  "#E5CDCD",
  "#BED4BD",
  "#DDCFB0",
];

export const grayBackground = "#f4f5f8";

export const menuItemRatingColor = "#ffb400";

// Food Categories
export const carbohydrateColor = "#7EAB55";
export const proteinColor = "#6A9AD0";
export const fatsColor = "#F6C242";
export const combinationColor = "#68339B";

// Bullet Scoring
export const bulletScoreFilled = "#2196f3";
export const bulletScoreUnfilled = "#c2c4cb";

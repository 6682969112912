import { Box } from "@mui/material";
import { useAthleteFoodPreferenceContext } from "@notemeal/shared/ui/contexts/AthleteFoodPreferences";
import { useMealPlanFoodPreferenceContext } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import { sortFnByKey } from "@notemeal/utils/sort";
import React from "react";
import { FullExchangeFragment, FullExchangeSetFragment, FullMealTemplateFragment, RecipeWithServingsFragment } from "../../../../types";
import { isExchange } from "../utils";
import ExchangeServingListBox from "./ExchangeServingListBox";
import { useExpandedStyles, useNoPlateStyles } from "./styles";

export interface ExportMealExchangeListsProps {
  mealTemplate: FullMealTemplateFragment;
  exchangeSet: FullExchangeSetFragment | null;
  displaySecondaryExchanges: boolean;
  expandExchanges: boolean;
  recipes: readonly RecipeWithServingsFragment[];
}

type ServingAmount = FullExchangeSetFragment["exchanges"][0]["exchangeServingList"]["servingAmounts"][0];

interface GetNumberServingsProps {
  allPrimaryServings: ServingAmount[];
  allSecondaryServings: ServingAmount[];
}

const ExportMealExchangeLists = ({
  exchangeSet,
  displaySecondaryExchanges,
  mealTemplate,
  expandExchanges,
  recipes,
}: ExportMealExchangeListsProps) => {
  const noPlateClasses = useNoPlateStyles();
  const expandedClasses = useExpandedStyles();
  const { isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isAvoidedFood } = useMealPlanFoodPreferenceContext();
  const isNegativeFood = (foodId: string) => {
    return isDislikedFood(foodId).value || isAvoidedFood(foodId).value;
  };
  const getServForEx = (exchangeIdToServingLists: { [id: string]: null | readonly string[] }, exchange?: FullExchangeFragment) => {
    if (!exchange) {
      return { servingAmounts: [] };
    }
    const servingIdList =
      exchangeIdToServingLists[exchange.id] ||
      exchange.exchangeServingList.servingAmounts.filter(sa => !isNegativeFood(sa.serving.foodOrRecipe.id)).map(sa => sa.serving.id);
    // If the servingIdList is null, use the exchange list!
    return {
      servingAmounts: exchange.exchangeServingList.servingAmounts.filter(sa =>
        servingIdList.some(servingId => servingId === sa.serving.id)
      ),
      exchangeId: exchange.id,
    };
  };

  if (!isExchange(mealTemplate) || !exchangeSet) {
    return null;
  }
  const exchangeTargets = mealTemplate.exchangeTargets;
  if (!exchangeTargets) {
    return null;
  }
  const exchangeIdToTarget: { [id: string]: number } = exchangeTargets.reduce(
    (cur: { [id: string]: number }, next) => ({
      ...cur,
      [next.exchange.id]: next.amount,
    }),
    {}
  );
  const exchangeIdToServingLists: {
    [id: string]: null | readonly string[];
  } = exchangeTargets.reduce(
    (cur: { [id: string]: null | readonly string[] }, next) => ({
      ...cur,
      [next.exchange.id]: next.pickListServingIds,
    }),
    {}
  );
  const exchanges = exchangeSet.exchanges;

  const numberServingsPerBox = 14;
  const maxNumSecondaryEx = numberServingsPerBox / 2;
  const getNumSecondaryServings = ({ allPrimaryServings, allSecondaryServings }: GetNumberServingsProps): number => {
    if (!displaySecondaryExchanges) {
      return 0;
    }
    return allSecondaryServings.length > maxNumSecondaryEx ? maxNumSecondaryEx : allSecondaryServings.length;
  };

  const getSecondaryServings = ({ allSecondaryServings, ...props }: GetNumberServingsProps): ServingAmount[] => {
    const num = getNumSecondaryServings({ allSecondaryServings, ...props });
    return allSecondaryServings.slice(0, num);
  };

  const getPrimaryServings = ({ allPrimaryServings, allSecondaryServings }: GetNumberServingsProps): ServingAmount[] => {
    const num = numberServingsPerBox - getNumSecondaryServings({ allPrimaryServings, allSecondaryServings });
    return allPrimaryServings.slice(0, num);
  };

  const fruit = getServForEx(
    exchangeIdToServingLists,
    exchanges.find(e => e.type === "fruit")
  );
  const fat = getServForEx(
    exchangeIdToServingLists,
    exchanges.find(e => e.type === "fat")
  );
  const vegetable = getServForEx(
    exchangeIdToServingLists,
    exchanges.find(e => e.type === "vegetable")
  );

  const proteinExchanges = exchanges.filter(e => e.type === "protein").sort(sortFnByKey("fat"));
  const dairyExchanges = exchanges.filter(e => e.type === "dairy").sort(sortFnByKey("fat"));
  // Sort by fat, as "sports fuel" will have 0 fat
  const starchExchanges = exchanges
    .filter(e => e.type === "starch")
    .sort(sortFnByKey("pro"))
    .reverse();

  /* NOTE: To support more than "1" protein types, we bucket "all protein exchanges but the leanest"
   * into one group of "hi fat proteins"
   * */
  const leanestProtein = getServForEx(exchangeIdToServingLists, proteinExchanges[0]);
  const dairy = getServForEx(exchangeIdToServingLists, dairyExchanges[0]);
  const starch = getServForEx(exchangeIdToServingLists, starchExchanges[0]);

  return (
    <Box sx={{ pt: 0.3 }} className={expandExchanges ? expandedClasses.mealPlateContainer : noPlateClasses.mealPlateContainer}>
      <div className={noPlateClasses.topLeft}>
        {starchExchanges.some(e => exchangeIdToTarget[e.id]) ? (
          <ExchangeServingListBox
            servingAmounts={getPrimaryServings({
              allPrimaryServings: starch.exchangeId && exchangeIdToTarget[starch.exchangeId] ? starch.servingAmounts : [],
              allSecondaryServings: starchExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
            })}
            secondaryServingAmounts={getSecondaryServings({
              allPrimaryServings: starch.servingAmounts,
              allSecondaryServings: starchExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
            })}
            secondaryExchangeName={starchExchanges[1] ? starchExchanges[1].shortName : undefined}
            name="Starches"
            exchangeType={"starch"}
            exchangeTargetAmount={starchExchanges.reduce((sum, ex) => sum + exchangeIdToTarget[ex.id], 0)}
            recipes={recipes}
          />
        ) : null}
      </div>
      <div className={noPlateClasses.topLeftMid}>
        {dairyExchanges.some(e => exchangeIdToTarget[e.id]) ? (
          <ExchangeServingListBox
            servingAmounts={getPrimaryServings({
              allPrimaryServings: dairy.exchangeId && exchangeIdToTarget[dairy.exchangeId] ? dairy.servingAmounts : [],
              allSecondaryServings: dairyExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
            })}
            secondaryServingAmounts={getSecondaryServings({
              allPrimaryServings: dairy.exchangeId && exchangeIdToTarget[dairy.exchangeId] ? dairy.servingAmounts : [],
              allSecondaryServings: dairyExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
            })}
            secondaryExchangeName={dairyExchanges[1] ? dairyExchanges[1].shortName : undefined}
            twoColumns={false}
            name="Dairy"
            exchangeType={"dairy"}
            exchangeTargetAmount={dairyExchanges.reduce((sum, ex) => sum + exchangeIdToTarget[ex.id], 0)}
            recipes={recipes}
          />
        ) : null}
      </div>
      <div className={noPlateClasses.topRightMid}>
        {fruit.exchangeId && exchangeIdToTarget[fruit.exchangeId] ? (
          <ExchangeServingListBox
            servingAmounts={fruit.servingAmounts}
            name="Fruits"
            exchangeType={"fruit"}
            exchangeTargetAmount={fruit.exchangeId ? exchangeIdToTarget[fruit.exchangeId] : 0}
            recipes={recipes}
          />
        ) : null}
      </div>
      <div className={noPlateClasses.topRight}>
        {vegetable.exchangeId && exchangeIdToTarget[vegetable.exchangeId] ? (
          <ExchangeServingListBox
            servingAmounts={vegetable.servingAmounts}
            name="Veggies"
            exchangeType={"vegetable"}
            exchangeTargetAmount={vegetable.exchangeId ? exchangeIdToTarget[vegetable.exchangeId] : 0}
            recipes={recipes}
          />
        ) : null}
      </div>
      <div className={noPlateClasses.bottomLeft}>
        {proteinExchanges.some(e => exchangeIdToTarget[e.id]) ? (
          <ExchangeServingListBox
            servingAmounts={getPrimaryServings({
              allPrimaryServings:
                leanestProtein.exchangeId && exchangeIdToTarget[leanestProtein.exchangeId] ? leanestProtein.servingAmounts : [],
              allSecondaryServings: proteinExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
            })}
            secondaryServingAmounts={getSecondaryServings({
              allPrimaryServings:
                leanestProtein.exchangeId && exchangeIdToTarget[leanestProtein.exchangeId] ? leanestProtein.servingAmounts : [],
              allSecondaryServings: proteinExchanges.slice(1).flatMap(e => getServForEx(exchangeIdToServingLists, e).servingAmounts),
            })}
            secondaryExchangeName={proteinExchanges[1] ? proteinExchanges[1].shortName : undefined}
            twoColumns={true}
            name="Protein"
            exchangeType={"protein"}
            exchangeTargetAmount={proteinExchanges.reduce((sum, ex) => sum + exchangeIdToTarget[ex.id], 0)}
            recipes={recipes}
          />
        ) : null}
      </div>
      <div className={noPlateClasses.bottomRight}>
        {fat.exchangeId && exchangeIdToTarget[fat.exchangeId] ? (
          <ExchangeServingListBox
            twoColumns
            servingAmounts={fat.servingAmounts}
            name="Healthy Fat"
            exchangeType={"fat"}
            exchangeTargetAmount={fat.exchangeId ? exchangeIdToTarget[fat.exchangeId] : 0}
            recipes={recipes}
          />
        ) : null}
      </div>
    </Box>
  );
};

export default ExportMealExchangeLists;

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import MenuSelectorCard from "../../../components/MenuOrder/MenuSelectorCard";
import { KioskMealMenuPreviewFragment, useMealMenuAdvancedSelectionSharingInfoQuery } from "../../../types";
import ShareContent from "../../Menus/CalendarEvent/ShareContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menusSelectorCard: {
      width: 400,
      alignSelf: "flex-start",
    },
    menuTeamChipsDiv: {
      margin: theme.spacing(2, 0, 1, 0),
    },
    menuTeamChip: {
      margin: theme.spacing(1, 1, 0, 0),
    },
  })
);

interface MenuSelectorCardWithSharingInfoProps {
  mealMenu: KioskMealMenuPreviewFragment;
  selected: boolean;
  onClick: (mealMenuId: string) => void;
}

const MenuSelectorCardWithSharingInfo = ({ mealMenu, selected, onClick }: MenuSelectorCardWithSharingInfoProps) => {
  const classes = useStyles();
  const { hasAdvancedSelection } = mealMenu;

  const { data, loading } = useMealMenuAdvancedSelectionSharingInfoQuery({
    variables: { mealMenuId: mealMenu.id },
    skip: !hasAdvancedSelection,
  });

  return (
    <MenuSelectorCard
      mealMenu={mealMenu}
      selected={selected}
      onClick={() => onClick(mealMenu.id)}
      disabled={mealMenu.athletes.length === 0}
      className={classes.menusSelectorCard}
    >
      <div className={classes.menuTeamChipsDiv}>
        <ShareContent
          mealMenu={mealMenu}
          advancedSelection={data?.mealMenu.advancedSelection}
          loading={loading}
          teamChipClassName={classes.menuTeamChip}
        />
      </div>
    </MenuSelectorCard>
  );
};

export default MenuSelectorCardWithSharingInfo;

import { getLuminance } from "@mui/material";
import { MealType } from "../../../types";

const DEFAULT_BREAKFAST_HUE = 0;
const DEFAULT_LUNCH_HUE = 90;
const DEFAULT_DINNER_HUE = 180;
const DEFAULT_SNACK_HUE = 270;

const HUE_INTERVAL = 70;
const HUE_LIMIT = 360;

const black = "#000000";
const white = "#ffffff";

export const getHueForMealMenu = (mealType: MealType, slot: number) => {
  const defaultHue =
    mealType === "breakfast"
      ? DEFAULT_BREAKFAST_HUE
      : mealType === "lunch"
      ? DEFAULT_LUNCH_HUE
      : mealType === "dinner"
      ? DEFAULT_DINNER_HUE
      : DEFAULT_SNACK_HUE;
  return (defaultHue + slot * HUE_INTERVAL) % HUE_LIMIT;
};

export const getTextColor = (colorString: string): string => {
  const luminance = getLuminance(colorString);
  const whiteLuminance = getLuminance(white);
  const blackLuminance = getLuminance(black);

  const whiteContrast = (whiteLuminance + 0.05) / (luminance + 0.05);
  const blackContrast = (luminance + 0.05) / (blackLuminance + 0.05);

  return whiteContrast > blackContrast ? white : black;
};

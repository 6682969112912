import { RatingCounts, RatingValue } from "../../../../types";

export const ratingValueToNumber = (value: RatingValue): number => {
  switch (value) {
    case "one":
      return 1;
    case "two":
      return 2;
    case "three":
      return 3;
    case "four":
      return 4;
    case "five":
      return 5;
    default:
      throw Error(`RatingValue enum is out of sync`);
  }
};

export const getNumberOfRatings = (ratingCounts: RatingCounts): number => {
  return ratingCounts.one + ratingCounts.two + ratingCounts.three + ratingCounts.four + ratingCounts.five;
};

export const getAverageRating = (ratingCounts: RatingCounts): number | null => {
  const sum = ratingCounts.one + ratingCounts.two * 2 + ratingCounts.three * 3 + ratingCounts.four * 4 + ratingCounts.five * 5;
  if (sum === 0) {
    return 0;
  }

  return Math.round((sum / getNumberOfRatings(ratingCounts)) * 10) / 10;
};

export const getRatingPercents = (ratingCounts: RatingCounts): RatingCounts => {
  const total = getNumberOfRatings(ratingCounts);

  return {
    one: Math.round((ratingCounts.one / total) * 100),
    two: Math.round((ratingCounts.two / total) * 100),
    three: Math.round((ratingCounts.three / total) * 100),
    four: Math.round((ratingCounts.four / total) * 100),
    five: Math.round((ratingCounts.five / total) * 100),
  };
};

import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Box, Button, Popover, useTheme } from "@mui/material";
import React from "react";

interface Props {
  position?: {
    top: number;
    left: number;
  };
  onClickCopy: () => void;
  disablePaste: boolean;
  onClickPaste: () => void;
}

export const MenuBuilderToolbar = ({ position, onClickCopy, disablePaste, onClickPaste }: Props) => {
  const {
    palette: { greyscale, highEmphasisTextDark },
  } = useTheme();

  const buttonSx = {
    color: highEmphasisTextDark,
    "&:hover": { backgroundColor: greyscale[700] },
    "&:disabled": { color: "#6B707A" },
  };

  return (
    <Popover
      sx={{ borderRadius: "6px" }}
      open={Boolean(position)}
      anchorReference="anchorPosition"
      anchorPosition={position}>
      <Box sx={{ backgroundColor: greyscale[900], color: highEmphasisTextDark, p: 1, display: "flex", gap: "6px" }}>
        <Button
          variant="text"
          sx={buttonSx}
          onClick={onClickCopy}
          startIcon={<FileCopyIcon />}>
          Copy
        </Button>
        <Button
          variant="text"
          disabled={disablePaste}
          sx={buttonSx}
          onClick={onClickPaste}
          startIcon={<ContentPasteIcon />}>
          Paste
        </Button>
      </Box>
    </Popover>
  );
};

import { CompassMenuItemFragment, CompassLocationFragment } from "../../../../types";

interface CompassMenu {
  mealPeriodId: number;
  date: string;
  name: string;
  displayOrder: number;
  stations: {
    stationId: number;
    name: string;
    displayOrder: number;
    items: CompassMenuItemFragment[];
  }[];
}

export const groupItemsIntoMenus = (location: CompassLocationFragment, items: readonly CompassMenuItemFragment[]): CompassMenu[] => {
  const allStations = location.mealPeriods.flatMap(mp => mp.stations);
  let menus: CompassMenu[] = [];
  items.forEach(item => {
    const itemDateFormatted = item.date.split("T")[0];
    const matchingMenu = menus.find(m => m.date === itemDateFormatted && m.mealPeriodId === item.mealPeriodId);
    if (matchingMenu) {
      const matchingStation = matchingMenu.stations.find(s => s.stationId === item.stationId);
      if (matchingStation) {
        matchingStation.items.push(item);
      } else {
        const locationStation = allStations.find(s => s.stationId === item.stationId);
        if (locationStation) {
          matchingMenu.stations.push({
            stationId: locationStation.stationId,
            name: locationStation.name,
            displayOrder: locationStation.displayOrder,
            items: [item],
          });
        }
      }
    } else {
      const locationMealPeriod = location.mealPeriods.find(mp => mp.mealPeriodId === item.mealPeriodId);
      const locationStation = allStations.find(s => s.stationId === item.stationId);
      if (locationMealPeriod && locationStation) {
        menus.push({
          date: itemDateFormatted,
          mealPeriodId: locationMealPeriod.mealPeriodId,
          name: locationMealPeriod.name,
          displayOrder: locationMealPeriod.displayOrder,
          stations: [
            {
              stationId: locationStation.stationId,
              name: locationStation.name,
              displayOrder: locationStation.displayOrder,
              items: [item],
            },
          ],
        });
      }
    }
  });
  return menus;
};

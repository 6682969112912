import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { DevRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { NAV_SETTINGS_THEME_PREFERENCE, staffSettingsLink } from "./SettingsPaths";
import { ThemePreferencePage } from "./ThemePreferencePage";

const secondaryNavLayoutProps: SecondaryNavLayoutProps = { current: staffSettingsLink, tabs: [], enableAthleteSearch: true };

export const SettingsRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route element={<DevRoute />}>
        <Route index element={<ThemePreferencePage />} />
        <Route path={NAV_SETTINGS_THEME_PREFERENCE} element={<ThemePreferencePage />} />
      </Route>
    </Route>
  </Routes>
);

import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { GenerateWithGPTDialog } from "apps/web/src/components/Recipe/GPT/Generator/GenerateWithGPTDialog";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer, useState } from "react";
import { MenuItemFullEditForm } from "../../../../components/MenuItem/Form/Form";
import DiscardChangesDialog from "../../../../components/universal/DiscardChangesDialog";
import { GptRecipeFragment, MenuItemFormFragment, RecipeFullFragment } from "../../../../types";

import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { newServingAmount } from "@notemeal/shared/ui/ServingAmount/utils";
import { newRecipeFormState, newRecipeFormStateFromGPTRecipe, recipeFormReducer } from "apps/web/src/components/Recipe/Form/utils";
import { TranscribeWithGPTDialog } from "apps/web/src/components/Recipe/GPT/Transcriber/TranscribeWithGPTDialog";
import { TranscribeError } from "apps/web/src/components/Recipe/GPT/Transcriber/utils";
import RecipeCreateDialog from "apps/web/src/components/Recipe/RecipeCreateDialog";
import { EditMenuItemState, editMenuItemReducer, toEditMenuItemState } from "./reducer";
import { trackEvent } from "apps/web/src/reporting/reporting";

interface MenuItemEditDialogContentProps {
  open: boolean;
  onClose: () => void;
  onSave: (menuItem: EditMenuItemState) => void;
  menuItem: MenuItemFormFragment;
  onDuplicate: () => void;
}

export const MenuItemEditDialogContent = ({
  open,
  onClose,
  onSave,
  menuItem: initMenuItem,
  onDuplicate,
}: MenuItemEditDialogContentProps) => {
  const [discardChangesBeforeCloseOpen, setDiscardChangesBeforeCloseOpen] = useState(false);
  const [discardChangesBeforeDuplicateOpen, setDiscardChangesBeforeDuplicateOpen] = useState(false);
  const { setMessage } = useSnackbar();

  const [state, dispatch] = useReducer(editMenuItemReducer, toEditMenuItemState(initMenuItem));
  const [initialRecipeState, initialRecipeDispatch] = useReducer(
    recipeFormReducer,
    undefined || newRecipeFormState(initMenuItem.name, initMenuItem.servingAmounts)
  );
  const [showGenerateRecipeModal, setShowGenerateRecipeModal] = useState(false);
  const [showTranscribeRecipeModal, setShowTranscribeRecipeModal] = useState(false);
  const [transcriberError, setTranscriberError] = useState<TranscribeError | null>(null);
  const [showCreateRecipeModal, setShowCreateRecipeModal] = useState(false);

  const showAiRecipes = useHasFeature("performanceKitchen");
  const onAiGenerateClicked = () => {
    trackEvent("Nutrition | Food Management | Menu item | Enter AI Generate Flow", {});
    setShowGenerateRecipeModal(true);
  };
  const onAiTranscribeClicked = () => {
    trackEvent("Nutrition | Food Management | Menu item | Enter AI Transcription Flow", {});
    setShowTranscribeRecipeModal(true);
  };

  const handleClose = () => {
    if (state.edited) {
      setDiscardChangesBeforeCloseOpen(true);
    } else {
      onClose();
    }
  };

  const handleDuplicate = () => {
    if (state.edited) {
      setDiscardChangesBeforeDuplicateOpen(true);
    } else {
      onClose();
      onDuplicate();
    }
  };

  const handleCancelRevision = () => dispatch({ type: "MenuItemCancelRevision" });

  const onGenerateError = () => {
    setMessage("error", "Error with generating a recipe");
  };

  const onGPTRecipeComplete = (gptRecipe: GptRecipeFragment, closeModal: () => void) => {
    const newState = newRecipeFormStateFromGPTRecipe(gptRecipe);
    initialRecipeDispatch({
      type: "REPLACE_STATE",
      payload: newState,
    });
    closeModal();
    setShowTranscribeRecipeModal(false);

    setShowCreateRecipeModal && setShowCreateRecipeModal(true);
  };

  const onSaveAiRecipe = (recipe: RecipeFullFragment) => {
    setMessage("success", `${recipe.name} has been created.`);
    const newRecipeServing = recipe.servings[0];
    const newRecipeServingAmounts = [
      newServingAmount(
        {
          ...newRecipeServing,
          foodOrRecipe: recipe,
        },
        1
      ),
    ];
    dispatch({
      type: "MenuItemChangeServingAmountsAction",
      payload: {
        servingAmounts: newRecipeServingAmounts,
      },
    });
  };

  return (
    <Dialog
      PaperProps={{
        sx: { minHeight: "calc(100% - 20px)" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle title="Edit Reusable Menu Item" onClose={handleClose} />
      <DialogContent>
        <MenuItemFullEditForm
          variant="MenuItem"
          state={state}
          dispatch={dispatch}
          onAiGenerateClicked={showAiRecipes ? onAiGenerateClicked : null}
          onAiTranscribeClicked={showAiRecipes ? onAiTranscribeClicked : null}
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          sx={{ alignSelf: "start", pl: 2 }}
          variant="outlined"
          onClick={handleDuplicate}>
          Duplicate Menu Item
        </Button>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button variant="outlined" onClick={() => handleClose()}>
            Close
          </Button>
          <Button onClick={() => onSave(state)}>Save</Button>
        </Box>
      </DialogActions>

      {state.pendingRevisionAction !== null && (
        <Dialog
          open={state.pendingRevisionAction !== null}
          onClose={handleCancelRevision}
          maxWidth="sm"
          fullWidth>
          <DialogTitle title="Create Revision of Menu Item?" onClose={handleCancelRevision} />
          <DialogContent>
            <Typography>Any changes to a menu item that affects its nutrients will create a new revision.</Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <CheckIcon sx={{ pr: 1 }} />
              <Typography>
                Usage on past menus and orders will preserve existing nutrients.
                <br />
                Usage on future menus and orders will be updated to the new revision.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCancelRevision}>
              Cancel
            </Button>
            <Button
              onClick={() =>
                dispatch({
                  type: "MenuItemMakeRevision",
                })
              }
            >
              Create Revision
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {discardChangesBeforeCloseOpen && (
        <DiscardChangesDialog
          open={discardChangesBeforeCloseOpen}
          onClose={() => setDiscardChangesBeforeCloseOpen(false)}
          onDiscard={onClose}
        />
      )}
      {discardChangesBeforeDuplicateOpen && (
        <DiscardChangesDialog
          open={discardChangesBeforeDuplicateOpen}
          onClose={() => setDiscardChangesBeforeDuplicateOpen(false)}
          onDiscard={() => {
            onDuplicate();
            onClose();
          }}
        />
      )}
      {showGenerateRecipeModal && (
        <GenerateWithGPTDialog
          open={showGenerateRecipeModal}
          onClose={() => setShowGenerateRecipeModal(false)}
          onGenerateComplete={onGPTRecipeComplete}
          onError={onGenerateError}
          initialName={state.menuItem.name}
        />
      )}
      {showTranscribeRecipeModal && (
        <TranscribeWithGPTDialog
          open={showTranscribeRecipeModal}
          onClose={() => setShowTranscribeRecipeModal(false)}
          onTranscribeComplete={onGPTRecipeComplete}
          transcriberError={transcriberError}
          setTranscriberError={setTranscriberError}
          onError={onGenerateError}
        />
      )}
      {showCreateRecipeModal && (
        <RecipeCreateDialog
          open={showCreateRecipeModal}
          onClose={() => setShowCreateRecipeModal(false)}
          onCreate={recipe => onSaveAiRecipe(recipe)}
          initialRecipeState={initialRecipeState}
        />
      )}
    </Dialog>
  );
};

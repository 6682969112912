import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { UnitConversionFullFragment } from "../../../types";
import GroupedServingAmountList from "./GroupedServingAmountList";
import { getMenuItemServingAmounts, MenuIngredientsAnalyzerState_Item } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      marginTop: theme.spacing(),
    },
  })
);

interface MenuIngredientsAnalyzerDialogMenuItemOutputProps {
  menuItem: MenuIngredientsAnalyzerState_Item;
  unitConversions: readonly UnitConversionFullFragment[];
}

const MenuIngredientsAnalyzerDialogMenuItemOutput = ({ menuItem, unitConversions }: MenuIngredientsAnalyzerDialogMenuItemOutputProps) => {
  const classes = useStyles();

  const servingAmounts = getMenuItemServingAmounts(menuItem);
  if (servingAmounts.filter(sa => sa.amount > 0).length === 0) {
    return null;
  }

  return (
    <div className={classes.menuItem}>
      <Typography>{menuItem.name}</Typography>
      <GroupedServingAmountList servingAmounts={servingAmounts} unitConversions={unitConversions} />
    </div>
  );
};

export default MenuIngredientsAnalyzerDialogMenuItemOutput;

import { useApolloClient } from "@apollo/client";
import { capitalize } from "@mui/material";
import { getStaffFoodSources, getStaffFoodSourcesForLocale } from "@notemeal/food/utils";
import React, { useState } from "react";
import { StaffFoodModal } from "../../../../components/Food/Staff/StaffFoodModal";
import BaseFoodTable, {
  RenderFormModalArgs,
  UseFoodDataOffsetConnectionArgs,
  UseFoodDataOffsetConnectionPayload,
} from "../../../../components/Food/Table/BaseFoodTable";
import { LocaleSelect } from "../../../../components/Locale/LocaleSelect";
import LabeledSelectNoId from "../../../../components/universal/LabeledSelectNoId";
import { FoodDataSource, useDeprecateSharedFoodMutation, useSharedEditableFoodsOffsetConnectionQuery } from "../../../../types";

export const FoodsPage = () => {
  const apolloClient = useApolloClient();

  // Locale and data source filtering
  const [localeCode, setLocaleCode] = useState<string | null>(null);
  const [foodDataSource, setFoodDataSource] = useState<FoodDataSource | null>(null);
  const dataSources = localeCode ? getStaffFoodSourcesForLocale(localeCode) : getStaffFoodSources();
  const localeFilter = localeCode ? [localeCode] : [];
  const dataSourceFilter = foodDataSource ? [foodDataSource] : dataSources;

  const useFoodDataOffsetConnection = ({ limit, offset, query }: UseFoodDataOffsetConnectionArgs): UseFoodDataOffsetConnectionPayload => {
    const { data, loading, error } = useSharedEditableFoodsOffsetConnectionQuery({
      variables: { pagination: { limit, offset }, query, localeCodes: localeFilter, dataSources: dataSourceFilter },
      fetchPolicy: "network-only",
    });

    return {
      foods: data?.foodOffsetConnection.edges,
      pageInfo: data?.foodOffsetConnection.pageInfo,
      loading,
      error,
    };
  };

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "foodOffsetConnection",
      broadcast: true,
    });
  };

  const [deleteFood] = useDeprecateSharedFoodMutation({
    onCompleted: () => {
      resetTable();
    },
  });

  const onRemoveFood = (id: string) => {
    deleteFood({
      variables: {
        input: { id },
      },
    });
  };

  const renderFoodModal = ({ initialFormState, onClose, isOpen, resetTable: _resetTable }: RenderFormModalArgs) => {
    return <StaffFoodModal
      initialFormState={initialFormState}
      onClose={onClose}
      open={isOpen}
      resetTable={_resetTable} />;
  };

  const filters = (
    <>
      <LabeledSelectNoId
        sx={{ minWidth: 200 }}
        selectedOption={foodDataSource ?? "None"}
        label="Food Source"
        placeholder="Select food source"
        options={[...dataSources, "None"]}
        optionToName={(name: string) => capitalize(name)}
        onChange={t => setFoodDataSource(t === "None" ? null : t)}
      />
      <LocaleSelect
        value={localeCode}
        onChange={e => setLocaleCode(e)}
        allowNone={true} />
    </>
  );

  return (
    <BaseFoodTable
      filters={filters}
      resetTable={resetTable}
      useFoodDataOffsetConnection={useFoodDataOffsetConnection}
      onRemoveFood={onRemoveFood}
      renderFoodModal={renderFoodModal}
      mode="staff"
      tableMode="staff"
    />
  );
};

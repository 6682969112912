import { Button, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const BUTTON_HEIGHT = 50;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      height: BUTTON_HEIGHT,
      minWidth: 100,
      margin: theme.spacing(0, 1),
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
    nameText: {
      WebkitLineClamp: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
    },
  })
);

interface TimelineMealButtonProps {
  name: string;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const TimelineMealButton = ({ name, selected, onClick, disabled }: TimelineMealButtonProps) => {
  const classes = useStyles();
  const nameComponent = <div className={classes.nameText}>{name}</div>;

  if (disabled) {
    return (
      <Button
        disabled
        variant="outlined"
        className={classes.menuButton}>
        {nameComponent}
      </Button>
    );
  }

  return (
    <Button
      variant={selected ? "contained" : "outlined"}
      onClick={onClick}
      className={classes.menuButton}>
      {nameComponent}
    </Button>
  );
};

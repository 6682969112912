import { Paper } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import CalendarEventLabel from "apps/web/src/components/Calendar/Week/EventLabel";
import { CalendarEvent, RenderCalendarEventArgs } from "apps/web/src/components/Calendar/Week/utils";
import React from "react";

interface MealPlanCalendarMealTemplateNewEventProps {
  newEvent: CalendarEvent;
  args: RenderCalendarEventArgs;
  mealPlanColor: string;
}

const MealPlanCalendarNewEventPaper = ({ newEvent, args, mealPlanColor }: MealPlanCalendarMealTemplateNewEventProps) => {
  const { PaperProps, isAbbreviated, eventStyle } = args;
  const clientTimezone = useClientTimezone();
  const backgroundColor = mealPlanColor;

  return (
    <Paper
      {...PaperProps}
      style={{
        ...PaperProps.style,
        backgroundColor,
      }}
    >
      <CalendarEventLabel
        style={eventStyle}
        primary={"(No Title)"}
        isAbbreviated={isAbbreviated}
        start={newEvent.start}
        durationInMinutes={newEvent.durationInMinutes}
        clientTimezone={clientTimezone}
      />
    </Paper>
  );
};

export default MealPlanCalendarNewEventPaper;

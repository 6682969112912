import { Box, Button, Dialog, DialogActions, DialogContent, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { addDays, startOfWeek } from "date-fns";
import React, { ChangeEvent, useState } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import {
  ImportSodexoMenusInput,
  SodexoMealTime,
  SodexoMenuSourceFragment,
  TeamMealMenuPreviewFragment,
  useSodexoMealTimesByDayQuery,
  useSodexoMenuSourcesQuery,
} from "../../../../types";
import OtherSettings from "../Common/OtherSettings";
import { ImportMenuOptionsState, getImportMenuOptionsInput } from "../Common/utils";
import SelectSodexoMealTimes from "./SelectSodexoMealTimes";
import SelectSodexoMenuSource from "./SelectSodexoMenuSource";
import { getInitImportMenuOptionsState } from "./SodexoUtils";

const STEPS = ["Select Menu Source", "Select Meal Times", "Edit Menu Details"];

interface SodexoDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (input: ImportSodexoMenusInput) => void;
  clientTimezone: string;
  allTeams: readonly TeamMealMenuPreviewFragment[];
  saving: boolean;
}

const SodexoDialog = ({ open, onClose, onImport, clientTimezone, allTeams, saving }: SodexoDialogProps) => {
  const { data: menuSourcesData } = useSodexoMenuSourcesQuery();
  const menuSources = menuSourcesData?.sodexoMenuSources;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedMenuSource, setSelectedMenuSource] = useState<SodexoMenuSourceFragment | null>(null);
  const [importMenuOptionsState, setImportMenuOptionsState] = useState<ImportMenuOptionsState | null>(null);
  const [startOfWeekDate, setStartOfWeekDate] = useState(startOfWeek(new Date()));
  const getEndDate = (startDate: Date) => addDays(startDate, 6);
  const {
    data: mealTimesData,
    loading,
    error,
  } = useSodexoMealTimesByDayQuery({
    variables: {
      locationId: selectedMenuSource?.locationId || "",
      menuSourceKey: selectedMenuSource?.menuSourceKey ?? "",
      startDate: serializeDate(startOfWeekDate),
      endDate: serializeDate(getEndDate(startOfWeekDate)),
    },
    skip: selectedMenuSource === null,
  });
  const mealTimesByDay = mealTimesData?.sodexoMealTimesByDay.mealTimesByDay ?? [];
  const [selectedMealTimes, setSelectedMealTimes] = useState<readonly SodexoMealTime[]>([]);

  const onToggleMealTime = (mealTime: SodexoMealTime) => {
    if (selectedMealTimes.includes(mealTime)) {
      setSelectedMealTimes(selectedMealTimes.filter(selectedMealTime => selectedMealTime !== mealTime));
    } else {
      setSelectedMealTimes([...selectedMealTimes, mealTime]);
    }
  };

  const onToggleAllMealTimes = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setSelectedMealTimes(mealTimesByDay.flatMap(({ mealTimes }) => (checked ? mealTimes : [])));
  };

  const onChangeStartOfWeekDate = (startOfWeekDate: Date) => {
    setStartOfWeekDate(startOfWeekDate);
    setSelectedMealTimes([]); // Clear selected menus if changing week
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case 0:
        return !selectedMenuSource ? ["Select menu source to look up meal times"] : [];
      case 1:
        return selectedMealTimes.length === 0 ? ["Select at least one meal time to import menus"] : [];
      default:
        return [];
    }
  };

  const canSaveTooltips = getCanSaveTooltipItems();

  const handleNext = async () => {
    if (canSaveTooltips.length) {
      return;
    }
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);
      if (mealTimesByDay.length) {
        setImportMenuOptionsState(getInitImportMenuOptionsState(selectedMealTimes, clientTimezone));
      }
    } else if (activeStep === 2) {
      if (selectedMenuSource && importMenuOptionsState) {
        const { locationId, menuSourceKey } = selectedMenuSource;
        setActiveStep(activeStep + 1);
        onImport({
          locationId,
          menuSourceKey,
          startDate: serializeDate(startOfWeekDate),
          endDate: serializeDate(getEndDate(startOfWeekDate)),
          mealTimes: selectedMealTimes.map(({ name, start, end }) => ({ name, start, end })),
          options: getImportMenuOptionsInput(importMenuOptionsState),
        });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { height: "calc(100% - 64px)" } }}>
      <DialogTitle title="Import Sodexo Menus" onClose={onClose} />
      <DialogContent>
        <Stepper sx={{ alignSelf: "center" }} activeStep={activeStep}>
          {STEPS.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ overflow: "auto" }}>
          {activeStep === 0 && menuSources && (
            <SelectSodexoMenuSource
              menuSources={menuSources}
              selectedMenuSource={selectedMenuSource}
              onSelectMenuSource={setSelectedMenuSource}
            />
          )}
          {activeStep === 1 && selectedMenuSource && (
            <SelectSodexoMealTimes
              loading={loading}
              error={!!error}
              mealTimesByDay={mealTimesByDay}
              selectedMealTimes={selectedMealTimes}
              onToggleMealTime={onToggleMealTime}
              onToggleAllMealTimes={onToggleAllMealTimes}
              startOfWeekDate={startOfWeekDate}
              onChangeStartOfWeekDate={onChangeStartOfWeekDate}
            />
          )}
          {activeStep === 2 && importMenuOptionsState && (
            <OtherSettings
              state={importMenuOptionsState}
              readOnlyTimes={true}
              onChange={setImportMenuOptionsState}
              allTeams={allTeams} />
          )}
          {activeStep === 3 &&
            (saving ? (
              <Box
                sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", pt: 15 }}
              >
                <Loading progressSize="md" />
                <Typography variant="h3">Importing Menus</Typography>
                <Typography variant="body2">This may take a moment...</Typography>
              </Box>
            ) : (
              <Loading progressSize="md" />
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={activeStep === STEPS.length || activeStep === 0}
          onClick={handleBack}>
          Back
        </Button>
        {activeStep >= STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before finishing" items={canSaveTooltips}>
            <Button onClick={handleNext} disabled={activeStep > STEPS.length - 1}>
              Finish
            </Button>
          </TWItemizedTooltip>
        )}
        {activeStep < STEPS.length - 1 && (
          <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
            <Button onClick={handleNext}>Next</Button>
          </TWItemizedTooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SodexoDialog;

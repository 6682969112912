import React from "react";
import { Fragment } from "react";

import { ScheduleModalEditorState } from "../../../reducers/ScheduleModalEditor";
import ScheduleModalContextProvider from "./ContextProvider";
import ScheduleModalEditor from "./Editor";

interface ScheduleModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (state: ScheduleModalEditorState) => void;
  initialState: ScheduleModalEditorState;
  title: "Edit Schedule" | "New Schedule";
  numberMealPlans: number;
}

const ScheduleModal = ({ open, onClose, onSave, initialState, title, numberMealPlans }: ScheduleModalProps) => {
  // This structure forces a reset of the scheduleModalEditReducer when the modal closes
  // Otherwise initial state is only ever used once
  return (
    <Fragment>
      {open && (
        <ScheduleModalContextProvider initialState={initialState}>
          <ScheduleModalEditor
            title={title}
            open={open}
            onClose={onClose}
            onSave={onSave}
            numberMealPlans={numberMealPlans} />
        </ScheduleModalContextProvider>
      )}
    </Fragment>
  );
};

export default ScheduleModal;

import { List, ListItem, ListItemText, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";
import { CbordBusinessUnitFragment, CbordFacilityFragment, CbordServiceUnitFragment } from "../../../../types";
import { SelectedServiceUnitInfo } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    noUnitsDiv: {
      justifyContent: "center",
      alignItems: "center",
    },
    facility: {
      marginTop: theme.spacing(2),
    },
  })
);

interface CbordDialogSelectServiceUnitProps {
  businessUnits: readonly CbordBusinessUnitFragment[];
  selectedServiceUnitInfo: SelectedServiceUnitInfo | null;
  onSelectServiceUnitInfo: (serviceUnitInfo: SelectedServiceUnitInfo) => void;
}

const CbordDialogSelectServiceUnit = ({
  businessUnits,
  selectedServiceUnitInfo,
  onSelectServiceUnitInfo,
}: CbordDialogSelectServiceUnitProps) => {
  const classes = useStyles();

  const facilities = businessUnits.filter((unit): unit is CbordFacilityFragment => unit.__typename === "CbordFacility");
  const serviceUnits = businessUnits.filter((unit): unit is CbordServiceUnitFragment => unit.__typename === "CbordServiceUnit");

  return businessUnits.length === 0 ? (
    <div className={classnames(classes.root, classes.noUnitsDiv)}>
      <Typography variant="h4">No Locations Found</Typography>
      <Typography variant="h3">Contact Cbord Support to configure data sharing settings</Typography>
    </div>
  ) : (
    <div className={classes.root}>
      <ServiceUnitList
        serviceUnits={serviceUnits}
        selectedServiceUnitInfo={selectedServiceUnitInfo}
        onSelectServiceUnitInfo={onSelectServiceUnitInfo}
      />
      {facilities.map(f => {
        const serviceUnits =
          f.businessUnits?.filter((unit): unit is CbordServiceUnitFragment => unit.__typename === "CbordServiceUnit") ?? [];
        return (
          <div key={f.oid} className={classes.facility}>
            <Typography variant="h3">{f.name}</Typography>
            {serviceUnits.length > 0 ? (
              <ServiceUnitList
                serviceUnits={serviceUnits}
                selectedServiceUnitInfo={selectedServiceUnitInfo}
                onSelectServiceUnitInfo={onSelectServiceUnitInfo}
              />
            ) : (
              <Typography>No Service Units Found for this Facility</Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CbordDialogSelectServiceUnit;

interface ServiceUnitListProps {
  serviceUnits: readonly CbordServiceUnitFragment[];
  selectedServiceUnitInfo: SelectedServiceUnitInfo | null;
  onSelectServiceUnitInfo: (serviceUnitInfo: SelectedServiceUnitInfo) => void;
}

const ServiceUnitList = ({ serviceUnits, selectedServiceUnitInfo, onSelectServiceUnitInfo }: ServiceUnitListProps) => {
  return (
    <List>
      {serviceUnits.map(({ oid, facilityOid, name }) => {
        return (
          <ListItem
            key={oid}
            onClick={() => onSelectServiceUnitInfo({ oid, facilityOid })}
            selected={oid === selectedServiceUnitInfo?.oid}
            button
          >
            <ListItemText primary={name} />
          </ListItem>
        );
      })}
    </List>
  );
};

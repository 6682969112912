import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import classNames from "classnames";
import React, { ReactNode, useState } from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0),
      width: "100%",
    },
    adornment: {},
    paper: {
      boxShadow: `0px 0px 5px 3px ${theme.palette.grey[500]}`,
    },
  })
);

export type OmittedAutocompleteProps<Option, Multiple extends boolean | undefined, FreeSolo extends boolean | undefined> = Omit<
  AutocompleteProps<Option, Multiple, false, FreeSolo>,
  | "className"
  | "size"
  | "inputValue"
  | "onInputChange"
  | "options"
  | "renderInput"
  | "blurOnSelect"
  | "onChange"
  | "classes"
  | "multiple"
  | "value"
  | "renderTags"
  | "disableClearable"
  | "freeSolo"
  | "renderOption"
>;

interface BasicOptionProps {
  element: ReactNode;
}

export const BasicOption = ({ element }: BasicOptionProps) => {
  return <Typography>{element}</Typography>;
};

export interface BaseAsyncAutoCompleteClasses {
  root?: string;
  adornment?: string;
  paper?: string;
}

interface BaseAsyncAutoCompleteProps<Option, Multiple extends boolean | undefined>
  extends OmittedAutocompleteProps<Option, Multiple, false> {
  multiple?: Multiple;
  value: AutocompleteValue<Option, Multiple, false, false>;
  handleChange: (options: AutocompleteValue<Option, Multiple, false, false>) => void;
  useSearchQuery: (value: string) => {
    options: Option[] | undefined;
    loading: boolean;
  };
  inputPlaceholder: string;
  filterUsedOptions: (options: Option[] | undefined) => Option[] | undefined;
  autoFocus?: boolean;
  inputRef?: React.Ref<any>;
  classes?: BaseAsyncAutoCompleteClasses;
  inputVariant?: TextFieldProps["variant"];
  renderOption?: (option: Option, state: AutocompleteRenderOptionState) => React.ReactNode;
}

const BaseAsyncAutoComplete = <Option extends object, Multiple extends boolean | undefined>({
  useSearchQuery,
  inputPlaceholder,
  handleChange,
  value,
  filterUsedOptions,
  autoFocus,
  inputRef,
  classes,
  inputVariant = "outlined",
  renderOption: _renderOption,
  ...restProps
}: BaseAsyncAutoCompleteProps<Option, Multiple>) => {
  const baseClasses = useStyles();

  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 200);
  const { options, loading } = useSearchQuery(debouncedValue);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      autoFocus={autoFocus}
      inputRef={inputRef}
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            <InputAdornment className={classNames(baseClasses.adornment, classes?.adornment)} position="start">
              {loading ? <CircularProgress size={24} /> : <SearchIcon />}
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        ),
        endAdornment: params.InputProps.endAdornment,
      }}
      placeholder={inputPlaceholder}
      fullWidth
      variant={inputVariant}
    />
  );

  const renderOption = _renderOption
    ? (props: React.HTMLAttributes<HTMLLIElement>, option: Option, state: AutocompleteRenderOptionState) => {
        return <li {...props}>{_renderOption(option, state)}</li>;
      }
    : undefined;

  return (
    <Autocomplete
      className={classNames(baseClasses.root, classes?.root)}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      options={(filterUsedOptions ? filterUsedOptions(options) : options) ?? []}
      renderInput={renderInput}
      blurOnSelect
      onChange={(e, newOption) => {
        handleChange(newOption);
      }}
      onClose={() => {
        if (Array.isArray(value) || value === null) {
          setInputValue("");
        }
      }}
      classes={{
        paper: classNames(baseClasses.paper, classes?.paper),
      }}
      value={value}
      renderOption={renderOption}
      {...restProps}
    />
  );
};

export default BaseAsyncAutoComplete;

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Checkbox, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { CalendarDateHeader } from "../../components/Calendar/Week/DateHeader";
import { CalendarDateHeaderProps, getCalendarEventsOnDate } from "../../components/Calendar/Week/utils";
import { MealMenuInstance } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoverContainer: {
      width: 40,
      height: 40,
    },
  })
);

interface MenusCalendarDateHeaderProps extends CalendarDateHeaderProps {
  clientTimezone: string;
  filteredMealMenuInstances: readonly MealMenuInstance[];
  inBulkEdit: boolean;
  bulkEditMealMenuIds: Set<string>;
  handleBulkEditMultiSelectMealMenuInstances: (mealMenus: readonly MealMenuInstance[], selected: boolean) => void;
  showCheckBoxDate: string | null;
  setShowCheckBoxDate: (showCheckBoxDate: string | null) => void;
}

const MenusCalendarDateHeader = ({
  inBulkEdit,
  bulkEditMealMenuIds,
  handleBulkEditMultiSelectMealMenuInstances,
  filteredMealMenuInstances,
  showCheckBoxDate,
  setShowCheckBoxDate,
  clientTimezone,
  date,
  dayOfWeekLabelNode,
  dateLabelNode,
}: MenusCalendarDateHeaderProps) => {
  const classes = useStyles();

  const dateEvents = getCalendarEventsOnDate(filteredMealMenuInstances, date, clientTimezone);
  const allSelected = dateEvents.every(event => bulkEditMealMenuIds.has(event.id)) && dateEvents.length > 0;
  const someSelected = dateEvents.some(event => bulkEditMealMenuIds.has(event.id)) && dateEvents.length > 0;

  return (
    <CalendarDateHeader>
      {dayOfWeekLabelNode}
      <div
        className={classes.hoverContainer}
        onMouseEnter={inBulkEdit ? () => setShowCheckBoxDate(date) : () => {}}
        onMouseLeave={() => setShowCheckBoxDate(null)}
      >
        {showCheckBoxDate === date ? (
          <Checkbox
            checked={allSelected || someSelected}
            onChange={event => {
              handleBulkEditMultiSelectMealMenuInstances(dateEvents, event.target.checked);
            }}
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={allSelected ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
            id={`${date}-checkbox`}
          />
        ) : (
          dateLabelNode
        )}
      </div>
    </CalendarDateHeader>
  );
};

export default MenusCalendarDateHeader;

import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import React, { Dispatch } from "react";
import { RefundPolicyType } from "../../../../types";
import RestaurantMenuFormCuisines from "./Cuisines";
import RestaurantMenuFormPaymentMethods from "./PaymentMethods";
import RestaurantMenuFormRestaurantType from "./RestaurantType";
import { RestaurantInfo, useNutritionixRestaurants } from "./useNutritionixRestaurants";
import { RestaurantFormAction, RestaurantFormState } from "./utils";

const Row = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  gap: spacing(2),
}));

interface RestaurantFormProps {
  state: RestaurantFormState;
  dispatch: Dispatch<RestaurantFormAction>;
}

const RestaurantForm = ({ state, dispatch }: RestaurantFormProps) => {
  const { data: nutritionixRestaurants, loading: nutritionixLoading, error: nutritionixError } = useNutritionixRestaurants();
  const autoCompleteValue = nutritionixRestaurants.find(option => option.id === state.nutritionixId);

  return (
    <>
      <Row>
        <TextField
          sx={{ flex: 1 }}
          autoFocus
          value={state.name}
          onChange={e => dispatch({ type: "CHANGE_NAME", payload: { name: e.target.value } })}
          label="Name"
        />
        {nutritionixError === null ? (
          <Autocomplete
            loading={nutritionixLoading}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            value={autoCompleteValue}
            onChange={(e, value: RestaurantInfo | null) => {
              dispatch({
                type: "CHANGE_NUTRITIONIX_ID",
                payload: value && {
                  nutritionixId: value.id,
                  nutritionixName: value.name,
                },
              });
            }}
            options={nutritionixRestaurants}
            renderInput={params => <TextField
              sx={{ minWidth: 250 }}
              {...params}
              label="Nutritionix Restaurant" />}
          />
        ) : (
          <Typography variant="body1Medium">
            Could not reach Nutritionix.
            <br />
            Please procceed without setting Nutritionix Link
          </Typography>
        )}
      </Row>
      <TextField
        fullWidth
        value={state.website}
        onChange={e =>
          dispatch({
            type: "CHANGE_WEBSITE",
            payload: { website: e.target.value },
          })
        }
        label="Website"
      />
      <Row>
        <FormControlLabel
          control={
            <Checkbox checked={state.isActive} onChange={(_, isActive) => dispatch({ type: "CHANGE_IS_ACTIVE", payload: { isActive } })} />
          }
          label="Is Active"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.firstPartyDelivery}
              onChange={(_, firstPartyDelivery) =>
                dispatch({
                  type: "CHANGE_FIRST_PARTY_DELIVERY",
                  payload: { firstPartyDelivery },
                })
              }
            />
          }
          label="First party delivery"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.athleteVisible}
              onChange={(_, athleteVisible) =>
                dispatch({
                  type: "CHANGE_ATHLETE_VISIBLE",
                  payload: { athleteVisible },
                })
              }
            />
          }
          label="Include in Athlete Search"
        />
      </Row>
      <Row>
        <TextField
          sx={{ flex: 1 }}
          value={state.proteinPortionSizeInOunces}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">oz</InputAdornment>,
          }}
          onChange={e =>
            dispatch({
              type: "CHANGE_PROTEIN_PORTION_IN_OUNCES",
              payload: {
                proteinPortionSizeInOunces: inputToNumber(e.target.value),
              },
            })
          }
          label="Protein portion"
        />

        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>{"Refund Policy"}</InputLabel>
          <Select
            value={state.refundPolicy}
            onChange={e => {
              const value = e.target.value === "None" ? null : (e.target.value as RefundPolicyType);
              dispatch({
                type: "CHANGE_REFUND_POLICY",
                payload: {
                  refundPolicy: value,
                },
              });
            }}
            label="Refund Policy"
          >
            <MenuItem value={"None"} color="error">
              None Selected
            </MenuItem>
            <MenuItem value={"full"}>{"Full"}</MenuItem>
            <MenuItem value={"labor"}>{"Labor"}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          sx={{ flex: 1 }}
          value={state.refundDueBeforeInMinutes}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
          }}
          onChange={e =>
            dispatch({
              type: "CHANGE_REFUND_DUE_BEFORE_IN_MINUTES",
              payload: {
                refundDueBeforeInMinutes: inputToNumber(e.target.value),
              },
            })
          }
          label="Refund due before"
        />
      </Row>
      <RestaurantMenuFormRestaurantType
        restaurantType={state.restaurantType}
        onChange={restaurantType =>
          dispatch({
            type: "CHANGE_RESTAURANT_TYPE",
            payload: { restaurantType },
          })
        }
      />
      <RestaurantMenuFormPaymentMethods
        onChange={paymentMethods =>
          dispatch({
            type: "CHANGE_PAYMENT_METHODS",
            payload: {
              paymentMethods: paymentMethods || [],
            },
          })
        }
        paymentMethods={state.paymentMethods}
      />
      <RestaurantMenuFormCuisines
        onChange={cuisines =>
          dispatch({
            type: "CHANGE_CUISINES",
            payload: {
              cuisines: cuisines || [],
            },
          })
        }
        cuisines={state.cuisines}
      />
    </>
  );
};

export default RestaurantForm;

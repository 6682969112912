import { sortByKey } from "@notemeal/utils/sort";
import { ImageFragment } from "../types";

export const getPreviewImage = (images: readonly ImageFragment[]): ImageFragment | null => {
  if (images.length === 0) {
    return null;
  }
  return sortByKey(images, "position")[0];
};

export const ASPECT_16_9 = 16 / 9;

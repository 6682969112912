import { Dialog, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import CreateDialogContent from "./CreateDialogContent";

const useStyles = makeStyles((theme: Theme) => createStyles({ paper: { height: "calc(100vh - 64px)" } }));

interface CreateDialogProps {
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
}

const CreateDialog = ({ open, onClose, resetTable }: CreateDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      sx={{ height: "100%" }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      classes={classes}>
      <DialogTitle title="Create Tag" onClose={onClose} />
      <CreateDialogContent onClose={onClose} resetTable={resetTable} />
    </Dialog>
  );
};

export default CreateDialog;

import { Box, Button, Dialog, DialogActions, DialogContent, Slider, Typography, useTheme } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { orderedActivityTypes } from "@notemeal/shared/ui/Activity/utils";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import { safeSerializeTime } from "@notemeal/shared/ui/utils/dateTimes";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { addMinutes } from "date-fns";
import React, { useState } from "react";
import TWItemizedTooltip from "../../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import LabeledSelectNoId from "../../../../../components/universal/LabeledSelectNoId";
import { ActivityType, AddActivityInput } from "../../../../../types";
import { rpeToColor } from "../FoodLogActivityUtils";

interface TimelineFoodLogActivityAddModalProps {
  open: boolean;
  onClose: () => void;
  onAddFoodLogActivity: (activity: AddActivityInput, rpe: number | null) => void;
}

const TimelineFoodLogActivityAddModal = ({ open, onClose, onAddFoodLogActivity }: TimelineFoodLogActivityAddModalProps) => {
  const theme = useTheme();
  const {
    palette: { grey },
  } = theme;
  const [activityType, setActivityType] = useState<ActivityType | null>(null);
  const [startDatetime, setStartDatetime] = useState<Date | null>(null);
  const [endDatetime, setEndDatetime] = useState<Date | null>(null);
  const [rpe, setRpe] = useState<null | number>(null);
  const start = safeSerializeTime(startDatetime);
  const end = safeSerializeTime(endDatetime);

  let tooltips = [];
  if (!activityType) {
    tooltips.push("Select activity type");
  }
  if (!start) {
    tooltips.push("Select start time");
  }
  if (!end) {
    tooltips.push("Select end time");
  }

  const handleSetStart = (datetime: Date | null) => {
    setStartDatetime(datetime);
    if (datetime && !endDatetime) {
      setEndDatetime(addMinutes(datetime, 30));
    }
  };

  const handleAdd = () => {
    if (tooltips.length > 0 || !activityType || !start || !end) {
      return;
    }
    onAddFoodLogActivity({ type: activityType, name: capitalize(activityType), start, end }, rpe);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Add Activity" onClose={onClose} />
      <DialogContent sx={{ width: 320, pb: 1, display: "flex", flexDirection: "column", gap: 3 }}>
        <LabeledSelectNoId
          options={[...orderedActivityTypes]}
          selectedOption={activityType}
          placeholder="Select Activity Type"
          optionToName={at => (at ? capitalize(at) : "")}
          onChange={setActivityType}
        />
        <DesktopTimePicker
          label="Start"
          value={startDatetime}
          onChange={handleSetStart} />
        <DesktopTimePicker
          label="End"
          value={endDatetime}
          onChange={datetime => setEndDatetime(datetime)} />
        <Box sx={{ mt: 3, width: "90%", alignSelf: "center", display: "flex" }}>
          <Typography sx={{ mr: 2 }} variant="body2Medium">
            RPE
          </Typography>
          <Slider
            style={{ color: rpe ? rpeToColor(theme, rpe) : grey[400] }}
            valueLabelDisplay="on"
            value={rpe || 0}
            step={1}
            marks
            onChange={(e, value) => setRpe(value as number)}
            min={0}
            max={10}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={tooltips}>
          <Button onClick={handleAdd}>Add</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TimelineFoodLogActivityAddModal;

import { eachDateOfInterval, eventToIsoOnDateInTz, parseDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import {
  ActivityTemplateFragment,
  ActivityTemplateTimeFragment,
  DayOfWeek,
  ExternalEvent,
  MealPlanDateModificationsFragment,
  MealPlanDayOfWeekAssignmentFragment,
  MealTemplateFragment,
  MealTemplateTimeFragment,
} from "apps/web/src/types";
import { differenceInMinutes, getDay, parseISO } from "date-fns";
import {
  ActivityTemplateCalendarEvent,
  MealPlanCalendarEvent,
  MealPlanScheduleEvent,
  MealTemplateCalendarEvent,
  TeamworksCalendarEvent,
} from "./types";
import { toDate } from "date-fns-tz";

interface MealPlanForEvents {
  id: string;
  startDate: string | null;
  endDate: string | null;
  mealTemplates: readonly MealTemplateFragment[];
  activityTemplates: readonly ActivityTemplateFragment[];
}

const MINUTES_IN_DAY = 24 * 60;

export const getMealPlanCalendarDetails = (
  start: string,
  end: string,
  timezone: string,
  mealPlan: MealPlanForEvents,
  daysOfWeek: readonly MealPlanDayOfWeekAssignmentFragment[],
  individualDates: readonly string[],
  modificationsByDates: readonly MealPlanDateModificationsFragment[],
  color: string
): { events: readonly MealPlanScheduleEvent[]; assignedDates: readonly string[] } => {
  const intervalDates = eachDateOfInterval({ start, end });
  const dayOfWeekAssignedDates = intervalDates
    .filter(date => {
      // Start date is required by weekly recurrence meal plans, so filter out meal plans w/ null startDate
      const afterStart = mealPlan.startDate === null ? false : date >= mealPlan.startDate;
      // End date is optional and if null means meal plan does not end
      const beforeEnd = mealPlan.endDate === null ? true : date <= mealPlan.endDate;
      return afterStart && beforeEnd;
    })
    .flatMap(date => {
      const matchingDayOfWeekAssignment = daysOfWeek.find(({ dayOfWeekPriority }) => dayOfWeekPriority.dayOfWeek === getDayOfWeek(date));
      if (!matchingDayOfWeekAssignment) {
        return [];
      }
      return {
        date,
        dayOfWeekAssignment: matchingDayOfWeekAssignment,
      };
    });
  const individualAssignedDates = intervalDates.filter(date => individualDates.includes(date));

  const dayOfWeekEvents: MealPlanScheduleEvent[] = dayOfWeekAssignedDates.flatMap(({ date, dayOfWeekAssignment }) => {
    const modificationsByDate = modificationsByDates.find(m => m.date === date);
    return getMealPlanCalendarEventsForDate(
      date,
      timezone,
      color,
      mealPlan.mealTemplates,
      mealPlan.activityTemplates,
      modificationsByDate,
      dayOfWeekAssignment
    );
  });
  const individualDateEvents: MealPlanScheduleEvent[] = individualAssignedDates.flatMap(date => {
    const modificationsByDate = modificationsByDates.find(m => m.date === date);
    return getMealPlanCalendarEventsForDate(date, timezone, color, mealPlan.mealTemplates, mealPlan.activityTemplates, modificationsByDate);
  });

  return {
    events: dayOfWeekEvents.concat(individualDateEvents),
    assignedDates: dayOfWeekAssignedDates.map(({ date }) => date).concat(individualAssignedDates),
  };
};

export const getMealPlanCalendarEventsForDate = (
  date: string,
  timezone: string,
  mealPlanId: string,
  mealTemplates: readonly MealTemplateTimeFragment[],
  activityTemplates: readonly ActivityTemplateTimeFragment[],
  modificationsByDate: MealPlanDateModificationsFragment | undefined,
  dayOfWeekModifications?: Omit<MealPlanDateModificationsFragment, "date"> | undefined
): readonly MealPlanScheduleEvent[] => {
  const mealTemplateEvents: readonly MealTemplateCalendarEvent[] = mealTemplates.map(mealTemplate => {
    const modificationByDate = modificationsByDate?.mealTemplateModifications.find(m => m.mealTemplate.id === mealTemplate.id);
    const modificationByDayOfWeek = dayOfWeekModifications?.mealTemplateModifications.find(m => m.mealTemplate.id === mealTemplate.id);

    // Modifications on an individual date take priority over day of week modifications
    const modification = modificationByDate ?? modificationByDayOfWeek;

    const { durationInMinutes, start } = eventToIsoOnDateInTz(modification ?? mealTemplate.meal, date, timezone);
    return {
      type: "MealTemplate",
      id: mealTemplate.id,
      mealTemplate,
      start: new Date(start),
      durationInMinutes,
      mealPlanId,
    };
  });

  const activityTemplateEvents: readonly ActivityTemplateCalendarEvent[] = activityTemplates.map(activityTemplate => {
    const modificationByDate = modificationsByDate?.activityTemplateModifications.find(m => m.activityTemplate.id === activityTemplate.id);
    const modificationByDayOfWeek = dayOfWeekModifications?.activityTemplateModifications.find(
      m => m.activityTemplate.id === activityTemplate.id
    );

    // Modifications on an individual date take priority over day of week modifications
    const modification = modificationByDate ?? modificationByDayOfWeek;

    const { durationInMinutes, start } = eventToIsoOnDateInTz(modification ?? activityTemplate.activity, date, timezone);
    return {
      type: "ActivityTemplate",
      id: activityTemplate.id,
      activityTemplate,
      start: new Date(start),
      durationInMinutes,
      mealPlanId,
    };
  });

  return [...mealTemplateEvents, ...activityTemplateEvents];
};

export const getDayOfWeek = (date: string): DayOfWeek => {
  const rawValue = getDay(parseISO(date));
  switch (rawValue) {
    case 0:
      return "sunday";
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
  }
};

export const getTeamworksCalendarAsCalendarEvents = (events: readonly ExternalEvent[]): TeamworksCalendarEvent[] => {
  return events.map(event => {
    // if it's an all day event, we get the day in utc and need to parse without the timezone to get the correct date
    const start = event.isAllDay ? toDate(event.startDate.split("T")[0]) : parseDateTime(event.startDate);
    if (event.isAllDay) {
      start.setHours(0, 0, 0, 0);
    }
    const end = parseDateTime(event.endDate);

    return {
      id: event.id,
      type: "Teamworks",
      name: event.name,
      start,
      durationInMinutes: event.isAllDay ? MINUTES_IN_DAY : differenceInMinutes(end, start),
      mealPlanId: null,
      location: event.location,
      eventType: event.eventType,
      isAllDay: event.isAllDay,
    };
  });
};

export const getNameForMealPlanCalendarEvent = (event: MealPlanCalendarEvent): string => {
  if (event.type === "MealTemplate" || event.type === "SuggestedMealTemplate") {
    return event.mealTemplate.meal.name;
  } else if (event.type === "ActivityTemplate") {
    return event.activityTemplate.activity.name;
  }

  return event.name;
};

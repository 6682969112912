import React from "react";

import { useMenuItemDialogQuery } from "apps/web/src/types";
import DialogContent from "../../../components/MenuItem/Dialog/Content";
import { MenuItemState } from "../../../components/MenuItem/reducer";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";

interface MenuItemDuplicateDialogProps {
  originalMenuItemId: string;
  open: boolean;
  onClose: () => void;
  onSave: (state: MenuItemState) => void;
  saving: boolean;
}

export const MenuItemDuplicateDialog = ({ originalMenuItemId, open, onClose, onSave, saving }: MenuItemDuplicateDialogProps) => {
  const { data } = useMenuItemDialogQuery({
    variables: { menuItemId: originalMenuItemId },
  });

  return !data || saving ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <DialogContent
      open={open}
      onClose={onClose}
      onSave={onSave}
      title="Reusable Menu Item"
      variant="MenuItem"
      initState={{
        menuItem: {
          ...data.currentMenuItem,
          name: `Copy of ${data.currentMenuItem.name}`,
        },
        maxAmount: null,
        availableForOrder: true,
        allowSpecialRequests: true,
        edited: false,
      }}
    />
  );
};

import { Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Switch, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer, useState } from "react";
import { FoodLogNotificationSettings, useEditAthleteFoodLogNotificationSettingsMutation } from "../../../../types";
import FoodLogNotificationSubcriptionTopicsForm from "../Form";
import { foodLogNotificationSettingsFormReducer } from "../Form/utils";
import { athleteAndTeamSubscriptionTopicsToFormState } from "../utils";

interface AthleteFoodLogNotificationSettingsModalProps {
  open: boolean;
  onClose: () => void;
  athleteId: string;
  athleteNotificationSettings: FoodLogNotificationSettings | null;
  teamNotificationSettings: FoodLogNotificationSettings | null;
}

const AthleteFoodLogNotificationSettingsModal = ({ athleteId, open, onClose, ...props }: AthleteFoodLogNotificationSettingsModalProps) => {
  const [overrideTeamSettings, setOverrideTeamSettings] = useState(!!props.athleteNotificationSettings);
  const [editAthleteFoodLogSubscription, { loading: loadingEditTopics }] = useEditAthleteFoodLogNotificationSettingsMutation({});
  const loading = loadingEditTopics;

  const handleSave = async () => {
    const { imageUploads, edits, messages } = subscribedTopicsState;
    editAthleteFoodLogSubscription({
      variables: {
        input: {
          athleteId,
          settings: overrideTeamSettings ? { imageUploads, edits, messages } : null,
        },
      },
      update: () => onClose(),
    });
  };

  const [subscribedTopicsState, subscribedTopicsDispatch] = useReducer(
    foodLogNotificationSettingsFormReducer,
    {
      ...props,
      disabled: !overrideTeamSettings,
    },
    athleteAndTeamSubscriptionTopicsToFormState
  );

  const state = overrideTeamSettings
    ? { ...subscribedTopicsState, disabled: false }
    : {
        ...athleteAndTeamSubscriptionTopicsToFormState({
          ...props,
          athleteNotificationSettings: null,
          disabled: true,
        }),
      };

  const dispatch = overrideTeamSettings ? subscribedTopicsDispatch : () => {};

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Notification Subscriptions" onClose={onClose} />
      <DialogContent>
        {loading ? (
          <Loading progressSize="lg" />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
            <FormControlLabel
              value={overrideTeamSettings}
              control={
                <Switch
                  checked={overrideTeamSettings}
                  onChange={e => {
                    // if overriding the default team setting subscriptions, check all the boxes
                    const useIndividualSettings = !overrideTeamSettings;
                    setOverrideTeamSettings(useIndividualSettings);
                    if (useIndividualSettings) {
                      subscribedTopicsDispatch({ type: "TOGGLE_MESSAGE", payload: useIndividualSettings });
                      subscribedTopicsDispatch({ type: "TOGGLE_EDIT_FOOD_LOG", payload: useIndividualSettings });
                      subscribedTopicsDispatch({ type: "TOGGLE_UPLOAD_IMAGE", payload: useIndividualSettings });
                    }
                  }}
                />
              }
              label={"Override Team Subscription Settings"}
              labelPlacement="start"
            />
            <Box
              sx={{
                alignSelf: "stretch",
                mt: 2,
                height: 110,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FoodLogNotificationSubcriptionTopicsForm state={state} dispatch={dispatch} />
              {!overrideTeamSettings && (
                <Typography variant="subtitle1">*Showing Team notification subscription settings above.</Typography>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AthleteFoodLogNotificationSettingsModal;

import { Box, Button, Typography } from "@mui/material";
import { ZERO_MACROS } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { MacrosProgress } from "../../MacrosProgress/MacrosProgress";

export const MacroProtocolPreviewPlaceholder = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, overflowY: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3">Total Calories</Typography>
        <Button
          disabled
          variant="outlined"
          size="small">
          Edit
        </Button>
      </Box>
      <MacrosProgress
        currentMacros={ZERO_MACROS}
        targetMacros={ZERO_MACROS}
        macroProgressViews="all" />
    </Box>
  );
};

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { useThemeModeContext, writeThemePreferenceToLocalStorage } from "./ThemeModeContextProvider";

export const ThemePreference = () => {
  const { themeMode, setThemeMode } = useThemeModeContext();

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">Theme Preference</Typography>
      <ToggleButtonGroup
        value={themeMode}
        exclusive
        onChange={(_event, value) => {
          if (value !== null) {
            writeThemePreferenceToLocalStorage(value);
            setThemeMode(value);
          }
        }}
      >
        <ToggleButton value="light">
          <LightModeOutlinedIcon /> Light
        </ToggleButton>
        <ToggleButton value="system">
          <SettingsBrightnessIcon /> System
        </ToggleButton>
        <ToggleButton value="dark">
          <DarkModeOutlinedIcon /> Dark
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

import React from "react";
import { ConfirmationDialog } from "../../componentLibrary";

interface DiscardChangesDialogProps {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

const DiscardChangesDialog = ({ open, onClose, onDiscard }: DiscardChangesDialogProps) => (
  <ConfirmationDialog
    open={open}
    title="Discard Unsaved Changes?"
    message="All unsaved changes will be lost."
    onCancel={onClose}
    onConfirm={onDiscard}
    confirmLabel="Discard"
    variant="containedDestructive"
  />
);

export default DiscardChangesDialog;

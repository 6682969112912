import React from "react";
import BaseAsyncAutoComplete, { BasicOption } from "../../../../components/universal/BaseAsyncAutocomplete";
import { TeamLabelFragment, useTeamLabelSearchQuery } from "../../../../types";

interface TeamLabelAutoCompleteProps {
  selectedTeamLabels: readonly TeamLabelFragment[];
  handleChange: (newValue: readonly TeamLabelFragment[]) => void;
}

const useSearchQuery = (query: string) => {
  const { data, loading } = useTeamLabelSearchQuery({
    variables: { query },
    fetchPolicy: "network-only",
  });
  const options = data ? [...data.allTeamLabels.edges] : undefined;
  return { options, loading };
};

const renderOption = (teamLabel: TeamLabelFragment) => {
  return <BasicOption element={teamLabel.name} />;
};

const TeamLabelAutoComplete = ({ handleChange, selectedTeamLabels }: TeamLabelAutoCompleteProps) => {
  const selectedTeamLabelIds = selectedTeamLabels.map(label => label.id);
  const filterUsedOptions = (options: TeamLabelFragment[] | undefined) => {
    if (options === undefined) {
      return undefined;
    }
    return options.filter(tt => !selectedTeamLabelIds.includes(tt.id));
  };

  return (
    <BaseAsyncAutoComplete
      useSearchQuery={useSearchQuery}
      inputPlaceholder="Add Team Label"
      noOptionsText="No Team Labels Found"
      handleChange={handleChange}
      filterUsedOptions={filterUsedOptions}
      multiple
      renderOption={option => renderOption(option)}
      getOptionLabel={(teamLabel: TeamLabelFragment) => teamLabel.name}
      value={selectedTeamLabels.concat()}
    />
  );
};

export default TeamLabelAutoComplete;

import { ButtonProps, Theme, alpha } from "@mui/material";
import { ButtonSize, ButtonVariant, ContainedButtonVariant, OutlinedButtonVariant, isOutlinedVariant, isTextVariant } from "../ThemeUtils";

export const muiButton = {
  styleOverrides: {
    root: ({
      ownerState: { size, variant },
      theme: {
        palette: {
          mode,
          text,
          success,
          info,
          warning,
          error,
          neutral,
          greyscale,
          highEmphasisTextDark,
          highEmphasisTextLight,
          lowEmphasisText,
          boxShadowExtraSmall,
        },
      },
    }: {
      ownerState: ButtonProps;
      theme: Theme;
    }) => {
      const outlined = isOutlinedVariant(variant);
      return {
        borderRadius: "6px",
        boxShadow: isTextVariant(variant) ? "unset" : boxShadowExtraSmall,
        minHeight: 0,
        minWidth: 0,
        padding: {
          small: outlined ? "3px 7px" : "4px 8px",
          medium: outlined ? "7px 11px" : "8px 12px",
          large: outlined ? "9px 15px" : "10px 16px",
        }[size as ButtonSize],
        fontSize: {
          small: 12,
          medium: 14,
          large: 16,
        }[size as ButtonSize],
        lineHeight: {
          small: "20px",
          medium: "20px",
          large: "24px",
        }[size as ButtonSize],
        backgroundColor: {
          contained: mode === "light" ? neutral.black : greyscale[700],
          containedSuccess: success.main,
          containedInformational: info.main,
          containedWarning: warning.main,
          containedDestructive: error.main,
        }[variant as ContainedButtonVariant],
        borderStyle: outlined ? "solid" : "none",
        borderWidth: outlined ? "1px" : "0px",
        borderColor: {
          outlined: mode === "light" ? greyscale[300] : neutral.white,
          outlinedSuccess: success.light,
          outlinedInformational: info.light,
          outlinedWarning: warning.light,
          outlinedDestructive: error.light,
        }[variant as OutlinedButtonVariant],
        color: {
          contained: mode === "light" ? neutral.white : text.primary,
          containedSuccess: mode === "light" ? neutral.white : text.primary,
          containedInformational: mode === "light" ? neutral.white : text.primary,
          containedWarning: mode === "light" ? neutral.white : text.primary,
          containedDestructive: mode === "light" ? neutral.white : text.primary,
          outlined: mode === "light" ? text.primary : "white",
          outlinedSuccess: success.main,
          outlinedInformational: info.main,
          outlinedWarning: warning.main,
          outlinedDestructive: error.main,
          text: mode === "light" ? text.primary : "white",
          textSuccess: success.main,
          textInformational: info.main,
          textWarning: warning.main,
          textDestructive: error.main,
        }[variant as ButtonVariant],
        "&:hover": {
          backgroundColor: {
            contained: mode === "light" ? alpha(neutral.black, 0.9) : greyscale[700],
            containedSuccess: alpha(success.main, 0.9),
            containedInformational: alpha(info.main, 0.9),
            containedWarning: alpha(warning.main, 0.9),
            containedDestructive: alpha(error.main, 0.9),
            outlined: mode === "light" ? neutral.black10 : greyscale[700],
            outlinedSuccess: success.lighter,
            outlinedInformational: info.lighter,
            outlinedWarning: warning.lighter,
            outlinedDestructive: error.lighter,
            text: mode === "light" ? neutral.black10 : greyscale[700],
            textSuccess: success.lighter,
            textInformational: info.lighter,
            textWarning: warning.lighter,
            textDestructive: error.lighter,
          }[variant as ButtonVariant],
          borderColor: {
            outlined: mode === "light" ? greyscale[300] : neutral.white,
            outlinedSuccess: success.light,
            outlinedInformational: info.light,
            outlinedWarning: warning.light,
            outlinedDestructive: error.light,
          }[variant as OutlinedButtonVariant],
          boxShadow: boxShadowExtraSmall,
        },
        "&:disabled": {
          color: {
            contained: mode === "light" ? highEmphasisTextDark : highEmphasisTextLight,
            containedSuccess: mode === "light" ? highEmphasisTextDark : highEmphasisTextLight,
            containedInformational: mode === "light" ? highEmphasisTextDark : highEmphasisTextLight,
            containedWarning: mode === "light" ? highEmphasisTextDark : highEmphasisTextLight,
            containedDestructive: mode === "light" ? highEmphasisTextDark : highEmphasisTextLight,
            outlined: mode === "light" ? lowEmphasisText : lowEmphasisText,
            outlinedSuccess: alpha(success.main, 0.38),
            outlinedInformational: alpha(info.main, 0.38),
            outlinedWarning: alpha(warning.main, 0.38),
            outlinedDestructive: alpha(error.main, 0.38),
            text: mode === "light" ? lowEmphasisText : lowEmphasisText,
            textSuccess: success.light,
            textInformational: info.light,
            textWarning: warning.light,
            textDestructive: error.light,
          }[variant as ButtonVariant],
          backgroundColor: {
            contained: lowEmphasisText,
            containedSuccess: success.light,
            containedInformational: info.light,
            containedWarning: warning.light,
            containedDestructive: error.light,
          }[variant as ContainedButtonVariant],
          borderColor: {
            outlined: mode === "light" ? lowEmphasisText : lowEmphasisText,
            outlinedSuccess: alpha(success.light, 0.38),
            outlinedInformational: alpha(info.light, 0.38),
            outlinedWarning: alpha(warning.light, 0.38),
            outlinedDestructive: alpha(error.light, 0.38),
          }[variant as OutlinedButtonVariant],
        },
      };
    },
    iconSizeLarge: {
      fontSize: 24,
    },
    iconSizeMedium: {
      fontSize: 20,
    },
    iconSizeSmall: {
      fontSize: 16,
    },
  },
};

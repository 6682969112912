import { resolverMap as exchangeAmountRM } from "./ExchangeAmount";
import { resolverMap as exchangeMealTemplateRM, mutationResolverMap as exchangeMealTemplateMRM } from "./ExchangeMealTemplate";
import { resolverMap as macroMealTemplateRM, mutationResolverMap as macroMealTemplateMRM } from "./MacroMealTemplate";
import { mutationResolverMap as mealTemplateMRM } from "./MealTemplate";
import { resolverMap as mealOptionRM, mutationResolverMap as mealOptionMRM } from "./MealOption";
import { resolverMap as mealOptionPendingRM, mutationResolverMap as mealOptionPendingMRM } from "./MealOptionPending";
import { resolverMap as mealPlanRM, mutationResolverMap as mealPlanMRM } from "./MealPlan";
import { resolverMap as exchangeSetRM } from "./ExchangeSet";

const Mutation = {
  ...exchangeMealTemplateMRM,
  ...macroMealTemplateMRM,
  ...mealTemplateMRM,
  ...mealPlanMRM,
  ...mealOptionMRM,
  ...mealOptionPendingMRM,
};

const Query = {};

export default {
  Mutation,
  Query,
  ExchangeAmount: exchangeAmountRM,
  ExchangeMealTemplate: exchangeMealTemplateRM,
  MacroMealTemplate: macroMealTemplateRM,
  MealOption: mealOptionRM,
  MealOptionPending: mealOptionPendingRM,
  MealPlan: mealPlanRM,
  ExchangeSet: exchangeSetRM,
};

import { MealMenuDiningStationForDigitalDisplayFragment } from "apps/web/src/types";
import { Banner, EXCLUDE } from "./DisplaySettings/BannerSelect";
import { Device, IPAD } from "./DisplaySettings/DeviceSelect";
import { FULL_MENU, Layout } from "./DisplaySettings/LayoutSelect";
import { LANDSCAPE, Orientation } from "./DisplaySettings/OrientationSelect";
import { ItemsPerRow, TWO } from "./MenuItemSettings/ItemsPerRowSelect";

export interface DigitalDisplaysConfigState {
  device: Device;
  orientation: Orientation;
  layout: Layout;
  banner: Banner;

  itemsPerRow: ItemsPerRow;
  description: boolean;
  macros: boolean;

  diningStations: DiningStationConfigState[];
}

export interface DiningStationConfigState {
  diningStationName: string;
  isChecked: boolean;
}

export type DigitalDisplaysConfigActions =
  | {
      type: "SET_DEVICE";
      payload: Device;
    }
  | {
      type: "SET_ORIENTATION";
      payload: Orientation;
    }
  | {
      type: "SET_LAYOUT";
      payload: Layout;
    }
  | {
      type: "SET_BANNER";
      payload: Banner;
    }
  | {
      type: "SET_ITEMS_PER_ROW";
      payload: ItemsPerRow;
    }
  | {
      type: "SET_DESCRIPTION";
      payload: boolean;
    }
  | {
      type: "SET_MACROS";
      payload: boolean;
    }
  | {
      type: "SET_DINING_STATION";
      payload: {
        diningStationName: string;
        isChecked: boolean;
      };
    };

export const digitalDisplaysConfigReducer = (
  state: DigitalDisplaysConfigState,
  action: DigitalDisplaysConfigActions
): DigitalDisplaysConfigState => {
  switch (action.type) {
    case "SET_DEVICE":
      return {
        ...state,
        device: action.payload,
      };
    case "SET_ORIENTATION":
      return {
        ...state,
        orientation: action.payload,
      };
    case "SET_LAYOUT":
      return {
        ...state,
        layout: action.payload,
      };
    case "SET_BANNER":
      return {
        ...state,
        banner: action.payload,
      };
    case "SET_ITEMS_PER_ROW":
      return {
        ...state,
        itemsPerRow: action.payload,
      };
    case "SET_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "SET_MACROS":
      return {
        ...state,
        macros: action.payload,
      };
    case "SET_DINING_STATION":
      const diningStation = state.diningStations.find(ds => ds.diningStationName === action.payload.diningStationName);
      if (diningStation) {
        return {
          ...state,
          diningStations: state.diningStations.map(ds =>
            ds.diningStationName !== action.payload.diningStationName
              ? ds
              : { diningStationName: action.payload.diningStationName, isChecked: action.payload.isChecked }
          ),
        };
      } else {
        return state;
      }
  }
};

export const initDigitalDisplaysConfigState = (
  diningStations: readonly MealMenuDiningStationForDigitalDisplayFragment[] | null
): DigitalDisplaysConfigState => {
  return {
    device: IPAD,
    orientation: LANDSCAPE,
    layout: FULL_MENU,
    banner: EXCLUDE,
    itemsPerRow: TWO,
    description: true,
    macros: true,
    diningStations: !diningStations ? [] : diningStations.map(ds => ({ diningStationName: ds.name, isChecked: true })),
  };
};

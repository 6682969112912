import { Box, Grid, Typography, styled } from "@mui/material";
import AddchartIcon from "@mui/icons-material/Addchart";
import { getServingAmountsMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { roundMacros } from "@notemeal/shared/utils/macro-protocol";
import { FullServingAmountFragment, MenuItem, MenuItemChoice } from "apps/web/src/types";
import React from "react";
import { Macros } from "./MacrosContent";
import { hasAddOn } from "./utils";
import { Stack } from "@mui/material";

export const BOX_WITH_DESCRIPTION_HEIGHT = "108px";
export const BOX_NO_DESCRIPTION_HEIGHT = "54px";

export const DESCRIPTION_WITH_WRAP_HEIGHT = "48px";
export const DESCRIPTION_NO_WRAP_HEIGHT = "24px";

export const StyledAddOnIcon = styled(AddchartIcon)(
  ({
    theme: {
      palette: { warning },
    },
  }) => ({
    color: warning.main,
    height: "24px",
    width: "24px",
  })
);

const MenuItemNameStack = styled(Stack)(() => ({
  alignContent: "center",
  gap: 0.5,
}));

const MenuItemCardBox = styled(Box)(
  ({
    theme: {
      palette: { grey, boxShadowSmall },
      spacing,
    },
  }) => ({
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: grey[200],
    boxShadow: boxShadowSmall,
    padding: spacing(2),
    margin: spacing(1),
    borderRadius: "4px", // rounded edge
  })
);

const MenuItemName = styled(Typography)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(0.5),
}));

const MenuItemNameWrap = styled(MenuItemName)(() => ({
  lineHeight: "28px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
}));

const MenuItemDescription = styled(Typography)(({ theme: { palette, spacing } }) => ({
  color: palette.mediumEmphasisText,
  fontSize: "16px",
  fontWeight: 500,
  paddingBottom: spacing(0.5),
}));

const MenuItemDescriptionWrap = styled(MenuItemDescription)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
}));

export type MenuItemWithFullServings = Pick<MenuItem, "name" | "description"> & {
  readonly servingAmounts: readonly FullServingAmountFragment[];
  readonly choices: readonly Pick<MenuItemChoice, "id">[];
};

interface MenuItemCardProps {
  menuItem: MenuItemWithFullServings;
  itemsPerRow: number;
  includeDescription: boolean;
  includeMacros: boolean;
  wrapText: boolean;
}
export const MenuItemCard = ({ menuItem, itemsPerRow, includeDescription, includeMacros, wrapText }: MenuItemCardProps) => {
  const macros = roundMacros(getServingAmountsMacros(menuItem.servingAmounts));
  const tileSize = 12 / itemsPerRow;
  const height = includeDescription ? BOX_WITH_DESCRIPTION_HEIGHT : BOX_NO_DESCRIPTION_HEIGHT;
  const showAddOnIcon = includeMacros && hasAddOn(menuItem);
  // note if anything is added to this card, the slide splitting functionality
  // will need to be updated in `splitContentToSlides()`
  return (
    <Grid item xs={tileSize}>
      <MenuItemCardBox>
        {wrapText ? (
          <Box minHeight={height} height={height}>
            <MenuItemNameStack direction="row">
              <MenuItemNameWrap variant="h3">{menuItem.name}</MenuItemNameWrap>
              {showAddOnIcon && <StyledAddOnIcon />}
            </MenuItemNameStack>
            {includeDescription &&
              (menuItem.description ? (
                <MenuItemDescriptionWrap>{menuItem.description}</MenuItemDescriptionWrap>
              ) : (
                <Typography height={wrapText ? DESCRIPTION_WITH_WRAP_HEIGHT : DESCRIPTION_NO_WRAP_HEIGHT} />
              ))}
          </Box>
        ) : (
          <Box>
            <MenuItemNameStack direction="row">
              <MenuItemName noWrap variant="h3">
                {menuItem.name}
              </MenuItemName>
              {showAddOnIcon && <StyledAddOnIcon />}
            </MenuItemNameStack>
            {includeDescription &&
              (menuItem.description ? (
                <MenuItemDescription noWrap>{menuItem.description}</MenuItemDescription>
              ) : (
                <Typography height={wrapText ? DESCRIPTION_WITH_WRAP_HEIGHT : DESCRIPTION_NO_WRAP_HEIGHT} />
              ))}
          </Box>
        )}
        {includeMacros && <Macros {...macros} />}
      </MenuItemCardBox>
    </Grid>
  );
};

import BarCharIcon from "@mui/icons-material/BarChart";
import { Box, IconButton, Tooltip } from "@mui/material";
import { MacrosProgressMode } from "@notemeal/shared/ui/Macros/hooks";
import { getServingAmountsMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import { useMacroProgressViews } from "apps/web/src/views/Athlete/FoodLogs/contexts/useMacroProgressViews";
import React, { useState } from "react";
import { FullServingAmountFragment } from "../../../types";
import { MacrosProgress } from "../../MacrosProgress/MacrosProgress";
import NutrientAmountDialog from "../../NutrientAmount/Modal";

interface MealOptionMacrosProgressProps {
  targetMacros: Macros;
  servingAmounts: readonly FullServingAmountFragment[];
  mode: MacrosProgressMode;
}

export const MealOptionMacrosProgress = ({ targetMacros, servingAmounts, mode }: MealOptionMacrosProgressProps) => {
  const [nutrientsOpen, setNutrientsOpen] = useState(false);
  const currentMacros = getServingAmountsMacros(servingAmounts);
  const macroProgressModes = useMacroProgressViews();

  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <MacrosProgress
        variant="sm"
        currentMacros={currentMacros}
        targetMacros={targetMacros}
        macroProgressViews={macroProgressModes} />
      <Tooltip title="View Meal Nutrients">
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setNutrientsOpen(true);
          }}
          size="small"
        >
          <BarCharIcon sx={{ color: "accents.yellow.400" }} />
        </IconButton>
      </Tooltip>
      {nutrientsOpen && (
        <NutrientAmountDialog
          disableDRI
          open={nutrientsOpen}
          onClose={() => setNutrientsOpen(false)}
          servingAmounts={servingAmounts} />
      )}
    </Box>
  );
};

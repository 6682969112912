import { ApolloCache } from "@apollo/client";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Button } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { sortByKey } from "@notemeal/utils/sort";
import React, { useState } from "react";
import { TimelineMealFragment, useAddFoodLogActivityWithoutTemplateMutation, useRemoveFoodLogActivityMutation } from "../../../../types";
import FoodLogActivityAddModal from "../FoodLogActivity/Add/Modal";
import FoodLogActivityExpandedCard from "../FoodLogActivity/ExpandedCard";
import FoodLogMealAddModal from "../FoodLogMeal/Add/Modal";
import MealMenuCard from "../MealMenu/Card";
import MealTemplateCard from "../MealTemplate/Card";
import TimelineMealModalLoader from "../TimelineMeal/TimelineModalLoader";
import TimelineMealCard from "../TimelineMealCard";
import { addTimelineMealIfNotPresent, updateAthleteTimelineInCache } from "../cache";
import {
  TimelineItemForDate,
  TimelineMealModalState,
  createTimelineMealModalStateFromMeal,
  createTimelineMealModalStateFromMealInput,
  createTimelineMealModalStateFromMenu,
  createTimelineMealModalStateFromTemplate,
  getTimelineMealForModal,
} from "../utils";
import { useCalendarContext } from "./CalendarContext";

interface TimelineDateColumnExpandedViewProps {
  athleteId: string;
  date: string;
  items: readonly TimelineItemForDate[];
}

const TimelineDateColumnExpandedView = ({ date, items, athleteId }: TimelineDateColumnExpandedViewProps) => {
  const clientTimezone = useClientTimezone();
  const [addMealOpen, setAddMealOpen] = useState(false);
  const [addActivityOpen, setAddActivityOpen] = useState(false);
  const [modalState, setModalState] = useState<TimelineMealModalState | null>(null);
  const timelineMealForModal = getTimelineMealForModal(
    modalState,
    items.flatMap(i => (i.__typename === "TimelineMeal" ? i : []))
  );

  const { onEditDate } = useCalendarContext();

  const [addFoodLogActivityWithoutTemplate] = useAddFoodLogActivityWithoutTemplateMutation({
    update: (cache, { data }) => {
      if (data) {
        updateAthleteTimelineInCache({
          athleteId,
          date,
          timezone: clientTimezone,
          cache,
          updateFn: q => ({
            ...q,
            athlete: {
              ...q.athlete,
              timelineDate: {
                ...q.athlete.timelineDate,
                activities: [...q.athlete.timelineDate.activities, data.addFoodLogActivityWithoutTemplateForAthlete.foodLogActivity],
              },
            },
          }),
        });
      }
    },
  });

  const [removeFoodLogActivity] = useRemoveFoodLogActivityMutation({
    update: (cache, { data }) => {
      if (data) {
        updateAthleteTimelineInCache({
          athleteId,
          date,
          timezone: clientTimezone,
          cache,
          updateFn: q => ({
            ...q,
            athlete: {
              ...q.athlete,
              timelineDate: {
                ...q.athlete.timelineDate,
                activities: q.athlete.timelineDate.activities.filter(
                  i => !(i.__typename === "FoodLogActivity" && i.id === data.removeFoodLogActivity.foodLogActivityId)
                ),
              },
            },
          }),
        });
      }
    },
  });

  const updateMealInCacheFn = (timelineMeal: TimelineMealFragment, cache: ApolloCache<any>) => {
    addTimelineMealIfNotPresent({
      athleteId,
      date,
      timezone: clientTimezone,
      cache,
      timelineMeal: timelineMeal,
    });
    onEditDate();
  };

  return (
    <>
      {sortByKey(items, "start").map(item => {
        if (item.__typename === "TimelineMeal") {
          return (
            <TimelineMealCard
              key={item.id}
              timelineMeal={item}
              onClick={() => setModalState(createTimelineMealModalStateFromMeal(item, null))}
              onClickComment={() => setModalState(createTimelineMealModalStateFromMeal(item, { type: "Comments" }))}
            />
          );
        } else if (item.__typename === "FoodLogActivity") {
          return (
            <FoodLogActivityExpandedCard
              key={item.id}
              onRemove={() => removeFoodLogActivity({ variables: { input: { foodLogActivityId: item.id } } })}
              foodLogActivity={item}
            />
          );
        } else if (item.__typename === "TimelineActivityTemplate") {
          return "";
        } else if (item.__typename === "TimelineMealTemplate") {
          if (
            !items.some(timelineItem => timelineItem.__typename === "TimelineMeal" && timelineItem.mealTemplate?.id === item.template.id)
          ) {
            return (
              <MealTemplateCard
                key={item.template.id}
                timelineMealTemplate={item}
                onClick={() => setModalState(createTimelineMealModalStateFromTemplate(item, clientTimezone, null))}
              />
            );
          } else {
            return null;
          }
        } else {
          return (
            <MealMenuCard
              key={item.id}
              mealMenu={item}
              // TODO: Do I need to declare and intent here?!
              onClick={() => setModalState(createTimelineMealModalStateFromMenu(item, null))}
              onClickComment={() => setModalState(createTimelineMealModalStateFromMenu(item, { type: "Comments" }))}
            />
          );
        }
      })}
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          sx={{ width: "50%" }}
          variant="outlined"
          startIcon={<RestaurantIcon />}
          onClick={() => setAddMealOpen(true)}>
          Add Meal
        </Button>
        <Button
          sx={{ width: "50%" }}
          variant="outlined"
          startIcon={<DirectionsRunIcon />}
          onClick={() => setAddActivityOpen(true)}>
          Add Activity
        </Button>
      </Box>
      {addMealOpen && (
        <FoodLogMealAddModal
          open={addMealOpen}
          onClose={() => setAddMealOpen(false)}
          date={date}
          onAdd={meal => {
            setModalState(createTimelineMealModalStateFromMealInput(meal, null));
          }}
        />
      )}
      {addActivityOpen && (
        <FoodLogActivityAddModal
          open={addActivityOpen}
          onClose={() => setAddActivityOpen(false)}
          onAddFoodLogActivity={(activity, rpe) =>
            addFoodLogActivityWithoutTemplate({
              variables: {
                input: {
                  athleteId,
                  date,
                  activity,
                  rpe,
                  timezone: clientTimezone,
                },
              },
            })
          }
        />
      )}
      {timelineMealForModal && (
        <TimelineMealModalLoader
          intent={modalState?.intent ?? null}
          updateMealInCacheFn={updateMealInCacheFn}
          onClose={() => setModalState(null)}
          open={!!timelineMealForModal}
          timelineMeal={timelineMealForModal}
          athleteId={athleteId}
          date={date}
          onChangeDetails={({ name, start, durationInMinutes, type, timezone }) =>
            modalState &&
            setModalState({
              ...modalState,
              timelineMeal: {
                ...modalState.timelineMeal,
                name,
                start,
                durationInMinutes,
                type,
                timezone,
              },
            })
          }
        />
      )}
    </>
  );
};

export default TimelineDateColumnExpandedView;

import { Box, CardMedia, useTheme } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom-v5-compat";

export const NoAuthNavLayout = () => {
  const {
    palette: { mode },
  } = useTheme();
  const imageUrl = mode === "light" ? "/img/performance_logo_name_black.svg" : "/img/performance_logo_name_white.svg";

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: 400, display: "flex", flexDirection: "column", gap: 4 }}>
          <Box sx={{ pt: "150px", pb: 3 }}>
            <CardMedia
              sx={{ width: "138px", height: "40px" }}
              component="img"
              image={imageUrl} />
          </Box>
          <Outlet />
        </Box>
      </Box>
      <CardMedia
        sx={{ width: "50%", height: "100%", objectFit: "cover" }}
        component="video"
        image="/video/teamworks-login.mp4"
        autoPlay
        muted
      />
    </Box>
  );
};

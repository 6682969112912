import { SxProps } from "@mui/material";
import { BasicOption } from "apps/web/src/components/universal/BaseAsyncAutocomplete";
import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "apps/web/src/components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import {
  FoodOwnerPreviewFragment,
  OrgGroupFoodOwnerCursorConnectionQueryVariables,
  useOrgGroupFoodOwnerCursorConnectionQuery,
} from "apps/web/src/types";
import React, { useCallback } from "react";

interface ManagedByMultiSelectProps {
  selectedUsers: FoodOwnerPreviewFragment[];
  searchText: string;
  onChangeSelectedUsers: (users: FoodOwnerPreviewFragment[]) => void;
  sx?: SxProps;
}

export const FoodManagedByMultiSelect = ({ onChangeSelectedUsers, selectedUsers, searchText, sx }: ManagedByMultiSelectProps) => {
  const getQueryVariables = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
        searchText,
      },
    }),
    [searchText]
  );

  const transformAndFilterOptions = useCallback(
    (edges: FoodOwnerPreviewFragment[] | undefined): FoodOwnerPreviewFragment[] => {
      const selectedUserIds = selectedUsers.map(u => u.id);
      return edges?.filter(u => !selectedUserIds.includes(u.id)) || [];
    },
    [selectedUsers]
  );

  return (
    <InfiniteScrollAutocomplete<
      "orgGroupFoodOwnerCursorConnection",
      FoodOwnerPreviewFragment,
      OrgGroupFoodOwnerCursorConnectionQueryVariables,
      FoodOwnerPreviewFragment,
      true,
      undefined
    >
      sx={sx}
      multiple
      getOptionSelected={usersAreEqual}
      disableCloseOnSelect
      blurOnSelect={false}
      showStartAdornment={false}
      disableRootPadding
      edgesAreEqual={usersAreEqual}
      queryKey="orgGroupFoodOwnerCursorConnection"
      useCursorConnectionQuery={useOrgGroupFoodOwnerCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariables}
      transformAndFilterOptions={transformAndFilterOptions}
      renderOption={user => <BasicOption element={getUserLabel(user)} />}
      handleChange={(_, users) => onChangeSelectedUsers(users)}
      getOptionLabel={getUserLabel}
      getCustomOptionKey={(u: FoodOwnerPreviewFragment) => u.id}
      noOptionsText="No Users Found"
      inputPlaceholder="Managed By"
      value={selectedUsers}
      size="medium"
    />
  );
};

const getUserLabel = (user: FoodOwnerPreviewFragment): string => `${user.firstName} ${user.lastName}`;

const usersAreEqual = (user1: FoodOwnerPreviewFragment, user2: FoodOwnerPreviewFragment) => user1.id === user2.id;

import { InMemoryCache } from "@apollo/client";

import TypesExport from "../types";

export const cache = new InMemoryCache({
  possibleTypes: TypesExport.possibleTypes,
  typePolicies: {
    TeamworksGroup: {
      keyFields: ["id", "type", "teamId"],
    },
    NamedTag: {
      keyFields: ["tag", ["id"]],
      fields: {
        tag: {
          merge: true,
        },
        tagName: {
          merge: true,
        },
      },
    },
    TeamTag: {
      fields: {
        teams: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    AthleteTag: {
      fields: {
        athletes: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    ComboTag: {
      fields: {
        namedChildTags: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    PositionTag: {
      fields: {
        positions: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          },
        },
      },
    },
  },
});

export interface TWColor {
  900: string;
  800: string;
  700: string;
  600: string;
  500: string;
  400: string;
  300: string;
  200: string;
  100: string;
  50: string;
  25: string;
}

export interface TWNeutral {
  black: string;
  black10: string;
  black15: string;
  white: string;
  white10: string;
  white15: string;
}

export const greyscaleLight: TWColor = {
  900: "#101828",
  800: "#1D2939",
  700: "#344054",
  600: "#475467",
  500: "#667085",
  400: "#98A2B3",
  300: "#D0D5DD",
  200: "#EAECF0",
  100: "#F2F4F7",
  50: "#F8FAFB",
  25: "#FCFCFC",
};

export const greyscaleDark: TWColor = {
  900: "#0B0D0D",
  800: "#121516",
  700: "#2A2C2D",
  600: "#414445",
  500: "#595B5C",
  400: "#717373",
  300: "#898A8B",
  200: "#A0A1A2",
  100: "#B8B9B9",
  50: "#D0D0D0",
  25: "#E7E8E8",
};

export const neutral: TWNeutral = {
  black: "#000000",
  black10: "rgba(0, 0, 0, 0.10)",
  black15: "rgba(0, 0, 0, 0.15)",
  white: "#FFFFFF",
  white10: "rgba(255, 255, 255, 0.10)",
  white15: "rgba(255, 255, 255, 0.15)",
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { MenuItemSuggestionCategory } from "../../../../types";
import { getSuggestionCategoryLabel, getSuggestionCategoryTooltip } from "./utils";

interface MenuItemSuggestionCategoryDialogProps {
  open: boolean;
  onClose: () => void;
  value: MenuItemSuggestionCategory | null;
  onDone: (value: MenuItemSuggestionCategory | null) => void;
}

const MenuItemSuggestionCategoryDialog = ({ open, onClose, value, onDone }: MenuItemSuggestionCategoryDialogProps) => {
  const [suggestionCategory, setSuggestionCategory] = useState(value);

  const handleDone = () => {
    onDone(suggestionCategory);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title="Category For Suggestions" onClose={onClose} />
      <DialogContent>
        <Box sx={{ p: 2, backgroundColor: "grey.200", borderRadius: "5px" }}>
          <Typography variant="body2" color="textSecondary">
            * Use the automatically assigned category OR override the category of this item for menu suggestions
          </Typography>
        </Box>

        <List>
          <MenuItemSuggestionCategoryListItem
            value={"combo"}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
          <MenuItemSuggestionCategoryListItem
            value={"main"}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
          <MenuItemSuggestionCategoryListItem
            value={"protein"}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
          <MenuItemSuggestionCategoryListItem
            value={"starch"}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
          <MenuItemSuggestionCategoryListItem
            value={"vegetable"}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
          <MenuItemSuggestionCategoryListItem
            value={"fruit"}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
          <MenuItemSuggestionCategoryListItem
            value={"other"}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
          <MenuItemSuggestionCategoryListItem
            value={null}
            selectedValue={suggestionCategory}
            onSelect={setSuggestionCategory} />
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleDone}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

interface MenuItemSuggestionCategoryListItemProps {
  value: MenuItemSuggestionCategory | null;
  selectedValue: MenuItemSuggestionCategory | null;
  onSelect: (value: MenuItemSuggestionCategory | null) => void;
}

const MenuItemSuggestionCategoryListItem = ({ value, selectedValue, onSelect }: MenuItemSuggestionCategoryListItemProps) => {
  return (
    <ListItem
      dense
      button
      onClick={() => onSelect(value)}>
      <ListItemIcon>
        <Radio
          edge="start"
          checked={value === selectedValue}
          disableRipple />
      </ListItemIcon>
      <ListItemText primary={getSuggestionCategoryLabel(value)} secondary={getSuggestionCategoryTooltip(value)} />
    </ListItem>
  );
};

export default MenuItemSuggestionCategoryDialog;

import { Box, Switch, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { getMealPlanTemplateRmrErrors } from "@notemeal/shared/ui/utils/macroProtocolState";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { Dispatch, useState } from "react";
import SampleDataSummary from "./Anthropometry/SampleDataSummary";
import CalorieBudget from "./CalorieBudget";
import { MacrosTargets } from "./MacroTargets";
import { MacroProtocolAction, MacroProtocolState } from "./reducer/macroProtocolReducer";
import { getAnthroSnapshotFromAnthroState } from "./utils/macroProtocolUtils";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateRows: "1fr auto",
      gridTemplateColumns: "42% 58%",
    },
    budget: {
      gridColumnStart: 1,
      gridRowStart: 1,
    },
    targets: {
      gridColumnStart: 2,
      gridRowStart: 1,
    },
    sampleData: {
      gridColumnStart: 1,
      gridRowStart: 2,
      paddingTop: spacing(4),
    },
    subHeader: {
      height: "40px",
      marginBottom: spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    subHeaderTitle: {
      color: grey[600],
    },
    budgetSwitch: {
      marginLeft: spacing(3),
    },
  })
);

const showRmrErrors = (rmrErrors: string[]) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
    {rmrErrors.map((rmrError, index) => (
      <Typography key={index} variant="body1">
        {rmrError}
      </Typography>
    ))}
  </Box>
);
interface MacroProtocolFormProps {
  state: MacroProtocolState;
  dispatch: Dispatch<MacroProtocolAction>;
}

const MacroProtocolForm = ({ state, dispatch }: MacroProtocolFormProps) => {
  const classes = useStyles();
  const {
    anthropometry,
    calorieBudget: { rmrMethod },
  } = state;
  const rmrErrors = getMealPlanTemplateRmrErrors(rmrMethod, getAnthroSnapshotFromAnthroState(anthropometry));
  const hasRmrErrors = rmrErrors.length > 0;
  const gPerKgOnly = !state.usingCalorieBudget;
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const handleConfirmation = () => {
    setShowConfirmationDialog(false);

    dispatch({
      type: "SET_USING_CALORIE_BUDGET",
      payload: true,
    });
    dispatch({
      type: "RESET_MACROS_TO_PERCENTAGES",
    });
  };
  const onChangeUsingCalorieBudget = (useCalorieBudget: boolean) => {
    const { cho, pro, fat } = state;
    const usesGPerKg = {
      cho: !cho.usesPercent,
      pro: !pro.usesPercent,
      fat: !fat.usesPercent,
    };

    if (useCalorieBudget && usesGPerKg.cho && usesGPerKg.pro && usesGPerKg.fat) {
      setShowConfirmationDialog(true);
    } else {
      dispatch({
        type: "SET_USING_CALORIE_BUDGET",
        payload: useCalorieBudget,
      });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.budget}>
        <div className={classes.subHeader}>
          <Typography variant="h3" className={classes.subHeaderTitle}>
            Calorie Budget
          </Typography>
          <Switch
            checked={state.usingCalorieBudget}
            onChange={e => onChangeUsingCalorieBudget(e.target.checked)}
            className={classes.budgetSwitch}
          />
        </div>
        <CalorieBudget
          state={state.calorieBudget}
          dispatch={dispatch}
          anthropometry={state.anthropometry}
          disabled={!state.usingCalorieBudget}
        />
      </div>
      <div className={classes.targets}>
        <div className={classes.subHeader}>
          <Typography variant="h3" className={classes.subHeaderTitle}>
            Macronutrient Targets
          </Typography>
        </div>
        {hasRmrErrors && showRmrErrors(rmrErrors)}
        {!hasRmrErrors && (
          // TODO: Metric Hotfix is this right?
          <MacrosTargets
            cho={state.cho}
            pro={state.pro}
            fat={state.fat}
            anthropometrySnapshot={getAnthroSnapshotFromAnthroState(state.anthropometry)}
            dispatch={dispatch}
            gPerKgOnly={gPerKgOnly}
          />
        )}
      </div>
      <div className={classes.sampleData}>
        <div className={classes.subHeader}>
          <Typography variant="h3" className={classes.subHeaderTitle}>
            Default/Sample Anthropometry Data
          </Typography>
        </div>
        <SampleDataSummary state={state.anthropometry} usePrefix={false} />
      </div>
      {showConfirmationDialog && (
        <ConfirmationDialog
          open={showConfirmationDialog}
          onCancel={() => setShowConfirmationDialog(false)}
          onConfirm={handleConfirmation}
          title={"All macros using g/kg"}
          message={"Turning on calorie budget will reset all macronutrients to the default percentages. Would you like to proceed?"}
        />
      )}
    </div>
  );
};

export default MacroProtocolForm;

import React, { createContext, useContext } from "react";

interface FoodLogAthlete {
  readonly id: string;
  readonly team: {
    id: string;
  };
  readonly user: {
    id: string;
  };
}

const FoodLogAthleteContext = createContext<FoodLogAthlete>({
  id: "",
  team: {
    id: "",
  },
  user: {
    id: "",
  },
});

interface FoodLogAthleteContextProviderProps {
  children?: React.ReactNode;
  athlete: FoodLogAthlete;
}

export const FoodLogAthleteContextProvider = ({ children, athlete }: FoodLogAthleteContextProviderProps) => {
  return <FoodLogAthleteContext.Provider value={athlete}>{children}</FoodLogAthleteContext.Provider>;
};

export const useFoodLogAthlete = () => useContext(FoodLogAthleteContext);

import { Button, Typography } from "@mui/material";
import React from "react";
import ActionPopoverBase from "./../ActionBase";

interface MealPlanCalendarEventNewWarningProps {
  anchorEl: HTMLElement;
  onClose: () => void;
  onConfirm: () => void;
}

const MealPlanCalendarEventNewWarning = ({ anchorEl, onClose, onConfirm }: MealPlanCalendarEventNewWarningProps) => {
  const warning = "This day is not currently assigned for this meal plan. Adding a new event to an unassigned day will do the following:";

  return (
    <ActionPopoverBase
      anchorEl={anchorEl}
      onClose={onClose}
      title={"New Date Assignment"}
      variant="xl"
      buttons={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button onClick={onConfirm}>Yes</Button>
        </>
      }
    >
      <Typography variant="body2" sx={{ pb: 4, whiteSpace: "pre-wrap" }}>
        {warning}
        <Typography variant="body2" sx={{ pt: 1, pb: 0.5 }}>{`\u2022 Add this date assignment to the meal plan`}</Typography>
        <Typography
          variant="body2"
          sx={{ pb: 2, pt: 0.5 }}
        >{`\u2022 Add this event to the meal plan schedule for all assigned days`}</Typography>
        Would you like to proceed?
      </Typography>
    </ActionPopoverBase>
  );
};

export default MealPlanCalendarEventNewWarning;

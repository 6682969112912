import { Autocomplete, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import React, { Reducer, useReducer, useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import { TeamsPickerTeamFragment, useTeamsPickerTeamsQuery } from "../../types";
import AdvancedSelectionDialog from "../../views/Tags/Dialogs/Mutation/AdvancedSelection/AdvancedSelectionDialog";
import {
  AdvancedSelectionState,
  buildInitialCreateAdvancedSelectionStateFromTeams,
  isAdvancedSelectionEmpty,
} from "../../views/Tags/reducers/advancedSelectionReducers";
import LoadingBackdrop from "../universal/LoadingBackdrop";
import { AthleteAssignAction, AthleteAssignState, athleteAssignReducer } from "./athleteAssignReducer";

interface AthleteAssignmentProps {
  open: boolean;
  onClose: () => void;
  onSave: (newState: AthleteAssignState<TeamsPickerTeamFragment>) => void;
  initialState: AthleteAssignState<TeamsPickerTeamFragment>;
}

const AthleteAssignment = ({ open, onClose, onSave, initialState }: AthleteAssignmentProps) => {
  const { data, loading } = useTeamsPickerTeamsQuery();

  const [state, dispatch] = useReducer<Reducer<AthleteAssignState<TeamsPickerTeamFragment>, AthleteAssignAction<TeamsPickerTeamFragment>>>(
    athleteAssignReducer,
    initialState
  );
  const [advancedSelectOpen, setAdvancedSelectOpen] = useState(false);

  const title = "Share Meal Plan Template";

  const handleSaveAdvancedSelection = (advancedSelectionState: AdvancedSelectionState) => {
    if (isAdvancedSelectionEmpty(advancedSelectionState)) {
      onSave({
        __typename: "Teams",
        teams: [],
        advancedSelectionState: null,
      });
    } else {
      // reset to use Teams picker
      onSave({
        __typename: "Tags",
        teams: null,
        advancedSelectionState: advancedSelectionState,
      });
    }
  };

  return loading || !data?.teams ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : state.__typename === "Teams" ? (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <Autocomplete
          sx={{ grow: 1 }}
          multiple
          filterSelectedOptions
          options={data.teams.concat()}
          getOptionLabel={option => option.name}
          disableCloseOnSelect
          blurOnSelect={false}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              multiline
              maxRows={3}
              size="small"
              label={"Share With Teams"}
              InputLabelProps={{
                ...params.InputLabelProps,
              }}
            />
          )}
          isOptionEqualToValue={(option: TeamsPickerTeamFragment, value: TeamsPickerTeamFragment) => option.id === value.id}
          value={state.teams.concat()}
          onChange={(_event, newValue) => dispatch({ type: "UPDATE_TEAMS_ACTION", payload: newValue })}
        />
        <Button
          sx={{ alignSelf: "flex-end" }}
          variant="text"
          onClick={() => setAdvancedSelectOpen(true)}>
          Advanced Selection
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onSave(state)}>Save</Button>
      </DialogActions>
      {advancedSelectOpen && (
        <AdvancedSelectionDialog
          title={title}
          open={advancedSelectOpen}
          onClose={() => setAdvancedSelectOpen(false)}
          onSave={handleSaveAdvancedSelection}
          initialState={buildInitialCreateAdvancedSelectionStateFromTeams(state.teams)}
        />
      )}
    </Dialog>
  ) : (
    <AdvancedSelectionDialog
      title={title}
      open={open}
      onClose={() => onClose()}
      onSave={handleSaveAdvancedSelection}
      initialState={state.advancedSelectionState}
    />
  );
};

export default AthleteAssignment;

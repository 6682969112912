import { DataProxy } from "@apollo/client";
import { safeReadQuery } from "../../../../client";

import {
  AthletesPreviewQuery,
  AthletesPreviewDocument,
  AthleteFragment,
  TeamRosterDocument,
  TeamAnthropometryDocument,
  TeamRosterQuery,
  TeamAnthropometryQuery,
} from "../../../../types";

interface GenericTeamQuery {
  team: {
    athletes: ReadonlyArray<{ id: string }>;
  };
}

export const updateCacheSetIsArchived = (cache: DataProxy, athleteId: string, isArchived: boolean) => {
  const athletesPreviewQuery = safeReadQuery<AthletesPreviewQuery>(cache, {
    query: AthletesPreviewDocument,
    variables: {},
  });
  if (!athletesPreviewQuery) {
    return;
  }
  cache.writeQuery<AthletesPreviewQuery>({
    query: AthletesPreviewDocument,
    data: {
      ...athletesPreviewQuery,
      athletes: athletesPreviewQuery.athletes.map(ath => (ath.id === athleteId ? { ...ath, isArchived } : ath)),
    },
  });
};

const addAthleteToTeamQuery = <T extends GenericTeamQuery>(
  cache: DataProxy,
  document: any,
  variables: any,
  toAddAthlete: AthleteFragment
) => {
  try {
    const teamQueryData = safeReadQuery<T>(cache, {
      query: document,
      variables,
    });
    if (!teamQueryData) {
      return;
    }
    const updatedData = {
      ...teamQueryData,
      team: {
        ...teamQueryData.team,
        athletes: [
          ...teamQueryData.team.athletes.filter(ath => ath.id !== toAddAthlete.id),
          {
            ...toAddAthlete,
            isArchived: false,
          },
        ],
      },
    };
    cache.writeQuery<T>({
      query: document,
      data: updatedData,
    });
  } catch {}
};

interface UpdateCacheUnarchiveAthlete {
  cache: DataProxy;
  mutationResult: any;
  teamId: string;
}

export const updateCacheUnarchiveAthlete = ({ cache, mutationResult, teamId }: UpdateCacheUnarchiveAthlete): void => {
  // Use this to remove athlete during a delete OR archive
  if (mutationResult.data) {
    addAthleteToTeamQuery<TeamRosterQuery>(cache, TeamRosterDocument, { id: teamId }, mutationResult.data.unarchiveAthlete);
    addAthleteToTeamQuery<TeamAnthropometryQuery>(cache, TeamAnthropometryDocument, { id: teamId }, mutationResult.data.unarchiveAthlete);
  }
};

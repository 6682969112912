import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      height: "100%",
      boxSizing: "border-box",
      overflow: "hidden",
    },
  })
);

interface ListsContainerProps {
  className?: string;
  children: React.ReactNode;
}

const ListsContainer = ({ className, children }: ListsContainerProps) => {
  const classes = useStyles();
  return <div className={classNames(classes.listContainer, className)}>{children}</div>;
};

export default ListsContainer;

import { z } from "zod";
import { GenericFoodSchema } from "./FoodSchema";

export const SodexoFoodSchema = GenericFoodSchema.merge(
  z.object({
    source: z.literal("sodexo"),
    sodexoItemId: z.string(),
    sodexoItemHash: z.string(),
  })
);

import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import {
  AddRestaurantMenuLinkPlateInput,
  RestaurantMenuLinkPlateItemFormFragment,
  AddRestaurantMenuLinkPlateItemInput,
  EditRestaurantMenuLinkInput,
  EditRestaurantMenuLinkPlateItemInput,
  EditRestaurantMenuLinkPlateInput,
} from "../../../../types";
import { RestaurantMenuLinkPlateState, RestaurantMenuLinkState } from "../types";

export const getAddRestaurantMenuLinkPlateInput = ({
  items,
  isDefault,
  bulkOrderAmountInput,
  position,
}: RestaurantMenuLinkPlateState): AddRestaurantMenuLinkPlateInput => ({
  position,
  isDefault,
  bulkOrderAmount: inputToNumber(bulkOrderAmountInput),
  items: items.map(getAddRestaurantMenuLinkPlateItemInput),
});

type getEditRestaurantMenuLinkPlateInputsValue = Pick<EditRestaurantMenuLinkInput, "addPlates" | "editPlates" | "removePlateIds">;

export const getEditRestaurantMenuLinkPlateInputs = (linkState: RestaurantMenuLinkState): getEditRestaurantMenuLinkPlateInputsValue => {
  const finalPlateIds = linkState.plates.map(l => l.id);

  const toAddPlates = linkState.plates.filter(p => !linkState.initialPlateIds.includes(p.id));
  const toEditPlates = linkState.plates.filter(p => linkState.initialPlateIds.includes(p.id));
  const removePlateIds = linkState.initialPlateIds.filter(pId => !finalPlateIds.includes(pId));

  return {
    addPlates: toAddPlates.map(getAddRestaurantMenuLinkPlateInput),
    editPlates: toEditPlates.map(getEditRestaurantMenuLinkPlateInput),
    removePlateIds,
  };
};

const getEditRestaurantMenuLinkPlateInput = ({
  id,
  items,
  isDefault,
  bulkOrderAmountInput,
  position,
  initialItemIds,
  editedItemIds,
}: RestaurantMenuLinkPlateState): EditRestaurantMenuLinkPlateInput => {
  const finalItemIds = items.map(i => i.id);

  const toAddItems = items.filter(i => !initialItemIds.includes(i.id));
  const toEditItems = items.filter(i => initialItemIds.includes(i.id) && editedItemIds.includes(i.id));
  const removeItemIds = initialItemIds.filter(iId => !finalItemIds.includes(iId));

  return {
    restaurantMenuLinkPlateId: id,
    isDefault,
    position,
    bulkOrderAmount: inputToNumber(bulkOrderAmountInput),
    addItems: toAddItems.map(getAddRestaurantMenuLinkPlateItemInput),
    editItems: toEditItems.map(getEditRestaurantMenuLinkPlateItemInput),
    removeItemIds,
  };
};

const getAddRestaurantMenuLinkPlateItemInput = ({
  amount,
  position,
  specialRequests,
  options,
  menuItem,
}: RestaurantMenuLinkPlateItemFormFragment): AddRestaurantMenuLinkPlateItemInput => ({
  menuItemId: menuItem.id,
  options: options.map(({ menuItemChoiceOption, amount }) => ({
    menuItemChoiceOptionId: menuItemChoiceOption.id,
    amount,
  })),
  amount,
  position,
  specialRequests,
});

const getEditRestaurantMenuLinkPlateItemInput = ({
  id,
  amount,
  position,
  specialRequests,
  options,
}: RestaurantMenuLinkPlateItemFormFragment): EditRestaurantMenuLinkPlateItemInput => ({
  restaurantMenuLinkPlateItemId: id,
  amount,
  position,
  specialRequests,
  options: options.map(({ menuItemChoiceOption, amount }) => ({
    menuItemChoiceOptionId: menuItemChoiceOption.id,
    amount,
  })),
});

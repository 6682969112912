import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import React, { useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";

type SimpleEntity = {
  id: string;
  name: string;
};

interface EntityDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
  type: string;
  entity?: SimpleEntity;
}

const EntityDialog = ({ open, onClose, onSave, type, entity }: EntityDialogProps) => {
  const [name, setName] = useState<string>(entity?.name || "");
  const handleSave = () => {
    onSave(name);
    onClose();
  };

  const title = entity ? "Edit" : "Create";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title={`${title} ${type}`} onClose={onClose} />
      <DialogContent>
        <TextField
          autoFocus
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
          placeholder={type}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EntityDialog;

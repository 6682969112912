import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import { AgreementFormFragment } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iframe: {
      marginTop: "-88px",
      marginLeft: "-72px",
      height: "500px",
    },
  })
);

interface AgreementFormModalProps {
  agreementForm: AgreementFormFragment;
  open: boolean;
  onAddAggrementFormSubmission: () => void;
  onClose?: () => void;
}

export const AgreementFormModal = ({ agreementForm, open, onAddAggrementFormSubmission, onClose = () => {} }: AgreementFormModalProps) => {
  const classes = useStyles();

  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const title = agreementForm.title;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <iframe
          className={classes.iframe}
          title={title}
          src={agreementForm.url}
          frameBorder="0" />
        <FormControlLabel
          sx={{ alignSelf: "center" }}
          control={<Checkbox checked={agreementAccepted} onChange={(_, value) => setAgreementAccepted(value)} />}
          label={agreementForm.acceptAgreementText}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onAddAggrementFormSubmission} disabled={!agreementAccepted}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { BulkEditDialogContentProps } from "../types";

type BaseContentProps = Pick<BulkEditDialogContentProps, "isOpen" | "onCancel" | "saving"> & {
  onSubmit: () => void;
  title: string;
  renderContent: () => React.ReactNode;
  submitDisabled: boolean;
  submitText?: string;
};

const BaseContent = ({
  isOpen,
  onCancel,
  saving,
  onSubmit,
  title,
  renderContent,
  submitDisabled,
  submitText = "Submit",
}: BaseContentProps) => (
  <Dialog
    open={isOpen}
    onClose={onCancel}
    maxWidth="md">
    <DialogTitle title={title} onClose={onCancel} />
    <DialogContent>{renderContent()}</DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        onClick={onCancel}
        disabled={saving}>
        Cancel
      </Button>
      <Button onClick={onSubmit} disabled={submitDisabled}>
        {submitText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default BaseContent;

import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, InputAdornment, TextField, Typography } from "@mui/material";
import { formatTimeRange } from "@notemeal/shared/ui/utils/dateTimes";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import { Macros } from "@notemeal/shared/utils/macro-protocol";
import React, { Dispatch, memo } from "react";
import { MealInputWithId, ScheduleAction } from "./scheduleReducer";

const MACRO_WIDTH = "170px";

interface EditMealMacroTargetsProps {
  meal: MealInputWithId;
  macros: Macros;
  viewOnly?: boolean;
  dispatch: Dispatch<ScheduleAction>;
  choTotalParts: number;
  proTotalParts: number;
  fatTotalParts: number;
}

const EditMealMacroTargets = ({
  meal,
  macros,
  viewOnly,
  dispatch,
  choTotalParts,
  proTotalParts,
  fatTotalParts,
}: EditMealMacroTargetsProps) => {
  const {
    choRatio,
    proRatio,
    fatRatio,
    choRatioInput,
    proRatioInput,
    fatRatioInput,
    meal: { name, start, end },
  } = meal;

  const { cho, pro, fat, kcal } = macros;

  type MacroLabel = "choRatio" | "proRatio" | "fatRatio";

  const getMacroTargetTextField = (
    label: string,
    macro: number,
    macroRatio: number,
    macroTotalParts: number,
    macroRatioInput: string,
    macroLabel: MacroLabel
  ) => (
    <TextField
      sx={{ width: MACRO_WIDTH }}
      type="number"
      disabled={viewOnly}
      inputProps={{ min: 0, step: 1 }}
      value={macroRatioInput}
      label={label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2">
                {macroRatio}/{macroTotalParts}
              </Typography>
              <Typography sx={{ ml: 0.5, color: "greyscale.400" }} variant="body2">
                ({Math.round(macro)}g)
              </Typography>
            </Box>
          </InputAdornment>
        ),
      }}
      onChange={e => handleChange(e.target.value, macroLabel)}
    />
  );

  const handleChange = (value: string | null, macroLabel: MacroLabel) => {
    const newValue = value ?? "0";
    const newState = {
      ...meal,
      [macroLabel]: inputToNumber(newValue) ?? meal[macroLabel],
      [`${macroLabel}Input`]: newValue,
    };
    dispatch({
      type: "EDIT_MEAL",
      payload: newState,
    });
  };

  return (
    <Card
      sx={{
        height: "72px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box sx={{ height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
        <RestaurantIcon fontSize="small" sx={{ color: "greyscale.400" }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body1Medium">{name}</Typography>
          <Typography sx={{ color: "mediumEmphasisText" }} variant="body2">
            {formatTimeRange(start, end)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ py: 1, display: "flex", gap: 1, alignItems: "center" }}>
        {getMacroTargetTextField("CHO Ratio", cho, choRatio, choTotalParts, choRatioInput, "choRatio")}
        {getMacroTargetTextField("PRO Ratio", pro, proRatio, proTotalParts, proRatioInput, "proRatio")}
        {getMacroTargetTextField("FAT Ratio", fat, fatRatio, fatTotalParts, fatRatioInput, "fatRatio")}
        <Typography sx={{ ml: 1, width: "200px", alignSelf: "flex-end" }}>Sample kcal: {Math.round(kcal || 0)} kcal</Typography>
      </Box>
    </Card>
  );
};

export default memo(EditMealMacroTargets);

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import LoadingBackdrop from "../../../../components/universal/LoadingBackdrop";
import { EditRestaurantLocationInput, RestaurantLocationFragment } from "../../../../types";
import Form from "./Form";
import { RestaurantLocationFormState, getEditFormState, getEditRestaurantLocationInput, locationFormTooltips } from "./Form/utils";

interface RestaurantLocationsEditDialogProps {
  open: boolean;
  onClose: () => void;
  onEdit: (input: EditRestaurantLocationInput) => void;
  saving: boolean;
  location: RestaurantLocationFragment;
}

const RestaurantLocationsEditDialog = ({ open, onClose, onEdit, saving, location }: RestaurantLocationsEditDialogProps) => {
  const [formState, setFormState] = useState<RestaurantLocationFormState>(getEditFormState(location));
  const tooltips = locationFormTooltips(formState);

  if (saving) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const onSave = () => {
    if (tooltips.length > 0) {
      return;
    }
    onEdit(getEditRestaurantLocationInput(formState, location.id));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title="Edit Restaurant Location" onClose={onClose} />
      <DialogContent>
        <Form state={formState} onChange={setFormState} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <TWItemizedTooltip title="Fix the following:" items={tooltips}>
          <Button onClick={onSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default RestaurantLocationsEditDialog;

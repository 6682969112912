import { Chip, SxProps, Tooltip } from "@mui/material";
import React from "react";

const HIDE_AFTER = 3;

interface Item {
  id: string | number;
  label: string;
}

interface ChipListPreviewProps<I extends Item> {
  items: readonly I[];
  hideItemsAfter?: number;
  chipClassName?: string;
  sx?: SxProps;
  getToolTipText?: (items: readonly I[]) => string;
}

const ChipListPreview = <I extends Item>({
  items,
  hideItemsAfter = HIDE_AFTER,
  chipClassName,
  sx,
  getToolTipText,
}: ChipListPreviewProps<I>) => {
  const shownItems = items.slice(0, hideItemsAfter);
  const hiddenItems = items.slice(hideItemsAfter);
  return (
    <>
      {shownItems.map(item => (
        <Chip
          sx={{ ...sx, mt: 1, mr: 1 }}
          size="small"
          key={item.id}
          label={item.label}
          className={chipClassName} />
      ))}
      {hiddenItems.length > 0 &&
        (getToolTipText ? (
          <Tooltip title={getToolTipText(hiddenItems)}>
            <Chip
              sx={{ ...sx, mt: 1, mr: 1 }}
              size="small"
              label={`+${hiddenItems.length}`}
              className={chipClassName} />
          </Tooltip>
        ) : (
          <Chip
            sx={{ ...sx, mt: 1, mr: 1 }}
            size="small"
            label={`+${hiddenItems.length}`}
            className={chipClassName} />
        ))}
    </>
  );
};

export default ChipListPreview;

import { Grid, Typography, styled } from "@mui/material";
import { MealMenuDiningStationForDigitalDisplayFragment } from "apps/web/src/types";
import React from "react";
import { MenuItemCard, MenuItemWithFullServings } from "./MenuItemCard";
import { DigitalDisplaysConfigState } from "./reducer";
import { shouldWrapText } from "./utils";

const MenuItemGrid = styled(Grid)(({ theme: { spacing } }) => ({
  padding: spacing(0, 2),
}));

const DiningStationName = styled(Typography)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
  marginLeft: spacing(3),
}));

interface DiningStationContentProps {
  diningStation: MealMenuDiningStationForDigitalDisplayFragment;
  menuItems: MenuItemWithFullServings[];
  state: DigitalDisplaysConfigState;
}
export const DiningStationContent = ({ diningStation, menuItems, state }: DiningStationContentProps) => {
  const itemsPerRow = parseInt(state.itemsPerRow);
  const includeDescription = state.description;
  const includeMacros = state.macros;
  const diningStationName = diningStation.name;
  return (
    <>
      <DiningStationName variant="h2">{diningStationName}</DiningStationName>
      <MenuItemGrid container>
        {menuItems.map(menuItem => {
          return (
            <MenuItemCard
              menuItem={menuItem}
              itemsPerRow={itemsPerRow}
              includeDescription={includeDescription}
              includeMacros={includeMacros}
              wrapText={shouldWrapText(state)}
            />
          );
        })}
      </MenuItemGrid>
    </>
  );
};

import { z } from "zod";
import { GenericFoodSchema } from "./FoodSchema";
import { IntegerSchema } from "@notemeal/validators";

export const CbordFoodSchema = GenericFoodSchema.merge(
  z.object({
    source: z.literal("cbord"),
    cbordItemOid: IntegerSchema,
    cbordUpdateCounts: z.string(),
  })
);

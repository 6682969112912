import { TextField, Typography } from "@mui/material";
import React from "react";

interface MenuSelectionItemSpecialRequestsInputProps {
  specialRequests: string | null;
  onChange: (specialRequests: string | null) => void;
}

const MenuSelectionItemSpecialRequestsInput = ({ specialRequests, onChange }: MenuSelectionItemSpecialRequestsInputProps) => {
  return (
    <>
      <Typography variant="h4">Special Requests</Typography>
      <TextField
        value={specialRequests}
        placeholder={"No onions, please!"}
        onChange={e => onChange(e.target.value)}
        multiline
        maxRows={4}
        sx={{ pt: 1, pr: 2 }}
        inputProps={{ maxLength: 400 }}
      />
    </>
  );
};

export default MenuSelectionItemSpecialRequestsInput;

import React from "react";
import { OrgLinkerOrgFragment } from "../../../../../../types";
import BaseNotemealOrgsList from "./BaseNotemealOrgsList";

interface NotemealOrgsListProps {
  selectedId: string | null;
  searchQuery: string;
  onLink: (org: OrgLinkerOrgFragment) => void;
  onCancel: () => void;
  orgs: readonly OrgLinkerOrgFragment[];
  loading: boolean;
}

const NotemealOrgsList = (props: NotemealOrgsListProps) => {
  const { selectedId } = props;

  return (
    <BaseNotemealOrgsList
      {...props}
      title="Unlinked Nutrition Orgs"
      columnMode={selectedId ? "NoAction" : "Link"}
      filterOrg={({ isLinked, hasSearchQueryMatch, isSelected }) => {
        if (isLinked) {
          return false;
        }
        return isSelected || hasSearchQueryMatch;
      }}
    />
  );
};

export default NotemealOrgsList;

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Chip, IconButton, SxProps, Theme, Typography, styled } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import IngredientsList from "@notemeal/shared/ui/ServingAmount/IngredientsList";
import LimitedAccessWarning from "@notemeal/shared/ui/ServingAmount/LimitedAccessWarning";
import ServingAmountTooltip from "@notemeal/shared/ui/ServingAmount/Tooltip";
import { DraggableSpring } from "@notemeal/shared/ui/hooks/useDraggable";
import { useAthleteHighlightFromServingAmount } from "@notemeal/shared/ui/utils/Foods/foods";
import { roundMacros, scaleMacros } from "@notemeal/shared/utils/macro-protocol";
import classnames from "classnames";
import React, { ReactNode, useState } from "react";
import { animated } from "react-spring";
import { ReactEventHandlers } from "react-use-gesture/dist/types";
import { FullServingAmountFragment, FullServingFragment } from "../../../types";
import ServingAmountEditAmount from "./Amount";
import ServingsMenu from "./ServingsMenu";

const LabelRow = styled(Box)(() => ({ display: "flex", flexDirection: "row", alignItems: "center" }));
const ChipLabelWrapper = styled(Box)(() => ({ overflow: "hidden", textOverflow: "ellipsis" }));

const useStyles = makeStyles(({ spacing, palette: { like, dislike } }: Theme) =>
  createStyles({
    chipLimitedAccess: {
      height: spacing(7),
    },
    chipDefault: {
      height: spacing(4),
    },
    chip: {
      marginTop: spacing(),
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      flexWrap: "nowrap",
      borderRadius: spacing(1),
    },
    marginBottom: {
      marginBottom: spacing(),
    },
    greenChip: {
      backgroundColor: like.lighter,
      "&:hover": {
        backgroundColor: like.light,
      },
      "&:focus": {
        backgroundColor: like.light,
      },
    },
    redChip: {
      backgroundColor: dislike.lighter,
      "&:hover": {
        backgroundColor: dislike.light,
      },
      "&:focus": {
        backgroundColor: dislike.light,
      },
    },
    chipLabel: {
      flexGrow: 1,
      flexShrink: 1,
      minWidth: 0,
    },
    foodName: {
      lineHeight: 1.2,
      fontWeight: "bold",
    },
    notExpanded: { borderRadius: spacing(1) },
    expanded: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
  })
);

export interface RenderServingsMenuArgs {
  anchorEl: HTMLElement;
  onClose: () => void;
  servingAmount: FullServingAmountFragment;
}

interface ServingAmountsEditChipProps {
  servingAmount: FullServingAmountFragment;
  onIncrement: () => void;
  onDecrement: () => void;
  onDelete: () => void;
  onReplaceServing: (serving: FullServingFragment, amount?: number) => void;
  onSetAmount: (amount: number) => void;
  expanded: boolean;
  onChangeExpanded: (expanded: boolean) => void;
  bindProps?: ReactEventHandlers;
  spring?: DraggableSpring;
  servingsMenuDisabled?: boolean;
  className?: string;
  recipeIngredient: boolean;
  onDeconstructRecipe?: () => void;
  onLoadIngredients: (ingredientsCount: number) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const ServingAmountsEditChip = ({
  servingAmount,
  onIncrement,
  onDecrement,
  onDelete,
  onSetAmount,
  onReplaceServing,
  expanded,
  onChangeExpanded,
  bindProps,
  spring,
  recipeIngredient,
  servingsMenuDisabled: _servingMenuDisabled,
  className,
  onDeconstructRecipe,
  onLoadIngredients,
  sx,
  disabled,
}: ServingAmountsEditChipProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { highlightType: highlightColor } = useAthleteHighlightFromServingAmount(servingAmount.serving.foodOrRecipe);

  const { serving, amount } = servingAmount;
  const foodOrRecipe = serving.foodOrRecipe;
  const servingsMenuDisabled = disabled || _servingMenuDisabled || !foodOrRecipe.hasFullAccess;

  const chipClassName = classnames([
    classes.chip,
    className,
    ...(foodOrRecipe.__typename === "Recipe" ? [] : [classes.marginBottom]),
    {
      [classes.redChip]: highlightColor === "Red",
      [classes.greenChip]: highlightColor === "Green",
      [classes.expanded]: expanded,
      [classes.notExpanded]: !expanded,
      [classes.chipDefault]: foodOrRecipe.hasFullAccess,
      [classes.chipLimitedAccess]: !foodOrRecipe.hasFullAccess,
    },
  ]);

  let style: any = {};
  if (spring) {
    const { zIndex, y, shadow } = spring;
    style = {
      zIndex,
      boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
      transform: y.to(y => `translate3d(0, ${y}px, 0)`),
    };
  }

  const editAmountComponent = (
    <ServingAmountEditAmount
      disabled={disabled}
      onSetAmount={onSetAmount}
      amount={servingAmount.amount}
      serving={serving}
      onClick={e => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
      }}
      ariaLabel="Units count"
      servingsMenuDisabled={servingsMenuDisabled}
    />
  );

  const ingredientsComponent = expanded && (
    <IngredientsList
      recipeId={foodOrRecipe.id}
      hasOwnNutrients={serving.hasOwnNutrients}
      perRecipeYield={serving.perRecipeYield || 1}
      units={serving.units}
      style={style}
      onDeconstruct={onDeconstructRecipe}
      onLoad={onLoadIngredients}
      onClose={() => onChangeExpanded(!expanded)}
    />
  );

  return (
    <>
      <Chip
        sx={{ ...sx, backgroundColor: "greyscale.100" }}
        className={chipClassName}
        classes={{ label: classes.chipLabel }}
        clickable
        onDelete={disabled ? undefined : onDelete}
        component={animated.div}
        style={style}
        role="banner"
        icon={
          disabled ? undefined : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              {bindProps && <DragIndicatorIcon {...bindProps} />}
              <IconButton
                sx={{ m: 0, p: 0, color: servingAmount.amount <= 0.125 ? "disabled" : "common.black" }}
                onClick={e => {
                  e.stopPropagation();
                  onDecrement();
                }}
                disabled={amount <= 0.125}
                disableRipple
                aria-label="Remove unit"
                size="large"
              >
                <RemoveCircleIcon />
              </IconButton>
              <IconButton
                sx={{ m: 0, p: 0, color: "common.black" }}
                onClick={e => {
                  e.stopPropagation();
                  onIncrement();
                }}
                aria-label="Add unit"
                disableRipple
                size="large"
              >
                <AddCircleIcon />
              </IconButton>
            </Box>
          )
        }
        label={
          <Label
            serving={serving}
            amount={amount}
            recipeIngredient={recipeIngredient}
            expanded={expanded}
            onChangeExpanded={onChangeExpanded}
            editAmountComponent={editAmountComponent}
            servingsMenuDisabled={servingsMenuDisabled}
            setAnchorEl={setAnchorEl}
          />
        }
      />
      {ingredientsComponent}
      {anchorEl && (
        <ServingsMenu
          foodOrRecipeId={servingAmount.serving.foodOrRecipe.id}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          includeRecipeOnlyIngredients={recipeIngredient}
          onSelect={(serving, amount) => onReplaceServing(serving, amount)}
          allowServingRequest={servingAmount.serving.foodOrRecipe.__typename === "GenericFood"}
        />
      )}
    </>
  );
};

export default ServingAmountsEditChip;

interface FullLabelProps {
  serving: FullServingFragment;
  amount: number;
  recipeIngredient: boolean;
  expanded: boolean;
  servingsMenuDisabled: boolean;
  onChangeExpanded: (expanded: boolean) => void;
  setAnchorEl: (el: HTMLElement | null) => void;
  editAmountComponent: ReactNode;
}

const Label = ({
  serving,
  onChangeExpanded,
  setAnchorEl,
  amount,
  editAmountComponent,
  expanded,
  recipeIngredient,
  servingsMenuDisabled,
}: FullLabelProps) => {
  const classes = useStyles();
  const { foodOrRecipe, macros } = serving;
  const { name, __typename, hasFullAccess } = foodOrRecipe;

  if (hasFullAccess) {
    const isExpandable = __typename === "Recipe";
    return (
      <ServingAmountTooltip serving={serving} amount={amount}>
        <ChipLabelWrapper
          onClick={e => {
            if (isExpandable) {
              onChangeExpanded(!expanded);
            } else if (!recipeIngredient && !servingsMenuDisabled) {
              setAnchorEl(e.currentTarget);
            }
          }}
        >
          <LabelRow>
            <Typography
              variant="subtitle1"
              classes={{ root: classes.foodName }}
              noWrap>
              {name}
            </Typography>
            {isExpandable && (expanded ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />)}
          </LabelRow>
          {editAmountComponent}
        </ChipLabelWrapper>
      </ServingAmountTooltip>
    );
  } else {
    const scaledMacros = roundMacros(scaleMacros(macros, amount));

    return (
      <ChipLabelWrapper
        onClick={e => {
          if (!recipeIngredient && !servingsMenuDisabled) {
            setAnchorEl(e.currentTarget);
          }
        }}
      >
        <LabelRow>
          <Typography
            variant="subtitle1"
            classes={{ root: classes.foodName }}
            noWrap>
            {name}
          </Typography>
          <LimitedAccessWarning serving={serving} />
        </LabelRow>
        {editAmountComponent}
        <MacrosSummaryLabel
          macros={scaledMacros}
          kcalPrefix
          variant="xs" />
      </ChipLabelWrapper>
    );
  }
};

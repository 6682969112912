import { Box, Divider } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { ImportPlannedMenuType } from "./ImportMenuSchema";
import { useImportPlannedMenu } from "./ImportPlannedMenuContext";
import { ImportPlannedMenuFormWeek } from "./ImportPlannedMenuFormWeek";

export const ImportPlannedMenuFormMeals = () => {
  const { form, mealsLoading } = useImportPlannedMenu();

  const weeksWithMealsArray = useFieldArray<ImportPlannedMenuType>({
    control: form.control,
    name: "weeks",
  });

  return (
    <Box sx={{ height: `calc(100vh - 280px)`, mt: 3 }}>
      <SectionHeader
        header="Edit Meals"
        subcopy="Make adjustments to the order details of each meal below. Additional adjustments can be made at a later time as well."
      />
      <Divider sx={{ pt: 2 }} />
      {mealsLoading ? (
        <Loading />
      ) : (
        weeksWithMealsArray.fields.map((week, weekIndex) => <ImportPlannedMenuFormWeek key={week.id} weekFormIndex={weekIndex} />)
      )}
    </Box>
  );
};

import { initMacros, Macros } from "@notemeal/shared/utils/macro-protocol";
import { getExchangeAmountsMacros } from "../ExchangeAmount/utils";
import { ExchangeAmountFragment, MealTemplateForTargetsFragment } from "../types";

export const getMealTemplateMacroTargets = (mealTemplate: MealTemplateForTargetsFragment): Macros | null => {
  return mealTemplate.__typename === "ExchangeMealTemplate"
    ? getExchangeAmountsMacros(mealTemplate.exchangeTargets || [])
    : mealTemplate.cho !== null && mealTemplate.pro !== null && mealTemplate.fat !== null
    ? initMacros(mealTemplate.cho, mealTemplate.pro, mealTemplate.fat)
    : null;
};

export const getMealTemplateExchangeTargets = (mealTemplate: MealTemplateForTargetsFragment): readonly ExchangeAmountFragment[] => {
  return mealTemplate.__typename === "ExchangeMealTemplate" ? mealTemplate.exchangeTargets : [];
};

import { Dialog } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { CreateFoodGroupInput } from "../../../types";
import FoodGroupForm from "../Form";
import { FoodGroupFormState } from "../Form/reducer";

export interface FoodGroupFoodUpdate {
  foodId: string;
  foodGroupId: string;
}

interface FoodGroupModalNewProps {
  open: boolean;
  onClose: () => void;
  onCreateFoodGroup: ({ name, source, foodIds }: CreateFoodGroupInput) => void;
}

export const FoodGroupModalNew = ({ open, onClose, onCreateFoodGroup }: FoodGroupModalNewProps) => {
  const handleCreateFoodGroup = (state: FoodGroupFormState) => {
    if (state.name === null || state.name === "") {
      return;
    }
    onCreateFoodGroup({
      name: state.name,
      source: state.source,
      foodIds: state.foods.map(f => f.id),
    });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Create Food Group" onClose={onClose} />
      <FoodGroupForm
        foodGroup={undefined}
        loading={false}
        onSave={handleCreateFoodGroup}
        onCancel={onClose} />
    </Dialog>
  );
};

import { Macros, ZERO_MACROS, getMacroCaloriesPerGram, initMacros } from "@notemeal/shared/utils/macro-protocol";
import { genericSort, sortByKey } from "@notemeal/utils/sort";
import { gcd } from "mathjs";
import { ExchangeAmountFragment, ExchangeFragment, ExchangeRatioFragment, ExchangeType } from "../types";

const MAX_EXCHANGE_RATIO = 4;

export interface ExchangeTotals {
  [exchangeId: string]: number | undefined;
}

// In the future we'll probably have to solve the harder problem of matching to any
// of the exchanges with all of the macros as moving parts and the number of exchanges as moving parts as well
export const pickClosestExchangeAmounts = (
  exchanges: readonly ExchangeFragment[],
  exchangeTypes: readonly ExchangeType[],
  macros: Macros
): ExchangeRatioFragment[] => {
  if (!exchangeTypes.length) return [];
  // !IMPORTANT: Filter out high fat protein exchanges because foods can't be composed of these.
  // Otherwise, when we search for "Nature Valley Chewy Bar" or "RxBar", we get
  // HF Pro: 2, Starch: 1, rather than LeanPro: 1, Starch: 1, Fat: 1
  const proteinExchanges = exchanges.filter(ex => ex.type === "protein");
  const leanestProtein = sortByKey(proteinExchanges, "fat")[0];
  const limitedExchanges = exchangeTypes.length > 1 ? [...exchanges.filter(ex => ex.type !== "protein"), leanestProtein] : exchanges;

  const exchangeCombos = getExchangeCombosMatchingTypes(limitedExchanges, exchangeTypes);
  if (exchangeCombos.length === 0) {
    throw Error(`No matching exchanges for types ${exchangeTypes}`);
  }

  const allExchangeAmountCombos = exchangeCombos.flatMap(combo => {
    return getAllExchangeAmountCombos(combo);
  });

  const foodMacroCaloriesBasis = getMacroCaloriesBasis(macros);

  const combosAndErrors = allExchangeAmountCombos.map(combo => {
    const comboMacroBasis = exchangeAmountsToMacroCalorieBasis(combo);
    const squaredError = getMacroBasisSquaredError(foodMacroCaloriesBasis, comboMacroBasis);
    return { combo, squaredError };
  });

  // Sort ascending based on squared error
  const sortedCombosAndErrors = combosAndErrors.sort((ce1, ce2) => {
    return ce1.squaredError - ce2.squaredError;
  });

  // Could return multiple combos if they are close enough here
  return sortedCombosAndErrors[0].combo;
};

const getMacroCaloriesBasis = ({ cho, pro, fat }: Macros): Macros => {
  const choCalories = getMacroCaloriesPerGram("cho") * cho;
  const proCalories = getMacroCaloriesPerGram("pro") * pro;
  const fatCalories = getMacroCaloriesPerGram("fat") * fat;
  const totalCalories = choCalories + proCalories + fatCalories;
  return initMacros(choCalories / totalCalories, proCalories / totalCalories, fatCalories / totalCalories);
};

const getExchangeCombosMatchingTypes = (
  exchanges: readonly ExchangeFragment[],
  exchangeTypes: readonly ExchangeType[]
): ExchangeFragment[][] => {
  const uniqueExchangeTypes = [...new Set(exchangeTypes)];
  return uniqueExchangeTypes.reduce((combos: ExchangeFragment[][], type: ExchangeType) => {
    const exchangesByType = exchanges.filter(e => e.type === type);
    if (!combos.length) {
      return exchangesByType.map(exchange => [exchange]);
    }
    return combos.flatMap(combo => {
      return exchangesByType.map(exchange => {
        return combo.concat([exchange]);
      });
    });
  }, []);
};

const getAllExchangeAmountCombos = (exchangeCombo: ExchangeFragment[]): ExchangeRatioFragment[][] => {
  if (!exchangeCombo.length) return [];
  const exchangeRatioAmounts = [...Array(MAX_EXCHANGE_RATIO).keys()];
  const rawExchangeAmountCombos = exchangeCombo.reduce((combos: ExchangeRatioFragment[][], exchange: ExchangeFragment) => {
    if (!combos.length) {
      return exchangeRatioAmounts.map(ratio => [{ exchange, ratio, __typename: "ExchangeRatio" as const }]);
    }
    return combos.flatMap(combo => {
      return exchangeRatioAmounts.map(ratio => {
        return combo.concat([{ exchange, ratio, __typename: "ExchangeRatio" as const }]);
      });
    });
  }, []);
  // Filter out all 0s and gcd =/= 1 (aka repeated, scaled up ratios)
  return rawExchangeAmountCombos.filter(combo => {
    const ratios = combo.map(({ ratio }) => ratio);
    if (ratios.length === 1) {
      return ratios[0] !== 0;
    } else {
      return !(ratios.every(a => a === 0) || gcd(...ratios) !== 1);
    }
  });
};

const exchangeAmountsToMacroCalorieBasis = (exchangeAmounts: ExchangeRatioFragment[]): Macros => {
  const exchangeAmountMacros = exchangeAmounts.reduce(
    (totals: Macros, { exchange, ratio }) =>
      initMacros(totals.cho + exchange.cho * ratio, totals.pro + exchange.pro * ratio, totals.fat + exchange.fat * ratio),
    ZERO_MACROS
  );
  return getMacroCaloriesBasis(exchangeAmountMacros);
};

// Basis values (i.e. sum to 1) so no normalization performed here
const getMacroBasisSquaredError = (expected: Macros, actual: Macros): number => {
  return (
    Math.pow(Math.abs(expected.cho - actual.cho), 2) +
    Math.pow(Math.abs(expected.pro - actual.pro), 2) +
    Math.pow(Math.abs(expected.fat - actual.fat), 2)
  );
};

export const sumExchangeTotals = (exchangeTotals: ExchangeTotals[]): ExchangeTotals => {
  const newExchangeTotals: ExchangeTotals = {};
  exchangeTotals.forEach(et => {
    const exchangeIds = Object.keys(et);
    exchangeIds.forEach(exchangeId => {
      if (newExchangeTotals[exchangeId]) {
        (newExchangeTotals[exchangeId] as number) += et[exchangeId] || 0;
      } else {
        newExchangeTotals[exchangeId] = et[exchangeId];
      }
    });
  });
  return newExchangeTotals;
};

export const mapExchangeTotals = (exchangeTotals: ExchangeTotals, mapFn: (total: number) => number): ExchangeTotals => {
  const mappedExchangeTotals: ExchangeTotals = {};
  Object.keys(exchangeTotals).forEach(exchangeId => {
    const exchangeAmount = exchangeTotals[exchangeId];
    if (exchangeAmount === undefined) return;
    mappedExchangeTotals[exchangeId] = mapFn(exchangeAmount);
  });
  return mappedExchangeTotals;
};

export const getExchangeTypeAnchoredMacro = (type: ExchangeType): "cho" | "pro" | "fat" | null => {
  switch (type) {
    case "starch":
    case "fruit":
    case "vegetable":
      return "cho";

    case "protein":
    case "dairy": // Dairy arguably has both cho and pro as principal macros, how would that work?
      // following above comment up, current decision is for implementation to go with protein scaling - N
      return "pro";

    case "fat":
      return "fat";

    default:
      return null;
  }
};

export const EXCHANGE_TYPE_ORDER: readonly ExchangeType[] = ["starch", "fruit", "vegetable", "dairy", "protein", "fat"];

export const sortExchangeFn = (e1: ExchangeFragment, e2: ExchangeFragment) => {
  return genericSort(EXCHANGE_TYPE_ORDER.indexOf(e1.type), EXCHANGE_TYPE_ORDER.indexOf(e2.type)) || genericSort(e1.name, e2.name);
};

export const sortExchanges = <E extends ExchangeFragment>(exchanges: readonly E[]): readonly E[] => {
  return [...exchanges].sort(sortExchangeFn);
};

// Converting from ExchangeTotals to ExchangeAmounts loses information, should only be used for ease of displaying on client
export const exchangeTotalsToAmounts = (
  exchanges: readonly ExchangeFragment[],
  totals: ExchangeTotals
): readonly ExchangeAmountFragment[] => {
  return exchanges.flatMap(exchange => {
    const amount = totals[exchange.id];
    if (amount === undefined) {
      return [];
    } else {
      return [
        {
          id: exchange.id,
          amount,
          pickListServingIds: null,
          exchange,
          __typename: "ExchangeAmount",
        },
      ];
    }
  });
};

export interface ExchangeFraction {
  numerator: number;
  denominator: number;
  label: string; // i.e. the shortName
  id: string;
  type: ExchangeType;
}

export const sortExchangeFractions = (fracArr: ExchangeFraction[]) => {
  return fracArr.sort((e1, e2) => {
    return genericSort(EXCHANGE_TYPE_ORDER.indexOf(e1.type), EXCHANGE_TYPE_ORDER.indexOf(e2.type)) || genericSort(e1.label, e2.label);
  });
};

interface createExchangeFractionsArgs {
  targetExchangeAmounts: readonly ExchangeAmountFragment[];
  currentExchangeAmounts: readonly ExchangeAmountFragment[];
}

export const createExchangeFractions = ({ targetExchangeAmounts, currentExchangeAmounts }: createExchangeFractionsArgs) => {
  const idToExchangeFractions = new Map<string, ExchangeFraction>();
  currentExchangeAmounts.forEach(et =>
    idToExchangeFractions.set(et.exchange.id, {
      numerator: et.amount,
      denominator: 0,
      label: et.exchange.shortName,
      id: et.exchange.id,
      type: et.exchange.type,
    })
  );
  targetExchangeAmounts.forEach(tgt => {
    const currentFrac = idToExchangeFractions.get(tgt.exchange.id);
    if (currentFrac) {
      idToExchangeFractions.set(tgt.exchange.id, {
        ...currentFrac,
        denominator: tgt.amount,
      });
    } else {
      idToExchangeFractions.set(tgt.exchange.id, {
        numerator: 0,
        denominator: tgt.amount,
        label: tgt.exchange.shortName,
        id: tgt.exchange.id,
        type: tgt.exchange.type,
      });
    }
  });
  return sortExchangeFractions(Array.from(idToExchangeFractions.values()));
};

export const ORDERED_EXCHANGE_TYPES: readonly ExchangeType[] = ["protein", "starch", "fruit", "vegetable", "dairy", "fat"];

import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { Dispatch, useRef } from "react";
import { RecipeFormAction } from "./utils";

interface InstructionsProps {
  steps: readonly string[];
  dispatch: Dispatch<RecipeFormAction>;
  disabled?: boolean;
}

export const Instructions = ({ steps, dispatch, disabled }: InstructionsProps) => {
  const focusIndexRef = useRef<number | null>(null);

  const handleStepChange = (text: string, index: number) => {
    const newSteps = text.split("\n");
    if (newSteps.length > 1) {
      focusIndexRef.current = index + newSteps.length - 1;
    }
    const _steps = [...steps.slice(0, index), ...newSteps, ...steps.slice(index + 1)];
    dispatch({ type: "CHANGE_STEPS", value: _steps });
  };

  const handleStepDelete = (index: number) => {
    const _steps = steps.length === 1 ? [""] : [...steps.slice(0, index), ...steps.slice(index + 1)];
    dispatch({ type: "CHANGE_STEPS", value: _steps });
  };

  const handleStepMerge = (index: number) => {
    if (index === 0) {
      return;
    }
    focusIndexRef.current = index - 1;
    const _steps = [
      ...steps.slice(0, index - 1),
      `${steps[index - 1]}${steps[index] ? " " : ""}${steps[index]}`,
      ...steps.slice(index + 1),
    ];
    dispatch({ type: "CHANGE_STEPS", value: _steps });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h4">Instructions</Typography>
      <Box sx={{ overflowY: "auto", maxHeight: 400 }}>
        {steps.map((step, index) => (
          <TextField
            disabled={disabled}
            onKeyDown={event => {
              const inputTarget = event.target as HTMLInputElement;
              const { selectionStart, selectionEnd } = inputTarget;
              if (selectionStart === 0 && selectionEnd === 0 && event.key === "Backspace") {
                event.preventDefault();
                handleStepMerge(index);
              }
            }}
            key={index}
            value={step}
            sx={{ mt: 1 }}
            inputRef={input => {
              if (index === focusIndexRef.current && input) {
                input.focus();
                focusIndexRef.current = null;
              }
            }}
            fullWidth
            multiline
            placeholder={steps.length === 1 ? "Add a step to the instructions" : undefined}
            onChange={e => handleStepChange(e.target.value, index)}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  disablePointerEvents
                  sx={{ alignSelf: "start", pt: 1.5, opacity: disabled ? 0.33 : 1 }}>
                  {index + 1}.
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={disabled} onClick={() => handleStepDelete(index)}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

import CheckIcon from "@mui/icons-material/CheckCircle";
import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import LoadingBackdrop from "../../../components/universal/LoadingBackdrop";
import {
  CheckInKioskAthletePreviewFragment,
  KioskMealMenuPreviewFragment,
  useCheckInKioskAthletesQuery,
  useSetMealMenuCheckInsMutation,
} from "../../../types";
import { ATHLETE_LIST_ITEM_TEXT_WIDTH } from "../Configure/AthleteListItem";
import { useKioskCheckInContext } from "../Contexts/CheckInContext";
import Kiosk from "../KioskDialog";
import Content from "./AthleteNavigation";
import CheckInKioskMenuDialog from "./MenuDialog";
import { athleteCheckedIntoMealMenu, mealMenuAppliesToAthlete } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRow: {
      display: "flex",
      paddingBottom: theme.spacing(1),
      borderBottom: `thin solid ${theme.palette.grey[300]}`,
    },
    item: {
      width: theme.spacing(20),
      textAlign: "center",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    placeholder: {
      width: ATHLETE_LIST_ITEM_TEXT_WIDTH + theme.spacing(2),
    },
  })
);

interface CheckInKioskProps {
  open: boolean;
  onClose: () => void;
  mealMenus: readonly KioskMealMenuPreviewFragment[];
}

const CheckInKiosk = ({ open, onClose, mealMenus }: CheckInKioskProps) => {
  const classes = useStyles();
  const mealMenuIds = mealMenus.map(mm => mm.id);

  const { data, loading } = useCheckInKioskAthletesQuery({
    variables: { mealMenuIds },
  });
  const [selectedAthleteId, setSelectedAthleteId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);
  const selectedAthlete = data?.athletesForMealMenus.find(a => a.id === selectedAthleteId);

  const { setCheckInsForAthleteInCache } = useKioskCheckInContext();
  const [setMealMenuCheckIns] = useSetMealMenuCheckInsMutation();

  const handleSave = (athleteId: string, selectedMenuIds: string[], unSelectedMealMenuIds: string[]) => {
    setMealMenuCheckIns({
      variables: {
        input: {
          athleteId,
          addMealMenuIds: selectedMenuIds,
          removeMealMenuIds: unSelectedMealMenuIds,
        },
      },
      update: (cache, { data }) => {
        data?.setMealMenuCheckInsForAthlete && setCheckInsForAthleteInCache(cache, athleteId, selectedMenuIds);
      },
    });
  };

  const renderAthleteListItemCheckIns = (athlete: CheckInKioskAthletePreviewFragment) =>
    mealMenus.map(mm => {
      if (!mealMenuAppliesToAthlete(athlete, mm)) {
        return <div key={mm.id} className={classes.item} />;
      } else if (athleteCheckedIntoMealMenu(athlete, mm.id)) {
        return <CheckIcon
          key={mm.id}
          className={classes.item}
          fontSize="large"
          sx={{ color: "success.light" }} />;
      } else {
        return <CheckIcon
          key={mm.id}
          className={classes.item}
          color="disabled"
          fontSize="large" />;
      }
    });

  const header = (
    <div className={classes.headerRow}>
      <div className={classes.placeholder} />
      {mealMenus.flatMap(mm => {
        return (
          <Typography
            variant="h3"
            key={mm.id}
            className={classes.item}>
            {mm.name}
          </Typography>
        );
      })}
    </div>
  );

  const selectAthlete = (athleteId: string | null) => {
    if (athleteId === null) {
      setSelectedAthleteId(null);
      return;
    }
    const foundAthlete = data?.athletesForMealMenus.find(a => a.id === athleteId);
    if (foundAthlete) {
      const possibleMenus = mealMenus.filter(m => m.athletes.map(a => a.id).includes(athleteId));
      if (possibleMenus.length === 1) {
        const mealMenuId = possibleMenus[0].id;
        // Is the Meal menu previously checked in?
        const shouldRemove = foundAthlete.checkInsForMealMenus.find(ci => ci.mealMenuId === mealMenuId) !== undefined;
        const selectedMenuIds = shouldRemove ? [] : [mealMenuId];
        const unSelectedMealMenuIds = shouldRemove ? [mealMenuId] : [];
        handleSave(athleteId, selectedMenuIds, unSelectedMealMenuIds);
      } else {
        setSelectedAthleteId(athleteId);
      }
    }
  };

  return (
    <Kiosk open={open} onClose={onClose}>
      {!data || loading ? (
        <LoadingBackdrop open={open} onClose={onClose} />
      ) : (
        <>
          <Content
            selectedAthleteId={selectedAthleteId}
            setSelectedAthleteId={selectAthlete}
            selectedTeamId={selectedTeamId}
            setSelectedTeamId={setSelectedTeamId}
            athletes={data.athletesForMealMenus}
            mealMenus={mealMenus}
            header={header}
            renderCheckInAthleteListItem={renderAthleteListItemCheckIns}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          {selectedAthlete && (
            <CheckInKioskMenuDialog
              open={selectedAthleteId !== null}
              onClose={() => {
                setSelectedAthleteId(null);
                setSearchText("");
                setSelectedTeamId(null);
              }}
              athlete={selectedAthlete}
              menus={mealMenus.filter(m => m.athletes.map(a => a.id).includes(selectedAthlete.id))}
              handleSave={handleSave}
            />
          )}
        </>
      )}
    </Kiosk>
  );
};

export default CheckInKiosk;

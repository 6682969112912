import CloseIcon from "@mui/icons-material/Close";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, IconButton, TextField } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { ScheduleModalEditorMealState } from "../../../reducers/ScheduleModalEditor";
import MealTypeSelect from "../../Meal/TypeSelect";
import { useScheduleModalEditorContext } from "./utils";

interface ScheduleEditModalMealItemProps {
  meal: ScheduleModalEditorMealState;
}

const ScheduleEditModalMealItem = ({ meal }: ScheduleEditModalMealItemProps) => {
  const { onChangeMealName, onChangeMealStart, onChangeMealEnd, onChangeMealType, onRemoveMeal } = useScheduleModalEditorContext();
  const commonPayload = { mealId: meal.id, activityId: null };

  return (
    <Card
      sx={{
        height: "80px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
        gap: 2,
      }}
    >
      <Box sx={{ mr: "2px", height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
      <RestaurantIcon fontSize="small" sx={{ alignSelf: "center", color: "greyscale.400" }} />
      <TextField
        autoFocus={!meal.name}
        label="Meal Name"
        value={meal.name}
        onChange={e =>
          onChangeMealName({
            ...commonPayload,
            name: e.target.value,
          })
        }
      />
      <DesktopTimePicker
        sx={{ mt: 3 }}
        label="Start"
        value={meal.startValue}
        onChange={datetime => onChangeMealStart({ ...commonPayload, value: datetime })}
      />
      <DesktopTimePicker
        sx={{ mt: 3 }}
        label="End"
        value={meal.endValue}
        onChange={datetime => onChangeMealEnd({ ...commonPayload, value: datetime })}
      />
      <MealTypeSelect
        sx={{ width: "190px" }}
        value={meal.type}
        onChange={type =>
          onChangeMealType({
            ...commonPayload,
            type,
          })
        }
      />
      <IconButton onClick={() => onRemoveMeal(commonPayload)} size="large">
        <CloseIcon />
      </IconButton>
    </Card>
  );
};

export default ScheduleEditModalMealItem;

import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, IconButton, Popover, Typography } from "@mui/material";
import React from "react";
import { LG_POPOVER_WIDTH, MD_POPOVER_WIDTH, MealPlanCalendarEventPopoverBaseProps, XL_POPOVER_WIDTH, variantStyles } from "./Base";

interface MealPlanCalendarEventActionPopoverBaseProps extends MealPlanCalendarEventPopoverBaseProps {
  title: string;
  buttons: React.ReactNode;
}

const MealPlanCalendarEventPopoverActionBase = ({
  children,
  buttons,
  title,
  anchorEl,
  onClose,
  variant = "md",
}: MealPlanCalendarEventActionPopoverBaseProps) => {
  const popoverWidth = variant === "md" ? MD_POPOVER_WIDTH : variant === "xl" ? XL_POPOVER_WIDTH : LG_POPOVER_WIDTH;

  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: popoverWidth + 10,
      }}
      PaperProps={{
        sx: variantStyles[variant],
        elevation: 24,
      }}
    >
      <Box sx={({ spacing }) => ({ mx: 3 })}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", py: 2 }}>
          <Typography variant="h3">{title}</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, py: 3 }}>{buttons}</Box>
      </Box>
    </Popover>
  );
};

export default MealPlanCalendarEventPopoverActionBase;

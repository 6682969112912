import { IAthletePreferenceRowInput } from "../utils";

type Header = "First Name" | "Last Name" | "Position" | "Avoided Foods";

export interface SerializedExportRow {
  firstName: string;
  lastName: string;
  position: string;
  avoidedFoods: string;
}
interface ExportColumnDef {
  header: Header;
  key: keyof SerializedExportRow;
  width: number;
}

export const exportColumns: ExportColumnDef[] = [
  { header: "First Name", key: "firstName", width: 10 },
  { header: "Last Name", key: "lastName", width: 15 },
  { header: "Position", key: "position", width: 10 },
  { header: "Avoided Foods", key: "avoidedFoods", width: 50 },
];

export const serializeRows = (rows: readonly IAthletePreferenceRowInput[]): SerializedExportRow[] => {
  return rows.map(r => {
    return {
      firstName: r.firstName,
      lastName: r.lastName,
      position: r.position?.name || "",
      avoidedFoods: r.dislikedFoodGroupNames,
    };
  });
};

import RssFeedIcon from "@mui/icons-material/RssFeed";
import { Button, Card, CardActionArea, CardContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import FoodLogNotificationSubcriptionTopicsForm from "../../../../components/FoodLog/NotificationSettings/Form";
import FoodLogNotificationSubcriptionTopicsModal from "../../../../components/FoodLog/NotificationSettings/TeamModal";
import { FoodLogNotificationSettings } from "../../../../types";
import DashboardCard from "../Card";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    card: {},
    notificationsPanelContent: {
      padding: theme.spacing(3),
      width: "75%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    panelText: {},
    panelIcon: {},
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    paddingTop: { paddingTop: theme.spacing(3) },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    question: {
      display: "flex",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface TeamSummaryNotificationsProps {
  teamId: string;
  teamNotificationSettings: FoodLogNotificationSettings | null;
}

const TeamSummaryNotifications = ({ teamId, teamNotificationSettings }: TeamSummaryNotificationsProps) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const classes = useStyles();

  const readOnlyFormState = teamNotificationSettings ? teamNotificationSettings : null;

  const editButton = (
    <Button
      variant="text"
      onClick={() => setEditModalOpen(true)}
      aria-label="Edit Notifications"
      size="small">
      Edit
    </Button>
  );

  return (
    <DashboardCard title="Notifications" options={editButton}>
      <div className={classes.notificationsPanelContent}>
        {readOnlyFormState && Object.values(readOnlyFormState).some(x => x === true) ? (
          <div>
            <Card raised className={classes.card}>
              <CardActionArea onClick={() => setEditModalOpen(true)}>
                <CardContent className={classes.row}>
                  <div className={classes.panelText}>
                    <Typography variant="h3">{`Subscribed`}</Typography>
                    <Typography variant="body2">You will receive notifications for the checked athlete actions below:</Typography>
                  </div>
                  <div className={classes.panelIcon}>
                    <RssFeedIcon sx={{ color: "success.main" }} fontSize="large" />
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
            <div className={classes.paddingTop}>
              <FoodLogNotificationSubcriptionTopicsForm state={{ ...readOnlyFormState, disabled: true }} dispatch={() => {}} />
            </div>
          </div>
        ) : (
          <Card raised className={classes.card}>
            <CardActionArea onClick={() => setEditModalOpen(true)}>
              <CardContent className={classes.row}>
                <div className={classes.panelText}>
                  <Typography variant="h3">Not Subscribed</Typography>
                  <Typography variant="body2">Click to subscribe to Team notifications.</Typography>
                </div>
                <div className={classes.panelIcon}>
                  <RssFeedIcon sx={{ color: "greyscale.300" }} fontSize="large" />
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      </div>
      {editModalOpen && (
        <FoodLogNotificationSubcriptionTopicsModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          teamId={teamId}
          teamNotificationSettings={teamNotificationSettings}
        />
      )}
    </DashboardCard>
  );
};

export default TeamSummaryNotifications;

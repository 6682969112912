import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Typography } from "@mui/material";
import React from "react";

export const ThemeViewerIcon = () => {
  return (
    <>
      <Typography variant="h2">Icon</Typography>
      <Box sx={{ alignItems: "flex-start", height: "100px", display: "flex", gap: 2 }}>
        <SettingsIcon fontSize="extraExtraExtraLarge" />
        <SettingsIcon fontSize="extraExtraLarge" />
        <SettingsIcon fontSize="extraLarge" />
        <SettingsIcon fontSize="large" />
        <SettingsIcon fontSize="medium" />
        <SettingsIcon fontSize="small" />
        <SettingsIcon fontSize="extraSmall" />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography>Default (Medium):</Typography>
          <SettingsIcon />
        </Box>
        <Typography variant="subtitle1">Icon Font Sizes: XXXL 48, XXL 40, XL 32, L 24, M 20, S 16, XS 12</Typography>
      </Box>
    </>
  );
};

import CloseIcon from "@mui/icons-material/Close";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Box, Card, IconButton, TextField } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { safeSerializeTime } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import ActivityTypeSelect from "../../Activity/TypeSelect";
import { FoodLogMergeFormActivityState } from "./utils";

interface FoodLogMergeFormActivityProps {
  state: FoodLogMergeFormActivityState;
  onChange: (state: FoodLogMergeFormActivityState) => void;
  onRemove: () => void;
}

const FoodLogMergeFormActivity = ({ state, onChange, onRemove }: FoodLogMergeFormActivityProps) => {
  const { name, type, startValue, endValue } = state;

  const handleChangeStartEnd = (timeKey: "startTime" | "endTime", value: Date | null) => {
    const timeValueKey = timeKey === "startTime" ? "startValue" : "endValue";
    const stringValue = safeSerializeTime(value);
    onChange({
      ...state,
      [timeValueKey]: value,
      [timeKey]: stringValue ? stringValue : state[timeKey],
    });
  };

  return (
    <Card
      sx={{
        pb: 1,
        height: "72px",
        display: "flex",
        alignItems: "flex-end",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
        gap: 2,
      }}
    >
      <Box sx={{ height: "100%", width: "6px", backgroundColor: "accents.orangeDark.400" }} />
      <DirectionsRunIcon fontSize="small" sx={{ alignSelf: "center", color: "accents.orangeDark.400" }} />
      <TextField
        sx={{ flex: 1 }}
        autoFocus={!name}
        label="Name"
        value={name}
        onChange={e =>
          onChange({
            ...state,
            name: e.target.value,
          })
        }
      />
      <DesktopTimePicker
        sx={{ flex: 1 }}
        label="Start"
        value={startValue}
        onChange={datetime => handleChangeStartEnd("startTime", datetime)}
      />
      <DesktopTimePicker
        sx={{ flex: 1 }}
        label="End"
        value={endValue}
        onChange={datetime => handleChangeStartEnd("endTime", datetime)} />
      <ActivityTypeSelect
        sx={{ flex: 1 }}
        value={type}
        onChange={type =>
          onChange({
            ...state,
            type,
          })
        }
      />
      <IconButton onClick={onRemove}>
        <CloseIcon />
      </IconButton>
    </Card>
  );
};

export default FoodLogMergeFormActivity;

import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import { AgreementFormModal } from "apps/web/src/components/AgreementForm/AgreementFormModal";
import React, { useReducer, useState } from "react";
import { AgreementFormState, agreementFormReducer, getInitState } from "./reducer";
import { buildAgreementFormTooltips } from "./utils";

interface AgreementFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (agreementForm: AgreementFormState) => void;
  initAgreementForm: AgreementFormState | null;
}

const ACCEPT_TEXT_CHAR_LIMIT = 200;

const AgreementFormDialog = ({ initAgreementForm, open, onClose, onSave }: AgreementFormDialogProps) => {
  const [state, dispatch] = useReducer(agreementFormReducer, getInitState(initAgreementForm));
  const [showPreview, setShowPreview] = useState(false);
  const canSaveTooltips = buildAgreementFormTooltips(state);

  const handleSave = () => {
    if (canSaveTooltips.length > 0) {
      return;
    }
    onSave(state);
    onClose();
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={onClose}>
        <DialogTitle title={`${initAgreementForm ? "Edit" : "Create"} Agreement Form`} onClose={onClose} />
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            sx={{ my: 1 }}
            value={state.title}
            onChange={e => {
              dispatch({ type: "SET_TITLE", payload: e.target.value });
            }}
            label="Title"
          ></TextField>

          <TextField
            sx={{ my: 1 }}
            value={state.url}
            onChange={e => {
              dispatch({ type: "SET_URL", payload: e.target.value });
            }}
            label="Url"
          ></TextField>

          <TextField
            sx={{ my: 1 }}
            multiline
            rows={3}
            value={state.acceptAgreementText}
            onChange={e => {
              dispatch({ type: "SET_ACCEPT_AGREEMENT_TEXT", payload: e.target.value });
            }}
            label="Acceptance Statement"
            inputProps={{ maxLength: ACCEPT_TEXT_CHAR_LIMIT }}
          ></TextField>

          <Button
            variant="text"
            onClick={() => setShowPreview(true)}
            disabled={canSaveTooltips.length > 0}>
            Show Preview
          </Button>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <TWItemizedTooltip title="Fix the following:" items={canSaveTooltips}>
            <Button onClick={handleSave}>Save</Button>
          </TWItemizedTooltip>
        </DialogActions>
      </Dialog>
      {showPreview && (
        <AgreementFormModal
          onAddAggrementFormSubmission={() => {}}
          agreementForm={{ ...state, id: "" }}
          open={showPreview}
          onClose={() => setShowPreview(false)}
        />
      )}
    </>
  );
};

export default AgreementFormDialog;

import { Chip, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
import { MyNamedTagsPreviewFragment } from "../../types";
import NamedTagChip from "./NamedTagChip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0, 1, 0, 0),
    },
  })
);

const HIDE_AFTER = 2;

interface NamedTagPreviewChipListProps {
  namedTagsPreview: MyNamedTagsPreviewFragment;
  hideAfter?: number;
  chipClassName?: string;
}

const NamedTagPreviewChipList = ({ namedTagsPreview, hideAfter = HIDE_AFTER, chipClassName }: NamedTagPreviewChipListProps) => {
  const classes = useStyles();

  const namedTags = namedTagsPreview.previewNamedTags;
  const namedTagsCount = namedTagsPreview.total;
  const baseHiddenText = namedTagsPreview.hiddenPreviewText;

  const showTagsLength = Math.min(namedTags.length, hideAfter);

  // named tags can be shorter than the hideAfter count even if the total count is larger
  const hiddenLength = namedTagsCount - showTagsLength;

  const additionalHiddenTags = namedTags.slice(showTagsLength);
  const stringsArray = [...additionalHiddenTags.map(t => t.tagName.name), ...(baseHiddenText ? [baseHiddenText] : [])];
  const previewText = stringsArray.length > 0 ? stringsArray.join(", ") : null;

  const overFlowChip =
    hiddenLength > 0 ? <Chip
      size="small"
      label={`+${hiddenLength}`}
      className={classNames(chipClassName, classes.chip)} /> : null;

  return (
    <>
      {namedTags.slice(0, hideAfter).map(namedTag => (
        <NamedTagChip
          size="small"
          key={namedTag.tag.id}
          namedTag={namedTag}
          className={classNames(chipClassName, classes.chip)} />
      ))}
      {overFlowChip && previewText ? <Tooltip title={previewText}>{overFlowChip}</Tooltip> : overFlowChip}
    </>
  );
};

export default NamedTagPreviewChipList;

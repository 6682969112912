import { WatchQueryFetchPolicy } from "@apollo/client";
import { AutocompleteRenderOptionState, Chip, createFilterOptions, FilterOptionsState, SxProps, Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useCallback, useState } from "react";
import { BasicOption } from "../../../components/universal/BaseAsyncAutocomplete";
import { GetQueryVariablesFromPaginationAndInputArgs } from "../../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import { NamedTagForSelectionFragment, useNamedTagCursorConnectionQuery } from "../../../types";
import NamedTagChip from "../NamedTagChip";
import BaseTagSelector, { BaseTagSelectorPassThroughProps } from "./BaseTagSelector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: "3px",
      "&:last-of-type": {
        marginRight: theme.spacing(0),
      },
    },
  })
);

interface NamedTagSelectorProps extends BaseTagSelectorPassThroughProps {
  sx?: SxProps;
  selectedNamedTags: readonly NamedTagForSelectionFragment[];
  onChange: (newNamedTags: NamedTagForSelectionFragment[]) => void;
  filterNamedTags?: (_edges: NamedTagForSelectionFragment[]) => NamedTagForSelectionFragment[];
  preventSmartTagRemoval?: boolean;
  limitTags?: number;
}

const NamedTagSelector = ({
  sx,
  selectedNamedTags,
  onChange,
  filterNamedTags,
  preventSmartTagRemoval = false,
  limitTags,
  textInputLabel,
}: NamedTagSelectorProps) => {
  const chipClasses = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const fetchPolicy: WatchQueryFetchPolicy = "network-only";
  const getQueryVariablesForNamedTag = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
      },
      fetchPolicy,
    }),
    []
  );

  const transformAndFilterNamedTagOptions = (_edges: NamedTagForSelectionFragment[] | undefined): NamedTagForSelectionFragment[] => {
    const edges = _edges ?? [];
    return filterNamedTags ? filterNamedTags(edges) : edges;
  };
  const filterOptions = (
    namedTags: NamedTagForSelectionFragment[],
    state: FilterOptionsState<NamedTagForSelectionFragment>
  ): NamedTagForSelectionFragment[] =>
    createFilterOptions<NamedTagForSelectionFragment>({
      stringify: () => state.inputValue,
    })(namedTags, state);

  const namedTagEdgesAreEqual = useCallback((edge1: NamedTagForSelectionFragment, edge2: NamedTagForSelectionFragment) => {
    return edge1.tag.id === edge2.tag.id;
  }, []);

  const handleChange = (_: string, namedTags: NamedTagForSelectionFragment[]) => onChange(namedTags);

  const renderTags = (namedTags: NamedTagForSelectionFragment[]) => {
    let visibleTags = namedTags;
    let hiddenTags = null;
    if (!isOpen && limitTags && namedTags.length > limitTags) {
      visibleTags = namedTags.slice(0, limitTags);
      hiddenTags = namedTags.slice(limitTags);
    }
    return (
      <>
        {visibleTags.map(namedTag => (
          <NamedTagChip
            namedTag={namedTag}
            key={namedTag.tag.id}
            onDelete={
              preventSmartTagRemoval && namedTag.tag.__typename !== "AthleteTag"
                ? undefined
                : namedTagToRemove => onChange(namedTags.filter(nt => nt.tag.id !== namedTagToRemove.tag.id))
            }
            className={chipClasses.chip}
          />
        ))}
        {hiddenTags && hiddenTags.length > 0 && (
          <Tooltip title={hiddenTags.map(({ tagName: { name } }) => name).join(", ")}>
            <Chip label={`+${hiddenTags.length}`} className={chipClasses.chip} />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <BaseTagSelector
      sx={sx}
      queryKey="namedTagCursorConnection"
      useCursorConnectionQuery={useNamedTagCursorConnectionQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariablesForNamedTag}
      transformAndFilterOptions={transformAndFilterNamedTagOptions}
      renderOption={(namedTag: NamedTagForSelectionFragment, _state: AutocompleteRenderOptionState) => (
        <BasicOption element={namedTag.tagName.name} />
      )}
      handleChange={handleChange}
      getOptionLabel={(namedTag: NamedTagForSelectionFragment) => namedTag.tagName.name}
      getCustomOptionKey={(namedTag: NamedTagForSelectionFragment) => namedTag.tag.id}
      renderTags={renderTags}
      filterOptions={filterOptions}
      noOptionsText="No Tags Found"
      inputPlaceholder={textInputLabel ? (selectedNamedTags.length > 0 ? undefined : "No Tags Selected") : "Tags"}
      edgesAreEqual={namedTagEdgesAreEqual}
      value={selectedNamedTags.concat()}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      textInputLabel={textInputLabel}
    />
  );
};

export default NamedTagSelector;

import { addMilliseconds, addMinutes, areIntervalsOverlapping, differenceInMinutes, eachDayOfInterval, max, min } from "date-fns";
import { AdjacentMeals, MealEventCandidate, MealModification, StaticEvent } from "./types";
import { MINIMUM_MEAL_DURATION_IN_MIN, findMealModificationForMeal, getIntervalForMaxMealTimeOffset } from "./utils";
import { isValidTimeSlot } from "./isValidTimeSlot";

const TEAMWORKS_MEAL_WORDS = ["breakfast", "breakfasts", "lunch", "lunches", "dinner", "dinners"];

/**
 * Returns all meal modifications for matching notemeal meals to valid external meals.
 * @param mealEventCandidates - all notemeal meals, sorted by start time
 * @param staticEvents - all static events for the schedule, sorted by start time
 */
export const getMealModificationsForExternalMealMatches = (
  mealEventCandidates: MealEventCandidate[],
  staticEvents: StaticEvent[]
): MealModification[] => {
  const externalMeals = staticEvents.filter(isStaticEventAMeal);
  const notemealMeals = mealEventCandidates.filter(meal => meal.mealType !== "snack");
  const mods: MealModification[][] = [];

  // don't attempt meal matching if there is nothing to match
  if (externalMeals.length === 0 || notemealMeals.length === 0) {
    return [];
  }

  // Get day range for all meals
  const dayInterval = getDayRangeForMeals(mealEventCandidates, externalMeals);

  // For each day, get mods for matched meals and validate that with them the schedule is still valid
  for (const day of eachDayOfInterval(dayInterval)) {
    const externalMealsForDay = externalMeals.filter(meal => meal.start.getDay() === day.getDay());
    const notemealMealsForDay = notemealMeals.filter(meal => meal.start.getDay() === day.getDay());
    const modsForMatches = tryToCreateMealModificationsForDay(externalMealsForDay, notemealMealsForDay);

    const dontUseMods =
      modsForMatches.length === 0 ||
      !isValidScheduleWithModifications(
        mealEventCandidates,
        staticEvents.filter(event => event.start.getDay() === day.getDay()),
        modsForMatches
      );
    mods.push(dontUseMods ? [] : modsForMatches);
  }

  return mods.flatMap(modsForDay => modsForDay);
};

/**
 * Create meal modifications for all matching meals if possible.
 * If in a given day there isn't a corresponding external meal for a meal, then return an empty list since we need to have a 1:1 match.
 */
const tryToCreateMealModificationsForDay = (externalMeals: StaticEvent[], notemealMeals: MealEventCandidate[]): MealModification[] => {
  const mods: MealModification[] = [];
  const matchedExternalMealIds: Set<string> = new Set();
  const moveableNotemealMeals = notemealMeals.filter(meal => !meal.isStatic);

  for (const notemealMeal of moveableNotemealMeals) {
    const matchingExternalMeal = externalMeals.find(externalMeal => areMealsMatching(notemealMeal, externalMeal));
    // skip if there is no matching external meal or we've already matched it
    if (!matchingExternalMeal || matchedExternalMealIds.has(matchingExternalMeal.id)) {
      continue;
    }

    const timeOffset = differenceInMinutes(matchingExternalMeal.start, notemealMeal.start);
    if (timeOffset !== 0) {
      // don't add a mod if the meal is already in the correct position
      mods.push({
        mealEvent: notemealMeal,
        timeOffset,
      });
    }
    matchedExternalMealIds.add(matchingExternalMeal.id);
  }

  return mods;
};

/**
 * Returns whether the schedule given the meal modifications is valid or not
 */
const isValidScheduleWithModifications = (
  mealEventCandidates: MealEventCandidate[],
  staticEvents: StaticEvent[],
  modsForMatches: MealModification[]
) => {
  const modifiedMeals = applyMealMatchModificationsToMealEventCandidates(mealEventCandidates, modsForMatches);
  return (
    areMealModificationOffsetsValid(modsForMatches) &&
    isMealOrderPreserved(mealEventCandidates, modifiedMeals) &&
    areModifiedMealsInValidSlots(modifiedMeals, modsForMatches, staticEvents)
  );
};

/**
 * Returns whether all modified meals are within the max meal time offset window
 */
const areMealModificationOffsetsValid = (mealMods: MealModification[]) => {
  for (const mod of mealMods) {
    const {
      mealEvent: { start, durationInMinutes },
      timeOffset,
    } = mod;
    const maxOffsetInterval = getIntervalForMaxMealTimeOffset(start);
    const window = {
      start: maxOffsetInterval.start,
      // add a millisecond to make sure the end is inclusive of the max offset
      end: addMilliseconds(maxOffsetInterval.end, 1),
    };
    const newStart = addMinutes(start, timeOffset);

    // if the new meal time is outside the max offset window, then it is invalid
    if (!areIntervalsOverlapping({ start: addMinutes(start, timeOffset), end: addMinutes(newStart, durationInMinutes) }, window)) {
      return false;
    }
  }
  return true;
};

/**
 * Returns whether the order of the meals is preserved with the modifications
 */
const isMealOrderPreserved = (originalMeals: MealEventCandidate[], modifiedMeals: MealEventCandidate[]) => {
  let curMealIdx = 0;
  while (curMealIdx < originalMeals.length) {
    if (originalMeals[curMealIdx].id !== modifiedMeals[curMealIdx].id) {
      return false;
    }
    curMealIdx++;
  }
  return true;
};

/**
 * Validates that the modified meals are in valid time slots in relation to all other events
 */
const areModifiedMealsInValidSlots = (modifiedMeals: MealEventCandidate[], mealMods: MealModification[], staticEvents: StaticEvent[]) => {
  // only review meals that were modified since they could now be invalid
  for (let i = 0; i < mealMods.length; i++) {
    const curMod = mealMods[i];

    const idxOfMeal = modifiedMeals.findIndex(meal => meal.id === curMod.mealEvent.id);
    const hasPreviousMeal = idxOfMeal > 0;
    const hasNextMeal = idxOfMeal < modifiedMeals.length - 1;

    const adjacentMeals: AdjacentMeals = {
      isPreviousMealModified: hasPreviousMeal ? findMealModificationForMeal(mealMods, modifiedMeals[idxOfMeal - 1]) !== undefined : false,
      isNextMealModified: hasNextMeal ? findMealModificationForMeal(mealMods, modifiedMeals[idxOfMeal + 1]) !== undefined : false,
      prevMeal: hasPreviousMeal ? modifiedMeals[idxOfMeal - 1] : null,
      nextMeal: hasNextMeal ? modifiedMeals[idxOfMeal + 1] : null,
    };

    if (!isValidTimeSlot(curMod.mealEvent, adjacentMeals, staticEvents, curMod.timeOffset, MINIMUM_MEAL_DURATION_IN_MIN)) {
      return false;
    }
  }
  return true;
};

/**
 * Apply meal match modifications to meal event candidates
 * @return modified meals, sorted by start time
 */
const applyMealMatchModificationsToMealEventCandidates = (
  mealEventCandidates: MealEventCandidate[],
  teamworksMealMatchModifications: MealModification[]
): MealEventCandidate[] => {
  return mealEventCandidates
    .map(mealEventCandidate => {
      const modForCandidate = findMealModificationForMeal(teamworksMealMatchModifications, mealEventCandidate);
      if (modForCandidate) {
        return {
          ...mealEventCandidate,
          start: addMinutes(mealEventCandidate.start, modForCandidate.timeOffset),
        };
      } else {
        return mealEventCandidate;
      }
    })
    .sort((a, b) => a.start.getTime() - b.start.getTime());
};

/**
 * Return the day range for all meals by their start time.
 * @param notemealMeals - sorted by start time
 * @param externalMeals - sorted by start time
 * @returns
 */
const getDayRangeForMeals = (notemealMeals: MealEventCandidate[], externalMeals: StaticEvent[]): Interval => {
  const hasNotemealMeals = notemealMeals.length > 0;
  const hasExternalMeals = externalMeals.length > 0;

  // if the lists have meals, get the start time of the first of their meals and the start time of the last of their meals
  const firstMeals = [hasExternalMeals ? externalMeals[0].start : null, hasNotemealMeals ? notemealMeals[0].start : null];
  const lastMeals = [
    hasExternalMeals ? externalMeals[externalMeals.length - 1].start : null,
    hasNotemealMeals ? notemealMeals[notemealMeals.length - 1].start : null,
  ];

  // Get the earliest start time and latest start time of all meals to determine the day range
  return {
    start: min(firstMeals.filter(meal => meal !== null) as Date[]),
    end: max(lastMeals.filter(meal => meal !== null) as Date[]),
  };
};

const isStaticEventAMeal = (staticEvent: StaticEvent) => {
  if (staticEvent.__typename !== "external") {
    return false;
  }
  const wordsInEventName = staticEvent.name.toLowerCase().split(/[\s//]+/);
  return TEAMWORKS_MEAL_WORDS.some(word => wordsInEventName.includes(word));
};

const areMealsMatching = (notemealMeal: MealEventCandidate, externalMeal: StaticEvent) => {
  // If the notemeal meal type is breakfast, lunch, or dinner, then the external meal name must contain that same word to match
  // This accounts for pluralization of the meal word
  if (notemealMeal.mealType === "breakfast" && externalMeal.name.toLowerCase().includes("breakfast")) {
    return true;
  } else if (notemealMeal.mealType === "lunch" && externalMeal.name.toLowerCase().includes("lunch")) {
    return true;
  } else if (notemealMeal.mealType === "dinner" && externalMeal.name.toLowerCase().includes("dinner")) {
    return true;
  }
  return false;
};

import { Typography } from "@mui/material";
import { US_LOCALE_CODE } from "@notemeal/locale/utils";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import React, { useState } from "react";
import { TWTabGroup } from "../../../../componentLibrary/TWTabGroup/TWTabGroup";
import { ImportAthlete } from "../../../../components/views/Import/Athlete/AthleteTabPanel";
import { AthleteTemplateCsv, AthleteTemplateXlsx } from "../../../../components/views/Import/Athlete/AthleteTemplateFiles";
import { loadGeneralCsvImportableAthletes } from "../../../../components/views/Import/Athlete/General/csv";
import { loadGeneralXlsxImportableAthletes } from "../../../../components/views/Import/Athlete/General/xlsx";

const US_LOCALE_KEY = [
  "First Name*: John",
  "Last Name*: Smith",
  "Team*: Must be an existing team name in your org; capitalization does not matter",
  "Position: Must match the position code for your sport",
  "Email*: Must have “@”",
  "Phone Number: XXX-XXX-XXXX",
  "Sex: M / F (required for teams without a gender assigned)",
  "Inbody ID: ####",
  "Weight: # (in pounds). Height is required if weight is entered",
  "Height: # (in inches). Weight is required if height is entered",
  "Birthdate: MM/DD/YYYY",
];

const GB_LOCALE_KEY = [
  "First Name*: John",
  "Last Name*: Smith",
  "Team*: Must be an existing team name in your org; capitalization does not matter",
  "Position: Must match the position code for your sport",
  "Email*: Must have “@”",
  "Phone Number: XXX-XXX-XXXX",
  "Sex: M / F (required for teams without a gender assigned)",
  "Inbody ID: ####",
  "Weight: # (in kilograms). Height is required if weight is entered",
  "Height: # (in centimeters). Weight is required if height is entered",
  "Birthdate: DD/MM/YYYY",
];

const keyDisclaimer = (
  <Typography>
    * Import <b>Weight</b> and <b>Height</b> for existing records through the Anthro page
  </Typography>
);

const excelTemplateFile = { name: "General Athlete Info.xlsx", data: AthleteTemplateXlsx };
const csvTemplateFile = { name: "General Athlete Info.csv", data: AthleteTemplateCsv };

export const ImportAthletePage = () => {
  const EXCEL = "General (.xlsx)";
  const CSV = "General (.csv)";
  const [selected, setSelected] = useState(EXCEL);
  const tabs = [EXCEL, CSV];
  const { locale } = useLocaleContext();
  // add more keys as necessary by different locales
  const key = locale === US_LOCALE_CODE ? US_LOCALE_KEY : GB_LOCALE_KEY;

  return (
    <>
      <TWTabGroup tabs={tabs} onSelected={selected => setSelected(selected)} />
      <ImportAthlete
        matchFields={["lastName", "firstName"]}
        linkFields={["teamId", "sex", "teamName"]}
        linkOnFields={["teamName"]}
        loadAndParse={selected === EXCEL ? loadGeneralXlsxImportableAthletes : loadGeneralCsvImportableAthletes}
        fileExtension={selected === EXCEL ? ".xlsx" : ".csv"}
        keyEntries={key}
        keyDisclaimer={keyDisclaimer}
        templateFile={selected === EXCEL ? excelTemplateFile : csvTemplateFile}
      />
    </>
  );
};

import { TeamFormState, GenderObject } from "../Form/Create/utils";
import { CreateTeamInput, FullSportFragment } from "../../../types";

export const initialCreateTeamFormState = (): TeamFormState => ({
  sport: null,
  gender: null,
  name: null,
});

type SaveableTeamFormState = {
  gender: GenderObject | null;
  sport: FullSportFragment | null;
  name: string;
};

export const teamFormToCreateInput = (state: SaveableTeamFormState): CreateTeamInput => teamFormToInput(state);

export const teamFormToInput = (state: SaveableTeamFormState): CreateTeamInput => ({
  sportId: state.sport ? state.sport.id : null,
  gender: state.gender ? state.gender.name : null,
  name: state.name,
});

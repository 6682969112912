import { Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ChipList from "@notemeal/shared/ui/global/ChipList";
import React, { Fragment } from "react";
import { Food, FullFoodGroupFragment } from "../../../types";
import { getDislikeChipDarkerSx, getDislikeChipSx, getLikeChipDarkerSx, getLikeChipSx } from "../FoodPreferenceUtils";
import { FoodMultiSearchBar } from "./FoodMultiSearchBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "flex-start",
    },
    searchTypeToggle: {
      paddingLeft: theme.spacing(2),
    },
    chipLists: {
      display: "flex",
      justifyContent: "space-around",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    chipList: {
      paddingTop: theme.spacing(),
      height: theme.spacing(35),
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "start",
    },
    foodPref: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

export interface AddOrRemoveFoodPreferenceProps {
  type: "Athlete" | "MealPlan";
  id: string;
  food: Pick<Food, "id" | "name">;
}

export interface AddOrRemoveFoodGroupPreferenceProps {
  type: "Athlete" | "MealPlan";
  id: string;
  foodGroup: Pick<FullFoodGroupFragment, "id" | "name">;
}

interface FoodPreferenceFormActions {
  addLikedFood: (props: AddOrRemoveFoodPreferenceProps) => void;
  removeLikedFood: (props: AddOrRemoveFoodPreferenceProps) => void;
  addLikedFoodGroup: (props: AddOrRemoveFoodGroupPreferenceProps) => void;
  removeLikedFoodGroup: (props: AddOrRemoveFoodGroupPreferenceProps) => void;

  addDislikedFood: (props: AddOrRemoveFoodPreferenceProps) => void;
  removeDislikedFood: (props: AddOrRemoveFoodPreferenceProps) => void;
  addDislikedFoodGroup: (props: AddOrRemoveFoodGroupPreferenceProps) => void;
  removeDislikedFoodGroup: (props: AddOrRemoveFoodGroupPreferenceProps) => void;
}

interface IFoodPreferenceChip {
  id: string;
  name: string;
  typename: FoodPreferenceType;
}

export type FoodPreferenceType = "Food" | "FoodGroup";

interface FoodPreferences {
  likedFoods: ReadonlyArray<Pick<Food, "id" | "name">>;
  dislikedFoods: ReadonlyArray<Pick<Food, "id" | "name">>;
  likedFoodGroups: ReadonlyArray<Pick<FullFoodGroupFragment, "id" | "name">>;
  dislikedFoodGroups: ReadonlyArray<Pick<FullFoodGroupFragment, "id" | "name">>;
}

interface FoodPreferenceFormProps {
  type: "Athlete" | "MealPlan";
  preferrerId: string; // The `id` of the `Athlete` or `MealPlan`
  preferences: FoodPreferences;
  actions: FoodPreferenceFormActions;
}

export const FoodPreferenceForm = ({
  type,
  preferrerId,
  preferences: { likedFoods, dislikedFoods, likedFoodGroups, dislikedFoodGroups },
  actions: {
    removeLikedFood,
    removeLikedFoodGroup,
    removeDislikedFood,
    removeDislikedFoodGroup,
    addLikedFood,
    addLikedFoodGroup,
    addDislikedFood,
    addDislikedFoodGroup,
  },
}: FoodPreferenceFormProps) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.chipLists}>
        <div className={classes.foodPref}>
          <FoodMultiSearchBar
            type={type}
            preference="like"
            addFood={addLikedFood}
            addFoodGroup={addLikedFoodGroup}
            preferrerId={preferrerId}
          />
          <ChipList<IFoodPreferenceChip>
            onDeleteObject={obj => {
              if (obj.typename === "Food") {
                removeLikedFood({ type, id: preferrerId, food: obj });
              } else {
                removeLikedFoodGroup({ type, id: preferrerId, foodGroup: obj });
              }
            }}
            objects={likedFoodGroups
              .map(({ id, name }) => ({
                id,
                name,
                typename: "FoodGroup" as FoodPreferenceType,
              }))
              .concat(
                likedFoods.map(({ id, name }) => ({
                  id,
                  name,
                  typename: "Food",
                }))
              )}
            objectToName={obj => obj.name}
            objectToChipProps={obj => (obj.typename === "Food" ? { sx: getLikeChipSx(theme) } : { sx: getLikeChipDarkerSx(theme) })}
            className={classes.chipList}
          />
        </div>
        <div className={classes.foodPref}>
          <FoodMultiSearchBar
            type={type}
            preference="dislike"
            addFood={addDislikedFood}
            addFoodGroup={addDislikedFoodGroup}
            preferrerId={preferrerId}
          />
          <ChipList<IFoodPreferenceChip>
            onDeleteObject={obj => {
              if (obj.typename === "Food") {
                removeDislikedFood({ type, id: preferrerId, food: obj });
              } else {
                removeDislikedFoodGroup({
                  type,
                  id: preferrerId,
                  foodGroup: obj,
                });
              }
            }}
            objects={dislikedFoodGroups
              .map(({ id, name }) => ({
                id,
                name,
                typename: "FoodGroup" as FoodPreferenceType,
              }))
              .concat(
                dislikedFoods.map(({ id, name }) => ({
                  id,
                  name,
                  typename: "Food",
                }))
              )}
            objectToName={obj => obj.name}
            objectToChipProps={obj => (obj.typename === "Food" ? { sx: getDislikeChipSx(theme) } : { sx: getDislikeChipDarkerSx(theme) })}
            className={classes.chipList}
          />
        </div>
      </div>
    </Fragment>
  );
};

import AddCircleIcon from "@mui/icons-material/AddCircle";
import SportsIcon from "@mui/icons-material/Casino";
import { Link, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useEffect, useState } from "react";
import CreateSportModal from "../../../../components/Staff/Shared/Sport/CreateModal";
import PositionChipList from "../../../../components/Staff/Shared/Sport/Position";
import IconCard from "../../../../components/universal/IconCard";
import { FullSportFragment, useSportsQuery } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(3, 0),
      flexBasis: "100%",
    },
    sportsDiv: {
      gridColumnStart: 2,
      gridColumnEnd: 3,
      display: "flex",
      flexFlow: "row wrap",
    },
    sportCard: {
      margin: "10px",
    },
    divider: {
      gridColumnStart: 3,
      gridColumnEnd: 4,
    },
    positionsDiv: {
      gridColumnStart: 4,
      gridColumnEnd: 5,
    },
    card: {
      margin: theme.spacing(2),
      minWidth: theme.spacing(25),
    },
    container: {
      display: "grid",
      gridTemplateColumns: "0% 73% 2% 25% 0%",
      maxHeight: "inherit",
    },
  })
);

export const SportsPage = () => {
  const classes = useStyles();
  const [selectedSport, setSelectedSport] = useState<FullSportFragment>();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { data, loading } = useSportsQuery();
  // TODO: This is a ridiculous abuse of useEffect!!!
  // eslint-disable-next-line
  useEffect(() => {
    if (data && !loading && !!selectedSport) {
      const refreshedSport = data.sports.find(s => s.id === selectedSport.id);
      // This bumps the VDOM to update the PositionChipList upon adding a new Position to a sport
      setSelectedSport(refreshedSport);
    }
  });

  if (loading || !data) {
    return <Loading progressSize="lg" />;
  }
  const sports = data.sports;

  return (
    <div className={classes.container}>
      <div className={classes.sportsDiv}>
        {sports.map(sport => (
          <div key={sport.id} className={classes.sportCard}>
            <Link sx={{ m: 2, minWidth: 25 }} href="#">
              <IconCard
                onClick={() => setSelectedSport(sport)}
                icon={SportsIcon}
                title={sport.name} />
            </Link>
          </div>
        ))}
        <div className={classes.sportCard}>
          <Link href="#" className={classes.card}>
            <IconCard
              title={""}
              icon={AddCircleIcon}
              onClick={() => setCreateModalOpen(true)} />
          </Link>
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.positionsDiv}>
        {selectedSport && <PositionChipList sportId={selectedSport.id} positions={selectedSport.positions} />}
      </div>
      <CreateSportModal open={createModalOpen} onClose={() => setCreateModalOpen(false)} />
    </div>
  );
};

import { Macros, ZERO_MACROS } from "@notemeal/shared/utils/macro-protocol";
import React, { createContext, useContext, useState } from "react";

interface CurrentMacrosContextType {
  currentMacros: Macros;
  setCurrentMacros: (macros: Macros) => void;
}

const initialValue = {
  currentMacros: ZERO_MACROS,
  setCurrentMacros: () => {},
};
const CurrentMacrosContext = createContext<CurrentMacrosContextType>(initialValue);

export const CurrentMacrosContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentTotals, setCurrentTotals] = useState(ZERO_MACROS);

  return (
    <CurrentMacrosContext.Provider
      value={{
        currentMacros: currentTotals,
        setCurrentMacros: setCurrentTotals,
      }}
    >
      {children}
    </CurrentMacrosContext.Provider>
  );
};

export const useCurrentMacrosContext = () => {
  return useContext(CurrentMacrosContext);
};

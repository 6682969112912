import BackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, DialogTitle, Divider, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import WsClientStatusIndicator from "../../components/global/WsClientStatusIndicator";
import { KdsPageMealMenuInDateRangeFragment } from "../../types";

interface DialogHeaderProps {
  selectedDiningStationFraction: string;
  onClose: () => void;
  setPrinterSettingsDialogOpen: (t: boolean) => void;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
  setUserSearch: (q: string | null) => void;
  selectedMealMenus: KdsPageMealMenuInDateRangeFragment[];
}

export const DialogHeader = ({
  selectedDiningStationFraction,
  selectedMealMenus,
  onClose,
  setPrinterSettingsDialogOpen,
  setUserSearch,
  startPolling,
  stopPolling,
}: DialogHeaderProps) => {
  return (
    <DialogTitle
      sx={{
        pb: 0,
        "@media print": {
          display: "none !important",
        },
        "@media screen": {
          display: "block",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton onClick={onClose}>
            <BackIcon />
          </IconButton>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0, alignItems: "flex-start" }}>
              <Typography variant="h3">{selectedMealMenus.map(mm => mm.name).join(", ")}</Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography variant="body1">{`Dining Stations ${selectedDiningStationFraction}`}</Typography>
                <Divider orientation="vertical" flexItem />
                <WsClientStatusIndicator pollingFns={{ start: startPolling, stop: stopPolling }} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
          <TextField
            placeholder="Search Tickets"
            onChange={e => setUserSearch(e.target.value.toLowerCase() || null)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="outlined" onClick={e => setPrinterSettingsDialogOpen(true)}>
            Printer Settings
          </Button>
        </Box>
      </Box>
    </DialogTitle>
  );
};

export default DialogHeader;

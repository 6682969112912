import CloseIcon from "@mui/icons-material/Close";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Box, Card, IconButton, TextField } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { ScheduleModalEditorActivityState } from "../../../reducers/ScheduleModalEditor";
import ActivityTypeSelect from "../../Activity/TypeSelect";
import { useScheduleModalEditorContext } from "./utils";

interface ScheduleModalActivityItemProps {
  activity: ScheduleModalEditorActivityState;
}

const ScheduleModalActivityItem = ({ activity }: ScheduleModalActivityItemProps) => {
  const { onChangeActivityName, onChangeActivityStart, onChangeActivityEnd, onChangeActivityType, onRemoveActivity } =
    useScheduleModalEditorContext();
  const commonPayload = { mealId: null, activityId: activity.id };
  return (
    <Card
      sx={{
        pb: 1,
        height: "72px",
        display: "flex",
        alignItems: "flex-end",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
        gap: 2,
      }}
    >
      <Box sx={{ height: "100%", width: "6px", backgroundColor: "accents.orangeDark.400" }} />
      <DirectionsRunIcon fontSize="small" sx={{ alignSelf: "center", color: "accents.orangeDark.400" }} />
      <TextField
        autoFocus={!activity.name}
        label="Activity Name"
        value={activity.name}
        onChange={e =>
          onChangeActivityName({
            ...commonPayload,
            name: e.target.value,
          })
        }
      />
      <DesktopTimePicker
        label="Start"
        value={activity.startValue}
        onChange={datetime => onChangeActivityStart({ ...commonPayload, value: datetime })}
      />
      <DesktopTimePicker
        label="End"
        value={activity.endValue}
        onChange={datetime => onChangeActivityEnd({ ...commonPayload, value: datetime })}
      />
      <ActivityTypeSelect
        sx={{ width: "190px" }}
        value={activity.type}
        onChange={type =>
          onChangeActivityType({
            ...commonPayload,
            type,
          })
        }
      />
      <IconButton onClick={() => onRemoveActivity(commonPayload)} size="large">
        <CloseIcon />
      </IconButton>
    </Card>
  );
};

export default ScheduleModalActivityItem;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";

export type AutoSuggestionStrategy = "override" | "save-mods";

const ExtraSmallRadio = styled(Radio)({
  "& .MuiSvgIcon-root": {
    height: 15,
    width: 15,
  },
});

interface AutoSuggestionsWarningDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: (strategy: AutoSuggestionStrategy) => void;
}

const AutoSuggestionsWarningDialog = ({ open, onClose, onContinue }: AutoSuggestionsWarningDialogProps) => {
  const [autoSuggestionStrategy, setAutoSuggestionStrategy] = useState<AutoSuggestionStrategy>("override");
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Conflicts with Auto-Accept" onClose={onClose} />
      <DialogContent sx={{ pt: 0 }}>
        <Typography variant="subtitle1">
          The meal plan already has{" "}
          <Tooltip
            title={
              <Typography variant="subtitle1">
                Manual modifications are any timing adjustments that have been made to a <b>meal or activity.</b>
              </Typography>
            }
          >
            <Typography variant="subtitle1" sx={{ display: "inline", borderBottom: "1px dotted" }}>
              manual modifications
            </Typography>
          </Tooltip>{" "}
          in place. Please select one of the options to proceed with auto-accepting all suggestions.
        </Typography>

        <FormControl>
          <RadioGroup defaultValue={"override"} defaultChecked>
            <FormControlLabel
              value="override"
              control={<ExtraSmallRadio />}
              label={<Typography variant="subtitle1">Override and delete all modifications</Typography>}
              onChange={() => setAutoSuggestionStrategy("override")}
            />
            <FormControlLabel
              value="save-mods"
              control={<ExtraSmallRadio />}
              label={<Typography variant="subtitle1">Save current modifications</Typography>}
              onChange={() => setAutoSuggestionStrategy("save-mods")}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onContinue(autoSuggestionStrategy)}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoSuggestionsWarningDialog;

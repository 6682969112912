import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PeopleIcon from "@mui/icons-material/People";
import { Avatar, ListItemIcon, ListItemText, MenuItem, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { PreviewMealPlanFragment } from "../../types";
import { useMealPlanColorsContext } from "../../views/Athlete/MealPlans/contexts/Colors";
import LabeledSelect from "../universal/LabeledSelect";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    listItemIcon: {
      minWidth: "unset",
      marginRight: spacing(),
    },
    avatarOrIcon: {
      height: "20px",
      width: "20px",
    },
  })
);

interface MealPlanSelectProps {
  mealPlans: readonly PreviewMealPlanFragment[];
  selectedMealPlan: PreviewMealPlanFragment | null;
  onChange: (mealPlanId: string) => void;
  onNew?: () => void;
  onAssignFromMPT?: () => void;
  onCopy?: () => void;
  onBulkCopy?: () => void;
}

const MealPlanSelect = ({ mealPlans, selectedMealPlan, onChange, onNew, onAssignFromMPT, onCopy, onBulkCopy }: MealPlanSelectProps) => {
  const classes = useStyles();
  const { getMealPlanColor } = useMealPlanColorsContext();

  return (
    <LabeledSelect
      placeholder={!selectedMealPlan ? "Select Meal Plan" : "Selected Meal Plan"}
      options={[...mealPlans]}
      selectedOption={selectedMealPlan}
      onChange={mp => onChange(mp.id)}
      optionToName={mp => mp.name}
      renderOption={mp => (
        <MenuItem key={mp.id} value={mp.id}>
          <div className={classes.container}>
            <ListItemIcon className={classes.listItemIcon}>
              <Avatar
                className={classes.avatarOrIcon}
                style={{
                  backgroundColor: getMealPlanColor(mp.id),
                }}
              />
            </ListItemIcon>
            <ListItemText primary={mp.name} />
          </div>
        </MenuItem>
      )}
      variant={"outlined"}
    >
      {onNew && (
        <MenuItem value="newMealPlan">
          <ListItemIcon className={classes.listItemIcon}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Create Meal Plan" onClick={onNew} />
        </MenuItem>
      )}
      {onAssignFromMPT && (
        <MenuItem value="assignFromMealTemplate">
          <ListItemIcon className={classes.listItemIcon}>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Assign From Meal Plan Template" onClick={onAssignFromMPT} />
        </MenuItem>
      )}
      {onCopy && (
        <MenuItem value="copyMealPlan">
          <ListItemIcon className={classes.listItemIcon}>
            <FileCopyIcon className={classes.avatarOrIcon} />
          </ListItemIcon>
          <ListItemText primary="Copy From Existing Meal Plan" onClick={onCopy} />
        </MenuItem>
      )}
      {onBulkCopy && selectedMealPlan && (
        <MenuItem value="bulkCopyMealPlan">
          <ListItemIcon className={classes.listItemIcon}>
            <PeopleIcon className={classes.avatarOrIcon} />
          </ListItemIcon>
          <ListItemText primary="Copy Meal Plan To Athletes" onClick={onBulkCopy} />
        </MenuItem>
      )}
    </LabeledSelect>
  );
};

export default MealPlanSelect;

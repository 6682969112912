import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Typography, styled } from "@mui/material";
import React, { Dispatch } from "react";
import { DeviceSelect } from "./DisplaySettings/DeviceSelect";
import { OrientationSelect } from "./DisplaySettings/OrientationSelect";
import { LayoutSelect } from "./DisplaySettings/LayoutSelect";
import { BannerSelect } from "./DisplaySettings/BannerSelect";
import { ItemsPerRowSelect } from "./MenuItemSettings/ItemsPerRowSelect";
import { DigitalDisplaysConfigActions, DigitalDisplaysConfigState } from "./reducer";

const StyledContent = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  margin: spacing(0, 2),
}));

const StyledCustomizationsHeader = styled(Typography)(({ theme: { spacing } }) => ({
  margin: spacing(3, 0),
}));

const StyledSubHeader = styled(Typography)(({ theme: { spacing } }) => ({
  marginBottom: spacing(1),
}));

const StyledDivider = styled(Divider)(({ theme: { spacing } }) => ({
  margin: spacing(2, 0),
}));

const StyledCheckbox = styled(FormControlLabel)(({ theme: { spacing } }) => ({
  fontSize: "16px",
  height: "24px",
  marginTop: spacing(0.75),
}));

const ItemsPerRow = styled(ItemsPerRowSelect)(({ theme: { spacing } }) => ({
  marginBottom: spacing(1),
}));

interface CustomizationsContentProps {
  state: DigitalDisplaysConfigState;
  dispatch: Dispatch<DigitalDisplaysConfigActions>;
}

export const CustomizationsContent = ({ state, dispatch }: CustomizationsContentProps) => {
  return (
    <StyledContent>
      <StyledCustomizationsHeader variant="h3">Customizations</StyledCustomizationsHeader>
      <StyledSubHeader variant="h4">Display Settings</StyledSubHeader>
      <DeviceSelect
        value={state.device}
        orientation={state.orientation}
        dispatch={dispatch} />
      <OrientationSelect
        value={state.orientation}
        device={state.device}
        dispatch={dispatch} />
      <LayoutSelect value={state.layout} dispatch={dispatch} />
      <BannerSelect value={state.banner} dispatch={dispatch} />

      <StyledDivider variant="middle" />

      <StyledSubHeader variant="h4">Menu Item Settings</StyledSubHeader>
      {!(state.orientation === "Portrait") && <ItemsPerRow value={state.itemsPerRow} dispatch={dispatch} />}
      <FormGroup>
        <StyledCheckbox
          control={
            <Checkbox
              defaultChecked
              onClick={_ =>
                dispatch({
                  type: "SET_DESCRIPTION",
                  payload: !state.description,
                })
              }
            />
          }
          label="Description"
        />
        <StyledCheckbox
          control={
            <Checkbox
              defaultChecked
              onClick={_ =>
                dispatch({
                  type: "SET_MACROS",
                  payload: !state.macros,
                })
              }
            />
          }
          label="Macros"
        />
      </FormGroup>

      <StyledDivider variant="middle" />
      <StyledSubHeader variant="h4">Dining Stations</StyledSubHeader>
      <FormGroup>
        {state.diningStations &&
          state.diningStations.map(diningStation => {
            const diningStationState = state.diningStations.find(ds => ds.diningStationName === diningStation.diningStationName);
            if (!diningStationState) {
              return null;
            } else {
              return (
                <StyledCheckbox
                  control={
                    <Checkbox
                      key={`${diningStationState.diningStationName}-Checkbox`}
                      checked={diningStationState.isChecked}
                      onClick={_ =>
                        dispatch({
                          type: "SET_DINING_STATION",
                          payload: {
                            diningStationName: diningStation.diningStationName,
                            isChecked: !diningStationState.isChecked,
                          },
                        })
                      }
                    />
                  }
                  label={diningStation.diningStationName}
                />
              );
            }
          })}
      </FormGroup>
    </StyledContent>
  );
};

import React from "react";

import { MenuItemFormFragment } from "../../../types";
import DialogContent from "../../MenuItem/Dialog/Content";
import { MenuItemState } from "../../MenuItem/reducer";
import { MenuItemAppearanceState } from "../reducer";
import { getMenuItemAppearanceStateText, onConvertMenuItemAppearance, onEditMenuItemAppearance } from "../utils";

interface MenuItemAppearanceEditDialogFullProps {
  open: boolean;
  onClose: () => void;
  menuItemAppearance: MenuItemAppearanceState;
  menuItem: MenuItemFormFragment;
  onEdit: onEditMenuItemAppearance;
  onConvert: onConvertMenuItemAppearance;
}

const MenuItemAppearanceEditDialogFull = ({
  open,
  onClose,
  onEdit,
  onConvert,
  menuItemAppearance,
  menuItem,
}: MenuItemAppearanceEditDialogFullProps) => {
  const handleSave = (state: MenuItemState) => {
    if (menuItemAppearance.type === "Edit" || menuItemAppearance.type === "Create") {
      onEdit(state.menuItem, state.maxAmount, state.availableForOrder, state.allowSpecialRequests, menuItem);
    } else {
      onConvert(state.menuItem, state.maxAmount, state.availableForOrder, state.allowSpecialRequests, state.menuItem.isOneOff, menuItem);
    }
    onClose();
  };

  return (
    <DialogContent
      open={open}
      onClose={onClose}
      onSave={handleSave}
      title={getMenuItemAppearanceStateText(menuItemAppearance)}
      variant="MenuItemAppearance"
      initState={{
        menuItem,
        maxAmount: menuItemAppearance.maxAmount,
        availableForOrder: menuItemAppearance.availableForOrder,
        allowSpecialRequests: menuItemAppearance.allowSpecialRequests,
        edited: false,
      }}
    />
  );
};

export default MenuItemAppearanceEditDialogFull;

import { dateToIsoInTz } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { CalendarEvent, RenderCalendarEventPaper } from "../utils";
import { useBasePaperProps } from "./utils";

interface CalendarDraggedEventContainerProps<E extends CalendarEvent> {
  hourHeight: number;
  date: string;
  clientTimezone: string;
  hasAllDayCell: boolean;
  event: E;
  renderEventPaper: RenderCalendarEventPaper<E>;
}

const CalendarDraggedEventContainer = <E extends CalendarEvent>({
  hourHeight,
  date,
  clientTimezone,
  hasAllDayCell,
  event,
  renderEventPaper,
}: CalendarDraggedEventContainerProps<E>) => {
  const dateStart = new Date(dateToIsoInTz(date, clientTimezone));

  const selected = true;
  const slot = 1;
  const maxSlot = 1;

  const PaperProps = useBasePaperProps({
    event,
    slot,
    maxSlot,
    hourHeight,
    dateStart,
    selected,
    hasAllDayCell,
  });

  return (
    <>
      {renderEventPaper(event, {
        PaperProps,
        isAbbreviated: event.isAllDay || event.durationInMinutes < 60,
        slot,
      })}
    </>
  );
};

export default CalendarDraggedEventContainer;

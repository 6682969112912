import { DateSchema } from "@notemeal/validators";
import { z } from "zod";

export const BaseDurationSelectSchema = z.object({
  start: DateSchema,
  end: DateSchema,
});

export const TimeDurationSelectSchema = () =>
  BaseDurationSelectSchema.merge(z.object({})).superRefine((state, ctx) => {
    if (state.end && state.start && (state.end < state.start || state.start.toISOString() === state.end.toISOString())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "End Time must be after Start Time",
        path: ["end"],
      });
    }
  });

export type DurationSelectType = z.infer<typeof BaseDurationSelectSchema>;

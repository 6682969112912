import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, Typography } from "@mui/material";
import { getExchangeAmountsMacros } from "@notemeal/shared/ui/ExchangeAmount/utils";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import React, { memo, useCallback } from "react";
import { EditFullExchangeMealTemplateFragment, FullExchangeSetFragment } from "../../../../types";
import { EditExchangeMealOption } from "../../../MealOption/Edit/EditExchangeMealOption";
import { MealOptionPreview } from "../../../MealOption/Edit/EditMealOption";
import { EditMealOptions } from "./EditMealOptions";

interface EditExchangeMealOptionsProps {
  mealTemplate: EditFullExchangeMealTemplateFragment;
  exchangeSet: FullExchangeSetFragment;
}

export const EditExchangeMealOptions = memo(({ mealTemplate, exchangeSet }: EditExchangeMealOptionsProps) => {
  const { id, selectedMealOptionId, newMealOptionIds, exchangeTargets, meal } = mealTemplate;

  const renderExchangeMealOption = useCallback(
    (mealOption: MealOptionPreview) => {
      if (mealOption.__typename === "MealOption") {
        return (
          <EditExchangeMealOption
            key={mealOption.id}
            mealOption={mealOption}
            mealTemplateId={id}
            mealType={meal.type}
            selected={mealOption.id === selectedMealOptionId}
            isNew={newMealOptionIds.includes(mealOption.id)}
            exchangeSet={exchangeSet}
            targetExchangeAmounts={exchangeTargets || []}
          />
        );
      }
    },
    [id, selectedMealOptionId, newMealOptionIds, exchangeSet, meal.type, exchangeTargets]
  );

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
        <Box sx={{ mr: "2px", height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
        <Box sx={{ pr: 2, flex: 1, display: "flex", flexDirection: "column" }}>
          <Box sx={{ pt: 1, display: "flex", gap: 2, alignItems: "center" }}>
            <RestaurantIcon fontSize="small" sx={{ color: "greyscale.400" }} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1Medium">{meal.name}</Typography>
              <MacrosSummaryLabel
                macros={getExchangeAmountsMacros(mealTemplate.exchangeTargets)}
                variant="sm"
                kcalPrefix={true} />
            </Box>
          </Box>
          <EditMealOptions mealTemplate={mealTemplate} renderMealOptionPreview={renderExchangeMealOption} />
        </Box>
      </Box>
    </Card>
  );
});

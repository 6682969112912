import { Box, Typography } from "@mui/material";
import React from "react";

interface SectionHeaderProps {
  header: string;
  subcopy?: string;
}

export const SectionHeader = ({ header, subcopy }: SectionHeaderProps) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Typography variant="h4">{header}</Typography>
    {subcopy && (
      <Typography variant="body2" color="mediumEmphasisText">
        {subcopy}
      </Typography>
    )}
  </Box>
);

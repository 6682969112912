import React from "react";
import { useLocalesQuery } from "../../types";
import LabeledSelectNoId from "../universal/LabeledSelectNoId";

interface LocaleSelectProps {
  value: string | null;
  onChange: (value: string | null) => void;
  allowNone?: boolean;
}

export const LocaleSelect = ({ value, onChange, allowNone = false }: LocaleSelectProps) => {
  const { data } = useLocalesQuery();

  const locales = [...(data?.locales.map(locale => locale.code) ?? []), ...(allowNone ? ["None"] : [])];

  return (
    <LabeledSelectNoId
      sx={{ minWidth: 200 }}
      selectedOption={value ?? "None"}
      placeholder="Select locale"
      label="Locale"
      options={locales}
      optionToName={(name: string) => name}
      onChange={t => onChange(t === "None" && allowNone ? null : t)}
    />
  );
};

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Theme,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { round } from "@notemeal/shared/ui/utils/numbers";
import React, { useState } from "react";
import { AnthropometryEntryType, SexType, TeamAnthropometryQuery } from "../../../types";
import { AnthropometryMetricCard } from "../AnthropometryMetricCard";
import { AnthropometrySummaryTable } from "./Table";
import { Position } from "./types";
import { flattenAthleteAnthropometry, getAggregations } from "./utils";

export const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    formControl: {
      margin: spacing(1),
      minWidth: 300,
      maxWidth: 500,
    },
    menuItem: {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: `${grey[200]} !important`,
      },
    },
    anthropometrySummaryHeader: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
      alignItems: "center",
      flexGrow: 1,
    },
    metricsCards: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-evenly",
      flexGrow: 0.333,
    },
  })
);

export type TeamAnthropometryAthlete = TeamAnthropometryQuery["team"]["athletes"][0];

interface TeamAnthropometryContainerProps {
  exportFilename: string;
  teamId: string;
  athletes: readonly TeamAnthropometryAthlete[];
  positions: readonly Position[];
  athleteSex: SexType;
}

const TeamAnthropometryContainer = ({ exportFilename, teamId, athletes, positions, athleteSex }: TeamAnthropometryContainerProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const [highlight, setHighlight] = useState(true);
  const { isMetricLocale } = useLocaleContext();
  const [selectedAnthropometryTypes, setSelectedAnthropometryTypes] = useState<AnthropometryEntryType[]>([
    "bioelectricalImpedance",
    "bodpod",
    "dexa",
  ]);
  const anthropometryEntryTypes: AnthropometryEntryType[] = ["bioelectricalImpedance", "bodpod", "dexa", "estimate", "weighIn"];

  const teamMostRecentAnthropometryEntries = flattenAthleteAnthropometry(athletes, anthropometryEntryTypes, isMetricLocale).flatMap(a =>
    a.mostRecentAnthropometryEntry ? [a.mostRecentAnthropometryEntry] : []
  );
  const teamAggregations = getAggregations(teamMostRecentAnthropometryEntries);

  const [selectedPositionNames, setSelectedPositionNames] = useState<string[]>([]);
  const handleSelectPosition = (event: SelectChangeEvent<string[]>) => {
    setSelectedPositionNames(event.target.value as string[]);
  };

  const handleSelectAnthropometryEntryTypes = (event: SelectChangeEvent<AnthropometryEntryType[]>) => {
    setSelectedAnthropometryTypes(event.target.value as AnthropometryEntryType[]);
  };

  const filteredAthletes =
    selectedPositionNames.length === 0
      ? athletes
      : athletes.filter(ath => selectedPositionNames.some(posName => ath.position && posName === ath.position.name));

  const data = flattenAthleteAnthropometry(filteredAthletes, selectedAnthropometryTypes, isMetricLocale);
  const anthropometryEntries = data.flatMap(a => a.anthropometryEntries);
  const mostRecentAnthropometryEntries = data.flatMap(a => {
    return a.mostRecentAnthropometryEntry ? [a.mostRecentAnthropometryEntry] : [];
  });
  const filteredAggregations = getAggregations(mostRecentAnthropometryEntries);
  const props = {
    exportFilename,
    anthropometryEntries,
    mostRecentAnthropometryEntries,
    rows: filteredAthletes,
    teamAggregations,
    filteredAggregations,
    theme,
    highlight,
  };

  return (
    <Box sx={{ display: "flex", flexFlow: "row wrap", width: "100%" }}>
      <div className={classes.anthropometrySummaryHeader}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox">Filter Positions </InputLabel>
          <Select
            multiple
            value={selectedPositionNames}
            onChange={handleSelectPosition}
            input={<Input id="select-multiple-checkbox" />}
            renderValue={selected => (selected as string[]).join(", ")}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 7 + 8, // 7 items displayed by default, the rest can be scrolled to
                  width: 250,
                },
              },
            }}
          >
            {positions
              .map(p => p.name)
              .map(posName => (
                <MenuItem
                  dense
                  key={posName}
                  value={posName}
                  classes={{ selected: classes.menuItem }}>
                  <Checkbox checked={selectedPositionNames.includes(posName)} />
                  <ListItemText primary={posName} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple-checkbox-soure">Filter by Source </InputLabel>
          <Select
            multiple
            value={selectedAnthropometryTypes}
            onChange={handleSelectAnthropometryEntryTypes}
            input={<Input id="select-multiple-checkbox-source" />}
            renderValue={selected => (selected as string[]).join(", ")}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 7 + 8, // 7 items displayed by default, the rest can be scrolled to
                  width: 250,
                },
              },
            }}
          >
            {anthropometryEntryTypes.map(typ => (
              <MenuItem
                dense
                key={typ}
                value={typ}
                classes={{ selected: classes.menuItem }}>
                <Checkbox checked={selectedAnthropometryTypes.includes(typ)} />
                <ListItemText primary={typ} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Switch checked={highlight} onChange={e => setHighlight(!highlight)} />}
          label={"Highlight Outliers? "}
          labelPlacement="start"
        />
        <div className={classes.metricsCards}>
          <AnthropometryMetricCard
            name="Δ Lean"
            tooltipExplanation="Avg. change in Team lean body mass"
            metricText={String(round(teamAggregations.leanBodyMassChangeAvg))}
            metricTrendText={`+/- ${String(round(teamAggregations.leanBodyMassChangeStdev))}`}
          />
          <AnthropometryMetricCard
            name="Δ %BF"
            tooltipExplanation="Avg. change in Team % Body Fat"
            metricText={String(round(teamAggregations.percentBodyFatChangeAvg))}
            metricTrendText={`+/- ${String(round(teamAggregations.percentBodyFatChangeStdev))}`}
          />
        </div>
      </div>
      <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
        <AnthropometrySummaryTable {...props} />
      </Box>
    </Box>
  );
};

export default TeamAnthropometryContainer;

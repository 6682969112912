import { getLeanBodyMass as getAnthroLeanBodyMass, heightToCm, weightToKg } from "./Anthropometry";
import { SexType } from "./types/AnthropometryTypes";
import { RmrCaloriesArgs, RmrMethod } from "./types/RmrTypes";
import { RmrCaloriesCustomErrorMap, RmrCaloriesSchema } from "./validation/RmrValidation";

export const rmrMethodToText = (rmrMethod: RmrMethod): string => {
  switch (rmrMethod) {
    case "cunningham":
      return "Cunningham";
    case "mifflin":
      return "Mifflin";
    case "harrisBenedict":
      return "Harris-Benedict";
    case "average":
      return "Average";
    default:
      return "";
  }
};

export const rmrMethodHasPercentBodyFat = (rmrMethod: RmrMethod) => {
  return rmrMethod === "cunningham" || rmrMethod === "average";
};

export const rmrMethodHasLeanBodyMass = (rmrMethod: RmrMethod) => {
  return rmrMethod === "cunningham" || rmrMethod === "average";
};

const getMetricRmrCaloriesCunningham = (leanBodyMass: number): number => 500 + 22 * leanBodyMass;

const getRmrCaloriesCunningham = (leanBodyMass: number): number => 500 + 22 * weightToKg(leanBodyMass);

const getMetricRmrCaloriesMifflin = (weight: number, height: number, age: number, sex: SexType): number =>
  9.99 * weight + 6.25 * height - 4.92 * age + (sex === "female" ? -161 : 5);

const getRmrCaloriesMifflin = (weight: number, height: number, age: number, sex: SexType): number =>
  9.99 * weightToKg(weight) + 6.25 * heightToCm(height) - 4.92 * age + (sex === "female" ? -161 : 5);

const getMetricRmrCaloriesHarrisBenedict = (weight: number, height: number, age: number, sex: SexType): number => {
  let calories;

  if (sex === "female") {
    calories = 447.6 + 9.25 * weight + 3.1 * height - 4.33 * age;
  } else {
    // "male"
    calories = 88.4 + 13.4 * weight + 4.8 * height - 5.68 * age;
  }

  return calories;
};

const getRmrCaloriesHarrisBenedict = (weight: number, height: number, age: number, sex: SexType): number => {
  const kgWeight = weightToKg(weight);
  const cmHeight = heightToCm(height);
  let calories;

  if (sex === "female") {
    calories = 447.6 + 9.25 * kgWeight + 3.1 * cmHeight - 4.33 * age;
  } else {
    // "male"
    calories = 88.4 + 13.4 * kgWeight + 4.8 * cmHeight - 5.68 * age;
  }
  return calories;
};

const getLeanBodyMass = (lbm?: number | null, percentBodyFat?: number | null, weight?: number) =>
  lbm ? lbm : percentBodyFat && weight ? getAnthroLeanBodyMass(weight, percentBodyFat) ?? 0 : 0;

export const getMetricRmrCalories = (rmrCaloriesArgs: RmrCaloriesArgs): number => {
  const validatedRmrCalories = RmrCaloriesSchema.parse(rmrCaloriesArgs, { errorMap: RmrCaloriesCustomErrorMap });
  let calories;

  switch (validatedRmrCalories.rmrMethod) {
    case "cunningham": {
      const { leanBodyMass: lbm, percentBodyFat, weight } = validatedRmrCalories;
      calories = getMetricRmrCaloriesCunningham(getLeanBodyMass(lbm, percentBodyFat, weight));
      break;
    }
    case "mifflin": {
      const { weight, height, age, sex } = validatedRmrCalories;
      calories = getMetricRmrCaloriesMifflin(weight, height, age, sex);
      break;
    }
    case "harrisBenedict": {
      const { weight, height, age, sex } = validatedRmrCalories;
      calories = getMetricRmrCaloriesHarrisBenedict(weight, height, age, sex);
      break;
    }
    case "average": {
      const { leanBodyMass: lbm, percentBodyFat, weight, height, age, sex } = validatedRmrCalories;

      calories =
        (getMetricRmrCaloriesCunningham(getLeanBodyMass(lbm, percentBodyFat, weight)) +
          getMetricRmrCaloriesMifflin(weight ?? 0, height, age, sex) +
          getMetricRmrCaloriesHarrisBenedict(weight ?? 0, height, age, sex)) /
        3;
      break;
    }
    default:
      calories = 0;
  }

  return calories;
};

export const getRmrCalories = (rmrCaloriesArgs: RmrCaloriesArgs): number => {
  const validatedRmrCalories = RmrCaloriesSchema.parse(rmrCaloriesArgs, { errorMap: RmrCaloriesCustomErrorMap });
  let calories;
  // The incoming weight should be imperial
  // The incoming weight can be a current weight OR a target weight if Dietitian is using the weight target feature
  switch (validatedRmrCalories.rmrMethod) {
    case "cunningham": {
      const { leanBodyMass: lbm, percentBodyFat, weight } = validatedRmrCalories;
      calories = getRmrCaloriesCunningham(getLeanBodyMass(lbm, percentBodyFat, weight));
      break;
    }
    case "mifflin": {
      const { weight, height, age, sex } = validatedRmrCalories;
      calories = getRmrCaloriesMifflin(weight, height, age, sex);
      break;
    }
    case "harrisBenedict": {
      const { weight, height, age, sex } = validatedRmrCalories;
      calories = getRmrCaloriesHarrisBenedict(weight, height, age, sex);
      break;
    }
    case "average": {
      const { leanBodyMass: lbm, percentBodyFat, weight, height, age, sex } = validatedRmrCalories;

      calories =
        (getRmrCaloriesCunningham(getLeanBodyMass(lbm, percentBodyFat, weight)) +
          getRmrCaloriesMifflin(weight ?? 0, height, age, sex) +
          getRmrCaloriesHarrisBenedict(weight ?? 0, height, age, sex)) /
        3;
      break;
    }
    default:
      calories = 0;
  }

  return calories;
};

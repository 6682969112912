import { useOffsetPagination } from "apps/web/src/utils/pagination";
import React from "react";
import { useStaffDashboardUsersQuery } from "../../../../types";
import UsersTable from "../../../../views/Staff/UserSearch/Table";

export const UserSearchPage = () => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading } = useStaffDashboardUsersQuery({
    variables: {
      input: {
        offset,
        limit,
      },
      query,
    },
  });

  return <UsersTable
    loading={loading}
    paginationHooks={paginationHooks}
    offsetConnectionData={data} />;
};

import { differenceInMinutes } from "date-fns";
import { ActivityTemplate, MealTemplate } from "@notemeal/db/entities";
import { getSuggestedMealEventsForMealPlanEvents, MealPlanEvent, ExternalEvent } from "@notemeal/meal-plan-suggestions/core";
import { ExternalEvent as ExternalEventType } from "@notemeal/graphql/types";
import { getMealPlanEventsForTemplatesOnDate } from "./utils";
import { logger } from "@notemeal/server/log";

export interface SuggestedMealPlanProps {
  mealPlanEvents: MealPlanEvent[];
  externalEvents: readonly ExternalEvent[];
  modifiedMealTemplateIds: string[];
}

interface SuggestedMealEvent {
  id: string;
  oldId: string;
  oldStart: Date;
  start: Date;
  durationInMinutes: number;
  name: string;
  isAllDay: boolean;
}

/*
 * Given a list of calendar events for mealPlan and external events returns a list of suggested meal events.
 *
 * (Used in dietitian calendar preview)
 */

export const getSuggestedMealEventsForCalendarEvents = ({
  mealPlanEvents,
  externalEvents,
  modifiedMealTemplateIds,
}: SuggestedMealPlanProps): SuggestedMealEvent[] => {
  try {
    const suggestions = getSuggestedMealEventsForMealPlanEvents({ mealPlanEvents, externalEvents, modifiedMealTemplateIds }).map(e => {
      return {
        id: e.id,
        oldId: e.oldId,
        oldStart: e.oldStart,
        start: e.start,
        durationInMinutes: e.durationInMinutes,
        name: e.name,
        isAllDay: e.isAllDay,
      };
    });
    return suggestions;
  } catch (e) {
    logger.error({
      type: "misc",
      message: `Error in getSuggestedMealEventsForMealPlanEvents: ${String(e)}`,
    });
    return [];
  }
};

/*
 * For a list of external events and meal and activity templates returns a suggested meal plan schedule.
 *
 * (Used in athlete flow)
 */
export const getSuggestedMealEventsForTemplatesOnDate = (
  date: Date,
  externalEvents: ExternalEventType[],
  mealTemplates: MealTemplate[],
  activityTemplates: ActivityTemplate[],
  modifiedMealTemplateIds: string[]
): SuggestedMealEvent[] => {
  const mealPlanEvents = getMealPlanEventsForTemplatesOnDate(date, mealTemplates, activityTemplates);
  try {
    const suggestions = getSuggestedMealEventsForMealPlanEvents({
      mealPlanEvents,
      externalEvents: externalEvents.map(event => {
        return {
          ...event,
          __typename: "external",
          start: event.startDate,
          durationInMinutes: differenceInMinutes(event.endDate, event.startDate),
        };
      }),
      modifiedMealTemplateIds,
    });
    return suggestions;
  } catch (e) {
    logger.error({
      type: "misc",
      message: `Error in getSuggestedMealEventsForTemplatesOnDate: ${String(e)}`,
    });
    return [];
  }
};

// TODO: Remove this file. These are unnecesarilly complicated and redundanct filter and concat

export const remove = <T>(a: ReadonlyArray<T>, elem: T, eqFn: (x: T, y: T) => boolean = (x, y) => x === y): ReadonlyArray<T> => {
  // Removes 'elem' from 'a' and returns NEW array
  const idx = a.findIndex(x => eqFn(elem, x));
  return [...a.slice(0, idx), ...a.slice(idx + 1)];
};

export const update = <T>(a: ReadonlyArray<T>, idx: number, newElem: T): ReadonlyArray<T> => {
  // Updates 'elem' from 'a' and returns NEW array
  return [...a.slice(0, idx), newElem, ...a.slice(idx + 1)];
};

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { TimelineMealForModal } from "../utils";
import { TimelineMealButton } from "./TimelineMealButton";
import { useTimelineMealModalContext } from "./TimelineMealModalContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      marginBottom: theme.spacing(4),
    },
  })
);

// TODO: Add support from restaurant
interface TimelineMealMenuLogButtonsProps {
  selectedMenuId: string | null;
  timelineMeal: TimelineMealForModal;
  setSelectedMealMenuId: (id: string) => void;
}

const TimelineMealMenuLogButtons = ({ selectedMenuId, timelineMeal, setSelectedMealMenuId }: TimelineMealMenuLogButtonsProps) => {
  const classes = useStyles();
  const { setLogMenuState } = useTimelineMealModalContext();
  return (
    <div className={classes.container}>
      {timelineMeal.mealMenus.map(mm => {
        return (
          <TimelineMealButton
            key={mm.id}
            selected={mm.id === selectedMenuId}
            name={mm.name}
            onClick={() => {
              setSelectedMealMenuId(mm.id);
              setLogMenuState({ type: "mealMenuDiningStations", forOrder: false });
            }}
          />
        );
      })}
    </div>
  );
};

export default TimelineMealMenuLogButtons;

import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

import { ActivityItem } from "@notemeal/shared/ui/Activity";
import MealTemplateViewExchange from "@notemeal/shared/ui/MealTemplate/View/Exchange";
import MealTemplateViewMacro from "@notemeal/shared/ui/MealTemplate/View/Macro";
import { sortedScheduleComponents } from "@notemeal/shared/ui/Schedule/Container";

import ExchangeSummaryLabel from "@notemeal/shared/ui/Exchange/SummaryLabel";
import { getMacroProtocolMacros } from "@notemeal/shared/ui/MacroProtocol/utils";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import {
  ActivityTemplateFragment,
  ExchangeMealPlanDisplaySettingsFragment,
  FullMealPlanFragment,
  MacroMealPlanDisplaySettingsFragment,
} from "../../../types";
import { getTypedMealPlanFromFullMealPlanFragment } from "./types";

const PREVIEW_HEIGHT = 573;
const PREVIEW_WIDTH = 373;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: PREVIEW_HEIGHT,
      width: PREVIEW_WIDTH,
    },
    header: {
      padding: theme.spacing(),
    },
    activityTemplate: {
      margin: 0,
      boxSizing: "border-box",
      height: "100%",
    },
    templateDiv: {
      padding: theme.spacing(),
      boxSizing: "border-box",
      overflowX: "hidden",
    },
  })
);

interface MealPlanViewPreviewProps {
  mealPlan: FullMealPlanFragment;
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
}

const MealPlanViewPreview = ({ mealPlan: _mealPlan, macroDisplaySettings, exchangeDisplaySettings }: MealPlanViewPreviewProps) => {
  const classes = useStyles();

  const mealPlan = getTypedMealPlanFromFullMealPlanFragment(_mealPlan);

  // Need this since we force meal plan into a macro or exchange type
  // Should never reach though
  if (!mealPlan) {
    return <div>Meal Plan Not Found</div>;
  }

  const activities = mealPlan.activityTemplates;
  const activityToStart = (at: ActivityTemplateFragment) => at.activity.start;
  const renderActivity = (at: ActivityTemplateFragment) => <ActivityItem activity={at.activity} className={classes.activityTemplate} />;

  const mealPlanComponents =
    mealPlan.type === "exchange"
      ? sortedScheduleComponents({
          meals: mealPlan.mealTemplates,
          mealToStart: mt => mt.meal.start,
          renderMeal: mt => (
            <MealTemplateViewExchange
              mealTemplate={mt}
              containerWidth={PREVIEW_WIDTH}
              exchangeSet={mealPlan.exchangeSet}
              displayExchangeLists={exchangeDisplaySettings.dailyProgressViewsForExchanges === "all"}
              displayMealExchanges={exchangeDisplaySettings.mealProgressViewsForExchanges === "all"}
              displayMealMacros={exchangeDisplaySettings.mealProgressViewsForMacros === "all"}
              displayMealOptionMacros={exchangeDisplaySettings.mealProgressViewsForMacros === "all"}
              displayMealOptions={true}
            />
          ),
          activities,
          activityToStart,
          renderActivity,
        })
      : sortedScheduleComponents({
          meals: mealPlan.mealTemplates,
          mealToStart: mt => mt.meal.start,
          renderMeal: mt => (
            <MealTemplateViewMacro
              mealTemplate={mt}
              containerWidth={PREVIEW_WIDTH}
              displayMealMacros={macroDisplaySettings.mealProgressViews === "all"}
              displayMealOptions={true}
              displayMealOptionMacros={macroDisplaySettings.mealProgressViews === "all"}
            />
          ),
          activities: mealPlan.activityTemplates,
          activityToStart,
          renderActivity,
        });

  const mealPlanMacros = getMacroProtocolMacros(mealPlan.macroProtocol, mealPlan.athlete.birthDate);
  const headerComponent = (
    <div className={classes.header}>
      <Typography variant="h3">{mealPlan.name}</Typography>
      {(exchangeDisplaySettings.dailyProgressViewsForMacros === "all" || macroDisplaySettings.dailyProgressViews === "all") && (
        <>
          <Typography variant="h3">{mealPlanMacros.kcal}kcal</Typography>
          <div>
            <MacrosSummaryLabel macros={mealPlanMacros} variant="sm" />
          </div>
        </>
      )}
      {exchangeDisplaySettings.dailyProgressViewsForExchanges === "all" && mealPlan.type === "exchange" && (
        <div>
          <ExchangeSummaryLabel
            targetExchangeAmounts={mealPlan.exchangeTargets}
            variant="sm"
            consolidatePro={false} />
        </div>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      {headerComponent}
      {mealPlanComponents.map(({ component, id }) => (
        <div key={id} className={classes.templateDiv}>
          {component}
        </div>
      ))}
    </div>
  );
};

export default MealPlanViewPreview;

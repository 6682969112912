import LinkIcon from "@mui/icons-material/Link";
import { Button, Modal, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { genericSort } from "@notemeal/utils/sort";
import React, { useState } from "react";
import { ILinkProps, ILinkResult, LinkType } from "../../../utils/import/link";
import { pickIfTruthy } from "../../../utils/pick";
import SearchBarDefault from "../SearchBar/Default";

interface ImportLinkModalProps<F, I extends F, L extends F>
  extends Pick<ILinkProps<F, I, L>, "linkableRows" | "linkOnFields" | "linkFields"> {
  linkResults: ILinkResult<F, I, L>[];
  linkFromEntityName: string;
  linkToEntityName: string;
  onManualLink: (newLinkResults: ILinkResult<F, I, L>[]) => void;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      height: 650,
      width: 500,
      padding: theme.spacing(3),
      display: "flex",
      justifyContent: "space-between",
      flexFlow: "column nowrap",
    },
    button: {
      width: "125px",
      alignSelf: "flex-end",
      margin: theme.spacing(3),
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    missingLinks: {
      height: 400,
      overflowY: "auto",
      padding: theme.spacing(2),
    },
    wideResults: {
      width: 300,
    },
    missingLinkRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
  })
);

export const ImportLinkModal = <F extends object, I extends F, L extends F>(props: ImportLinkModalProps<F, I, L>) => {
  const { open, onClose, linkFromEntityName, linkToEntityName, linkableRows, linkOnFields, linkResults, onManualLink } = props;
  const classes = useStyles();
  const manyLinks = linkResults.filter(ln => ln.type === "many");
  const [successfulLinks, setSuccessfulLinks] = useState(linkResults.filter(ln => ln.type === "one"));
  // WARNING! This can return duplicate athletes, if two teams where
  // matched to one team (i.e. Men's Track (Spring) and Men's Track (Fall)
  // ...we need to filter on `matchFields` in the match step.
  const [failedLinks, setFailedLinks] = useState(linkResults.filter(ln => ln.type === "none"));

  const rowToText = (row: F) =>
    linkOnFields
      .slice(1)
      .reduce((curr, next) => `${curr}${row[next]}, `, `${row[linkOnFields[0]]}, `)
      .slice(0, -2);
  const sortLinks = (a: ILinkResult<F, I, L>, b: ILinkResult<F, I, L>) => genericSort(rowToText(a.originalRow), rowToText(b.originalRow));
  const uniqueFailedLinks = Object.values(
    failedLinks.reduce(
      (curr, nxt) => ({
        ...curr,
        [rowToText(nxt.originalRow)]: nxt,
      }),
      {} as { [key: string]: ILinkResult<F, I, L> }
    )
  ).sort(sortLinks);
  const missingLinkToText = (row: F, type: LinkType, linkableRow: L) => {
    const txt = rowToText(row);
    const node = <Typography variant="body1Medium">{txt}</Typography>;
    return type === "none" ? (
      node
    ) : (
      <div className={classes.column}>
        {node}
        <Typography variant="subtitle1" color="error">
          {rowToText(linkableRow)}
        </Typography>
      </div>
    );
  };
  // const successLinks = linkResults.filter( ln => ln.type === "one");
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.root}>
      <Paper className={classes.paper}>
        <div>
          <Typography variant="h3">Couldn't Find {linkToEntityName}s</Typography>
          <br />
          <Typography variant="body2Medium">
            {`Help us match the ${uniqueFailedLinks.length} misspelled `}
            {`${linkToEntityName}s to existing ${linkToEntityName}s below.`}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {`${failedLinks.filter(ln => ln.type === "none").length} ${linkFromEntityName}s without matched ${linkToEntityName}s.`}
            <br />
            {manyLinks.length > 0 && (
              <>
                {`${manyLinks.filter(ln => ln.type === "many").length}
                ${linkFromEntityName}s matched to many ${linkToEntityName}s.`}{" "}
                <strong>These will be ignored.</strong>
              </>
            )}
          </Typography>
        </div>
        <div className={classes.missingLinks}>
          {uniqueFailedLinks.map(({ originalRow, linkedRows, linkOnFields, linkFields, type }, idx) => (
            <div key={`failed-link-${idx}`} className={classes.missingLinkRow}>
              {missingLinkToText(originalRow, type, linkedRows[0])}
              {type !== "one" && (
                <SearchBarDefault<L & { id: string }>
                  /* Hide once this has been manually linked!*/
                  menuClassName={classes.wideResults}
                  objects={linkableRows.map((r, idx) => ({
                    ...r,
                    id: rowToText(r) + ` -${idx}`,
                  }))}
                  objectToName={r => rowToText(r)}
                  onSelectObject={r => {
                    let newFailedLinks: ILinkResult<F, I, L>[] = [];
                    let newSuccessfulLinks: ILinkResult<F, I, L>[] = successfulLinks;
                    failedLinks.forEach(failedLink => {
                      if (rowToText(failedLink.originalRow) === rowToText(originalRow)) {
                        newFailedLinks.push({
                          ...failedLink,
                          linkedRows: [r],
                          mergedRow: {
                            ...failedLink.originalRow,
                            ...pickIfTruthy(r, linkFields),
                          },
                          type: "one",
                        });
                        newSuccessfulLinks.push({
                          linkedRows: [r],
                          originalRow: failedLink.originalRow,
                          mergedRow: {
                            ...failedLink.originalRow,
                            ...pickIfTruthy(r, linkFields),
                          },
                          type: "one",
                          linkOnFields,
                          linkFields,
                        });
                      } else {
                        newFailedLinks.push(failedLink);
                      }
                    });
                    setFailedLinks(newFailedLinks);
                    setSuccessfulLinks(newSuccessfulLinks);
                  }}
                  label={`Search Existing ${linkToEntityName}s`}
                />
              )}
            </div>
          ))}
        </div>
        <Button className={classes.button} onClick={() => onManualLink(successfulLinks)}>
          Continue <LinkIcon className={classes.marginLeft} />
        </Button>
      </Paper>
    </Modal>
  );
};

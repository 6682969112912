import { Orientation, SCALE_FACTOR } from ".";

interface PagifyProps {
  rootNodeElement: HTMLDivElement;
  headerElement: HTMLDivElement;
  orientation: Orientation;
}

export interface PageBreak {
  /*  TODO: HAndle the following case gracefully using 'childComponentIndex'
   *  <ComponentBiggerThanOnePage>
   *     <TakesUpThreeQuartersOfPage/>
   *     </TakesUpThreeQuartersOfPage />
   *   </ComponentsBiggerThanOnePage>
   */
  componentIndex: number;
  childComponentIndex?: number;
}

// Get page breaks
const pagify = ({ rootNodeElement, headerElement, orientation }: PagifyProps): PageBreak[] => {
  // Everything is in inches!
  const DPI = 96;
  const exportComponents = rootNodeElement.children;
  const headerHeight = headerElement.getBoundingClientRect().height / DPI / SCALE_FACTOR;

  if (exportComponents.length === 0 || !headerHeight) {
    return [];
  }

  const maxPageHeight = orientation === "portrait" ? 11 : 8.5;
  const maxHeightLessHeader = maxPageHeight - headerHeight;
  const vertMarginTotalHeight = 1.1; // We add a 1/10" of buffer for weird scaling issues
  const availableHeight = maxHeightLessHeader - vertMarginTotalHeight;
  const len: number = exportComponents.length;

  let pageBreaks = [];
  let idx = 0;
  let pageHeight = 0;
  while (idx < len) {
    const elem = exportComponents[idx];
    const hgt = elem.getBoundingClientRect().height / DPI / SCALE_FACTOR;
    pageHeight += hgt;
    if (pageHeight > availableHeight) {
      pageBreaks.push({ componentIndex: idx });
      pageHeight = hgt;
    }
    idx++;
  }
  pageBreaks.push({ componentIndex: len });
  return pageBreaks;
};

export default pagify;

import { Box, List, ListItem, ListItemButton, ListItemText, Slider, TextField, Tooltip, Typography } from "@mui/material";
import { getMacroProtocolMacros } from "@notemeal/shared/ui/MacroProtocol/utils";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import { getMealPlanRmrErrors } from "@notemeal/shared/ui/utils/macroProtocolState";
import {
  AnthropometrySnapshot,
  getMacrosCalories,
  hasRmrErrors,
  maybeAthleteBirthDateToAge,
  rmrMethodToText,
} from "@notemeal/shared/utils/macro-protocol";
import React, { Dispatch, useCallback, useState } from "react";
import { MealPlanWithAthleteFragment, useMealPlanForCopyCursorConnectionQuery } from "../../../types";
import InfiniteScrollContainer from "../../universal/InfiniteScroll/InfiniteScrollContainer";
import useInfiniteCursorConnectionScroll from "../../universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import { MealPlanSearchFormAction, MealPlanSearchFormState } from "./utils";

const athleteMealPlanToPrimaryText = ({ name, athlete: { firstName, lastName } }: MealPlanWithAthleteFragment) => {
  return (
    <Typography variant="body1">
      {name} ({firstName} {lastName})
    </Typography>
  );
};

const athleteMealPlanToSecondaryText = ({ macroProtocol, athlete }: MealPlanWithAthleteFragment) => {
  const macros = getMacroProtocolMacros(macroProtocol, athlete.birthDate);
  return <MacrosSummaryLabel
    macros={macros}
    kcalPrefix
    variant="sm" />;
};

const CALORIE_RANGE_INIT_START = 1000;
const CALORIE_RANGE_INIT_END = 6000;
const CALORIE_RANGE_MIN = 0;
const CALORIE_RANGE_MAX = 10000;

interface MealPlanSearchFormProps {
  anthropometrySnapshot?: AnthropometrySnapshot;
  state: MealPlanSearchFormState;
  dispatch: Dispatch<MealPlanSearchFormAction>;
}

const MealPlanSearchForm = ({ anthropometrySnapshot, state, dispatch }: MealPlanSearchFormProps) => {
  const [calorieValue, setCalorieValue] = useState<[number, number]>([CALORIE_RANGE_INIT_START, CALORIE_RANGE_INIT_END]);

  const debouncedAthleteName = useDebounce(state.athleteSearchTerm, 200);
  const debouncedMealPlanName = useDebounce(state.mealPlanSearchTerm, 200);

  const searchResults = useInfiniteCursorConnectionScroll({
    useCursorConnectionQuery: useMealPlanForCopyCursorConnectionQuery,
    getQueryVariablesFromPagination: useCallback(
      ({ cursor, limit }) => ({
        variables: {
          pagination: { cursor, limit },
          athleteName: debouncedAthleteName,
          mealPlanName: debouncedMealPlanName,
        },
      }),
      [debouncedAthleteName, debouncedMealPlanName]
    ),
    queryKey: "mealPlanForCopyCursorConnection",
    edgesAreEqual: useCallback((mealPlan1: MealPlanWithAthleteFragment, mealPlan2: MealPlanWithAthleteFragment) => {
      return mealPlan1.id === mealPlan2.id;
    }, []),
    limit: 100,
  });

  const filterEdges = (mealPlan: MealPlanWithAthleteFragment) => {
    const [lower, upper] = calorieValue;
    const anthropometrySnapshot = mealPlan.macroProtocol?.anthropometrySnapshot;
    const age = maybeAthleteBirthDateToAge(mealPlan.athlete.birthDate);
    const rmrMethod = mealPlan?.macroProtocol?.calorieBudget?.rmrMethod;

    if (!anthropometrySnapshot || !age || !rmrMethod) {
      return false;
    }

    if (hasRmrErrors({ rmrMethod, ...anthropometrySnapshot, age })) {
      return false;
    }

    const macroProtocolCalories = getMacrosCalories(getMacroProtocolMacros(mealPlan.macroProtocol, mealPlan.athlete.birthDate));
    return macroProtocolCalories >= lower && macroProtocolCalories < upper;
  };

  const getTitle = (rmrErrors: string[]) => (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box>
        <Typography variant="body2Medium">
          The athlete's most recent anthropometry is preventing use of this meal plan's RMR method:
        </Typography>
      </Box>
      {rmrErrors.map(item => (
        <Typography variant="body2" key={item}>
          {item}
        </Typography>
      ))}
    </Box>
  );

  const renderListItem = useCallback(
    (mealPlanWithAthlete: MealPlanWithAthleteFragment, forwardRef?: React.MutableRefObject<HTMLDivElement | null>) => {
      const calBudget = mealPlanWithAthlete.macroProtocol.calorieBudget;
      const rmrMethod = calBudget ? calBudget.rmrMethod : null;

      let rmrErrors: string[] = [];
      if (anthropometrySnapshot) {
        if (rmrMethod) {
          rmrErrors = getMealPlanRmrErrors(rmrMethod, anthropometrySnapshot);
          rmrErrors = rmrErrors.length > 0 ? [`RMR method ${rmrMethodToText(rmrMethod)}:`].concat(rmrErrors) : [];
        }
      } else {
        rmrErrors.push("Cannot copy meal plan: athlete has no anthropometry entries");
      }

      const hasRmrErrors = rmrErrors.length > 0;

      return (
        <Tooltip
          key={mealPlanWithAthlete.id}
          title={hasRmrErrors ? getTitle(rmrErrors) : ""}
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                maxWidth: 700,
              },
            },
          }}
        >
          <span ref={forwardRef}>
            <ListItemButton
              sx={{ cursor: "pointer" }}
              disabled={hasRmrErrors}
              selected={mealPlanWithAthlete.id === (state.selectedMealPlan && state.selectedMealPlan.id)}
              onClick={() => dispatch({ type: "CHANGE_SELECTED_MEAL_PLAN_TEMPLATE", payload: mealPlanWithAthlete })}
            >
              <ListItemText
                primary={athleteMealPlanToPrimaryText(mealPlanWithAthlete)}
                secondary={athleteMealPlanToSecondaryText(mealPlanWithAthlete)}
              />
            </ListItemButton>
          </span>
        </Tooltip>
      );
    },
    [anthropometrySnapshot, dispatch, state.selectedMealPlan]
  );

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "hidden",
        justifyContent: "space-around",
        alignItems: "center",
        height: "calc(100% - 60px)",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "50%",
          height: "100%",
        }}
      >
        <Typography variant="h3" sx={{ flexGrow: 0, flexShrink: 0 }}>
          Select Meal Plan
        </Typography>
        <List
          sx={theme => ({
            paddingTop: 0,
            overflowY: "scroll",
            flexGrow: 1,
            flexShrink: 1,
            height: "90%",
            width: "100%",
            borderRadius: "6px",
            border: `1px ${theme.palette.divider} solid`,
            p: 2,
          })}
        >
          <InfiniteScrollContainer
            noItemsComponent={
              <ListItem sx={{ cursor: "pointer" }}>
                <Typography>No MealPlans Found</Typography>
              </ListItem>
            }
            infiniteScrollResults={searchResults}
            loadingOffset={2}
            renderItem={renderListItem}
            filterEdges={filterEdges}
          />
        </List>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "100%",
          width: "40%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h4">Search By Names</Typography>
          <Box sx={{ display: "flex", ml: 1, flexDirection: "column", width: "60%", gap: 1 }}>
            <TextField
              label="Athlete Name"
              value={state.athleteSearchTerm}
              onChange={e =>
                dispatch({
                  type: "CHANGE_ATHLETE_SEARCH_TERM",
                  payload: e.target.value,
                })
              }
            />
            <TextField
              label="Meal Plan Name"
              value={state.mealPlanSearchTerm}
              onChange={e =>
                dispatch({
                  type: "CHANGE_MEAL_PLAN_TEMPLATE_SEARCH_TERM",
                  payload: e.target.value,
                })
              }
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
          <Typography variant="h4">Search By Calories</Typography>
          <Slider
            sx={{ width: "80%", ml: 1 }}
            valueLabelDisplay="on"
            value={calorieValue}
            onChange={(e, value) => setCalorieValue(value as [number, number])}
            min={CALORIE_RANGE_MIN}
            max={CALORIE_RANGE_MAX}
            step={100}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MealPlanSearchForm;

import React from "react";

import LabeledSelectNoId from "../../universal/LabeledSelectNoId";

import { SexType } from "../../../types";

const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

interface AthleteFormSexTypeSelectProps {
  value: SexType | null;
  onChange: (type: SexType) => void;
  required?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
}

const AthleteFormSexTypeSelect = ({ value, onChange, required, error, helperText, disabled = false }: AthleteFormSexTypeSelectProps) => {
  return (
    <LabeledSelectNoId<SexType>
      sx={{ width: "120px" }}
      placeholder="Sex"
      required={required}
      options={["male", "female"]}
      selectedOption={value}
      onChange={onChange}
      optionToName={capitalize}
      error={error}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default AthleteFormSexTypeSelect;

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classnames from "classnames";
import { StringUnitLength } from "luxon";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoWrapper: {
      height: "100%",
      padding: theme.spacing(1, 2, 1, 0),
    },
    logo: {
      height: "100%",
    },
  })
);

export interface DefaultSVGLogoProps {
  src: string;
  alt?: string;
  logoClassName?: StringUnitLength;
  logoWrapperClassName?: string;
}

export type OptionalDefaultLogoProps = Omit<DefaultSVGLogoProps, "src">;

const DefaultSVGLogo = ({ src, alt, logoClassName, logoWrapperClassName }: DefaultSVGLogoProps) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.logoWrapper, logoWrapperClassName)}>
      <img
        alt={alt ?? "logo"}
        src={src}
        className={classnames(classes.logo, logoClassName)} />
    </div>
  );
};

export default DefaultSVGLogo;

import newId from "../utils/newId";
import { addSelectionOptionsToMenuItem, getMenuItemOptionErrors, MenuItemForModal } from "../MenuItem/utils";
import { MenuItemAppearancePreviewFragment, MenuSelectionItemFormFragment } from "../types";

export type MenuSelectionItemWithSpecialRequests<T extends MenuSelectionItemFormFragment = MenuSelectionItemFormFragment> = T & {
  id: string;
  specialRequests: string | null;
};
export type MenuSelectionItemWithId<T extends MenuSelectionItemFormFragment = MenuSelectionItemFormFragment> = T & {
  id: string;
};

export type MenuSelectionItemWithAppearance<T extends MenuSelectionItemFormFragment = MenuSelectionItemFormFragment> = T & {
  id: string;
  specialRequests: string | null;
  menuItemAppearance: {
    id: string;
    maxAmount: number | null;
    availableForOrder: boolean;
    allowSpecialRequests: boolean;
  };
};

export interface MenuSection {
  id: string;
  name: string;
  position: number;
  maxAmount?: number | null;
  menuItemAppearances: readonly MenuItemAppearancePreviewFragment[];
}

export const isMenuSelectionInvalid = (
  menuSelectionItems: readonly MenuSelectionItemWithAppearance[],
  menuSections: readonly MenuSection[],
  ignoreMaxAmounts = false
): boolean => {
  const menuItemAmounts = ignoreMaxAmounts ? {} : getMenuItemAmountsForSelection(menuSelectionItems);
  const allMenuItemAppearances = menuSections.flatMap(s => s.menuItemAppearances);
  const itemsAreInvalid = menuSelectionItems.some(({ menuItem, options }) => {
    const menuItemOptionErrors = getMenuItemOptionErrors(addSelectionOptionsToMenuItem(menuItem, options));
    const matchingMenuItemAppearance = allMenuItemAppearances.find(mia => mia.menuItem.id === menuItem.id);
    if (!matchingMenuItemAppearance) {
      return true;
    }

    const hasErrors = menuItemOptionErrors.length > 0;
    // Short circuit and return if we are ignoring max amounts or it has errors
    if (ignoreMaxAmounts || hasErrors) {
      return hasErrors;
    }

    const maxAmount = matchingMenuItemAppearance.maxAmount;

    const currentMenuItemAmount = menuItemAmounts[menuItem.id] || 0;
    const menuItemAmountExceedsMax = Boolean(maxAmount && currentMenuItemAmount > maxAmount);

    return menuItemAmountExceedsMax;
  });

  const menuSectionAmounts = getMenuSectionAmountsForSelection(menuSelectionItems, menuSections);
  const menuSectionsMaxAmountAreInvalid = menuSections.some(({ id, maxAmount }) => {
    const currentMenuSectionAmount = menuSectionAmounts[id] || 0;
    return Boolean(maxAmount && currentMenuSectionAmount > maxAmount);
  });
  return menuSectionsMaxAmountAreInvalid || itemsAreInvalid;
};

/**
 * Returns a javascript object where
 * Keys are menuItem ids
 * Values are the sum of amounts from menuSelectionItems where the menuSelectionItem.menuItem.id matches that id
 */
export const getMenuItemAmountsForSelection = (
  menuSelectionItems: readonly MenuSelectionItemWithAppearance[]
): Record<string, number | undefined> => {
  return menuSelectionItems.reduce<Record<string, number | undefined>>((result, menuSelectionItem) => {
    const existingAmount = result[menuSelectionItem.menuItem.id];
    if (existingAmount) {
      return {
        ...result,
        [menuSelectionItem.menuItem.id]: existingAmount + menuSelectionItem.amount,
      };
    } else {
      return {
        ...result,
        [menuSelectionItem.menuItem.id]: menuSelectionItem.amount,
      };
    }
  }, {});
};

/**
 * Returns a javascript object where
 * Keys are menuSectionIds (from array passed as arg)
 * Values are the sum of amounts from menuSelectionItems where the menuSelectionItem.menuItem appears on the key's menuSection
 */
export const getMenuSectionAmountsForSelection = (
  menuSelectionItems: readonly MenuSelectionItemWithAppearance[],
  menuSections: readonly MenuSection[]
): Record<string, number | undefined> => {
  const menuItemToDiningStationIds = menuSections
    .flatMap(mmds =>
      mmds.menuItemAppearances.map(mia => ({
        menuItemId: mia.menuItem.id,
        menuSectionId: mmds.id,
      }))
    )
    .reduce<Record<string, string | undefined>>((result, { menuItemId, menuSectionId }) => {
      return {
        ...result,
        [menuItemId]: menuSectionId,
      };
    }, {});

  return menuSelectionItems.reduce<Record<string, number | undefined>>((result, menuSelectionItem) => {
    const menuSectionId = menuItemToDiningStationIds[menuSelectionItem.menuItem.id];
    if (!menuSectionId) {
      return result;
    }

    const existingAmount = result[menuSectionId];
    if (existingAmount) {
      return {
        ...result,
        [menuSectionId]: existingAmount + menuSelectionItem.amount,
      };
    } else {
      return {
        ...result,
        [menuSectionId]: menuSelectionItem.amount,
      };
    }
  }, {});
};

interface getNewMenuSelectionItemWithAppearanceArgs {
  menuSelectionItemId: string | null;
  percentConsumed: number | null;
  menuItem: MenuItemForModal;
  amount: number;
  specialRequests: string | null;
  maxAmount: number | null;
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
}

export const getNewMenuSelectionItemWithAppearance = ({
  menuSelectionItemId,
  percentConsumed,
  menuItem,
  amount,
  specialRequests,
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
}: getNewMenuSelectionItemWithAppearanceArgs): MenuSelectionItemWithAppearance => {
  return {
    id: menuSelectionItemId ?? newId(),
    specialRequests,
    amount,
    percentConsumed,
    menuItemAppearance: { id: newId(), maxAmount, availableForOrder, allowSpecialRequests },
    menuItem,
    options: menuItem.choices.flatMap(c =>
      c.options.flatMap(o =>
        !o.menuSelectionItemOption
          ? []
          : [
              {
                ...o.menuSelectionItemOption,
                menuItemChoiceOption: o,
                percentConsumed: o.menuSelectionItemOption.percentConsumed,
              },
            ]
      )
    ),
  };
};

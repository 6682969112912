import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";

export interface TeamMealPlansTableHeaderProps {
  selectedRowIds: string[];
  queryText: string;
  setQueryText: (queryText: string) => void;
  handleClickEmail: () => void;
  handleClickDelete: () => void;
  handleClickShare: () => void;
  handleClickUnshare: () => void;
}

export const TeamMealPlansTableHeader = ({
  selectedRowIds,
  queryText,
  setQueryText,
  handleClickEmail,
  handleClickDelete,
  handleClickShare,
  handleClickUnshare,
}: TeamMealPlansTableHeaderProps) => (
  <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      placeholder="Search"
      value={queryText}
      onChange={e => setQueryText(e.target.value)}
    />
    {selectedRowIds.length > 0 && (
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
        <Typography variant="h3">{`${selectedRowIds.length} row(s) selected`}</Typography>
        <Tooltip title="Export Meal Plan(s)">
          <IconButton onClick={handleClickEmail}>
            <CloudDownloadIcon sx={{ color: "common.black" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Meal Plan(s)">
          <IconButton onClick={handleClickDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Share Meal Plan(s) to mobile">
          <IconButton onClick={handleClickShare}>
            <MobileFriendlyIcon color="info" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Unshare Meal Plan(s) from mobile">
          <IconButton onClick={handleClickUnshare}>
            <MobileOffIcon color="info" />
          </IconButton>
        </Tooltip>
      </Box>
    )}
  </Box>
);

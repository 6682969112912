import { Button } from "@mui/material";
import React, { useState } from "react";

import DisplaySettingsPreview from "../../../../components/DisplaySettings/Preview";
import DashboardCard from "../Card";
import EditModal from "./EditModal";

import {
  ExchangeMealPlanDisplaySettingsFragment,
  MacroMealPlanDisplaySettingsFragment,
  NoTargetsDisplaySettingsFragment,
} from "../../../../types";

interface TeamDashboardDisplaySettingsCardProps {
  teamId: string;
  defaultMacroDisplaySettings: MacroMealPlanDisplaySettingsFragment | null;
  defaultExchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment | null;
  defaultNoTargetsDisplaySettings: NoTargetsDisplaySettingsFragment | null;
  defaultHideAnthropometry: boolean | null;
}

const TeamDashboardDisplaySettingsCard = ({
  defaultMacroDisplaySettings,
  defaultExchangeDisplaySettings,
  defaultNoTargetsDisplaySettings,
  defaultHideAnthropometry,
  teamId,
}: TeamDashboardDisplaySettingsCardProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const editButton = (
    <Button
      size="small"
      variant="text"
      onClick={() => setEditOpen(true)}
      aria-label="Edit Display Settings">
      Edit
    </Button>
  );

  return (
    <DashboardCard title="Display Settings" options={editButton}>
      <DisplaySettingsPreview
        macroDisplaySettings={defaultMacroDisplaySettings}
        exchangeDisplaySettings={defaultExchangeDisplaySettings}
        noTargetsDisplaySettings={defaultNoTargetsDisplaySettings}
      />
      {editOpen && (
        <EditModal
          teamId={teamId}
          defaultExchangeDisplaySettings={defaultExchangeDisplaySettings}
          defaultMacroDisplaySettings={defaultMacroDisplaySettings}
          noTargetsDisplaySettings={defaultNoTargetsDisplaySettings}
          hideAnthropometry={defaultHideAnthropometry ?? false}
          open={editOpen}
          onClose={() => setEditOpen(false)}
        />
      )}
    </DashboardCard>
  );
};

export default TeamDashboardDisplaySettingsCard;

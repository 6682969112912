import { standardSchedules } from "apps/web/src/components/MealPlanTemplate/Schedule/scheduleUtils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import React from "react";
import CreateMealPlanTemplateStepper from "../../../components/MealPlanTemplate/CreateMealPlanTemplateStepper";
import { StaffMealPlanTemplateListItemFragment, useCreateSharedMacroMealPlanTemplateMutation, useGoalTypesQuery } from "../../../types";

interface CreateModalProps {
  open: boolean;
  onClose: () => void;
  onCreateSuccess: (mealPlanTemplate: StaffMealPlanTemplateListItemFragment) => void;
}

const CreateModal = ({ open, onClose, onCreateSuccess }: CreateModalProps) => {
  const { setMessage } = useSnackbar();
  const [createMealPlanTemplate, { loading: createLoading }] = useCreateSharedMacroMealPlanTemplateMutation({
    onCompleted: data => {
      const newMealTemplatePreview = data.createSharedMacroMealPlanTemplate.macroMealPlanTemplate;
      onCreateSuccess(newMealTemplatePreview);
    },
    onError: () => {
      setMessage("error", "An Error occurred creating meal plan template: Please go back and try again.");
    },
  });

  const { data: goalTypeData, loading: loadingGoalTypes } = useGoalTypesQuery();

  const loading = loadingGoalTypes;

  return loading || !goalTypeData?.goalTypes ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <CreateMealPlanTemplateStepper
      open={open}
      onClose={onClose}
      createMealPlanTemplate={input => createMealPlanTemplate({ variables: { input } })}
      createLoading={createLoading}
      goalTypes={goalTypeData.goalTypes}
      standardSchedules={standardSchedules}
      teamSchedules={[]}
    />
  );
};

export default CreateModal;

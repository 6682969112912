import { sortByKey } from "@notemeal/utils/sort";
import { MenuItemAppearanceState } from "../../../components/MenuItemAppearance/reducer";
import {
  MenuItemAppearancePreviewFragment,
  StaffDashboardRestaurantFragment,
  StaffDashboardRestaurantMenuSectionFragment,
} from "../../../types";
import { MenuSectionState, RestaurantMenuDialogState } from "./types";

export const getMenuItemAppearanceState = (
  menuItemAppearances: readonly MenuItemAppearancePreviewFragment[],
  createOrEdit: "create" | "edit"
): MenuItemAppearanceState[] =>
  menuItemAppearances.flatMap(mia =>
    mia.menuItem.isDeleted
      ? []
      : createOrEdit === "create"
      ? {
          ...mia,
          type: "Add",
          partial: true,
        }
      : {
          ...mia,
          type: "Move",
          partial: true,
          deleted: false,
        }
  );

export const getMenuSectionsState = (menuSections: readonly StaffDashboardRestaurantMenuSectionFragment[]): MenuSectionState[] =>
  sortByKey(menuSections, "position").map(menuSection => ({
    id: menuSection.id,
    name: menuSection.name,
    position: menuSection.position,
    menuItemAppearances: getMenuItemAppearanceState(menuSection.menuItemAppearances, "edit"),
  }));

export const getRestaurantMenuDialogStateFromRestaurant = (restaurant: StaffDashboardRestaurantFragment): RestaurantMenuDialogState => {
  if (!restaurant.menu?.id) {
    return {
      __typename: "New",
      id: null,
      edited: false,
      supportedDiets: [],
      sections: [],
    };
  }

  return {
    __typename: "Edit",
    id: restaurant.menu.id,
    edited: false,
    supportedDiets: restaurant.menu.supportedDiets,
    sections: getMenuSectionsState(restaurant.menu.sections),
  };
};

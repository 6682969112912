import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Components, Theme } from "@mui/material";
import { Semibold } from "./TypographyCommon";

export const componentsCommon: Components<Omit<Theme, "components">> = {
  MuiAutocomplete: {
    defaultProps: {
      forcePopupIcon: false,
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
      size: "medium",
    },
  },
  MuiCard: {
    defaultProps: {
      variant: "outlined",
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: "flex-end",
        margin: "16px",
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      component: "h3",
    },
    styleOverrides: {
      root: {
        fontSize: 20,
        fontWeight: Semibold,
        lineHeight: "28px",
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      shrink: true,
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: ExpandMoreIcon,
    },
  },
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: "extraExtraExtraLarge" },
        style: {
          fontSize: 48,
        },
      },
      {
        props: { fontSize: "extraExtraLarge" },
        style: {
          fontSize: 40,
        },
      },
      {
        props: { fontSize: "extraLarge" },
        style: {
          fontSize: 32,
        },
      },
      {
        props: { fontSize: "large" },
        style: {
          fontSize: 24,
        },
      },
      {
        props: { fontSize: "medium" },
        style: {
          fontSize: 20,
        },
      },
      {
        props: { fontSize: "small" },
        style: {
          fontSize: 16,
        },
      },
      {
        props: { fontSize: "extraSmall" },
        style: {
          fontSize: 12,
        },
      },
    ],
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: 10,
        "&.Mui-selected": {
          fontWeight: Semibold,
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        position: "sticky",
        top: 0,
        zIndex: 10,
        fontWeight: Semibold,
        lineHeight: "18px",
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "outlined",
      size: "medium",
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
  },
};

import CopyFileIcon from "@mui/icons-material/FileCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Dialog, DialogActions, DialogContent, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DenseActivityItem } from "@notemeal/shared/ui/Activity";
import { DenseMealItem } from "@notemeal/shared/ui/Meal";
import ScheduleContainer from "@notemeal/shared/ui/Schedule/Container";
import ChipList from "@notemeal/shared/ui/global/ChipList";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { getDislikeChipDarkerSx, getLikeChipDarkerSx } from "apps/web/src/components/FoodPreference/FoodPreferenceUtils";
import React, { useState } from "react";
import ComplianceEventList from "../../../../components/global/Compliance/EventList";
import ComplianceKeyMetric from "../../../../components/global/Compliance/KeyMetric";
import { TeamAthletesMealPlanRow } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconPadding: {
      paddingRight: theme.spacing(),
    },
    detailPanel: {
      padding: theme.spacing(1),
      display: "grid",
      gridTemplateRows: "25px 250px",
      gridTemplateColumns: "15% 40% 30% 15%",
    },
    header: {
      display: "flex",
      padding: theme.spacing(),
      alignItems: "center",
      justifyContent: "flex-start",
      gridColumnStart: 1,
      gridColumnEnd: 5,
      gridRowStart: 1,
      gridRowEnd: 2,
    },
    mealPlanName: {
      paddingRight: theme.spacing(2),
    },
    events: {
      display: "flex",
      flexDirection: "column",
      gridRowStart: 2,
      gridColumnStart: 4,
      gridColumnEnd: 5,
      overflow: "hidden",
    },
    foodPreferences: {
      display: "flex",
      flexDirection: "row",
      gridRowStart: 2,
      gridColumnStart: 3,
      gridColumnEnd: 4,
    },
    schedule: {
      gridRowStart: 2,
      gridColumnStart: 2,
      gridColumnEnd: 3,
      width: 400,
    },
    actions: {
      gridRowStart: 2,
      gridColumnStart: 1,
      gridColumnEnd: 2,
    },
    chipList: {
      padding: theme.spacing(),
      height: "inherit",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "start",
    },
    fab: {
      margin: theme.spacing(1),
    },
  })
);

interface IFoodGroupPreferenceChip {
  id: string;
  name: string;
}

const cantCopyToAthletes = (row: Pick<TeamAthletesMealPlanRow, "macroProtocol">): boolean => {
  const {
    macroProtocol: { cho, pro, fat },
  } = row;
  return !!cho.gPerKg && !!fat.gPerKg && !!pro.gPerKg;
};

interface TeamAthletesMealPlansDetailPanelProps {
  mealPlanRow: TeamAthletesMealPlanRow;
  handleClickCopy: (mealPlanRow: TeamAthletesMealPlanRow) => void;
  handleNavigateTo: (mealPlanRow: TeamAthletesMealPlanRow) => void;
}

const TeamAthletesMealPlansDetailPanel = ({ mealPlanRow, handleClickCopy, handleNavigateTo }: TeamAthletesMealPlansDetailPanelProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const [showViews, setShowViews] = useState<boolean>(false);
  // TODO: If we need this, simple add back the `FullMacroProtocol` to the `FullPreviewMealPlan` fragment
  // const macros = getMacroProtocolMacros(mealPlanRow.macroProtocol, mealPlanRow.athlete.birthDate)
  return (
    <div className={classes.detailPanel}>
      <div className={classes.header}>
        <div className={classes.mealPlanName}>
          <Typography variant="h3">{mealPlanRow.name}</Typography>
        </div>
        {/*mealPlanRow.type === "exchange" ? 
          <ExchangeSummaryLabel exchangeTargets={mealPlanRow.exchangeTargets || []} variant="lg" consolidatePro={false}/>
            :
          <MacrosSummaryLabel macros={macros} variant="lg" />
          */}
      </div>
      <div className={classes.events}>
        <ComplianceKeyMetric
          events={mealPlanRow.events}
          daysAgo={30}
          setDaysAgo={() => {}} />
        {mealPlanRow.events.length > 0 && (
          <Button
            sx={{ mt: 2, alignSelf: "center" }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowViews(true);
            }}
          >
            Show Views
          </Button>
        )}
      </div>
      <div className={classes.foodPreferences}>
        <ChipList<IFoodGroupPreferenceChip>
          objects={mealPlanRow.promotedFoodGroups.map(({ id, name }) => ({
            id,
            name,
          }))}
          objectToName={obj => obj.name}
          objectToChipProps={() => ({ sx: getLikeChipDarkerSx(theme) })}
          className={classes.chipList}
        />
        <ChipList<IFoodGroupPreferenceChip>
          objects={mealPlanRow.avoidedFoodGroups.map(({ id, name }) => ({
            id,
            name,
          }))}
          objectToName={obj => obj.name}
          objectToChipProps={() => ({ sx: getDislikeChipDarkerSx(theme) })}
          className={classes.chipList}
        />
      </div>
      <div className={classes.schedule}>
        <ScheduleContainer
          meals={mealPlanRow.mealTemplates.map(mt => mt.meal)}
          mealToStart={m => m.start}
          activities={mealPlanRow.activityTemplates.map(at => at.activity)}
          activityToStart={a => a.start}
          renderMeal={meal => <DenseMealItem meal={meal} />}
          renderActivity={act => <DenseActivityItem activity={act} />}
        />
      </div>
      <div className={classes.actions}>
        <Box sx={{ mt: 2, width: "100%", display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start" }}>
          <Tooltip
            title={
              cantCopyToAthletes(mealPlanRow)
                ? "Can't copy: CHO/PRO/FAT ratios are ALL locked in to g/kg...try locking PRO to g/kg, and setting CHO or FAT to '%' in Macro Protocol"
                : "Copy to Athletes"
            }
          >
            <div>
              <Button
                disabled={cantCopyToAthletes(mealPlanRow)}
                startIcon={<CopyFileIcon />}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  // ^ TODO: These don't do anything, but the detailPanel collapses when thie button is clicked
                  handleClickCopy(mealPlanRow);
                }}
              >
                Copy
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Open Meal Plan in New Tab">
            <Button
              variant="outlined"
              startIcon={<OpenInNewIcon />}
              onClick={() => handleNavigateTo(mealPlanRow)}>
              Open
            </Button>
          </Tooltip>
        </Box>
      </div>

      {showViews && (
        <Dialog open={showViews} onClose={() => setShowViews(false)}>
          <DialogTitle title="All Meal Plan Views" onClose={() => setShowViews(false)} />
          <DialogContent>
            <ComplianceEventList
              eventsAndMealPlans={mealPlanRow.events.map(e => ({
                ...e,
                mealPlanName: null,
              }))}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowViews(false)}>Done</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default TeamAthletesMealPlansDetailPanel;

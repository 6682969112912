import { CalendarEvent } from "apps/web/src/components/Calendar/Week/utils";
import React, { useState } from "react";
import { NewEvent } from "../../../Form/utils";
import EventPopoverNew from "./NewEventContent";
import EventWarning from "./NewEventWarning";

interface MealPlanCalendarEventNewLoaderProps {
  newEvent: CalendarEvent;
  anchorEl: HTMLElement;
  isAssignedDayOfWeek: boolean;
  onClose: () => void;
  onConfirm: (payload: NewEvent) => void;
}

const MealPlanCalendarEventNewLoader = ({
  newEvent,
  anchorEl,
  isAssignedDayOfWeek,
  onClose,
  onConfirm,
}: MealPlanCalendarEventNewLoaderProps) => {
  const [unassignedDialogOpen, setWeeklyDialogOpen] = useState<boolean>(!isAssignedDayOfWeek);

  return unassignedDialogOpen ? (
    <EventWarning
      anchorEl={anchorEl}
      onConfirm={() => {
        setWeeklyDialogOpen(false);
      }}
      onClose={onClose}
    />
  ) : (
    <EventPopoverNew
      anchorEl={anchorEl}
      newEvent={newEvent}
      onClose={onClose}
      onConfirm={onConfirm} />
  );
};

export default MealPlanCalendarEventNewLoader;

import { Box, TextField } from "@mui/material";
import React, { Dispatch } from "react";
import LabeledSelectNoId from "../../../components/universal/LabeledSelectNoId";
import SportSelect from "../../Teams/SportSelect";
import { TeamFormAction, TeamFormState } from "./utils";

interface TeamFormProps {
  state: TeamFormState;
  dispatch: Dispatch<TeamFormAction>;
  editMode?: boolean;
  allowSportEdit?: boolean;
}

const TeamForm = ({ state, dispatch, editMode, allowSportEdit = true }: TeamFormProps) => (
  <>
    <Box sx={{ display: "flex", gap: 2 }}>
      <LabeledSelectNoId
        options={["Men's", "Women's", "None"]}
        optionToName={o => (o ? o : "None")}
        selectedOption={state.gender ? state.gender : "None"}
        placeholder="Select Gender"
        onChange={gender => dispatch({ type: "CHANGE_GENDER", payload: gender === "None" ? null : gender })}
      />
      {allowSportEdit && (
        <SportSelect
          selectedSport={state.sport}
          onChange={sport => dispatch({ type: "CHANGE_SPORT", payload: sport })}
          disabled={editMode}
        />
      )}
    </Box>
    <TextField
      value={state.name || ""}
      fullWidth
      margin="dense"
      label="Enter Team Name"
      onChange={e => dispatch({ type: "CHANGE_NAME", payload: e.target.value })}
    />
  </>
);

export default TeamForm;

import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  InputAdornment,
  List as MuiList,
  ListItem as MuiListItem,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useOrgGroupOrgOptionsQuery } from "../../types";

const Container = styled("div")(({ theme }) => ({
  flex: 1,
  height: "calc(100vh - 450px)",
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "5px",
  overflowY: "scroll",
  position: "relative",
}));

const ListHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
}));

const List = styled(MuiList)(({ theme }) => ({
  overflowY: "scroll",
  height: "100%",
  userSelect: "none",
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  justifyContent: "space-between",
  cursor: "pointer",

  "&:hover": {
    background: theme.palette.grey[100],
  },
}));

interface OrgTransferListProps {
  selectedOrgIds: string[];
  onChange: (ids: string[]) => void;
}

export const OrgTransferList = ({ selectedOrgIds, onChange }: OrgTransferListProps) => {
  const { data, loading } = useOrgGroupOrgOptionsQuery();
  const [filterText, setFilterText] = useState("");
  const selected = useMemo(() => {
    return data?.orgs.filter(org => selectedOrgIds.includes(org.id)) ?? [];
  }, [data?.orgs, selectedOrgIds]);
  const available = useMemo(() => {
    return (
      data?.orgs.filter(org => !selectedOrgIds.find(id => id === org.id) && org.name.toLowerCase().includes(filterText.toLowerCase())) ?? []
    );
  }, [data?.orgs, selectedOrgIds, filterText]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Container sx={{ paddingTop: "113px" }}>
        <ListHeader>
          <Typography variant="h4" sx={{ mb: 2, color: theme => theme.palette.text.secondary }}>
            Available Orgs
          </Typography>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setFilterText("")} edge="end">
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            placeholder="Search"
          />
        </ListHeader>
        <List>
          {loading && [
            <ListItem>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={24}
                width={240} />
            </ListItem>,
            <ListItem>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={24}
                width={280} />
            </ListItem>,
            <ListItem>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={24}
                width={260} />
            </ListItem>,
          ]}
          {available.map(org => (
            <ListItem onClick={() => onChange([...selectedOrgIds, org.id])}>
              <Typography>{org.name}</Typography>
            </ListItem>
          ))}
        </List>
      </Container>
      <ChevronRight fontSize="large" sx={{ mb: 2, color: theme => theme.palette.text.secondary }} />
      <Container sx={{ paddingTop: "57px" }}>
        <ListHeader sx={{ gap: 1 }}>
          <Typography variant="h4" sx={{ color: theme => theme.palette.text.secondary }}>
            Selected Orgs ({selected.length})
          </Typography>
        </ListHeader>
        <List>
          {loading && [
            <ListItem>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={24}
                width={240} />
            </ListItem>,
            <ListItem>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={24}
                width={280} />
            </ListItem>,
            <ListItem>
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={24}
                width={260} />
            </ListItem>,
          ]}
          {selected.map(org => (
            <ListItem onClick={() => onChange(selectedOrgIds.filter(id => id !== org.id))}>
              <Typography>{org.name}</Typography>
              <CloseIcon fontSize="small" sx={{ color: theme => theme.palette.text.secondary }} />
            </ListItem>
          ))}
        </List>
      </Container>
    </Box>
  );
};

import { ZodIssue } from "zod";

export const ERROR_UNKNOWN = "ERROR_UNKNOWN";

export const DEFAULT_ERROR_UNKNOWN = "An unknown error has occurred.";

export const getErrors = (issues: ZodIssue[]): string[] =>
  issues.map(({ message }) => (message.startsWith("ERROR_") ? message : ERROR_UNKNOWN));

export const translateErrors = (zodIssues: ZodIssue[], errorLookup: Map<string, string>): string[] => {
  const errors = getErrors(zodIssues);
  const translatedErrors = errors.length ? errors.map(error => errorLookup.get(error) || DEFAULT_ERROR_UNKNOWN) : [];
  return [...new Set(translatedErrors)];
};

import { AgreementFormFragment } from "apps/web/src/types";

interface SetAcceptAgreementText {
  type: "SET_ACCEPT_AGREEMENT_TEXT";
  payload: string;
}
interface SetUrl {
  type: "SET_URL";
  payload: string;
}
interface SetTitle {
  type: "SET_TITLE";
  payload: string;
}

export type AgreementFormAction = SetAcceptAgreementText | SetUrl | SetTitle;

export const agreementFormReducer = (state: AgreementFormState, action: AgreementFormAction): AgreementFormState => {
  switch (action.type) {
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "SET_URL":
      return { ...state, url: action.payload };
    case "SET_ACCEPT_AGREEMENT_TEXT":
      return { ...state, acceptAgreementText: action.payload };
  }
};

export const getInitState = (agreementForm: AgreementFormState | null): AgreementFormState => {
  return agreementForm ? agreementForm : { title: "", url: "", acceptAgreementText: "" };
};

export type AgreementFormState = Omit<AgreementFormFragment, "id">;

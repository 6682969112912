import { AnthropometryEntryType, PositionFragment } from "../../../types";

export type Position = PositionFragment;

export type Rank = "Excellent" | "Good" | "Avg" | "Below Avg" | "Poor" | "";

interface ImportRow {
  Position: string | null;
  First: string;
  Last: string;
  "Birth Date": Date | null;
  Date: Date;
  Comment: string | null;
  Height: number | null;
  Weight: number;
  Type: AnthropometryEntryType;
  "Percent\nBody\nFat": number | null;
  "Lean\nMass": number | null;
  "Fat\nMass": number | null;
  "BMD Z-Score": number | null;
  BMC: number | null;
  "Visceral\nFat Area": number | null;
  "Skeletal\nMuscle\nMass": number | null;
  "Trunk\nFat": number | null;
  "Dry\nLean\nMass": number | null;
}

export interface ExportRow extends ImportRow {
  Rank: Rank;
  "Weight\nChange": number | null;
  "Percent\nBody\nFat\nChange": number | null;
  "Percent\nBody\nFat\nTrend": string | null;
  "Lean\nMass\nChange": number | null;
  "Lean\nMass\nTrend": string | null;
  "Fat\nMass\nChange": number | null;
  "Fat\nMass\nTrend": string | null;
  BMI: number | null;
  "Visceral\nFat Area\nChange": number | null;
  "Visceral\nFat Area\nTrend": number | null;
  "Skeletal\nMuscle\nMass\nChange": number | null;
  "Skeletal\nMuscle\nMass\nTrend": number | null;
  "Trunk\nFat\nChange": number | null;
  "Trunk\nFat\nTrend": number | null;
  "Dry\nLean\nMass\nChange": number | null;
  "Dry\nLean\nMass\nTrend": number | null;
}

export type ImportedExcelColumnKey =
  | "position"
  | "lastName"
  | "firstName"
  | "datetime"
  | "weight"
  | "percentBodyFat"
  | "boneMineralDensityZScore"
  | "boneMineralContent"
  | "height"
  | "bodyFatMass"
  | "leanBodyMass"
  | "visceralFatArea"
  | "trunkFat"
  | "dryLeanMass"
  | "skeletalMuscleMass"
  | "comment";

export type ExportedExcelColumnKey =
  | (
      | "weightChange"
      | "percentBodyFatChange"
      | "percentBodyFatArrow"
      | "rank"
      | "bodyFatMassChange"
      | "bodyFatMassArrow"
      | "leanBodyMassChange"
      | "leanBodyMassArrow"
      | "visceralFatAreaChange"
      | "visceralFatAreaArrow"
      | "trunkFatChange"
      | "trunkFatArrow"
      | "dryLeanMassChange"
      | "dryLeanMassArrow"
      | "skeletalMuscleMassChange"
      | "skeletalMuscleMassArrow"
      | "BMI"
      | "type"
    )
  | ImportedExcelColumnKey;

interface ExportColumnDef {
  header: keyof ExportRow;
  key: ExportedExcelColumnKey;
  width: number;
}

export const exportColumns: ExportColumnDef[] = [
  { header: "Position", key: "position", width: 8 },
  { header: "Last", key: "lastName", width: 12 },
  { header: "First", key: "firstName", width: 10 },
  { header: "Rank", key: "rank", width: 9 },
  { header: "Date", key: "datetime", width: 9 },
  { header: "Height", key: "height", width: 6 },
  { header: "Weight", key: "weight", width: 6 },
  { header: "Weight\nChange", key: "weightChange", width: 7 },
  { header: "Percent\nBody\nFat", key: "percentBodyFat", width: 7 },
  {
    header: "Percent\nBody\nFat\nChange",
    key: "percentBodyFatChange",
    width: 7,
  },
  { header: "Percent\nBody\nFat\nTrend", key: "percentBodyFatArrow", width: 7 },
  { header: "Fat\nMass", key: "bodyFatMass", width: 5 },
  { header: "Fat\nMass\nChange", key: "bodyFatMassChange", width: 7 },
  { header: "Fat\nMass\nTrend", key: "bodyFatMassArrow", width: 6 },
  { header: "Lean\nMass", key: "leanBodyMass", width: 6 },
  { header: "Lean\nMass\nChange", key: "leanBodyMassChange", width: 7 },
  { header: "Lean\nMass\nTrend", key: "leanBodyMassArrow", width: 6 },
  { header: "BMD Z-Score", key: "boneMineralDensityZScore", width: 5 },
  { header: "BMC", key: "boneMineralContent", width: 5 },
  { header: "BMI", key: "BMI", width: 5 },
  { header: "Visceral\nFat Area", key: "visceralFatArea", width: 7 },
  {
    header: "Visceral\nFat Area\nChange",
    key: "visceralFatAreaChange",
    width: 7,
  },
  { header: "Visceral\nFat Area\nTrend", key: "visceralFatAreaArrow", width: 7 },
  { header: "Skeletal\nMuscle\nMass", key: "skeletalMuscleMass", width: 7 },
  {
    header: "Skeletal\nMuscle\nMass\nChange",
    key: "skeletalMuscleMassChange",
    width: 7,
  },
  {
    header: "Skeletal\nMuscle\nMass\nTrend",
    key: "skeletalMuscleMassArrow",
    width: 7,
  },
  { header: "Trunk\nFat", key: "trunkFat", width: 7 },
  { header: "Trunk\nFat\nChange", key: "trunkFatChange", width: 7 },
  { header: "Trunk\nFat\nTrend", key: "trunkFatArrow", width: 7 },
  { header: "Dry\nLean\nMass", key: "dryLeanMass", width: 7 },
  { header: "Dry\nLean\nMass\nChange", key: "dryLeanMassChange", width: 7 },
  { header: "Dry\nLean\nMass\nTrend", key: "dryLeanMassArrow", width: 7 },
  { header: "Type", key: "type", width: 5 },
  { header: "Comment", key: "comment", width: 12 },
];

import { Box, Typography } from "@mui/material";
import React from "react";

interface MenuOrderDiningStationLabelProps {
  name: string;
  currentAmount: number;
  maxAmount: number | null;
}

const MenuOrderDiningStationLabel = ({ name, currentAmount, maxAmount }: MenuOrderDiningStationLabelProps) => {
  const amountProgress = `(${currentAmount}/${maxAmount})`;

  return maxAmount === null ? (
    <Typography variant="h3">{name}</Typography>
  ) : (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pr: 2 }}>
      <div>
        <Typography variant="h3">{name}</Typography>
        <Typography>Choose up to {maxAmount} serving(s)</Typography>
      </div>
      {currentAmount === 0 ? null : <Typography>{amountProgress}</Typography>}
    </Box>
  );
};

export default MenuOrderDiningStationLabel;

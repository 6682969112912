import { Dialog, DialogTitle } from "@mui/material";
import React from "react";
import { AdvancedSelectionState } from "../../../reducers/advancedSelectionReducers";
import AdvancedSelectionContent from "./AdvancedSelectionContent";

interface AdvancedSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (state: AdvancedSelectionState) => void;
  initialState: AdvancedSelectionState;
  title: React.ReactNode;
}

const AdvancedSelectionDialog = ({ onSave, open, onClose, initialState, title }: AdvancedSelectionDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <AdvancedSelectionContent
        onCancel={() => onClose()}
        onSave={state => onSave(state)}
        initialState={initialState} />
    </Dialog>
  );
};

export default AdvancedSelectionDialog;

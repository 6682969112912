import deburr from "lodash/deburr";
import React from "react";

import SearchBar, { SearchBarProps } from ".";

import { Entity } from "./type";

interface SearchBarDefaultProps<T extends Entity> extends SearchBarProps<T> {
  objects: T[];
  limit?: number;
}

/*
 * DEPRECATED: Use MUI's Autocomplete or make a new version of this similar to how
 * we did for AutocompleteQuerySearchBar. Thank you!
 */

const SearchBarDefault = <T extends Entity>({ objects, limit, ...props }: SearchBarDefaultProps<T>) => {
  const getSuggestions = (inputValue: string | null): T[] => {
    if (inputValue === null) {
      return [];
    }
    const value = deburr(inputValue.trim()).toLowerCase();

    const matchesStart =
      value.length === 0 ? [] : objects.filter(obj => props.objectToName(obj).toLowerCase().slice(0, value.length) === value);

    const matchesSubstring =
      value.length === 0
        ? []
        : objects.filter(obj => props.objectToName(obj).toLowerCase().includes(value) && !matchesStart.find(o => o.id === obj.id));

    return matchesStart.concat(matchesSubstring).slice(0, limit);
  };

  return (
    <SearchBar<T>
      renderSuggestions={({ inputValue, renderSuggestion }) =>
        getSuggestions(inputValue).map((data, idx) => {
          return renderSuggestion(data, idx, inputValue || "");
        })
      }
      {...props}
    />
  );
};

export default SearchBarDefault;

import { Theme, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { CalendarDateHeader } from "apps/web/src/components/Calendar/Week/DateHeader";
import { CalendarDateHeaderProps, CalendarDisplaySettings } from "apps/web/src/components/Calendar/Week/utils";
import WarningIcon from "apps/web/src/components/universal/WarningIcon";
import React from "react";

const useStyles = makeStyles(({ palette: { grey } }: Theme) =>
  createStyles({
    dateHeader: {
      position: "relative",
    },
    topRow: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      width: "100%",
    },
  })
);

interface MealPlanCalendarDateHeaderProps extends CalendarDateHeaderProps {
  overridingMealPlanName: string | undefined;
  displaySettings?: CalendarDisplaySettings;
}

const MealPlanCalendarDateHeader = ({
  date,
  dayOfWeekLabelNode,
  dateLabelNode,
  overridingMealPlanName,
  displaySettings,
  showAllDayEvents,
}: MealPlanCalendarDateHeaderProps) => {
  const {
    palette: { grey },
  } = useTheme();
  const classes = useStyles();
  const today = serializeDate(new Date());
  const isPast = date < today;
  const pastDate = displaySettings?.disableEditPast && isPast ? { backgroundColor: grey[100] } : undefined;

  return (
    <CalendarDateHeader
      sx={{
        position: "relative",
        ...(pastDate ?? {}),
        ...(showAllDayEvents ? { borderBottom: "none" } : {}),
      }}
    >
      <div className={classes.topRow}>
        {dayOfWeekLabelNode}
        {overridingMealPlanName && (
          <WarningIcon
            sx={{
              position: "absolute",
              right: "1px",
              fontSize: 20,
            }}
            tip={`${overridingMealPlanName} overrides the meal plan on this day`}
          />
        )}
      </div>

      {dateLabelNode}
    </CalendarDateHeader>
  );
};

export default MealPlanCalendarDateHeader;

import React, { createContext, useContext } from "react";

const ClientTimezoneContext = createContext<string>("");

export interface ClientTimezoneContextProviderProps {
  children: React.ReactNode;
}

export const ClientTimezoneContextProvider = ({ children }: ClientTimezoneContextProviderProps) => {
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return <ClientTimezoneContext.Provider value={clientTimezone}>{children}</ClientTimezoneContext.Provider>;
};

export const useClientTimezone = () => {
  const context = useContext(ClientTimezoneContext);
  if (context === "" || context === undefined) {
    throw new Error("useClientTimezone must be used within a ClientTimezoneContextProvider");
  }
  return context;
};

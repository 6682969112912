import { Box, styled, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { heightToInches, isFormDirty } from "@notemeal/validators";
import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { HeightTextMask } from "../../../utils/textMasks";
import AnthropometryEntryTypeSelect from "../TypeSelect";
import { AnthropometryEntryType } from "./AnthropometryEntryFormSchema";

const Row = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  gap: spacing(2),
  alignItems: "flex-end",
}));

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) => {
  return createStyles({
    field: {
      flex: "1 1 0",
      width: 0,
      maxWidth: "50%",
    },
  });
});

const inchesToHeight = (height: number | null): string | null => {
  if (height === null) {
    return null;
  }
  const feet = Math.floor(height / 12);
  const inches = height - 12 * feet;
  return `${feet}'${inches}`;
};

const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (value: number) => void) => {
  const rawValue = e.target.value;
  const inchesValue = heightToInches(rawValue);

  // Only call onChange if both ft and inch are present (valid #) to avoid needing to type the first digit twice in the UI
  if (!isNaN(inchesValue)) {
    onChange(inchesValue);
  }
};

export interface AnthropometryImperialEntryFormProps {
  form: UseFormReturn<AnthropometryEntryType>;
  hideCommentField?: boolean;
  fauxEditMode?: boolean;
}

export const ImperialAnthropometryEntryForm = ({ form, hideCommentField, fauxEditMode }: AnthropometryImperialEntryFormProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = form;
  const classes = useStyles();

  const formType = form.watch("type");
  const weightUnits = "(lbs)";
  const isDirty = isFormDirty(form);
  const disableDates = fauxEditMode && !isDirty;

  useEffect(() => {
    if (fauxEditMode && isDirty) {
      setValue("date", new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, fauxEditMode]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="body1Medium">Anthropometry Data</Typography>
      <Row>
        <Controller
          control={control}
          name={"date"}
          render={({ field }) => (
            <DesktopDatePicker
              sx={{ mt: 1 }}
              {...field}
              className={classes.field}
              label="Date"
              slotProps={{
                textField: {
                  error: Boolean(errors.date),
                  helperText: errors.date?.message,
                },
              }}
              disabled={disableDates}
            />
          )}
        />
        <Controller
          control={control}
          name={"type"}
          render={({ field: { value, onChange } }) => (
            <AnthropometryEntryTypeSelect
              className={classes.field}
              selectedType={value}
              onChange={onChange}
              error={Boolean(errors.type)}
              helperText={errors.type?.message}
            />
          )}
        />
        <div className={classes.field} />
      </Row>
      <Row>
        <Controller
          control={control}
          name={"height"}
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value ? inchesToHeight(value) : ""}
              onChange={e => handleHeightChange(e, onChange)}
              className={classes.field}
              label="Height (ft. in.)"
              margin="dense"
              InputProps={{
                inputComponent: HeightTextMask as any,
              }}
              error={Boolean(errors.height)}
              helperText={errors.height?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"weight"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label={`Weight ${weightUnits}`}
              type="number"
              inputProps={{ min: 0 }}
              error={Boolean(errors.weight)}
              helperText={errors.weight?.message}
            />
          )}
        />
      </Row>

      <Row>
        <Controller
          control={control}
          name={"leanBodyMass"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label={`Lean Mass ${weightUnits}`}
              type="number"
              inputProps={{ min: 0 }}
              error={Boolean(errors.leanBodyMass)}
              helperText={errors.leanBodyMass?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"bodyFatMass"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label={`Fat Mass ${weightUnits}`}
              type="number"
              inputProps={{ min: 0 }}
              error={Boolean(errors.bodyFatMass)}
              helperText={errors.bodyFatMass?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"percentBodyFat"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label="% Body Fat"
              type="number"
              inputProps={{ min: 0, max: 100, step: 0.1 }}
              error={Boolean(errors.percentBodyFat)}
              helperText={errors.percentBodyFat?.message}
            />
          )}
        />

        <Controller
          control={control}
          name={"boneMineralDensityZScore"}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.field}
              margin="dense"
              label="BMD (Z-score)"
              type="number"
              error={Boolean(errors.boneMineralDensityZScore)}
              helperText={errors.boneMineralDensityZScore?.message}
            />
          )}
        />
      </Row>

      {formType === "bioelectricalImpedance" && (
        <Row>
          <Controller
            control={control}
            name={"skeletalMuscleMass"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label={`Skeletal Muscle Mass ${weightUnits}`}
                type="number"
                inputProps={{ min: 0 }}
                error={Boolean(errors.skeletalMuscleMass)}
                helperText={errors.skeletalMuscleMass?.message}
              />
            )}
          />

          <Controller
            control={control}
            name={"dryLeanMass"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label={`Dry Lean Mass ${weightUnits}`}
                type="number"
                inputProps={{ min: 0 }}
                error={Boolean(errors.dryLeanMass)}
                helperText={errors.dryLeanMass?.message}
              />
            )}
          />

          <Controller
            control={control}
            name={"visceralFatArea"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label="Visceral Fat Area (cm²)"
                type="number"
                inputProps={{ min: 0, max: 100, step: 0.1 }}
                error={Boolean(errors.visceralFatArea)}
                helperText={errors.visceralFatArea?.message}
              />
            )}
          />

          <Controller
            control={control}
            name={"trunkFat"}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.field}
                margin="dense"
                label={`Trunk Fat ${weightUnits}`}
                type="number"
                error={Boolean(errors.visceralFatArea)}
                helperText={errors.visceralFatArea?.message}
              />
            )}
          />
        </Row>
      )}

      {!hideCommentField && (
        <Controller
          control={control}
          name={"comment"}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ flex: "1 100%", mt: 2 }}
              margin="dense"
              label="Comment"
              multiline
              rows={3}
              error={Boolean(errors.comment)}
              helperText={errors.comment?.message}
            />
          )}
        />
      )}
    </Box>
  );
};

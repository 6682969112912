import { useApolloClient } from "@apollo/client";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { RecipesPageHeader } from "apps/web/src/views/Recipes/components/RecipesPageHeader";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { TableRecipeFragment, useDeleteRecipeMutation, useEditRecipeIsSharedMutation } from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import { MoveRecipeToOrgGroupDialog } from "../../../../views/Recipes/components/MoveRecipeToOrgGroupDialog";
import { RecipePrintDialog } from "../../../../views/Recipes/components/Printing/RecipePrintDialog";
import { RecipesPageTable } from "../../../../views/Recipes/components/RecipesPageTable";
import { RecipesPageProvider } from "../../../../views/Recipes/components/useRecipesPage";
import { getOrgRecipeNavigation } from "./FoodManagementPaths";
import { ORGANIZATION_RECIPES } from "./Utils";

export interface RecipeMoreInfo {
  moreAnchorElement: HTMLElement;
  recipe: TableRecipeFragment;
}

export const RecipesPage = () => {
  const apolloClient = useApolloClient();
  const [tab, setTab] = useState(ORGANIZATION_RECIPES);
  const [printRecipeId, setPrintRecipeId] = useState<string | null>(null);
  const [moveToOrgGroupRecipe, setMoveToOrgGroupRecipe] = useState<TableRecipeFragment | null>(null);
  const [deleteableRecipe, setDeleteableRecipe] = useState<TableRecipeFragment | null>(null);

  const { setMessage } = useSnackbar();
  const paginationHooks = useOffsetPagination(25);
  const { onChangePage } = paginationHooks;
  const navigate = useNavigate();

  function resetTable() {
    apolloClient.cache.evict({
      fieldName: "recipeOffsetConnection",
      broadcast: true,
    });
    apolloClient.cache.evict({
      fieldName: "orgGroupRecipeOffsetConnection",
      broadcast: true,
    });
    onChangePage(0);
  }

  const [deleteRecipe] = useDeleteRecipeMutation({
    onCompleted: () => {
      resetTable();
      setDeleteableRecipe(null);
      setMessage("success", `Deleted recipe`);
    },
  });
  const handleDelete = (recipeId: string) => {
    deleteRecipe({
      variables: { input: { recipeId } },
    });
  };

  const [editRecipeIsShared] = useEditRecipeIsSharedMutation();
  const handleEditIsShared = (id: string, isShared: boolean) => {
    const message = isShared ? "Shared recipe with athletes" : "Unshared recipe";
    setMessage("success", message);
    apolloClient.cache.modify({
      id: `Recipe:${id}`,
      fields: {
        isShared: () => isShared,
      },
    });
    editRecipeIsShared({
      variables: { input: { id, isShared } },
    });
  };

  const handleEditRecipe = (row: TableRecipeFragment) => {
    const { to, options } = getOrgRecipeNavigation(
      {
        readonly: !row.org && !row.orgGroup,
      },
      row.id
    );

    navigate(to, options);
  };

  const handleDuplicateRecipe = (duplicatedRecipeId: string) => {
    const { to, options } = getOrgRecipeNavigation({ duplicatedRecipeId, isDuplicate: true });

    navigate(to, options);
  };

  return (
    <RecipesPageProvider
      paginationHooks={paginationHooks}
      resetTable={resetTable}
      onCreateRecipe={() => {
        const { to, options } = getOrgRecipeNavigation();
        navigate(to, options);
      }}
      handleEditIsShared={handleEditIsShared}
      tab={tab}
      setTab={setTab}
      onEditRecipe={handleEditRecipe}
      onDuplicate={handleDuplicateRecipe}
      deleteableRecipe={deleteableRecipe}
      setDeleteableRecipe={setDeleteableRecipe}
      printRecipeId={printRecipeId}
      setPrintRecipeId={setPrintRecipeId}
      moveToOrgGroupRecipe={moveToOrgGroupRecipe}
      setMoveToOrgGroupRecipe={setMoveToOrgGroupRecipe}
    >
      <RecipesPageHeader />
      <RecipesPageTable />
      {deleteableRecipe !== null && (
        <ConfirmationDialog
          open={!!deleteableRecipe}
          title="Delete Recipe"
          message="Are you sure that you would like to delete the recipe?"
          onCancel={() => setDeleteableRecipe(null)}
          onConfirm={() => handleDelete(deleteableRecipe.id)}
          variant="containedDestructive"
        />
      )}
      {printRecipeId !== null && <RecipePrintDialog recipeId={printRecipeId} onClose={() => setPrintRecipeId(null)} />}
      {moveToOrgGroupRecipe !== null && (
        <MoveRecipeToOrgGroupDialog onClose={() => setMoveToOrgGroupRecipe(null)} setSuccessMsg={setMessage} />
      )}
    </RecipesPageProvider>
  );
};

import { useMenuBuilderNavQuery } from "apps/web/src/types";
import React from "react";
import { Route, Routes, useParams } from "react-router-dom-v5-compat";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { kitchenLink, menuBuilderLink } from "../Kitchen/KitchenPaths";
import { MenuBuilderPage } from "./MenuBuilderPage";
import { getNavOrgMenuBuilderLink } from "./MenuBuilderPaths";

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "Planned Menu",
  current,
  breadCrumbs: [kitchenLink, menuBuilderLink],
  tabs: [],
  enableAthleteSearch: true,
});

export const MenuBuilderRouter = () => {
  const { plannedMenuId: maybePlannedMenuId } = useParams();
  const plannedMenuId = maybePlannedMenuId || "";
  let plannedMenuName = "";
  const { data, loading } = useMenuBuilderNavQuery({ variables: { id: plannedMenuId } });

  if (!loading && data) {
    const {
      plannedMenu: { name },
    } = data;
    plannedMenuName = name;
  }

  const current = getNavOrgMenuBuilderLink(plannedMenuId, plannedMenuName);
  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  return (
    <Routes>
      <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route index element={<MenuBuilderPage />} />
      </Route>
    </Routes>
  );
};

import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { ProteinNutrientGroup } from "@notemeal/shared/ui/NutrientAmount/types";
import { sumAmountsForNutrient } from "@notemeal/shared/ui/NutrientAmount/utils";
import React from "react";
import NutrientTreeItem from "./NutrientTreeItem";
import { CloseSquare, MinusSquare, PlusSquare } from "./StyledTreeItem";
import { generateTreeStyles } from "./styles";

const useStyles = makeStyles((theme: Theme) => createStyles(generateTreeStyles(theme.palette.accents.indigo[50], theme)));

export interface ProteinTreeProps {
  proteins: ProteinNutrientGroup;
  displayDri: boolean;
}

const ProteinTree = ({ proteins, displayDri }: ProteinTreeProps) => {
  const classes = useStyles();

  const essential = sumAmountsForNutrient("Essential", proteins["Histidine"], [
    proteins["Isoleucine"],
    proteins["Leucine"],
    proteins["Methionine"],
    proteins["Phenylalanine"],
    proteins["Threonine"],
    proteins["Tryptophan"],
    proteins["Valine"],
    proteins["Lysine"],
  ]);

  const nonEssential = sumAmountsForNutrient("Non-essential", proteins["Cystine"], [
    proteins["Tyrosine"],
    proteins["Arginine"],
    proteins["Alanine"],
    proteins["Glutamine"],
    proteins["Glutamate"],
    proteins["Aspartic Acid"],
    proteins["Glycine"],
    proteins["Proline"],
    proteins["Serine"],
  ]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Proteins
      </Typography>
      <div className={classes.content}>
        <TreeView
          className={classes.tree}
          defaultExpanded={["1"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <NutrientTreeItem
            isRoot={true}
            nodeId="1"
            displayDri={displayDri}
            nutrientAmount={proteins["Protein"]}>
            <NutrientTreeItem
              nodeId="2"
              displayDri={displayDri}
              nutrientAmount={nonEssential}>
              <NutrientTreeItem
                nodeId="3"
                displayDri={displayDri}
                nutrientAmount={proteins["Cystine"]} />
              <NutrientTreeItem
                nodeId="4"
                displayDri={displayDri}
                nutrientAmount={proteins["Tyrosine"]} />
              <NutrientTreeItem
                nodeId="5"
                displayDri={displayDri}
                nutrientAmount={proteins["Arginine"]} />
              <NutrientTreeItem
                nodeId="6"
                displayDri={displayDri}
                nutrientAmount={proteins["Alanine"]} />
              <NutrientTreeItem
                nodeId="7"
                displayDri={displayDri}
                nutrientAmount={proteins["Glutamate"]} />
              <NutrientTreeItem
                nodeId="8"
                displayDri={displayDri}
                nutrientAmount={proteins["Glutamine"]} />
              <NutrientTreeItem
                nodeId="9"
                displayDri={displayDri}
                nutrientAmount={proteins["Aspartic Acid"]} />
              <NutrientTreeItem
                nodeId="10"
                displayDri={displayDri}
                nutrientAmount={proteins["Glycine"]} />
              <NutrientTreeItem
                nodeId="11"
                displayDri={displayDri}
                nutrientAmount={proteins["Proline"]} />
              <NutrientTreeItem
                nodeId="12"
                displayDri={displayDri}
                nutrientAmount={proteins["Serine"]} />
            </NutrientTreeItem>
            <NutrientTreeItem
              nodeId="13"
              displayDri={displayDri}
              nutrientAmount={essential}>
              <NutrientTreeItem
                nodeId="14"
                displayDri={displayDri}
                nutrientAmount={proteins["Histidine"]} />
              <NutrientTreeItem
                nodeId="15"
                displayDri={displayDri}
                nutrientAmount={proteins["Isoleucine"]} />
              <NutrientTreeItem
                nodeId="16"
                displayDri={displayDri}
                nutrientAmount={proteins["Leucine"]} />
              <NutrientTreeItem
                nodeId="17"
                displayDri={displayDri}
                nutrientAmount={proteins["Methionine"]} />
              <NutrientTreeItem
                nodeId="18"
                displayDri={displayDri}
                nutrientAmount={proteins["Phenylalanine"]} />
              <NutrientTreeItem
                nodeId="19"
                displayDri={displayDri}
                nutrientAmount={proteins["Threonine"]} />
              <NutrientTreeItem
                nodeId="20"
                displayDri={displayDri}
                nutrientAmount={proteins["Tryptophan"]} />
              <NutrientTreeItem
                nodeId="21"
                displayDri={displayDri}
                nutrientAmount={proteins["Valine"]} />
              <NutrientTreeItem
                nodeId="22"
                displayDri={displayDri}
                nutrientAmount={proteins["Lysine"]} />
            </NutrientTreeItem>
          </NutrientTreeItem>
        </TreeView>
      </div>
    </div>
  );
};

export default ProteinTree;

import React, { createContext, useContext, useState } from "react";
import { SnackbarVariant } from "./Snackbar";

interface SnackbarContextType {
  setMessage: (variant: SnackbarVariant, msg: string) => void;
  variant: SnackbarVariant;
  message: string;
}

const SnackbarContext = createContext<SnackbarContextType>({
  setMessage: () => {},
  variant: "info",
  message: "",
});

export interface SnackbarContextProviderProps {
  children: React.ReactNode;
}

export const SnackbarContextProvider = ({ children }: SnackbarContextProviderProps) => {
  const [variant, setInternalVariant] = useState<SnackbarVariant>("info");
  const [message, setInternalMessage] = useState<string>("");
  const setMessage = (variant: SnackbarVariant, message: string) => {
    setInternalVariant(variant);
    setInternalMessage(message);
  };
  return <SnackbarContext.Provider value={{ variant, message, setMessage }}>{children}</SnackbarContext.Provider>;
};

export const useSnackbar = () => {
  return useContext<SnackbarContextType>(SnackbarContext);
};

import { Box, SxProps } from "@mui/material";
import { ScoringSystemFragment } from "../types";
import { BulletScoreFilledIcon, BulletScoreUnfilledIcon } from "./Icons/BulletIcon";

const BADGE_SIZES = {
  sm: 4,
  lg: 6,
};

interface ScoreProps {
  scoreValue: number;
  scoringSystem?: ScoringSystemFragment | null;
  size?: "sm" | "lg";
  sx?: SxProps;
}

export const Score = ({ scoreValue, scoringSystem, size = "sm", sx }: ScoreProps) => {
  // [JIRA KS-360] use scoring system to determine icon display
  if (scoringSystem === null || scoringSystem === undefined || scoringSystem.name !== "Bullet Score") {
    return null;
  }

  // hardcoded for bullet scoring
  const upperBound = 3;

  let curIdx = 0;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        width: BADGE_SIZES[size],
        justifyContent: "space-between",
        gap: 0.2,
        ...sx,
      }}
    >
      {Array(upperBound)
        .fill(0)
        .map(() => {
          curIdx += 1;
          if (curIdx <= scoreValue) {
            return <BulletScoreFilledIcon key={curIdx} />;
          } else {
            return <BulletScoreUnfilledIcon key={curIdx} />;
          }
        })}
    </Box>
  );
};

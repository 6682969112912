import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { Box, Button, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from "@mui/material";
import TWItemizedTooltip from "apps/web/src/componentLibrary/TWTooltip/TWItemizedTooltip";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import React, { useState } from "react";

type PositionType =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

export const ThemeViewerTooltip = () => {
  const [longTitleChecked, setLongTitleChecked] = useState(false);
  const [longSubcopyChecked, setLongSubcopyChecked] = useState(false);
  const title = longTitleChecked ? "This is a very long title to make sure it wraps correctly" : "The Title";
  const subcopy = longSubcopyChecked ? "This is very long subcopy to make sure it wraps correctly" : "This is some subcopy";
  const positions = [
    "top-start",
    "top",
    "top-end",
    "right-start",
    "right",
    "right-end",
    "bottom-end",
    "bottom",
    "bottom-start",
    "left-end",
    "left",
    "left-start",
  ];
  const [index, setIndex] = useState(1);
  const handlePrev = () => setIndex(index === 0 ? positions.length - 1 : index - 1);
  const handleNext = () => setIndex(index === positions.length - 1 ? 0 : index + 1);

  return (
    <>
      <Typography variant="h2">Tooltip</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton onClick={handlePrev}>
              <RotateLeftIcon />
            </IconButton>
            <Typography sx={{ width: 100 }} align="center">
              {positions[index]}
            </Typography>
            <IconButton onClick={handleNext}>
              <RotateRightIcon />
            </IconButton>
          </Box>
          <FormControlLabel
            control={<Checkbox checked={longTitleChecked} onChange={() => setLongTitleChecked(!longTitleChecked)} />}
            label="Long Title"
          />
          <FormControlLabel
            control={<Checkbox checked={longSubcopyChecked} onChange={() => setLongSubcopyChecked(!longSubcopyChecked)} />}
            label="Long Subcopy"
          />
        </Box>
        <Box sx={{ display: "flex", gap: 6, alignItems: "center" }}>
          <TWTooltip
            leaveDelay={1000000}
            open={true}
            sx={{ my: 8, mx: 30, p: 5 }}
            title={title}
            subcopy={subcopy}
            placement={positions[index] as PositionType}
          >
            <Button>ACTION</Button>
          </TWTooltip>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4">TWTooltip</Typography>
            <TWTooltip
              title={title}
              subcopy={subcopy}
              placement={positions[index] as PositionType}>
              <Button>ACTION</Button>
            </TWTooltip>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4">TWItemizedTooltip</Typography>
            <TWItemizedTooltip
              title={title}
              items={[subcopy, subcopy, subcopy]}
              placement={positions[index] as PositionType}>
              <Button>ACTION</Button>
            </TWItemizedTooltip>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h4">MUI Tooltip</Typography>
            <Tooltip title={title} placement={positions[index] as PositionType}>
              <Button>ACTION</Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};

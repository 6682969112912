import FoodBankIcon from "@mui/icons-material/FoodBankRounded";
import React from "react";

// full page path
export const NAV_ORG_KITCHEN = "/org/kitchen";

// local child paths
export const NAV_KITCHEN_MENU_SCHEDULE = "menu-schedule";
export const NAV_KITCHEN_MENU_BUILDER = "menu-builder";
export const NAV_KITCHEN_MENU_ITEMS = "menu-items";
export const NAV_KITCHEN_DINING_STATION_TEMPLATES = "dining-station-templates";
export const NAV_KITCHEN_KDS = "kds";
export const NAV_KITCHEN_KIOSK = "kiosk";
export const NAV_KITCHEN_MENU_ATTENDANCE = "menu-attendance";

// full child paths
export const NAV_ORG_KITCHEN_MENU_SCHEDULE = `${NAV_ORG_KITCHEN}/${NAV_KITCHEN_MENU_SCHEDULE}`;
export const NAV_ORG_KITCHEN_MENU_BUILDER = `${NAV_ORG_KITCHEN}/${NAV_KITCHEN_MENU_BUILDER}`;
export const NAV_ORG_KITCHEN_MENU_ATTENDANCE = `${NAV_ORG_KITCHEN}/${NAV_KITCHEN_MENU_ATTENDANCE}`;
export const NAV_ORG_KITCHEN_KDS = `${NAV_ORG_KITCHEN}/${NAV_KITCHEN_KDS}`;
export const NAV_ORG_KITCHEN_KIOSK = `${NAV_ORG_KITCHEN}/${NAV_KITCHEN_KIOSK}`;
export const getNavOrgKitchenMenuScheduleDate = (date: string) => `${NAV_ORG_KITCHEN_MENU_SCHEDULE}/${date}`;
export const getNavOrgKitchenKioskDate = (date: string) => `${NAV_ORG_KITCHEN_KIOSK}/${date}`;
export const getNavOrgKitchenMenuScheduleImport = (date: string, plannedMenuId: string) =>
  `${NAV_ORG_KITCHEN_MENU_SCHEDULE}/${date}/${plannedMenuId}`;

export const kitchenLink = {
  to: NAV_ORG_KITCHEN,
  name: "Kitchen",
  icon: <FoodBankIcon />,
};

export const menuBuilderLink = {
  name: "Menu Builder",
  to: NAV_ORG_KITCHEN_MENU_BUILDER,
};

export const menuScheduleLink = {
  name: "Menu Schedule",
  to: NAV_ORG_KITCHEN_MENU_SCHEDULE,
};

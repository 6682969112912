import { ApolloCache, MutationUpdaterFn } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import DialogTitleWithTooltip from "apps/web/src/componentLibrary/DialogTitleWithToolTip";

import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import {
  AthleteSummaryAthleteFragment,
  EditAthleteMutation,
  EditedAthleteFragment,
  EditSyncedAthleteMutation,
  TeamsPageDocument,
  TeamsPageQuery,
  TeamWithSportFragment,
  useEditAthleteMutation,
} from "../../../types";
import Form from "../Form";
import { athleteFormDefaultValues, AthleteFormSchema, athleteFormToEditInput, AthleteFormType } from "../Form/AthleteFormSchema";

export const editAthleteMutationUpdater =
  (teamIdRef: React.MutableRefObject<string>, athleteId: string) => (): MutationUpdaterFn<EditAthleteMutation> => (cache, mutationResult) =>
    baseEditAthleteMutationUpdater(teamIdRef, athleteId, cache, mutationResult.data?.editAthlete.athlete);

export const editSyncedAthleteMutationUpdater =
  (teamIdRef: React.MutableRefObject<string>, athleteId: string) =>
  (): MutationUpdaterFn<EditSyncedAthleteMutation> =>
  (cache, mutationResult) =>
    baseEditAthleteMutationUpdater(teamIdRef, athleteId, cache, mutationResult.data?.editSyncedAthlete.athlete);

const baseEditAthleteMutationUpdater = (
  teamIdRef: React.MutableRefObject<string>,
  athleteId: string,
  cache: ApolloCache<any>,
  athlete?: EditedAthleteFragment
) => {
  const teamsPageQuery: TeamsPageQuery | null = cache.readQuery({
    query: TeamsPageDocument,
    variables: {},
  });
  if (teamsPageQuery && athlete) {
    const athleteNotifications = athlete.timelineMealNotifications;
    if (teamIdRef.current !== athlete.team.id) {
      // Need to remove 'moved athlete' notifications if the athlete moved!
      cache.writeQuery<TeamsPageQuery>({
        query: TeamsPageDocument,
        data: {
          teams: teamsPageQuery.teams.map(t =>
            t.id === teamIdRef.current
              ? {
                  ...t,
                  recentNotifications: t.recentNotifications.filter(teamNotif => athleteNotifications.every(n => n.id !== teamNotif.id)),
                  athletes: t.athletes.filter(a => a.id !== athleteId),
                }
              : t
          ),
        },
      });
    }
  }
};

interface AthleteEditModalProps {
  athlete: AthleteSummaryAthleteFragment;
  open: boolean;
  onClose: () => void;
  teams: readonly TeamWithSportFragment[];
  isNotemealLinked: boolean;
}

const AthleteEditModal = ({ open, onClose, athlete, teams, isNotemealLinked }: AthleteEditModalProps) => {
  const isForNotemealOnlyAthlete = isNotemealLinked && athlete.isProfileNotemealOnly;
  const form = useForm<AthleteFormType>({
    defaultValues: athleteFormDefaultValues(athlete.team.id, athlete),
    resolver: zodResolver(AthleteFormSchema),
  });

  const hasFormErrors = form.formState.isSubmitted && !form.formState.isValid;

  const teamIdRef = useRef(athlete.team.id);
  const [editAthlete] = useEditAthleteMutation({
    update: editAthleteMutationUpdater(teamIdRef, athlete.id),
  });

  const submit = (newAthlete: AthleteFormType) => {
    const { sex, teamId } = newAthlete;
    if (sex) {
      editAthlete({
        variables: {
          input: athleteFormToEditInput({ ...newAthlete, sex, teamId }, athlete.id, athlete.allOrderedNamedTags),
        },
      });
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      {isForNotemealOnlyAthlete ? (
        <DialogTitleWithTooltip
          title="Edit Nutrition-Only Athlete"
          tooltipText="This athlete will be added as a Nutrition-only user and will not have a profile created in Teamworks. Nutrition-only users will not be able to use the Teamworks SSO to sign in or have their profile information synced with Teamworks."
          onClose={onClose}
        />
      ) : (
        <DialogTitle title="Edit Athlete" onClose={onClose} />
      )}
      <DialogContent>
        <Form
          displayUidFields
          teams={teams}
          isForSyncedAthlete={false}
          username={athlete.user.username ?? undefined}
          form={form} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
          <Button onClick={form.handleSubmit(submit)}>Save</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AthleteEditModal;

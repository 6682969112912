import { format, isAfter, isToday, isYesterday, subDays } from "date-fns";

export const formatMessageDate = (lastMessageAt: Date | null) => {
  if (!lastMessageAt) {
    return " ";
  }
  if (isToday(lastMessageAt)) {
    return format(lastMessageAt, "p");
  } else if (isYesterday(lastMessageAt)) {
    return "Yesterday"; // todo translation i18n
  } else if (isAfter(lastMessageAt, subDays(new Date(), 4))) {
    return format(lastMessageAt, "EEEE");
  }

  return format(lastMessageAt, "PP");
};

import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";

interface MenuItemMaxAmountInputProps {
  defaultLabel?: boolean;
  value: number | null;
  onChange: (value: number | null) => void;
  disabled?: boolean;
}

const MenuItemMaxAmountInput = ({ defaultLabel = false, value, onChange, disabled }: MenuItemMaxAmountInputProps) => {
  return (
    <>
      <FormControlLabel
        label={defaultLabel ? "Default Limit per Order" : "Limit per Order"}
        control={
          <Checkbox
            size="small"
            checked={value !== null}
            onChange={e => onChange(e.target.checked ? 1 : null)}
            disabled={disabled} />
        }
      />
      {value !== null && (
        <TextField
          label="Max per Order"
          type="number"
          inputProps={{
            min: 1,
          }}
          sx={{ width: 110, height: 48 }}
          value={value}
          onChange={e => onChange(parseInt(e.target.value))}
          error={isNaN(value)}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default MenuItemMaxAmountInput;

import { Button, DialogContent, Divider, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { getBaseFoodFormDefaultValues } from "../FoodFormSchema/FoodFormSchema";
import { AddFoodRequestField } from "./AddFoodRequestField";
import { OrgFoodModal } from "./OrgFoodModal";

interface FoodFormProps {
  onClose: () => void;
  onRequestSuccess: (msg: string) => void;
  onRequestFailure: (msg: string) => void;
}

export const OrgFoodRequestForm = ({ onClose, onRequestSuccess, onRequestFailure }: FoodFormProps) => {
  const [isManualFoodFormOpen, setIsManualFoodFormOpen] = useState(false);

  return (
    <>
      <DialogTitle title="Request/Add Food" onClose={onClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h4">Request Food</Typography>
        <Typography variant="body2" color="textSecondary">
          Enter the name of the food that you would like to request and our support team will add the food for you.
        </Typography>
        <AddFoodRequestField
          onSuccess={food => onRequestSuccess(`Request for "${food}" recieved and will be added by the Nutrition staff shortly!`)}
          onFailure={food => onRequestFailure(`Request to add "${food}" failed.`)}
        />
        <Divider sx={{ my: 4 }} />
        <Typography variant="h4">Add Food</Typography>
        <Typography variant="body2" color="textSecondary">
          Manually add a food by filling out some details. This food will be specific to your organization and available for your athletes
          to view and log.
        </Typography>
        <Button
          variant="outlined"
          sx={{ width: "97px", whiteSpace: "nowrap", mb: 8 }}
          onClick={() => setIsManualFoodFormOpen(true)}>
          Add Food
        </Button>
        {isManualFoodFormOpen && (
          <OrgFoodModal
            open={isManualFoodFormOpen}
            onClose={() => {
              setIsManualFoodFormOpen(false);
              onClose();
            }}
            initialFormState={getBaseFoodFormDefaultValues("org")}
          />
        )}
      </DialogContent>
    </>
  );
};

import { Box, Typography } from "@mui/material";
import React from "react";

export const FontCheckPage = () => {
  return (
    <Box sx={{ flex: 1, m: 4, display: "flex", gap: 5 }}>
      <Box>
        <Typography sx={{ fontWeight: 100 }}>FontWeight 100</Typography>
        <Typography sx={{ fontWeight: 200 }}>FontWeight 200</Typography>
        <Typography sx={{ fontWeight: 300 }}>FontWeight 300</Typography>
        <Typography sx={{ fontWeight: 400 }}>FontWeight 400</Typography>
        <Typography sx={{ fontWeight: 500 }}>FontWeight 500</Typography>
        <Typography sx={{ fontWeight: 600 }}>FontWeight 600</Typography>
        <Typography sx={{ fontWeight: 700 }}>FontWeight 700</Typography>
        <Typography sx={{ fontWeight: 800 }}>FontWeight 800</Typography>
        <Typography sx={{ fontWeight: 900 }}>FontWeight 900</Typography>
        <Typography sx={{ fontWeight: 1000 }}>FontWeight 1000</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontStyle: "italic", fontWeight: 100 }}>FontStyle Italic with FontWeight 100</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 200 }}>FontStyle Italic with FontWeight 200</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 300 }}>FontStyle Italic with FontWeight 300</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 400 }}>FontStyle Italic with FontWeight 400</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 500 }}>FontStyle Italic with FontWeight 500</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 600 }}>FontStyle Italic with FontWeight 600</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 700 }}>FontStyle Italic with FontWeight 700</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 800 }}>FontStyle Italic with FontWeight 800</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 900 }}>FontStyle Italic with FontWeight 900</Typography>
        <Typography sx={{ fontStyle: "italic", fontWeight: 1000 }}>FontStyle Italic with FontWeight 1000</Typography>
      </Box>
    </Box>
  );
};

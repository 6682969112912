import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import Loading from "@notemeal/shared/ui/global/Loading";
import { AnthropometrySnapshot } from "@notemeal/shared/utils/macro-protocol";
import { initialMealPlanFormState, mealPlanFormReducer } from "apps/web/src/components/MealPlan/Form/utils";
import MealPlanSearchForm from "apps/web/src/components/MealPlan/SearchForm";
import {
  initialMealPlanSearchFormState,
  mealPlanSearchFormReducer,
  mealPlanSearchFormToSaveTooltips,
} from "apps/web/src/components/MealPlan/SearchForm/utils";
import { getInitialMealPlanName } from "apps/web/src/components/MealPlan/utils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import ActionDialogContent from "apps/web/src/components/universal/ActionDialogContent";
import { getNavOrgAthleteMealPlansMealPlan } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import { ExchangeSetFragment, FullGoalFragment, useCopyMealPlanNewMutation } from "apps/web/src/types";
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { NameAndTypeForm } from "../../../../components/MealPlan/NameAndTypeForm";
import { nameAndTypeFormToSaveTooltips } from "../../../../components/MealPlan/NameAndTypeForm/utils";
import { dateAssignmentReducer, initDateAssignmentState } from "../Content/Calendar/Form/reducer";
import { mealPlanDateAssignmentToSaveTooltips } from "../Content/Calendar/Form/utils";
import { evictMealPlanCalendarQueriesForAthlete } from "../Content/Calendar/cache";
import DateAssignment from "../Content/DateAssignment";
import { getCopyMealPlanInput } from "./utils";

const SEARCH_MEAL_PLANS_STEP = "Search Meal Plans";
const CALENDAR_STEP = "Calendar";
const NAME_TYPE_STEP = "Name and Type";
const steps = ["Search Meal Plans", "Calendar", "Name and Type"] as const;
type DialogStep = (typeof steps)[number];

interface CopyMealPlanDialogContentProps {
  athleteId: string;
  anthropometrySnapshot: AnthropometrySnapshot;
  currentGoal: FullGoalFragment | null;
  sportName: string;
  onClose: () => void;
  exchangeSets: readonly ExchangeSetFragment[];
}

const CopyMealPlanDialogContent = ({
  athleteId,
  anthropometrySnapshot,
  onClose,
  sportName,
  currentGoal,
  exchangeSets,
}: CopyMealPlanDialogContentProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const activeStepName: DialogStep = steps[activeStep];
  const navigate = useNavigate();
  const { setMessage } = useSnackbar();
  const clientTimezone = useClientTimezone();

  const [mptSearchFormState, mptSearchFormDispatch] = useReducer(mealPlanSearchFormReducer, {}, initialMealPlanSearchFormState);
  const [mptFormState, mptFormDispatch] = useReducer(mealPlanFormReducer, initialMealPlanFormState(exchangeSets));
  const [dateAssignmentState, dateAssignmentDispatch] = useReducer(dateAssignmentReducer, initDateAssignmentState(clientTimezone));
  const [isAutoSuggestionsEnabled, setIsAutoSuggestionsEnabled] = useState(false);

  const [copyMealPlan] = useCopyMealPlanNewMutation({
    update: (cache, { data }) => {
      if (data) {
        evictMealPlanCalendarQueriesForAthlete({
          athleteId,
          cache,
          type: "Create",
        });
      }
    },
    onCompleted: data => {
      if (data) {
        const mealPlanId = data.copyMealPlanNew.mealPlan.id;
        onClose();
        navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, mealPlanId));
      }
    },
    onError: () => setMessage("error", "Something went wrong"),
  });

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStepName) {
      case SEARCH_MEAL_PLANS_STEP:
        return mealPlanSearchFormToSaveTooltips(mptSearchFormState);
      case CALENDAR_STEP:
        return mealPlanDateAssignmentToSaveTooltips(dateAssignmentState);
      case NAME_TYPE_STEP:
        return nameAndTypeFormToSaveTooltips(mptFormState);
      default:
        return [];
    }
  };

  const runSideEffects = () => {
    const { selectedMealPlan } = mptSearchFormState;
    switch (activeStepName) {
      case SEARCH_MEAL_PLANS_STEP:
        if (selectedMealPlan) {
          mptFormDispatch({
            type: "CHANGE_TYPE",
            payload: selectedMealPlan.type,
          });
          mptFormDispatch({
            type: "CHANGE_NAME",
            payload: getInitialMealPlanName(selectedMealPlan.macroProtocol, anthropometrySnapshot, currentGoal, sportName),
          });
          setIsAutoSuggestionsEnabled(selectedMealPlan.isAutoSuggestionsEnabled);

          const exchangeSetId = selectedMealPlan.exchangeSet?.id;
          if (exchangeSetId) {
            const exchangeSet = exchangeSets.find(ex => ex.id === exchangeSetId);
            if (exchangeSet) {
              mptFormDispatch({
                type: "CHANGE_EXCHANGE_SET",
                payload: exchangeSet,
              });
            }
          }
        }
        break;
      case NAME_TYPE_STEP:
        if (selectedMealPlan) {
          const input = getCopyMealPlanInput(athleteId, selectedMealPlan.id, mptFormState, dateAssignmentState, isAutoSuggestionsEnabled);
          if (!input) {
            return;
          }

          copyMealPlan({
            variables: {
              input,
            },
          });
        }
        break;
    }
  };

  return (
    <ActionDialogContent
      title="Copy From Existing Meal Plan"
      steps={steps}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      runSideEffects={runSideEffects}
      getCanSaveTooltipItems={getCanSaveTooltipItems}
      onClose={onClose}
    >
      {activeStepName === SEARCH_MEAL_PLANS_STEP ? (
        <MealPlanSearchForm
          anthropometrySnapshot={anthropometrySnapshot}
          state={mptSearchFormState}
          dispatch={mptSearchFormDispatch} />
      ) : activeStepName === CALENDAR_STEP ? (
        <DateAssignment
          state={dateAssignmentState}
          dispatch={dateAssignmentDispatch}
          isAutoSuggestionsEnabled={isAutoSuggestionsEnabled}
          setIsAutoSuggestionsEnabled={setIsAutoSuggestionsEnabled}
        />
      ) : activeStepName === NAME_TYPE_STEP ? (
        <NameAndTypeForm
          state={mptFormState}
          dispatch={mptFormDispatch}
          editMode
          copyForm={true} />
      ) : (
        <Loading progressSize="md" />
      )}
    </ActionDialogContent>
  );
};

export default CopyMealPlanDialogContent;

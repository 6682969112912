import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useEffect } from "react";
import IntersectRow from "./IntersectRow";
import { UseInfiniteCursorConnectionScrollResults } from "./useInfiniteCursorConnectionScroll";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      height: "unset",
      marginTop: theme.spacing(1),
    },
  })
);

interface InfiniteScrollContainerProps<K extends string, EdgeType> {
  renderItem: (edge: EdgeType, forwardRef?: React.MutableRefObject<HTMLDivElement | null>) => JSX.Element;
  loadingOffset?: number;
  loadingComponent?: JSX.Element;
  noItemsComponent?: JSX.Element;
  infiniteScrollResults: UseInfiniteCursorConnectionScrollResults<K, EdgeType>;
  filterEdges?: (edge: EdgeType) => boolean;
}

const InfiniteScrollContainer = <K extends string, EdgeType>({
  renderItem,
  loadingOffset = 0,
  loadingComponent: _loadingComponent,
  noItemsComponent,
  infiniteScrollResults,
  filterEdges,
}: InfiniteScrollContainerProps<K, EdgeType>) => {
  const classes = useStyles();
  const loadingComponent = _loadingComponent ?? <Loading progressSize="xs" classes={{ root: classes.loading }} />;
  const { edges: _edges = [], hasNextPage, next, loading } = infiniteScrollResults;
  const edges = filterEdges ? _edges.filter(filterEdges) : _edges;

  useEffect(() => {
    if (edges.length === 0 && hasNextPage && !loading) {
      next();
    }
  }, [edges.length, next, hasNextPage, loading]);

  return (
    <>
      {edges.map((edge, index) => {
        return index === Math.max(edges.length - (1 + loadingOffset), 0) && hasNextPage ? (
          <IntersectRow
            next={next}
            key={`intersect-row-for-edge-${index}`}
            renderChild={forwardRef => renderItem(edge, forwardRef)} />
        ) : (
          renderItem(edge)
        );
      })}
      {loading && loadingComponent}
      {edges.length === 0 && !hasNextPage && !loading && noItemsComponent}
    </>
  );
};

export default InfiniteScrollContainer;

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, Switch, TableCell, TableRow, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";
import { HeaderRowInfo, Idable } from "./types";

const useStyles = makeStyles(({ palette: { greyscale } }: Theme) => ({
  onHover: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: greyscale[100],
    },
  },
}));

export interface TogglableRowProps<T extends Idable> {
  headerRowInfo: HeaderRowInfo;
  onToggle: (obj: T) => void;
  getToggleValue: (obj: T) => boolean;
  disabled?: (obj: T) => boolean;
  tooltip?: (obj: T) => string;
}

export interface MenuAction<T extends Idable> {
  label: string;
  action: (obj: T) => void;
}

interface TableHeaderRowProps<T extends Idable> {
  headerRowInfo: HeaderRowInfo[];
  hasDelete?: boolean;
  hasEdit?: boolean;
  togglable?: TogglableRowProps<T>;
  hasActionMenu?: Boolean;
}

interface TableRowProps<T extends Idable> {
  obj: T;
  onEdit?: (obj: T) => void;
  onDelete?: (obj: T) => void;
  onRowClick?: (obj: T) => void;
  actionMenuItems?: MenuAction<T>[];
  rowData: Record<string, ReactNode>;
  headerRowInfo: HeaderRowInfo[];
  togglable?: TogglableRowProps<T>;
}

export const TableHeaderRow = <T extends Idable>({
  headerRowInfo,
  hasDelete,
  hasEdit,
  togglable,
  hasActionMenu,
}: TableHeaderRowProps<T>) => (
  <TableRow>
    {togglable && (
      <TableCell align="center" key="header-row-toggle">
        {togglable.headerRowInfo.displayName}
      </TableCell>
    )}
    {hasEdit && (
      <TableCell
        key="header-row-edit"
        padding="checkbox"
        align="center"
        width={48}>
        Edit
      </TableCell>
    )}
    {headerRowInfo.map(col => (
      <TableCell key={`header-row-${col.key}`}>{col.displayName}</TableCell>
    ))}
    {hasDelete && (
      <TableCell key="header-row-delete" align="right">
        Delete
      </TableCell>
    )}
    {hasActionMenu && (
      <TableCell key="header-row-actions-menu" align="center">
        Actions
      </TableCell>
    )}
  </TableRow>
);

const GeneralTableRow = <T extends Idable>({
  obj,
  onEdit,
  onDelete,
  onRowClick,
  actionMenuItems,
  rowData,
  headerRowInfo,
  togglable,
}: TableRowProps<T>) => {
  const classes = useStyles();
  const hasRowClick = onRowClick !== undefined;
  const onClick = onRowClick ? () => onRowClick(obj) : undefined;

  const [actionMenuAnchor, setActionMenuAnchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(actionMenuAnchor);
  const openActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionMenuAnchor(event.currentTarget);
  };
  const closeActionMenu = () => {
    setActionMenuAnchor(null);
  };

  return (
    <TableRow
      className={hasRowClick ? classes.onHover : undefined}
      onClick={onClick}
      key={`${obj.id}-innerR-row`}>
      {togglable && (
        <TableCell scope="row" align="center">
          <Tooltip title={togglable.tooltip && togglable.tooltip(obj)}>
            <div>
              <Switch
                disabled={togglable.disabled && togglable.disabled(obj)}
                sx={{ marginY: -1 }}
                size="medium"
                checked={Boolean(rowData[togglable.headerRowInfo.key])}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  togglable.onToggle(obj);
                }}
              />
            </div>
          </Tooltip>
        </TableCell>
      )}
      {onEdit && (
        <TableCell scope="row" align="center">
          <IconButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onEdit(obj);
            }}
            size="large"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </TableCell>
      )}

      {headerRowInfo.map(col => (
        <TableCell key={`row-${obj.id}-col-${col.key}`}>{rowData[col.key]}</TableCell>
      ))}

      {onDelete && (
        <TableCell scope="row">
          <IconButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(obj);
            }}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      )}
      {actionMenuItems && (
        <TableCell scope="row" align="center">
          <IconButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              openActionMenu(e);
            }}
            size="large"
            sx={{ marginY: -1 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            anchorEl={actionMenuAnchor}
            open={open}
            onClose={closeActionMenu}
          >
            {actionMenuItems.map(({ label, action }) => (
              <MenuItem
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  action(obj);
                  closeActionMenu();
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

export default GeneralTableRow;

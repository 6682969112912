import { EditMacroState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import {
  AnthropometrySnapshot,
  measurementConversionToImperial,
  measurementConversionToMetric,
  roundToHundredthsFloor,
} from "@notemeal/shared/utils/macro-protocol";
import {
  CreateMacroMealPlanTemplateInput,
  EditMacroMealPlanTemplateInput,
  FoodPreferenceInput,
  MacroMealPlanTemplateMealInput,
  MacroMealPlanTemplatePendingStateInput,
  MealPlanTemplateActivityInput,
  MealPlanTemplateAnthropometryInput,
  MealPlanTemplateCalorieBudgetInput,
  MealPlanTemplateMacroProtocolInput,
  PublishMacroMealPlanTemplateInput,
} from "../../types";
import { macroTargetStateToMathInput } from "../../utils/macroProtocol";
import { FoodPreferenceState, foodPreferenceFormToSaveTooltips, pluckIdsFromFoodPreferenceState } from "../FoodPreference/MealPlan/reducer";
import { MealPlanTemplateGoalState } from "./MacroProtocol/Goals/goalsReducer";
import { AnthropometryState } from "./MacroProtocol/reducer/anthropometryReducer";
import { CalorieBudgetState } from "./MacroProtocol/reducer/calorieBudgetReducer";
import { MacroProtocolState } from "./MacroProtocol/reducer/macroProtocolReducer";
import { MacroType, createMacroCalorieAction, macroReducer } from "./MacroProtocol/reducer/macroReducer";
import { getAnthroSnapshotFromAnthroState, getCanSaveMacroProtocolToolTips } from "./MacroProtocol/utils/macroProtocolUtils";
import { ActivityInputWithId, MealInputWithId, getCanSaveScheduleTooltips } from "./Schedule/scheduleReducer";
import { EditMacroMealPlanTemplateState } from "./mealPlanTemplateReducer";

interface BuildInputArgs {
  name: string;
  description: string | null;
  macroProtocolState: MacroProtocolState;
  foodPreferenceFormState: FoodPreferenceState;
  meals: MealInputWithId[];
  activities: ActivityInputWithId[];
}

export const buildAnthropometryInputFromState = (
  anthro: AnthropometryState,
  isMetricLocale: boolean
): MealPlanTemplateAnthropometryInput => {
  switch (anthro.__typename) {
    case "FormFields":
      return {
        template: null,
        formFields: anthro.formFields,
      };
    case "Template":
      return {
        formFields: null,
        template: {
          height: measurementConversionToMetric(isMetricLocale, anthro.height, "length"),
          percentBodyFat: anthro.percentBodyFat,
          weight: measurementConversionToMetric(isMetricLocale, anthro.weight, "weight")!,
        },
      };
  }
};

export const buildCalorieBudgetInputFromState = (calorieBudget: CalorieBudgetState): MealPlanTemplateCalorieBudgetInput => {
  return {
    activityFactor: calorieBudget.activityFactor,
    goals: calorieBudget.goals.map(goal => ({
      goalTypeId: goal.goalTypeId,
      kcalOffset: goal.kcalOffset,
      name: goal.name,
    })),
    rmrMethod: calorieBudget.rmrMethod,
  };
};

export const buildMacroProtocolInputFromState = (
  macroProtocolState: MacroProtocolState,
  isMetricLocale: boolean
): MealPlanTemplateMacroProtocolInput => {
  const hasCalorieBudget = macroProtocolState.usingCalorieBudget;

  const calorieBudget: MealPlanTemplateCalorieBudgetInput | null = hasCalorieBudget
    ? buildCalorieBudgetInputFromState(macroProtocolState.calorieBudget)
    : null;
  const anthropometry = buildAnthropometryInputFromState(macroProtocolState.anthropometry, isMetricLocale);

  return {
    anthropometry,
    calorieBudget,
    cho: macroTargetStateToMathInput(macroProtocolState.cho, hasCalorieBudget),
    fat: macroTargetStateToMathInput(macroProtocolState.fat, hasCalorieBudget),
    pro: macroTargetStateToMathInput(macroProtocolState.pro, hasCalorieBudget),
    useExistingAnthropometry: macroProtocolState.useExistingAnthropometry,
  };
};

export const buildActivitiesInputFromState = (activities: ActivityInputWithId[]): MealPlanTemplateActivityInput[] => {
  return activities.map(({ end, name, start, type }) => ({
    end,
    name,
    start,
    type,
  }));
};

export const v1 = "V1";
export const additionalStateCurrentVersion = "V1";

export interface AdditionalState {
  anthropometry: AnthropometryState;
  calorieBudget: CalorieBudgetState;
}

export const convertImperialAnthropometryStateToMetric = (anthropometry: AnthropometryState): AnthropometryState => {
  const { __typename } = anthropometry;
  if (__typename === "FormFields") {
    const { sampleHeight, sampleWeight } = anthropometry;
    const sampleMetricHeight = measurementConversionToMetric(false, sampleHeight, "length");
    const sampleMetricWeight = measurementConversionToMetric(false, sampleWeight, "weight")!;
    return {
      ...anthropometry,
      sampleHeight: sampleMetricHeight,
      sampleWeight: sampleMetricWeight,
      sampleHeightInput: sampleMetricHeight ? String(roundToHundredthsFloor(sampleMetricHeight)) : "",
      sampleWeightInput: String(roundToHundredthsFloor(sampleMetricWeight)),
    };
  } else {
    const { height, weight } = anthropometry;
    const metricWeight = measurementConversionToMetric(false, weight, "weight")!;
    const metricHeight = measurementConversionToMetric(false, height, "length");
    return {
      ...anthropometry,
      height: metricHeight,
      weight: metricWeight,
      heightInput: metricHeight ? String(roundToHundredthsFloor(metricHeight)) : "",
      weightInput: String(roundToHundredthsFloor(metricWeight)),
    };
  }
};

export const parseAdditionalState = (additionalState: string | null): AdditionalState | null => {
  if (!additionalState) {
    return null;
  }
  const additionalStateParsed: AdditionalState & { version?: string } = JSON.parse(additionalState);

  if (!additionalStateParsed.version) {
    const { anthropometry, calorieBudget } = additionalStateParsed;

    return {
      calorieBudget,
      anthropometry: convertImperialAnthropometryStateToMetric(anthropometry),
    };
  }
  if (additionalStateParsed.version === v1) {
    return additionalStateParsed;
  }

  throw new Error(`Unknown additionalState version: ${additionalStateParsed.version}`);
};

const serializeAdditionalState = (additionalState: AdditionalState): string => {
  return JSON.stringify({ ...additionalState, version: additionalStateCurrentVersion });
};

export const buildMealsInputFromState = (meals: MealInputWithId[]): MacroMealPlanTemplateMealInput[] =>
  meals.map(({ choRatio, fatRatio, proRatio, meal, mealOptions }) => ({
    choRatio,
    fatRatio,
    proRatio,
    meal,
    mealOptions: mealOptions.map(({ servingAmounts, note, name }, index) => ({
      servingAmounts,
      position: index,
      note,
      name,
    })),
  }));

export const buildCreateOrPublishInput = (
  { name, description, macroProtocolState, foodPreferenceFormState, meals, activities }: BuildInputArgs,
  isMetricLocale: boolean
): CreateMacroMealPlanTemplateInput | EditMacroMealPlanTemplateInput => {
  const foodPreferences: FoodPreferenceInput = pluckIdsFromFoodPreferenceState(foodPreferenceFormState);
  const macroProtocol = buildMacroProtocolInputFromState(macroProtocolState, isMetricLocale);
  const { anthropometry, calorieBudget } = macroProtocolState;
  // TODO: need to version this
  const additionalState = serializeAdditionalState({ anthropometry, calorieBudget });
  return {
    name,
    description,
    additionalState,
    activities: buildActivitiesInputFromState(activities),
    foodPreferences,
    macroProtocol,
    meals: buildMealsInputFromState(meals),
  };
};

export const buildMacroMealPlanTemplatePendingStateInputFromState = (
  state: EditMacroMealPlanTemplateState
): MacroMealPlanTemplatePendingStateInput => ({
  macroMealPlanTemplateId: state.id,
  pendingState: JSON.stringify(state),
});

export const buildPublishMacroMealPlanTemplateInputFromState = (
  state: EditMacroMealPlanTemplateState,
  isMetricLocale: boolean
): PublishMacroMealPlanTemplateInput => ({
  macroMealPlanTemplateId: state.id,
  macroMealPlanTemplate: buildCreateOrPublishInput(
    {
      name: state.name,
      description: state.description,
      macroProtocolState: state.macroProtocol,
      foodPreferenceFormState: state.foodPreferences,
      meals: state.meals,
      activities: state.activities,
    },
    isMetricLocale
  ),
});

export const getCanSaveMacroMealPlanTemplateToolTips = (state: EditMacroMealPlanTemplateState): string[] => {
  return [
    ...getCanSaveMacroProtocolToolTips(state.macroProtocol),
    ...foodPreferenceFormToSaveTooltips(state.foodPreferences),
    ...getCanSaveScheduleTooltips({
      meals: state.meals,
      activities: state.activities,
    }),
    ...(state.name === "" ? ["Meal Plan Template name is required"] : []),
  ];
};

export interface TotalParts {
  choTotalParts: number;
  proTotalParts: number;
  fatTotalParts: number;
}

export const getTotalParts = (meals: MealInputWithId[]): TotalParts => {
  return meals.reduce(
    (acc, meal) => {
      return {
        choTotalParts: acc.choTotalParts + meal.choRatio,
        proTotalParts: acc.proTotalParts + meal.proRatio,
        fatTotalParts: acc.fatTotalParts + meal.fatRatio,
      };
    },
    {
      choTotalParts: 0,
      proTotalParts: 0,
      fatTotalParts: 0,
    }
  );
};

export const convertMPTMacroAnthroInputsToLocale = (
  state: EditMacroMealPlanTemplateState,
  isMetricLocale: boolean
): EditMacroMealPlanTemplateState => {
  const { macroProtocol } = state;
  const { anthropometry } = macroProtocol;
  const { __typename } = anthropometry;

  let newAnthropometry: any;

  if (__typename === "FormFields") {
    const { sampleHeight, sampleWeight } = anthropometry;

    const convertedHeight = isMetricLocale ? sampleHeight : measurementConversionToImperial(!isMetricLocale, sampleHeight, "length");
    const convertedWeight = isMetricLocale ? sampleWeight : measurementConversionToImperial(!isMetricLocale, sampleWeight, "weight")!;

    const newSampleHeightInput = convertedHeight ? String(roundToHundredthsFloor(convertedHeight)) : "";
    const newSampleWeightInput = String(roundToHundredthsFloor(convertedWeight));

    newAnthropometry = {
      ...anthropometry,
      sampleHeightInput: newSampleHeightInput,
      sampleWeightInput: newSampleWeightInput,
    };
  } else {
    const { height, weight } = anthropometry;

    const convertedHeight = measurementConversionToImperial(true, height, "length");
    const convertedWeight = measurementConversionToImperial(true, weight, "weight")!;

    const newHeightInput = convertedHeight ? String(roundToHundredthsFloor(convertedHeight)) : "";
    const newWeightInput = String(roundToHundredthsFloor(convertedWeight));

    newAnthropometry = {
      ...anthropometry,
      heightInput: newHeightInput,
      weightInput: newWeightInput,
    };
  }

  return {
    ...state,
    macroProtocol: {
      ...macroProtocol,
      anthropometry: newAnthropometry,
    },
  };
};

interface GoalAlert {
  goals: string[];
  reason: string;
  type: GoalAlertType;
}

interface ProcessedGoal {
  name: string;
  type: GoalAlertType | "valid";
}

interface FlaggedGoal {
  name: string;
  type: GoalAlertType;
}

export type GoalAlertType = "warn" | "error";

export const getGoalAlert = (macroProtocol: MacroProtocolState): GoalAlert | null => {
  const processedGoals = macroProtocol.calorieBudget.goals.map(goal => processGoal(macroProtocol, goal));
  const flaggedGoals = processedGoals.filter(isFlaggable);

  if (flaggedGoals.length === 0) {
    return null;
  }

  const errorGoals = flaggedGoals.filter(goal => goal.type === "error");
  if (errorGoals.length) {
    return createGoalAlertForType(errorGoals, "error");
  }

  const warningGoals = flaggedGoals.filter(goal => goal.type === "warn");
  if (warningGoals.length) {
    return createGoalAlertForType(warningGoals, "warn");
  }
  return null;
};

const processGoal = (macroProtocol: MacroProtocolState, goal: MealPlanTemplateGoalState): ProcessedGoal => {
  const macros = getMacrosForGoal(macroProtocol, goal);

  if (isError(macros)) {
    return {
      name: goal.name,
      type: "error",
    };
  }

  if (isWarning(macros)) {
    return {
      name: goal.name,
      type: "warn",
    };
  }

  return {
    name: goal.name,
    type: "valid",
  };
};

interface MacrosPreview {
  cho: EditMacroState;
  pro: EditMacroState;
  fat: EditMacroState;
}

const isError = (macros: MacrosPreview): boolean => {
  return macros.cho.gPerKg < 0 || macros.pro.gPerKg < 0 || macros.fat.gPerKg < 0;
};

const isWarning = (macros: MacrosPreview): boolean => {
  // if for any macro: 0 <= macro < 10
  return (
    (macros.cho.percent >= 0 && macros.cho.percent < 10) ||
    (macros.pro.percent >= 0 && macros.pro.percent < 10) ||
    (macros.fat.percent >= 0 && macros.fat.percent < 10)
  );
};

const isFlaggable = (goal: ProcessedGoal): goal is FlaggedGoal => {
  return goal.type !== "valid";
};

const getMacrosForGoal = (state: MacroProtocolState, goal: MealPlanTemplateGoalState): MacrosPreview => {
  const targetAnthroSnapshot = getAnthroSnapshotFromAnthroState(state.anthropometry);
  const calorieBudget: CalorieBudgetState = {
    ...state.calorieBudget,
    selectedPreviewGoal: goal,
  };

  return {
    cho: getMacroForGoal(state, targetAnthroSnapshot, "cho", calorieBudget),
    pro: getMacroForGoal(state, targetAnthroSnapshot, "pro", calorieBudget),
    fat: getMacroForGoal(state, targetAnthroSnapshot, "fat", calorieBudget),
  };
};

const getMacroForGoal = (
  state: MacroProtocolState,
  targetAnthroSnapshot: AnthropometrySnapshot,
  macro: MacroType,
  calorieBudget: CalorieBudgetState
): EditMacroState => {
  return macroReducer(state, createMacroCalorieAction(macro, calorieBudget), targetAnthroSnapshot);
};

const createGoalAlertForType = (flaggedGoals: FlaggedGoal[], type: GoalAlertType): GoalAlert => {
  return {
    goals: flaggedGoals.map(goal => goal.name),
    reason:
      type === "warn"
        ? "The following goals may cause macro target values to be less than 10% of the calorie budget for some athletes:"
        : "The following goals may cause macro target values to be less than 0g for some athletes:",
    type,
  };
};

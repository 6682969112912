import { IAthleteEngagementRowInput } from "../utils";

type Header =
  | "First Name"
  | "Last Name"
  | "Position"
  | "Total Meals"
  | "Meals Logged"
  | "Meals Ordered"
  | "Meal Plan Views"
  | "Image Uploads";

export interface SerializedExportRow {
  firstName: string;
  lastName: string;
  position: string;
  mealsLoggedCount: number;
  mealsOrderedCount: number;
  totalMealsCount: number;
  mealPlanViewCount: number;
  imageUploadCount: number;
}

interface ExportColumnDef {
  header: Header;
  key: keyof SerializedExportRow;
  width: number;
}

export const exportColumns: ExportColumnDef[] = [
  { header: "First Name", key: "firstName", width: 10 },
  { header: "Last Name", key: "lastName", width: 15 },
  { header: "Position", key: "position", width: 10 },
  { header: "Total Meals", key: "totalMealsCount", width: 15 },
  { header: "Meals Logged", key: "mealsLoggedCount", width: 15 },
  { header: "Meals Ordered", key: "mealsOrderedCount", width: 15 },
  { header: "Meal Plan Views", key: "mealPlanViewCount", width: 15 },
  { header: "Image Uploads", key: "imageUploadCount", width: 15 },
];

export const serializeRows = (rows: readonly IAthleteEngagementRowInput[]): SerializedExportRow[] => {
  return rows.map(r => {
    return {
      firstName: r.firstName,
      lastName: r.lastName,
      position: r.position?.name || "",
      mealsLoggedCount: r.mealsLoggedCount,
      mealsOrderedCount: r.mealsOrderedCount,
      totalMealsCount: r.totalMealsCount,
      mealPlanViewCount: r.mealPlanViewCount,
      imageUploadCount: r.imageUploadCount,
    };
  });
};

import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { RoleRoute } from "../../ProtectedRoutes";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { FoodGroupsPage } from "./FoodGroupsPage";
import {
  NAV_FOOD_MANAGEMENT_FOODS,
  NAV_FOOD_MANAGEMENT_FOOD_GROUPS,
  NAV_FOOD_MANAGEMENT_RECIPES,
  orgFoodManagementLink,
} from "./FoodManagementPaths";
import { FoodsPage } from "./FoodsPage";
import { RecipesPage } from "./RecipesPage";

const secondaryNavLayoutProps: SecondaryNavLayoutProps = {
  current: orgFoodManagementLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Recipes",
      to: NAV_FOOD_MANAGEMENT_RECIPES,
    },
    {
      name: "Foods",
      to: NAV_FOOD_MANAGEMENT_FOODS,
    },
    {
      name: "Food Groups",
      to: NAV_FOOD_MANAGEMENT_FOOD_GROUPS,
    },
  ],
};

export const FoodManagementRouter = () => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
      <Route element={<RoleRoute roles={["foodmanager"]} />}>
        <Route index element={<RecipesPage />} />
        <Route path={NAV_FOOD_MANAGEMENT_RECIPES} element={<RecipesPage />} />
        <Route path={NAV_FOOD_MANAGEMENT_FOODS} element={<FoodsPage />} />
        <Route path={NAV_FOOD_MANAGEMENT_FOOD_GROUPS} element={<FoodGroupsPage />} />
      </Route>
    </Route>
  </Routes>
);

import { List, ListItem, ListItemText, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { CompassLocationFragment } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noLocationsDiv: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface MenusCompassDialogSelectLocationProps {
  locations: readonly CompassLocationFragment[];
  selectedLocationId: number | null;
  onSelectLocationId: (locationId: number) => void;
}

const MenusCompassDialogSelectLocation = ({ locations, selectedLocationId, onSelectLocationId }: MenusCompassDialogSelectLocationProps) => {
  const classes = useStyles();

  return locations.length === 0 ? (
    <div className={classes.noLocationsDiv}>
      <Typography variant="h4">No Locations Found</Typography>
      <Typography variant="h3">Contact Compass Support to configure data sharing settings</Typography>
    </div>
  ) : (
    <List>
      {locations.map(l => {
        return (
          <ListItem
            key={l.locationId}
            onClick={() => onSelectLocationId(l.locationId)}
            selected={l.locationId === selectedLocationId}
            button
          >
            <ListItemText primary={l.name} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default MenusCompassDialogSelectLocation;

import { WatchQueryFetchPolicy } from "@apollo/client";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import { AutocompleteRenderOptionState, createFilterOptions, FilterOptionsState, ListItemIcon, Typography } from "@mui/material";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useCallback } from "react";
import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "../../../components/universal/InfiniteScroll/InfiniteScrollAutocomplete";
import {
  DiningStationTemplateFullFragment,
  DiningStationTemplatePreviewFragment,
  useDiningStationTemplateDialogLazyQuery,
  useDiningStationTemplateSearchQuery,
} from "../../../types";

const CREATE_OPTION = {
  __typename: "CREATE_OPTION" as const,
};

type Option = DiningStationTemplatePreviewFragment | typeof CREATE_OPTION;

interface MealMenuDiningStationSearchBarProps {
  diningStationTemplateIds: readonly string[];
  onCreate: (name: string) => void;
  onAdd: (template: DiningStationTemplateFullFragment) => void;
}

const MealMenuDiningStationSearchBar = ({ diningStationTemplateIds, onCreate, onAdd }: MealMenuDiningStationSearchBarProps) => {
  const { setMessage } = useSnackbar();

  const [loadDiningStationTemplate, { data: dstData, loading: dstLoading }] = useDiningStationTemplateDialogLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: () => {
      dstData && onAdd(dstData.diningStationTemplate);
    },
  });

  const renderOption = (option: Option, state: AutocompleteRenderOptionState) => {
    const { inputValue } = state;
    if (option.__typename === "CREATE_OPTION") {
      const text = inputValue ? `Use "${inputValue}" as new dining station` : `Create new dining station`;
      return (
        <>
          <ListItemIcon>
            <PlusOneIcon />
          </ListItemIcon>
          <Typography>{text}</Typography>
        </>
      );
    } else {
      return <Typography>{option.name}</Typography>;
    }
  };

  const handleSelect = (inputValue: string, option: Option | string | null) => {
    if (typeof option === "string" || option === null) {
      return;
    }
    if (option.__typename === "CREATE_OPTION") {
      onCreate(inputValue);
    } else if (option.__typename === "DiningStationTemplate") {
      if (diningStationTemplateIds.includes(option.id)) {
        setMessage("error", `Dining Station "${option.name}" already exists on this menu`);
      } else {
        loadDiningStationTemplate({
          variables: { id: option.id },
        });
      }
    } else {
      onCreate(option);
    }
  };

  const groupBy = (option: Option) => {
    return option.__typename === "DiningStationTemplate" ? "Dining Station Templates" : "";
  };

  const getOptionLabel = (option: Option | string, inputValue: string) => {
    return typeof option === "string" ? option : option.__typename === "DiningStationTemplate" ? option.name : inputValue;
  };

  const filterOptions = (option: any, state: FilterOptionsState<DiningStationTemplatePreviewFragment>): any[] =>
    createFilterOptions<DiningStationTemplatePreviewFragment>({
      stringify: () => state.inputValue,
    })(option, state);

  const defaultOptions: Option[] = [CREATE_OPTION];
  const transformAndFilterOptions = (edges: DiningStationTemplatePreviewFragment[] | undefined): Option[] => {
    const diningStationTemplates = (edges ?? []).filter(dst => !diningStationTemplateIds.includes(dst.id));
    return [...defaultOptions, ...diningStationTemplates];
  };

  const edgesAreEqual = useCallback((edge1: DiningStationTemplatePreviewFragment, edge2: DiningStationTemplatePreviewFragment) => {
    return edge1.id === edge2.id;
  }, []);

  const fetchPolicy: WatchQueryFetchPolicy = "cache-first";
  const getQueryVariablesFromPaginationAndInput = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: { query: input, pagination: { cursor, limit } },
      fetchPolicy,
    }),
    []
  );

  const getOptionKey = (option: Option | string, state: AutocompleteRenderOptionState): string => {
    return typeof option === "string" ? option : option.__typename === "DiningStationTemplate" ? option.id : `${state.index}`;
  };

  return (
    <InfiniteScrollAutocomplete
      sx={{ mt: 0 }}
      queryKey="diningStationTemplateCursorConnection"
      limit={10}
      useCursorConnectionQuery={useDiningStationTemplateSearchQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariablesFromPaginationAndInput}
      transformAndFilterOptions={transformAndFilterOptions}
      groupBy={groupBy}
      renderOption={renderOption}
      handleChange={handleSelect}
      getOptionLabel={getOptionLabel}
      getCustomOptionKey={getOptionKey}
      filterOptions={filterOptions}
      multiple={false}
      freeSolo
      noOptionsText="No Re-usable Dining Stations Found"
      inputPlaceholder="Add Dining Station"
      edgesAreEqual={edgesAreEqual}
      value={null}
      loading={dstLoading}
    />
  );
};

export default MealMenuDiningStationSearchBar;

import { addMinutes, getMinutes, isBefore, setHours, setMinutes } from "date-fns";

export const UNDER_HOUR_DURATION_OPTIONS = [15, 30, 45];
export const MAX_HOURS = 24;

export const getDurationOptions = (startDateTime: Date, currentValue: number) => {
  let durationOptions = UNDER_HOUR_DURATION_OPTIONS.concat(
    // add options for every 1 hour and every half hour for 12 hours after start time
    Array.from({ length: MAX_HOURS }, (_, i) => (i + 1) * 60),
    Array.from({ length: MAX_HOURS }, (_, i) => (i + 1) * 60 + 15),
    Array.from({ length: MAX_HOURS }, (_, i) => (i + 1) * 60 + 30),
    Array.from({ length: MAX_HOURS }, (_, i) => (i + 1) * 60 + 45)
  ).sort((a, b) => a - b);

  // define the end of the day as 11:45 PM on the same day as the start time
  const endOfDay = setHours(setMinutes(startDateTime, 45), 23);

  // filter out options that would make the end time cross into the next day
  durationOptions = durationOptions.filter(dur => {
    const endDateTime = addMinutes(startDateTime, dur);
    return isBefore(endDateTime, endOfDay) || endDateTime.getTime() === endOfDay.getTime();
  });

  // add the current end time to the dropdown options if it is not already there (this happens when a user sets an alternative time via the clock component in the UI)
  const curEndtime = getMinutes(startDateTime) + currentValue;
  if (durationOptions.indexOf(curEndtime) === -1) {
    durationOptions.push(curEndtime);
  }

  return durationOptions.sort((a, b) => a - b);
};

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Button, Dialog, DialogActions, DialogContent, List, ListItem, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDraggable } from "@notemeal/shared/ui/hooks/useDraggable";
import { sortByKey } from "@notemeal/utils/sort";
import React, { useState } from "react";
import { animated } from "react-spring";
import DialogTitle from "../../componentLibrary/DialogTitle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      cursor: "pointer",
    },
  })
);

interface Reorderable {
  id: string;
  position: number;
  name: string;
}

interface ReorderDialogProps<T extends Reorderable> {
  open: boolean;
  onClose: () => void;
  onDone: (reorderableArray: readonly T[]) => void;
  reorderableArray: readonly T[];
}

const ReorderDialog = <T extends Reorderable>({ open, onClose, onDone, reorderableArray: _reorderableArray }: ReorderDialogProps<T>) => {
  const [reorderableArray, setReorderableArray] = useState(_reorderableArray);
  const sortedReorderableArray = sortByKey(reorderableArray, "position");

  const handleChangeOrder = (ids: string[]) => {
    const reorderedDiningStations = ids.flatMap((id, index) => {
      const matchingDiningStation = reorderableArray.find(item => item.id === id);
      return matchingDiningStation ? [{ ...matchingDiningStation, position: index + 1 }] : [];
    });
    setReorderableArray(reorderedDiningStations);
  };

  const handleDone = () => {
    onDone(reorderableArray);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Drag dining stations to re-order" onClose={onClose} />
      <ReorderDialogContent
        key={sortedReorderableArray.map(item => item.id).join(",")}
        sortedReorderableArray={sortedReorderableArray}
        onChangeOrder={handleChangeOrder}
      />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleDone}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ReorderDialogContentProps<T extends Reorderable> {
  sortedReorderableArray: readonly T[];
  onChangeOrder: (itemIds: string[]) => void;
}

const ReorderDialogContent = <T extends Reorderable>({ sortedReorderableArray, onChangeOrder }: ReorderDialogContentProps<T>) => {
  const classes = useStyles();

  const draggables = sortedReorderableArray.map(({ id }, index) => ({
    id,
    index,
    height: 48.1,
  }));
  const { springs, bind } = useDraggable(draggables, onChangeOrder);

  return (
    <DialogContent>
      <List>
        {sortedReorderableArray.map(({ id: itemId, name: itemName }) => {
          const springObject = draggables.find(({ id }) => id === itemId);
          if (!springObject) {
            return;
          }

          const spring = springs[springObject.index];
          const bindProps = bind(springObject.index);

          if (!spring || !bindProps) {
            return;
          }

          const { zIndex, y, shadow } = spring;

          return (
            <animated.div
              key={itemId}
              {...bindProps}
              style={{
                zIndex,
                boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px 0px ${s}px 0px`),
                transform: y.to(y => `translate3d(0, ${y}px, 0)`),
              }}
            >
              <ListItem className={classes.listItem}>
                <ListItemText primary={itemName} />
                <DragIndicatorIcon />
              </ListItem>
            </animated.div>
          );
        })}
      </List>
    </DialogContent>
  );
};

export default ReorderDialog;

import { ApolloError } from "@apollo/client";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import { formatDate, getMostRecent } from "@notemeal/shared/ui/utils/dateTimes";
import { roundToHundredthsFloor } from "@notemeal/shared/utils/macro-protocol";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { enUS } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { ConfirmationDialog } from "../../../../componentLibrary";
import {
  anthropometryEntryFormDefaultValues,
  roundImperialAnthroValues,
} from "../../../../components/AnthropometryEntry/Form/AnthropometryEntryFormSchema";
import {
  metricAnthropometryEntryFormDefaultValues,
  roundMetricAnthroValues,
} from "../../../../components/AnthropometryEntry/Form/MetricAnthropometryEntryFormSchema";
import { AnthropometryEntryModal } from "../../../../components/AnthropometryEntry/Modal";
import { AnthropometryEntryTable } from "../../../../components/AnthropometryEntry/Table";
import { RefetchQueriesContextProvider } from "../../../../contexts/RefetchQueries";
import {
  AnthropometryEntry,
  AthleteAnthropometryDocument,
  AthleteMealPlansDocument,
  AthleteSummaryDocument,
  SexType,
  useAthleteAnthropometryQuery,
  useDeleteAnthropometryEntryMutation,
} from "../../../../types";

export const AthleteAnthropometryPage = () => {
  const { athleteId: maybeAthleteId } = useParams();
  const athleteId = maybeAthleteId || "";

  const { isMetricLocale } = useLocaleContext();
  const defaultFormValues = isMetricLocale ? metricAnthropometryEntryFormDefaultValues : anthropometryEntryFormDefaultValues;

  const [modalOpen, setModalOpen] = useState(false);
  const [deletableEntry, setDeletableEntry] = useState<AnthropometryEntry | null>(null);
  const [initialFormState, setInitialFormState] = useState(defaultFormValues());
  const [anthropometryEntries, setAnthropometryEntries] = useState<AnthropometryEntry[]>([]);
  const [athleteSex, setAthleteSex] = useState<SexType>("male");
  const { setMessage } = useSnackbar();
  const getAthleteAnthropometryVars = { id: athleteId };
  const { data, loading } = useAthleteAnthropometryQuery({
    variables: getAthleteAnthropometryVars,
  });
  const { formatDateWithLocale } = useDateFormatting();

  useEffect(() => {
    if (!!data && !loading) {
      setAnthropometryEntries([...data.athlete.anthropometryEntries]);
      if (data.athlete.sex) {
        setAthleteSex(data.athlete.sex);
      }
    }
  }, [loading, data]);
  const refetchQueries = [
    {
      query: AthleteAnthropometryDocument,
      variables: getAthleteAnthropometryVars,
    },
    {
      query: AthleteSummaryDocument,
      variables: { id: athleteId, inLastDays: 3 },
    },
    { query: AthleteMealPlansDocument, variables: { athleteId } },
  ];
  const [deleteAnthropometryEntry] = useDeleteAnthropometryEntryMutation({
    refetchQueries,
    onError: (e: ApolloError) => setMessage("error", e.message),
  });

  const handleRowClick = ({
    id,
    leanBodyMass,
    percentBodyFat,
    boneMass,
    height,
    weight,
    datetime,
    type,
    bodyFatMass,
    visceralFatArea,
    dryLeanMass,
    skeletalMuscleMass,
    trunkFat,
    boneMineralDensityZScore,
    comment,
    heightInCm,
    weightInKg,
    leanBodyMassInKg,
    dryLeanMassInKg,
    skeletalMuscleMassInKg,
    trunkFatInKg,
    bodyFatMassInKg,
  }: AnthropometryEntry) => {
    if (isMetricLocale) {
      setInitialFormState(
        roundMetricAnthroValues({
          id,
          leanBodyMassInKg,
          boneMass,
          percentBodyFat,
          bodyFatMassInKg,
          boneMineralDensityZScore,
          leanBodyMassIsRequired: !!leanBodyMassInKg,
          heightInCm,
          dryLeanMassInKg,
          visceralFatArea,
          skeletalMuscleMassInKg,
          trunkFatInKg,
          weightInKg,
          date: new Date(formatDate(new Date(datetime).toISOString().split("T")[0], undefined, enUS)), // TODO: Locale is this safe to change?
          type,
          comment: comment || "",
        })
      );
    } else {
      setInitialFormState(
        roundImperialAnthroValues({
          id,
          leanBodyMass,
          boneMass,
          percentBodyFat,
          bodyFatMass,
          boneMineralDensityZScore,
          leanBodyMassIsRequired: !!leanBodyMass,
          height,
          dryLeanMass,
          visceralFatArea,
          skeletalMuscleMass,
          trunkFat,
          weight,
          date: new Date(formatDate(new Date(datetime).toISOString().split("T")[0], undefined, enUS)), // TODO: Locale is this safe to change?
          type,
          comment: comment || "",
        })
      );
    }
    setModalOpen(true);
  };
  const handleAddRow = () => {
    const mostRecentAnthroEntry = getMostRecent(anthropometryEntries, "datetime");
    if (mostRecentAnthroEntry) {
      setInitialFormState({
        ...initialFormState,
        height: mostRecentAnthroEntry.height && roundToHundredthsFloor(mostRecentAnthroEntry.height),
        heightInCm: mostRecentAnthroEntry.heightInCm && roundToHundredthsFloor(mostRecentAnthroEntry.heightInCm),
      });
    }
    setModalOpen(true);
  };
  const handleDeleteRowClick = (row: AnthropometryEntry) => {
    setDeletableEntry(row);
  };
  const handleDeleteModalClose = () => {
    setDeletableEntry(null);
  };

  const handleClose = () => {
    setInitialFormState(anthropometryEntryFormDefaultValues());
    setModalOpen(false);
  };

  if (loading || !data) {
    return <Loading progressSize="lg" />;
  }

  return (
    <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
      <AnthropometryEntryTable
        tableRows={anthropometryEntries}
        onClickAddEntry={handleAddRow}
        onClickRow={handleRowClick}
        onClickDeleteRow={handleDeleteRowClick}
      />
      {modalOpen && (
        <AnthropometryEntryModal
          athleteId={athleteId}
          open={modalOpen}
          onClose={handleClose}
          initialFormState={initialFormState}
          athleteSex={athleteSex}
        />
      )}
      {deletableEntry && (
        <ConfirmationDialog
          open={!!deletableEntry}
          title={"Delete Entry?"}
          message={`${deletableEntry.type} - ${formatDateWithLocale(deletableEntry.datetime)}`}
          onCancel={() => handleDeleteModalClose()}
          onConfirm={async () => {
            deleteAnthropometryEntry({
              variables: { input: { id: deletableEntry.id } },
            });
            handleDeleteModalClose();
          }}
          variant="containedDestructive"
        />
      )}
    </RefetchQueriesContextProvider>
  );
};

import { PossibleLocation } from "../../../../components/Map/types";
import {
  AddDeliveryLocationInput,
  DeliveryLocationWithTeamsFragment,
  EditDeliveryLocationInput,
  TeamLabelFragment,
} from "../../../../types";

export interface DeliveryLocationFormState_Create {
  name: string;
  location: PossibleLocation | null;
  isActive: boolean;
  teamLabels: readonly TeamLabelFragment[];
  deliveryInstructions: string | null;
}

export interface DeliveryLocationFormState_Edit {
  name: string;
  cityInfo: string | null;
  isActive: boolean;
  teamLabels: readonly TeamLabelFragment[];
  deliveryInstructions: string | null;
}

interface ChangeName {
  type: "CHANGE_NAME";
  payload: {
    name: string;
  };
}

interface ChangeCityInfo {
  type: "CHANGE_CITY_INFO";
  payload: {
    cityInfo: string | null;
  };
}

interface ChangeDeliveryInstructions {
  type: "CHANGE_DELIVERY_INSTRUCTIONS";
  payload: {
    deliveryInstructions: string | null;
  };
}

interface ChangeIsActive {
  type: "CHANGE_IS_ACTIVE";
  payload: {
    isActive: boolean;
  };
}

interface ChangeTeamLabels {
  type: "CHANGE_TEAM_TAGS";
  payload: {
    teamLabels: readonly TeamLabelFragment[];
  };
}

interface ChangeLocation {
  type: "CHANGE_LOCATION";
  payload: {
    location: PossibleLocation | null;
  };
}

export type DeliveryLocationFormAction_Create =
  | ChangeName
  | ChangeCityInfo
  | ChangeDeliveryInstructions
  | ChangeIsActive
  | ChangeTeamLabels
  | ChangeLocation;

export function deliveryLocationFormReducer_Create(
  state: DeliveryLocationFormState_Create,
  action: DeliveryLocationFormAction_Create
): DeliveryLocationFormState_Create {
  switch (action.type) {
    case "CHANGE_NAME":
      return { ...state, name: action.payload.name };
    case "CHANGE_IS_ACTIVE":
      return { ...state, isActive: action.payload.isActive };
    case "CHANGE_DELIVERY_INSTRUCTIONS":
      return {
        ...state,
        deliveryInstructions: action.payload.deliveryInstructions,
      };
    case "CHANGE_TEAM_TAGS":
      return { ...state, teamLabels: action.payload.teamLabels };
    case "CHANGE_LOCATION":
      return {
        ...state,
        location: action.payload.location,
        name: action.payload.location ? action.payload.location.name : state.name,
      };
    default:
      return state;
  }
}

export type DeliveryLocationFormAction_Edit = ChangeName | ChangeCityInfo | ChangeDeliveryInstructions | ChangeIsActive | ChangeTeamLabels;

export function deliveryLocationFormReducer_Edit(
  state: DeliveryLocationFormState_Edit,
  action: DeliveryLocationFormAction_Edit
): DeliveryLocationFormState_Edit {
  switch (action.type) {
    case "CHANGE_NAME":
      return { ...state, name: action.payload.name };
    case "CHANGE_IS_ACTIVE":
      return { ...state, isActive: action.payload.isActive };
    case "CHANGE_DELIVERY_INSTRUCTIONS":
      return {
        ...state,
        deliveryInstructions: action.payload.deliveryInstructions,
      };
    case "CHANGE_CITY_INFO":
      return { ...state, cityInfo: action.payload.cityInfo };
    case "CHANGE_TEAM_TAGS":
      return { ...state, teamLabels: action.payload.teamLabels };
    default:
      return state;
  }
}

export const buildDeliveryLocationFormTooltips = (state: DeliveryLocationFormState_Create | DeliveryLocationFormState_Edit): string[] => {
  const tooltips: string[] = [];
  if (!state.name.trim()) {
    tooltips.push("'Name' is required");
  }

  return tooltips;
};

export const buildInitialCreateFormState = (): DeliveryLocationFormState_Create => ({
  name: "",
  location: null,
  teamLabels: [],
  deliveryInstructions: null,
  isActive: true,
});

export const buildInitialEditFormState = ({
  name,
  teamLabels,
  deliveryInstructions,
  isActive,
  cityInfo,
}: DeliveryLocationWithTeamsFragment): DeliveryLocationFormState_Edit => ({
  name,
  isActive,
  cityInfo,
  teamLabels: [...teamLabels],
  deliveryInstructions,
});

export const getAddDeliveryLocationInput = (state: DeliveryLocationFormState_Create): AddDeliveryLocationInput | null => {
  if (state.location === null) {
    return null;
  }
  return {
    googlePlaceId: state.location.googlePlaceId,
    lat: state.location.lat,
    long: state.location.long,
    isActive: state.isActive,
    name: state.name,
    deliveryInstructions: state.deliveryInstructions,
    teamLabelIds: state.teamLabels.map(t => t.id),
  };
};

export const getEditDeliveryLocationInput = (
  state: DeliveryLocationFormState_Edit,
  deliveryLocationId: string
): EditDeliveryLocationInput => ({
  deliveryLocationId,
  isActive: state.isActive,
  name: state.name,
  deliveryInstructions: state.deliveryInstructions,
  teamLabelIds: state.teamLabels.map(t => t.id),
  cityInfo: state.cityInfo || null,
});

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { useCreateRestaurantMutation } from "../../../types";
import Form from "./Form";
import { buildInitialCreateFormState, buildRestaurantFormTooltips, restaurantFormReducer } from "./Form/utils";

interface CreateDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (resturantName: string) => void;
}

const CreateDialog = ({ open, onClose, onCreate }: CreateDialogProps) => {
  const { setMessage } = useSnackbar();
  const [state, dispatch] = useReducer(restaurantFormReducer, buildInitialCreateFormState());

  const canSaveTooltips = buildRestaurantFormTooltips(state);

  const [createRestaurant] = useCreateRestaurantMutation({
    onCompleted: data => {
      onCreate(data.createRestaurant.restaurant.name);
      onClose();
    },
    onError: e => setMessage("error", e.message),
  });

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    const { restaurantType, paymentMethods, cuisines, ...rest } = state;
    createRestaurant({
      variables: {
        input: {
          ...rest,
          restaurantTypeId: restaurantType?.id || null,
          paymentMethodIds: paymentMethods.map(pm => pm.id),
          cuisineIds: cuisines.map(c => c.id),
        },
      },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Create Restaurant" onClose={onClose} />
      <DialogContent>
        <Form state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button onClick={handleSave}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDialog;

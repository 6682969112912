import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { useUnitsQuery } from "../../../types";
import { WarningTooltip } from "../../universal/Tooltip";
import { getServingUnits, ServingUnitsState } from "../utils";
import ConversionsButton from "./ConversionsButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      alignSelf: "center",
      width: 200,
    },
    unitsDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    unitDiv: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    unitChip: {
      flexGrow: 1,
      margin: theme.spacing(0.5, 1, 0.5, 0),
    },
  })
);

interface ServingUnitsInputDialogProps {
  open: boolean;
  onClose: () => void;
  value: ServingUnitsState;
  onChange: (units: ServingUnitsState) => void;
}

// TODO: test No longer opens on initial focus
const ServingUnitsInputDialog = ({ open, onClose, value, onChange }: ServingUnitsInputDialogProps) => {
  const classes = useStyles();
  const [state, setState] = useState(value);

  const { data: allUnitsData } = useUnitsQuery();
  const allUnits = allUnitsData?.units || [];
  const unitsStartWithsNumber = (state.customUnits || "").match(/^\d/) !== null;
  const handleDone = () => {
    onChange(state);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title={`Units: "${getServingUnits(state)}"`} onClose={onClose} />
      <DialogContent className={classes.content}>
        {state.customUnits !== null ? (
          <Autocomplete
            freeSolo
            options={[...allUnits]}
            getOptionLabel={option => (typeof option === "string" ? option : option.name)}
            inputValue={state.customUnits}
            onInputChange={(_, customUnits) =>
              setState({
                ...state,
                customUnits,
              })
            }
            onChange={(_, unit: { id: string; name: string } | null | string) => {
              // TODO: test
              if (unit !== null && typeof unit !== "string") {
                setState({
                  customUnits: null,
                  unit,
                  unitPrefix: null,
                  unitSuffix: null,
                });
              }
            }}
            renderInput={params => (
              <WarningTooltip
                hidden={!unitsStartWithsNumber}
                msg={unitsStartWithsNumber ? "You probably don't want 'unit' starting w/ number" : ""}
              >
                <TextField
                  margin="dense"
                  label="Units"
                  {...params} />
              </WarningTooltip>
            )}
          />
        ) : (
          <>
            <TextField
              margin="dense"
              fullWidth
              label="Prefix"
              value={state.unitPrefix || ""}
              onChange={e =>
                setState({
                  ...state,
                  unitPrefix: e.target.value,
                })
              }
            />
            <Typography color="textSecondary" variant="subtitle1">
              Unit
            </Typography>
            <div className={classes.unitDiv}>
              <Chip
                label={state.unit.name}
                className={classes.unitChip}
                onDelete={() =>
                  setState({
                    customUnits: "",
                    unit: null,
                    unitSuffix: null,
                    unitPrefix: null,
                  })
                }
              />
              <ConversionsButton
                unitId={state.unit.id}
                tooltipTitle="Show available unit conversions"
                menuTitle="Unit Conversions" />
            </div>
            <TextField
              margin="dense"
              fullWidth
              label="Suffix"
              value={state.unitSuffix || ""}
              onChange={e =>
                setState({
                  ...state,
                  unitSuffix: e.target.value,
                })
              }
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleDone}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServingUnitsInputDialog;

import { PaperProps } from "@mui/material";
import { dateToIsoInTz } from "@notemeal/shared/ui/utils/dateTimes";
import React, { useState } from "react";
import { CalendarEvent, RenderCalendarEvent } from "../utils";
import { useBasePaperProps } from "./utils";

interface CalendarNewEventContainerProps {
  hourHeight: number;
  date: string;
  clientTimezone: string;
  hasAllDayCell: boolean;
  event: CalendarEvent;
  renderEvent: RenderCalendarEvent<CalendarEvent>;
  onClose: () => void;
}

const CalendarNewEventContainer = ({
  hourHeight,
  date,
  clientTimezone,
  hasAllDayCell,
  event,
  renderEvent: { renderPaper, renderPopover },
  onClose,
}: CalendarNewEventContainerProps) => {
  const dateStart = new Date(dateToIsoInTz(date, clientTimezone));

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const selected = anchorEl !== null;
  const slot = 1;
  const maxSlot = 1;

  const basePaperProps = useBasePaperProps({
    event,
    slot,
    maxSlot,
    hourHeight,
    dateStart,
    selected,
    hasAllDayCell,
  });

  const PaperProps: PaperProps = {
    ...basePaperProps,
    ref: r => r && setAnchorEl(r as HTMLElement),
  };

  return (
    <>
      {renderPaper(event, {
        PaperProps,
        isAbbreviated: event.isAllDay || event.durationInMinutes < 60,
        slot,
      })}
      {anchorEl &&
        renderPopover(event, {
          anchorEl,
          onClose,
          action: "click",
        })}
    </>
  );
};

export default CalendarNewEventContainer;

import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PhoneNumberInput from "../../../../../components/global/PhoneNumberInput";
import DeliveryLocationSelect from "./DeliveryLocationSelect";
import { RestaurantLocationFormState } from "./utils";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

interface RestaurantLocationFormProps {
  state: RestaurantLocationFormState;
  onChange: (state: RestaurantLocationFormState) => void;
}

const RestaurantLocationForm = ({ state, onChange }: RestaurantLocationFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.isActive}
            onChange={(e, isActive) =>
              onChange({
                ...state,
                isActive,
              })
            }
          />
        }
        label="Is Active"
      />
      <PhoneNumberInput
        setInput={phoneNumber =>
          onChange({
            ...state,
            phoneNumber,
          })
        }
        input={state.phoneNumber || ""}
      />
      <TextField
        fullWidth
        margin="dense"
        label="Email"
        value={state.email || ""}
        onChange={e => onChange({ ...state, email: e.target.value })}
      />
      {state.type === "edit" && (
        <TextField
          fullWidth
          margin="dense"
          label="City Info"
          value={state.cityInfo || ""}
          onChange={e => onChange({ ...state, cityInfo: e.target.value })}
        />
      )}
      <TextField
        fullWidth
        margin="dense"
        label="Notes"
        multiline
        rows={4}
        maxRows={4}
        value={state.notes || ""}
        onChange={e => onChange({ ...state, notes: e.target.value })}
      />
      <DeliveryLocationSelect value={state.deliveryLocations} onChange={deliveryLocations => onChange({ ...state, deliveryLocations })} />
    </div>
  );
};

export default RestaurantLocationForm;

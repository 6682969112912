import { TableCell, TableCellProps, TableRow, TableRowProps } from "@mui/material";
import React from "react";

interface RowProps<DataArray extends React.ReactNode[]> extends Omit<TableRowProps, "children"> {
  children: DataArray;
  tableCellProps?: TableCellProps;
}

const Row = <DataArray extends React.ReactNode[]>({ children, tableCellProps, ...tableRowProps }: RowProps<DataArray>) => {
  return (
    <TableRow {...tableRowProps}>
      {children.map((cell, index) => (
        <TableCell key={index} {...tableCellProps}>
          {cell}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default Row;

import MealTemplateView from ".";
import MealOptionViewMacro from "../../MealOption/View/Macro";

import { maybeMacrosToMacros } from "@notemeal/shared/utils/macro-protocol";
import MacrosSummaryLabel from "../../Macros/SummaryLabel";
import { MacroMealTemplateViewFragment } from "../../types";
interface MealTemplateViewMacroProps {
  mealTemplate: MacroMealTemplateViewFragment;
  containerWidth: number;
  displayMealMacros: boolean;
  displayMealOptions: boolean;
  displayMealOptionMacros: boolean;
}

const MealTemplateViewMacro = ({
  mealTemplate,
  containerWidth,
  displayMealOptions,
  displayMealMacros,
  displayMealOptionMacros,
}: MealTemplateViewMacroProps) => {
  const mealOptions = displayMealOptions ? mealTemplate.mealOptions : [];
  const macros = maybeMacrosToMacros(mealTemplate);

  return (
    <MealTemplateView
      label={displayMealMacros && <MacrosSummaryLabel variant="sm" macros={macros} />}
      meal={mealTemplate.meal}
      containerWidth={containerWidth}
      mealOptions={mealOptions}
      renderMealOption={mealOption => <MealOptionViewMacro mealOption={mealOption} displayMacros={displayMealOptionMacros} />}
    />
  );
};

export default MealTemplateViewMacro;

import React, { useState } from "react";
import MenuNotificationSettingsButton from "../../Dialog/NotificationSettingsButton";
import { getInitialBulkEditNotificationValue } from "../../utils";
import { BaseBulkEditProps, BaseContentProps } from "../types";
import BaseContent from "./BaseContent";

interface NotificationContentProps extends BaseContentProps {
  onBulkEdit: BaseBulkEditProps["onBulkEdit"];
}

const NotificationContent = ({ isOpen, onBack, onCancel, onBulkEdit, mealMenuInstances, saving }: NotificationContentProps) => {
  const mealMenuIds = mealMenuInstances.map(mealMenuInstance => mealMenuInstance.id);
  const initialNotificationValue = getInitialBulkEditNotificationValue(mealMenuInstances);
  const [notificationValue, setNotificationValue] = useState<number | null>(initialNotificationValue);

  return (
    <BaseContent
      isOpen={isOpen}
      onCancel={onCancel}
      saving={saving}
      onSubmit={async () => {
        await onBulkEdit({
          mealMenuIds,
          actionType: "notification",
          sharingState: null,
          notificationSentBeforeOrderDueInMinutes: notificationValue,
          timingState: null,
        });
        onBack();
      }}
      title={`Edit Notifications for ${mealMenuInstances.length} Meal Menu(s)`}
      renderContent={() => <MenuNotificationSettingsButton onChange={setNotificationValue} value={notificationValue} />}
      submitDisabled={mealMenuInstances.length === 0 || saving}
    />
  );
};

export default NotificationContent;

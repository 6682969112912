import { ApolloError } from "@apollo/client";

const SERVER_PARSE_ERROR = "ServerParseError";

export const parseImportError = (error: ApolloError): string => {
  const payloadTooLarge = error.networkError?.name === SERVER_PARSE_ERROR;
  if (payloadTooLarge) {
    return "File may be too large. Try splitting into chunks of 100 rows and retry import.";
  } else {
    return error.message;
  }
};

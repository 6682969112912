import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import DisplayCard, { DisplayCardProps } from "../../shared/DisplayCard";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: 65,
    },
  })
);

export interface PositionLike {
  id: string | number;
  name: string;
}

type PositionCardProps<T> = Omit<DisplayCardProps<T>, "primaryText" | "secondaryElement" | "className">;

const PositionCard = <T extends PositionLike>(props: PositionCardProps<T>) => {
  const classes = useStyles();
  const { element } = props;
  const primaryText = element.name;
  return <DisplayCard
    {...props}
    primaryText={primaryText}
    className={classes.card} />;
};

export default PositionCard;

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IconButton, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import newId from "@notemeal/shared/ui/utils/newId";
import React, { useState } from "react";
import { useAddTimelineMealCommentForAthleteMutation } from "../../../../types";
import Comments, { NewComment } from "../Comments";
import { addTimelineMealIfNotPresent } from "../cache";
import { TimelineMealForModal, getTimelineMealInput } from "../utils";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputs: {
      flexGrow: 0,
      display: "flex",
      alignItems: "center",
    },
    inputText: {
      flexGrow: 1,
    },
    inputButton: {
      marginLeft: theme.spacing(),
    },
    root: {
      marginTop: theme.spacing(),
      display: "flex",
      flexDirection: "column",
      padding: `0 15%`,
      flexGrow: 1,
    },
  })
);

interface TimelineMealCommentsProps {
  timelineMeal: TimelineMealForModal;
  athleteId: string;
  timezone: string;
  date: string;
}

const TimelineMealComments = ({ timelineMeal, athleteId, timezone, date }: TimelineMealCommentsProps) => {
  const classes = useStyles();

  const [newComments, setNewComments] = useState<NewComment[]>([]);

  const [addTimelineMealComment] = useAddTimelineMealCommentForAthleteMutation();
  const [text, setText] = useState("");
  const handleAddComment = () => {
    if (!text) {
      return;
    }
    setText("");
    const newCommentId = newId();
    setNewComments([...newComments, { id: newCommentId, text }]);

    addTimelineMealComment({
      variables: {
        input: {
          athleteId,
          timelineMeal: getTimelineMealInput(timelineMeal),
          text,
          imageUrl: null,
        },
      },
      update: (cache, { data }) => {
        if (data) {
          addTimelineMealIfNotPresent({
            athleteId,
            timezone,
            date,
            cache,
            timelineMeal: data.addTimelineMealCommentForAthlete.timelineMeal,
          });
          setNewComments(newComments.filter(c => c.id !== newCommentId));
        }
      },
    });
  };

  return (
    <div className={classes.root}>
      <Comments comments={timelineMeal.comments} newComments={newComments} />
      <div className={classes.inputs}>
        <TextField
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="Message"
          margin="dense"
          multiline
          maxRows={4}
          className={classes.inputText}
          onKeyPress={e => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleAddComment();
            }
          }}
        />
        <IconButton
          size="small"
          onClick={handleAddComment}
          className={classes.inputButton}>
          <ArrowForwardIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default TimelineMealComments;

import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { EditImperialMacroProtocolState, editMacroProtocolReducer } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import React, { useReducer } from "react";
import DialogTitle from "../../../../componentLibrary/DialogTitle";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { ImperialMacroProtocolForm } from "../../../../components/MacroProtocol/Form/ImperialMacroProtocolForm";
import { macroProtocolStateToSaveTooltips } from "../../../../components/MacroProtocol/Form/utils";
import { GoalTypeFragment } from "../../../../types";

interface ImperialMacroProtocolEditProps {
  initialState: EditImperialMacroProtocolState;
  goalTypes: readonly GoalTypeFragment[];
  open: boolean;
  onClose: () => void;
  onSave: (state: EditImperialMacroProtocolState) => void;
}

export const ImperialMacroProtocolEdit = ({ initialState, goalTypes, onClose, open, onSave }: ImperialMacroProtocolEditProps) => {
  const [imperialState, imperialDispatch] = useReducer(editMacroProtocolReducer, initialState);

  const canSaveTooltips = macroProtocolStateToSaveTooltips(imperialState);

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    onSave(imperialState);
    onClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Macronutrient Protocol" onClose={onClose} />
      <DialogContent sx={{ pb: 0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
          <Typography variant="body2">
            * Example daily macros are never shown to the athlete and only exist as a helpful reference when you are reviewing food logs
            with an athlete who does not have a meal plan assigned. Daily Target Macros are for local reference only. Athletes will not see
            these changes.
          </Typography>
        </Box>
        <ImperialMacroProtocolForm
          state={imperialState}
          dispatch={imperialDispatch}
          goalTypes={goalTypes} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before saving:" items={canSaveTooltips}>
          <Button onClick={handleSave} disabled={canSaveTooltips.length > 0}>
            Done
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

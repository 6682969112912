import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

function desc<T>(a: T, b: T, orderBy: keyof T): number {
  const bValue = b[orderBy];
  const aValue = a[orderBy];
  if (!aValue) {
    return -1;
  }
  if (!bValue) {
    return -1;
  }
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

interface Enumerated<T> {
  row: T;
  index: number;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number): T[] {
  const stabilizedThis: Enumerated<T>[] = array.map((el, index) => ({
    row: el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = cmp(a.row, b.row);
    if (order !== 0) {
      return order;
    }
    return a.index - b.index;
  });
  return stabilizedThis.map(el => el.row);
}

function getSorting<T>(order: string | boolean, orderBy: keyof T): (a: T, b: T) => number {
  return order === "desc" ? (a: T, b: T) => desc(a, b, orderBy) : (a: T, b: T) => -desc(a, b, orderBy);
}

export interface ICol<T> {
  id: keyof T & string;
  isNumeric: boolean;
  disablePadding: boolean;
  label: string;
}

interface EnhancedTableHeadProps<T> {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<HTMLSelectElement>, property: keyof T) => void;
  onSelectAllClick: (col: any) => any;
  order: "asc" | "desc" | false | undefined;
  orderBy: keyof T;
  rowCount: number;
  cols: ICol<T>[];
  deleteable?: boolean;
}

function EnhancedTableHead<T extends HasID>(props: EnhancedTableHeadProps<T>) {
  const { cols, order, orderBy, onRequestSort, deleteable } = props;
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<HTMLSelectElement>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {cols.map(col => (
          <TableCell
            key={col.id}
            align={"left" /*col.isNumeric ? 'right' : 'left'*/}
            padding={col.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === col.id ? order : false}
          >
            {/* <Tooltip
                title="Sort"
                placement={row.isNumeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              > */}
            <TableSortLabel
              active={orderBy === col.id}
              direction={order === false ? "desc" : order}
              onClick={createSortHandler(col.id)}>
              {col.label}
            </TableSortLabel>
            {/* </Tooltip> */}
          </TableCell>
        ))}

        {!!deleteable && (
          <TableCell align={"left" /*col.isNumeric ? 'right' : 'left'*/} padding={"none"}>
            Delete
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

export interface GenericTableProps<T> {
  tableRows: T[];
  tableCols: ICol<T>[];
  addButtonText: string;
  onClickAddEntry: () => void;
  formatTableCell: (record: T, key: keyof T) => string | React.ReactNode;
  defaultOrderBy: keyof T;
  onClickRow?: (row: T) => void;
  onClickDeleteRow?: (row: T) => void;
  size?: "small" | "medium";
  initRowsPerPage?: number;
}

interface HasID {
  id: string;
}

function TTable<T extends HasID>({
  size,
  tableRows,
  tableCols,
  addButtonText,
  onClickAddEntry,
  onClickDeleteRow,
  onClickRow,
  formatTableCell,
  defaultOrderBy,
  initRowsPerPage,
}: GenericTableProps<T>) {
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<keyof T>(defaultOrderBy);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage || 10);

  function handleRequestSort(event: React.MouseEvent<HTMLSelectElement>, property: keyof T) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelecteds: string[] = tableRows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event: React.MouseEvent<HTMLTableRowElement>, id: string) {
    const selectedIndex = tableRows.findIndex(r => r.id === id);
    onClickRow && onClickRow(tableRows[selectedIndex]);
  }

  function handleClickDeleteRow(event: React.MouseEvent<HTMLButtonElement>, id: string) {
    event.stopPropagation();
    const selectedIndex = tableRows.findIndex(r => r.id === id);
    onClickDeleteRow && onClickDeleteRow(tableRows[selectedIndex]);
  }

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setRowsPerPage(Number(event.target.value));
  }

  return (
    <Box sx={{ flex: 1, overflow: "hidden", display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onClickAddEntry}>{addButtonText}</Button>
      </Box>
      <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <Table size={size || "medium"}>
          <EnhancedTableHead
            cols={tableCols}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={tableRows.length}
            deleteable={!!onClickDeleteRow}
          />
          <TableBody>
            {stableSort(tableRows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(n => {
                return (
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    onClick={event => handleClick(event, n.id)}
                    key={n.id}>
                    {tableCols.map(col => (
                      <TableCell key={col.id + "-" + n.id} align="left">
                        {formatTableCell(n, col.id)}
                      </TableCell>
                    ))}
                    {
                      /* Display trash can if deletable rows*/
                      !!onClickDeleteRow && (
                        <TableCell>
                          <IconButton onClick={event => handleClickDeleteRow(event, n.id)} size="large">
                            <DeleteIcon sx={{ color: "error.main" }} />
                          </IconButton>
                        </TableCell>
                      )
                    }
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
        rowsPerPageOptions={[10, 25, 50]}
        count={tableRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default TTable;

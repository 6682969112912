import { Box, Chip, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { roundMacros, scaleMacros } from "@notemeal/shared/utils/macro-protocol";
import classnames from "classnames";
import { useState } from "react";
import MacrosSummaryLabel from "../../Macros/SummaryLabel";
import { FullServingAmountFragment, FullServingFragment } from "../../types";
import { useAthleteHighlightFromServingAmount } from "../../utils/Foods/foods";
import IngredientsList from "../IngredientsList";
import LimitedAccessWarning from "../LimitedAccessWarning";
import ServingAmountTooltip from "../Tooltip";
import { formatAmbiguousServingAmountWithTotalWeight } from "../utils";

const useStyles = makeStyles(({ spacing, palette: { like, dislike } }: Theme) =>
  createStyles({
    chipLimitedAccess: {
      height: spacing(7),
    },
    chipDefault: {
      height: spacing(4),
    },
    chip: {
      marginTop: spacing(1),
      width: "100%",
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      flexWrap: "nowrap",
      borderRadius: spacing(1),
    },
    notExpanded: { borderRadius: spacing(1) },
    greenChip: {
      backgroundColor: like.lighter,
      "&:hover": {
        backgroundColor: like.light,
      },
      "&:focus": {
        backgroundColor: like.light,
      },
    },
    redChip: {
      backgroundColor: dislike.lighter,
      "&:hover": {
        backgroundColor: dislike.light,
      },
      "&:focus": {
        backgroundColor: dislike.light,
      },
    },
    chipLabel: {
      flexGrow: 1,
      flexShrink: 1,
      minWidth: 0,
      position: "relative",
      lineHeight: "1em",
    },
    chipLabelWrapper: {
      display: "flex",
      width: "100%",
    },
    chipLabelText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    chipLabelDots: {
      display: "flex",
      alignItems: "center",
    },
    chipLabelDot: {
      marginLeft: spacing(0.5),
      width: spacing(2.5),
      height: spacing(2.5),
    },
    foodServingAmount: {
      lineHeight: 1,
    },
    foodName: {
      lineHeight: 1,
      fontWeight: "bold",
    },
    expanded: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
  })
);

interface ServingAmountViewChipProps {
  servingAmount: FullServingAmountFragment;
  hideLimitedAccessWarning?: boolean;
  onRemoveServing?: (servingAmountId: string) => void;
}

const ServingAmountViewChip = ({ servingAmount, hideLimitedAccessWarning = false, onRemoveServing }: ServingAmountViewChipProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const { highlightType: highlightColor } = useAthleteHighlightFromServingAmount(servingAmount.serving.foodOrRecipe);

  const { serving, amount } = servingAmount;

  const isExpandable = serving.foodOrRecipe.__typename === "Recipe" && serving.foodOrRecipe.hasFullAccess;

  const toggleExpanded = () => {
    if (isExpandable) {
      setExpanded(!expanded);
    }
  };

  const ingredientsComponent = serving.foodOrRecipe.__typename === "Recipe" && expanded && (
    <IngredientsList
      recipeId={serving.foodOrRecipe.id}
      perRecipeYield={serving.perRecipeYield || 1}
      units={serving.units}
      onClose={toggleExpanded}
    />
  );

  const className = classnames([
    classes.chip,
    {
      [classes.redChip]: highlightColor === "Red",
      [classes.greenChip]: highlightColor === "Green",
      [classes.expanded]: expanded,
      [classes.notExpanded]: !expanded,
      [classes.chipDefault]: serving.foodOrRecipe.hasFullAccess,
      [classes.chipLimitedAccess]: !serving.foodOrRecipe.hasFullAccess,
    },
  ]);

  return (
    <>
      <Chip
        label={
          <ServingAmountViewChipLabel
            serving={serving}
            amount={amount}
            hideLimitedAccessWarning={hideLimitedAccessWarning}
            toggleExpanded={toggleExpanded}
          />
        }
        className={className}
        classes={{ label: classes.chipLabel }}
        clickable
        onDelete={onRemoveServing ? () => onRemoveServing(servingAmount.id) : undefined}
        onClick={toggleExpanded}
      />
      {ingredientsComponent}
    </>
  );
};

export default ServingAmountViewChip;

interface ServingAmountViewChipLabelProps {
  serving: FullServingFragment;
  amount: number;
  hideLimitedAccessWarning: boolean;
  toggleExpanded: () => void;
}

const ServingAmountViewChipLabel = ({ serving, amount, hideLimitedAccessWarning, toggleExpanded }: ServingAmountViewChipLabelProps) => {
  const classes = useStyles();

  if (serving.foodOrRecipe.hasFullAccess || hideLimitedAccessWarning) {
    return (
      <ServingAmountTooltip serving={serving} amount={amount}>
        <div className={classes.chipLabelWrapper} onClick={toggleExpanded}>
          <div className={classes.chipLabelText}>
            <Typography variant="subtitle1" classes={{ root: classes.foodName }} noWrap>
              {serving.foodOrRecipe.name}
            </Typography>
            <Typography variant="subtitle1" classes={{ root: classes.foodServingAmount }}>
              {formatAmbiguousServingAmountWithTotalWeight({
                amount,
                serving,
              })}
            </Typography>
          </div>
        </div>
      </ServingAmountTooltip>
    );
  } else {
    const scaledMacros = roundMacros(scaleMacros(serving.macros, amount));

    return (
      <>
        <div className={classes.chipLabelWrapper} onClick={toggleExpanded}>
          <div className={classes.chipLabelText}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" classes={{ root: classes.foodName }} noWrap>
                {serving.foodOrRecipe.name}
              </Typography>
              <LimitedAccessWarning serving={serving} />
            </Box>
            <Typography variant="subtitle1" classes={{ root: classes.foodServingAmount }}>
              {formatAmbiguousServingAmountWithTotalWeight({
                amount,
                serving,
              })}
            </Typography>
          </div>
        </div>

        <MacrosSummaryLabel macros={scaledMacros} kcalPrefix variant="xs" />
      </>
    );
  }
};

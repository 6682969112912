import { Box, CircularProgress, Divider, PaletteColor, Typography, useTheme } from "@mui/material";
import React from "react";
import { getCaption } from "../MacrosProgressUtils";

interface MultiColorDialProps {
  current: number;
  target: number;
  variant?: "lg" | "md" | "sm" | "xs";
  hideNumbers?: boolean;
  label: string;
  color: PaletteColor;
}

export const MultiColorDial = ({ current, target, variant = "lg", hideNumbers, label, color }: MultiColorDialProps) => {
  const {
    palette: { mode },
  } = useTheme();

  const circularProgressProps = {
    lg: {
      thickness: 5,
      size: 90,
    },
    md: {
      thickness: 4,
      size: 60,
    },
    sm: {
      thickness: 4,
      size: 45,
    },
    xs: {
      thickness: 3,
      size: 40,
    },
  };

  const unit = "g";
  const value = (100 * current) / target;
  const currentText = `${Math.round(current)}${unit}`;
  const targetText = `${Math.round(target)}${unit}`;
  const dividerColor = mode === "light" ? "black" : "white";
  const dividerWidth = variant === "lg" ? "35%" : "50%";
  const excludeTarget = variant === "sm" || variant === "xs";
  const caption = getCaption(current, target, unit, hideNumbers, excludeTarget);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr" }}>
        <CircularProgress
          sx={{ gridColumnStart: 1, gridRowStart: 1, color: color.light }}
          variant="determinate"
          value={100}
          {...circularProgressProps[variant]}
        />
        <CircularProgress
          sx={{ gridColumnStart: 1, gridRowStart: 1, color: color.main }}
          variant="determinate"
          value={Math.min(value, 100)}
          {...circularProgressProps[variant]}
        />
        {value > 100 && (
          <CircularProgress
            sx={{ gridColumnStart: 1, gridRowStart: 1, color: color.dark }}
            variant="determinate"
            value={Math.min(value - 100, 100)}
            {...circularProgressProps[variant]}
          />
        )}
        <Box
          sx={{
            gridColumnStart: 1,
            gridRowStart: 1,
            width: circularProgressProps[variant].size,
            height: circularProgressProps[variant].size,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {variant === "sm" || variant === "xs" || hideNumbers ? (
            <Typography variant="subtitle1">{caption}</Typography>
          ) : (
            <>
              <Typography variant="subtitle1">{currentText}</Typography>
              <Divider sx={{ backgroundColor: dividerColor, my: "1px", width: dividerWidth }} />
              <Typography variant="subtitle1">{targetText}</Typography>
            </>
          )}
        </Box>
      </Box>
      <Typography
        sx={{ color: color.main, textAlign: "center" }}
        variant={variant === "lg" ? "body2" : variant === "md" ? "subtitle1" : "caption"}
      >
        {label}
      </Typography>
    </Box>
  );
};

import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { UnitWithConversionsFragment } from "../../../types";
import { BaseFoodFormType } from "../FoodFormSchema/FoodFormSchema";
import { createNewFoodServingState } from "../FoodFormSchema/foodServingsUtils";
import { FoodDefaultServings } from "./FoodDefaultServings";
import { FoodDetailsSection } from "./FoodDetails/FoodDetailsSection";
import { FoodImageSection } from "./FoodImageSection";
import { FoodServingNutrients } from "./FoodServingNutrients";

interface FoodFormProps {
  form: UseFormReturn<BaseFoodFormType>;
  unitsData: readonly UnitWithConversionsFragment[];
  gramUnit: UnitWithConversionsFragment;
  readonly?: boolean;
}

export const FoodForm = ({ form, unitsData, gramUnit, readonly }: FoodFormProps) => {
  const { control, watch } = form;
  const [watchedName, watchedServings] = watch(["name", "servings"]);

  const defaultServing = watchedServings.find(s => s.isDefault);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 0.5 }}>
      <Grid
        container
        spacing={5}
        sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={9}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            Details
          </Typography>
          <FoodDetailsSection
            form={form}
            disabled={readonly}
            setIsLoading={setIsLoading}
            unitsData={unitsData}
            gramUnit={gramUnit} />
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ minHeight: 350 }}>
          <Controller
            name={"thumbnailUrl"}
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <FoodImageSection
                disabled={readonly}
                name={watchedName}
                thumbnailUrl={field.value}
                defaultServing={defaultServing}
                changeImage={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Grid
        container
        spacing={5}
        sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={9}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography variant="h3">Servings</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                const { getValues, setValue } = form;
                const { servings } = getValues();
                setValue("servings", [...servings, createNewFoodServingState(false)]);
              }}
              disabled={readonly}
              sx={{ mr: 1 }}
              aria-label="Add new serving nutrients"
            >
              Add Serving
            </Button>
          </Box>
          <FoodDefaultServings
            form={form}
            disabled={readonly}
            isLoading={isLoading}
            unitsData={unitsData}
            gramUnit={gramUnit} />
        </Grid>
        <Grid item xs={3}>
          <FoodServingNutrients
            disabled={readonly}
            defaultServing={defaultServing}
            form={form} />
        </Grid>
      </Grid>
    </Box>
  );
};

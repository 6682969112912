import { Card, CardActionArea, CardContent, CardHeader, Typography, useTheme } from "@mui/material";
import { formatEventTime } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { TimelineMealTemplateFragment } from "../../../../types";

interface TimelineMealTemplateCardProps {
  timelineMealTemplate: TimelineMealTemplateFragment;
  onClick: () => void;
}

const TimelineMealTemplateCard = ({ timelineMealTemplate, onClick }: TimelineMealTemplateCardProps) => {
  const timeRange = formatEventTime(timelineMealTemplate.template.meal);
  const isOrderable = timelineMealTemplate.mealMenus.some(mm => mm.isOrderable);
  const actionText = isOrderable ? "Click to Place Order / Save Log" : "Click to Log Meal";

  const {
    palette: { greyscale },
  } = useTheme();

  return (
    <Card sx={{ border: `1px solid ${greyscale[100]}` }}>
      <CardHeader
        title={
          <b>
            {timelineMealTemplate.template.meal.name} ({timeRange})
          </b>
        }
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography variant="body2">{actionText}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TimelineMealTemplateCard;

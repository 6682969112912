import { Card, FormControlLabel, Switch, SxProps } from "@mui/material";
import { MenuItemAppearancePreviewImage } from "apps/web/src/components/MenuItemAppearance/MenuItemAppearancePreview/MenuItemAppearancePreviewImage";
import { MenuItemAppearancePreviewTitle } from "apps/web/src/components/MenuItemAppearance/MenuItemAppearancePreview/MenuItemAppearancePreviewTitle";
import { MenuCardActionArea, MenuCardContent } from "apps/web/src/components/MenuItemAppearance/MenuItemAppearancePreview/utils";
import React, { useState } from "react";
import { RestaurantMenuItemAppearanceState } from "../types";
import { RestaurantMenuItemAppearanceDialog } from "./RestaurantMenuItemAppearanceDialog";
import { INCLUDE_ON_MENU_LABEL } from "./utils";

interface RestaurantMenuItemAppearancePreviewProps {
  menuItemAppearance: RestaurantMenuItemAppearanceState;
  onEdit: (menuItemAppearance: RestaurantMenuItemAppearanceState) => void;
  sx?: SxProps;
}

export const RestaurantMenuItemAppearancePreview = ({ menuItemAppearance, sx, onEdit }: RestaurantMenuItemAppearancePreviewProps) => {
  const { menuItem, included: isIncluded } = menuItemAppearance;
  const { name, imageUrl, score } = menuItem;

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Card sx={sx} elevation={3}>
      <MenuCardActionArea onClick={() => setDialogOpen(true)}>
        <MenuCardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }} imageUrl={imageUrl}>
          <MenuItemAppearancePreviewTitle
            isDeleted={!isIncluded}
            title={name}
            score={score} />
          <FormControlLabel
            sx={{ ml: 0 }}
            control={
              <Switch
                sx={{ mr: 1 }}
                size="small"
                checked={isIncluded}
                onChange={(_, included) =>
                  onEdit({
                    ...menuItemAppearance,
                    included,
                  })
                }
              />
            }
            labelPlacement="end"
            label={INCLUDE_ON_MENU_LABEL}
          />
        </MenuCardContent>
        <MenuItemAppearancePreviewImage imageUrl={imageUrl} />
      </MenuCardActionArea>
      {dialogOpen && (
        <RestaurantMenuItemAppearanceDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onDone={onEdit}
          menuItemAppearance={menuItemAppearance}
        />
      )}
    </Card>
  );
};

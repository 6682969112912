import { getLink } from "../../../NavUtils";

// full page path
export const getNavOrgTeam = (teamId: string) => `/org/teams/${teamId}`;

// local child paths
export const NAV_TEAM_DASHBOARD = "dashboard";
export const NAV_TEAM_ROSTER = "roster";
export const NAV_TEAM_ANTHROPOMETRY = "anthropometry";
export const NAV_TEAM_MEAL_PLANS = "meal-plans";
export const NAV_TEAM_NOTES = "notes";

// full child paths
export const getNavOrgTeamRoster = (teamId: string) => `${getNavOrgTeam(teamId)}/${NAV_TEAM_ROSTER}`;

export const getNavOrgTeamLink = (teamId: string, teamName: string) => getLink(teamName, getNavOrgTeam(teamId));

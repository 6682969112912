import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShareIcon from "@mui/icons-material/Share";
import { Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { formatDistanceToNow } from "date-fns";
import { enUS } from "date-fns/locale";
import React, { useState } from "react";
import LoadingBackdrop from "../../../../../components/universal/LoadingBackdrop";
import {
  useOrgsStatusSyncQuery,
  useRefreshTeamworksSyncStatusMutation,
  useRunMaintainTeamworksFullySyncedJobMutation,
} from "../../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      overflow: "hidden",
    },
    container: {
      maxHeight: "100%",
    },
  })
);

interface OrgSyncStatusDialogProps {
  open: boolean;
  onClose: () => void;
  onEdit: (orgId: string, teamworksId: number) => void;
}

const OrgSyncStatusDialog = ({ open, onClose, onEdit }: OrgSyncStatusDialogProps) => {
  const classes = useStyles();
  const { data, loading } = useOrgsStatusSyncQuery();

  const [refreshingOrgIds, setRefreshingOrgIds] = useState<string[]>([]);
  const [refreshSyncStatus] = useRefreshTeamworksSyncStatusMutation({
    onCompleted: data => {
      setRefreshingOrgIds(ids => ids.filter(id => id !== data.refreshTeamworksSyncStatus.org.id));
    },
  });

  const [maintainJobOrgIds, setMaintainJobOrgIds] = useState<string[]>([]);
  const [runMaintainFullySyncedJob] = useRunMaintainTeamworksFullySyncedJobMutation();

  if (!data || loading) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
      onClose={onClose}
      classes={{ paper: classes.paper }}>
      <DialogContent className={classes.content}>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Edit</TableCell>
                <TableCell align="center">Org</TableCell>
                <TableCell align="center">Unlinked Nutrition Profiles</TableCell>
                <TableCell align="center">Unlinked Teamworks Profiles</TableCell>
                <TableCell align="center">To Deactivate Profiles</TableCell>
                <TableCell align="center">Linked Profiles</TableCell>
                <TableCell align="center">Notemeal Linked</TableCell>
                <TableCell align="center">Teamworks Linked</TableCell>
                <TableCell align="center">Fully Synced</TableCell>
                <TableCell align="center">Last Updated</TableCell>
                <TableCell align="center">Refresh Sync Status</TableCell>
                <TableCell align="center">Run Maintain Job</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.orgs.map(
                ({ id, name, teamworksId, teamworksSyncStatus }) =>
                  teamworksId && (
                    <TableRow key={id}>
                      <TableCell align="center">
                        <IconButton size="small" onClick={() => onEdit(id, teamworksId)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="center">{teamworksSyncStatus?.unlinkedNotemealProfileCount}</TableCell>
                      <TableCell align="center">{teamworksSyncStatus?.unlinkedTeamworksProfileCount}</TableCell>
                      <TableCell align="center">{teamworksSyncStatus?.toDeactivateProfileCount}</TableCell>
                      <TableCell align="center">{teamworksSyncStatus?.linkedProfileCount}</TableCell>
                      <TableCell align="center">{teamworksSyncStatus?.isNotemealLinked && "✓"}</TableCell>
                      <TableCell align="center">{teamworksSyncStatus?.isTeamworksLinked && "✓"}</TableCell>
                      <TableCell align="center">{teamworksSyncStatus?.isFullySynced && "✓"}</TableCell>
                      <TableCell align="center">
                        {teamworksSyncStatus &&
                          // TODO: Locale Cleanup Staff page
                          formatDistanceToNow(new Date(teamworksSyncStatus.updatedAt), {
                            addSuffix: true,
                            locale: enUS,
                          })}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (refreshingOrgIds.includes(id)) {
                              return;
                            }
                            setRefreshingOrgIds(ids => [...ids, id]);
                            refreshSyncStatus({
                              variables: {
                                input: {
                                  orgId: id,
                                },
                              },
                            });
                          }}
                        >
                          {refreshingOrgIds.includes(id) ? <Loading progressSize="xs" /> : <RefreshIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          disabled={!teamworksSyncStatus?.isNotemealLinked}
                          onClick={() => {
                            if (maintainJobOrgIds.includes(id)) {
                              return;
                            }
                            setMaintainJobOrgIds(ids => [...ids, id]);
                            runMaintainFullySyncedJob({
                              variables: {
                                input: {
                                  orgId: id,
                                },
                              },
                            });
                          }}
                        >
                          {maintainJobOrgIds.includes(id) ? <CheckIcon /> : <ShareIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default OrgSyncStatusDialog;

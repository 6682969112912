import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

interface MenuItemAllowSpecialRequestsInputProps {
  variant: "MenuItem" | "MenuItemAppearance" | "RestaurantLinkMenuItemAppearance";
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const MenuItemAllowSpecialRequestsInput = ({ variant, value, onChange, disabled }: MenuItemAllowSpecialRequestsInputProps) => {
  return (
    <FormControlLabel
      label={variant === "MenuItem" ? "Allow Special Requests (Default)" : "Allow Special Requests"}
      control={<Checkbox
        size="small"
        checked={value}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled} />}
    />
  );
};

export default MenuItemAllowSpecialRequestsInput;

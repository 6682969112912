import { getExchangePickList } from "../ExchangeServingList/utils";
import { useApolloResolverContext } from "../hooks/useApolloResolverContext";
import { useServingAmountsWithExchangeTotals } from "./utils";
import { ExchangeAmountFragment, ExchangeFragment, FullExchangeSetFragment, FullServingAmountFragment } from "../types";
import { mergeServingAmounts } from "../ServingAmount/utils";
import { sortExchangeFn } from "../Exchange/utils";

// currentServingAmounts can belong to any ExchangeTarget it contributes to the count of
// i.e. a combo food would be in multiple ExchangeTargets's "currentServingAmounts"
export interface ExchangeTarget {
  readonly exchange: ExchangeFragment;
  readonly amount: number;
  readonly pickList: readonly FullServingAmountFragment[];
  readonly servingAmountsWithExchangeAmount: readonly ServingAmountWithExchangeAmount[];
}

export interface ServingAmountWithExchangeAmount extends FullServingAmountFragment {
  exchangeAmount: number;
}

export interface useExchangeTargetsArgs {
  targetExchangeAmounts: readonly ExchangeAmountFragment[];
  exchangeSet: FullExchangeSetFragment;
  servingAmounts: readonly FullServingAmountFragment[];
  onEditServingAmounts: (servingAmounts: readonly FullServingAmountFragment[]) => void;
}

interface useExchangeTargetsPayload {
  exchangeTargets: readonly ExchangeTarget[];
  unassignedServingAmounts: readonly FullServingAmountFragment[];
  onChangeExchangeTargetServingAmounts: (exchangeTarget: ExchangeTarget, newServingAmounts: readonly FullServingAmountFragment[]) => void;
}

export const useExchangeTargets = ({
  targetExchangeAmounts,
  servingAmounts,
  exchangeSet,
  onEditServingAmounts,
}: useExchangeTargetsArgs): useExchangeTargetsPayload => {
  const context = useApolloResolverContext();
  const servingAmountsWithExchangeTotals = useServingAmountsWithExchangeTotals(servingAmounts, exchangeSet, targetExchangeAmounts, context);

  const unassignedServingAmounts = servingAmountsWithExchangeTotals
    .filter(({ exchangeTotals }) => {
      return Object.keys(exchangeTotals).every(exchangeId => exchangeTotals[exchangeId] === undefined);
    })
    .map(({ servingAmount }) => servingAmount);

  const exchangeTargets: ExchangeTarget[] = exchangeSet.exchanges
    .flatMap(exchange => {
      const matchingExchangeAmount = targetExchangeAmounts.find(ea => ea.exchange.id === exchange.id);
      const pickList = getExchangePickList(exchange.exchangeServingList, matchingExchangeAmount?.pickListServingIds || null);
      const servingAmountsWithExchangeAmount: ServingAmountWithExchangeAmount[] = servingAmountsWithExchangeTotals
        .map(({ servingAmount, exchangeTotals }) => ({
          ...servingAmount,
          exchangeAmount: exchangeTotals[exchange.id],
        }))
        .flatMap(sa => (sa.exchangeAmount === undefined ? [] : { ...sa, exchangeAmount: sa.exchangeAmount }));

      const exchangeTargetAmount = matchingExchangeAmount?.amount || 0;

      if (exchangeTargetAmount === 0 && servingAmountsWithExchangeAmount.length === 0) {
        return [];
      } else {
        return {
          amount: exchangeTargetAmount,
          exchange,
          pickList,
          servingAmountsWithExchangeAmount,
        };
      }
    })
    .sort((ea1, ea2) => {
      const ea1IsEmpty = ea1.servingAmountsWithExchangeAmount.length === 0;
      const ea2IsEmpty = ea2.servingAmountsWithExchangeAmount.length === 0;
      if (ea1IsEmpty === ea2IsEmpty) {
        return sortExchangeFn(ea1.exchange, ea2.exchange);
      } else if (ea1IsEmpty) {
        return -1;
      } else {
        return 1;
      }
    });

  const onChangeExchangeTargetServingAmounts = (
    exchangeTarget: ExchangeTarget,
    newServingAmounts: readonly FullServingAmountFragment[]
  ) => {
    const replaceServingIds = exchangeTarget.servingAmountsWithExchangeAmount.map(sa => sa.serving.id);
    onEditServingAmounts(
      mergeServingAmounts([...servingAmounts.filter(sa => !replaceServingIds.includes(sa.serving.id)), ...newServingAmounts])
    );
  };

  return {
    exchangeTargets,
    onChangeExchangeTargetServingAmounts,
    unassignedServingAmounts,
  };
};

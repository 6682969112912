import Email from "@mui/icons-material/Email";
import Phone from "@mui/icons-material/Phone";
import { Button, Dialog, DialogActions, DialogContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { DeliveryLocationMenuLinkFormFragment, RestaurantLocationPreviewFragment } from "../../../types";

interface RestaurantMenuLinkLocationInfoDetailsProps {
  restaurantLocation: RestaurantLocationPreviewFragment | null;
  deliveryLocation: DeliveryLocationMenuLinkFormFragment | null;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2, 1),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    address: { padding: theme.spacing(2, 0) },
    locationInfo: { display: "flex", flexDirection: "column" },
    contactRow: { display: "flex", alignItems: "center" },
    icon: { padding: theme.spacing(1) },
    notes: { padding: theme.spacing(2, 0) },
  })
);

const RestaurantMenuLinkLocationDetailsDialog = ({
  restaurantLocation,
  deliveryLocation,
  open,
  onClose,
}: RestaurantMenuLinkLocationInfoDetailsProps) => {
  const classes = useStyles();

  const deliveryLocationName = deliveryLocation?.name ?? null;
  const deliveryLocationAddress = deliveryLocation?.address?.displayName ?? null;
  const email = restaurantLocation?.email ?? null;
  const phoneNumber = restaurantLocation?.phoneNumber ?? null;
  const notes = restaurantLocation?.notes ?? null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs">
      <DialogTitle title="Location Details" onClose={onClose} />
      <DialogContent>
        <div className={classes.locationInfo}>
          {deliveryLocationName && (
            <div className={classes.address}>
              <Typography variant="body1">Deliver To:</Typography>
              <Typography variant="body1">{deliveryLocationName} </Typography>
              {deliveryLocationAddress && <Typography variant="body1">{deliveryLocationAddress}</Typography>}
            </div>
          )}
          {email && (
            <div className={classes.contactRow}>
              <Email className={classes.icon} />
              <Typography variant="body1">{email}</Typography>
            </div>
          )}
          {phoneNumber && (
            <div className={classes.contactRow}>
              <Phone className={classes.icon} />
              <Typography variant="body1">{phoneNumber}</Typography>
            </div>
          )}
          {notes && (
            <Typography variant="subtitle1" className={classes.notes}>
              {notes}
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestaurantMenuLinkLocationDetailsDialog;

import { useHasFeature } from "@notemeal/shared/ui/Feature";
import React from "react";
import { PlannedMenuTable } from "../../../../views/MenuBuilder/PlannedMenuTable/PlannedMenuTable";
import { PlannedMenusPageProvider } from "../../../../views/MenuBuilder/PlannedMenuTable/PlannedMenuTableProvider";

export const PlannedMenuPage = () => {
  const showMenuBuilder = useHasFeature("performanceKitchen");
  if (showMenuBuilder) {
    return (
      <PlannedMenusPageProvider>
        <PlannedMenuTable />
      </PlannedMenusPageProvider>
    );
  }
  return null;
};

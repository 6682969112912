import CheckIcon from "@mui/icons-material/Check";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SmsIcon from "@mui/icons-material/Sms";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import { Button, CircularProgress, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { createRef, useState } from "react";
import DisplaySettingsExchangeForm from "../../../DisplaySettings/MobileExchangeForm";
import DisplaySettingsMacroForm from "../../../DisplaySettings/MobileMacroForm";

// import { MealPlanDisplaySettings } from '../../../../types';
import { ExchangeMealPlanDisplaySettingsFragment, MacroMealPlanDisplaySettingsFragment, MealPlanType } from "../../../../types";

const ORIGIN = window.location.origin;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    denseSwitch: {
      marginLeft: "5px",
      marginRight: "3px",
      paddingTop: 5,
      paddingBottom: 5,
    },
    buttonLogo: {
      marginRight: "5px",
    },
    orientationButton: {
      padding: theme.spacing(2),
    },
    displayTargetsButton: {
      padding: theme.spacing(1),
    },
    divider: {
      width: "100%",
      margin: theme.spacing(2),
    },
    toolbarDividerTitle: {
      padding: theme.spacing(1),
    },
    formRow: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      width: "100%",
    },
    hiddenUrlInput: {
      opacity: 0,
      height: 0,
      weight: 0,
    },
    actionButton: {
      margin: theme.spacing(0, 0.5),
    },
    linkPlaceHolder: {
      height: 73,
      width: 335,
    },
    linkCaption: {
      margin: theme.spacing(0, 1),
      textAlign: "center",
    },
    spacer: {
      width: theme.spacing(3),
    },
  })
);

interface ExportToolbarSingleMobileProps {
  mealPlanId: string;
  type: MealPlanType;
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
  textButtonStatus?: "called" | "success" | "error";
  onClickText: () => void;
}

const ExportToolbarSingleMobile = ({
  mealPlanId,
  type,
  macroDisplaySettings,
  exchangeDisplaySettings,
  onClickText,
  textButtonStatus,
}: ExportToolbarSingleMobileProps) => {
  const classes = useStyles();
  const textFieldRef = createRef<HTMLInputElement>();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (textFieldRef.current) {
      setCopied(true);
      textFieldRef.current.select();
      document.execCommand("copy");
    }
  };

  return (
    <>
      <div>
        <div className={classes.formRow}>
          <Button
            onClick={handleCopy}
            disabled={copied}
            className={classes.actionButton}>
            {!copied ? <FileCopyIcon /> : <CheckIcon />}
          </Button>
          <Button
            onClick={onClickText}
            disabled={!!textButtonStatus}
            className={classes.actionButton}>
            {!textButtonStatus ? (
              <SmsIcon />
            ) : textButtonStatus === "called" ? (
              <CircularProgress size={25} />
            ) : textButtonStatus === "success" ? (
              <CheckIcon color="success" />
            ) : (
              <SmsFailedIcon color="error" />
            )}
          </Button>
        </div>
        <div className={classes.formRow}>
          <Typography variant="subtitle1" className={classes.linkCaption}>
            Copy meal plan link or text it to athlete.
          </Typography>
        </div>
        <div className={classes.formRow}>
          <Typography
            variant="subtitle1"
            color="error"
            className={classes.linkCaption}>
            Note: this will not send a mobile activation link!{" "}
          </Typography>
        </div>
      </div>
      {type === "exchange" && <DisplaySettingsExchangeForm displaySettings={exchangeDisplaySettings} />}
      {type === "macro" && <DisplaySettingsMacroForm displaySettings={macroDisplaySettings} />}
      <input
        readOnly
        value={`${ORIGIN}/meal-plans/${mealPlanId}`}
        className={classes.hiddenUrlInput}
        ref={textFieldRef} />
      <div className={classes.formRow}>
        <Typography variant="subtitle1">*Boxes show athlete's view on the mobile app.</Typography>
      </div>
    </>
  );
};

export default ExportToolbarSingleMobile;

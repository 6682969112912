import { addMinutes, compareAsc } from "date-fns";
import { v4 } from "uuid";
import {
  MealEvent,
  ActivityEvent,
  ExternalEvent,
  SuggestedEvent,
  MealModification,
  StaticEvent,
  MealEventCandidate,
  MealPlanEvent,
} from "./types";
import { getTimeOffsetForMeal } from "./getTimeOffsetForMeal";
import { getMealModificationsForExternalMealMatches } from "./getMealModificationsForExternalMealMatches";
import { findMealModificationForMeal } from "./utils";

type Event = MealEvent | ActivityEvent | ExternalEvent;
type ResolvedMealEvent = (SuggestedEvent & { type: "SuggestedMealTemplate" }) | (MealEvent & { type: "MealTemplate" });

const isMealEvent = (event: Event): event is MealEvent => event.__typename === "meal";
const isActivityEvent = (event: Event): event is ActivityEvent => event.__typename === "activity";
const isSuggestedMealTemplate = (event: ResolvedMealEvent): event is SuggestedEvent & { type: "SuggestedMealTemplate" } =>
  event.type === "SuggestedMealTemplate";

interface SuggestedMealPlanProps {
  mealPlanEvents: readonly MealPlanEvent[];
  externalEvents: readonly ExternalEvent[];
  modifiedMealTemplateIds: string[];
}

export const getSuggestedMealEventsForMealPlanEvents = ({
  mealPlanEvents,
  externalEvents,
  modifiedMealTemplateIds,
}: SuggestedMealPlanProps): SuggestedEvent[] => {
  const { meals } = getSuggestedNewMealSchedule({ mealPlanEvents, externalEvents, modifiedMealTemplateIds });
  return meals.filter(isSuggestedMealTemplate);
};

const getSuggestedNewMealSchedule = ({ mealPlanEvents, externalEvents, modifiedMealTemplateIds }: SuggestedMealPlanProps) => {
  const mealEvents: MealEvent[] = mealPlanEvents.flatMap(event => (isMealEvent(event) ? event : []));
  const activityEvents: ActivityEvent[] = mealPlanEvents.flatMap(event => (isActivityEvent(event) ? event : []));

  const mealModifications = getMealModificationsForEvents(externalEvents, mealEvents, activityEvents, modifiedMealTemplateIds);

  // get new meal events using modifications found
  const resolvedMealEvents: ResolvedMealEvent[] = getResolvedMealEvents(mealEvents, mealModifications);
  return { meals: resolvedMealEvents, activities: activityEvents };
};

/**
 * Finds all modifications needed to existing meal times
 * given any conflicts with external events
 */
const getMealModificationsForEvents = (
  externalEvents: readonly ExternalEvent[],
  mealEvents: MealEvent[],
  activityEvents: ActivityEvent[],
  modifiedMealTemplateIds: string[]
): MealModification[] => {
  // get all static, non-meal, events
  const staticEvents = getStaticEvents(externalEvents, activityEvents).sort((e1, e2) => compareAsc(e1.start, e2.start));
  // sort and pre-process meal events
  const mealEventCandidates: MealEventCandidate[] = mealEvents
    .sort((e1, e2) => compareAsc(e1.start, e2.start))
    .map(me => {
      const isStatic = isStaticMealEvent(me, modifiedMealTemplateIds);
      return { ...me, isStatic };
    });

  // Match Notemeal meals to External meals
  const teamworksMealMatchModifications = getMealModificationsForExternalMealMatches(mealEventCandidates, staticEvents);

  // for each meal event, find if a meal needs to be moved
  const mealModifications: MealModification[] = teamworksMealMatchModifications;
  for (const mealEventCandidate of mealEventCandidates) {
    // can't move static meals or meals that have been matched to an external meal
    if (mealEventCandidate.isStatic || findMealModificationForMeal(mealModifications, mealEventCandidate)) {
      continue;
    }

    const mealMod = getModificationForMeal(mealEventCandidate, mealEventCandidates, staticEvents, mealModifications);
    if (mealMod) {
      mealModifications.push(mealMod);
    }
  }

  return mealModifications;
};

/**
 * Returns a list of static events.
 * Static events are events that cannot be moved.
 */
const getStaticEvents = (externalEvents: readonly ExternalEvent[], activityEvents: ActivityEvent[]): StaticEvent[] => {
  const staticEvents: StaticEvent[] = [];

  // all external events are static
  for (const event of externalEvents) {
    if (event.isAllDay) {
      // skip all day events since they shouldn't conflict with meal times
      continue;
    }
    staticEvents.push(event);
  }

  // all activities are static
  for (const activityEvent of activityEvents) {
    staticEvents.push(activityEvent);
  }

  return staticEvents;
};

const getModificationForMeal = (
  mealEvent: MealEventCandidate,
  mealEvents: MealEventCandidate[],
  orderedStaticEvents: StaticEvent[],
  mealModifications: MealModification[]
): MealModification | null => {
  const timeOffset = getTimeOffsetForMeal(mealEvent, orderedStaticEvents, mealEvents, mealModifications);
  return timeOffset === 0 ? null : { mealEvent, timeOffset };
};

/**
 * Returns the new meal events using the list of meal modifications
 */
const getResolvedMealEvents = (mealEvents: MealEvent[], mealModifications: MealModification[]): ResolvedMealEvent[] => {
  return mealEvents.map(me => {
    const modForMeal = findMealModificationForMeal(mealModifications, me);
    const { mealType, ...rest } = me;
    // adjust time if offset exists, else event stays the same
    return modForMeal
      ? {
          ...rest,
          id: v4(),
          oldId: me.id,
          oldStart: me.start,
          type: "SuggestedMealTemplate",
          start: addMinutes(me.start, modForMeal.timeOffset),
          end: addMinutes(me.start, me.durationInMinutes + modForMeal.timeOffset),
        }
      : { ...me, mealType, type: "MealTemplate" };
  });
};

/**
 * Returns whether a meal is static or not.
 * A meal is static if it is in the past or just started or has been modified
 */
const isStaticMealEvent = (event: MealEvent, modifiedMealTemplateIds: string[]): boolean => {
  return modifiedMealTemplateIds.includes(event.id) || event.start <= new Date();
};

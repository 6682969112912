import { reduceStatuses } from "./status";
import {
  MenuOrderItemFormFragment,
  MenuOrderItemInput,
  EditMenuOrderItemInput,
  ImageInput,
  MealMenuDiningStationWithItemPreviewsFragment,
} from "../types";
import { PickupTime } from "./usePickupTimeState";
import { formatTimeInTimezone } from "../utils/dateTimes";
import { isMenuSelectionInvalid, MenuSelectionItemWithAppearance } from "../MenuSelection/utils";
import newId from "../utils/newId";

export interface AddMenuOrderPayload {
  orderItems: readonly MenuOrderItemInput[];
  logItems: readonly MenuOrderItemInput[];
  images: readonly ImageInput[] | null;
  pickupTime: string;
}

export type MenuOrderItemWithAppearance = MenuSelectionItemWithAppearance<MenuOrderItemFormFragment>;

interface MenuOrderErrors {
  type: "errors";
  invalidOrder: boolean;
  error: string | null;
}

interface AddMenuOrderInputs extends AddMenuOrderPayload {
  type: "inputs";
}

export const getAddMenuOrderInputsOrErrors = (
  menuOrderItems: readonly MenuOrderItemWithAppearance[],
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[],
  images: readonly ImageInput[] | null,
  pickupTime: PickupTime | null
): AddMenuOrderInputs | MenuOrderErrors => {
  const noItems = menuOrderItems.length === 0;
  const invalidOrder = isMenuSelectionInvalid(menuOrderItems, mealMenuDiningStations);
  const anyOrderItems = menuOrderItems.some(moi => moi.forOrder);
  const noRemainingOrderSpots = pickupTime?.remaining === 0 && anyOrderItems;
  if (invalidOrder || pickupTime === null || noRemainingOrderSpots || noItems) {
    const error =
      pickupTime === null
        ? "Set pickup time to update order"
        : noRemainingOrderSpots
        ? "No order spots left in time window"
        : noItems
        ? "Add menu items to place order"
        : null;

    return {
      type: "errors",
      invalidOrder,
      error,
    };
  } else {
    return {
      type: "inputs",
      pickupTime: pickupTime.value,
      orderItems: menuOrderItems.filter(i => i.forOrder).map(getMenuOrderItemInput),
      logItems: menuOrderItems.filter(i => !i.forOrder).map(getMenuOrderItemInput),
      images: images && images.map(({ url, position }) => ({ url, position })),
    };
  }
};

const getMenuOrderItemInput = (item: MenuOrderItemFormFragment | MenuOrderItemWithAppearance): MenuOrderItemInput => ({
  amount: item.amount,
  menuItemId: item.menuItem.id,
  specialRequests: item.specialRequests,
  options: item.options.map(o => ({
    amount: o.amount,
    menuItemChoiceOptionId: o.menuItemChoiceOption.id,
  })),
});

export const getMenuOrderItemsWithAppearance = (
  menuOrderItems: readonly MenuOrderItemFormFragment[],
  menuItemAppearances: readonly {
    id: string;
    menuItem: { id: string };
    availableForOrder: boolean;
    maxAmount: number | null;
    allowSpecialRequests: boolean;
  }[]
): readonly MenuOrderItemWithAppearance[] => {
  return menuOrderItems.map(menuOrderItem => {
    const matchingAppearance = menuItemAppearances.find(mia => mia.menuItem.id === menuOrderItem.menuItem.id);
    if (matchingAppearance) {
      return {
        ...menuOrderItem,
        menuItemAppearance: {
          id: matchingAppearance.id,
          availableForOrder: matchingAppearance.availableForOrder,
          maxAmount: matchingAppearance.maxAmount,
          allowSpecialRequests: matchingAppearance.allowSpecialRequests,
        },
      };
    } else {
      // If a matching MenuItemAppearance cannot be found, that could mean that this order is out of sync
      // However for now we can fall back to the menuOrderItem.method
      return {
        ...menuOrderItem,
        menuItemAppearance: {
          id: newId(),
          availableForOrder: menuOrderItem.forOrder,
          allowSpecialRequests: false,
          maxAmount: null,
        },
      };
    }
  });
};

interface EditMenuOrderInputs {
  type: "inputs";
  addOrderItems: readonly MenuOrderItemInput[];
  addLogItems: readonly MenuOrderItemInput[];
  editItems: readonly EditMenuOrderItemInput[];
  removeItemIds: readonly string[];
  pickupTime: string;
}

export const getEditMenuOrderInputsOrErrors = (
  initialMenuOrderItems: readonly MenuOrderItemFormFragment[],
  finalMenuOrderItems: readonly MenuOrderItemWithAppearance[],
  mealMenuDiningStations: readonly MealMenuDiningStationWithItemPreviewsFragment[],
  editedMenuOrderItemIds: readonly string[],
  pickupTime: PickupTime | null
): EditMenuOrderInputs | MenuOrderErrors => {
  const initalItemIds = initialMenuOrderItems.map(i => i.id);
  const finalItemIds = finalMenuOrderItems.map(i => i.id);

  const addOrderItems = finalMenuOrderItems.filter(i => !initalItemIds.includes(i.id) && i.forOrder);
  const addLogItems = finalMenuOrderItems.filter(i => !initalItemIds.includes(i.id) && !i.forOrder);
  const editedItems = finalMenuOrderItems.flatMap(item => {
    if (editedMenuOrderItemIds.includes(item.id)) {
      const matchingInitialItem = initialMenuOrderItems.find(i => i.id === item.id);
      if (!matchingInitialItem) {
        return [];
      }
      return {
        ...item,
        initialForOrder: matchingInitialItem.forOrder,
      };
    } else {
      return [];
    }
  });
  const removedItems = initialMenuOrderItems.flatMap(item => {
    if (!finalItemIds.includes(item.id)) {
      const matchingInitialItem = initialMenuOrderItems.find(i => i.id === item.id);
      if (!matchingInitialItem) {
        return [];
      }
      return {
        id: item.id,
        initialForOrder: matchingInitialItem.forOrder,
      };
    } else {
      return [];
    }
  });

  const anyOrderItems = initialMenuOrderItems.some(moi => moi.forOrder) || finalMenuOrderItems.some(moi => moi.forOrder);

  const invalidOrder = isMenuSelectionInvalid(finalMenuOrderItems, mealMenuDiningStations);
  const editingForOrderItems =
    [...addOrderItems, ...editedItems.filter(i => i.forOrder || i.initialForOrder), ...removedItems.filter(i => i.initialForOrder)].length >
    0;
  const noItems = finalMenuOrderItems.length === 0;

  const orderBeingPrepared = reduceStatuses(initialMenuOrderItems.map(i => i.status)) !== "new";
  const orderBeingPreparedConflict = orderBeingPrepared && editingForOrderItems;
  const pickupTimeNoRemaining = pickupTime?.remaining === 0 && anyOrderItems;

  if (invalidOrder || pickupTime === null || pickupTimeNoRemaining || noItems || orderBeingPreparedConflict) {
    const error =
      pickupTime === null
        ? "Set pickup time to update order"
        : pickupTimeNoRemaining
        ? "No order spots left in time window"
        : orderBeingPreparedConflict
        ? `Can't update. Order is being prepared`
        : noItems
        ? "Add menu items to update order"
        : null;

    return {
      type: "errors",
      invalidOrder,
      error,
    };
  } else {
    return {
      type: "inputs",
      pickupTime: pickupTime.value,
      addLogItems: addLogItems.map(getMenuOrderItemInput),
      addOrderItems: addOrderItems.map(getMenuOrderItemInput),
      editItems: editedItems.map(i => ({
        menuOrderItemId: i.id,
        forOrder: !!i.forOrder,
        amount: i.amount,
        options: i.options.map(o => ({
          amount: o.amount,
          menuItemChoiceOptionId: o.menuItemChoiceOption.id,
        })),
        specialRequests: i.specialRequests,
      })),
      removeItemIds: removedItems.map(i => i.id),
    };
  }
};

interface formatPickupTimeArgs {
  pickupTime: PickupTime;
  mealMenuTimezone: string;
  clientTimezone: string;
  forLog?: boolean;
}

export const formatPickupTime = ({ pickupTime, clientTimezone, mealMenuTimezone, forLog = false }: formatPickupTimeArgs) => {
  const excludeTimezoneSuffix = clientTimezone === mealMenuTimezone;

  if (pickupTime.type === "ASAP") {
    if (pickupTime.remaining === null || forLog) {
      return `Ready in ${pickupTime.mealMenuPrepTimeInMinutes}m`;
    } else {
      return `Ready in ${pickupTime.mealMenuPrepTimeInMinutes}m (${pickupTime.remaining} left)`;
    }
  } else {
    const timePart = formatTimeInTimezone(pickupTime.value, mealMenuTimezone, {
      excludeTimezoneSuffix,
      alwaysShowMinutes: true,
    });
    if (pickupTime.remaining === null || forLog) {
      return timePart;
    } else {
      return `${timePart} (${pickupTime.remaining} left)`;
    }
  }
};

export const getPickupTimePickerLabel = (forOrder: boolean): string => {
  return forOrder ? "Pickup Time" : "When You Ate";
};

export const isPickupTimeError = (pickupTime: PickupTime | null, forOrder: boolean) => {
  return pickupTime === null || (forOrder && pickupTime.remaining === 0);
};

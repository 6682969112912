import { resetLastOrderDue } from "../Dialog/utils";
import {
  MenuChangeDurationInMinutesAction,
  MenuChangeLastOrderDaysBeforeAction,
  MenuChangeLastOrderTimeAction,
  MenuChangeStartTimeAction,
  MenuChangeTimezoneAction,
} from "../reducer";
import { TimingState } from "../types";

export const defaultTimingState = {
  startTime: "12:00:00",
  durationInMinutes: 120,
  timezone: "America/New_York",
  lastOrderDaysBefore: 0,
  lastOrderTime: "12:00:00",
  prepTimeInMinutes: 15,
};
interface ReplaceStateAction {
  type: "ReplaceStateAction";
  payload: {
    newState: TimingState;
  };
}

export type TimingChangeAction =
  | MenuChangeStartTimeAction
  | MenuChangeDurationInMinutesAction
  | MenuChangeTimezoneAction
  | MenuChangeLastOrderDaysBeforeAction
  | MenuChangeLastOrderTimeAction
  | ReplaceStateAction;

export const timingReducer = (state: TimingState, action: TimingChangeAction): TimingState => {
  switch (action.type) {
    case "MenuChangeStartTimeAction":
      return resetLastOrderDue({
        ...state,
        startTime: action.payload.startTime,
      });
    case "MenuChangeDurationInMinutesAction":
      return resetLastOrderDue({
        ...state,
        durationInMinutes: action.payload.durationInMinutes,
      });
    case "MenuChangeTimezoneAction":
      return {
        ...state,
        timezone: action.payload.timezone,
      };
    case "MenuChangeLastOrderDaysBeforeAction":
      return {
        ...state,
        lastOrderDaysBefore: action.payload.lastOrderDaysBefore,
      };
    case "MenuChangeLastOrderTimeAction":
      return {
        ...state,
        lastOrderTime: action.payload.lastOrderTime,
      };
    case "ReplaceStateAction":
      return {
        ...action.payload.newState,
      };
    default:
      return state;
  }
};

import { Box, CircularProgress, PaletteColor, Typography } from "@mui/material";
import React from "react";

interface SingleColorDialProps {
  value: number;
  total: number;
  hideNumbers?: boolean;
  variant?: "sm" | "md" | "lg";
  label: string;
  color: PaletteColor;
}

export const SingleColorDial = ({ value, total, hideNumbers, variant = "lg", label, color }: SingleColorDialProps) => {
  const formattedPercent = total > 0 ? Math.round((value / total) * 100) : 0;
  const formattedRawValue = Math.round(value);
  const insetLabel = hideNumbers ? formattedPercent + "%" : formattedRawValue + "g";
  const typographyVariant = variant === "lg" ? "body2" : variant === "md" ? "subtitle1" : "caption";
  const circularProgressProps = {
    lg: {
      thickness: 5,
      size: 90,
    },
    md: {
      thickness: 4,
      size: 60,
    },
    sm: {
      thickness: 4,
      size: 45,
    },
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "1fr" }}>
        <CircularProgress
          variant="determinate"
          sx={{ gridColumnStart: 1, gridRowStart: 1, color: color.main }}
          value={100}
          {...circularProgressProps[variant]}
        />
        <Box
          sx={{
            gridColumnStart: 1,
            gridRowStart: 1,
            width: circularProgressProps[variant].size,
            height: circularProgressProps[variant].size,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">{insetLabel}</Typography>
        </Box>
      </Box>
      <Typography sx={{ color: color.main, textAlign: "center" }} variant={typographyVariant}>
        {label}
      </Typography>
    </Box>
  );
};

import { OrderWithDiningStation, OrderWithItems } from "./types";
import { KdsTicketMenuOrderItemFragment } from "../../../types";

interface ConvertToOrderWithDiningStationItemsArgs<I extends OrderWithItems> {
  order: I;
  menuOrderItemIdsToDiningStationName: Record<string, string>;
}

export const convertToOrderWithDiningStationItems = <I extends OrderWithItems>({
  order,
  menuOrderItemIdsToDiningStationName,
}: ConvertToOrderWithDiningStationItemsArgs<I>): OrderWithDiningStation & I => {
  return {
    ...order,
    diningStationsToMenuOrderItems: order.itemsByDiningStation.reduce(
      (curr, item) => ({
        ...curr,
        [menuOrderItemIdsToDiningStationName[item.id]]: [...(curr[menuOrderItemIdsToDiningStationName[item.id]] || []), item],
      }),
      {} as { [key: string]: KdsTicketMenuOrderItemFragment[] }
    ),
  };
};

interface KDSAthlete {
  firstName: string;
  lastName: string;
  jerseyNumber: string | null;
}
export const getTicketDisplayNameForAthlete = ({ firstName, lastName, jerseyNumber }: KDSAthlete) => {
  const fullName = `${lastName}, ${firstName}`;
  if (jerseyNumber) {
    return `${fullName} (#${jerseyNumber})`;
  } else {
    return fullName;
  }
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { useForm } from "react-hook-form";
import { useRefetchQueriesContext } from "../../../contexts/RefetchQueries";
import {
  useCreateFoodServingsMutation,
  useCreateRestaurantBrandedFoodMutation,
  useEditFoodServingMutation,
  useEditRestaurantBrandedFoodMutation,
  useGramUnitQuery,
  useUnitsWithConversionsQuery,
} from "../../../types";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import { BaseFoodFormType, FoodFormSchema } from "../FoodFormSchema/FoodFormSchema";
import {
  toCreateBrandedFoodInput,
  toCreateFoodServingsInput,
  toEditBrandedFoodInput,
  toEditFoodServingInputs,
} from "../FoodFormSchema/foodSchemaToInputUtils";
import { FoodForm } from "../Form/FoodForm";

interface RestaurantFoodModalProps {
  open: boolean;
  onClose: () => void;
  initialFormState: Partial<BaseFoodFormType>;
  resetTable: () => void;
  restaurantId: string;
}

export const RestaurantFoodModal = ({ open, onClose, initialFormState, resetTable, restaurantId }: RestaurantFoodModalProps) => {
  const refetchQueries = useRefetchQueriesContext();
  const { setMessage } = useSnackbar();

  const form = useForm<BaseFoodFormType>({
    defaultValues: initialFormState,
    resolver: zodResolver(FoodFormSchema()),
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitted, isValid },
  } = form;
  const watchedId = watch("id");
  const hasFormErrors = isSubmitted && !isValid;

  const onCompleted = () => {
    resetTable();
  };

  const [createBrandedFood] = useCreateRestaurantBrandedFoodMutation({
    onError: e => setMessage("error", e.message),
    onCompleted,
  });
  const [editBrandedFood] = useEditRestaurantBrandedFoodMutation({
    onError: e => setMessage("error", e.message),
    onCompleted,
  });

  const [createServings] = useCreateFoodServingsMutation({
    refetchQueries,
    onCompleted,
    onError: e => setMessage("error", e.message),
  });
  const [editServing] = useEditFoodServingMutation({
    refetchQueries,
    onCompleted,
    onError: e => setMessage("error", e.message),
  });

  const { data: unitsData } = useUnitsWithConversionsQuery();
  const { data: gramUnit } = useGramUnitQuery();

  const handleSave = async (state: BaseFoodFormType) => {
    const { id: editFoodId } = state;

    if (hasFormErrors) {
      // try re-triggering form to force validation
      await form.trigger();
      return;
    }

    if (!editFoodId) {
      const input = toCreateBrandedFoodInput(state);
      if (!input) {
        return;
      }
      await createBrandedFood({
        variables: { input: { ...input, restaurantId } },
      });
    } else {
      const editServingInputs = toEditFoodServingInputs(state);
      const createServingsInput = toCreateFoodServingsInput(state);
      if (!createServingsInput || !editServingInputs) {
        return;
      }

      const editFoodInput = toEditBrandedFoodInput(state);
      if (!editFoodInput) {
        return;
      }
      const editFoodPromise = editBrandedFood({
        variables: {
          input: { ...editFoodInput, restaurantId },
        },
      });

      await Promise.all([
        editFoodPromise,
        createServings({
          variables: { input: createServingsInput },
        }),
        Promise.all(
          editServingInputs.map(input =>
            editServing({
              variables: { input },
            })
          )
        ),
      ]);
    }
    onClose();
  };
  return gramUnit && unitsData ? (
    <Dialog
      maxWidth="xl"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title={`${!watchedId ? "Add" : "Edit"} Food`} onClose={onClose} />
      <DialogContent>
        <FoodForm
          form={form}
          gramUnit={gramUnit.gramUnit}
          unitsData={unitsData.units} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
          <Button onClick={handleSubmit(handleSave)}>Save</Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  ) : (
    <LoadingBackdrop open={open} onClose={onClose} />
  );
};

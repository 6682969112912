import { ServingUnitsInput, ServingWithUnitsFragment, UnitFragment } from "../../types";

interface ServingUnitsState_Custom {
  customUnits: string;
  unit: null;
  unitPrefix: null;
  unitSuffix: null;
}

interface ServingUnitsState_NonCustom {
  customUnits: null;
  unit: UnitFragment;
  unitPrefix: string | null;
  unitSuffix: string | null;
}

export type ServingUnitsState = ServingUnitsState_Custom | ServingUnitsState_NonCustom;

export const getServingUnits = ({ unit, customUnits, unitPrefix, unitSuffix }: ServingWithUnitsFragment): string => {
  if (customUnits) {
    return customUnits;
  } else if (unit) {
    return `${unitPrefix || ""}${unit.name}${unitSuffix || ""}`;
  } else {
    return "";
  }
};

export const servingUnitsToState = ({ unit, customUnits, unitPrefix, unitSuffix }: ServingWithUnitsFragment): ServingUnitsState => {
  return customUnits
    ? {
        customUnits,
        unit: null,
        unitPrefix: null,
        unitSuffix: null,
      }
    : unit
    ? { unit, unitPrefix, unitSuffix, customUnits: null }
    : {
        customUnits: "",
        unit: null,
        unitPrefix: null,
        unitSuffix: null,
      };
};

export const servingUnitsToInput = ({ unit, customUnits, unitPrefix, unitSuffix }: ServingWithUnitsFragment): ServingUnitsInput => {
  return {
    customUnits,
    unitPrefix,
    unitSuffix,
    unitId: unit?.id || null,
  };
};

import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import { getMealPlanTemplateRmrErrors } from "@notemeal/shared/ui/utils/macroProtocolState";
import {
  AnthropometrySnapshot,
  getLeanBodyMass,
  measurementConversionToImperial,
  measurementConversionToImperialNonNull,
} from "@notemeal/shared/utils/macro-protocol";
import { AnthropometryFormFieldsState, AnthropometryState } from "../reducer/anthropometryReducer";
import { MacroProtocolState } from "../reducer/macroProtocolReducer";

// TODO: metric update run phase - macroProtocol.anthro to metric variable names
// TODO: metric update run phase - update mpt => macro => anthro nested states/resolvers
export const getAnthroSnapshotFromAnthroState = (state: AnthropometryState): AnthropometrySnapshot => {
  if (state.__typename === "FormFields") {
    const { formFields, sampleAge, sampleHeight, sampleWeight, samplePercentBodyFat } = state;

    const _weight = measurementConversionToImperialNonNull(true, formFields.includes("weight") ? sampleWeight : 100, "weight");

    return {
      age: sampleAge,
      height: formFields.includes("height") ? measurementConversionToImperial(true, sampleHeight, "length") : null,
      leanBodyMass: formFields.includes("percentBodyFat") ? getLeanBodyMass(_weight, samplePercentBodyFat) : null,
      percentBodyFat: formFields.includes("percentBodyFat") ? samplePercentBodyFat : null,
      sex: state.sampleSex,
      weight: _weight,
      // TODO: metric update - see above
      heightInCm: formFields.includes("height") ? sampleHeight : null,
      weightInKg: formFields.includes("weight") ? sampleWeight : 100,
      leanBodyMassInKg: formFields.includes("percentBodyFat") ? getLeanBodyMass(sampleWeight, samplePercentBodyFat) : null,
    };
  } else {
    const { sampleAge, sampleSex, height, weight, percentBodyFat } = state;

    const _weight = measurementConversionToImperialNonNull(true, weight, "weight");

    return {
      age: sampleAge,
      sex: sampleSex,
      height: measurementConversionToImperial(true, height, "length"),
      leanBodyMass: getLeanBodyMass(_weight, percentBodyFat),
      percentBodyFat,
      weight: _weight,
      // TODO: metric update - see above
      heightInCm: height,
      weightInKg: weight,
      leanBodyMassInKg: getLeanBodyMass(weight, percentBodyFat),
    };
  }
};

interface Result_Error {
  __typename: "Error";
  error: string;
}

interface Result_Payload<T> {
  __typename: "Success";
  value: T;
}

export type Result<T> = Result_Payload<T> | Result_Error;

const getCanSaveCalorieBudgetToolTips = (state: MacroProtocolState): string[] => {
  const tooltips = [];
  if (!state.usingCalorieBudget) {
    return [];
  }

  if (state.calorieBudget.goals.length === 0) {
    tooltips.push("At least one goal must be defined to use calorie budgets");
  }

  if (inputToNumber(state.calorieBudget.activityFactorInput) !== state.calorieBudget.activityFactor) {
    tooltips.push("An Activity factor must be defined");
  }

  return tooltips;
};

// TODO: Metric Hotfix Ripping out the need for equality because value can be metric and input can be imperial
const isInvalidHeight = (height: number | null, heightInput: string) => height !== null && height <= 0;
// TODO: Metric Hotfix Ripping out the need for equality because value can be metric and input can be imperial
const isInvalidWeight = (weight: number | null, weightInput: string) => weight !== null && weight <= 0;

const isInvalidPercentBodyFat = (percentBodyFat: number | null, percentBodyFatInput: string) =>
  (percentBodyFat !== null && (percentBodyFat <= 0 || percentBodyFat > 100)) || percentBodyFat !== inputToNumber(percentBodyFatInput);

const isMissingRequiredField = (state: AnthropometryState) => {
  let heightOrPercentBodyFatRequired = false;
  if (state.__typename === "FormFields") {
    if (!state.formFields.includes("height") && !state.formFields.includes("percentBodyFat")) {
      heightOrPercentBodyFatRequired = true;
    }
  } else {
    const { height, heightInput, percentBodyFat, percentBodyFatInput } = state;
    if (
      (height === null || isInvalidHeight(height, heightInput)) &&
      (percentBodyFat === null || isInvalidPercentBodyFat(percentBodyFat, percentBodyFatInput))
    ) {
      heightOrPercentBodyFatRequired = true;
    }
  }

  return heightOrPercentBodyFatRequired;
};

export const getCanSaveAnthropometryToolTips = (state: AnthropometryState) => {
  const tooltips = [];

  if (state.__typename === "Template") {
    const { height, heightInput, weight, weightInput, percentBodyFat, percentBodyFatInput } = state;

    if (isInvalidWeight(weight, weightInput)) {
      tooltips.push("Weight must be greater than 0 and defined");
    }

    if (isInvalidHeight(height, heightInput)) {
      tooltips.push("Height must be greater than 0 if it is defined");
    }

    if (isInvalidPercentBodyFat(percentBodyFat, percentBodyFatInput)) {
      tooltips.push("Percent Body Fat must be greater than 0 and less than or equal to 100 if it is defined");
    }

    if (isMissingRequiredField(state)) {
      tooltips.push("A valid default Height or default Percent Body Fat must be filled in to have an RMR Method available.");
    }
  } else {
    if (isMissingRequiredField(state)) {
      tooltips.push("Height or Percent Body Fat must be chosen in order to have an RMR Method available.");
    }
  }

  return tooltips;
};

export const getCanSaveSampleDataToolTips = (state: AnthropometryState) => {
  const {
    __typename: typeName,
    formFields,
    sampleAge,
    sampleAgeInput,
    sampleHeight,
    sampleWeight,
    samplePercentBodyFat,
    samplePercentBodyFatInput,
  } = state as AnthropometryFormFieldsState;
  const tooltips = [];

  if (sampleAge === null || sampleAge <= 12 || sampleAge !== inputToNumber(sampleAgeInput)) {
    tooltips.push("Sample Age must be greater than 12.");
  }

  if (typeName === "FormFields") {
    if (formFields.includes("height") && (sampleHeight === null || sampleHeight <= 0)) {
      tooltips.push("Sample Height must be greater than 0.");
    }
    if (formFields.includes("weight") && (sampleWeight === null || sampleWeight <= 0)) {
      tooltips.push("Sample Weight must be greater than 0.");
    }
    if (
      formFields.includes("percentBodyFat") &&
      (samplePercentBodyFat === null ||
        samplePercentBodyFat <= 0 ||
        samplePercentBodyFat > 100 ||
        samplePercentBodyFat !== inputToNumber(samplePercentBodyFatInput))
    ) {
      tooltips.push("Sample Percent Body Fat must be greater than 0 and less than or equal to 100.");
    }
  }

  return tooltips;
};

export const getCanSaveMacroProtocolToolTips = (state: MacroProtocolState): string[] => {
  const {
    anthropometry,
    calorieBudget: { rmrMethod },
  } = state;
  const tooltips = [...getCanSaveCalorieBudgetToolTips(state), ...getCanSaveAnthropometryToolTips(state.anthropometry)];

  const rmrErrors = getMealPlanTemplateRmrErrors(rmrMethod, getAnthroSnapshotFromAnthroState(anthropometry));
  if (rmrErrors.length) {
    rmrErrors.forEach(error => tooltips.push(error));
  } else {
    if (state.usingCalorieBudget && state.cho.percent + state.pro.percent + state.fat.percent !== 100) {
      tooltips.push("Macro percentages must sum to 100%");
    }
    if (state.cho.percent !== inputToNumber(state.cho.percentInput) || state.cho.gPerKg !== inputToNumber(state.cho.gPerKGInput)) {
      tooltips.push("Cho input values do not match");
    }
    if (state.pro.percent !== inputToNumber(state.pro.percentInput) || state.pro.gPerKg !== inputToNumber(state.pro.gPerKGInput)) {
      tooltips.push("Pro input values do not match");
    }
    if (state.fat.percent !== inputToNumber(state.fat.percentInput) || state.fat.gPerKg !== inputToNumber(state.fat.gPerKGInput)) {
      tooltips.push("Fat input values do not match");
    }
  }
  return tooltips;
};

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { NutritionColorIcon } from "@notemeal/shared/ui/global/Icons";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { useState } from "react";
import { TWTabGroup } from "../../componentLibrary/TWTabGroup/TWTabGroup";
import {
  CreateFoodGroupInput,
  EditFoodGroupInput,
  useCreateOrgFoodGroupMutation,
  useCreateSharedFoodGroupMutation,
  useEditOrgFoodGroupMutation,
  useEditSharedFoodGroupMutation,
  useOrgFoodGroupsTableQuery,
  useSharedFoodGroupsTableQuery,
} from "../../types";
import { useOffsetPagination } from "../../utils/pagination";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import { FoodGroupModalEdit } from "./Modal/Edit";
import { FoodGroupModalNew } from "./Modal/New";
import { FoodGroupTable, IFoodGroup } from "./Table";
import { makeUpdateCacheCreateFoodGroup, makeUpdateCacheEditFoodGroup } from "./utils";

export interface FoodGroupDashboardProps {
  isStaff?: boolean;
}

export const FoodGroupDashboard = ({ isStaff }: FoodGroupDashboardProps) => {
  const CUSTOM = "Custom Food Groups";
  const STANDARD = "Food Groups";
  const standardTab = {
    label: STANDARD,
    icon: <NutritionColorIcon />,
  };

  const [selected, setSelected] = useState(isStaff ? STANDARD : CUSTOM);
  const tabs = [CUSTOM, standardTab];
  const { setMessage } = useSnackbar();
  const [selectedFoodGroup, setSelectedFoodGroup] = useState<IFoodGroup | null>(null);
  const [foodGroupModalOpen, setFoodGroupModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const paginationHooks = useOffsetPagination();
  const { queryText = "", onChangeQueryText, limit, offset, query } = paginationHooks;
  const orgQueryResult = useOrgFoodGroupsTableQuery({
    variables: {
      query,
      input: {
        limit,
        offset,
      },
      sortOrder,
    },
  });
  const sharedQueryResult = useSharedFoodGroupsTableQuery({
    variables: { query, input: { limit, offset }, sortOrder },
  });
  const updateCacheCreateFoodGroup = makeUpdateCacheCreateFoodGroup({ scope: isStaff ? "shared" : "org" });
  const updateCacheEditFoodGroup = makeUpdateCacheEditFoodGroup({ scope: isStaff ? "shared" : "org" });
  const [createOrgFoodGroup] = useCreateOrgFoodGroupMutation({});
  const [editOrgFoodGroup] = useEditOrgFoodGroupMutation({});
  const [createSharedFoodGroup] = useCreateSharedFoodGroupMutation();
  const [editSharedFoodGroup] = useEditSharedFoodGroupMutation({});
  const createFoodGroup = isStaff ? createSharedFoodGroup : createOrgFoodGroup;
  const editFoodGroup = isStaff ? editSharedFoodGroup : editOrgFoodGroup;

  const loading =
    selected === CUSTOM ? !orgQueryResult.data || !!orgQueryResult.loading : !sharedQueryResult.data || !!sharedQueryResult.loading;
  const foodGroups =
    selected === CUSTOM
      ? orgQueryResult.data?.orgFoodGroupsOffsetConnection.edges || []
      : sharedQueryResult.data?.sharedFoodGroupsOffsetConnection.edges || [];
  const total =
    selected === CUSTOM
      ? orgQueryResult.data?.orgFoodGroupsOffsetConnection.pageInfo.total || 0
      : sharedQueryResult.data?.sharedFoodGroupsOffsetConnection.pageInfo.total || 0;

  const handleSetSelected = (selected: string) => {
    onChangeQueryText("");
    setSelected(selected);
  };

  const onCreateFoodGroup = async ({ name, source, foodIds }: CreateFoodGroupInput) => {
    const input = {
      name,
      source,
      foodIds,
    };
    createFoodGroup({
      variables: { input },
      update: (cache, mutationResult) => {
        updateCacheCreateFoodGroup({ cache, mutationResult });
        setMessage("success", `Success! Created Food Group '${name}.'`);
        setSelectedFoodGroup(null);
        setFoodGroupModalOpen(false);
      },
    });
  };

  const onEditFoodGroup = async ({ id, foodGroup }: EditFoodGroupInput) => {
    const input = {
      id,
      foodGroup,
    };
    editFoodGroup({
      variables: { input },
      update: (cache, mutationResult) => {
        updateCacheEditFoodGroup({ cache, mutationResult });
        setMessage("success", `Success! Updated Food Group '${foodGroup.name}.'`);
        setSelectedFoodGroup(null);
        setFoodGroupModalOpen(false);
      },
    });
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <Box>{!isStaff && <TWTabGroup tabs={tabs} onSelected={handleSetSelected} />}</Box>
        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", alignItems: "flex-end" }}>
          <TextField
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={queryText}
            onChange={e => onChangeQueryText(e.target.value)}
          />
          <Button disabled={selected === STANDARD && !isStaff} onClick={() => setFoodGroupModalOpen(true)}>
            Add Food Group
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <>
          <FoodGroupTable
            readOnly={selected === CUSTOM || isStaff}
            setFoodGroupModalOpen={setFoodGroupModalOpen}
            setSelectedFoodGroup={setSelectedFoodGroup}
            rows={[...foodGroups]}
            paginationHooks={paginationHooks}
            total={total}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
          />
          {foodGroupModalOpen &&
            (selectedFoodGroup ? (
              <FoodGroupModalEdit
                readOnly={selected === STANDARD && !isStaff}
                open={!!foodGroupModalOpen}
                onClose={() => {
                  setSelectedFoodGroup(null);
                  setFoodGroupModalOpen(false);
                }}
                onEditFoodGroup={onEditFoodGroup}
                foodGroupId={selectedFoodGroup.id}
              />
            ) : (
              <FoodGroupModalNew
                open={!!foodGroupModalOpen}
                onClose={() => {
                  setSelectedFoodGroup(null);
                  setFoodGroupModalOpen(false);
                }}
                onCreateFoodGroup={onCreateFoodGroup}
              />
            ))}
        </>
      )}
    </Box>
  );
};

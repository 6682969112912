import { Dialog } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { MenuItemAppearanceFormFragment } from "../../../types";
import MenuItemReadonlyDialogContent from "../../MenuItem/Form/ReadonlyForm";

interface MenuItemAppearanceReadonlyDialogContentProps {
  open: boolean;
  onClose: () => void;
  menuItemAppearance: MenuItemAppearanceFormFragment;
}

const MenuItemAppearanceReadonlyDialogContent = ({ open, onClose, menuItemAppearance }: MenuItemAppearanceReadonlyDialogContentProps) => {
  const { menuItem } = menuItemAppearance;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth>
      <DialogTitle title={menuItem.isOneOff ? "One-off Menu Item" : "Reusable Menu Item"} onClose={onClose} />
      <MenuItemReadonlyDialogContent menuItem={menuItem} />
    </Dialog>
  );
};

export default MenuItemAppearanceReadonlyDialogContent;

import { Orientation } from ".";

export type ATHLETE_PLATE_TYPE = "easy" | "moderate" | "hard";

export interface ExportPdfState {
  orientation: Orientation;
  condensed: boolean;

  displayRecipes: boolean;
  displayFoodGroups: boolean;

  displayTitle: boolean;
  expandRecipes: boolean;
  expandExchanges: boolean;
  displaySecondaryExchanges: boolean;
}

export const createInitialExportFormState = (): ExportPdfState => {
  return {
    orientation: "portrait",
    condensed: false,
    displayRecipes: true,
    displayFoodGroups: true,

    displaySecondaryExchanges: true,
    displayTitle: true,
    expandRecipes: true,
    expandExchanges: false,
  };
};

interface SetOrientation {
  type: "SET_ORIENTATION";
  payload: Orientation;
}

interface SetCondensed {
  type: "SET_CONDENSED";
  payload: boolean;
}

interface SetDisplayRecipes {
  type: "SET_DISPLAY_RECIPES";
  payload: boolean;
}

interface SetDisplayFoodGroups {
  type: "SET_DISPLAY_FOOD_GROUPS";
  payload: boolean;
}

interface SetExpandExchanges {
  type: "SET_EXPAND_EXCHANGES";
  payload: boolean;
}

interface ToggleDisplaySecondaryExchanges {
  type: "TOGGLE_DISPLAY_SECONDARY_EXCHANGES";
  payload: boolean;
}

interface SetExpandRecipes {
  type: "SET_EXPAND_RECIPES";
  payload: boolean;
}

interface SetDisplayTitle {
  type: "SET_DISPLAY_TITLE";
  payload: boolean;
}

export type ExportPdfAction =
  | SetOrientation
  | SetDisplayRecipes
  | SetDisplayFoodGroups
  | SetExpandRecipes
  | SetDisplayTitle
  | ToggleDisplaySecondaryExchanges
  | SetCondensed
  | SetExpandExchanges;

export const exportFormReducer = (state: ExportPdfState, action: ExportPdfAction): ExportPdfState => {
  switch (action.type) {
    case "SET_EXPAND_EXCHANGES":
      return {
        ...state,
        expandExchanges: action.payload,
      };
    case "SET_CONDENSED":
      return {
        ...state,
        condensed: action.payload,
        displayRecipes: false,
        displayFoodGroups: false,
        expandExchanges: true,
      };
    case "SET_ORIENTATION":
      return { ...state, orientation: action.payload };
    case "SET_DISPLAY_RECIPES":
      return { ...state, displayRecipes: action.payload };
    case "SET_DISPLAY_FOOD_GROUPS":
      return { ...state, displayFoodGroups: action.payload };
    case "SET_EXPAND_RECIPES":
      return { ...state, expandRecipes: action.payload };
    case "SET_DISPLAY_TITLE":
      return { ...state, displayTitle: action.payload };
    case "TOGGLE_DISPLAY_SECONDARY_EXCHANGES":
      return { ...state, displaySecondaryExchanges: action.payload };
  }
};

export const getCanSaveTooltips = (state: ExportPdfState) => {
  let tooltips: string[] = [];
  return tooltips;
};

import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useDraggable } from "@notemeal/shared/ui/hooks/useDraggable";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import { animated } from "react-spring";
import { MenuItemChoiceOptionFormFragment } from "../../../types";
import OptionListItem from "../OptionListItem";
import { OptionListWrapper } from "../utils";

interface MenuItemChoiceOptionListProps {
  options: readonly MenuItemChoiceOptionFormFragment[];
  selectedOptionId: string | null;
  exclusive: boolean;
  onAddOption: () => void;
  onChangeOrder: (ids: string[]) => void;
  onSelectOption: (id: string) => void;
  onRemoveOption: (id: string) => void;
}

const MenuItemChoiceOptionList = ({
  onAddOption,
  onChangeOrder,
  onSelectOption,
  onRemoveOption,
  options: _options,
  selectedOptionId,
  exclusive,
}: MenuItemChoiceOptionListProps) => {
  const options = sortByKey(_options, "position");
  const draggableOptions = options.map(({ id }, index) => ({
    id,
    index,
    height: 50,
  }));
  const { springs, bind } = useDraggable(draggableOptions, onChangeOrder);

  return (
    <OptionListWrapper>
      {options.map(o => {
        const springObject = draggableOptions.find(({ id }) => id === o.id);
        if (!springObject) {
          return null;
        }

        const spring = springs[springObject.index];
        const bindProps = bind(springObject.index);

        if (!spring || !bindProps) {
          return null;
        }

        const { zIndex, y } = spring;

        return (
          <animated.div
            key={o.id}
            style={{
              zIndex,
              transform: y.to(y => `translate3d(0, ${y}px, 0)`),
            }}
          >
            <OptionListItem
              name={o.name}
              onSelect={() => onSelectOption(o.id)}
              onRemove={() => onRemoveOption(o.id)}
              servingAmounts={o.servingAmounts}
              selected={selectedOptionId === o.id}
              exclusive={exclusive}
              bindProps={bindProps}
              choiceCounter={o.position}
            />
          </animated.div>
        );
      })}
      <Button
        variant="outlined"
        onClick={onAddOption}
        startIcon={<AddIcon />}>
        Add Choice
      </Button>
    </OptionListWrapper>
  );
};

export default MenuItemChoiceOptionList;

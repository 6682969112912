import { Alert, Box, Button, Typography } from "@mui/material";
import { common } from "@mui/material/colors";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import WarningIcon from "apps/web/src/components/universal/WarningIcon";
import { trackEvent } from "apps/web/src/reporting/reporting";
import { useForceLockPlannedMenuMutation } from "apps/web/src/types";
import { format, formatDistanceToNow } from "date-fns";
import React, { useState } from "react";
import { useMenuBuilderContext } from "./MenuBuilderProvider";

export const MenuBuilderLockBanner = () => {
  const { mealGridWidth, lockCreatedAt, lockCreatedBy, menuId, menuName, isEditable, menuLastEditedAt } = useMenuBuilderContext();
  const [takeEditingAccessDialogOpen, setTakeEditingAccessDialogOpen] = useState(false);
  const { setMessage } = useSnackbar();

  const [forceLockPlannedMenu] = useForceLockPlannedMenuMutation({
    variables: { input: { plannedMenuId: menuId } },
    refetchQueries: ["PlannedMenuGrid"],
    onCompleted: () => {
      trackEvent("Nutrition | Kitchen | Menu Builder | User Force Took Edit Access", { menuId });
      setMessage("success", `Gained access to edit ${menuName}`);
    },
    onError: error => {
      setMessage("error", "Something went wrong taking editing access. Please try again.");
      console.error("failed to force acquire lock, got error:", error);
    },
  });

  const lastSavedTime = menuLastEditedAt ? format(new Date(menuLastEditedAt), "h:mm a") : "";
  const lastSavedMinutesAgo = menuLastEditedAt ? formatDistanceToNow(new Date(menuLastEditedAt)) : "";
  const lockCreatedMinutesAgo = lockCreatedAt ? formatDistanceToNow(new Date(lockCreatedAt)) : "";
  const lockCreatedByName = lockCreatedBy ? `${lockCreatedBy.firstName} ${lockCreatedBy.lastName}` : "";

  return (
    <>
      {lockCreatedAt && !isEditable && (
        <>
          <Alert
            icon={<WarningIcon tip="" />}
            sx={{ mb: 5, maxWidth: mealGridWidth }}
            severity="warning">
            <Typography variant={"body2Semibold"}>Menu editing in progress</Typography>
            <Typography sx={{ mb: 2, mt: 1 }} variant={"body2"}>
              {lockCreatedByName} has been editing this menu for {lockCreatedMinutesAgo}. Please note that you can either take over the
              editing access or continue to view the menu by refreshing the page.
            </Typography>
            <Button
              sx={{ mr: 1 }}
              variant="containedWarning"
              onClick={() => setTakeEditingAccessDialogOpen(true)}>
              Take Editing Access
            </Button>
            <Button
              variant="outlined"
              sx={{ backgroundColor: common.white }}
              onClick={() => window.location.reload()}>
              Refresh Page
            </Button>
          </Alert>

          <ConfirmationDialog
            open={takeEditingAccessDialogOpen}
            title="Take Editing Access"
            variant="containedDestructive"
            confirmLabel="Yes"
            message={
              <Box>
                <Typography paragraph={true}>
                  {menuName} is currently being edited. Taking over the editing access will result in {lockCreatedByName}{" "}
                  <strong>losing all their work.</strong> Are you sure you want to proceed?
                </Typography>
                <Typography>
                  Current editor: {lockCreatedByName}
                  <br />
                  Editing time: {lockCreatedMinutesAgo}
                  <br />
                  {menuLastEditedAt && `Last saved: ${lastSavedTime} (${lastSavedMinutesAgo} ago)`}
                </Typography>
              </Box>
            }
            onCancel={() => {
              setTakeEditingAccessDialogOpen(false);
            }}
            onConfirm={() => {
              setTakeEditingAccessDialogOpen(false);
              forceLockPlannedMenu();
            }}
          />
        </>
      )}
    </>
  );
};

import { TableCell, TableRow } from "@mui/material";
import TablePage from "apps/web/src/components/universal/TablePage";
import TablePageDefaultHeader from "apps/web/src/components/universal/TablePage/DefaultHeader";
import { PaginationHooks } from "apps/web/src/utils/pagination";
import React from "react";
import { StaffDashboardUsersQuery } from "../../../types";

interface UsersTableProps {
  offsetConnectionData?: StaffDashboardUsersQuery;
  loading: boolean;
  paginationHooks: PaginationHooks;
}

const UsersTable = ({ offsetConnectionData, loading, paginationHooks }: UsersTableProps) => {
  const { queryText, onChangeQueryText } = paginationHooks;
  const header = (
    <TablePageDefaultHeader
      title="User"
      searchTip="Search for users by first name, last name, email, username, or phone number"
      queryText={queryText}
      onChangeQueryText={onChangeQueryText}
    />
  );

  const TableHeaderRow = () => (
    <TableRow>
      <TableCell>First Name</TableCell>
      <TableCell>Last Name</TableCell>
      <TableCell>Username</TableCell>
      <TableCell>Email</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell>Organizations</TableCell>
      <TableCell>User Roles</TableCell>
      <TableCell>Active?</TableCell>
      <TableCell>Teamworks?</TableCell>
    </TableRow>
  );

  const tableBodyRows = offsetConnectionData
    ? offsetConnectionData.staffUserSearchOffsetConnection.edges.flatMap(user =>
        // For each org, create a new row. If no orgs, create a single row for user with empty cells for org-related info
        user.orgInfo.length > 0
          ? user.orgInfo.map(orgInfoObj => (
              <TableRow key={`${user.id}-${orgInfoObj.org.id}`}>
                <TableCell scope="row">{user.firstName}</TableCell>
                <TableCell scope="row">{user.lastName}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{orgInfoObj.org.name}</TableCell>
                <TableCell>{orgInfoObj.orgMembership?.roles.join(", ")}</TableCell>
                <TableCell>{orgInfoObj.orgMembership?.isActive && "✓"}</TableCell>
                <TableCell>{orgInfoObj.orgMembership?.teamworksId && "✓"}</TableCell>
              </TableRow>
            ))
          : [
              <TableRow key={user.id}>
                <TableCell scope="row">{user.firstName}</TableCell>
                <TableCell scope="row">{user.lastName}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>,
            ]
      )
    : null;

  return (
    <TablePage
      header={header}
      tableHeaderRow={<TableHeaderRow />}
      tableBodyRows={tableBodyRows}
      loading={loading}
      paginationHooks={paginationHooks}
      total={offsetConnectionData?.staffUserSearchOffsetConnection.pageInfo.total || 0}
    />
  );
};

export default UsersTable;

import { Workbook, Worksheet } from "exceljs";
import { parseCsv } from "./parse";

interface IUpload {
  file: File;
  onError: (msg: string) => void;
}

const readFile = async (file: File, onError: (msg: string) => void): Promise<ArrayBuffer | null> => {
  try {
    const result = (await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        if (!fileReader.result) {
          onError("Something went wrong reading file!");
          reject();
        }
        resolve(fileReader.result as ArrayBuffer);
      };
      fileReader.readAsArrayBuffer(file);
    })) as ArrayBuffer;
    return result;
  } catch (e) {
    if (e instanceof Error) {
      onError(e.message);
    } else {
      onError("Something went wrong!");
    }
    return null;
  }
};

const readFileAsText = async (file: File, onError: (msg: string) => void): Promise<string | null> => {
  try {
    const result = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        if (!fileReader.result) {
          onError("Something went wrong reading file!");
          reject();
        }
        resolve(fileReader.result as string);
      };
      fileReader.readAsText(file);
    });
    return result as string;
  } catch (e) {
    if (e instanceof Error) {
      onError(e.message);
    } else {
      onError("Something went wrong!");
    }
    return null;
  }
};

export const loadWorkbook = async <T>({ file, onError }: IUpload): Promise<Worksheet | null> => {
  const result = await readFile(file, onError);
  if (result) {
    const blankWorkbook = new Workbook();
    const workbook = await blankWorkbook.xlsx.load(result);
    // https://stackoverflow.com/questions/35789498/new-typescript-1-8-4-build-error-build-property-result-doe  s-not-exist-on-t
    if (workbook.worksheets.length > 1) {
      onError(`Excel file should have exactly 1 'sheet'. Found sheets:
        [${workbook.worksheets.map(w => "'" + w.name + "' ")}]`);
      return null;
    }
    if (workbook.worksheets.length === 0) {
      onError(`Excel file should have exactly 1 'sheet'. None found!`);
      return null;
    }
    const worksheet = workbook.worksheets[0];
    if (worksheet.rowCount === 0) {
      onError("Empty excel sheet.");
      return null;
    }
    if (worksheet.rowCount === 1) {
      onError("No data, only headers provided.");
      return null;
    }
    return worksheet;
  } else {
    onError("FileReader never finished reading file!");
    return null;
  }
};

export const loadTsv = async <T>({ file, onError }: IUpload): Promise<{ [key in keyof T]: string }[] | null> => {
  const fileData = await readFileAsText(file, onError);
  if (fileData) {
    try {
      const unparsedRows = fileData.split("\n");
      const headers = unparsedRows[0].split("\t");
      // Remove last row, as it's just an empty line
      const rows = unparsedRows.slice(1, unparsedRows.length - 1).map((r, rowIdx) =>
        r.split("\t").reduce((row, val, idx) => {
          const headerKey = headers[idx].trim();
          if (!headerKey) {
            console.warn(`Row '${rowIdx}' has extra data '${val}' in column '${idx}'`);
            return row;
          }
          return { ...row, [headerKey]: val.trim() };
        }, {} as { [key in keyof T]: string })
      );
      return rows;
    } catch (e) {
      if (e instanceof Error) {
        onError(e.message);
      } else {
        onError("Something went wrong!");
      }
      return null;
    }
  } else {
    onError("FileReader never finished reading file!");
    return null;
  }
};

export const loadCsv = async <T>({ file, onError }: IUpload, delimiter = ","): Promise<{ [key in keyof T]: string }[] | null> => {
  const fileData = await readFileAsText(file, onError);
  if (fileData) {
    try {
      const parsed = parseCsv(fileData, delimiter);
      const headers = parsed[0] as (keyof T)[];
      const rows = parsed.slice(1, parsed.length);
      return rows.map(row => {
        return headers.reduce(
          (rowObj, header, i) => ({
            ...rowObj,
            [header]: row[i],
          }),
          {} as { [key in keyof T]: string }
        );
      });
    } catch (e) {
      if (e instanceof Error) {
        onError(e.message);
      } else {
        onError("Something went wrong!");
      }
      return null;
    }
  } else {
    onError("FileReader never finished reading file!");
    return null;
  }
};

import React from "react";
import { OrderPageMealMenuDiningStationFragment, OrderPageMenuOrderFragment } from "../../../types";
import { menuOrderItemIdsToDiningStationName } from "../../../utils/menuItems";
import MenuOrdersTable from "./Table/MenuOrdersTable";

interface OrdersContentProps {
  allOrders: readonly OrderPageMenuOrderFragment[];
  mealMenuDiningStations: readonly (OrderPageMealMenuDiningStationFragment & {
    mealMenuId: string;
  })[];
}

const OrdersContent = ({ allOrders, mealMenuDiningStations }: OrdersContentProps) => {
  const tableRows = allOrders
    .flatMap(mo => mo.items)
    .filter(i => i.forOrder)
    .map(i => ({ ...i }));
  const itemIdsToDiningStationName = menuOrderItemIdsToDiningStationName(
    mealMenuDiningStations,
    allOrders.map(o => ({ ...o, itemsByDiningStation: o.items }))
  );

  return <MenuOrdersTable tableRows={tableRows} itemIdsToDiningStationName={itemIdsToDiningStationName} />;
};

export default OrdersContent;

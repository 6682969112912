import CheckIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PrintIcon from "@mui/icons-material/Print";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Tv from "@mui/icons-material/Tv";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  Popover,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import Loading from "@notemeal/shared/ui/global/Loading";
import { formatTimeRangeInTimezone, serializeDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import { round } from "@notemeal/shared/ui/utils/numbers";
import { sortByFn, sortByKey } from "@notemeal/utils/sort";
import { getMenuAttendancePath } from "apps/web/src/pages/Auth/Org/MenuAttendance/MenuAttendanceRouter";
import { addMinutes } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import MealMenuIdentityChip from "../../../../components/MealMenuIdentity/Chip";
import { MealMenuModalFragment, useMealMenuPaperQuery, useSendMealMenuNotificationMutation } from "../../../../types";
import { ViewMenuOrdersDialog } from "../../../Orders/ViewMenuOrdersDialog";
import MealMenuDialogReadonly from "../../Dialog/Readonly";
import IngredientsAnalyzerDialog from "../../IngredientsAnalyzer/Dialog";
import MissingOrdersDialog from "../../MissingOrders/Dialog";
import { useBulkOrderExport } from "../../OrderExport/useBulkOrderExport";
import SuggestionsPreviewDialog from "../../SuggestionsPreview/Dialog";
import { MealMenuInstance, MenuDialogState, StandaloneMenuDialogState } from "../../types";
import { getMenuDialogStateForCopy, getMenuDialogStateForEdit } from "../../utils";
import ShareContent from "../ShareContent";
import { PopoverListItem, StyledDivider } from "./PopoverListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(1, 1, 0, 0),
    },
    secondaryText: {
      color: theme.palette.text.secondary,
    },
    identityChip: {
      marginLeft: theme.spacing(),
    },
  })
);

const HIDE_TEAMS_AFTER = 3;
const POPOVER_MIN_WIDTH = 400;

interface MenusCalendarEventDefaultPopoverProps {
  mealMenu: MealMenuInstance;
  anchorEl: HTMLElement;
  onClose: () => void;
  onOpenMenuDialog: (menuDialogState: MenuDialogState) => void;
  clientTimezone: string;
  onOpenDigitalDisplaysDialog: (mealMenuId: string) => void;
}

export const MenusCalendarEventDefaultPopover = ({
  mealMenu,
  anchorEl,
  onClose,
  onOpenMenuDialog,
  clientTimezone,
  onOpenDigitalDisplaysDialog,
}: MenusCalendarEventDefaultPopoverProps) => {
  const classes = useStyles();
  const [viewMenuOrdersOpen, setViewMenuOrdersOpen] = useState(false);
  const { formatDateInTimezoneWithLocale, formatEventDatetimeWithLocale } = useDateFormatting();
  const [missingOrdersOpen, setMissingOrdersOpen] = useState(false);
  const [ingredientsAnalyzerOpen, setIngredientsAnalyzerOpen] = useState(false);
  const [suggestionsPreviewOpen, setSuggestionsPreviewOpen] = useState(false);
  const [readonlyMealMenuForModal, setReadonlyMealMenuForModal] = useState<StandaloneMenuDialogState | null>(null);
  const [openMoreActions, setOpenMoreActions] = useState(false);
  const moreActionsRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const mealMenuId = mealMenu.id;
  const { data, loading } = useMealMenuPaperQuery({
    variables: {
      mealMenuId: mealMenu.id,
    },
    fetchPolicy: "network-only",
  });

  const [waitingToOpen, setWaitingToOpen] = useState<"edit" | "copy" | "readonly" | "export" | null>(null);
  useEffect(() => {
    if (data && waitingToOpen) {
      setWaitingToOpen(null);
      if (waitingToOpen === "copy") {
        handleOpenDialogForCopy(data.mealMenu);
      } else if (waitingToOpen === "edit") {
        handleOpenDialogForEdit(data.mealMenu);
      } else if (waitingToOpen === "readonly") {
        handleOpenDialogForReadonly(data.mealMenu);
      }
    }
  }, [data, waitingToOpen]);

  const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);
  const [sendMealMenuNotification] = useSendMealMenuNotificationMutation({
    variables: { mealMenuId },
  });

  const handleOpenDialogForEdit = (mealMenuForModal: MealMenuModalFragment) => {
    onClose();
    onOpenMenuDialog(
      getMenuDialogStateForEdit(
        mealMenu,
        mealMenuForModal.allOrders,
        mealMenuForModal.mealMenuDiningStations,
        mealMenuForModal.restaurantMenuLinks,
        mealMenuForModal.advancedSelection
      )
    );
  };

  const handleOpenDialogForCopy = (mealMenuForModal: MealMenuModalFragment) => {
    onClose();
    onOpenMenuDialog(
      getMenuDialogStateForCopy(
        mealMenu,
        mealMenuForModal.mealMenuDiningStations,
        mealMenuForModal.restaurantMenuLinks,
        mealMenuForModal.advancedSelection
      )
    );
  };

  const handleOpenDialogForReadonly = (mealMenuForModal: MealMenuModalFragment) => {
    setReadonlyMealMenuForModal(
      getMenuDialogStateForEdit(
        mealMenu,
        mealMenuForModal.allOrders,
        mealMenuForModal.mealMenuDiningStations,
        mealMenuForModal.restaurantMenuLinks,
        mealMenuForModal.advancedSelection
      )
    );
  };

  const { onExport, loading: exportLoading } = useBulkOrderExport(mealMenu.name, mealMenu.timezone, mealMenu.start);

  const tryOpenDialogForEdit = () => {
    if (data) {
      handleOpenDialogForEdit(data.mealMenu);
    } else {
      setWaitingToOpen("edit");
    }
  };

  const tryOpenDialogForCopy = () => {
    if (data) {
      handleOpenDialogForCopy(data.mealMenu);
    } else {
      setWaitingToOpen("copy");
    }
  };

  const tryOpenDialogForReadonly = () => {
    if (data) {
      handleOpenDialogForReadonly(data.mealMenu);
    } else {
      setWaitingToOpen("readonly");
    }
  };

  const athleteCount = mealMenu.athleteCount;

  const popoverOptions = {
    excludeTimezoneSuffix: true,
    alwaysShowMinutes: true,
  };
  const mealMenuStartToEndForPopover = `${formatDateInTimezoneWithLocale(mealMenu.start, clientTimezone)}, ${formatTimeRangeInTimezone(
    mealMenu.start,
    mealMenu.durationInMinutes,
    clientTimezone,
    popoverOptions
  )}`;
  const lastOrderDateTime = addMinutes(mealMenu.start, mealMenu.durationInMinutes - mealMenu.lastOrderTimeBeforeEndInMinutes);
  const lastOrderForPopover = `${formatEventDatetimeWithLocale(lastOrderDateTime, { timeZone: clientTimezone })}`;
  const hasRestaurantMenus = useHasFeature("hasRestaurantMenus");
  const hasBulkOrdering = useHasFeature("bulkOrdering");
  const hasDigitalDisplays = useHasFeature("digitalDisplays");
  const showRestaurants = hasRestaurantMenus && mealMenu.restaurantMenuLinks.length > 0;
  const showDiningStations = !showRestaurants || mealMenu.mealMenuDiningStations.length > 0;

  return (
    <>
      <Popover
        open
        anchorEl={anchorEl}
        onClose={onClose}
        transformOrigin={{
          vertical: "center",
          horizontal: POPOVER_MIN_WIDTH,
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: POPOVER_MIN_WIDTH,
              maxWidth: POPOVER_MIN_WIDTH,
            },
          },
        }}
      >
        <Box
          sx={({ spacing }) => ({
            display: "flex",
            justifyContent: "flex-end",
            padding: spacing(1.5),
            paddingBottom: 0,
          })}
        >
          <Tooltip title="Edit">
            {waitingToOpen !== "edit" ? (
              <IconButton
                sx={({ spacing }) => ({
                  marginLeft: spacing(),
                })}
                onClick={tryOpenDialogForEdit}
                size="small"
                aria-label="Edit menu"
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Box sx={{ minWidth: 26, paddingLeft: 6, paddingRight: 6 }}>
                <Loading progressSize="xs" />
              </Box>
            )}
          </Tooltip>

          <Tooltip title="Copy">
            {waitingToOpen !== "copy" ? (
              <IconButton
                sx={({ spacing }) => ({
                  marginLeft: spacing(),
                })}
                onClick={tryOpenDialogForCopy}
                size="small"
                aria-label="Copy menu"
              >
                <FileCopyIcon />
              </IconButton>
            ) : (
              <Box sx={{ minWidth: 26, paddingLeft: 6, paddingRight: 6 }}>
                <Loading progressSize="xs" />
              </Box>
            )}
          </Tooltip>
          <Tooltip title="More actions">
            <IconButton
              ref={moreActionsRef}
              aria-label="More actions"
              size="small"
              sx={({ spacing }) => ({
                marginLeft: spacing(),
              })}
              onClick={() => setOpenMoreActions(true)}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            sx={({ spacing }) => ({
              marginLeft: spacing(),
            })}
            onClick={onClose}
            size="small"
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={({ spacing }) => ({
            padding: spacing(1.5),
            paddingTop: spacing(0.5),
          })}
        >
          {mealMenu.isOrderAndLogRestricted && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: 1, mb: 2 }}>
              * Ordering and logging restricted to dietitians
            </Typography>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{mealMenu.name}</Typography>
            {mealMenu.isHubCheckInEnabled && (
              <Chip
                size="small"
                label="Check-in Enabled"
                color="success"
                className={classes.identityChip} />
            )}
            {mealMenu.identity && (
              <MealMenuIdentityChip
                size="small"
                className={classes.identityChip}
                mealMenuIdentity={mealMenu.identity} />
            )}
          </Box>
          <Typography color="textSecondary">{mealMenuStartToEndForPopover}</Typography>

          {showDiningStations && (
            <Box
              sx={({ spacing }) => ({
                margin: spacing(1, 0),
              })}
            >
              <Typography fontSize="14px" fontWeight="medium">
                Dining Stations
              </Typography>
              {sortByKey(mealMenu.mealMenuDiningStations, "position").map(mmds => (
                <Chip
                  size="small"
                  key={mmds.id}
                  label={mmds.name}
                  sx={({ spacing }) => ({
                    margin: spacing(1, 1, 0, 0),
                  })}
                />
              ))}
            </Box>
          )}

          {showRestaurants && (
            <Box
              sx={({ spacing }) => ({
                margin: spacing(1, 0),
              })}
            >
              <Typography>Restaurants</Typography>
              {sortByFn(mealMenu.restaurantMenuLinks, l => l.restaurant.name).map(l => (
                <Chip
                  size="small"
                  key={l.id}
                  label={l.restaurant.name}
                  sx={({ spacing }) => ({
                    margin: spacing(1, 1, 0, 0),
                  })}
                />
              ))}
            </Box>
          )}
          <Typography fontSize="14px" fontWeight="medium">
            Order Details
          </Typography>
          <Typography fontSize="14px">
            Orders Due: <span className={classes.secondaryText}>{lastOrderForPopover}</span>
          </Typography>
          {athleteCount > 0 && (
            <>
              <Typography fontSize="14px">
                Orders Placed:
                <span className={classes.secondaryText}>
                  {` ` + mealMenu.athleteOrderCount}/{athleteCount} ({round((mealMenu.athleteOrderCount / athleteCount) * 100, 0)}
                  %)
                  {mealMenu.nonAthleteOrderCount > 0 && ` - (+${mealMenu.nonAthleteOrderCount} admin orders)`}
                </span>
              </Typography>
            </>
          )}

          <Box
            sx={({ spacing }) => ({
              margin: spacing(1, 0),
            })}
          >
            <ShareContent
              mealMenu={mealMenu}
              advancedSelection={data?.mealMenu.advancedSelection}
              loading={loading}
              teamChipClassName={classes.chip}
              maxTeamChips={HIDE_TEAMS_AFTER}
            />
          </Box>
        </Box>
      </Popover>
      {openMoreActions && (
        <Popover
          open={openMoreActions}
          anchorEl={moreActionsRef.current}
          onClose={onClose}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <List>
              <PopoverListItem
                onClick={tryOpenDialogForReadonly}
                icon={<PrintIcon />}
                copy="Print" />
              {hasDigitalDisplays && (
                <PopoverListItem
                  onClick={() => onOpenDigitalDisplaysDialog(mealMenu.id)}
                  icon={<Tv />}
                  copy="Create menu display" />
              )}
              <StyledDivider />
              <PopoverListItem
                onClick={() => setNotificationsDialogOpen(true)}
                icon={<NotificationsIcon />}
                copy="Send notifications" />
              <StyledDivider />

              {
                // this check trickles down from the featureFlag check
                mealMenu.isHubCheckInEnabled && (
                  <PopoverListItem
                    onClick={() => navigate(getMenuAttendancePath(mealMenu.id))}
                    icon={<CheckIcon />}
                    copy="View Attendance Report"
                  />
                )
              }
              <PopoverListItem
                onClick={() => setViewMenuOrdersOpen(true)}
                icon={<RestaurantIcon />}
                copy="View menu orders" />
              <PopoverListItem
                onClick={() => setMissingOrdersOpen(true)}
                icon={<PeopleAltIcon />}
                copy="View missing orders" />
              <PopoverListItem
                onClick={() => setSuggestionsPreviewOpen(true)}
                icon={<EmojiObjectsIcon />}
                copy="View suggestions Preview"
              />
              <PopoverListItem
                onClick={() => setIngredientsAnalyzerOpen(true)}
                icon={<TrendingUpIcon />}
                copy="Analyze ingredients" />

              {hasBulkOrdering && (
                <PopoverListItem
                  onClick={() => onExport(mealMenuId)}
                  icon={<DownloadIcon />}
                  copy="Export bulk orders"
                  disabled={exportLoading}
                />
              )}
            </List>
          </Box>
        </Popover>
      )}
      {notificationsDialogOpen && (
        <Dialog open={notificationsDialogOpen} onClose={() => setNotificationsDialogOpen(false)}>
          <DialogTitle>Manually Send Menu Available Notifications?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              All athletes who have not placed an order will get a notification that this menu is available for ordering.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setNotificationsDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendMealMenuNotification();
                setNotificationsDialogOpen(false);
              }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {viewMenuOrdersOpen && (
        <ViewMenuOrdersDialog
          open={viewMenuOrdersOpen}
          onClose={() => setViewMenuOrdersOpen(false)}
          mealMenuId={mealMenu.id}
          mealMenuStart={serializeDateTime(mealMenu.start)}
        />
      )}
      {missingOrdersOpen && (
        <MissingOrdersDialog
          open={missingOrdersOpen}
          onClose={() => setMissingOrdersOpen(false)}
          mealMenuId={mealMenu.id} />
      )}
      {ingredientsAnalyzerOpen && (
        <IngredientsAnalyzerDialog
          mealMenuId={mealMenuId}
          open={ingredientsAnalyzerOpen}
          onClose={() => setIngredientsAnalyzerOpen(false)}
        />
      )}
      {suggestionsPreviewOpen && (
        <SuggestionsPreviewDialog
          mealMenuId={mealMenuId}
          open={suggestionsPreviewOpen}
          onClose={() => setSuggestionsPreviewOpen(false)} />
      )}
      {readonlyMealMenuForModal !== null && (
        <MealMenuDialogReadonly
          open={readonlyMealMenuForModal !== null}
          onClose={() => {
            onClose();
            setReadonlyMealMenuForModal(null);
          }}
          mealMenu={readonlyMealMenuForModal}
          clientTimezone={clientTimezone}
        />
      )}
    </>
  );
};

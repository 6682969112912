import { Box } from "@mui/material";
import { EditMacroState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import { AnthropometrySnapshot } from "@notemeal/shared/utils/macro-protocol";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { Dispatch, useState } from "react";
import { MacroInput } from "./MacroInput";
import { MacroTotals } from "./MacroTotals";
import { MacroProtocolAction } from "./reducer/macroProtocolReducer";
import { ChangeMacroGPerKGAction } from "./reducer/macroReducer";

export const GRAM_WIDTH = 85;
export const PERCENT_WIDTH = 85;

interface MacrosTargetsProps {
  cho: EditMacroState;
  pro: EditMacroState;
  fat: EditMacroState;
  anthropometrySnapshot: AnthropometrySnapshot;
  dispatch: Dispatch<MacroProtocolAction>;
  gPerKgOnly: boolean;
}

// Should receive anthro snapshot w/ weight target include already
export const MacrosTargets = ({ cho, pro, fat, anthropometrySnapshot, dispatch, gPerKgOnly }: MacrosTargetsProps) => {
  const [changeThirdGPerKG, setChangeThirdGPerKG] = useState<ChangeMacroGPerKGAction | null>(null);
  const handleConfirmation = () => {
    if (changeThirdGPerKG) {
      dispatch({
        type: "SET_USING_CALORIE_BUDGET",
        payload: false,
      });
      dispatch(changeThirdGPerKG);
    }
    setChangeThirdGPerKG(null);
  };
  const onChangeGPerKg = (action: ChangeMacroGPerKGAction) => {
    const usesGPerKg = {
      cho: !cho.usesPercent,
      pro: !pro.usesPercent,
      fat: !fat.usesPercent,
    };
    const {
      payload: { macro },
    } = action;
    usesGPerKg[macro] = true;

    if (!gPerKgOnly && usesGPerKg.cho && usesGPerKg.pro && usesGPerKg.fat) {
      setChangeThirdGPerKG(action);
    } else {
      dispatch(action);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      <MacroInput
        macro={"cho"}
        state={cho}
        anthropometrySnapshot={anthropometrySnapshot}
        dispatch={dispatch}
        onChangeGPerKg={onChangeGPerKg}
        gPerKgOnly={gPerKgOnly}
      />
      <MacroInput
        macro={"pro"}
        state={pro}
        anthropometrySnapshot={anthropometrySnapshot}
        dispatch={dispatch}
        onChangeGPerKg={onChangeGPerKg}
        gPerKgOnly={gPerKgOnly}
      />
      <MacroInput
        macro={"fat"}
        state={fat}
        anthropometrySnapshot={anthropometrySnapshot}
        dispatch={dispatch}
        onChangeGPerKg={onChangeGPerKg}
        gPerKgOnly={gPerKgOnly}
      />
      <MacroTotals
        cho={cho}
        pro={pro}
        fat={fat}
        anthropometrySnapshot={anthropometrySnapshot}
        gPerKgOnly={gPerKgOnly} />
      {changeThirdGPerKG && (
        <ConfirmationDialog
          open={!!changeThirdGPerKG}
          onCancel={() => setChangeThirdGPerKG(null)}
          onConfirm={handleConfirmation}
          title={"All macros using g/kg"}
          message={"3 macronutrient targets edited as g/kg will turn off calorie budget. Would you like to proceed?"}
        />
      )}
    </Box>
  );
};

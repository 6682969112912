import { Box, LinearProgress, Typography, linearProgressClasses, useTheme } from "@mui/material";
import React from "react";
import { getCaption } from "../MacrosProgressUtils";

interface MacrosGreyBarProps {
  current: number;
  target: number;
  unit: string;
  hideNumbers?: boolean;
}

export const MacrosGreyBar = ({ current, target, unit, hideNumbers }: MacrosGreyBarProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  const value = (100 * current) / target;
  const caption = getCaption(current, target, unit, hideNumbers);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1">{caption}</Typography>
      <Box sx={{ display: "grid", gridTemplateColumns: "auto", gridTemplateRows: "auto" }}>
        <LinearProgress
          sx={{
            gridRowStart: 1,
            gridColumnStart: 1,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: greyscale[200],
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: greyscale[500],
            },
          }}
          variant="determinate"
          value={Math.min(100, value)}
        />
        {value > 100 && (
          <LinearProgress
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: greyscale[500],
              },
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: greyscale[900],
              },
            }}
            variant="determinate"
            value={Math.min(100, value - 100)}
          />
        )}
      </Box>
    </Box>
  );
};

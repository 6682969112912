import { GptRecipeByNameInput } from "@notemeal/graphql/types";
import { z } from "zod";

export const GenerateWithGPTFormSchema = z.object({
  name: z.string({ required_error: "Required" }).min(1, "A recipe description is required").max(100, "Must be less than 100 characters"),
  servingYield: z.coerce
    .string()
    .transform(n => (Number.isNaN(parseInt(n, 10)) ? null : parseInt(n, 10)))
    .refine((n): n is number => n !== null, "A serving size is required")
    .refine(n => n > 0, "Serving size must be greater than 0"),
});

export type GenerateWithGPTFormType = z.infer<typeof GenerateWithGPTFormSchema>;

export const generateWithGPTFormDefaultValues = (initialName?: string, initialServingYield?: number): Partial<GenerateWithGPTFormType> => ({
  name: initialName ?? "",
  servingYield: initialServingYield ?? undefined,
});

export const generateWithGPTFormToInput = (state: GenerateWithGPTFormType): GptRecipeByNameInput => ({
  name: state.name,
  servingYield: state.servingYield,
});

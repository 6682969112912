import React, { useState } from "react";

import { MRNSearch } from "./ImportRecipeDialog/MRNSearch";
import { NameSearch } from "./ImportRecipeDialog/NameSearch";
import { QueryLabel, SearchProps, queryLabels } from "./ImportRecipeDialog/types";

export const CompassRecipesSearchBar = (props: Omit<SearchProps, "setSelectedLabel" | "selectedLabel">) => {
  const [selectedLabel, setSelectedLabel] = useState<QueryLabel>(queryLabels[0]);

  const searchProps = {
    ...props,
    selectedLabel,
    setSelectedLabel,
  };

  if (selectedLabel === "Name") {
    return <NameSearch {...searchProps} />;
  } else if (selectedLabel === "Reference #") {
    return <MRNSearch {...searchProps} />;
  } else {
    return null;
  }
};

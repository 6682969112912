import { MealPlanCopyOptionsFormState } from "apps/web/src/components/MealPlan/CopyOptionsForm/utils";
import { initialMealPlanFormState, MealPlanFormState } from "apps/web/src/components/MealPlan/Form/utils";
import { BulkCopyMealPlanNewInput, CopyableMealPlanFragment, ExchangeSetFragment } from "apps/web/src/types";
import { MealPlanDateAssignment } from "../Content/Calendar/Form/types";
import { getOverrideMealPlanDateAssignmentInput } from "../Content/Calendar/Form/utils";

export const getBulkCopyMealPlanInput = (
  athleteIds: string[],
  mealPlanId: string,
  { name, description, isShared }: MealPlanFormState,
  dateAssignmentState: MealPlanDateAssignment,
  isAutoSuggestionsEnabled: boolean,
  { copyFoodPreferences, copyPickListServingIds }: MealPlanCopyOptionsFormState
): BulkCopyMealPlanNewInput | null => {
  const dateAssignment = getOverrideMealPlanDateAssignmentInput(dateAssignmentState);
  if (!dateAssignment) {
    return null;
  }

  return {
    athleteIds,
    mealPlanId,
    name,
    description,
    isShared,
    dateAssignment,
    isAutoSuggestionsEnabled,
    copyFoodPreferences,
    copyPickListServingIds,
  };
};

export const initialBulkCopyMealPlanFormState = (
  { type, exchangeSet, name, description }: CopyableMealPlanFragment,
  exchangeSets: readonly ExchangeSetFragment[]
): MealPlanFormState => {
  return {
    ...initialMealPlanFormState(
      exchangeSet && !exchangeSets.map(es => es.id).includes(exchangeSet.id) ? [...exchangeSets, exchangeSet] : exchangeSets
    ),
    description: description || "",
    type,
    exchangeSet,
    name,
  };
};

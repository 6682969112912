import { PureQueryOptions } from "@apollo/client";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { compareHubDisplayDate } from "@notemeal/shared/ui/utils/dateTimes";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";

import { InfoBox } from "apps/web/src/components/universal/InfoBox";
import TablePage from "apps/web/src/components/universal/TablePage";
import { RefetchQueriesContextProvider } from "apps/web/src/contexts/RefetchQueries";
import { NamedTagForSelectionFragment, PositionFragment, TeamPreviewFragment, useMealMenuAttendeesQuery } from "apps/web/src/types";
import { getRowsForTablePage, useOffsetPagination } from "apps/web/src/utils/pagination";
import { MealMenuAttendanceTableHeaderRow, MealMenuAttendanceTableRow } from "apps/web/src/views/MenuAttendance/AttendanceRow";
import { exportAttendanceToExcel } from "apps/web/src/views/MenuAttendance/Export";
import { MealMenuAttendanceTableToolbar } from "apps/web/src/views/MenuAttendance/Toolbar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { MealMenuAttendeesToAttendanceRows, MenuAttendanceRowInput } from "./utils";

interface MenuAttendancePageProps {
  refetchQueries: PureQueryOptions[];
  isLive: boolean;
  mealMenuName: string;
}

export const MenuAttendancePage = ({ refetchQueries, mealMenuName, isLive }: MenuAttendancePageProps) => {
  const { mealMenuId: maybeMealMenuId } = useParams();
  const mealMenuId = maybeMealMenuId || "";
  const { formatHubDisplayDate } = useDateFormatting();

  const [selectedTeams, setSelectedTeams] = useState<readonly TeamPreviewFragment[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<readonly PositionFragment[]>([]);
  const [namedTagsForQuery, setNamedTagsForQuery] = useState<NamedTagForSelectionFragment[]>([]);
  const [checkedInForQuery, setCheckedInForQuery] = useState<boolean | null>(null);
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
  const [sortOrderAscending, setSortOrderAscending] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [queryLastRanTime, setQueryLastRanTime] = useState<string | null>(null);
  const { setMessage } = useSnackbar();
  // tags ids will trigger a refetch but acceptable for MVP
  const tagIds = namedTagsForQuery.map(({ tag: { id } }) => id);

  const paginationHooks = useOffsetPagination();
  const { page, limit, resetOffset } = paginationHooks;

  const queryVariables = {
    mealMenuId,
    tagIds,
  };

  const POLL_INTERVAL_MS = 5000;

  const {
    data: mealMenuAttendanceData,
    loading: mealMenuAttendanceLoading,
    refetch,
  } = useMealMenuAttendeesQuery({
    variables: queryVariables,
    //only polling when the meal is live
    pollInterval: isLive ? POLL_INTERVAL_MS : undefined,
  });

  const queryCompletedAt = mealMenuAttendanceData?.mealMenuAttendees.queryCompletedAt;
  const { formatTimeInTimezoneWithLocale } = useDateFormatting();
  const timezone = useClientTimezone();

  useEffect(() => {
    try {
      queryCompletedAt && setQueryLastRanTime(formatTimeInTimezoneWithLocale(queryCompletedAt, timezone));
    } catch (e) {
      //todo remove once functionality is confirmed
      console.error("Error parsing", e);
    }
  }, [queryCompletedAt, formatTimeInTimezoneWithLocale, timezone]);

  const handleRefetch = () => {
    refetch(queryVariables);
    setMessage("success", "Menu Attendees Updated!");
  };
  const handleChangeSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);
    resetOffset();
  };

  const handleChangePositions = (newSelectedPositions: readonly PositionFragment[]) => {
    setSelectedPositions([...newSelectedPositions]);
    resetOffset();
  };

  const handleChangeTeams = (newSelectedTeams: readonly TeamPreviewFragment[]) => {
    setSelectedTeams(newSelectedTeams);
    resetOffset();
  };

  const handleChangeCheckedIn = (newCheckedIn: boolean | null) => {
    setCheckedInForQuery(newCheckedIn);
    resetOffset();
  };

  const handleChangeHasAccess = (newHasAccess: boolean | null) => {
    setHasAccess(newHasAccess);
    resetOffset();
  };

  const handleChangeNamedTags = (newTags: NamedTagForSelectionFragment[]) => {
    setNamedTagsForQuery([...newTags]);
    resetOffset();
  };

  const handleSortOrder = () => {
    const newSortOrder = sortOrderAscending === null ? true : !sortOrderAscending;
    setSortOrderAscending(newSortOrder);
    resetOffset();
  };
  const [infoBoxVisible, setInfoBoxVisible] = useState(isLive);

  const allPositions: PositionFragment[] = mealMenuAttendanceData
    ? mealMenuAttendanceData.mealMenuAttendees.notemealAttendees.flatMap(a => (a.athlete.position ? [a.athlete.position] : []))
    : [];

  const sortRows = (a: MenuAttendanceRowInput, b: MenuAttendanceRowInput) => {
    // purely for hub checkin display dates
    // sortByFn could possibly work but hub's checkin date makes it arguable just as messy
    return sortOrderAscending
      ? compareHubDisplayDate(a.hubCheckInDate, b.hubCheckInDate)
      : compareHubDisplayDate(b.hubCheckInDate, a.hubCheckInDate);
  };

  const allRows = mealMenuAttendanceData
    ? MealMenuAttendeesToAttendanceRows({
        notemealAttendees: [...mealMenuAttendanceData.mealMenuAttendees.notemealAttendees],
        nonNotemealAttendees: [...mealMenuAttendanceData.mealMenuAttendees.nonNotemealAttendees],
        formatFn: formatHubDisplayDate,
      })
    : [];

  const filteredTeamRows = selectedTeams.length > 0 ? allRows.filter(r => selectedTeams.map(t => t.id).includes(r.teamId)) : allRows;
  const filteredHasAccessRows = hasAccess !== null ? filteredTeamRows.filter(r => r.hasAccess === hasAccess) : filteredTeamRows;
  const filteredCheckedInRows =
    checkedInForQuery !== null ? filteredHasAccessRows.filter(r => r.checkedIn === checkedInForQuery) : filteredHasAccessRows;
  const filteredPositionRows =
    selectedPositions.length > 0
      ? filteredCheckedInRows.filter(r => selectedPositions.map(p => p.id).includes(r.positionId))
      : filteredCheckedInRows;
  const filteredQueryRows =
    searchText.length > 0
      ? filteredPositionRows.filter(r => r.fullName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
      : filteredPositionRows;
  const rows = filteredQueryRows.sort(sortRows);

  const exportData = () => {
    exportAttendanceToExcel({ fileName: `${mealMenuName}_Attendance`, rows });
  };

  const pageRows = getRowsForTablePage({ rows, limit, page });

  return (
    <RefetchQueriesContextProvider refetchQueries={refetchQueries}>
      {infoBoxVisible && (
        <InfoBox
          title="Attendance Report"
          info={`Please note that the table below will automatically update every 5 seconds. 
          ${queryLastRanTime ? `Last updated at ${queryLastRanTime}` : ""}`}
          onClose={() => setInfoBoxVisible(false)}
        />
      )}
      <TablePage
        tableHeaderRow={<MealMenuAttendanceTableHeaderRow sortOrderAscending={sortOrderAscending} onChangeSortOrder={handleSortOrder} />}
        header={
          <MealMenuAttendanceTableToolbar
            selectedTeams={selectedTeams}
            onChangeTeams={handleChangeTeams}
            onChangeCheckedIn={handleChangeCheckedIn}
            searchText={searchText}
            onChangeSearchText={handleChangeSearchText}
            hasAccess={hasAccess}
            onChangeHasAccess={handleChangeHasAccess}
            selectNamedTags={namedTagsForQuery}
            onChangeNamedTags={handleChangeNamedTags}
            checkedIn={checkedInForQuery}
            positions={[...new Set(allPositions)]}
            selectedPositions={selectedPositions}
            onChangePosition={handleChangePositions}
            exportData={exportData}
            isLive={isLive}
            refetchAttendees={handleRefetch}
          />
        }
        paginationHooks={paginationHooks}
        total={rows.length}
        tableBodyRows={pageRows.map(row => (
          <MealMenuAttendanceTableRow input={row} />
        ))}
        loading={mealMenuAttendanceLoading}
      />
    </RefetchQueriesContextProvider>
  );
};

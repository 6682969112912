import { TextFieldProps, Theme } from "@mui/material";
import { Semibold } from "../Common/TypographyCommon";

export const muiTextField = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: TextFieldProps; theme: Theme }) => {
      const {
        palette: { error, mediumEmphasisText },
      } = theme;
      return {
        marginTop: ownerState.variant === "outlined" ? "24px" : "0px",
        ".MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: mediumEmphasisText,
        },
        "& .MuiInputLabel-root": {
          marginLeft: "-12px",
          marginTop: "-12px",
          color: mediumEmphasisText,
          fontWeight: Semibold,
          fontSize: "16px",
          "&.Mui-focused": {
            color: mediumEmphasisText,
          },
        },
        "& .MuiInputLabel-root.Mui-error": {
          color: error.main,
        },
        "& .MuiFormHelperText-root": {
          marginTop: "4px",
          marginLeft: 0,
          fontSize: "11px",
        },
        "& .MuiOutlinedInput-root": {
          "& .MuiAutocomplete-inputRoot": {
            padding: {
              small: "2px 16px",
              medium: "6px 16px",
            }[ownerState.size as "small" | "medium"],
          },
          "& .MuiAutocomplete-input": {
            padding: "0px",
            height: "24px",
            lineHeight: "24px",
          },
          "& .MuiAutocomplete-endAdornment": {
            height: "0px",
          },
        },
      };
    },
  },
};

import { SelectProps, Theme } from "@mui/material";

export const muiSelect = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: SelectProps; theme: Theme }) => {
      return {
        "& .MuiSelect-outlined": {
          fontSize: "14px",
          padding: "6px 16px",
          //
          // TODO - not respecting ownerState for some reason
          //
          // fontSize: {
          //   small: "12px",
          //   medium: "14px",
          // }[ownerState.size as "small" | "medium"],
          // padding: {
          //   small: "2px 16px",
          //   medium: "6px 16px",
          // }[ownerState.size as "small" | "medium"],
        },
      };
    },
  },
};

import { parseTime, serializeTime } from "@notemeal/shared/ui/utils/dateTimes";
import { ActivityType, MealType } from "apps/web/src/types";
import { addMinutes, isValid } from "date-fns";
import { v4 } from "uuid";
import { MealPlanCalendarState } from "../types";
import { EditScheduleEvent, EditScheduleState } from "./types";

export type EditScheduleAction =
  | AddEvent
  | EditEventName
  | EditEventStart
  | EditEventEnd
  | EditMealType
  | EditActivityType
  | RemoveEvent
  | ResetEvent;

interface AddEvent {
  type: "AddEvent";
  payload: {
    type: "meal" | "activity";
  };
}

interface EditEventName {
  type: "EditEventName";
  payload: {
    id: string;
    name: string;
  };
}

interface EditEventStart {
  type: "EditEventStart";
  payload: {
    id: string;
    time: Date | null;
  };
}

interface EditEventEnd {
  type: "EditEventEnd";
  payload: {
    id: string;
    time: Date | null;
  };
}

interface EditMealType {
  type: "EditMealType";
  payload: {
    id: string;
    mealType: MealType;
  };
}

interface EditActivityType {
  type: "EditActivityType";
  payload: {
    id: string;
    activityType: ActivityType;
  };
}

interface RemoveEvent {
  type: "RemoveEvent";
  payload: {
    id: string;
  };
}

interface ResetEvent {
  type: "ResetEvent";
  payload: {
    id: string;
  };
}

const DEFAULT_START_TIME = "06:00:00";
const DEFAULT_END_TIME = "06:30:00";

export const editScheduleReducer = (state: EditScheduleState, action: EditScheduleAction): EditScheduleState => {
  switch (action.type) {
    case "AddEvent": {
      let newEvent: EditScheduleEvent;
      if (action.payload.type === "meal") {
        newEvent = {
          id: v4(),
          mealId: v4(),
          name: "",
          modified: false,
          type: "meal",
          start: DEFAULT_START_TIME,
          end: DEFAULT_END_TIME,
          startRaw: parseTime(DEFAULT_START_TIME),
          endRaw: parseTime(DEFAULT_END_TIME),
          mealType: "snack",
        };
      } else {
        newEvent = {
          id: v4(),
          activityId: v4(),
          name: "",
          modified: false,
          type: "activity",
          start: DEFAULT_START_TIME,
          end: DEFAULT_END_TIME,
          startRaw: parseTime(DEFAULT_START_TIME),
          endRaw: parseTime(DEFAULT_END_TIME),
          activityType: "practice",
        };
      }
      return { events: [...state.events, newEvent] };
    }
    case "EditEventName":
      return {
        events: state.events.map(m => (m.id === action.payload.id ? { ...m, name: action.payload.name } : m)),
      };
    case "EditEventStart":
      return {
        events: state.events.map(m => {
          if (m.id !== action.payload.id) {
            return m;
          }
          const startRaw = action.payload.time;
          const start = startRaw && isValid(startRaw) ? serializeTime(startRaw) : m.start;
          const endRaw = startRaw && isValid(startRaw) ? addMinutes(startRaw, 30) : m.endRaw;
          const end = endRaw ? serializeTime(endRaw) : m.end;
          return {
            ...m,
            start,
            startRaw,
            end,
            endRaw,
          };
        }),
      };
    case "EditEventEnd":
      return {
        events: state.events.map(m => {
          if (m.id !== action.payload.id) {
            return m;
          }
          const end = action.payload.time && isValid(action.payload.time) ? serializeTime(action.payload.time) : m.end;
          return {
            ...m,
            end,
            endRaw: action.payload.time,
          };
        }),
      };
    case "EditMealType":
      return {
        events: state.events.map(m => (m.id === action.payload.id && m.type === "meal" ? { ...m, mealType: action.payload.mealType } : m)),
      };
    case "EditActivityType":
      return {
        events: state.events.map(a =>
          a.id === action.payload.id && a.type === "activity" ? { ...a, activityType: action.payload.activityType } : a
        ),
      };
    case "ResetEvent":
      return {
        events: state.events.map(m => (m.id === action.payload.id ? { ...m, modified: false } : m)),
      };
    case "RemoveEvent":
      return {
        events: state.events.filter(m => m.id !== action.payload.id),
      };
  }
};

export const initScheduleState = (calendarState: MealPlanCalendarState): EditScheduleState => {
  const meals: readonly EditScheduleEvent[] = calendarState.mealTemplates.map(
    ({ id, meal: { id: mealId, name, start, end, type }, hasDateModifications, dateModifications, dayOfWeekModifications }) => ({
      id,
      mealId,
      name,
      start,
      end,
      mealType: type,
      startRaw: parseTime(start),
      endRaw: parseTime(end),
      type: "meal",
      modified: hasDateModifications || dateModifications.length + dayOfWeekModifications.length > 0,
    })
  );
  const activities: readonly EditScheduleEvent[] = calendarState.activityTemplates.map(
    ({ id, activity: { id: activityId, name, start, end, type }, hasDateModifications, dateModifications, dayOfWeekModifications }) => ({
      id,
      activityId,
      name,
      start,
      end,
      activityType: type,
      startRaw: parseTime(start),
      endRaw: parseTime(end),
      type: "activity",
      modified: hasDateModifications || dateModifications.length + dayOfWeekModifications.length > 0,
    })
  );

  return {
    events: meals.concat(activities),
  };
};

export const getEditScheduleCanSaveTooltips = (state: EditScheduleState): string[] => {
  let tooltips: string[] = [];

  if (state.events.some(e => e.name.length === 0)) {
    tooltips.push("Name is required");
  }

  if (state.events.some(e => e.startRaw === null || !isValid(e.startRaw))) {
    tooltips.push("Start is required");
  }

  if (state.events.some(e => e.endRaw === null || !isValid(e.endRaw))) {
    tooltips.push("End is required");
  }

  if (state.events.some(e => e.start >= e.end)) {
    tooltips.push("Start must be before End");
  }

  return tooltips;
};

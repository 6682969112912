import React, { Dispatch } from "react";
import { CustomizationSelect } from "../CustomizationSelect";
import { DigitalDisplaysConfigActions } from "../reducer";
import { Orientation } from "./OrientationSelect";

const DEVICE = "Device";
export const IPAD = "iPad";
export const TV = "TV";
const DEVICE_OPTIONS = [IPAD, TV] as const;
export type Device = (typeof DEVICE_OPTIONS)[number];
export const DEFAULT_DEVICE = IPAD;

export interface DeviceSelectProps {
  value: Device;
  orientation: Orientation;
  dispatch: Dispatch<DigitalDisplaysConfigActions>;
  className?: string;
}

export const DeviceSelect = ({ value, orientation, dispatch, className }: DeviceSelectProps) => {
  const handleSelectDevice = (value: Device) => {
    dispatch({ type: "SET_DEVICE", payload: value });
    if (orientation === "Portrait" && value === "TV") {
      dispatch({ type: "SET_ITEMS_PER_ROW", payload: "1" });
    } else {
      dispatch({ type: "SET_ITEMS_PER_ROW", payload: "2" });
    }
  };

  return <CustomizationSelect
    label={DEVICE}
    value={value}
    onChange={handleSelectDevice}
    options={DEVICE_OPTIONS}
    className={className} />;
};

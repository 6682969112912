import AssignmentIcon from "@mui/icons-material/AssignmentRounded";
import React from "react";

// full page path
export const NAV_ORG_MEAL_PLANS = "/org/meal-plans";

// local child paths
export const NAV_MEAL_PLANS_MEAL_PLAN_TEMPLATES = "meal-plan-templates";
export const NAV_MEAL_PLANS_EXCHANGE_SETS = "exchange-sets";

// full child paths
export const NAV_ORG_MEAL_PLANS_MEAL_PLAN_TEMPLATES = `${NAV_ORG_MEAL_PLANS}/${NAV_MEAL_PLANS_MEAL_PLAN_TEMPLATES}`;
export const NAV_ORG_MEAL_PLANS_EXCHANGE_SETS = `${NAV_ORG_MEAL_PLANS}/${NAV_MEAL_PLANS_EXCHANGE_SETS}`;

export const mealPlansLink = {
  to: NAV_ORG_MEAL_PLANS,
  name: "Meal Plans",
  icon: <AssignmentIcon />,
};

import React, { createContext, ReactNode, useContext, useState } from "react";

import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { groupTimelineMealsByDate, TimelineMealsAndItemsByDate } from "@notemeal/shared/ui/Timeline/group";
import { getRangeAroundDateInTz, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { useAthleteTimelinePreviewQuery } from "../../../../types";

interface ICalendarContextProvider {
  mealsByDate: readonly TimelineMealsAndItemsByDate[];
  onChangeMonth: (date: string) => void;
  onEditDate: () => void;
  loading: boolean;
}

const CalendarContext = createContext<ICalendarContextProvider>({
  mealsByDate: [],
  onChangeMonth: () => {},
  onEditDate: () => {},
  loading: false,
});

interface CalendarContextProviderProps {
  athleteId: string;
  children?: ReactNode;
}

// TODO: Clean up timelineItemsInRange from this component.
export const CalendarContextProvider = ({ athleteId, children }: CalendarContextProviderProps) => {
  const clientTimezone = useClientTimezone();

  const initStartEnd = getRangeAroundDateInTz(serializeDate(new Date()), clientTimezone);

  const [startEnd, setStartEnd] = useState<{
    start: string;
    end: string;
  }>(initStartEnd);

  const { data, refetch, loading } = useAthleteTimelinePreviewQuery({
    variables: {
      athleteId,
      start: startEnd.start,
      end: startEnd.end,
    },
  });

  const mealsByDate = groupTimelineMealsByDate(
    data?.athlete.timelineMealsInRange ?? [],
    new Date(startEnd.start),
    new Date(startEnd.end),
    clientTimezone
  );

  const onChangeMonth = (date: string) => {
    const nextStartEnd = getRangeAroundDateInTz(date, clientTimezone);
    setStartEnd(nextStartEnd);
  };

  const onEditDate = () => {
    refetch();
  };

  return (
    <CalendarContext.Provider
      value={{
        mealsByDate,
        onChangeMonth,
        loading,
        onEditDate,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = () => useContext(CalendarContext);

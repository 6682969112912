import { MenuItem, SxProps, TextField } from "@mui/material";
import React from "react";

import { ActivityType } from "../../types";

interface ActivityTypeSelectProps {
  value: ActivityType | null;
  onChange: (mt: ActivityType) => void;
  disabled?: boolean;
  className?: string;
  sx?: SxProps;
}

const ActivityTypeSelect = ({ value, onChange, className, sx, disabled }: ActivityTypeSelectProps) => {
  return (
    <TextField
      select
      label="Type"
      value={value || ""}
      className={className}
      sx={sx}
      disabled={disabled}
      onChange={e => onChange(e.target.value as ActivityType)}
    >
      <MenuItem value={"practice"}>Practice</MenuItem>
      <MenuItem value={"lift"}>Lift</MenuItem>
      <MenuItem value={"conditioning"}>Conditioning</MenuItem>
      <MenuItem value={"competition"}>Competition</MenuItem>
      <MenuItem value={"class"}>Class</MenuItem>
    </TextField>
  );
};

export default ActivityTypeSelect;

import { Box, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";

import { forwardRef, useCallback, useEffect, useState } from "react";

interface MacroProtocolFormRmrMethodMenuItemDisabledProps {
  text: string;
  disabledReasons: string[];
  value?: string;
}

const MacroProtocolFormRmrMethodMenuItemDisabled = forwardRef<HTMLDivElement, MacroProtocolFormRmrMethodMenuItemDisabledProps>(
  (props, ref) => {
    const { text, disabledReasons, value } = props;
    const [open, setOpen] = useState(false);

    const handleCloseTooltip = useCallback(() => setOpen(false), [setOpen]);
    const handleOpenTooltip = useCallback(() => setOpen(true), [setOpen]);

    useEffect(() => {
      if (open) {
        const timer = setTimeout(handleCloseTooltip, 2000);
        return () => clearTimeout(timer);
      }
    }, [open, setOpen, handleCloseTooltip]);

    return (
      <Tooltip
        open={open}
        title={
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {disabledReasons.length && (
              <Box>
                <Typography variant="body2Medium">The athlete's most recent anthropometry is preventing use of this RMR method:</Typography>
              </Box>
            )}
            {disabledReasons.length &&
              disabledReasons.map(item => (
                <Typography variant="body2" key={item}>
                  {item}
                </Typography>
              ))}
          </Box>
        }
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              maxWidth: 600,
            },
          },
        }}
      >
        <div ref={ref} onClick={handleOpenTooltip}>
          <MenuItem value={value} disabled>
            {text}
          </MenuItem>
        </div>
      </Tooltip>
    );
  }
);

export default MacroProtocolFormRmrMethodMenuItemDisabled;

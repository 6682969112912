import { Box, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { ExchangeType, FoodLimitedAccessFragment, FullFoodGroupFragment, useFoodGroupQuery } from "../../../../types";
import { Orientation } from "../utils";
import ExchangeBox from "./ExchangeBox";

interface AppendixFoodGroupProps {
  promoteOrAvoid: "promote" | "avoid";
  foodGroup: FullFoodGroupFragment;
  orientation: Orientation;
}

const useStyles = (orientation: Orientation) => {
  return makeStyles(({ spacing, palette: { success, error, grey } }: Theme) =>
    createStyles({
      grid: {
        display: "grid",
        gridTemplateRows: `.5in .25in ${orientation === "portrait" ? 7.25 : 4.75}in`,
        gridTemplateColumns: "1fr 1fr",
      },
      title: {
        padding: spacing(1),
        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowStart: 1,
      },
      descriptionContainer: {
        gridColumnStart: 1,
        gridColumnEnd: 3,
        gridRowStart: 2,
        padding: spacing(1),
      },
      blockQuote: {
        lineHeight: 0.65,
        fontStyle: "italic",
        borderLeft: `1px solid ${grey[300]}`,
        paddingLeft: 10,
      },
      descriptionText: {
        lineHeight: 0.8,
        fontSize: "10px",
        color: grey[600],
      },
      promoteColor: { color: success.dark },
      avoidColor: { color: error.dark },
    })
  );
};

type GroupedFoodMap = {
  [key in ExchangeType]: FoodLimitedAccessFragment[];
};

const groupFoodsByExchange = (foods: readonly FoodLimitedAccessFragment[]): GroupedFoodMap => {
  let groups: GroupedFoodMap = {
    starch: [],
    fat: [],
    protein: [],
    dairy: [],
    fruit: [],
    vegetable: [],
    "%future added value": [],
  };

  foods.forEach(f => {
    const firstExchangeType = f.exchangeTypes && f.exchangeTypes[0];
    if (!!firstExchangeType) {
      groups[firstExchangeType] = [...groups[firstExchangeType], f];
    }
  });
  return groups;
};

const AppendixFoodGroup = ({ orientation, foodGroup, promoteOrAvoid }: AppendixFoodGroupProps) => {
  const classes = useStyles(orientation)();

  const { data, loading } = useFoodGroupQuery({
    variables: { id: foodGroup.id },
    fetchPolicy: "network-only",
  });

  if (loading || !data) {
    return null;
  }

  const exchangeGroupedFoods = groupFoodsByExchange(data.foodGroup.foods);
  const exchangeTypes = Object.keys(exchangeGroupedFoods).filter(et => et !== "%future added value") as ExchangeType[];
  return (
    <div className={classes.grid}>
      <div className={classes.title}>
        <Typography className={promoteOrAvoid === "promote" ? classes.promoteColor : classes.avoidColor} variant="body1Semibold">
          {foodGroup.name}
        </Typography>
      </div>
      <div className={classes.descriptionContainer}>
        <div className={classes.blockQuote}>
          <div className={classes.descriptionText}>
            <Typography variant="subtitle1" classes={{ root: classes.descriptionText }}>
              {promoteOrAvoid === "promote" ? "These foods should be included in your diet." : "These foods should be avoided!"}
              {/*{foodGroup.description}*/}
            </Typography>
          </div>
        </div>
      </div>
      <Box
        sx={{
          gridColumnStart: 1,
          gridColumnEnd: 3,
          gridRowStart: 3,
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {exchangeTypes.map(exchangeType => (
          <ExchangeBox
            key={exchangeType}
            foods={exchangeGroupedFoods[exchangeType]}
            name={exchangeType} />
        ))}
      </Box>
    </div>
  );
};

export default AppendixFoodGroup;

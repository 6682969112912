import {
  AddMealMenuDiningStationInput,
  CreateMenuItemInput,
  EditMealMenuDiningStationInput,
  EditMenuItemInput,
  MenuItemAppearanceFormFragment,
  MenuItemFormFragment,
  MenuOrderCountsFragment,
} from "../../types";
import { getMenuItemChoiceInput, getMenuItemChoiceDiffInputs } from "../MenuItemAddOns/utils";
import { MenuItemAppearanceState } from "./reducer";

type onCreateMenuItemAppearance = (
  menuItem: MenuItemFormFragment,
  maxAmount: number | null,
  availableForOrder: boolean,
  allowSpecialRequests: boolean,
  isOneOff: boolean
) => void;

export type onConvertMenuItemAppearance = (
  menuItem: MenuItemFormFragment,
  maxAmount: number | null,
  availableForOrder: boolean,
  allowSpecialRequests: boolean,
  isOneOff: boolean,
  initialMenuItem: MenuItemFormFragment
) => void;

export type onEditMenuItemAppearance = (
  menuItem: MenuItemFormFragment,
  maxAmount: number | null,
  availableForOrder: boolean,
  allowSpecialRequests: boolean,
  initialMenuItem: MenuItemFormFragment
) => void;

export interface BaseMenuItemAppearanceCardProps {
  menuItemAppearance: MenuItemAppearanceState;
  onCreate: onCreateMenuItemAppearance;
  onEdit: onEditMenuItemAppearance;
  onConvert: onConvertMenuItemAppearance;
  onEditAppearance: (maxAmount: number | null, availableForOrder: boolean, allowSpecialRequests: boolean) => void;
}

export const getCreateMenuItemInputs = (
  menuItemAppearances: readonly MenuItemAppearanceState[]
): Pick<AddMealMenuDiningStationInput, "addMenuItems" | "createMenuItems" | "editMenuItems"> => {
  return {
    addMenuItems: menuItemAppearances
      .flatMap(mia => (mia.type === "Add" ? mia : []))
      .map(({ menuItem, position, maxAmount, availableForOrder, allowSpecialRequests }) => ({
        menuItemId: menuItem.id,
        appearance: {
          position,
          maxAmount,
          availableForOrder,
          allowSpecialRequests,
        },
      })),
    createMenuItems: menuItemAppearances.flatMap(mia => (mia.type === "Create" ? mia : [])).map(getCreateMenuItemInput),
    editMenuItems: menuItemAppearances.flatMap(mia => (mia.type === "Edit" ? mia : [])).map(getEditMenuItemInput),
  };
};

export const getEditMenuItemInputs = (
  menuItemAppearances: readonly MenuItemAppearanceState[]
): Pick<
  EditMealMenuDiningStationInput,
  "addMenuItems" | "createMenuItems" | "editMenuItems" | "convertMenuItems" | "moveMenuItems" | "removeMenuItems"
> => {
  return {
    ...getCreateMenuItemInputs(menuItemAppearances),
    moveMenuItems: menuItemAppearances
      .flatMap(mia => (mia.type === "Move" && !mia.deleted ? mia : []))
      .map(({ menuItem, position, maxAmount, availableForOrder, allowSpecialRequests }) => ({
        menuItemId: menuItem.id,
        appearance: {
          position,
          maxAmount,
          availableForOrder,
          allowSpecialRequests,
        },
      })),
    removeMenuItems: menuItemAppearances
      .flatMap(mia => (menuItemAppearanceIsDeleted(mia) ? mia : []))
      .map(mia => ({ menuItemId: mia.menuItem.id })),
    convertMenuItems: menuItemAppearances
      .flatMap(mia => (mia.type === "Conversion" && !mia.deleted ? mia : []))
      .map(({ menuItem: finalMenuItem, initialMenuItem, position, maxAmount, availableForOrder, allowSpecialRequests }) => {
        const { name, servingName, description, isOneOff, imageUrl, suggestionCategory, score, servingAmounts, foodCategory } =
          finalMenuItem;
        return {
          menuItemId: initialMenuItem.id,
          name,
          servingName,
          description,
          isOneOff,
          imageUrl,
          suggestionCategory,
          scoreValue: score?.value ?? null,
          foodCategory: foodCategory?.category ?? null,
          appearance: {
            position,
            maxAmount,
            availableForOrder,
            allowSpecialRequests,
          },
          servingAmounts: servingAmounts.map(sa => ({
            servingId: sa.serving.id,
            amount: sa.amount,
            position: sa.position,
          })),
          ...getMenuItemChoiceDiffInputs({
            initial: initialMenuItem.choices,
            final: finalMenuItem.choices,
          }),
        };
      }),
    editMenuItems: menuItemAppearances.flatMap(mia => (mia.type === "Edit" && !mia.deleted ? mia : [])).map(getEditMenuItemInput),
  };
};

const getCreateMenuItemInput = ({
  menuItem,
  position,
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
}: MenuItemAppearanceFormFragment): CreateMenuItemInput => ({
  name: menuItem.name,
  servingName: menuItem.servingName,
  description: menuItem.description,
  imageUrl: menuItem.imageUrl,
  suggestionCategory: menuItem.suggestionCategory,
  isOneOff: menuItem.isOneOff,
  appearance: {
    position,
    maxAmount,
    availableForOrder,
    allowSpecialRequests,
  },
  servingAmounts: menuItem.servingAmounts.map(sa => ({
    servingId: sa.serving.id,
    amount: sa.amount,
    position: sa.position,
  })),
  choices: menuItem.choices.map(getMenuItemChoiceInput),
  scoreValue: menuItem.score?.value ?? null,
  foodCategory: menuItem.foodCategory?.category ?? null,
});

const getEditMenuItemInput = ({
  menuItem: finalMenuItem,
  initialMenuItem,
  position,
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
}: Extract<MenuItemAppearanceState, { type: "Edit" }>): EditMenuItemInput => ({
  menuItemId: initialMenuItem.id,
  name: finalMenuItem.name,
  servingName: finalMenuItem.servingName,
  description: finalMenuItem.description,
  imageUrl: finalMenuItem.imageUrl,
  suggestionCategory: finalMenuItem.suggestionCategory,
  scoreValue: finalMenuItem.score?.value ?? null,
  foodCategory: finalMenuItem.foodCategory?.category ?? null,
  appearance: {
    position,
    maxAmount,
    availableForOrder,
    allowSpecialRequests,
  },
  servingAmounts: finalMenuItem.servingAmounts.map(sa => ({
    servingId: sa.serving.id,
    amount: sa.amount,
    position: sa.position,
  })),
  ...getMenuItemChoiceDiffInputs({
    initial: initialMenuItem.choices,
    final: finalMenuItem.choices,
  }),
});

export const getMenuItemAppearanceStateText = (menuItemAppearance: MenuItemAppearanceState): string => {
  if (menuItemAppearance.type === "Create") {
    if (menuItemAppearance.menuItem.isOneOff) {
      return "Creating New One-Off Menu Item";
    } else {
      return "Creating New Reusable Menu Item";
    }
  } else if (menuItemAppearance.type === "Edit") {
    return "Editing Reusable Menu Item";
  } else if (menuItemAppearance.type === "Conversion") {
    if (menuItemAppearance.menuItem.isOneOff) {
      return menuItemAppearance.initialMenuItem.isOneOff ? "One-Off Menu Item" : "Converting to One-Off Menu Item";
    } else {
      return "Converting to New Reusable Menu Item";
    }
  } else {
    return menuItemAppearance.menuItem.isOneOff ? "One-Off Menu Item" : "Reusable Menu Item";
  }
};

export type MenuOrderItemCounts = Record<string, number | undefined>;

export const getMenuOrderItemCounts = (menuOrders: readonly MenuOrderCountsFragment[]): MenuOrderItemCounts => {
  return menuOrders
    .flatMap(o => o.items)
    .filter(i => i.forOrder === true)
    .reduce<MenuOrderItemCounts>((counts, next) => {
      const currentAmount = counts[next.menuItem.id] || 0;
      return {
        ...counts,
        [next.menuItem.id]: currentAmount + next.amount,
      };
    }, {});
};

export const getMenuItemAppearanceOrderCount = (menuItemAppearance: MenuItemAppearanceState, counts: MenuOrderItemCounts): number => {
  if (menuItemAppearance.type === "Edit" || menuItemAppearance.type === "Conversion") {
    return counts[menuItemAppearance.initialMenuItem.id] || 0;
  } else {
    return counts[menuItemAppearance.menuItem.id] || 0;
  }
};

export const isExistingReusableMenuItem = (menuItemAppearance: MenuItemAppearanceState): boolean => {
  return (menuItemAppearance.type === "Add" || menuItemAppearance.type === "Move") && !menuItemAppearance.menuItem.isOneOff;
};

interface getMenuItemAppearanceEditOptionsPayload {
  disableConvertToOneOff: boolean;
  disableConvertToReusable: boolean;
  disableEditReusable: boolean;
}

export const getMenuItemAppearanceEditOptions = (menuItemAppearance: MenuItemAppearanceState): getMenuItemAppearanceEditOptionsPayload => {
  const existingReusable = isExistingReusableMenuItem(menuItemAppearance);

  const disableEditReusable = !existingReusable && menuItemAppearance.type !== "Edit";
  const disableConvertToOneOff = (!existingReusable && menuItemAppearance.menuItem.isOneOff) || menuItemAppearance.type === "Edit";
  const disableConvertToReusable = !existingReusable && !menuItemAppearance.menuItem.isOneOff;

  return {
    disableEditReusable,
    disableConvertToReusable,
    disableConvertToOneOff,
  };
};

type DeletableMenuItemAppearanceState = Extract<MenuItemAppearanceState, { type: "Move" | "Edit" | "Conversion" }>;

export const menuItemAppearanceIsDeleted = (menuItemAppearance: MenuItemAppearanceState): boolean => {
  return menuItemAppearanceCanBeDeleted(menuItemAppearance) && menuItemAppearance.deleted;
};

export const menuItemAppearanceCanBeDeleted = (
  menuItemAppearance: MenuItemAppearanceState
): menuItemAppearance is DeletableMenuItemAppearanceState => {
  return menuItemAppearance.type === "Move" || menuItemAppearance.type === "Edit" || menuItemAppearance.type === "Conversion";
};

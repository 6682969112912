import { IFileImport } from "../../../../universal/Import/Button";
import { loadCsv } from "../../../../../utils/import/load";
import { parseDelimitedFile } from "../../../../../utils/import/parse";
import { ImportableAnthroEntry } from "../types";
import { subYears } from "date-fns";

// First Name	Middle Name	Last Name	Date of Birth	Test Date	Age	Gender	Height (cm)	Height (in)	ID 1	ID 2	Ethnicity	Operator	Test No.	Density Model	TGV Model	% Fat	% FFM	Fat Mass (kg)	Fat Mass (lb)	Fat Free Mass (kg)	Fat Free Mass (lb)	Body Mass (kg)	Body Mass  (lb)	Est. RMR (kcal/day)	Est. TEE (kcal/day)	Activity Level	Body Volume	BD (kg/L)	Volume1 (L)	Volume2 (L)	Volume3 (L)	DFM (kg/L)	DFFM (kg/L)	TGV (L)	Predicted TGV (L)	BSA (cm^2)	Comments

interface BodPodFileImportFields {
  "Last Name": string;
  "First Name": string;
  "Date of Birth": string;
  Gender: string;
  "Dept ID": string;
  "Most Recent Measurement Date": string;
  "Test Date": string;
  "Height (in)": string;
  "% Fat": number;
  "Fat Mass (lb)": number;
  "Fat Free Mass (lb)": number;
  "Body Mass  (lb)": number;
  "Body Mass (lb)": number;
}

type BodPodFileImportFieldsRaw = {
  [key in keyof BodPodFileImportFields]: string;
};

export const iBodPodMatchFields: (keyof ImportableAnthroEntry)[] = ["firstName" as const, "lastName" as const, "datetime" as const];

type I = ImportableAnthroEntry;

const numberFieldsWithoutHeight = [
  {
    fields: ["Fat Mass (lb)" as const],
    required: true,
    importableField: "bodyFatMass" as const,
  },
  {
    fields: ["Fat Free Mass (lb)" as const],
    required: true,
    importableField: "leanBodyMass" as const,
  },
  {
    fields: ["Body Mass  (lb)" as const, "Body Mass (lb)" as const],
    required: true,
    importableField: "weight" as const,
  },
  // {field: 'Bone Mass - Total' as const, required: true, importableField: 'boneMass'},
];
const numberFields = [
  ...numberFieldsWithoutHeight,
  { fields: ["Height (in)" as const], importableField: "height" as const },
  {
    fields: ["% Fat" as const],
    required: true,
    importableField: "percentBodyFat" as const,
  },
];
const stringFields = [
  {
    fields: ["Last Name" as const],
    required: true,
    importableField: "lastName" as const,
  },
  {
    fields: ["First Name" as const],
    required: true,
    importableField: "firstName" as const,
  },
  // {field: 'Dept ID' as const, importableField: 'teamName' as const},
]; // More on 'as const': https://stackoverflow.com/a/55387357/3923962
const dateFields = [
  {
    fields: ["Test Date" as const, "Most Recent Measurement Date" as const],
    required: true,
    importableField: "datetime" as const,
  },
];

export const fields = [...numberFields, ...stringFields, ...dateFields];
export const msg = `
  Imports data using the BodPod exported .tsv file
`;

export const loadBodPodRows = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  // type T = BodPodFileImportFields;
  type F = BodPodFileImportFieldsRaw;
  const rows = await loadCsv<F>({ file, onError }, "\t");
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  const filteredRows = parsedRows.filter(row => new Date(row.datetime) > subYears(new Date(), 5));
  return filteredRows.map(r => ({ ...r, type: "bodpod" }));
};

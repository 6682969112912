import { Box, Button, Dialog, DialogActions, DialogContent, Step, StepLabel, Stepper } from "@mui/material";
import React, { useReducer, useState } from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import TWItemizedTooltip from "../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { GoalTypeFragment } from "../../types";
import Anthropometry from "./MacroProtocol/Anthropometry/Anthropometry";
import SampleDataForm from "./MacroProtocol/Anthropometry/SampleDataForm";
import Goals from "./MacroProtocol/Goals/Goals";
import { convertEditorStateToSaveState, getCanSaveGoalState, getInitialGoalsState, goalsReducer } from "./MacroProtocol/Goals/goalsReducer";
import MacroProtocolForm from "./MacroProtocol/MacroProtocolForm";
import { MacroProtocolState, macroProtocolReducer } from "./MacroProtocol/reducer/macroProtocolReducer";
import {
  getCanSaveAnthropometryToolTips,
  getCanSaveMacroProtocolToolTips,
  getCanSaveSampleDataToolTips,
} from "./MacroProtocol/utils/macroProtocolUtils";

export enum EditMealPlanSteps {
  Goals,
  Anthropometry,
  SampleData,
  MacroProtocol,
}

const stepLabels = ["Weight Targets", "Anthropometry", "Sample Data", "Macro Protocol"];

interface EditMealPlanTemplateStepperProps {
  initialMacroProtocolState: MacroProtocolState;
  goalTypes: readonly GoalTypeFragment[];
  initialStep: EditMealPlanSteps;
  onClose: () => void;
  onDone: (macroProtocol: MacroProtocolState) => void;
}

const EditMealPlanTemplateStepper = ({
  initialMacroProtocolState,
  goalTypes,
  initialStep,
  onClose,
  onDone,
}: EditMealPlanTemplateStepperProps) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [state, dispatch] = useReducer(macroProtocolReducer, initialMacroProtocolState);

  const {
    anthropometry,
    calorieBudget: { goals, selectedPreviewGoal },
  } = state;

  const [goalsState, goalsDispatch] = useReducer(goalsReducer, getInitialGoalsState(goals));

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case EditMealPlanSteps.Goals:
        return getCanSaveGoalState(goalsState);
      case EditMealPlanSteps.Anthropometry:
        return getCanSaveAnthropometryToolTips(anthropometry);
      case EditMealPlanSteps.SampleData:
        return getCanSaveSampleDataToolTips(anthropometry);
      case EditMealPlanSteps.MacroProtocol:
        return getCanSaveMacroProtocolToolTips(state);
      default:
        return [];
    }
  };

  const canSaveTooltips = getCanSaveTooltipItems();

  const firstStep = 0;
  // https://devblogs.microsoft.com/typescript/announcing-typescript-5-0-beta/#forbidden-implicit-coercions-in-relational-operators
  const lastStep = Number(Object.values(EditMealPlanSteps)[Object.values(EditMealPlanSteps).length - 1]);
  const getPrevLabel = () => (activeStep === firstStep ? "Cancel" : "Back");
  const getNextLabel = () => (activeStep === lastStep ? "Done" : "Next");

  const onPrev = () => {
    if (activeStep === firstStep) {
      onClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };
  const onNext = () => {
    if (canSaveTooltips.length) {
      return;
    }

    switch (activeStep) {
      case EditMealPlanSteps.Goals:
        const goals = convertEditorStateToSaveState(goalsState);
        dispatch({ type: "OVERWRITE_GOALS", payload: goals });
        if (!(selectedPreviewGoal && goals.includes(selectedPreviewGoal))) {
          dispatch({ type: "SET_SELECTED_PREVIEW_GOAL", payload: goals[0] });
        }
        break;
      case lastStep:
        onDone(state);
        break;
    }
    setActiveStep(activeStep + 1);
  };

  const disabled = activeStep > lastStep;

  return (
    <Dialog
      open={initialStep !== null}
      onClose={onClose}
      maxWidth={false}>
      <DialogTitle title="Edit Meal Plan Template" onClose={onClose} />
      <DialogContent sx={{ width: 1060, minWidth: 1060, height: 720 }}>
        <Stepper
          sx={{ p: 2, mb: 3, justifyContent: "center" }}
          activeStep={activeStep}
          connector={<div />}>
          {stepLabels.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ px: 2 }}>
          {activeStep === EditMealPlanSteps.Goals && <Goals
            state={goalsState}
            dispatch={goalsDispatch}
            goalTypes={goalTypes} />}
          {activeStep === EditMealPlanSteps.Anthropometry && <Anthropometry state={state} dispatch={dispatch} />}
          {activeStep === EditMealPlanSteps.SampleData && <SampleDataForm state={state} dispatch={dispatch} />}
          {activeStep === EditMealPlanSteps.MacroProtocol && <MacroProtocolForm state={state} dispatch={dispatch} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={disabled}
          onClick={onPrev}>
          {getPrevLabel()}
        </Button>
        <TWItemizedTooltip title="Fix the following before advancing" items={canSaveTooltips}>
          <Button onClick={onNext} disabled={disabled}>
            {getNextLabel()}
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default EditMealPlanTemplateStepper;

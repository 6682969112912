import { MenuItem, TextField } from "@mui/material";
import React from "react";

import { useMenusStyles } from "../styles";
const MENU_TYPE = "Menu Type";
export const CATERING = "Catering";
export const IN_HOUSE = "In-House";
export const ALL = "All";
const MENU_TYPE_OPTIONS = [CATERING, IN_HOUSE, ALL] as const;
export type MenuType = typeof MENU_TYPE_OPTIONS[number];

export interface MenuTypeSelectProps {
  value: MenuType | null;
  onChange: (value: MenuType) => void;
  className?: string;
}

const MenuTypeSelect = ({ value, onChange, className }: MenuTypeSelectProps) => {
  const menuClasses = useMenusStyles();

  return (
    <TextField
      select
      label={MENU_TYPE}
      margin="dense"
      inputProps={{
        "aria-label": MENU_TYPE,
      }}
      value={value ?? ""}
      SelectProps={{
        classes: {
          select: menuClasses.selectRoot,
        },
      }}
      className={className}
      placeholder={MENU_TYPE}
    >
      {MENU_TYPE_OPTIONS.map(option => {
        return (
          <MenuItem
            key={option}
            value={option}
            onClick={() => onChange(option)}>
            {option}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default MenuTypeSelect;

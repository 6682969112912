import { MenuSelectionItemWithAppearance } from "../MenuSelection/utils";
import { RestaurantMenuLinkPlateItemFormFragment } from "../types";

export type RestaurantMenuLinkPlateItemWithAppearance = MenuSelectionItemWithAppearance<RestaurantMenuLinkPlateItemFormFragment>;

export const RESTAURANT_MENU_LINK_MENU_ITEM_APPEARANCE = {
  availableForOrder: true,
  allowSpecialRequests: true,
  maxAmount: null,
};

export const getRestaurantMenuLinkPlateItemsWithAppearance = (
  plateItems: readonly RestaurantMenuLinkPlateItemFormFragment[]
): readonly RestaurantMenuLinkPlateItemWithAppearance[] => {
  return plateItems.map(plateItem => {
    return {
      ...plateItem,
      menuItemAppearance: RESTAURANT_MENU_LINK_MENU_ITEM_APPEARANCE,
    };
  });
};

import { ActivityTypeSchema, FormFieldsSchema, MealTypeSchema, RMRMethodSchema, SexTypeSchema } from "@notemeal/validators";
import { z } from "zod";
import { v21 } from "./validationV30";

// V2.1 MealOption adds optional name

export const MealOptionSchema = z.object({
  id: z.string().uuid(),
  note: z.string().nullable(),
  name: z.string().nullable(),
  position: z.number().gte(0),
  servingAmounts: z
    .object({
      amount: z.number(),
      position: z.number().gte(0),
      servingId: z.string().uuid(),
    })
    .array(),
});

export const MealSchema = z.object({
  id: z.string().uuid(),
  choRatio: z.number(),
  choRatioInput: z.string(),
  fatRatio: z.number(),
  fatRatioInput: z.string(),
  meal: z.object({
    name: z.string(),
    type: MealTypeSchema,
    start: z.string(),
    end: z.string(),
  }),
  mealOptions: MealOptionSchema.array(),
  proRatio: z.number(),
  proRatioInput: z.string(),
});

export const FormFieldsAnthroSchema = z.object({
  __typename: z.literal("FormFields"),
  formFields: FormFieldsSchema.array(),
  sampleWeight: z.number(),
  sampleWeightInput: z.string(),
  samplePercentBodyFat: z.number().nullable(),
  samplePercentBodyFatInput: z.string(),
  sampleHeight: z.number().nullable(),
  sampleHeightInput: z.string(),
  sampleAge: z.number().nullable(),
  sampleAgeInput: z.string(),
  sampleSex: SexTypeSchema,
});

export const TemplateAnthroSchema = z.object({
  __typename: z.literal("Template"),
  height: z.number().nullable(),
  heightInput: z.string(),
  percentBodyFat: z.number().nullable(),
  percentBodyFatInput: z.string(),
  weight: z.number(),
  weightInput: z.string(),
  sampleAge: z.number().nullable(),
  sampleAgeInput: z.string(),
  sampleSex: SexTypeSchema,
});

export const AnthroStateSchema = z.union([FormFieldsAnthroSchema, TemplateAnthroSchema]);

export const MacroStateSchema = z.object({
  percentInput: z.string(),
  gPerKGInput: z.string(),
  percent: z.number(),
  gPerKg: z.number(),
  usesPercent: z.boolean(),
});

export const MealPlanTemplateGoalStateSchema = z.object({
  __typename: z.literal("MealPlanTemplateGoalState"),
  goalTypeId: z.string(),
  id: z.string().uuid(),
  kcalOffset: z.number(),
  kcalOffsetInput: z.string(),
  name: z.string(),
});

export const calorieBudgetPayload = z.object({
  rmrMethod: RMRMethodSchema,
  activityFactor: z.number(),
  activityFactorInput: z.string(),
  goals: MealPlanTemplateGoalStateSchema.array(),
  selectedPreviewGoal: MealPlanTemplateGoalStateSchema.nullable(),
});

export const MacroProtocolSchema = z.object({
  cho: MacroStateSchema,
  pro: MacroStateSchema,
  fat: MacroStateSchema,
  useExistingAnthropometry: z.boolean(),
  anthropometry: AnthroStateSchema,
  calorieBudget: calorieBudgetPayload,
  usingCalorieBudget: z.boolean(),
  showInKg: z.boolean(),
});

export const ActivitySchema = z.object({
  id: z.string().uuid(),
  end: z.string(),
  name: z.string(),
  start: z.string(),
  type: ActivityTypeSchema,
});

export const IdAndNameSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
});

export const FoodPreferencesSchema = z.object({
  promotedFoods: IdAndNameSchema.array(),
  avoidedFoods: IdAndNameSchema.array(),
  promotedFoodGroups: IdAndNameSchema.array(),
  avoidedFoodGroups: IdAndNameSchema.array(),
});

export const MacroMealPlanTemplateEditStateSchemaV21 = z.object({
  version: z.literal(v21),
  __typename: z.literal("Edit"),
  id: z.string().uuid(),
  name: z.string(),
  description: z.string().nullable(),
  macroProtocol: MacroProtocolSchema,
  meals: MealSchema.array(),
  activities: ActivitySchema.array(),
  foodPreferences: FoodPreferencesSchema,
});

import { EditExchangeMealTemplatesMutationVariables } from "../types";
import { ExchangeMealPlan } from "../MealPlan/types";

export const createDeselectNegativeFoodsMutationVars = (mealPlan: ExchangeMealPlan): EditExchangeMealTemplatesMutationVariables => {
  const exchanges = mealPlan.exchangeSet.exchanges;
  const avoidedFoodGroupIds = mealPlan.avoidedFoodGroups.flatMap(fg => fg.foods.map(f => f.id));
  const avoidedFoodIds = mealPlan.avoidedFoods.map(f => f.id);
  const dislikedFoodGroupIds = mealPlan.athlete.dislikedFoodGroups.flatMap(fg => fg.foods.map(f => f.id));
  const dislikedFoodIds = mealPlan.athlete.dislikedFoods.map(f => f.id);

  const negativeFoodIds = [...avoidedFoodGroupIds, ...avoidedFoodIds, ...dislikedFoodGroupIds, ...dislikedFoodIds];

  const mealTemplateInput = mealPlan.mealTemplates.map(({ id, exchangeTargets }) => {
    const exchangeTargetsInput = exchanges.map(({ id: exchangeId, exchangeServingList }) => {
      const matchingExchangeTarget = exchangeTargets.find(et => et.exchange.id === exchangeId);
      const amount = matchingExchangeTarget ? matchingExchangeTarget.amount : 0;
      const existingPickListServingIds = matchingExchangeTarget ? matchingExchangeTarget.pickListServingIds : null;
      const pickListServingIds = exchangeServingList.servingAmounts
        .map(sa => sa.serving)
        .filter(s => {
          return !negativeFoodIds.includes(s.foodOrRecipe.id) && (!existingPickListServingIds || existingPickListServingIds.includes(s.id));
        })
        .map(s => s.id);
      return { exchangeId, amount, pickListServingIds };
    });

    return { mealTemplateId: id, exchangeTargets: exchangeTargetsInput };
  });
  return { input: mealTemplateInput };
};

/*
 ************************************************************************************
 * Copyright (C) 2019 Openbravo S.L.U.
 * Licensed under the Apache Software License version 2.0
 * You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to  in writing,  software  distributed
 * under the License is distributed  on  an  "AS IS"  BASIS,  WITHOUT  WARRANTIES  OR
 * CONDITIONS OF ANY KIND, either  express  or  implied.  See  the  License  for  the
 * specific language governing permissions and limitations under the License.
 ************************************************************************************
 */

import { useEffect, useState } from "react";
import { EPSONTMT88VI, STARSP700, STARSML300, EPSONTML90, EPSONTML100 } from "./printers";
import { PrinterType } from "./PrinterType";
import { arrays8append } from "./typedarrays";

const ESCPOS = {
  encoderText: new TextEncoder(),
  NEW_LINE_EPSON: new Uint8Array([0x0d, 0x0a]),
  NEW_LINE_STAR: new Uint8Array([0x0d, 0x0a]),
  PARTIAL_CUT_EPSON: new Uint8Array([0x1b, 0x69]),
  PARTIAL_CUT_STAR: new Uint8Array([0x1b, 0x64, 0x03]),
  PARTIAL_CUT_BROTHER: new Uint8Array([0x1b, 0x69, 0x43, 0x01]),
  PARTIAL_CUT_STAR_SML300: new Uint8Array([0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x3d, 0x0a]),
  CHAR_SIZE_0: new Uint8Array([0x1d, 0x21, 0x00]),
  CHAR_SIZE_1: new Uint8Array([0x1d, 0x21, 0x01]),
  CHAR_SIZE_2: new Uint8Array([0x1d, 0x21, 0x30]),
  CHAR_SIZE_3: new Uint8Array([0x1d, 0x21, 0x31]),

  BOLD_SET: new Uint8Array([0x1b, 0x45, 0x01]),
  BOLD_RESET: new Uint8Array([0x1b, 0x45, 0x00]),
  UNDERLINE_SET: new Uint8Array([0x1b, 0x2d, 0x01]),
  UNDERLINE_RESET: new Uint8Array([0x1b, 0x2d, 0x00]),

  CENTER_JUSTIFICATION: new Uint8Array([0x1b, 0x61, 0x01]),
  LEFT_JUSTIFICATION: new Uint8Array([0x1b, 0x61, 0x00]),
  RIGHT_JUSTIFICATION: new Uint8Array([0x1b, 0x61, 0x02]),

  DRAWER_OPEN: new Uint8Array([0x1b, 0x70, 0x00, 0x32, -0x06]),
};

export interface WebPrinter {
  print: (doc: string) => Promise<void>;
  connect: (e: React.ChangeEvent<HTMLElement>) => void;
  disconnect: () => Promise<void>;
}

export const useWebPrinter = (printerType: PrinterType | null): WebPrinter | null => {
  const [device, setDevice] = useState(() => (printerType ? printerType.createWebDevice() : null));

  const NEW_LINE = printerType?.name === STARSP700.name ? ESCPOS.NEW_LINE_STAR : ESCPOS.NEW_LINE_EPSON;

  useEffect(() => {
    if (printerType) {
      const dev = printerType.createWebDevice();
      dev.request().then(() => setDevice(dev));
    } else {
      console.log("Setting printer to 'null'");
      setDevice(null);
    }
  }, [printerType]);

  const disconnect = async () => {
    setDevice(null);
  };
  if (!device) {
    return null;
  }
  const connect = async (e: React.ChangeEvent<HTMLElement>) => {
    // typed strongly b/c the following will fail if not in context of User gesture
    if (!device.connected()) {
      await device.request();
    }
  };
  const print = async (doc: string): Promise<void> => {
    console.log("Printing doc.");
    try {
      if (!device.connected()) {
        await device.request();
      } else {
        console.log("Device is connected.");
      }
    } catch (error) {
      console.error(error);
      alert("Failed to print! Check console...");
    }

    const parser: DOMParser = new DOMParser();
    const escapedDoc = doc.replace(/&/g, "+");
    const dom: Document = parser.parseFromString(escapedDoc, "application/xml");

    if (dom.documentElement.nodeName === "parsererror") {
      throw new Error("Error while parsing XML template.");
    }

    const printerdocs: Uint8Array = await processDOM(dom);
    if (printerdocs.length > 0) {
      console.log("SendingData.");
      await device.sendData(printerdocs);
      console.log("sendData returned.");
    } else {
      console.log("Empty printerdocs!");
    }
  };

  const processDOM = async (dom: Document): Promise<Uint8Array> => {
    for (let el of dom.children as any) {
      if (el.nodeName === "output") {
        return await processOutput(el);
      }
    }
    return new Uint8Array();
  };

  const processOutput = async (dom: Element): Promise<Uint8Array> => {
    let output = new Uint8Array();
    for (let el of dom.children as any) {
      if (el.nodeName === "ticket") {
        output = arrays8append(output, await processTicket(el));
      } else if (el.nodeName === "opendrawer") {
        output = arrays8append(output, ESCPOS.DRAWER_OPEN);
      }
    }
    return output;
  };

  const processTicket = async (dom: Element): Promise<Uint8Array> => {
    let output = new Uint8Array();
    for (let el of dom.children as any) {
      if (el.nodeName === "line") {
        output = arrays8append(output, await processLine(el));
        output = arrays8append(output, NEW_LINE);
      }
    }
    output = arrays8append(output, NEW_LINE);
    output = arrays8append(output, NEW_LINE);
    output = arrays8append(output, NEW_LINE);
    if (printerType?.name === STARSP700.name) {
      output = arrays8append(output, ESCPOS.PARTIAL_CUT_STAR);
    } else if (printerType?.name === STARSML300.name) {
      // Print a bunch of ============ b/c there's no autocutter!
      output = arrays8append(output, ESCPOS.PARTIAL_CUT_STAR_SML300);
      output = arrays8append(output, NEW_LINE);
      output = arrays8append(output, NEW_LINE);
    } else if (printerType?.name === EPSONTMT88VI.name || printerType?.name === EPSONTML90.name || printerType?.name === EPSONTML100.name) {
      // EPSON
      output = arrays8append(output, NEW_LINE);
      output = arrays8append(output, ESCPOS.PARTIAL_CUT_EPSON);
    } else {
      output = arrays8append(output, NEW_LINE);
      output = arrays8append(output, ESCPOS.PARTIAL_CUT_BROTHER);
    }
    return output;
  };

  const processLine = async (dom: Element): Promise<Uint8Array> => {
    let output: Uint8Array = new Uint8Array();
    const fontsize: string | null = dom.getAttribute("size");

    if (fontsize === "1") {
      output = arrays8append(output, ESCPOS.CHAR_SIZE_1);
    } else if (fontsize === "2") {
      output = arrays8append(output, ESCPOS.CHAR_SIZE_2);
    } else if (fontsize === "3") {
      output = arrays8append(output, ESCPOS.CHAR_SIZE_3);
    } else {
      output = arrays8append(output, ESCPOS.CHAR_SIZE_0);
    }
    for (let el of dom.children as any) {
      if (el.nodeName === "text") {
        const txt = el.textContent;

        if (txt) {
          const bold = el.getAttribute("bold");
          const uderline = el.getAttribute("underline");

          if (bold === "true") {
            output = arrays8append(output, ESCPOS.BOLD_SET);
          }
          if (uderline === "true") {
            output = arrays8append(output, ESCPOS.UNDERLINE_SET);
          }
          output = arrays8append(output, ESCPOS.encoderText.encode(txt));
          if (bold === "true") {
            output = arrays8append(output, ESCPOS.BOLD_RESET);
          }
          if (uderline === "true") {
            output = arrays8append(output, ESCPOS.UNDERLINE_RESET);
          }
        }
      }
    }
    return output;
  };
  return {
    print,
    connect,
    disconnect,
  };
};

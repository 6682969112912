import { useHasFeature } from "@notemeal/shared/ui/Feature";
import React, { ReactNode, createContext, useContext, useEffect } from "react";
import { useCreateChatClient } from "stream-chat-react";
import { useSnackbar } from "../components/Snackbar/SnackbarContext";
import { MeForStreamChatQuery } from "../types";
import { useUser } from "../utils/tokens";

interface StartChatArgs {
  withUserId: string;
  name: string;
}

export interface StreamChatContextType {
  client: ReturnType<typeof useCreateChatClient>;
  isInitialized: boolean;
  channelFilters: any | undefined;
  channel: any | undefined;
  setChannel: (channel: any | null) => void;
  startChat: (args: StartChatArgs) => Promise<void>;
  totalUnreadCount: number;
}

export const StreamChatContext = createContext<StreamChatContextType | null>(null);

export const StreamChatProvider = ({
  children,
  meForStreamChatResult,
}: {
  children: ReactNode;
  meForStreamChatResult: MeForStreamChatQuery;
}) => {
  const hasMessagingEnabled = useHasFeature("messaging");
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [channelFilters, setChannelFilters] = React.useState<any | undefined>();
  const [channel, setChannel] = React.useState<any | undefined>();
  const [totalUnreadCount, setTotalUnreadCount] = React.useState(0);

  const user = useUser();
  const { setMessage } = useSnackbar();

  const client = useCreateChatClient({
    apiKey: process.env.REACT_APP_STREAM_CHAT_API_KEY ?? "",
    tokenOrProvider: meForStreamChatResult.myStreamChatToken,
    userData: { id: user?.id ?? "" },
  });

  useEffect(() => {
    if (hasMessagingEnabled && meForStreamChatResult && client) {
      const connect = async () => {
        const userId = meForStreamChatResult.myUser.id;

        await client.connectUser({ id: userId }, meForStreamChatResult.myStreamChatToken);

        const filters = {
          type: "messaging",
          members: { $in: [userId] },
          teamworksContext: { $eq: "nutrition" },
          nutritionOrgId: { $eq: user?.orgMembership?.org.id },
        };

        setChannelFilters(filters);
        const channels = await client.queryChannels(filters, { last_message_at: -1, limit: 1 });

        setChannel(channels[0]);
        setTotalUnreadCount((client.user?.total_unread_count as number | undefined) || 0);

        client.on("notification.mark_read", async () => {
          const { total_unread_count: newCount } = await client.getUnreadCount();
          setTotalUnreadCount(newCount);
        });

        client.on("notification.message_new", async () => {
          const { total_unread_count: newCount } = await client.getUnreadCount();
          setTotalUnreadCount(newCount);
        });

        setIsInitialized(true);
      };

      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meForStreamChatResult, client]);

  const startChat = async ({ withUserId, name }: StartChatArgs) => {
    if (!client) {
      setMessage("error", `We encountered an unexpected error, please refresh the page and try again.`);
      return;
    }

    const myUserId = meForStreamChatResult?.myUser.id ?? "";
    try {
      const channel = client.channel("messaging", {
        members: [myUserId, withUserId],
        teamworksContext: "nutrition",
        nutritionOrgId: user?.orgMembership?.org.id,
        team: user?.orgMembership?.org.id,
      });

      await channel.create();
      setChannel(channel);
    } catch {
      setMessage("error", `Unable to start chat as ${name} is not on the most updated of Teamworks Nutrition`);
    }
  };

  return (
    <StreamChatContext.Provider value={{ isInitialized, client, totalUnreadCount, channelFilters, startChat, channel, setChannel }}>
      {children}
    </StreamChatContext.Provider>
  );
};

export const useStreamChat = () => {
  const context = useContext(StreamChatContext);

  return (
    context || {
      client: null,
      isInitialized: false,
      channelFilters: null,
      setChannel: () => {},
      startChat: async () => Promise.resolve(),
      totalUnreadCount: 0,
      channel: null,
    }
  );
};

import { Reference } from "@apollo/client";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Card, CardActionArea, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";

import ScheduleModal from "../../../../components/Schedule/Modal";
import { ScheduleModalEditorState } from "../../../../reducers/ScheduleModalEditor";
import { initialScheduleModalEditorState, scheduleModalEditorStateToCreateInput } from "../../../../utils/ScheduleModalEditor";

import { useCreateScheduleMutation } from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
    card: {
      height: "60%",
      width: "60%",
    },
    cardTitle: {
      marginBottom: theme.spacing(),
    },
    cardActionArea: {
      height: "100%",
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "column",
    },
  })
);

interface TeamSchedulesEmptyContentProps {
  teamId: string;
}

const TeamSchedulesEmptyContent = ({ teamId }: TeamSchedulesEmptyContentProps) => {
  const classes = useStyles();
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const newScheduleModalState = initialScheduleModalEditorState("", true, [], []);

  const [createSchedule] = useCreateScheduleMutation({
    update: (cache, { data }) => {
      if (data) {
        const __ref = cache.identify(data.createSchedule.schedule);
        if (__ref) {
          cache.modify({
            id: `Team:${teamId}`,
            fields: {
              schedules: (schedules: Reference[]): Reference[] => [...schedules, { __ref }],
            },
          });
        }
      }
    },
    onCompleted: () => setScheduleModalOpen(false),
  });

  const handleSave = (state: ScheduleModalEditorState) => {
    createSchedule({
      variables: {
        input: {
          ...scheduleModalEditorStateToCreateInput(state),
          name: state.scheduleName,
          teamId,
          activityFactor: state.activityFactor,
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card} raised>
        <CardActionArea className={classes.cardActionArea} onClick={() => setScheduleModalOpen(true)}>
          <Typography variant="h3" className={classes.cardTitle}>
            New Team Schedule
          </Typography>
          <AddCircleIcon fontSize="large" />
        </CardActionArea>
      </Card>
      {scheduleModalOpen && (
        <ScheduleModal
          open={scheduleModalOpen}
          onClose={() => setScheduleModalOpen(false)}
          onSave={handleSave}
          numberMealPlans={0}
          initialState={newScheduleModalState}
          title="New Schedule"
        />
      )}
    </div>
  );
};

export default TeamSchedulesEmptyContent;

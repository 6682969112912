import { SxProps } from "@mui/material";
import { addDays } from "date-fns";
import React from "react";
import CalendarWeekNavigation from "../../components/Calendar/Week/Navigation";

interface MenusWeekNavigationProps {
  startOfWeekDate: Date;
  onChange: (date: Date) => void;
  sx?: SxProps;
}

const MenusWeekNavigation = ({ startOfWeekDate, onChange, sx }: MenusWeekNavigationProps) => {
  const makeOnNavigate = (dir: "backwards" | "forwards") => () => {
    onChange(addDays(startOfWeekDate, dir === "backwards" ? -7 : 7));
  };

  return (
    <CalendarWeekNavigation
      startOfWeekDate={startOfWeekDate}
      sx={sx}
      onForwards={makeOnNavigate("forwards")}
      onBackwards={makeOnNavigate("backwards")}
    />
  );
};

export default MenusWeekNavigation;

import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import Loading from "@notemeal/shared/ui/global/Loading";
import { parseDateWithoutTz, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { measurementConversionToMetric } from "@notemeal/shared/utils/macro-protocol";
import React, { ReactNode, useState } from "react";
import {
  AnthropometryEntryType,
  AthleteAnthropometryDocument,
  AthleteSummaryDocument,
  TeamAnthropometryDocument,
  useAllAthletesAndAnthropometryEntriesQuery,
  useCreateMetricAnthropometryEntriesMutation,
  useEditMetricAnthropometryEntriesMutation,
} from "../../../../types";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { teamToName } from "../Athlete/AthleteTabPanel";
import ImportAnthropometryInfoDialog, { FieldDefinition } from "../InfoDialog";
import ImportTabPanel, { ImportTabPanelProps } from "../TabPanel";
import { ICrudHandler } from "../types";
import { parseImportError } from "../utils";
import { ImportableAnthroEntry, Linkable, LinkableAthlete } from "./types";

export interface AnthropometryImportTabPanelProps
  extends Pick<
    ImportTabPanelProps<Linkable, ImportableAnthroEntry, LinkableAthlete>,
    "matchFields" | "loadAndParse" | "linkFields" | "linkOnFields" | "disableInsert"
  > {
  msg: ReactNode;
  fields: FieldDefinition[];
  useExistingWeight?: boolean;
}

export const AnthropometryImportTabPanel = ({
  matchFields,
  loadAndParse,
  linkFields,
  linkOnFields,
  disableInsert,
  msg,
  fields,
  useExistingWeight,
}: AnthropometryImportTabPanelProps) => {
  // TODO: metric update run phase - user chosen measurement system import
  // TODO: metric update - R&D metric import sources

  // using locale context breaks DXA imports for mass fields when the user is in a metric locale (i.e.en-GB)
  // We transform all DXA mass fields into pounds, regardless of locale
  // then this change assumes if the user is in metric locale that the data is already in metric so does not need converting
  // for now is fine since we don't support DXA imports for en-GB, however will need to be adjusted when we do
  const { isMetricLocale } = useLocaleContext();
  const { data, loading } = useAllAthletesAndAnthropometryEntriesQuery();
  const { setMessage } = useSnackbar();

  const [createMetricAnthropometryEntries] = useCreateMetricAnthropometryEntriesMutation({
    onError: e => setMessage("error", parseImportError(e)),
  });

  const [editMetricAnthropometryEntries] = useEditMetricAnthropometryEntriesMutation();

  const [infoOpen, setInfoOpen] = useState(false);
  if (!data || loading) {
    return <Loading progressSize="lg" />;
  }
  const existingRows = data.athletes.flatMap(({ anthropometryEntries, ...athlete }) => {
    return anthropometryEntries.map(({ datetime, ...ae }) => ({
      ...athlete,
      ...ae,
      teamName: teamToName(athlete.team),
      athleteId: athlete.id,
      datetime: parseDateWithoutTz(datetime),
      mostRecentHeight: athlete.mostRecentAnthropometryEntry ? athlete.mostRecentAnthropometryEntry.height : null,
    }));
    // TODO: teamName comes from TeamWithSportFragment, after it is added to query above
  });
  const existingAthletes = data.athletes.map(
    ({ firstName, secaUid, inbodyUid, lastName, sex, id, team, mostRecentAnthropometryEntry }) => ({
      firstName,
      mostRecentHeight: mostRecentAnthropometryEntry ? mostRecentAnthropometryEntry.height : null,
      lastName,
      sex,
      id,
      secaUid,
      inbodyUid,
      athleteId: id,
      teamName: teamToName(team),
    })
  );
  const teamIdsToRefetch = data.athletes.reduce((currTeamIds, ath) => {
    if (currTeamIds.some(id => id === ath.team.id)) {
      return currTeamIds;
    } else {
      return [...currTeamIds, ath.team.id];
    }
  }, [] as string[]);
  const teamRefetchQueries = teamIdsToRefetch.map(teamId => ({
    query: TeamAnthropometryDocument,
    variables: { id: teamId },
  }));
  const handleInsertAnthropometryEntries = ({ matches, onCacheUpdate }: ICrudHandler<ImportableAnthroEntry & LinkableAthlete>) => {
    const input = matches.map(
      ({
        row: {
          height,
          leanBodyMass,
          bodyFatMass,
          weight,
          dryLeanMass,
          trunkFat,
          skeletalMuscleMass,
          mostRecentHeight,
          firstName,
          lastName,
          teamName,
          secaUid,
          inbodyUid,
          athleteId,
          ...anthropometryEntry
        },
      }) => {
        return {
          anthropometryEntry: {
            ...anthropometryEntry,
            heightInCm: measurementConversionToMetric(isMetricLocale, height || mostRecentHeight, "length"),
            leanBodyMassInKg: measurementConversionToMetric(isMetricLocale, leanBodyMass, "weight"),
            bodyFatMassInKg: measurementConversionToMetric(isMetricLocale, bodyFatMass, "weight"),
            weightInKg: measurementConversionToMetric(isMetricLocale, weight, "weight")!,
            dryLeanMassInKg: measurementConversionToMetric(isMetricLocale, dryLeanMass, "weight"),
            trunkFatInKg: measurementConversionToMetric(isMetricLocale, trunkFat, "weight"),
            skeletalMuscleMassInKg: measurementConversionToMetric(isMetricLocale, skeletalMuscleMass, "weight"),
            datetime: serializeDate(anthropometryEntry.datetime),
          },
          athleteId,
        };
      }
    );

    //TODO: We could just wipe the cache instead of refetching 500+ records here!
    //TODO: followup - is there a reason we haven't done this? -NE
    const anthroRefetchQueries = matches.flatMap(({ row: { athleteId } }) => [
      { query: AthleteAnthropometryDocument, variables: { id: athleteId } },
      { query: AthleteSummaryDocument, variables: { id: athleteId } },
    ]);

    createMetricAnthropometryEntries({
      variables: {
        input,
      },
      update: onCacheUpdate,
      refetchQueries: [...anthroRefetchQueries, ...teamRefetchQueries],
    });
  };

  const handleUpdateAnthropometryEntries = ({ matches, onCacheUpdate }: ICrudHandler<ImportableAnthroEntry & LinkableAthlete>) => {
    //  matches.matchedRows should be a single list!
    const input = matches.flatMap(
      ({
        row: {
          firstName,
          lastName,
          teamName,
          height: heightFromFile,
          leanBodyMass,
          bodyFatMass,
          weight: anthroWeight,
          dryLeanMass,
          trunkFat,
          skeletalMuscleMass,
          athleteId,
          secaUid,
          inbodyUid,
          mostRecentHeight,
          ...anthropometryEntry
        },
        matchedRows,
      }) => {
        const { id, height: existingHeight, weight: existingWeight, type } = matchedRows[0];
        return {
          id,
          anthropometryEntry: {
            ...anthropometryEntry,
            heightInCm: measurementConversionToMetric(isMetricLocale, heightFromFile || existingHeight || mostRecentHeight, "length"),
            leanBodyMassInKg: measurementConversionToMetric(isMetricLocale, leanBodyMass, "weight"),
            bodyFatMassInKg: measurementConversionToMetric(isMetricLocale, bodyFatMass, "weight"),
            weightInKg: measurementConversionToMetric(isMetricLocale, useExistingWeight ? existingWeight : anthroWeight, "weight")!,
            dryLeanMassInKg: measurementConversionToMetric(isMetricLocale, dryLeanMass, "weight"),
            trunkFatInKg: measurementConversionToMetric(isMetricLocale, trunkFat, "weight"),
            skeletalMuscleMassInKg: measurementConversionToMetric(isMetricLocale, skeletalMuscleMass, "weight"),
            type: type as AnthropometryEntryType,
            datetime: serializeDate(anthropometryEntry.datetime),
            //TODO: these two type casts should be handled by matchedRows being the full type on IMatchRe  sult
          },
        };
      }
    );
    editMetricAnthropometryEntries({
      variables: {
        input,
      },
      update: onCacheUpdate,
    });
  };
  return (
    <ImportTabPanel<Linkable, ImportableAnthroEntry, LinkableAthlete>
      groupKey="teamName"
      entityName={"Anthropometry Entrie"}
      linkToEntityName={"Athlete"}
      updateSelectedMatches={handleUpdateAnthropometryEntries}
      insertSelectedMatches={handleInsertAnthropometryEntries}
      matchableRows={existingRows}
      matchFields={matchFields}
      linkableRows={existingAthletes}
      linkFields={linkFields}
      /* We include firstName and lastName, b/c these get pick()ed on ImportLinkModal */
      linkOnFields={linkOnFields}
      loadAndParse={loadAndParse}
      disableInsert={disableInsert}
    >
      <span>
        {msg}{" "}
        <IconButton
          sx={{ color: "common.black" }}
          onClick={() => setInfoOpen(true)}
          aria-label="info-popover"
          size="small">
          <InfoIcon fontSize="small" />
        </IconButton>
      </span>
      <ImportAnthropometryInfoDialog
        open={infoOpen}
        setOpen={setInfoOpen}
        fields={fields} />
    </ImportTabPanel>
  );
};

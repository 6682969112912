import { ResolverContext } from "./types";
import { makeFragmentFuncsWithInit } from "./utils";
import {
  FullExchangeSetWithoutExchangeRatiosFragment,
  FullExchangeSetFragment,
  FullExchangeSetFragmentDoc,
  FoodOrRecipeExchangeRatiosFragment,
  ExchangeRatioFragment,
  ServingWithFoodOrRecipeFragment,
  FoodOrRecipeExchangeRatiosFragmentDoc,
  RecipeWithIngredientsFragment,
  FullServingFragment,
} from "../../types";
import { pickClosestExchangeAmounts } from "../../Exchange/utils";
import { Reference } from "@apollo/client";

const initExchangeSet = (exchangeSet: FullExchangeSetWithoutExchangeRatiosFragment): FullExchangeSetFragment => {
  return { ...exchangeSet, foodOrRecipeExchangeRatios: [] };
};

const { getFragmentOrInit } = makeFragmentFuncsWithInit("ExchangeSet", "FullExchangeSet", FullExchangeSetFragmentDoc, initExchangeSet);

export const resolverMap = {
  foodOrRecipeExchangeRatios: (
    src: FullExchangeSetFragment,
    // eslint-disable-next-line @typescript-eslint/ban-types
    args: {},
    context: ResolverContext
  ): readonly FoodOrRecipeExchangeRatiosFragment[] => {
    return getFragmentOrInit(src, context).foodOrRecipeExchangeRatios;
  },
};

export const getFoodOrRecipeExchangeRatios = (
  exchangeSet: FullExchangeSetFragment,
  serving: FullServingFragment,
  descendantRecipes: readonly RecipeWithIngredientsFragment[],
  context: ResolverContext
): readonly ExchangeRatioFragment[] => {
  const { foodOrRecipe } = serving;
  const matchingFoodOrRecipeExchangeRatios = exchangeSet.foodOrRecipeExchangeRatios.find(
    ({ foodOrRecipeId }) => foodOrRecipeId === foodOrRecipe.id
  );
  if (matchingFoodOrRecipeExchangeRatios) {
    return matchingFoodOrRecipeExchangeRatios.exchangeRatios;
  } else {
    const cache = context.cache;
    const macros = serving.macros;
    const exchangeRatios = pickClosestExchangeAmounts(exchangeSet.exchanges, foodOrRecipe.exchangeTypes || [], macros);
    const ratiosId = `FoodOrRecipeExchangeRatios:${foodOrRecipe.id}`;
    cache.writeFragment({
      fragment: FoodOrRecipeExchangeRatiosFragmentDoc,
      fragmentName: "FoodOrRecipeExchangeRatios",
      id: ratiosId,
      data: {
        id: foodOrRecipe.id,
        foodOrRecipeId: foodOrRecipe.id,
        exchangeRatios,
        __typename: "FoodOrRecipeExchangeRatios",
      },
    });
    const exchangeSetIdentity = cache.identify(exchangeSet);
    if (exchangeSetIdentity) {
      cache.modify({
        id: exchangeSetIdentity,
        fields: {
          foodOrRecipeExchangeRatios: (ratios: Reference[]) => {
            return ratios.concat([{ __ref: ratiosId }]);
          },
        },
      });
    }
    return exchangeRatios;
  }
};

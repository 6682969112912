import React, { useMemo } from "react";

import MissingOrdersList from "./List";

import { sortByFn } from "@notemeal/utils/sort";
import { MealMenuMissingOrdersFragment } from "../../../types";
import { getCompareString, sortGroupAndFilterAthletesByTeam } from "../../Tags/utils";
import { getAllOrdersFromMealMenu } from "../utils";

interface MissingOrdersDialogContentProps {
  searchQuery: string;
  mealMenu: MealMenuMissingOrdersFragment;
}

const MissingOrdersDialogContent = ({ mealMenu, searchQuery }: MissingOrdersDialogContentProps) => {
  // TODO: Multiple Orders do we want to indicate if a user/athlete has multiple orders FE
  const allOrders = getAllOrdersFromMealMenu(mealMenu);
  const athleteIdsWithOrders = useMemo(() => new Set(allOrders.flatMap(o => o.athlete?.id || [])), [allOrders]);

  const athletes = mealMenu.athletes;

  const groupedMissingOrderAthletes = useMemo(
    () =>
      sortGroupAndFilterAthletesByTeam({
        athletes,
        sortFn: athleteList => sortByFn(athleteList, getCompareString),
        filterFn: athletesList => athletesList.filter(a => getCompareString(a).includes(searchQuery) && !athleteIdsWithOrders.has(a.id)),
      }),
    [athletes, athleteIdsWithOrders, searchQuery]
  );

  return (
    <>
      {groupedMissingOrderAthletes.map(t => (
        <MissingOrdersList
          key={t.id}
          team={t}
          athletes={t.athletes.filter(
            ath => !searchQuery || `${ath.lastName}, ${ath.firstName}`.toLowerCase().includes(searchQuery.toLowerCase())
          )}
        />
      ))}
    </>
  );
};

export default MissingOrdersDialogContent;

import HelpIcon from "@mui/icons-material/Help";
import { Tooltip } from "@mui/material";
import React from "react";

interface HelpTipIconProps {
  tip: string;
  tipMarginTop?: number;
}

const HelpTipIcon = ({ tip, tipMarginTop = 0 }: HelpTipIconProps) => {
  return (
    <Tooltip title={tip}>
      <HelpIcon sx={{ ml: 2, mt: tipMarginTop, fontSize: "24px" }} />
    </Tooltip>
  );
};

export default HelpTipIcon;

import { Box, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { EditCalorieBudgetState, EditMacroProtocolAction } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import { getMealPlanRmrErrors } from "@notemeal/shared/ui/utils/macroProtocolState";
import {
  AnthropometrySnapshot,
  RmrCaloriesArgs,
  getRmrCalories,
  getTotalCalories,
  hasRmrErrors,
  rmrMethodToText,
} from "@notemeal/shared/utils/macro-protocol";
import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { Dispatch } from "react";
import { GoalTypeFragment, RmrMethod } from "../../../types";
import { RMR_METHOD_ORDER } from "../utils";
import RmrMethodMenuItemDisabled from "./RmrMethodMenuItemDisabled";

const COLUMN1_WIDTH = 150;
const COLUMN2_WIDTH = 160;
const COLUMN3_WIDTH = 125;

const getRmrMethodMenuItem = (rmrMethod: RmrMethod, anthropometrySnapshot: AnthropometrySnapshot) => {
  const rmrErrors = getMealPlanRmrErrors(rmrMethod, anthropometrySnapshot);
  const text = rmrMethodToText(rmrMethod);

  if (rmrErrors.length === 0) {
    return (
      <MenuItem value={rmrMethod} key={rmrMethod}>
        {text}
      </MenuItem>
    );
  } else {
    return <RmrMethodMenuItemDisabled
      key={rmrMethod}
      text={text}
      disabledReasons={rmrErrors} />;
  }
};

interface MacroProtocolFormCalorieBudgetProps {
  state: EditCalorieBudgetState;
  dispatch: Dispatch<EditMacroProtocolAction>;
  anthropometrySnapshot: AnthropometrySnapshot;
  goalTypes: readonly GoalTypeFragment[];
  disabled: boolean;
}

// Should receive anthro snapshot w/ weight target include already
const MacroProtocolFormCalorieBudget = ({
  state,
  anthropometrySnapshot,
  dispatch,
  goalTypes,
  disabled,
}: MacroProtocolFormCalorieBudgetProps) => {
  const { rmrMethod, activityFactor, rmrCaloriesInput, activityFactorInput, goalSnapshot, kcalOffsetInput } = state;

  // RMR calculations are used several times here so we check for errors first to not blow up the app
  const rmrCaloriesArgs: RmrCaloriesArgs = { rmrMethod, ...anthropometrySnapshot };
  if (rmrMethod && hasRmrErrors(rmrCaloriesArgs)) {
    return (
      <Typography
        border={1}
        borderColor="error.main"
        sx={{ p: 2 }}
        variant="body2">
        Unable to edit due to RMR errors
      </Typography>
    );
  }

  const rmrCalories = rmrMethod ? Math.round(getRmrCalories(rmrCaloriesArgs)) : state.rmrCalories ? state.rmrCalories : 0;
  const activityFactorCalories = Math.round((activityFactor - 1) * rmrCalories);
  const totalCalories = Math.round(getTotalCalories(anthropometrySnapshot, state));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      {/* RMR Method */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }}>RMR Method</Typography>
        <Select
          sx={{ width: COLUMN2_WIDTH }}
          value={disabled ? "" : rmrMethod || "Custom"}
          disabled={disabled}
          onChange={event => {
            const rmrMethod = event.target.value === "Custom" ? null : (event.target.value as RmrMethod);
            trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Change RMR Method", { rmrMethod });
            dispatch({
              type: "CHANGE_RMR_METHOD",
              payload: rmrMethod,
            });
          }}
        >
          {RMR_METHOD_ORDER.map(rmrMethod => getRmrMethodMenuItem(rmrMethod, anthropometrySnapshot))}
          {
            <MenuItem value="Custom" key="Custom">
              Custom
            </MenuItem>
          }
        </Select>
        {disabled ? null : rmrMethod === null ? (
          <TextField
            value={rmrCaloriesInput}
            type="number"
            inputProps={{ min: 0, max: 50000, step: 50 }}
            onChange={e =>
              dispatch({
                type: "CHANGE_RMR_CALORIES",
                payload: e.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" variant="filled">
                  kcal
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <Typography>{rmrCalories} kcal</Typography>
        )}
      </Box>

      {/* Activity Factor */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }}>Activity Factor</Typography>
        <TextField
          sx={{ mt: 0, width: COLUMN2_WIDTH }}
          disabled={disabled}
          type="number"
          inputProps={{ min: 1, max: 10, step: 0.05 }}
          value={!disabled && activityFactorInput}
          onChange={event => {
            trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Change Activity Factor", { activityFactor: event.target.value });
            dispatch({
              type: "CHANGE_ACTIVITY_FACTOR",
              payload: event.target.value,
            });
          }}
        />
        <Typography>{!disabled && `${activityFactorCalories} kcal`}</Typography>
      </Box>

      {/* Goal Description */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }}>Weight Target Type</Typography>
        <Select
          sx={{ width: COLUMN2_WIDTH }}
          disabled={disabled}
          value={!disabled && goalSnapshot.type && goalSnapshot.type.id}
          onChange={event => {
            const goalType = goalTypes.find(gt => gt.id === event.target.value);
            if (!goalType) {
              return;
            }
            trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Change Goal", { goalType: goalType.name });
            dispatch({
              type: "CHANGE_GOAL_TYPE",
              payload: goalType,
            });
          }}
        >
          {goalTypes.map(gt => (
            <MenuItem key={gt.id} value={gt.id}>
              {gt.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          sx={{ mt: 0, width: COLUMN3_WIDTH }}
          type="number"
          inputProps={{ step: 50 }}
          value={disabled ? "" : goalSnapshot.kcalOffsetInput}
          onChange={e => {
            trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Change Kcal Offset", { goalKcalOffset: e.target.value });
            dispatch({
              type: "CHANGE_GOAL_KCAL_OFFSET",
              payload: e.target.value,
            });
          }}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" variant="filled">
                <Typography variant="body2">kcal</Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Kcal Offset */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }}>KCal Offset</Typography>
        <TextField
          sx={{ mt: 0, width: COLUMN2_WIDTH }}
          type="number"
          inputProps={{ step: 50 }}
          value={disabled ? "" : kcalOffsetInput}
          onChange={e =>
            dispatch({
              type: "CHANGE_KCAL_OFFSET",
              payload: e.target.value,
            })
          }
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" variant="filled">
                kcal
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Totals */}
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography sx={{ width: COLUMN1_WIDTH }} variant="body1Semibold">
          TOTAL
        </Typography>
        <Box sx={{ width: COLUMN2_WIDTH }} />
        <Typography variant="body1Semibold">{!disabled && `${totalCalories} kcal`}</Typography>
      </Box>
    </Box>
  );
};

export default MacroProtocolFormCalorieBudget;

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ExchangeTarget } from "@notemeal/shared/ui/ExchangeAmount/useExchangeTargets";
import { usePickListController } from "@notemeal/shared/ui/ExchangeAmount/usePickListController";
import { formatAmbiguousServingAmountWithTotalWeight, scaleServingAmount } from "@notemeal/shared/ui/ServingAmount/utils";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { FullServingAmountFragment } from "../../../../types";
import ItemAmountButtons from "../../../universal/ItemAmountButtons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      paddingTop: theme.spacing(1),
    },
    listItemSecondaryAction: {
      display: "flex",
      alignItems: "center",
    },
    amount: {
      minWidth: 24,
      textAlign: "center",
      padding: theme.spacing(0, 1),
    },
    secondaryAction: {
      paddingRight: 108,
    },
    incorrectTotal: {
      color: "red",
    },
    spacer: {
      flexGrow: 1,
    },
  })
);

interface MealOptionPickListModeDialogProps {
  open: boolean;
  onClose: () => void;
  exchangeTarget: ExchangeTarget;
  onPick: (servingAmounts: readonly FullServingAmountFragment[]) => void;
}

const MealOptionPickListModeDialog = ({ open, onClose, exchangeTarget, onPick }: MealOptionPickListModeDialogProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  const classes = useStyles();
  const { servingAmounts, pickList, onDecrement, onIncrement, exchangeAmountTotal } = usePickListController({
    exchangeTarget,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const pickListMatchingSearchTerm = pickList.filter(
    ({
      serving: {
        foodOrRecipe: { name },
      },
    }) => name.toLowerCase().search(searchTerm.toLowerCase()) !== -1
  );

  const handlePick = () => {
    onPick(servingAmounts);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth>
      <DialogTitle title="Servings" onClose={onClose} />
      <DialogContent>
        <Box sx={{}}>
          <span
            style={{
              color: greyscale[500],
            }}
          >
            {exchangeTarget.exchange.name}
          </span>
          : Pick {exchangeTarget.amount}
          <TextField
            className={classes.search}
            placeholder="Search for Servings"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Box>
        <List>
          {pickListMatchingSearchTerm.map(servingAmount => {
            const exchangeAmount = servingAmounts.find(sa => sa.serving.id === servingAmount.serving.id)?.exchangeAmount;
            const scaledServingAmount = exchangeAmount ? scaleServingAmount(servingAmount, exchangeAmount) : null;

            return (
              <ListItem
                divider
                key={servingAmount.id}
                button={!exchangeAmount as any} // https://github.com/mui-org/material-ui/issues/14971
                dense
                disableGutters
                onClick={() => {
                  if (!exchangeAmount) {
                    onIncrement(servingAmount.serving);
                  }
                }}
                classes={{
                  secondaryAction: classes.secondaryAction,
                }}
              >
                <ListItemText
                  primary={
                    servingAmount.fromPickList
                      ? `${servingAmount.serving.foodOrRecipe.name} (${formatAmbiguousServingAmountWithTotalWeight(servingAmount)})`
                      : servingAmount.serving.foodOrRecipe.name
                  }
                  primaryTypographyProps={{}}
                  secondary={
                    !scaledServingAmount
                      ? undefined
                      : servingAmount.fromPickList
                      ? `Total: ${formatAmbiguousServingAmountWithTotalWeight(scaledServingAmount)}`
                      : formatAmbiguousServingAmountWithTotalWeight(servingAmount)
                  }
                />
                <ListItemSecondaryAction>
                  <ItemAmountButtons
                    amount={exchangeAmount}
                    onDecrement={() => onDecrement(servingAmount.serving)}
                    onIncrement={() => onIncrement(servingAmount.serving)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <DialogActions>
        <div className={classes.spacer} />
        <Typography className={exchangeAmountTotal !== exchangeTarget.amount ? classes.incorrectTotal : undefined}>
          {exchangeAmountTotal} / {exchangeTarget.amount}
        </Typography>
        <div className={classes.spacer} />
        <Button onClick={handlePick}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealOptionPickListModeDialog;

export const success = {
  25: "#E7F3EC",
  200: "#B6DCC5",
  700: "#0A893C",
};

export const informational = {
  25: "#E6EFFF",
  200: "#9BBEFF",
  700: "#045DFF",
};

export const warning = {
  25: "#FEF4E6",
  200: "#FCD39D",
  700: "#F79009",
  800: "#EF6820",
};

export const error = {
  25: "#FBECEB",
  200: "#EFB1AD",
  700: "#D83D32",
};

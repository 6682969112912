import SearchIcon from "@mui/icons-material/Search";
import { Box, Typography } from "@mui/material";
import React from "react";
import { paletteCommon } from "../../pages/Theme/Common/PaletteCommon";

interface NoFoodResultsAlertProps {
  children: React.ReactNode;
}
export const NoFoodResultsAlert = ({ children }: NoFoodResultsAlertProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 0.5, py: 5 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: paletteCommon.success.lighter,
          }}
        >
          <SearchIcon color="success" fontSize="large" />
        </Box>
        <Typography variant="body2Semibold">No results</Typography>
        <Typography
          color="mediumEmphasisText"
          variant="body2"
          align="center"
          sx={{ maxWidth: "286px" }}>
          Please try a different search or check "Branded" to see if the food is available
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";

interface LoadingButtonProps extends Omit<ButtonProps, "children"> {
  loading?: boolean;
  buttonText: string;
  icon?: React.ReactNode;
}
const LoadingButton = ({ loading, disabled, buttonText, variant = "contained", icon, ...restProps }: LoadingButtonProps) => {
  return (
    <Button
      disabled={disabled || loading}
      variant={variant}
      {...restProps}
      startIcon={icon}>
      <Box component="span" sx={loading ? { color: "transparent !important" } : {}}>
        {buttonText}
      </Box>
      {loading && (
        <CircularProgress
          size={20}
          thickness={5}
          sx={{
            position: "absolute",
            color: "inherit",
          }}
        />
      )}
    </Button>
  );
};

export default LoadingButton;

import { Box } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import { StreamChatProvider } from "../../contexts/StreamChatContext";
import { useMeForStreamChatQuery } from "../../types";
import { OrgRouter } from "./Org/OrgRouter";
import { OrgAndStaffRouterProps } from "./PrimaryNavUtils";

export const OrgApp = ({ orgs, onOrgChange }: OrgAndStaffRouterProps) => {
  const hasMessaging = useHasFeature("messaging");
  const { data, loading } = useMeForStreamChatQuery({ skip: !hasMessaging });

  if (loading) {
    return (
      <Box height={"100%"} width={"100%"}>
        <Loading />
      </Box>
    );
  } else if (hasMessaging && data) {
    return (
      <StreamChatProvider meForStreamChatResult={data}>
        <OrgRouter orgs={orgs} onOrgChange={onOrgChange} />
      </StreamChatProvider>
    );
  }

  return <OrgRouter orgs={orgs} onOrgChange={onOrgChange} />;
};

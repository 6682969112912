import { useApolloClient } from "@apollo/client";
import { ResolverContext } from "../graphql/resolvers/types";

// Could not find a way to get Apollo's getCacheKey outside of the resolver context, so the getCacheKey func here
// functions the same as the one from the library but that could change which would produce errors.
export const useApolloResolverContext = (): ResolverContext => {
  const client = useApolloClient();
  return {
    client,
    cache: client.cache,
    getCacheKey: ({ __typename, id }) => `${__typename}:${id}`,
  };
};

import Contacts from "@mui/icons-material/Contacts";
import { Badge, IconButton, TableCell, TableRow, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { RestaurantLocationFragment } from "../../../../types";

const useStyles = makeStyles(({ palette: { greyscale } }: Theme) =>
  createStyles({
    onHover: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: greyscale[100],
      },
    },
  })
);

interface RestaurantTableRowProps {
  restaurantLocation: RestaurantLocationFragment;
  onClick: (location: RestaurantLocationFragment) => void;
  setLocationIdForContactsDialog: (id: string) => void;
}

export const TableHeaderRow = () => (
  <TableRow>
    <TableCell>City Info</TableCell>
    <TableCell>Full Address</TableCell>
    <TableCell>Email</TableCell>
    <TableCell>Phone Number</TableCell>
    <TableCell>Contacts</TableCell>
    <TableCell>Delivery Locations</TableCell>
  </TableRow>
);

const RestaurantTableRow = ({ restaurantLocation, onClick, setLocationIdForContactsDialog }: RestaurantTableRowProps) => {
  const classes = useStyles();

  return (
    <TableRow
      className={classes.onHover}
      onClick={() => onClick(restaurantLocation)}
      key={restaurantLocation.id}>
      <TableCell scope="row">{restaurantLocation.cityInfo}</TableCell>
      <TableCell>{restaurantLocation.address?.displayName}</TableCell>
      <TableCell>{restaurantLocation.email}</TableCell>
      <TableCell>{restaurantLocation.phoneNumber}</TableCell>
      <TableCell>
        <IconButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setLocationIdForContactsDialog(restaurantLocation.id);
          }}
          size="large"
        >
          <Badge badgeContent={restaurantLocation.contacts.length} color="error">
            <Contacts fontSize="small" />
          </Badge>
        </IconButton>
      </TableCell>
      <TableCell>{restaurantLocation.deliveryLocations.map(dl => dl.name).join(", ")}</TableCell>
    </TableRow>
  );
};

export default RestaurantTableRow;

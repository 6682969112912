import { IFileImport } from "../../../../universal/Import/Button";
import { loadTsv } from "../../../../../utils/import/load";
import { parseDelimitedFile } from "../../../../../utils/import/parse";
import { ImportableAnthroEntry } from "../types";
import { subYears } from "date-fns";

interface MostRecentSecaFileImportFields {
  id: string;
  timestamp: string;
  "Absolute fat mass value": number;
  "Fat-free mass value": number;
  "Weight value": number;
  "Height value": number;
}

type MostRecentSecaFileImportFieldsRaw = {
  [key in keyof MostRecentSecaFileImportFields]: string;
};

export const iDXAMatchFields: (keyof ImportableAnthroEntry)[] = ["secaUid" as const, "datetime" as const];

type I = ImportableAnthroEntry;
export const msg = `
  Imports data from the SECA machine's export file using the athlete's SECA ID.
`;
export const numberFieldsWithoutHeight = [
  {
    fields: ["Absolute fat mass value" as const],
    required: true,
    importableField: "bodyFatMass" as const,
  },
  {
    fields: ["Fat-free mass value" as const],
    required: true,
    importableField: "leanBodyMass" as const,
  },
  {
    fields: ["Weight value" as const],
    required: true,
    importableField: "weight" as const,
  },
];
const numberFields = [
  ...numberFieldsWithoutHeight,
  {
    fields: ["Height value" as const],
    required: true,
    importableField: "height" as const,
    transform: (hgtFeetDecimal: number | null) => (hgtFeetDecimal ? hgtFeetDecimal * 12 : null),
  },
];
const stringFields = [
  {
    fields: ["id" as const],
    required: true,
    importableField: "secaUid" as const,
  },
  // {field: 'Dept ID' as const, importableField: 'teamName' as const},
]; // More on 'as const': https://stackoverflow.com/a/55387357/3923962

const dateFields = [
  {
    fields: ["timestamp" as const],
    required: true,
    importableField: "datetime" as const,
  },
];
export const fields = [...numberFields, ...stringFields, ...dateFields];

export const loadSecaRows = async ({ file, onError, locale }: IFileImport): Promise<I[] | null> => {
  // type T = MostRecentSecaFileImportFields;
  type F = MostRecentSecaFileImportFieldsRaw;
  const rows = await loadTsv<F>({ file, onError });
  if (rows === null) {
    return null;
  }
  const parsedRows = parseDelimitedFile<F, I>({
    rows,
    numberFields,
    dateFields,
    stringFields,
    onError,
    locale,
  });
  return parsedRows
    .filter(row => new Date(row.datetime) > subYears(new Date(), 5))
    .map(row => ({
      ...row,
      type: "bioelectricalImpedance",
      percentBodyFat: row.bodyFatMass ? (row.bodyFatMass / row.weight) * 100 : null,
    }));
};

import { ExchangeMealPlan } from "@notemeal/shared/ui/MealPlan/types";
import { calculateChoExchangeServings } from "@notemeal/shared/ui/utils/calculateExchangeTotals";
import { CopyableMealPlanFragment, MealPlanType } from "apps/web/src/types";

export type AutomationStrategy = "COPY" | "FIX_AND_ADJUST" | "ADJUST";

export interface MealPlanCopyOptionsFormState {
  copyFoodPreferences: boolean;
  copyPickListServingIds: boolean;
  automationStrategy: AutomationStrategy;
  vegetableServings: number;
  fruitServings: number;
  dairyServings: number;
  vegetableServingsInput: string;
  fruitServingsInput: string;
  dairyServingsInput: string;
}

interface ChangeAutomationStrategy {
  type: "CHANGE_AUTOMATION_LEVEL";
  payload: AutomationStrategy;
}

interface ChangeVegetableServings {
  type: "CHANGE_VEGETABLE_SERVINGS";
  payload: string;
}
interface ChangeFruitServings {
  type: "CHANGE_FRUIT_SERVINGS";
  payload: string;
}
interface ChangeDairyServings {
  type: "CHANGE_DAIRY_SERVINGS";
  payload: string;
}

interface ChangeCopyFoodPreferences {
  type: "CHANGE_COPY_FOOD_PREFERENCES";
  payload: boolean;
}

interface ChangeCopyPickListServingIds {
  type: "CHANGE_COPY_PICK_LIST_SERVING_IDS";
  payload: boolean;
}

export type MealPlanCopyOptionsFormAction =
  | ChangeAutomationStrategy
  | ChangeCopyFoodPreferences
  | ChangeVegetableServings
  | ChangeFruitServings
  | ChangeDairyServings
  | ChangeCopyPickListServingIds;

export const mealPlanCopyOptionsReducer = (
  state: MealPlanCopyOptionsFormState,
  action: MealPlanCopyOptionsFormAction
): MealPlanCopyOptionsFormState => {
  switch (action.type) {
    case "CHANGE_AUTOMATION_LEVEL":
      return { ...state, automationStrategy: action.payload };
    case "CHANGE_COPY_FOOD_PREFERENCES":
      return { ...state, copyFoodPreferences: action.payload };
    case "CHANGE_VEGETABLE_SERVINGS":
      return {
        ...state,
        vegetableServings: Number(action.payload),
        vegetableServingsInput: action.payload,
      };
    case "CHANGE_FRUIT_SERVINGS":
      return {
        ...state,
        fruitServings: Number(action.payload),
        fruitServingsInput: action.payload,
      };
    case "CHANGE_DAIRY_SERVINGS":
      return {
        ...state,
        dairyServings: Number(action.payload),
        dairyServingsInput: action.payload,
      };
    case "CHANGE_COPY_PICK_LIST_SERVING_IDS":
      return { ...state, copyPickListServingIds: action.payload };
  }
};

interface InitMealPlanCopyOptionsStateProps {
  mealPlan: CopyableMealPlanFragment;
  type: MealPlanType;
}

export const initMealPlanCopyOptionsState = ({ mealPlan, type }: InitMealPlanCopyOptionsStateProps): MealPlanCopyOptionsFormState => {
  if (type === "exchange") {
    const { fruitServings, vegetableServings, dairyServings } = calculateChoExchangeServings(mealPlan as ExchangeMealPlan);
    return {
      automationStrategy: "COPY",
      copyFoodPreferences: true,
      copyPickListServingIds: true,
      fruitServings,
      vegetableServings,
      dairyServings,
      fruitServingsInput: String(fruitServings),
      vegetableServingsInput: String(vegetableServings),
      dairyServingsInput: String(dairyServings),
    };
  } else {
    // Macro plan!
    return {
      automationStrategy: "COPY",
      copyFoodPreferences: false,
      copyPickListServingIds: true,
      fruitServings: 0,
      vegetableServings: 0,
      dairyServings: 0,
      fruitServingsInput: "",
      vegetableServingsInput: "",
      dairyServingsInput: "",
    };
  }
};

export const mealPlanCopyOptionsFormToSaveTooltips = (state: MealPlanCopyOptionsFormState) => {
  let tooltips: string[] = [];
  if (state.automationStrategy === "FIX_AND_ADJUST") {
    if (state.vegetableServingsInput === "") {
      tooltips.push("Veg must not be empty");
    }
    if (state.fruitServingsInput === "") {
      tooltips.push("Fruit must not be empty");
    }
    if (state.dairyServingsInput === "") {
      tooltips.push("Dairy must not be empty");
    }
  }
  return tooltips;
};

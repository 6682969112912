import { Checkbox, FormControlLabel, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch } from "react";
import TeamLabelAutoComplete from "./TeamLabelAutoComplete";
import { DeliveryLocationFormAction_Edit, DeliveryLocationFormState_Edit } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      marginBottom: theme.spacing(2),
    },
  })
);

interface DeliveryLocationFormProps {
  state: DeliveryLocationFormState_Edit;
  dispatch: Dispatch<DeliveryLocationFormAction_Edit>;
  disableCityInfo?: boolean;
}

const DeliveryLocationForm = ({ state, dispatch, disableCityInfo }: DeliveryLocationFormProps) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        autoFocus
        fullWidth
        value={state.name}
        className={classes.row}
        onChange={e => dispatch({ type: "CHANGE_NAME", payload: { name: e.target.value } })}
        label="Name"
      />
      {!disableCityInfo && (
        <TextField
          fullWidth
          value={state.cityInfo}
          className={classes.row}
          onChange={e =>
            dispatch({
              type: "CHANGE_CITY_INFO",
              payload: { cityInfo: e.target.value },
            })
          }
          label="City Info"
        />
      )}
      <TextField
        multiline
        fullWidth
        rows={4}
        maxRows={4}
        value={state.deliveryInstructions}
        className={classes.row}
        onChange={e =>
          dispatch({
            type: "CHANGE_DELIVERY_INSTRUCTIONS",
            payload: { deliveryInstructions: e.target.value },
          })
        }
        label="Delivery Instructions"
      />
      <div className={classes.row}>
        <TeamLabelAutoComplete
          handleChange={newOptions =>
            dispatch({
              type: "CHANGE_TEAM_TAGS",
              payload: { teamLabels: newOptions },
            })
          }
          selectedTeamLabels={state.teamLabels}
        />
      </div>
      <FormControlLabel
        className={classes.row}
        control={
          <Checkbox checked={state.isActive} onChange={(_, isActive) => dispatch({ type: "CHANGE_IS_ACTIVE", payload: { isActive } })} />
        }
        label="Is Active"
      />
    </>
  );
};

export default DeliveryLocationForm;

import { v4 as uuid } from "uuid";
import { ZodError } from "zod";
import { FullMealPlanTemplateFragment } from "../../types";
import { FoodPreferenceState, initialFoodPreferenceState } from "../FoodPreference/MealPlan/reducer";
import { MacroProtocolState, buildMacroProtocolState, getBlankMacroProtocolState } from "./MacroProtocol/reducer/macroProtocolReducer";
import {
  CreateServingAmountInputItem,
  ScheduleAction,
  ScheduleState,
  buildInitialEditScheduleStateFromMealPlanTemplate,
  scheduleReducer,
} from "./Schedule/scheduleReducer";
import {
  MacroMealPlanTemplateEditStateSchemaV30,
  MacroMealPlanTemplateEditStateSchemaV31,
  PendingStateVersion,
  latestVersion,
  v10,
  v11,
  v21,
  v30,
  v31,
} from "./validationV30";
import { MacroMealPlanTemplateEditStateSchemaV21 } from "./validationV21";
import { MacroMealPlanTemplateEditStateSchemaV11 } from "./validationV11";
import { MacroMealPlanTemplateEditStateSchemaV10 } from "./validationV10";
import { convertImperialAnthropometryStateToMetric } from "./mealPlanTemplateUtils";

interface BaseMacroMealPlanTemplateState {
  version: PendingStateVersion;
  name: string;
  description: string | null;

  macroProtocol: MacroProtocolState;
  meals: ScheduleState["meals"];
  foodPreferences: FoodPreferenceState;
  activities: ScheduleState["activities"];
}

export interface EditMacroMealPlanTemplateState extends BaseMacroMealPlanTemplateState {
  __typename: "Edit";
  id: string;
}

interface CreateMacroMealPlanTemplateState extends BaseMacroMealPlanTemplateState {
  __typename: "Create";
}

export type MacroMealPlanTemplateState = EditMacroMealPlanTemplateState | CreateMacroMealPlanTemplateState;

interface ResetAction {
  type: "RESET";
  payload: EditMacroMealPlanTemplateState;
}

interface EditNameAction {
  type: "EDIT_NAME";
  payload: string;
}
interface EditDescriptionAction {
  type: "EDIT_DESCRIPTION";
  payload: string | null;
}

interface UpdateMacroProtocolAction {
  type: "UPDATE_MACRO_PROTOCOL";
  payload: MacroProtocolState;
}

interface UpdateFoodPreferencesAction {
  type: "UPDATE_FOOD_PREFERENCES";
  payload: FoodPreferenceState;
}

export type MacroMealPlanTemplateAction =
  | ResetAction
  | EditNameAction
  | EditDescriptionAction
  | UpdateMacroProtocolAction
  | UpdateFoodPreferencesAction
  | ScheduleAction;

export const editMealPlanTemplateReducer = (state: EditMacroMealPlanTemplateState, action: MacroMealPlanTemplateAction) =>
  mealPlanTemplateReducer(state, action);

export const mealPlanTemplateReducer = <T extends MacroMealPlanTemplateState>(state: T, action: MacroMealPlanTemplateAction): T => {
  switch (action.type) {
    case "RESET":
      return { ...state, ...action.payload };
    case "EDIT_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "EDIT_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "UPDATE_MACRO_PROTOCOL":
      return {
        ...state,
        macroProtocol: action.payload,
      };
    case "UPDATE_FOOD_PREFERENCES":
      return {
        ...state,
        foodPreferences: action.payload,
      };
    case "EDIT_MEAL":
    case "OVERWRITE_SCHEDULE_STATE":
    case "DISTRIBUTE_MACROS":
      return {
        ...state,
        ...scheduleReducer({ meals: state.meals, activities: state.activities }, action),
      };
  }
};

export const getBlankCreateState = (isMetricLocale: boolean): CreateMacroMealPlanTemplateState => ({
  __typename: "Create",
  version: latestVersion,
  name: "",
  description: "",

  macroProtocol: getBlankMacroProtocolState(isMetricLocale),
  meals: [],
  foodPreferences: initialFoodPreferenceState({}),
  activities: [],
});

export interface MealOptionInputItem {
  servingAmounts: CreateServingAmountInputItem[];
  position: number;
  note: string | null;
  name?: string | null;
  id?: string;
}

// TODO: metric update run phase - macroProtocol.anthro to metric variable names
// TODO: metric update run phase - update mpt => macro => anthro nested states/resolvers
const parseV31 = (pendingState: any) => {
  let message = "Unknown Error";
  try {
    return MacroMealPlanTemplateEditStateSchemaV31.parse(pendingState);
  } catch (error) {
    if (error instanceof ZodError) {
      message = error.message;
    }
    throw new Error(
      `Error: Edit Meal Plan Template failed to pass runtypes check for version ${pendingState.version}. Message: ${message}`
    );
  }
};

const parseV30ToV31 = (pendingState: any) => {
  let message = "Unknown Error";
  try {
    const v30State = MacroMealPlanTemplateEditStateSchemaV30.parse(pendingState);

    const { macroProtocol } = v30State;
    const { anthropometry } = macroProtocol;
    const newAnthropometry = convertImperialAnthropometryStateToMetric(anthropometry);

    const MacroProtocolV31 = {
      ...macroProtocol,
      anthropometry: {
        ...newAnthropometry,
      },
    };

    const v31State = {
      ...v30State,
      macroProtocol: MacroProtocolV31,
      version: v31,
    };

    return MacroMealPlanTemplateEditStateSchemaV31.parse(v31State);
  } catch (error) {
    if (error instanceof ZodError) {
      message = error.message;
    }
    throw new Error(
      `Error: Edit Meal Plan Template failed to pass runtypes check for version ${pendingState.version}. Message: ${message}`
    );
  }
};

const parseV21ToV30 = (pendingState: any) => {
  let message = "Unknown Error";
  try {
    const v21State = MacroMealPlanTemplateEditStateSchemaV21.parse(pendingState);
    const v30State = { ...v21State, showInKg: undefined, version: v30 };
    return MacroMealPlanTemplateEditStateSchemaV30.parse(v30State);
  } catch (error) {
    if (error instanceof ZodError) {
      message = error.message;
    }
    throw new Error(
      `Error: Edit Meal Plan Template failed to pass runtypes check for version ${pendingState.version}. Message: ${message}`
    );
  }
};

const parseV11ToV21 = (pendingState: any) => {
  let message = "Unknown Error";
  try {
    const v11State = MacroMealPlanTemplateEditStateSchemaV11.parse(pendingState);
    const meals = v11State.meals.map(meal => ({
      ...meal,
      mealOptions: meal.mealOptions.map(mealOption => ({ ...mealOption, name: "" })),
    }));
    // V2.1 MealOption adds optional name
    const v21State = { ...v11State, version: v21, meals };
    return MacroMealPlanTemplateEditStateSchemaV21.parse(v21State);
  } catch (error) {
    if (error instanceof ZodError) {
      message = error.message;
    }
    throw new Error(
      `Error: Edit Meal Plan Template failed to pass runtypes check for version ${pendingState.version}. Message: ${message}`
    );
  }
};

const parseV10ToV11 = (pendingState: any) => {
  let message = "Unknown Error";
  try {
    const v10State = MacroMealPlanTemplateEditStateSchemaV10.parse(pendingState);

    // A breaking schema change happened in V1.  The version should have been changed when that happened but it was not.
    // 'id' was added to meal options.  As a result, there are some V1 pending state strings that are missing id from mealOptions
    // so we detect and fix them here.
    const meals = v10State.meals.map(meal => ({
      ...meal,
      mealOptions: meal.mealOptions.map(mealOption => ({ ...mealOption, id: mealOption.id ? mealOption.id : uuid() })),
    }));

    // V1.1 MealOption requires id
    const v11State = { ...v10State, version: v11, meals };
    return MacroMealPlanTemplateEditStateSchemaV11.parse(v11State);
  } catch (error) {
    if (error instanceof ZodError) {
      message = error.message;
    }
    throw new Error(
      `Error: Edit Meal Plan Template failed to pass runtypes check for version ${pendingState.version}. Message: ${message}`
    );
  }
};

export const buildInitialEditStateFromMealPlanTemplate = (
  mealPlanTemplate: FullMealPlanTemplateFragment,
  isMetricLocale: boolean
): EditMacroMealPlanTemplateState => {
  if (mealPlanTemplate.__typename === "ExchangeMealPlanTemplate") {
    throw new Error("meal plan template type not supported");
  }

  if (mealPlanTemplate.pendingState) {
    const { pendingState } = mealPlanTemplate;
    const uncheckedPendingState = JSON.parse(pendingState);
    const { version } = uncheckedPendingState;

    if (version === v31) {
      return parseV31(uncheckedPendingState);
    } else if (version === v30) {
      return parseV30ToV31(uncheckedPendingState);
    } else if (version === v21) {
      return parseV30ToV31(parseV21ToV30(uncheckedPendingState));
    } else if (version === v11) {
      return parseV30ToV31(parseV21ToV30(parseV11ToV21(uncheckedPendingState)));
    } else if (version === v10) {
      return parseV30ToV31(parseV21ToV30(parseV11ToV21(parseV10ToV11(uncheckedPendingState))));
    } else {
      throw new Error(`Meal Plan Template pending state: unsupported version '${version}'`);
    }
  }

  const {
    id,
    name,
    description,
    additionalState,
    meals: scheduleMeals,
    activities: scheduleActivities,
    macroProtocol: serverMacroProtocol,
  } = mealPlanTemplate;

  const { meals, activities } = buildInitialEditScheduleStateFromMealPlanTemplate(scheduleMeals, scheduleActivities);

  const macroProtocol = buildMacroProtocolState(serverMacroProtocol, additionalState, isMetricLocale);

  const foodPreferences = initialFoodPreferenceState(mealPlanTemplate);

  return {
    __typename: "Edit",
    version: latestVersion,
    id,
    name,
    description,
    macroProtocol,
    meals,
    activities,
    foodPreferences,
  };
};

import { Forum as ForumIcon } from "@mui/icons-material";
import { Box, Button, Typography, alpha } from "@mui/material";
import React from "react";

interface EmptyChannelListProps {
  onCreateMessage: () => void;
}

export const EmptyChannelList = ({ onCreateMessage }: EmptyChannelListProps) => {
  return (
    <Box
      sx={{
        padding: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={({ palette }) => ({
          backgroundColor: alpha(palette.primary.main, 0.1),
          height: "56px",
          width: "56px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 1,
        })}
      >
        <ForumIcon sx={({ palette }) => ({ color: palette.primary.main, fontSize: "32px" })} />
      </Box>
      <Typography variant="h4">No Messages</Typography>
      <Typography variant="body2" sx={{ textAlign: "center" }}>
        Looks like there are no messages yet. Get started by creating and sending one today.
      </Typography>
      <Button
        onClick={() => onCreateMessage()}
        fullWidth
        size={"large"}
        sx={{ marginTop: 3 }}>
        Create Message
      </Button>
    </Box>
  );
};

import { Dialog } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { EditFoodGroupInput, useFoodGroupQuery } from "../../../types";
import FoodGroupForm from "../Form";
import { FoodGroupFormState } from "../Form/reducer";

export interface FoodGroupFoodUpdate {
  foodId: string;
  foodGroupId: string;
}

interface FoodModalEditProps {
  open: boolean;
  onClose: () => void;
  foodGroupId: string;
  onEditFoodGroup: ({ id, foodGroup }: EditFoodGroupInput) => void;
  readOnly: boolean;
}

export const FoodGroupModalEdit = ({ open, onClose, foodGroupId, onEditFoodGroup, readOnly }: FoodModalEditProps) => {
  const { data, loading } = useFoodGroupQuery({
    variables: { id: foodGroupId },
    fetchPolicy: "network-only",
  });

  const handleEditFoodGroup = (state: FoodGroupFormState) => {
    if (state.name === null || state.name === "") {
      return;
    }

    onEditFoodGroup({
      id: foodGroupId,
      foodGroup: {
        name: state.name,
        source: state.source,
        foodIds: state.foods.map(f => f.id),
      },
    });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Food Group" onClose={onClose} />
      <FoodGroupForm
        readOnly={readOnly}
        foodGroup={!loading && data ? data.foodGroup : undefined}
        loading={loading}
        onSave={handleEditFoodGroup}
        onCancel={() => onClose()}
      />
    </Dialog>
  );
};

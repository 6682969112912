import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useMoveOrgGroupFoodToMyOrgMutation } from "apps/web/src/types";
import React from "react";
import { IFoodRow } from "../Table/TableRow";

interface MoveOrgGroupFoodToMyOrgDialogProps {
  setSuccessMsg: (msg: string | null) => void;
  onClose: () => void;
  moveOrgGroupFoodToMyOrg: IFoodRow;
}

export const MoveOrgGroupFoodToMyOrgDialog = ({ setSuccessMsg, onClose, moveOrgGroupFoodToMyOrg }: MoveOrgGroupFoodToMyOrgDialogProps) => {
  const [moveOrgGroupFoodToMyOrgMutation] = useMoveOrgGroupFoodToMyOrgMutation({
    variables: {
      input: {
        id: moveOrgGroupFoodToMyOrg?.id ?? "",
      },
    },
    onCompleted: () => {
      onClose();
      setSuccessMsg(`Moved ${moveOrgGroupFoodToMyOrg?.name ?? "the food"} to your org`);
    },
  });

  return (
    <Dialog
      maxWidth="sm"
      open={true}
      onClose={onClose}>
      <DialogTitle title="Move Food" onClose={onClose} />
      <DialogContent>Are you sure you want to move the food back to your org?</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          aria-label="Cancel">
          Cancel
        </Button>
        <Button onClick={() => moveOrgGroupFoodToMyOrgMutation()} aria-label="Print">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

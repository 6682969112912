import { Button, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";

interface ConfirmModalProps {
  onClose: () => void;
  open: boolean;
  onConfirm: () => void;
  messages: string[];
  numberMealPlans: number;
  title?: string;
}

const ScheduleModalConfirmation = ({ onClose, open, onConfirm, messages, numberMealPlans, title }: ConfirmModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Confirm Edit Schedule?" onClose={onClose} />
      <DialogContent>
        <Typography variant="body2">
          {title ? (
            title
          ) : (
            <>
              This action updates <strong>{numberMealPlans}</strong> meal plans:
            </>
          )}
        </Typography>
        <br />
        <Divider />
        <br />
        {messages.map(msg => (
          <Typography
            key={msg}
            color="error"
            variant="subtitle1">
            {msg}
            <br />
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleModalConfirmation;

import { ApolloError, DocumentNode, LazyQueryHookOptions, QueryLazyOptions, useQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FilterOptionsState,
  InputAdornment,
  Paper,
  SvgIconTypeMap,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useDebounce } from "@notemeal/shared/ui/hooks/useDebounce";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";
import { NoFoodResultsAlert } from "../../ServingAmounts/NoFoodResultsAlert";
import { useSnackbar } from "../../Snackbar/SnackbarContext";

type GetOptionSxProps<Option> = (option: Option) => SxProps;
const DEFAULT_OPTION_ID = "_?_";

export type AutocompleteQuerySearchBarProps<Option, Query, Variables> = {
  query: DocumentNode;
  label?: string;
  placeholder: string;
  getQueryOptions: (query: string) => QueryLazyOptions<Variables>;
  getOptionsFromQueryData: (data: Query) => Option[];
  getOptionLabel: (option: Option) => string;
  getOptionSublabel?: (option: Option) => string;
  onChange: (newValue: Option | null) => void;
  getUserFriendlyQueryErrorMessage: (error: ApolloError) => string;
  groupBy?: {
    sort: (a: Option, b: Option) => number;
    label: (option: Option) => string;
  };
  sx?: SxProps;
  fullWidth?: boolean;
  disabled?: boolean;
  lazyQueryHookOptions?: LazyQueryHookOptions<Query, Variables>;
  autoFocus?: boolean;
  defaultOption?: {
    text: string;
    onClick: () => void;
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
      muiName: string;
    };
  };
  getOptionSx?: GetOptionSxProps<Option>;
  minInputChars?: number;
  filterOptions?: (options: Option[], params: FilterOptionsState<Option>) => Option[];
  clearOnSelect?: boolean;
  foodsSelected?: boolean;
};

export const AutocompleteQuerySearchBar = <Option extends { id: string }, Query extends {}, Variables extends {}>({
  query,
  label,
  placeholder,
  getQueryOptions,
  getOptionsFromQueryData,
  getOptionLabel,
  getOptionSublabel,
  onChange,
  getUserFriendlyQueryErrorMessage,
  groupBy,
  sx,
  disabled,
  lazyQueryHookOptions,
  autoFocus = false,
  defaultOption,
  getOptionSx,
  minInputChars = 1,
  filterOptions,
  clearOnSelect = true,
  foodsSelected,
}: AutocompleteQuerySearchBarProps<Option, Query, Variables>) => {
  const [value, setValue] = React.useState<Option | null>(null);
  const [_inputText, setInputText] = React.useState<string | null>(null);
  const debouncedInputText = useDebounce(_inputText, 200);
  const [options, setOptions] = React.useState<Option[]>([]);
  const { setMessage } = useSnackbar();

  const { loading } = useQuery<Query>(query, {
    ...lazyQueryHookOptions,
    ...getQueryOptions(debouncedInputText ?? ""),
    onCompleted: data => {
      if (groupBy) {
        setOptions(getOptionsFromQueryData(data).sort(groupBy.sort));
      } else {
        setOptions(getOptionsFromQueryData(data));
      }
    },
    onError: error => {
      setMessage("error", getUserFriendlyQueryErrorMessage(error));
    },
    // instant search if minInputChars is 0
    skip: minInputChars > 0 && (!debouncedInputText || debouncedInputText.length <= minInputChars),
  });

  let noOptionsText = (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <CircularProgress size={20} /> {"Loading..."}
    </Box>
  );
  if (!loading && debouncedInputText && debouncedInputText.length > minInputChars && _inputText) {
    noOptionsText = <>No results</>;
  } else {
    noOptionsText = <>Start typing to search</>;
  }

  // If there are no results returned for foods, show message to users to check in Branded Foods OR request new food
  const hasNoFoodResults =
    foodsSelected && options.length === 0 && !loading && debouncedInputText && debouncedInputText.length > minInputChars && _inputText;

  return (
    <Autocomplete
      inputValue={_inputText ?? ""}
      autoComplete
      disabled={disabled}
      filterOptions={(options, params) => {
        const filtered = filterOptions ? filterOptions(options, params) : options;
        const { inputValue } = params;
        const isExisting = options.some(option => inputValue === option.id);
        if (defaultOption && inputValue !== "" && !isExisting) {
          filtered.push({ id: DEFAULT_OPTION_ID } as Option);
        }
        return filtered;
      }}
      value={value}
      options={options}
      getOptionLabel={option => {
        if (option.id === DEFAULT_OPTION_ID && defaultOption) {
          return defaultOption.text;
        }
        return getOptionLabel(option);
      }}
      PaperComponent={({ children, ...props }) => (
        <Paper sx={{ minWidth: "300px" }}>{hasNoFoodResults ? <NoFoodResultsAlert children={children} /> : children}</Paper>
      )}
      noOptionsText={noOptionsText}
      onChange={(_, newValue: Option | null) => {
        if (newValue?.id === DEFAULT_OPTION_ID && defaultOption) {
          defaultOption.onClick();
        } else {
          if (clearOnSelect) {
            setInputText("");
            setValue(null);
          } else {
            setValue(newValue);
          }
          onChange(newValue);
        }
      }}
      onInputChange={(_, searchTerm) => {
        let term = searchTerm ?? null;
        setInputText(term);
        if (!term) {
          setOptions([]);
        }
      }}
      onBlur={() => setOptions([])}
      renderInput={params => (
        <TextField
          {...params}
          sx={{ minWidth: 230, ...sx }}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            autoFocus,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            ...(value ? {} : { endAdornment: null }),
          }}
        />
      )}
      groupBy={groupBy?.label}
      renderOption={(props, option, { inputValue }) => {
        if (option.id === DEFAULT_OPTION_ID && defaultOption) {
          return (
            <li {...props}>
              <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                <defaultOption.Icon fontSize="small" sx={{ marginRight: 0.5 }} />
                <Typography variant={hasNoFoodResults ? "body2Semibold" : "body1Semibold"}>{defaultOption.text}</Typography>
              </Box>
            </li>
          );
        }

        const label = getOptionLabel(option);
        const labelMatches = match(label, inputValue, { insideWords: true });
        const labelParts = parse(label, labelMatches);

        const sublabel = getOptionSublabel ? getOptionSublabel(option) : "";

        const highlightedText = (
          <li {...props}>
            <div>
              <Typography sx={getOptionSx && getOptionSx(option)}>
                {labelParts.map(({ highlight, text }, index) => (
                  <span key={index} style={{ fontWeight: highlight ? 700 : 400 }}>
                    {text}
                  </span>
                ))}
              </Typography>
              {sublabel.length > 0 && (
                <Typography sx={theme => ({ color: theme.palette.mediumEmphasisText })} variant="subtitle1">
                  {sublabel}
                </Typography>
              )}
            </div>
          </li>
        );

        return highlightedText;
      }}
      componentsProps={{ popupIndicator: { sx: { display: "none" } } }}
    />
  );
};

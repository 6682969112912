import { ListItem, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { RestaurantLocationSearchResultFragment } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    restaurantType: {
      textAlign: "right",
    },
    right: { display: "flex", flexDirection: "column", flex: 1 },
    left: { display: "flex", flexDirection: "column", flex: 2 },
    notes: {
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
    },
  })
);

interface RestaurantSearchLocationResultItemProps {
  result: RestaurantLocationSearchResultFragment;
  onClick: () => void;
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
  disabled?: boolean;
}

const RestaurantSearchLocationResultItem = ({ result, onClick, forwardRef, disabled }: RestaurantSearchLocationResultItemProps) => {
  const classes = useStyles();
  const restaurantTypeName = result.restaurant?.restaurantType?.name;
  const restaurantNote = result.restaurantLocation?.notes;
  return (
    <ListItem
      button
      onClick={onClick}
      ref={forwardRef}
      disabled={disabled}>
      <ListItemText
        className={classes.left}
        primary={result.restaurant.name}
        secondary={result.restaurantLocation?.address?.displayName} />
      <div className={classes.right}>
        {restaurantTypeName && <ListItemText secondary={restaurantTypeName} classes={{ secondary: classes.restaurantType }} />}
        {restaurantNote && <ListItemText secondary={restaurantNote} className={classes.notes} />}
      </div>
    </ListItem>
  );
};

export default RestaurantSearchLocationResultItem;

import { roundToHundredthsFloor } from "@notemeal/shared/utils/macro-protocol";
import { AnthropometryEntry, AnthropometryEntryType } from "../../types";

export const anthropometryEntryTypeToName = (type: AnthropometryEntryType): string => {
  switch (type) {
    case "bodpod":
      return "Bod Pod";
    case "dexa":
      return "DXA";
    case "estimate":
      return "Estimate";
    case "bioelectricalImpedance":
      return "Bioelectrical impedance";
    case "isak":
      return "ISAK";
    case "weighIn":
      return "Weigh in";
    default:
      return "";
  }
};

export const roundAnthroEntry = ({
  height,
  weight,
  leanBodyMass,
  bodyFatMass,
  heightInCm,
  weightInKg,
  leanBodyMassInKg,
  bodyFatMassInKg,
  ...r
}: AnthropometryEntry): AnthropometryEntry => ({
  height: height && roundToHundredthsFloor(height),
  weight: weight && roundToHundredthsFloor(weight),
  leanBodyMass: leanBodyMass && roundToHundredthsFloor(leanBodyMass),
  bodyFatMass: bodyFatMass && roundToHundredthsFloor(bodyFatMass),
  heightInCm: heightInCm && roundToHundredthsFloor(heightInCm),
  weightInKg: weightInKg && roundToHundredthsFloor(weightInKg),
  leanBodyMassInKg: leanBodyMassInKg && roundToHundredthsFloor(leanBodyMassInKg),
  bodyFatMassInKg: bodyFatMassInKg && roundToHundredthsFloor(bodyFatMassInKg),
  ...r,
});

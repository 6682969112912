import { Box, SxProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { Macros } from "@notemeal/shared/utils/macro-protocol";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    prefixLg: {
      paddingRight: spacing(3),
    },
    proLg: {
      paddingLeft: spacing(1.5),
    },
    fatLg: {
      paddingLeft: spacing(1.5),
    },
    prefixSm: {
      paddingRight: spacing(1),
    },
    proSm: {
      paddingLeft: spacing(1),
    },
    fatSm: {
      paddingLeft: spacing(1),
    },
    xs: {
      fontSize: ".6rem",
    },
  })
);

interface MacrosSummaryLabelProps {
  macros: Macros;
  variant: "xs" | "sm" | "lg";
  prefix?: string;
  kcalPrefix?: boolean;
  sx?: SxProps<Theme>;
}

const MacrosSummaryLabel = ({ macros: { cho, pro, fat, kcal }, prefix, variant, kcalPrefix = false, sx }: MacrosSummaryLabelProps) => {
  const classes = useStyles();
  const prefixText = prefix ? prefix : kcalPrefix ? `${Math.round(kcal)}kcal ` : undefined;

  return (
    <Typography
      variant={variant === "lg" ? "body1" : "body2"}
      classes={variant === "xs" ? { root: classes.xs } : {}}
      color="inherit"
      sx={sx}
    >
      {prefixText && <span className={variant === "lg" ? classes.prefixLg : classes.prefixSm}>{prefixText}</span>}
      <Box component="span" sx={{ color: "cho.main" }}>
        CHO
      </Box>
      : {Math.round(cho)}g
      <Box component="span" sx={{ color: "pro.main" }} className={variant === "lg" ? classes.proLg : classes.proSm}>
        PRO
      </Box>
      : {Math.round(pro)}g
      <Box component="span" sx={{ color: "fat.main" }} className={variant === "lg" ? classes.fatLg : classes.fatSm}>
        FAT
      </Box>
      : {Math.round(fat)}g
    </Typography>
  );
};

export default MacrosSummaryLabel;

import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import {
  dateAndTimeToIsoInTz,
  getDefaultEndTime,
  jsDateToDateAndTimeInTz,
  parseTime,
  safeSerializeTime,
} from "@notemeal/shared/ui/utils/dateTimes";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { addMinutes, differenceInMinutes } from "date-fns";
import React, { useState } from "react";
import MealTypeSelect from "../../../../../components/Meal/TypeSelect";
import { EditTimelineMealDetailsInput, MealType } from "../../../../../types";
import { TimelineMealForModal } from "../../utils";

interface FoodLogMealEditTimeModalProps {
  onClose: () => void;
  open: boolean;
  onSave: (input: Omit<EditTimelineMealDetailsInput, "timelineMealId">) => void;
  meal: TimelineMealForModal;
  date: string;
}

const FoodLogMealEditTimeModal = ({ onClose, open, onSave, meal, date }: FoodLogMealEditTimeModalProps) => {
  const [name, setName] = useState(meal.name);
  const clientTimezone = useClientTimezone();

  const { time: initStart } = jsDateToDateAndTimeInTz(new Date(meal.start), meal.timezone);
  const { time: initEnd } = jsDateToDateAndTimeInTz(addMinutes(new Date(meal.start), meal.durationInMinutes), meal.timezone);

  const [startDateTime, setStartDateTime] = useState<Date | null>(parseTime(initStart));
  const [endDateTime, setEndDateTime] = useState<Date | null>(parseTime(initEnd));
  const [type, setType] = useState<MealType>(meal.type);

  const handleSetStartTime = (startTime: Date | null) => {
    setStartDateTime(startTime);
    const newStart = safeSerializeTime(startTime);
    if (newStart) {
      setEndDateTime(parseTime(getDefaultEndTime(newStart)));
    }
  };

  const handleSetEndTime = (endTime: Date | null) => {
    setEndDateTime(endTime);
  };

  const handleSave = () => {
    const startTime = safeSerializeTime(startDateTime);
    const endTime = safeSerializeTime(endDateTime);

    if (!startTime || !endTime) {
      return;
    }

    const start = new Date(dateAndTimeToIsoInTz({ date, time: startTime }, clientTimezone));
    const end = new Date(dateAndTimeToIsoInTz({ date, time: endTime }, clientTimezone));
    const durationInMinutes = differenceInMinutes(end, start);

    onSave({
      name,
      type,
      start: start.toISOString(),
      durationInMinutes,
      timezone: clientTimezone,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title="Edit Meal Time" onClose={onClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "stretch", gap: 2 }}>
          <TextField
            sx={{ flex: "1" }}
            label="Name"
            value={name}
            onChange={e => setName(e.target.value)}
            margin="dense" />
          <MealTypeSelect
            sx={{ flex: "1" }}
            value={type}
            onChange={type => setType(type)} />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <DesktopTimePicker
            sx={{}}
            label="Start"
            value={startDateTime}
            onChange={handleSetStartTime} />
          <DesktopTimePicker
            sx={{}}
            label="End"
            value={endDateTime}
            onChange={handleSetEndTime} />
        </Box>{" "}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FoodLogMealEditTimeModal;

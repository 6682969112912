import { Box, ListSubheader, MenuItem, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ActivityItem } from "@notemeal/shared/ui/Activity";
import { MealItem } from "@notemeal/shared/ui/Meal";
import ScheduleContainer from "@notemeal/shared/ui/Schedule/Container";
import React from "react";
import { ScheduleWithTeamFragment } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mealItem: {
      padding: theme.spacing(1, 0, 1, 0),
      margin: theme.spacing(1, 0, 0, 0),
    },
  })
);

interface ScheduleSelectorProps {
  onChange: (selectedSchedule: ScheduleWithTeamFragment) => void;
  selectedSchedule: ScheduleWithTeamFragment;
  standardSchedules: readonly ScheduleWithTeamFragment[];
  teamSchedules: readonly ScheduleWithTeamFragment[];
}

const ScheduleSelector = ({ onChange, selectedSchedule, standardSchedules, teamSchedules }: ScheduleSelectorProps) => {
  const classes = useStyles();

  const mapTeamSchedules: Map<string, ScheduleWithTeamFragment[]> = new Map<string, ScheduleWithTeamFragment[]>();
  teamSchedules?.forEach(schedule => {
    const teamName = schedule.team?.name || "";
    const teamSchedules = mapTeamSchedules.get(teamName) || [];
    mapTeamSchedules.set(teamName, teamSchedules.concat(schedule));
  });

  const handleChange = (scheduleId: string) => {
    const selectedSchedule = standardSchedules.concat(teamSchedules).find(({ id }) => id === scheduleId);
    if (selectedSchedule) {
      onChange(selectedSchedule);
    }
  };

  const renderTeamSchedules = (teamName: string, teamSchedules: ScheduleWithTeamFragment[]) => {
    // TextField select cannot have children in between the parent and the menu item
    // if we want selection to work (bubbling of event is the issue), i.e. no divs
    // or fragments around elements like normal. Using element array here to achieve this.
    const elements = [];
    elements.push(<ListSubheader>{teamName} Schedules</ListSubheader>);
    teamSchedules.map(({ id, name }) =>
      elements.push(
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      )
    );

    return elements;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        select
        fullWidth
        label="Select Template Schedule"
        value={selectedSchedule.id}
        onChange={e => handleChange(e.target.value)}
        SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}
      >
        {renderTeamSchedules("Standard", standardSchedules.concat())}
        {Array.from(mapTeamSchedules.keys())
          .sort()
          .map(teamName => renderTeamSchedules(teamName, mapTeamSchedules.get(teamName) || []))}
      </TextField>
      <Box sx={{ p: 1, mt: 2, backgroundColor: "grey.200", borderRadius: "5px" }}>
        <Typography variant="body2">* The schedule can be customized after meal plan creation.</Typography>
      </Box>
      {selectedSchedule && (
        <ScheduleContainer
          meals={selectedSchedule.meals}
          mealToStart={m => m.start}
          activities={selectedSchedule.activities}
          activityToStart={a => a.start}
          renderMeal={meal => <MealItem className={classes.mealItem} meal={{ ...meal, __typename: "Meal", id: meal.id }} />}
          renderActivity={activity => <ActivityItem activity={{ ...activity, __typename: "Activity" }} />}
        />
      )}
    </Box>
  );
};

export default ScheduleSelector;

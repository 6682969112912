import { useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import TablePage from "../../../../components/universal/TablePage";
import TablePageDefaultHeader from "../../../../components/universal/TablePage/DefaultHeader";
import { NamedTagPreviewFragment, useDeleteTagMutation, useTagTableQuery } from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
// import CreateModal from "./CreateModal";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import DeleteDialog from "../../../../views/Tags/Dialogs/DeleteDialog";
import CreateDialog from "../../../../views/Tags/Dialogs/Mutation/Create/CreateDialog";
import DuplicateDialog from "../../../../views/Tags/Dialogs/Mutation/Duplicate/DuplicateDialog";
import EditDialog from "../../../../views/Tags/Dialogs/Mutation/Edit/EditDialog";
import { HeaderRow, Row } from "../../../../views/Tags/TagRow";

export const TagsPage = () => {
  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();
  const paginationHooks = useOffsetPagination();
  const { query, limit, offset, queryText, onChangeQueryText } = paginationHooks;
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [tagIdForEdit, setTagIdForEdit] = useState<string | null>(null);
  const [tagIdForDuplicate, setTagIdForDuplicate] = useState<string | null>(null);
  const [namedTagForDelete, setNamedTagForDelete] = useState<NamedTagPreviewFragment | null>(null);

  const { data, loading, refetch } = useTagTableQuery({
    variables: {
      input: {
        limit,
        offset,
      },
      query,
    },
  });

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "namedTagOffsetConnection",
      broadcast: true,
    });
    refetch();
  };

  const [deleteTag] = useDeleteTagMutation({
    onCompleted: () => {
      resetTable();
      setMessage("success", `Successfully Deleted Tag: ${namedTagForDelete?.tagName.name}`);
      setNamedTagForDelete(null);
    },
    onError: () => {
      setMessage("error", `Error while Deleting Tag: ${namedTagForDelete?.tagName.name}`);
    },
  });

  const handleDelete = (id: string) => {
    deleteTag({ variables: { id } });
  };

  const Rows = data
    ? data.namedTagOffsetConnection.edges.map(namedTagPreview => (
        <Row
          namedTagPreview={namedTagPreview}
          key={namedTagPreview.tag.id}
          onClick={id => setTagIdForEdit(id)}
          onDelete={namedTag => setNamedTagForDelete(namedTag)}
          onDuplicate={id => setTagIdForDuplicate(id)}
        />
      ))
    : [];

  return (
    <>
      <TablePage
        header={
          <TablePageDefaultHeader
            title="Tag"
            queryText={queryText}
            onChangeQueryText={onChangeQueryText}
            onCreate={() => setCreateDialogOpen(true)}
            searchPlaceHolder="Search For Tag"
          />
        }
        tableHeaderRow={<HeaderRow />}
        paginationHooks={paginationHooks}
        tableBodyRows={Rows}
        loading={loading}
        total={data?.namedTagOffsetConnection.pageInfo.total || 0}
      />
      {namedTagForDelete !== null && (
        <DeleteDialog
          open={!!namedTagForDelete}
          onCancel={() => setNamedTagForDelete(null)}
          handleDelete={id => handleDelete(id)}
          namedTagPreview={namedTagForDelete}
        />
      )}
      {createDialogOpen && <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        resetTable={resetTable} />}
      {tagIdForEdit !== null && (
        <EditDialog
          open={tagIdForEdit !== null}
          onClose={() => setTagIdForEdit(null)}
          tagId={tagIdForEdit}
          resetTable={resetTable} />
      )}

      {tagIdForDuplicate !== null && (
        <DuplicateDialog
          open={tagIdForDuplicate !== null}
          onClose={() => setTagIdForDuplicate(null)}
          tagId={tagIdForDuplicate}
          resetTable={resetTable}
        />
      )}
    </>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import DiscardChangesDialog from "apps/web/src/components/universal/DiscardChangesDialog";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MealPlanCalendarContextProvider } from "../../MealPlans/contexts/Calendar";
import { GoalConfigurationForm } from "./GoalConfigurationForm";
import {
  CreateGoalConfigurationInputType,
  GoalConfigurationFormSchema,
  GoalConfigurationFormType,
  goalConfigurationFormDefaultValues,
  goalConfigurationFormToCreateInput,
} from "./GoalConfigurationSchema";

interface GoalConfigurationCreateDialogProps {
  open: boolean;
  onClose: () => void;
  athleteId: string;
  onCreateGoalConfiguration: (input: CreateGoalConfigurationInputType) => void;
}

export const GoalConfigurationCreateDialog = ({
  open,
  onClose,
  athleteId,
  onCreateGoalConfiguration,
}: GoalConfigurationCreateDialogProps) => {
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const form = useForm<GoalConfigurationFormType>({
    defaultValues: goalConfigurationFormDefaultValues(),
    resolver: zodResolver(GoalConfigurationFormSchema),
    mode: "onChange",
  });

  const { isDirty } = form.formState;

  const handleClose = () => {
    if (isDirty) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  const submit = (goal: GoalConfigurationFormType) => {
    onCreateGoalConfiguration(goalConfigurationFormToCreateInput(athleteId, goal));
    //todo success message
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        sx: { width: "900px", height: "650px" },
      }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle title="Create Goal" onClose={handleClose} />
      <DialogContent sx={{ pt: 0 }}>
        <MealPlanCalendarContextProvider>
          <GoalConfigurationForm form={form} mode="create" />
        </MealPlanCalendarContextProvider>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={form.handleSubmit(submit)}>Save</Button>
        </Box>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
    </Dialog>
  );
};

import { Box, Divider, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { PlannedMenuOccurrence } from "@notemeal/graphql/types";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { LabeledSelectWithPlaceholder } from "apps/web/src/components/universal/LabeledSelectWithPlaceholder";
import { addWeeks } from "date-fns";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import TimezoneSelect from "../../Menus/SelectComponents/TimezoneSelect";
import { PlannedMenuFormType } from "./PlannedMenuFormSchema";

interface PlannedMenuFormProps {
  form: UseFormReturn<PlannedMenuFormType>;
  mode: "create" | "edit" | "duplicate";
  shouldDisableStartDate?: (date: Date) => boolean;
  numWeeks?: number; // only for duplicate
}

export const PlannedMenuForm = ({ form, mode, numWeeks = 0, shouldDisableStartDate }: PlannedMenuFormProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = form;

  const startDate = watch("dateRange.startDate");
  const disableOccurrence = mode === "edit" || mode === "duplicate";
  const disableEndDate = mode === "duplicate";
  const dateRangeSubcopy =
    mode === "edit"
      ? "Select the date range and occurrence of the menu. Please note that any menu items that are no longer in the new date range will be deleted."
      : "Select the date range and occurrence of the menu";

  return (
    <>
      <SectionHeader header="Menu Details" subcopy="Input the menu name and description" />
      <Box sx={{ display: "flex", gap: 1 }}>
        <Controller
          name={"menuName"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              sx={{ width: "240px" }}
              label="Menu Name"
              error={Boolean(errors.menuName)}
              helperText={errors.menuName?.message}
              autoFocus={!field.value}
              placeholder="Name"
            />
          )}
        />
        <Controller
          name={"menuDescription"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              sx={{ width: "330px" }}
              label="Description (optional)"
              error={Boolean(errors.menuDescription)}
              helperText={errors.menuDescription?.message}
              placeholder="Description"
            />
          )}
        />
      </Box>
      <Divider />
      <SectionHeader header="Date Range" subcopy={dateRangeSubcopy} />
      <Box sx={{ mt: 3, display: "flex", gap: 2 }}>
        <Controller
          name={"dateRange.startDate"}
          control={control}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              sx={{ width: "18%" }}
              label="Start Date"
              value={field.value ?? null}
              shouldDisableDate={shouldDisableStartDate}
              onChange={date => {
                field.onChange(date);
                if (mode === "duplicate" && date) {
                  form.setValue("dateRange.endDate", addWeeks(date, numWeeks - 1));
                }
                // react-hook-form only notices errors from the field that is changing
                // this forces it to accept errors from our superRefine which spans fields
                form.trigger("dateRange.dateRangeError");
              }}
              slotProps={{
                textField: {
                  error: Boolean(errors.dateRange?.startDate || errors.dateRange?.dateRangeError),
                  helperText: errors.dateRange?.startDate?.message ?? errors.dateRange?.dateRangeError?.message,
                  placeholder: "Select Date",
                },
              }}
            />
          )}
        />
        <Controller
          name={"dateRange.endDate"}
          control={control}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              sx={{ width: "18%" }}
              label="End Date"
              disabled={disableEndDate}
              minDate={startDate}
              value={field.value ?? null}
              onChange={date => {
                field.onChange(date);
                // react-hook-form only notices errors from the field that is changing
                // this forces it to accept errors from our superRefine which spans fields
                form.trigger("dateRange.dateRangeError");
              }}
              slotProps={{
                textField: {
                  error: Boolean(errors.dateRange?.endDate || errors.dateRange?.dateRangeError),
                  helperText: errors.dateRange?.endDate?.message ?? errors.dateRange?.dateRangeError?.message,
                  placeholder: "Select Date",
                },
              }}
            />
          )}
        />
        <Controller
          name={"occurrence"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <LabeledSelectWithPlaceholder
              optionToName={option => {
                if (option === PlannedMenuOccurrence.daily) {
                  return "Daily (Monday - Sunday)";
                } else if (option === PlannedMenuOccurrence.weekdays) {
                  return "Every weekday (Monday - Friday)";
                } else {
                  return option;
                }
              }}
              placeholder={"Occurrence"}
              selectedOption={field.value ?? null}
              options={[PlannedMenuOccurrence.daily, PlannedMenuOccurrence.weekdays]}
              {...field}
              disabled={disableOccurrence}
              error={Boolean(errors.occurrence)}
              helperText={errors.occurrence?.message}
              sx={{ mt: 0, pt: 0, width: "30%" }}
            />
          )}
        />
        <Controller
          name={"timezone"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TimezoneSelect
              value={field.value ?? null}
              onChange={field.onChange}
              sx={{ pt: 0, mt: 0, width: "20%" }}
              error={Boolean(errors.timezone)}
              helperText={errors.timezone?.message}
            />
          )}
        />
      </Box>
      <Divider />
    </>
  );
};

import LockIcon from "@mui/icons-material/LockOutlined";
import { Theme, Tooltip } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { dateToIsoInTz } from "@notemeal/shared/ui/utils/dateTimes";
import DayColumn from "apps/web/src/components/Calendar/Week/DayColumn";
import {
  BaseCalendarDayColumnProps,
  CalendarEvent,
  DAY_HEADER_HEIGHT,
  getCalendarEventsOnDate,
  isDateInPast,
} from "apps/web/src/components/Calendar/Week/utils";
import React from "react";
import { LOCKED_DAY_REASON } from "./Form/utils";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) =>
  createStyles({
    lockedDay: {
      position: "absolute",
      backgroundColor: grey[400],
      opacity: 0.75,
      zIndex: 100,
      top: -DAY_HEADER_HEIGHT,
      bottom: 0,
      right: spacing(-2),
      left: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    lockIcon: {
      position: "fixed",
      top: 400,
      bottom: 350,
      width: 35,
      height: 35,
    },
  })
);

export interface MealPlanCalendarDayColumnProps<E extends CalendarEvent> extends BaseCalendarDayColumnProps<E> {
  isLockedDay?: boolean;
}

const MealPlanCalendarDayColumn = <E extends CalendarEvent>({
  date,
  clientTimezone,
  events,
  renderEvent,
  renderNewEvent,
  dragSettings,
  displaySettings,
  showAllDayCell,
  sx,
  isLockedDay,
}: MealPlanCalendarDayColumnProps<E>) => {
  const classes = useStyles();
  const dateStart = new Date(dateToIsoInTz(date, clientTimezone));
  const isInPast = isDateInPast(new Date(), dateStart);
  const shouldLockDay = isLockedDay && !isInPast;

  return (
    <DayColumn
      key={date}
      date={date}
      clientTimezone={clientTimezone}
      events={getCalendarEventsOnDate(events, date, clientTimezone)}
      renderEvent={renderEvent}
      renderNewEvent={renderNewEvent}
      dragSettings={shouldLockDay ? undefined : dragSettings}
      displaySettings={displaySettings}
      showAllDayCell={showAllDayCell}
      sx={sx}
    >
      {shouldLockDay && (
        <Tooltip title={LOCKED_DAY_REASON} placement="left">
          <div className={classes.lockedDay}>
            <LockIcon className={classes.lockIcon} />
          </div>
        </Tooltip>
      )}
    </DayColumn>
  );
};

export default MealPlanCalendarDayColumn;

import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { ClientCredentialFragment } from "../../../types";
import TextFieldWithCopy from "../TextFieldWithCopy";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientSecretDiv: {
      display: "flex",
      flexDirection: "column",
    },
    redText: {
      color: theme.palette.error.main,
    },
    icon: {
      marginLeft: theme.spacing(),
    },
    editRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    grow: {
      flexGrow: 1,
      marginRight: theme.spacing(3),
    },
    refreshBtn: {
      fontWeight: 700,
    },
  })
);

interface ClientCredentialsEditDialogProps {
  open: boolean;
  onClose: () => void;
  clientCredential: ClientCredentialFragment;
  onEdit: (name: string, isActive: boolean) => void;
  onRefresh: () => void;
}

const ClientCredentialsEditDialog = ({ open, onClose, clientCredential, onEdit, onRefresh }: ClientCredentialsEditDialogProps) => {
  const classes = useStyles();

  const [verifyRefresh, setVerifyRefresh] = useState(false);
  const [name, setName] = useState(clientCredential.name);
  const [isActive, setIsActive] = useState(clientCredential.isActive);

  const handleSave = () => {
    if (name) {
      onEdit(name, isActive);
      onClose();
    }
  };

  const handleRefresh = () => {
    onRefresh();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle title="Edit Credential" onClose={onClose} />
      <DialogContent className={classes.content}>
        <Typography
          sx={{ mb: 2 }}
          variant="body2"
          color="textSecondary">
          Edit the name of this credential, or toggle if it is active.
        </Typography>
        <div className={classes.editRow}>
          <TextField
            label="Name"
            value={name}
            onChange={e => setName(e.target.value)}
            className={classes.grow}
            inputProps={{
              "aria-label": "Credential name",
            }}
          />
          <FormControlLabel
            control={<Checkbox
              checked={isActive}
              onChange={(_, checked) => setIsActive(checked)}
              aria-label="Is Active" />}
            label="Is Active"
          />
        </div>
        <div className={classes.editRow}>
          <TextFieldWithCopy
            label="Client ID"
            value={clientCredential.clientId}
            className={classes.grow}
            ariaLabel="Client ID" />
          <div className={classes.clientSecretDiv}>
            <Typography variant="subtitle1" color="textSecondary">
              Client Secret
            </Typography>
            <Button
              onClick={() => setVerifyRefresh(true)}
              aria-label="Refresh Client ID"
              className={classes.refreshBtn}>
              Refresh
              <RefreshIcon className={classes.icon} />
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>

      {verifyRefresh && (
        <Dialog
          open={verifyRefresh}
          onClose={() => setVerifyRefresh(false)}
          maxWidth="xs">
          <DialogTitle title="Refresh Client Secret?" onClose={() => setVerifyRefresh(false)} />
          <DialogContent>
            <Typography variant="body2" color="textSecondary">
              <span className={classes.redText}>
                <strong>Warning</strong>: You can't undo this!
              </span>
              <br />
              <br />
              You will have to replace any old usages of the <strong>Client Secret</strong> with the new value, or API requests will fail
              authentication.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setVerifyRefresh(false)}>
              Cancel
            </Button>
            <Button onClick={handleRefresh}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default ClientCredentialsEditDialog;

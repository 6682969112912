import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Checkbox, FormControlLabel, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

export const ThemeViewerTextField = () => {
  const [mainText, setMainText] = useState("");
  const [errorText, setErrorText] = useState("legal first name");
  const [textChecked, setTextChecked] = useState(false);
  const [errorChecked, setErrorChecked] = useState(false);
  const [disabledChecked, setDisabledChecked] = useState(false);

  const handleTextChecked = () => {
    const newTextChecked = !textChecked;
    setTextChecked(newTextChecked);

    const text = newTextChecked ? "Text" : "";
    setMainText(text);
  };

  const handleErrorChecked = () => {
    const newErrorChecked = !errorChecked;
    setErrorChecked(newErrorChecked);

    const error = newErrorChecked ? "Fix the error" : "legal first name";
    setErrorText(error);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">TextField</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <FormControlLabel control={<Checkbox checked={textChecked} onChange={() => handleTextChecked()} />} label="Text" />
        <FormControlLabel control={<Checkbox checked={errorChecked} onChange={() => handleErrorChecked()} />} label="Error" />
        <FormControlLabel
          control={<Checkbox checked={disabledChecked} onChange={() => setDisabledChecked(!disabledChecked)} />}
          label="Disabled"
        />
      </Box>

      <Typography variant="h3">Medium</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          label="First Name"
          placeholder="Placeholder"
          disabled={disabledChecked}
          error={errorChecked}
          helperText={errorText}
          onChange={e => setMainText(e.target.value)}
          value={mainText}
        />
        <TextField
          sx={{ width: 150 }}
          label="Both Icons"
          placeholder="Placeholder"
          disabled={disabledChecked}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SettingsIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SettingsIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setMainText(e.target.value)}
          value={mainText}
        />
        <TextField
          label="Search"
          placeholder="Placeholder"
          disabled={disabledChecked}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setMainText(e.target.value)}
          value={mainText}
        />
      </Box>
      <Typography variant="h3">Small</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <TextField
          size="small"
          label="First Name"
          placeholder="Placeholder"
          disabled={disabledChecked}
          error={errorChecked}
          helperText={errorText}
          onChange={e => setMainText(e.target.value)}
          value={mainText}
        />
        <TextField
          size="small"
          sx={{ width: 150 }}
          label="Both Icons"
          placeholder="Placeholder"
          disabled={disabledChecked}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SettingsIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SettingsIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setMainText(e.target.value)}
          value={mainText}
        />
        <TextField
          size="small"
          label="Search"
          placeholder="Placeholder"
          disabled={disabledChecked}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setMainText(e.target.value)}
          value={mainText}
        />
      </Box>
    </Box>
  );
};

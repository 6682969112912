import { Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useState } from "react";

export const ThemeViewerButton = () => {
  const [disabled, setDisabled] = useState(false);
  const [variantChoice, setVariantChoice] = useState("normal");

  const getContainedVariant = () =>
    variantChoice === "normal"
      ? "contained"
      : variantChoice === "success"
      ? "containedSuccess"
      : variantChoice === "informational"
      ? "containedInformational"
      : variantChoice === "warning"
      ? "containedWarning"
      : "containedDestructive";

  const getOutlinedVariant = () =>
    variantChoice === "normal"
      ? "outlined"
      : variantChoice === "success"
      ? "outlinedSuccess"
      : variantChoice === "informational"
      ? "outlinedInformational"
      : variantChoice === "warning"
      ? "outlinedWarning"
      : "outlinedDestructive";

  const getTextVariant = () =>
    variantChoice === "normal"
      ? "text"
      : variantChoice === "success"
      ? "textSuccess"
      : variantChoice === "informational"
      ? "textInformational"
      : variantChoice === "warning"
      ? "textWarning"
      : "textDestructive";

  return (
    <>
      <Typography variant="h2">Button</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControlLabel control={<Checkbox checked={disabled} onChange={() => setDisabled(!disabled)} />} label="Disabled" />
          <RadioGroup
            row
            value={variantChoice}
            onChange={e => setVariantChoice(e.target.value)}>
            <FormControlLabel
              value="normal"
              control={<Radio />}
              label="Normal" />
            <FormControlLabel
              value="success"
              control={<Radio />}
              label="Success" />
            <FormControlLabel
              value="informational"
              control={<Radio />}
              label="Informational" />
            <FormControlLabel
              value="warning"
              control={<Radio />}
              label="Warning" />
            <FormControlLabel
              value="destructive"
              control={<Radio />}
              label="Destructive" />
          </RadioGroup>
        </Box>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">Contained</Typography>
            <Box sx={{ alignItems: "flex-start", height: "60px", display: "flex", gap: 2 }}>
              <Button
                size="large"
                variant={getContainedVariant()}
                disabled={disabled}>
                Large
              </Button>
              <Button variant={getContainedVariant()} disabled={disabled}>
                Medium
              </Button>
              <Button
                size="small"
                variant={getContainedVariant()}
                disabled={disabled}>
                Small
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">Outlined</Typography>
            <Box sx={{ alignItems: "flex-start", height: "60px", display: "flex", gap: 2 }}>
              <Button
                size="large"
                variant={getOutlinedVariant()}
                disabled={disabled}>
                Large
              </Button>
              <Button variant={getOutlinedVariant()} disabled={disabled}>
                Medium
              </Button>
              <Button
                size="small"
                variant={getOutlinedVariant()}
                disabled={disabled}>
                Small
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h3">Text</Typography>
            <Box sx={{ alignItems: "flex-start", height: "60px", display: "flex", gap: 2 }}>
              <Button
                size="large"
                variant={getTextVariant()}
                disabled={disabled}>
                Large
              </Button>
              <Button variant={getTextVariant()} disabled={disabled}>
                Medium
              </Button>
              <Button
                size="small"
                variant={getTextVariant()}
                disabled={disabled}>
                Small
              </Button>
            </Box>
          </Box>
        </Box>
        <Typography variant="subtitle1">Heights: Large 44, Medium 36, Small 28</Typography>
      </Box>
    </>
  );
};

import { z } from "zod";

export const SelfServiceAthleteFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email({ message: "Invalid email address" }),
  orgId: z.string(),
});

export type SelfServiceAthleteForm = z.infer<typeof SelfServiceAthleteFormSchema>;

export const selfServiceAthleteFormDefaultValues: SelfServiceAthleteForm = {
  firstName: "",
  lastName: "",
  email: "",
  orgId: "",
};

import { Box, Typography } from "@mui/material";
import { ExchangeAmountFragment } from "../types";
import { createExchangeFractions } from "./utils";

interface ExchangeFractionLabelProps {
  targetExchangeAmounts: readonly ExchangeAmountFragment[];
  currentExchangeAmounts: readonly ExchangeAmountFragment[];
  variant: "xs" | "sm" | "lg";
  overrideStyle?: boolean;
}

const ExchangeFractionLabel = ({ targetExchangeAmounts, currentExchangeAmounts, variant, overrideStyle }: ExchangeFractionLabelProps) => {
  const exchangeFractions = createExchangeFractions({
    currentExchangeAmounts,
    targetExchangeAmounts,
  });

  return (
    <Typography
      sx={[
        { display: "flex", flexFlow: "row wrap", justifyContent: "space-around" },
        variant === "xs" ? { root: { fontSize: ".6rem", lineHeight: 1.1 } } : {},
      ]}
      variant={variant === "lg" ? "subtitle1" : "caption"}
      color="inherit"
    >
      {exchangeFractions.map(
        ({ numerator, denominator, label, id, type }) =>
          (!!numerator || !!denominator) && (
            <Box component="span" key={id} sx={{ flexBasis: "12.5%", textAlign: "center" }}>
              <span>{label}</span> <br />
              <Box component="span" sx={{ color: numerator && numerator > denominator ? "error.main" : "inherit" }}>
                {numerator}/{denominator}
              </Box>
            </Box>
          )
      )}
    </Typography>
  );
};

export default ExchangeFractionLabel;

import { useApolloClient } from "@apollo/client";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import {
  StaffMealPlanTemplateListItemFragment,
  useCopySharedMacroMealPlanTemplateMutation,
  useDeleteSharedMacroMealPlanTemplateMutation,
  useStaffSharedMealPlanTemplateOffsetConnectionQuery,
  useUpdateSharedMacroMealPlanTemplateSharingScopeMutation,
} from "../../../../types";
import { getMuiTablePaginationProps, useOffsetPagination } from "../../../../utils/pagination";
import CreateModal from "../../../../views/Staff/MealPlanTemplates/CreateModal";
import EditModal from "../../../../views/Staff/MealPlanTemplates/EditModal";

type ConfirmDelete = {
  macroMealPlanTemplateId: string;
  name: string;
};

interface MoreInfo {
  moreAnchorElement: HTMLElement;
  id: string;
  name: string;
}

export const MealPlanTemplatesPage = () => {
  const apolloClient = useApolloClient();
  const { setMessage } = useSnackbar();

  const [sortAscending, setSortAscending] = useState(true);
  const ROWS_PER_PAGE = 5;
  const paginationHooks = useOffsetPagination(ROWS_PER_PAGE);
  const { limit, offset, query: debouncedSearchText, queryText: searchText, onChangeQueryText, onChangePage } = paginationHooks;
  const { data, loading: loadingList } = useStaffSharedMealPlanTemplateOffsetConnectionQuery({
    variables: { pagination: { offset, limit }, searchText: debouncedSearchText, sortAscending },
  });
  const [updateIsShared] = useUpdateSharedMacroMealPlanTemplateSharingScopeMutation();
  const [copySharedMealPlanTemplate] = useCopySharedMacroMealPlanTemplateMutation({
    onCompleted: ({ copySharedMacroMealPlanTemplate: { macroMealPlanTemplate } }) => {
      completeAction("copied", "", true);
      setSelectedTemplateId(macroMealPlanTemplate.id);
    },
  });
  const [removeMealPlanTemplate] = useDeleteSharedMacroMealPlanTemplateMutation({
    onCompleted: () => completeAction("removed", "", true),
  });
  const [showCreate, setShowCreate] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const rows = data?.sharedMealPlanTemplateOffsetConnection.edges || [];
  const getSortOrder = () => (sortAscending ? "asc" : "desc");
  const toggleSortOrder = () => setSortAscending(!sortAscending);

  const handleCreateSave = async (mptId: string) => {
    setShowCreate(false);
    setSelectedTemplateId(mptId);
  };

  const onClose = () => {
    setShowCreate(false);
    setSelectedTemplateId(null);
  };

  const completeAction = (action: string, name: string, resetPaging: boolean, mptId?: string) => {
    if (resetPaging) {
      apolloClient.cache.evict({ fieldName: "sharedMealPlanTemplateOffsetConnection", broadcast: true });
      onChangePage(0);
    }
    setMessage("success", `Successfully ${action} Meal Plan Template ${name}`);
    if (mptId) {
      handleCreateSave(mptId);
    } else {
      onClose();
    }
  };
  const onCreateSuccess = ({ name, id }: StaffMealPlanTemplateListItemFragment) => completeAction("created", name, true, id);
  const onEditSuccess = ({ name }: StaffMealPlanTemplateListItemFragment) => completeAction("edited", name, false);
  const onDiscardEditSuccess = () => completeAction("discarded edits", "", false);
  const onPublishSuccess = ({ name }: StaffMealPlanTemplateListItemFragment) => completeAction("published", name, true);

  const [confirmDelete, setConfirmDelete] = useState<ConfirmDelete | null>(null);
  const title = "Delete Meal Plan Template?";
  const message = `This action cannot be undone. Are you sure you want to delete the meal plan template “${
    confirmDelete ? confirmDelete.name : ""
  }”?`;
  const onCancel = () => {
    setConfirmDelete(null);
  };
  const onConfirm = () => {
    if (confirmDelete) {
      const { macroMealPlanTemplateId } = confirmDelete;
      removeMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId } } });
    }
    onCancel();
  };
  const [moreInfo, setMoreInfo] = useState<MoreInfo | null>(null);

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", alignItems: "flex-end" }}>
        <TextField
          sx={{ width: 250 }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={e => onChangeQueryText(e.target.value)}
        />
        <Button onClick={() => setShowCreate(true)}>Create Template</Button>
      </Box>
      {loadingList && (
        <Box sx={{ zIndex: 1, minHeight: 0, overflowY: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loading progressSize="md" />
        </Box>
      )}
      {!loadingList && (
        <>
          <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ w: 150 }}>Shared with orgs</TableCell>
                  <TableCell>
                    <TableSortLabel direction={getSortOrder()} onClick={toggleSortOrder}>
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell sx={{ w: 50 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  const { id, name, isShared, pendingState } = row;
                  const hasPending = !!pendingState;
                  const greenChip = {
                    color: "green",
                    borderColor: "green",
                    "& .MuiChip-label": {
                      color: "green",
                    },
                  };
                  const blueChip = {
                    color: "info.main",
                    borderColor: "info.main",
                    "& .MuiChip-label": {
                      color: "info.main",
                    },
                  };
                  const chipStyles = hasPending ? greenChip : blueChip;
                  const chipLabel = hasPending ? "Pending Changes" : "All Changes Published";

                  return (
                    <React.Fragment key={id}>
                      <TableRow id={id} onClick={() => setSelectedTemplateId(id)}>
                        <TableCell>
                          <Tooltip title={"Share meal plan template with all orgs"}>
                            <FormControl>
                              <Switch
                                size="medium"
                                inputProps={{
                                  "aria-label": "Share meal plan template with all orgs",
                                }}
                                checked={isShared}
                                onClick={e => e.stopPropagation()}
                                onChange={e =>
                                  updateIsShared({ variables: { input: { macroMealPlanTemplateId: id, isShared: e.target.checked } } })
                                }
                              />
                            </FormControl>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>
                          <Chip
                            sx={chipStyles}
                            variant="outlined"
                            label={chipLabel} />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={e => {
                              e.stopPropagation();
                              setMoreInfo({ moreAnchorElement: e.currentTarget, id, name });
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <Menu
                        anchorEl={moreInfo?.moreAnchorElement}
                        open={!!moreInfo}
                        onClose={() => {
                          setMoreInfo(null);
                        }}
                        keepMounted
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {moreInfo && (
                          <>
                            <MenuItem
                              dense
                              onClick={() => {
                                copySharedMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId: moreInfo.id } } });
                                setMoreInfo(null);
                              }}
                            >
                              Duplicate
                            </MenuItem>
                            <MenuItem
                              dense
                              onClick={() => {
                                setConfirmDelete({ macroMealPlanTemplateId: moreInfo.id, name: moreInfo.name });
                                setMoreInfo(null);
                              }}
                            >
                              Delete
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
            {...getMuiTablePaginationProps(paginationHooks, data?.sharedMealPlanTemplateOffsetConnection.pageInfo.total || 0)}
          />
        </>
      )}
      {showCreate && <CreateModal
        open={showCreate}
        onClose={onClose}
        onCreateSuccess={onCreateSuccess} />}
      {selectedTemplateId && (
        <EditModal
          open={selectedTemplateId !== null}
          mealPlanTemplateId={selectedTemplateId}
          onClose={onClose}
          onEditSuccess={onEditSuccess}
          onDiscardEditSuccess={onDiscardEditSuccess}
          onPublishSuccess={onPublishSuccess}
        />
      )}
      {confirmDelete && (
        <ConfirmationDialog
          open={!!confirmDelete}
          title={title}
          message={message}
          onCancel={onCancel}
          onConfirm={onConfirm}
          variant="containedDestructive"
        />
      )}
    </Box>
  );
};

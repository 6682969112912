import { Dialog, DialogContent } from "@mui/material";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import React from "react";
import EditMealPlanTemplate from "../../../components/MealPlanTemplate/EditMealPlanTemplate";
import {
  StaffMealPlanTemplateListItemFragment,
  useDeleteSharedMacroMealPlanTemplatePendingStateMutation,
  useEditSharedMacroMealPlanTemplateMutation,
  useGoalTypesQuery,
  usePublishSharedMacroMealPlanTemplateMutation,
  useSharedMealPlanTemplateQuery,
} from "../../../types";

interface EditModalProps {
  open: boolean;
  onClose: () => void;
  onEditSuccess: (mealPlanTemplate: StaffMealPlanTemplateListItemFragment) => void;
  onDiscardEditSuccess: () => void;
  onPublishSuccess: (mealPlanTemplate: StaffMealPlanTemplateListItemFragment) => void;
  mealPlanTemplateId: string;
}

const EditModal = ({ open, onClose, mealPlanTemplateId, onEditSuccess, onDiscardEditSuccess, onPublishSuccess }: EditModalProps) => {
  const [editMealPlanTemplate] = useEditSharedMacroMealPlanTemplateMutation({
    onCompleted: data => onEditSuccess(data.editSharedMacroMealPlanTemplate.macroMealPlanTemplate),
  });
  const [publishMealPlanTemplate] = usePublishSharedMacroMealPlanTemplateMutation({
    onCompleted: data => onPublishSuccess(data.publishSharedMacroMealPlanTemplate.macroMealPlanTemplate),
  });
  const [deletePendingState] = useDeleteSharedMacroMealPlanTemplatePendingStateMutation({
    onCompleted: data => onDiscardEditSuccess(),
  });
  const { data: mealTemplateData, loading: loadingMealTemplate } = useSharedMealPlanTemplateQuery({
    variables: {
      id: mealPlanTemplateId,
    },
  });
  const { data: goalTypeData, loading: loadingGoalTypes } = useGoalTypesQuery();
  const loading = loadingGoalTypes || loadingMealTemplate;

  return loading || !mealTemplateData?.sharedMealPlanTemplate || !goalTypeData?.goalTypes ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogContent>
        <EditMealPlanTemplate
          isShared={true}
          mealPlanTemplate={mealTemplateData.sharedMealPlanTemplate}
          editMealPlanTemplate={input => editMealPlanTemplate({ variables: { input } })}
          publishMealPlanTemplate={input => publishMealPlanTemplate({ variables: { input } })}
          deletePendingState={input => deletePendingState({ variables: { input } })}
          onClose={onClose}
          goalTypes={goalTypeData.goalTypes}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditModal;

import {
  AnthropometryEntrySnapshot,
  AnthropometrySnapshot,
  SexType,
  formatImperialHeight,
  formatImperialWeight,
  formatMetricHeight,
  formatMetricWeight,
} from "@notemeal/shared/utils/macro-protocol";
import { useCallback } from "react";
import { useLocaleContext } from "./LocaleContext";

interface ExtractedAnthropometryValues {
  weight: number;
  leanBodyMass: number | null;
  percentBodyFat: number | null;
  height: number | null;
  sex: SexType;
  age: number | null;
}

interface ExtractedAnthropometryEntryValues extends ExtractedAnthropometryValues {
  datetime: string;
}

export const useAnthropometryFormatting = () => {
  const { isMetricLocale } = useLocaleContext();

  const formatHeight = useCallback(
    (height: number): string => {
      return isMetricLocale ? formatMetricHeight(height) : formatImperialHeight(height);
    },
    [isMetricLocale]
  );

  const formatWeight = useCallback(
    (weight: number): string => {
      return isMetricLocale ? formatMetricWeight(weight) : formatImperialWeight(weight);
    },
    [isMetricLocale]
  );

  const extractValuesFromAnthropometrySnapshot = useCallback(
    (anthropometrySnapshot: AnthropometrySnapshot): ExtractedAnthropometryValues => {
      const { weight, leanBodyMass, percentBodyFat, height, sex, age, weightInKg, leanBodyMassInKg, heightInCm } = anthropometrySnapshot;
      return isMetricLocale
        ? {
            weight: weightInKg,
            leanBodyMass: leanBodyMassInKg,
            percentBodyFat,
            height: heightInCm,
            age,
            sex,
          }
        : {
            weight,
            leanBodyMass,
            percentBodyFat,
            height,
            age,
            sex,
          };
    },
    [isMetricLocale]
  );

  const extractValuesFromAnthropometryEntrySnapshot = useCallback(
    (anthropometryEntrySnapshot: AnthropometryEntrySnapshot): ExtractedAnthropometryEntryValues => ({
      ...extractValuesFromAnthropometrySnapshot(anthropometryEntrySnapshot),
      datetime: anthropometryEntrySnapshot.datetime,
    }),
    [extractValuesFromAnthropometrySnapshot]
  );

  return {
    formatHeight,
    formatWeight,
    extractValuesFromAnthropometrySnapshot,
    extractValuesFromAnthropometryEntrySnapshot,
  };
};

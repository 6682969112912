import WarningIcon from "@mui/icons-material/Warning";
import { FormControl, FormControlLabel, Radio, RadioGroup, Switch, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { Dispatch } from "react";
import { Link } from "react-router-dom";
import { NAV_ORG_MEAL_PLANS_EXCHANGE_SETS } from "../../../pages/Auth/Org/MealPlans/MealPlansPaths";
import LabeledSelect from "../../universal/LabeledSelect";
import { MealPlanFormAction, MealPlanFormState } from "../Form/utils";

const useStyles = makeStyles(({ spacing, palette: { grey } }: Theme) => {
  const baseSize = 14;

  return createStyles({
    root: {
      paddingTop: spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      height: "100%",
    },
    mealPlanName: {
      width: "40%",
    },
    mealPlanDetails: {
      paddingTop: spacing(4),
    },
    typeAndShareRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: spacing(2),
    },
    typeSelect: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    group: {
      margin: spacing(1, 0),
      display: "flex",
      flexDirection: "row",
    },
    description: {
      lineHeight: 1.25,
    },
    warning: {
      display: "flex",
    },
    placeholder: {
      height: "20px", //hard coded to the height of the small icon
    },
    exchangeSelect: {
      paddinTop: 0,
    },
    exchangeText: {
      minWidth: spacing(baseSize * 2),
    },
    denseSwitch: {
      alignItems: "right",
      marginLeft: "5px",
      marginRight: "3px",
      paddingTop: 5,
      paddingBottom: 5,
    },
    greyInputLabel: {
      color: grey[600],
    },
  });
});

interface NameAndTypeFormProps {
  state: MealPlanFormState;
  dispatch: Dispatch<MealPlanFormAction>;
  editMode?: boolean;
  copyForm?: boolean;
}

export const NameAndTypeForm = ({ state, dispatch, editMode = false, copyForm = false }: NameAndTypeFormProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        className={classes.mealPlanName}
        fullWidth
        label="Meal Plan Name"
        value={state.name}
        onChange={e => dispatch({ type: "CHANGE_NAME", payload: e.target.value })}
      />
      <div className={classes.mealPlanDetails}>
        <div className={classes.typeAndShareRow}>
          <div className={classes.typeSelect}>
            <FormControl disabled={editMode}>
              <RadioGroup className={classes.group} value={state.type}>
                <FormControlLabel
                  value="macro"
                  control={<Radio />}
                  label="Macro"
                  onChange={() => {
                    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Select Macro Type", {});
                    dispatch({ type: "CHANGE_TYPE", payload: "macro" });
                  }}
                />
                <FormControlLabel
                  value="exchange"
                  control={<Radio />}
                  label="Exchange:"
                  onChange={() => {
                    trackEvent("Nutrition | Team | Athlete | Create Meal Plan | Select Exchange Type", {});
                    dispatch({
                      type: "CHANGE_TYPE",
                      payload: "exchange",
                    });
                  }}
                />
              </RadioGroup>
            </FormControl>
            {state.type === "exchange" && (
              <>
                {state.exchangeSets.length >= 1 ? (
                  <LabeledSelect
                    placeholder="Exchange Set"
                    options={[...state.exchangeSets]}
                    selectedOption={state.exchangeSet}
                    optionToName={es => es.name}
                    SelectProps={{ IconComponent: () => null }}
                    onChange={es => {
                      dispatch({
                        type: "CHANGE_EXCHANGE_SET",
                        payload: es,
                      });
                    }}
                    textFieldProps={{
                      disabled: copyForm,
                      fullWidth: false,
                      className: classes.exchangeText,
                    }}
                  />
                ) : (
                  <Link to={NAV_ORG_MEAL_PLANS_EXCHANGE_SETS}>
                    <Typography>Click here to create a custom exchange set!</Typography>
                  </Link>
                )}

                {editMode && state.exchangeSet?.id !== state.initialExchangeSetId && !copyForm ? (
                  <div className={classes.warning}>
                    <WarningIcon fontSize="small" />
                    <div>
                      <Typography variant="caption">Exchange targets will be reset to zero</Typography>
                    </div>
                  </div>
                ) : !copyForm && editMode ? (
                  <div className={classes.placeholder} />
                ) : null}
              </>
            )}
          </div>
          {copyForm && (
            <FormControlLabel
              control={
                <Switch
                  checked={state.isShared}
                  onChange={e => {
                    dispatch({
                      type: "CHANGE_IS_SHARED",
                      payload: e.target.checked,
                    });
                  }}
                />
              }
              className={classes.denseSwitch}
              label="Share to athlete mobile app"
              labelPlacement="end"
              classes={{ label: classes.greyInputLabel }}
            />
          )}
        </div>
        <TextField
          className={classes.description}
          fullWidth
          multiline
          rows={3}
          maxRows={5}
          label="Note to Athlete"
          value={state.description}
          placeholder="This note will be included on the meal plan export."
          onChange={e => dispatch({ type: "CHANGE_DESCRIPTION", payload: e.target.value })}
        />
      </div>
    </div>
  );
};

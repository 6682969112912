import { Button, Dialog, DialogActions, DialogContent, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ExchangeMealPlanLegacyDisplaySettings,
  MacroMealPlanLegacyDisplaySettings,
  initialExchangeMealPlanLegacyDisplaySettings,
  initialMacroMealPlanLegacyDisplaySettings,
} from "@notemeal/shared/ui/DisplaySettings/utils";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import axios from "axios";
import React, { useCallback, useReducer, useRef, useState } from "react";
import MealPlanExportContainer from "../../../../components/MealPlan/Export/Container";
import { mealPlanJpegsToPdf, mealPlanPageDivsToJpegs } from "../../../../components/MealPlan/Export/Modal/utils";
import ExportToolbarBulk from "../../../../components/MealPlan/Export/Toolbar/Bulk";
import { Orientation, SCALE_FACTOR, getMealPlanFilename } from "../../../../components/MealPlan/Export/utils";
import { createInitialExportFormState, exportFormReducer } from "../../../../components/MealPlan/Export/utils/pdfReducer";
import { useGetEditMealPlanQuery, useRecipesByIdQuery } from "../../../../types";

const useStyles = (orientation: Orientation) => {
  return makeStyles(({ palette: { grey } }: Theme) => ({
    /* WARNING!
     * Any class here will not get respected (after a re-render) in any of the
     * pagified components. These classes should ONLY be used for the Modal component.
     * */
    modalPaper: {
      padding: `${0.1}in`,
      height: `${orientation === "portrait" ? 11 * SCALE_FACTOR : 8.5 * SCALE_FACTOR}in`,
      width: `${orientation === "portrait" ? 8.5 * SCALE_FACTOR : 11 * SCALE_FACTOR}in`,
      overflowY: "auto",
    },
    container: {
      padding: "10px",
      display: "flex",
      flexDirection: "row",
      backgroundColor: grey[300],
    },
  }))();
};

export interface CurrentMealPlanExportInfo {
  hasEmail: boolean;
  athlete: {
    firstName: string;
    lastName: string;
  };
  name: string;
}

interface TeamAthletesMealPlansBulkExportModalProps {
  open: boolean;
  onClose: () => void;
  previewMealPlanIds: string[];
}

const TeamAthletesMealPlansBulkExportModal = ({ previewMealPlanIds, open, onClose }: TeamAthletesMealPlansBulkExportModalProps) => {
  const [state, dispatch] = useReducer(exportFormReducer, createInitialExportFormState());
  const classes = useStyles(state.orientation);
  const [mealPlanIdx, setMealPlanIdx] = useState(0);
  const [iterating, setIterating] = useState(false);

  const onEachIterating =
    useRef<(pageDivs: HTMLDivElement[], mealPlanInfo: CurrentMealPlanExportInfo, currentMealPlanId: string) => Promise<void>>();
  const onFinishIterating = useRef<() => Promise<void>>();

  const lastMealPlanIdx = previewMealPlanIds.length - 1;
  const safeSetMealPlanIdx = useCallback(
    (newIdx: number) => {
      if (newIdx > lastMealPlanIdx) {
        setMealPlanIdx(0);
      } else if (newIdx < 0) {
        setMealPlanIdx(lastMealPlanIdx);
      } else {
        setMealPlanIdx(newIdx);
      }
    },
    [setMealPlanIdx, lastMealPlanIdx]
  );

  const mealPlanId = previewMealPlanIds[mealPlanIdx];

  const mealPlanResult = useGetEditMealPlanQuery({
    variables: { mealPlanId },
  });
  const recipeIds =
    mealPlanResult.data?.mealPlan.mealTemplates.flatMap(mt =>
      mt.mealOptions.flatMap(mo =>
        mo.servingAmounts.flatMap(sa =>
          sa.serving.foodOrRecipe.__typename === "Recipe" && sa.serving.foodOrRecipe.hasFullAccess ? sa.serving.foodOrRecipe.id : []
        )
      )
    ) ?? [];
  const recipesResult = useRecipesByIdQuery({
    variables: {
      ids: recipeIds,
    },
    skip: !mealPlanResult.data,
  });

  const initialDisplaySettings =
    mealPlanResult.data?.mealPlan.type === "macro"
      ? initialMacroMealPlanLegacyDisplaySettings
      : initialExchangeMealPlanLegacyDisplaySettings;
  const [displaySettings, setDisplaySettings] = useState<MacroMealPlanLegacyDisplaySettings | ExchangeMealPlanLegacyDisplaySettings>(
    () => initialDisplaySettings as MacroMealPlanLegacyDisplaySettings | ExchangeMealPlanLegacyDisplaySettings
  );

  const handleChangePageDivs = async (pageDivs: HTMLDivElement[], mealPlanInfo: CurrentMealPlanExportInfo) => {
    if (iterating) {
      await (onEachIterating.current && onEachIterating.current(pageDivs, mealPlanInfo, previewMealPlanIds[mealPlanIdx]));
      safeSetMealPlanIdx(mealPlanIdx + 1);
      if (mealPlanIdx === lastMealPlanIdx) {
        setIterating(false);
        onFinishIterating.current && onFinishIterating.current();
      }
    }
  };

  const [emailButtonStatus, setEmailButtonStatus] = useState<"called" | "error" | "success">();
  const { setMessage } = useSnackbar();

  const startMealPlanIteration = () => {
    setMealPlanIdx(0);
    setIterating(true);
  };

  const handleDownload = () => {
    onEachIterating.current = async (pageDivs, mealPlanInfo, mealPlanId) => {
      const jpegs = await mealPlanPageDivsToJpegs(pageDivs);
      const pdf = mealPlanJpegsToPdf(jpegs, state.orientation);
      await pdf.save(`${getMealPlanFilename(mealPlanInfo)}.pdf`, {
        returnPromise: true,
      });
    };
    onFinishIterating.current = undefined;
    startMealPlanIteration();
  };

  const handleBulkDownload = () => {
    let allJpegs: string[] = [];
    onEachIterating.current = async pageDivs => {
      const jpegs = await mealPlanPageDivsToJpegs(pageDivs);
      allJpegs = allJpegs.concat(jpegs);
    };
    onFinishIterating.current = async () => {
      const pdf = mealPlanJpegsToPdf(allJpegs, state.orientation);
      pdf.save(`MealPlans.pdf`);
    };
    startMealPlanIteration();
  };

  const handleEmail = () => {
    const formData = new FormData();
    let missingEmailCount: number = 0;

    onEachIterating.current = async (pageDivs, mealPlanInfo, currentMealPlanId) => {
      const { hasEmail } = mealPlanInfo;
      const jpegs = await mealPlanPageDivsToJpegs(pageDivs);
      const pdf = mealPlanJpegsToPdf(jpegs, state.orientation);
      const mealPlanPdf = pdf.output("blob");
      if (hasEmail) {
        formData.append(currentMealPlanId, mealPlanPdf);
      } else {
        missingEmailCount = missingEmailCount + 1;
      }
    };
    onFinishIterating.current = async () => {
      const mealPlanPdfUploadUrl = `${process.env.REACT_APP_URL_SCHEME}${process.env.REACT_APP_SERVER_DOMAIN}/upload/meal-plans`;
      axios
        .post(mealPlanPdfUploadUrl, formData, {
          withCredentials: true,
        })
        .then(() => setEmailButtonStatus("success"))
        .catch(() => setEmailButtonStatus("error"));

      if (missingEmailCount > 0) {
        setMessage("warning", `${missingEmailCount}/${previewMealPlanIds.length} plans were missing athlete emails.`);
      }
    };
    setEmailButtonStatus("called");
    startMealPlanIteration();
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}>
      <DialogTitle title="Export Meal Plans" onClose={onClose} />
      <DialogContent>
        <Paper elevation={1} className={classes.container}>
          <Paper elevation={15} className={classes.modalPaper}>
            {mealPlanResult.loading || !mealPlanResult.data || recipesResult.loading || !recipesResult.data ? (
              <Loading progressSize="lg" />
            ) : (
              <MealPlanExportContainer
                toolbarState={state}
                displaySettings={displaySettings}
                mealPlan={mealPlanResult.data.mealPlan}
                recipes={recipesResult.data.recipesById}
                onChangePageDivs={handleChangePageDivs}
              />
            )}
          </Paper>
          <ExportToolbarBulk
            state={state}
            dispatch={dispatch}
            disabled={iterating}
            onClickEmail={handleEmail}
            onClickDownload={handleDownload}
            onClickBulkDownload={handleBulkDownload}
            onChangeDisplaySettings={setDisplaySettings}
            displaySettings={displaySettings}
            incrementMealPlanNumber={() => safeSetMealPlanIdx(mealPlanIdx + 1)}
            decrementMealPlanNumber={() => safeSetMealPlanIdx(mealPlanIdx - 1)}
            selectedMealPlanNumber={mealPlanIdx + 1}
            mealPlanCount={previewMealPlanIds.length}
            emailButtonStatus={emailButtonStatus}
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamAthletesMealPlansBulkExportModal;

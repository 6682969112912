import { Box, ChipProps, Theme, Typography, useTheme } from "@mui/material";
import { formatAmbiguousServingAmountWithTotalWeight } from "@notemeal/shared/ui/ServingAmount/utils";
import { useAthleteFoodPreferenceContext } from "@notemeal/shared/ui/contexts/AthleteFoodPreferences";
import { useMealPlanFoodPreferenceContext } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import ChipList from "@notemeal/shared/ui/global/ChipList";
import { sortByKey } from "@notemeal/utils/sort";
import React, { Fragment } from "react";
import { FullServingAmountFragment, RecipeWithServingsFragment } from "../../../types";

const getRedChipStyle = ({ palette: { dislike } }: Theme) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexGrow: 1,
  flexShrink: 0,
  paddingLeft: "3px",
  marginTop: `0px`,
  marginBottom: `0px`,
  backgroundColor: dislike.light,
});

const getGreenChipStyle = ({ palette: { like } }: Theme) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  flexGrow: 1,
  flexShrink: 0,
  paddingLeft: "3px",
  marginTop: `0px`,
  marginBottom: `0px`,
  backgroundColor: like.light,
});

const chipStyle = {
  width: "100%",
  height: "auto",
  display: "flex",
  flexGrow: 1,
  flexShrink: 0,
  backgroundColor: "transparent",
};

const foodServingAmountStyle = { lineHeight: "inherit", fontSize: "inherit" };

const twoColumnChipLabelStyle = { flexGrow: 1, flexShrink: 1, minWidth: 0, fontSize: ".5rem", px: 1, py: 0 };
const singleColumnChipLabelStyle = { flexGrow: 1, flexShrink: 1, minWidth: 0, fontSize: ".5rem" };

const twoColumnStyle = {
  display: "flex",
  width: "50%",
  height: "calc(100% - 24px)", // This is the height of the box title
  flexFlow: "column wrap",
};

// Overridden by label in getChipProps
const servingAmountToChipName = (sa: FullServingAmountFragment): string => "";

export interface MealPlanExportSmallChipListProps {
  servingAmounts: readonly FullServingAmountFragment[];
  recipes: readonly RecipeWithServingsFragment[];
  expandRecipes: boolean;
  twoColumns?: boolean;
  negativeFoodStrategy?: "filter" | "highlight";
  positiveFoodStrategy?: "highlight";
}

const MealPlanExportSmallChipList = ({
  servingAmounts,
  recipes,
  expandRecipes,
  twoColumns,
  negativeFoodStrategy,
  positiveFoodStrategy,
}: MealPlanExportSmallChipListProps) => {
  const theme = useTheme();
  const { isLikedFood, isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isAvoidedFood, isPromotedFood } = useMealPlanFoodPreferenceContext();
  const getChipStyle = (foodOrRecipe: FullServingAmountFragment["serving"]["foodOrRecipe"]) => {
    if (foodOrRecipe.__typename === "Recipe") {
      const descendantFoodIds = foodOrRecipe.descendantFoods.map(f => f.id);
      return negativeFoodStrategy === "highlight" &&
        (descendantFoodIds.some(fId => isDislikedFood(fId).value) || descendantFoodIds.some(fId => isAvoidedFood(fId).value))
        ? getRedChipStyle(theme)
        : positiveFoodStrategy &&
          (descendantFoodIds.some(fId => isLikedFood(fId).value) || descendantFoodIds.some(fId => isPromotedFood(fId).value))
        ? getGreenChipStyle(theme)
        : chipStyle;
    } else {
      const foodId = foodOrRecipe.id;
      return negativeFoodStrategy === "highlight" && (isAvoidedFood(foodId).value || isDislikedFood(foodId).value)
        ? getRedChipStyle(theme)
        : !!positiveFoodStrategy && (isPromotedFood(foodId).value || isLikedFood(foodId).value)
        ? getGreenChipStyle(theme)
        : chipStyle;
    }
  };
  const getChipProps = (servingAmount: FullServingAmountFragment): ChipProps => {
    const { foodOrRecipe } = servingAmount.serving;
    const totalAmount = servingAmount.amount;

    const recipeIngredients = recipes.find(r => r.id === foodOrRecipe.id)?.ingredients ?? [];

    const label =
      foodOrRecipe.__typename === "Recipe" ? (
        <Box sx={{ lineHeight: 1, width: "100%" }}>
          <Typography variant="subtitle1" sx={{ lineHeight: "inherit", fontWeight: "bold", fontSize: ".6rem" }}>
            {!expandRecipes && "*"}
            {foodOrRecipe.name}
          </Typography>
          <Typography variant="subtitle1" sx={foodServingAmountStyle}>
            {formatAmbiguousServingAmountWithTotalWeight({ amount: totalAmount, serving: servingAmount.serving })}
          </Typography>
          {expandRecipes && (
            <Box sx={{ pt: 0.4, whiteSpace: "initial", lineHeight: 1.2, fontSize: ".55rem" }}>
              {sortByKey(recipeIngredients, "position").map(({ serving, amount }) => (
                <Fragment key={serving.id}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 300, ...foodServingAmountStyle }}>
                    - {serving.foodOrRecipe.name}, {formatAmbiguousServingAmountWithTotalWeight({ amount, serving: servingAmount.serving })}
                  </Typography>
                </Fragment>
              ))}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{ overflow: "hidden", textOverflow: "ellipsis", lineHeight: 1.1, fontSize: ".6rem", width: "100%", whiteSpace: "initial" }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              fontSize: "inherit",
              lineHeight: "inherit",
            }}
          >
            {foodOrRecipe.name}
          </Typography>
          <Typography variant="subtitle1" sx={{ ...foodServingAmountStyle, pt: 0.2 }}>
            {formatAmbiguousServingAmountWithTotalWeight({ amount: totalAmount, serving: servingAmount.serving })}
          </Typography>
        </Box>
      );

    return {
      size: "small" as "small",
      clickable: false,
      label,
      sx: { "	.MuiChip-label": twoColumns ? twoColumnChipLabelStyle : singleColumnChipLabelStyle, ...getChipStyle(foodOrRecipe) },
    };
  };
  // Filter negative foods out if `negativeFoodStrategy` === filter
  // TODO: Sort SA's by the ones that are liked/promoted
  const filteredServingAmounts =
    negativeFoodStrategy === "filter"
      ? servingAmounts.filter(sa => !isDislikedFood(sa.serving.foodOrRecipe.id) && !isAvoidedFood(sa.serving.foodOrRecipe.id))
      : servingAmounts;

  return (
    <ChipList<FullServingAmountFragment>
      sx={twoColumns ? twoColumnStyle : {}}
      objects={[...sortByKey(filteredServingAmounts, "position")]}
      objectToName={servingAmountToChipName}
      objectToChipProps={getChipProps}
    />
  );
};

export default MealPlanExportSmallChipList;

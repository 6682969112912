import { anthropometryFormFieldsReducer } from "./anthropometryFormFieldsReducer";
import { anthropometryTemplateReducer } from "./anthropometryTemplateReducer";
import { AnthropometryFormField, SexType } from "../../../../types";

export interface AnthropometryFormFieldsState {
  __typename: "FormFields";
  formFields: AnthropometryFormField[];
  sampleWeight: number;
  sampleWeightInput: string;
  samplePercentBodyFat: number | null;
  samplePercentBodyFatInput: string;
  sampleHeight: number | null;
  sampleHeightInput: string;
  sampleAge: number | null;
  sampleAgeInput: string;
  sampleSex: SexType;
}

export interface AnthropometryTemplateState {
  __typename: "Template";
  weight: number;
  weightInput: string;
  height: number | null;
  heightInput: string;
  percentBodyFat: number | null;
  percentBodyFatInput: string;
  sampleAge: number | null;
  sampleAgeInput: string;
  sampleSex: SexType;
}

interface EditFormFieldsAction {
  type: "EDIT_FORM_FIELDS";
  payload: AnthropometryFormField[];
}

interface EditSampleWeightAction {
  type: "EDIT_SAMPLE_WEIGHT";
  payload: string;
  isMetricLocale?: boolean;
}

interface EditSampleHeightAction {
  type: "EDIT_SAMPLE_HEIGHT";
  payload: string;
  isMetricLocale?: boolean;
}

interface EditSamplePercentBodyFatAction {
  type: "EDIT_SAMPLE_PERCENT_BODY_FAT";
  payload: string;
}

interface EditSampleSexAction {
  type: "EDIT_SAMPLE_SEX";
  payload: SexType;
}

interface EditSampleAgeAction {
  type: "EDIT_SAMPLE_AGE";
  payload: string;
}

type EditSampleDataAction =
  | EditSampleWeightAction
  | EditSampleHeightAction
  | EditSamplePercentBodyFatAction
  | EditSampleSexAction
  | EditSampleAgeAction;

type AnthropometryFormFieldsAction = EditSampleDataAction | EditFormFieldsAction;

interface EditHeightAction {
  type: "EDIT_HEIGHT";
  payload: string;
  isMetricLocale?: boolean;
}

interface EditLeanBodyMassAction {
  type: "EDIT_PERCENT_BODY_FAT";
  payload: string;
}

interface EditWeightAction {
  type: "EDIT_WEIGHT";
  payload: string;
  isMetricLocale?: boolean;
}

type AnthropometryTemplateAction = EditHeightAction | EditLeanBodyMassAction | EditWeightAction | EditSampleSexAction | EditSampleAgeAction;

export type EditSampleFieldTypes = EditSampleDataAction["type"];

export type AnthropometryAction = AnthropometryFormFieldsAction | AnthropometryTemplateAction;

export type AnthropometryState = AnthropometryFormFieldsState | AnthropometryTemplateState;

export const anthropometryReducer = (state: AnthropometryState, action: AnthropometryAction): AnthropometryState => {
  if (state.__typename === "Template") {
    return anthropometryTemplateReducer(state, action);
  } else if (state.__typename === "FormFields") {
    return anthropometryFormFieldsReducer(state, action);
  }
  return state;
};

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { TeamPageTeamFragment } from "../../../types";
import Form from "../EditForm";
import { TeamFormState, teamFormReducer, teamFormToSaveTooltips } from "../EditForm/utils";
import { initialEditTeamFormState } from "./utils";

interface TeamEditModalProps {
  open: boolean;
  onClose: () => void;
  team: Pick<TeamPageTeamFragment, "id" | "name" | "gender" | "sport">;
  allowSportEdit?: boolean;
  loading: boolean;
  handleSave: (state: TeamFormState) => void;
}

const TeamEditModal = ({ open, onClose, team, allowSportEdit = true, loading, handleSave }: TeamEditModalProps) => {
  const [state, dispatch] = useReducer(teamFormReducer, team, initialEditTeamFormState);
  const canSaveTooltips = teamFormToSaveTooltips(state);

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Team" onClose={onClose} />
      <DialogContent sx={{ width: 500, display: "flex", flexDirection: "column", gap: 2 }}>
        {loading ? <Loading progressSize="lg" /> : <Form
          state={state}
          dispatch={dispatch}
          allowSportEdit={allowSportEdit} />}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="" items={canSaveTooltips}>
          <Button onClick={() => handleSave(state)}>Save</Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TeamEditModal;

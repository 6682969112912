import React, { useState } from "react";
import { CheckInKioskAthletePreviewFragment, KioskMealMenuPreviewFragment } from "../../../types";
import MenuDialog from "../MenuDialog";

interface CheckInKioskMenuDialogProps {
  open: boolean;
  onClose: () => void;
  athlete: CheckInKioskAthletePreviewFragment;
  menus: readonly KioskMealMenuPreviewFragment[];
  handleSave: (athleteId: string, selectedMenuIds: string[], unSelectedMealMenuIds: string[]) => void;
}

const CheckInKioskMenuDialog = ({ open, onClose, menus, athlete, handleSave }: CheckInKioskMenuDialogProps) => {
  const [selectedMenuIds, setSelectedMenuIds] = useState<string[]>(athlete.checkInsForMealMenus.map(ci => ci.mealMenuId));
  const handleClickMenu = (mm: KioskMealMenuPreviewFragment) => {
    if (selectedMenuIds.includes(mm.id)) {
      setSelectedMenuIds([...selectedMenuIds.filter(smId => smId !== mm.id)]);
    } else {
      setSelectedMenuIds([...selectedMenuIds, mm.id]);
    }
  };

  const handleOkay = () => {
    const unSelectedMealMenuIds = menus.filter(m => !selectedMenuIds.includes(m.id)).map(mmm => mmm.id);
    handleSave(athlete.id, selectedMenuIds, unSelectedMealMenuIds);
    onClose();
  };

  return (
    <MenuDialog
      open={open}
      onClose={onClose}
      menus={menus}
      onSelectMenu={handleClickMenu}
      onClickOk={handleOkay}
      selectedMenuIds={selectedMenuIds}
    />
  );
};
export default CheckInKioskMenuDialog;

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useCompassMrnRecipeQuery } from "../../../../types";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { Buttons } from "./Buttons";
import { ListDisplay } from "./ListDisplay";
import { InvalidSearch, SearchProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      flexGrow: 1,
      paddingRight: theme.spacing(3),
    },
    searchBar: {
      flexGrow: 1,
    },
    multiQuerySearchBarContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      width: "100%",
    },
  })
);

const INT_32_MAX = 2147483647;

export const MRNSearch = ({ isImporting, setSelectedRecipe, selectedRecipe, setSelectedLabel, selectedLabel }: SearchProps) => {
  const classes = useStyles();
  const [mrnQuery, setMrnQuery] = useState("");
  const [search, setSearch] = useState("");
  const [invalidSearch, setInvalidSearch] = useState<InvalidSearch | null>(null);
  const { setMessage } = useSnackbar();

  const { data: mrnRecipe, loading: mrnLoading } = useCompassMrnRecipeQuery({
    variables: { query: mrnQuery },
    skip: mrnQuery === "",
    onError: e => {
      setMessage("error", "There was an issue with your search. Please try again.");
    },
  });
  const loading = mrnLoading || isImporting;
  const recipes = mrnRecipe ? (mrnRecipe.compass_mrn_recipe ? [mrnRecipe.compass_mrn_recipe] : []) : null;

  const handleSearch = () => {
    if (!/^[\d.]*$/.test(search)) {
      setInvalidSearch(InvalidSearch.NonNumeric);
    } else if (search.includes(".") && (Number(search.split(".")[0]) > INT_32_MAX || Number(search.split(".")[1]) > INT_32_MAX)) {
      // Compass returns 500 error code when left and/or right side of decimal is greater than INT32 max value
      setInvalidSearch(InvalidSearch.TooLarge);
    } else if (Number(search) > INT_32_MAX) {
      setInvalidSearch(InvalidSearch.TooLarge);
    } else {
      setInvalidSearch(null);
      setMrnQuery(search);
    }
  };

  return (
    <>
      <div className={classes.multiQuerySearchBarContainer}>
        <div className={classes.searchBar}>
          <TextField
            className={classes.search}
            label="Reference #"
            placeholder="Search by reference #"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={e => setSearch(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            error={invalidSearch !== null}
            helperText={invalidSearch}
          />
        </div>
        <Buttons
          setSelectedLabel={setSelectedLabel}
          selectedLabel={selectedLabel}
          setSelectedRecipe={setSelectedRecipe} />
      </div>
      <ListDisplay
        recipes={recipes}
        selectedRecipe={selectedRecipe}
        setSelectedRecipe={setSelectedRecipe}
        loading={loading}
        invalidSearch={invalidSearch}
      />
    </>
  );
};

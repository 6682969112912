import React from "react";

import { FullServingFragment } from "../../../../types";
import Item from "./Item";
import ItemWithUnitConversions from "./ItemWithUnitConversions";

interface ServingAmountsServingsMenuRegularContentProps {
  servings: readonly FullServingFragment[];
  onClose: () => void;
  onSelect: (serving: FullServingFragment) => void;
  includeRecipeOnlyIngredients: boolean;
}

const ServingAmountsServingsMenuRegularContent = ({
  servings,
  onClose,
  onSelect,
  includeRecipeOnlyIngredients,
}: ServingAmountsServingsMenuRegularContentProps) => {
  return (
    <>
      {servings.map(serving =>
        serving.unit ? (
          <ItemWithUnitConversions
            key={serving.id}
            serving={serving}
            onClose={onClose}
            onSelect={onSelect}
            hideProductionOnlyUnits={!includeRecipeOnlyIngredients}
            unitId={serving.unit.id}
          />
        ) : (
          <Item
            key={serving.id}
            serving={serving}
            onClose={onClose}
            onSelect={onSelect} />
        )
      )}
    </>
  );
};

export default ServingAmountsServingsMenuRegularContent;

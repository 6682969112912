import { ScheduleModalEditorState, ScheduleModalEditorMealState, ScheduleModalEditorActivityState } from "../reducers/ScheduleModalEditor";
import {
  AddMealInput,
  EditMealInput,
  RemoveMealInput,
  MealFragment,
  AddActivityInput,
  EditActivityInput,
  RemoveActivityInput,
  ActivityFragment,
} from "../types";
import { parseTime, canSaveDate } from "@notemeal/shared/ui/utils/dateTimes";

export const initialScheduleModalEditorState = (
  scheduleName: string,
  canEditScheduleName: boolean,
  meals: readonly MealFragment[],
  activities: readonly ActivityFragment[]
): ScheduleModalEditorState => {
  const mealStates = meals.map(mealToState);
  const activityStates = activities.map(activityToState);
  return {
    scheduleName,
    canEditScheduleName,
    meals: mealStates,
    activities: activityStates,
    initialMeals: mealStates,
    initialActivities: activityStates,
    activityFactor: null,
    scheduleId: null,
  };
};

export const newScheduleModalEditorState = (canEditScheduleName: boolean): ScheduleModalEditorState => {
  return {
    scheduleId: null,
    activityFactor: null,
    scheduleName: "",
    canEditScheduleName,
    meals: [],
    activities: [],
    initialMeals: [],
    initialActivities: [],
  };
};

const mealToState = (meal: MealFragment): ScheduleModalEditorMealState => ({
  id: meal.id,
  name: meal.name,
  type: meal.type,
  diningSources: [],
  start: meal.start,
  startValue: parseTime(meal.start),
  end: meal.end,
  endValue: parseTime(meal.end),
});

const activityToState = (activity: ActivityFragment): ScheduleModalEditorActivityState => ({
  id: activity.id,
  name: activity.name,
  type: activity.type,
  start: activity.start,
  startValue: parseTime(activity.start),
  end: activity.end,
  endValue: parseTime(activity.end),
});

export interface CommonEditScheduleInput {
  addMeals: AddMealInput[];
  editMeals: EditMealInput[];
  removeMeals: RemoveMealInput[];
  addActivities: AddActivityInput[];
  editActivities: EditActivityInput[];
  removeActivities: RemoveActivityInput[];
}

export const scheduleModalEditorStateToEditInput = (schedule: ScheduleModalEditorState): CommonEditScheduleInput => {
  const existingMealIds = schedule.initialMeals.map(m => m.id);
  const existingActivityIds = schedule.initialActivities.map(a => a.id);

  const addMeals = schedule.meals.filter(m => existingMealIds.indexOf(m.id) === -1).map(mealToAddInput);
  const addActivities = schedule.activities.filter(a => existingActivityIds.indexOf(a.id) === -1).map(activityToAddInput);

  const editMeals = schedule.meals
    .filter(meal => {
      const initialMeal = schedule.initialMeals.find(m => meal.id === m.id);
      // Send edit if the meal still exists && has changed
      return initialMeal && meal !== initialMeal;
    })
    .map(mealToEditInput);
  const editActivities = schedule.activities
    .filter(activity => {
      const initialActivity = schedule.initialActivities.find(a => activity.id === a.id);
      // Send edit if the activity still exists && has changed
      return initialActivity && activity !== initialActivity;
    })
    .map(activityToEditInput);

  const removeMeals = existingMealIds.filter(mId => schedule.meals.map(m => m.id).indexOf(mId) === -1).map(mealId => ({ mealId }));
  const removeActivities = existingActivityIds
    .filter(aId => schedule.activities.map(a => a.id).indexOf(aId) === -1)
    .map(activityId => ({ activityId }));

  return {
    addMeals,
    addActivities,
    editMeals,
    editActivities,
    removeMeals,
    removeActivities,
  };
};

interface CommonCreateScheduleInput {
  meals: AddMealInput[];
  activities: AddActivityInput[];
}

export const scheduleModalEditorStateToCreateInput = (schedule: ScheduleModalEditorState): CommonCreateScheduleInput => {
  return {
    meals: schedule.meals.map(mealToAddInput),
    activities: schedule.activities.map(activityToAddInput),
  };
};

const mealToAddInput = (meal: ScheduleModalEditorMealState): AddMealInput => ({
  name: meal.name,
  start: meal.start,
  end: meal.end,
  type: meal.type,
});

const mealToEditInput = (meal: ScheduleModalEditorMealState): EditMealInput => ({
  mealId: meal.id,
  meal: mealToAddInput(meal),
});

const activityToAddInput = (activity: ScheduleModalEditorActivityState): AddActivityInput => ({
  name: activity.name,
  start: activity.start,
  end: activity.end,
  type: activity.type,
});

const activityToEditInput = (activity: ScheduleModalEditorActivityState): EditActivityInput => ({
  activityId: activity.id,
  activity: activityToAddInput(activity),
});

export const canSaveScheduleModalEditorState = (state: ScheduleModalEditorState) => {
  return (
    state.meals.every(m => canSaveDate(m.startValue) && canSaveDate(m.endValue)) &&
    state.activities.every(m => canSaveDate(m.startValue) && canSaveDate(m.endValue))
  );
};

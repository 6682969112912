import { ORGANIZATION_RECIPES, ORG_GROUP_RECIPES, STAFF_RECIPES } from "apps/web/src/pages/Auth/Org/FoodManagement/Utils";
import React from "react";
import TablePage from "../../../components/universal/TablePage";
import { NotemealHeaderRow, OrgGroupHeaderRow, OrgHeaderRow } from "./HeaderRows";
import { NotemealRecipeRows } from "./Rows/NotemealRecipeRows";
import { OrgGroupRecipeRows } from "./Rows/OrgGroupRecipeRows";
import { OrgRecipeRows } from "./Rows/OrgRecipeRows";
import { useRecipesPage } from "./useRecipesPage";

export const RecipesPageTable = () => {
  const { paginationHooks, tab, scoringSystem, pageDepsLoading, totalRows, rowsLoading } = useRecipesPage();

  return (
    <TablePage
      tableHeaderRow={
        <>
          {tab === ORGANIZATION_RECIPES && <OrgHeaderRow hasScoringSystem={!!scoringSystem} />}
          {tab === ORG_GROUP_RECIPES && <OrgGroupHeaderRow hasScoringSystem={!!scoringSystem} />}
          {tab === STAFF_RECIPES && <NotemealHeaderRow />}
        </>
      }
      tableBodyRows={
        <>
          {tab === ORGANIZATION_RECIPES && <OrgRecipeRows />}
          {tab === ORG_GROUP_RECIPES && <OrgGroupRecipeRows />}
          {tab === STAFF_RECIPES && <NotemealRecipeRows />}
        </>
      }
      paginationHooks={paginationHooks}
      loading={rowsLoading || pageDepsLoading}
      total={totalRows}
    />
  );
};

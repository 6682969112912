import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import TablePage from "../../../../components/universal/TablePage";
import {
  RestaurantLocationFragment,
  StaffDashboardRestaurantFragment,
  useAddRestaurantLocationMutation,
  useEditRestaurantLocationMutation,
  useRestaurantLocationsQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import LocationsAddDialog from "./AddDialog";
import LocationsContacts from "./Contacts";
import LocationsEditDialog from "./EditDialog";
import LocationTableRow, { TableHeaderRow } from "./TableRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: "flex",
      justifyContent: "space-between",
    },
    spacer: {
      flexGrow: 1,
    },
    buttons: {
      marginLeft: theme.spacing(3),
    },
  })
);

interface TabPanelProps {
  restaurant: StaffDashboardRestaurantFragment;
}

const TabPanel = ({ restaurant }: TabPanelProps) => {
  const classes = useStyles();
  const restaurantId = restaurant.id;

  const { setMessage } = useSnackbar();
  const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false);

  const paginationHooks = useOffsetPagination();
  const { limit, offset, query, queryText, onChangeQueryText } = paginationHooks;
  const {
    data: data_locations,
    loading: loading_locations,
    refetch: refetch_locations,
  } = useRestaurantLocationsQuery({
    variables: { restaurantId, input: { limit, offset }, query },
    fetchPolicy: "network-only",
  });

  const [addRestaurantLocation, { loading: saving_add }] = useAddRestaurantLocationMutation({
    onCompleted: () => {
      setAddLocationDialogOpen(false);
      refetch_locations();
    },
    onError: e => {
      setMessage("error", e.message);
      setAddLocationDialogOpen(false);
      refetch_locations();
    },
  });

  const [toEditRestaurantLocation, setToEditRestaurantLocation] = useState<RestaurantLocationFragment | null>(null);
  const [editRestaurantLocation, { loading: saving_edit }] = useEditRestaurantLocationMutation({
    onCompleted: () => {
      setToEditRestaurantLocation(null);
    },
    onError: e => {
      setMessage("error", e.message);
      setToEditRestaurantLocation(null);
    },
  });

  const [locationIdForContactsDialog, setLocationIdForContactsDialog] = useState<string | null>(null);

  const contacts = data_locations?.allRestaurantLocations.edges.find(rl => rl.id === locationIdForContactsDialog)?.contacts;

  const header = (
    <>
      <div className={classes.spacer} />
      <TextField
        label="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={queryText}
        onChange={e => onChangeQueryText(e.target.value)}
      />
      <Button onClick={() => setAddLocationDialogOpen(true)} className={classes.buttons}>
        Add Location
      </Button>
    </>
  );

  return (
    <>
      <TablePage
        header={header}
        tableHeaderRow={<TableHeaderRow />}
        tableBodyRows={data_locations?.allRestaurantLocations.edges.map(restaurantLocation => (
          <LocationTableRow
            key={restaurantLocation.id}
            restaurantLocation={restaurantLocation}
            onClick={setToEditRestaurantLocation}
            setLocationIdForContactsDialog={setLocationIdForContactsDialog}
          />
        ))}
        loading={loading_locations}
        paginationHooks={paginationHooks}
        total={data_locations?.allRestaurantLocations.pageInfo.total || 0}
      />
      {addLocationDialogOpen && (
        <LocationsAddDialog
          open={addLocationDialogOpen}
          onClose={() => setAddLocationDialogOpen(false)}
          restaurant={restaurant}
          onAdd={input => addRestaurantLocation({ variables: { input } })}
          saving={saving_add}
        />
      )}
      {toEditRestaurantLocation && (
        <LocationsEditDialog
          location={toEditRestaurantLocation}
          onEdit={input => editRestaurantLocation({ variables: { input } })}
          onClose={() => setToEditRestaurantLocation(null)}
          open={!!toEditRestaurantLocation}
          saving={saving_edit}
        />
      )}
      {contacts && locationIdForContactsDialog !== null && (
        <LocationsContacts
          locationId={locationIdForContactsDialog}
          contacts={contacts}
          open={!!locationIdForContactsDialog}
          onClose={() => setLocationIdForContactsDialog(null)}
          refetch={refetch_locations}
        />
      )}
    </>
  );
};

export default TabPanel;

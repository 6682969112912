import { WatchQueryFetchPolicy } from "@apollo/client";
import { AutocompleteRenderInputParams, AutocompleteRenderOptionState, Typography } from "@mui/material";
import React, { useCallback } from "react";

import {
  DiningStationTemplatePreviewFragment,
  MenuItemAppearanceForMenuBuilderFragment,
  useDiningStationTemplateMenuItemsForMenuBuilderLazyQuery,
  useDiningStationTemplateSearchQuery,
} from "apps/web/src/types";

import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "apps/web/src/components/universal/InfiniteScroll/InfiniteScrollAutocomplete";

type Option = DiningStationTemplatePreviewFragment | string;

interface MenuBuilderDiningStationTemplateSearchProps {
  onSelect: (appearances: readonly MenuItemAppearanceForMenuBuilderFragment[]) => void;
  onChange: (value: string) => void;
  onClose: () => void;
  renderInput: (params: AutocompleteRenderInputParams) => JSX.Element;
  value: string;
  // free text the user entered instead of selecting a dining station template. used for autocomplete
  freeTextValues: string[];
}

export const MenuBuilderDiningStationTemplateSearch = ({
  onSelect,
  onChange,
  onClose,
  renderInput,
  value,
  freeTextValues,
}: MenuBuilderDiningStationTemplateSearchProps) => {
  const LIMIT = 5;
  const [getMenuItems, { data: dstData, loading: dstLoading }] = useDiningStationTemplateMenuItemsForMenuBuilderLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: () => {
      dstData && onSelect(dstData.diningStationTemplate.menuItemAppearances);
    },
  });

  const renderOption = (option: Option) => {
    return <Typography>{typeof option === "string" ? option : option.name}</Typography>;
  };

  const handleSelect = (_: string, option: Option | string | null) => {
    if (typeof option === "string" || option === null) {
      return;
    }
    getMenuItems({
      variables: { diningStationId: option.id },
    });
  };

  const getOptionLabel = (option: Option | string, inputValue: string) => {
    return typeof option === "string" ? option : option.__typename === "DiningStationTemplate" ? option.name : inputValue;
  };

  const transformAndFilterOptions = (edges: DiningStationTemplatePreviewFragment[] | undefined): Option[] => {
    return edges ? [...freeTextValues.slice(0, LIMIT), ...edges] : freeTextValues;
  };

  const edgesAreEqual = useCallback((edge1: DiningStationTemplatePreviewFragment, edge2: DiningStationTemplatePreviewFragment) => {
    return edge1.id === edge2.id;
  }, []);

  const fetchPolicy: WatchQueryFetchPolicy = "cache-first";
  const getQueryVariablesFromPaginationAndInput = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: { query: input, pagination: { cursor, limit } },
      fetchPolicy,
    }),
    []
  );

  const getOptionKey = (option: Option | string, state: AutocompleteRenderOptionState): string => {
    return typeof option === "string" ? option : option.__typename === "DiningStationTemplate" ? option.id : `${state.index}`;
  };

  const groupBy = (option: Option) => {
    return typeof option === "string" ? "From this Menu" : "Dining Station Templates";
  };

  return (
    <InfiniteScrollAutocomplete
      queryKey="diningStationTemplateCursorConnection"
      limit={LIMIT}
      useCursorConnectionQuery={useDiningStationTemplateSearchQuery}
      getQueryVariablesFromPaginationAndInput={getQueryVariablesFromPaginationAndInput}
      transformAndFilterOptions={transformAndFilterOptions}
      groupBy={groupBy}
      renderOption={renderOption}
      handleChange={handleSelect}
      getOptionLabel={getOptionLabel}
      getCustomOptionKey={getOptionKey}
      multiple={false}
      freeSolo
      inputPlaceholder="e.g. Fueling Station"
      edgesAreEqual={edgesAreEqual}
      value={value}
      handleInputChange={onChange}
      loading={dstLoading}
      renderInput={renderInput}
      clearOnClose={false}
      onClose={onClose}
      // always open popover down regardless of available space
      componentsProps={{
        popper: {
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        },
      }}
    />
  );
};

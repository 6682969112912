import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import React, { useState } from "react";
import CuisineDialog from "../../../../components/universal/RenameDialog";
import CuisineTable from "../../../../components/universal/TablePage/NameOnlyTablePage";
import {
  CuisineFragment,
  useCreateCuisineMutation,
  useDeleteCuisineMutation,
  useEditCuisineMutation,
  useStaffDashboardCuisinesQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";

const CUISINE = "Cuisine";

export const CuisinesPage = () => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading, refetch } = useStaffDashboardCuisinesQuery({
    variables: {
      input: { limit, offset },
      query,
    },
    fetchPolicy: "network-only",
  });

  const [editCuisine] = useEditCuisineMutation({
    onCompleted: () => refetch(),
  });
  const [createCuisine] = useCreateCuisineMutation({
    onCompleted: () => refetch(),
  });
  const [deleteCuisine] = useDeleteCuisineMutation({
    onCompleted: () => refetch(),
  });
  const handleEditCuisine = async (name: string) => {
    if (editingCuisine) {
      editCuisine({
        variables: { input: { name, id: editingCuisine.id } },
      });
    }
  };
  const handleCreateCuisine = async (name: string) => {
    createCuisine({ variables: { input: { name } } });
  };
  const handleDeleteCuisine = async () => {
    if (deleteCuisineId) {
      deleteCuisine({ variables: { input: { id: deleteCuisineId } } });
      setDeleteCuisineId(null);
    }
  };
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [editingCuisine, setEditingCuisine] = useState<CuisineFragment | null>(null);
  const [deleteCuisineId, setDeleteCuisineId] = useState<string | null>(null);

  return (
    <>
      <CuisineTable
        onEdit={setEditingCuisine}
        onDelete={setDeleteCuisineId}
        data={data?.cuisineOffsetConnection}
        loading={loading}
        paginationHooks={paginationHooks}
        onCreate={() => setShowCreate(true)}
        title={CUISINE}
      />
      {showCreate && <CuisineDialog
        open={showCreate}
        onClose={() => setShowCreate(false)}
        onSave={handleCreateCuisine}
        type={CUISINE} />}
      {!!editingCuisine && (
        <CuisineDialog
          open={editingCuisine !== null}
          onClose={() => setEditingCuisine(null)}
          onSave={handleEditCuisine}
          entity={editingCuisine}
          type={CUISINE}
        />
      )}
      {!!deleteCuisineId && (
        <ConfirmationDialog
          open={deleteCuisineId !== null}
          title="Delete Cuisine"
          message="Are you sure that you would like to delete the cuisine?"
          onCancel={() => setDeleteCuisineId(null)}
          onConfirm={handleDeleteCuisine}
          variant="containedDestructive"
        />
      )}
    </>
  );
};

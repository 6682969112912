import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { NamedTagPreviewFragment } from "../../../types";

interface DeleteDialogProps {
  namedTagPreview: NamedTagPreviewFragment;
  open: boolean;
  onCancel: () => void;
  handleDelete: (tagId: string) => void;
}

const DeleteDialog = ({ namedTagPreview, open, onCancel, handleDelete }: DeleteDialogProps) => (
  <Dialog
    open={open}
    onClose={onCancel}
    maxWidth="xs"
    fullWidth>
    <DialogTitle title="Delete Tag?" onClose={onCancel} />
    <DialogContent>
      <Typography>
        Are you sure you want to delete the tag "{namedTagPreview.tagName.name}"? If so, this tag will be removed from{" "}
        {namedTagPreview.tag.athleteCount} athlete profiles.{" "}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onCancel()} variant="outlined">
        Cancel
      </Button>
      <Button variant="containedDestructive" onClick={() => handleDelete(namedTagPreview.tag.id)}>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;

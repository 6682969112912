import { Box } from "@mui/material";
import { sortByKey } from "@notemeal/utils/sort";
import React, { memo, useState } from "react";
import { idArraysAreEqual } from "../../views/Menus/DiningStation/utils";
import MenuItemDialogContent from "../MenuItem/Dialog/Content";
import MenuItemSearchBar from "../MenuItem/SearchBar/MenuItem";
import RestaurantMenuItemSearchBar from "../MenuItem/SearchBar/RestaurantMenuItem";
import { MenuItemState } from "../MenuItem/reducer";
import { newMenuItemState } from "../MenuItem/utils";
import { MenuItemAppearancePreview } from "./MenuItemAppearancePreview/MenuItemAppearancePreview";
import { MenuItemAppearanceGroupAction, MenuItemAppearanceGroupState } from "./reducer";
import { MenuOrderItemCounts, getMenuItemAppearanceOrderCount } from "./utils";

interface MenuItemEditCardsProps {
  variant: "RestaurantMenu" | "DiningStationTemplate" | "MealMenu";
  usedMenuItemIds: readonly string[];
  state: MenuItemAppearanceGroupState;
  dispatch: (action: MenuItemAppearanceGroupAction) => void;
  orderItemCounts?: MenuOrderItemCounts;
}

const MenuItemEditCards = ({ state, variant, usedMenuItemIds, dispatch, orderItemCounts }: MenuItemEditCardsProps) => {
  const [createDialogState, setCreateDialogState] = useState<MenuItemState | null>(null);
  const handleCreate = (name: string, isOneOff: boolean) => setCreateDialogState(newMenuItemState(name, isOneOff));

  return (
    <>
      <Box>
        {variant === "RestaurantMenu" ? (
          <RestaurantMenuItemSearchBar
            onCreate={handleCreate}
            onSelectRestaurantFood={menuItem => {
              dispatch({
                type: "CreateMenuItemAction",
                payload: {
                  menuItem,
                  maxAmount: null,
                  availableForOrder: true,
                  allowSpecialRequests: false,
                },
              });
              setCreateDialogState(null);
            }}
            usedMenuItemNames={state.map(mia => mia.menuItem.name)}
          />
        ) : (
          <MenuItemSearchBar
            sx={{ mt: 0 }}
            usedMenuItemIds={usedMenuItemIds}
            variant={variant}
            onAdd={menuItem =>
              dispatch({
                type: "AddMenuItemAction",
                payload: { menuItem },
              })
            }
            onCreate={handleCreate}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 2, rowGap: 2 }}>
        {sortByKey(state, "position").map(mia => (
          <MenuItemAppearancePreview
            key={mia.menuItem.id}
            usedMenuItemIds={usedMenuItemIds}
            sx={{ width: "calc(50% - 16px)" }}
            menuItemAppearance={mia}
            onCreate={(menuItem, maxAmount, availableForOrder, isOneOff, allowSpecialRequests) =>
              setCreateDialogState({
                menuItem: {
                  ...menuItem,
                  name: `Copy of ${menuItem.name}`,
                  isOneOff,
                },
                maxAmount,
                availableForOrder,
                allowSpecialRequests,
                edited: false,
              })
            }
            onEdit={(menuItem, maxAmount, availableForOrder, allowSpecialRequests, initialMenuItem) =>
              dispatch({
                type: "EditMenuItemAction",
                payload: {
                  menuItem,
                  maxAmount,
                  availableForOrder,
                  allowSpecialRequests,
                  initialMenuItem,
                },
              })
            }
            onConvert={(menuItem, maxAmount, availableForOrder, allowSpecialRequests, isOneOff, initialMenuItem) =>
              dispatch({
                type: "ConvertMenuItemAction",
                payload: {
                  menuItem,
                  maxAmount,
                  availableForOrder,
                  allowSpecialRequests,
                  initialMenuItem,
                  isOneOff,
                },
              })
            }
            onEditAppearance={(maxAmount, availableForOrder, allowSpecialRequests) =>
              dispatch({
                type: "EditMenuItemAppearanceAction",
                payload: {
                  menuItemId: mia.menuItem.id,
                  maxAmount,
                  availableForOrder,
                  allowSpecialRequests,
                },
              })
            }
            onRemove={() =>
              dispatch({
                type: "RemoveMenuItemAction",
                payload: {
                  menuItemId: mia.menuItem.id,
                },
              })
            }
            onRestore={() =>
              dispatch({
                type: "RestoreMenuItemAction",
                payload: {
                  menuItemId: mia.menuItem.id,
                },
              })
            }
            orderCount={orderItemCounts ? getMenuItemAppearanceOrderCount(mia, orderItemCounts) : null}
          />
        ))}
      </Box>
      {createDialogState && (
        <MenuItemDialogContent
          open={createDialogState !== null}
          onClose={() => setCreateDialogState(null)}
          title={createDialogState.menuItem.isOneOff ? "Add One-Off Menu Item" : "Create Reusable Menu Item"}
          initState={createDialogState}
          variant="MenuItemAppearance"
          onSave={({ menuItem, maxAmount, availableForOrder, allowSpecialRequests }) => {
            dispatch({
              type: "CreateMenuItemAction",
              payload: {
                menuItem,
                maxAmount,
                availableForOrder,
                allowSpecialRequests,
              },
            });
            setCreateDialogState(null);
          }}
        />
      )}
    </>
  );
};

const propsAreEqual = (props: MenuItemEditCardsProps, prevProps: MenuItemEditCardsProps): boolean => {
  if (!idArraysAreEqual(props.usedMenuItemIds, prevProps.usedMenuItemIds)) {
    return false;
  } else {
    return (
      props.dispatch === prevProps.dispatch &&
      props.orderItemCounts === prevProps.orderItemCounts &&
      props.variant === prevProps.variant &&
      props.state === prevProps.state
    );
  }
};

export default memo(MenuItemEditCards, propsAreEqual);

import React from "react";
import { useContext, createContext } from "react";

import { NutritionixBrandedFood } from "../../../types";

type IServingAmountModeContext =
  | {
      type: "default";
    }
  | {
      type: "restaurant";
      restaurantNutritionixFoods: readonly NutritionixBrandedFood[];
      restaurantId: string;
    };

export const ServingAmountModeContext = createContext<IServingAmountModeContext>({
  type: "default",
});

// Uses "default" by not having a provider for this context
// If multiple non-default types are used in the future, will need to change this interface
interface ServingAmountModeContextProviderProps {
  children?: React.ReactNode;
  restaurantNutritionixFoods: readonly NutritionixBrandedFood[];
  restaurantId: string;
}

const ServingAmountModeContextProvider = ({
  children,
  restaurantNutritionixFoods,
  restaurantId,
}: ServingAmountModeContextProviderProps) => {
  return (
    <ServingAmountModeContext.Provider
      value={{
        type: "restaurant",
        restaurantNutritionixFoods,
        restaurantId,
      }}
    >
      {children}
    </ServingAmountModeContext.Provider>
  );
};

export const useServingAmountModeContext = () => useContext(ServingAmountModeContext);

export default ServingAmountModeContextProvider;

import { Box, Card, CardContent, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { EXCHANGE_AMOUNT_CARD_WIDTH } from "@notemeal/shared/ui/ExchangeAmount/utils";
import { getExchangePickList } from "@notemeal/shared/ui/ExchangeServingList/utils";
import { formatAmbiguousServingAmountWithTotalWeight } from "@notemeal/shared/ui/ServingAmount/utils";
import { useAthleteFoodPreferenceContext } from "@notemeal/shared/ui/contexts/AthleteFoodPreferences";
import { useMealPlanFoodPreferenceContext } from "@notemeal/shared/ui/contexts/MealPlanFoodPreferences";
import React, { useEffect } from "react";
import {
  ChangeExchangeTargetMutationVariables,
  EditExchangeAmountFragment,
  FullExchangeServingListFragment,
  useChangeExchangeTargetMutation,
} from "../../../../../types";
import { deselectNegativeFoods } from "../../../../ExchangeServingList/utils";

const useStyles = makeStyles(({ spacing, palette: { greyscale } }: Theme) =>
  createStyles({
    root: {
      margin: spacing(),
      background: "white",
      width: EXCHANGE_AMOUNT_CARD_WIDTH,
      flexGrow: 1,
      flexShrink: 0,
      "&:hover": {
        cursor: "pointer",
        background: greyscale[200],
      },
    },
    content: {
      boxSizing: "border-box",
      height: "100%",
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto minmax(0, 1fr)",
    },
    pickList: {
      overflowY: "auto",
      overflowX: "hidden",
      paddingLeft: spacing(2),
      marginBottom: 0,
      gridRowStart: 2,
      gridColumnStart: 1,
    },
  })
);

interface ExchangeAmountCardProps {
  mealTemplateId: string;
  exchangeAmount: EditExchangeAmountFragment;
  servingList: FullExchangeServingListFragment;
  onSelect: (exchangeId: string) => void;
}

export const ExchangeAmountCard = ({ mealTemplateId, exchangeAmount, servingList, onSelect }: ExchangeAmountCardProps) => {
  const {
    palette: { mediumEmphasisText },
  } = useTheme();
  const classes = useStyles();

  const { exchange, amountInput, amount, pickListServingIds } = exchangeAmount;
  const pickList = getExchangePickList(servingList, pickListServingIds);
  const [changeExchangeTarget] = useChangeExchangeTargetMutation();
  const commonVariables: ChangeExchangeTargetMutationVariables = {
    exchangeId: exchange.id,
    target: amountInput,
    pickListServingIds,
    mealTemplateId,
  };
  const servingListServingIds = servingList.servingAmounts.map(sa => sa.serving.id);
  const { isDislikedFood } = useAthleteFoodPreferenceContext();
  const { isAvoidedFood } = useMealPlanFoodPreferenceContext();
  const isNegativeFood = (foodId: string) => {
    return isDislikedFood(foodId).value || isAvoidedFood(foodId).value;
  };
  useEffect(() => {
    // pickListServingIds is null => Default is all servings selected
    if (pickListServingIds === null) {
      const mealTemplates = [{ id: mealTemplateId, exchangeTargets: [{ exchange, amountInput }] }];
      const exchangeSet = {
        exchanges: [{ id: exchange.id, exchangeServingList: servingList }],
      };
      deselectNegativeFoods({
        isNegativeFood,
        mealTemplates,
        exchangeSet,
        changeExchangeTarget,
      });
      changeExchangeTarget({
        variables: {
          ...commonVariables,
          pickListServingIds: servingList.servingAmounts.filter(sa => !isNegativeFood(sa.serving.foodOrRecipe.id)).map(sa => sa.serving.id),
        },
      });
    } else if (pickListServingIds && pickListServingIds.some(sId => !servingListServingIds.includes(sId))) {
      changeExchangeTarget({
        variables: {
          ...commonVariables,
          pickListServingIds: pickListServingIds.filter(sId => servingListServingIds.includes(sId)),
        },
      });
    }
  }, [pickListServingIds]);

  return (
    <Card className={classes.root} onClick={() => onSelect(exchange.id)}>
      <CardContent className={classes.content}>
        <Typography variant="body2Medium" sx={{ display: "flex", gridRowStart: 1, gridColumnStart: 1 }}>
          {exchange.shortName}: {amount}
        </Typography>
        <ul className={classes.pickList}>
          {pickList.map(sa => (
            <li key={sa.id}>
              <Box
                sx={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "clip",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="subtitle1" noWrap>
                  {sa.serving.foodOrRecipe.name}
                </Typography>
                <Typography sx={{ color: mediumEmphasisText }} variant="subtitle1">
                  {formatAmbiguousServingAmountWithTotalWeight(sa)}
                </Typography>
              </Box>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

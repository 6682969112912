import { ExchangeFragment, EditExchangeAmountFragment } from "../../../../../types";
import newId from "@notemeal/shared/ui/utils/newId";

export interface ExchangeTargetsById {
  [exchangeId: string]: EditExchangeAmountFragment;
}

export const makeExchangeTargetsById = (
  exchanges: readonly ExchangeFragment[],
  exchangeTargets: readonly EditExchangeAmountFragment[] | null
): ExchangeTargetsById => {
  let exchangeTargetsById: ExchangeTargetsById = {};
  exchanges.forEach(e => {
    const matchingExchangeTarget = exchangeTargets && exchangeTargets.find(et => et.exchange.id === e.id);
    exchangeTargetsById[e.id] = matchingExchangeTarget || {
      id: newId(),
      __typename: "ExchangeAmount",
      amount: 0,
      exchange: e,
      amountInput: "0",
      pickListServingIds: null,
    };
  });
  return exchangeTargetsById;
};

import {
  AnthropometrySnapshot,
  ERROR_AGE_MINIMUM,
  ERROR_HEIGHT_REQUIRED,
  ERROR_CUNNINGHAM_REQUIRED,
  RMR_MIN_AGE,
  rmrErrorLookup,
  validateRmr,
  translateErrors,
} from "@notemeal/shared/utils/macro-protocol";
import { RmrMethod } from "../types";

// ALL FUNCTIONS IN THIS FILE SHOULD RECEIVE ANTHROPOMETRY SNAPSHOT W/ WEIGHT TARGET INCLUDED ALREADY
// (see utils/macroProtocol.ts -> anthropometrySnapshotWithWeightTarget)

const MP_AGE_MINIMUM = `Age must be at least ${RMR_MIN_AGE}. Enter valid birthdate on athlete summary page.`;

const localMealPlanErrorLookup = new Map<string, string>([[ERROR_AGE_MINIMUM, MP_AGE_MINIMUM]]);

export const mealPlanErrorLookup = new Map([...rmrErrorLookup, ...localMealPlanErrorLookup]);

export const getMealPlanRmrErrors = (rmrMethod: RmrMethod, anthropometrySnapshot: AnthropometrySnapshot): string[] =>
  translateErrors(validateRmr({ rmrMethod, ...anthropometrySnapshot }), mealPlanErrorLookup);

const MPT_PERCENT_BODY_FAT = "Percent Body Fat is required for the selected RMR Method";
const MPT_HEIGHT_REQUIRED = "Height is required for the selected RMR Method";

const localMealPlanTemplateErrorLookup = new Map<string, string>([
  [ERROR_CUNNINGHAM_REQUIRED, MPT_PERCENT_BODY_FAT],
  [ERROR_HEIGHT_REQUIRED, MPT_HEIGHT_REQUIRED],
]);

export const mealPlanTemplateErrorLookup = new Map([...rmrErrorLookup, ...localMealPlanTemplateErrorLookup]);

export const getMealPlanTemplateRmrErrors = (rmrMethod: RmrMethod, anthropometrySnapshot: AnthropometrySnapshot): string[] =>
  translateErrors(validateRmr({ rmrMethod, ...anthropometrySnapshot }), mealPlanTemplateErrorLookup);

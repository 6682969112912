import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { sortFnByKey } from "@notemeal/utils/sort";
import React, { useState } from "react";
import { SimpleFoodsDocument, SimpleFoodsQuery, SimpleFoodsQueryVariables } from "../../types";
import { useFoodSearchFiltersForUser } from "../../utils/foodSearchFilters/foodSearchFiltersForUser";
import { getMuiTablePaginationProps, getRowsForTablePage, useOffsetPagination } from "../../utils/pagination";
import { AutocompleteQuerySearchBar } from "../universal/AutocompleteQuerySearchBar/AutocompleteQuerySearchBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      height: 360,
    },
    actionCell: {
      width: 100,
    },
  })
);

export interface IFoodGroupFood {
  name: string;
  id: string;
}

interface FoodGroupFoodTableProps {
  foods: IFoodGroupFood[];
  addFood: (food: IFoodGroupFood) => void;
  removeFood: (food: IFoodGroupFood) => void;
  loading: boolean;
}

interface EditableFoodGroupFoodTableProps extends FoodGroupFoodTableProps {
  setErrors: (errors: string[]) => void;
}

interface ReadOnlyFoodGroupFoodTableProps extends FoodGroupFoodTableProps {
  readOnly: boolean;
}

const isReadOnly = (props: EditableFoodGroupFoodTableProps | ReadOnlyFoodGroupFoodTableProps): props is ReadOnlyFoodGroupFoodTableProps => {
  return !!(props as ReadOnlyFoodGroupFoodTableProps).readOnly;
};

const FoodGroupFoodTable = (props: ReadOnlyFoodGroupFoodTableProps | EditableFoodGroupFoodTableProps) => {
  const classes = useStyles();
  const { loading, foods } = props;
  const { addFood, removeFood } = props;
  const ROWS_PER_PAGE = 7;
  const pagination = useOffsetPagination(ROWS_PER_PAGE);
  const { limit, page, queryText, onChangeQueryText } = pagination;
  const [sortOrderAscending, setSortOrderAscending] = useState(true);
  const [adding, setAdding] = useState(false);

  const getRows = () => foods.sort(sortFnByKey("name", { reverse: !sortOrderAscending })).filter(filterRow) || [];
  const filterRow = (row: IFoodGroupFood) => row.name.toLowerCase().indexOf(queryText.toLowerCase()) !== -1;
  const getSortOrder = () => (sortOrderAscending ? "asc" : "desc");
  const toggleSortOrder = () => setSortOrderAscending(!sortOrderAscending);

  const rows = getRows();
  const pageRows = getRowsForTablePage({ rows, limit, page });

  const { localeFilter, dataSourceFilter } = useFoodSearchFiltersForUser();

  if (loading) {
    return <Loading progressSize="lg" />;
  }

  return (
    <Card sx={{ p: 1, display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <Typography sx={{ alignSelf: "flex-start" }} variant="h3">
          Foods
        </Typography>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
          <TextField
            sx={{ mt: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            value={queryText}
            onChange={e => onChangeQueryText(e.target.value)}
          />
          <Button disabled={isReadOnly(props)} onClick={() => setAdding(true)}>
            Add
          </Button>
        </Box>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Actions</b>
              </TableCell>
              <TableCell>
                <TableSortLabel direction={getSortOrder()} onClick={toggleSortOrder}>
                  <b>Name</b>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adding && (
              <TableRow>
                <TableCell className={classes.actionCell}>
                  <Tooltip title="Cancel">
                    <IconButton size="small" onClick={() => setAdding(false)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <AutocompleteQuerySearchBar<IFoodGroupFood, SimpleFoodsQuery, SimpleFoodsQueryVariables>
                    sx={{ marginTop: 0 }}
                    query={SimpleFoodsDocument}
                    placeholder="Search for food to add"
                    fullWidth
                    getOptionLabel={o => o.name}
                    getOptionsFromQueryData={data => [...data.foods]}
                    getQueryOptions={searchTerm => ({
                      variables: {
                        searchTerm,
                        limit: 10,
                        localeCodes: localeFilter,
                        dataSources: dataSourceFilter,
                      },
                    })}
                    onChange={o => (o ? addFood(o) : null)}
                    getUserFriendlyQueryErrorMessage={() => "Error searching foods, please try again."}
                  />
                </TableCell>
              </TableRow>
            )}
            {pageRows.map(food => {
              const { id, name } = food;
              return (
                <TableRow key={id} id={id}>
                  <TableCell className={classes.actionCell}>
                    <Tooltip title="Delete">
                      <IconButton
                        disabled={isReadOnly(props)}
                        size="small"
                        onClick={() => removeFood(food)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
        {...getMuiTablePaginationProps(pagination, rows.length)}
      />
    </Card>
  );
};

export default React.memo(FoodGroupFoodTable);

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { addSelectionOptionsToMenuItem } from "@notemeal/shared/ui/MenuItem/utils";
import { MenuSelectionItemWithAppearance } from "@notemeal/shared/ui/MenuSelection/utils";
import Loading from "@notemeal/shared/ui/global/Loading";
import React, { ReactNode } from "react";
import { MenuItemPreviewFragment, useMenuItemForSelectionFormQuery } from "../../types";
import NewForm from "./NewForm";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface MenuSelectionItemNewProps {
  menuSelectionItemId: string;
  menuItem: MenuItemPreviewFragment;
  maxAmount: number | null; // Result of getMenuItemMaxAmount function, will change based on amount in diningStation
  availableForOrder: boolean;
  allowSpecialRequests: boolean;
  onDone: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  onChange: (menuSelectionItem: MenuSelectionItemWithAppearance) => void;
  selectionType: string; // i.e. Order, Log, Bulk Order/Plate
  children?: ReactNode;
}

export const KioskMenuSelectionItemNew = ({
  menuSelectionItemId,
  menuItem,
  maxAmount,
  availableForOrder,
  allowSpecialRequests,
  selectionType,
  onDone,
  onChange,
  children,
}: MenuSelectionItemNewProps) => {
  const classes = useStyles();
  const { data } = useMenuItemForSelectionFormQuery({
    variables: {
      menuItemId: menuItem.id,
    },
  });

  return (
    <div className={classes.root}>
      {!data ? (
        <Loading />
      ) : (
        <NewForm
          menuSelectionItemId={menuSelectionItemId}
          maxAmount={maxAmount}
          availableForOrder={availableForOrder}
          allowSpecialRequests={allowSpecialRequests}
          selectionType={selectionType}
          onDone={onDone}
          onChange={onChange}
          menuItem={addSelectionOptionsToMenuItem(data.menuItem, [])}
          children={children}
        />
      )}
    </div>
  );
};

import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { invalidEmail } from "../../utils/invalidEmail";

interface UpdateEmailFormProps {
  handleSave: (email: string) => void;
  saving: boolean;
}

const UpdateEmailForm = ({ handleSave, saving }: UpdateEmailFormProps) => {
  const [input, setInput] = useState("");
  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h3">Missing athlete email</Typography>
      {saving && <CircularProgress size={35} />}
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
        <TextField
          label={"Email"}
          onChange={e => setInput(e.target.value)}
          value={input}
          error={Boolean(input) && invalidEmail(input)}
          disabled={saving}
        />
        <Button onClick={e => handleSave(input)} disabled={saving}>
          Add Email
        </Button>
      </Box>
    </Box>
  );
};

export default UpdateEmailForm;

import { z } from "zod";

const UnitSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
});

const ServingUnits_CustomSchema = z.object({
  customUnits: z.string().min(1),
  unit: z.null(),
  unitPrefix: z.null(),
  unitSuffix: z.null(),
});

const ServingUnits_NonCustomSchema = z.object({
  customUnits: z.null(),
  unit: UnitSchema,
  unitPrefix: z.string().nullable(),
  unitSuffix: z.string().nullable(),
});

export const ServingUnitsSchema = ServingUnits_CustomSchema.or(ServingUnits_NonCustomSchema);
export type ServingUnitsType = z.infer<typeof ServingUnitsSchema>;

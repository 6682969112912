import { Button, Dialog, DialogActions, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";
import MenuSelectorCard from "../../components/MenuOrder/MenuSelectorCard";
import { KioskMealMenuPreviewFragment, MenuOrderInfoFragment } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2, 2, 0, 2),
      "& *": {
        fontWeight: theme.typography.h4.fontWeight,
      },
    },
    card: {
      margin: theme.spacing(2),
    },
  })
);

interface OrderMenuDialogProps {
  open: boolean;
  onClose: () => void;
  menus: readonly KioskMealMenuPreviewFragment[];
  onSelectMenu: (mealMenu: KioskMealMenuPreviewFragment) => void;
  selectedMenuIds?: string[];
  onClickOk?: () => void;
  kioskPreviewOrderInfo?: readonly MenuOrderInfoFragment[];
}

const MenuDialog = ({ open, onClose, menus, onSelectMenu, selectedMenuIds, onClickOk, kioskPreviewOrderInfo }: OrderMenuDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="body">
      <DialogTitle title="Select Menu" onClose={onClose} />
      {menus.map(mm => (
        <MenuSelectorCard
          key={mm.id}
          mealMenu={mm}
          onClick={() => onSelectMenu(mm)}
          className={classes.card}
          selected={selectedMenuIds ? selectedMenuIds.includes(mm.id) : false}
          kioskPreviewOrderInfoForMenu={kioskPreviewOrderInfo?.find(info => info.mealMenuId === mm.id)}
        />
      ))}
      {onClickOk && (
        <DialogActions>
          <Button onClick={onClickOk}>Ok</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default MenuDialog;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useCallback, useRef, useState } from "react";
import TWItemizedTooltip from "../../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { PossibleLocation } from "../../../../../components/Map/types";
import LoadingBackdrop from "../../../../../components/universal/LoadingBackdrop";
import { AddRestaurantLocationInput, StaffDashboardRestaurantFragment, useGoogleMapsApiKeyQuery } from "../../../../../types";
import Form from "../Form";
import { RestaurantLocationFormState, getAddRestaurantLocationInput, getCreateFormState, locationFormTooltips } from "../Form/utils";
import Content from "./Content";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100vh - 64px)",
    },
    searchBar: {
      margin: theme.spacing(),
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    button: {
      margin: theme.spacing(),
    },
  })
);

interface RestaurantLocationsAddDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (input: AddRestaurantLocationInput) => void;
  saving: boolean;
  restaurant: StaffDashboardRestaurantFragment;
}

export interface LatLng {
  lat: number;
  lng: number;
}

const RADIUS_IN_METERS = 5000;

const RestaurantLocationsAddDialog = ({ open, onClose, onAdd, saving, restaurant }: RestaurantLocationsAddDialogProps) => {
  const classes = useStyles();

  const { data } = useGoogleMapsApiKeyQuery({});
  const [toAddLocation, setToAddLocation] = useState<{
    formState: RestaurantLocationFormState;
    location: PossibleLocation;
  } | null>(null);

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [defaultFiltersEnabled, setDefaultFiltersEnabled] = useState(true);
  const [latLng, setLatLng] = useState<LatLng | null>(null);
  const [possibleLocations, setPossibleLocations] = useState<PossibleLocation[]>([]);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);

  const findPossiblePlacesFromLatLong = useCallback(
    (_latLng: LatLng | null, _defaultFiltersEnabled: boolean) => {
      if (_latLng) {
        setLatLng(_latLng);
      }
      const location = _latLng ?? latLng;

      const placesService = placesServiceRef.current;
      if (!placesService) {
        return;
      }

      const query = _defaultFiltersEnabled ? restaurant.name : searchQuery;
      if (!query) {
        return;
      }

      const defaultFilterArgs = {
        type: "food",
      };
      const args = {
        query,
        radius: location ? RADIUS_IN_METERS : undefined,
        location: location ?? undefined,
        ...(_defaultFiltersEnabled ? defaultFilterArgs : {}),
      };
      placesService.textSearch(args, (places, status) => {
        if (places) {
          setPossibleLocations(
            places.flatMap(({ geometry, name, website, place_id, formatted_address }) => {
              const lat = geometry?.location?.lat();
              const long = geometry?.location?.lng();
              if (!lat || !long || !place_id || !name || !formatted_address) {
                return [];
              }
              return {
                lat,
                long,
                googlePlaceId: place_id,
                name,
                website,
                formatted_address,
              };
            })
          );
        } else {
          setPossibleLocations([]);
        }
      });
    },
    [searchQuery, latLng, restaurant.name]
  );

  const tooltips = toAddLocation ? locationFormTooltips(toAddLocation.formState) : [];

  if (saving || !data) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const onSave = () => {
    if (!toAddLocation) {
      return;
    }
    if (tooltips.length > 0) {
      return;
    }
    onAdd(getAddRestaurantLocationInput(toAddLocation.formState, toAddLocation.location, restaurant.id));
  };

  const filterButtonText = defaultFiltersEnabled ? "Disable default filters" : "Enable default Filters";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.headerContainer}>
        <DialogTitle>Add Restaurant Location</DialogTitle>
        {!defaultFiltersEnabled && (
          <div className={classes.headerContainer}>
            <TextField
              value={searchQuery}
              onChange={e => {
                setSearchQuery(e.target.value);
              }}
              type="text"
              placeholder={`i.e. ${restaurant.name}`}
              className={classes.searchBar}
              label="Location Name"
            />
            <Button
              onClick={() => {
                findPossiblePlacesFromLatLong(latLng, defaultFiltersEnabled);
              }}
              className={classes.button}
            >
              Search
            </Button>
          </div>
        )}
        <Button
          onClick={() => {
            const newDefaultFiltersEnabled = !defaultFiltersEnabled;
            findPossiblePlacesFromLatLong(latLng, newDefaultFiltersEnabled);
            setDefaultFiltersEnabled(newDefaultFiltersEnabled);
          }}
          className={classes.button}
        >
          {filterButtonText}
        </Button>
      </div>
      <Content
        googleMapsApiKey={data.googleMapsApiKey}
        onAddLocation={location =>
          setToAddLocation({
            location,
            formState: getCreateFormState(),
          })
        }
        placesServiceRef={placesServiceRef}
        findPosiblePlacesFromLatLong={_latLng => findPossiblePlacesFromLatLong(_latLng, defaultFiltersEnabled)}
        possibleLocations={possibleLocations}
        resetLatLng={() => setLatLng(null)}
      />
      {toAddLocation !== null && (
        <Dialog
          open={toAddLocation !== null}
          onClose={() => setToAddLocation(null)}
          maxWidth="sm"
          fullWidth>
          <DialogTitle>Restaurant Location Details</DialogTitle>
          <DialogContent>
            <Form state={toAddLocation.formState} onChange={formState => setToAddLocation({ ...toAddLocation, formState })} />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setToAddLocation(null)}>
              Close
            </Button>
            <TWItemizedTooltip title="Fix the following:" items={tooltips}>
              <Button onClick={onSave}>Save</Button>
            </TWItemizedTooltip>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default RestaurantLocationsAddDialog;

import { Box, InputAdornment, Slider, TextField, Typography } from "@mui/material";
import { EditMacroState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import { AnthropometrySnapshot, macroMetricGPerKgToGramsRounded } from "@notemeal/shared/utils/macro-protocol";
import React, { Dispatch } from "react";
import { GRAM_WIDTH, PERCENT_WIDTH } from "./MacroTargets";
import { MacroProtocolAction } from "./reducer/macroProtocolReducer";
import { ChangeMacroGPerKGAction } from "./reducer/macroReducer";

interface MacroInputProps {
  macro: "cho" | "pro" | "fat";
  state: EditMacroState;
  anthropometrySnapshot: AnthropometrySnapshot;
  dispatch: Dispatch<MacroProtocolAction>;
  onChangeGPerKg: (action: ChangeMacroGPerKGAction) => void;
  gPerKgOnly: boolean;
}

export const MacroInput = ({ macro, state, anthropometrySnapshot, dispatch, onChangeGPerKg, gPerKgOnly }: MacroInputProps) => {
  const grams = Math.round(macroMetricGPerKgToGramsRounded(state, anthropometrySnapshot.weightInKg));
  const color = macro === "cho" ? "cho.main" : macro === "pro" ? "pro.main" : "fat.main";

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Typography sx={{ width: 45, color }}>{macro.toUpperCase()}</Typography>
      <Slider
        sx={{ width: 200, mr: 1 }}
        min={0}
        max={100}
        step={1}
        value={state.percent}
        onChange={(e, value) => {
          value !== null &&
            dispatch({
              type: "CHANGE_MACRO_PERCENT",
              payload: {
                macro: macro,
                value: `${value}`,
              },
            });
        }}
      />
      <TextField
        sx={{
          width: PERCENT_WIDTH,
          mt: 0,
          ".MuiInputBase-root": { backgroundColor: !gPerKgOnly && state.usesPercent ? "info.lighter" : "inherit" },
        }}
        type="number"
        value={gPerKgOnly ? "" : state.percentInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" variant="filled">
              <Typography variant="body2">%</Typography>
            </InputAdornment>
          ),
        }}
        onChange={event => {
          dispatch({
            type: "CHANGE_MACRO_PERCENT",
            payload: {
              macro: macro,
              value: event.target.value,
            },
          });
        }}
        disabled={gPerKgOnly}
      />
      <TextField
        sx={{ width: 115, mt: 0, ".MuiInputBase-root": { backgroundColor: gPerKgOnly || !state.usesPercent ? "info.lighter" : "inherit" } }}
        type="number"
        inputProps={{ step: 0.05 }}
        value={state.gPerKGInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="body2">g/kg</Typography>
            </InputAdornment>
          ),
        }}
        onChange={event =>
          onChangeGPerKg({
            type: "CHANGE_MACRO_G_PER_KG",
            payload: {
              macro: macro,
              value: event.target.value,
            },
          })
        }
      />
      <Typography sx={{ width: GRAM_WIDTH }}>{grams}g</Typography>
    </Box>
  );
};

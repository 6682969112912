import { Box, Button, Checkbox, FormControlLabel, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { getServingAmountMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import { initMacros, scaleMacros, sumMacros } from "@notemeal/shared/utils/macro-protocol";
import { trackEvent } from "apps/web/src/reporting/reporting";
import React, { Dispatch } from "react";
import { ExchangeType } from "../../../types";
import ExchangeTypeMultiSelect from "../../Exchange/TypeMultiSelect";
import ServingUnitsInput from "../../Serving/Units/Input";
import { ServingUnitsState, getServingUnits } from "../../Serving/utils";
import { NewChip } from "../../universal/NewChip";
import { RecipeFormAction, RecipeFormState } from "./utils";

interface RecipeFormServingProps {
  state: RecipeFormState;
  dispatch: Dispatch<RecipeFormAction>;
  disabled?: boolean;
  forStaff?: boolean;
  openScaleRecipe: () => void;
}

const RecipeFormServing = ({ state, dispatch, disabled, openScaleRecipe, forStaff }: RecipeFormServingProps) => {
  const { cho: totalCho, pro: totalPro, fat: totalFat, kcal: totalKcal } = sumMacros(state.ingredients.map(getServingAmountMacros));
  const hasRecipeScaling = useHasFeature("performanceKitchen");
  const { serving, manualMacros, choInput, proInput, fatInput, exchangeTypes, ingredients } = state;
  const { perRecipeYield, defaultAmount, perRecipeYieldInput, units, defaultAmountInput } = serving;

  const handleServingChangeUnits = (servingUnits: ServingUnitsState) => {
    dispatch({ type: "CHANGE_SERVINGS", value: { ...serving, units: servingUnits } });
  };

  const handleServingChangePerRecipeYield = (perRecipeYieldInput: string) => {
    dispatch({
      type: "CHANGE_SERVINGS",
      value: {
        ...serving,
        perRecipeYieldInput,
        perRecipeYield: inputToNumber(perRecipeYieldInput) || perRecipeYield,
      },
    });
  };

  const handleServingChangeDefaultAmount = (defaultAmountInput: string) => {
    dispatch({
      type: "CHANGE_SERVINGS",
      value: {
        ...serving,
        defaultAmountInput,
        defaultAmount: inputToNumber(defaultAmountInput) || defaultAmount,
      },
    });
  };

  const handleToggleManualMacros = () => {
    dispatch({
      type: "CHANGE_MANUAL_MACROS",
      value: !manualMacros,
    });
  };

  const handleChangeExchangeTypes = (exchangeTypes: ExchangeType[]) => {
    dispatch({
      type: "CHANGE_EXCHANGE_TYPES",
      value: exchangeTypes,
    });
  };

  const macros = manualMacros
    ? // Will eventually add kcal to this form. Until then we'll get kcal from macros.
      initMacros(inputToNumber(choInput) || 0, inputToNumber(proInput) || 0, inputToNumber(fatInput) || 0)
    : scaleMacros(
        {
          cho: totalCho,
          pro: totalPro,
          fat: totalFat,
          kcal: totalKcal,
        },
        defaultAmount / perRecipeYield
      );

  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "flex-start" }}>
      <Box sx={{ display: "flex", flexDirection: "column", height: 105, gap: 1 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            disabled={disabled}
            label="Yields"
            onChange={e => handleServingChangePerRecipeYield(e.target.value)}
            value={perRecipeYieldInput}
            error={inputToNumber(perRecipeYieldInput) === null}
            sx={{ width: 100 }}
          />
          <ServingUnitsInput
            disabled={disabled}
            value={units}
            onChange={handleServingChangeUnits}
            sx={{ width: 224 }} />
          <Tooltip title="Default Amount" placement="bottom">
            <TextField
              disabled={disabled}
              label="Default Amount"
              onChange={e => handleServingChangeDefaultAmount(e.target.value)}
              value={defaultAmountInput}
              error={inputToNumber(defaultAmountInput) === null}
              sx={{ width: 100 }}
            />
          </Tooltip>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle1">
            Macros per "{defaultAmount} {getServingUnits(units)}":
          </Typography>
          <Box sx={{ display: "flex", ml: 0.5, alignItems: "center" }}>
            {manualMacros ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="subtitle1">{macros.kcal}kcal </Typography>
                <MacrosInput
                  disabled={disabled}
                  value={choInput}
                  placeholder="CHO"
                  onChange={e =>
                    dispatch({
                      type: "CHANGE_CHO_INPUT",
                      value: e.target.value,
                    })
                  }
                />
                <MacrosInput
                  disabled={disabled}
                  value={proInput}
                  placeholder="PRO"
                  onChange={e =>
                    dispatch({
                      type: "CHANGE_PRO_INPUT",
                      value: e.target.value,
                    })
                  }
                />
                <MacrosInput
                  disabled={disabled}
                  value={fatInput}
                  placeholder="FAT"
                  onChange={e =>
                    dispatch({
                      type: "CHANGE_FAT_INPUT",
                      value: e.target.value,
                    })
                  }
                />
              </Box>
            ) : (
              <MacrosSummaryLabel
                macros={macros}
                kcalPrefix
                variant="sm"
                sx={theme => ({ ...theme.typography.subtitle1 })} />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", height: 105, width: 228 }}>
        <ExchangeTypeMultiSelect
          disabled={disabled}
          label={"Exchange Types"}
          handleChangeExchangeTypes={handleChangeExchangeTypes}
          selectedExchangeTypes={exchangeTypes}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              sx={{ py: 0, alignSelf: "center" }}
              size="small"
              checked={manualMacros}
              onChange={handleToggleManualMacros}
            />
          }
          label="Manually set macros"
          labelPlacement="end"
        />
      </Box>
      <Box height={105}>
        {(hasRecipeScaling || forStaff) && (
          <Box sx={{ display: "flex", height: 60, alignItems: "flex-end" }}>
            <Button
              variant="outlined"
              disabled={disabled || ingredients.length <= 0 || !inputToNumber(perRecipeYieldInput)}
              onClick={() => {
                openScaleRecipe();
                trackEvent("Nutrition | Team | Recipe | Scale Recipe", {});
              }}
            >
              Scale Recipe
              <NewChip feature="scalingRecipe" />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface MacrosInputProps {
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const MacrosInput = ({ value, placeholder, onChange, disabled }: MacrosInputProps) => {
  return (
    <TextField
      disabled={disabled}
      sx={{ width: 70, m: 0 }}
      value={value}
      error={inputToNumber(value) === null}
      placeholder={placeholder}
      onChange={onChange}
      inputProps={{
        sx: { pl: 1 },
      }}
      InputProps={{
        sx: { fontSize: 12, pr: 1, pl: 0 },
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant="subtitle1">g</Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RecipeFormServing;

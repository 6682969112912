import { TeamFormState } from "../EditForm/utils";
import { EditTeamInput, TeamPageTeamFragment } from "../../../types";
import { FullSportFragment } from "../../../types";

export const initialEditTeamFormState = (team: Pick<TeamPageTeamFragment, "id" | "name" | "gender" | "sport">): TeamFormState => ({
  id: team.id,
  sport: team.sport,
  name: team.name,
  gender: team.gender as "Men's" | "Women's" | null,
});

type SaveableTeamFormState = {
  id: string;
  gender: "Men's" | "Women's" | null;
  sport: FullSportFragment | null;
  name: string;
};

export const teamFormToEditInput = (state: SaveableTeamFormState): EditTeamInput => teamFormToInput(state);

export const teamFormToInput = (state: SaveableTeamFormState): EditTeamInput => ({
  team: {
    sportId: state.sport ? state.sport.id : null,
    gender: state.gender,
    name: state.name,
  },
  id: state.id,
});

import { createFilterOptions, FilterOptionsState } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import {
  DeliveryLocationMenuLinkFormFragment,
  TeamLabelFragment,
  useDeliveryLocationCursorConnectionQuery,
  useTeamLabelCursorConnectionQuery,
} from "../../../types";
import { BasicOption } from "../../universal/BaseAsyncAutocomplete";
import InfiniteScrollAutocomplete, {
  GetQueryVariablesFromPaginationAndInputArgs,
} from "../../universal/InfiniteScroll/InfiniteScrollAutocomplete";

interface TeamAndDeliveryLocationSelectProps {
  deliveryLocation: DeliveryLocationMenuLinkFormFragment | undefined;
  teamLabel: TeamLabelFragment | undefined;
  onChange: (newValue: {
    deliveryLocation: DeliveryLocationMenuLinkFormFragment | undefined;
    teamLabel: TeamLabelFragment | undefined;
  }) => void;
}

const TeamAndDeliveryLocationSelect = ({ deliveryLocation, teamLabel, onChange }: TeamAndDeliveryLocationSelectProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (teamLabel && inputRef.current) {
      inputRef.current.focus();
    }
  }, [teamLabel]);

  const getQueryVariablesForTeamLabels = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
      },
    }),
    []
  );
  const getQueryVariablesForDeliveryLocations = useCallback(
    ({ cursor, limit, input }: GetQueryVariablesFromPaginationAndInputArgs) => ({
      variables: {
        query: input,
        pagination: { cursor, limit },
        teamLabelId: teamLabel?.id || null,
      },
      skip: !teamLabel,
    }),
    [teamLabel]
  );

  const filterOptions = (option: any, state: FilterOptionsState<TeamLabelFragment>): any[] =>
    createFilterOptions<TeamLabelFragment>({
      stringify: () => state.inputValue,
    })(option, state);

  const transformAndFilterTeamLabelOptions = (edges: TeamLabelFragment[] | undefined): TeamLabelFragment[] => {
    return edges ?? [];
  };
  const transformAndFilterDeliveryLocationOptions = (
    edges: DeliveryLocationMenuLinkFormFragment[] | undefined
  ): DeliveryLocationMenuLinkFormFragment[] => {
    return edges ?? [];
  };

  const teamLabelEdgesAreEqual = useCallback((edge1: TeamLabelFragment, edge2: TeamLabelFragment) => {
    return edge1.id === edge2.id;
  }, []);
  const deliveryLocationEdgesAreEqual = useCallback(
    (edge1: DeliveryLocationMenuLinkFormFragment, edge2: DeliveryLocationMenuLinkFormFragment) => {
      return edge1.id === edge2.id;
    },
    []
  );

  const handleChangeTeamLabel = (_: string, option: TeamLabelFragment) =>
    onChange({
      teamLabel: option ?? undefined,
      deliveryLocation: undefined,
    });

  const handleChangeDeliveryLocation = (_: string, option: DeliveryLocationMenuLinkFormFragment) =>
    onChange({
      teamLabel: teamLabel,
      deliveryLocation: option ?? undefined,
    });
  return (
    <>
      <InfiniteScrollAutocomplete
        queryKey="teamLabelCursorConnection"
        useCursorConnectionQuery={useTeamLabelCursorConnectionQuery}
        getQueryVariablesFromPaginationAndInput={getQueryVariablesForTeamLabels}
        transformAndFilterOptions={transformAndFilterTeamLabelOptions}
        renderOption={({ name }) => <BasicOption element={name} />}
        getCustomOptionKey={({ id }) => id}
        handleChange={handleChangeTeamLabel}
        getOptionLabel={teamLabel => teamLabel.name}
        filterOptions={filterOptions}
        multiple={false}
        freeSolo
        noOptionsText="No Teams Found"
        inputPlaceholder="Team for Delivery Locations"
        edgesAreEqual={teamLabelEdgesAreEqual}
        value={teamLabel ?? null}
      />
      <InfiniteScrollAutocomplete
        queryKey="deliveryLocationCursorConnection"
        useCursorConnectionQuery={useDeliveryLocationCursorConnectionQuery}
        getQueryVariablesFromPaginationAndInput={getQueryVariablesForDeliveryLocations}
        transformAndFilterOptions={transformAndFilterDeliveryLocationOptions}
        renderOption={({ name }) => <BasicOption element={name} />}
        handleChange={handleChangeDeliveryLocation}
        getOptionLabel={teamLabel => teamLabel.name}
        getCustomOptionKey={({ id }) => id}
        filterOptions={filterOptions}
        multiple={false}
        freeSolo
        inputPlaceholder="Add Delivery Locations"
        noOptionsText={teamLabel ? "No Delivery Locations Found" : "Select a Team"}
        edgesAreEqual={deliveryLocationEdgesAreEqual}
        value={deliveryLocation ?? null}
        disabled={!teamLabel}
      />
    </>
  );
};

export default TeamAndDeliveryLocationSelect;

import { Box, Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import DialogTitle from "./DialogTitle";

const DialogTitleExample = () => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Button onClick={() => setShowDialog(true)}>show Dialog using DialogTitle</Button>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}>
        <DialogTitle title="Title" onClose={() => setShowDialog(false)} />
      </Dialog>
    </Box>
  );
};

export default DialogTitleExample;

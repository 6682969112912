import { useLayoutEffect, useState } from "react";
import { ExchangeProgressViews, MacroProgressViews } from "../types";

export type MacrosProgressMode = "HasTargets_ShowNumbers" | "HasTargets_HideNumbers" | "NoTargets_ShowNumbers" | "NoTargets_HideNumbers";

interface useMacrosProgressModePayload<M extends string> {
  mode: MacrosProgressMode | M;
  onChangeMode: () => void;
  additionalModes?: M[];
}

export const getAllowedViews = ({
  macroProgressViews,
  hasTargets,
}: {
  macroProgressViews: MacroProgressViews;
  hasTargets: boolean;
}): MacrosProgressMode[] => {
  let modes: MacrosProgressMode[] = [];
  if (macroProgressViews === "all") {
    if (hasTargets) {
      modes = [...modes, "HasTargets_ShowNumbers", "HasTargets_HideNumbers", "NoTargets_HideNumbers"];
    } else {
      modes = [...modes, "NoTargets_ShowNumbers", "NoTargets_HideNumbers"];
    }
  } else if (macroProgressViews === "hideNumbers") {
    if (hasTargets) {
      modes = [...modes, "HasTargets_HideNumbers", "NoTargets_HideNumbers"];
    } else {
      modes = [...modes, "NoTargets_HideNumbers"];
    }
  } else {
    //"none" case. No available displays.
  }
  return modes;
};

interface useProgressModeArgs<M extends string> {
  macroProgressViews: MacroProgressViews;
  exchangeProgressViews?: ExchangeProgressViews;
  hasTargets: boolean;
  additionalModes?: M[];
  additionalModesFirst?: boolean;
}

export const useProgressViews = <M extends string = never>({
  macroProgressViews,
  hasTargets,
  additionalModes = [],
  additionalModesFirst = false,
}: useProgressModeArgs<M>): useMacrosProgressModePayload<M> => {
  const [modeIndex, setModeIndex] = useState(0);
  const [allowedModes, setAllowedModes] = useState(
    getAllowedViews({
      macroProgressViews,
      hasTargets,
    })
  );
  const allModes = additionalModesFirst ? [...additionalModes, ...allowedModes] : [...allowedModes, ...additionalModes];
  const mode = allModes[modeIndex];

  useLayoutEffect(() => {
    setModeIndex(0);
    setAllowedModes(
      getAllowedViews({
        macroProgressViews,
        hasTargets,
      })
    );
  }, [macroProgressViews, hasTargets, setModeIndex]);

  const onChangeMode = () => {
    setModeIndex((modeIndex + 1) % allModes.length);
  };

  return {
    mode,
    onChangeMode,
  };
};

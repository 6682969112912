import { Button, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch, useState } from "react";
import { AddExcludeAthleteAction, AdvancedSelectionState } from "../../../reducers/advancedSelectionReducers";
import ShareWithInfo from "./ShareWithInfo";
import ViewSelectedAthletesDialog from "./ViewSelectedAthletes/ViewSelectedAthletesDialog";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2, 0),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    button: {
      minWidth: theme.spacing(26),
      marginLeft: theme.spacing(2),
    },
    shareWithContainer: {
      width: "100%",
    },
  })
);

interface ShareWithContentProps {
  state: AdvancedSelectionState;
  dispatch: Dispatch<AddExcludeAthleteAction>;
}
const ShareWithContent = ({ state, dispatch }: ShareWithContentProps) => {
  const classes = useStyles();
  const [viewSelectedAthletesOpen, setViewSelectedAthletesOpen] = useState(false);
  return (
    <>
      <div className={classes.container}>
        <ShareWithInfo
          state={state}
          maxChips={16}
          showWrapper
          showAdvancedSelectionClear={false}
          containerClassName={classes.shareWithContainer}
        />

        <Button
          variant="outlined"
          onClick={() => setViewSelectedAthletesOpen(true)}
          className={classes.button}>
          View Selected Athletes
        </Button>
      </div>
      {viewSelectedAthletesOpen && (
        <ViewSelectedAthletesDialog
          open={viewSelectedAthletesOpen}
          onClose={() => setViewSelectedAthletesOpen(false)}
          state={state}
          dispatch={dispatch}
        />
      )}
    </>
  );
};

export default ShareWithContent;

import React from "react";
import { CuisineFragment, useCuisineCursorConnectionQuery } from "../../../../types";
import { BasicOption } from "../../../universal/BaseAsyncAutocomplete";
import BaseFilter from "./BaseFilter/BaseFilterWithInfiniteScroll";
import { baseEdgesAreEqual, baseGetKey, baseGetLabel, getBaseTransformAndFilter } from "./BaseFilter/utils";

interface CuisineFilterProps {
  selectedCuisines: CuisineFragment[] | undefined;
  handleChange: (newValue: CuisineFragment[] | null) => void;
  disabled?: boolean;
}

const CuisineFilter = ({ selectedCuisines, handleChange, disabled = false }: CuisineFilterProps) => {
  const transformAndFilterCuisineOptions = getBaseTransformAndFilter(selectedCuisines ?? []);

  return (
    <BaseFilter
      getLabel={baseGetLabel}
      getKey={baseGetKey}
      filterLabel={"Cuisine"}
      selectedFilters={selectedCuisines ?? []}
      handleChange={filters => handleChange(filters.length === 0 ? null : filters)}
      disabled={disabled}
      queryKey="cuisineCursorConnection"
      useCursorConnectionQuery={useCuisineCursorConnectionQuery}
      edgesAreEqual={baseEdgesAreEqual}
      renderOption={({ name }) => <BasicOption element={name} />}
      transformAndFilterOptions={transformAndFilterCuisineOptions}
    />
  );
};

export default CuisineFilter;

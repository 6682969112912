import { Theme } from "@mui/material";

export const getLikeChipSx = ({ palette: { like, greyscale } }: Theme) => ({
  backgroundColor: like.lighter,
  color: like.main,
  "& .MuiChip-label": { fontWeight: 450 },
  "& .MuiChip-deleteIcon": { color: greyscale[500] },
});

export const getDislikeChipSx = ({ palette: { dislike, greyscale } }: Theme) => ({
  backgroundColor: dislike.lighter,
  color: dislike.main,
  "& .MuiChip-label": { fontWeight: 450 },
  "& .MuiChip-deleteIcon": { color: greyscale[500] },
});

export const getLikeChipDarkerSx = ({ palette: { like, greyscale } }: Theme) => ({
  backgroundColor: like.lighter,
  color: like.main,
  "& .MuiChip-label": { fontWeight: 650 },
  "& .MuiChip-deleteIcon": { color: greyscale[500] },
});

export const getDislikeChipDarkerSx = ({ palette: { dislike, greyscale } }: Theme) => ({
  backgroundColor: dislike.lighter,
  color: dislike.main,
  "& .MuiChip-label": { fontWeight: 650 },
  "& .MuiChip-deleteIcon": { color: greyscale[500] },
});

import ContactsIcon from "@mui/icons-material/ContactsRounded";
import React from "react";

// full page path
export const NAV_ORG_TEAM_MANAGEMENT = "/org/team-management";

// local child paths
export const NAV_TEAM_MANAGEMENT_TEAMS = "teams";
export const NAV_TEAM_MANAGEMENT_TAGS = "tags";
export const NAV_TEAM_MANAGEMENT_TRANSFERS = "transfers";

// full child paths
export const NAV_ORG_TEAM_MANAGEMENT_TEAMS = `${NAV_ORG_TEAM_MANAGEMENT}/${NAV_TEAM_MANAGEMENT_TEAMS}`;

export const teamManagementLink = {
  to: NAV_ORG_TEAM_MANAGEMENT,
  name: "Team Management",
  icon: <ContactsIcon />,
};

export const teamsLink = {
  name: "Teams",
  to: NAV_ORG_TEAM_MANAGEMENT_TEAMS,
};

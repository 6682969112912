import React from "react";
import { PositionWithMappingsFragment } from "../../../../../../types";
import PositionList from "./PositionList";

interface LinkedPositionsListProps {
  selectedId: string | null;
  positions: readonly PositionWithMappingsFragment[];
}

const LinkedPositionsList = (props: LinkedPositionsListProps) => {
  return <PositionList
    {...props}
    title="Linked Positions"
    columnMode="NoAction"
    disableOnSelected />;
};

export default LinkedPositionsList;

import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import { Box, Button, Popover, useTheme } from "@mui/material";
import React from "react";

interface Props {
  position?: {
    top: number;
    left: number;
  };
  onClickDisable: () => void;
  onClickEnable: () => void;
  isDayEnabled: boolean;
  onClose: () => void;
}

export const MenuBuilderColumnToolbar = ({ position, onClickDisable, onClickEnable, isDayEnabled, onClose }: Props) => {
  const {
    palette: { greyscale, highEmphasisTextDark },
  } = useTheme();

  const buttonSx = {
    color: highEmphasisTextDark,
    "&:hover": { backgroundColor: greyscale[700] },
    "&:disabled": { color: "#6B707A" },
  };

  return (
    <Popover
      sx={{ borderRadius: "6px" }}
      open={Boolean(position)}
      anchorReference="anchorPosition"
      anchorPosition={position}
      onClose={onClose}
    >
      <Box sx={{ backgroundColor: greyscale[900], color: highEmphasisTextDark, p: 1, display: "flex", gap: "6px" }}>
        <Button
          variant="text"
          sx={buttonSx}
          onClick={() => isDayEnabled ? onClickDisable() : onClickEnable()}
          startIcon={isDayEnabled ? <DisabledByDefaultRoundedIcon /> : <CheckBoxRoundedIcon />}
        >
          {isDayEnabled ? "Disable" : "Enable"}
        </Button>
      </Box>
    </Popover>
  );
};

import { Box, SxProps } from "@mui/material";
import React, { useState } from "react";

import ServingAmountEditChipList from "./ChipList";

import { FullServingAmountFragment, RecipeWithServingsFragment } from "../../../types";
import { OrgFoodRequestModal } from "../../Food/Org/OrgFoodRequestModal";
import { ServingAmountsSearchBar } from "../SearchBar";

interface ServingAmountsEditChipListWithSearchBarProps {
  selectedServingAmounts: readonly FullServingAmountFragment[];
  onChange: (servingAmounts: readonly FullServingAmountFragment[], addedRecipe?: RecipeWithServingsFragment) => void;
  enableRecipes?: boolean;
  enableBranded: boolean;
  selected: boolean;
  className?: string;
  sx?: SxProps;
  noCache?: boolean;
  includeRecipeIngredients?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  addOnSearchBarSx?: SxProps;
}

export const ServingAmountsEditChipListWithSearchBar = ({
  selectedServingAmounts,
  className,
  sx,
  selected,
  autoFocus,
  onChange,
  enableRecipes = true,
  enableBranded,
  noCache = false,
  includeRecipeIngredients = false,
  disabled,
  addOnSearchBarSx,
}: ServingAmountsEditChipListWithSearchBarProps) => {
  const [foodModalOpen, setFoodModalOpen] = useState(false);
  const [loadingChip, setLoadingChip] = useState<React.ReactNode | null>(null);
  const handleAdd = (servingAmount: FullServingAmountFragment, recipe?: RecipeWithServingsFragment) => {
    // Prevent duplicate foods from being added
    if (selectedServingAmounts.map(sa => sa.serving.foodOrRecipe.id).includes(servingAmount.serving.foodOrRecipe.id)) {
      return;
    }
    onChange([...selectedServingAmounts, servingAmount], recipe);
  };
  return (
    <Box sx={{ ...sx, display: "flex", flexDirection: "column" }} className={className}>
      {!disabled && (
        <>
          {selected ? (
            <ServingAmountsSearchBar
              onClickAddFood={() => setFoodModalOpen(true)}
              selectedServingAmounts={selectedServingAmounts}
              onAdd={handleAdd}
              noCache={noCache}
              enableRecipes={enableRecipes}
              enableBranded={enableBranded}
              includeRecipeIngredients={includeRecipeIngredients}
              setLoadingChip={setLoadingChip}
              autoFocus={autoFocus}
              addOnSearchBarSx={addOnSearchBarSx}
            />
          ) : (
            <Box
              sx={{
                // When you click on a mealOption card, we don't want the chip list to jump under search bar
                height: 56, // Could be 52 to be exact, but the effect makes the newly clicked mealOption chip list "pop"
              }}
            />
          )}
        </>
      )}
      <Box sx={{ overflowY: "auto", overflowX: "visible", flexGrow: 1, mt: 2 }}>
        <ServingAmountEditChipList
          disabled={disabled}
          servingAmounts={selectedServingAmounts}
          onChange={onChange}
          recipeIngredient={includeRecipeIngredients}
          loadingChip={loadingChip}
        />
      </Box>
      {foodModalOpen && <OrgFoodRequestModal open={foodModalOpen} onClose={() => setFoodModalOpen(false)} />}
    </Box>
  );
};

import { Card, CardActionArea, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

interface ChangeStatusDialogButtonProps {
  onClick: () => void;
  onClose: () => void;
  children: React.ReactNode;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    square: {
      height: "40vh",
      display: "flex",
    },
    flexCenterColumn: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
  })
);

const ChangeStatusDialogButton = ({ onClick, onClose, children, label }: ChangeStatusDialogButtonProps) => {
  const classes = useStyles();
  return (
    <Grid item md={4}>
      <Card className={classes.square}>
        <CardActionArea
          className={classes.flexCenterColumn}
          onClick={() => {
            onClick();
            onClose();
          }}
        >
          {children}
          <Typography color="textSecondary" variant="h4">
            {label}
          </Typography>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ChangeStatusDialogButton;

import { datadogRum } from "@datadog/browser-rum";
import { Box, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { IMatchResult, MatchType } from "../../../utils/import/match";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import ImportButton, { IFileImport, ImportMatchResults } from "../../universal/Import/Button";
import ImportResultBadge from "./ResultBadge";
import { matchFieldsToText } from "./ResultList";
import ImportResultListContainer from "./ResultList/Container";
import { ICrudHandler } from "./types";

export interface ImportTabPanelProps<
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
> {
  children?: React.ReactNode;
  groupKey: keyof (ImportableEntity & LinkEntityFields) | null;
  updateSelectedMatches: (props: ICrudHandler<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>) => void;
  insertSelectedMatches: (props: ICrudHandler<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>) => void;
  matchableRows: (ImportableEntity & LinkEntityFields & AdditionalDisplayFields & { id: string })[];
  matchFields: (keyof ImportableEntity)[];
  linkToEntityName: string;
  entityName: string;
  linkableRows: LinkEntityFields[];
  linkFields: (keyof LinkEntityFields)[];
  linkOnFields: (keyof LinkFields)[];
  loadAndParse: ({ file, onError }: IFileImport) => Promise<ImportableEntity[] | null>;
  disableManualMatch?: boolean;
  disableInsert?: boolean;
  allowShowImportRow?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newRecordsSection: {
      padding: theme.spacing(3),
      display: "flex",
      flexFlow: "column nowrap",
      flexBasis: "55%",
      height: "inherit",
    },
    existingRecordsSection: {
      padding: theme.spacing(3),
      display: "flex",
      flexFlow: "column nowrap",
      flexBasis: "35%",
      height: "inherit",
    },
    resultsRow: {
      display: "flex",
      flexFlow: "row wrap",
    },
    resultsHeader: {
      display: "flex",
    },
  })
);

const ImportTabPanel = <
  LinkFields extends object,
  ImportableEntity extends LinkFields,
  LinkEntityFields extends LinkFields,
  AdditionalDisplayFields extends object = {}
>(
  props: ImportTabPanelProps<LinkFields, ImportableEntity, LinkEntityFields, AdditionalDisplayFields>
) => {
  const {
    children,
    groupKey,
    updateSelectedMatches,
    insertSelectedMatches,
    matchableRows,
    matchFields,
    linkableRows,
    linkFields,
    linkOnFields,
    loadAndParse,
    linkToEntityName,
    entityName,
    disableManualMatch,
    disableInsert,
    allowShowImportRow,
  } = props;
  const classes = useStyles();
  const { setMessage } = useSnackbar();
  const [loadState, setLoadState] = useState<"loading" | "done" | "error" | null>(null);
  const [rowsToUpdate, setRowsToUpdate] = useState<Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>>([]);
  const [rowsToInsert, setRowsToInsert] = useState<Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>>([]);
  const [rowsWithManyMatches, setRowsWithManyMatches] = useState<
    Array<IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>>
  >([]);
  const onMatched = ({
    failedMatches,
    manyMatches,
    singleMatches,
  }: ImportMatchResults<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>) => {
    // We filter out duplicates here...this can happen in ImportLinkModal when
    // two teams are aliased to one team, and the Teamworks import file contains
    // many athletes!
    const uniqMatches = failedMatches.reduce(
      (uniqMap, match) =>
        uniqMap[matchFieldsToText(match.matchFields, match.row)]
          ? uniqMap
          : {
              ...uniqMap,
              [matchFieldsToText(match.matchFields, match.row)]: match,
            },
      {} as {
        [key: string]: IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>;
      }
    );
    setRowsToInsert(Object.values(uniqMatches));
    // This loses sort order!
    setRowsToUpdate(singleMatches);
    setRowsWithManyMatches(manyMatches);
  };
  const onFileParsed = (rows: ImportableEntity[]) => {
    setLoadState("done");
  };
  const onFileLoaded = () => {
    setLoadState("loading");
  };
  const onError = (msg: string) => {
    const errorMessage = `Error while importing entityName: ${entityName}: ${msg}`;
    console.error(errorMessage);
    datadogRum.addError(errorMessage);
    setMessage("error", msg);
    setLoadState("error");
  };

  const moveRecordToMatched = (match: IMatchResult<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>, idx: number) => {
    setRowsToInsert(rowsToInsert.filter(m => matchFieldsToText(match.matchFields, match.row) !== matchFieldsToText(m.matchFields, m.row)));
    setRowsToUpdate([...rowsToUpdate, match]);
  };

  const onChange = (row: ImportableEntity & LinkEntityFields, type: MatchType, newRow: ImportableEntity & LinkEntityFields) => {
    const newMatchResult = {
      row: newRow,
      matchFields,
      type: "none" as const,
      matchedRows: [],
    };
    if (type === "none") {
      setRowsToInsert([...rowsToInsert.filter(matchRows => matchRows.row !== row), newMatchResult]);
    } else {
      setRowsToUpdate([...rowsToUpdate.filter(matchRows => matchRows.row !== row)]);
      setRowsToInsert([...rowsToInsert, newMatchResult]);
    }
  };

  return (
    <>
      <Box sx={{ p: 4, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ width: "800px" }}>{children}</Box>
        <ImportButton
          label="Import"
          matchableRows={matchableRows}
          matchFields={matchFields}
          linkToEntityName={linkToEntityName}
          linkFromEntityName={entityName}
          linkableRows={linkableRows}
          linkFields={linkFields}
          linkOnFields={linkOnFields}
          loadAndParse={loadAndParse}
          onMatched={onMatched}
          onError={onError}
          onFileLoaded={onFileLoaded}
          onFileParsed={onFileParsed}
        />
      </Box>
      <div className={classes.resultsRow}>
        <div className={classes.existingRecordsSection}>
          <div className={classes.resultsHeader}>
            <ImportResultBadge state={loadState} numberRecords={rowsToUpdate.length}>
              <Typography component="span" variant="h3">
                Existing Records Matched
              </Typography>
            </ImportResultBadge>
          </div>
          <ImportResultListContainer<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>
            matches={rowsToUpdate}
            setMatches={setRowsToUpdate}
            groupKey={groupKey}
            onAction={updateSelectedMatches}
            actionButtonName="Update Selected Records"
            onChange={onChange}
            allowShowImportRow={allowShowImportRow}
          />
          {rowsWithManyMatches.length > 0 && (
            <>
              <ImportResultBadge state={loadState} numberRecords={rowsWithManyMatches.length}>
                <Typography component="span" variant="h3">
                  Multiple Matches
                </Typography>
              </ImportResultBadge>
              <ImportResultListContainer<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>
                matches={rowsWithManyMatches}
                setMatches={setRowsWithManyMatches}
                groupKey={groupKey}
                onAction={updateSelectedMatches} //ad update here
                actionButtonName="Update Most Recent Matched Record"
                onChange={onChange}
                allowShowImportRow={allowShowImportRow}
              />
            </>
          )}
        </div>
        <div className={classes.newRecordsSection}>
          <div className={classes.resultsHeader}>
            <ImportResultBadge numberRecords={rowsToInsert.length} state={loadState}>
              <Typography component="span" variant="h3">
                New Records
              </Typography>
            </ImportResultBadge>
          </div>
          <ImportResultListContainer<ImportableEntity & LinkEntityFields, AdditionalDisplayFields>
            matches={rowsToInsert}
            setMatches={setRowsToInsert}
            groupKey={groupKey}
            onAction={insertSelectedMatches}
            onMatch={disableManualMatch ? undefined : moveRecordToMatched}
            actionButtonName="Insert Selected Records"
            existingRows={matchableRows}
            onChange={onChange}
            allowShowImportRow={allowShowImportRow}
            disabled={disableInsert}
          />
        </div>
      </div>
    </>
  );
};

export default ImportTabPanel;

import { ServingUnitsState } from "./types";
import { Unit } from "@notemeal/graphql/types";

export const standardizeUnitsState = (
  unitsState: ServingUnitsState,
  standardizedUnits: readonly Pick<Unit, "id" | "name">[]
): ServingUnitsState => {
  const customUnit = unitsState.customUnits?.toLowerCase();
  if (!customUnit) {
    return unitsState;
  }
  const regExpMap: { [key: string]: RegExp[] } = {
    L: [/\bliters\b/, /\bliter\(s\)\B/, /\bl\b/],
    mL: [/\bml\b/, /\bmilliliter\b/, /\bmilliliter\(s\)\b/, /\bmilliliters\b/],
    "gallon(s)": [/\bgallons\b/, /\bgallon\(s\)\B/, /\bgallon\b/],
    "quart(s)": [/\bquarts\b/, /\bqt\b/, /\bquart\(s\)\b/, /\bquart\(s\)\B/, /\bquart\b/],
    "pint(s)": [/\bpt\b/, /\bpints\b/, /\bpint\(s\)\B/, /\bpint\b/],
    "cup(s)": [/\bcup\(s\)\B/, /\bcup\b/, /\bcp\b/, /\bcups\b/],
    "fl. ounces": [/\bfl. ounces\b/, /\bfl. oz\b/, /\bfl oz\b/, /\bfluid ounces\b/, /\bfl. ounces\(s\)\b/, /\bfluid ounce\(s\)\b/],
    tbsp: [/\btbsp\b/, /\btablespoons\b/, /\btablespoon\b/, /\btablespoon\(s\)\b/],
    tsp: [/\btsp\b/, /\bteaspoons\b/, /\bteaspoon\b/, /\bteaspoon\(s\)\b/],
    kg: [/\bkg\b/, /\bkilograms\b/, /\bkilogram\(s\)\b/],
    "gram(s)": [/\bgrams\b/, /\bg\b/, /\bgram\(s\)\B/],
    mg: [/\bmg\b/, /\bmilligrams\b/, /\bmilligram\b/, /\bmilligram\(s\)\b/],
    "pound(s)": [/\bpound\(s\)\B/, /\blbs\b/, /\blbs.\b/, /\bpounds\b/, /\bpound\b/],
    "ounce(s)": [/\boz\b/, /\bonz\b/, /\bounces\b/, /\bounce\b/, /\bounce\(s\)\B/],
  };
  const keys = Object.keys(regExpMap);
  const stdUnitState = keys.flatMap(k => {
    const re = regExpMap[k].find(re => re.test(customUnit));
    if (re) {
      const match = re.exec(customUnit);
      if (match) {
        const stdUnit = standardizedUnits.find(u => u.name === k);
        if (stdUnit) {
          return [
            {
              customUnits: null,
              unit: stdUnit,
              unitPrefix: customUnit.split(match[0])[0] || null,
              unitSuffix: customUnit.split(match[0])[1] || null,
            },
          ];
        }
      }
    }
    return [];
  });
  return stdUnitState.length > 0 ? stdUnitState[0] : unitsState;
};

export const getCustomUnitInput = (input: string) => ({ unitId: null, customUnits: input, unitPrefix: null, unitSuffix: null });
export const getUnitInput = ({ unit, unitPrefix, unitSuffix, customUnits }: ServingUnitsState) => ({
  unitId: unit ? unit.id : null,
  customUnits,
  unitPrefix,
  unitSuffix,
});
export const getUnitState = (input: string) => ({ customUnits: input, unitPrefix: null, unit: null, unitSuffix: null });

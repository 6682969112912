import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import React from "react";
import { ReactEventHandlers } from "react-use-gesture/dist/types";
import { FullServingAmountFragment } from "../../types";

interface MenuItemChoiceOptionListItemProps {
  name: string;
  onSelect: () => void;
  servingAmounts: readonly FullServingAmountFragment[];
  selected: boolean;
  exclusive: boolean;
  onRemove?: () => void;
  bindProps?: ReactEventHandlers;
  choiceCounter?: number;
}

const MenuItemChoiceOptionListItem = ({
  name,
  onSelect,
  servingAmounts,
  selected,
  exclusive,
  onRemove,
  bindProps,
  choiceCounter,
}: MenuItemChoiceOptionListItemProps) => {
  return (
    <ListItemButton
      dense
      onClick={onSelect}
      sx={{
        backgroundColor: selected ? "grey.100" : "transparent",
        py: 2,
        px: 1,
        mb: 1,
      }}
    >
      {bindProps && (
        <ListItemIcon sx={{ minWidth: 40 }}>
          <DragIndicatorIcon {...bindProps} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={name ? name : `Choice ${choiceCounter}`}
        secondary={servingAmounts.length === 0 ? "Missing ingredients" : undefined}
        secondaryTypographyProps={{ sx: ({ palette }) => ({ color: palette.warning.main }) }}
      />
      {onRemove && (
        <ListItemSecondaryAction>
          <IconButton size="small" onClick={onRemove}>
            <CloseIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
};

export default MenuItemChoiceOptionListItem;

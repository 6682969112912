import DownloadIcon from "@mui/icons-material/CloudDownload";
import { Button, Paper, TableContainer, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { TWTabGroup } from "apps/web/src/componentLibrary/TWTabGroup/TWTabGroup";
import React, { useState } from "react";
import { OrderPageRestaurantMenuLinkPreviewFragment, useOrderPageRestaurantMenuLinkQuery } from "../../../types";
import InstructionsModal from "./InstructionsModal";
import MenuItemsTable from "./MenuItemsTable";
import OrdersTable from "./OrdersTable";
import PlatesTable from "./PlatesTable";
import {
  formatMenuItemsRow,
  formatRestaurantMenuLinkOrderRows,
  formatRestaurantMenuLinkPlateRows,
  getSerializedMenuItemsFromRMLOrdersAndPlates,
} from "./utils";
import { buildWorkbook, saveWorkbook } from "./utils/exportUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      marginBottom: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
    downloadIcon: {
      marginLeft: theme.spacing(1),
    },
    tabs: {
      marginBottom: theme.spacing(2),
    },
    tableRoot: {
      padding: theme.spacing(3, 6),
      boxSizing: "border-box",
      height: "calc(80vh - 64px)", // TODO: fine tune this number
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto 1fr",
    },
    paper: {
      display: "grid",
      gridTemplateRows: "1fr auto",
      gridTemplateColumns: "auto",
      minHeight: 0,
      maxHeight: "100%",
    },
    tableContainer: {
      gridColumnStart: 1,
      gridRowStart: 1,
      minHeight: 0,
      overflowY: "auto",
    },
  })
);

interface RestaurantOrdersPageProps {
  restaurantMenuLinkPreview: OrderPageRestaurantMenuLinkPreviewFragment;
  start: string;
  mealMenuName: string;
}

const RestaurantOrdersPage = ({ restaurantMenuLinkPreview, start, mealMenuName }: RestaurantOrdersPageProps) => {
  const classes = useStyles();
  const ORDERS_BY_ATHLETE = "Orders By Athlete";
  const PLATES = "Plates";
  const ORDERS_AND_PLATES_BY_MENU_ITEM = "Orders and Plates By Menu Item";

  const [selected, setSelected] = useState(ORDERS_BY_ATHLETE);
  const tabs = [ORDERS_BY_ATHLETE, PLATES, ORDERS_AND_PLATES_BY_MENU_ITEM];
  const { data, loading } = useOrderPageRestaurantMenuLinkQuery({
    variables: {
      restaurantMenuLinkId: restaurantMenuLinkPreview.id,
    },
    fetchPolicy: "network-only",
  });
  const [addInstructionsModalOpen, setAddInstructionsModalOpen] = useState(false);

  if (!data || loading) {
    return <Loading progressSize="lg" />;
  }

  const showOrders = selected === ORDERS_BY_ATHLETE;
  const showPlates = selected === PLATES;
  const showMenuItems = selected === ORDERS_AND_PLATES_BY_MENU_ITEM;
  const { restaurantMenuLink } = data;

  const { orders, plates, deliveryLocation } = restaurantMenuLink;
  const orderRowInfoArray = formatRestaurantMenuLinkOrderRows(orders, plates);
  const plateRowInfoArray = formatRestaurantMenuLinkPlateRows(plates);
  const serializedMenuItems = getSerializedMenuItemsFromRMLOrdersAndPlates({
    orders,
    plates,
  });

  const menuItemRowInfos = serializedMenuItems.map(formatMenuItemsRow);

  const handleExport = async (instructions: string) => {
    const ordersDescriptionInfo = { deliveryLocation, instructions };

    const workbook = buildWorkbook({
      ordersDescriptionInfo,
      orderRowInfoArray,
      plateRowInfoArray,
      serializedMenuItems,
    });
    const fileName = `${mealMenuName}-${restaurantMenuLinkPreview.restaurant.name}-${new Date(start).toLocaleDateString()}.xlsx`;
    await saveWorkbook(workbook, fileName);
  };

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography variant="h4">{restaurantMenuLinkPreview.restaurant.name}</Typography>
        <Button onClick={() => setAddInstructionsModalOpen(true)}>
          Export <DownloadIcon className={classes.downloadIcon} />
        </Button>
      </div>
      <TWTabGroup tabs={tabs} onSelected={setSelected} />
      <div className={classes.tableRoot}>
        <Paper elevation={5} className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            {showOrders && <OrdersTable orderRowInfoArray={orderRowInfoArray} />}
            {showPlates && <PlatesTable plateRowInfoArray={plateRowInfoArray} />}
            {showMenuItems && <MenuItemsTable menuItemRowInfos={menuItemRowInfos} />}
          </TableContainer>
        </Paper>
      </div>
      {addInstructionsModalOpen && (
        <InstructionsModal
          open={addInstructionsModalOpen}
          onClose={() => setAddInstructionsModalOpen(false)}
          onDone={handleExport} />
      )}
    </>
  );
};

export default RestaurantOrdersPage;

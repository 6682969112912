import { Box, Button, Typography } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import CalendarWeek, { HOURS_COLUMN_WIDTH } from "apps/web/src/components/Calendar/Week";
import CalendarWeekNavigation from "apps/web/src/components/Calendar/Week/Navigation";
import {
  EditFullMealPlanFragment,
  useAthleteCalendarInRangePreviewQuery,
  useExternalCalendarsQuery,
  useMealPlanCalendarInRangePreviewQuery,
  useMealPlanDaysOfWeekQuery,
} from "apps/web/src/types";
import React from "react";
import { useMealPlanCalendarContext } from "../../contexts/Calendar";
import { getEventArgsForEvent, getOverridingMealPlanName, useMealPlanSideEffects } from "../utils";
import MealPlanCalendarDateHeader from "./DateHeader";
import { EditMealPlanCalendarDialog } from "./EditDialog/EditMealPlanCalendarDialog";
import EventPaper from "./Event/Paper";
import NotemealEventPopoverPreview from "./Event/Popover/NotemealPreview";
import TeamworksEventPopoverPreview from "./Event/Popover/TeamworksPreview";
import ExternalCalendarStatus from "./ExternalCalendar/ExternalCalendarStatus";
import { getSuggestedEvents, resolveEventsWithSuggestions } from "./Suggestions/utils";
import { MealPlanCalendarEvent } from "./types";
import { getMealPlanCalendarDetails, getTeamworksCalendarAsCalendarEvents } from "./utils";

export type EventWarning = "empty-view" | "empty-plan" | "none";

interface MealPlanCalendarProps {
  athleteId: string;
  mealPlan: EditFullMealPlanFragment;
  setEditOpen: (open: boolean) => void;
  editOpen: boolean;
  onEditClose: () => void;
}

export const MealPlanCalendar = ({ athleteId, mealPlan, setEditOpen, editOpen, onEditClose }: MealPlanCalendarProps) => {
  const clientTimezone = useClientTimezone();
  const { startOfWeekDate, startOfNextWeek, start, end, onForwardsWeek, onBackwardsWeek } = useMealPlanCalendarContext();
  useMealPlanSideEffects(mealPlan, "calendar");

  const { id: mealPlanId, name, isAutoSuggestionsEnabled, startDate } = mealPlan;

  const dayOfWeekResult = useMealPlanDaysOfWeekQuery({
    variables: {
      mealPlanId,
    },
  });

  const mealPlanCalendarResult = useMealPlanCalendarInRangePreviewQuery({
    variables: {
      mealPlanId,
      start,
      end,
    },
  });

  const athleteCalendarResult = useAthleteCalendarInRangePreviewQuery({
    variables: {
      athleteId,
      start,
      end,
    },
  });

  const scheduleResult = useExternalCalendarsQuery({
    variables: {
      athleteId,
      start,
      end: startOfNextWeek,
    },
  });

  const mealPlanDaysOfWeek = dayOfWeekResult.data?.mealPlan.daysOfWeek ?? [];
  const mealPlanIndividualDates = mealPlanCalendarResult.data?.mealPlan.individualDatesInRange ?? [];
  const modificationsByDate = mealPlanCalendarResult.data?.mealPlan.modificationsInRange ?? [];
  const dateAssignments = athleteCalendarResult.data?.athlete.mealPlanDateAssignmentsInRange ?? [];
  const hasIndividualDates = mealPlanCalendarResult.data?.mealPlan.hasIndividualDateAssignments ?? true;
  const twEvents = scheduleResult.data?.teamworksCalendarForAthleteInRange.events ?? [];

  const externalEventsLoading = scheduleResult.loading;
  const loading = dayOfWeekResult.loading || athleteCalendarResult.loading || externalEventsLoading;

  const { events, assignedDates } = getMealPlanCalendarDetails(
    start,
    end,
    clientTimezone,
    mealPlan,
    mealPlanDaysOfWeek,
    mealPlanIndividualDates,
    modificationsByDate,
    mealPlanId
  );

  const twCalendarEvents = getTeamworksCalendarAsCalendarEvents(twEvents);

  // Get any suggestions if auto suggestions are enabled
  const suggestedEvents = isAutoSuggestionsEnabled
    ? getSuggestedEvents({
        twCalendarEvents,
        events,
        mealPlanId,
        modifiedMealTemplateIds: modificationsByDate.flatMap(mt => {
          return mt.mealTemplateModifications.flatMap(m => m.mealTemplate.id);
        }),
      })
    : [];

  const resolvedEvents = resolveEventsWithSuggestions(isAutoSuggestionsEnabled, events, suggestedEvents, [], []);
  const calendarEvents = [...resolvedEvents, ...twCalendarEvents];

  const eventWarning = getWarningForCurrentView(startDate, hasIndividualDates, calendarEvents);

  return (
    <Box sx={{ mr: 4 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 1,
          ml: `${HOURS_COLUMN_WIDTH}px`,
        }}
      >
        <CalendarWeekNavigation
          startOfWeekDate={startOfWeekDate}
          onForwards={onForwardsWeek}
          onBackwards={onBackwardsWeek}
          typographyProps={{ variant: "h3" }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <ExternalCalendarStatus
            isLoading={externalEventsLoading}
            isErrored={Boolean(scheduleResult.error)}
            isPartialSuccess={Boolean(scheduleResult.data?.teamworksCalendarForAthleteInRange.isPartialSuccess)}
          />
          <Button onClick={() => setEditOpen(true)}>Edit Calendar</Button>
        </Box>
      </Box>
      <CalendarWeek
        startOfWeek={start}
        events={calendarEvents}
        clientTimezone={clientTimezone}
        renderOverlay={() => (
          <>
            {!loading && eventWarning !== "none" && (
              <Box
                sx={{
                  backgroundColor: "grey.50",
                  zIndex: 20,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  opacity: "80%",
                  display: "flex",
                  pt: 14,
                  px: 10,
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ textAlign: "center" }} style={{ whiteSpace: "pre-wrap" }}>
                  {getTextForEventWarning(eventWarning)}
                </Typography>
              </Box>
            )}
          </>
        )}
        renderEvent={{
          renderPaper: (event, args) => (
            <EventPaper
              key={event.id}
              event={event}
              args={getEventArgsForEvent(args, event, dateAssignments, mealPlanId, assignedDates)} />
          ),
          renderPopover: (event, args) => {
            if (event.type === "Teamworks") {
              return <TeamworksEventPopoverPreview
                key={event.id}
                event={event}
                anchorEl={args.anchorEl}
                onClose={args.onClose} />;
            } else {
              return <NotemealEventPopoverPreview
                key={event.id}
                event={event}
                anchorEl={args.anchorEl}
                onClose={args.onClose} />;
            }
          },
        }}
        renderDateHeader={props => (
          <MealPlanCalendarDateHeader
            {...props}
            overridingMealPlanName={getOverridingMealPlanName(props.date, dateAssignments, mealPlanId, assignedDates)}
          />
        )}
        loading={loading}
      />
      {editOpen && (
        <EditMealPlanCalendarDialog
          athleteId={athleteId}
          mealPlanId={mealPlanId}
          mealPlanName={name}
          onClose={() => {
            setEditOpen(false);
            onEditClose();
          }}
          open={editOpen}
          promptToSetDefaultDates={eventWarning === "empty-plan"}
        />
      )}
    </Box>
  );
};

const getWarningForCurrentView = (
  start: string | null,
  hasIndividualDates: boolean,
  events: readonly MealPlanCalendarEvent[]
): EventWarning => {
  // if there aren't any events for the current week but there are past or future events
  const mealPlanEvents = events.filter(e => e.type !== "Teamworks");
  const isEventlessForCurrentView = mealPlanEvents.length === 0;

  if (!isEventlessForCurrentView) {
    return "none";
  }

  const hasRangeSet = start !== null && start !== "";
  const isAssignedPlan = hasIndividualDates || hasRangeSet;
  return isAssignedPlan ? "empty-view" : "empty-plan";
};

const getTextForEventWarning = (warning: EventWarning) => {
  if (warning === "empty-plan") {
    return `The meal plan selected does not have any days assigned to the current calendar view.
    Edit the calendar to assign this meal plan.`;
  }
  return `The meal plan selected does not have any days assigned in the current calendar view.
    Select a date that this meal plan is assigned on to view the schedule.`;
};

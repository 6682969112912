import CodeIcon from "@mui/icons-material/CodeRounded";
import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { DevRoute } from "../ProtectedRoutes";
import { SecondaryNavLayout } from "../SecondaryNav/SecondaryNavLayout";
import { FontCheckPage } from "./FontCheckPage";
import { LibraryDemoPage } from "./LibraryDemoPage";
import { ThemeViewerPage } from "./ThemeViewerPage";

// full page path
export const NAV_ORG_DEV = "/org/dev";
export const NAV_STAFF_DEV = "/staff/dev";

// local child paths
export const NAV_DEV_COMPONENT_LIBRARY = "component-library";
export const NAV_DEV_THEME_VIEWER = "theme-viewer";
export const NAV_DEV_FONT_CHECK = "font-check";

// full child paths
export const NAV_ORG_DEV_COMPONENT_LIBRARY = `${NAV_ORG_DEV}/${NAV_DEV_COMPONENT_LIBRARY}`;
export const getNavOrgDevComponentLibraryComponent = (componentName: string) => `${NAV_ORG_DEV_COMPONENT_LIBRARY}/${componentName}`;
export const NAV_STAFF_DEV_COMPONENT_LIBRARY = `${NAV_STAFF_DEV}/${NAV_DEV_COMPONENT_LIBRARY}`;
export const getNavStaffDevComponentLibraryComponent = (componentName: string) => `${NAV_STAFF_DEV_COMPONENT_LIBRARY}/${componentName}`;

export const orgDevLink = {
  to: NAV_ORG_DEV,
  name: "Dev",
  icon: <CodeIcon />,
};

export const staffDevLink = {
  to: NAV_STAFF_DEV,
  name: "Dev",
  icon: <CodeIcon />,
};

const secondaryNavLayoutProps = (org: boolean) => ({
  current: org ? orgDevLink : staffDevLink,
  enableAthleteSearch: true,
  tabs: [
    {
      name: "Theme Viewer",
      to: NAV_DEV_THEME_VIEWER,
    },
    {
      name: "Component Library",
      to: NAV_DEV_COMPONENT_LIBRARY,
    },
    {
      name: "Font Check",
      to: NAV_DEV_FONT_CHECK,
    },
  ],
});

export interface DevRouterProps {
  org: boolean;
}

export const DevRouter = ({ org }: DevRouterProps) => (
  <Routes>
    <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps(org)} />}>
      <Route element={<DevRoute />}>
        <Route index element={<ThemeViewerPage />} />
        <Route path={NAV_DEV_THEME_VIEWER} element={<ThemeViewerPage />} />
        <Route path={NAV_DEV_COMPONENT_LIBRARY} element={<LibraryDemoPage org={org} />} />
        <Route path={`${NAV_DEV_COMPONENT_LIBRARY}/:componentName?`} element={<LibraryDemoPage org={org} />} />
        <Route path={NAV_DEV_FONT_CHECK} element={<FontCheckPage />} />
      </Route>
    </Route>
  </Routes>
);

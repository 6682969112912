import DeleteIcon from "@mui/icons-material/Close";
import { IconButton, List, ListItem, ListItemSecondaryAction, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
import { MealMenuInstance } from "../types";
import BulkCopyCalendar from "./BulkCopyCalendar";
import { getFormattedAnchorInterval, sortAndGroupByDate } from "./bulkCopyHelpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bulkCopyContainer: {
      display: "flex",
      width: 850,
      height: 470,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    left: {
      marginRight: theme.spacing(2),
      flexBasis: "40%",
      justifyContent: "stretch",
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(3),
    },
    calendarContainer: {
      backgroundColor: "white",
      padding: theme.spacing(1),
    },
    rightContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginLeft: theme.spacing(3),
    },
    rightTop: {
      paddingBottom: theme.spacing(3),
    },
    rightBottom: {},
    list: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(0, 1, 0, 1),
      height: 180,
      overflow: "auto",
    },
    fullList: {
      height: 425,
    },
    anchorDateListItemContainer: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "white",
      padding: theme.spacing(1, 0, 1, 3),
      margin: theme.spacing(1, 0, 0, 0),
    },
    mealMenuListItem: {
      padding: theme.spacing(0, 0, 0, 2),
      margin: theme.spacing(1),
    },
    columnText: {
      paddingBottom: theme.spacing(2),
    },
    boldColumnText: {
      fontWeight: "bold",
      paddingBottom: theme.spacing(2),
    },
  })
);

interface BulkCopyContentProps {
  mealMenuInstances: readonly MealMenuInstance[];
  anchorDates: Date[];
  onAddAnchor: (newAnchorDate: Date) => void;
  onDeleteAnchor: (anchorDate: Date) => void;
  clientTimezone: string;
}

const BulkCopyContent = ({ mealMenuInstances, anchorDates, onAddAnchor, onDeleteAnchor, clientTimezone }: BulkCopyContentProps) => {
  const classes = useStyles();
  const groupedDates = sortAndGroupByDate(mealMenuInstances);
  const hasAnchorDates = !!anchorDates.length;

  return (
    <div className={classes.bulkCopyContainer}>
      <div className={classes.left}>
        <Typography className={classes.columnText}>Use the calendar to select dates to apply your selection.</Typography>
        <div className={classes.calendarContainer}>
          <BulkCopyCalendar
            groupedDates={groupedDates}
            anchorDates={anchorDates}
            onAddAnchor={onAddAnchor}
            clientTimezone={clientTimezone}
          />
        </div>
      </div>
      <div className={classes.rightContainer}>
        {hasAnchorDates && (
          <div className={classes.rightTop}>
            <Typography className={classes.boldColumnText}>Selected Dates:</Typography>
            <List dense className={classes.list}>
              {anchorDates
                .sort((a, b) => a.getTime() - b.getTime())
                .map(anchorDate => (
                  <ListItem className={classes.anchorDateListItemContainer} key={anchorDate.getDate()}>
                    <Typography>{getFormattedAnchorInterval(anchorDate, mealMenuInstances)}</Typography>
                    <ListItemSecondaryAction>
                      <IconButton size="small" onClick={() => onDeleteAnchor(anchorDate)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </div>
        )}
        <div className={classes.rightBottom}>
          <Typography className={classes.boldColumnText}>Selected Menus:</Typography>
          <List
            dense
            className={classNames(classes.list, {
              [classes.fullList]: !hasAnchorDates,
            })}
          >
            {[...groupedDates.values()].map(groupedMealMenuInstance => {
              return (
                <div className={classes.mealMenuListItem} key={groupedMealMenuInstance.displayDate}>
                  <Typography>{groupedMealMenuInstance.displayDate}</Typography>
                  <List dense>
                    {groupedMealMenuInstance.mealMenuInstances.map(mm => (
                      <ListItem key={mm.id}>
                        <Typography>{`\u2022 ${mm.name}`}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default BulkCopyContent;

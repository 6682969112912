import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import DialogTitle from "../../componentLibrary/DialogTitle";

export type ChangeType = "added" | "removed" | "both";

const REMOVED_DINING_STATION_MSG = "A user on a different device removed a dining station from a currently selected menu.";
const RESELECT_MSG = "You may want to re-select dining stations to include the new station.";
const ADDED_DINING_STATION_MSG = ` A user on a different device added a dining station to a currently selected menu. ${RESELECT_MSG}`;
const ADDED_AND_REMOVED_DINING_STATION_MSG = `A user on a different device added and removed a dining stations from at least one currently selected menu. ${RESELECT_MSG} `;

interface NewDiningStationDialogProps {
  changeType: ChangeType;
  goToDiningStations: () => void;
  onClose: () => void;
}

const DiningStationsChangedDialog = ({ changeType, onClose, goToDiningStations }: NewDiningStationDialogProps) => {
  const message = (changeType: ChangeType) => {
    switch (changeType) {
      case "added":
        return ADDED_DINING_STATION_MSG;
      case "removed":
        return REMOVED_DINING_STATION_MSG;
      case "both":
        return ADDED_AND_REMOVED_DINING_STATION_MSG;
      default:
        return "";
    }
  };

  return (
    <Dialog open={changeType !== null} onClose={onClose}>
      <DialogTitle title="Dining Stations Updated" onClose={onClose} />
      <DialogContent>
        <DialogContentText>{message(changeType)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Dismiss
        </Button>
        {changeType !== "removed" && (
          <Button
            onClick={() => {
              onClose();
              goToDiningStations();
            }}
          >
            Select Dining Stations
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DiningStationsChangedDialog;

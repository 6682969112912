import { useApolloClient } from "@apollo/client";
import { scaleTheRecipeWithYield } from "apps/web/src/components/Recipe/Form/utils";
import RecipeCreateContent from "apps/web/src/components/Recipe/RecipeCreateContent";
import { getRecipeName, recipeToFormState } from "apps/web/src/components/Recipe/utils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import { RecipeFullFragment, useRecipeDuplicateDialogQuery } from "apps/web/src/types";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

const RecipeCreatePage = () => {
  const { setMessage } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { duplicatedRecipeId, isDuplicate, newYield } = location.state || {};
  const apolloClient = useApolloClient();

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "recipeOffsetConnection",
      broadcast: true,
    });
  };

  const onSuccess = (obj: RecipeFullFragment) => {
    setMessage("success", `Successfully created Recipe ${obj.name}`);
    resetTable();
    navigate(-1);
  };

  const { data, loading } = useRecipeDuplicateDialogQuery({
    variables: {
      id: duplicatedRecipeId,
    },
  });

  const onClose = () => {
    navigate(-1);
  };

  const getInitialRecipeState = () => {
    if (!data) {
      return undefined;
    }

    let state = recipeToFormState(data.recipe);

    if (isDuplicate) {
      return { ...state, name: getRecipeName(state.name, isDuplicate) };
    }

    if (newYield && newYield > 0) {
      state = scaleTheRecipeWithYield(newYield, state);
      delete state.id;
    }

    return { ...state, name: getRecipeName(state?.name, isDuplicate) };
  };

  const isLoading = duplicatedRecipeId && (loading || !data);

  return isLoading ? (
    <LoadingBackdrop open={isLoading} onClose={onClose} />
  ) : (
    <RecipeCreateContent
      forStaff={true}
      onClose={onClose}
      onCreate={thing => onSuccess(thing)}
      initialRecipeState={getInitialRecipeState()}
    />
  );
};

export default RecipeCreatePage;

import { Button, List, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Idable } from "../../../../../views/Staff/GeneralizedStaffContent/types";
import FilterCheckListItem from "./FilterCheckListItem";
import { getItemList, hasShowMoreButton } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(-1),
    },
  })
);

interface FilterCheckListProps<T extends Idable> {
  onFilterChange: (item: T, isSelected: boolean) => void;
  selectedFilters: T[];
  visibleFilters: T[];
  getLabel: (item: T) => string;
  isOpen: boolean;
  hasShowMore?: boolean;
  showMoreLimit?: number;
  disabled?: boolean;
}

const FilterCheckList = <T extends Idable>({
  onFilterChange,
  selectedFilters,
  visibleFilters,
  getLabel,
  isOpen,
  hasShowMore = true,
  showMoreLimit = 2,
  disabled = false,
}: FilterCheckListProps<T>) => {
  const classes = useStyles();
  const selectedFilterIds = new Set(selectedFilters.map(item => item.id));

  const [showMoreOpen, setShowMoreOpen] = useState(false);

  const renderListItem = (item: T) => {
    const label = getLabel(item);
    return (
      <FilterCheckListItem
        key={`filter-for-${label}`}
        label={label}
        isSelected={selectedFilterIds.has(item.id)}
        onChange={isSelected => onFilterChange(item, isSelected)}
        disabled={disabled}
      />
    );
  };

  const itemList = getItemList({
    hasShowMore,
    isOpen,
    showMoreOpen,
    showMoreLimit,
    visibleFilters,
    selectedFilters,
  });

  const showShowMoreButton = hasShowMoreButton({
    hasShowMore,
    showMoreLimit,
    visibleFilters,
    selectedFilters,
  });

  // TODO: Add some expand transition
  return (
    <div>
      <List disablePadding>{itemList.map(renderListItem)}</List>
      {isOpen && showShowMoreButton && (
        <Button
          size="small"
          onClick={() => setShowMoreOpen(!showMoreOpen)}
          component="div"
          className={classes.button}>
          {showMoreOpen ? "Show Less" : "Show More"}
        </Button>
      )}
    </div>
  );
};

export default FilterCheckList;

import { EditImperialMacroProtocolState, EditMacroProtocolAction } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import React, { Dispatch } from "react";
import { GoalTypeFragment } from "../../../types";
import { MacroProtocolForm } from "./MacroProtocolForm";

interface ImperialMacroProtocolFormProps {
  state: EditImperialMacroProtocolState;
  dispatch: Dispatch<EditMacroProtocolAction>;
  goalTypes: readonly GoalTypeFragment[];
}

export const ImperialMacroProtocolForm = ({ state, dispatch, goalTypes }: ImperialMacroProtocolFormProps) => {
  return <MacroProtocolForm
    state={state}
    dispatch={dispatch}
    goalTypes={goalTypes} />;
};

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Card, CardActionArea, CardContent, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

export interface AnthropometryMetricCardArgs {
  name: string;
  metricText: string;

  metricTrendText: string;
  tooltipExplanation?: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      color: theme.palette.success.main,
      display: "flex",
      flexDirection: "column",
      lineHeight: 1.2,
    },
    red: {
      color: theme.palette.error.main,
      display: "flex",
      flexDirection: "column",
      lineHeight: 1.2,
    },
    grey: {
      color: theme.palette.grey[500],
      display: "flex",
      flexDirection: "column",
      lineHeight: 1.2,
    },
    plusOrMinus: {
      paddingLeft: "3px",
      color: theme.palette.grey[500],
      lineHeight: 1.2,
    },
    avgBorderTop: {
      borderTop: `solid 2px`,
    },
    colFlexCard: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "6px",
    },
    rowFlexCard: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "4px 8px 4px 8px",
    },
    arrowAndMetric: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    shortCard: {
      height: "45px",
    },
  })
);

export const AnthropometryMetricCard = ({ tooltipExplanation, name, metricText, metricTrendText }: AnthropometryMetricCardArgs) => {
  const positive = Number(metricText) > 0;
  const negative = Number(metricText) < 0;
  const classes = useStyles();
  return (
    <Tooltip title={tooltipExplanation || ""}>
      <Card raised className={classes.shortCard}>
        <CardActionArea>
          <CardContent className={classes.rowFlexCard}>
            <Typography
              variant="h3"
              align="center"
              classes={{ root: classes.avgBorderTop }}>
              {name}
            </Typography>
            <div className={classes.colFlexCard}>
              <div className={classes.arrowAndMetric}>
                <Typography
                  align="center"
                  variant="h3"
                  classes={{
                    root: positive ? classes.green : negative ? classes.red : classes.grey,
                  }}
                >
                  {positive ? <ArrowUpwardIcon /> : negative ? <ArrowDownwardIcon /> : null}
                </Typography>
                <Typography
                  align="center"
                  variant="h3"
                  classes={{
                    root: positive ? classes.green : negative ? classes.red : classes.grey,
                  }}
                >
                  {metricText}
                </Typography>
              </div>
              <Typography
                className={classes.plusOrMinus}
                align="center"
                variant="subtitle1">
                {metricTrendText}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

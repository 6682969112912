import React, { createContext, useContext, useMemo } from "react";
import { FoodPreviewFragment, FullFoodGroupWithFoodsFragment } from "../types";
import { PreferenceFoodFunction, PreferenceFoodInfo, combineFoodsAndFoodGroupsIntoIndex } from "./utils";

interface MealPlanFoodPreferenceContextProps {
  children: React.ReactNode;
  promotedFoods: readonly FoodPreviewFragment[];
  avoidedFoods: readonly FoodPreviewFragment[];
  promotedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
  avoidedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
}

export interface MealPlanFoodPreferenceContextType {
  isPromotedFood: PreferenceFoodFunction;
  isAvoidedFood: PreferenceFoodFunction;
  promotedFoods: readonly FoodPreviewFragment[];
  avoidedFoods: readonly FoodPreviewFragment[];
  promotedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
  avoidedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
}

const MealPlanFoodPreferenceContext = createContext<MealPlanFoodPreferenceContextType>({
  isPromotedFood: () => ({
    value: false,
    reason: [],
  }),
  isAvoidedFood: () => ({
    value: false,
    reason: [],
  }),
  promotedFoods: [],
  avoidedFoods: [],
  promotedFoodGroups: [],
  avoidedFoodGroups: [],
});

export const MealPlanFoodPreferenceContextProvider = ({
  children,
  promotedFoods,
  avoidedFoods,
  promotedFoodGroups,
  avoidedFoodGroups,
}: MealPlanFoodPreferenceContextProps) => {
  const value = useMemo(() => {
    const promotedFoodsIndex = combineFoodsAndFoodGroupsIntoIndex(promotedFoods, promotedFoodGroups);

    const avoidedFoodsIndex = combineFoodsAndFoodGroupsIntoIndex(avoidedFoods, avoidedFoodGroups);

    return {
      isPromotedFood: (foodId: string): PreferenceFoodInfo => {
        const promotedFoodsForFood = [...(promotedFoodsIndex[foodId] || [])];
        return {
          value: promotedFoodsForFood.length > 0,
          reason: promotedFoodsForFood.sort(),
        };
      },
      isAvoidedFood: (foodId: string): PreferenceFoodInfo => {
        const avoidedFoodsForFood = [...(avoidedFoodsIndex[foodId] || [])];
        return {
          value: avoidedFoodsForFood.length > 0,
          reason: avoidedFoodsForFood.sort(),
        };
      },
      promotedFoods,
      avoidedFoods,
      promotedFoodGroups,
      avoidedFoodGroups,
    };
  }, [
    promotedFoods
      .map(f => f.id)
      .sort()
      .join(","),
    avoidedFoods
      .map(f => f.id)
      .sort()
      .join(","),
    promotedFoodGroups
      .map(fg => fg.id)
      .sort()
      .join(","),
    avoidedFoodGroups
      .map(fg => fg.id)
      .sort()
      .join(","),
    promotedFoodGroups
      .flatMap(fg => fg.foods.map(f => f.id))
      .sort()
      .join(","),
    avoidedFoodGroups
      .flatMap(fg => fg.foods.map(f => f.id))
      .sort()
      .join(","),
  ]);

  return <MealPlanFoodPreferenceContext.Provider value={value}>{children}</MealPlanFoodPreferenceContext.Provider>;
};

export const useMealPlanFoodPreferenceContext = () => {
  return useContext(MealPlanFoodPreferenceContext);
};

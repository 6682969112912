import { FormControl, Switch, TableCell, Tooltip } from "@mui/material";
import React from "react";

interface ShareTableCellProps {
  mealPlanTemplateId: string;
  isShared: boolean;
  updateIsShared: (mealPlanTemplateId: string, isShared: boolean) => void;
}

const ShareTableCell = ({ mealPlanTemplateId, isShared, updateIsShared }: ShareTableCellProps) => {
  return (
    <TableCell>
      <Tooltip title={"Share meal plan template with org"}>
        <FormControl>
          <Switch
            size="medium"
            inputProps={{
              "aria-label": "Share meal plan template with org",
            }}
            checked={isShared}
            onClick={e => e.stopPropagation()}
            onChange={e => updateIsShared(mealPlanTemplateId, e.target.checked)}
          />
        </FormControl>
      </Tooltip>
    </TableCell>
  );
};

export default ShareTableCell;

import React, { useState } from "react";
import BulkCopyContent from "../BulkCopyContent";
import { addAnchorDate, groupedDatesToBulkCopyInput, sortAndGroupByDate } from "../bulkCopyHelpers";
import { BaseBulkEditProps, BaseContentProps } from "../types";
import BaseContent from "./BaseContent";

interface CopyContentProps extends BaseContentProps {
  onBulkCopy: BaseBulkEditProps["onBulkCopy"];
  clientTimezone: BaseBulkEditProps["clientTimezone"];
}

const CopyContent = ({ isOpen, onBack, onCancel, onBulkCopy, mealMenuInstances, saving, clientTimezone }: CopyContentProps) => {
  const [anchorDates, setAnchorDates] = useState<Date[]>([]);
  const onAddAnchor = (anchorDate: Date) => setAnchorDates(addAnchorDate(anchorDate, anchorDates, mealMenuInstances));
  const onDeleteAnchor = (anchorDate: Date) =>
    setAnchorDates([...anchorDates.filter(date => date.toISOString() !== anchorDate.toISOString())]);
  return (
    <BaseContent
      isOpen={isOpen}
      onCancel={onCancel}
      saving={saving}
      onSubmit={async () => {
        const input = groupedDatesToBulkCopyInput(sortAndGroupByDate(mealMenuInstances), anchorDates);
        await onBulkCopy(input);
        onBack();
        return;
      }}
      title={`Copy ${mealMenuInstances.length} Meal Menu(s)`}
      renderContent={() => (
        <BulkCopyContent
          mealMenuInstances={mealMenuInstances}
          anchorDates={anchorDates}
          onAddAnchor={onAddAnchor}
          onDeleteAnchor={onDeleteAnchor}
          clientTimezone={clientTimezone}
        />
      )}
      submitDisabled={mealMenuInstances.length === 0 || saving || anchorDates.length === 0}
    />
  );
};

export default CopyContent;

import { DataProxy, FetchResult } from "@apollo/client";
import {
  CreateSharedFoodGroupMutation,
  EditSharedFoodGroupMutation,
  SharedFoodGroupsQuery,
  SharedFoodGroupsDocument,
  FoodGroupsDocument,
  FullFoodGroupFragment,
  OrgFoodGroupsDocument,
  OrgFoodGroupsQuery,
  FoodGroupsQuery,
  EditOrgFoodGroupMutation,
  CreateOrgFoodGroupMutation,
} from "../../types";
import { safeReadQuery } from "../../client";
import { EntityScope } from "../../utils/scope";
import { update } from "../../utils/remove";

export const DEFAULT_LIMIT = 25;

export interface UpdateCacheCreateFoodGroup {
  cache: DataProxy;
  mutationResult: FetchResult<CreateSharedFoodGroupMutation | CreateOrgFoodGroupMutation>;
}

export interface UpdateCacheEditFoodGroup {
  cache: DataProxy;
  mutationResult: FetchResult<EditSharedFoodGroupMutation | EditOrgFoodGroupMutation>;
}

export interface MakeUpdateFoodGroupCache {
  scope: EntityScope;
}

export const makeUpdateCacheCreateFoodGroup = ({ scope }: MakeUpdateFoodGroupCache) => {
  if (scope === "shared") {
    const updateCacheCreateFoodGroup = ({ cache, mutationResult }: UpdateCacheCreateFoodGroup): void => {
      const castedResult = mutationResult as FetchResult<CreateSharedFoodGroupMutation>;
      if (castedResult.data) {
        addToSharedFoodGroupsQuery(cache, castedResult.data.createSharedFoodGroup.foodGroup);
        addToFoodGroupsQuery(cache, castedResult.data.createSharedFoodGroup.foodGroup);
      }
    };
    return updateCacheCreateFoodGroup;
  } else {
    const updateCacheCreateFoodGroup = ({ cache, mutationResult }: UpdateCacheCreateFoodGroup): void => {
      const castedResult = mutationResult as FetchResult<CreateOrgFoodGroupMutation>;
      if (castedResult.data) {
        addToOrgFoodGroupsQuery(cache, castedResult.data.createOrgFoodGroup.foodGroup);
        addToFoodGroupsQuery(cache, castedResult.data.createOrgFoodGroup.foodGroup);
      }
    };
    return updateCacheCreateFoodGroup;
  }
};

const addToSharedFoodGroupsQuery = (cache: DataProxy, createdFoodGroup: FullFoodGroupFragment): void => {
  const foodGroupsQuery = safeReadQuery<SharedFoodGroupsQuery>(cache, {
    query: SharedFoodGroupsDocument,
    variables: { searchTerm: "", limit: DEFAULT_LIMIT },
  });
  if (foodGroupsQuery) {
    cache.writeQuery<SharedFoodGroupsQuery>({
      query: SharedFoodGroupsDocument,
      data: {
        ...foodGroupsQuery,
        sharedFoodGroups: [...foodGroupsQuery.sharedFoodGroups, createdFoodGroup],
      },
      variables: { searchTerm: "", limit: DEFAULT_LIMIT },
    });
  }
};
const addToOrgFoodGroupsQuery = (cache: DataProxy, createdFoodGroup: FullFoodGroupFragment): void => {
  const foodGroupsQuery = safeReadQuery<OrgFoodGroupsQuery>(cache, {
    query: OrgFoodGroupsDocument,
    variables: { searchTerm: "", limit: DEFAULT_LIMIT },
  });
  if (foodGroupsQuery) {
    cache.writeQuery<OrgFoodGroupsQuery>({
      query: OrgFoodGroupsDocument,
      data: {
        ...foodGroupsQuery,
        orgFoodGroups: [...foodGroupsQuery.orgFoodGroups, createdFoodGroup],
      },
      variables: { searchTerm: "", limit: DEFAULT_LIMIT },
    });
  }
};
const addToFoodGroupsQuery = (cache: DataProxy, createdFoodGroup: FullFoodGroupFragment): void => {
  const foodGroupsQuery = safeReadQuery<FoodGroupsQuery>(cache, {
    query: FoodGroupsDocument,
    variables: { searchTerm: "", limit: DEFAULT_LIMIT },
  });
  if (foodGroupsQuery) {
    cache.writeQuery<FoodGroupsQuery>({
      query: FoodGroupsDocument,
      data: {
        ...foodGroupsQuery,
        foodGroups: [...foodGroupsQuery.foodGroups, createdFoodGroup],
      },
      variables: { searchTerm: "", limit: DEFAULT_LIMIT },
    });
  } else {
    cache.writeQuery<FoodGroupsQuery>({
      query: FoodGroupsDocument,
      data: { foodGroups: [createdFoodGroup] },
      variables: { searchTerm: "", limit: DEFAULT_LIMIT },
    });
  }
};

/* END CREATE */

/* BEGIN EDIT */

export const makeUpdateCacheEditFoodGroup = ({ scope }: MakeUpdateFoodGroupCache) => {
  if (scope === "shared") {
    const updateCacheEditFoodGroup = ({ cache, mutationResult }: UpdateCacheEditFoodGroup): void => {
      const castedResult = mutationResult as FetchResult<EditSharedFoodGroupMutation>;
      if (castedResult.data) {
        updateFoodGroupsQuery(cache, castedResult.data.editSharedFoodGroup.foodGroup);
        updateSharedFoodGroupsQuery(cache, castedResult.data.editSharedFoodGroup.foodGroup);
      }
    };
    return updateCacheEditFoodGroup;
  } else {
    const updateCacheEditFoodGroup = ({ cache, mutationResult }: UpdateCacheEditFoodGroup): void => {
      const castedResult = mutationResult as FetchResult<EditOrgFoodGroupMutation>;
      if (castedResult.data) {
        updateFoodGroupsQuery(cache, castedResult.data.editOrgFoodGroup.foodGroup);
        updateOrgFoodGroupsQuery(cache, castedResult.data.editOrgFoodGroup.foodGroup);
      }
    };
    return updateCacheEditFoodGroup;
  }
};

const updateSharedFoodGroupsQuery = (cache: DataProxy, updatedFoodGroup: FullFoodGroupFragment) => {
  const foodGroupsQuery: SharedFoodGroupsQuery | null = cache.readQuery({
    query: SharedFoodGroupsDocument,
    variables: { searchTerm: "", limit: DEFAULT_LIMIT },
  });
  if (foodGroupsQuery) {
    const idx = foodGroupsQuery.sharedFoodGroups.findIndex(b => b.id === updatedFoodGroup.id);
    const { id, name, source, foodsCount, __typename } = updatedFoodGroup;
    cache.writeQuery<SharedFoodGroupsQuery>({
      query: FoodGroupsDocument,
      data: {
        ...foodGroupsQuery,
        sharedFoodGroups: update(foodGroupsQuery.sharedFoodGroups, idx, {
          id,
          name,
          source,
          foodsCount,
          __typename,
        }),
      },
      variables: { searchTerm: "", limit: DEFAULT_LIMIT },
    });
  }
};

const updateOrgFoodGroupsQuery = (cache: DataProxy, updatedFoodGroup: FullFoodGroupFragment) => {
  const foodGroupsQuery: OrgFoodGroupsQuery | null = cache.readQuery({
    query: OrgFoodGroupsDocument,
    variables: { searchTerm: "", limit: DEFAULT_LIMIT },
  });
  if (foodGroupsQuery) {
    const idx = foodGroupsQuery.orgFoodGroups.findIndex(b => b.id === updatedFoodGroup.id);
    const { id, name, source, foodsCount, __typename } = updatedFoodGroup;
    cache.writeQuery<OrgFoodGroupsQuery>({
      query: OrgFoodGroupsDocument,
      data: {
        ...foodGroupsQuery,
        orgFoodGroups: update(foodGroupsQuery.orgFoodGroups, idx, {
          id,
          name,
          source,
          foodsCount,
          __typename,
        }),
      },
      variables: { searchTerm: "", limit: DEFAULT_LIMIT },
    });
  }
};

const updateFoodGroupsQuery = (cache: DataProxy, updatedFoodGroup: FullFoodGroupFragment) => {
  const foodGroupsQuery: FoodGroupsQuery | null = cache.readQuery({
    query: FoodGroupsDocument,
    variables: { searchTerm: "", limit: DEFAULT_LIMIT },
  });
  if (foodGroupsQuery) {
    const idx = foodGroupsQuery.foodGroups.findIndex(b => b.id === updatedFoodGroup.id);
    const { id, name } = updatedFoodGroup;
    cache.writeQuery<FoodGroupsQuery>({
      query: FoodGroupsDocument,
      data: {
        ...foodGroupsQuery,
        foodGroups: update(foodGroupsQuery.foodGroups, idx, {
          id,
          name,
        }),
      },
      variables: { searchTerm: "", limit: DEFAULT_LIMIT },
    });
  }
};

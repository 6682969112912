import { Box, Checkbox, FormControlLabel, Radio, Typography } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { serializeDate, today } from "@notemeal/shared/ui/utils/dateTimes";
import React, { Dispatch, useState } from "react";
import { StartDateInPastBehavior } from ".";
import DayOfWeekSelector from "../DayOfWeekSelector";
import { MealPlanDateAssignmentAction } from "../reducer";
import { MealPlanDateAssignment } from "../types";

interface MealPlanDateAssignmentModeFormProps {
  state: MealPlanDateAssignment;
  dispatch: Dispatch<MealPlanDateAssignmentAction>;
  calendarLocation: "below" | "on left";
  startDateInPastBehavior: StartDateInPastBehavior;
  size?: "small" | "medium";
}

const MealPlanDateAssignmentModeForm = ({
  state,
  dispatch,
  startDateInPastBehavior,
  calendarLocation,
  size,
}: MealPlanDateAssignmentModeFormProps) => {
  const [startDateErrorMessage, setStartDateErrorMessage] = useState<string | null>("");
  const [endDateErrorMessage, setEndDateErrorMessage] = useState<string | null>("");

  const startDateProps: Partial<DesktopDatePickerProps<Date>> =
    startDateInPastBehavior === "error"
      ? {
          disablePast: true,
        }
      : {
          disabled: true,
        };

  return (
    <>
      <Typography variant={size === "small" ? "body2" : "body1"} color="textSecondary">
        {state.mode === "weekly"
          ? "Select the days from the options listed below"
          : `Select dates from the calendar displayed ${calendarLocation}`}
      </Typography>
      <FormControlLabel
        control={
          <Radio
            size="small"
            checked={state.mode === "weekly"}
            onChange={() =>
              dispatch({
                type: "EditDateAssignmentMode",
                payload: {
                  mode: "weekly",
                },
              })
            }
          />
        }
        sx={{ pl: 1.5 }}
        label="Weekly"
        labelPlacement="end"
      />
      {state.mode === "weekly" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pl: 4 }}>
          <DayOfWeekSelector
            daysOfWeek={state.dayOfWeekPriorities.map(d => d.dayOfWeek)}
            onToggleDayOfWeek={dayOfWeek =>
              dispatch({
                type: "ToggleDayOfWeekAssignment",
                payload: { dayOfWeek },
              })
            }
            sx={{ width: "100%", justifyContent: "left" }}
          />
          <DesktopDatePicker
            sx={{ mt: 3, width: "220px" }}
            label="Start Date*"
            onChange={date => {
              if (!date) {
                setStartDateErrorMessage("Invalid Start Date");
              } else if (startDateInPastBehavior && date < today) {
                setStartDateErrorMessage("Start Date cannot be in the past");
              } else {
                setStartDateErrorMessage(null);
              }
              dispatch({
                type: "EditStartDate",
                payload: {
                  date,
                },
              });
            }}
            slotProps={{
              textField: {
                helperText: startDateErrorMessage,
                error: startDateErrorMessage ? true : false,
              },
            }}
            value={state.startDateRaw}
            {...startDateProps}
          />
          <DesktopDatePicker
            sx={{ mt: 3, width: "220px" }}
            label="End Date"
            onChange={date => {
              if (!date) {
                setEndDateErrorMessage("Invalid End Date");
              } else if (date < today) {
                setEndDateErrorMessage("End Date cannot be in the past");
              } else if (serializeDate(date) < state.startDate) {
                setEndDateErrorMessage("End Date cannot not be before Start Date");
              } else {
                setEndDateErrorMessage(null);
              }
              dispatch({
                type: "EditEndDate",
                payload: {
                  date,
                },
              });
            }}
            minDate={state.startDateRaw}
            slotProps={{
              textField: {
                helperText: endDateErrorMessage,
                error: endDateErrorMessage ? true : false,
              },
            }}
            disabled={state.disableEndDate}
            value={state.endDateRaw}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.disableEndDate}
                onChange={() => {
                  dispatch({ type: "ToggleEndDate", payload: { disableEndDate: !state.disableEndDate } });
                  setEndDateErrorMessage(null);
                }}
              />
            }
            label="No end date"
          />
        </Box>
      )}
      <FormControlLabel
        control={
          <Radio
            size="small"
            checked={state.mode === "individual"}
            onChange={() =>
              dispatch({
                type: "EditDateAssignmentMode",
                payload: {
                  mode: "individual",
                },
              })
            }
          />
        }
        sx={{ pl: 1.5 }}
        label="Individual Dates"
        labelPlacement="end"
      />
    </>
  );
};

export default MealPlanDateAssignmentModeForm;

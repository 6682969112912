import { Box, Button, TextField, Typography } from "@mui/material";
import { sendPasswordResetLink } from "@notemeal/shared/ui/utils/tokens";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

enum PageState {
  ProvideUsername,
  Finished,
}

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [pageState, setPageState] = useState<PageState>(PageState.ProvideUsername);

  const handleChangeUsername = (username: string) => {
    setUsername(username);
    setError("");
  };

  const handleSendUsername = () => {
    sendPasswordResetLink({ username })
      .then(() => {
        setPageState(PageState.Finished);
      })
      .catch(e => {
        setError(e.response?.data?.errors[0]);
      });
  };

  const provideUsername = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">Forgot password?</Typography>
        <Typography variant="subtitle1">Enter the username associated with your account.</Typography>
      </Box>
      <TextField
        label="Username"
        placeholder="john.doe"
        error={!!error}
        helperText={error}
        value={username}
        onChange={e => handleChangeUsername(e.target.value)}
      />
      <Button disabled={!username} onClick={handleSendUsername}>
        Send Link
      </Button>
    </>
  );

  const finished = (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h1">Check email or phone</Typography>
        <Typography variant="subtitle1">Check your email or phone for the link to reset the password.</Typography>
      </Box>
      <Button onClick={() => navigate(-1)}>Back to Login</Button>
    </>
  );

  return pageState === PageState.ProvideUsername ? provideUsername : finished;
};

import { List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom-v5-compat";
import NotificationCountLabel from "../../components/Notification/CountLabel";
import { getNavOrgAthlete, getNavOrgAthleteFoodLogs } from "../../pages/Auth/Org/Athlete/AthletePaths";
import { TeamPageAthleteFragment } from "../../types";

interface TeamRosterListProps {
  athletes: readonly TeamPageAthleteFragment[];
}

export const TeamRosterList = ({ athletes }: TeamRosterListProps) => {
  return (
    <List sx={{ overflowY: "auto", flexGrow: 1, flexShrink: 1 }}>
      {athletes.map(athlete => {
        return (
          <ListItemButton
            sx={{ display: "flex", alignItems: "center", pr: 2 }}
            component={Link}
            to={athlete.timelineMealNotifications.length > 0 ? getNavOrgAthleteFoodLogs(athlete.id) : getNavOrgAthlete(athlete.id)}
            key={athlete.id}
          >
            <Typography>{athlete.lastName + ", " + athlete.firstName}</Typography>
            <NotificationCountLabel max={9} notifications={athlete.timelineMealNotifications} />
          </ListItemButton>
        );
      })}
    </List>
  );
};

import { MenuItem, SxProps, TextField } from "@mui/material";
import React from "react";

const LAST_ORDER_DAY_OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7];
const NEXT_DAY_LAST_ORDER_DAY_OPTIONS = [-1, ...LAST_ORDER_DAY_OPTIONS];

interface MenuLastOrderDaySelectProps {
  value: number;
  onChange: (value: number) => void;
  showNextDay: boolean;
  sx?: SxProps;
}

const MenuLastOrderDaySelect = ({ onChange, value, sx, showNextDay }: MenuLastOrderDaySelectProps) => {
  const getMenuItemText = (value: number) => {
    if (value === -1) {
      return "Next Day (End Day)";
    } else if (value === 0) {
      return showNextDay ? "Same Day (Start Day)" : "Same Day";
    } else if (value === 1) {
      return "1 day before";
    } else {
      return `${value} days before`;
    }
  };

  const handleChange = (stringValue: string) => {
    const value = parseInt(stringValue);
    if (!isNaN(value)) {
      onChange(value);
    }
  };

  const dayOptions = showNextDay ? NEXT_DAY_LAST_ORDER_DAY_OPTIONS : LAST_ORDER_DAY_OPTIONS;

  return (
    <TextField
      select
      label="Last Order Due"
      value={value}
      sx={sx}
      onChange={e => handleChange(e.target.value)}>
      {dayOptions.map(value => {
        return (
          <MenuItem key={value} value={value}>
            {getMenuItemText(value)}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default MenuLastOrderDaySelect;

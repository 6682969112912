import { Checkbox, FormControlLabel, SxProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      gap: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
    input: {
      marginLeft: theme.spacing(1),
      maxWidth: theme.spacing(8),
      "& input": {
        textAlign: "center",
      },
    },
  })
);

interface HubCheckInProps {
  isHubCheckInEnabled: boolean;
  onChange: (checked: boolean) => void;
  sx?: SxProps;
}

export const HubCheckIn = ({ isHubCheckInEnabled, onChange, sx }: HubCheckInProps) => {
  const classes = useStyles();

  const handleCheckboxOnChange = (checked: boolean) => {
    onChange(checked);
  };

  return (
    <div className={classes.wrapper}>
      <FormControlLabel
        label="Enable Kiosk Check-in"
        sx={sx}
        control={<Checkbox checked={isHubCheckInEnabled} onChange={e => handleCheckboxOnChange(e.currentTarget.checked)} />}
      />
    </div>
  );
};

import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React from "react";
import { useMenuBuilderContext } from "../MenuBuilderProvider";
import { MenuBuilderMealRowItemIndexedType, MenuBuilderMealRowItemType } from "./MenuBuilderMealSchema";
import { MenuBuilderRowItem } from "./MenuBuilderRowItem";

interface Props {
  removeRowItem: (index: number) => void;
  replaceRowItems: (newItems: MenuBuilderMealRowItemType[]) => void;
  rowItemsForDay: readonly MenuBuilderMealRowItemIndexedType[];
  setEditMenuItemId: (id: string) => void;
}

export const MenuBuilderRowItemList = ({ removeRowItem, replaceRowItems, rowItemsForDay, setEditMenuItemId }: Props) => {
  const { isEditable } = useMenuBuilderContext();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // require moving a few pixels before dragging so we don't prevent onClick
        distance: 8,
      },
    })
  );

  return (
    <DndContext
      // only allow dragging if the user is in edit mode
      sensors={isEditable ? sensors : []}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      // smoother movement than the default for sorting a list
      collisionDetection={closestCenter}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = rowItemsForDay.findIndex(rowItem => rowItem.menuItem.id === active.id);
          const overIndex = rowItemsForDay.findIndex(rowItem => rowItem.menuItem.id === over.id);
          replaceRowItems(arrayMove([...rowItemsForDay], activeIndex, overIndex));
        }
      }}
    >
      {/* vertical sorting strategy makes it handle different sized items better */}
      <SortableContext items={rowItemsForDay.map(rowItem => rowItem.menuItem.id)} strategy={verticalListSortingStrategy}>
        {rowItemsForDay.map(rowItem => {
          return (
            <MenuBuilderRowItem
              key={rowItem.menuItem.id}
              rowItem={rowItem}
              removeRowItem={removeRowItem}
              setEditMenuItemId={setEditMenuItemId}
            ></MenuBuilderRowItem>
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

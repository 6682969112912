import React, { useState } from "react";
import {
  AgreementFormFragment,
  useAddAgreementFormMutation,
  useEditAgreementFormMutation,
  useStaffDashboardAgreementFormsQuery,
} from "../../../../types";
import { useOffsetPagination } from "../../../../utils/pagination";
import AgreementFormDialog from "../../../../views/Staff/AgreementForms/Dialog";
import AgreementFormTable from "../../../../views/Staff/AgreementForms/Table";
import { AgreementFormState } from "../../../../views/Staff/AgreementForms/reducer";

export const AgreementFormsPage = () => {
  const paginationHooks = useOffsetPagination();
  const { limit, offset, query } = paginationHooks;
  const { data, loading, refetch } = useStaffDashboardAgreementFormsQuery({
    variables: {
      input: { limit, offset },
      query,
    },
    fetchPolicy: "network-only",
  });

  const [addAgreementForm] = useAddAgreementFormMutation({
    onCompleted: () => refetch(),
  });
  const [editAgreementForm] = useEditAgreementFormMutation({
    onCompleted: () => refetch(),
  });

  const handleAddAgreementForm = async (state: AgreementFormState) => {
    addAgreementForm({ variables: { input: state } });
  };
  const handleEditAgreementForm = async (state: AgreementFormFragment) => {
    editAgreementForm({ variables: { input: state } });
  };

  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [formForEditModal, setFormForEditModal] = useState<AgreementFormFragment | null>(null);

  return (
    <>
      <AgreementFormTable
        offsetConnectionData={data}
        loading={loading}
        paginationHooks={paginationHooks}
        onAddAgreementForm={() => setShowCreate(true)}
        onEditAgreementForm={setFormForEditModal}
      />
      {showCreate && (
        <AgreementFormDialog
          open={showCreate}
          onClose={() => setShowCreate(false)}
          onSave={handleAddAgreementForm}
          initAgreementForm={null}
        />
      )}
      {formForEditModal && (
        <AgreementFormDialog
          open={!!formForEditModal}
          onClose={() => setFormForEditModal(null)}
          onSave={input => handleEditAgreementForm({ ...input, id: formForEditModal.id })}
          initAgreementForm={formForEditModal}
        />
      )}
    </>
  );
};

import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { PlannedMenuSummaryFragment } from "apps/web/src/types";
import { differenceInWeeks, getDay } from "date-fns";
import { z } from "zod";

type PlannedMenuSummaryType = Omit<PlannedMenuSummaryFragment, "lockCreatedAt" | "lockCreatedBy">;

const dateRangeSchema = z
  .object({
    startDate: z.date({ required_error: "Start date is required", invalid_type_error: "Start date is required" }),
    endDate: z.date({ required_error: "End date is required", invalid_type_error: "End date is required" }),
    // placeholder for errors that span both dates. react-hook-form won't add errors to the formState if they're not in the schema
    dateRangeError: z.undefined(),
    dayOfStartDate: z.number().optional(),
  })
  .superRefine((data, ctx) => {
    const { startDate, endDate, dayOfStartDate } = data;
    if (startDate > endDate) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["dateRangeError"], message: "End date must be after start date" });
    } else if (differenceInWeeks(endDate, startDate) > 11) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["dateRangeError"], message: "End date must be within 12 weeks of start date" });
    } else if (dayOfStartDate !== undefined && getDay(startDate) !== dayOfStartDate) {
      // when duplicating the user must select the same day of the week as the original menu
      ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["startDate"], message: "Start date must stay the same day of the week" });
    }
  });

export const PlannedMenuFormSchema = z.object({
  menuName: z.string({ required_error: "Name is required" }).min(1, { message: "Name is required" }),
  menuDescription: z.string().nullable(),
  // a nested object so we can validate across dates without the other fields being filled in yet
  dateRange: dateRangeSchema,
  occurrence: z.enum(["weekdays", "daily", "%future added value"], { required_error: "Occurrence is required" }),
  timezone: z.string({ required_error: "Timezone is required" }).min(1, { message: "Timezone is required" }),
});

export type PlannedMenuFormType = z.infer<typeof PlannedMenuFormSchema>;

export const plannedMenuFormDefaultValues = (menu?: PlannedMenuSummaryType, dayOfStartDate?: number): Partial<PlannedMenuFormType> => {
  if (menu) {
    return {
      menuName: menu.name,
      menuDescription: menu.description,
      dateRange: {
        startDate: parseDate(menu.startDate),
        endDate: parseDate(menu.endDate),
        dayOfStartDate,
      },
      occurrence: menu.occurrence,
      timezone: menu.timezone,
    };
  }
  return {
    menuName: "",
    menuDescription: "",
    dateRange: undefined,
    occurrence: undefined,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

export const plannedMenuFormToCreateInput = (menu: PlannedMenuFormType) => ({
  ...plannedMenuFormToInputCommon(menu),
  occurrence: menu.occurrence,
});

const plannedMenuFormToInputCommon = (menu: PlannedMenuFormType) => ({
  name: menu.menuName,
  description: menu.menuDescription,
  startDate: serializeDate(menu.dateRange.startDate),
  endDate: serializeDate(menu.dateRange.endDate),
  timezone: menu.timezone,
});

export const plannedMenuFormToEditInput = (menu: PlannedMenuFormType, id: string) => ({
  plannedMenuId: id,
  ...plannedMenuFormToInputCommon(menu),
});

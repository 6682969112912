import { useApolloClient } from "@apollo/client";
import CheckIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import RestaurantIcon from "@mui/icons-material/Restaurant";

import PrintIcon from "@mui/icons-material/Print";
import { Box, Chip, IconButton, List, Popover, Theme, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { formatTimeInTimezone, formatTimeRangeInTimezone, serializeDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import { round } from "@notemeal/shared/ui/utils/numbers";
import { sortByFn, sortByKey } from "@notemeal/utils/sort";
import { getMenuAttendancePath } from "apps/web/src/pages/Auth/Org/MenuAttendance/MenuAttendanceRouter";
import { addMinutes } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import MealMenuIdentityChip from "../../../../components/MealMenuIdentity/Chip";
import {
  FinishedMealMenuPaperDocument,
  FinishedMealMenuPaperQuery,
  FinishedMealMenuPaperQueryVariables,
  MealMenuModalFragment,
  useMealMenuPaperQuery,
} from "../../../../types";
import { ViewMenuOrdersDialog } from "../../../Orders/ViewMenuOrdersDialog";
import MealMenuDialogReadonly from "../../Dialog/Readonly";
import MissingOrdersDialog from "../../MissingOrders/Dialog";
import { useBulkOrderExport } from "../../OrderExport/useBulkOrderExport";
import { MealMenuInstance, MenuDialogState, StandaloneMenuDialogState } from "../../types";
import { getMenuDialogStateForEdit, getMenuDialogStateForFinishedCopy } from "../../utils";
import { PopoverListItem, StyledDivider } from "../Default/PopoverListItem";
import ShareContent from "../ShareContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(1, 1, 0, 0),
    },
    secondaryText: {
      color: theme.palette.text.secondary,
    },

    identityChip: {
      marginLeft: theme.spacing(),
    },
  })
);

const POPOVER_MIN_WIDTH = 400;

const HIDE_TEAMS_AFTER = 3;

interface MenusCalendarEventFinishedPopoverProps {
  mealMenu: MealMenuInstance;
  anchorEl: HTMLElement;
  onClose: () => void;
  onOpenMenuDialogForCopy: (menuDialogState: MenuDialogState) => void;
  clientTimezone: string;
}

export const MenusCalendarEventFinishedPopover = ({
  mealMenu,
  anchorEl,
  onClose,
  onOpenMenuDialogForCopy,
  clientTimezone,
}: MenusCalendarEventFinishedPopoverProps) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const [openMoreActions, setOpenMoreActions] = useState(false);
  const moreActionsRef = useRef<HTMLButtonElement | null>(null);

  const { formatDateWithLocale } = useDateFormatting();
  const [readonlyMealMenuForModal, setReadonlyMealMenuForModal] = useState<StandaloneMenuDialogState | null>(null);
  const [viewMenuOrdersOpen, setViewMenuOrdersOpen] = useState(false);
  const [missingOrdersOpen, setMissingOrdersOpen] = useState(false);

  const { data, loading } = useMealMenuPaperQuery({
    variables: {
      mealMenuId: mealMenu.id,
    },
    fetchPolicy: "network-only",
  });

  const [waitingToOpen, setWaitingToOpen] = useState<"readonly" | null>(null);
  useEffect(() => {
    if (data && waitingToOpen) {
      setWaitingToOpen(null);
      if (waitingToOpen === "readonly") {
        handleOpenDialogForReadonly(data.mealMenu);
      }
    }
  }, [data, waitingToOpen]);

  const handleOpenDialogForReadonly = async (mealMenuForModal: MealMenuModalFragment) => {
    setReadonlyMealMenuForModal(
      getMenuDialogStateForEdit(
        mealMenu,
        mealMenuForModal.allOrders,
        mealMenuForModal.mealMenuDiningStations,
        mealMenuForModal.restaurantMenuLinks,
        mealMenuForModal.advancedSelection
      )
    );
  };

  const { onExport, loading: exportLoading } = useBulkOrderExport(mealMenu.name, mealMenu.timezone, mealMenu.start);

  const tryOpenDialogForReadonly = () => {
    if (data) {
      handleOpenDialogForReadonly(data.mealMenu);
    } else {
      setWaitingToOpen("readonly");
    }
  };

  const handleOpenDialogForCopy = async () => {
    const { data } = await apolloClient.query<FinishedMealMenuPaperQuery, FinishedMealMenuPaperQueryVariables>({
      query: FinishedMealMenuPaperDocument,
      variables: { mealMenuId: mealMenu.id },
      fetchPolicy: "network-only",
    });
    if (data && data.mealMenu) {
      onClose();
      onOpenMenuDialogForCopy(getMenuDialogStateForFinishedCopy(mealMenu, data.mealMenu));
    }
  };

  const athleteCount = mealMenu.athleteCount;

  const popoverOptions = {
    excludeTimezoneSuffix: true,
    alwaysShowMinutes: true,
  };
  const mealMenuStartToEndForPopover = `${formatDateWithLocale(mealMenu.start)}, ${formatTimeRangeInTimezone(
    mealMenu.start,
    mealMenu.durationInMinutes,
    clientTimezone,
    popoverOptions
  )}`;
  const lastOrderDateTime = addMinutes(mealMenu.start, mealMenu.durationInMinutes - mealMenu.lastOrderTimeBeforeEndInMinutes);
  const lastOrderForPopover = `${formatDateWithLocale(lastOrderDateTime)} at ${formatTimeInTimezone(
    lastOrderDateTime,
    clientTimezone,
    popoverOptions
  )}`;

  const hasRestaurantMenus = useHasFeature("hasRestaurantMenus");
  const hasBulkOrdering = useHasFeature("bulkOrdering");
  const showRestaurants = hasRestaurantMenus && mealMenu.restaurantMenuLinks.length > 0;
  const showDiningStations = !showRestaurants || mealMenu.mealMenuDiningStations.length > 0;

  return (
    <>
      <Popover
        open
        anchorEl={anchorEl}
        onClose={onClose}
        transformOrigin={{
          vertical: "center",
          horizontal: POPOVER_MIN_WIDTH,
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: POPOVER_MIN_WIDTH,
              maxWidth: POPOVER_MIN_WIDTH,
            },
          },
        }}
      >
        <Box
          sx={({ spacing }) => ({
            display: "flex",
            justifyContent: "flex-end",
            padding: spacing(1.5),
            paddingBottom: 0,
          })}
        >
          <Tooltip title="Copy">
            <IconButton
              sx={({ spacing }) => ({
                marginLeft: spacing(),
              })}
              onClick={handleOpenDialogForCopy}
              size="small"
              aria-label="Copy menu"
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="More actions">
            <IconButton
              ref={moreActionsRef}
              aria-label="More actions"
              size="small"
              sx={({ spacing }) => ({
                marginLeft: spacing(),
              })}
              onClick={() => setOpenMoreActions(true)}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            sx={({ spacing }) => ({
              marginLeft: spacing(),
            })}
            onClick={onClose}
            size="small"
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={({ spacing }) => ({
            padding: spacing(1.5),
            paddingTop: spacing(0.5),
          })}
        >
          {mealMenu.isOrderAndLogRestricted && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: 1, mb: 2 }}>
              * Ordering and logging restricted to dietitians
            </Typography>
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{mealMenu.name}</Typography>
            {mealMenu.identity && (
              <MealMenuIdentityChip
                size="small"
                className={classes.identityChip}
                mealMenuIdentity={mealMenu.identity} />
            )}
          </Box>
          <Typography
            fontSize="14px"
            fontWeight="medium"
            color="textSecondary">
            {mealMenuStartToEndForPopover}
          </Typography>

          {showDiningStations && (
            <Box
              sx={({ spacing }) => ({
                margin: spacing(1, 0),
              })}
            >
              <Typography fontSize="14px" fontWeight="medium">
                Dining Stations
              </Typography>
              {sortByKey(mealMenu.mealMenuDiningStations, "position").map(mmds => (
                <Chip
                  sx={({ spacing }) => ({
                    margin: spacing(1, 1, 0, 0),
                  })}
                  size="small"
                  key={mmds.id}
                  label={mmds.name}
                />
              ))}
            </Box>
          )}

          {showRestaurants && (
            <Box
              sx={({ spacing }) => ({
                margin: spacing(1, 0),
              })}
            >
              <Typography fontSize="14px" fontWeight="medium">
                Restaurants
              </Typography>
              {sortByFn(mealMenu.restaurantMenuLinks, l => l.restaurant.name).map(l => (
                <Chip
                  sx={({ spacing }) => ({
                    margin: spacing(1, 1, 0, 0),
                  })}
                  size="small"
                  key={l.id}
                  label={l.restaurant.name}
                />
              ))}
            </Box>
          )}
          <Typography fontSize="14px" fontWeight="medium">
            Order Details
          </Typography>
          <Typography fontSize="14px">
            Orders Due: <span className={classes.secondaryText}>{lastOrderForPopover}</span>
          </Typography>
          {athleteCount > 0 && (
            <>
              <Typography fontSize="14px">
                Orders Placed: {mealMenu.athleteOrderCount}/{athleteCount}{" "}
                <span className={classes.secondaryText}>
                  ({round((mealMenu.athleteOrderCount / athleteCount) * 100, 0)}
                  %)
                  {mealMenu.nonAthleteOrderCount > 0 && ` - (+${mealMenu.nonAthleteOrderCount} admin orders)`}{" "}
                </span>
              </Typography>
            </>
          )}

          <Box
            sx={({ spacing }) => ({
              margin: spacing(1, 0),
            })}
          >
            <ShareContent
              mealMenu={mealMenu}
              advancedSelection={data?.mealMenu.advancedSelection}
              loading={loading}
              teamChipClassName={classes.chip}
              maxTeamChips={HIDE_TEAMS_AFTER}
            />
          </Box>
        </Box>
      </Popover>
      {openMoreActions && (
        <Popover
          open={openMoreActions}
          anchorEl={moreActionsRef.current}
          onClose={onClose}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <List>
              <PopoverListItem
                onClick={tryOpenDialogForReadonly}
                icon={<PrintIcon />}
                copy="Print" />

              <StyledDivider />

              {
                // this check trickles down from the featureFlag check
                mealMenu.isHubCheckInEnabled && (
                  <PopoverListItem
                    onClick={() => navigate(getMenuAttendancePath(mealMenu.id))}
                    icon={<CheckIcon />}
                    copy="View attendance report"
                  />
                )
              }
              <PopoverListItem
                onClick={() => setViewMenuOrdersOpen(true)}
                icon={<RestaurantIcon />}
                copy="View menu orders" />
              <PopoverListItem
                onClick={() => setMissingOrdersOpen(true)}
                icon={<PeopleAltIcon />}
                copy="View missing orders" />

              {hasBulkOrdering && (
                <PopoverListItem
                  onClick={() => onExport(mealMenu.id)}
                  icon={<DownloadIcon />}
                  copy="Export bulk orders"
                  disabled={exportLoading}
                />
              )}
            </List>
          </Box>
        </Popover>
      )}
      {readonlyMealMenuForModal !== null && (
        <MealMenuDialogReadonly
          open={readonlyMealMenuForModal !== null}
          onClose={() => {
            onClose();
            setReadonlyMealMenuForModal(null);
          }}
          mealMenu={readonlyMealMenuForModal}
          clientTimezone={clientTimezone}
        />
      )}
      {viewMenuOrdersOpen && (
        <ViewMenuOrdersDialog
          open={viewMenuOrdersOpen}
          onClose={() => setViewMenuOrdersOpen(false)}
          mealMenuId={mealMenu.id}
          mealMenuStart={serializeDateTime(mealMenu.start)}
        />
      )}
      {missingOrdersOpen && (
        <MissingOrdersDialog
          open={missingOrdersOpen}
          onClose={() => setMissingOrdersOpen(false)}
          mealMenuId={mealMenu.id} />
      )}
    </>
  );
};

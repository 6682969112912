import {
  Button,
  capitalize,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  canLinkTeamworksProfile,
  getIsValidSyncRule,
  getTeamworksUserIdCounts,
  LinkedProfile,
  NotemealProfile,
  ProfileSyncRuleWithEntities,
} from "@notemeal/profile-sync";
import { sortByKey } from "@notemeal/utils/sort";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { Dispatch, useMemo, useState } from "react";
import LabeledSelectNoId from "../../../../../../components/universal/LabeledSelectNoId";
import { TeamworksGender, TeamworksGroupFragment, TeamworksProfileFragment, TeamworksTeamFragment } from "../../../../../../types";
import ListsColumn from "../../shared/ListColumn";
import ListsContainer from "../../shared/ListsContainer";
import { TeamworksProfilesAction } from "../reducer";
import { autoLinkProfiles, filterNotemealProfilesForRule, NOTEMEAL_ACCOUNT_TYPES } from "../utils";
import { LinkedProfilesList, NotemealProfilesList, PendingProfilesList, TeamworksProfilesList } from "./DisplayLists";
import SelectionCriteria from "./SelectionCriteria";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "calc(100% - 64px)",
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    flex: {
      display: "flex",
    },
    selectionCriteria: {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
    },
    spacer: {
      flexGrow: 1,
    },
    listColumn: {
      overflow: "hidden",
    },
    selectRules: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    autoLinkButton: {
      alignSelf: "flex-start",
      marginLeft: theme.spacing(3),
    },
    resolveText: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ProfileSyncRuleDialogProps {
  open: boolean;
  onClose: () => void;
  profileSyncRule: ProfileSyncRuleWithEntities;
  unlinkedNotemealProfiles: readonly NotemealProfile[];
  teamworksTeams: readonly TeamworksTeamFragment[];
  teamworksUserTypes: readonly TeamworksGroupFragment[];
  teamworksAthleteStatuses: readonly TeamworksGroupFragment[];
  allTeamworksProfiles: readonly TeamworksProfileFragment[];
  teamworksPositions: readonly TeamworksGroupFragment[];
  dispatch: Dispatch<TeamworksProfilesAction>;
}

const formatTeamworksGender = (
  teamworksGenders: readonly TeamworksGender[]
): {
  id: TeamworksGender;
  label: TeamworksGender;
}[] => {
  return teamworksGenders.map(gender => ({
    id: gender,
    label: gender,
  }));
};

interface NotemealTeamInfo {
  id: string;
  name: string;
  teamworksTeam: TeamworksTeamFragment;
}

const getNotemealTeamInfo = (
  teamworksTeams: readonly TeamworksTeamFragment[],
  matchNotemealTeamIds: readonly string[]
): {
  allNotemealTeamInfo: NotemealTeamInfo[];
  selectedNotemealTeamInfo: NotemealTeamInfo[];
  infoMissingTeamworksTeams: TeamworksTeamFragment[];
} => {
  const selectedNotemealTeamInfo: NotemealTeamInfo[] = [];
  const infoMissingTeamworksTeams: TeamworksTeamFragment[] = [];
  const allNotemealTeamInfo = teamworksTeams.flatMap(teamworksTeam => {
    if (teamworksTeam.notemealTeams.length <= 1) {
      return [];
    }
    const notemealTeamInfoList = teamworksTeam.notemealTeams.map(({ id, name }) => ({ id, name, teamworksTeam }));
    const match = notemealTeamInfoList.find(({ id }) => matchNotemealTeamIds.includes(id));
    if (match) {
      selectedNotemealTeamInfo.push(match);
    } else {
      infoMissingTeamworksTeams.push(teamworksTeam);
    }
    return notemealTeamInfoList;
  });

  return {
    selectedNotemealTeamInfo,
    infoMissingTeamworksTeams,
    allNotemealTeamInfo,
  };
};

const ProfileSyncRuleDialog = ({
  open,
  onClose,
  unlinkedNotemealProfiles: _unlinkedNotemealProfiles,
  profileSyncRule,
  teamworksTeams,
  teamworksUserTypes,
  teamworksAthleteStatuses,
  allTeamworksProfiles,
  teamworksPositions,
  dispatch,
}: ProfileSyncRuleDialogProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();

  const allGenders: TeamworksGender[] = useMemo(() => {
    const genders = allTeamworksProfiles.flatMap(profile => (profile.gender ? profile.gender : []));
    return [...new Set(genders)];
  }, [allTeamworksProfiles]);

  const profilesWithNoGenderCount = useMemo(() => {
    const profilesWithNoGender = profileSyncRule.unlinkedProfiles.filter(profile => profile.gender === null);
    return profilesWithNoGender.length;
  }, [profileSyncRule.unlinkedProfiles]);

  const [selectedNotemealProfile, setSelectedNotemealProfile] = useState<NotemealProfile | null>(null);
  const selectedId = selectedNotemealProfile?.id ?? null;

  const unlinkedNotemealProfiles = useMemo(
    () => _unlinkedNotemealProfiles.filter(p => filterNotemealProfilesForRule(p, profileSyncRule)),
    [_unlinkedNotemealProfiles, profileSyncRule]
  );

  const autoLinkableProfiles = useMemo(
    () => autoLinkProfiles(unlinkedNotemealProfiles, profileSyncRule.unlinkedProfiles),
    [profileSyncRule.unlinkedProfiles, unlinkedNotemealProfiles]
  );

  const teamworksUserIdCounts = useMemo(() => getTeamworksUserIdCounts(allTeamworksProfiles), [allTeamworksProfiles]);

  const handleChange = (
    payload: Partial<
      Pick<
        ProfileSyncRuleWithEntities,
        | "teams"
        | "athleteStatuses"
        | "userTypes"
        | "profiles"
        | "notemealAccountType"
        | "matchOnProfiles"
        | "genders"
        | "positions"
        | "matchNotemealTeamIds"
        | "onlyNotemealProfilesOnSelectedTeams"
      >
    >
  ) => {
    const {
      id: profileSyncRuleId,
      teams,
      profiles,
      userTypes,
      athleteStatuses,
      notemealAccountType,
      matchOnProfiles,
      positions,
      genders,
      matchNotemealTeamIds,
      onlyNotemealProfilesOnSelectedTeams,
    } = profileSyncRule;
    dispatch({
      type: "EDIT_PROFILE_SYNC_RULE",
      payload: {
        profileSyncRuleId,
        teams,
        profiles,
        userTypes,
        athleteStatuses,
        notemealAccountType,
        matchOnProfiles,
        positions,
        genders,
        matchNotemealTeamIds,
        onlyNotemealProfilesOnSelectedTeams,
        ...payload,
      },
    });
  };

  const handleLinkProfile = (teamworks: TeamworksProfileFragment, notemeal: NotemealProfile | null) => {
    if (canLinkTeamworksProfile(teamworks, teamworksUserIdCounts)) {
      dispatch({
        type: "LINK_PROFILE",
        payload: {
          teamworks,
          notemeal,
        },
      });
      setSelectedNotemealProfile(null);
    } else {
      // Profiles missing required fields are disabled, so only the userId case can make it here
      setMessage("error", "A Teamworks profile with the same userId has already been linked");
    }
  };

  const handleDelinkProfile = ({ teamworks, notemeal }: LinkedProfile) => {
    dispatch({
      type: "DELINK_PROFILE",
      payload: {
        teamworks,
        notemeal,
      },
    });
  };

  const handleCreateAllTeamworksProfiles = () => {
    const creatableTeamworksProfiles = profileSyncRule.unlinkedProfiles.filter(p => canLinkTeamworksProfile(p, teamworksUserIdCounts));
    dispatch({
      type: "LINK_MULTIPLE_PROFILES",
      payload: creatableTeamworksProfiles.map(teamworks => ({
        teamworks,
        isPending: true,
        notemeal: null,
      })),
    });
  };

  const inputTeams = profileSyncRule.teams.length > 0 ? profileSyncRule.teams : teamworksTeams;
  const { allNotemealTeamInfo, selectedNotemealTeamInfo, infoMissingTeamworksTeams } = getNotemealTeamInfo(
    inputTeams,
    profileSyncRule.matchNotemealTeamIds
  );

  const { isValidSyncRule, hasValidSelectionCriteria, hasValidNotemealTeams } = getIsValidSyncRule(profileSyncRule, teamworksTeams);

  const handleClose = () => {
    if (!hasValidNotemealTeams) {
      const teamworksNames = infoMissingTeamworksTeams.map(({ name }) => name).join(", ");
      setMessage("error", `Select a Nutrition team for the ambiguous teamworks teams: ${teamworksNames}`);
    } else if (!hasValidSelectionCriteria) {
      setMessage("error", "Select some criteria to match profiles on!");
    } else {
      onClose();
    }
  };
  const selectedTeamworksTeamIds = profileSyncRule.teams.map(({ id }) => id);

  const positionsForSelectedTeams = teamworksPositions.filter(
    position => position.teamId && selectedTeamworksTeamIds.includes(position.teamId)
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      classes={{ paper: classes.paper }}>
      <DialogTitle>
        Sync Teamworks Profiles{" "}
        {unlinkedNotemealProfiles.length === 0 ? (
          <Button onClick={handleCreateAllTeamworksProfiles} className={classes.autoLinkButton}>
            Create All Teamworks Profiles
          </Button>
        ) : (
          <Button
            disabled={autoLinkableProfiles.length === 0}
            onClick={() =>
              dispatch({
                type: "LINK_MULTIPLE_PROFILES",
                payload: autoLinkableProfiles,
              })
            }
            className={classes.autoLinkButton}
          >
            {autoLinkableProfiles.length > 0 ? `Autolink ${autoLinkableProfiles.length} Profile(s)` : "No autolinkable Profiles"}
          </Button>
        )}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div>
            <div className={classes.flex}>
              {profileSyncRule.matchOnProfiles ? (
                <SelectionCriteria
                  title="Profiles"
                  noItemsChipPlaceholder="No Profiles"
                  className={classes.selectionCriteria}
                  onChange={profiles =>
                    handleChange({
                      profiles,
                    })
                  }
                  options={allTeamworksProfiles}
                  value={profileSyncRule.profiles}
                  getItemLabel={i => `${i.firstName} ${i.lastName}, ${i.email ?? "<NO EMAIL>"}, ${i.cellPhone || "<NO PHONE>"}`}
                />
              ) : (
                <>
                  <SelectionCriteria
                    title="Teams"
                    noItemsChipPlaceholder="Any Teams"
                    className={classes.selectionCriteria}
                    onChange={teams => {
                      handleChange({
                        teams,
                      });
                    }}
                    options={teamworksTeams}
                    value={profileSyncRule.teams}
                    getItemLabel={t => t.name}
                  />
                  <SelectionCriteria
                    title="User Types"
                    noItemsChipPlaceholder="Any User Types"
                    className={classes.selectionCriteria}
                    onChange={userTypes =>
                      handleChange({
                        userTypes,
                      })
                    }
                    options={teamworksUserTypes}
                    value={profileSyncRule.userTypes}
                    getItemLabel={t => t.label}
                  />
                  <SelectionCriteria
                    title="Athlete Statuses"
                    noItemsChipPlaceholder="Any Athlete Statuses"
                    className={classes.selectionCriteria}
                    onChange={athleteStatuses =>
                      handleChange({
                        athleteStatuses,
                      })
                    }
                    options={teamworksAthleteStatuses}
                    value={profileSyncRule.athleteStatuses}
                    getItemLabel={a => a.label}
                  />
                  <SelectionCriteria
                    title="Positions"
                    noItemsChipPlaceholder="Any Position"
                    className={classes.selectionCriteria}
                    onChange={positions =>
                      handleChange({
                        positions,
                      })
                    }
                    options={sortByKey(positionsForSelectedTeams, "label")}
                    value={profileSyncRule.positions}
                    getItemLabel={a => a.label}
                    noOptionsText={
                      profileSyncRule.teams.length === 0
                        ? "Select a team to see positions"
                        : teamworksPositions.length > 0
                        ? "No Positions for selected team(s)"
                        : undefined
                    }
                  />
                  <SelectionCriteria
                    title="Genders"
                    noItemsChipPlaceholder="Any Gender"
                    className={classes.selectionCriteria}
                    onChange={genders =>
                      handleChange({
                        genders: genders.map(({ label }) => label),
                      })
                    }
                    options={formatTeamworksGender(allGenders)}
                    value={formatTeamworksGender(profileSyncRule.genders)}
                    getItemLabel={a => a.label}
                    warningMessage={
                      profileSyncRule.genders.length > 0 && profilesWithNoGenderCount > 0
                        ? `${profilesWithNoGenderCount} with no gender that will ignore specified gender`
                        : undefined
                    }
                  />
                </>
              )}
            </div>
            <FormControlLabel
              control={
                <Checkbox checked={profileSyncRule.matchOnProfiles} onChange={(_, matchOnProfiles) => handleChange({ matchOnProfiles })} />
              }
              label="Lookup Specific Profiles"
            />
          </div>
          <div className={classes.spacer} />
          <div className={classes.content}>
            <div className={classes.flex}>
              {allNotemealTeamInfo.length > 0 && (
                <SelectionCriteria
                  title="Notemeal Teams"
                  noItemsChipPlaceholder="No Teams"
                  className={classes.selectionCriteria}
                  onChange={notemealTeamInfos =>
                    handleChange({
                      matchNotemealTeamIds: notemealTeamInfos.map(({ id }) => id),
                    })
                  }
                  options={allNotemealTeamInfo}
                  value={selectedNotemealTeamInfo}
                  getItemLabel={a => a.name}
                  groupBy={notemealTeamInfo => notemealTeamInfo.teamworksTeam.name}
                  filterOptions={(options, selectedOptions) => {
                    const selectedTeamworksTeamIds = selectedOptions.map(({ teamworksTeam }) => teamworksTeam.id);
                    return options.filter(({ teamworksTeam }) => !selectedTeamworksTeamIds.includes(teamworksTeam.id));
                  }}
                  disableCloseOnSelect={infoMissingTeamworksTeams.length > 1}
                />
              )}
              <LabeledSelectNoId
                sx={{ width: "200px" }}
                placeholder="Notemeal Account Type"
                options={NOTEMEAL_ACCOUNT_TYPES}
                selectedOption={profileSyncRule.notemealAccountType}
                optionToName={n => capitalize(n)}
                onChange={notemealAccountType => handleChange({ notemealAccountType })}
              />
            </div>
            {!profileSyncRule.matchOnProfiles && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={profileSyncRule.onlyNotemealProfilesOnSelectedTeams}
                    onChange={(_, onlyNotemealProfilesOnSelectedTeams) => handleChange({ onlyNotemealProfilesOnSelectedTeams })}
                  />
                }
                label="Only Nutrition Profiles on Selected Teams"
              />
            )}
          </div>
        </div>

        {isValidSyncRule ? (
          <ListsContainer>
            <ListsColumn className={classes.listColumn}>
              {/* Hide if none initially */}
              <NotemealProfilesList
                selectedId={selectedId}
                onLink={setSelectedNotemealProfile}
                onCancel={() => setSelectedNotemealProfile(null)}
                profiles={unlinkedNotemealProfiles}
              />
            </ListsColumn>

            <ListsColumn className={classes.listColumn}>
              <TeamworksProfilesList
                selectedId={selectedId}
                profiles={profileSyncRule.unlinkedProfiles}
                onLink={teamworksProfile => {
                  if (selectedNotemealProfile) {
                    handleLinkProfile(teamworksProfile, selectedNotemealProfile);
                  }
                }}
                onAdd={teamworksProfile => {
                  if (!selectedNotemealProfile) {
                    handleLinkProfile(teamworksProfile, null);
                  }
                }}
              />
            </ListsColumn>

            <ListsColumn className={classes.listColumn}>
              <PendingProfilesList
                selectedId={selectedId}
                profiles={profileSyncRule.linkedProfiles.filter(p => p.isPending)}
                onCancel={handleDelinkProfile}
              />
            </ListsColumn>

            <ListsColumn className={classes.listColumn}>
              {/* Hide if none initially */}
              <LinkedProfilesList selectedId={selectedId} profiles={profileSyncRule.linkedProfiles.filter(p => !p.isPending)} />
            </ListsColumn>
          </ListsContainer>
        ) : (
          <div className={classes.selectRules}>
            {!hasValidSelectionCriteria && (
              <Typography variant="h3">Set Teamworks Selection Criteria for this Profile Sync Rule</Typography>
            )}
            {!hasValidNotemealTeams && (
              <Typography className={classes.resolveText} variant="h3">
                Resolve Ambiguous Nutrition Teams
              </Typography>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileSyncRuleDialog;

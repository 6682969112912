import { TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { invalidPhoneNumber, parsePhoneNumber } from "@notemeal/utils/phone-number";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { CountryCodeMask, PhoneNumberMask } from "../../utils/textMasks";

interface PhoneNumberInputProps {
  setInput: (phoneNumber: string) => void;
  disabled?: boolean;
  input?: string | null;
  className?: string;
  disablePadding?: boolean;
  inputClassName?: string;
  error?: boolean;
  helperText?: string;
}

const useStyles = (disablePadding: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      countryCode: {
        width: 96,
      },
      spacer: {
        width: theme.spacing(1),
      },
      row: {
        display: "flex",
        padding: disablePadding ? undefined : theme.spacing(),
        justifyContent: "flex-start",
      },
    })
  );

const PhoneNumberInput = ({
  setInput,
  disabled,
  className,
  input,
  disablePadding = false,
  inputClassName,
  error,
  helperText,
}: PhoneNumberInputProps) => {
  const [phoneNumber, setPhoneNumber] = useState((input && parsePhoneNumber(input)?.phoneNumber) || "");
  const [code, setCode] = useState((input && parsePhoneNumber(input)?.countryCode) || "+1");
  const classes = useStyles(disablePadding)();

  useEffect(() => {
    setInput(coercePhoneNumber(`${code}${phoneNumber}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, code]); // intentially omitting setInput as a dep here to prevent render loops

  return (
    <div className={className}>
      <div className={classes.row}>
        <TextField
          label={"Code"}
          className={classNames(classes.countryCode, inputClassName)}
          onChange={e => setCode(e.target.value)}
          value={code}
          error={error || code === "+"}
          InputProps={{ inputComponent: CountryCodeMask as any }}
          disabled={disabled}
        />
        <div className={classes.spacer} />
        <TextField
          autoFocus
          label={"Phone Number"}
          onChange={e => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          InputProps={{ inputComponent: PhoneNumberMask as any }}
          error={error || (Boolean(phoneNumber) && invalidPhoneNumber(phoneNumber))}
          disabled={disabled}
          className={inputClassName}
          helperText={helperText}
        />
      </div>
    </div>
  );
};

export const coercePhoneNumber = (phoneNumber: string) => {
  return phoneNumber === "+1" || phoneNumber === "1" ? "" : phoneNumber;
};

export default PhoneNumberInput;

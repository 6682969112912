import { Box } from "@mui/material";
import { MealPlanDateAssignmentPreviewFragment } from "apps/web/src/types";
import React, { Dispatch } from "react";
import { MealPlanDateAssignmentAction } from "../reducer";
import { MealPlanDateAssignment } from "../types";
import CalendarSelect from "./Calendar";
import MealPlanCalendarDateAssignmentForm from "./ModeForm";

export type StartDateInPastBehavior = "error" | "disabled";

interface MealPlanCalendarDateAssignmentProps {
  state: MealPlanDateAssignment;
  dispatch: Dispatch<MealPlanDateAssignmentAction>;
  onClickCalendarDate: (date: string) => void;
  startDateInPastBehavior: StartDateInPastBehavior;
  isLoading: (startOfMonthDate: Date) => boolean;
  otherMealPlanDateAssignments: readonly MealPlanDateAssignmentPreviewFragment[];
  mealPlanColor: string;
}

const MealPlanCalendarDateAssignment = ({
  state,
  dispatch,
  onClickCalendarDate,
  startDateInPastBehavior,
  isLoading,
  otherMealPlanDateAssignments,
  mealPlanColor,
}: MealPlanCalendarDateAssignmentProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", pt: 0.5 }}>
      <MealPlanCalendarDateAssignmentForm
        state={state}
        dispatch={dispatch}
        calendarLocation="below"
        startDateInPastBehavior={startDateInPastBehavior}
        size="small"
      />
      <CalendarSelect
        state={state}
        otherMealPlanDateAssignments={otherMealPlanDateAssignments}
        mealPlanColor={mealPlanColor}
        dispatch={dispatch}
        onClickCalendarDate={onClickCalendarDate}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default MealPlanCalendarDateAssignment;

import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React from "react";
import { useForm } from "react-hook-form";
import { AthleteSummaryAthleteFragment, TeamWithSportFragment, useEditSyncedAthleteMutation } from "../../../types";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import LoadingButton from "../../universal/LoadingButton";
import Form from "../Form";
import { AthleteFormSchema, AthleteFormType, athleteFormDefaultValues, syncedAthleteForToEditInput } from "../Form/AthleteFormSchema";

interface SyncedAthleteEditModalProps {
  athlete: AthleteSummaryAthleteFragment;
  open: boolean;
  teams: readonly TeamWithSportFragment[];
  onClose: () => void;
}

const SyncedAthleteEditModal = ({ open, onClose, athlete, teams }: SyncedAthleteEditModalProps) => {
  const form = useForm<AthleteFormType>({
    defaultValues: athleteFormDefaultValues(athlete.team.id, athlete),
    resolver: zodResolver(AthleteFormSchema),
  });

  const hasFormErrors = form.formState.isSubmitted && !form.formState.isValid;

  const { setMessage } = useSnackbar();

  const [editSyncedAthlete, { loading }] = useEditSyncedAthleteMutation({
    onCompleted: data => {
      const editedAthlete = data.editSyncedAthlete.athlete;
      setMessage("success", `Successfully edited Athlete: ${editedAthlete.firstName} ${editedAthlete.lastName}`);
      onClose();
    },
    onError: () => {
      setMessage("error", `Error editing Athlete: ${athlete.firstName} ${athlete.lastName}`);
      onClose();
    },
  });

  const submit = (newAthlete: AthleteFormType) => {
    const { sex } = newAthlete;

    editSyncedAthlete({
      variables: {
        input: syncedAthleteForToEditInput({ ...newAthlete, sex }, athlete.id, athlete.allOrderedNamedTags),
      },
    });
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Athlete" onClose={onClose} />
      <DialogContent
        sx={{
          width: 800,
          display: "flex",
          flexDirection: "column",
          flexGrow: 0,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Form
            displayUidFields
            isForSyncedAthlete
            username={athlete.user.username ?? undefined}
            teams={teams}
            form={form} />
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          onClick={onClose}
          loading={loading}
          buttonText="Cancel" />
        <Tooltip title={hasFormErrors ? "Resolve the errors above to continue" : ""}>
          <LoadingButton
            onClick={form.handleSubmit(submit)}
            loading={loading}
            buttonText="Save" />
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default SyncedAthleteEditModal;

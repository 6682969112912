import { KioskMealMenuPreviewFragment, OrderKioskAthletePreviewFragment } from "apps/web/src/types";

interface BaseAthleteOrderState {
  selectedMealMenu: KioskMealMenuPreviewFragment;
}

export interface AthleteOrderStateSelectOrder extends BaseAthleteOrderState {
  type: "selectNewOrEdit";
  orderIdToEdit: string;
}

interface AthleteOrderStateViewOnly extends BaseAthleteOrderState {
  type: "viewOnly";
}

interface AthleteOrderStateAddNewOrder extends BaseAthleteOrderState {
  type: "new";
}

interface AthleteOrderStateEditOrder extends BaseAthleteOrderState {
  type: "edit";
  menuOrderId: string;
}

export type OrderFormAthleteOrderState = AthleteOrderStateAddNewOrder | AthleteOrderStateEditOrder | AthleteOrderStateViewOnly;

export type NonNullAthleteOrderState =
  | AthleteOrderStateSelectOrder
  | AthleteOrderStateAddNewOrder
  | AthleteOrderStateEditOrder
  | AthleteOrderStateViewOnly;

export type AthleteOrderState = NonNullAthleteOrderState | null;

interface EditMealMenuAction {
  type: "EditMealMenu";
  payload: {
    selectedMealMenu: KioskMealMenuPreviewFragment;
    selectedAthlete: OrderKioskAthletePreviewFragment;
  };
}

interface CloseOrderFormAction {
  type: "CloseOrderForm";
}

interface StartNewOrderAction {
  type: "StartNewOrder";
}

interface SwitchToEditOrderAction {
  type: "SwitchToEditOrderAction";
  payload: { menuOrderId: string };
}

export type AthleteOrderStateAction = EditMealMenuAction | CloseOrderFormAction | StartNewOrderAction | SwitchToEditOrderAction;

export const athleteOrderStateReducer = (state: AthleteOrderState, action: AthleteOrderStateAction): AthleteOrderState => {
  switch (action.type) {
    case "EditMealMenu": {
      const { selectedAthlete, selectedMealMenu } = action.payload;
      const orderInfoForSelectedMenu = selectedAthlete.kioskPreviewOrderInfo.find(
        orderInfo => orderInfo.mealMenuId === selectedMealMenu.id
      ) || {
        mealMenuId: selectedMealMenu.id,
        menuOrderCount: 0,
        mostRecentEditableMenuOrderId: null,
      };

      const { menuOrderCount, mostRecentEditableMenuOrderId } = orderInfoForSelectedMenu;

      const canAddMoreOrders =
        menuOrderCount === 0 || selectedMealMenu.userOrderLimit === null || menuOrderCount < selectedMealMenu.userOrderLimit;

      if (mostRecentEditableMenuOrderId) {
        return canAddMoreOrders
          ? {
              selectedMealMenu,
              type: "selectNewOrEdit",
              orderIdToEdit: mostRecentEditableMenuOrderId,
            }
          : {
              selectedMealMenu,
              type: "edit",
              menuOrderId: mostRecentEditableMenuOrderId,
            };
      } else if (canAddMoreOrders) {
        return {
          selectedMealMenu,
          type: "new",
        };
      } else {
        return {
          selectedMealMenu,
          type: "viewOnly",
        };
      }
    }
    case "StartNewOrder": {
      if (state === null) {
        return state;
      }
      return {
        selectedMealMenu: state.selectedMealMenu,
        type: "new",
      };
    }
    case "SwitchToEditOrderAction": {
      if (state === null) {
        return state;
      }
      return {
        selectedMealMenu: state.selectedMealMenu,
        type: "edit",
        menuOrderId: action.payload.menuOrderId,
      };
    }
    case "CloseOrderForm":
      return null;

    default:
      return state;
  }
};

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, ListItem, TextField, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { inputToNumber } from "@notemeal/shared/ui/utils/inputUtils";
import React from "react";
import { MenuIngredientsAnalyzerState_Item } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      flexGrow: 1,
    },
    menuItem: {
      display: "block",
      marginTop: theme.spacing(),
    },
    menuItemDetail: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    menuItemName: {
      marginRight: theme.spacing(),
    },
    menuItemAmount: {
      width: 50,
      marginRight: theme.spacing(2),
    },
    menuItemChoice: {
      marginLeft: theme.spacing(),
    },
    menuItemChoiceName: {
      width: "100%",
      textAlign: "center",
    },
  })
);

interface MenuIngredientsAnalyzerDialogMenuItemInputProps {
  menuItem: MenuIngredientsAnalyzerState_Item;
  onChangeAmount: (amountInput: string) => void;
  onToggleExpanded: () => void;
  onChangeOptionAmount: (args: { menuItemChoiceOptionId: string; amountInput: string }) => void;
}

const MenuIngredientsAnalyzerDialogMenuItemInput = ({
  menuItem,
  onChangeAmount,
  onChangeOptionAmount,
  onToggleExpanded,
}: MenuIngredientsAnalyzerDialogMenuItemInputProps) => {
  const classes = useStyles();

  return (
    <ListItem divider className={classes.menuItem}>
      <div className={classes.menuItemDetail}>
        <Typography className={classes.menuItemName} noWrap>
          {menuItem.name}
        </Typography>
        {menuItem.choices.length > 1 && (
          <IconButton size="small" onClick={onToggleExpanded}>
            {menuItem.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
        <div className={classes.spacer} />
        <TextField
          value={menuItem.amountInput}
          error={menuItem.amount !== inputToNumber(menuItem.amountInput)}
          className={classes.menuItemAmount}
          onChange={e => onChangeAmount(e.target.value)}
        />
      </div>
      {menuItem.expanded && (
        <>
          {menuItem.choices.map(mic => (
            <div key={mic.id} className={classes.menuItemChoice}>
              <Typography
                variant="body2"
                noWrap
                className={classes.menuItemChoiceName}>
                {mic.name}
              </Typography>
              {mic.options.map(mico => (
                <div className={classes.menuItemDetail}>
                  <Typography
                    variant="body2"
                    className={classes.menuItemName}
                    noWrap>
                    {mico.name}
                  </Typography>
                  <div className={classes.spacer} />
                  <TextField
                    value={mico.amountInput}
                    error={mico.amount !== inputToNumber(mico.amountInput)}
                    className={classes.menuItemAmount}
                    onChange={e =>
                      onChangeOptionAmount({
                        menuItemChoiceOptionId: mico.id,
                        amountInput: e.target.value,
                      })
                    }
                  />
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </ListItem>
  );
};

export default MenuIngredientsAnalyzerDialogMenuItemInput;

import { Button, Divider, Menu, MenuItem, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useState } from "react";
import {
  FullServingFragment,
  useCreateWeightUnitServingForFoodMutation,
  useFoodOrRecipeQuery,
  useUnitsForNewServingsQuery,
} from "../../../../types";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import RequestServingModal from "../../RequestServingModal";
import RegularContent from "./RegularContent";
import UnitExpandedContent from "./UnitExpandedContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemRoot: {
      minHeight: "auto",
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    servingsText: {
      fontWeight: "bold",
      padding: theme.spacing(0.5, 2),
    },
    ingredientsText: {
      fontWeight: "bold",
    },
    requestServingButton: {
      width: "100%",
    },
  })
);

interface ServingAmountsServingsMenuProps {
  foodOrRecipeId: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSelect: (serving: FullServingFragment, newAmount?: number) => void;
  allowServingRequest: boolean;
  includeRecipeOnlyIngredients: boolean;
}

const ServingAmountsServingsMenu = ({
  foodOrRecipeId,
  anchorEl,
  onClose,
  onSelect,
  allowServingRequest,
  includeRecipeOnlyIngredients,
}: ServingAmountsServingsMenuProps) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const { setMessage } = useSnackbar();

  const { data, loading } = useFoodOrRecipeQuery({
    variables: { id: foodOrRecipeId },
  });

  const { data: unitsData } = useUnitsForNewServingsQuery();
  const [createWeightUnitServingForFood] = useCreateWeightUnitServingForFoodMutation({
    onCompleted: data => {
      if (data.createWeightUnitServingForFood) {
        onSelect(data.createWeightUnitServingForFood.serving, data.createWeightUnitServingForFood.amount);
      }
    },
    onError: e => setMessage("error", "Could not change serving due to error."),
  });
  const servings =
    !loading && data && data.foodOrRecipe
      ? data.foodOrRecipe.servings.filter(s => includeRecipeOnlyIngredients || !s.isRecipeServingOnly)
      : null;

  const showOunceServing = !loading && data && data.foodOrRecipe.__typename !== "Recipe" && data.foodOrRecipe.showOunceServing;
  const showGramServing = !loading && data && data.foodOrRecipe.__typename !== "Recipe" && data.foodOrRecipe.showGramServing;
  const foodOrRecipeName = !loading && data && data.foodOrRecipe ? data.foodOrRecipe.name : "";

  const handleCreateOunceServing = () => {
    if (!unitsData) {
      return;
    }
    const ounceUnitId = unitsData.ounceUnit.id;
    createWeightUnitServingForFood({ variables: { input: { foodId: foodOrRecipeId, unitId: ounceUnitId } } });
  };
  const handleCreateGramServing = () => {
    if (!unitsData) {
      return;
    }
    const gramUnitId = unitsData.gramUnit.id;
    createWeightUnitServingForFood({ variables: { input: { foodId: foodOrRecipeId, unitId: gramUnitId } } });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Typography variant="subtitle1" className={classes.servingsText}>
        Servings
      </Typography>
      {!servings ? (
        <MenuItem classes={{ root: classes.menuItemRoot }} dense>
          <Typography variant="subtitle1">Loading...</Typography>
        </MenuItem>
      ) : servings.length === 1 && servings[0].unit !== null ? (
        <UnitExpandedContent
          serving={servings[0]}
          unitId={servings[0].unit.id}
          onClose={onClose}
          onSelect={onSelect}
          includeRecipeOnlyIngredients={includeRecipeOnlyIngredients}
        />
      ) : (
        <>
          <RegularContent
            servings={servings}
            onClose={onClose}
            onSelect={onSelect}
            includeRecipeOnlyIngredients={includeRecipeOnlyIngredients}
          />
          {showOunceServing && (
            <MenuItem
              classes={{ root: classes.menuItemRoot }}
              dense
              onClick={handleCreateOunceServing}>
              <Typography variant="subtitle1">ounce(s)</Typography>
            </MenuItem>
          )}
          {showGramServing && (
            <MenuItem
              classes={{ root: classes.menuItemRoot }}
              dense
              onClick={handleCreateGramServing}>
              <Typography variant="subtitle1">gram(s)</Typography>
            </MenuItem>
          )}
        </>
      )}
      {allowServingRequest && (
        <>
          <Divider className={classes.divider} key="divider" />
          <Button
            variant="text"
            size="small"
            key="requestServingButton"
            onClick={() => setModalOpen(true)}
            className={classes.requestServingButton}
          >
            Request Serving
          </Button>
        </>
      )}
      {modalOpen && (
        <RequestServingModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          foodName={foodOrRecipeName}
          onRequestSuccess={msg => setMessage("success", msg)}
          onRequestFailure={msg => setMessage("error", msg)}
        />
      )}
    </Menu>
  );
};

export default ServingAmountsServingsMenu;

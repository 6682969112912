import { SimpleFoodGroupFragment } from "libs/shared/ui/src/lib/types";
import { Food, FoodGroup, FullFoodGroupFragment } from "../../../types";
import { remove } from "../../../utils/remove";

export interface FoodPreferenceState {
  promotedFoods: readonly Pick<Food, "id" | "name">[];
  avoidedFoods: readonly Pick<Food, "id" | "name">[];
  promotedFoodGroups: readonly SimpleFoodGroupFragment[];
  avoidedFoodGroups: readonly SimpleFoodGroupFragment[];
}

interface AddAvoidedFood {
  type: "ADD_AVOIDED_FOOD";
  payload: Pick<Food, "id" | "name">;
}
interface AddPromotedFood {
  type: "ADD_PROMOTED_FOOD";
  payload: Pick<Food, "id" | "name">;
}
interface AddAvoidedFoodGroup {
  type: "ADD_AVOIDED_FOOD_GROUP";
  payload: SimpleFoodGroupFragment;
}
interface AddPromotedFoodGroup {
  type: "ADD_PROMOTED_FOOD_GROUP";
  payload: SimpleFoodGroupFragment;
}

interface RemoveAvoidedFood {
  type: "REMOVE_AVOIDED_FOOD";
  payload: Pick<Food, "id" | "name">;
}
interface RemovePromotedFood {
  type: "REMOVE_PROMOTED_FOOD";
  payload: Pick<Food, "id" | "name">;
}
interface RemoveAvoidedFoodGroup {
  type: "REMOVE_AVOIDED_FOOD_GROUP";
  payload: Pick<FullFoodGroupFragment, "id" | "name">;
}
interface RemovePromotedFoodGroup {
  type: "REMOVE_PROMOTED_FOOD_GROUP";
  payload: Pick<FullFoodGroupFragment, "id" | "name">;
}

export const pluckIdsFromFoodPreferenceState = (state: FoodPreferenceState) => {
  return {
    promotedFoodIds: state.promotedFoods.map(x => x.id),
    avoidedFoodIds: state.avoidedFoods.map(x => x.id),
    promotedFoodGroupIds: state.promotedFoodGroups.map(x => x.id),
    avoidedFoodGroupIds: state.avoidedFoodGroups.map(x => x.id),
  };
};

export type FoodPreferenceAction =
  | AddAvoidedFood
  | AddPromotedFood
  | AddAvoidedFoodGroup
  | AddPromotedFoodGroup
  | RemoveAvoidedFood
  | RemovePromotedFood
  | RemoveAvoidedFoodGroup
  | RemovePromotedFoodGroup;

const foodExists = (state: FoodPreferenceState, food: Pick<Food, "id" | "name">): boolean => {
  return !!state.avoidedFoods.find(f => f.id === food.id) || !!state.promotedFoods.find(f => f.id === food.id);
};

const foodGroupExists = (state: FoodPreferenceState, food: Pick<FoodGroup, "id" | "name">): boolean => {
  return !!state.avoidedFoodGroups.find(f => f.id === food.id) || !!state.promotedFoodGroups.find(f => f.id === food.id);
};
const idEqFn = (x: { id: string }, y: { id: string }) => x.id === y.id;

export const foodPreferenceReducer = (state: FoodPreferenceState, action: FoodPreferenceAction): FoodPreferenceState => {
  switch (action.type) {
    case "ADD_AVOIDED_FOOD":
      if (foodExists(state, action.payload)) {
        return state;
      }
      return {
        ...state,
        avoidedFoods: [...state.avoidedFoods, action.payload],
      };
    case "ADD_PROMOTED_FOOD":
      if (foodExists(state, action.payload)) {
        return state;
      }
      return {
        ...state,
        promotedFoods: [...state.promotedFoods, action.payload],
      };
    case "ADD_AVOIDED_FOOD_GROUP":
      if (foodGroupExists(state, action.payload)) {
        return state;
      }
      return {
        ...state,
        avoidedFoodGroups: [...state.avoidedFoodGroups, action.payload],
      };
    case "ADD_PROMOTED_FOOD_GROUP":
      if (foodGroupExists(state, action.payload)) {
        return state;
      }
      return {
        ...state,
        promotedFoodGroups: [...state.promotedFoodGroups, action.payload],
      };
    case "REMOVE_AVOIDED_FOOD":
      return {
        ...state,
        avoidedFoods: remove(state.avoidedFoods, action.payload, idEqFn),
      };
    case "REMOVE_PROMOTED_FOOD":
      return {
        ...state,
        promotedFoods: remove(state.promotedFoods, action.payload, idEqFn),
      };
    case "REMOVE_AVOIDED_FOOD_GROUP":
      return {
        ...state,
        avoidedFoodGroups: remove(state.avoidedFoodGroups, action.payload, idEqFn),
      };
    case "REMOVE_PROMOTED_FOOD_GROUP":
      return {
        ...state,
        promotedFoodGroups: remove(state.promotedFoodGroups, action.payload, idEqFn),
      };
  }
};

export const foodPreferenceFormToSaveTooltips = (state: FoodPreferenceState): string[] => {
  let tooltips: string[] = [];
  return tooltips;
};

export const initialFoodPreferenceState = (input: Partial<FoodPreferenceState>): FoodPreferenceState => ({
  avoidedFoods: input.avoidedFoods || [],
  promotedFoods: input.promotedFoods || [],
  avoidedFoodGroups: input.avoidedFoodGroups || [],
  promotedFoodGroups: input.promotedFoodGroups || [],
});

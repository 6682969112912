import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Card, CardActionArea, CardContent, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { SectionHeader } from "apps/web/src/componentLibrary/SectionHeader";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import { CopyableMealPlanFragment } from "apps/web/src/types";
import React, { Dispatch } from "react";
import { MealPlanCopyOptionsFormAction, MealPlanCopyOptionsFormState } from "./utils";

interface MealPlanCopyOptionsFormProps {
  toCopyMealPlan: CopyableMealPlanFragment;
  state: MealPlanCopyOptionsFormState;
  dispatch: Dispatch<MealPlanCopyOptionsFormAction>;
}

const MealPlanCopyOptionsForm = ({ state, dispatch, toCopyMealPlan }: MealPlanCopyOptionsFormProps) => {
  const exactCopyTitle = toCopyMealPlan.type === "exchange" ? "Exact Copy" : "Adjust Daily Targets + Copy Meal Targets";

  const exactCopyMsg =
    toCopyMealPlan.type === "exchange"
      ? `Exchanges will be copied exactly from the original meal plan`
      : `Each meal's macros will be copied exactly from the original meal plan, daily targets will adjust to the new athlete`;

  const autoAdjustMsg =
    toCopyMealPlan.type === "exchange"
      ? `Exchanges will be auto-adjusted using Nutrition's internal algorithm using anthropometry and goal`
      : `Both daily and meal macros will be auto-adjusted based on athlete anthropometry and goal`;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h3">{toCopyMealPlan.type === "exchange" ? "Exchanges" : "Macros"} Targets</Typography>
      <Box sx={{ height: "100%", display: "flex", gap: 2 }}>
        <Box sx={{ height: "100%", flexGrow: 1, maxWidth: 700, display: "flex", flexDirection: "column", gap: 2 }}>
          <Card>
            <CardActionArea onClick={() => dispatch({ type: "CHANGE_AUTOMATION_LEVEL", payload: "COPY" })}>
              <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <SectionHeader header={exactCopyTitle} subcopy={exactCopyMsg} />
                {state.automationStrategy === "COPY" && <CheckCircleIcon fontSize="large" color="success" />}
              </CardContent>
            </CardActionArea>
          </Card>
          {toCopyMealPlan.type === "exchange" && (
            <Card>
              <CardActionArea onClick={() => dispatch({ type: "CHANGE_AUTOMATION_LEVEL", payload: "FIX_AND_ADJUST" })}>
                <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <SectionHeader
                      header="Fix and Adjust"
                      subcopy="Exchanges below will be fixed, others will adjust based on athlete's anthropometry and goal."
                    />
                    {state.automationStrategy === "FIX_AND_ADJUST" && <CheckCircleIcon fontSize="large" color="success" />}
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      type="number"
                      disabled={state.automationStrategy !== "FIX_AND_ADJUST"}
                      label="Veg"
                      value={state.vegetableServingsInput}
                      onChange={e => dispatch({ type: "CHANGE_VEGETABLE_SERVINGS", payload: e.target.value })}
                    />
                    <TextField
                      disabled={state.automationStrategy !== "FIX_AND_ADJUST"}
                      label="Fruit"
                      value={state.fruitServingsInput}
                      onChange={e => dispatch({ type: "CHANGE_FRUIT_SERVINGS", payload: e.target.value })}
                    />
                    <TextField
                      disabled={state.automationStrategy !== "FIX_AND_ADJUST"}
                      label="Dairy"
                      value={state.dairyServingsInput}
                      onChange={e => dispatch({ type: "CHANGE_DAIRY_SERVINGS", payload: e.target.value })}
                    />
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          <Card>
            <CardActionArea onClick={() => dispatch({ type: "CHANGE_AUTOMATION_LEVEL", payload: "ADJUST" })}>
              <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <SectionHeader header="Auto Adjust All Targets" subcopy={autoAdjustMsg} />
                {state.automationStrategy === "ADJUST" && <CheckCircleIcon fontSize="large" color="success" />}
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
        <Card sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <SectionHeader header="Additional Options" />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.copyFoodPreferences}
                    onChange={e => dispatch({ type: "CHANGE_COPY_FOOD_PREFERENCES", payload: e.target.checked })}
                  />
                }
                label="Copy Promoted/Avoided Foods"
              />
              <TWTooltip
                title="Copy Promoted/Avoided Foods"
                subcopy="If checked, promoted/avoided foods and food groups will be copied to the new meal plans. This will effect exchange lists on the new plan by auto-deselecting avoided foods."
              >
                <InfoIcon />
              </TWTooltip>
            </Box>
            {toCopyMealPlan.type === "exchange" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.copyPickListServingIds}
                      onChange={e => dispatch({ type: "CHANGE_COPY_PICK_LIST_SERVING_IDS", payload: e.target.checked })}
                    />
                  }
                  label="Copy Pick Lists"
                />
                <TWTooltip
                  title="Copy Pick Lists"
                  subcopy="If checked, all de-selected servings on the original meal plan's pick lists will also be de-selected in new meal plans."
                >
                  <InfoIcon />
                </TWTooltip>
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default MealPlanCopyOptionsForm;

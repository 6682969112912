import { InputProps } from "@mui/material";
import React from "react";
import { GoalTypeFragment, useGoalTypesQuery } from "../../types";
import LabeledSelect, { TextBoxVariant } from "../universal/LabeledSelect";

interface GoalTypeSelectProps {
  selectedType: GoalTypeFragment | null;
  onChange: (type: GoalTypeFragment) => void;
  disabled?: boolean;
  required?: boolean;
  variant?: TextBoxVariant | undefined;
  InputProps?: InputProps;
  inputProps?: any;
  className?: string;
  error?: boolean;
  helperText?: string;
}

const GoalTypeSelect = ({
  selectedType,
  onChange,
  disabled,
  required,
  variant,
  InputProps,
  inputProps,
  helperText,
  error,
  className,
}: GoalTypeSelectProps) => {
  const { data, loading } = useGoalTypesQuery();
  const goalTypes = !loading && data ? data.goalTypes : [];

  return (
    <LabeledSelect
      variant={variant}
      required={required}
      placeholder="Weight Target Type"
      options={[...goalTypes]}
      selectedOption={selectedType}
      onChange={onChange}
      optionToName={o => o.name}
      textFieldProps={{
        disabled,
        margin: "dense",
      }}
      InputProps={InputProps}
      inputProps={inputProps}
      className={className}
      error={error}
      helperText={helperText}
    />
  );
};

export default GoalTypeSelect;

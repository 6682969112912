import React, { forwardRef } from "react";
import MaskedInput from "react-text-mask";

export const PhoneNumberMask = forwardRef((props, _) => (
  <MaskedInput
    {...props}
    mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
    placeholderChar="_"
    placeholder="(___) ___-____"
  />
));

export const CountryCodeMask = forwardRef((props, _) => (
  <MaskedInput
    {...props}
    guide={false}
    mask={["+", /\d/, /\d/, /\d/]}
    placeholder="+1" />
));

export const heightTextMaskFunction = (rawValue: string) => {
  const decimal = rawValue.match(/\./);
  const numbers = rawValue.match(/\d/g) || ([] as string[]);

  if (numbers.length > 2 && numbers[1] === "1" && (numbers[2] === "0" || numbers[2] === "1")) {
    if (decimal) {
      return [/\d/, "'", /\d/, /\d/, /\./, /\d/, '"'];
    } else {
      return [/\d/, "'", /\d/, /\d/, '"'];
    }
  } else {
    if (decimal) {
      return [/\d/, "'", /\d/, /\./, /\d/, '"'];
    } else {
      return [/\d/, "'", /\d/, '"'];
    }
  }
};

export const HeightTextMask = forwardRef((props, _) => (
  <MaskedInput
    {...props}
    mask={heightTextMaskFunction}
    placeholderChar="_"
    placeholder={"_'_\""} />
));

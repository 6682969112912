import { StandaloneMealMenu, Team } from "libs/shared/ui/src/lib/types";

type MealMenusCheckedInDataPayload = {
  mealMenuExpectedAttendeeCount: number;
  mealMenuActualAttendeeCount: number;
  attendeeUnlistedCount: number;
  attendeeListedCount: number;
  mealMenu: Pick<StandaloneMealMenu, "id" | "name" | "type" | "start"> & {
    teams: readonly Pick<Team, "id" | "name">[];
  };
};

export interface ViewAttendanceRowInput {
  id: string;
  start: string;
  name: string;
  type: string;
  teamIds: string[];
  mealMenuExpectedAttendeeCount: number;
  mealMenuActualAttendeeCount: number;
  attendeeUnlistedCount: number;
  attendeeListedCount: number;
}

export const MealMenusCheckInDataToViewAttendanceRows = (payload: MealMenusCheckedInDataPayload[]): ViewAttendanceRowInput[] =>
  payload.map(({ mealMenu, mealMenuExpectedAttendeeCount, mealMenuActualAttendeeCount, attendeeUnlistedCount, attendeeListedCount }) => ({
    id: mealMenu.id,
    start: mealMenu.start,
    name: mealMenu.name,
    type: mealMenu.type,
    teamIds: mealMenu.teams.map(t => t.id),
    mealMenuExpectedAttendeeCount,
    mealMenuActualAttendeeCount,
    attendeeUnlistedCount,
    attendeeListedCount,
  }));

import {
  assignProfilesToSyncRules,
  getIsValidSyncRule,
  getMatchingSyncRuleForProfile,
  LinkedProfile,
  NotemealProfile,
  TeamworksProfile,
  TeamworksProfilesState,
  TeamworksProfileSyncRuleState,
  TeamworksTeam,
} from "@notemeal/profile-sync";
import { sortByKey } from "@notemeal/utils/sort";
import {
  AthleteForLinkingFragment,
  NotemealAccountType,
  OrgMembershipForLinkingFragment,
  TeamworksGender,
  TeamworksGroupFragment,
  TeamworksProfileFragment,
  TeamworksTeamFragment,
} from "../../../../../types";
import newId from "@notemeal/shared/ui/utils/newId";

interface AddProfileSyncRuleAction {
  type: "ADD_PROFILE_SYNC_RULE";
  payload: {
    profileSyncRuleId: string;
  };
}

interface EditProfileSyncRuleAction {
  type: "EDIT_PROFILE_SYNC_RULE";
  payload: {
    profileSyncRuleId: string;
    teams: readonly TeamworksTeamFragment[];
    userTypes: readonly TeamworksGroupFragment[];
    profiles: readonly TeamworksProfileFragment[];
    athleteStatuses: readonly TeamworksGroupFragment[];
    positions: readonly TeamworksGroupFragment[];
    matchNotemealTeamIds: readonly string[];
    genders: readonly TeamworksGender[];
    notemealAccountType: NotemealAccountType;
    matchOnProfiles: boolean;
    onlyNotemealProfilesOnSelectedTeams: boolean;
  };
}

interface LinkProfileAction {
  type: "LINK_PROFILE";
  payload: {
    teamworks: TeamworksProfileFragment;
    notemeal: AthleteForLinkingFragment | OrgMembershipForLinkingFragment | null;
  };
}

interface UnlinkProfileAction {
  type: "UNLINK_PROFILE";
  payload: {
    teamworks: TeamworksProfileFragment;
    notemeal: AthleteForLinkingFragment | OrgMembershipForLinkingFragment;
    newEmail?: string;
    newPhone?: string;
    archiveAthlete?: boolean;
  };
}

interface UndoUnlinkProfileAction {
  type: "UNDO_UNLINK_PROFILE";
  payload: {
    teamworks: TeamworksProfileFragment;
    notemeal: AthleteForLinkingFragment | OrgMembershipForLinkingFragment;
  };
}

interface UnlinkRelinkProfileAction {
  type: "UNLINK_RELINK_PROFILE";
  payload: {
    teamworks: TeamworksProfileFragment;
    newNotemeal: AthleteForLinkingFragment | OrgMembershipForLinkingFragment;
    oldNotemeal: AthleteForLinkingFragment | OrgMembershipForLinkingFragment;
    linkedTeamworksTeams: readonly TeamworksTeam[];
    newEmail?: string;
    newPhone?: string;
    archiveAthlete?: boolean;
  };
}

interface UndoUnlinkRelinkProfileAction {
  type: "UNDO_UNLINK_RELINK_PROFILE";
  payload: {
    teamworks: TeamworksProfileFragment;
    notemeal: AthleteForLinkingFragment | OrgMembershipForLinkingFragment;
  };
}

interface LinkMultipleProfilesAction {
  type: "LINK_MULTIPLE_PROFILES";
  payload: LinkedProfile[];
}

interface DelinkProfileAction {
  type: "DELINK_PROFILE";
  payload: {
    teamworks: TeamworksProfileFragment;
    notemeal: AthleteForLinkingFragment | OrgMembershipForLinkingFragment | null;
  };
}

interface RemoveProfileSyncRuleAction {
  type: "REMOVE_PROFILE_SYNC_RULE";
  payload: {
    profileSyncRuleId: string;
    linkedTeamworksTeams: readonly TeamworksTeam[];
  };
}

interface ReorderMatchOnProfileProfileSyncRules {
  type: "REORDER_MATCH_PROFILE_SYNC_RULES";
  payload: string[];
}

interface ReorderOtherProfileSyncRules {
  type: "REORDER_OTHER_PROFILE_SYNC_RULES";
  payload: string[];
}

export type NotemealOnlyOrgMembershipProfile = OrgMembershipForLinkingFragment & {
  isPending?: boolean;
};

export type NotemealOnlyAthleteProfile = AthleteForLinkingFragment & {
  isPending?: boolean;
};

export type NotemealOnlyProfile = NotemealOnlyOrgMembershipProfile | NotemealOnlyAthleteProfile;

interface AddNotemealOnlyOrgMembershipAction {
  type: "ADD_NOTEMEAL_ONLY_ORG_MEMBERSHIP_ACTION";
  payload: NotemealOnlyOrgMembershipProfile;
}

interface RemoveNotemealOnlyOrgMembershipAction {
  type: "REMOVE_NOTEMEAL_ONLY_ORG_MEMBERSHIP_ACTION";
  payload: NotemealOnlyOrgMembershipProfile;
}

interface AddNotemealOnlyAthleteAction {
  type: "ADD_NOTEMEAL_ONLY_ATHLETE_ACTION";
  payload: NotemealOnlyAthleteProfile;
}

interface RemoveNotemealOnlyAthleteAction {
  type: "REMOVE_NOTEMEAL_ONLY_ATHLETE_ACTION";
  payload: NotemealOnlyAthleteProfile;
}

interface ArchiveAthlete {
  type: "ARCHIVE_ATHLETE";
  payload: {
    athleteId: string;
  };
}

interface UnarchiveAthlete {
  type: "UNARCHIVE_ATHLETE";
  payload: {
    athleteId: string;
  };
}

export type TeamworksProfilesAction =
  | AddProfileSyncRuleAction
  | EditProfileSyncRuleAction
  | RemoveProfileSyncRuleAction
  | LinkProfileAction
  | UnlinkProfileAction
  | UndoUnlinkProfileAction
  | UnlinkRelinkProfileAction
  | UndoUnlinkRelinkProfileAction
  | DelinkProfileAction
  | LinkMultipleProfilesAction
  | AddNotemealOnlyOrgMembershipAction
  | RemoveNotemealOnlyOrgMembershipAction
  | ArchiveAthlete
  | UnarchiveAthlete
  | ReorderMatchOnProfileProfileSyncRules
  | ReorderOtherProfileSyncRules
  | AddNotemealOnlyAthleteAction
  | RemoveNotemealOnlyAthleteAction;

export const teamworksProfilesReducer = (state: TeamworksProfilesState, action: TeamworksProfilesAction): TeamworksProfilesState => {
  switch (action.type) {
    case "ADD_PROFILE_SYNC_RULE":
      return {
        ...state,
        syncRules: [
          ...state.syncRules,
          {
            id: action.payload.profileSyncRuleId,
            teams: [],
            userTypes: [],
            athleteStatuses: [],
            profiles: [],
            positions: [],
            matchNotemealTeamIds: [],
            genders: [],
            matchOnProfiles: false,
            onlyNotemealProfilesOnSelectedTeams: false,
            notemealAccountType: "athlete",
            priority: state.syncRules.length === 0 ? 1 : Math.max(...state.syncRules.map(r => r.priority)) + 1,
          },
        ],
      };
    case "EDIT_PROFILE_SYNC_RULE":
      return enforceRulePriority({
        ...state,
        syncRules: state.syncRules.map(r => {
          if (r.id === action.payload.profileSyncRuleId) {
            // Remove positions that are on teams no longer in the rule if teams are edited
            const teams = action.payload.teams;
            let positions = action.payload.positions;
            // if the teams are the same dont process to preserve the object references.
            if (r.teams !== teams) {
              const teamIds = teams.map(({ id }) => id);
              // Remove positions that are on teams no longer in the rule
              const newPositions = action.payload.positions.filter(position => position.teamId && teamIds.includes(position.teamId));
              // If the filtered and unfiltered versions are the same preserve the object reference
              if (newPositions.length !== positions.length) {
                positions = newPositions;
              }
            }
            return {
              id: r.id,
              priority: r.priority,
              teams,
              matchNotemealTeamIds: action.payload.matchNotemealTeamIds,
              positions,
              genders: action.payload.genders,
              userTypes: action.payload.userTypes,
              athleteStatuses: action.payload.athleteStatuses,
              profiles: action.payload.profiles,
              notemealAccountType: action.payload.notemealAccountType,
              matchOnProfiles: action.payload.matchOnProfiles,
              onlyNotemealProfilesOnSelectedTeams: action.payload.onlyNotemealProfilesOnSelectedTeams,
            };
          }
          return r;
        }),
      });
    case "LINK_PROFILE":
      const notemealProfileIsUnlinked = action.payload.notemeal
        ? !!state.unlinkedNotemealProfiles.find(p => p.id === action.payload.notemeal?.id)
        : true;
      const teamworksProfileIsUnlinked = !!state.unlinkedTeamworksProfiles.find(p => p.id === action.payload.teamworks.id);
      if (!notemealProfileIsUnlinked || !teamworksProfileIsUnlinked) {
        return state;
      }
      return {
        ...state,
        linkedProfiles: [
          ...state.linkedProfiles,
          {
            isPending: true,
            teamworks: action.payload.teamworks,
            notemeal: action.payload.notemeal,
          },
        ],
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles.filter(om => action.payload.notemeal?.id !== om.id)],
        unlinkedTeamworksProfiles: [
          ...state.unlinkedTeamworksProfiles.filter(unlinkedProfile => action.payload.teamworks.id !== unlinkedProfile.id),
        ],
      };
    // TODO: Test
    case "LINK_MULTIPLE_PROFILES":
      // make sure each is unlinked
      const notemealProfilesAllUnlinked = action.payload.every(({ notemeal }) =>
        notemeal ? !!state.unlinkedNotemealProfiles.find(om => om.id === notemeal.id) : true
      );
      const teamworksProfilesAllUnlinked = action.payload.every(
        ({ teamworks }) => !!state.unlinkedTeamworksProfiles.find(p => p.id === teamworks.id)
      );
      if (!notemealProfilesAllUnlinked || !teamworksProfilesAllUnlinked) {
        return state;
      }
      const notemealIds = action.payload.flatMap(({ notemeal }) => (notemeal ? notemeal.id : []));
      const teamworksIds = action.payload.map(({ teamworks }) => teamworks.id);

      return {
        ...state,
        linkedProfiles: [...state.linkedProfiles, ...action.payload],
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles.filter(om => !notemealIds.includes(om.id))],
        unlinkedTeamworksProfiles: [
          ...state.unlinkedTeamworksProfiles.filter(unlinkedProfile => !teamworksIds.includes(unlinkedProfile.id)),
        ],
      };

    case "UNLINK_PROFILE": {
      const linkedProfile = state.linkedProfiles.find(
        p => p.notemeal?.id === action.payload.notemeal.id && p.teamworks.id === action.payload.teamworks.id && !p.isPending
      );
      if (!linkedProfile) {
        return state;
      }
      return {
        ...state,
        linkedProfiles: [...state.linkedProfiles.filter(p => p.notemeal?.id !== action.payload.notemeal.id)],
        unlinkProfiles: [
          ...state.unlinkProfiles,
          {
            ...action.payload,
            newEmail: action.payload.newEmail ?? undefined,
            newPhone: action.payload.newPhone ?? undefined,
            archiveAthlete: action.payload.archiveAthlete ?? false,
          },
        ],
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles, ...(action.payload.notemeal ? [action.payload.notemeal] : [])],
        unlinkedTeamworksProfiles: [...state.unlinkedTeamworksProfiles, action.payload.teamworks],
      };
    }

    case "UNDO_UNLINK_PROFILE": {
      const unlinkProfile = state.unlinkProfiles.find(
        p => p.notemeal.id === action.payload.notemeal.id && p.teamworks.id === action.payload.teamworks.id
      );
      if (!unlinkProfile) {
        return state;
      }

      return {
        ...state,
        linkedProfiles: [
          ...state.linkedProfiles,
          { isPending: false, teamworks: action.payload.teamworks, notemeal: action.payload.notemeal },
        ],
        unlinkProfiles: [...state.unlinkProfiles.filter(p => p.notemeal?.id !== action.payload.notemeal.id)],
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles.filter(p => p.id !== action.payload.notemeal.id)],
        unlinkedTeamworksProfiles: [...state.unlinkedTeamworksProfiles.filter(p => p.id !== action.payload.teamworks.id)],
      };
    }

    case "UNLINK_RELINK_PROFILE": {
      const profileToUnlink = state.linkedProfiles.find(
        p => p.notemeal?.id === action.payload.oldNotemeal.id && p.teamworks.id === action.payload.teamworks.id && !p.isPending
      );
      const notemealProfileToRelink = state.unlinkedNotemealProfiles.find(p => p.id === action.payload.newNotemeal.id);
      if (!profileToUnlink || !notemealProfileToRelink) {
        return state;
      }

      let profileMatchSyncRule = null;
      if (
        !relinkHasMatchingProfileSyncRule(
          state.syncRules,
          action.payload.linkedTeamworksTeams,
          action.payload.teamworks,
          action.payload.newNotemeal
        )
      ) {
        profileMatchSyncRule = createProfileMatchSyncRule(state.syncRules, action.payload.teamworks, action.payload.newNotemeal);
      }

      return enforceRulePriority({
        ...state,
        linkedProfiles: [
          ...state.linkedProfiles.filter(
            p => p.notemeal?.id !== action.payload.oldNotemeal.id && p.notemeal?.id !== action.payload.newNotemeal.id
          ),
          { isPending: true, teamworks: action.payload.teamworks, notemeal: action.payload.newNotemeal },
        ],
        unlinkedNotemealProfiles: [
          ...state.unlinkedNotemealProfiles.filter(p => p.id !== action.payload.newNotemeal.id),
          action.payload.oldNotemeal,
        ],
        unlinkProfiles: [
          ...state.unlinkProfiles,
          {
            teamworks: action.payload.teamworks,
            notemeal: action.payload.oldNotemeal,
            newEmail: action.payload.newEmail ?? undefined,
            newPhone: action.payload.newPhone ?? undefined,
            archiveAthlete: false,
          },
        ],
        ...(profileMatchSyncRule ? { syncRules: [...state.syncRules, profileMatchSyncRule] } : {}),
      });
    }

    case "UNDO_UNLINK_RELINK_PROFILE": {
      const unlinkProfile = state.unlinkProfiles.find(
        p => p.notemeal?.id === action.payload.notemeal.id && p.teamworks.id === action.payload.teamworks.id
      );
      const relinkProfile = state.linkedProfiles.find(p => p.teamworks.id === action.payload.teamworks.id && p.isPending);
      if (!unlinkProfile || !relinkProfile || !relinkProfile.notemeal) {
        return state;
      }

      return enforceRulePriority({
        ...state,
        linkedProfiles: [
          ...state.linkedProfiles.filter(p => action.payload.teamworks.id !== p.teamworks.id),
          {
            ...action.payload,
            isPending: false,
          },
        ],
        unlinkProfiles: [...state.unlinkProfiles.filter(p => p.notemeal?.id !== action.payload.notemeal.id)],
        unlinkedNotemealProfiles: [
          ...state.unlinkedNotemealProfiles.filter(p => p.id !== action.payload.notemeal.id),
          relinkProfile.notemeal,
        ],
        syncRules: [...state.syncRules.filter(r => !r.profiles.includes(action.payload.teamworks))],
      });
    }

    case "DELINK_PROFILE":
      const linkedProfileExists = !!state.linkedProfiles.find(({ teamworks, notemeal, isPending }) => {
        return isPending && teamworks.id === action.payload.teamworks.id && notemeal?.id === action.payload.notemeal?.id;
      });
      if (!linkedProfileExists) {
        return state;
      }
      return {
        ...state,
        linkedProfiles: [
          ...state.linkedProfiles.filter(linkedProfile => {
            if (!linkedProfile.isPending) {
              return true;
            }
            const matchesDelink =
              linkedProfile.teamworks.id === action.payload.teamworks.id && linkedProfile.notemeal?.id === action.payload.notemeal?.id;
            return !matchesDelink;
          }),
        ],
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles, ...(action.payload.notemeal ? [action.payload.notemeal] : [])],
        unlinkedTeamworksProfiles: [...state.unlinkedTeamworksProfiles, action.payload.teamworks],
      };
    case "REMOVE_PROFILE_SYNC_RULE":
      // In addition to removing the profile sync rule, also delink any pending links that were assigned to that rule
      const syncRulesWithEntities = assignProfilesToSyncRules(state, action.payload.linkedTeamworksTeams).syncRules.find(
        r => r.id === action.payload.profileSyncRuleId
      );
      const delinkProfiles =
        syncRulesWithEntities?.linkedProfiles.flatMap(({ teamworks, notemeal, isPending }) => (isPending ? { teamworks, notemeal } : [])) ??
        [];
      const stateWithoutRule = {
        ...state,
        syncRules: state.syncRules.filter(r => r.id !== action.payload.profileSyncRuleId),
      };
      return enforceRulePriority(
        delinkProfiles.reduce<TeamworksProfilesState>(
          (state, { teamworks, notemeal }) =>
            teamworksProfilesReducer(state, {
              type: "DELINK_PROFILE",
              payload: {
                teamworks,
                notemeal,
              },
            }),
          stateWithoutRule
        )
      );
    case "ADD_NOTEMEAL_ONLY_ORG_MEMBERSHIP_ACTION": {
      const addProfile = action.payload;
      const isPending = !addProfile.isPending;
      const newAddProfile = { ...addProfile, isPending };
      return {
        ...state,
        notemealOnlyState: {
          ...state.notemealOnlyState,
          notemealOnlyOrgMemberships: [...state.notemealOnlyState.notemealOnlyOrgMemberships, newAddProfile],
          removeNotemealOnlyOrgMemberships: state.notemealOnlyState.removeNotemealOnlyOrgMemberships.filter(
            ({ id }) => id !== addProfile.id
          ),
        },
        unlinkedNotemealProfiles: state.unlinkedNotemealProfiles.filter(({ id }) => id !== addProfile.id),
      };
    }
    case "REMOVE_NOTEMEAL_ONLY_ORG_MEMBERSHIP_ACTION": {
      const removeProfile = action.payload;
      const isPending = !removeProfile.isPending;
      const newRemovedProfile = { ...removeProfile, isPending };
      return {
        ...state,
        notemealOnlyState: {
          ...state.notemealOnlyState,
          notemealOnlyOrgMemberships: state.notemealOnlyState.notemealOnlyOrgMemberships.filter(({ id }) => id !== removeProfile.id),
          removeNotemealOnlyOrgMemberships: [
            ...state.notemealOnlyState.removeNotemealOnlyOrgMemberships,
            ...(isPending ? [newRemovedProfile] : []),
          ],
        },
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles, newRemovedProfile],
      };
    }
    case "ADD_NOTEMEAL_ONLY_ATHLETE_ACTION": {
      const addAthlete = action.payload;
      const isPending = !addAthlete.isPending;
      const newAddProfile = { ...addAthlete, isPending };
      return {
        ...state,
        notemealOnlyState: {
          ...state.notemealOnlyState,
          notemealOnlyAthletes: [...state.notemealOnlyState.notemealOnlyAthletes, newAddProfile],
          removeNotemealOnlyAthletes: state.notemealOnlyState.removeNotemealOnlyAthletes.filter(({ id }) => id !== addAthlete.id),
        },
        unlinkedNotemealProfiles: state.unlinkedNotemealProfiles.filter(({ id }) => id !== addAthlete.id),
      };
    }
    case "REMOVE_NOTEMEAL_ONLY_ATHLETE_ACTION": {
      const removeProfile = action.payload;
      const isPending = !removeProfile.isPending;
      const newRemovedProfile = { ...removeProfile, isPending };
      return {
        ...state,
        notemealOnlyState: {
          ...state.notemealOnlyState,
          notemealOnlyAthletes: state.notemealOnlyState.notemealOnlyAthletes.filter(({ id }) => id !== removeProfile.id),
          removeNotemealOnlyAthletes: [...state.notemealOnlyState.removeNotemealOnlyAthletes, ...(isPending ? [newRemovedProfile] : [])],
        },
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles, newRemovedProfile],
      };
    }
    case "ARCHIVE_ATHLETE": {
      const athlete = state.unlinkedNotemealProfiles.find(p => p.id === action.payload.athleteId);
      if (!athlete || athlete.__typename !== "Athlete") {
        return state;
      }
      return {
        ...state,
        unlinkedNotemealProfiles: state.unlinkedNotemealProfiles.filter(p => p.id !== action.payload.athleteId),
        archiveAthletes: [...state.archiveAthletes, athlete],
      };
    }
    case "UNARCHIVE_ATHLETE": {
      const athlete = state.archiveAthletes.find(a => a.id === action.payload.athleteId);
      if (!athlete) {
        return state;
      }
      return {
        ...state,
        unlinkedNotemealProfiles: [...state.unlinkedNotemealProfiles, athlete],
        archiveAthletes: state.archiveAthletes.filter(a => a.id !== action.payload.athleteId),
      };
    }
    case "REORDER_MATCH_PROFILE_SYNC_RULES": {
      const allRules = sortByKey(state.syncRules, "priority");
      const matchOnProfileRules = allRules.filter(rule => rule.matchOnProfiles);
      const hasAllIds =
        matchOnProfileRules.length === action.payload.length && matchOnProfileRules.every(rule => action.payload.includes(rule.id));
      if (!hasAllIds) {
        return state;
      }
      const orderedMatchRules = sortByKey(
        matchOnProfileRules.map(rule => {
          const priority = action.payload.indexOf(rule.id) + 1;
          return {
            ...rule,
            priority,
          };
        }),
        "priority"
      );
      const otherRules = allRules.filter(rule => !rule.matchOnProfiles);
      return {
        ...state,
        syncRules: [...orderedMatchRules, ...otherRules],
      };
    }
    case "REORDER_OTHER_PROFILE_SYNC_RULES": {
      const allRules = sortByKey(state.syncRules, "priority");
      const matchOnProfileRules = allRules.filter(rule => rule.matchOnProfiles);
      const otherRules = allRules.filter(rule => !rule.matchOnProfiles);
      const hasAllIds = otherRules.length === action.payload.length && otherRules.every(rule => action.payload.includes(rule.id));
      if (!hasAllIds) {
        return state;
      }
      const orderedOtherRules = sortByKey(
        otherRules.map(rule => {
          const priority = matchOnProfileRules.length + action.payload.indexOf(rule.id) + 1;
          return {
            ...rule,
            priority,
          };
        }),
        "priority"
      );
      return {
        ...state,
        syncRules: [...matchOnProfileRules, ...orderedOtherRules],
      };
    }
  }
};

export const enforceRulePriority = (state: TeamworksProfilesState): TeamworksProfilesState => {
  const allRules = sortByKey(state.syncRules, "priority");
  const matchOnProfileRules = allRules.filter(rule => rule.matchOnProfiles).map((rule, index) => ({ ...rule, priority: index + 1 }));
  const otherRules = allRules
    .filter(rule => !rule.matchOnProfiles)
    .map((rule, index) => ({ ...rule, priority: index + 1 + matchOnProfileRules.length }));
  return {
    ...state,
    syncRules: [...matchOnProfileRules, ...otherRules],
  };
};

const relinkHasMatchingProfileSyncRule = (
  syncRules: readonly TeamworksProfileSyncRuleState[],
  allLinkedTeamworkTeams: readonly TeamworksTeam[],
  teamworksProfile: TeamworksProfile,
  relinkProfile: NotemealProfile
): boolean => {
  const sortedSyncRules = sortByKey(syncRules, "priority").map(rule => {
    return {
      ...rule,
      unlinkedProfiles: [],
      linkedProfiles: [],
    };
  });
  const validSyncRules = sortedSyncRules.filter(rule => getIsValidSyncRule(rule, allLinkedTeamworkTeams).isValidSyncRule);
  const matchingRule = getMatchingSyncRuleForProfile(
    validSyncRules,
    teamworksProfile,
    allLinkedTeamworkTeams.map(t => t.id)
  );
  if (!matchingRule) {
    return false;
  }
  const matchingRuleAccountType = matchingRule.matchingRule.notemealAccountType;
  if (relinkProfile.__typename === "OrgMembership") {
    if (matchingRuleAccountType === "athlete" && relinkProfile.roles.length > 1) {
      // if roles is not exclusively athlete, we want to create a rule for the higher priority role so return false
      return false;
    }
    return relinkProfile.roles.includes(matchingRuleAccountType);
  } else {
    return matchingRuleAccountType === "athlete";
  }
};

const createProfileMatchSyncRule = (
  syncRules: readonly TeamworksProfileSyncRuleState[],
  teamworksProfile: TeamworksProfile,
  notemealProfile: NotemealProfile
) => {
  const profileSyncRuleId = newId();
  const notemealAccountType = getNotemealAccountTypeForProfileMatchSyncRule(notemealProfile);
  const numProfileMatchRules = syncRules.filter(rule => rule.matchOnProfiles).length;
  return {
    id: profileSyncRuleId,
    teams: [],
    userTypes: [],
    athleteStatuses: [],
    profiles: [teamworksProfile],
    positions: [],
    matchNotemealTeamIds: [],
    genders: [],
    matchOnProfiles: true,
    onlyNotemealProfilesOnSelectedTeams: false,
    notemealAccountType,
    priority: numProfileMatchRules + 1,
  };
};

const getNotemealAccountTypeForProfileMatchSyncRule = (profileToRelink: NotemealProfile): NotemealAccountType => {
  // create a profile-based rule so that it will get picked up by profile sync flow
  let priorityRole: NotemealAccountType = "athlete";
  if (profileToRelink.__typename === "OrgMembership") {
    if (profileToRelink.roles.includes("dietitian")) {
      priorityRole = "dietitian";
    } else if (profileToRelink.roles.includes("chef")) {
      priorityRole = "chef";
    }
  }
  return priorityRole;
};

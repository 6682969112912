export interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface getBase64stringProps {
  cropArea: CropArea | null;
  imageUrl: string;
}

export const getBase64string = async ({ cropArea, imageUrl }: getBase64stringProps) => {
  if (cropArea) {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.src = imageUrl;
    const canvas: HTMLCanvasElement | null = await new Promise(resolve => {
      const canvasEl = document.createElement("canvas");
      const context2d = canvasEl.getContext("2d");

      img.onload = function () {
        canvasEl.width = cropArea.width;
        canvasEl.height = cropArea.height;
        if (context2d) {
          context2d.drawImage(img, cropArea.x, cropArea.y, cropArea.width, cropArea.height, 0, 0, cropArea.width, cropArea.height);
          resolve(canvasEl);
        } else {
          resolve(null);
        }
      };
    });

    return canvas ? canvas.toDataURL("image/jpeg") : null;
  }
  return null;
};

import { NamedTagForEditFragment } from "apps/web/src/types";
import {
  CustomTagAction,
  CustomTagState,
  customTagReducer,
  useValidateCustomTagStateForSave,
  isEmptyCustomTagState,
  buildInitialCustomTagCreateState,
  buildInitialCustomTagEditState,
} from "./customTagReducers";
import {
  buildInitialSmartTagEditState,
  createInitialSmartTagState,
  isEmptySmartTagState,
  SmartTagAction,
  smartTagReducer,
  SmartTagState,
  useValidateSmartTagStateForSave,
} from "./smartTagReducers";
import {
  BaseTagAction,
  BaseTagState,
  buildInitialBaseTagState,
  buildInitialBaseTagStateFromNamedTag,
  tagReducers,
  useValidateBaseTagState,
} from "./tagReducers";

interface SwitchTagStateAction {
  type: "SWITCH_TAG_STATE_ACTION";
}

export type CombinedTagAction = CustomTagAction | SmartTagAction | SwitchTagStateAction | BaseTagAction;

const customTagTypes = ["EDIT_ATHLETES_ACTION", "ADD_ATHLETE_ACTION", "REMOVE_ATHLETE_ACTION"];
const smartTagTypes = ["EDIT_POSITIONS_ACTION", "EDIT_TEAMS_ACTION", "EDIT_TAGS_ACTION", "SWITCH_TYPE_ACTION"];

const actionIsCustomTagAction = (action: CombinedTagAction): action is CustomTagAction => {
  return customTagTypes.includes(action.type);
};

const actionIsSmartTagAction = (action: CombinedTagAction): action is SmartTagAction => {
  return smartTagTypes.includes(action.type);
};

export interface SmartTagCombinedState extends BaseTagState {
  __typeName: "Smart";
  smartState: SmartTagState;
  customState: null;
}

export interface CustomTagCombinedState extends BaseTagState {
  __typeName: "Custom";
  customState: CustomTagState;
  smartState: null;
}

export type CombinedTagState = SmartTagCombinedState | CustomTagCombinedState;

export const combinedTagStateReducer = (state: CombinedTagState, action: CombinedTagAction): CombinedTagState => {
  switch (action.type) {
    case "EDIT_NAME_ACTION":
    case "EDIT_DESCRIPTION_ACTION":
    case "EDIT_IS_PRINTABLE_ACTION":
      return tagReducers(state, action);
    case "SWITCH_TAG_STATE_ACTION": {
      if (state.__typeName === "Custom") {
        return {
          ...state,
          __typeName: "Smart",
          smartState: createInitialSmartTagState(),
          customState: null,
        };
      } else {
        return {
          ...state,
          __typeName: "Custom",
          smartState: null,
          customState: buildInitialCustomTagCreateState(),
        };
      }
    }

    default: {
      if (state.__typeName === "Smart" && actionIsSmartTagAction(action)) {
        return { ...state, smartState: smartTagReducer(state.smartState, action) };
      }
      if (state.__typeName === "Custom" && actionIsCustomTagAction(action)) {
        return { ...state, customState: customTagReducer(state.customState, action) };
      }
      return state;
    }
  }
};

export const buildInitialCreateState = (): CombinedTagState => {
  return {
    __typeName: "Custom",
    ...buildInitialBaseTagState(),
    customState: buildInitialCustomTagCreateState(),
    smartState: null,
  };
};

export const buildInitialEditState = (namedTag: NamedTagForEditFragment): CombinedTagState => {
  if (namedTag.tag.__typename === "AthleteTag") {
    return {
      ...buildInitialBaseTagStateFromNamedTag(namedTag),
      __typeName: "Custom",
      customState: buildInitialCustomTagEditState(namedTag.tag.athletes),
      smartState: null,
    };
  } else {
    return {
      ...buildInitialBaseTagStateFromNamedTag(namedTag),
      __typeName: "Smart",
      customState: null,
      smartState: buildInitialSmartTagEditState(namedTag),
    };
  }
};

export const buildInitialDuplicateState = (namedTag: NamedTagForEditFragment): CombinedTagState => {
  const baseState = buildInitialEditState({ tag: namedTag.tag, tagName: { ...namedTag.tagName, isPrintable: false } });
  return combinedTagStateReducer(baseState, { type: "EDIT_NAME_ACTION", payload: `[Copy of] ${namedTag.tagName.name}` });
};

export const isEmptyTagState = (state: CombinedTagState): boolean => {
  return isEmptyCustomTagState(state.customState) && isEmptySmartTagState(state.smartState);
};

export const useValidateCombinedState = (state: CombinedTagState, existingTagName?: string): [string[], boolean] => {
  const smartErrors = useValidateSmartTagStateForSave(state.smartState);
  const customErrors = useValidateCustomTagStateForSave(state.customState);
  const [baseTagErrors, isTagNameUnique] = useValidateBaseTagState(state, existingTagName);

  return [[...smartErrors, ...customErrors, ...baseTagErrors], isTagNameUnique];
};

export const useValidateCombinedStateForCreate = (state: CombinedTagState): [string[], boolean] => useValidateCombinedState(state);

export const useValidateCombinedStateForEdit = (state: CombinedTagState, existingTagName: string): [string[], boolean] =>
  useValidateCombinedState(state, existingTagName);

import { Box, Button, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useUser } from "apps/web/src/utils/tokens";
import html2canvas from "html2canvas";
import React from "react";
import QRCode from "react-qr-code";
import { NAV_SELF_SERVICE_ONBOARDING } from "../../../NoAuth/NoAuthPaths";
import { getOrgUserDetails } from "../../OrgUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nutritionIcon: {
      width: "100px",
    },
    QR: {
      height: "300px",
      maxHeight: "300px",
      maxWidth: "300px",
      width: "300px",
    },
    callToActionBg: {
      width: "400px",
    },
  })
);

export const SelfServiceDietitianPage = () => {
  const classes = useStyles();
  const user = useUser();
  const orgUserDetails = getOrgUserDetails(user);
  const { orgId } = orgUserDetails;
  const { REACT_APP_URL_SCHEME: urlScheme, REACT_APP_WEB_DOMAIN: domain } = process.env;

  const renderHiddenQRTemplate = () => (
    <Box
      id="qr-template"
      sx={({ spacing }) => ({
        position: "absolute", // to escape layout constraints
        display: "none", // goes "flex" on download
        flexDirection: "column",
        height: 1024,
        width: 1440,
        justifyContent: "center",
        alignItems: "center",
        gap: spacing(4),
      })}
    >
      <img
        alt="Nutrition product icon"
        src="/img/nutrition_color.svg"
        className={classes.nutritionIcon} />
      <Typography
        component="span"
        variant="h2"
        sx={{ width: 534, textAlign: "center", mb: 8 }}>
        Onboard the Teamworks Nutrition app by scanning the QR code below
      </Typography>
      <QRCode
        size={300}
        className={classes.QR}
        value={`${urlScheme}${domain}${NAV_SELF_SERVICE_ONBOARDING}/${orgId}`}
        viewBox={`0 0 300 300`}
      />
    </Box>
  );

  const getCanvas = () => {
    const template = document.getElementById("qr-template");
    if (!template) {
      return;
    }
    return html2canvas(template, {
      onclone: snapshot => {
        const canvas = snapshot.getElementById("qr-template");
        if (!canvas) {
          return;
        }
        canvas.style.display = "flex";
      },
    });
  };

  const downloadQR = async () => {
    const canvas = await getCanvas();
    if (!canvas) {
      throw new Error("There was an error creating the QR");
    }
    const imgUrl = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = imgUrl;
    downloadLink.download = "Nutrition QR.jpeg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Box
      sx={({ spacing }) => ({
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: spacing(2),
      })}
    >
      <img
        alt="Self-service onboarding background"
        src="/img/self-onboarding-bg.svg"
        className={classes.callToActionBg} />
      <Box
        sx={({ spacing }) => ({
          display: "flex",
          gap: spacing(1),
          flexDirection: "column",
          width: "360px",
          textAlign: "center",
          alignItems: "center",
        })}
      >
        <Typography component="span" variant="h3">
          Onboarding QR code
        </Typography>
        <Typography variant="body2" color="mediumEmphasisText">
          Allow athletes to onboard the Teamworks Nutrition app by scanning the QR code.
        </Typography>
        <Button
          size={"medium"}
          onClick={downloadQR}
          sx={({ spacing }) => ({
            margin: spacing(2),
            width: "fit-content",
          })}
        >
          Download QR Code
        </Button>
        {renderHiddenQRTemplate()}
      </Box>
    </Box>
  );
};

import BarChartIcon from "@mui/icons-material/BarChart";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { getMacroProtocolMacros } from "@notemeal/shared/ui/MacroProtocol/utils";
import { mealPlanErrorLookup } from "@notemeal/shared/ui/utils/macroProtocolState";
import { Macros, getTotalCalories, maybeAthleteBirthDateToAge, translateErrors } from "@notemeal/shared/utils/macro-protocol";
import { MacrosProgress } from "apps/web/src/components/MacrosProgress/MacrosProgress";
import React, { useState } from "react";
import { ZodError } from "zod";
import { useCurrentMacrosContext } from "../../../contexts/CurrentMacros";
import { useSelectedMealOptionsContext } from "../../../contexts/SelectedMealOptions";
import {
  AthleteFragment,
  FullGoalFragment,
  FullMacroProtocolFragment,
  GoalTypeFragment,
  useAthleteMostRecentAnthroForRmrMethodQuery,
} from "../../../types";
import NutrientAmountDialog from "../../NutrientAmount/Modal";
import { MacroProtocolEditModal } from "../EditModal";

interface MacroProtocolPreviewProps {
  athlete: AthleteFragment;
  currentGoal: FullGoalFragment | null;
  macroProtocol: FullMacroProtocolFragment;
  goalTypes: readonly GoalTypeFragment[];
}

const getMacroProtocolMacrosOrError = (props: FullMacroProtocolFragment, athleteBirthDate: string | null): Macros | string[] => {
  try {
    return getMacroProtocolMacros(props, athleteBirthDate);
  } catch (e) {
    return e instanceof ZodError ? translateErrors(e.issues, mealPlanErrorLookup) : ["Unknown Error"];
  }
};
const isError = (x: Macros | string[]): x is string[] => {
  return (x as Macros).cho === undefined;
};

export const MacroProtocolPreview = ({ athlete, macroProtocol, currentGoal, goalTypes }: MacroProtocolPreviewProps) => {
  const { currentMacros } = useCurrentMacrosContext();
  const [selectedMealOptions] = useSelectedMealOptionsContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [nutrientModalOpen, setNutrientModalOpen] = useState(false);
  const targetMacrosOrError = getMacroProtocolMacrosOrError(macroProtocol, athlete.birthDate);
  const enableNutrientsEdit = selectedMealOptions.length !== 0;

  const { data } = useAthleteMostRecentAnthroForRmrMethodQuery({
    variables: {
      athleteId: athlete.id,
      rmrMethod: macroProtocol.calorieBudget?.rmrMethod || null,
    },
  });
  const mostRecentAnthropometryEntry = data?.athlete.mostRecentAnthropometryEntryForRmrMethod;

  if (isError(targetMacrosOrError)) {
    return (
      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2, border: 1, borderColor: "error.main" }}>
        <Typography variant="body2Medium">Unable to show Macro Protocol</Typography>
        {targetMacrosOrError.map((rmrError, index) => (
          <Typography variant="body2" key={index}>
            {rmrError}
          </Typography>
        ))}
      </Box>
    );
  }

  const newAnthropometryEntry =
    mostRecentAnthropometryEntry?.id && macroProtocol.anthropometrySnapshot.id !== mostRecentAnthropometryEntry.id;
  const newGoal = currentGoal?.id && macroProtocol.calorieBudget && macroProtocol.calorieBudget.goalSnapshot.id !== currentGoal.id;
  const needsUpdate = newAnthropometryEntry || newGoal;
  const kcal = macroProtocol.calorieBudget
    ? getTotalCalories(
        {
          ...macroProtocol.anthropometrySnapshot,
          age: maybeAthleteBirthDateToAge(athlete.birthDate) || 25,
        },
        macroProtocol.calorieBudget
      )
    : undefined;
  const selectedServingAmounts = selectedMealOptions.flatMap(mo => mo.servingAmounts);
  const tooltipTitle = (
    <>
      <div>
        <b>Macronutrient Protocol is out of date due to the following:</b>
      </div>
      {newAnthropometryEntry && <div>New Athlete Anthropometry Entry</div>}
      {newGoal && <div>New Athlete Goal</div>}
    </>
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h3">Total Calories</Typography>
        <Tooltip title={needsUpdate ? tooltipTitle : "Edit"}>
          <Button
            onClick={() => setModalOpen(true)}
            variant="outlined"
            size="small">
            Edit
          </Button>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <MacrosProgress
          currentMacros={currentMacros}
          targetMacros={targetMacrosOrError}
          macroProgressViews={"all"} />
        <Tooltip
          title={enableNutrientsEdit ? "Nutrients" : "Add a Meal Option to see nutrients breakdown"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box>
            <IconButton
              onClick={() => setNutrientModalOpen(true)}
              size="small"
              disabled={!enableNutrientsEdit}>
              <BarChartIcon sx={{ color: enableNutrientsEdit ? "accents.yellow.400" : "disabled" }} />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      {modalOpen && (
        <MacroProtocolEditModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          athleteId={athlete.id}
          mostRecentAnthropometryEntry={mostRecentAnthropometryEntry || null}
          currentGoal={currentGoal}
          athleteBirthdate={athlete.birthDate}
          initialMacroProtocol={macroProtocol}
          goalTypes={goalTypes}
        />
      )}
      {nutrientModalOpen && (
        <NutrientAmountDialog
          kcal={kcal}
          age={maybeAthleteBirthDateToAge(athlete.birthDate) || undefined}
          sex={athlete.sex}
          open={nutrientModalOpen}
          onClose={() => setNutrientModalOpen(false)}
          servingAmounts={selectedServingAmounts}
        />
      )}
    </Box>
  );
};

import React from "react";
import NamedTagDataLoaderDialog from "../NamedTagDataLoader";
import EditDialogContent from "./EditDialogContent";

interface EditDialogProps {
  tagId: string;
  open: boolean;
  onClose: () => void;
  resetTable: () => void;
}

const EditDialog = ({ resetTable, ...props }: EditDialogProps) => {
  return (
    <NamedTagDataLoaderDialog
      {...props}
      title="Edit Tag"
      renderContent={namedTag => {
        return <EditDialogContent
          onClose={props.onClose}
          existingTag={namedTag}
          resetTable={resetTable} />;
      }}
    />
  );
};

export default EditDialog;

import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

import { CompassRecipe } from "@notemeal/graphql/types";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { RecipeSearchFragment, useImportCompassRecipeMutation } from "../../../../types";
import { useSnackbar } from "../../../Snackbar/SnackbarContext";
import { CompassRecipesSearchBar } from "../SearchBar";
import { parseCompassImportPermissionError } from "./utils";

interface CompassImportRecipeDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (recipe: RecipeSearchFragment) => void;
}

const CompassImportRecipeDialog = ({ open, onClose, onImport }: CompassImportRecipeDialogProps) => {
  const [selectedRecipe, setSelectedRecipe] = useState<CompassRecipe | null>(null);
  const { setMessage } = useSnackbar();

  const [importCompassRecipe, { loading: importing }] = useImportCompassRecipeMutation({
    onCompleted: data => {
      onImport(data.importCompassRecipe.recipe);
      onClose();
    },
    onError: e => {
      const importError = parseCompassImportPermissionError(e);
      if (importError) {
        setMessage(
          "error",
          `Unable to import ${
            selectedRecipe?.name ?? "Recipe"
          } due not having permission to access: ${importError}. Contact Compass Support for Assistance.`
        );
      } else {
        setMessage("error", "Recipe import failed. Contact Compass support for assistance.");
      }

      onClose();
    },
  });

  const handleImport = (masterRefNumber: string) => {
    importCompassRecipe({
      variables: {
        input: {
          masterRefNumber,
        },
      },
    });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth>
      <DialogTitle title="Import Compass Recipe" onClose={onClose} />
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          Search for recipes to import by entering the name (at least 3 characters) or reference number. Hit "Return" to see the search
          results.
        </Typography>
        <div>
          <CompassRecipesSearchBar
            isImporting={importing}
            selectedRecipe={selectedRecipe}
            setSelectedRecipe={setSelectedRecipe} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        {selectedRecipe ? (
          <Button onClick={() => handleImport(selectedRecipe.masterRefNumber)}>Import</Button>
        ) : (
          <Button disabled>Import</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CompassImportRecipeDialog;

import { ListItem, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ComponentProps, ReactNode } from "react";
import { MenuSelectionItemFormFragment } from "../../types";
import MenuSelectionItemListItem from "../MenuSelectionItem/ListItem";
import MenuSelectionItemSpecialRequestsCaption from "../MenuSelectionItem/SpecialRequests/Caption";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    specialRequests: {
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
    },
  })
);

interface MenuOrderItemListItemProps {
  menuOrderItem: MenuSelectionItemFormFragment & {
    specialRequests: string | null;
  };
  ListItemProps?: ComponentProps<typeof ListItem>;
  children?: ReactNode;
  hidePercentConsumed?: boolean;
}

const MenuOrderItemListItem = ({ menuOrderItem, children, ListItemProps, hidePercentConsumed }: MenuOrderItemListItemProps) => {
  return (
    <MenuSelectionItemListItem
      menuSelectionItem={menuOrderItem}
      ListItemProps={ListItemProps}
      textChildren={<MenuSelectionItemSpecialRequestsCaption specialRequests={menuOrderItem.specialRequests} />}
      hidePercentConsumed={hidePercentConsumed}
    >
      {children}
    </MenuSelectionItemListItem>
  );
};

export default MenuOrderItemListItem;

import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { MenuItemRowInfoWithAmount } from "../utils";
import ChoiceCell from "./ChoiceCell";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      fontSize: 12,
      fontWeight: 600,
    },
    specialRequests: {
      fontSize: 10,
    },
  })
);

interface MenuItemCellProps {
  menuItemCellInfo: MenuItemRowInfoWithAmount;
}

const MenuItemCell = ({ menuItemCellInfo }: MenuItemCellProps) => {
  const classes = useStyles();
  const hasChoices = menuItemCellInfo.mappedChoices.length > 0;
  const hasSpecialRequests = !!menuItemCellInfo.specialRequests;
  return (
    <Typography component="div">
      <span className={classes.name}>{`${menuItemCellInfo.name} (${menuItemCellInfo.amount}x)`}</span>
      {hasChoices && <br />}
      <ChoiceCell mappedChoices={menuItemCellInfo.mappedChoices} />
      {hasSpecialRequests && <br />}
      <span className={classes.specialRequests}>{menuItemCellInfo.specialRequests}</span>
    </Typography>
  );
};

export default MenuItemCell;

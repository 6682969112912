import { Chip, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

interface FoodLogPreviewImageProps {
  previewImageUrl: string;
  imageCount: number;
  imageClassName: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      position: "absolute",
      bottom: theme.spacing(2.5),
      right: theme.spacing(),
    },
    root: {
      position: "relative",
    },
  })
);

const FoodLogPreviewImage = ({ previewImageUrl, imageCount, imageClassName, onClick }: FoodLogPreviewImageProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={previewImageUrl}
        alt=""
        className={imageClassName}
        onClick={onClick} />
      <Chip
        size="small"
        className={classes.chip}
        label={`1 / ${imageCount}`}
        onClick={onClick} />
    </div>
  );
};

export default FoodLogPreviewImage;

import { MenuItem, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import LabeledSelect from "../../../../components/universal/LabeledSelect";
import { AgreementFormFragment, useAgreementFormsQuery } from "../../../../types";

interface AgreementFormSelectProps {
  agreementForm: AgreementFormFragment | null;
  setAgreementForm: (agreementForm: AgreementFormFragment | null) => void;
}

const AgreementFormSelect = ({ agreementForm, setAgreementForm }: AgreementFormSelectProps) => {
  const { data, loading } = useAgreementFormsQuery();
  return (
    <LabeledSelect
      placeholder="Agreement Form"
      options={data ? [...data.agreementForms] : []}
      selectedOption={agreementForm}
      optionToName={ag => ag.title}
      onChange={e => setAgreementForm(e)}
      textFieldProps={{
        fullWidth: true,
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <MenuItem onClick={() => setAgreementForm(null)}>
          <Typography>None</Typography>
        </MenuItem>
      )}
    </LabeledSelect>
  );
};

export default AgreementFormSelect;

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, DialogTitle as MuiDialogTitle } from "@mui/material";
import React from "react";

interface BaseDialogTitleProps {
  content: React.ReactNode;
  onClose: () => void;
}

const BaseDialogTitle = ({ content, onClose }: BaseDialogTitleProps) => (
  <MuiDialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
    {content}
    <IconButton onClick={onClose} size="small">
      <CloseIcon />
    </IconButton>
  </MuiDialogTitle>
);

export default BaseDialogTitle;

import { ApolloQueryResult } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import {
  ContactFragment,
  RestaurantLocationsQuery,
  useAddRestaurantLocationContactMutation,
  useEditRestaurantLocationContactMutation,
} from "../../../../../types";
import CreateDialog from "./CreateDialog";
import EditDialog from "./EditDialog";
import { RestaurantLocationContactFormState, buildRestaurantLocationContactInput } from "./Form/utils";
import ContactListItem from "./ListItem";

interface RestaurantLocationContactsProps {
  locationId: string;
  open: boolean;
  onClose: () => void;
  contacts: readonly ContactFragment[];
  refetch: () => Promise<ApolloQueryResult<RestaurantLocationsQuery>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minWidth: theme.spacing(40),
    },
    contactIconPadding: {
      padding: theme.spacing(1, 2, 0, 0),
    },
    flex: { display: "flex", alignContent: "center", alignItems: "center" },
  })
);

const RestaurantLocationContacts = ({ contacts, open, onClose, locationId, refetch }: RestaurantLocationContactsProps) => {
  const classes = useStyles();
  const { setMessage } = useSnackbar();

  const [showAddContactDialog, setShowAddContactDialog] = useState(false);
  const [editContactId, setEditContactId] = useState<string | null>(null);

  const [addRestaurantLocationContact] = useAddRestaurantLocationContactMutation({
    onCompleted: () => {
      refetch();
    },
    onError: e => {
      setMessage("error", e.message);
      refetch();
    },
  });
  const [editRestaurantLocationContact] = useEditRestaurantLocationContactMutation({
    onError: e => {
      setMessage("error", e.message);
    },
  });

  const handleCreate = (contactFormState: RestaurantLocationContactFormState) => {
    setShowAddContactDialog(false);
    addRestaurantLocationContact({
      variables: {
        input: {
          locationId,
          restaurantLocationContact: buildRestaurantLocationContactInput(contactFormState),
        },
      },
    });
  };

  const handleEdit = (contactFormState: RestaurantLocationContactFormState) => {
    setEditContactId(null);
    const { id } = contactFormState;
    if (id !== null) {
      editRestaurantLocationContact({
        variables: {
          input: {
            id,
            restaurantLocationContact: buildRestaurantLocationContactInput(contactFormState),
          },
        },
      });
    }
  };

  const editContact = contacts && contacts.find(contact => contact.id === editContactId);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <div className={classes.header}>
            <Typography variant="h3">Contacts</Typography>
            <Button
              onClick={() => {
                setShowAddContactDialog(true);
              }}
            >
              Add Contact
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <List>
            {contacts &&
              contacts.length > 0 &&
              contacts.map(contact => {
                return <ContactListItem contact={contact} setEditContactId={setEditContactId} />;
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {showAddContactDialog && (
        <CreateDialog
          open={showAddContactDialog}
          onClose={() => setShowAddContactDialog(false)}
          onCreate={handleCreate} />
      )}

      {!!editContactId && editContact && (
        <EditDialog
          open={!!editContactId}
          onClose={() => setEditContactId(null)}
          onEdit={handleEdit}
          initialState={editContact} />
      )}
    </>
  );
};

export default RestaurantLocationContacts;

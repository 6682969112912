import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { getMealOptionName } from "@notemeal/shared/ui/MealOption/utils";
import { getServingAmountsMacros } from "@notemeal/shared/ui/ServingAmount/utils";
import { roundMacros } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { FullMealOptionFragment, RecipeWithServingsFragment } from "../../../../types";
import ServingAmountsSmallChipList from "../SmallChipList";

interface ExportMealOptionProps {
  mealOption: FullMealOptionFragment;
  recipes: readonly RecipeWithServingsFragment[];
  displayMacros: boolean;
  expandRecipes: boolean;
  index: number;
}

const ExportMealOption = ({ mealOption, displayMacros, expandRecipes, index, recipes }: ExportMealOptionProps) => {
  const macros = roundMacros(getServingAmountsMacros(mealOption.servingAmounts));
  const macrosPadding = displayMacros ? { pb: 0 } : {};

  return (
    <Card
      sx={{
        mb: 0.5,
        mx: 0.5,
        height: `calc(100% - 5px)`,
        // This needs to be doubled if we add marginTop back
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardActionArea disableRipple disabled>
        <CardContent sx={{ padding: "2px", ...macrosPadding }}>
          <Box sx={{ ml: 1 }}>
            <Typography
              variant="body2Semibold"
              sx={{ WebkitLineClamp: 2, overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical" }}
            >
              {getMealOptionName(mealOption.name, index)}
            </Typography>
          </Box>
          {mealOption.note && (
            <Box sx={{ ml: 1, mr: 1, textAlign: "left", width: "calc(100% - 16px)", overflow: "hidden", pb: 0.1 }}>
              <Typography sx={{ color: "grey.600", fontSize: "8px", lineHeight: `8px` }} variant="subtitle1">
                {mealOption.note}
              </Typography>
            </Box>
          )}
          <Box sx={{ height: "100%" }}>
            <ServingAmountsSmallChipList
              negativeFoodStrategy="highlight"
              positiveFoodStrategy="highlight"
              recipes={recipes}
              expandRecipes={expandRecipes}
              servingAmounts={mealOption.servingAmounts}
            />
          </Box>
        </CardContent>
      </CardActionArea>
      {displayMacros && (
        <Box sx={{ ml: 1, pb: "2px" }}>
          <MacrosSummaryLabel macros={macros} variant="xs" />
        </Box>
      )}
    </Card>
  );
};

export default ExportMealOption;

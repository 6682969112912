import { Box, Checkbox, FormControlLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

export const ThemeViewerSelect = () => {
  const {
    palette: { greyscale },
  } = useTheme();
  const [placeholderChecked, setPlaceholderChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [optionChecked, setOptionChecked] = useState(false);
  const [disabledChecked, setDisabledChecked] = useState(false);

  const handleOptionChecked = () => {
    const newOptionChecked = !optionChecked;
    setOptionChecked(newOptionChecked);

    const option = newOptionChecked ? "option1" : "";
    setSelectedOption(option);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h2">Select</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={placeholderChecked} onChange={() => setPlaceholderChecked(!placeholderChecked)} />}
          label="Placeholder"
        />
        <FormControlLabel control={<Checkbox checked={optionChecked} onChange={() => handleOptionChecked()} />} label="Option" />
        <FormControlLabel
          control={<Checkbox checked={disabledChecked} onChange={() => setDisabledChecked(!disabledChecked)} />}
          label="Disabled"
        />
      </Box>
      <Typography variant="h3">Medium * only medium working so far</Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        {!placeholderChecked && (
          <Select
            sx={{ width: 130 }}
            label="Meal"
            disabled={disabledChecked}
            value={selectedOption}
            onChange={e => setSelectedOption(e.target.value)}
          >
            <MenuItem value={"option1"}>Option1</MenuItem>
            <MenuItem value={"option2"}>Option2</MenuItem>
            <MenuItem value={"option3"}>Option3</MenuItem>
          </Select>
        )}
        {placeholderChecked && (
          <Select
            sx={{ width: 130 }}
            label="Meal"
            disabled={disabledChecked}
            value={selectedOption}
            onChange={e => setSelectedOption(e.target.value)}
            displayEmpty
            renderValue={() => (!selectedOption ? <Box sx={{ color: greyscale[400] }}>Placeholder</Box> : selectedOption)}
          >
            <MenuItem value={"option1"}>Option1</MenuItem>
            <MenuItem value={"option2"}>Option2</MenuItem>
            <MenuItem value={"option3"}>Option3</MenuItem>
          </Select>
        )}
      </Box>
      <Typography variant="h3">Small</Typography>
      <Box sx={{ alignItems: "flex-start", display: "flex", gap: 2 }}>
        <Select
          sx={{ width: 130 }}
          size="small"
          label="Meal"
          disabled={disabledChecked}
          value={selectedOption}
          onChange={e => setSelectedOption(e.target.value)}
        >
          <MenuItem value={"option1"}>Option1</MenuItem>
          <MenuItem value={"option2"}>Option2</MenuItem>
          <MenuItem value={"option3"}>Option3</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

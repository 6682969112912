import { Autocomplete, AutocompleteRenderGetTagProps, Chip, SxProps, TextField, Tooltip } from "@mui/material";
import { OrgForTerritoryFragment, useOrgsForTerritoryQuery } from "apps/web/src/types";
import React from "react";

interface OrgMultiSelectProps {
  values: readonly string[];
  onChange: (values: string[]) => void;
  error: boolean;
  helperText?: string;
  sx?: SxProps;
}

const ORG_DISPLAY_LIMIT = 4;

export const OrgMultiSelect = ({ values, onChange, error, helperText, sx }: OrgMultiSelectProps) => {
  const { data } = useOrgsForTerritoryQuery();
  const orgs = data?.orgs ?? [];
  const chipSx: SxProps = {
    margin: "3px",
    "&:last-of-type": {
      marginRight: 0,
    },
  };

  const renderTags = (orgs: OrgForTerritoryFragment[], getTagProps: AutocompleteRenderGetTagProps) => {
    let visibleOrgs = orgs;
    let hiddenOrgs = null;
    if (orgs.length > ORG_DISPLAY_LIMIT) {
      visibleOrgs = orgs.slice(0, ORG_DISPLAY_LIMIT);
      hiddenOrgs = orgs.slice(ORG_DISPLAY_LIMIT);
    }

    return (
      <>
        {visibleOrgs.map((org, index) => {
          const tagProps = getTagProps({ index });
          return <Chip
            label={org.name}
            key={org.id}
            onDelete={tagProps.onDelete}
            sx={chipSx} />;
        })}
        {hiddenOrgs && hiddenOrgs.length > 0 && (
          <Tooltip title={hiddenOrgs.map(({ name }) => name).join(", ")}>
            <Chip label={`+${hiddenOrgs.length}`} sx={chipSx} />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <Autocomplete
      multiple
      // filterSelectedOptions
      options={orgs}
      getOptionLabel={option => option.name}
      value={orgs.filter(({ id }) => values.includes(id))}
      isOptionEqualToValue={(option: OrgForTerritoryFragment, value: OrgForTerritoryFragment) => option.id === value.id}
      renderInput={params => <TextField
        sx={sx}
        {...params}
        label={"Orgs"} />}
      onChange={(_, value) => onChange(value.map(({ id }) => id))}
      renderTags={renderTags}
    />
  );
};

import { MenuItem, SxProps, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";

import { MealType } from "../../types";

interface MealTypeSelectProps {
  value: MealType | null;
  onChange: (mt: MealType) => void;
  disabled?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
  placeholder?: string;
}

const MealTypeSelect = ({ value, onChange, className, sx, disabled, placeholder }: MealTypeSelectProps) => {
  return (
    <TextField
      select
      label={placeholder ? placeholder : "Type"}
      value={value || ""}
      className={className}
      sx={sx}
      disabled={disabled}
      onChange={e => onChange(e.target.value as MealType)}
    >
      <MenuItem value={"breakfast"}>Breakfast</MenuItem>
      <MenuItem value={"lunch"}>Lunch</MenuItem>
      <MenuItem value={"dinner"}>Dinner</MenuItem>
      <MenuItem value={"snack"}>Snack</MenuItem>
    </TextField>
  );
};

export default MealTypeSelect;

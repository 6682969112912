import { alpha, Chip, useTheme } from "@mui/material";
import React from "react";

const featureSlugToEndDate = {
  transcribeRecipe: new Date("2024-10-4"),
  scalingRecipe: new Date("2024-10-31"),
  messaging: new Date("2024-10-30"),
};

type NewFeatureSlug = keyof typeof featureSlugToEndDate;

export interface NewChipProps {
  feature: NewFeatureSlug;
  variant?: "default" | "nav";
}

export const NewChip = ({ feature, variant }: NewChipProps) => {
  const {
    palette: { info, common },
    spacing,
  } = useTheme();

  // If the current date is greater (after) than or equal to the end date of the feature, return null
  if (new Date() >= featureSlugToEndDate[feature]) {
    return null;
  }

  return (
    <Chip
      size="small"
      sx={{
        marginLeft: spacing(1),
        backgroundColor: variant === "nav" ? alpha(common.white, 0.15) : info.lighter,
        color: variant === "nav" ? common.white : info.main,
        fontWeight: 500,
        fontSize: "11px",
        lineHeight: "14px",
        height: "20px",
      }}
      label="New"
    />
  );
};

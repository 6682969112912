import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useReducer } from "react";
import TWItemizedTooltip from "../../../../componentLibrary/TWTooltip/TWItemizedTooltip";
import { OrgDocument, OrgQuery, StaffDashboardOrgPreviewFragment, useEditOrgMutation } from "../../../../types";
import Form from "../Form/Create";
import { createOrgMembershipFormReducer, createOrgMembershipFormToSaveTooltips, initialCreateOrgMembershipFormState } from "../Form/utils";
import { getIntegrationInput } from "../utils";

interface OrgMembershipModalProps {
  open: boolean;
  onClose: () => void;
  org: StaffDashboardOrgPreviewFragment;
}

const CreateOrgMembershipModal = ({ open, onClose, org }: OrgMembershipModalProps) => {
  const { setMessage } = useSnackbar();
  const [state, dispatch] = useReducer(createOrgMembershipFormReducer, initialCreateOrgMembershipFormState(org.id));
  const canSaveTooltips = createOrgMembershipFormToSaveTooltips(state);

  const [editOrg] = useEditOrgMutation({
    update: (dataProxy, result) => {
      const orgQuery = dataProxy.readQuery<OrgQuery>({
        query: OrgDocument,
        variables: { id: org.id },
      });
      if (result.data && orgQuery) {
        dataProxy.writeQuery({
          query: OrgDocument,
          data: {
            ...orgQuery,
            org: {
              ...orgQuery.org,
              orgMemberships: result.data.editOrg.org.memberships,
            },
          },
        });
      }
    },
    onError: e => setMessage("error", e.message),
  });

  const handleSave = () => {
    if (canSaveTooltips.length) {
      return;
    }
    const { roles, isAdmin, email, firstName, lastName, method, phoneNumber } = state;
    editOrg({
      variables: {
        input: {
          id: org.id,
          name: org.name,
          teamworksId: org.teamworksId,
          allowsPublicAccess: org.allowsPublicAccess,
          isActive: org.isActive,
          territoryName: org.territoryName,
          createMemberships: [
            {
              isAdmin,
              roles,
              email,
              firstName,
              lastName,
              method,
              phoneNumber,
              teamName: org.name,
              isNotemealOnly: org.isNotemealLinked,
            },
          ],
          editMemberships: [],
          integrations: getIntegrationInput(org.integrations),
          agreementFormId: org.agreementForm?.id || null,
          scoringSystemId: org.scoringSystem?.id || null,
          localeCode: org.localeCode,
        },
      },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Create Org Membership" onClose={onClose} />
      <DialogContent sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        {org.isNotemealLinked && (
          <Box sx={{ p: 2, backgroundColor: "grey.200", mt: 2, mx: 0, mb: 4, borderRadius: "5px" }}>
            <Typography variant="body1Medium">
              * This Org is Fully Synced. All new Org Memberships will be made as Nutrition only accounts.
            </Typography>
          </Box>
        )}
        <Form state={state} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <TWItemizedTooltip title="Fix the following before finishing:" items={canSaveTooltips}>
          <Button disabled={!!canSaveTooltips.length} onClick={handleSave}>
            Save
          </Button>
        </TWItemizedTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrgMembershipModal;

export interface RefreshOptions {
  didTransferOrgs: boolean;
}

interface createDedupedRefreshArgs<T> {
  refresh: (opts?: RefreshOptions) => Promise<T>;
}

export const createDedupedRefresh = <T>({ refresh }: createDedupedRefreshArgs<T>): ((opts?: RefreshOptions) => Promise<T | undefined>) => {
  let isRefreshing = false;
  let pendingRequests: ((t?: T) => void)[] = [];

  const setIsRefreshing = (value: boolean) => {
    isRefreshing = value;
  };

  const addPendingRequest = (pendingRequest: (t?: T) => void) => {
    pendingRequests.push(pendingRequest);
  };

  const resolvePendingRequests = (t?: T) => {
    pendingRequests.map(callback => callback(t));
    pendingRequests = [];
  };

  return (opts?: RefreshOptions) => {
    if (!isRefreshing) {
      setIsRefreshing(true);
      return refresh(opts)
        .then(t => {
          resolvePendingRequests(t);
          setIsRefreshing(false);
          return t;
        })
        .catch(e => {
          resolvePendingRequests();
          setIsRefreshing(false);
          throw e;
        });
    } else {
      return new Promise<T | undefined>(resolve => {
        addPendingRequest(t => resolve(t));
      });
    }
  };
};

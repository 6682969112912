import { invalidPhoneNumber } from "@notemeal/utils/phone-number";
import { subYears } from "date-fns";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const IntegerSchema = z.coerce
  .string()
  .transform(n => (Number.isNaN(parseInt(n, 10)) ? null : parseInt(n, 10)))
  .refine((n): n is number => n !== null, "Required");

export const FloatSchema = z.coerce
  .string()
  .transform(n => (Number.isNaN(parseFloat(n)) ? null : parseFloat(n)))
  .refine((n): n is number => n !== null, "Required");

export const EmailSchema = z.string().email("Invalid");

export const PhoneNumberSchmea = z.string().refine(val => !invalidPhoneNumber(val), { message: "Invalid" });

export const DateSchema = z.coerce.date({
  errorMap: (issue, { defaultError }) => ({
    message: issue.code === "invalid_date" ? "Invalid" : defaultError, // temporary custom error for date workaround from: https://github.com/colinhacks/zod/issues/1526
  }),
});

export const BirthDateSchema = DateSchema.max(subYears(new Date(), 13), "Athlete must be at least 13");

export const NamedTagsSchema = z.array(
  z.object({
    tagName: z.object({ name: z.string(), color: z.string() }),
    tag: z.object({ __typename: z.any(), id: z.string() }),
  })
);

export const SexTypeSchema = z.enum(["male", "female", "%future added value"]);
export const MealTypeSchema = z.enum(["breakfast", "lunch", "dinner", "meal", "snack", "%future added value"]);
export type MealTypeInferredType = z.infer<typeof MealTypeSchema>;
export const FormFieldsSchema = z.enum(["height", "weight", "percentBodyFat", "%future added value"]);
export const RMRMethodSchema = z.enum(["average", "cunningham", "harrisBenedict", "mifflin", "%future added value"]);
export const ActivityTypeSchema = z.enum(["class", "competition", "conditioning", "lift", "practice", "%future added value"]);
export const DayOfWeekSchema = z.enum(["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]);
export const JerseyNumberSchema = z
  .string()
  .regex(/^[A-Za-z0-9]*$/, "No symbols")
  .max(3, "3 characters max");

export const PositionSchema = z.object({ id: z.string(), name: z.string(), __typename: z.literal("Position") });

export const round = (n: number, places = 1): number => Math.round(10 ** places * n) / 10 ** places;

export const heightToInches = (height: string): number => {
  const [feetToken, inchesToken] = height.split("'");
  return 12 * parseInt(feetToken) + round(parseFloat(inchesToken), 1);
};

export const HeightSchema = z
  .string({ required_error: "Required" })
  .refine(value => (value ? Boolean(heightToInches(value)) : true), { message: "Invalid" });

export const PercentBodyFatSchema = FloatSchema.refine(value => value >= 0 && value <= 100, { message: "Invalid" });

export const AnthropometryEntryTypeSchema = z.enum(
  ["estimate", "dexa", "bodpod", "bioelectricalImpedance", "isak", "weighIn", "%future added value"],
  {
    required_error: "Required",
  }
);

export const BoneMineralDensityZScoreSchema = FloatSchema.refine(value => value <= 5 && value >= -5, { message: "Invalid" });

export const invalidVFAValue = (value: number | null) => {
  if (!value) {
    return false;
  }
  return value === null || value < 0;
};

export const VisceralFatAreaSchema = FloatSchema.refine(value => !invalidVFAValue(value), { message: "Invalid" });

const invalidMassValue = (value: number | null | undefined, weight: number | undefined) => {
  if (!value || !weight) {
    return false;
  }
  return value === null || value > weight || value < 0;
};

export const applyInvalidMassValueError = (
  value: number | null | undefined,
  weight: number | undefined,
  ctx: z.RefinementCtx,
  path: string
) => {
  if (invalidMassValue(value, weight)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid",
      path: [path],
    });
  }
};

export const GoalTypeSchema = z.object({
  __typename: z.literal("GoalType"),
  id: z.string(),
  name: z.string(),
  defaultKcalOffset: z.number(),
});

export const isFormDirty = (form: UseFormReturn<any>) => {
  return Object.keys(form.formState.dirtyFields).length > 0;
};

const BaseAthleteSchema = z.object({
  firstName: z.string({ required_error: "Required" }).min(1, "Required"),
  lastName: z.string({ required_error: "Required" }).min(1, "Required"),
  sex: SexTypeSchema,
  birthDate: BirthDateSchema.nullable(),
  phoneNumber: PhoneNumberSchmea.or(z.literal("")),
  position: PositionSchema.nullable(),
  inbodyUid: z.string().nullable(),
  secaUid: z.string().nullable(),
  jerseyNumber: JerseyNumberSchema.nullable(),
  teamId: z.string(),
});

export const RequireEmailAthleteSchema = BaseAthleteSchema.extend({
  email: z.string({ required_error: "Required" }).min(1, "Required").email("Invalid"),
});

export const AthleteSchema = BaseAthleteSchema.extend({
  email: EmailSchema.email("Invalid").or(z.literal("")),
});

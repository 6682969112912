import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Box, Typography } from "@mui/material";
import React from "react";

const TeamGoalsSummaryEmptyContent = () => {
  return (
    <Box sx={{ justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center" }}>
      <WorkspacePremiumIcon fontSize="large" />
      <Typography variant="h4">No Goals</Typography>
      <Typography variant="body2">
        No athletes on the team have goals yet. Head over to an athlete’s profile to create and assign one today.
      </Typography>
    </Box>
  );
};

export default TeamGoalsSummaryEmptyContent;

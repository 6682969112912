import { DialogContentText } from "@mui/material";
import React from "react";
import { MealMenuInstance } from "../../types";
import { BaseBulkEditProps, BaseContentProps } from "../types";
import BaseContent from "./BaseContent";

interface DeleteContentProps extends BaseContentProps {
  onBulkDelete: BaseBulkEditProps["onBulkDelete"];
}

const DeleteContent = ({ isOpen, onCancel, onBulkDelete, mealMenuInstances, saving }: DeleteContentProps) => {
  const deleteOrderCountSum = mealMenuInstances.reduce((sum, mealMenuInstance: MealMenuInstance) => {
    return sum + mealMenuInstance.athleteOrderCount + mealMenuInstance.nonAthleteOrderCount;
  }, 0);
  return (
    <BaseContent
      isOpen={isOpen}
      onCancel={onCancel}
      saving={saving}
      onSubmit={async () => {
        await onBulkDelete(mealMenuInstances.map(({ id }) => id));
        onCancel();
      }}
      title={`Delete ${mealMenuInstances.length} Meal Menu(s)`}
      renderContent={() => (
        <DialogContentText color="error">
          Delete {mealMenuInstances.length} Menu(s) including {deleteOrderCountSum} order(s)
        </DialogContentText>
      )}
      submitDisabled={mealMenuInstances.length === 0 || saving}
      submitText="Delete"
    />
  );
};

export default DeleteContent;

import { Box, Switch, Typography } from "@mui/material";
import React, { useState } from "react";

export const ThemeViewerSwitch = () => {
  const [switchChecked, setSwitchChecked] = useState(true);

  return (
    <>
      <Typography variant="h2">Switch</Typography>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h3">Medium</Typography>
          <Switch checked={switchChecked} onChange={() => setSwitchChecked(!switchChecked)} />
          <Switch
            disabled
            checked={switchChecked}
            onChange={() => {}} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h3">Small</Typography>
          <Switch
            size="small"
            checked={switchChecked}
            onChange={() => setSwitchChecked(!switchChecked)} />
          <Switch
            size="small"
            disabled
            checked={switchChecked}
            onChange={() => {}} />
        </Box>
      </Box>
    </>
  );
};

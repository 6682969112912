import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import React, { ReactNode } from "react";
import DialogTitle from "../../../componentLibrary/DialogTitle";

interface RecipeUnsavedChangesDialogProps {
  message: string | ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  onDiscard: () => void;
  open: boolean;
  title: string;
}

export const RecipeUnsavedChangesDialog = ({ title, open, message, onCancel, onConfirm, onDiscard }: RecipeUnsavedChangesDialogProps) => (
  <Dialog maxWidth="xs" open={open}>
    <DialogTitle title={title} onClose={onCancel} />
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onDiscard} variant="outlinedDestructive">
        Discard
      </Button>
      <LoadingButton onClick={onConfirm} buttonText={"Save"} />
    </DialogActions>
  </Dialog>
);

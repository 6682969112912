import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import Loading from "@notemeal/shared/ui/global/Loading";
import { EditImperialMacroProtocolState, EditMetricMacroProtocolState } from "@notemeal/shared/ui/reducers/EditMacroProtocol";
import { maybeAthleteBirthDateToAge } from "@notemeal/shared/utils/macro-protocol";
import ActionDialog from "apps/web/src/componentLibrary/ActionDialog/Dialog";
import { ImperialMacroProtocolForm } from "apps/web/src/components/MacroProtocol/Form/ImperialMacroProtocolForm";
import { MetricMacroProtocolForm } from "apps/web/src/components/MacroProtocol/Form/MetricMacroProtocolForm";
import ActionDialogContent from "apps/web/src/components/universal/ActionDialogContent";
import { getNavOrgAthleteMealPlansMealPlan } from "apps/web/src/pages/Auth/Org/Athlete/AthletePaths";
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import FoodLogMergeForm from "../../../components/FoodLog/MergeForm";
import {
  FoodLogMergeFormState,
  convertTimelineMeal,
  getFoodLogMergeFormSaveTooltips,
  getFoodLogMergeFormState,
} from "../../../components/FoodLog/MergeForm/utils";
import FoodPreferenceMealPlanForm from "../../../components/FoodPreference/MealPlan/Form";
import {
  FoodPreferenceState,
  foodPreferenceFormToSaveTooltips,
  foodPreferenceReducer,
  initialFoodPreferenceState,
  pluckIdsFromFoodPreferenceState,
} from "../../../components/FoodPreference/MealPlan/reducer";
import { macroProtocolStateToSaveTooltips, metricMacroProtocolStateToSaveTooltips } from "../../../components/MacroProtocol/Form/utils";
import { useImperialMacroProtocolReducer, useMetricMacroProtocolReducer } from "../../../components/MacroProtocol/utils";
import {
  MealPlanFormState,
  initialMealPlanFormState,
  mealPlanFormReducer,
  mealPlanFormToSaveTooltips,
} from "../../../components/MealPlan/Form/utils";
import { NameAndTypeForm } from "../../../components/MealPlan/NameAndTypeForm";
import { getInitialMealPlanName } from "../../../components/MealPlan/utils";
import {
  AnthropometryEntryFragment,
  AthleteMealPlansDocument,
  AthleteMealPlansQuery,
  AthleteMealPlansQueryVariables,
  CreateMetricMealPlanFromFoodLogsInput,
  ExchangeSetFragment,
  FoodLogActivityTimelineFragment,
  FullGoalFragment,
  GoalTypeFragment,
  TimelineMealFragment,
  useCreateMetricMealPlanFromFoodLogsMutation,
} from "../../../types";
import {
  editImperialMacroProtocolStateInMealPlanToMetricMacroProtocolInput,
  editMetricMacroProtocolStateInMealPlanToMetricMacroProtocolInput,
} from "../../../utils/macroProtocol";
import { dateAssignmentReducer, initDateAssignmentState } from "../MealPlans/Content/Calendar/Form/reducer";
import { MealPlanDateAssignment } from "../MealPlans/Content/Calendar/Form/types";
import { getOverrideMealPlanDateAssignmentInput, mealPlanDateAssignmentToSaveTooltips } from "../MealPlans/Content/Calendar/Form/utils";
import DateAssignment from "../MealPlans/Content/DateAssignment";
import { MealPlanCalendarContextProvider } from "../MealPlans/contexts/Calendar";

const steps = ["Food Logs", "Macronutrient Protocol", "Calendar", "Name and Type", "Promoted / Avoided Foods"];

interface CreateMealPlanFromFoodLogsModalProps {
  open: boolean;
  onClose: () => void;
  timelineMeals: readonly TimelineMealFragment[];
  foodLogActivities: readonly FoodLogActivityTimelineFragment[];
  athleteId: string;
  currentGoal: FullGoalFragment | null;
  mostRecentAnthropometryEntry: AnthropometryEntryFragment;
  athleteBirthdate: string | null;
  exchangeSets: readonly ExchangeSetFragment[];
  goalTypes: readonly GoalTypeFragment[];
  sportName: string;
}

const CreateMealPlanFromFoodLogsModal = ({
  athleteId,
  currentGoal,
  mostRecentAnthropometryEntry,
  athleteBirthdate,
  goalTypes,
  open,
  onClose,
  sportName,
  exchangeSets,
  timelineMeals,
  foodLogActivities,
}: CreateMealPlanFromFoodLogsModalProps) => {
  const navigate = useNavigate();
  const clientTimezone = useClientTimezone();
  const { isMetricLocale } = useLocaleContext();
  const [activeStep, setActiveStep] = useState(0);

  const athleteAge = maybeAthleteBirthDateToAge(athleteBirthdate);

  const [createMetricMealPlanFromFoodLogs, { loading: saving }] = useCreateMetricMealPlanFromFoodLogsMutation({
    onCompleted: data => {
      onClose();
      navigate(getNavOrgAthleteMealPlansMealPlan(athleteId, data.createMetricMealPlanFromFoodLogs.mealPlan.id));
    },
    update: (cache, { data }) => {
      const queryInCache = cache.readQuery<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>({
        query: AthleteMealPlansDocument,
        variables: { athleteId },
      });
      if (data && queryInCache) {
        cache.writeQuery<AthleteMealPlansQuery, AthleteMealPlansQueryVariables>({
          query: AthleteMealPlansDocument,
          variables: { athleteId },
          data: {
            ...queryInCache,
            athlete: {
              ...queryInCache.athlete,
              mealPlans: [...queryInCache.athlete.mealPlans, data.createMetricMealPlanFromFoodLogs.mealPlan],
            },
          },
        });
      }
    },
  });

  const allTimelineMeals = timelineMeals.flatMap(m => convertTimelineMeal(m) ?? []);
  const [foodLogMergeState, setFoodLogMergeState] = useState(
    getFoodLogMergeFormState(
      allTimelineMeals,
      foodLogActivities.map(fla => ({ ...fla, type: fla.activityType }))
    )
  );
  const [mpFormState, mptFormDispatch] = useReducer(mealPlanFormReducer, initialMealPlanFormState(exchangeSets));

  const [imperialMacroProtocolFormState, imperialMacroProtocolFormDispatch] = useImperialMacroProtocolReducer({
    currentGoal,
    mostRecentAnthropometryEntry,
    athleteAge,
    goalTypes,
  });

  const [metricMacroProtocolFormState, metricMacroProtocolFormDispatch] = useMetricMacroProtocolReducer({
    currentGoal,
    mostRecentAnthropometryEntry,
    athleteAge,
    goalTypes,
  });

  const [foodPreferenceFormState, foodPreferenceFormDispatch] = useReducer(foodPreferenceReducer, {}, initialFoodPreferenceState);
  const [dateAssignmentState, dateAssignmentDispatch] = useReducer(dateAssignmentReducer, initDateAssignmentState(clientTimezone));
  const [isAutoSuggestionsEnabled, setIsAutoSuggestionsEnabled] = useState(false);

  const getCanSaveTooltipItems = (): string[] => {
    switch (activeStep) {
      case 0:
        return getFoodLogMergeFormSaveTooltips(foodLogMergeState);
      case 1:
        return isMetricLocale
          ? metricMacroProtocolStateToSaveTooltips(metricMacroProtocolFormState)
          : macroProtocolStateToSaveTooltips(imperialMacroProtocolFormState);
      case 2:
        return mealPlanDateAssignmentToSaveTooltips(dateAssignmentState);
      case 3:
        return mealPlanFormToSaveTooltips(mpFormState);
      case 4:
        return foodPreferenceFormToSaveTooltips(foodPreferenceFormState);
      default:
        return [];
    }
  };

  const runImperialSideEffects = () => {
    const { calorieBudget } = imperialMacroProtocolFormState;
    const { goalSnapshot } = calorieBudget;
    const { type } = goalSnapshot;
    switch (activeStep) {
      case 1:
        mptFormDispatch({
          type: "CHANGE_NAME",
          payload: getInitialMealPlanName(
            imperialMacroProtocolFormState,
            { ...mostRecentAnthropometryEntry, age: athleteAge },
            type && { ...goalSnapshot, type },
            sportName
          ),
        });
        break;
      case 4:
        const input = getMetricInputFromImperialState(
          athleteId,
          foodLogMergeState,
          dateAssignmentState,
          isAutoSuggestionsEnabled,
          mpFormState,
          imperialMacroProtocolFormState,
          currentGoal,
          foodPreferenceFormState
        );
        if (!input) {
          return;
        }

        createMetricMealPlanFromFoodLogs({
          variables: {
            input,
          },
        });
        break;
    }
  };

  const runMetricSideEffects = () => {
    const { calorieBudget } = metricMacroProtocolFormState;
    const { goalSnapshot } = calorieBudget;
    const { type } = goalSnapshot;

    switch (activeStep) {
      case 1:
        mptFormDispatch({
          type: "CHANGE_NAME",
          payload: getInitialMealPlanName(
            imperialMacroProtocolFormState,
            { ...mostRecentAnthropometryEntry, age: athleteAge },
            type && { ...goalSnapshot, type },
            sportName
          ),
        });
        break;
      case 4:
        const input = getMetricInputFromMetricState(
          athleteId,
          foodLogMergeState,
          dateAssignmentState,
          isAutoSuggestionsEnabled,
          mpFormState,
          metricMacroProtocolFormState,
          currentGoal,
          foodPreferenceFormState
        );
        if (!input) {
          return;
        }

        createMetricMealPlanFromFoodLogs({
          variables: {
            input,
          },
        });
        break;
    }
  };

  const runSideEffects = () => (isMetricLocale ? runMetricSideEffects() : runImperialSideEffects());

  return (
    <MealPlanCalendarContextProvider>
      <ActionDialog open={open} onClose={onClose}>
        <ActionDialogContent
          title="Create Meal Plan From Food Logs"
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          runSideEffects={runSideEffects}
          getCanSaveTooltipItems={getCanSaveTooltipItems}
          onClose={onClose}
        >
          {saving ? (
            <Loading progressSize="md" />
          ) : activeStep === 0 ? (
            <FoodLogMergeForm
              state={foodLogMergeState}
              onChange={setFoodLogMergeState}
              onReset={() => setFoodLogMergeState(getFoodLogMergeFormState([], []))}
              allTimelineMeals={allTimelineMeals}
            />
          ) : activeStep === 1 ? (
            isMetricLocale ? (
              <MetricMacroProtocolForm
                state={metricMacroProtocolFormState}
                dispatch={metricMacroProtocolFormDispatch}
                goalTypes={goalTypes}
              />
            ) : (
              <ImperialMacroProtocolForm
                state={imperialMacroProtocolFormState}
                dispatch={imperialMacroProtocolFormDispatch}
                goalTypes={goalTypes}
              />
            )
          ) : activeStep === 2 ? (
            <DateAssignment
              state={dateAssignmentState}
              dispatch={dateAssignmentDispatch}
              isAutoSuggestionsEnabled={isAutoSuggestionsEnabled}
              setIsAutoSuggestionsEnabled={setIsAutoSuggestionsEnabled}
            />
          ) : activeStep === 3 ? (
            <NameAndTypeForm state={mpFormState} dispatch={mptFormDispatch} />
          ) : activeStep === 4 ? (
            <FoodPreferenceMealPlanForm state={foodPreferenceFormState} dispatch={foodPreferenceFormDispatch} />
          ) : (
            <Loading progressSize="md" />
          )}
        </ActionDialogContent>
      </ActionDialog>
    </MealPlanCalendarContextProvider>
  );
};

export default CreateMealPlanFromFoodLogsModal;

const getMetricInputFromMetricState = (
  athleteId: string,
  foodLogMergeState: FoodLogMergeFormState,
  dateAssignmentState: MealPlanDateAssignment,
  isAutoSuggestionsEnabled: boolean,
  { exchangeSet, name, description, isShared, type }: MealPlanFormState,
  macroProtocolState: EditMetricMacroProtocolState,
  currentGoal: FullGoalFragment | null,
  foodPreferenceState: FoodPreferenceState
): CreateMetricMealPlanFromFoodLogsInput | null => {
  const dateAssignment = getOverrideMealPlanDateAssignmentInput(dateAssignmentState);
  const macroProtocol = editMetricMacroProtocolStateInMealPlanToMetricMacroProtocolInput(macroProtocolState, currentGoal);
  if (!dateAssignment || !macroProtocol) {
    return null;
  }

  return {
    athleteId,
    type,
    exchangeSetId: exchangeSet?.id ?? null,
    name,
    description,
    isShared,
    isAutoSuggestionsEnabled,
    foodLogMealTemplates: foodLogMergeState.meals.map(({ timelineMealIds, startTime, endTime, name, type }) => ({
      timelineMealIds,
      meal: {
        name,
        start: startTime,
        end: endTime,
        type,
      },
    })),
    foodLogActivityTemplates: foodLogMergeState.activities.map(({ startTime, endTime, name, type }) => ({
      name,
      start: startTime,
      end: endTime,
      type,
    })),
    dateAssignment,
    macroProtocol,
    foodPreferences: pluckIdsFromFoodPreferenceState(foodPreferenceState),
  };
};

const getMetricInputFromImperialState = (
  athleteId: string,
  foodLogMergeState: FoodLogMergeFormState,
  dateAssignmentState: MealPlanDateAssignment,
  isAutoSuggestionsEnabled: boolean,
  { exchangeSet, name, description, isShared, type }: MealPlanFormState,
  macroProtocolState: EditImperialMacroProtocolState,
  currentGoal: FullGoalFragment | null,
  foodPreferenceState: FoodPreferenceState
): CreateMetricMealPlanFromFoodLogsInput | null => {
  const dateAssignment = getOverrideMealPlanDateAssignmentInput(dateAssignmentState);
  const macroProtocol = editImperialMacroProtocolStateInMealPlanToMetricMacroProtocolInput(macroProtocolState, currentGoal);
  if (!dateAssignment || !macroProtocol) {
    return null;
  }

  return {
    athleteId,
    type,
    exchangeSetId: exchangeSet?.id ?? null,
    name,
    description,
    isShared,
    isAutoSuggestionsEnabled,
    foodLogMealTemplates: foodLogMergeState.meals.map(({ timelineMealIds, startTime, endTime, name, type }) => ({
      timelineMealIds,
      meal: {
        name,
        start: startTime,
        end: endTime,
        type,
      },
    })),
    foodLogActivityTemplates: foodLogMergeState.activities.map(({ startTime, endTime, name, type }) => ({
      name,
      start: startTime,
      end: endTime,
      type,
    })),
    dateAssignment,
    macroProtocol,
    foodPreferences: pluckIdsFromFoodPreferenceState(foodPreferenceState),
  };
};

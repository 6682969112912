import { MenuItem, SxProps, TextField } from "@mui/material";
import React from "react";
import { useLocalesQuery } from "../../types";

interface LocaleMultiSelectProps {
  values: readonly string[];
  onChange: (values: string[]) => void;
  error: boolean;
  helperText?: string;
  sx?: SxProps;
}

export const LocaleMultiSelect = ({ values, onChange, error, helperText, sx }: LocaleMultiSelectProps) => {
  const { data } = useLocalesQuery();
  return (
    <TextField
      select
      label="Locale"
      fullWidth
      SelectProps={{
        multiple: true,
      }}
      sx={sx}
      value={values}
      error={error}
      helperText={helperText}
      onChange={e => onChange(e.target.value as unknown as string[])}
    >
      {data?.locales.map(locale => (
        <MenuItem value={locale.code} key={locale.code}>
          {locale.code}
        </MenuItem>
      ))}
    </TextField>
  );
};

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, TableCell, TableRow, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHasFeature } from "@notemeal/shared/ui/Feature";
import React, { useState } from "react";
import { NamedTagPreviewFragment } from "../../types";
import { useUser } from "../../utils/tokens";
import NamedTagChip from "./NamedTagChip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      marginLeft: theme.spacing(),
    },
  })
);

export const HeaderRow = () => {
  const showPrintableTags = useHasFeature("printableTags");
  return (
    <TableRow>
      <TableCell padding="checkbox">Actions</TableCell>
      <TableCell>Tag Name</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Tag Type</TableCell>
      <TableCell>Created By</TableCell>
      {showPrintableTags && <TableCell>Visible on Order</TableCell>}
      <TableCell># Athletes</TableCell>
    </TableRow>
  );
};

interface RowProps {
  namedTagPreview: NamedTagPreviewFragment;
  onClick: (tagId: string) => void;
  onDelete: (namedTagPreview: NamedTagPreviewFragment) => void;
  onDuplicate: (tagId: string) => void;
}

export const Row = ({ namedTagPreview, onClick, onDelete, onDuplicate }: RowProps) => {
  const user = useUser();
  const classes = useStyles();
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const showPrintableTags = useHasFeature("printableTags");

  const { createdBy, __typename, athleteCount, id: tagId } = namedTagPreview.tag;

  const meString = createdBy && createdBy.id === user?.id ? " (me)" : "";
  const createdByString = createdBy ? `${createdBy.firstName} ${createdBy.lastName}${meString}` : "";
  const tagType = __typename === "AthleteTag" ? "Custom Tag" : "Smart Tag";
  return (
    <>
      <TableRow onClick={() => onClick(tagId)}>
        <TableCell padding="checkbox">
          <IconButton
            className={classes.actionButton}
            onClick={e => {
              e.stopPropagation();
              setMoreAnchorEl(e.currentTarget);
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <NamedTagChip namedTag={namedTagPreview} />
        </TableCell>
        <TableCell>{namedTagPreview.tagName.description}</TableCell>
        <TableCell>{tagType}</TableCell>
        <TableCell>{createdByString}</TableCell>
        {showPrintableTags && <TableCell>{namedTagPreview.tagName.isPrintable ? "✓" : ""}</TableCell>}
        <TableCell>{athleteCount}</TableCell>
      </TableRow>
      <Menu
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={() => setMoreAnchorEl(null)}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            onDuplicate(tagId);
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            onDelete(namedTagPreview);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

import { Checkbox, FormControlLabel, SxProps, Typography } from "@mui/material";
import React from "react";

interface ImportHubCheckInCheckboxProps {
  onChange: (value: boolean) => void;
  sx?: SxProps;
}

export const ImportHubCheckInCheckbox = ({ onChange, sx }: ImportHubCheckInCheckboxProps) => {
  return (
    <FormControlLabel
      label={<Typography variant="body2">Enable Kiosk Check-in</Typography>}
      control={<Checkbox onChange={e => onChange(e.target.checked)} sx={sx} />}
    />
  );
};

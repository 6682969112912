import React, { Dispatch } from "react";
import { ScheduleModalEditorState } from "../../../reducers/ScheduleModalEditor";
import ScheduleModalContextProvider from "../../Schedule/Modal/ContextProvider";
import ScheduleModalEditor from "../../Schedule/Modal/Editor";
import {
  ScheduleAction,
  ScheduleState,
  convertScheduleModalEditorActivityToTemplateScheduleActivity,
  convertScheduleModalEditorMealToTemplateScheduleMeal,
  convertTemplateScheduleStateToScheduleEditorState,
} from "./scheduleReducer";

interface EditScheduleProps {
  open: boolean;
  onClose: () => void;
  dispatch: Dispatch<ScheduleAction>;
  state: ScheduleState;
}

export const EditSchedule = ({ open, onClose, dispatch, state }: EditScheduleProps) => {
  // This structure forces a reset of the scheduleModalEditReducer when the modal closes
  // Otherwise initial state is only ever used once

  const onDone = (newState: ScheduleModalEditorState) => {
    dispatch({
      type: "OVERWRITE_SCHEDULE_STATE",
      payload: {
        meals: newState.meals.map(meal => convertScheduleModalEditorMealToTemplateScheduleMeal(meal, state.meals)),
        activities: newState.activities.map(convertScheduleModalEditorActivityToTemplateScheduleActivity),
      },
    });
    onClose();
  };

  return (
    <ScheduleModalContextProvider initialState={convertTemplateScheduleStateToScheduleEditorState(state)}>
      <ScheduleModalEditor
        title="Edit Schedule"
        open={open}
        onClose={onClose}
        onSave={onDone}
        numberMealPlans={0}
        showScheduleName={false}
      />
    </ScheduleModalContextProvider>
  );
};

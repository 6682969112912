import { Dialog, DialogActions, DialogContent, DialogContentText, DialogContentTextProps } from "@mui/material";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import React, { ReactNode } from "react";
import DialogTitle from "../../../componentLibrary/DialogTitle";

interface UnsavedChangesDialogProps {
  message: string | ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  title: string;
  messageSx?: DialogContentTextProps["sx"];
}

export const UnsavedChangesDialog = ({ title, open, message, onCancel, onConfirm, messageSx }: UnsavedChangesDialogProps) => (
  <Dialog maxWidth="xs" open={open}>
    <DialogTitle title={title} onClose={onCancel} />
    <DialogContent>
      <DialogContentText sx={messageSx}>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <LoadingButton
        onClick={onConfirm}
        autoFocus
        buttonText={"Okay"} />
    </DialogActions>
  </Dialog>
);

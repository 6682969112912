import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, SxProps, Typography, TypographyProps } from "@mui/material";
import { addDays, format, getMonth, getYear } from "date-fns";
import React, { ReactNode } from "react";

// TODO: Locale low priority
const getNavigationText = (startOfWeekDate: Date) => {
  const endOfWeekDate = addDays(startOfWeekDate, 6);
  if (getYear(startOfWeekDate) !== getYear(endOfWeekDate)) {
    return `${format(startOfWeekDate, "MMM y")} - ${format(endOfWeekDate, "MMM y")}`;
  } else if (getMonth(startOfWeekDate) !== getMonth(endOfWeekDate)) {
    return `${format(startOfWeekDate, "MMM")} - ${format(endOfWeekDate, "MMM y")}`;
  } else {
    return format(startOfWeekDate, "MMMM y");
  }
};

interface CalendarWeeklyNavigationProps {
  startOfWeekDate: Date;
  onForwards: () => void;
  onBackwards: () => void;
  typographyProps?: TypographyProps;
  className?: string;
  sx?: SxProps;
}

const CalendarWeeklyNavigation = ({ startOfWeekDate, onForwards, onBackwards, sx, typographyProps }: CalendarWeeklyNavigationProps) => {
  return (
    <Box sx={{ ...sx, display: "flex", alignItems: "center" }}>
      <WeeklyNavigationButton onClick={() => onBackwards()}>
        <NavigateBeforeIcon color="action" />
      </WeeklyNavigationButton>
      <Typography
        {...typographyProps}
        className={typographyProps?.className}
        sx={{ mr: 2, ml: 2 }}>
        {getNavigationText(startOfWeekDate)}
      </Typography>
      <WeeklyNavigationButton onClick={() => onForwards()}>
        <NavigateNextIcon color="action" />
      </WeeklyNavigationButton>
    </Box>
  );
};

interface WeeklyNavigationButtonProps {
  onClick: () => void;
  children: ReactNode;
}

const WeeklyNavigationButton = ({ onClick, children }: WeeklyNavigationButtonProps) => {
  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => onClick()}
      sx={{ minWidth: 0, p: 0.5 }}>
      {children}
    </Button>
  );
};

export default CalendarWeeklyNavigation;

import { datadogRum } from "@datadog/browser-rum";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Box, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { DayOfWeek } from "@notemeal/graphql/types";
import { grayBackground } from "@notemeal/palette";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import { ConfirmationDialog } from "apps/web/src/componentLibrary";
import { TWTooltip } from "apps/web/src/componentLibrary/TWTooltip/TWTooltip";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { addDays, format } from "date-fns";
import React, { useState } from "react";
import { Controller, UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { useMenuBuilderContext } from "../MenuBuilderProvider";
import { DAY_CELL_WIDTH, LEFT_PANEL_CELL_WIDTH } from "./constants";
import MenuBuilderAthletesBirthdayModal from "./MenuBuilderAthletesBirthdayModal";
import { MenuBuilderColumnToolbar } from "./MenuBuilderColumnToolbar";
import { MenuBuilderMealGridRow } from "./MenuBuilderMealGridRow";
import { MenuBuilderType } from "./MenuBuilderMealSchema";

interface MenuBuilderMealProps {
  rows: UseFieldArrayReturn<MenuBuilderType>;
  form: UseFormReturn<MenuBuilderType>;
  weekFormIndex: number;
  mealFormIndex: number;
}

export const MenuBuilderMealGrid = ({ rows, form, mealFormIndex, weekFormIndex }: MenuBuilderMealProps) => {
  const {
    days,
    weeks,
    selectedWeekIndex,
    setRowsToDelete,
    rowsToDelete,
    menuId,
    disabledDays,
    setDisabledDays,
    selectedDay,
    setSelectedDay,
    markMealAsUpdated,
    isEditable,
    athleteBirthdays,
  } = useMenuBuilderContext();
  const [toolbarPosition, setToolbarPosition] = useState<{ top: number; left: number } | undefined>(undefined);
  const [deleteMenuItemDialogOpen, setDeleteMenuItemDialogOpen] = useState(false);
  const {
    palette: { background, info, greyscale },
  } = useTheme();
  const { setMessage } = useSnackbar();
  const { formatYearlessDateWithLocale } = useDateFormatting();

  const {
    control,
    formState: { errors },
  } = form;

  const { id: currentWeekId, startDate } = weeks[selectedWeekIndex];

  const currentMealErrors = errors?.weeks?.[weekFormIndex]?.meals?.[mealFormIndex];

  const deleteRow = (rowFormIndex: number) => {
    datadogRum.addAction("menu_builder.removed_dining_station", { menuId });
    // only delete rows that previously existed
    const rowId = form.getValues(`weeks.${weekFormIndex}.meals.${mealFormIndex}.rows.${rowFormIndex}`).id;
    if (rowId) {
      setRowsToDelete([...rowsToDelete, rowId]);
    }
    rows.remove(rowFormIndex);
  };
  const currentWeeksDisabledDays = disabledDays.filter(d => d.plannedMenuWeekId === currentWeekId).flatMap(d => d.disabledDays);
  const isDayEnabled = (day: DayOfWeek) => !currentWeeksDisabledDays.includes(day);

  const displayDayOfWeek = (day: DayOfWeek) => {
    return `${formatYearlessDateWithLocale(addDays(startDate, days.indexOf(day)))} (${capitalize(day)})`;
  };

  const disableDay = (day: DayOfWeek) => {
    const dayIndex = days.indexOf(day);
    datadogRum.addAction("menu_builder.disable_day", { menuId });
    setDisabledDays([
      ...disabledDays.filter(d => d.plannedMenuWeekId !== currentWeekId),
      {
        plannedMenuWeekId: currentWeekId,
        disabledDays: [...currentWeeksDisabledDays, day],
      },
    ]);

    markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);

    const allWeeks = form.getValues("weeks");

    // clear themes for disabled day across all meals
    allWeeks[weekFormIndex].meals.forEach((_, mealIndex: number) => {
      const themes = form.getValues(`weeks.${weekFormIndex}.meals.${mealIndex}.themes`);
      themes[dayIndex] = "";
      form.setValue(`weeks.${weekFormIndex}.meals.${mealIndex}.themes`, themes);
    });

    setDeleteMenuItemDialogOpen(false);
    closeToolbar();
    setMessage("success", `Disabled ${displayDayOfWeek(day)}`);
  };

  const maybeDisableDay = (day: DayOfWeek) => {
    const itemsForDay = form
      .getValues(`weeks.${weekFormIndex}.meals`)
      .flatMap(meal => meal.rows)
      .flatMap(row => row.items)
      .filter(item => item.dayOfWeek === day);
    if (itemsForDay.length === 0) {
      disableDay(day);
    } else {
      setDeleteMenuItemDialogOpen(true);
    }
  };

  const enableDay = (day: DayOfWeek) => {
    datadogRum.addAction("menu_builder.enable_day", { menuId });
    setDisabledDays([
      ...disabledDays.filter(d => d.plannedMenuWeekId !== currentWeekId),
      {
        plannedMenuWeekId: currentWeekId,
        disabledDays: currentWeeksDisabledDays.filter(d => d !== day),
      },
    ]);
    closeToolbar();
    markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
    setMessage("success", `Enabled ${displayDayOfWeek(day)}`);
  };

  const getBackgroundAndBorder = (day: DayOfWeek) => {
    if (!isDayEnabled(day)) {
      return { backgroundColor: greyscale[200], borderColor: greyscale[300], borderWidth: "0px 1px 1px 0px" };
    } else if (selectedDay === day) {
      return { backgroundColor: info.lighter, borderColor: info.main, borderWidth: "1px" };
    } else {
      return { backgroundColor: background.default, borderColor: greyscale[300], borderWidth: "0px 1px 1px 0px" };
    }
  };

  const closeToolbar = () => {
    setToolbarPosition(undefined);
    setSelectedDay(undefined);
  };

  return (
    <Box>
      {/* header with days */}
      <Box sx={{ display: "grid" }}>
        <Box
          sx={{
            backgroundColor: grayBackground,
            minWidth: LEFT_PANEL_CELL_WIDTH,
            height: "50px",
            borderWidth: "0px 1px 1px 1px",
            borderStyle: "solid",
            borderColor: greyscale[300],
          }}
        ></Box>
        {days.map((day, index) => (
          <Tooltip
            key={day}
            title={
              isDayEnabled(day) ? (
                ""
              ) : (
                <>
                  <Typography sx={{ color: "white" }} variant="subtitle1Semibold">
                    {`Disabled ${displayDayOfWeek(day)}`}
                  </Typography>
                  <Typography sx={{ color: "white", width: "197px" }} variant="subtitle1">
                    No meals will be available for order and it will not be visible to athletes
                  </Typography>
                </>
              )
            }
          >
            <Box
              sx={{
                ...getBackgroundAndBorder(day),
                borderStyle: "solid",
                gridColumn: `${index + 2}`,
                width: DAY_CELL_WIDTH,
                height: "50px",
                justifyContent: "center",
                alignContent: "center",
                display: "grid",
                "&:hover": isEditable ? { cursor: "pointer" } : undefined,
              }}
              onClick={e => {
                e.stopPropagation();
                if (isEditable) {
                  setToolbarPosition({ top: e.clientY, left: e.clientX });
                  setSelectedDay(day);
                }
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}>
                <Box>
                  <Typography display="inline" variant="body2">
                    {capitalize(day)}{" "}
                  </Typography>
                  <Typography variant="body2Semibold">{format(addDays(startDate, index), "d")}</Typography>
                </Box>
                <MenuBuilderAthletesBirthdayModal
                  athleteBirthdays={athleteBirthdays}
                  birthDayFormat={format(addDays(startDate, index), "MM-dd")}
                />
              </Box>
            </Box>
          </Tooltip>
        ))}
      </Box>
      {/* meal theme row */}
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            backgroundColor: grayBackground,
            minWidth: LEFT_PANEL_CELL_WIDTH,
            minHeight: "60px",
            borderWidth: "0px 1px 1px 1px",
            borderStyle: "solid",
            borderColor: greyscale[300],
            display: "flex",
            pl: 2,
          }}
        >
          <Typography
            color="mediumEmphasisText"
            sx={{ alignContent: "center", fontSize: "12px", fontWeight: "600", display: "flex", alignItems: "center" }}
          >
            Theme (optional)
            <TWTooltip title="Theme (optional)" subcopy="Theme will be visible on Menu Schedule and the athlete mobile app">
              <InfoRoundedIcon fontSize="small" sx={{ ml: 1, color: "black" }} />
            </TWTooltip>
          </Typography>
        </Box>

        {days.map((day, index) => (
          <Controller
            key={index}
            name={`weeks.${weekFormIndex}.meals.${mealFormIndex}.themes.${index}`}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                variant="outlined"
                onBlur={field.onBlur}
                placeholder={isDayEnabled(day) ? "e.g. Mediterranean" : ""}
                disabled={!isDayEnabled(day) || !isEditable}
                multiline
                sx={{
                  width: "217px",
                  minHeight: "60px",
                  margin: 0,
                  p: 0,
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                    "& fieldset": {
                      borderRadius: "0px",
                      borderWidth: "0px 1px 1px 0px",
                    },
                    "&.Mui-disabled": {
                      borderRadius: "0px",
                      "& fieldset": {
                        borderColor: greyscale[300],
                        backgroundColor: !isDayEnabled(day) ? greyscale[200] : undefined,
                      },
                    },
                    "&:hover": {
                      backgroundColor: isDayEnabled(day) && isEditable ? grayBackground : undefined,
                      "& fieldset": {
                        borderWidth: "0px 1px 1px 0px",
                        borderColor: greyscale[300],
                      },
                    },
                    "&.Mui-focused": {
                      backgroundColor: background.default,
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: info.main,
                      },
                    },
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: `${selectedDay === day && isDayEnabled(day) ? info.lighter : background.default}`,
                  },
                }}
                InputProps={{
                  sx: {
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                      lineHeight: "1.25",
                    },
                  },
                }}
                error={Boolean(currentMealErrors?.themes)}
                onChange={e => {
                  field.onChange(e);
                  markMealAsUpdated(`weeks.${weekFormIndex}.meals.${mealFormIndex}`);
                }}
              />
            )}
          />
        ))}
      </Box>
      {/* cells for rows with menu items */}
      {rows.fields.map((row, index) => (
        <MenuBuilderMealGridRow
          key={row.id}
          form={form}
          weekFormIndex={weekFormIndex}
          mealFormIndex={mealFormIndex}
          rowFormIndex={index}
          deleteRow={deleteRow}
          currentWeeksDisabledDays={currentWeeksDisabledDays}
        />
      ))}

      {selectedDay && (
        <>
          <MenuBuilderColumnToolbar
            position={toolbarPosition}
            onClickDisable={() => maybeDisableDay(selectedDay)}
            onClickEnable={() => enableDay(selectedDay)}
            isDayEnabled={isDayEnabled(selectedDay)}
            onClose={closeToolbar}
          />
          <ConfirmationDialog
            open={deleteMenuItemDialogOpen}
            title="Delete Menu Items"
            message={`Are you sure you want to disable the column? Any menu items that have been added to the date will be deleted.`}
            onCancel={() => {
              datadogRum.addAction("menu_builder.cancelled_disable_day", { menuId });
              setDeleteMenuItemDialogOpen(false);
              closeToolbar();
            }}
            onConfirm={() => disableDay(selectedDay)}
            confirmLabel="Yes"
            variant="containedDestructive"
          />
        </>
      )}
    </Box>
  );
};

import { Box, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { ExchangeType, UnitWithConversionsFragment } from "../../../../types";
import ExchangeTypeMultiSelect from "../../../Exchange/TypeMultiSelect";
import { WarningTooltip } from "../../../universal/Tooltip";
import { BaseFoodFormType } from "../../FoodFormSchema/FoodFormSchema";
import { FoodDataSourceSection } from "./FoodDataSourceSection";
import { FoodGroceryCategorySelect } from "./FoodGroceryCategorySelect";
import { FoodGroupsSection } from "./FoodGroupsSection";
import { FoodMacros } from "./FoodMacros";
import { FoodTypeSelect } from "./FoodTypeSelect";

export type SimpleFoodGroup = {
  id: string;
  name: string;
};

interface FoodDetailsSectionProps {
  form: UseFormReturn<BaseFoodFormType>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  unitsData: readonly UnitWithConversionsFragment[];
  gramUnit: UnitWithConversionsFragment;
  disabled?: boolean;
}

export const FoodDetailsSection = ({ form, setIsLoading, unitsData, gramUnit, disabled }: FoodDetailsSectionProps) => {
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = form;

  const [watchedMode] = watch(["mode"]);
  const isRestaurant = watchedMode === "restaurant";
  const isStaffMode = watchedMode === "staff";
  const isOrgMode = watchedMode === "org";
  const disableMacros = isOrgMode;

  const handleChangeExchangeTypes = (exchangeTypes: ExchangeType[]) => {
    if (exchangeTypes.length > 0) {
      setValue("isFreeFood", false);
    }
    setValue("exchangeTypes", exchangeTypes, { shouldValidate: true });
  };

  const handleChangeIsFreeFood = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue("isFreeFood", checked);
    setValue("exchangeTypes", checked ? null : []);
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <Controller
          name={"name"}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              required
              fullWidth
              label="Food Name"
              disabled={disabled}
            />
          )}
        />
        <Box display={"flex"} flexDirection={"column"}>
          <Controller
            name={"isRecipeIngredientOnly"}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <FormControlLabel
                label="Recipe Ingredient Only?"
                control={<Checkbox
                  {...field}
                  disabled={disabled}
                  checked={field.value}
                  value="isRecipeIngredient" />}
              />
            )}
          />

          {isStaffMode && (
            <Controller
              name={"excludeFromSuggestions"}
              control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControlLabel
                  label="Exclude From Suggestions"
                  control={<Checkbox
                    {...field}
                    disabled={disabled}
                    checked={field.value}
                    value="excludeFromSuggestions" />}
                />
              )}
            />
          )}
        </Box>
        <FoodMacros isDisabled={disabled || disableMacros} form={form} />

        <Controller
          name={"groceryListCategoryId"}
          control={control}
          render={({ field: { ref, onChange, ...field } }) => (
            <FoodGroceryCategorySelect
              sx={{ mt: 4 }}
              disabled={disabled}
              groceryListCategoryId={field.value}
              changeGroceryListCategory={onChange}
            />
          )}
        />
      </Grid>

      {!isRestaurant && (
        <Grid item xs={6}>
          <FoodDataSourceSection
            disabled={disabled}
            unitsData={unitsData}
            gramUnit={gramUnit}
            form={form}
            setIsLoading={setIsLoading} />

          {isStaffMode && (
            <Grid item xs={12}>
              <FoodGroupsSection form={form} />
            </Grid>
          )}

          <Grid
            container
            spacing={2}
            pt={1}>
            <Grid item xs={6}>
              <Controller
                name={"exchangeTypes"}
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <ExchangeTypeMultiSelect
                    {...field}
                    disabled={disabled}
                    margin="dense"
                    fullWidth
                    label="Exchange Types"
                    error={Boolean(errors.exchangeTypes)}
                    helperText={errors.exchangeTypes?.message}
                    handleChangeExchangeTypes={handleChangeExchangeTypes}
                    selectedExchangeTypes={field.value}
                  />
                )}
              />

              <Controller
                name={"isFreeFood"}
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <FormControlLabel
                    disabled={disabled}
                    control={
                      <WarningTooltip msg="Setting exchanges to not applicable will prevent this food from counting towards exchange totals in an exchange meal plan.">
                        <Checkbox
                          {...field}
                          disabled={disabled}
                          onChange={handleChangeIsFreeFood}
                          checked={field.value}
                          value="isFreeFood"
                        />
                      </WarningTooltip>
                    }
                    label="Not applicable"
                  />
                )}
              />
            </Grid>

            {!isOrgMode && <FoodTypeSelect form={form} disabled={disabled} />}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

import { OrgGroupFormType, orgGroupFormToInput } from "apps/web/src/components/OrgGroup/OrgGroupFormSchema";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import { StaffDashboardOrgGroupsDocument, StaffDashboardOrgGroupsQuery, useStaffDashboardCreateOrgGroupMutation } from "apps/web/src/types";
import React from "react";
import { BaseOrgGroupModal } from "./BaseOrgGroupModal";

interface CreateOrgGroupModalProps {
  open: boolean;
  onClose: () => void;
}

export const CreateOrgGroupModal = ({ open, onClose }: CreateOrgGroupModalProps) => {
  const { setMessage } = useSnackbar();
  const [createOrgGroup] = useStaffDashboardCreateOrgGroupMutation({
    update: (dataProxy, result) => {
      const dashboardQuery = dataProxy.readQuery<StaffDashboardOrgGroupsQuery>({
        query: StaffDashboardOrgGroupsDocument,
      });
      if (result.data && dashboardQuery) {
        dataProxy.writeQuery({
          query: StaffDashboardOrgGroupsDocument,
          data: {
            ...dashboardQuery,
            orgGroups: [...dashboardQuery.orgGroups, result.data.createOrgGroup.orgGroup],
          },
        });
      }
    },
    onError: e => setMessage("error", e.message),
  });

  const handleSubmit = (orgGroup: OrgGroupFormType) => {
    createOrgGroup({ variables: { input: orgGroupFormToInput(orgGroup) } });
  };

  return <BaseOrgGroupModal
    open={open}
    onClose={onClose}
    title={"Create Org Group"}
    onSubmit={handleSubmit} />;
};

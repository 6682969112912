import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";

import DisplaySettingsPreview from "../../../../components/DisplaySettings/Preview";
import EditModal from "./EditModal";

import {
  DEFAULT_EXCHANGE_DISPLAY_SETTINGS,
  DEFAULT_MACRO_DISPLAY_SETTINGS,
  DEFAULT_NO_TARGETS_DISPLAY_SETTINGS,
} from "@notemeal/shared/ui/DisplaySettings/utils";
import { AthleteSummaryAthleteFragment } from "../../../../types";

interface AthleteSummaryDisplaySettingsProps {
  athlete: AthleteSummaryAthleteFragment;
}

const AthleteSummaryDisplaySettings = ({ athlete }: AthleteSummaryDisplaySettingsProps) => {
  const [editOpen, setEditOpen] = useState(false);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3">Display Settings</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setEditOpen(true)}>
          Edit
        </Button>
      </Box>
      <DisplaySettingsPreview
        macroDisplaySettings={athlete.macroDisplaySettings}
        exchangeDisplaySettings={athlete.exchangeDisplaySettings}
        noTargetsDisplaySettings={athlete.noTargetsDisplaySettings}
      />
      <EditModal
        athleteId={athlete.id}
        defaultExchangeDisplaySettings={athlete.defaultExchangeDisplaySettings}
        defaultMacroDisplaySettings={athlete.defaultMacroDisplaySettings}
        defaultNoTargetsDisplaySettings={athlete.defaultNoTargetsDisplaySettings}
        defaultHideAnthropometry={athlete.defaultHideAnthropometry}
        teamExchangeDisplaySettings={athlete.team.defaultExchangeDisplaySettings || DEFAULT_EXCHANGE_DISPLAY_SETTINGS}
        teamMacroDisplaySettings={athlete.team.defaultMacroDisplaySettings || DEFAULT_MACRO_DISPLAY_SETTINGS}
        teamNoTargetsDisplaySettings={athlete.team.defaultNoTargetsDisplaySettings || DEFAULT_NO_TARGETS_DISPLAY_SETTINGS}
        teamHideAnthropometry={athlete.team.defaultHideAnthropometry ?? false}
        open={editOpen}
        onClose={() => setEditOpen(false)}
      />
    </Box>
  );
};

export default AthleteSummaryDisplaySettings;

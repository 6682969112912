import { formatEventTime, getDefaultEndTime } from "../utils/dateTimes";
import { ActivityFragment, AddActivityInput, ActivityType } from "../types";

export const formatActivity = (activity: Pick<ActivityFragment, "start" | "end" | "name">) => {
  return `${activity.name} (${formatEventTime(activity)})`;
};

export type ActivityTypeChoice = "Practice" | "Lift" | "Conditioning" | "Competition";

export const orderedActivityTypeChoices: readonly ActivityTypeChoice[] = ["Practice", "Lift", "Conditioning", "Competition"];

export const orderedActivityTypes: readonly ActivityType[] = ["practice", "lift", "conditioning", "competition"];

interface activityTypeChoiceToInputArgs {
  activityTypeChoice: ActivityTypeChoice;
  start: string;
  end?: string | null;
  name?: string;
}

export const activityTypeChoiceToInput = ({ activityTypeChoice, start, end, name }: activityTypeChoiceToInputArgs): AddActivityInput => {
  return {
    start,
    end: end || getDefaultEndTime(start),
    name: name || activityTypeChoice,
    type:
      activityTypeChoice === "Practice"
        ? "practice"
        : activityTypeChoice === "Lift"
        ? "lift"
        : activityTypeChoice === "Conditioning"
        ? "conditioning"
        : "competition",
  };
};

import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { useDraggable } from "@notemeal/shared/ui/hooks/useDraggable";
import { sortByKey } from "@notemeal/utils/sort";
import React from "react";
import { animated } from "react-spring";
import { MenuItemChoiceFormFragment } from "../../../types";
import WarningIcon from "../../universal/WarningIcon";
import { menuItemChoiceIsMissingAnyIngredients, menuItemChoiceUsesOldRecipe } from "../utils";

interface MenuItemChoiceListProps {
  choices: readonly MenuItemChoiceFormFragment[];
  onAddChoice: () => void;
  onSelectChoice: (choice: MenuItemChoiceFormFragment) => void;
  onRemoveChoice: (id: string) => void;
  onChangeOrder: (ids: string[]) => void;
}

const MenuItemChoiceList = ({ choices: _choices, onAddChoice, onChangeOrder, onRemoveChoice, onSelectChoice }: MenuItemChoiceListProps) => {
  const choices = sortByKey(_choices, "position");
  const draggableChoices = choices.map(({ id }, index) => ({
    id,
    index,
    height: 61.3,
  }));
  const { springs, bind } = useDraggable(draggableChoices, onChangeOrder);

  return (
    <List sx={{ ml: 0, position: "relative", overflowY: "auto" }}>
      {sortByKey(choices, "position").map(c => {
        const springObject = draggableChoices.find(({ id }) => id === c.id);
        if (!springObject) {
          return null;
        }

        const spring = springs[springObject.index];
        const bindProps = bind(springObject.index);

        if (!spring || !bindProps) {
          return null;
        }
        const { zIndex, y, shadow } = spring;

        const missingAnyIngredientsTooltip = menuItemChoiceIsMissingAnyIngredients(c) ? "Some options are missing ingredients" : null;
        const usingOldRecipeTooltip = menuItemChoiceUsesOldRecipe(c) ? "Some options using old recipes" : null;

        return (
          <animated.div
            key={c.id}
            style={{
              zIndex,
              boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px 0px ${s}px 0px`),
              transform: y.to(y => `translate3d(0, ${y}px, 0)`),
            }}
          >
            <ListItem
              key={c.id}
              dense
              button
              onClick={() => onSelectChoice(c)}>
              <ListItemIcon sx={{ minWidth: 40 }} onClick={e => e.stopPropagation()}>
                <DragIndicatorIcon {...bindProps} />
              </ListItemIcon>
              <ListItemText primary={c.name} secondary={c.required ? "Required" : "Optional"} />
              <ListItemSecondaryAction sx={{ display: "flex", alignItems: "center" }}>
                {(missingAnyIngredientsTooltip || usingOldRecipeTooltip) && (
                  <WarningIcon
                    tip={
                      <>
                        {missingAnyIngredientsTooltip || ""}
                        {missingAnyIngredientsTooltip && usingOldRecipeTooltip && <br />}
                        {usingOldRecipeTooltip || ""}
                      </>
                    }
                  />
                )}
                <IconButton
                  edge="end"
                  onClick={() => onRemoveChoice(c.id)}
                  size="large">
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </animated.div>
        );
      })}
      <ListItem sx={{ p: 0 }}>
        <Button
          variant="outlined"
          sx={{ flexGrow: 1 }}
          onClick={onAddChoice}>
          New Add-on
        </Button>
      </ListItem>
    </List>
  );
};

export default MenuItemChoiceList;

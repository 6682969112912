import LinkIcon from "@mui/icons-material/Link";
import { Button, Dialog, DialogActions, DialogContent, IconButton, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import DialogTitle from "../../componentLibrary/DialogTitle";
import { getNavOrgAthleteAnthropometry, getNavOrgAthleteSummary } from "../../pages/Auth/Org/Athlete/AthletePaths";

const Row = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  paddingTop: 6,
}));

interface MacroProtocolMissingDataDialogProps {
  athleteId: string;
  noAnthropometry: boolean;
  noBirthdate: boolean;
  invalidAge: boolean;
  incompleteAnthropometry: boolean;
  // Only used in copy meal plan case, as goal will be created during create meal plan process
  noGoal?: boolean;
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

// TODO - This should get reworked to use the shared-utils/macro-protocol lib validation and/or other zod validation
const MacroProtocolMissingDataDialog = ({
  athleteId,
  noAnthropometry,
  noBirthdate,
  invalidAge,
  incompleteAnthropometry,
  noGoal = false,
  open,
  onClose,
  onContinue,
}: MacroProtocolMissingDataDialogProps) => {
  const anthroUrl = getNavOrgAthleteAnthropometry(athleteId);
  const summaryUrl = getNavOrgAthleteSummary(athleteId);

  const noAnthropometryComponent = (
    <>
      <DialogContent sx={{ flexDirection: "row", gap: 2 }}>
        <Link to={anthroUrl}>
          <IconButton size="large">
            <LinkIcon />
          </IconButton>
        </Link>
        <Typography>Athlete has no anthropometry entries, but one is required for creating a meal plan.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </>
  );

  const noGoalComponent = (
    <>
      <DialogContent sx={{ flexDirection: "row", gap: 2 }}>
        <Link to={summaryUrl}>
          <IconButton size="large">
            <LinkIcon />
          </IconButton>
        </Link>
        <Typography>Athlete has no goals, but one is required for copying a meal plan.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </>
  );

  const incompleteDataComponent = (
    <>
      <DialogContent>
        <Typography>Athlete is missing some data that will limit the macro protocol options, but nothing required.</Typography>
        {noBirthdate && (
          <Row>
            <Link to={summaryUrl}>
              <IconButton size="large">
                <LinkIcon />
              </IconButton>
            </Link>
            <Typography variant="body2">Athlete is missing birth date, can be set on Summary Page</Typography>
          </Row>
        )}
        {invalidAge && (
          <Row>
            <Link to={summaryUrl}>
              <IconButton size="large">
                <LinkIcon />
              </IconButton>
            </Link>
            <Typography variant="body2">Athlete must be at least 13 years old, please set a valid birthdate on the Summary Page</Typography>
          </Row>
        )}
        {incompleteAnthropometry && (
          <Row>
            <Link to={anthroUrl}>
              <IconButton size="large">
                <LinkIcon />
              </IconButton>
            </Link>
            <Typography variant="body2">
              Athlete is missing height and/or lean body mass and/or percent body fat from last anthropometry entry
            </Typography>
          </Row>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Back
        </Button>
        <Button autoFocus onClick={onContinue}>
          Continue
        </Button>
      </DialogActions>
    </>
  );

  const childComponent = noAnthropometry ? noAnthropometryComponent : noGoal ? noGoalComponent : incompleteDataComponent;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle title="Missing Data" onClose={onClose} />
      {childComponent}
    </Dialog>
  );
};

export default MacroProtocolMissingDataDialog;

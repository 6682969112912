import React from "react";
import LoadingBackdrop from "../../universal/LoadingBackdrop";
import { MenuItemState } from "../reducer";
import Content from "./Content";

interface MenuItemCreateDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (state: MenuItemState) => void;
  title: string;
  initState?: MenuItemState;
  saving: boolean;
  variant: "MenuItem" | "MenuItemAppearance";
}

const MenuItemDialog = ({ open, onClose, onSave, title, initState, saving, variant }: MenuItemCreateDialogProps) => {
  return saving ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Content
      open={open}
      onClose={onClose}
      onSave={onSave}
      title={title}
      variant={variant}
      initState={initState} />
  );
};

export default MenuItemDialog;

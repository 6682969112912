import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { formatEventTime } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import { useDrag } from "react-dnd";
import { FoodLogMealDnDType, TimelineMealForState } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    meal: {
      padding: theme.spacing(),
      margin: theme.spacing(),
      boxSizing: "border-box",
      height: 100,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    removeButton: {
      marginLeft: theme.spacing(),
    },
  })
);

interface FoodLogMergeFormMealProps {
  timelineMeal: TimelineMealForState;
  onRemove: () => void;
}

const FoodLogMergeFormMeal = ({ timelineMeal, onRemove }: FoodLogMergeFormMealProps) => {
  const classes = useStyles();
  const { formatDateWithLocale } = useDateFormatting();
  const [{ isDragging }, dragRef] = useDrag({
    type: FoodLogMealDnDType,
    item: { id: timelineMeal.id, type: FoodLogMealDnDType },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDragging) {
    return <div ref={dragRef} />;
  }

  return (
    <Paper className={classes.meal} ref={dragRef}>
      <div className={classes.header}>
        <Typography>{timelineMeal.name}</Typography>
        <IconButton
          size="small"
          className={classes.removeButton}
          onClick={onRemove}>
          <CloseIcon />
        </IconButton>
      </div>
      <Typography variant="subtitle1">
        {formatDateWithLocale(timelineMeal.date)}
        <br />
        {formatEventTime({
          start: timelineMeal.startTime,
          end: timelineMeal.endTime,
        })}
      </Typography>
    </Paper>
  );
};

export default FoodLogMergeFormMeal;

import { coercePhoneNumber } from "../../../../../components/global/PhoneNumberInput";
import { PossibleLocation } from "../../../../../components/Map/types";
import {
  RestaurantLocationFragment,
  DeliveryLocationMenuLinkFormFragment,
  AddRestaurantLocationInput,
  EditRestaurantLocationInput,
} from "../../../../../types";
import { invalidEmail } from "../../../../../utils/invalidEmail";
import { invalidPhoneNumber } from "@notemeal/utils/phone-number";

export interface RestaurantLocationFormState {
  type: "create" | "edit";
  notes: string | null;
  email: string | null;
  phoneNumber: string | null;
  isActive: boolean;
  cityInfo: string | null;
  deliveryLocations: readonly DeliveryLocationMenuLinkFormFragment[];
}

export const getCreateFormState = (): RestaurantLocationFormState => ({
  type: "create",
  notes: null,
  email: null,
  phoneNumber: null,
  cityInfo: null,
  isActive: true,
  deliveryLocations: [],
});

export const getEditFormState = (location: RestaurantLocationFragment): RestaurantLocationFormState => ({
  type: "edit",
  notes: location.notes,
  email: location.email,
  phoneNumber: location.phoneNumber,
  cityInfo: location.cityInfo,
  isActive: location.isActive,
  deliveryLocations: location.deliveryLocations,
});

export const locationFormTooltips = (state: RestaurantLocationFormState): string[] => {
  const tooltips: string[] = [];
  if (!state.phoneNumber?.trim() && !state.email?.trim()) {
    tooltips.push("One contact method is required");
  }
  if (state.phoneNumber && coercePhoneNumber(state.phoneNumber) && invalidPhoneNumber(state.phoneNumber)) {
    tooltips.push("Invalid phone number");
  }
  if (state.email && invalidEmail(state.email)) {
    tooltips.push("Invalid email");
  }
  return tooltips;
};

export const getAddRestaurantLocationInput = (
  state: RestaurantLocationFormState,
  location: PossibleLocation,
  restaurantId: string
): AddRestaurantLocationInput => ({
  restaurantId,
  googlePlaceId: location.googlePlaceId,
  lat: location.lat,
  long: location.long,
  phoneNumber: coercePhoneNumber(state.phoneNumber || "") || null,
  email: state.email || null,
  notes: state.notes || null,
  isActive: state.isActive,
  deliveryLocationIds: state.deliveryLocations.map(dl => dl.id),
});

export const getEditRestaurantLocationInput = (
  state: RestaurantLocationFormState,
  restaurantLocationId: string
): EditRestaurantLocationInput => ({
  restaurantLocationId,
  phoneNumber: coercePhoneNumber(state.phoneNumber || "") || null,
  email: state.email || null,
  notes: state.notes || null,
  isActive: state.isActive,
  deliveryLocationIds: state.deliveryLocations.map(dl => dl.id),
  cityInfo: state.cityInfo || null,
});

import React, { Dispatch } from "react";
import { CustomizationSelect } from "../CustomizationSelect";
import { DigitalDisplaysConfigActions } from "../reducer";

const BANNER = "Banner";
export const INCLUDE = "Include meal details";
export const EXCLUDE = "Exclude meal details";
const BANNER_OPTIONS = [INCLUDE, EXCLUDE] as const;
export type Banner = (typeof BANNER_OPTIONS)[number];
export const DEFAULT_BANNER = INCLUDE;

export interface BannerSelectProps {
  value: Banner;
  dispatch: Dispatch<DigitalDisplaysConfigActions>;
  className?: string;
}

export const BannerSelect = ({ value, dispatch, className }: BannerSelectProps) => {
  const handleSelectBanner = (value: Banner) => {
    dispatch({ type: "SET_BANNER", payload: value });
  };

  return <CustomizationSelect
    label={BANNER}
    value={value}
    onChange={handleSelectBanner}
    options={BANNER_OPTIONS}
    className={className} />;
};

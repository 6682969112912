import { z } from "zod";
import { BrandedFoodSchema, GenericFoodSchema } from "./FoodSchema";
import { IntegerSchema } from "@notemeal/validators";

const UsdaFoodSchema = z.object({
  usdaFdcDescription: z.string().optional().nullable(),
  usdaFdcId: IntegerSchema,
  usdaFdcFoodCategoryId: IntegerSchema.optional().nullable(),
  usdaFdcDataType: z.string().optional().nullable(),
  source: z.literal("usda"),
});

export const UsdaGenericFoodSchema = GenericFoodSchema.merge(UsdaFoodSchema);

export const UsdaBrandedFoodSchema = BrandedFoodSchema.merge(UsdaFoodSchema);

import differenceInHours from "date-fns/differenceInHours";
import { Maybe, OrgMembership } from "../types";
import { Role } from "../types";

export type AthleteMobileState = "inactive" | "waiting" | "expired" | "active";

interface AthleteWithMobileState {
  activationLinkSent: Maybe<string>;
  orgMembership: Maybe<Pick<OrgMembership, "id" | "isActive">>;
}

export const getAthleteMobileState = (athlete: AthleteWithMobileState): AthleteMobileState => {
  if (athlete.orgMembership?.isActive) {
    return "active";
  } else {
    if (!athlete.activationLinkSent) {
      return "inactive";
    } else {
      if (differenceInHours(new Date(), new Date(athlete.activationLinkSent)) > 72) {
        return "expired";
      } else {
        return "waiting";
      }
    }
  }
};

export const NEW_ATHLETE_ROLES: Role[] = ["athlete"];

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, styled } from "@mui/material";
import React, { ReactNode } from "react";

export const MenuSectionBox = styled(Box)(
  ({
    theme: {
      spacing,
      palette: { grey },
    },
  }) => ({
    padding: spacing(1, 0),
    borderBottom: `thin solid ${grey[300]}`,
  })
);

export const MenuSectionHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  pb: 2,
});

interface MenuSectionProps {
  title: string;
  expanded: boolean;
  onClick: () => void;
  children: ReactNode;
}

export const MenuSection = ({ title, expanded, onClick, children }: MenuSectionProps) => {
  const icon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  return (
    <MenuSectionBox>
      <MenuSectionHeader onClick={onClick}>
        <Typography>{title}</Typography>
        {icon}
      </MenuSectionHeader>
      {expanded && children}
    </MenuSectionBox>
  );
};

import { Macros, getCalories } from "@notemeal/shared/utils/macro-protocol";
import { getMacroProtocolMacros } from "../MacroProtocol/utils";
import { EditMacroMealTemplatesMutationVariables, FullMealPlanFragment } from "../types";

const MACRO_SNACK_FACTOR = 1;
export const MACRO_MEAL_FACTOR = 2;

export const createDistributeMacrosMutationVars = (mealPlan: FullMealPlanFragment): EditMacroMealTemplatesMutationVariables => {
  const { snackMacros, mealMacros, leftoverMacros } = getDefaultMacros({
    mealPlan,
  });
  const meals = mealPlan.mealTemplates.map(mt => mt.meal);
  const snackCount = meals.filter(m => m.type === "snack").length;
  const mealCount = meals.length - snackCount;

  const stopIndices = {
    cho: getLeftOverStopIndices(leftoverMacros.cho, snackCount, mealCount, MACRO_SNACK_FACTOR),
    pro: getLeftOverStopIndices(leftoverMacros.pro, snackCount, mealCount, MACRO_SNACK_FACTOR),
    fat: getLeftOverStopIndices(leftoverMacros.fat, snackCount, mealCount, MACRO_SNACK_FACTOR),
  };

  let currentSnackCount = 0;
  let currentMealCount = 0;

  const mealTemplateInputs = mealPlan.mealTemplates.map(({ id, meal }) => {
    let cho = meal.type === "snack" ? snackMacros.cho : mealMacros.cho;
    let pro = meal.type === "snack" ? snackMacros.pro : mealMacros.pro;
    let fat = meal.type === "snack" ? snackMacros.fat : mealMacros.fat;

    if (meal.type === "snack") {
      if (currentSnackCount < stopIndices.cho.stopExtraSnack) cho += MACRO_SNACK_FACTOR;
      if (currentSnackCount < stopIndices.pro.stopExtraSnack) pro += MACRO_SNACK_FACTOR;
      if (currentSnackCount < stopIndices.fat.stopExtraSnack) fat += MACRO_SNACK_FACTOR;
      currentSnackCount += 1;
    } else {
      if (currentMealCount < stopIndices.cho.stopTwoExtraMeal) cho += MACRO_SNACK_FACTOR;
      if (currentMealCount < stopIndices.cho.stopOneExtraMeal) cho += MACRO_SNACK_FACTOR;
      if (currentMealCount < stopIndices.pro.stopTwoExtraMeal) pro += MACRO_SNACK_FACTOR;
      if (currentMealCount < stopIndices.pro.stopOneExtraMeal) pro += MACRO_SNACK_FACTOR;
      if (currentMealCount < stopIndices.fat.stopTwoExtraMeal) fat += MACRO_SNACK_FACTOR;
      if (currentMealCount < stopIndices.fat.stopOneExtraMeal) fat += MACRO_SNACK_FACTOR;
      currentMealCount += 1;
    }
    return { mealTemplateId: id, cho, pro, fat };
  });
  return { input: mealTemplateInputs };
};

export interface StopIndices {
  stopOneExtraMeal: number;
  stopExtraSnack: number;
  stopTwoExtraMeal: number;
}

// This "top-off macros" solution is related to SNACK_FACTOR / MEAL_FACTOR above
// If changing those, would need more general solution here
// Notably, the values can be scaled up and down such that 2 * SNACK_FACTOR = MEAL_FACTOR
export const getLeftOverStopIndices = (leftOverValue: number, snackCount: number, mealCount: number, snackFactor: number): StopIndices => {
  const stopOneExtraMeal = leftOverValue > mealCount * snackFactor ? mealCount : leftOverValue / snackFactor;
  const leftOverValueAfterOneMeal = leftOverValue - stopOneExtraMeal * snackFactor;
  const stopExtraSnack = leftOverValueAfterOneMeal > snackCount * snackFactor ? snackCount : leftOverValueAfterOneMeal / snackFactor;
  const leftOverValueAfterSnack = leftOverValueAfterOneMeal - stopExtraSnack * snackFactor;
  const stopTwoExtraMeal = leftOverValueAfterSnack > mealCount * snackFactor ? mealCount : leftOverValueAfterSnack / snackFactor;

  return {
    stopOneExtraMeal,
    stopExtraSnack,
    stopTwoExtraMeal,
  };
};

interface getDefaultMacrosProps {
  mealPlan: FullMealPlanFragment;
  SNACK_RATIO?: number;
  MEAL_RATIO?: number;
}

interface DefaultMacrosResult {
  snackMacros: Macros;
  mealMacros: Macros;
  leftoverMacros: Macros;
}

// RATIOS need to be whole numbers or macro suggestions are not whole numbers
const getDefaultMacros = ({ mealPlan }: getDefaultMacrosProps): DefaultMacrosResult => {
  const meals = mealPlan.mealTemplates.map(mt => mt.meal);
  const snackCount = meals.filter(m => m.type === "snack").length;
  const mealCount = meals.length - snackCount;
  const basisTotal = mealCount * MACRO_MEAL_FACTOR + snackCount * MACRO_SNACK_FACTOR;
  // const {cho, pro, fat} = mealPlan.macroProtocol.dailyTotals;
  const { cho, pro, fat } = getMacroProtocolMacros(mealPlan.macroProtocol, mealPlan.athlete.birthDate);
  const basisMacros = {
    cho: Math.floor(cho / basisTotal),
    pro: Math.floor(pro / basisTotal),
    fat: Math.floor(fat / basisTotal),
  };
  const snackMacros = {
    cho: basisMacros.cho * MACRO_SNACK_FACTOR,
    pro: basisMacros.pro * MACRO_SNACK_FACTOR,
    fat: basisMacros.fat * MACRO_SNACK_FACTOR,
  };
  const mealMacros = {
    cho: basisMacros.cho * MACRO_MEAL_FACTOR,
    pro: basisMacros.pro * MACRO_MEAL_FACTOR,
    fat: basisMacros.fat * MACRO_MEAL_FACTOR,
  };
  const leftoverMacros = {
    cho: cho % (basisTotal * basisMacros.cho),
    pro: pro % (basisTotal * basisMacros.pro),
    fat: fat % (basisTotal * basisMacros.fat),
  };
  return {
    snackMacros: {
      ...snackMacros,
      kcal: getCalories(snackMacros.cho, snackMacros.pro, snackMacros.fat),
    },
    mealMacros: {
      ...mealMacros,
      kcal: getCalories(mealMacros.cho, mealMacros.pro, mealMacros.fat),
    },
    leftoverMacros: {
      ...leftoverMacros,
      kcal: getCalories(leftoverMacros.cho, leftoverMacros.pro, leftoverMacros.fat),
    },
  };
};

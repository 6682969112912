import { addMinutes, areIntervalsOverlapping } from "date-fns";
import { AdjacentMeals, MealEventCandidate, MealModification, StaticEvent } from "./types";
import { isValidTimeSlot } from "./isValidTimeSlot";
import { MINIMUM_MEAL_DURATION_IN_MIN, MINIMUM_MEAL_DURATION_IN_MIN_FALLBACK, getIntervalForMaxMealTimeOffset } from "./utils";

// offsets in minutes from 0 to -2hrs/+2hrs, meals can't move more than 2 hours from original time!
const TIME_OFFSET_OPTIONS = [0, -15, 15, -30, 30, -45, 45, -60, 60, -75, 75, -90, 90, -120, 120];

/**
 * Find a valid time offset (in minutes) for a given meal event.
 * If one doesn't exist then use the original time slot.
 */
export const getTimeOffsetForMeal = (
  mealEvent: MealEventCandidate,
  staticEvents: StaticEvent[],
  sortedMealEvents: MealEventCandidate[],
  mods: MealModification[]
): number => {
  const adjacentMeals = getAdjacentMealsInRange(mealEvent, sortedMealEvents, mods);

  // get only the static events that overlap with the time range in which the meal can be moved.
  // we only want examine the static events that could affect where the meal can move.
  const staticEventsInRange = getStaticEventsInRangeOfFutureMealSlot(
    staticEvents,
    mealEvent.start,
    mealEvent.durationInMinutes,
    adjacentMeals
  );

  // try to get meal offset with ideal meal duration
  const timeOffset = tryToGetTimeOffsetForMeal(mealEvent, staticEventsInRange, adjacentMeals, MINIMUM_MEAL_DURATION_IN_MIN);

  if (timeOffset !== -1) {
    return timeOffset;
  }

  // if we didn't find an offset yet, try with fallback meal duration
  const fallbackTimeOffset = tryToGetTimeOffsetForMeal(
    mealEvent,
    staticEventsInRange,
    adjacentMeals,
    MINIMUM_MEAL_DURATION_IN_MIN_FALLBACK
  );
  return fallbackTimeOffset === -1 ? 0 : fallbackTimeOffset;
};

/**
 * Get the previous meal and next meal for a given meal, if any exist and are in range of the max timeoffset for meals.
 */
const getAdjacentMealsInRange = (
  mealEvent: MealEventCandidate,
  sortedMealEvents: MealEventCandidate[],
  mods: MealModification[]
): AdjacentMeals => {
  const mealIdx = sortedMealEvents.indexOf(mealEvent);

  // if it exists, use the previous meal should as a boundary and adjust its time with any mod
  const prevMeal = mealIdx > 0 ? sortedMealEvents[mealIdx - 1] : null;
  const modifiedPrev = prevMeal
    ? { ...prevMeal, start: addMinutes(prevMeal.start, mods.find(mod => mod.mealEvent.id === prevMeal.id)?.timeOffset ?? 0) }
    : null;

  // if it exists, the next meal is a boundary
  const nextMeal: MealEventCandidate | null = mealIdx < sortedMealEvents.length - 1 ? sortedMealEvents[mealIdx + 1] : null;

  return {
    isPreviousMealModified: prevMeal !== null && mods.find(mod => mod.mealEvent.id === prevMeal.id) !== undefined,
    isNextMealModified: nextMeal !== null && mods.find(mod => mod.mealEvent.id === nextMeal.id) !== undefined,
    prevMeal:
      modifiedPrev && isEventInRangeOfMeal(modifiedPrev.start, modifiedPrev.durationInMinutes, mealEvent.start, mealEvent.durationInMinutes)
        ? modifiedPrev
        : null,
    nextMeal:
      nextMeal && isEventInRangeOfMeal(nextMeal.start, nextMeal.durationInMinutes, mealEvent.start, mealEvent.durationInMinutes)
        ? nextMeal
        : null,
  };
};

/**
 * Returns whether an event is within the maximum time offset of a meal event.
 */
const isEventInRangeOfMeal = (eventStart: Date, eventDuration: number, mealEventStart: Date, mealDuration: number): boolean => {
  const maxOffsetInterval = getIntervalForMaxMealTimeOffset(mealEventStart);
  const window = {
    start: maxOffsetInterval.start,
    end: addMinutes(maxOffsetInterval.end, mealDuration), // add meal duration to account for meal length
  };
  try {
    return areIntervalsOverlapping({ start: eventStart, end: addMinutes(eventStart, eventDuration) }, window);
  } catch {
    throw new Error(
      `Error in areIntervalsOverlapping": start: ${eventStart}, end:${addMinutes(eventStart, eventDuration)}, window:${JSON.stringify(
        window
      )}`
    );
  }
};

/**
 * Returns the events that overlap with or are within the boundaries of the future meal slot,
 * which is either between the previous meal and the next meal or within the range of the maximum time offset.
 */
const getStaticEventsInRangeOfFutureMealSlot = (
  staticEvents: StaticEvent[],
  mealEventStart: Date,
  mealDuration: number,
  adjacentMeals: AdjacentMeals
): StaticEvent[] => {
  const { prevMeal, nextMeal } = adjacentMeals;
  const maxOffsetInterval = getIntervalForMaxMealTimeOffset(mealEventStart);

  const windowForMealMovement = {
    // a meal can't move before a previous meal or past the maximum time offset from its original time
    start: prevMeal ? addMinutes(prevMeal.start, prevMeal.durationInMinutes) : maxOffsetInterval.start,
    // a meal can't move after the next meal or past the maximum time offset from its original time
    end: nextMeal ? nextMeal.start : addMinutes(maxOffsetInterval.end, mealDuration), // add meal duration to account for meal length
  };

  return staticEvents.filter(e =>
    areIntervalsOverlapping({ start: e.start, end: addMinutes(e.start, e.durationInMinutes) }, windowForMealMovement)
  );
};

/**
 * Returns timeoffset to use for a meal event or -1 if no valid offset exists.
 */
const tryToGetTimeOffsetForMeal = (
  mealEvent: MealEventCandidate,
  staticEventsInBounds: StaticEvent[],
  adjacentMeals: AdjacentMeals,
  minMealDurationInMinutes: number
): number => {
  let offsetIdx = 0;
  // Walk through time offset options until a valid offset is found
  // or run out of options.
  while (
    offsetIdx < TIME_OFFSET_OPTIONS.length &&
    !isValidTimeSlot(mealEvent, adjacentMeals, staticEventsInBounds, TIME_OFFSET_OPTIONS[offsetIdx], minMealDurationInMinutes)
  ) {
    offsetIdx++;
  }

  return offsetIdx === TIME_OFFSET_OPTIONS.length ? -1 : TIME_OFFSET_OPTIONS[offsetIdx];
};

import { addHours, addMinutes, subHours } from "date-fns";
import { parseTime, canSaveDate, serializeTime, jsDateToDateAndTimeInTz } from "@notemeal/shared/ui/utils/dateTimes";
import newId from "@notemeal/shared/ui/utils/newId";

import { MealType, ActivityType, TimelineMealFragment, FoodLogActivityTimelineFragment } from "../../../types";

export const FoodLogMealDnDType = "FoodLogMeal";

export type TimelineMealsById = Record<string, TimelineMealForState | undefined>;

export type TimelineMealForState = {
  id: string;
  name: string;
  date: string;
  startTime: string;
  endTime: string;
  type: MealType;
};

export interface FoodLogMergeFormState {
  meals: FoodLogMergeFormMealState[];
  activities: FoodLogMergeFormActivityState[];
}

export interface FoodLogMergeFormMealState {
  id: string;
  name: string;
  startTime: string;
  startValue: Date | null;
  endTime: string;
  endValue: Date | null;
  type: MealType;
  timelineMealIds: string[];
}

export const convertTimelineMeal = ({
  id,
  start,
  durationInMinutes,
  name,
  type,
  timezone,
  servingAmounts,
}: TimelineMealFragment): TimelineMealForState | null => {
  if (servingAmounts.length === 0) {
    return null;
  }
  const { time: startTime, date } = jsDateToDateAndTimeInTz(new Date(start), timezone);
  const { time: endTime } = jsDateToDateAndTimeInTz(addMinutes(new Date(start), durationInMinutes), timezone);
  return {
    id,
    date,
    name,
    type,
    startTime,
    endTime,
  };
};

export interface FoodLogMergeFormActivityState {
  id: string;
  name: string;
  startTime: string;
  startValue: Date | null;
  endTime: string;
  endValue: Date | null;
  type: ActivityType;
}

export const getFoodLogMergeFormState = (
  timelineMeals: readonly TimelineMealForState[],
  foodLogActivities: readonly FoodLogActivityTimelineFragment[]
): FoodLogMergeFormState => {
  const timelineMealNameMap = new Map<string, TimelineMealForState[]>();
  const foodLogActivityNameMap = new Map<string, FoodLogActivityTimelineFragment[]>();

  timelineMeals.forEach(tlm => {
    const matchingFoodLogMeals = timelineMealNameMap.get(tlm.name);
    if (matchingFoodLogMeals) {
      // TODO: Check if minStart / maxEnd are close enough to start/end times
      timelineMealNameMap.set(tlm.name, [...matchingFoodLogMeals, tlm]);
    } else {
      timelineMealNameMap.set(tlm.name, [tlm]);
    }
  });

  foodLogActivities.forEach(fla => {
    const matchingFoodLogActivities = foodLogActivityNameMap.get(fla.name);
    if (matchingFoodLogActivities) {
      // TODO: Check if minStart / maxEnd are close enough to start/end times
      foodLogActivityNameMap.set(fla.name, [...matchingFoodLogActivities, fla]);
    } else {
      foodLogActivityNameMap.set(fla.name, [fla]);
    }
  });

  return {
    meals: partitionEvents(Array.from(timelineMealNameMap.values())).map(timelineMeals => {
      const [{ startTime, endTime, name, type }] = timelineMeals;
      return {
        id: newId(),
        name,
        type,
        startTime,
        endTime,
        timelineMealIds: timelineMeals.map(tlm => tlm.id),
        startValue: parseTime(startTime),
        endValue: parseTime(endTime),
      };
    }),
    activities: partitionEvents(Array.from(foodLogActivityNameMap.values())).map(foodLogActivities => {
      const [{ startTime, endTime, name, activityType }] = foodLogActivities;
      return {
        id: newId(),
        name,
        type: activityType,
        startTime,
        endTime,
        startValue: parseTime(startTime),
        endValue: parseTime(endTime),
      };
    }),
  };
};

interface ParitionableEvent {
  name: string;
  startTime: string;
  endTime: string;
}

interface ParitionedEvents<T extends ParitionableEvent> {
  events: T[];
  minStart: string;
  maxEnd: string;
}

const EXEMPT_PARTITION_EVENT_NAMES = ["Breakfast", "Lunch", "Dinner"];

const partitionEvents = <T extends ParitionableEvent>(groupedEvents: T[][]): T[][] => {
  return groupedEvents.flatMap(events => {
    const [{ name }] = events;
    if (EXEMPT_PARTITION_EVENT_NAMES.includes(name)) {
      return [events];
    }

    const partitionedEvents = events.reduce((partitionedEvents: ParitionedEvents<T>[], event: T) => {
      const newEventPartition: ParitionedEvents<T> = {
        events: [event],
        minStart: event.startTime,
        maxEnd: event.endTime,
      };
      const intersectingPartitions = partitionedEvents.filter(p => eventInPartition(event, p));
      if (!intersectingPartitions.length) {
        return [...partitionedEvents, newEventPartition];
      } else {
        const newCombinedPartition = intersectingPartitions.reduce((newPartition: ParitionedEvents<T>, nextPartition) => {
          return {
            events: [...newPartition.events, ...nextPartition.events],
            minStart: newPartition.minStart > nextPartition.minStart ? nextPartition.minStart : newPartition.minStart,
            maxEnd: newPartition.maxEnd < nextPartition.maxEnd ? nextPartition.maxEnd : newPartition.maxEnd,
          };
        }, newEventPartition);
        return [...partitionedEvents.filter(p => !intersectingPartitions.includes(p)), newCombinedPartition];
      }
    }, []);
    return partitionedEvents.map(({ events }) => events);
  });
};

const eventInPartition = <T extends ParitionableEvent>({ startTime, endTime }: T, { minStart, maxEnd }: ParitionedEvents<T>): boolean => {
  const effectiveStart = serializeTime(subHours(parseTime(minStart), 1));
  const effectiveEnd = serializeTime(addHours(parseTime(maxEnd), 1));
  return (startTime >= effectiveStart && startTime <= effectiveEnd) || (endTime >= effectiveStart && endTime <= effectiveEnd);
};

export const getFoodLogMergeFormSaveTooltips = (state: FoodLogMergeFormState): string[] => {
  let tooltips = [];
  const { meals, activities } = state;
  const hasIncompleteMeals = !meals.every(m => m.name && m.startTime && canSaveDate(m.startValue) && m.endTime && canSaveDate(m.endValue));
  if (hasIncompleteMeals) {
    tooltips.push("Some meals are incomplete");
  }

  const hasIncompleteActivities = !activities.every(
    e => e.name && e.startTime && canSaveDate(e.startValue) && e.endTime && canSaveDate(e.endValue)
  );
  if (hasIncompleteActivities) {
    tooltips.push("Some activities are incomplete");
  }
  return tooltips;
};

import { Box, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { Dispatch } from "react";
import { AthleteForCustomTagFragment } from "../../../../../types";
import AthleteSelector from "../../../Selectors/AthleteSelector";
import { SelectAthleteAction } from "../../../reducers/advancedSelectionReducers";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      color: theme.palette.grey[700],
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    athleteSelect: {
      background: "#FFFFFF",
      padding: 0,
    },
    text: {
      whiteSpace: "nowrap",
      marginRight: theme.spacing(2),
    },
  })
);

interface SelectAthletesProps {
  includeAthletes: readonly AthleteForCustomTagFragment[];
  excludeAthletes: readonly AthleteForCustomTagFragment[];
  dispatch: Dispatch<SelectAthleteAction>;
}

export const SelectAthletes = ({ includeAthletes, excludeAthletes, dispatch }: SelectAthletesProps) => {
  const {
    palette: { greyscale },
  } = useTheme();
  const classes = useStyles();

  const renderAthleteSelect = (athletes: readonly AthleteForCustomTagFragment[], type: "include" | "exclude") => {
    const athleteIdsToFilter = type === "include" ? excludeAthletes.map(({ id }) => id) : includeAthletes.map(({ id }) => id);
    const filterAthletes = (athletes: AthleteForCustomTagFragment[]) => {
      return athletes.filter(athlete => !athleteIdsToFilter.includes(athlete.id));
    };
    return (
      <Box
        sx={{
          p: 2,
          background: greyscale[200],
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
        }}
        key={type}
      >
        <Typography className={classes.text}>{`Also ${type} the following athletes:`}</Typography>
        <AthleteSelector
          sx={{ mt: 0, width: "100%" }}
          selectedAthletes={athletes}
          onChange={payload => dispatch({ type: type === "include" ? "UPDATE_INCLUDE_ATHLETES" : "UPDATE_EXCLUDE_ATHLETES", payload })}
          filterAthletes={filterAthletes}
        />
      </Box>
    );
  };

  return (
    <>
      <Typography variant="h3" className={classes.sectionTitle}>
        Individual Athletes
      </Typography>
      <Box sx={{ p: 2, border: `1px solid ${greyscale[400]}`, borderRadius: "5px" }}>
        {renderAthleteSelect(includeAthletes, "include")}
        {renderAthleteSelect(excludeAthletes, "exclude")}
      </Box>
    </>
  );
};

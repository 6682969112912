import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { parseDateTime, serializeDateTime } from "@notemeal/shared/ui/utils/dateTimes";
import { addDays } from "date-fns";
import React from "react";
import { Route, Routes, useSearchParams } from "react-router-dom-v5-compat";
import { getLink } from "../../../NavUtils";
import { RoleRoute } from "../../ProtectedRoutes";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";
import { kitchenLink, menuScheduleLink } from "../Kitchen/KitchenPaths";
import { ViewAttendancePage } from "./ViewAttendancePage";

export const getViewAttendancePath = () => `/org/view-attendance`;
export const getViewAttendanceLink = () => getLink("View Attendance Report", getViewAttendancePath());

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "",
  current,
  breadCrumbs: [kitchenLink, menuScheduleLink],
  tabs: [],
  enableAthleteSearch: false,
});

export const ViewAttendanceRouter = () => {
  const [urlParams] = useSearchParams();
  const { formatDateWithLocale } = useDateFormatting();

  const startDateIsoString = urlParams.get("startDate");
  const endDateIsoString = urlParams.get("endDate");

  const pageHeader = formatDateWithLocale(new Date(startDateIsoString!)) + " - " + formatDateWithLocale(new Date(endDateIsoString!));

  //TODO: navigate back to kitchen on invalid dates - very minor edge case
  const start = serializeDateTime(parseDateTime(startDateIsoString!));
  // include last selected day
  const end = serializeDateTime(addDays(parseDateTime(endDateIsoString!), 1));

  const current = { ...getViewAttendanceLink(), pageHeader };
  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  return (
    <Routes>
      <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route element={<RoleRoute roles={["dietitian", "chef", "foodmanager", "nonRdAdmin"]} />}>
          <Route index element={<ViewAttendancePage start={start} end={end} />} />
        </Route>
      </Route>
    </Routes>
  );
};

import { Autocomplete, AutocompleteRenderInputParams, TextField, TextFieldProps } from "@mui/material";
import React from "react";

type HasId = {
  id: string | number;
};

interface AutocompleteForMultiselectProps<T extends HasId> {
  onChange: (options: T[]) => void;
  options: T[] | readonly T[];
  value: T[] | readonly T[];
  isOptionSelected: (option: T) => boolean;
  getOptionLabel: (option: T) => string;
  inputProps?: TextFieldProps;
  groupBy?: (option: T) => string;
  filterOptions?: (options: T[]) => T[];
  disableCloseOnSelect?: boolean;
  noOptionsText?: string;
}

const AutocompleteForMultiselect = <T extends HasId>({
  onChange,
  options,
  value,
  isOptionSelected,
  getOptionLabel,
  inputProps,
  groupBy,
  filterOptions,
  disableCloseOnSelect,
  noOptionsText,
}: AutocompleteForMultiselectProps<T>) => {
  const handleChange = (_: any, value: T[] | null) => {
    if (Array.isArray(value)) {
      onChange(value);
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams) => <TextField {...inputProps} {...params} />;

  return (
    <Autocomplete
      multiple
      value={value.concat()}
      options={options.filter(o => !isOptionSelected(o))}
      isOptionEqualToValue={(o, _) => isOptionSelected(o)}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      renderInput={renderInput}
      groupBy={groupBy}
      filterOptions={filterOptions}
      disableCloseOnSelect={disableCloseOnSelect}
      noOptionsText={noOptionsText}
    />
  );
};

export default AutocompleteForMultiselect;

import { Reference } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, Switch, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import DisplaySettingsTable from "../../../../components/DisplaySettings/Table";
import {
  ExchangeMealPlanDisplaySettingsFragment,
  MacroMealPlanDisplaySettingsFragment,
  NoTargetsDisplaySettingsFragment,
  useEditAthleteDisplaySettingsMutation,
} from "../../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 0,
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    flexSpaceAround: {
      display: "flex",
      justifyContent: "space-around",
    },
    info: {
      paddingLeft: theme.spacing(2),
    },
    spacer: { height: theme.spacing(2.5) },
  })
);

interface AthleteSummaryDisplaySettingsEditModalProps {
  athleteId: string;
  open: boolean;
  onClose: () => void;
  defaultMacroDisplaySettings: MacroMealPlanDisplaySettingsFragment | null;
  defaultExchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment | null;
  defaultNoTargetsDisplaySettings: NoTargetsDisplaySettingsFragment | null;
  defaultHideAnthropometry: boolean | null;
  teamMacroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  teamExchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
  teamNoTargetsDisplaySettings: NoTargetsDisplaySettingsFragment;
  teamHideAnthropometry: boolean;
}

const AthleteSummaryDisplaySettingsEditModal = ({
  open,
  onClose,
  athleteId,
  defaultMacroDisplaySettings,
  defaultExchangeDisplaySettings,
  defaultNoTargetsDisplaySettings,
  defaultHideAnthropometry,
  teamMacroDisplaySettings,
  teamExchangeDisplaySettings,
  teamNoTargetsDisplaySettings,
  teamHideAnthropometry,
}: AthleteSummaryDisplaySettingsEditModalProps) => {
  const classes = useStyles();

  const [macroDisplaySettings, setMacroDisplaySettings] = useState(defaultMacroDisplaySettings);
  const [exchangeDisplaySettings, setExchangeDisplaySettings] = useState(defaultExchangeDisplaySettings);
  const [noTargetsDisplaySettings, setNoTargetsDisplaySettings] = useState(defaultNoTargetsDisplaySettings);
  const [hideAnthropometry, setHideAnthropometry] = useState(defaultHideAnthropometry);

  const [overrideTeamSettings, setOverrideTeamSettings] = useState(
    !!(defaultHideAnthropometry || defaultMacroDisplaySettings || defaultExchangeDisplaySettings || defaultNoTargetsDisplaySettings)
  );

  const [editAthleteDisplaySettings] = useEditAthleteDisplaySettingsMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `Athlete:${athleteId}`,
        fields: {
          mealPlans: (mealPlans: Reference[]) => {
            mealPlans.map(mp =>
              cache.modify({
                id: mp.__ref,
                fields: {
                  macroDisplaySettings: (_, { DELETE }) => DELETE,
                  exchangeDisplaySettings: (_, { DELETE }) => DELETE,
                },
              })
            );
          },
        },
      });
    },
  });

  const handleSave = async () => {
    await editAthleteDisplaySettings({
      variables: {
        input: overrideTeamSettings
          ? {
              athleteId,
              hideAnthropometry,
              noTargetsDisplaySettings: noTargetsDisplaySettings
                ? {
                    dailyProgressViewsForMacros: noTargetsDisplaySettings.dailyProgressViewsForMacros,
                    mealProgressViewsForMacros: noTargetsDisplaySettings.mealProgressViewsForMacros,
                  }
                : null,
              macroDisplaySettings: macroDisplaySettings
                ? {
                    dailyProgressViews: macroDisplaySettings.dailyProgressViews,
                    mealProgressViews: macroDisplaySettings.mealProgressViews,
                  }
                : null,
              exchangeDisplaySettings: exchangeDisplaySettings
                ? {
                    dailyProgressViewsForExchanges: exchangeDisplaySettings.dailyProgressViewsForExchanges,
                    dailyProgressViewsForMacros: exchangeDisplaySettings.dailyProgressViewsForMacros,
                    mealProgressViewsForExchanges: exchangeDisplaySettings.mealProgressViewsForExchanges,
                    mealProgressViewsForMacros: exchangeDisplaySettings.mealProgressViewsForMacros,
                  }
                : null,
            }
          : {
              athleteId,
              hideAnthropometry: null,
              noTargetsDisplaySettings: null,
              macroDisplaySettings: null,
              exchangeDisplaySettings: null,
            },
      },
    });
    onClose();
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}>
      <DialogTitle title="Edit Display Settings" onClose={onClose} />
      <DialogContent>
        <div>
          <FormControlLabel
            value={overrideTeamSettings}
            control={
              <Switch
                checked={overrideTeamSettings}
                onChange={() => {
                  setOverrideTeamSettings(!overrideTeamSettings);
                }}
              />
            }
            label={"Override Team Display Settings"}
            labelPlacement="start"
          />
          <div>
            {!overrideTeamSettings ? (
              <Typography variant="body2" className={classes.info}>
                *Showing team's display settings below
              </Typography>
            ) : (
              <div className={classes.spacer} />
            )}
          </div>
        </div>
        <div className={classes.content}>
          <DisplaySettingsTable
            macroDisplaySettings={overrideTeamSettings && macroDisplaySettings ? macroDisplaySettings : teamMacroDisplaySettings}
            exchangeDisplaySettings={
              overrideTeamSettings && exchangeDisplaySettings ? exchangeDisplaySettings : teamExchangeDisplaySettings
            }
            noTargetsDisplaySettings={
              overrideTeamSettings && noTargetsDisplaySettings ? noTargetsDisplaySettings : teamNoTargetsDisplaySettings
            }
            hideAnthropometry={overrideTeamSettings && hideAnthropometry !== null ? hideAnthropometry : teamHideAnthropometry}
            onChangeMacroDisplaySettings={setMacroDisplaySettings}
            onChangeExchangeDisplaySettings={setExchangeDisplaySettings}
            onChangeNoTargetsDisplaySettings={setNoTargetsDisplaySettings}
            onChangeHideAnthropometry={setHideAnthropometry}
            disabled={!overrideTeamSettings}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AthleteSummaryDisplaySettingsEditModal;

import CopyIcon from "@mui/icons-material/ContentCopy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, TableCell, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { formatDistanceToNowStrict } from "date-fns";
import React, { useState } from "react";
import { ConfirmationDialog } from "../../componentLibrary";
import { ORG, ROWS_PER_PAGE } from "../../pages/Auth/Org/MealPlans/MealPlanTemplatesPage";
import {
  DietitianOrgGroupMealPlanTemplateListItemFragment,
  GoalTypeFragment,
  MealPlanTemplateOwnerPreviewFragment,
  useCopyMacroMealPlanTemplateMutation,
  useDeleteOrgGroupMacroMealPlanTemplateMutation,
  useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery,
  useMoveOrgGroupMacroMealPlanTemplateToOrgMutation,
} from "../../types";
import { useOffsetPagination } from "../../utils/pagination";
import { useUser } from "../../utils/tokens";
import BaseMealPlanTemplates from "./BaseMealPlanTemplate";
import EditOrgGroupMealPlanTemplate from "./EditOrgGroupMealPlanTemplate";
import ViewOnlyMealPlanTemplate from "./ViewOnlyMealPlanTemplate";

interface OrgGroupMealPlanTemplateHeaderRowProps {
  direction: "desc" | "asc";
  toggleSortOrder: () => void;
}

interface OrgGroupMealPlanTemplateRowProps {
  mealPlanTemplate: DietitianOrgGroupMealPlanTemplateListItemFragment;
  selectEditMealPlanTemplate: () => void;
  selectViewOnlyMealPlanTemplate: () => void;
  moveMealPlanTemplate: () => void;
  removeMealPlanTemplate: () => void;
  copyMealPlanTemplate: () => void;
}

const OrgGroupMealPlanTemplateHeaderRow = ({ direction, toggleSortOrder }: OrgGroupMealPlanTemplateHeaderRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <TableSortLabel direction={direction} onClick={() => toggleSortOrder()}>
          Name
        </TableSortLabel>
      </TableCell>
      <TableCell>Description</TableCell>
      <TableCell
        sx={{
          maxWidth: 150,
          minWidth: 150,
        }}
      >
        Managed By
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 150,
          minWidth: 150,
        }}
      >
        # of Duplications
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 150,
          minWidth: 150,
        }}
      >
        Last Edited
      </TableCell>
      <TableCell
        sx={{
          width: 50,
        }}
      >
        Actions
      </TableCell>
    </TableRow>
  );
};

const OrgGroupMealPlanTemplateRow = ({
  mealPlanTemplate,
  selectEditMealPlanTemplate,
  selectViewOnlyMealPlanTemplate,
  removeMealPlanTemplate,
  copyMealPlanTemplate,
  moveMealPlanTemplate,
}: OrgGroupMealPlanTemplateRowProps) => {
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
  const user = useUser();
  const { dateFnsLocale } = useDateFormatting();
  const { id, name, description, updatedAt, owner, copiedCount } = mealPlanTemplate;
  const isOwner = user?.id === owner.id;

  return (
    <>
      <TableRow
        id={id}
        onClick={() => (isOwner ? selectEditMealPlanTemplate() : selectViewOnlyMealPlanTemplate())}
        hover
        sx={{
          cursor: "pointer",
        }}
      >
        <TableCell>{name}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell
          sx={{
            maxWidth: 150,
            minWidth: 150,
          }}
        >
          {owner.firstName} {owner.lastName}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: 150,
            minWidth: 150,
          }}
        >
          {copiedCount}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: 150,
            minWidth: 150,
          }}
        >
          {/* TODO: Locale Cleanup? */}
          {formatDistanceToNowStrict(new Date(updatedAt), {
            addSuffix: true,
            unit: "day",
            locale: dateFnsLocale,
          })}
        </TableCell>
        <TableCell>
          {isOwner && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setMoreAnchorEl(e.currentTarget);
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          )}
          {!isOwner && (
            <Tooltip title="Duplicate template to my org">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  copyMealPlanTemplate();
                }}
                size="large"
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <Menu
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={() => setMoreAnchorEl(null)}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            copyMealPlanTemplate();
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            removeMealPlanTemplate();
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            setMoreAnchorEl(null);
            moveMealPlanTemplate();
          }}
        >
          Move to My Org
        </MenuItem>
      </Menu>
    </>
  );
};

interface OrgGroupMealPlanTemplateProps {
  selectedUsers: MealPlanTemplateOwnerPreviewFragment[];
  changeTab: (tab: string) => void;
  onCompleteAction: (action: string, name: string, resetPaging: boolean) => void;
  onSelectEditTemplateId: (selectedTemplateId: string | null) => void;
  selectedEditTemplateId: string | null;
  goalTypes: readonly GoalTypeFragment[];
  hasOrgGroupTemplates: boolean;
}

export const OrgGroupMealPlanTemplates = ({
  selectedUsers,
  changeTab,
  onCompleteAction,
  onSelectEditTemplateId,
  selectedEditTemplateId,
  goalTypes,
  hasOrgGroupTemplates,
}: OrgGroupMealPlanTemplateProps) => {
  const [sortAscending, setSortAscending] = useState(true);
  const pagination = useOffsetPagination(ROWS_PER_PAGE);
  const { limit, offset, query: debouncedSearchText, onChangePage } = pagination;

  const ownerIds = selectedUsers.length === 0 ? null : selectedUsers.map(u => u.id);
  const { data, loading } = useDietitianOrgGroupMealPlanTemplateOffsetConnectionQuery({
    variables: { pagination: { offset, limit }, searchText: debouncedSearchText, sortAscending, ownerIds },
  });

  const [selectedViewOnlyTemplateId, setSelectedViewOnlyTemplateId] = useState<string | null>(null);
  const [copyMealPlanTemplate] = useCopyMacroMealPlanTemplateMutation({
    onCompleted: ({ copyMacroMealPlanTemplate: { macroMealPlanTemplate } }) => {
      onCompleteAction("copied", "", true);
      onChangePage(0);
      changeTab(ORG);
      onSelectEditTemplateId(macroMealPlanTemplate.id);
    },
  });

  const rows = data?.orgGroupMealPlanTemplateOffsetConnection.edges || [];
  const getSortOrder = () => (sortAscending ? "asc" : "desc");
  const toggleSortOrder = () => setSortAscending(!sortAscending);

  const [removeMealPlanTemplate] = useDeleteOrgGroupMacroMealPlanTemplateMutation({
    onCompleted: () => {
      onCompleteAction("removed", "", true);
      onChangePage(0);
    },
  });
  const [confirmDelete, setConfirmDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const onConfirmDelete = (macroMealPlanTemplateId: string) => {
    removeMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId } } });
    setConfirmDelete(null);
  };

  const [moveMealPlanTemplate] = useMoveOrgGroupMacroMealPlanTemplateToOrgMutation({
    onCompleted: () => {
      onCompleteAction("moved", "", true);
      onChangePage(0);
    },
  });
  const [toMoveMealPlanTemplate, setToMoveMealPlanTemplate] = useState<DietitianOrgGroupMealPlanTemplateListItemFragment | null>(null);
  const onConfirmMove = (macroMealPlanTemplateId: string) => {
    moveMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId } } });
    setToMoveMealPlanTemplate(null);
  };

  return (
    <>
      <BaseMealPlanTemplates
        pagination={pagination}
        renderHeaderRow={() => <OrgGroupMealPlanTemplateHeaderRow direction={getSortOrder()} toggleSortOrder={toggleSortOrder} />}
        mealPlanTemplateRows={rows.concat()}
        loading={loading}
        total={data?.orgGroupMealPlanTemplateOffsetConnection.pageInfo.total || 0}
        renderMealPlanTemplateRow={mealPlanTemplate => (
          <OrgGroupMealPlanTemplateRow
            key={mealPlanTemplate.id}
            mealPlanTemplate={mealPlanTemplate}
            selectEditMealPlanTemplate={() => onSelectEditTemplateId(mealPlanTemplate.id)}
            selectViewOnlyMealPlanTemplate={() => setSelectedViewOnlyTemplateId(mealPlanTemplate.id)}
            removeMealPlanTemplate={() => setConfirmDelete(mealPlanTemplate)}
            copyMealPlanTemplate={() => copyMealPlanTemplate({ variables: { input: { macroMealPlanTemplateId: mealPlanTemplate.id } } })}
            moveMealPlanTemplate={() => setToMoveMealPlanTemplate(mealPlanTemplate)}
          />
        )}
      />
      {selectedEditTemplateId && (
        <EditOrgGroupMealPlanTemplate
          open={selectedEditTemplateId !== null}
          onEditSuccess={({ name }) => onCompleteAction("edited", name, false)}
          onDiscardEditSuccess={() => onCompleteAction("discarded edits", "", false)}
          onPublishSuccess={({ name }) => onCompleteAction("published", name, true)}
          onClose={() => onSelectEditTemplateId(null)}
          goalTypes={goalTypes}
          id={selectedEditTemplateId}
        />
      )}
      {selectedViewOnlyTemplateId && (
        <ViewOnlyMealPlanTemplate
          open={selectedViewOnlyTemplateId !== null}
          onClose={() => setSelectedViewOnlyTemplateId(null)}
          goalTypes={goalTypes}
          id={selectedViewOnlyTemplateId}
        />
      )}
      {confirmDelete && (
        <ConfirmationDialog
          open={!!confirmDelete}
          title="Delete Meal Plan Template?"
          message={`This action cannot be undone. Are you sure you want to delete the meal plan template “${
            confirmDelete ? confirmDelete.name : ""
          }”?`}
          onCancel={() => setConfirmDelete(null)}
          onConfirm={() => onConfirmDelete(confirmDelete.id)}
          variant="containedDestructive"
        />
      )}
      {toMoveMealPlanTemplate && (
        <ConfirmationDialog
          open={!!toMoveMealPlanTemplate}
          title="Move Template to My Org"
          message="Are you sure you want to move this template back to your org? Doing so will remove it from Org Group Templates and dietitians will no longer be able to view or duplicate it to their orgs."
          onCancel={() => setToMoveMealPlanTemplate(null)}
          onConfirm={() => onConfirmMove(toMoveMealPlanTemplate.id)}
          confirmLabel="Yes"
        />
      )}
    </>
  );
};

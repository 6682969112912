import { Box, Button, Dialog, DialogActions } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { MenuItemFormFragment } from "../../../types";
import MenuItemReadonlyDialogContent from "../../MenuItem/Form/ReadonlyForm";
import MenuItemOrderDetails from "../../MenuItem/OrderDetails/Details";
import DiscardChangesDialog from "../../universal/DiscardChangesDialog";
import MoreOptionsButton from "../MoreOptionsButton";
import { BaseMenuItemAppearanceCardProps } from "../utils";

interface MenuItemAppearanceEditDialogReusableProps extends BaseMenuItemAppearanceCardProps {
  open: boolean;
  menuItem: MenuItemFormFragment;
  onClose: () => void;
}

const MenuItemAppearanceEditDialogReusable = ({
  open,
  onClose,
  onEdit,
  onConvert,
  onEditAppearance,
  onCreate,
  menuItemAppearance,
  menuItem,
}: MenuItemAppearanceEditDialogReusableProps) => {
  const [maxAmount, setMaxAmount] = useState(menuItemAppearance.maxAmount);
  const [availableForOrder, setAvailableForOrder] = useState(menuItemAppearance.availableForOrder);
  const [allowSpecialRequests, setAllowSpecialRequests] = useState(menuItemAppearance.allowSpecialRequests);
  const [edited, setEdited] = useState(false);
  const [discardChangesOpen, setDiscardChangesOpen] = useState(false);

  const handleClose = () => {
    if (edited) {
      setDiscardChangesOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth>
      <DialogTitle title="Reusable Menu Item" onClose={handleClose} />
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", pr: 2 }}>
        <MoreOptionsButton
          IconButtonProps={{
            sx: { ml: 2 },
          }}
          onEdit={() => onEdit(menuItem, maxAmount, availableForOrder, allowSpecialRequests, menuItem)}
          onConvert={isOneOff => onConvert(menuItem, maxAmount, availableForOrder, allowSpecialRequests, isOneOff, menuItem)}
          onDuplicate={isOneOff => {
            onClose();
            onCreate(menuItem, maxAmount, availableForOrder, allowSpecialRequests, isOneOff);
          }}
        />
      </Box>

      <MenuItemReadonlyDialogContent
        menuItem={menuItem}
        orderDetails={
          <Box sx={{ display: "flex", alignItems: "center", flexShrink: 0, gap: 2 }}>
            <MenuItemOrderDetails
              variant="MenuItemAppearance"
              onChangeMaxAmount={_maxAmount => {
                setMaxAmount(_maxAmount);
                setEdited(true);
              }}
              onChangeAvailableForOrder={_availableForOrder => {
                setAvailableForOrder(_availableForOrder);
                setEdited(true);
              }}
              onChangeAllowSpecialRequests={_allowSpecialRequests => {
                setAllowSpecialRequests(_allowSpecialRequests);
                setEdited(true);
              }}
              availableForOrder={availableForOrder}
              allowSpecialRequests={allowSpecialRequests}
              maxAmount={maxAmount}
            />
          </Box>
        }
      />
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button
          onClick={() => {
            onEditAppearance(maxAmount, availableForOrder, allowSpecialRequests);
            onClose();
          }}
        >
          Done
        </Button>
      </DialogActions>
      {discardChangesOpen && (
        <DiscardChangesDialog
          open={discardChangesOpen}
          onClose={() => setDiscardChangesOpen(false)}
          onDiscard={onClose} />
      )}
    </Dialog>
  );
};

export default MenuItemAppearanceEditDialogReusable;

import React, { createContext, useContext, useMemo } from "react";
import { FoodFragment, FullFoodGroupWithFoodsFragment } from "../types";
import { PreferenceFoodFunction, PreferenceFoodInfo, combineFoodsAndFoodGroupsIntoIndex } from "./utils";

interface AthleteFoodPreferenceContextProps {
  children: React.ReactNode;
  likedFoods: readonly FoodFragment[];
  dislikedFoods: readonly FoodFragment[];
  likedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
  dislikedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
}

export interface AthleteFoodPreferenceContextType {
  isLikedFood: PreferenceFoodFunction;
  isDislikedFood: PreferenceFoodFunction;
  likedFoods: readonly FoodFragment[];
  dislikedFoods: readonly FoodFragment[];
  likedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
  dislikedFoodGroups: readonly FullFoodGroupWithFoodsFragment[];
}

const AthleteFoodPreferenceContext = createContext<AthleteFoodPreferenceContextType>({
  isLikedFood: () => ({
    value: false,
    reason: [],
  }),
  isDislikedFood: () => ({
    value: false,
    reason: [],
  }),
  likedFoods: [],
  dislikedFoods: [],
  likedFoodGroups: [],
  dislikedFoodGroups: [],
});

export const AthleteFoodPreferenceContextProvider = ({
  children,
  likedFoods,
  dislikedFoods,
  likedFoodGroups,
  dislikedFoodGroups,
}: AthleteFoodPreferenceContextProps) => {
  const value = useMemo(() => {
    const likedFoodsIndex = combineFoodsAndFoodGroupsIntoIndex(likedFoods, likedFoodGroups);

    const dislikedFoodsIndex = combineFoodsAndFoodGroupsIntoIndex(dislikedFoods, dislikedFoodGroups);

    return {
      isLikedFood: (foodId: string): PreferenceFoodInfo => {
        const likedFoodsForFood = [...(likedFoodsIndex[foodId] || [])];
        return {
          value: likedFoodsForFood.length > 0,
          reason: likedFoodsForFood.sort(),
        };
      },
      isDislikedFood: (foodId: string): PreferenceFoodInfo => {
        const dislikedFoodsForFood = [...(dislikedFoodsIndex[foodId] || [])];
        return {
          value: dislikedFoodsForFood.length > 0,
          reason: dislikedFoodsForFood.sort(),
        };
      },
      likedFoods,
      dislikedFoods,
      likedFoodGroups,
      dislikedFoodGroups,
    };
  }, [
    likedFoods
      .map(f => f.id)
      .sort()
      .join(","),
    dislikedFoods
      .map(f => f.id)
      .sort()
      .join(","),
    likedFoodGroups
      .map(fg => fg.id)
      .sort()
      .join(","),
    dislikedFoodGroups
      .map(fg => fg.id)
      .sort()
      .join(","),
    likedFoodGroups
      .flatMap(fg => fg.foods.map(f => f.id))
      .sort()
      .join(","),
    dislikedFoodGroups
      .flatMap(fg => fg.foods.map(f => f.id))
      .sort()
      .join(","),
  ]);

  return <AthleteFoodPreferenceContext.Provider value={value}>{children}</AthleteFoodPreferenceContext.Provider>;
};

export const useAthleteFoodPreferenceContext = () => {
  return useContext(AthleteFoodPreferenceContext);
};

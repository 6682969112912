import React from "react";

import { Typography, styled } from "@mui/material";
import { endsOnDifferentDay, isLastOrderInvalid } from "../Dialog/utils";
import { MenuDurationSelect } from "../SelectComponents/DurationSelect";
import LastOrderDaySelect from "../SelectComponents/LastOrderDaySelect";
import StartTimeSelect from "../SelectComponents/StartTimeSelect";
import TimezoneSelect from "../SelectComponents/TimezoneSelect";
import { TimingState } from "../types";
import { TimingChangeAction } from "./timingReducer";

const InputContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  marginLeft: -spacing(2),
  marginBottom: spacing(2),
}));

interface TimingEditorProps {
  timingState: TimingState;
  dispatch: (action: TimingChangeAction) => void;
}

const TimingEditor = ({ timingState, dispatch }: TimingEditorProps) => {
  const lastOrderStatus = isLastOrderInvalid(timingState);

  return (
    <>
      <InputContainer sx={{ gap: 2 }}>
        <StartTimeSelect
          label="Start"
          value={timingState.startTime}
          onChange={startTime =>
            dispatch({
              type: "MenuChangeStartTimeAction",
              payload: {
                startTime,
              },
            })
          }
        />
        <Typography>to</Typography>
        <MenuDurationSelect
          value={timingState.durationInMinutes}
          startTime={timingState.startTime}
          onChange={durationInMinutes =>
            dispatch({
              type: "MenuChangeDurationInMinutesAction",
              payload: {
                durationInMinutes,
              },
            })
          }
        />
        <TimezoneSelect
          value={timingState.timezone}
          onChange={timezone =>
            dispatch({
              type: "MenuChangeTimezoneAction",
              payload: {
                timezone,
              },
            })
          }
        />
      </InputContainer>
      <InputContainer sx={{ gap: 2 }}>
        <LastOrderDaySelect
          sx={{ width: 170 }}
          onChange={lastOrderDaysBefore =>
            dispatch({
              type: "MenuChangeLastOrderDaysBeforeAction",
              payload: {
                lastOrderDaysBefore,
              },
            })
          }
          value={timingState.lastOrderDaysBefore}
          showNextDay={endsOnDifferentDay(timingState)}
        />
        <StartTimeSelect
          label="Time"
          value={timingState.lastOrderTime}
          onChange={lastOrderTime =>
            dispatch({
              type: "MenuChangeLastOrderTimeAction",
              payload: {
                lastOrderTime,
              },
            })
          }
          error={lastOrderStatus === "invalid"}
          warning={lastOrderStatus === "potential"}
        />
      </InputContainer>
    </>
  );
};

export default TimingEditor;

import { Paper, SxProps, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";
import { ActivityFragment } from "../types";
import { formatEventTime } from "../utils/dateTimes";

export const useActivityItemContainerStyles = makeStyles(
  ({
    palette: {
      accents: { orangeDark },
    },
  }: Theme) => ({
    activityItemContainer: {
      backgroundColor: orangeDark[100],
      padding: 10,
      margin: 10,
    },
    exported: {
      backgroundColor: orangeDark[100],
      height: `20px`,
      marginTop: `5px`,
      marginBottom: `5px`,
      padding: `2px`,
    },
    dense: {
      backgroundColor: orangeDark[100],
      padding: 0,
      margin: 3,
      textAlign: "center",
    },
  })
);

interface ActivityItemContainerProps {
  children?: React.ReactNode;
  className?: string;
  sx?: SxProps;
}

export const ActivityItemContainer = ({ children, className, sx }: ActivityItemContainerProps) => {
  const classes = useActivityItemContainerStyles();
  return (
    <Paper sx={sx} className={classnames(classes.activityItemContainer, className)}>
      {children}
    </Paper>
  );
};

interface ActivityItemProps {
  activity: ActivityFragment;
  className?: string;
}

export const ActivityItem = ({ activity, className }: ActivityItemProps) => {
  const classes = useActivityItemContainerStyles();
  return (
    <ActivityItemContainer className={classnames(classes.activityItemContainer, className)}>
      <Typography align="center" variant="body1Medium">
        {activity.name} ({formatEventTime(activity)})
      </Typography>
    </ActivityItemContainer>
  );
};

export const ExportActivityItem = ({ activity, className }: ActivityItemProps) => {
  const classes = useActivityItemContainerStyles();
  return (
    <ActivityItemContainer className={classnames(classes.exported, className)}>
      <Typography align="center" variant="body2">
        {activity.name} ({formatEventTime(activity)})
      </Typography>
    </ActivityItemContainer>
  );
};

export const DenseActivityItem = ({ activity }: ActivityItemProps) => {
  const classes = useActivityItemContainerStyles();
  return (
    <ActivityItemContainer className={classes.dense}>
      <Typography align="center" variant="subtitle1">
        {activity.name} ({formatEventTime(activity)})
      </Typography>
    </ActivityItemContainer>
  );
};

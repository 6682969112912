import { Backdrop, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
    },
  })
);

interface LoadingBackdropProps {
  open: boolean;
  onClose: () => void;
}

const LoadingBackdrop = ({ open, onClose }: LoadingBackdropProps) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      onClick={onClose}>
      <Loading />
    </Backdrop>
  );
};

export default LoadingBackdrop;

import { List } from "@mui/material";
import React from "react";
import InfiniteScrollContainer from "../../../../../components/universal/InfiniteScroll/InfiniteScrollContainer";
import { UseInfiniteCursorConnectionScrollResults } from "../../../../../components/universal/InfiniteScroll/useInfiniteCursorConnectionScroll";
import { CardMode } from "./DisplayCard";
import ListHeader from "./ListHeader";

interface IDAbleStringOrNumber {
  id: string | number;
}

export interface RenderListItemArgs<T extends IDAbleStringOrNumber> {
  element: T;
  disabled: boolean;
  focused: boolean;
  mode: CardMode;
  key: string;
  forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
}

interface InfiniteScrollDisplayListProps<K extends string, EdgeType extends IDAbleStringOrNumber> {
  title: string;
  renderListItem: (args: RenderListItemArgs<EdgeType>) => JSX.Element;
  columnMode: CardMode;
  selectedId?: string | null;
  disabled?: boolean;
  infiniteScrollResults: UseInfiniteCursorConnectionScrollResults<K, EdgeType>;
}

const InfiniteScrollDisplayList = <K extends string, EdgeType extends IDAbleStringOrNumber>({
  title,
  renderListItem,
  columnMode,
  selectedId = null,
  disabled: _disabled = false,
  infiniteScrollResults,
}: InfiniteScrollDisplayListProps<K, EdgeType>) => {
  const renderItem = (element: EdgeType, forwardRef?: React.MutableRefObject<HTMLDivElement | null>) => {
    const focused = element.id === selectedId;
    const disabled = !focused && _disabled;
    const mode = focused ? "Cancel" : columnMode;
    return renderListItem({
      element,
      disabled,
      focused,
      mode,
      key: `listItem-${element.id}`,
      forwardRef,
    });
  };

  return (
    <List disablePadding>
      <ListHeader title={title} />
      <InfiniteScrollContainer
        renderItem={renderItem}
        infiniteScrollResults={infiniteScrollResults}
        loadingOffset={10} />
    </List>
  );
};

export default InfiniteScrollDisplayList;

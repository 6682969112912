import { Dialog } from "@mui/material";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import { jsDateToDateAndTimeInTz } from "@notemeal/shared/ui/utils/dateTimes";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import LoadingBackdrop from "apps/web/src/components/universal/LoadingBackdrop";
import { useEditMealPlanCalendarMutation, useMealPlanCalendarQuery } from "apps/web/src/types";
import React, { useState } from "react";
import { evictMealPlanCalendarQueriesForAthlete } from "../cache";
import { EditMealPlanCalendarDialogContent } from "./EditMealPlanCalendarDialogContent";

interface EditMealPlanCalendarDialogProps {
  athleteId: string;
  mealPlanId: string;
  mealPlanName: string;
  open: boolean;
  onClose: () => void;
  promptToSetDefaultDates?: boolean;
}

export const EditMealPlanCalendarDialog = ({
  athleteId,
  mealPlanId,
  mealPlanName,
  onClose,
  open,
  promptToSetDefaultDates,
}: EditMealPlanCalendarDialogProps) => {
  const { setMessage } = useSnackbar();
  const clientTimezone = useClientTimezone();
  // Store in state so that we don't re-run the query when the date changes
  const [after] = useState(jsDateToDateAndTimeInTz(new Date(), clientTimezone).date);

  const { data, loading } = useMealPlanCalendarQuery({
    variables: {
      mealPlanId,
      after,
    },
    onError: () => {
      setMessage("error", "An error occurred while fetching the meal plan calendar...");
      onClose();
    },
  });

  const [editMealPlanCalendar, { loading: saving }] = useEditMealPlanCalendarMutation({
    update: (cache, { data }) => {
      if (data) {
        evictMealPlanCalendarQueriesForAthlete({
          athleteId,
          cache,
          type: "Edit",
          mealPlanId,
        });
      }
    },
    onCompleted: () => {
      setMessage("success", "Meal plan calendar updated!");
      onClose();
    },
    onError: () => {
      setMessage("error", "An error occurred while updating the meal plan calendar. Please try again.");
      onClose();
    },
  });

  const loadingOrSaving = saving || loading;

  if (loadingOrSaving || !data) {
    return <LoadingBackdrop open={open} onClose={onClose} />;
  }

  const { isAutoSuggestionsEnabled, schedule, mealTemplates, activityTemplates, startDate, endDate } = data.mealPlan;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth>
      <EditMealPlanCalendarDialogContent
        athleteId={athleteId}
        mealPlanId={mealPlanId}
        mealPlanName={mealPlanName}
        isAutoSuggestionsEnabled={isAutoSuggestionsEnabled}
        schedule={schedule}
        startDate={startDate}
        endDate={endDate}
        mealTemplates={mealTemplates}
        activityTemplates={activityTemplates}
        dayOfWeekAssignments={data?.mealPlan.daysOfWeek}
        onClose={onClose}
        onDone={input =>
          editMealPlanCalendar({
            variables: { input },
          })
        }
        saving={saving}
        promptToSetDefaultDates={promptToSetDefaultDates}
      />
    </Dialog>
  );
};

import { Box, useTheme } from "@mui/material";
import { Macros, getCaloriesPerMacro } from "@notemeal/shared/utils/macro-protocol";
import React from "react";
import { SingleColorDial } from "./SingleColorDial";

interface SingleColorDialsProps {
  macros: Macros;
  hideNumbers?: boolean;
  variant?: "sm" | "md" | "lg";
}

export const SingleColorDials = ({ macros, hideNumbers, variant }: SingleColorDialsProps) => {
  const {
    palette: { cho, pro, fat },
  } = useTheme();

  const { cho: choCal, pro: proCal, fat: fatCal } = hideNumbers ? getCaloriesPerMacro(macros) : macros;
  const { kcal: total } = macros;

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <SingleColorDial
        hideNumbers={hideNumbers}
        variant={variant}
        value={choCal}
        total={total ?? 0}
        label="CHO"
        color={cho} />
      <SingleColorDial
        hideNumbers={hideNumbers}
        variant={variant}
        value={proCal}
        total={total ?? 0}
        label="PRO"
        color={pro} />
      <SingleColorDial
        hideNumbers={hideNumbers}
        variant={variant}
        value={fatCal}
        total={total ?? 0}
        label="FAT"
        color={fat} />
    </Box>
  );
};

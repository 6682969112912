import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, Typography } from "@mui/material";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { initMacros, maybeMacrosToMacros } from "@notemeal/shared/utils/macro-protocol";
import React, { memo, useCallback } from "react";
import { EditFullMacroMealTemplateFragment } from "../../../../types";
import { EditMacroMealOption } from "../../../MealOption/Edit/EditMacroMealOption";
import { MealOptionPreview } from "../../../MealOption/Edit/EditMealOption";
import { EditMealOptions } from "./EditMealOptions";

interface EditMacroMealOptionsProps {
  mealTemplate: EditFullMacroMealTemplateFragment;
}

export const EditMacroMealOptions = memo(({ mealTemplate }: EditMacroMealOptionsProps) => {
  const { id, meal, selectedMealOptionId, newMealOptionIds } = mealTemplate;
  const targetMacros = maybeMacrosToMacros(mealTemplate);

  const renderMacroMealOption = useCallback(
    (mealOption: MealOptionPreview) => (
      <EditMacroMealOption
        key={mealOption.id}
        mealOption={mealOption}
        mealTemplateId={id}
        mealType={meal.type}
        targetMacros={targetMacros}
        selected={mealOption.id === selectedMealOptionId}
        isNew={newMealOptionIds.includes(mealOption.id)}
      />
    ),
    [id, selectedMealOptionId, newMealOptionIds, targetMacros, meal.type]
  );

  const { mealOptions, pendingMealOptions } = mealTemplate;
  const mealOptionsCount = mealOptions.length;
  const pendingMealOptionsCount = pendingMealOptions.length;
  const totalCount = mealOptionsCount + pendingMealOptionsCount;
  const needsReviewCounts = `${pendingMealOptionsCount}/${totalCount}`;
  const needsReviewText = " meal options need review";
  const { cho, pro, fat } = targetMacros;

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
        <Box sx={{ mr: "2px", height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
        <Box sx={{ pr: 2, flex: 1, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ pt: 1, display: "flex", gap: 2, alignItems: "center" }}>
              <RestaurantIcon fontSize="small" sx={{ color: "greyscale.400" }} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1Medium">{meal.name}</Typography>
                <MacrosSummaryLabel macros={initMacros(cho, pro, fat)} variant="sm" />
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              {!!pendingMealOptionsCount && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography variant="body1Medium">{needsReviewCounts}</Typography>
                  <Typography variant="body1">{needsReviewText}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          <EditMealOptions mealTemplate={mealTemplate} renderMealOptionPreview={renderMacroMealOption} />
        </Box>
      </Box>
    </Card>
  );
});

import { Box, Table, TableBody, TableContainer, TableHead, TablePagination } from "@mui/material";
import React, { useState } from "react";
import AthleteAssignment from "../../components/AthleteAssignment/AthleteAssignment";
import { AthleteAssignState } from "../../components/AthleteAssignment/athleteAssignReducer";
import {
  TeamsPickerTeamFragment,
  useUpdateMacroMealPlanTemplateAssignScopeMutation,
  useUpdateMacroMealPlanTemplateSharingScopeMutation,
} from "../../types";
import { PaginationHooks, getMuiTablePaginationProps } from "../../utils/pagination";
import { buildAdvancedSelectionInput } from "../Tags/reducers/advancedSelectionReducers";

interface EditMPTAssignInfo {
  macroMealPlanTemplateId: string;
  initialState: AthleteAssignState<TeamsPickerTeamFragment>;
}

interface BaseMealPlanTemplateProps<MPT extends { id: string }> {
  pagination: PaginationHooks;
  renderHeaderRow: () => React.ReactNode;
  mealPlanTemplateRows: MPT[];
  renderMealPlanTemplateRow: (
    mealPlanTemplate: MPT,
    updateIsShared: (macroMealPlanTemplateId: string, isShared: boolean) => void,
    setEditMPTAssignInfo: (macroMealPlanTemplateId: string, initialState: AthleteAssignState<TeamsPickerTeamFragment>) => void
  ) => React.ReactNode;
  loading: boolean;
  total: number;
}

const BaseMealPlanTemplates = <MPT extends { id: string }>({
  pagination,
  renderHeaderRow,
  mealPlanTemplateRows,
  renderMealPlanTemplateRow,
  loading,
  total,
}: BaseMealPlanTemplateProps<MPT>) => {
  const [updateIsShared] = useUpdateMacroMealPlanTemplateSharingScopeMutation();
  const [updateAssignScope] = useUpdateMacroMealPlanTemplateAssignScopeMutation();

  const [editMPTAssignInfo, setEditMPTAssignInfo] = useState<EditMPTAssignInfo | null>(null);

  const updateAssignSharing = (mealPlanTemplateId: string, assignState: AthleteAssignState<TeamsPickerTeamFragment>) => {
    updateAssignScope({
      variables: {
        input: {
          mealPlanTemplateId: mealPlanTemplateId,
          teamIds: assignState.__typename === "Teams" ? assignState.teams.map(team => team.id) : null,
          advancedSelectionInput:
            assignState.__typename === "Tags" ? buildAdvancedSelectionInput(assignState.advancedSelectionState) : null,
        },
      },
    });
    setEditMPTAssignInfo(null);
  };

  const rowUpdateIsShared = (macroMealPlanTemplateId: string, isShared: boolean) =>
    updateIsShared({ variables: { input: { macroMealPlanTemplateId, isShared } } });
  const rowSetEditMPTAssignInfo = (macroMealPlanTemplateId: string, initialState: AthleteAssignState<TeamsPickerTeamFragment>) =>
    setEditMPTAssignInfo({ macroMealPlanTemplateId, initialState });

  return (
    <>
      <Box
        sx={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TableContainer sx={{ flex: 1, display: "flex", flexDirection: "column", overflowY: "auto" }}>
          <Table size="small">
            <TableHead>{renderHeaderRow()}</TableHead>
            <TableBody>
              {mealPlanTemplateRows.map(mealPlanTemplate =>
                renderMealPlanTemplateRow(mealPlanTemplate, rowUpdateIsShared, rowSetEditMPTAssignInfo)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          sx={{ ".MuiTablePagination-actions": { mr: 8 } }}
          {...getMuiTablePaginationProps(pagination, total)}
        />
      </Box>
      {editMPTAssignInfo && (
        <AthleteAssignment
          open={!!editMPTAssignInfo}
          onClose={() => setEditMPTAssignInfo(null)}
          onSave={newState => updateAssignSharing(editMPTAssignInfo.macroMealPlanTemplateId, newState)}
          initialState={editMPTAssignInfo.initialState}
        />
      )}
    </>
  );
};

export default BaseMealPlanTemplates;

import { useApolloClient } from "@apollo/client";
import { RecipeEdit } from "apps/web/src/components/Recipe/EditDialog/RecipeEdit";
import { RecipeFormState } from "apps/web/src/components/Recipe/Form/utils";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";
import { NAV_ORG_RECIPE_CREATE } from "../FoodManagement/FoodManagementPaths";

const RecipeCreatePage = () => {
  const { id: editRecipeId } = useParams<{ id: string }>();
  const { setMessage } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const apolloClient = useApolloClient();

  const { readonly } = location.state || {};

  const resetTable = () => {
    apolloClient.cache.evict({
      fieldName: "recipeOffsetConnection",
      broadcast: true,
    });
  };

  const onSuccess = () => {
    setMessage("success", `Edited recipe`);
    resetTable();
    navigate(-1);
  };

  const onClose = () => {
    navigate(-1);
  };

  const onDuplicate = () => {
    navigate(NAV_ORG_RECIPE_CREATE, {
      state: { duplicatedRecipeId: editRecipeId, isDuplicate: true },
      replace: true,
    });
  };

  const onScaleAndCreateNewRecipe = (state: RecipeFormState) => {
    navigate(NAV_ORG_RECIPE_CREATE, {
      state: { duplicatedRecipeId: state.id, isDuplicate: false, newYield: state.serving.perRecipeYield },
      replace: true,
    });
  };

  return (
    !!editRecipeId && (
      <RecipeEdit
        forStaff={false}
        onEdit={onSuccess}
        onClose={onClose}
        readonly={readonly}
        recipeId={editRecipeId}
        onDuplicate={onDuplicate}
        onScaleAndCreateNewRecipe={onScaleAndCreateNewRecipe}
      />
    )
  );
};

export default RecipeCreatePage;

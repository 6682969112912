import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";

import { ActivityItem } from "@notemeal/shared/ui/Activity";
import ExchangeSummaryLabel from "@notemeal/shared/ui/Exchange/SummaryLabel";
import { getMacroProtocolMacros } from "@notemeal/shared/ui/MacroProtocol/utils";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import MealTemplateViewExchange from "@notemeal/shared/ui/MealTemplate/View/Exchange";
import MealTemplateViewMacro from "@notemeal/shared/ui/MealTemplate/View/Macro";
import { sortedScheduleComponents } from "@notemeal/shared/ui/Schedule/Container";
import {
  ActivityTemplateFragment,
  ExchangeMealPlanDisplaySettingsFragment,
  MacroMealPlanDisplaySettingsFragment,
  MacroMealTemplateViewFragment,
} from "../../../types";
import { MealPlan } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templateDiv: {
      padding: theme.spacing(),
      boxSizing: "border-box",
      overflowX: "hidden",
    },
    activityTemplate: {
      margin: 0,
      boxSizing: "border-box",
      height: "100%",
    },
    header: {
      padding: theme.spacing(),
    },
  })
);

interface MealPlanViewContentProps {
  mealPlan: MealPlan;
  macroDisplaySettings: MacroMealPlanDisplaySettingsFragment;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment;
}

const MealPlanViewContent = ({ mealPlan, exchangeDisplaySettings, macroDisplaySettings }: MealPlanViewContentProps) => {
  const classes = useStyles();

  const [{ windowWidth }, setWindow] = useState<{
    windowWidth: number;
    windowHeight: number;
  }>({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });
  const windowResizeCallback = useRef<() => void>();

  useEffect(() => {
    if (windowResizeCallback.current) {
      window.removeEventListener("resize", windowResizeCallback.current);
    }
    const newResizeCallback = () => {
      if (windowWidth !== window.innerWidth) {
        setWindow({
          windowHeight: window.innerHeight,
          windowWidth: window.innerWidth,
        });
      }
    };
    window.addEventListener("resize", newResizeCallback);
    windowResizeCallback.current = newResizeCallback;
  }, [windowWidth, setWindow]);

  const activities = mealPlan.activityTemplates;
  const activityToStart = (at: ActivityTemplateFragment) => at.activity.start;
  const renderActivity = (at: ActivityTemplateFragment) => <ActivityItem activity={at.activity} className={classes.activityTemplate} />;

  const mealPlanComponents =
    mealPlan.type === "exchange"
      ? sortedScheduleComponents({
          meals: mealPlan.mealTemplates,
          mealToStart: mt => mt.meal.start,
          renderMeal: mt => (
            <MealTemplateViewExchange
              mealTemplate={mt}
              containerWidth={windowWidth}
              exchangeSet={mealPlan.exchangeSet}
              displayExchangeLists={exchangeDisplaySettings.dailyProgressViewsForExchanges === "all"}
              displayMealExchanges={exchangeDisplaySettings.mealProgressViewsForExchanges === "all"}
              displayMealMacros={exchangeDisplaySettings.mealProgressViewsForMacros === "all"}
              displayMealOptions={true}
              displayMealOptionMacros={exchangeDisplaySettings.mealProgressViewsForMacros === "all"}
            />
          ),
          activities,
          activityToStart,
          renderActivity,
        })
      : sortedScheduleComponents({
          meals: mealPlan.mealTemplates,
          mealToStart: mt => mt.meal.start,
          renderMeal: (mt: MacroMealTemplateViewFragment) => (
            <MealTemplateViewMacro
              mealTemplate={mt}
              containerWidth={windowWidth}
              displayMealMacros={macroDisplaySettings.mealProgressViews === "all"}
              displayMealOptions={true}
              displayMealOptionMacros={macroDisplaySettings.mealProgressViews === "all"}
            />
          ),
          activities,
          activityToStart: a => a.activity.start,
          renderActivity,
        });
  const mealPlanMacros = getMacroProtocolMacros(mealPlan.macroProtocol, mealPlan.athlete.birthDate);
  const headerComponent = (
    <div className={classes.header}>
      <Typography variant="h3">{mealPlan.name}</Typography>
      {(exchangeDisplaySettings.dailyProgressViewsForMacros === "all" || macroDisplaySettings.dailyProgressViews === "all") && (
        <>
          <Typography variant="h3">{mealPlanMacros.kcal}kcal</Typography>
          <div>
            <MacrosSummaryLabel macros={mealPlanMacros} variant="sm" />
          </div>
        </>
      )}

      {exchangeDisplaySettings.dailyProgressViewsForExchanges === "all" && mealPlan.type === "exchange" && (
        <div>
          <ExchangeSummaryLabel
            targetExchangeAmounts={mealPlan.exchangeTargets}
            variant="sm"
            consolidatePro={false} />
        </div>
      )}
    </div>
  );

  return (
    <div>
      {headerComponent}
      {mealPlanComponents.map(({ component, id }) => (
        <div key={id} className={classes.templateDiv}>
          {component}
        </div>
      ))}
    </div>
  );
};

export default MealPlanViewContent;

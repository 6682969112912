import { Card, CardActionArea, CardContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import classnames from "classnames";
import React, { ReactNode, useState } from "react";
import { KioskMealMenuPreviewFragment, MenuOrderInfoFragment } from "../../types";
import MenuTimingChip from "../../views/Kiosk/Configure/MenuTimingChip";

export interface MenuSelectorCardProps {
  mealMenu: KioskMealMenuPreviewFragment;
  selected?: boolean;
  onClick: () => void;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  kioskPreviewOrderInfoForMenu?: MenuOrderInfoFragment;
}

const useStyles = makeStyles(({ spacing, palette: { info, secondary } }: Theme) =>
  createStyles({
    disabledCard: { opacity: 0.15 },
    chip: {
      margin: spacing(1, 1, 0, 0),
    },
    selected: {
      backgroundColor: info.lighter,
    },
    limitText: {
      display: "flex",
      gap: "0.25em",
    },
  })
);

const MenuSelectorCard = ({
  mealMenu,
  selected,
  onClick,
  disabled,
  className,
  children,
  kioskPreviewOrderInfoForMenu,
}: MenuSelectorCardProps) => {
  const classes = useStyles();
  const [timezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const { formatDateInTimezoneWithLocale } = useDateFormatting();
  const mealMenuStartToEndForPopover = `${formatDateInTimezoneWithLocale(mealMenu.start, timezone)}, ${formatTimeRangeInTimezone(
    mealMenu.start,
    mealMenu.durationInMinutes,
    timezone
  )}`;

  const orderCount = kioskPreviewOrderInfoForMenu?.menuOrderCount;

  const orderLimitTextColor = orderCount ? "info" : "textSecondary";
  const orderLimitText = orderCount
    ? mealMenu.userOrderLimit !== null
      ? `(${orderCount} of ${mealMenu.userOrderLimit} orders placed)`
      : `(${orderCount} order${orderCount > 1 ? "s" : ""} placed. No limit)`
    : mealMenu.userOrderLimit === null
    ? "(No limit)"
    : `(Limit ${mealMenu.userOrderLimit})`;

  return (
    <Card className={classnames(className, selected ? classes.selected : disabled ? classes.disabledCard : undefined)} raised>
      <CardActionArea disabled={disabled} onClick={onClick}>
        <CardContent>
          <Typography
            variant="h2"
            gutterBottom
            sx={theme => ({ fontWeight: theme.typography.fontWeightRegular })}>
            {mealMenu.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
            gutterBottom>
            {mealMenuStartToEndForPopover}
          </Typography>
          {mealMenu.userOrderLimit !== undefined && mealMenu.userOrderLimit !== 1 && (
            <div className={classes.limitText}>
              <Typography
                color="textSecondary"
                variant="body2"
                gutterBottom>
                *Available for multiple orders
              </Typography>
              <Typography variant="body2" color={orderLimitTextColor}>
                {orderLimitText}
              </Typography>
            </div>
          )}
          <MenuTimingChip mealMenu={mealMenu} />
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MenuSelectorCard;

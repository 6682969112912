import LeftIcon from "@mui/icons-material/NavigateBefore";
import { Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useClientTimezone } from "@notemeal/shared/ui/contexts/ClientTimezone";
import Loading from "@notemeal/shared/ui/global/Loading";
import { formatTimeRangeInTimezone } from "@notemeal/shared/ui/utils/dateTimes";
import React from "react";
import Content from "../../../../components/MenuOrder/EditDialogContent";
import { MenuOrderFormFragment, useMealMenuDialogQuery } from "../../../../types";
import { AthleteOrderStateAction, OrderFormAthleteOrderState } from "../orderStateReducer";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    paper: {
      height: `calc(100% - 64px)`,
    },
    leftTitle: { display: "flex", alignItems: "center" },
    backButton: {
      marginRight: theme.spacing(),
    },
    content: {
      padding: 0,
    },
  })
);

interface KioskMenuOrderEditDialogProps {
  onRemoveMenuOrder: (menuOrderId: string) => void;
  open: boolean;
  onClose: () => void;
  athleteName: string;
  athleteId: string;
  athleteUserId?: string;
  teamId: string;
  avoidFoodGroupIds: string[];
  athleteOrderState: OrderFormAthleteOrderState;
  athleteOrderStateDispatch: React.Dispatch<AthleteOrderStateAction>;
  menuOrders: readonly MenuOrderFormFragment[];
  activeMenuOrder: MenuOrderFormFragment;
}

const KioskMenuOrderEditDialog = ({
  onRemoveMenuOrder,
  open,
  onClose,
  athleteName,
  athleteId,
  athleteUserId,
  teamId,
  avoidFoodGroupIds,
  athleteOrderState,
  athleteOrderStateDispatch,
  menuOrders,
  activeMenuOrder,
}: KioskMenuOrderEditDialogProps) => {
  const classes = useStyles();
  const clientTimezone = useClientTimezone();
  const { data } = useMealMenuDialogQuery({
    variables: {
      mealMenuId: activeMenuOrder.mealMenu.id,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogTitle className={classes.title}>
        <div className={classes.leftTitle}>
          <IconButton
            size="small"
            onClick={onClose}
            className={classes.backButton}>
            <LeftIcon fontSize="large" />
          </IconButton>
          <Typography variant="h3">
            {activeMenuOrder.mealMenu.name} (
            {formatTimeRangeInTimezone(
              activeMenuOrder.mealMenu.start,
              activeMenuOrder.mealMenu.durationInMinutes,
              activeMenuOrder.mealMenu.timezone,
              {
                excludeTimezoneSuffix: activeMenuOrder.mealMenu.timezone === clientTimezone,
              }
            )}
            )
          </Typography>
        </div>
        <Typography variant="h3" color="textSecondary">
          Ordering for {athleteName}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!data ? (
          <Loading />
        ) : (
          <Content
            key={activeMenuOrder.id} // TODO: this feels hacky
            activeMenuOrder={activeMenuOrder}
            athleteId={athleteId}
            athleteUserId={athleteUserId}
            teamId={teamId}
            onRemoveMenuOrder={onRemoveMenuOrder}
            mealMenuDiningStations={data.mealMenu.mealMenuDiningStations}
            onClose={onClose}
            avoidFoodGroupIds={avoidFoodGroupIds}
            athleteOrderState={athleteOrderState}
            athleteOrderStateDispatch={athleteOrderStateDispatch}
            menuOrders={menuOrders}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default KioskMenuOrderEditDialog;

import { Theme } from "@mui/material";
import { Semibold } from "../Common/TypographyCommon";

export const muiAlert = {
  styleOverrides: {
    root: {
      margin: "8px 0",
    },
    standardSuccess: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.success.lighter,
    }),
    // You can add more overrides for other alert variants if needed
    standardError: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.error.lighter,
    }),
    standardWarning: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.warning.lighter,
    }),
    standardInfo: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.info.lighter,
    }),
  },
};

export const muiAlertTitle = {
  styleOverrides: {
    root: {
      fontWeight: Semibold,
      fontSize: 14,
    },
  },
};

import CakeIcon from "@mui/icons-material/Cake";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AthletesBirthdaysFragment } from "apps/web/src/types";
import React, { useCallback, useMemo, useState } from "react";
import { findAthleteBirthdaysForDayByTeam, groupAthletesByTeam } from "./utils";

interface Props {
  athleteBirthdays: readonly AthletesBirthdaysFragment[];
  birthDayFormat: string;
}

const MenuBuilderAthletesBirthdayModal = ({ athleteBirthdays = [], birthDayFormat }: Props) => {
  const [openBirthDayModal, setOpenBirthDayModal] = useState<boolean>(false);

  const { spacing, palette } = useTheme();

  const onCloseModal = useCallback(() => {
    setOpenBirthDayModal(false);
  }, []);

  const filteredAthletesWithBirthdays = useMemo(
    () => findAthleteBirthdaysForDayByTeam(athleteBirthdays, birthDayFormat),
    [athleteBirthdays, birthDayFormat]
  );

  const athleteCategoriesWithTeam = useMemo(() => {
    const grouped = groupAthletesByTeam(filteredAthletesWithBirthdays);
    return Object.values(grouped);
  }, [filteredAthletesWithBirthdays]);

  return filteredAthletesWithBirthdays.length > 0 ? (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <ButtonBase
        sx={{
          height: 20,
          width: 39,
          marginLeft: spacing(1),
          backgroundColor: palette.primary.light,
          borderRadius: spacing(2),
          paddingLeft: spacing(0.5),
          paddingRight: spacing(0.5),
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        onClick={() => {
          setOpenBirthDayModal(true);
        }}
      >
        <CakeIcon
          sx={{
            color: palette.primary.main,
            fontSize: "12px !important",
          }}
        />
        <Typography
          sx={{
            color: palette.primary.main,
            fontSize: 11,
            lineHeight: "14px",
            fontWeight: "500",
          }}
        >
          {filteredAthletesWithBirthdays.length}
        </Typography>
      </ButtonBase>
      <Dialog
        open={openBirthDayModal}
        onClose={onCloseModal}
        fullWidth={true}
        maxWidth={"sm"}>
        <DialogTitle>View Athlete Birthdays</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: spacing(2),
            top: spacing(1),
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {athleteCategoriesWithTeam.map(item => (
            <Box key={item.id}>
              <Typography variant="body2Semibold">{item.name}</Typography>
              {item.athletes.map(athlete => (
                <Box
                  key={athlete.id}
                  sx={{
                    padding: spacing(1),
                    backgroundColor: palette.greyscaleLight[50],
                    marginBottom: spacing(1),
                    marginTop: spacing(1),
                  }}
                >
                  <Typography variant="body2">{athlete.lastName + ", " + athlete.firstName}</Typography>
                </Box>
              ))}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default MenuBuilderAthletesBirthdayModal;

import { Box, Button } from "@mui/material";
import React from "react";
import { TWTooltip } from "./TWTooltip";

export const TWTooltipExample = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TWTooltip title="Title" subcopy="Subcopy">
        <Button>ACTION</Button>
      </TWTooltip>
    </Box>
  );
};

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment, MenuItem, TextField } from "@mui/material";
import LoadingButton from "apps/web/src/components/universal/LoadingButton";
import { NamedTagForSelectionFragment } from "apps/web/src/types";
import React from "react";
import LabeledSelectNoId from "../../../../components/universal/LabeledSelectNoId";
import { toTitleCase } from "../../../../utils/titleCase";
import NamedTagSelector from "../../../Tags/Selectors/NamedTagSelector";

export const PROFILES = "profiles";
export const ENGAGEMENT = "engagement";
export const PREFERENCES = "preferences";
export type TableView = typeof PROFILES | typeof ENGAGEMENT | typeof PREFERENCES;

export const ACTIVE = "active";
export const NO_LINK_SENT = "no_link_sent";
export const LINK_EXPIRED = "link_expired";
export const ARCHIVED = "archived";
export const ALL = "all";
export type TableFilter = typeof ACTIVE | typeof NO_LINK_SENT | typeof LINK_EXPIRED | typeof ARCHIVED | typeof ALL;

export const AVOIDED_FOODS = "avoided foods";
export const MEAL_PLAN_VIEW_COUNT = "mealPlanViewCount";
export const MEALS_LOGGED_COUNT = "mealsLoggedCount";
export const MEALS_ORDERED_COUNT = "mealsOrderedCount";
export const TOTAL_MEALS_COUNT = "totalMealsCount";
export const IMAGE_UPLOAD_COUNT = "imageUploadCount";

export type SortType =
  | typeof AVOIDED_FOODS
  | typeof MEAL_PLAN_VIEW_COUNT
  | typeof MEALS_LOGGED_COUNT
  | typeof MEALS_ORDERED_COUNT
  | typeof TOTAL_MEALS_COUNT
  | typeof IMAGE_UPLOAD_COUNT;

interface TableToolbarProps {
  title: string;
  setInLastDays: (inLastDays: number) => void;
  inLastDays: number;
  setView: (view: TableView) => void;
  view: TableView;
  setFilter: (filter: TableFilter) => void;
  filter: TableFilter;
  queryText: string;
  setQueryText: (text: string) => void;
  onClickAdd: () => void;
  onExport: () => void;
  selectedNamedTags: NamedTagForSelectionFragment[];
  onChangeNamedTags: (newTags: NamedTagForSelectionFragment[]) => void;
  isNotemealLinked: boolean;
  exportLoading: boolean;
}

function getFilterName(filter: string): string {
  switch (filter) {
    case ACTIVE:
      return "Active";
    case NO_LINK_SENT:
      return "No Link Sent";
    case LINK_EXPIRED:
      return "Link Expired";
    case ARCHIVED:
      return "Archived";
    case ALL:
      return "All";
    default:
      return "unknown filter";
  }
}

const TableToolbar = ({
  setView,
  view,
  inLastDays,
  setInLastDays,
  filter,
  setFilter,
  queryText,
  setQueryText,
  onClickAdd,
  onExport,
  selectedNamedTags,
  onChangeNamedTags,
  isNotemealLinked,
  exportLoading,
}: TableToolbarProps) => {
  return (
    <Box sx={{ width: "100%", display: "flex", gap: 2, justifyContent: "flex-start", alignItems: "flex-end" }}>
      <TextField
        sx={{ width: 200 }}
        select
        label="View"
        value={view}
        onChange={e => setView(e.target.value as TableView)}>
        {[PROFILES, ENGAGEMENT, PREFERENCES].map(option => (
          <MenuItem key={option} value={option}>
            {toTitleCase(option)}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ width: 200 }}
        select
        label="Filter"
        value={filter}
        onChange={e => setFilter(e.target.value as TableFilter)}>
        {[ACTIVE, NO_LINK_SENT, LINK_EXPIRED, ARCHIVED, ALL].map(option => (
          <MenuItem key={option} value={option}>
            {getFilterName(option)}
          </MenuItem>
        ))}
      </TextField>
      <Box sx={{ ml: 10, minWidth: "300px" }}>
        <NamedTagSelector
          selectedNamedTags={selectedNamedTags}
          onChange={newNamedTags => onChangeNamedTags(newNamedTags)}
          limitTags={1}
          textInputLabel={"Tags"}
        />
      </Box>
      {view === ENGAGEMENT && (
        <LabeledSelectNoId<string>
          sx={{ width: "200px" }}
          placeholder="Over Last Days"
          optionToName={o => (o === "1" ? "Last 24 hours" : o === "90" ? `Last 3 months` : o === "365" ? `Last year` : `Last ${o} days`)}
          selectedOption={String(inLastDays)}
          options={["1", "7", "14", "30", "90", "365"]}
          onChange={option => setInLastDays(Number(option))}
        />
      )}
      <TextField
        sx={{ minWidth: "250px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        inputProps={{
          "aria-label": "Search Roster",
        }}
        placeholder="Search"
        value={queryText}
        onChange={e => setQueryText(e.target.value)}
      />
      <LoadingButton
        loading={exportLoading}
        buttonText="Export"
        variant="outlined"
        onClick={onExport} />
      <Button sx={{ whiteSpace: "nowrap" }} onClick={onClickAdd}>
        {isNotemealLinked ? "Create Nutrition-Only Athlete" : "Create Athlete"}
      </Button>
    </Box>
  );
};

export default TableToolbar;

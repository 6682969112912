import { getFoodSearchFiltersForOrgLocale } from "@notemeal/food/utils";
import { useLocaleContext } from "@notemeal/shared/ui/contexts/LocaleContext";
import { useUser } from "../tokens";

export const useFoodSearchFiltersForUser = () => {
  const user = useUser();
  const localeContext = useLocaleContext();

  if (user?.isStaff) {
    return { localeFilter: [], dataSourceFilter: [] };
  } else {
    return getFoodSearchFiltersForOrgLocale(localeContext.locale);
  }
};

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import { OrgGroup, useMoveOrgFoodToOrgGroupMutation, useResourceSharingOrgGroupsQuery } from "apps/web/src/types";
import React, { useEffect, useState } from "react";
import { IFoodRow } from "../Table/TableRow";

interface MoveOrgFoodToOrgGroupDialogProps {
  setSuccessMsg: (msg: string | null) => void;
  onClose: () => void;
  moveOrgFoodToOrgGroup: IFoodRow;
}

export const MoveOrgFoodToOrgGroupDialog = ({ setSuccessMsg, onClose, moveOrgFoodToOrgGroup }: MoveOrgFoodToOrgGroupDialogProps) => {
  const { data: resourceSharingData, loading: resourceSharingLoading } = useResourceSharingOrgGroupsQuery();

  const resourceSharingOrgGroups = resourceSharingData?.resourceSharingOrgGroups;

  const [orgGroup, setOrgGroup] = useState<Pick<OrgGroup, "name" | "id"> | null>(null);

  useEffect(() => {
    if (resourceSharingOrgGroups?.length === 1) {
      setOrgGroup(resourceSharingOrgGroups[0]);
    }
  }, [resourceSharingOrgGroups, resourceSharingOrgGroups?.length]);

  const [moveOrgFoodToOrgGroupMutation] = useMoveOrgFoodToOrgGroupMutation({
    variables: {
      input: {
        id: moveOrgFoodToOrgGroup?.id ?? "",
        orgGroupId: orgGroup?.id ?? "",
      },
    },
    onCompleted: () => {
      onClose();
      setSuccessMsg(`Moved ${moveOrgFoodToOrgGroup?.name ?? "the food"} to ${orgGroup?.name ?? "your"} Org Group Foods`);
    },
  });

  if (resourceSharingLoading || !resourceSharingOrgGroups) {
    return null;
  }

  return (
    <Dialog
      maxWidth="sm"
      open={true}
      onClose={onClose}>
      <DialogTitle title="Move Food" onClose={onClose} />
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to move the food to Org Group Foods? Doing so will:
          <ul>
            <li>Allow athletes to view and access the food for logging</li>
            <li>Allow other dietitians to view and use the food within their org</li>
          </ul>
        </Typography>
        {resourceSharingOrgGroups.length > 1 && (
          <>
            <Typography variant="body1">If so, please select the org group that you would like to move this food to.</Typography>
            <Autocomplete
              onChange={(_, option) => setOrgGroup(option?.orgGroup ?? null)}
              options={resourceSharingOrgGroups.map(og => ({ label: og.name, orgGroup: og }))}
              renderInput={params => <TextField {...params} label="Org Group" />}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          aria-label="Cancel">
          Cancel
        </Button>
        <Button
          disabled={!orgGroup}
          onClick={() => moveOrgFoodToOrgGroupMutation()}
          aria-label="Print">
          {resourceSharingOrgGroups?.length > 1 ? "Continue" : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Box, Button, Typography } from "@mui/material";
import ImageWithDismiss from "@notemeal/shared/ui/Image/ImageWithDismiss";
import React, { useState } from "react";
import { useGetOpenAiImageLazyQuery } from "../../../types";
import CropperModal from "../../universal/Image/CropModal";
import ImageFileDragAndDrop from "../../universal/Image/ImageFileDragAndDrop";

import { trackEvent } from "apps/web/src/reporting/reporting";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { useUploadPhoto } from "../../universal/Image/uploadPhoto";
import { FoodServingType } from "../FoodFormSchema/FoodServingSchema";

interface FoodImageSectionProps {
  name: string | null;
  thumbnailUrl: string | null;
  defaultServing?: FoodServingType;
  changeImage: (imageUrl: string | null) => void;
  disabled?: boolean;
}

export const FoodImageSection = ({ name, thumbnailUrl, defaultServing, changeImage, disabled }: FoodImageSectionProps) => {
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const { setMessage } = useSnackbar();

  const [uploadPhoto, imageUploading] = useUploadPhoto({
    onComplete: (imageUrl: string): void => {
      if (imageUrl) {
        changeImage(imageUrl);
      }
    },
  });

  const handleCropComplete = async (croppedImage: string) => {
    uploadPhoto(croppedImage);
  };

  const [getGeneratedImage, { loading: imageGenerating, data: imageGeneratingData, error: imageGeneratingError }] =
    useGetOpenAiImageLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: async data => {
        if (!data.getOpenAIImage) {
          return null;
        }
        trackEvent("Nutrition | Food Management | Food | Generate AI Food Image", { foodName: name ?? "" });
        uploadPhoto(data.getOpenAIImage);
      },
      onError: e => setMessage("error", e.message),
    });

  const handleRemovePhoto = () => {
    changeImage(null);
  };

  const handleOnGenerateImage = async () => {
    if (!(name && defaultServing?.defaultAmount && (defaultServing?.units.unit?.name || defaultServing?.units.customUnits))) {
      return;
    }

    if (thumbnailUrl) {
      handleRemovePhoto();
    }

    const {
      defaultAmount,
      units: { unit, customUnits },
    } = defaultServing;
    getGeneratedImage({
      variables: {
        foodName: name,
        servingUnit: (unit?.name || customUnits)!,
        servingAmount: defaultAmount,
      },
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {thumbnailUrl ? (
          <ImageWithDismiss
            disabled={disabled}
            imageUrl={thumbnailUrl}
            onDismiss={handleRemovePhoto} />
        ) : (
          <ImageFileDragAndDrop
            hasError={!!imageGeneratingError}
            disabled={disabled}
            errorMessage={
              <Typography variant="body2" sx={{ mt: 1 }}>
                Failed to generate the image.
                <br />
                Please try again.
              </Typography>
            }
            sx={{ p: 2, textAlign: "center", flexGrow: 1 }}
            onUpload={setImageToCrop}
            loading={imageGenerating || imageUploading}
          >
            <Typography gutterBottom>Drop Image or Click to Upload</Typography>
          </ImageFileDragAndDrop>
        )}
        <Button
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={handleOnGenerateImage}
          disabled={disabled || !name || imageGenerating || imageUploading}
        >
          {imageGeneratingData ? "Regenerate Image" : "Generate Image"}
        </Button>
      </Box>
      {imageToCrop && (
        <CropperModal
          open={!!imageToCrop}
          onClose={() => setImageToCrop(null)}
          imageUrl={imageToCrop}
          onSave={handleCropComplete} />
      )}
    </>
  );
};

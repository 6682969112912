import React from "react";

export const placesLibraries: ["places"] = ["places"];

const pacContainerStyles = `
  .pac-container {
    z-index: 2000
  }
`;

// Change styles of ReactGoogleAutocomplete dropdown (which has class "pac-container")
// This is needed because without it the dropdown is rendered behind modals.
export const GoogleMapsAutocompleteStyles = () => <style>{pacContainerStyles}</style>;

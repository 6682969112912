import React from "react";
import { MenuItemRowInfoWithAmount } from "../utils";
import Row from "../Shared/Row";
import ChoiceCell from "../Shared/ChoiceCell";

interface TableRowProps {
  menuItemRowInfo: MenuItemRowInfoWithAmount;
}

export const HeaderRow = () => {
  return <Row>{["Amount", "Name", "Options", "Special Requests"]}</Row>;
};

const TableRow = ({ menuItemRowInfo }: TableRowProps) => {
  return (
    <Row>
      {[
        menuItemRowInfo.amount,
        menuItemRowInfo.name,
        <ChoiceCell key={`choice-cell-${menuItemRowInfo.id}`} mappedChoices={menuItemRowInfo.mappedChoices} />,
        menuItemRowInfo.specialRequests,
      ]}
    </Row>
  );
};

export default TableRow;

import { Macros, scaleMacros, sumMacros } from "@notemeal/shared/utils/macro-protocol";
import { ExchangeTotals, getExchangeTypeAnchoredMacro, mapExchangeTotals, sortExchangeFn, sumExchangeTotals } from "../Exchange/utils";
import { getExchangePickList } from "../ExchangeServingList/utils";
import { getFoodOrRecipeExchangeRatios } from "../graphql/resolvers/ExchangeSet";
import { ResolverContext } from "../graphql/resolvers/types";
import {
  ExchangeAmountFragment,
  ExchangeRatioFragment,
  ExchangeSummaryLabelFragment,
  FullExchangeSetFragment,
  FullServingAmountFragment,
  RecipeWithIngredientsFragment,
  useRecipesByIdWithIngredientsQuery,
} from "../types";

export const getExchangeAmountsMacros = (exchangeAmounts: readonly ExchangeAmountFragment[]): Macros =>
  sumMacros(exchangeAmounts.map(getExchangeAmountMacros));

export const getExchangeAmountMacros = ({ exchange, amount }: ExchangeAmountFragment): Macros => {
  return scaleMacros(exchange, amount);
};

export const sortExchangeAmounts = (exchangeAmounts: readonly ExchangeSummaryLabelFragment[]): readonly ExchangeSummaryLabelFragment[] => {
  return [...exchangeAmounts].sort((ea1, ea2) => sortExchangeFn(ea1.exchange, ea2.exchange));
};

export const EXCHANGE_AMOUNT_CARD_WIDTH = 150;
export const EXCHANGE_AMOUNT_SELECTED_CARD_WIDTH = 225;

export const exchangeAmountsAreUnset = (exchangeAmounts: readonly ExchangeAmountFragment[] | null): boolean => {
  return !exchangeAmounts || exchangeAmounts.reduce((sum, ex) => sum + ex.amount, 0) === 0;
};

interface ServingAmountWithExchangeTotals {
  servingAmount: FullServingAmountFragment;
  exchangeTotals: ExchangeTotals;
}

export const useServingAmountsWithExchangeTotals = (
  servingAmounts: readonly FullServingAmountFragment[],
  exchangeSet: FullExchangeSetFragment,
  targetExchangeAmounts: readonly ExchangeAmountFragment[],
  context: ResolverContext
): readonly ServingAmountWithExchangeTotals[] => {
  const recipeIds = servingAmounts.flatMap(sa =>
    sa.serving.foodOrRecipe.__typename === "Recipe" && sa.serving.foodOrRecipe.hasFullAccess ? [sa.serving.foodOrRecipe.id] : []
  );
  const { data } = useRecipesByIdWithIngredientsQuery({
    variables: {
      ids: recipeIds.sort(),
    },
  });

  const allDescendantRecipes = data?.recipesById.flatMap(r => r.descendantRecipes.concat(r)) ?? [];

  return servingAmounts.map(servingAmount => {
    const exchangeServingAmounts = exchangeSet.exchanges.flatMap(e => {
      const matchingTargetExchangeAmount = targetExchangeAmounts.find(ea => ea.exchange.id === e.id);
      if (!matchingTargetExchangeAmount) {
        return [];
      }
      return getExchangePickList(e.exchangeServingList, matchingTargetExchangeAmount.pickListServingIds).map(servingAmount => ({
        exchangeId: e.id,
        servingId: servingAmount.serving.id,
        amount: servingAmount.amount,
      }));
    });

    const rawExchangeTotals = servingAmountToExchangeTotals_recursive(
      servingAmount,
      exchangeSet,
      exchangeServingAmounts,
      allDescendantRecipes,
      context
    );

    return {
      servingAmount,
      exchangeTotals: mapExchangeTotals(rawExchangeTotals, total => Math.round(total * 2) / 2),
    };
  });
};

interface ExchangServingAmount {
  exchangeId: string;
  servingId: string;
  amount: number;
}

const servingAmountToExchangeTotals_recursive = (
  servingAmount: FullServingAmountFragment,
  exchangeSet: FullExchangeSetFragment,
  exchangServingAmounts: readonly ExchangServingAmount[],
  descendantRecipes: readonly RecipeWithIngredientsFragment[],
  context: ResolverContext
): ExchangeTotals => {
  const matchingExchangeServingAmount = exchangServingAmounts.find(esa => {
    return esa.servingId === servingAmount.serving.id;
  });
  if (matchingExchangeServingAmount) {
    return {
      [matchingExchangeServingAmount.exchangeId]: servingAmount.amount / matchingExchangeServingAmount.amount,
    };
  }

  const exchangeRatios = getFoodOrRecipeExchangeRatios(exchangeSet, servingAmount.serving, descendantRecipes, context);
  const { foodOrRecipe } = servingAmount.serving;
  if (foodOrRecipe.__typename === "Recipe") {
    const perRecipeYield = servingAmount.serving.perRecipeYield;
    if (perRecipeYield === null) {
      throw new Error("PerRecipeYield is null for recipe serving");
    }
    // For recipe, calculate using exchange amount ratios if present
    if (exchangeRatios.length) {
      return servingAmountToExchangeTotals(servingAmount, exchangeRatios, descendantRecipes, context);
    } else {
      const matchingRecipe = descendantRecipes.find(r => r.id === foodOrRecipe.id);
      if (!matchingRecipe) {
        return {};
      }
      const ingredientExchangeTotals = matchingRecipe.ingredients.map(ingredientServingAmount => {
        const scaledServingAmount = {
          ...ingredientServingAmount,
          amount: ingredientServingAmount.amount * servingAmount.amount,
        };
        return mapExchangeTotals(
          servingAmountToExchangeTotals_recursive(scaledServingAmount, exchangeSet, exchangServingAmounts, descendantRecipes, context),
          total => total / perRecipeYield
        );
      });
      return sumExchangeTotals(ingredientExchangeTotals);
    }
  } else {
    // Always calculate using exchange amount ratios if food
    return servingAmountToExchangeTotals(servingAmount, exchangeRatios, descendantRecipes, context);
  }
};

// There are two ways we could calculate exchange amounts:
// via the principal macro (implementing this for now) or the total kcals
// Investigate which is more applicable in general (preliminary results say macros)
const servingAmountToExchangeTotals = (
  { serving, amount }: FullServingAmountFragment,
  exchangeRatios: readonly ExchangeRatioFragment[],
  descendantRecipes: readonly RecipeWithIngredientsFragment[],
  context: ResolverContext
) => {
  const servingAmoutMacros = scaleMacros(serving.macros, amount);
  const exchangeAmoutMacroTotals = sumMacros(exchangeRatios.map(({ exchange, ratio }) => scaleMacros(exchange, ratio)));

  const anchoredMacroRatios = exchangeRatios.flatMap(({ exchange }) => {
    const anchoredMacro = getExchangeTypeAnchoredMacro(exchange.type);
    if (!anchoredMacro) {
      return [];
    }
    const servingAnchoredMacroValue = servingAmoutMacros[anchoredMacro];
    const exchangeAnchoredMacroValue = exchangeAmoutMacroTotals[anchoredMacro];
    return servingAnchoredMacroValue / exchangeAnchoredMacroValue;
  });

  // Choosing biggest macro contribution to anchor by. This could change in the future.
  const maxAnchoredMacroRatio = anchoredMacroRatios.sort()[anchoredMacroRatios.length - 1];

  const exchangeTotals: ExchangeTotals = {};
  exchangeRatios.forEach(({ exchange, ratio }) => {
    exchangeTotals[exchange.id] = ratio * maxAnchoredMacroRatio;
  });

  return exchangeTotals;
};

import { ListItemButton, ListItemText, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ReactNode, useEffect, useRef } from "react";
import { KioskAthlete } from "../utils";

interface AthleteListItemProps {
  athlete: KioskAthlete;
  selected?: boolean;
  onSelect: (teamId: string) => void;
  backgroundTinted?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  listItemDecoration?: ReactNode;
}

export const ATHLETE_LIST_ITEM_TEXT_WIDTH = 175;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: theme.palette.grey[100],
    },
    listItemText: {
      width: ATHLETE_LIST_ITEM_TEXT_WIDTH,
    },
  })
);

const AthleteListItem = ({
  athlete,
  selected = false,
  onSelect,
  backgroundTinted,
  autoFocus = false,
  disabled = false,
  listItemDecoration,
}: AthleteListItemProps) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref && ref.current) {
      if (autoFocus) {
        ref.current.scrollIntoView();
      }
    }
  }, [autoFocus]);

  return (
    <ListItemButton
      key={athlete.id}
      onClick={() => onSelect(athlete.id)}
      className={backgroundTinted ? classes.selected : undefined}
      ref={ref}
      disabled={disabled}
      selected={selected}
    >
      <ListItemText className={classes.listItemText} primary={`${athlete.lastName}, ${athlete.firstName}`} />
      {listItemDecoration}
    </ListItemButton>
  );
};

export default AthleteListItem;

import React, { useCallback } from "react";

import { newServingAmount } from "@notemeal/shared/ui/ServingAmount/utils";

import { getNextPosition } from "@notemeal/shared/ui/utils/getNextPosition";
import {
  FoodsWithDefaultServingOnlyDocument,
  FoodsWithDefaultServingOnlyQuery,
  FoodsWithDefaultServingOnlyQueryVariables,
  FullServingAmountFragment,
} from "../../types";

import AddIcon from "@mui/icons-material/Add";
import { useFoodSearchFiltersForUser } from "../../utils/foodSearchFilters/foodSearchFiltersForUser";
import { AutocompleteQuerySearchBar } from "../universal/AutocompleteQuerySearchBar/AutocompleteQuerySearchBar";

interface ServingAmountsSearchBarProps {
  selectedServingAmounts: readonly FullServingAmountFragment[];
  onAdd: (servingAmount: FullServingAmountFragment) => void;
  onClickAddFood: () => void;
  noCache: boolean;
}

const FoodOnlyServingAmountsSearchBar = ({ onAdd, selectedServingAmounts, onClickAddFood, noCache }: ServingAmountsSearchBarProps) => {
  const { localeFilter, dataSourceFilter } = useFoodSearchFiltersForUser();
  const nextPosition = getNextPosition(selectedServingAmounts);

  const foodsQueryToServingAmounts = useCallback(
    (data: FoodsWithDefaultServingOnlyQuery): FullServingAmountFragment[] => {
      return data.foods.map(food => newServingAmount(food.defaultServing, nextPosition));
    },
    [nextPosition]
  );

  return (
    <AutocompleteQuerySearchBar<FullServingAmountFragment, FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>
      fullWidth
      query={FoodsWithDefaultServingOnlyDocument}
      placeholder="Search foods..."
      getOptionLabel={o => o.serving.foodOrRecipe.name}
      getOptionsFromQueryData={foodsQueryToServingAmounts}
      getQueryOptions={searchTerm => ({
        variables: {
          searchTerm,
          limit: 10,
          localeCodes: localeFilter,
          dataSources: dataSourceFilter,
        },
      })}
      onChange={o => (o ? onAdd(o) : null)}
      getUserFriendlyQueryErrorMessage={() => "Error searching foods, please try again."}
      lazyQueryHookOptions={{ fetchPolicy: noCache ? "no-cache" : "network-only" }}
      defaultOption={{
        text: "Request Food",
        onClick: onClickAddFood,
        Icon: AddIcon,
      }}
    />
  );
};

export default FoodOnlyServingAmountsSearchBar;

import {
  EditFullMacroMealTemplateFragment,
  EditFullMacroMealTemplateFragmentDoc,
  EditMacroMealTemplateDocument,
  EditMacroMealTemplateFragment,
  EditMacroMealTemplateFragmentDoc,
  EditMacroMealTemplateInput,
  EditMacroMealTemplateMutation,
  EditMacroMealTemplateMutationVariables,
  EditMacroMealTemplatesDocument,
  EditMacroMealTemplatesMutation,
  EditMacroMealTemplatesMutationVariables,
  FullMacroMealTemplateFragment,
  MacroMealTemplateFragment,
  MutationChangeMacroTargetArgs,
  MutationDistributeMacroTargetsArgs,
} from "../../types";
import { createDistributeMacrosMutationVars } from "../../utils/distributeMacros";
import { inputToNumber } from "../../utils/inputUtils";
import { getEditFullMealPlanFragment, writeEditFullMealPlanFragment } from "./MealPlan";
import { initEditMealTemplateFragment } from "./MealTemplate";
import { ResolverContext } from "./types";
import { makeFragmentFuncs, makeFragmentFuncsWithInit } from "./utils";

const initEditMacroMealTemplateFragment = (src: FullMacroMealTemplateFragment): EditMacroMealTemplateFragment => {
  const { cho, pro, fat } = src;
  return {
    ...initEditMealTemplateFragment(src),
    choInput: cho === null ? "" : String(cho),
    proInput: pro === null ? "" : String(pro),
    fatInput: fat === null ? "" : String(fat),
  };
};

const { getFragmentOrInit } = makeFragmentFuncsWithInit(
  "MacroMealTemplate",
  "EditMacroMealTemplate",
  EditMacroMealTemplateFragmentDoc,
  initEditMacroMealTemplateFragment
);

const { getFragment, writeFragment } = makeFragmentFuncs<EditFullMacroMealTemplateFragment>(
  "MacroMealTemplate",
  "EditFullMacroMealTemplate",
  EditFullMacroMealTemplateFragmentDoc
);

export const resolverMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  choInput: (src: FullMacroMealTemplateFragment, args: any, context: ResolverContext): string => {
    return getFragmentOrInit(src, context).choInput;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  proInput: (src: FullMacroMealTemplateFragment, args: any, context: ResolverContext): string => {
    return getFragmentOrInit(src, context).proInput;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fatInput: (src: FullMacroMealTemplateFragment, args: any, context: ResolverContext): string => {
    return getFragmentOrInit(src, context).fatInput;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isAutosaving: (src: FullMacroMealTemplateFragment, args: any, context: ResolverContext): boolean => {
    return getFragmentOrInit(src, context).isAutosaving;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedMealOptionId: (src: FullMacroMealTemplateFragment, args: any, context: ResolverContext): string | null => {
    return getFragmentOrInit(src, context).selectedMealOptionId;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newMealOptionIds: (src: FullMacroMealTemplateFragment, args: any, context: ResolverContext): readonly string[] => {
    return getFragmentOrInit(src, context).newMealOptionIds;
  },
};

export const mutationResolverMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeMacroTarget: (src: any, { mealTemplateId, macro, target }: MutationChangeMacroTargetArgs, context: ResolverContext) => {
    const editFragment = getFragment(mealTemplateId, context);
    const { choInput, proInput, fatInput, isAutosaving } = editFragment;
    const macroValue = inputToNumber(target);

    if (!(isAutosaving && macroValue === null)) {
      // The above causes a slight "hitch" when backspacing across a large number, but is barely noticeable.
      // This prevents infiinte mutation requests when backspacing the contents of a cell
      writeFragment(
        {
          ...editFragment,
          [macro]: macroValue,
          [`${macro}Input`]: target,
          isAutosaving: macroValue !== null,
        },
        context
      );
    }
    if (!isAutosaving && macroValue !== null) {
      runEditMacroMealTemplateMutation(context, {
        input: {
          mealTemplateId,
          cho: inputToNumber(choInput),
          pro: inputToNumber(proInput),
          fat: inputToNumber(fatInput),
          [macro]: macroValue,
        },
      });
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  distributeMacroTargets: (src: any, { mealPlanId }: MutationDistributeMacroTargetsArgs, context: ResolverContext) => {
    const mealPlan = getEditFullMealPlanFragment(mealPlanId, context);
    if (mealPlan.type !== "macro") return;

    const vars = createDistributeMacrosMutationVars(mealPlan);
    writeEditFullMealPlanFragment(
      {
        ...mealPlan,
        mealTemplates: mealPlan.mealTemplates.map(mt => {
          const input = vars.input;
          const matchingVars = isEditMacroMealTemplateInputArray(input)
            ? input.find(({ mealTemplateId }) => mealTemplateId === mt.id)
            : input.mealTemplateId === mt.id
            ? input
            : null;
          if (!matchingVars) return mt;
          return {
            ...mt,
            cho: matchingVars.cho,
            choInput: String(matchingVars.cho),
            pro: matchingVars.pro,
            proInput: String(matchingVars.pro),
            fat: matchingVars.fat,
            fatInput: String(matchingVars.fat),
            isAutosaving: true,
          };
        }),
      },
      context
    );
    runEditMacroMealTemplatesMutation(context, vars);
  },
};

const runEditMacroMealTemplateMutation = (context: ResolverContext, variables: EditMacroMealTemplateMutationVariables) => {
  const { mealTemplateId } = variables.input;
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  context.client.mutate<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>({
    mutation: EditMacroMealTemplateDocument,
    variables,
    update: (_, { data, errors }) => {
      if (errors) {
        console.error("Mutation failed!!!");
        return;
      }

      if (data) {
        updateOrFinishMealTemplateMutation(mealTemplateId, data.editMacroMealTemplate.macroMealTemplate, context);
      }
    },
  });
};

const runEditMacroMealTemplatesMutation = (context: ResolverContext, variables: EditMacroMealTemplatesMutationVariables) => {
  const mealTemplateIds = getMealTemplateIdsFromVariables(variables);
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  context.client.mutate<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>({
    mutation: EditMacroMealTemplatesDocument,
    variables,
    update: (_, { data, errors }) => {
      if (errors) {
        console.error("Mutation failed!!!");
        return;
      }

      if (data) {
        mealTemplateIds.forEach(mtId => {
          const serverMealTemplate = data.editMacroMealTemplates.macroMealTemplates.find(mt => mt.id === mtId);
          if (serverMealTemplate) {
            updateOrFinishMealTemplateMutation(mtId, serverMealTemplate, context);
          }
        });
      }
    },
  });
};

const getMealTemplateIdsFromVariables = (variables: EditMacroMealTemplatesMutationVariables): readonly string[] => {
  const input = variables.input;
  return isEditMacroMealTemplateInputArray(input) ? input.map(({ mealTemplateId }) => mealTemplateId) : [input.mealTemplateId];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isEditMacroMealTemplateInputArray = (obj: any): obj is readonly EditMacroMealTemplateInput[] => {
  return Array.isArray(obj);
};

// Compares meal template in local cache vs. mutation result. If different, send subsequent mutation, else set isAutosaving to false.
const updateOrFinishMealTemplateMutation = (
  mealTemplateId: string,
  serverMealTemplate: MacroMealTemplateFragment,
  context: ResolverContext
) => {
  const editFragment = getFragment(mealTemplateId, context);
  const { choInput, proInput, fatInput } = editFragment;
  const newCho = inputToNumber(choInput);
  const newPro = inputToNumber(proInput);
  const newFat = inputToNumber(fatInput);

  const { cho, pro, fat } = serverMealTemplate;
  if (newCho !== cho || newPro !== pro || newFat !== fat) {
    const newVariables = {
      input: {
        mealTemplateId,
        cho: newCho,
        pro: newPro,
        fat: newFat,
      },
    };
    runEditMacroMealTemplateMutation(context, newVariables);
  } else {
    writeFragment(
      {
        ...editFragment,
        isAutosaving: false,
      },
      context
    );
  }
};

import { getCreateMenuItemInputs, getEditMenuItemInputs } from "../../../components/MenuItemAppearance/utils";
import { AddMealMenuDiningStationInput, CreateMealMenusInput, EditMealMenuInput } from "../../../types";
import { MealMenuDiningStationState } from "./types";

type getCreateMealMenuDiningStationInputsValue = Pick<CreateMealMenusInput, "diningStations">;

export const getCreateMealMenuDiningStationInputs = (
  mealMenuDiningStations: readonly MealMenuDiningStationState[]
): getCreateMealMenuDiningStationInputsValue => {
  return {
    diningStations: mealMenuDiningStations.map(getMealMenuDiningStationInput),
  };
};

interface getEditMealMenuDiningStationInputsArgs {
  initial: readonly MealMenuDiningStationState[];
  final: readonly MealMenuDiningStationState[];
}

type getEditMealMenuDiningStationInputsValue = Pick<EditMealMenuInput, "addDiningStations" | "editDiningStations" | "removeDiningStations">;

export const getEditMealMenuDiningStationInputs = ({
  initial,
  final,
}: getEditMealMenuDiningStationInputsArgs): getEditMealMenuDiningStationInputsValue => {
  const finalIds = final.map(mmds => mmds.id);
  const initialIds = initial.map(mmds => mmds.id);

  return {
    addDiningStations: final.filter(mmds => !initialIds.includes(mmds.id)).map(getMealMenuDiningStationInput),
    editDiningStations: final
      .filter(mmds => initialIds.includes(mmds.id))
      .map(({ id, name, position, maxAmount, menuItemAppearances }) => {
        const initialDS = initial.find(mmds => mmds.id === id);
        if (!initialDS) {
          // TODO: Could refactor to avoid this error but it'd be a pain, should never reach this point
          throw new Error("Invariant violation");
        } else {
          return {
            mealMenuDiningStationId: id,
            name,
            position,
            maxAmount,
            ...getEditMenuItemInputs(menuItemAppearances),
          };
        }
      }),
    removeDiningStations: initialIds.filter(id => !finalIds.includes(id)).map(id => ({ mealMenuDiningStationId: id })),
  };
};

const getMealMenuDiningStationInput = ({
  name,
  diningStationTemplate,
  menuItemAppearances,
  position,
  maxAmount,
}: MealMenuDiningStationState): AddMealMenuDiningStationInput => ({
  diningStationTemplateId: diningStationTemplate?.id || null,
  name,
  position,
  maxAmount,
  ...getCreateMenuItemInputs(menuItemAppearances),
});

export const idArraysAreEqual = (ids1: readonly string[], ids2: readonly string[]) => {
  if (ids1.length !== ids2.length) {
    return false;
  }
  return [...ids1].sort().join() === [...ids2].sort().join();
};

import { Box, Typography } from "@mui/material";
import { ScoreAssignmentFragment } from "apps/web/src/types";
import { ScoreBadge } from "libs/shared/ui/src/lib/Score/ScoreBadge";
import React from "react";

interface MenuItemAppearancePreviewTitleProps {
  isDeleted?: boolean;
  title: string;
  score: ScoreAssignmentFragment | null;
}

export const MenuItemAppearancePreviewTitle = ({ isDeleted, title, score }: MenuItemAppearancePreviewTitleProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="h4" sx={isDeleted ? { textDecoration: "line-through" } : {}}>
        {title}
      </Typography>
      <ScoreBadge score={score} sx={{ ml: 0.5 }} />
    </Box>
  );
};

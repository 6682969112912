import { Card, CardActionArea, CardContent, SvgIconProps, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { ElementType } from "react";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    cardContent: {
      padding: spacing(),
    },
    selectedTitle: {
      fontWeight: 700,
    },
  })
);

interface IconCardProps {
  icon: ElementType<SvgIconProps>;
  title: string;
  onClick?: () => void;
  isSelected?: boolean;
  variant?: "lg" | "sm";
}

const IconCard = ({ icon, title, onClick, isSelected, variant = "lg" }: IconCardProps) => {
  const classes = useStyles();
  const iconProps: SvgIconProps = {
    fontSize: variant === "lg" ? "large" : "medium",
  };

  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <CardContent classes={{ root: classes.cardContent }}>
          <Typography variant={isSelected ? "h4" : "body1"} align="center">
            {title}
          </Typography>
          <Typography align="center">{React.createElement<SvgIconProps>(icon, iconProps)}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default IconCard;

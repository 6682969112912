import { DialogContent, DialogTitle } from "@mui/material";
import React, { useReducer, useState } from "react";
import { SportWithPositionMappingsFragment, TeamworksPositionLinkFragment, useEditTeamForStaffMutation } from "../../../../../types";
import { LinkedTeamsList, NotemealTeamsList, PendingTeamsList, TeamworksTeamsList } from "./DisplayLists";
import { addAndLinkTeamsReducer, AddAndLinkTeamsState, NotemealTeam, PendingTeam, TeamworksTeam } from "./reducer";
import TeamCreateDialog from "./TeamCreateDialog";
import TeamLinkDialog from "./TeamLinkDialog";

import { TeamFormState } from "../../../../Team/EditForm/utils";
import TeamEditModal from "../../../../Team/EditModal";
import { teamFormToEditInput } from "../../../../Team/EditModal/utils";
import LinkModalHeader from "../shared/LinkModalHeader";
import ListsColumn from "../shared/ListColumn";
import ListsContainer from "../shared/ListsContainer";
import ConfirmTeamsLinkDialog from "./ConfirmTeamsLinkDialog";
import EditPendingTeamDialog from "./EditPendingTeamDialog";
import { mergeTeams } from "./utils";

interface TeamsLinkerContentProps {
  initialTeamsState: AddAndLinkTeamsState;
  sports: readonly SportWithPositionMappingsFragment[];
  teamworksPositionLinks: readonly TeamworksPositionLinkFragment[];
  onClose: () => void;
  onNext: () => void;
  orgId: string;
}

interface LinkDialogState {
  teamworksTeam: TeamworksTeam;
  notemealTeam: NotemealTeam;
}

interface CreateDialogState {
  teamworksTeam: TeamworksTeam;
}

const TeamsLinkerContent = ({ initialTeamsState, sports, teamworksPositionLinks, onClose, onNext, orgId }: TeamsLinkerContentProps) => {
  const [state, dispatch] = useReducer(addAndLinkTeamsReducer, initialTeamsState);

  const [showConfirm, setShowConfirm] = useState(false);

  const [selectedNotemealTeam, setSelectedNotemealTeam] = useState<NotemealTeam | null>(null);

  const [linkDialogState, setLinkDialogState] = useState<LinkDialogState | null>(null);
  const [createDialogState, setCreateDialogState] = useState<CreateDialogState | null>(null);

  const [editPendingTeamState, setEditPendingTeamState] = useState<PendingTeam | null>(null);

  const [editExistingTeam, setEditExistingTeam] = useState<NotemealTeam | null>(null);

  const [editTeam, { loading: editTeamLoading }] = useEditTeamForStaffMutation({
    update: (_, result) => {
      if (result.data) {
        const updatedTeam = result.data.editTeamForStaff.team;
        dispatch({
          type: "UPDATE_EXISTING_TEAM",
          payload: {
            ...updatedTeam,
            __type: "NotemealTeam",
            sportName: updatedTeam.sport?.name ?? null,
          },
        });
      }
    },
  });

  const handleEditTeamSave = async (teamState: TeamFormState) => {
    const { gender, sport, name } = teamState;
    if (name) {
      await editTeam({
        variables: {
          input: {
            orgId,
            ...teamFormToEditInput({ ...teamState, gender, sport, name }),
          },
        },
      });
      setEditExistingTeam(null);
    }
  };

  const originalNotemealTeams = state.notemealTeams;

  const { pendingTeams, notemealTeams, teamworksTeams, previousLinkedTeams } = mergeTeams(state);

  const baseListProps = {
    selectedId: selectedNotemealTeam?.id ?? null,
  };

  const handleNextClicked = () => {
    if (state.addedTeams.length === 0 && state.linkedTeams.length === 0) {
      onNext();
    } else {
      setShowConfirm(true);
    }
  };

  const usedTeamNames = [...previousLinkedTeams, ...pendingTeams].map(({ name }) => name.trim().toLowerCase());

  return (
    <>
      <DialogTitle>
        <LinkModalHeader
          activeStep={0}
          nextDisabled={pendingTeams.length === 0 && previousLinkedTeams.length === 0}
          onNextClicked={handleNextClicked}
          onClose={onClose}
          orgName={state.org.name}
        />
      </DialogTitle>
      <DialogContent>
        <ListsContainer>
          {originalNotemealTeams.length > 0 && (
            <ListsColumn>
              <NotemealTeamsList
                {...baseListProps}
                teams={notemealTeams}
                onCancel={() => setSelectedNotemealTeam(null)}
                onLink={team => setSelectedNotemealTeam(team)}
              />
            </ListsColumn>
          )}
          <ListsColumn>
            <TeamworksTeamsList
              {...baseListProps}
              teams={teamworksTeams}
              onAdd={teamworksTeam => {
                setCreateDialogState({ teamworksTeam });
              }}
              onLink={teamworksTeam => {
                if (selectedNotemealTeam) {
                  setLinkDialogState({
                    teamworksTeam,
                    notemealTeam: selectedNotemealTeam,
                  });
                }
              }}
            />
          </ListsColumn>
          <ListsColumn>
            <PendingTeamsList
              {...baseListProps}
              teams={pendingTeams}
              onClick={team => {
                setEditPendingTeamState(team);
              }}
              onCancel={team => dispatch({ type: "REMOVE_PENDING_TEAM_ACTION", payload: team })}
            />
          </ListsColumn>

          {previousLinkedTeams.length > 0 && (
            <>
              <ListsColumn>
                {" "}
                <LinkedTeamsList
                  {...baseListProps}
                  teams={previousLinkedTeams}
                  onEdit={team => setEditExistingTeam(team)} />
              </ListsColumn>
              {editExistingTeam && (
                <TeamEditModal
                  open={editExistingTeam !== null}
                  allowSportEdit={false}
                  onClose={() => setEditExistingTeam(null)}
                  handleSave={handleEditTeamSave}
                  loading={editTeamLoading}
                  team={{
                    ...editExistingTeam,
                    sport: editExistingTeam.sport
                      ? {
                          ...editExistingTeam.sport,
                          __typename: "Sport",
                          positions: editExistingTeam.sport.positions.map(position => ({
                            ...position,
                            __typename: "Position",
                          })),
                        }
                      : null,
                  }}
                />
              )}
            </>
          )}
        </ListsContainer>

        {createDialogState && (
          <TeamCreateDialog
            isOpen={createDialogState !== null}
            sports={sports}
            teamworksTeam={createDialogState.teamworksTeam}
            onCancel={() => setCreateDialogState(null)}
            onConfirm={payload => {
              dispatch({ type: "ADD_TEAM_ACTION", payload });
              setCreateDialogState(null);
            }}
            teamworksOrgId={state.teamworksOrg.id}
            teamworksPositionLinks={teamworksPositionLinks.filter(l => l.teamworksTeamId === createDialogState.teamworksTeam.id)}
            usedTeamNames={usedTeamNames}
          />
        )}
        {linkDialogState && (
          <TeamLinkDialog
            isOpen={linkDialogState !== null}
            teamworksTeam={linkDialogState.teamworksTeam}
            notemealTeam={linkDialogState.notemealTeam}
            onCancel={() => setLinkDialogState(null)}
            sports={sports}
            onConfirm={payload => {
              dispatch({ type: "LINK_TEAMS_ACTION", payload });
              setSelectedNotemealTeam(null);
              setLinkDialogState(null);
            }}
            teamworksOrgId={state.teamworksOrg.id}
            teamworksPositionLinks={teamworksPositionLinks.filter(l => l.teamworksTeamId === linkDialogState.teamworksTeam.id)}
            usedTeamNames={usedTeamNames}
          />
        )}
        {editPendingTeamState && (
          <EditPendingTeamDialog
            isOpen={editPendingTeamState !== null}
            pendingTeam={editPendingTeamState}
            notemealTeams={originalNotemealTeams}
            teamworksTeams={teamworksTeams}
            onCancel={() => setEditPendingTeamState(null)}
            sports={sports}
            onConfirm={payload => {
              dispatch({ type: "EDIT_PENDING_TEAM_ACTION", payload });
              setEditPendingTeamState(null);
            }}
            teamworksOrgId={state.teamworksOrg.id}
            teamworksPositionLinks={teamworksPositionLinks.filter(l => l.teamworksTeamId === editPendingTeamState.teamworksId)}
            usedTeamNames={usedTeamNames}
          />
        )}
      </DialogContent>
      {showConfirm && (
        <ConfirmTeamsLinkDialog
          isOpen={showConfirm}
          teamsLinkState={state}
          confirmComplete={onNext}
          confirmCancel={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default TeamsLinkerContent;

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Divider, Menu, MenuItem, PopoverOrigin, Typography } from "@mui/material";
import Loading from "@notemeal/shared/ui/global/Loading";
import { sortByKeys } from "@notemeal/utils/sort";
import { TWIconButton } from "apps/web/src/componentLibrary/TWIconButton/TWIconButton";
import { useSnackbar } from "apps/web/src/components/Snackbar/SnackbarContext";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import { isCommentNotification, isFoodLogNotification } from "../../../../components/Notification/utils";
import { useMarkNotificationsAsViewedMutation, useTeamPageQuery } from "../../../../types";
import { onCompleteNotifications } from "../../../../utils/notifications";
import AthleteCreateModal from "../../../../views/Team/CreateAthleteModal";
import { TeamDashboard } from "../../../../views/Team/Dashboard/TeamDashboard";
import { TeamRosterList } from "../../../../views/Team/TeamRosterList";
import { getNavOrgAthlete } from "../Athlete/AthletePaths";

export const TeamPage = () => {
  const navigate = useNavigate();
  const { teamId: maybeTeamId } = useParams();
  const teamId = maybeTeamId || "";
  const [actionsMenu, setActionsMenu] = useState<HTMLElement | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [completeNotifications] = useMarkNotificationsAsViewedMutation();
  const { setMessage } = useSnackbar();

  const { data, loading } = useTeamPageQuery({
    variables: { teamId },
  });

  if (loading || !data) {
    return <Loading progressSize="lg" />;
  }

  const foodLogNotificationIds = data.team.athletes.flatMap(ath =>
    ath.timelineMealNotifications.filter(isFoodLogNotification).map(n => n.id)
  );
  const clearFoodLogNotifications = () => {
    completeNotifications({
      variables: {
        input: { notificationIds: foodLogNotificationIds },
      },
      update: (cache, { data }) => {
        onCompleteNotifications(cache, data);
        setMessage("success", "Cleared 'food log' notifications");
      },
    });
  };

  const commentNotificationIds = data.team.athletes.flatMap(ath =>
    ath.timelineMealNotifications.filter(isCommentNotification).map(n => n.id)
  );
  const clearCommentNotifications = () => {
    completeNotifications({
      variables: {
        input: { notificationIds: commentNotificationIds },
      },
      update: (cache, { data }) => {
        onCompleteNotifications(cache, data);
        setMessage("success", "Cleared 'comment' notifications");
      },
    });
  };

  const anchorOrigin: PopoverOrigin = { vertical: "bottom", horizontal: "right" };
  const transformOrigin: PopoverOrigin = { vertical: "top", horizontal: "right" };

  const handleClearFoodLogs = () => {
    setActionsMenu(null);
    clearFoodLogNotifications();
  };

  const handleClearComment = () => {
    setActionsMenu(null);
    clearCommentNotifications();
  };

  return (
    <Box sx={{ pt: 1, height: "100%", display: "flex" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mx: 2, height: "100%", width: 300 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h3">Athletes</Typography>
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
            <Button size="small" onClick={() => setModalOpen(true)}>
              Create
            </Button>
            <TWIconButton
              variant="outlined"
              size="small"
              onClick={e => setActionsMenu(e.currentTarget)}
              icon={<MoreVertIcon />} />
          </Box>
        </Box>
        <TeamRosterList athletes={sortByKeys(data.team.athletes, ["lastName", "firstName"])} />
      </Box>
      <Divider orientation="vertical" />
      <TeamDashboard teamId={teamId} team={data.team} />
      <Menu
        anchorEl={actionsMenu}
        open={!!actionsMenu}
        onClose={() => setActionsMenu(null)}
        keepMounted
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem dense onClick={handleClearFoodLogs}>
          Clear food logs
        </MenuItem>
        <MenuItem dense onClick={handleClearComment}>
          Clear comments
        </MenuItem>
      </Menu>
      {modalOpen && (
        <AthleteCreateModal
          teams={[data.team]}
          teamId={data.team.id}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onFinish={athleteId => {
            setModalOpen(false);
            navigate(getNavOrgAthlete(athleteId));
          }}
        />
      )}
    </Box>
  );
};

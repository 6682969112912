import {
  CuisineFragment,
  PaymentMethod,
  PaymentMethodFragment,
  RefundPolicyType,
  RestaurantType,
  StaffDashboardRestaurantFullFragment,
  Cuisine,
} from "../../../../types";

export interface RestaurantFormState {
  name: string;
  nutritionixId: string | null;
  isActive: boolean;

  website: string | null;
  proteinPortionSizeInOunces: number | null;
  firstPartyDelivery: boolean;
  athleteVisible: boolean;
  refundPolicy: RefundPolicyType | null;
  refundDueBeforeInMinutes: number | null;

  restaurantType: RestaurantType | null;
  paymentMethods: readonly PaymentMethod[];
  cuisines: readonly Cuisine[];
}

interface ChangeName {
  type: "CHANGE_NAME";
  payload: {
    name: string;
  };
}

interface ChangeWebsite {
  type: "CHANGE_WEBSITE";
  payload: {
    website: string | null;
  };
}

interface ChangeProteinPortionSizeInOunces {
  type: "CHANGE_PROTEIN_PORTION_IN_OUNCES";
  payload: {
    proteinPortionSizeInOunces: number | null;
  };
}

interface ChangeFirstPartyDelivery {
  type: "CHANGE_FIRST_PARTY_DELIVERY";
  payload: {
    firstPartyDelivery: boolean;
  };
}
interface ChangeAthleteVisible {
  type: "CHANGE_ATHLETE_VISIBLE";
  payload: {
    athleteVisible: boolean;
  };
}

interface ChangeRefundPolicy {
  type: "CHANGE_REFUND_POLICY";
  payload: {
    refundPolicy: RefundPolicyType | null;
  };
}

interface ChangeRefundDueBeforeInMinutes {
  type: "CHANGE_REFUND_DUE_BEFORE_IN_MINUTES";
  payload: {
    refundDueBeforeInMinutes: number | null;
  };
}

interface ChangeRestaurantType {
  type: "CHANGE_RESTAURANT_TYPE";
  payload: {
    restaurantType: RestaurantType | null;
  };
}

interface ChangePaymentMethods {
  type: "CHANGE_PAYMENT_METHODS";
  payload: {
    paymentMethods: readonly PaymentMethodFragment[];
  };
}

interface ChangeCuisines {
  type: "CHANGE_CUISINES";
  payload: {
    cuisines: readonly CuisineFragment[];
  };
}
interface ChangeNutritionixId {
  type: "CHANGE_NUTRITIONIX_ID";
  payload: {
    nutritionixName: string;
    nutritionixId: string;
  } | null;
}

interface ChangeIsActive {
  type: "CHANGE_IS_ACTIVE";
  payload: {
    isActive: boolean;
  };
}

export type RestaurantFormAction =
  | ChangeName
  | ChangeNutritionixId
  | ChangeIsActive
  | ChangeWebsite
  | ChangeProteinPortionSizeInOunces
  | ChangeFirstPartyDelivery
  | ChangeAthleteVisible
  | ChangeRefundPolicy
  | ChangeRefundDueBeforeInMinutes
  | ChangeRestaurantType
  | ChangePaymentMethods
  | ChangeCuisines;

export function restaurantFormReducer<T extends RestaurantFormState>(state: T, action: RestaurantFormAction): T {
  switch (action.type) {
    case "CHANGE_NAME":
      return { ...state, name: action.payload.name };
    case "CHANGE_NUTRITIONIX_ID":
      if (action.payload) {
        return {
          ...state,
          nutritionixId: action.payload.nutritionixId,
          name: state.name || action.payload.nutritionixName,
        };
      } else {
        return { ...state, nutritionixId: null };
      }
    case "CHANGE_IS_ACTIVE":
      return {
        ...state,
        isActive: action.payload.isActive,
      };
    case "CHANGE_WEBSITE": {
      return {
        ...state,
        website: action.payload.website,
      };
    }
    case "CHANGE_PROTEIN_PORTION_IN_OUNCES": {
      return {
        ...state,
        proteinPortionSizeInOunces: action.payload.proteinPortionSizeInOunces,
      };
    }
    case "CHANGE_FIRST_PARTY_DELIVERY": {
      return {
        ...state,
        firstPartyDelivery: action.payload.firstPartyDelivery,
      };
    }
    case "CHANGE_ATHLETE_VISIBLE": {
      return {
        ...state,
        athleteVisible: action.payload.athleteVisible,
      };
    }
    case "CHANGE_REFUND_POLICY": {
      return {
        ...state,
        refundPolicy: action.payload.refundPolicy,
      };
    }
    case "CHANGE_REFUND_DUE_BEFORE_IN_MINUTES": {
      return {
        ...state,
        refundDueBeforeInMinutes: action.payload.refundDueBeforeInMinutes,
      };
    }
    case "CHANGE_RESTAURANT_TYPE": {
      return {
        ...state,
        restaurantType: action.payload.restaurantType,
      };
    }
    case "CHANGE_PAYMENT_METHODS": {
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods,
      };
    }
    case "CHANGE_CUISINES": {
      return {
        ...state,
        cuisines: action.payload.cuisines,
      };
    }
    default:
      return state;
  }
}

export const buildRestaurantFormTooltips = (state: RestaurantFormState): string[] => {
  const tooltips: string[] = [];
  if (!state.name.trim()) {
    tooltips.push("'Name' is required");
  }

  return tooltips;
};

export const buildInitialCreateFormState = (): RestaurantFormState => ({
  name: "",
  nutritionixId: null,
  isActive: true,

  website: null,
  proteinPortionSizeInOunces: null,
  firstPartyDelivery: false,
  athleteVisible: false,
  refundPolicy: null,
  refundDueBeforeInMinutes: null,

  restaurantType: null,
  paymentMethods: [],
  cuisines: [],
});

export const buildInitialEditFormState = ({
  name,
  nutritionixId,
  isActive,
  website,
  proteinPortionSizeInOunces,
  firstPartyDelivery,
  athleteVisible,
  refundPolicy,
  refundDueBeforeInMinutes,
  restaurantType,
  paymentMethods,
  cuisines,
}: StaffDashboardRestaurantFullFragment): RestaurantFormState => ({
  name,
  nutritionixId,
  isActive,
  website,
  proteinPortionSizeInOunces,
  firstPartyDelivery,
  athleteVisible,
  refundPolicy,
  refundDueBeforeInMinutes,
  restaurantType,
  paymentMethods,
  cuisines,
});

import { Chip, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import React from "react";
import { NutritionixBrandedFoodFragment } from "../../../types";

interface LoadingChipProps {
  food: NutritionixBrandedFoodFragment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      height: theme.spacing(4),
      marginTop: theme.spacing(1),
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      flexWrap: "nowrap",
    },
    chipLabel: {
      flexGrow: 1,
      flexShrink: 1,
      minWidth: 0,
    },
    chipLabelWrapper: {
      marginLeft: theme.spacing(4.5),
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    foodServingAmount: {
      lineHeight: 1,
    },
    foodName: {
      lineHeight: 1,
      fontWeight: "bold",
    },
    chipContainer: {
      display: "flex",
      flexDirection: "row",
    },
    loading: {
      padding: theme.spacing(),
    },
    fullWidth: {
      width: "100%",
    },
  })
);

const LoadingChip = ({ food }: LoadingChipProps) => {
  const classes = useStyles();
  return (
    <Chip
      classes={{ label: classes.fullWidth }}
      label={
        <div className={classes.chipContainer}>
          <div className={classes.loading}>
            <Loading progressSize="xs" />
          </div>
          <div className={classes.chipLabelWrapper}>
            <Typography
              variant="subtitle1"
              classes={{ root: classes.foodName }}
              noWrap>
              {food.name}
            </Typography>
            <br />
            <Typography
              variant="subtitle1"
              classes={{ root: classes.foodServingAmount }}
              noWrap>
              Loading...
            </Typography>
          </div>
        </div>
      }
      className={classes.chip}
    />
  );
};

export default LoadingChip;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import LoadingBackdrop from "../../components/universal/LoadingBackdrop";
import { NAV_ROOT } from "./AuthPaths";
import { useMemberships, useSwitchOrgs } from "./Org/OrgMembershipUtils";

export const SwitchOrgPage = () => {
  const navigate = useNavigate();
  const switchOrgs = useSwitchOrgs();
  const memberships = useMemberships();
  useEffect(() => {
    async function switchToFirstOrg() {
      if (memberships && memberships.length) {
        await switchOrgs(memberships[0].org.id);
        navigate(NAV_ROOT);
      }
    }
    switchToFirstOrg();
  }, [memberships, switchOrgs, navigate]);

  return <LoadingBackdrop open={true} onClose={() => {}} />;
};

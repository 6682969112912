import { ApolloCache, ApolloClient } from "@apollo/client";
import {
  AthleteTimelineForDateDocument,
  AthleteTimelineForDateQuery,
  AthleteTimelineForDateQueryVariables,
  CommentTimelineFragment,
  TimelineMealFragment,
  TimelineMealFragmentDoc,
} from "../../../types";

interface AddTimelineMealCommentArgs {
  client: ApolloClient<object>;
  timelineMealId: string;
  comment: CommentTimelineFragment;
}

export const addTimelineMealComment = ({ client, timelineMealId, comment }: AddTimelineMealCommentArgs) => {
  try {
    const fragmentParams = {
      id: `TimelineMeal:${timelineMealId}`,
      fragmentName: "TimelineMeal",
      fragment: TimelineMealFragmentDoc,
    };
    const frag = client.readFragment<TimelineMealFragment>(fragmentParams);
    if (frag && !frag.comments.map(c => c.id).includes(comment.id)) {
      client.writeFragment({
        ...fragmentParams,
        data: {
          ...frag,
          comments: [...frag.comments, comment],
        },
      });
    }
  } catch (e) {
    console.info("Can't add timelineMeal comment b/c fragment is empty.");
  }
};

interface updateAthleteTimelineInCacheArgs {
  athleteId: string;
  date: string;
  timezone: string;
  cache: ApolloCache<any>;
  updateFn: (query: AthleteTimelineForDateQuery) => AthleteTimelineForDateQuery | null;
}

export const updateAthleteTimelineInCache = ({ cache, date, timezone, athleteId, updateFn }: updateAthleteTimelineInCacheArgs) => {
  const cacheQuery = cache.readQuery<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>({
    query: AthleteTimelineForDateDocument,
    variables: { athleteId, date, timezone },
  });
  if (cacheQuery) {
    const newData = updateFn(cacheQuery);
    if (!newData) {
      return;
    }
    cache.writeQuery<AthleteTimelineForDateQuery, AthleteTimelineForDateQueryVariables>({
      query: AthleteTimelineForDateDocument,
      variables: { athleteId, date, timezone },
      data: newData,
    });
  }
};

interface evictAthleteTimelineInCacheArgs {
  athleteId: string;
  date: string;
  timezone: string;
  cache: ApolloCache<any>;
}

export const evictAthleteTimelineInCache = ({ cache, date, timezone, athleteId }: evictAthleteTimelineInCacheArgs) => {
  cache.evict({
    id: `Athlete:${athleteId}`,
    fieldName: "timelineDate",
    args: { date, timezone },
    broadcast: true,
  });
};

interface AddTimelineMealIfNotPresentArgs {
  athleteId: string;
  date: string;
  timezone: string;
  cache: ApolloCache<any>;
  timelineMeal: TimelineMealFragment;
}

export const addTimelineMealIfNotPresent = ({ cache, date, timezone, athleteId, timelineMeal }: AddTimelineMealIfNotPresentArgs) => {
  updateAthleteTimelineInCache({
    cache,
    date,
    timezone,
    athleteId,
    updateFn: data => {
      const currentTimelineMealIds = data.athlete.timelineDate.meals.flatMap(t => (t.__typename === "TimelineMeal" ? t.id : []));
      if (currentTimelineMealIds.includes(timelineMeal.id)) {
        return null;
      }
      return {
        ...data,
        athlete: {
          ...data.athlete,
          timelineDate: {
            ...data.athlete.timelineDate,
            meals: [...data.athlete.timelineDate.meals, timelineMeal],
          },
        },
      };
    },
  });
};

import { Card, CardActionArea, CardContent, Dialog, DialogContent, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { LinkedProfile } from "@notemeal/profile-sync";
import DialogTitle from "apps/web/src/componentLibrary/DialogTitle";
import React, { useState } from "react";
import { OrgMembershipForLinkingFragment } from "../../../../../types";
import ListsColumn from "../shared/ListColumn";
import ListsContainer from "../shared/ListsContainer";
import ProfilesList from "./SyncRule/DisplayLists/ProfilesList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listColumn: {
      overflow: "hidden",
    },
    paper: {
      height: "calc(100% - 64px)",
    },
    cardContent: {
      display: "flex",
      justifyContent: "center",
      color: "white",
      backgroundColor: "red",
    },
    dialogContent: { padding: 0 },
  })
);

interface OtherProfilesCardProps {
  className?: string;
  toDeactivateLinkedProfiles: readonly LinkedProfile[];
  toDeactivateOrgMemberships: readonly OrgMembershipForLinkingFragment[];
}

const OtherProfilesCard = ({ className, toDeactivateLinkedProfiles, toDeactivateOrgMemberships }: OtherProfilesCardProps) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const deactivatingProfiles = toDeactivateLinkedProfiles.length > 0;
  const profilesNotFound = toDeactivateOrgMemberships.length > 0;

  if (!deactivatingProfiles && !profilesNotFound) {
    return null;
  }

  let cardTexts = [];
  if (deactivatingProfiles) {
    cardTexts.push(`${toDeactivateLinkedProfiles.length} Profile(s) will
    be de-activated due to no longer matching a Profile Sync Rule`);
  }
  if (profilesNotFound) {
    cardTexts.push(`${toDeactivateOrgMemberships.length} Profile(s) will be de-activated because linked Teamworks Profile is now inactive`);
  }
  const cardText = cardTexts.join(", ");

  return (
    <Card raised className={className}>
      <CardActionArea onClick={() => setShowDialog(true)}>
        <CardContent className={classes.cardContent}>
          <Typography>{cardText}</Typography>
        </CardContent>
      </CardActionArea>
      {showDialog && (
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="md"
          fullWidth
          classes={{ paper: classes.paper }}>
          <DialogTitle title="To Deactivate Profiles" onClose={() => setShowDialog(false)} />
          <DialogContent className={classes.dialogContent}>
            <ListsContainer>
              {deactivatingProfiles && (
                <ListsColumn className={classes.listColumn}>
                  <ProfilesList
                    title="No Rule Match"
                    selectedId={null}
                    columnMode="NoAction"
                    profiles={toDeactivateLinkedProfiles.map(p => p.teamworks)}
                    getProfileForDisplay={({ firstName, lastName, email, cellPhone }) => ({
                      firstName,
                      lastName,
                      email,
                      phoneNumber: cellPhone,
                      missingTeamworksRequiredFields: false, // Not relevant here because these profiles are not being sync'd
                    })}
                  />
                </ListsColumn>
              )}
              <ListsColumn className={classes.listColumn}>
                <ProfilesList
                  title="Inactive in Teamworks"
                  selectedId={null}
                  columnMode="NoAction"
                  profiles={toDeactivateOrgMemberships.map(p => p.user)}
                  getProfileForDisplay={({ firstName, lastName, email, phoneNumber }) => ({
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    missingTeamworksRequiredFields: false, // Not relevant here because these profiles are not being sync'd
                  })}
                />
              </ListsColumn>
            </ListsContainer>
          </DialogContent>
        </Dialog>
      )}{" "}
    </Card>
  );
};

export default OtherProfilesCard;

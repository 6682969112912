import { TableCell, TableRow } from "@mui/material";
import { useDateFormatting } from "@notemeal/shared/ui/contexts/useDateFormatting";
import { capitalize } from "@notemeal/shared/ui/utils/capitalize";
import React from "react";
import { ViewAttendanceRowInput } from "./utils";

interface ViewAttendanceTableRowProps {
  input: ViewAttendanceRowInput;
  hasAccess: boolean | null;
}

export const ViewAttendanceTableRow = ({ hasAccess, input }: ViewAttendanceTableRowProps) => {
  const { id, start, type, name, mealMenuExpectedAttendeeCount, mealMenuActualAttendeeCount, attendeeListedCount, attendeeUnlistedCount } =
    input;

  const { formatDateWithLocale } = useDateFormatting();

  const checkInCount = hasAccess === true ? attendeeListedCount : hasAccess === false ? attendeeUnlistedCount : mealMenuActualAttendeeCount;
  const attendancePercentage = mealMenuExpectedAttendeeCount === 0 ? 0 : Math.round((checkInCount / mealMenuExpectedAttendeeCount) * 100);
  const formattedDate = formatDateWithLocale(start);

  return (
    <TableRow sx={{ height: 45 }} key={id}>
      <TableCell>{formattedDate}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{capitalize(type)}</TableCell>
      <TableCell>{checkInCount}</TableCell>
      <TableCell>{mealMenuExpectedAttendeeCount}</TableCell>
      <TableCell>{attendancePercentage}%</TableCell>
    </TableRow>
  );
};

export const ViewAttendanceTableHeaderRow = () => {
  return (
    <TableRow>
      <TableCell>Date</TableCell>
      <TableCell>Meal Name</TableCell>
      <TableCell>Meal Type</TableCell>
      <TableCell>Check-Ins</TableCell>
      <TableCell>Expected Check-Ins</TableCell>
      <TableCell>% of Expected Check-Ins</TableCell>
    </TableRow>
  );
};

import { Avatar, Button, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "@notemeal/shared/ui/global/Loading";
import { parseDate, serializeDate } from "@notemeal/shared/ui/utils/dateTimes";
import { BonAppetitMenusPreviewFragment } from "apps/web/src/types";
import classnames from "classnames";
import React from "react";
import WeekNavigation from "../../WeekNavigation";

const useStyles = makeStyles(({ spacing, palette: { info, warning, common, text, getContrastText } }: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "auto",
      gridTemplateRows: "auto 1fr",
      height: "100%",
      overflow: "auto",
    },
    header: {
      position: "sticky",
      gridRowStart: 1,
      gridColumnStart: 1,
      zIndex: 1,
      top: 0,
      backgroundColor: common.white,
    },
    subHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerDate: {
      flex: 1,
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
    },
    dateAvatar: {
      backgroundColor: common.white,
      color: text.secondary,
    },
    todayAvatar: {
      backgroundColor: info.main,
      color: getContrastText(info.main),
    },
    spacer: {
      flexGrow: 1,
    },
    fixedSpacer: {
      width: 175,
    },
    date: {
      flex: 1,
    },
    content: {
      display: "flex",
      gridRowStart: 2,
      gridColumnStart: 1,
    },
    serviceMenu: {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      margin: spacing(0.5),
      padding: spacing(0.5),
    },
    ignoreServiceMenu: {
      backgroundColor: info.lighter,
    },
    importServiceMenu: {
      backgroundColor: info.main,
    },
    baseServiceMenu: {
      backgroundColor: warning.main,
    },
    station: {
      marginTop: spacing(1.5),
    },
    menuItem: {
      marginTop: spacing(0.5),
    },
    legendItem: {
      display: "flex",
      alignItems: "center",
      marginTop: spacing(),
    },
    legendColor: {
      height: 24,
      width: 24,
      borderRadius: 12,
      marginRight: spacing(),
    },
    selectAllButton: {
      marginBottom: spacing(),
    },
  })
);

const DAY_OF_WEEK_NAMES = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

interface SelectBonAppetitMenusProps {
  loading: boolean;
  menusPreview: BonAppetitMenusPreviewFragment;
  selectedMenuIds: readonly string[];
  onToggleMenu: (menuId: string) => void;
  onSelectAllMenus: () => void;
  startOfWeekDate: Date;
  onChangeStartOfWeekDate: (startOfWeekDate: Date) => void;
}

const SelectBonAppetitMenus = ({
  loading,
  menusPreview,
  selectedMenuIds,
  onToggleMenu,
  onSelectAllMenus,
  startOfWeekDate,
  onChangeStartOfWeekDate,
}: SelectBonAppetitMenusProps) => {
  const classes = useStyles();
  const today = serializeDate(new Date());

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.subHeader}>
          <div className={classes.fixedSpacer}>
            <div className={classes.legendItem}>
              <div className={classnames(classes.legendColor, classes.importServiceMenu)} />
              <Typography>Import</Typography>
            </div>
            <div className={classes.legendItem}>
              <div className={classnames(classes.legendColor, classes.ignoreServiceMenu)} />
              <Typography>Ignore</Typography>
            </div>
          </div>
          <div className={classes.spacer} />
          <WeekNavigation startOfWeekDate={startOfWeekDate} onChange={onChangeStartOfWeekDate} />
          <div className={classes.spacer} />
          <div className={classes.fixedSpacer}>
            <Button onClick={onSelectAllMenus} className={classes.selectAllButton}>
              Select All Menus
            </Button>
          </div>
        </div>
        <div className={classes.subHeader}>
          {menusPreview.days.map(({ date }, dayOfWeek) => {
            const isToday = date === today;
            const dayOfWeekColor = isToday ? "info" : ("textSecondary" as const);
            const avatarClassName = isToday ? classes.todayAvatar : classes.dateAvatar;

            return (
              <div key={date} className={classes.headerDate}>
                <Typography variant="subtitle1" color={dayOfWeekColor}>
                  {DAY_OF_WEEK_NAMES[dayOfWeek]}
                </Typography>
                <Avatar className={avatarClassName}>
                  <Typography variant="h4" color="inherit">
                    {parseDate(date).getDate()}
                  </Typography>
                </Avatar>
              </div>
            );
          })}
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className={classes.content}>
          {menusPreview.days.map(({ date, dayParts }) => {
            return (
              <div key={date} className={classes.date}>
                {dayParts.map(({ id, label }) => {
                  const menuId = `${date} ${id}`;
                  const selected = selectedMenuIds.includes(menuId);
                  return (
                    <Paper
                      key={menuId}
                      className={classnames(classes.serviceMenu, {
                        [classes.importServiceMenu]: selected,
                        [classes.ignoreServiceMenu]: !selected,
                      })}
                      onClick={() => onToggleMenu(menuId)}
                    >
                      <Typography>{label}</Typography>
                    </Paper>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectBonAppetitMenus;

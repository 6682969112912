import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Box, Typography } from "@mui/material";
import React from "react";

interface GPTLoadingContentProps {
  actionText: string;
}

export const GPTLoadingContent = ({ actionText }: GPTLoadingContentProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
      <DotLottieReact
        src="https://lottie.host/602904f8-d7ad-4e18-9380-ff5d662066fc/yJ3IXiaIQg.lottie"
        autoplay
        loop
        style={{ width: "250px" }}
      />
      <Typography variant="h4">{actionText} your recipe...</Typography>
      <Typography variant="body1" color="textSecondary">
        Please sit back and wait momentarily for your new recipe!
      </Typography>
    </Box>
  );
};

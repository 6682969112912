import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import EditMealPlanTemplate from "../../components/MealPlanTemplate/EditMealPlanTemplate";
import LoadingBackdrop from "../../components/universal/LoadingBackdrop";
import { GoalTypeFragment, useMealPlanTemplateQuery } from "../../types";

interface ViewOnlyMealPlanTemplateProps {
  open: boolean;
  onClose: () => void;
  goalTypes: readonly GoalTypeFragment[];
  id: string;
}

const ViewOnlyMealPlanTemplate = ({ open, onClose, goalTypes, id }: ViewOnlyMealPlanTemplateProps) => {
  const { data, loading } = useMealPlanTemplateQuery({ variables: { id } });

  return loading || !data?.mealPlanTemplate ? (
    <LoadingBackdrop open={open} onClose={onClose} />
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen>
      <DialogContent>
        <EditMealPlanTemplate
          viewOnly={true}
          mealPlanTemplate={data.mealPlanTemplate}
          editMealPlanTemplate={() => {}}
          publishMealPlanTemplate={() => {}}
          deletePendingState={() => {}}
          onClose={onClose}
          goalTypes={goalTypes}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ViewOnlyMealPlanTemplate;

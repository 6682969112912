import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import { getExchangeAmountsMacros } from "@notemeal/shared/ui/ExchangeAmount/utils";
import MacrosSummaryLabel from "@notemeal/shared/ui/Macros/SummaryLabel";
import { formatTimeRange } from "@notemeal/shared/ui/utils/dateTimes";
import React, { memo, useState } from "react";
import { EditFullExchangeMealTemplateFragment, FullExchangeSetFragment } from "../../../../../types";
import { makeExchangeTargetsById } from "../../../../MealPlan/Edit/Exchange/utils";
import { ExchangeAmounts } from "./ExchangeAmounts";
import { ExpandedExchangeAmounts } from "./ExpandedExchangeAmounts";

interface EditExchangeTargetsProps {
  mealTemplate: EditFullExchangeMealTemplateFragment;
  exchangeSet: FullExchangeSetFragment;
}

export const EditExchangeTargets = memo(({ mealTemplate, exchangeSet }: EditExchangeTargetsProps) => {
  const [showExpanded, setShowExpanded] = useState(false);
  const exchanges = exchangeSet.exchanges;
  const exchangeTargetsById = makeExchangeTargetsById(exchanges, mealTemplate.exchangeTargets);
  const { meal } = mealTemplate;

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "greyscale.25",
        borderColor: "greyscale.50",
      }}
    >
      <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
        <Box sx={{ mr: "2px", height: "100%", width: "6px", backgroundColor: "greyscale.400" }} />
        <Box sx={{ pr: 2, flex: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ pt: 2, display: "flex", gap: 2, alignItems: "center" }}>
              <RestaurantIcon fontSize="small" sx={{ color: "greyscale.400" }} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1Medium">{meal.name}</Typography>
                <Typography sx={{ color: "mediumEmphasisText" }} variant="body2">
                  {formatTimeRange(meal.start, meal.end)}
                </Typography>
              </Box>
            </Box>
            <MacrosSummaryLabel
              macros={getExchangeAmountsMacros(mealTemplate.exchangeTargets)}
              variant="sm"
              kcalPrefix={true} />
          </Box>
          <Box sx={{ pb: 2, flex: 1, display: "flex", gap: 2, alignItems: "center" }}>
            {showExpanded ? (
              <ExpandedExchangeAmounts
                mealTemplateId={mealTemplate.id}
                exchangeTargetsById={exchangeTargetsById}
                exchangeSet={exchangeSet}
              />
            ) : (
              <ExchangeAmounts
                mealTemplateId={mealTemplate.id}
                exchangeTargetsById={exchangeTargetsById}
                exchangeSet={exchangeSet} />
            )}
            <Tooltip sx={{ alignSelf: "flex-start" }} title="Toggle exchange pick lists">
              <IconButton onClick={() => setShowExpanded(!showExpanded)}>{showExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}</IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Card>
  );
});

import { Food } from "../../../types";

export interface FoodGroupFormState {
  foods: readonly Pick<Food, "id" | "name">[];
  name: string | null;
  source: string | null;
  id: string | undefined;
  addedFoods: readonly Pick<Food, "id" | "name">[];
  removedFoods: readonly Pick<Food, "id" | "name">[];
}

interface AddFood {
  type: "ADD_FOOD";
  payload: Pick<Food, "id" | "name">;
}
interface RemoveFood {
  type: "REMOVE_FOOD";
  payload: Pick<Food, "id" | "name">;
}
interface ChangeName {
  type: "CHANGE_NAME";
  payload: string | null;
}
interface ChangeSource {
  type: "CHANGE_SOURCE";
  payload: string | null;
}
interface InitFromQuery {
  type: "INIT_FROM_QUERY";
  payload: FoodGroupFormState;
}

type FoodGroupFormAction = AddFood | RemoveFood | ChangeName | ChangeSource | InitFromQuery;

export const foodGroupFormReducer = (state: FoodGroupFormState, action: FoodGroupFormAction): FoodGroupFormState => {
  switch (action.type) {
    case "ADD_FOOD":
      const foodAlreadyAdded = state.addedFoods.find(f => f.id === action.payload.id);
      if (foodAlreadyAdded) {
        return state;
      }
      return {
        ...state,
        foods: [...state.foods, action.payload],
        removedFoods: state.removedFoods.filter(food => food.id !== action.payload.id),
        addedFoods: [...state.addedFoods, ...(state.removedFoods.find(food => food.id === action.payload.id) ? [] : [action.payload])],
      };
    case "REMOVE_FOOD":
      return {
        ...state,
        foods: state.foods.filter(food => food.id !== action.payload.id),
        addedFoods: state.addedFoods.filter(food => food.id !== action.payload.id),
        removedFoods: [...state.removedFoods, ...(state.addedFoods.find(food => food.id === action.payload.id) ? [] : [action.payload])],
      };
    case "CHANGE_NAME":
      return { ...state, name: action.payload };
    case "CHANGE_SOURCE":
      return { ...state, source: action.payload };
    case "INIT_FROM_QUERY":
      return action.payload;
    default:
      return state;
  }
};

export const getCanSaveTooltips = (state: FoodGroupFormState) => {
  let tooltips = [];
  if (state.name === null || state.name === "") {
    tooltips.push("Name is required.");
  }
  return tooltips;
};
